import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ApplicationContext, AssetContext } from '..';
import {
    DashboardListItem,
    DashboardListFlags,
    DashboardFlagQueryOperator,
} from '../../api/v2';
import { listDashboardHandler } from './dashboardListQuery';
import React from 'react';
import { DashboardContext } from './dashboardContext';

const QUERY_PREFIX = ['dashboards.list'];

export function refreshDashboardListQueries(config: { client: QueryClient }) {
    return config.client.resetQueries(QUERY_PREFIX, {});
}

export const useDashboardList = (
    context: ApplicationContext,
    assetId?: number,
    dashbard?: string,
    operator?: DashboardFlagQueryOperator,
    flags?: Array<DashboardListFlags>
): UseQueryResult<DashboardListItem[]> => {
    const query = useQuery({
        queryKey: [...QUERY_PREFIX, assetId, dashbard, operator, ...(flags || [])],
        queryFn: listDashboardHandler(
            context,
            assetId ? [assetId] : [],
            dashbard,
            operator,
            flags
        ),
        suspense: true,
        refetchOnWindowFocus: false,
    });
    return query;
};

export const useLeanDashboard = (dashContext: DashboardContext) => {
    const dashList = useDashboardList(
        dashContext,
        dashContext.assetId,
        dashContext.slug,
        'any',
        ['exclusive_access', 'is_highlighted']
    );
    const dashboard = React.useMemo<DashboardListItem | null>(() => {
        return (
            dashList.data?.find((dashboard) => dashboard.slug === dashContext.slug) ??
            null
        );
    }, [dashList.data]);
    return { dashboard, isLoading: dashList.isLoading };
};
