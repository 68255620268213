import moment from 'moment';
import { ApplicationContext } from '../../hooks';
import { Duration } from '../duration';
import { DateRange } from './daterangeModel';

export function daterangeToString(
    context: Pick<ApplicationContext, 'formatter'>,
    value: DateRange
) {
    return context.formatter.daterange(value);
}

export function daterangeToDuration(value: DateRange): Duration {
    const differenceInDays = moment.utc(value.end).diff(value.start, 'days');
    return {
        interval: 'day',
        amount: differenceInDays,
    };
}

export function subtractDuration(value: DateRange, duration: Duration): DateRange {
    return {
        ...value,
        start: moment
            .utc(value.start)
            .subtract(duration.amount, duration.interval)
            .toDate(),
        end: moment.utc(value.end).subtract(duration.amount, duration.interval).toDate(),
    };
}
