import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getAssetCounts } from '../../../api/counts';
import { ViewEntity } from '../../domain';
import { castTypeToDomain } from '../../impl';
import { SystemContextDeps } from './systemConfig';
import { SystemContextLoader } from './systemInterface';

export function createSystemContextLoader(deps: SystemContextDeps): SystemContextLoader {
    const {
        axios,
        api: {
            dashboard: {
                views: { list: listViews },
            },
        },
    } = deps;
    return {
        useData(context) {
            const queries = useQueries({
                queries: [
                    {
                        queryKey: ['views'],
                        async queryFn() {
                            const response = await listViews(context, {});
                            return response.data.map((item): ViewEntity => {
                                return {
                                    ...item,
                                    columns: item.columns.map((column) => ({
                                        ...column,
                                        type: castTypeToDomain(column.type),
                                        constraints: [],
                                        isDisabled: false,
                                        disabledReason: null,
                                    })),
                                };
                            });
                        },
                        staleTime: Infinity,
                    },
                    {
                        queryKey: ['asset-counts'],
                        queryFn() {
                            return getAssetCounts(axios);
                        },
                        staleTime: Infinity,
                    },
                    // // TODO move feature loading into middleware
                    // {
                    //     queryKey: ['features'],
                    //     async queryFn() {
                    //         const response = await listFeatures(context, axios);
                    //         const featureById = response.items.reduce(
                    //             (acc, item) => ({ ...acc, [item.id]: item }),
                    //             {} as FeatureMap
                    //         );
                    //         return featureById;
                    //     },
                    //     staleTime: Infinity,
                    // },
                ],
            });

            // const responseFeatures = repository.feature.useLookup(context);

            const firstError = queries.find((q) => q.isError);
            if (firstError) {
                throw firstError;
            }
            const [responseViews, responseAssetCounts] = queries;

            const mergedStatus = useMemo(
                () => ({
                    isLoading: queries.every((item) => item.isLoading),
                    isSuccess: queries.every((item) => item.isSuccess),
                }),
                [queries]
            );

            return {
                isLoading: mergedStatus.isLoading,
                isSuccess: mergedStatus.isSuccess,
                data: {
                    views: responseViews,
                    assetCount: responseAssetCounts,
                    // features: responseFeatures,
                },
            };
        },
    };
}
