import {
    BenchmarkItemConnectedProps,
    BenchmarkItemDisconnectedProps,
    BenchmarkItemProps,
} from './benchmarkListProps';

export function isErrorProps(props: BenchmarkItemProps): props is Error {
    return props instanceof Error;
}

export function isConnectedProps(
    props: BenchmarkItemProps
): props is BenchmarkItemConnectedProps {
    return !isErrorProps(props) && props.status === 'ready';
}

export function isDisconnectedProps(
    props: BenchmarkItemProps
): props is BenchmarkItemDisconnectedProps {
    return !isErrorProps(props) && !isConnectedProps(props);
}
