import { useQuery } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { PricingPlan } from '../plan';
import { createPortalHandler } from './portalMutation';

export const usePortal = (
    context: ApplicationContext,
    plan: Pick<PricingPlan, 'id'> | null,
    options: { returnPath: string }
) => {
    const handler = createPortalHandler(context, {
        plan: plan?.id,
        return_path: options.returnPath,
    });
    const query = useQuery(['billing', 'portal'], handler, { suspense: true });
    return query;
};
