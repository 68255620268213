import React, { useEffect } from 'react';
import { ReportDetailController } from '../../../view/reports';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyReportEvent } from './trackingReportEvent';
import { chain } from 'lodash';
import moment from 'moment';

export function createReportLoadTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyReportEvent>();
            return tracker;
        }

        function enhanceReportController(
            controller: ReportDetailController
        ): ReportDetailController {
            return {
                ...controller,
                useProps(...args) {
                    const [_context, data] = args;
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    useEffect(() => {
                        const report = data.item.data?.report;
                        if (report && viewProps.status === 'success') {
                            tracker.track('report_view_loaded', {
                                report_id: report.id,
                                report_kind: report.kind,
                                report_plugin_ids: chain(
                                    viewProps.getMetricListProps().items
                                )
                                    .map((item) => item.plugin.id)
                                    .uniq()
                                    .value(),
                                report_metric_definition_ids: viewProps
                                    .getMetricListProps()
                                    .items.map((item) => item.definition.id),
                                report_opportunity_definition_ids: viewProps
                                    .getOpportunityListProps()
                                    .items.map((item) => item.insight.definition.id),
                                report_period_start_at: moment(report.period.from).format(
                                    'YYYY-MM-DD'
                                ),
                                report_period_end_at: moment(report.period.to).format(
                                    'YYYY-MM-DD'
                                ),
                                report_created_at: moment(report.createdAt).format(
                                    'YYYY-MM-DD'
                                ),
                            });
                        }
                    }, [viewProps.status]);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                reports: {
                    ...init.controller.reports,
                    createReportDetail(...args) {
                        const controller = init.controller.reports.createReportDetail(
                            ...args
                        );
                        return enhanceReportController(controller);
                    },
                },
            },
            service: {
                ...init.service,
                reports: {
                    ...init.service.reports,
                    createScheduledReportService(serviceConfig) {
                        const service =
                            init.service.reports.createScheduledReportService(
                                serviceConfig
                            );
                        return {
                            ...service,
                            useUpdate(context) {
                                const tracker = useTracker();
                                const callback = service.useUpdate(context);
                                return async (schedule, payload) => {
                                    const response = await callback(schedule, payload);
                                    tracker.track('scheduledReportUpdate', {
                                        schedule_asset_id: schedule.asset,
                                        schedule_report_id: schedule.report,
                                        schedule_key: schedule.key,
                                        schedule_name: schedule.name,
                                        schedule_custom: schedule.custom,
                                        schedule_enabled: schedule.enabled,
                                        schedule_recipients:
                                            payload.recipients?.map(
                                                (recipient) => recipient.user
                                            ) ?? [],
                                    });
                                    return response;
                                };
                            },
                            useToggle(context) {
                                const tracker = useTracker();
                                const callback = service.useToggle(context);
                                return async (schedule, payload) => {
                                    const response = await callback(schedule, payload);
                                    tracker.track('scheduledReportToggle', {
                                        schedule_asset_id: schedule.asset,
                                        schedule_report_id: schedule.report,
                                        schedule_key: schedule.key,
                                        schedule_name: schedule.name,
                                        schedule_custom: schedule.custom,
                                        schedule_enabled: payload.enabled,
                                        schedule_recipients: schedule.recipients.map(
                                            (recipient) =>
                                                typeof recipient.user === 'number'
                                                    ? recipient.user
                                                    : recipient.user.id
                                        ),
                                    });
                                    return response;
                                };
                            },
                        };
                    },
                },
            },
            module: {
                ...init.module,
                createReportModule(moduleConfig) {
                    return init.module.createReportModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(context, config) {
                                        const tracker =
                                            instance.infra.useTracker<AnyReportEvent>();
                                        const viewProps = controller.useProps(
                                            context,
                                            config
                                        );
                                        useEffect(() => {
                                            tracker.track('reportLoad', {
                                                report_id: viewProps.report.id,
                                                report_name: viewProps.report.name,
                                            });
                                            return () => {
                                                tracker.track('reportUnLoad', {
                                                    report_id: viewProps.report.id,
                                                    report_name: viewProps.report.name,
                                                });
                                            };
                                        }, [config.definitionId]);
                                        return viewProps;
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
