import React from 'react';
import { DatePickerConfig } from './chakraDatePickerConfig';
import { buildDatePickerLayoutProps } from './chakraDatePickerFactory';
import { DatePickerComponents } from './chakraDatePickerInterface';
import { createDatePickerView } from './chakraDatePickerView';

export function createDatePickerComponents(): DatePickerComponents {
    const View = createDatePickerView();
    const instance: DatePickerComponents = {
        Container(props) {
            const layoutProps = buildDatePickerLayoutProps(instance, props);
            return <View {...props} layout={layoutProps} />;
        },
        Relative(props) {
            return <></>;
        },
        Absolute(props) {
            return <></>;
        },
    };
    return instance;
}
