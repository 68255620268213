import React from 'react';
import { AssistantContainerBaseConfig } from '../../base';
import { AssistantErrorContainerProps } from '../error';
import { AssistantRootContainerProps } from './assistantRootProps';

export function createAssistantRootContainer(
    config: AssistantContainerBaseConfig,
    View: {
        Error: React.FC<
            { children?: React.ReactNode | undefined } & AssistantErrorContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & AssistantRootContainerProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        return (
            <ErrorBoundary fallback={(info) => <View.Error info={info} />}>
                {children}
            </ErrorBoundary>
        );
    };
}
