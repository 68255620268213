import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { RecommendationResource } from './recommendationInterface';
import { RecommendationListSchema } from './recommendationSchema';

export function createRecommendationResource(
    client: AxiosInstance
): RecommendationResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const response = await client.post(
                `/api/v1/personalize/recommendations`,
                query,
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = RecommendationListSchema.parse(response.data);
            return parsed;
        },
    };
}
