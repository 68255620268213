import { ApplicationEntryEnhancer } from '../../entrypoint';
import { QueryParamFormConfig } from './queryParamConfig';
import { useSearchParams } from 'react-router-dom';
import { fromSearchParams, toQueryParams } from './queryParamSerializer';

export function createQueryParamFormStrategy(
    init: QueryParamFormConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
            },
            module: {
                ...config.module,
                createReportModule(moduleConfig) {
                    return config.module.createReportModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                return moduleConfig.provider.createController({
                                    ...controllerConfig,
                                    hook: {
                                        ...controllerConfig.hook,
                                        useStore() {
                                            const override =
                                                controllerConfig.hook.useStore();
                                            const [queryParams, setQueryParams] =
                                                useSearchParams();
                                            return {
                                                ...override,
                                                setControl(control) {
                                                    const encoded =
                                                        toQueryParams(control);
                                                    setQueryParams(encoded);
                                                    return override.setControl(control);
                                                },
                                                getControl() {
                                                    const control = override.getControl();
                                                    const decoded =
                                                        fromSearchParams(queryParams);
                                                    return {
                                                        ...control,
                                                        date: {
                                                            ...control.date,
                                                            period:
                                                                decoded?.period ??
                                                                control.date.period,
                                                        },
                                                    };
                                                },
                                            };
                                        },
                                    },
                                });
                            },
                        },
                    });
                },
            },
        });
    };
}
