import {
    AnyPeerHealthcheckQuery,
    PeerHealthcheckConditionQuery,
    PeerHealthcheckGroupQuery,
} from './peerHealthcheckQuery';

export function isPeerHealthcheckConditionQuery(
    query: AnyPeerHealthcheckQuery
): query is PeerHealthcheckConditionQuery {
    return Array.isArray((query as PeerHealthcheckConditionQuery).conditions);
}

export function isPeerHealthcheckGroupQuery(
    query: AnyPeerHealthcheckQuery
): query is PeerHealthcheckGroupQuery {
    return (query as PeerHealthcheckGroupQuery).group !== undefined;
}
