import React from 'react';
import { CTA_INAPP_BY_PLAN } from '../../config';
import { excludeFreePlan, PlanListView, usePlanList } from '../../domain';
import { useLocation } from 'react-router';
import { useAuthenticatedContext } from '../../container';
import { useSettingsLayout, SettingsLayout } from '../../layout';

export const PlanPage = () => {
    const layout = useSettingsLayout();
    const context = useAuthenticatedContext();
    const location = useLocation();
    const viewProps = usePlanList(context, {
        absoluteSuccessPath: location.pathname,
        absoluteCancelPath: location.pathname,
        returnPath: location.pathname,
        excludeDefault: excludeFreePlan(context),
    });
    return (
        <SettingsLayout {...layout}>
            <PlanListView
                {...viewProps}
                containerProps={{ paddingY: 0 }}
                caption={context.access.current?.error}
                variant="ghost"
                // title="Upgrade Account"
                title="Select your plan"
                // subtitle="Join 4,432 companies benchmarking on Varos"
                // subtitle="Try Varos free for 14 days"
                getCta={(plan) => CTA_INAPP_BY_PLAN[plan.id]?.text}
            />
        </SettingsLayout>
    );
};
