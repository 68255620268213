import { useMemo } from 'react';
import { OpportunityItemControllerConfig } from './itemConfig';
import { buildOpportunityItemProps } from './itemFactory';
import { OpportunityItemController } from './itemInterface';

export function createOpportunityItemController(
    config: Pick<OpportunityItemControllerConfig, 'infra'>
): OpportunityItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props) {
            const viewProps = useMemo(() => {
                return buildOpportunityItemProps(formatter, props.item);
            }, [props.item]);
            return viewProps;
        },
    };
}
