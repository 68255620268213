import {
    Box,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Tag,
    VStack,
} from '@chakra-ui/react';
import { Column, ConditionObject } from '../../../api/v2';
import React from 'react';

export interface ConditionPopoverProps extends PopoverProps {
    content: React.ReactElement;
    column: Pick<Column, 'name'>;
}

export const ConditionPopover: React.FC<
    { children?: React.ReactNode | undefined } & ConditionPopoverProps
> = ({ isOpen, onClose, ...props }) => {
    return (
        <Popover
            isOpen={isOpen}
            onClose={onClose}
            isLazy={props.isLazy}
            lazyBehavior={props.lazyBehavior}
        >
            <PopoverTrigger>{props.children}</PopoverTrigger>
            <PopoverContent
                bg="#292929"
                _focus={{ outline: 'none', borderColor: 'none' }}
                width="fit-content"
                // borderRadius={8}
                // borderColor="gray.700"
                // borderWidth={1}
                // borderStyle="solid"
            >
                <PopoverCloseButton top={2} />
                <PopoverHeader>{props.column.name}</PopoverHeader>
                <PopoverBody>{props.content}</PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
