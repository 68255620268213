import React from 'react';
import { Flex, PopoverTrigger } from '@chakra-ui/react';
import { MenuTargetProps } from './MenuTargetProps';
import { applyTargetChildDefaults } from './MenuTargetHelper';

export const MenuTarget: React.FC<
    { children?: React.ReactNode | undefined } & MenuTargetProps
> = ({ ...props }) => {
    return <PopoverTrigger>{applyTargetChildDefaults(props.children)}</PopoverTrigger>;
};
