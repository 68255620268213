import {
    PeerGroupDataSet,
    DataSetQuery,
    TableSummary,
    MakeQueryRequest,
    QueryExecutionResult,
    ParticipantDataSetState,
    DataSet,
} from '../models/DataSet';
import queryString from 'querystring';
import { apiV1 } from './axios';
import { Option } from '../models/Common';
import { AxiosResponse } from 'axios';

export const fetchDatasetsForParticipants = async (participantId: number) => {
    return apiV1.get<unknown, AxiosResponse<Array<DataSet>>>(
        `/peer-group-participants/${participantId}/datasets`
    );
};

export const fetcQueriesForDataset = async (
    participantId: number,
    peerGroupDatasetId: number
) => {
    return apiV1.get<Array<DataSetQuery>>(
        `/peer-group-participants/${participantId}/datasets/${peerGroupDatasetId}/queries`
    );
};

export const getQueryDescriptor = async (
    participantId: number,
    peerGroupDatasetId: number,
    queryId: number
) => {
    return apiV1.get<TableSummary>(`/queries/${queryId}/descriptor`);
};

export const executeQuery = async (participantId: number, req: MakeQueryRequest) => {
    return apiV1.post<unknown, AxiosResponse<QueryExecutionResult>>(
        `/peer-group-participants/${participantId}/datasets/query-request`,
        req
    );
};

export const getKeyVals = async (
    columnKey: string,
    participantId: number,
    peerGroupFilterId?: number
) => {
    const qs = queryString.stringify({
        column_key: columnKey,
        asset_id: participantId,
        ...(peerGroupFilterId && { peer_group_filter_id: peerGroupFilterId }),
    });
    return apiV1.get<Array<Option>>(`/queries/key-values?${qs}`);
};

export const getParticipantStateInDatasets = async (
    particpnatId: number,
    pgdsIds: Set<number>
) => {
    const qs = queryString.stringify({
        pg_ds_ids: Array.from(pgdsIds),
    });
    return Promise.resolve<{ data: ParticipantDataSetState }>({
        data: {
            peer_group_datasets_requires_update: [],
        },
    });
    // return apiV1.get<ParticipantDataSetState>(`/peer-group-participants/${particpnatId}/dataset-state?${qs}`);
};
