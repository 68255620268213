import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuthenticatedApi } from '../../../../infra';
import { evaluateFilter } from '../../../../api';
import {
    EvaluationResponse,
    MetadataRule,
    Transparancy,
} from '../../../../models/Metadata';
import { castConditionToApi } from '../../../impl';
import { useWorkspaceApi, useDashboardApi, useQueryApi } from '../../../app/dashboard';
import { CompetitiveSetViewProps } from '../../../view/dashboard';
import { CompetitiveSetContainerProps } from './competitiveProps';

export const useCompetitiveSetController = (
    props: Omit<CompetitiveSetContainerProps, 'as'>
): CompetitiveSetViewProps => {
    const client = useAuthenticatedApi();
    const api = {
        workspace: useWorkspaceApi(),
        dashboard: useDashboardApi(),
        query: useQueryApi(),
    };

    const workspace = api.workspace.getWorkspace();
    const dashboard = api.dashboard.getDashboard();
    
    const segment = api.query.getSegment();

    const evalQuery = useQuery<EvaluationResponse>({
        queryKey: ['traits', 'evaluate', api.query.getSegmentHash()],
        async queryFn(): Promise<EvaluationResponse> {
            const { data } = await evaluateFilter(
                client,
                workspace.id as number,
                {
                    operator: 'and',
                    sub_rules: segment
                        .filter((condition) => condition.value)
                        .map<MetadataRule>(
                            // @ts-expect-error
                            (condition) => {
                                const mapped = castConditionToApi(condition);
                                return {
                                    metadata_definition_key: mapped.key,
                                    operator: mapped.operator,
                                    value: mapped.value,
                                };
                            }
                        ),
                },
                dashboard.id
            );
            return data;
            // return {
            //     quality: 'relevant_sample',
            //     range: [10, 30],
            // };
        },
        refetchOnWindowFocus: false,
        suspense: true,
        staleTime: Infinity,
        onSuccess(data) {
            if (data.downgraded_to) {
                console.warn('peer group downgrade was not handled', data);
            }
            return data;
        },
    });

    return {
        transparency: evalQuery.data?.evaluated_quality!,
    };
};
