import { ApplicationIcons } from './iconInterface';
import { Graphics } from '../config/svg';

export function createApplicationIcons(): ApplicationIcons {
    return {
        billing: {
            visa: { src: Graphics.Visa },
            mastercard: { src: Graphics.MasterCard },
            americanexpress: { src: Graphics.AmericanExpress },
        },
    };
}
