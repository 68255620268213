import { AlertListControllerConfig } from './alertConfig';
import { AlertListController } from './alertInterface';
import { AlertListItemProps, AlertListViewProps } from './alertProps';

export function createAlertListController(
    config: AlertListControllerConfig = {}
): AlertListController {
    return {
        useProps(item, props): AlertListViewProps {
            const items = item.items.filter((item) =>
                props.dismissed ? !props.dismissed?.has(item.id) : true
            );
            return {
                containerRef: props.containerRef,
                items: items,
                getItemProps(item): AlertListItemProps {
                    return {
                        id: item.id,
                        kind: item.kind,
                        label: item.title,
                        status: item.status,
                        action: {
                            link: {
                                id: item.id,
                                to: item.to,
                            },
                            label: item.actionText,
                        },
                        onDismiss: item.isDismissable
                            ? props.onDismiss?.bind(null, item) ?? null
                            : null,
                    };
                },
            };
        },
    };
}
