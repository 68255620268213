import { useMemo } from 'react';
import { HomeRouteController, HomeRouteProps } from '../../route';
import { AlertListAggregate, AlertListController } from '../../view/common';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { PersistedAlertsMiddlewareConfig } from './persistedAlertsMiddlewareConfig';
import { PageItemAggregateV2 } from '../../view/page';
import { useLocalStorage } from 'react-use';

/**
 * Persists the dismissed state of alerts
 * @param init
 * @returns
 */
export function createPersistedAlertsMiddleware(
    init: PersistedAlertsMiddlewareConfig = {}
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(controller: AlertListController): AlertListController {
            return {
                ...controller,
                useProps(item, props, ...args) {
                    const [dismissed = [], setDismissed] = useLocalStorage(
                        'vs_dismissed_alerts',
                        [] as string[]
                    );
                    const dismissedSet = useMemo(
                        () => new Set([...dismissed]),
                        [dismissed]
                    );
                    return controller.useProps(
                        item,
                        {
                            ...props,
                            dismissed: dismissedSet,
                            onDismiss(item) {
                                setDismissed([...dismissed, item.id]);
                                props.onDismiss?.(item);
                            },
                        },
                        ...args
                    );
                },
            };
        }
        const instance = create({
            ...config,
            v2: {
                ...config.v2,
                common: {
                    ...config.v2.common,
                    alert: {
                        ...config.v2.common.alert,
                        createController(...args) {
                            const controller =
                                config.v2.common.alert.createController(args);
                            return enhanceController(controller);
                        },
                    },
                },
            },
        });
        return instance;
    };
}
