import { assertNever } from '../../../../util';
import { StatusInfo } from '../../../common';
import { DatasetConnectionAggregate } from './connectionItemModel';

export function getConnectionStatus(item: DatasetConnectionAggregate): StatusInfo {
    if (item.connections.length === 0) {
        return {
            value: null,
            kind: 'warning',
            label: 'Pending',
            description: `Data sources have not yet synced to this dataset`,
            ordinal: 2,
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'active')) {
        return {
            value: null,
            kind: 'success',
            label: 'Active',
            description: 'Dataset is connected and up-to-date',
            ordinal: 0,
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'stale')) {
        return {
            value: null,
            kind: 'warning',
            label: 'Stale',
            description: null,
            ordinal: 1,
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'delayed')) {
        return {
            value: null,
            kind: 'warning',
            label: 'Delayed',
            description: null,
            ordinal: 1,
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'error')) {
        const [connection] = item.connections;
        return {
            value: null,
            kind: 'error',
            label: 'Error',
            description: connection.status.message,
            ordinal: 2,
            reason: null,
        };
    }
    return {
        value: null,
        kind: 'warning',
        label: 'Pending',
        description: `Data sources have not yet synced to this dataset`,
        ordinal: 2,
    };
}
