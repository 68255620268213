import { useForm } from 'react-hook-form';
import { BreakdownForm, BreakdownViewProps } from '../../../../service/query';
import { BreakdownConfiguration } from '../../../../domain/query';
import { BreakdownController, BreakdownFactory } from './breakdownInterface';
import { AnalysisBreakdownViewProps } from './breakdownProps';

export function createBreakdownController(
    forms: {
        breakdown: BreakdownForm;
    },
    factory: BreakdownFactory
): BreakdownController {
    return {
        useProps(state, props): AnalysisBreakdownViewProps {
            const view = props.view;

            const defaultValues = state.value?.properties?.[0]?.key
                ? {
                      properties: [
                          {
                              key: state.value.properties[0].key,
                          },
                      ],
                  }
                : {};

            const form = useForm<BreakdownConfiguration>({
                defaultValues,
            });

            const formProps = forms.breakdown.useFormState(view, {
                value: null,
                onChange(value) {},
            });

            const formValues = form.watch();

            return {
                ...formProps,
                options: formProps.getOptions(),
                context: props.context,
                value: formValues,
                // @ts-expect-error
                onChange: form.handleSubmit((values) => state.onChange(values)),
            };
        },
    };
}
