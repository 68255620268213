import { camelCase, mapKeys } from 'lodash';
import { z } from 'zod';

export const PaymentMethodEditUrlParamsSchema = z
    .record(z.any())
    .transform((x) => mapKeys(x, (value, key) => camelCase(key)))
    .pipe(
        z
            .object({
                returnPath: z.string(),
            })
            .partial()
    );
