import { DateRangesFeatureDto } from '../../../../../api';
import { ContextFreePeriod, periodToDays } from '../../../../../domain';

export function isPeriodEnabled(
    period: ContextFreePeriod,
    feature: DateRangesFeatureDto
) {
    const lookbackDays = feature.config?.lookback_period_days;
    if (typeof lookbackDays !== 'number') {
        return true;
    }
    const days = periodToDays(period);
    return days <= lookbackDays;
}
