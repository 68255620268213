import {
    OnboardingMappingController,
    OnboardingPeerGroupController,
} from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingPeerStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingPeerGroupController
        ): OnboardingPeerGroupController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        onContinue(context) {
                            const response = viewProps.onContinue?.(context);
                            tracker.track('onboarding_peer_groups_submitted', {});
                            return response;
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createPeerGroups(...args) {
                        const controller = config.controller.onboarding.createPeerGroups(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
