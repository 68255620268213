import { OpportunityCollectionConfig } from './collectionConfig';
import { OpportunityCollectionLoader } from './collectionInterface';

export function createOpportunityCollectionLoader(
    config: OpportunityCollectionConfig
): OpportunityCollectionLoader {
    const { service } = config;
    return {
        useData(context, props, options) {
            return {
                items: service.opportunity.useFind(context, props.query, options),
            };
        },
    };
}
