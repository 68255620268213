import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    SimpleGrid,
    InputRightAddon,
    InputRightElement,
    FormErrorMessage,
    Select,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import React from 'react';
import { AssetNewViewProps } from '../../../view/assets';

export function createOnboardingAssetNewView(): React.FC<
    { children?: React.ReactNode | undefined } & AssetNewViewProps
> {
    return (props) => {
        const formProps = props.getFormProps();
        return (
            <VStack
                w="full"
                spacing={6}
                py={6}
                divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
            >
                <VStack spacing={6} w="full" align="start">
                    <VStack
                        w="full"
                        spacing={6}
                        maxW={{ base: undefined, md: '75%', lg: '66%', xl: '50%' }}
                        mx="auto"
                    >
                        {formProps.error && (
                            <Alert status="error">
                                <AlertIcon />
                                <AlertDescription>
                                    {formProps.error.message}
                                </AlertDescription>
                            </Alert>
                        )}
                        <FormControl isInvalid={props.getControlProps('url').isInvalid}>
                            <VStack align="start" spacing={0} w="full">
                                <FormLabel htmlFor="name" fontWeight="semibold">
                                    Website URL
                                </FormLabel>
                                <InputGroup borderColor="gray.300" size="lg">
                                    <InputLeftAddon bg="gray.200">
                                        <Text color="gray.500">https://</Text>
                                    </InputLeftAddon>
                                    <Input
                                        bg="white"
                                        placeholder="Website URL"
                                        {...props.getInputProps('url')}
                                    />
                                </InputGroup>
                            </VStack>
                            <FormErrorMessage>
                                {props.getControlProps('url').error?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={props.getControlProps('name').isInvalid}>
                            <VStack align="start" w="full">
                                <FormLabel htmlFor="name" fontWeight="semibold" m={0}>
                                    Name
                                </FormLabel>
                                <InputGroup borderColor="gray.300" size="lg">
                                    <Input
                                        bg="white"
                                        placeholder="Name"
                                        {...props.getInputProps('name')}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {props.getControlProps('name').error?.message}
                                </FormErrorMessage>
                            </VStack>
                        </FormControl>
                    </VStack>
                </VStack>
            </VStack>
        );
    };
}
