import React, { useEffect } from 'react';
import { useMeasure } from 'react-use';
import { compose } from 'lodash/fp';
import { BoxProps, Center, Box, Alert, AlertIcon } from '@chakra-ui/react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { ErrorBoundary } from '../../../infra';
import { GRAY, VAROS_GREEN } from '../../config';
import { isAggregatedLineVisualization } from '../../../api';
import {
    LineVisualization,
    TableVisualization,
    BarVisualization,
    StatVisualization,
    createLineStrategy,
    createBarStrategy,
} from '../../../strategy';
import { ApplicationEntrypointConfig } from '../../entrypoint';
import {
    CompetitiveSetView,
    ActionView,
    FilterItemView,
    FilterListView,
    FilterDateView,
    CardListView,
    CardItemView,
    createChartView,
    SegmentListView,
    SegmentItemView,
} from '../../view';
import {
    useDashboardContext,
    useUserApi,
    DashboardProviderConfig,
    createDashboardProvider,
} from '../../app';
import {
    useFilterItemController,
    useDateController,
    useActionController,
    useSegmentListController,
    useSegmentItemViewController,
    useCardListController,
    useCardItemController,
    useChartController,
    useIssueController,
    useFilterListController,
    createDashboardGroupView,
} from '../../controller';
import {
    createApiDashboardImpl,
    createChartApiImpl,
    createApiConfigurationImpl,
    createPreferencesApiImpl,
} from '../../impl';
import {
    createDashboardDefaultRedirect,
    createDashboardRoute,
    DashboardRouteConfig,
} from '../../route';
import { createVisualizationIntl } from '../../intl';
import { SCROLLBAR_STYLE } from '../../style';
import { isErrorInfo } from '../../../utils/axiosUtils';
import { DashboardLayoutComponents } from '../../layout';
import { ApplicationUI } from '../../ui';

const CARD_BODY_HEIGHT = '335px';
const CARD_BODY_HEIGHT_LARGE = '450px';

export const DashboardDefaultRedirect = createDashboardDefaultRedirect();
// export const SettingsDefaultRedirect = createSettingsDefaultRedirect()

// TODO refactor this

export function createDashboardConfig(
    UI: ApplicationUI
): Pick<DashboardRouteConfig, 'controller' | 'views' | 'style'> {
    const ChartView = createChartView({
        lineStrategy: createLineStrategy({
            intl: createVisualizationIntl({}),
        }),
        barStrategy: createBarStrategy({
            intl: createVisualizationIntl({}),
        }),
    });
    // const { Layout } = config;
    return {
        // actions: [],
        // settings: [],
        // Layout,
        controller: {
            useIssue: useIssueController,
            useCardList: useCardListController,
            useCardItem: useCardItemController,
            useSegmentList: useSegmentListController,
            useSegmentItem: useSegmentItemViewController,
            useActions: useActionController,
            useFilterItem: useFilterItemController,
            useFilterList: useFilterListController,
            useDateFilter: useDateController,
            useChart: useChartController,
        },
        style: {
            layout: {
                wrapper: {
                    gridTemplateColumns: 'minmax(18rem, 14.2vw) 1fr',
                },
                sidebar: {
                    gridTemplateRows: 'min-content 1fr min-content',
                    color: 'whiteAlpha.900',
                    bg: GRAY[800],
                },
                scroll: {
                    __css: SCROLLBAR_STYLE,
                },
                main: {
                    gridTemplateRows: 'min-content 1fr',
                },
                segment: {
                    px: 6,
                },
                competitive: {
                    mx: 6,
                    mb: 6,
                    py: 6,
                    bg: GRAY[800],
                    borderBottomWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'whiteAlpha.300',
                },
                controls: {
                    px: 4,
                    bg: 'black',
                },
                actions: {
                    py: 3,
                    borderBottomWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'whiteAlpha.300',
                },
                filters: {
                    py: 3,
                },
                cards: {
                    px: 4,
                    paddingBottom: 24,
                },
            },
            visualization: {
                table: {
                    table: {
                        h: 'full',
                    },
                    thead: {
                        bg: GRAY[800],
                    },
                    th: {
                        bg: '#1d1c21',
                        color: 'whiteAlpha.700',
                        borderColor: '#292929',
                    },
                    td: {
                        // height: `${getTableCardRowHeight(
                        //     card,
                        //     props
                        // )}px`,
                        h: '4rem',
                        borderBottomWidth: 0,
                        borderTopWidth: 1,
                        borderColor: '#292929',
                    },
                },
            },
        },
        views: {
            CompetitiveSet: (props) => (
                <CompetitiveSetView
                    {...props}
                    style={{
                        label: {
                            color: 'whiteAlpha.900',
                            fontSize: 'sm',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        },
                    }}
                />
            ),
            Group: createDashboardGroupView({
                UI: { Application: UI },
            }),
            Segment: {
                List: (props) => (
                    <SegmentListView
                        {...props}
                        style={{
                            wrapper: { spacing: 6 },
                        }}
                    />
                ),
                Item: (props) => (
                    <SegmentItemView
                        style={{
                            input: { colorScheme: 'green' },
                            label: {
                                color: 'whiteAlpha.700',
                            },
                        }}
                        {...props}
                    />
                ),
            },
            Issue: (props) => (
                <Alert status={props.issue.details.level}>
                    <AlertIcon />
                    {props.issue.details.message}
                </Alert>
            ),
            Actions: (props) => (
                <ActionView
                    {...props}
                    style={{
                        wrapper: {
                            h: '100%',
                            spacing: 2,
                            // borderBottomWidth: 2,
                            // borderStyle: 'solid',
                            // borderColor: 'whiteAlpha.300',
                        },
                        button: {
                            size: 'sm',
                        },
                        buttonExport: {
                            // px: 3,
                            // py: 1,
                            // borderWidth: 1,
                            // borderStyle: 'solid',
                            bg: GRAY[600],
                            border: 'none',
                            color: 'whilteAlpha.900',
                            _active: {
                                color: 'white',
                                bg: VAROS_GREEN['200'],
                                outline: 'none',
                                // border: 'none',
                            },
                            _disabled: {
                                opacity: 0.66,
                            },
                        },
                    }}
                />
            ),
            Date: (props) => (
                <FilterDateView
                    style={{
                        trigger: {
                            px: 3,
                            py: 1.5,
                            bg: GRAY[600],
                            color: VAROS_GREEN['200'],
                            borderColor: VAROS_GREEN['200'],
                            borderWidth: 1,
                            borderStyle: 'solid',
                            _active: {
                                color: 'white',
                                bg: VAROS_GREEN['200'],
                            },
                        },
                        content: {
                            bg: GRAY[600],
                            p: 2,
                        },
                    }}
                    {...props}
                />
            ),
            Filter: {
                List: (props) => (
                    <FilterListView
                        {...props}
                        style={{
                            wrapper: { h: '100%', spacing: 3 },
                            label: {
                                color: 'whiteAlpha.700',
                                fontSize: 'sm',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                            },
                        }}
                    />
                ),
                Item: (props) => (
                    <FilterItemView
                        {...props}
                        style={{
                            trigger: {
                                px: 3,
                                py: 1.5,
                                bg: GRAY[600],
                                color: VAROS_GREEN['200'],
                                borderColor: VAROS_GREEN['200'],
                                borderWidth: 1,
                                borderStyle: 'solid',
                                // boxSizing: 'border-box',
                                // outline: 'none',
                                _active: {
                                    color: 'white',
                                    bg: VAROS_GREEN['200'],
                                    outline: 'none',
                                    // border: 'none',
                                },
                                _disabled: {
                                    bg: GRAY[600],
                                    border: 'none',
                                    color: 'whilteAlpha.900',
                                    opacity: 0.66,
                                },
                            },
                            content: {
                                bg: GRAY[600],
                                p: 2,
                            },
                        }}
                    />
                ),
            },
            Card: {
                List: (props) => (
                    <CardListView
                        {...props}
                        style={{
                            list: { wrapper: { gap: 4 } },
                        }}
                    />
                ),
                Item: (props) => (
                    <CardItemView
                        style={{
                            wrapper: {
                                gridTemplateRows: 'min-content 1fr',
                                minH: '390px',
                                overflow: 'hidden',
                                color: 'whiteAlpha.900',
                                borderColor: GRAY[700],
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: 'lg',
                            },
                            header: {
                                h: 'full',
                                bg: GRAY[600],
                                p: 4,
                                fontWeight: 'bold',
                                borderColor: GRAY[500],
                                borderBottomWidth: '1px',
                                borderStyle: 'solid',
                            },
                            body: {
                                h: {
                                    base: CARD_BODY_HEIGHT,
                                    '2xl': CARD_BODY_HEIGHT_LARGE,
                                },
                            },
                            scroll: {
                                __css: SCROLLBAR_STYLE,
                            },
                        }}
                        {...props}
                    />
                ),
            },
            Chart: (props) => (
                <ChartView
                    {...props}
                    style={{
                        wrapper: {
                            h: '100%',
                            p: 8,
                        },
                    }}
                />
            ),
            Visualization: {
                Line: ({ card, ...props }) => (
                    <Box w="full" h="full" pt={6} px={12}>
                        <LineVisualization
                            {...props}
                            // @ts-expect-error
                            width="100%"
                            // height={{ base: 250, '2xl': 300 }}
                            height={250}
                            padding={
                                // Padding is handled by the grid layout of multiple visualizations
                                isAggregatedLineVisualization(card.visualization)
                                    ? { right: 0 }
                                    : {}
                            }
                        />
                    </Box>
                ),
                Table: (props) => (
                    <Box
                        height={{
                            base: CARD_BODY_HEIGHT,
                            '2xl': CARD_BODY_HEIGHT_LARGE,
                        }}
                        overflowY="auto"
                        overflowX="auto"
                        width="100%"
                        __css={SCROLLBAR_STYLE}
                    >
                        <TableVisualization {...props} />
                    </Box>
                ),
                Bar: (props) => (
                    <Box w="full" h="full" pt={6} px={12}>
                        <BarVisualization
                            {...props}
                            // @ts-expect-error
                            width="100%"
                            // height={{ base: 250, '2xl': 300 }}
                            height={250}
                        />
                    </Box>
                ),
                Stat: (props) => <StatVisualization {...props} />,
            },
        },
    };
}

export const DashboardSetupCompleteRedirect: React.FC<
    { children?: React.ReactNode | undefined } & {
        children?: React.ReactNode;
    }
> = () => {
    const location = useLocation();
    const { dashboard } = useDashboardContext();
    if (dashboard.access !== 'active') {
        return null;
    }
    // TODO: find a less hacky way to navigate to parent route
    const newUrl = location.pathname.replace('/setup', '');
    return <Navigate to={newUrl} replace={true} />;
};

export function configureDashboardProvider(
    config: Pick<DashboardProviderConfig, 'param'>
) {
    return createDashboardProvider({
        ...config,
        getDeps({ api }) {
            return {
                dashboard: createApiDashboardImpl({ axios: api }),
                configuration: createApiConfigurationImpl({
                    axios: api,
                    overrides: {},
                }),
                chart: createChartApiImpl({ axios: api }),
                preferences: createPreferencesApiImpl({ axios: api }),
            };
        },
    });
}

export const DashboardErrorBoundary: React.FC<
    { children?: React.ReactNode | undefined } & {
        paramName: string;
        children?: React.ReactNode;
    }
> = (props) => {
    const { [props.paramName]: dashboardId } = useParams();
    return (
        <ErrorBoundary
            key={dashboardId}
            fallback={(props) => <DashboardErrorComponent {...props} />}
        >
            {props.children}
        </ErrorBoundary>
    );
};

export const DashboardErrorComponent: React.FC<
    { children?: React.ReactNode | undefined } & { error: Error }
> = (props) => {
    const navigate = useNavigate();
    const user = useUserApi();

    useEffect(() => {
        const url = user.getDefaultWorkspaceUrl(true);
        if (isErrorInfo(props.error) && props.error.errType == 'notFound' && url) {
            navigate(url, { replace: true });
        }
    }, [props.error]);

    return (
        <Center p={4}>
            <Alert status="error">
                <AlertIcon />
                {props.error.message}
            </Alert>
        </Center>
    );
};
