import { AnyCondition } from 'src/v2/domain/attributes';
import { MetricDefinition } from '../../../domain/metrics';
import {
    AnyVisualization,
    AnyVisualizationCreateProps,
    buildVisualization,
} from '../../../domain/visualization';
import { AnalysisRouteFormValues } from './analysisRouteModel';

export function buildAnalysisVisualization(
    state: AnalysisRouteFormValues,
    metrics: MetricDefinition[],
    defaultValues: AnyVisualizationCreateProps
): AnyVisualization {
    return buildVisualization(
        metrics,
        // @ts-expect-error TODO fix type narrowing
        {
            key: 'some key',
            name: null,
            description: null,
            kind: defaultValues.kind,
            metrics: defaultValues.metrics as any,
            view: state.view ?? defaultValues.view,
            granularity: state.granularity ?? defaultValues.granularity,
            period: state.period ?? defaultValues.period,
            filters: state.filters
                ? Object.entries(state.filters).flatMap(([key, value]): AnyCondition[] =>
                      value ? [{ key, operator: 'in', value: value }] : []
                  )
                : defaultValues.filters,
            ranking: state.ranking ?? defaultValues.ranking,
            breakdown: state.breakdown ?? defaultValues.breakdown,
        }
    );
}
