import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { OrganizationMappingFormValues } from '../../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import {
    SettingImportNewController,
    SettingImportNewDeps,
    SettingImportNewLoader,
} from './importNewInterface';
import { SettingImportNewContainerProps } from './importNewProps';
import { SettingImportConfig } from '../importConfig';
import { useDisclosure } from '@chakra-ui/react';

export function createSettingImportNewContainer(
    init: Pick<SettingImportConfig, 'infra' | 'form' | 'repository'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingImportNewLoader,
    controller: SettingImportNewController
): React.FC<{ children?: React.ReactNode | undefined } & SettingImportNewContainerProps> {
    const {
        form,
        infra: { toaster },
        repository: { import: importRepository },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const viewForm = form.import.useForm(viewContext, viewData);
        const viewFormValues = viewForm.watch();
        const viewMutation = {
            create: importRepository.useCreate(viewContext),
        };
        const deps: SettingImportNewDeps = {
            navigate: useNavigate(),
            toast: toaster.useToast(),
            view: {
                disclosure: {
                    select: useDisclosure(),
                },
                mutation: viewMutation,
                form: viewForm,
                values: viewFormValues,
            },
        };
        const viewProps = controller.useProps(viewContext, deps, { item: viewData });
        return <Component {...viewProps}>{children}</Component>;
    };
}
