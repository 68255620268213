import { Asset } from '../../../domain/assets';
import { AssetAdapter } from '../../../app/assets';
import { AssetImplConfig } from './assetImplConfig';

export function createAssetImpl(config: AssetImplConfig): AssetAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.platform.assets.list(context, {
                visible: query.visible ?? undefined,
            });
            return {
                total: response.data.length,
                items: response.data.flatMap((item): Asset[] => {
                    if (!item.url) {
                        console.warn(`asset ${item.id} does not have an url`);
                        return [];
                    }
                    return [
                        {
                            id: item.id,
                            name: item.name,
                            url: item.url,
                            visible: item.is_visible,
                            type: item.asset_type,
                            createdAt: item.created_at,
                            updatedAt: item.updated_at,
                        },
                    ];
                }),
                limit: query.limit,
            };
        },
        async create(context, props) {
            const responseAsset = await api.platform.assets.create(context, {
                organization_id: context.organization.id,
                name: props.name,
                url: props.url,
                asset_type: props.type,
                is_visible: props.isVisible,
            });

            const organization = await api.platform.organizations.get(
                context,
                context.organization.id
            );

            if (organization.default_asset === null) {
                try {
                    console.info(`setting default asset for org: ${responseAsset.id}`);
                    const responseOrg = await api.platform.organizations.update(
                        context,
                        context.organization.id,
                        { default_asset: responseAsset.id }
                    );
                    console.info(`succesfully organization updated`, responseOrg);
                } catch (error) {
                    console.error(`failed to set default asset`, error);
                }
            }

            return {
                ...responseAsset,
                visible: responseAsset.is_visible,
                type: responseAsset.asset_type,
                createdAt: responseAsset.created_at,
                updatedAt: responseAsset.updated_at,
            };
        },
        async update(context, id, props) {
            const response = await api.platform.assets.update(context, id, {
                name: props.name,
                url: props.url,
                asset_type: props.type,
                is_visible: props.isVisible,
            });
            return {
                ...response,
                visible: response.is_visible,
                type: response.asset_type,
                createdAt: response.created_at,
                updatedAt: response.updated_at,
            };
        },
    };
}
