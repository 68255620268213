import { z } from 'zod';

export const BillingAddOnDtoSchema = z.object({
    addon_id: z.string(),
    product_id: z.string(),
    is_payed: z.boolean(),
})

// Item

export const BillingSubscriptionDtoSchema = z.object({
    id: z.number(),
    plan: z.string(),
    active: z.boolean(),
    customer: z.nullable(z.string()),
    add_ons: z.array(BillingAddOnDtoSchema),
});

// Create

export const BillingSubscriptionCreateDtoSchema = z.object({
    plan: z.string(),
});

export const BillingSubscriptionCreateOptionsDtoSchema = z.object({
    wait: z.number(),
    idempotency_key: z.string(),
});
