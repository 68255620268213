import { Box, Checkbox, CheckboxProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { ButtonIndicator } from '../../../ui';
import { IntegrationView } from '../../../components/Integrations/IntegrationsList';
import { Integration, IntegrationConf } from '../../../models/integration';
import { useAuthenticatedContext } from '../../../hooks';
import {
    UserIntegrationDefinition,
    IntegrationCard,
    IntegrationCardProps,
    IntegrationCardAction,
    IntegrationCardActionProps,
    IntegrationCardButton,
    IntegrationModal,
    IntegrationCardStatus,
    AnyIntegrationStatus,
} from '../../../domain';
import { Asset } from '../../../api';
import {
    useAssetIntegrationDefinition,
    useIntegrationTheme,
} from '../../../hooks/integrations/hooks';

export interface IntegrationItemAssetViewProps
    extends Pick<IntegrationCardProps, 'containerProps'>,
        Omit<
            IntegrationCardActionProps,
            'onConnect' | 'onReconnect' | 'renderButton' | 'theme'
        > {
    asset: Pick<Asset, 'id'>;
    definition: UserIntegrationDefinition & { isIgnored: boolean; canIgnore: boolean };
    reconnectInteg?: Integration;
    onConnect?: (integ: IntegrationConf) => void;
    onReconnect?: (def: IntegrationConf, integration: Integration) => void;
    onSelect?(): void;
    forceShowIgnore?: boolean;
    forceDisconnected?: boolean;
    status?: AnyIntegrationStatus;
}

export const IntegrationItemAssetView: React.FC<
    { children?: React.ReactNode | undefined } & IntegrationItemAssetViewProps
> = ({ definition, forceDisconnected, ...props }) => {
    const context = useAuthenticatedContext();
    const theme = useIntegrationTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { actions } = useAssetIntegrationDefinition(
        { ...context, assetId: props.asset.id },
        definition
    );

    const onDontUseClick: CheckboxProps['onChange'] = (event) => {
        actions.ignore.mutate(event.target.checked);
    };

    const onConnectClick: IntegrationCardActionProps['onConnect'] = (event) => {
        event.stopPropagation();
        return props.onConnect?.(definition);
    };
    const onReconnectClick: IntegrationCardActionProps['onReconnect'] = () => {
        const [integration] = definition.integrations;
        if (!integration) {
            console.warn('reconnect clicked but no reconnectInteg passed to component');
            return;
        }
        return props.onReconnect?.(definition, integration);
    };

    const isConnectable =
        props.status?.kind !== 'connected' && props.status?.kind !== 'pending';
    const showBadge = props.status?.kind === 'pending';
    const showAction = isConnectable;
    const showIgnore = definition.canIgnore && isConnectable;

    return (
        <IntegrationCard
            containerProps={props.containerProps}
            highlight={false}
            definition={definition}
            description={null}
            theme={theme}
            status={props.status}
            statusText={
                props.status && (
                    <IntegrationCardStatus
                        status={props.status}
                        onClick={onOpen}
                        minimal={true}
                    />
                )
            }
            action={
                showAction ? (
                    <IntegrationCardAction
                        {...props}
                        theme={theme}
                        definition={definition}
                        forceDisconnected={forceDisconnected}
                        onConnect={onConnectClick}
                        onReconnect={onReconnectClick}
                        onConnectedClick={onOpen}
                        renderButton={(buttonProps) => (
                            <IntegrationCardButton.Connect {...buttonProps} theme={theme}>
                                {buttonProps.children}
                                {showBadge && (
                                    <ButtonIndicator
                                        borderColor={theme.cardBgColor}
                                        bgColor="red.600"
                                    />
                                )}
                            </IntegrationCardButton.Connect>
                        )}
                    />
                ) : undefined
            }
            footer={
                <Checkbox
                    visibility={showIgnore ? 'inherit' : 'hidden'}
                    isChecked={definition.isIgnored}
                    onChange={onDontUseClick}
                    colorScheme="gray"
                >
                    I don't use this tool
                </Checkbox>
            }
        >
            {props.onConnect && (
                <IntegrationModal
                    isOpen={isOpen}
                    onClose={onClose}
                    definition={definition}
                    onConnect={props.onConnect}
                >
                    {definition.integrations
                        .filter((integration) =>
                            props.asset
                                ? integration.assets.includes(props.asset.id)
                                : true
                        )
                        .map((integration) => (
                            <Box
                                w="full"
                                borderBottom="1px solid #46454A"
                                key={integration.id}
                            >
                                <IntegrationView
                                    integration={integration}
                                    integrationDefinition={definition}
                                    connect={props.onConnect!}
                                    reconnect={props.onReconnect!}
                                />
                            </Box>
                        ))}
                </IntegrationModal>
            )}
        </IntegrationCard>
    );
};
