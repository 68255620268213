import { UseFormReturn } from 'react-hook-form';
import { AnyInputProps } from '../../../../ui/application/impl/forms';
import {
    isDateType,
    isStringType,
    isEnumType,
    isLazyEnum,
    AnyCondition,
    isInCondition,
    isEqualCondition,
    isReferenceType,
    isEnumLikeType,
} from '../../../../domain/attributes';
import { AnyFilter } from '../../../../domain/dashboard';
import type { FilterItemFormValues } from './FilterItemView';

export function getInputProps(
    form: UseFormReturn<FilterItemFormValues>,
    filter: AnyFilter,
    condition: Pick<AnyCondition, 'value'>
): AnyInputProps {
    const { property } = filter;
    if (isDateType(property.type)) {
        return {
            type: 'string',
        };
    }
    if (isStringType(property.type)) {
        return {
            type: 'string',
        };
    }
    if (isEnumLikeType(property.type)) {
        const options = property.type.members.map((member) => ({
            label: member.label,
            value: member.value,
        }));
        const isSingleSelect = property.constraints.some(
            (x) => x.expression.operator == 'single_select'
        );
        if (
            (filter.default?.operator === 'in' &&
                (Array.isArray(condition.value) || !condition.value)) ||
            (filter.default.operator == 'eq' && !Array.isArray(condition.value))
        ) {
            const value =
                (Array.isArray(condition.value)
                    ? condition.value
                    : [condition.value]
                )?.map(
                    (element) => options.find((candidate) => candidate.value === element)!
                ) ?? [];
            if (isSingleSelect) {
                // @ts-expect-error
                return {
                    type: 'select',
                    isMulti: false,
                    value: value[0],
                    options,
                    onChange(options) {
                        return form.setValue(
                            'value',
                            options.value
                            // options.map((option) => option.value)
                        );
                    },
                };
            } else {
                // @ts-expect-error
                return {
                    type: 'select',
                    isMulti: true,
                    value,
                    options,
                    onChange(options) {
                        return form.setValue(
                            'value',
                            options.map((option) => option.value)
                        );
                    },
                };
            }
        }
        //  else if (isEqualCondition(condition)) {
        //     const value = options.find(
        //         (candidate) => candidate.value === condition.value
        //     );
        //     if (isSingleSelect) {
        //         return {
        //             type: 'select',
        //             isMulti: false,
        //             value,
        //             options,
        //             onChange(option) {
        //                 return form.setValue('value', option.value);
        //             },
        //         };
        //     } else {
        //         return {
        //             type: 'select',
        //             isMulti: true,
        //             value: value ? [value] : null,
        //             options,
        //             onChange(options) {
        //                 return form.setValue(
        //                     'value',
        //                     options.map((option) => option.value)
        //                 );
        //             },
        //         };
        //     }
        // }
    }
    if (isLazyEnum(property.type) || isReferenceType(property.type)) {
        // @ts-expect-error
        return {
            type: 'select',
            isMulti: false,
            onChange(option) {
                console.log('changed');
            },
        };
    }
    console.error('condition', condition);
    throw new Error(`'unexpected property ${JSON.stringify(property)}`);
}
