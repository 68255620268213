import { Location, NavigateFunction } from 'react-router';
import { UseFormReturn } from 'react-hook-form';
import { assert } from '../../../../../util/assert';
import { getAssetStatus } from '../../../../../domain/assets';
import { AnyOnboardingStepId } from '../../../../../domain/onboarding';
import { OnboardingAssetOptions } from '../../../../../service/onboarding';
import { ReportSelectFormProps } from './reportFormProps';
import { ReportSelectFormValues } from './reportFormModel';
import { ReportSelectFormValuesSchema } from './reportFormSchema';
import { ReportSelectData } from '../reportSelectInterface';

export function buildReportSelectFormProps(
    deps: { location: Location; navigate: NavigateFunction },
    data: ReportSelectData,
    form: UseFormReturn<ReportSelectFormValues>,
    values: ReportSelectFormValues
): ReportSelectFormProps {
    const { location, navigate } = deps;
    // const parsed = ReportSelectFormValuesSchema.safeParse(values);
    return {
        async onSubmit(raw) {
            const values = ReportSelectFormValuesSchema.parse(raw);
            const options: OnboardingAssetOptions = {
                cancel_path: location.pathname,
                mode: 'report',
            };

            const organizationOnboardingStep: AnyOnboardingStepId = 'integration';
            const assetOnboardingUrlParams = new URLSearchParams(Object.entries(options));
            const assetQueryString = assetOnboardingUrlParams.toString();

            const integrationUrlParams = new URLSearchParams();
            integrationUrlParams.set('step', organizationOnboardingStep);

            if (values.mode === 'new') {
                const asset = await data.asset.create.mutateAsync({
                    name: values.name,
                    url: values.url,
                    type: 'website',
                    isVisible: false,
                });
                const integrationSuccessPath = `/u/assets/${asset.id}/onboarding?${assetQueryString}`;
                integrationUrlParams.set('success_path', integrationSuccessPath);
                const integrationQueryString = integrationUrlParams.toString();
                navigate(`/u/onboarding?${integrationQueryString}`);
            } else {
                const item = data.item.data?.items.find(
                    (item) => item.asset.id === values.asset
                );
                assert(item, `asset ${values.asset} not found`);

                const status = getAssetStatus(item);

                // console.log('status', item.asset.name, status, item);
                const step: AnyOnboardingStepId =
                    status === 'unmapped' ? 'mapping' : 'peer_groups';

                assetOnboardingUrlParams.set('step', step);
                const integrationSuccessPath = `/u/assets/${values.asset}/onboarding?${assetQueryString}`;

                integrationUrlParams.set('success_path', integrationSuccessPath);
                const integrationQueryString = integrationUrlParams.toString();

                navigate(`/u/onboarding?${integrationQueryString}`);
            }
        },
        values,
        submitText: 'Continue',
        isSubmittable: !form.formState.isSubmitting,
        isSubmitting: form.formState.isSubmitting,
        form,
        error: data.asset.create.error,
    };
}
