import { useMemo } from 'react';
import { Asset, getAssetDomain } from '../../../../domain/assets';
import { AssetNewConfig } from './assetNewConfig';
import { AssetNewController } from './assetNewInterface';
import {
    AssetNewFormProps,
    AssetNewSuggestionProps,
    AssetNewViewProps,
} from './assetNewProps';

export function createAssetNewController(config: AssetNewConfig): AssetNewController {
    return {
        useProps(context, deps, props): AssetNewViewProps {
            const { form } = deps;

            const formValues = form.watch();

            const suggestionsByDomain = useMemo(
                () =>
                    props.item.assets.reduce(
                        (acc, item) => {
                            if (item.visible) {
                                // visible assets cannot be suggestions
                                return acc;
                            }
                            if (!item.url) {
                                return acc;
                            }
                            const domain = getAssetDomain(item);
                            if (!domain) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [domain]: item,
                            };
                        },
                        {} as Record<string, Asset>
                    ),
                [props.item.assets]
            );

            const suggestionProps = useMemo<AssetNewSuggestionProps>(() => {
                const domain = getAssetDomain(formValues);
                const suggestion = domain ? suggestionsByDomain[domain] : null;
                return {
                    candidates: props.item.assets.filter((item) => !item.visible),
                    match: suggestion,
                };
            }, [suggestionsByDomain, formValues, props.item.assets]);

            const formProps: AssetNewFormProps = {
                isSubmittable: form.formState.isDirty,
                error: deps.create.error ?? deps.update.error,
                handleSubmit: deps.form.handleSubmit,
                async onSubmit(values) {
                    if (props.item.current) {
                        console.info(
                            `no suggestion found for '${values.url}', updating existing asset...`
                        );
                        const response = await deps.update.mutateAsync([
                            props.item.current.id,
                            {
                                ...values,
                            },
                        ]);
                        return response;
                    }
                    const suggestion = suggestionProps.match;
                    if (suggestion) {
                        console.info(
                            `suggestion for '${values.url}' was found, setting visible...`
                        );
                        const response = await deps.update.mutateAsync([
                            suggestion.id,
                            {
                                ...values,
                                isVisible: true,
                            },
                        ]);
                        return response;
                    }
                    console.info(
                        `no suggestion found for '${values.url}', creating new asset...`
                    );
                    const response = await deps.create.mutateAsync({
                        ...values,
                        type: 'website',
                        isVisible: true,
                    });
                    return response;
                },
            };

            return {
                getFormProps() {
                    return formProps;
                },
                getControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                        error,
                    };
                },
                getInputProps(key) {
                    return { ...form.register(key) };
                },
                getSubmitButtonProps() {
                    return {
                        isDisabled: !formProps.isSubmittable,
                        isLoading: form.formState.isSubmitting,
                    };
                },
                getFormElementProps() {
                    return {
                        onSubmit: deps.form.handleSubmit(formProps.onSubmit),
                    };
                },
                getFormControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                    };
                },
                getErrorMessageProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        children: error?.message,
                    };
                },
                getAlertProps() {
                    if (!formProps.error) {
                        return null;
                    }
                    return {
                        status: 'error',
                        text: formProps.error.message,
                    };
                },
                getSuggestionProps() {
                    return suggestionProps;
                },
                getHelpProps() {
                    return {
                        onClick() {
                            config.infra.help.show();
                        },
                    };
                },
            };
        },
    };
}
