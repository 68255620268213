import { Box, ButtonGroup, Text, Tooltip, Button, Icon } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { CardSpinner } from '../../../../domain';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { OnboardingMetricConfig } from './onboardingMetricConfig';
import { createOnboardingMetricView } from './onboardingMetricView';
import { AiOutlineArrowRight } from 'react-icons/ai';

// export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createOnboardingMetricItem(
    init: OnboardingMetricConfig
): OnboardingAssetRouteStrategy {
    const { provider, loader, hooks } = init;
    const Component = createOnboardingMetricView();
    const controller = provider.createController(init);
    return {
        id: 'metrics',
        name: 'Select metrics',
        slug: 'metrics',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const organization = hooks.useOrganization();
                    const workspace = hooks.useWorkspace();
                    const auth = hooks.useAuth();
                    const context = { organization, workspace, auth };

                    const deps = init.loader.metric.useLoad(context);
                    const form = init.form.metric.useForm(context, deps.queries);

                    const viewProps = controller.useProps(context, deps, form, {
                        asset: props.asset,
                        onContinue: props.onContinue,
                    });

                    const formProps = viewProps.getFormProps();

                    return (
                        <Container
                            title="Select default metrics"
                            description={
                                <Text>
                                    Select the default set of metrics to include on your
                                    home page and in your reports
                                </Text>
                            }
                            rightContent={
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    isDisabled={formProps.isSubmittable}
                                    label={<Box p={2}>{formProps.submitLabel}</Box>}
                                >
                                    <span>
                                        <Button
                                            onClick={formProps.onSubmit}
                                            colorScheme="green"
                                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                                            isDisabled={!formProps.isSubmittable}
                                            isLoading={formProps.isSubmitting}
                                        >
                                            Continue
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
