import { z } from 'zod';

export const StudiesSubmissionNewParamsSchema = z.object({
    survey: z.string(),
    layout: z.union([z.literal('interview'), z.literal('form')]).default('form'),
});

export function createStudiesSubmissionFormSchema(PayloadSchema: z.ZodSchema) {
    return z.object({
        dataset: z.string(),
        // invite: InviteFormValuesSchema,
        payload: PayloadSchema,
    });
}
