import { useMemo } from 'react';
import { AssetEditAggregate } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingAssetProfileConfig } from './profileConfig';
import { SettingAssetProfileLoader } from './profileInterface';

export function createSettingAssetProfileLoader(
    init: Pick<SettingAssetProfileConfig, 'repository' | 'service'>,
    config: SettingAssetItemProviderConfig
): SettingAssetProfileLoader {
    const {
        api: { loader: pageLoader },
    } = config;
    return {
        useLoad(context) {
            const pageData = pageLoader.useLoad(context);
            const aggregate = useMemo<AssetEditAggregate>(
                () => ({ asset: pageData.asset }),
                [pageData]
            );
            return aggregate;
        },
    };
}
