import { LegalLayoutController } from './legalLayoutInterface';

export function createLegalLayoutController(): LegalLayoutController {
    return {
        useProps(props) {
            return {
                link: {
                    home: {
                        to: '/u',
                    },
                    back: props.back,
                },
            };
        },
    };
}
