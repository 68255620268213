export function downloadFile(config: { downloadUrl: string; filename: string }) {
    let element = document.createElement('a');
    if (typeof element.download === 'undefined') {
        window.location.href = config.downloadUrl;
    } else {
        element.href = config.downloadUrl;
        element.download = config.filename;
        document.body.appendChild(element);
    }
    element.click();
    element.remove();
    return;
}
