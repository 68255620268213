import { ValueFormatter } from '../../../../app/attributes';
import { QueryRequest } from '../../../../domain/query';
import { WorkspaceContextV2 } from '../../../../app';
import { StudyResponseItemAggregate } from './responseItemModel';
import { StudyResponseItemViewProps } from './responseItemProps';

export function buildStudyResponseItemViewProps(
    formatter: ValueFormatter,
    context: WorkspaceContextV2,
    item: StudyResponseItemAggregate
): StudyResponseItemViewProps {
    return {
        id: item.metric.id,
        metric: {
            title: item.metric.name,
            description: item.metric.description,
        },
        chart: {
            view: item.view,
            visualization: {
                kind: 'distribution',
                metrics: [item.metric],
                filters: [],
                granularity: 'week',
                period: null,
                breakdown: null,
                // @ts-expect-error
                ranking: null,
            },
        },
        links: {},
    };
}

// const REQUEST: QueryRequest = {
//     segment: [],
//     projections: [
//         {
//             name: 'distribution',
//             columns: [
//                 {
//                     key: 'creative_type',
//                     alias: null,
//                     expr: null,
//                     type: {
//                         kind: 'reference',
//                         id: 'creatives.creative_type',
//                     },
//                 },
//                 {
//                     key: 'dist',
//                     alias: null,
//                     expr: null,
//                     type: 'percent',
//                 },
//             ],
//         },
//         {
//             name: 'absolute',
//             columns: [
//                 {
//                     key: 'creative_type',
//                     alias: null,
//                     expr: null,
//                     type: {
//                         kind: 'reference',
//                         id: 'creatives.creative_type',
//                     },
//                 },
//                 {
//                     key: 'spend',
//                     alias: null,
//                     expr: 'total * dist',
//                     type: {
//                         kind: 'currency',
//                         currency: 'usd',
//                         constraints: [],
//                     },
//                 },
//             ],
//         },
//     ],
//     filters: [
//         {
//             key: 'TS',
//             operator: 'previous',
//             value: {
//                 amount: 4,
//                 interval: 'week',
//             },
//         },
//     ],
//     comparison: null,
//     facet: {
//         keys: ['creative_type'],
//         bucket_limit: {
//             limit: 8,
//             over_limit_bucket_name: 'Other',
//             value_aggregation: {
//                 kind: 'aggregation',
//                 type: 'sum',
//                 column: 'spend',
//                 nullify_zeros: false,
//                 partition: [],
//                 filters: [],
//             },
//         },
//         explode_values: true,
//     },
//     aggregations: [
//         {
//             name: 'total',
//             inverse: false,
//             expr: {
//                 kind: 'aggregation',
//                 type: 'sum',
//                 column: 'spend',
//                 nullify_zeros: false,
//                 partition: [],
//                 filters: [],
//             },
//         },
//         {
//             name: 'spend',
//             inverse: false,
//             expr: {
//                 kind: 'aggregation',
//                 type: 'sum',
//                 column: 'spend',
//                 nullify_zeros: false,
//                 partition: ['creative_type'],
//                 filters: [],
//             },
//         },
//         {
//             name: 'dist',
//             inverse: false,
//             expr: {
//                 kind: 'binary',
//                 operator: '/',
//                 left: {
//                     kind: 'aggregation',
//                     type: 'sum',
//                     column: 'spend',
//                     nullify_zeros: false,
//                     partition: ['creative_type'],
//                     filters: [],
//                 },
//                 right: {
//                     kind: 'aggregation',
//                     type: 'sum',
//                     column: 'spend',
//                     nullify_zeros: false,
//                     partition: [],
//                     filters: [],
//                 },
//             },
//         },
//     ],
//     segments: [
//         {
//             kind: 'asset',
//             name: 'My company',
//         },
//         {
//             kind: 'range',
//             name: 'High performers',
//             range: {
//                 column: 'cost_per_purchase',
//                 start: {
//                     kind: 'percentile',
//                     value: 66,
//                 },
//                 end: {
//                     kind: 'percentile',
//                     value: 100,
//                 },
//             },
//             reducer: {
//                 kind: 'average',
//             },
//         },
//     ],
//     granularity: 'week',
//     reducer: {
//         kind: 'average',
//     },
//     group: [],
//     timeseries: null,
//     source: {
//         view: 'creatives-type',
//     },
//     pivot: null,
// };
