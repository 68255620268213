import { groupBy as lodashGroupBy } from 'lodash';

type GroupByCallback<TItem> = (item: TItem) => string;

/**
 * Properly sets nullable type for members which is currently not done by
 * native lodash types
 * @param items
 * @param callback
 * @returns
 */
export function groupBySafe<TItem>(
    items: TItem[],
    callback: GroupByCallback<TItem>
): Record<string, TItem[] | undefined> {
    return lodashGroupBy(items, callback);
}
