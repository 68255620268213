import { z } from 'zod';

// Item

export const BillingPaymentIntentDtoSchema = z.object({
    id: z.string(),
    client_secret: z.string(),
});

// // Create

// export const BillingPaymentCreateDtoSchema = z.object({
//     plan: z.string(),
// });

// export const BillingPaymentCreateOptionsDtoSchema = z.object({
//     wait: z.number(),
//     idempotency_key: z.string(),
// });
