import {
    Box,
    HStack,
    VStack,
    ButtonProps,
    TextProps,
    StackDivider,
    Center,
    Text,
    Button,
    Alert,
    AlertTitle,
    Grid,
    GridItem,
    Image,
} from '@chakra-ui/react';
import { FiSettings, FiPlus, FiMinus } from 'react-icons/fi';
import { HiAdjustments, HiPlus } from 'react-icons/hi';
import { FaFilter } from 'react-icons/fa';
import { FaChartPie } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import React from 'react';
import {
    AiFillInfoCircle,
    AiFillPieChart,
    AiFillWarning,
    AiOutlineBarChart,
    AiOutlineInfoCircle,
    AiOutlinePieChart,
    AiOutlineInfo,
    AiOutlineLock,
} from 'react-icons/ai';
import { HiArrowSmRight, HiArrowSmUp, HiArrowSmDown } from 'react-icons/hi';
import { MetricStaticDeps } from '../../metricsConfig';
import {
    BenchmarkItemConnectedProps,
    BenchmarkListViewProps,
    BenchmarkStatus,
} from './benchmarkListProps';
import { createCalculationLayout } from '../../../../layout';
import { capitalize, first, last } from 'lodash';
// import { Link } from 'react-router-dom';
import { isConnectedProps } from './benchmarkListGuard';
import {
    InfoIcon,
    InfoOutlineIcon,
    LockIcon,
    Search2Icon,
    SettingsIcon,
} from '@chakra-ui/icons';
import { Bucket } from '../../../../domain/visualization';
import {
    AnyValue,
    isDateRangeValue,
    isMaybePartialNumericRangeValue,
} from '../../../../domain/attributes';
import { BenchmarkListAggregate } from './benchmarkListModel';
import { createComparisonContainer } from '../../comparison';
import {
    MetricCalculation,
    MetricConfiguration,
    MetricDefinition,
} from 'src/v2/domain/metrics';

const UpIcon = HiArrowSmUp;
const DownIcon = HiArrowSmDown;
const PeerGroupIcon = FaChartPie;

function getColor(status?: BenchmarkStatus): TextProps['color'] {
    if (!status) {
        return 'whiteAlpha.900';
    }
    return (
        {
            danger: 'red.500',
            warn: 'yellow.500',
            success: 'green.500',
        } as const
    )[status];
}

export function createBenchmarkListView(
    config: MetricStaticDeps
): React.FC<{ children?: React.ReactNode | undefined } & BenchmarkListViewProps> {
    const {
        UI: {
            Link,
            Tag: { Item: Tag },
            EmptyState,
            View,
            Table,
            Tbody,
            Thead,
            Tr,
            Td,
            Th,
            Icon: { IconGroup, Icon },
            Heading,
            Text,
        },
    } = config;
    const {
        Container,
        Value,
        Date,
        Name,
        Formula,
        Condition: { List: ConditionList, Item: ConditionItem },
        Tooltip: CalculationTooltip,
    } = createCalculationLayout(config);
    const MovementValue = createMovementValue(config);
    const DistributionValue = createDistributionValue(config);

    function getColorScheme(status?: BenchmarkStatus): TextProps['colorScheme'] {
        if (!status) {
            return undefined;
        }
        return (
            {
                danger: 'red',
                warn: 'yellow',
                success: 'green',
            } as const
        )[status];
    }

    return (props) => {
        // if (props.items.length === 0) {
        //     return (
        //         <EmptyState
        //             icon={<Icon fontSize="4xl" as={AiOutlineBarChart} />}
        //             title="No metrics found"
        //         />
        //     );
        // }
        return (
            <VStack w="full" align="start" spacing={6}>
                {props.plugins.disabled.length > 0 && (
                    <Grid templateColumns="repeat(2, 1fr)" w="full" minH={16}>
                        {props.plugins.disabled.map((item) => (
                            <GridItem key={item.id}>
                                <VStack
                                    align="start"
                                    w="full"
                                    h="full"
                                    borderWidth={2}
                                    borderStyle="solid"
                                    borderColor="whiteAlpha.300"
                                    borderRadius="lg"
                                    p={6}
                                >
                                    <HStack w="full" justify="space-between" spacing={12}>
                                        <HStack w="full" spacing={4}>
                                            <Box
                                                p={2}
                                                borderRadius="md"
                                                bg="whiteAlpha.300"
                                                // borderWidth={2}
                                                // borderColor="whiteAlpha.300"
                                                // borderStyle="solid"
                                                flexShrink={0}
                                                position="relative"
                                            >
                                                <Image
                                                    h={5}
                                                    w={5}
                                                    src={item.iconUrl}
                                                    zIndex={0}
                                                />
                                                <Box
                                                    top={-1.5}
                                                    right={-1.5}
                                                    position="absolute"
                                                    zIndex={1}
                                                    textShadow="lg"
                                                >
                                                    <Icon
                                                        // color="whiteAlpha.700"
                                                        fontSize="xl"
                                                        color="white"
                                                        as={LockIcon}
                                                    />
                                                </Box>
                                            </Box>
                                            <Text fontWeight="semibold">
                                                {item.disabledReason}
                                            </Text>
                                        </HStack>
                                        <HStack>
                                            <Link {...(item.link ?? { to: '' })}>
                                                <Button
                                                    size="sm"
                                                    variant="outline"
                                                    colorScheme="green"
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    Upgrade
                                                </Button>
                                            </Link>
                                        </HStack>
                                    </HStack>
                                </VStack>
                            </GridItem>
                        ))}
                    </Grid>
                )}
                {props.items.length === 0 && (
                    <EmptyState
                        icon={<Icon fontSize="4xl" as={AiOutlineBarChart} />}
                        title="No metrics found"
                    />
                )}
                {props.items.length > 0 && (
                    <Table>
                        <Thead>
                            <Tr>
                                <Th bg="black"></Th>
                                <Th bg="black"></Th>
                                <Th
                                    bg="black"
                                    textAlign="center"
                                    colSpan={2}
                                    borderBottomWidth="2px"
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                >
                                    {/* Absolute */}
                                    <VStack
                                        spacing={3}
                                        w="full"
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.300"
                                                borderWidth="1px"
                                            />
                                        }
                                    >
                                        <HStack justify="center" w="full">
                                            <Text>Benchmark</Text>
                                        </HStack>
                                    </VStack>
                                </Th>
                                <Th bg="black"></Th>
                                <Th
                                    bg="black"
                                    textAlign="center"
                                    colSpan={2}
                                    px={0}
                                    borderBottomWidth="2px"
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                >
                                    <VStack
                                        spacing={3}
                                        w="full"
                                        divider={
                                            <StackDivider
                                                borderColor="whiteAlpha.300"
                                                borderWidth="1px"
                                            />
                                        }
                                    >
                                        <HStack justify="center" w="full">
                                            <Text>Trend</Text>
                                        </HStack>
                                    </VStack>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td borderBottom="none" p={3}></Td>
                                <Td borderBottom="none" py={3} px={6}></Td>
                                <Td borderBottom="none" p={3} textAlign="center">
                                    <Text fontSize="sm" fontWeight="medium">
                                        Me
                                    </Text>
                                </Td>
                                <Td borderBottom="none" p={3} textAlign="center">
                                    <Text fontSize="sm" fontWeight="medium">
                                        Comparison
                                    </Text>
                                </Td>
                                <Td borderBottom="none" p={3} textAlign="center"></Td>
                                <Td borderBottom="none" p={3} textAlign="center">
                                    <Text fontSize="sm" fontWeight="medium">
                                        Me
                                    </Text>
                                </Td>
                                <Td borderBottom="none" p={3} textAlign="center">
                                    <Text fontSize="sm" fontWeight="medium">
                                        Peer group
                                    </Text>
                                </Td>
                                {/* <Td borderBottom="none" p={3} textAlign="center"></Td> */}
                            </Tr>
                            {props.items.flatMap((item) => {
                                const itemProps = props.getItemProps(item);
                                const peerProps = props.getPeerProps(item);
                                if (itemProps instanceof Error) {
                                    console.error('failed to render metric', itemProps);
                                    return [];
                                }
                                return [
                                    <Tr key={item.metric.id}>
                                        <Td>
                                            <HStack align="center">
                                                <CalculationTooltip
                                                    id="metric_list_name_tooltip"
                                                    minW="fit-content"
                                                    label={
                                                        <Container>
                                                            <Name
                                                                icon={
                                                                    itemProps.icon ??
                                                                    undefined
                                                                }
                                                                caption={
                                                                    item.metric
                                                                        .description ??
                                                                    undefined
                                                                }
                                                            >
                                                                {item.metric.name}
                                                            </Name>
                                                            <ConditionList
                                                                label="filters"
                                                                displayAsTags={true}
                                                            >
                                                                {item.configuration.filters
                                                                    // we want to show each filter value as a separate tag
                                                                    .flatMap((item) =>
                                                                        Array.isArray(
                                                                            item.value
                                                                        )
                                                                            ? item.value.map(
                                                                                  (
                                                                                      element
                                                                                  ) => ({
                                                                                      ...item,
                                                                                      value: [
                                                                                          element,
                                                                                      ] as any,
                                                                                  })
                                                                              )
                                                                            : []
                                                                    )
                                                                    .map(
                                                                        (item, index) => (
                                                                            <ConditionItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                name={props.getNameLabel(
                                                                                    item
                                                                                )}
                                                                                operator={props.getOperatorLabel(
                                                                                    item
                                                                                )}
                                                                            >
                                                                                {props.getValueLabel(
                                                                                    item
                                                                                )}
                                                                            </ConditionItem>
                                                                        )
                                                                    )}
                                                            </ConditionList>
                                                            {/* <Formula>{item.metric.formula}</Formula> */}
                                                        </Container>
                                                    }
                                                >
                                                    <span>
                                                        <Link
                                                            to={
                                                                itemProps.metricPath ??
                                                                'hackfix'
                                                            }
                                                        >
                                                            <HStack
                                                                spacing={4}
                                                                align="center"
                                                            >
                                                                <HStack
                                                                    spacing={2}
                                                                    flexShrink={0}
                                                                >
                                                                    {itemProps.icon ? (
                                                                        <IconGroup
                                                                            p={2}
                                                                            borderRadius="md"
                                                                            flexShrink={0}
                                                                        >
                                                                            <Icon
                                                                                w={5}
                                                                                h={5}
                                                                                src={
                                                                                    itemProps.icon
                                                                                }
                                                                            />
                                                                        </IconGroup>
                                                                    ) : null}
                                                                </HStack>
                                                                <VStack
                                                                    align="flex-start"
                                                                    spacing={1}
                                                                >
                                                                    <Box fontWeight="medium">
                                                                        {itemProps.label}
                                                                    </Box>
                                                                </VStack>
                                                            </HStack>
                                                        </Link>
                                                    </span>
                                                </CalculationTooltip>
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <CalculationTooltip
                                                id="metric_list_peers_tooltip"
                                                minW="fit-content"
                                                label={
                                                    <Container>
                                                        <Name
                                                            icon={
                                                                <Icon
                                                                    as={PeerGroupIcon}
                                                                />
                                                            }
                                                            kind={
                                                                <Tag colorScheme="blue">
                                                                    Default
                                                                </Tag>
                                                            }
                                                        >
                                                            {item.plugin.name}
                                                        </Name>
                                                        <ConditionList>
                                                            {item.calculation?.peers.map(
                                                                (item) => (
                                                                    <ConditionItem
                                                                        key={item.key}
                                                                        name={props.getNameLabel(
                                                                            item
                                                                        )}
                                                                        operator={props.getOperatorLabel(
                                                                            item
                                                                        )}
                                                                    >
                                                                        {props.getValueLabel(
                                                                            item
                                                                        )}
                                                                    </ConditionItem>
                                                                )
                                                            ) ?? []}
                                                        </ConditionList>
                                                        {peerProps.status ? (
                                                            <HStack
                                                                color="orange.400"
                                                                spacing={3}
                                                                align="start"
                                                                position="relative"
                                                            >
                                                                <Text>
                                                                    <Box
                                                                        as="span"
                                                                        top={0.5}
                                                                        position="relative"
                                                                        mr={2}
                                                                    >
                                                                        <Icon
                                                                            fontSize="md"
                                                                            color="orange.400"
                                                                            as={
                                                                                AiOutlineInfoCircle
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    {
                                                                        peerProps.status
                                                                            .text
                                                                    }
                                                                </Text>
                                                            </HStack>
                                                        ) : null}
                                                    </Container>
                                                }
                                            >
                                                <HStack
                                                    justify="start"
                                                    color="whiteAlpha.700"
                                                    fontWeight="medium"
                                                >
                                                    <Link
                                                        id="metric_list_peers"
                                                        to={
                                                            itemProps.peersPath ??
                                                            'hack fix'
                                                        }
                                                    >
                                                        <HStack
                                                            align="center"
                                                            whiteSpace="nowrap"
                                                        >
                                                            {item.configuration
                                                                .competitiveSet.conditions
                                                                .length > 0 ? (
                                                                <Button
                                                                    // color="whiteAlpha.700"
                                                                    // fontWeight="medium"
                                                                    // variant="link"
                                                                    bg="#292929"
                                                                    variant="outline"
                                                                    size="sm"
                                                                    rightIcon={
                                                                        <Icon
                                                                            as={
                                                                                peerProps.status
                                                                                    ? AiFillInfoCircle
                                                                                    : AiOutlineInfoCircle
                                                                            }
                                                                            color={
                                                                                peerProps
                                                                                    .status
                                                                                    ?.status ===
                                                                                'warning'
                                                                                    ? 'orange.400'
                                                                                    : undefined
                                                                            }
                                                                        />
                                                                    }
                                                                >
                                                                    Peers
                                                                </Button>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        // color="whiteAlpha.700"
                                                                        // fontWeight="medium"
                                                                        // variant="link"
                                                                        bg="#292929"
                                                                        variant="outline"
                                                                        size="sm"
                                                                        rightIcon={
                                                                            <Icon
                                                                                as={
                                                                                    HiPlus
                                                                                }
                                                                            />
                                                                        }
                                                                    >
                                                                        Set peers
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </HStack>
                                                    </Link>
                                                </HStack>
                                            </CalculationTooltip>
                                        </Td>
                                        {/* <Td></Td> */}
                                        {isConnectedProps(itemProps) ? (
                                            <>
                                                <Td>
                                                    <HStack justify="center">
                                                        <MovementValue
                                                            tooltipId="metrics_list_benchmark_me_tooltip"
                                                            description={
                                                                itemProps.absolute.tooltip
                                                                    .description
                                                            }
                                                            iconUrl={
                                                                itemProps.icon ??
                                                                undefined
                                                            }
                                                            name={itemProps.label}
                                                            value={
                                                                itemProps.absolute.tooltip
                                                                    .label
                                                            }
                                                            period={
                                                                props.datelabel.period
                                                            }
                                                        >
                                                            <Tag
                                                                userSelect="none"
                                                                minW={16}
                                                                size="lg"
                                                                colorScheme={getColorScheme(
                                                                    itemProps.absolute
                                                                        .tooltip.status ??
                                                                        undefined
                                                                )}
                                                                variant="solid"
                                                                justifyContent="center"
                                                            >
                                                                {
                                                                    itemProps.absolute
                                                                        .tooltip.label
                                                                }
                                                            </Tag>
                                                        </MovementValue>
                                                    </HStack>
                                                </Td>
                                                <Td px={0}>
                                                    <DistributionValue
                                                        {...props}
                                                        calculation={item.calculation}
                                                        definition={item.metric}
                                                        configuration={item.configuration}
                                                        ranking={item.ranking}
                                                        tooltipId="metrics_list_benchmark_comparison_tooltip"
                                                        current={{
                                                            value: itemProps.value,
                                                            label: itemProps.absolute
                                                                .tooltip.label,
                                                            color: getColor(
                                                                itemProps.absolute.tooltip
                                                                    .status ?? undefined
                                                            ),
                                                        }}
                                                        buckets={itemProps.buckets}
                                                        inverse={itemProps.inverse}
                                                        ticks={[
                                                            ...itemProps.ranges.map(
                                                                (item) => ({
                                                                    name: item.name,
                                                                    tick: item.label,
                                                                    description:
                                                                        item.description ??
                                                                        undefined,
                                                                    color: getColor(
                                                                        item.status
                                                                    ),
                                                                    value: {
                                                                        value:
                                                                            item.value ??
                                                                            0,
                                                                        label: item.label,
                                                                    },
                                                                })
                                                            ),
                                                        ]}
                                                    />
                                                </Td>
                                                <Td bg="black"></Td>
                                                {itemProps.trend.segments.map(
                                                    (item, index) => {
                                                        let iconContent = null;
                                                        if (item.direction === 'up') {
                                                            iconContent = (
                                                                <>
                                                                    <Icon
                                                                        fontSize="lg"
                                                                        as={UpIcon}
                                                                    />
                                                                </>
                                                            );
                                                        }
                                                        if (item.direction === 'down') {
                                                            iconContent = (
                                                                <>
                                                                    <Icon
                                                                        fontSize="lg"
                                                                        as={DownIcon}
                                                                    />
                                                                </>
                                                            );
                                                        }
                                                        const valueContent = (
                                                            <HStack
                                                                spacing={0.5}
                                                                color={getColor(
                                                                    item.status ??
                                                                        undefined
                                                                )}
                                                            >
                                                                {iconContent}
                                                                <Text>{item.label}</Text>
                                                            </HStack>
                                                        );
                                                        return (
                                                            <Td key={index}>
                                                                <VStack>
                                                                    <MovementValue
                                                                        tooltipId={
                                                                            item.tooltipId
                                                                        }
                                                                        description={
                                                                            item.description
                                                                        }
                                                                        iconUrl={
                                                                            itemProps.icon ??
                                                                            undefined
                                                                        }
                                                                        name={
                                                                            itemProps.label
                                                                        }
                                                                        value={
                                                                            valueContent
                                                                        }
                                                                        comparison={
                                                                            props
                                                                                .datelabel
                                                                                .comparison
                                                                        }
                                                                        period={
                                                                            props
                                                                                .datelabel
                                                                                .period
                                                                        }
                                                                    >
                                                                        <Tag
                                                                            userSelect="none"
                                                                            minW={16}
                                                                            size="lg"
                                                                            variant="solid"
                                                                            justifyContent="center"
                                                                            bg="whiteAlpha.300"
                                                                        >
                                                                            {valueContent}
                                                                        </Tag>
                                                                    </MovementValue>
                                                                </VStack>
                                                            </Td>
                                                        );
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Td>
                                            <HStack justify="center">
                                                <Link
                                                    id="metric_list_dashboard"
                                                    to={itemProps.path ?? 'hack fix'}
                                                >
                                                    <HStack
                                                        fontWeight="medium"
                                                        whiteSpace="nowrap"
                                                        color="whiteAlpha.700"
                                                        _hover={{
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        <Text>Dashboard</Text>
                                                        <Icon as={HiArrowSmRight} />
                                                    </HStack>
                                                </Link>
                                            </HStack>
                                        </Td>
                                    </Tr>,
                                ];
                            })}
                        </Tbody>
                    </Table>
                )}
            </VStack>
        );
    };
}

interface DistributionValueProps
    extends Pick<
        BenchmarkListViewProps,
        'getNameLabel' | 'getValueLabel' | 'getOperatorLabel'
    > {
    calculation: MetricCalculation | null;
    definition: MetricDefinition;
    configuration: MetricConfiguration;
    ranking: MetricDefinition | null;
    tooltipId: string;
    current: {
        label: string;
        value: number | null;
        color: ButtonProps['color'];
    };
    buckets: Array<Bucket & { status: BenchmarkStatus }>;
    inverse: boolean;
    ticks: Array<{
        color: ButtonProps['color'];
        name: string;
        description?: string;
        value: {
            label: string;
            value: number;
        };
    }>;
}

function createDistributionValue(
    config: MetricStaticDeps
): React.FC<{ children?: React.ReactNode | undefined } & DistributionValueProps> {
    const { Tooltip } = createCalculationLayout(config);
    const Comparison = createComparisonContainer({
        UI: config.UI,
        hook: config.hook,
    });
    const PercentileRank = config.createPercentileRank({});
    return (props) => {
        return (
            <Tooltip
                id={props.tooltipId}
                label={
                    props.calculation ? (
                        <Comparison
                            configuration={props.configuration}
                            ranking={props.ranking}
                            metric={props.definition}
                            calculation={props.calculation}
                        />
                    ) : null
                }
            >
                <span>
                    <PercentileRank
                        buckets={props.buckets.map((item) => ({
                            lower: item.lower,
                            upper: item.upper,
                            color: getColor(item.status),
                        }))}
                        inverse={props.inverse}
                        value={props.current.value}
                        ticks={props.ticks.map((item, index) => ({
                            hidden: index === 1,
                            value: item.value.value,
                            label: item.value.label,
                        }))}
                    />
                </span>
            </Tooltip>
        );
    };
}

interface MovementValueProps {
    tooltipId: string;
    iconUrl?: string;
    name: string;
    value: React.ReactNode;
    description: React.ReactNode;
    period: string;
    comparison?: string;
    // colorScheme: ButtonProps['colorScheme'];
}

function createMovementValue(
    config: MetricStaticDeps
): React.FC<{ children?: React.ReactNode | undefined } & MovementValueProps> {
    const {
        Container,
        Value: Cohort,
        Date,
        Name,
        Description,
        Tooltip,
    } = createCalculationLayout(config);
    return (props) => {
        return (
            <Tooltip
                id={props.tooltipId}
                label={
                    <Container>
                        <Name icon={props.iconUrl}>{props.name}</Name>
                        {props.comparison ? <Date>{props.comparison}</Date> : null}
                        <Date>{props.period}</Date>
                        <Cohort>{props.value}</Cohort>
                        {props.description ? (
                            <Description>{props.description}</Description>
                        ) : null}
                    </Container>
                }
            >
                {props.children}
            </Tooltip>
        );
    };
}
