import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import { AiOutlineBarChart, AiOutlineInfo, AiOutlineInfoCircle } from 'react-icons/ai';
import { BiTrendingUp } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import {
    Button,
    VStack,
    Flex,
    Text,
    Grid,
    GridItem,
    Box,
    Center,
    LightMode,
    SimpleGrid,
    HStack,
    Heading,
    ButtonGroup,
    Tag,
    Icon,
    Wrap,
    WrapItem,
    Skeleton,
    SkeletonText,
    SkeletonCircle,
    DarkMode,
    TagProps,
    TextProps,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    StackProps,
    StackDivider,
    Alert,
    AlertTitle,
    AlertIcon,
} from '@chakra-ui/react';
import { CardSpinner } from '../../../domain';
import { fetchElements, getAllChildrenProps } from '../../util';
import { getGridProps } from '../../ui';
import type { HomeLayout, HomeLayoutViewItemProps } from '../../route/home';
import { HomeLayoutConfig } from './homeLayoutConfig';
import { ChevronDownIcon, WarningIcon } from '@chakra-ui/icons';
import { last } from 'lodash';

export function createHomeLayout(config: HomeLayoutConfig): HomeLayout {
    const {
        errorFormatter,
        UI: {
            Tooltip,
            createErrorBoundary,
            EmptyState,
            Td,
            Text,
            Icon: { IconGroup },
            Link,
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    const Instance: HomeLayout = {
        UI: {
            ...config.UI,
            Grid: {
                ...config.UI.Grid,
                GridContainer(props) {
                    const mapped = getGridProps(Instance.UI.Grid, props);
                    return (
                        <SimpleGrid columns={{ base: 1, md: 2, xl: 3, '2xl': 3 }} gap={6}>
                            {mapped.itemProps.map((item) => {
                                let containerProps: StackProps = {};
                                if (item.itemProps.variant === 'ghost') {
                                    containerProps = {
                                        // bg: 'whiteAlpha.100',
                                        border: '2px solid',
                                        borderColor: 'whiteAlpha.300',
                                    };
                                } else {
                                    containerProps = {
                                        border: '2px solid',
                                        borderColor: 'whiteAlpha.300',
                                    };
                                }
                                return (
                                    <VStack
                                        key={item.itemProps.id}
                                        {...containerProps}
                                        align="start"
                                        borderRadius="lg"
                                        spacing={3}
                                    >
                                        <Box pt={6} px={6} w="full">
                                            {item.headerProps?.children}
                                        </Box>
                                        <Box px={6} w="full" flex={1}>
                                            {item.bodyProps?.children}
                                        </Box>
                                        <Box pb={6} px={6} w="full">
                                            {item.footerProps?.children}
                                        </Box>
                                    </VStack>
                                );
                            })}
                        </SimpleGrid>
                    );
                },
            },
            Td(props) {
                return <Td p={6} {...props} />;
            },
            Icon: {
                ...config.UI.Icon,
                IconGroup(props) {
                    return <IconGroup {...props} />;
                },
            },
            Text(props) {
                return <Text {...props} />;
            },
            Tag: {
                ...config.UI.Tag,
                Item: React.forwardRef<HTMLSpanElement, TagProps>((props, ref) => {
                    if (props.colorScheme === undefined) {
                        return <Tag ref={ref} bg="whiteAlpha.300" {...props} />;
                    }
                    return <Tag ref={ref} {...props} />;
                }),
            },
            EmptyState(props) {
                let commonTextStyle: Pick<TextProps, 'color' | 'opacity'> = {};
                let titleTextStyle: Pick<TextProps, 'color' | 'opacity'> = {
                    color: 'whiteAlpha.800',
                };
                let bodyTextStyle: Pick<TextProps, 'color' | 'opacity'> = {
                    color: 'whiteAlpha.500',
                };

                if (props.status === 'danger') {
                    commonTextStyle = { ...commonTextStyle, color: 'red.500' };
                    titleTextStyle = { ...titleTextStyle, color: 'red.500' };
                    bodyTextStyle = { ...bodyTextStyle, color: 'red.500' };
                }

                const icon = props.icon
                    ? React.cloneElement(props.icon, {
                          ...props.icon.props,
                          color: 'whiteAlpha.500',
                          fontSize: '4xl',
                          ...commonTextStyle,
                      })
                    : null;

                return (
                    <Center
                        h="full"
                        w="full"
                        minH="16rem"
                        // borderColor="whiteAlpha.300"
                        // borderWidth="2px"
                        // borderStyle="solid"
                        // bg="whiteAlpha.50"
                        p={16}
                        // px={32}
                        borderRadius="lg"
                        border="2px solid"
                        borderColor="whiteAlpha.300"
                    >
                        <VStack
                            w="full"
                            color="whiteAlpha.700"
                            spacing={4}
                            // px={16}
                            // py={12}
                            // bg="whiteAlpha.100"
                            // border="2px solid"
                            // borderColor="whiteAlpha.200"
                            borderRadius="md"
                        >
                            {icon && (
                                <Box
                                    bg="whiteAlpha.200"
                                    p={4}
                                    borderRadius="md"
                                    {...commonTextStyle}
                                >
                                    {icon}
                                </Box>
                            )}
                            <VStack spacing={2}>
                                <Heading
                                    m={0}
                                    fontSize="lg"
                                    fontWeight="medium"
                                    color="whiteAlpha.700"
                                    {...titleTextStyle}
                                >
                                    {props.title}
                                </Heading>
                                {props.description && (
                                    <Text
                                        fontSize="md"
                                        fontWeight="medium"
                                        color="whiteAlpha.700"
                                        {...bodyTextStyle}
                                    >
                                        {props.description}
                                    </Text>
                                )}
                            </VStack>
                        </VStack>
                    </Center>
                );
            },
        },
        Container(props) {
            const { ViewList, Header, ControlList, AlertList } = fetchElements(
                props.children,
                {
                    Header: Instance.Header,
                    ViewList: Instance.View.List,
                    ControlList: Instance.Control.List,
                    AlertList: Instance.Alert.List,
                }
            );
            const { viewItemProps } = getAllChildrenProps(ViewList.props.children, {
                viewItemProps: Instance.View.Item,
            });
            const { controlItemProps } = getAllChildrenProps(ControlList.props.children, {
                controlItemProps: Instance.Control.Item,
            });
            const { alertItemProps } = getAllChildrenProps(AlertList.props.children, {
                alertItemProps: Instance.Alert.Item,
            });

            const grouped = useMemo(
                () =>
                    viewItemProps.reduce(
                        (acc, item, index, array) => {
                            item.children;
                            if (item.layout?.fullWidth) {
                                return {
                                    sections: [...acc.sections, acc.current, [item]],
                                    current: [],
                                };
                            }
                            if (acc.current.length === 1) {
                                return {
                                    sections: [...acc.sections, [...acc.current, item]],
                                    current: [],
                                };
                            }
                            if (index === array.length - 1) {
                                return {
                                    sections: [...acc.sections, [...acc.current, item]],
                                    current: [],
                                };
                            }
                            return {
                                sections: [...acc.sections],
                                current: [item],
                            };
                        },
                        {
                            sections: [] as Array<HomeLayoutViewItemProps[]>,
                            current: [] as HomeLayoutViewItemProps[],
                        }
                    ).sections,
                [viewItemProps]
            ).filter((items) => items.length > 0);

            // console.log('DEBUG viewItemProps', viewItemProps);
            // console.log('DEBUG grouped', grouped);

            function getViewProps(item: HomeLayoutViewItemProps) {
                const { content } = fetchElements(
                    // @ts-expect-error
                    item.children,
                    {
                        content: Instance.View.Content,
                    }
                );
                const { actions } = getAllChildrenProps(item.children, {
                    actions: Instance.View.Action,
                });
                return { content, actions };
            }

            return (
                <DarkMode>
                    <HStack justifyContent="center" alignItems="center" w="full" h="full">
                        <VStack
                            align="start"
                            w="full"
                            h="full"
                            pt={{
                                sm: 16,
                            }}
                            px={4}
                            maxW={{
                                sm: '100vw',
                                // '2xl': '78vw',
                                md: '90vw',
                                lg: '90vw',
                                xl: '90vw',
                                '2xl': '80vw',
                                '3xl': '75vw',
                                // '2xl': '96rem',
                            }}
                        >
                            {alertItemProps.length > 0 && (
                                <VStack
                                    ref={AlertList.props.containerRef}
                                    align="start"
                                    w="full"
                                >
                                    {alertItemProps.map((item) => (
                                        <Alert
                                            key={item.id}
                                            status={item.status}
                                            // status="info"
                                            size="lg"
                                            w="full"
                                        >
                                            <AlertIcon />
                                            <AlertTitle w="full">
                                                <HStack w="full" justify="space-between">
                                                    <Text>{item.label}</Text>
                                                    <HStack spacing={4}>
                                                        {item.onDismiss && (
                                                            <Text
                                                                onClick={item.onDismiss}
                                                                opacity={0.5}
                                                                // color="blue.500"
                                                                cursor="pointer"
                                                                _hover={{
                                                                    // color: 'blue.600',
                                                                    opacity: 0.6,
                                                                }}
                                                            >
                                                                Dismiss
                                                            </Text>
                                                        )}
                                                        {item.action && (
                                                            <Link {...item.action.link}>
                                                                <Text
                                                                    cursor="pointer"
                                                                    _hover={{
                                                                        textDecor:
                                                                            'underline',
                                                                    }}
                                                                >
                                                                    {item.action.label}
                                                                </Text>
                                                            </Link>
                                                        )}
                                                    </HStack>
                                                </HStack>
                                            </AlertTitle>
                                        </Alert>
                                    ))}
                                </VStack>
                            )}

                            <Grid
                                w="full"
                                templateAreas={{
                                    base: `
                                        "title title"
                                        "control control"
                                        "content content"
                                    `,
                                    lg: `
                                        "title control"
                                        "content content"
                                    `,
                                }}
                                templateRows="min-content 1fr"
                            >
                                <GridItem
                                    py={{ base: 0, lg: 4 }}
                                    area="title"
                                    // on show divider on large screen
                                    borderBottomWidth={{ base: undefined, lg: 2 }}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                >
                                    <HStack w="full" align="center" spacing={4}>
                                        <Heading fontSize="2xl" whiteSpace="nowrap">
                                            {Header.props.children}
                                        </Heading>
                                        {Header.props.caption ? (
                                            <Tag size="md" flexShrink={0}>
                                                {Header.props.caption}
                                            </Tag>
                                        ) : null}
                                    </HStack>
                                </GridItem>
                                <GridItem
                                    py={{ base: 4, lg: 4 }}
                                    area="control"
                                    // on large screen show divider
                                    borderBottomWidth={2}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                >
                                    <HStack w="full" justify="flex-end" spacing={4}>
                                        {controlItemProps.map((item, index) => (
                                            <Box key={index}>{item.children}</Box>
                                        ))}
                                    </HStack>
                                </GridItem>
                                <GridItem area="content" pt={8} pb={48}>
                                    <Suspense
                                        fallback={
                                            <Center w="full" p={16}>
                                                <CardSpinner />
                                            </Center>
                                            // </Box>
                                        }
                                    >
                                        <VStack
                                            spacing={12}
                                            // divider={
                                            //     <StackDivider
                                            //         borderColor="whiteAlpha.200"
                                            //         borderWidth={2}
                                            //     />
                                            // }
                                        >
                                            {grouped.map((items) => (
                                                <SimpleGrid
                                                    key={items[0].id}
                                                    // minChildWidth="min-content"
                                                    // columns={{
                                                    //     xl: 1,
                                                    //     '2xl': 2,
                                                    // }}
                                                    columns={items.length}
                                                    // py={6}
                                                    gap={16}
                                                    w="full"
                                                    // templateColumns={{
                                                    //     // xl: '1fr 1fr',
                                                    //     '2xl': '1fr minmax(min-content, 25vw)',
                                                    // }}
                                                >
                                                    {items.map((item) => {
                                                        const { content, actions } =
                                                            getViewProps(item);

                                                        return (
                                                            <VStack
                                                                key={item.id}
                                                                w="full"
                                                                align="flex-start"
                                                                spacing={6}
                                                                // spacing={0}
                                                                divider={
                                                                    item.layout
                                                                        ?.showDivider ? (
                                                                        <StackDivider
                                                                            borderWidth={
                                                                                2
                                                                            }
                                                                            borderColor="whiteAlpha.200"
                                                                        />
                                                                    ) : undefined
                                                                }
                                                                display={
                                                                    item.isVisible
                                                                        ? undefined
                                                                        : 'none'
                                                                }
                                                                {...item.style}
                                                            >
                                                                <HStack
                                                                    w="full"
                                                                    justify="space-between"
                                                                    // borderBottom="2px solid"
                                                                    // borderColor="whiteAlpha.300"
                                                                    // pb={4}
                                                                >
                                                                    <HStack
                                                                        align="center"
                                                                        spacing={3}
                                                                    >
                                                                        <Heading
                                                                            fontSize="xl"
                                                                            color="whiteAlpha.900"
                                                                        >
                                                                            {item.title}
                                                                        </Heading>

                                                                        {item.description && (
                                                                            <Tooltip
                                                                                id={`home_view_${item.id}_tooltip`}
                                                                                p={3}
                                                                                placement="top"
                                                                                label={
                                                                                    item.description
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    <Icon
                                                                                        color="whiteAlpha.700"
                                                                                        fontSize="xl"
                                                                                        as={
                                                                                            AiOutlineInfoCircle
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        )}
                                                                        {item.status && (
                                                                            <Box>
                                                                                {
                                                                                    item.status
                                                                                }
                                                                            </Box>
                                                                        )}
                                                                    </HStack>
                                                                    <HStack justify="end">
                                                                        {
                                                                            item.rightContent
                                                                        }
                                                                        {actions.map(
                                                                            (
                                                                                action,
                                                                                index
                                                                            ) => (
                                                                                <Box
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        action.children
                                                                                    }
                                                                                </Box>
                                                                            )
                                                                        )}
                                                                    </HStack>
                                                                </HStack>
                                                                <ErrorBoundary
                                                                    fallback={(error) => {
                                                                        const info =
                                                                            errorFormatter.format(
                                                                                error.error
                                                                            );
                                                                        return (
                                                                            <Center
                                                                                w="full"
                                                                                py={8}
                                                                            >
                                                                                <Instance.UI.EmptyState
                                                                                    status="danger"
                                                                                    icon={
                                                                                        <Icon
                                                                                            as={
                                                                                                IoIosWarning
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    title={
                                                                                        info.name
                                                                                    }
                                                                                    description={
                                                                                        info.description
                                                                                    }
                                                                                />
                                                                            </Center>
                                                                        );
                                                                    }}
                                                                >
                                                                    <Box
                                                                        w="full"
                                                                        {...item.style
                                                                            ?.main}
                                                                    >
                                                                        {
                                                                            content.props
                                                                                .children
                                                                        }
                                                                    </Box>
                                                                </ErrorBoundary>
                                                            </VStack>
                                                        );
                                                    })}
                                                </SimpleGrid>
                                            ))}
                                        </VStack>
                                    </Suspense>
                                </GridItem>
                            </Grid>
                        </VStack>
                    </HStack>
                </DarkMode>
            );
        },
        Content(props) {
            return <></>;
        },
        Header(props) {
            return <></>;
        },
        View: {
            List(props) {
                return <></>;
            },
            Item(props) {
                return <></>;
            },
            Content(props) {
                return <></>;
            },
            Action(props) {
                return <></>;
            },
        },
        Control: {
            List(props) {
                return <></>;
            },
            Item(props) {
                return <></>;
            },
        },
        Alert: {
            List(props) {
                return <></>;
            },
            Item(props) {
                return <></>;
            },
        },
    };
    return Instance;
}
