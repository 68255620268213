import { useMemo } from 'react';
import { chain, groupBy, keyBy } from 'lodash';
import {
    DatasetConnectionAggregate,
    DatasetConnectionListAggregate,
    DataSourceAggregate,
    DataSourceListAggregate,
} from '../../../../view/datasets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { PluginEntity, ViewEntity } from '../../../../domain';
import { assert } from '../../../../util/assert';
import {
    AnyMappableEntity,
    Integration,
    IntegrationDefinition,
} from '../../../../domain/assets';
import { DatasetConnectionEntity, DatasetEntity } from '../../../../app/datasets';
import { SettingDataSourceConfig } from './sourceConfig';
import { SettingDataSourceLoader } from './sourceInterface';

export function createSettingDataSourceLoader(
    init: Pick<SettingDataSourceConfig, 'repository'>,
    config: SettingAssetItemProviderConfig
): SettingDataSourceLoader {
    const { repository } = init;
    const {} = config;
    return {
        useLoad(context) {
            const {
                api: { loader: pageLoader },
            } = config;
            const { asset } = pageLoader.useLoad(context);

            const plugins = repository.plugin.useFind(context, {}, { suspense: true });

            const definitions = repository.definition.useFind(
                context,
                {},
                { suspense: true }
            );
            const integrations = repository.integration.useFind(
                context,
                {},
                { suspense: true }
            );

            const entities = repository.entity.useFind(
                context,
                {
                    integrations:
                        integrations.data?.items.map((item) => ({
                            id: item.id,
                        })) ?? [],
                },
                { suspense: true }
            );

            const mappings = repository.mapping.useFind(
                context,
                {
                    integrations:
                        integrations.data?.items.map((item) => ({
                            id: item.id,
                        })) ?? [],
                },
                { suspense: true }
            );

            const connections = repository.connection.useFind(
                context,
                { asset },
                { suspense: true }
            );

            const integrationById = useMemo(
                () =>
                    keyBy(integrations.data?.items, (item) => item.id) as Record<
                        string,
                        Integration | undefined
                    >,
                [integrations.data?.items]
            );

            const pluginById = useMemo(
                () =>
                    keyBy(plugins.data, (item) => item.id) as Record<
                        string,
                        PluginEntity | undefined
                    >,
                [plugins.data]
            );

            const definitionById = useMemo(
                () =>
                    keyBy(definitions.data?.items, (item) => item.id) as Record<
                        string,
                        IntegrationDefinition | undefined
                    >,
                [definitions.data?.items]
            );

            const entityByKeyAndType = useMemo(
                () =>
                    keyBy(entities.data?.items, (item) =>
                        [item.key, item.type].join('|')
                    ) as Record<string, AnyMappableEntity | undefined>,
                [entities.data?.items]
            );

            const connectionsByEntityKeyAndType = useMemo(
                () =>
                    groupBy(connections.data, (item) =>
                        [item.entity.key, item.entity.type].join('|')
                    ) as Record<string, DatasetConnectionEntity[] | undefined>,
                [connections.data]
            );

            // console.log('pluginById', pluginById);

            const aggregate = useMemo<DataSourceListAggregate>(() => {
                return {
                    items: chain(mappings.data?.items)
                        .filter((item) => item.asset.id === context.workspace.id)
                        .flatMap((item): DataSourceAggregate[] => {
                            const entityLookupKey = [
                                item.entity.key,
                                item.entity.type,
                            ].join('|');
                            const entity = entityByKeyAndType[entityLookupKey];
                            const connections =
                                connectionsByEntityKeyAndType[entityLookupKey] ?? [];

                            if (!entity) {
                                console.warn(
                                    `entity ${entityLookupKey} not found`,
                                    entityByKeyAndType
                                );
                                return [];
                            }
                            const integration = integrationById[entity.integrationId];
                            if (!integration) {
                                console.warn(
                                    `integration ${entity.integrationId} not found`,
                                    integrationById
                                );
                                return [];
                            }
                            const definition = definitionById[integration.definitionId];
                            if (!definition) {
                                console.warn(
                                    `definition ${integration.definitionId} not found`,
                                    definitionById
                                );
                                return [];
                            }
                            return [
                                {
                                    mapping: item,
                                    asset,
                                    entity,
                                    definition,
                                    connections,
                                },
                            ];
                        })
                        .value(),
                };
            }, [
                asset,
                mappings.data?.items,
                entityByKeyAndType,
                connectionsByEntityKeyAndType,
                integrationById,
                definitionById,
            ]);

            return aggregate;
        },
    };
}
