import { Location } from 'react-router';
import { NavigationTrackingPayload } from './navigationTrackingEvent';
import { fromRaw } from './navigationTrackingSerializer';

export function buildPayload(location: Location): NavigationTrackingPayload | null {
    const parsed = fromRaw(location.state);
    if (!parsed) {
        return null;
    }
    return {
        source_link_id: parsed.source_link_id,
        source_path: parsed.source_path,
        source_page_title: parsed.source_page_title,
        target_path: location.pathname,
        target_page_title: document.title,
    };
}
