import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { DemoEntryEnhancer } from '../../../entrypoint/demo';
import { DemoRouteInit } from '../../../route/demo';
import { DemoFeatureConfig } from './demoFeatureConfig';
import { FeatureContext, useFeatureContext } from './demoFeatureContext';

export function createDemoFeatureStrategy(init: DemoFeatureConfig): DemoEntryEnhancer {
    const {
        view: { Modal: ModalView },
        redirectUrl,
    } = init;
    return (create) => (config) => {
        return create({
            ...config,
            components: {
                ...config.components,
                Provider(props) {
                    const Original = config.components.Provider;
                    const disclosure = useDisclosure();
                    return (
                        <FeatureContext.Provider value={disclosure}>
                            <Original {...props}>
                                {props.children}
                                <ModalView
                                    highlights={init.highlights}
                                    redirectUrl={redirectUrl}
                                    disclosure={disclosure}
                                />
                            </Original>
                        </FeatureContext.Provider>
                    );
                },
            },
            route: {
                ...config.route,
                demo: {
                    ...config.route.demo,
                    config: {
                        ...config.route.demo.config,
                        ...enhanceDemoConfig(init, config.route.demo.config),
                    },
                },
            },
        });
    };
}

function enhanceDemoConfig(
    init: DemoFeatureConfig,
    config: Pick<DemoRouteInit, 'controller'>
): Partial<DemoRouteInit> {
    return {
        ...config,
        controller: {
            ...config.controller,
            createRouteControllerHook() {
                const useController = config.controller.createRouteControllerHook();
                return () => {
                    const controller = useController();
                    const context = useFeatureContext();

                    const handleLockedClick = () => {
                        context.onOpen();
                    };

                    return {
                        ...controller,
                        getPlatformListProps(props) {
                            return controller.getPlatformListProps(props);
                        },
                        getPlatformItemProps(dashboard, props) {
                            const original = controller.getPlatformItemProps(
                                dashboard,
                                props
                            );
                            if (!init.lockedDashboardIds.includes(dashboard.id)) {
                                return original;
                            }
                            return {
                                ...original,
                                'aria-disabled': true,
                                tooltip: {
                                    label: 'Click register and view all data',
                                    placement: 'top',
                                },
                                onClick() {
                                    handleLockedClick();
                                },
                            };
                        },
                        getMetricProps(dashboard, metric, index) {
                            if (index === 0) {
                                // only give access to the first metric
                                return controller.getMetricProps(
                                    dashboard,
                                    metric,
                                    index
                                );
                            }
                            return {
                                ...controller.getMetricProps(dashboard, metric, index),
                                // we still want to trigger click events
                                // so we don't actually disable
                                onClick() {
                                    handleLockedClick();
                                },
                                'aria-disabled': true,
                                tooltip: 'Click register and view all data',
                            };
                        },
                        getDateFilterProps(dashboard) {
                            return {
                                ...controller.getDateFilterProps(dashboard),
                                // we still want to trigger click events
                                // so we don't actually disable
                                'aria-disabled': true,
                                onClick() {
                                    handleLockedClick();
                                },
                                tooltipProps: {
                                    placement: 'top',
                                    label: 'Click register and view all data',
                                },
                            };
                        },
                    };
                };
            },
        },
    };
}
