import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
    ColorToken,
    createBasicFormatter,
    createCharts,
    CustomTooltipProps,
} from '@varos/chakra-charts';
import { ReportInstanceDetailViewProps } from './instanceDetailProps';
import { ReportViewBaseConfig } from '../../../base';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    DarkMode,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    StackDivider,
    StackProps,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    TextProps,
    Tooltip,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import {
    ReportChapterViewProps,
    ReportChartCategoryViewProps,
    ReportChartCategoryContainerProps,
    ReportSectionViewProps,
    ReportVisualizationCategoryViewProps,
    ReportVisualizationViewProps,
    ReportVisualizationTrendViewProps,
} from '../../../view';
import { assertNever } from '@varos/util-typescript';
import {
    AiFillBulb,
    AiOutlineBarChart,
    AiOutlineBulb,
    AiOutlineCaretDown,
    AiOutlineCaretUp,
    AiOutlineCloudDownload,
    AiOutlineEdit,
    AiOutlineHeart,
    AiOutlineInfoCircle,
    AiOutlineLink,
    AiOutlineMenu,
    AiOutlineMore,
    AiOutlineShareAlt,
} from 'react-icons/ai';
import { FaAngleDown, FaAngleUp, FaChartPie } from 'react-icons/fa';
import {
    ReportChartTrendContainerProps,
    ReportChartTrendViewProps,
} from '../../../view/chart';
import { BsArrowRight, BsArrowRightShort } from 'react-icons/bs';
import {
    MdBookmarkBorder,
    MdFavoriteBorder,
    MdMoreVert,
    MdOutlineOpenInNew,
} from 'react-icons/md';
import { HiDotsHorizontal } from 'react-icons/hi';

const charts = createCharts({
    strategy: {
        format: createBasicFormatter({
            moment,
        }),
    },
});

const {
    Component: {
        ChartGrid: LineChartGrid,
        ChartLabel: LineChartLabel,
        ChartLegendItem: LineChartLegendItem,
        ChartLegendList: LineChartLegendList,
        ChartLegendTooltip: LineChartLegendTooltip,
        ChartRoot: LineChartRoot,
        ChartScalarAxis: LineChartScalarAxis,
        ChartTick: LineChartTick,
        ChartTimeAxis: LineChartTimeAxis,
        ChartTooltip: LineChartTooltip,
    },
} = charts.createTrend({
    options: {
        interaction: 'nearest',
        line: { spanGaps: false },
    },
});

const {
    Component: {
        ChartGrid: CategoryChartGrid,
        ChartLabel: CategoryChartLabel,
        ChartLegendItem: CategoryChartLegendItem,
        ChartLegendList: CategoryChartLegendList,
        ChartLegendTooltip: CategoryChartLegendTooltip,
        ChartRoot: CategoryChartRoot,
        ChartScalarAxis: CategoryChartScalarAxis,
        ChartTooltip: CategoryChartTooltip,
        ChartBar: CategoryChartBar,
        ChartRow: CategoryChartRow,
        ChartSort: CategoryChartSort,
        ChartSortIcon: CategoryChartSortIcon,
    },
} = charts.createCategory({
    options: {
        interaction: 'nearest',
        line: { spanGaps: false },
    },
});

export function createReportInstanceDetailView(
    config: ReportViewBaseConfig & {
        Container: {
            CategoryChart: React.FC<ReportChartCategoryContainerProps>;
            TrendChart: React.FC<ReportChartTrendContainerProps>;
        };
    }
): React.FC<ReportInstanceDetailViewProps> {
    const {
        Layout,
        Container: {
            CategoryChart: CategoryChartContainer,
            TrendChart: TrendChartContainer,
        },
    } = config;

    const containerStyle = {
        maxW: { base: '90vw', lg: '85vw', xl: '75vw', '2xl': '72rem' },
    } satisfies Partial<BoxProps>;

    const typography = {
        maxW: '36rem',
    } satisfies Partial<TextProps>;

    const gutter = {
        lg: {
            spacing: {
                base: 12,
                '2xl': 12,
            },
        },
        md: {
            spacing: {
                base: 8,
                '2xl': 8,
            },
        },
    } satisfies Record<string, Pick<StackProps, 'spacing'>>;

    const CustomTooltip: React.FC<CustomTooltipProps> = (props) => {
        if (props.mode === 'index') {
            return (
                <VStack
                    align="start"
                    boxShadow="2xl"
                    borderWidth={1}
                    borderColor="whiteAlpha.300"
                    color="white"
                    p={3}
                    spacing={1}
                    minW="12rem"
                    borderRadius="lg"
                    bg="#141414"
                    fontSize="sm"
                >
                    <Text fontWeight="medium">{props.label}</Text>
                    <VStack align="start" spacing={0} w="full">
                        {props.datasets.map((item) => (
                            <HStack
                                key={item.key}
                                spacing={2}
                                w="full"
                                justify="space-between"
                            >
                                <HStack spacing={2}>
                                    <Box h={3} w={3} borderRadius="sm" bg={item.color} />
                                    <Text color="whiteAlpha.600">
                                        {item.name}, {props.axes.scalar.label}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text
                                        bg="blackAlpha.900"
                                        px={1}
                                        py={0}
                                        borderRadius="sm"
                                        lineHeight={1}
                                    >
                                        {item.data[props.dataIndex]}
                                    </Text>
                                    <Text>{props.axes.scalar.unit}</Text>
                                </HStack>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>
            );
        }
        return (
            <VStack
                align="start"
                boxShadow="2xl"
                borderWidth={1}
                borderColor="whiteAlpha.300"
                p={3}
                spacing={1}
                minW="12rem"
                borderRadius="lg"
                bg="#141414"
                fontSize="sm"
            >
                <HStack spacing={2} color="whiteAlpha.900">
                    <Box h={3} w={3} borderRadius="sm" bg={props.dataset.color}></Box>
                    <Text fontWeight="medium">
                        {props.dataset.name}, {props.axes.scalar.label}
                    </Text>
                </HStack>
                <Text color="whiteAlpha.600" fontSize="xs">
                    {props.label}
                </Text>
                <HStack lineHeight={1} fontSize="sm" color="whiteAlpha.800">
                    <Tag
                        size="sm"
                        fontSize="sm"
                        bg="whiteAlpha.50"
                        px={1}
                        py={0.5}
                        borderRadius="sm"
                    >
                        {props.data.formattedValue}
                    </Tag>
                    <Text color="whiteAlpha.600">{props.axes.scalar.unit}</Text>
                </HStack>
                {props.change && (
                    <HStack lineHeight={1} fontSize="sm">
                        <DarkMode>
                            <Tag
                                variant="subtle"
                                size="sm"
                                px={1}
                                py={0.5}
                                borderRadius="sm"
                                colorScheme={props.change.color}
                            >
                                {props.change.formattedValue}
                            </Tag>
                        </DarkMode>
                        <Text color="whiteAlpha.600">
                            from previous {props.axes.time?.unit ?? 'interval'}
                        </Text>
                    </HStack>
                )}
            </VStack>
        );
    };

    const Line: React.FC<ReportChartTrendViewProps> = (props) => {
        return (
            <LineChartRoot {...props.chart.root}>
                <LineChartLegendList
                    as={Wrap}
                    textColor="whiteAlpha.600"
                    fontWeight="medium"
                    spacingX={{ base: 1, '2xl': 3 }}
                    spacingY={{ base: 0, '2xl': 2 }}
                    p={{ base: 0, '2xl': 0 }}
                    pt={0}
                    w="full"
                    justify="center"
                    fontSize="sm"
                >
                    <LineChartLegendItem
                        as={Stack}
                        direction="row"
                        spacing={3}
                        textColor="whiteAlpha.800"
                        _hover={{ bg: 'whiteAlpha.50' }}
                        py={1}
                        px={2}
                        borderRadius="sm"
                    />
                    <LineChartLegendTooltip
                        as={Tooltip}
                        placement="top"
                        hasArrow={true}
                        p={3}
                        bg="gray.100"
                        textColor="gray.900"
                    />
                </LineChartLegendList>
                <LineChartLabel color="whiteAlpha.500" />
                <LineChartTooltip as={CustomTooltip} p={6} />
                <LineChartGrid borderColor="whiteAlpha.200" />
                <LineChartScalarAxis {...props.chart.scalarAxis} placement="right">
                    <LineChartTick color="whiteAlpha.500" p={6} />
                </LineChartScalarAxis>
                <LineChartTimeAxis {...props.chart.timeAxis} />
            </LineChartRoot>
        );
    };

    const Category: React.FC<ReportChartCategoryViewProps> = (props) => {
        return (
            <CategoryChartRoot {...props.chart.root}>
                {props.chart.legend.display === false ? null : (
                    <CategoryChartLegendList
                        as={Wrap}
                        textColor="whiteAlpha.600"
                        fontWeight="medium"
                        spacingX={{ base: 1, '2xl': 3 }}
                        spacingY={{ base: 0, '2xl': 2 }}
                        p={{ base: 0, '2xl': 0 }}
                        pt={0}
                        w="full"
                        justify="center"
                        fontSize="sm"
                    >
                        <CategoryChartLegendItem
                            as={Stack}
                            direction="row"
                            spacing={3}
                            textColor="whiteAlpha.800"
                            _hover={{ bg: 'whiteAlpha.50' }}
                            py={1}
                            px={2}
                            borderRadius="sm"
                        />
                        <CategoryChartLegendTooltip
                            as={Tooltip}
                            placement="top"
                            hasArrow={true}
                            p={3}
                            bg="gray.100"
                            textColor="gray.900"
                        />
                    </CategoryChartLegendList>
                )}
                <CategoryChartLabel color="whiteAlpha.500" />
                <CategoryChartTooltip as={CustomTooltip} p={6} />
                <CategoryChartGrid borderColor="whiteAlpha.200" />
                <CategoryChartScalarAxis {...props.chart.scalarAxis} />
                <CategoryChartBar as={Box} h={6} minW={3} borderRadius="sm" />
                <CategoryChartRow as={HStack} spacing={6} />
                <CategoryChartSort column="value" direction="desc">
                    <CategoryChartSortIcon
                        color="whiteAlpha.600"
                        asc={<Icon as={FaAngleUp} />}
                        desc={<Icon as={FaAngleDown} />}
                    />
                </CategoryChartSort>
            </CategoryChartRoot>
        );
    };

    const Chart: React.FC<ReportVisualizationViewProps> = (props) => {
        if (props.type === 'line' || props.type === 'column') {
            return (
                <Box w="full" position="relative" p={6} pb={6}>
                    <Box w="full" h="full" maxH="25vh">
                        <TrendChartContainer
                            as={Line}
                            visualization={props.visualization}
                        />
                    </Box>
                </Box>
            );
        }
        if (props.type === 'bar' && props.visualization.data.series.length === 1) {
            return (
                <Box
                    w="full"
                    h="full"
                    position="relative"
                    // maxH="50vh"
                    // overflowY="scroll"
                    p={6}
                >
                    <CategoryChartContainer
                        as={Category}
                        visualization={props.visualization}
                    />
                </Box>
            );
        }
        if (props.type === 'bar' || props.type === 'distribution') {
            return (
                <HStack
                    justify="space-between"
                    w="full"
                    h="full"
                    position="relative"
                    // maxH="50vh"
                    // overflowY="scroll"
                    // p={8}
                    // pt={4}
                    p={6}
                >
                    <Tabs w="full" variant="solid-rounded">
                        <TabList as={VStack} spacing={3}>
                            {props.visualization.data.series.map((series, index) => (
                                <Tab
                                    key={index}
                                    id={series.key}
                                    h={8}
                                    color="whiteAlpha.500"
                                    fontWeight="medium"
                                    bg="none"
                                    _hover={{ bg: 'whiteAlpha.100' }}
                                    _selected={{
                                        color: 'blackAlpha.900',
                                        bg: 'blue.200',
                                        _hover: { bg: 'blue.200' },
                                    }}
                                >
                                    <HStack spacing={3}>
                                        <Icon as={FaChartPie} />
                                        <Text>{series.label}</Text>
                                    </HStack>
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels>
                            {props.visualization.data.series.map((series, index) => (
                                <TabPanel key={index} id={series.key} p={4} pt={8}>
                                    <CategoryChartContainer
                                        as={Category}
                                        visualization={{
                                            ...props.visualization,
                                            data: {
                                                ...props.visualization.data,
                                                series: [series],
                                            },
                                        }}
                                    />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                </HStack>
            );
        }
        assertNever(props.type);
    };

    const VisualizationItem: React.FC<ReportVisualizationViewProps> = (props) => {
        return (
            <Grid w="full" templateRows="1fr min-content">
                <GridItem
                    // min width of zero is required for proper responsiveness of nested charts
                    minW={0}
                >
                    <VStack w="full" align="start" spacing={0}>
                        <HStack
                            w="full"
                            p={8}
                            pb={0}
                            spacing={3}
                            align="start"
                            fontSize="md"
                            fontWeight="medium"
                            justify="space-between"
                        >
                            <HStack
                                cursor="pointer"
                                userSelect="none"
                                spacing={3}
                                color="whiteAlpha.900"
                                _hover={{ textDecor: 'underline' }}
                            >
                                <Icon fontSize="2xl" as={AiOutlineBarChart} />
                                <Text fontSize="lg" letterSpacing="wide">
                                    {props.visualization.data.title}
                                </Text>
                            </HStack>
                            <HStack>
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    label="Qui fugiat mollit sint magna velit"
                                    bg="gray.50"
                                    color="gray.900"
                                    p={3}
                                >
                                    <IconButton
                                        size="sm"
                                        aria-label="save"
                                        bg="none"
                                        borderColor="whiteAlpha.200"
                                        _hover={{ bg: 'whiteAlpha.100' }}
                                        icon={
                                            <Icon fontSize="lg" as={MdBookmarkBorder} />
                                        }
                                    />
                                </Tooltip>
                                <ButtonGroup
                                    size="sm"
                                    isAttached={true}
                                    variant="outline"
                                >
                                    <Button
                                        bg="none"
                                        borderColor="whiteAlpha.200"
                                        _hover={{ bg: 'whiteAlpha.100' }}
                                    >
                                        Open
                                    </Button>
                                    <Menu placement="bottom-end">
                                        <MenuButton
                                            as={IconButton}
                                            aria-label="menu"
                                            icon={<Icon as={MdMoreVert} />}
                                            bg="none"
                                            borderColor="whiteAlpha.200"
                                            _hover={{ bg: 'whiteAlpha.100' }}
                                            _active={{ bg: 'whiteAlpha.200' }}
                                        />
                                        <MenuList>
                                            <MenuItem
                                                icon={<Icon as={AiOutlineShareAlt} />}
                                            >
                                                Share
                                            </MenuItem>
                                            <MenuItem
                                                icon={
                                                    <Icon as={AiOutlineCloudDownload} />
                                                }
                                            >
                                                Download
                                            </MenuItem>
                                            <MenuItem
                                                icon={<Icon as={MdBookmarkBorder} />}
                                            >
                                                Bookmark
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </ButtonGroup>
                            </HStack>
                        </HStack>
                        <Chart {...props} />
                    </VStack>
                </GridItem>
                <GridItem p={8} pt={0}>
                    <HStack
                        w="full"
                        color="whiteAlpha.400"
                        fontSize="sm"
                        fontStyle="italic"
                        justify="end"
                    >
                        {props.source && (
                            <HStack spacing={6}>
                                <HStack spacing={1}>
                                    <Text>Source:</Text>
                                    <HStack spacing={2} align="center" userSelect="none">
                                        <Text>{props.source.label}</Text>
                                        <Icon as={AiOutlineInfoCircle} />
                                    </HStack>
                                </HStack>
                            </HStack>
                        )}
                    </HStack>
                </GridItem>
            </Grid>
        );
    };

    const SectionItem: React.FC<ReportVisualizationViewProps> = (props) => {
        return (
            <Grid
                w="full"
                h="fit-content"
                templateColumns={{ base: '1fr 2fr', '2xl': '2fr 5fr' }}
                templateRows="1fr"
                gap={gutter.lg.spacing}
            >
                <GridItem p={gutter.md.spacing} borderRadius="xl" bg="whiteAlpha.50">
                    <VStack align="start" w="full" spacing={4}>
                        <HStack color="whiteAlpha.800" spacing={3}>
                            <Heading size="md" fontWeight="medium">
                                {props.title}
                            </Heading>
                        </HStack>
                        {props.description && (
                            <Text color="whiteAlpha.600">{props.description}</Text>
                        )}
                    </VStack>
                </GridItem>
                <GridItem
                    borderRadius="xl"
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor="whiteAlpha.100"
                >
                    <VisualizationItem {...props} />
                </GridItem>
            </Grid>
        );
    };

    const SectionList: React.FC<ReportSectionViewProps> = (props) => {
        return (
            <Grid id={props.id} w="full" templateRows="min-content 1fr" gap={6} pt={6}>
                <GridItem
                    borderBottomWidth={3}
                    borderColor="whiteAlpha.200"
                    borderStyle="dashed"
                    pb={6}
                >
                    <VStack align="start" w="full" maxW={typography.maxW}>
                        <Heading size="lg" color="whiteAlpha.800" fontWeight="semibold">
                            {props.title}
                        </Heading>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack align="start" spacing={gutter.lg.spacing}>
                        {props.description && (
                            <HStack
                                fontSize="xl"
                                color="whiteAlpha.700"
                                maxW={typography.maxW}
                                align="start"
                                spacing={3}
                                position="relative"
                            >
                                {/* <Icon
                                    fontSize="2xl"
                                    as={AiFillBulb}
                                    position="relative"
                                    top={1}
                                /> */}
                                <Text>{props.description}</Text>
                            </HStack>
                        )}
                        <VStack
                            align="start"
                            h="fit-content"
                            w="full"
                            spacing={gutter.lg.spacing}
                        >
                            {props.visualizations.map((visualization) => (
                                <SectionItem
                                    key={visualization.title}
                                    {...visualization}
                                />
                            ))}
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };

    const ChapterItem: React.FC<ReportChapterViewProps> = (props) => {
        return (
            <Grid w="full" templateRows="min-content 1fr" gap={8}>
                <GridItem borderBottomWidth={3} borderColor="whiteAlpha.200" pb={8}>
                    <HStack w="full" justify="center">
                        <HStack
                            maxW={containerStyle.maxW}
                            w="full"
                            h="full"
                            justify="space-between"
                            align="end"
                        >
                            <VStack align="start" spacing={1}>
                                <Heading size="xl" fontWeight="semibold">
                                    {props.title}
                                </Heading>
                                <HStack
                                    spacing={3}
                                    color="whiteAlpha.600"
                                    divider={<Box border="none">&#x2022;</Box>}
                                >
                                    <Text>{props.period.label}</Text>
                                    <Text>{props.updated.label}</Text>
                                </HStack>
                            </VStack>
                            <ButtonGroup>
                                <Button
                                    rightIcon={<Icon as={AiOutlineEdit} />}
                                    colorScheme="blue"
                                    variant="outline"
                                    borderRadius="full"
                                >
                                    Customize
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </HStack>
                </GridItem>
                <GridItem>
                    <HStack w="full" h="full" justify="center">
                        <VStack
                            align="start"
                            w="full"
                            h="full"
                            maxW={containerStyle.maxW}
                            spacing={12}
                        >
                            <VStack align="start" spacing={12}>
                                {props.subtitle && (
                                    <HStack>
                                        <Text
                                            fontSize="2xl"
                                            color="whiteAlpha.800"
                                            maxW={'42rem'}
                                        >
                                            {props.subtitle}
                                        </Text>
                                    </HStack>
                                )}
                                {/* table of contents */}
                                <VStack align="start" w="full" spacing={3} fontSize="xl">
                                    {props.content.items.map((content, index) => (
                                        <HStack
                                            key={index}
                                            cursor="pointer"
                                            userSelect="none"
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                            onClick={content.onClick}
                                        >
                                            <Text>{index + 1}.</Text>
                                            <Text>{content.label}</Text>
                                            <Icon fontSize="2xl" as={BsArrowRightShort} />
                                        </HStack>
                                    ))}
                                </VStack>
                            </VStack>
                            <VStack align="start" w="full" spacing={24}>
                                {props.sections.map((section) => (
                                    <SectionList key={section.id} {...section} />
                                ))}
                            </VStack>
                        </VStack>
                    </HStack>
                </GridItem>
            </Grid>
        );
    };

    return (props) => {
        return (
            <Layout
                rightContent={
                    <ButtonGroup size="md">
                        <Tooltip
                            p={2}
                            bg="gray.50"
                            color="gray.900"
                            label="Manage report sharing"
                            placement="bottom"
                            hasArrow={true}
                        >
                            <Button
                                aria-label="save"
                                bg="none"
                                color="whiteAlpha.800"
                                _hover={{
                                    color: 'blue.300',
                                    bg: 'whiteAlpha.50',
                                }}
                            >
                                Share
                            </Button>
                        </Tooltip>
                        <Tooltip
                            p={2}
                            bg="gray.50"
                            color="gray.900"
                            label="Favorite"
                            placement="bottom"
                            hasArrow={true}
                        >
                            <IconButton
                                aria-label="save"
                                icon={<Icon fontSize="xl" as={AiOutlineHeart} />}
                                bg="none"
                                color="whiteAlpha.800"
                                _hover={{
                                    color: 'blue.300',
                                    bg: 'whiteAlpha.50',
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            p={2}
                            bg="gray.50"
                            color="gray.900"
                            label="Copy report URL"
                            placement="bottom"
                            hasArrow={true}
                        >
                            <IconButton
                                aria-label="save"
                                icon={<Icon fontSize="xl" as={AiOutlineLink} />}
                                bg="none"
                                color="whiteAlpha.800"
                                _hover={{
                                    color: 'blue.300',
                                    bg: 'whiteAlpha.50',
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            p={2}
                            bg="gray.50"
                            color="gray.900"
                            label="Favorite"
                            placement="bottom"
                            hasArrow={true}
                            isDisabled={true}
                        >
                            <IconButton
                                aria-label="save"
                                icon={<Icon fontSize="xl" as={HiDotsHorizontal} />}
                                bg="none"
                                color="whiteAlpha.800"
                                _hover={{
                                    color: 'blue.300',
                                    bg: 'whiteAlpha.50',
                                }}
                            />
                        </Tooltip>
                    </ButtonGroup>
                }
            >
                <VStack align="start" w="full" spacing={12} pt={0} pb="24rem">
                    {props.chapters.map((chapter) => (
                        <ChapterItem key={chapter.title} {...chapter} />
                    ))}
                </VStack>
            </Layout>
        );
    };
}
