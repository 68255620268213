import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildGroupInviteItemViewProps, GroupInviteItemViewProps } from '../item';
import { GroupInviteListController } from './groupInviteListInterface';

export function createGroupInviteListController(): GroupInviteListController {
    return {
        useProps(context, item) {
            const items = useMemo(() => chain(item.items).value(), [item.items]);

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item, index) => ({
                            ...acc,
                            [item.group.id]: buildGroupInviteItemViewProps(
                                context,
                                item,
                                index
                            ),
                        }),
                        {} as Record<string, GroupInviteItemViewProps | undefined>
                    ),
                [context, items]
            );
            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.group.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
            };
        },
    };
}
