import { ErrorFormatter } from '../../../base';
import { EmailEntryEnhancer } from '../../../entrypoint';

export interface EmailErrorMiddlewareConfig {
    errorFormatter: ErrorFormatter;
}

export function createEmailErrorMiddleware(
    init: EmailErrorMiddlewareConfig
): EmailEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                createReportController(controllerConfig) {
                    const controller =
                        config.controller.createReportController(controllerConfig);
                    return {
                        useProps(context, definition, payload) {
                            return {
                                ...controller.useProps(context, definition, payload),
                                formatError(error) {
                                    return init.errorFormatter.format(error);
                                },
                            };
                        },
                    };
                },
            },
        });
    };
}
