import { ValueFormatter } from '../../app/attributes';
import { Property } from '../../domain/attributes';
import { FormatController } from './formatInterface';
import { OPERATOR_LABEL_BY_KEY } from './formatConstant';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

export function createFormatController(config: {
    hooks: {
        useValueFormatter(): ValueFormatter;
    };
}): FormatController {
    const {
        hooks: { useValueFormatter },
    } = config;
    return {
        useProps(context) {
            const formatter = useValueFormatter();
            const propertiesByKey = useMemo(
                () =>
                    context.properties.reduce(
                        (acc, item) => ({ ...acc, [item.key]: item }),
                        {} as Record<string, Property | undefined>
                    ),
                [context.properties]
            );
            return {
                tryFormatValue(condition) {
                    const property = propertiesByKey[condition.key];
                    if (property) {
                        return formatter.format(property.type, condition.value, {
                            notation: 'short',
                            truncate: 3,
                        });
                    }

                    if (typeof condition.value === 'string') {
                        return capitalize(condition.value).replaceAll('_', ' ');
                    }

                    //  hack, we need to fetch the data filter properties and map properly
                    const isEnumValues =
                        Array.isArray(condition.value) &&
                        condition.value.every((element) => typeof element === 'string');

                    if (isEnumValues) {
                        return condition.value
                            .map((item: string) => capitalize(item).replace('_', ' '))
                            .join(' ');
                    }
                    return null;
                },
                formatKey(condition) {
                    const property = propertiesByKey[condition.key];
                    // if (!property) {
                    //     console.info(
                    //         `condition property ${condition.key} not found`,
                    //         propertiesByKey
                    //     );
                    // }
                    // return property?.name ?? capitalize(condition.key).replace('_', ' ');
                    return property?.name ?? condition.key;
                },
                formatOperator(condition) {
                    const mapped = OPERATOR_LABEL_BY_KEY[condition.operator];
                    return mapped ?? condition.operator;
                },
            };
        },
    };
}
