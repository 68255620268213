import { Box, Button, Center, Icon, Text, VStack } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { CardSpinner } from '../../../../domain';
import { RegistrationViewConfig } from '../base';
import { RegistrationLayoutViewProps } from './registrationLayoutProps';

export function createRegistrationLayoutView(
    config: RegistrationViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & RegistrationLayoutViewProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
    } = config;
    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });
    return (props) => {
        return (
            <Box ref={props.containerRef} w="full" h="full" overflowY="auto">
                <Suspense fallback={<CardSpinner />}>
                    <ErrorBoundary
                        fallback={(info) => (
                            <Center w="full" h="full" p={16}>
                                <VStack
                                    align="center"
                                    color="red.600"
                                    bg="gray.100"
                                    borderWidth={1}
                                    borderStyle="solid"
                                    borderColor="gray.200"
                                    px={12}
                                    py={6}
                                    borderRadius="md"
                                >
                                    <Icon h={12} w={12} as={AiOutlineWarning} />
                                    <Text fontSize="lg" fontWeight="semibold">
                                        {info.error.message &&
                                            `An unexpected error occured`}
                                    </Text>
                                </VStack>
                            </Center>
                        )}
                    >
                        {props.children}
                    </ErrorBoundary>
                </Suspense>
            </Box>
        );
    };
}
