import { useDashboardApi } from '../../../app';
import { DashboardIssueController } from './issueInterface';

export const useIssueController: DashboardIssueController = () => {
    const api = useDashboardApi();
    const issue = api.useIssue();
    return {
        issue,
    };
};
