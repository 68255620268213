import { observer } from 'mobx-react';
import { InsightRouteStateProvider } from '../../../route';
import { InsightStoreImpl } from './stateImpl';

export function createInsightStateProvider(): InsightRouteStateProvider {
    const store = new InsightStoreImpl({
        initialValue: {
            period: null,
            sorting: null,
        },
    });
    return {
        useState() {
            return {
                value: store.getState(),
                onChange(value) {
                    store.setState(value);
                },
            };
        },
        connect(Component) {
            return observer(Component);
        },
    };
}
