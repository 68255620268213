export function calculateBarWidths(
    data: Array<{ value: number | null }>,
    maxWidth: number,
    startAtZero: boolean
): number[] {
    if (data.length === 0) {
        throw new Error('Data array cannot be empty');
    }

    const values = data.map((point) => point.value ?? 0);
    const min = startAtZero ? 0 : Math.min(...values);
    const max = Math.max(...values);
    const range = max - min;

    // Calculate the widths of each bar based on their normalized values
    const widths = data.map((point) => {
        const normalizedValue = ((point.value ?? 0) - min) / range;
        const width = normalizedValue * maxWidth;
        return Number(width.toFixed(1));
    });

    return widths;
}

export function calculateRelativeValues(data: Array<{ value: number | null }>): number[] {
    if (data.length === 0) {
        throw new Error('Data array cannot be empty');
    }

    const total = data.reduce((sum, point) => sum + (point.value ?? 0), 0);

    if (total === 0) {
        throw new Error('Total sum of values cannot be zero');
    }

    // Calculate the relative values as percentages
    const relativeValues = data.map((point) => {
        const percentage = ((point.value ?? 0) / total) * 100;
        return Number(percentage.toFixed(1));
    });

    return relativeValues;
}
