import { QueryRequest } from '../../../../domain/query';
import { DEFAULT_SEGMENT } from '../common';

export function getGoogleQuery(): QueryRequest {
    return {
        segment: [],
        projections: [
            {
                name: 'absolute',
                columns: [
                    {
                        key: 'cpm',
                        alias: null,
                        expr: null,
                        type: {
                            kind: 'currency',
                            currency: 'usd',
                            constraints: [],
                        },
                    },
                ],
            },
        ],
        filters: [
            {
                key: 'TS',
                operator: 'previous',
                value: {
                    amount: 4,
                    interval: 'week',
                },
            },
        ],
        comparison: null,
        facet: null,
        aggregations: [
            {
                name: 'cpm',
                title: 'CPM',
                inverse: true,
                expr: {
                    kind: 'binary',
                    operator: '/',
                    left: {
                        kind: 'aggregation',
                        type: 'sum',
                        column: 'spend',
                        partition: [],
                        filters: [],
                    },
                    right: {
                        kind: 'binary',
                        operator: '/',
                        left: {
                            kind: 'aggregation',
                            type: 'sum',
                            column: 'impressions',
                            partition: [],
                            filters: [],
                        },
                        right: {
                            kind: 'integer',
                            value: 1000,
                        },
                    },
                },
            },
        ],
        segments: DEFAULT_SEGMENT,
        granularity: 'week',
        group: [],
        timeseries: {},
        source: {
            view: 'googleads.googleads',
        },
    };
}
