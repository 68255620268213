import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { MappingResource } from './mappingInterface';
import { MappingListResponseSchema, MappingDtoSchema } from './mappingSchema';

export function createMappingResource(client: AxiosInstance): MappingResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/integrations/mapping-rules`, {
                params: {
                    ...params,
                    integrations: query.integrations,
                    page: 1,
                    page_size: 1000,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = MappingListResponseSchema.parse(response.data);
            return {
                data: parsed.items,
            };
        },
        async create(context, request) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(
                `/api/v1/integrations/mapping-rules`,
                request,
                {
                    params: {
                        ...params,
                    },
                    headers: {
                        ...headers,
                    },
                }
            );

            const parsed = MappingDtoSchema.parse(response.data);
            return parsed;
        },
        async delete(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.delete(
                `/api/v1/integrations/mapping-rules/${id}`,
                {
                    params: {
                        ...params,
                    },
                    headers: {
                        ...headers,
                    },
                }
            );
            return;
        },
    };
}
