import { action, computed, makeObservable, observable } from 'mobx';
import { MapModel } from '../models/IntegMapping';
import { getMappings } from '../api/integrations';
import StoreBase from './StoreBase';

class IntegMappingStore extends StoreBase {
    isLoading: boolean = false;
    mappingModels: Array<MapModel> | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            mappingModels: observable,
            isLoading: observable,

            // Actions
            getMapping: action,
            setMappingModels: action,
            setLoading: action,
        });
    }
    setLoading(flag: boolean) {
        this.isLoading = flag;
    }

    setMappingModels(models: Array<MapModel> | null) {
        this.mappingModels = models;
    }

    getMapping = async (integId: number) => {
        this.setLoading(true);
        try {
            const { data } = await getMappings(integId);
            this.setMappingModels(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getMapping(integId);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default IntegMappingStore;
