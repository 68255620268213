import React from 'react';
import { NetworkContextProvider } from '../base';
import { NetworkLayoutController } from './networkLayoutInterface';
import { NetworkLayoutRouteViewProps } from './networkLayoutProps';

export function createNetworkLayoutRoute(
    contexts: NetworkContextProvider,
    controller: NetworkLayoutController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & NetworkLayoutRouteViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const viewContext = contexts.useContext();
        const viewProps = controller.useProps(viewContext, {});
        return <View {...viewProps}>{props.children}</View>;
    };
}
