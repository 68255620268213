import React from 'react';
import { WorkspaceSelectionConfig } from './workspaceSelectionConfig';
import { WorkspaceSelectionController } from './workspaceSelectionInterface';
import { WorkspaceSelectionViewProps } from './workspaceSelectionProps';

export function createWorkspaceSelectionContainer(
    config: WorkspaceSelectionConfig['deps'],
    controller: WorkspaceSelectionController,
    Component: React.FC<
        { children?: React.ReactNode | undefined } & WorkspaceSelectionViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        hook: { useAuth, useOrganization },
    } = config;
    return (props) => {
        const auth = useAuth();
        const organization = useOrganization();
        const viewProps = controller.useProps({ auth, organization });
        return <Component {...viewProps}>{props.children}</Component>;
    };
}
