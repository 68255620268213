import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FormIds, PageIds } from '../../../../config';
import { UrlOptionParser } from '../../../../base';
import { PeerContextProvider, PeerRouteConfig } from '../../base';
import {
    PeerGroupDetailRouteController,
    PeerGroupRouteDetailLoader,
} from './peerGroupDetailInterface';
import { PeerGroupDetailEditFormValues } from './peerGroupDetailModel';
import { PeerGroupDetailViewProps } from './peerGroupDetailProps';
import { PeerGroupDetailPathParamsSchema } from './peerGroupDetailSchema';
import { useDisclosure } from '@chakra-ui/react';

export function createPeerGroupDetailRoute(
    config: PeerRouteConfig,
    options: UrlOptionParser,
    context: PeerContextProvider,
    controller: PeerGroupDetailRouteController,
    loader: PeerGroupRouteDetailLoader,
    View: React.FC<{ children?: React.ReactNode | undefined } & PeerGroupDetailViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        provider: { createFormController },
        controller: {
            common: { page: pageController },
        },
        repository: { peerset: peerSetRepository },
    } = config;

    const formController = createFormController();

    return (props) => {
        const navigate = useNavigate();

        const viewContext = context.useContext();

        const viewParams = options.useOptions(PeerGroupDetailPathParamsSchema);
        const viewData = loader.useLoad(viewContext, {
            peerset: { id: viewParams.competitiveSetId },
        });

        const mutation = {
            update: peerSetRepository.useUpdate(viewContext),
            remove: peerSetRepository.useRemove(viewContext),
        };

        const forms = {
            edit: useForm({
                defaultValues: {
                    name: viewData.group.peerset.name,
                },
            }),
        };

        const [searchParams, setSearchParams] = useSearchParams();
        const disclosure = useDisclosure({
            isOpen: !!searchParams.get('edit'),
            onOpen() {
                searchParams.set('edit', viewData.group.peerset.id);
                setSearchParams(searchParams, { replace: true });
            },
            onClose() {
                searchParams.delete('edit');
                setSearchParams(searchParams, { replace: true });
            },
        });

        const viewProps = controller.useProps(viewContext, viewData, {
            edit: {
                variant: 'modal',
                page: pageController.useProps(viewContext, {
                    item: {
                        id: PageIds.PEER_COMPETITIVE_SET_EDIT,
                        title: 'Edit group',
                        breadcrumbs: [],
                    },
                }),
                form: formController.useProps<PeerGroupDetailEditFormValues>({
                    id: FormIds.PEER_GROUP_EDIT,
                    form: forms.edit,
                    action: { label: 'Save group' },
                    async onSubmit(values) {
                        const response = await mutation.update.mutateAsync([
                            viewData.group.peerset,
                            values,
                        ]);
                        disclosure.onClose();
                        return response;
                    },
                }),
                disclosure,
            },
            actions: {
                navigate,
                async onDelete() {
                    const response = await mutation.remove.mutateAsync(
                        viewData.group.peerset
                    );
                    navigate('..');
                },
            },
            confirm: {
                disclosure: useDisclosure(),
                isSubmitting: mutation.remove.isLoading,
                async onSubmit() {
                    const response = await mutation.remove.mutateAsync(
                        viewData.group.peerset
                    );
                    navigate('..');
                    return response;
                },
            },
        });
        return <View {...viewProps} />;
    };
}
