import { InsightRouteConfigV2 } from '../insightRouteConfig';
import { InsightRouteItemController } from './insightItemInterface';

export function createInsightRouteItemController(
    config: Pick<InsightRouteConfigV2['deps'], 'controller'>
): InsightRouteItemController {
    const { controller } = config;
    return {
        useProps(context, props) {
            const opportunityProps = controller.opportunity.item.useProps(context, props);
            return { opportunityProps };
        },
    };
}
