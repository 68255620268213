import React from 'react';
import { useNavigate } from 'react-router';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingAssetItemConfig } from '../assetConfig';
import {
    SettingAssetNewController,
    SettingAssetNewLoader,
    SettingAssetDeps,
} from './newInterface';
import { SettingAssetNewContainerProps } from './newProps';

export function createSettingAssetNewContainer(
    init: Pick<SettingAssetItemConfig, 'form' | 'service' | 'infra'>,
    config: Pick<SettingAccountItemProviderConfig, 'api'>,
    loader: SettingAssetNewLoader,
    controller: SettingAssetNewController
): React.FC<{ children?: React.ReactNode | undefined } & SettingAssetNewContainerProps> {
    const {
        infra: { toaster },
        service: { asset: service },
        form: {
            asset: { new: newForm },
        },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const viewDeps: SettingAssetDeps = {
            toast: toaster.useToast(),
            navigate: useNavigate(),
            new: {
                create: service.useCreate(viewContext),
                update: service.useUpdate(viewContext),
                form: newForm.useForm(viewContext, { item: viewData }),
            },
        };
        const viewProps = controller.useProps(viewContext, viewDeps, { item: viewData });
        return <Component {...viewProps}>{children}</Component>;
    };
}
