import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useWorkspaceContextV2 } from '../../context';
import {
    relativePeriodToDates,
    dependentPeriodToAbsolute,
    isRelativePeriod,
    isAbsolutePeriod,
    periodToString,
    isDependentPeriod,
    periodToRange,
    Period,
} from '../../../domain';
import { PageIds } from '../../config';
import { HomeRouteConfig } from './homeRouteConfig';
import { HomeRouteController } from './homeRouteInterface';
import { HomeRouteProps } from './homeRouteProps';

export function createHomeRouteController(config: HomeRouteConfig): HomeRouteController {
    const {
        deps: {
            controller,
            adapter: { useControl },
        },
        providers: { createPageController, ...providers },
    } = config;

    const { useProps: usePageProps } = createPageController({
        id: PageIds.HOME,
        title: 'Home',
        description: null,
        provider: providers,
    });

    return {
        useProps(context, data, workspace, runtime): HomeRouteProps {
            const homedata = useWorkspaceContextV2();
            const control = useControl(context.workspace);

            const page = usePageProps(data.page, {});

            const [hidden, setHidden] = useState<string[]>([]);

            const dateProps = controller.date.useProps({
                value: control.value.date.period,
                onChange(value) {
                    if (isAbsolutePeriod(value)) {
                        control.set({
                            ...control.value,
                            date: {
                                ...control.value.date,
                                period: {
                                    start: value.start,
                                    end: value.end,
                                },
                            },
                        });
                    } else {
                        control.set({
                            ...control.value,
                            date: {
                                ...control.value.date,
                                period: value,
                            },
                        });
                    }
                },
            });

            const integrationProps = controller.integration.useProps(
                data.integration,
                {}
            );

            const destinationProps = controller.destination.useProps(
                data.destination,
                {}
            );

            const opportunityProps = controller.opportunity.useProps(
                context,
                data.opportunity,
                { period: control.value.date.period }
            );

            const alertProps = controller.alert.useProps(context, data.alert, {
                period: control.value.date.period,
            });

            const guideProps = controller.guide.useProps(context, {
                item: data.guide,
            });

            const expertCallProps = controller.expertCall.useProps(context, {
                item: data.guide,
            });

            const props: HomeRouteProps = {
                page,
                context: { ...context, data: homedata },
                isVisible(item) {
                    return hidden.includes(item.id) ? false : true;
                },
                isEnabled(item) {
                    return true;
                },
                getOpportunityProps() {
                    return opportunityProps;
                },
                getAlertProps() {
                    return alertProps;
                },
                getIntegrationProps() {
                    return integrationProps;
                },
                getDestinationProps() {
                    const id = 'destinations';
                    return {
                        ...destinationProps,
                        id,
                        isHidden: data.hidden.value[id] ?? false,
                        onHide() {
                            data.hidden.onChange({ ...data.hidden.value, [id]: true });
                        },
                    };
                },
                getGuideProps() {
                    const id = 'guides';
                    return {
                        ...guideProps,
                        id,
                        isHidden: data.hidden.value[id] ?? false,
                        onHide() {
                            data.hidden.onChange({ ...data.hidden.value, [id]: true });
                        },
                    };
                },
                getExpertCallProps() {
                    const id = 'expert_calls';
                    return {
                        ...expertCallProps,
                        id,
                        isHidden: data.hidden.value[id] ?? false,
                        onHide() {
                            data.hidden.onChange({ ...data.hidden.value, [id]: true });
                        },
                    };
                },
                getDateProps() {
                    return dateProps;
                },
                getRouteProps(item) {
                    return {
                        control: control.value,
                        context: { ...context, data: homedata },
                        onToggle(visible) {
                            return visible
                                ? setHidden(
                                      hidden.filter((candidate) => candidate !== item.id)
                                  )
                                : setHidden([...hidden, item.id]);
                        },
                    };
                },
                getActionProps(item) {
                    return {
                        control: control.value,
                        context: { ...context, data: homedata },
                        onToggle(visible) {
                            return visible
                                ? setHidden(
                                      hidden.filter((candidate) => candidate !== item.id)
                                  )
                                : setHidden([...hidden, item.id]);
                        },
                    };
                },
                value: control.value,
                headerProps: {
                    children: workspace.name,
                    caption: workspace.url,
                },
                control: {
                    period: {
                        value: control.value.date.period,
                        options: config.config.options.period,
                    },
                    comparison: {
                        value: control.value.date.comparison,
                        options: config.config.options.comparison,
                    },
                    range: {
                        onChange([start, end]) {
                            control.set({
                                ...control.value,
                                date: {
                                    ...control.value.date,
                                    period: {
                                        start,
                                        end,
                                    },
                                },
                            });
                        },
                    },
                },
                getComparisonOptionProps(option) {
                    let expandedPeriod = periodToRange(
                        control.value.date.period,
                        option.value
                    );
                    return {
                        label: option.label,
                        value: option.value,
                        onClick() {
                            control.set({
                                ...control.value.date,
                                date: {
                                    ...control.value.date,
                                    comparison: option.value,
                                },
                            });
                        },
                        isSelected: isEqual(control.value.date.comparison, option.value),
                        caption: dateProps.getAbsoluteLabel(expandedPeriod, true),
                    };
                },
                getComparisonLabel() {
                    return (
                        props.control.comparison.options.find((item) =>
                            isEqual(props.control.comparison.value, item.value)
                        )?.label ?? 'N/A'
                    );
                },
            };
            return props;
        },
    };
}
