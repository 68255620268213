import moment from 'moment';
import { Duration, DateRange } from '../../domain';
import { DateFormatOptions, FormatOptions, Formatter } from './formatInterface';

const NOW = new Date();
const NOW_MOMENT = moment(NOW);

/**
 * Fractional currency values greater than this number gets treated as whole number
 */
const CURRENCY_SKIP_FRACTION_THRESHOLD = 1000;

export class FormatterImpl implements Formatter {
    currency(
        value: number,
        currency: string,
        { notation = 'long', ...options }: FormatOptions = {}
    ): string {
        let suffix: string | null = null;

        if (notation == 'short') {
            const [truncatedValue, truncatedSuffix] = nFormatter(value);
            suffix = truncatedSuffix;
            value = truncatedValue;
        }

        const integer = new Intl.NumberFormat('en-US', {
            style: 'currency',
            // notation: value > 1000 ? 'compact' : undefined,
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const fraction = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
        });
        const isInteger = value % 1 == 0;
        const isLarge = value >= CURRENCY_SKIP_FRACTION_THRESHOLD;
        const formatter = isLarge || isInteger ? integer : fraction;
        const formatted = formatter.format(value);
        return [formatted, suffix].filter(Boolean).join('');
    }
    percent(value: number, options?: { decimals?: number }): string {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            // minimumSignificantDigits: 2,
            // maximumSignificantDigits: options?.decimals ?? 2,
            maximumFractionDigits: options?.decimals ?? 2,
        });
        return formatter.format(value);
    }
    float(value: number): string {
        const formatter = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
        });
        return formatter.format(value);
    }
    daterange(
        value: DateRange,
        { notation = 'short', granularity, ...options }: DateFormatOptions = {}
    ): string {
        const includeYear =
            value.start.getFullYear() !== NOW.getFullYear() ||
            value.end.getFullYear() !== NOW.getFullYear();

        const formatter = new Intl.DateTimeFormat('en', {
            timeZone: options.timezone,
            day: granularity === 'month' ? undefined : 'numeric',
            month: granularity === 'month' || notation === 'long' ? 'long' : 'short',
            // year: notation === 'long' ? 'numeric' : undefined,
            year: includeYear ? 'numeric' : undefined,
            weekday: notation === 'long' && granularity === 'day' ? 'short' : undefined,
            // year: 'numeric',
            // month: 'short',
            // day: '2-digit',
        });

        // we need to update typescript to get typing for this new api
        try {
            return formatter.formatRange(value.start, value.end);
        } catch (error) {
            console.error(`failed to foramt date range ${JSON.stringify(value)}`);
            throw error;
        }
    }
    duration(value: Duration): string {
        if (value.amount === 1) {
            return value.interval;
        }
        return `${value.amount} ${value.interval}s`;
    }
    date(
        value: string | Date,
        { notation = 'short', granularity, ...options }: DateFormatOptions = {}
    ): string {
        const normal = typeof value === 'string' ? new Date(value) : value;
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: options.timezone,
            day: granularity === 'month' ? undefined : 'numeric',
            month: granularity === 'month' ? 'short' : 'short',
            year: notation === 'long' ? 'numeric' : undefined,
            weekday: notation === 'long' && granularity === 'day' ? 'short' : undefined,
        });
        // console.log('formatting', value);
        // console.log('result', formatter.format(normal));
        return formatter.format(normal);
    }
    time(
        value: string | Date,
        { notation = 'short', granularity, ...options }: DateFormatOptions = {}
    ): string {
        const normal = typeof value === 'string' ? new Date(value) : value;
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: options.timezone,
            hour: 'numeric',
            minute: 'numeric',
        });
        return formatter.format(normal);
    }
    timeago(value: Date, options?: DateFormatOptions | undefined): string {
        if (options?.granularity === 'day') {
            const diffHours = NOW_MOMENT.diff(value, 'hour');
            if (diffHours <= 24) {
                return 'today';
            }
        }
        return moment.utc(value).fromNow(options?.notation == 'short' ? true : false);
    }
}

function nFormatter(num: number) {
    if (num >= 1000000000) {
        return [Number((num / 1000000000).toFixed(1).replace(/\.0$/, '')), 'G'] as const;
    }
    if (num >= 1000000) {
        return [Number((num / 1000000).toFixed(1).replace(/\.0$/, '')), 'M'] as const;
    }
    if (num >= 1000) {
        return [Number((num / 1000).toFixed(1).replace(/\.0$/, '')), 'K'] as const;
    }
    return [num, null] as const;
}
