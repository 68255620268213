import { TrackingAdapter } from '../../../strategy';
import { DemoSegmentTrackingConfig } from './segmentTrackerConfig';

export function createDemoSegmentTrackerImpl(
    config: DemoSegmentTrackingConfig
): TrackingAdapter {
    return {
        track(event, payload: unknown) {
            try {
                if (config.logging) {
                    console.info('[Segment] event type!', event, payload);
                }
                if (config.debug) {
                    console.info('[Segment] debug mode enabled, skipping event...');
                    return;
                }
                config.analytics.track(event, payload);
            } catch (error) {
                console.error(
                    `failed to track event '${event}' with payload ${JSON.stringify(
                        payload
                    )}`
                );
            }
        },
    };
}
