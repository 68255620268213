import { chain } from 'lodash';
import { useMemo } from 'react';
import { MetricCollectionNewController } from './collectionNewInterface';
import { MetricCollectionNewViewProps } from './collectionNewProps';
import { buildCollectionUpsertPayload } from './collectonNewFactory';
import { getCollectionNewItemProps, MetricCollectionNewItemProps } from './item';

export function createMetricCollectionNewController(): MetricCollectionNewController {
    return {
        useProps(context, data, form): MetricCollectionNewViewProps {
            const items = data.queries.metrics.data ?? [];
            const formValues = form.watch();

            const itemPropsByMetric = useMemo(() => {
                return items.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.metric.id]: getCollectionNewItemProps(
                            form,
                            formValues,
                            item
                        ),
                    }),
                    {} as Record<string, MetricCollectionNewItemProps>
                );
            }, [form, formValues]);

            const filtered = useMemo(() => {
                const term = formValues.search.toLowerCase();
                return chain(items)
                    .filter(
                        (item) =>
                            item.metric.name.toLowerCase().includes(term) ||
                            item.metric.description?.toLowerCase().includes(term) ||
                            item.plugin.name?.toLowerCase().includes(term)
                    )
                    .orderBy((item) => {
                        const itemProps = itemPropsByMetric[item.metric.id];
                        return itemProps.isSelectable ? 1 : -1;
                    }, 'desc')
                    .value();
            }, [formValues.search, items]);

            const selected = useMemo(
                () => new Set(Object.keys(formValues.selected)),
                [formValues.selected]
            );

            const status = useMemo(() => {
                if (data.status !== 'success') {
                    return 'loading';
                }
                if (items.length === 0) {
                    return 'empty';
                }
                return 'ready';
            }, [items, data.status]);

            return {
                items,
                getStatusProps() {
                    if (status === 'empty') {
                        return {
                            status: status,
                            title: 'Integrations not yet ready',
                            description: null,
                        };
                    }
                    return { status };
                },
                getFormProps() {
                    return {
                        isSubmitting: form.formState.isSubmitting,
                        isSubmittable: selected.size > 0 && data.status === 'success',
                        async onSubmit(values) {
                            return data.mutations.upsert.mutateAsync(
                                buildCollectionUpsertPayload(
                                    context,
                                    items,
                                    selected,
                                    data.queries.collection.default.data ?? null
                                )
                            );
                        },
                    };
                },
                getSearchProps() {
                    return form.register('search');
                },
                getFilteredItems() {
                    return filtered;
                },
                getSelectionProps() {
                    const showClear = selected.size > 0;
                    const label = showClear
                        ? `${selected.size} metrics selected`
                        : `No metrics selected`;

                    return {
                        label,
                        showClear,
                        onClear() {
                            form.setValue('selected', {});
                        },
                    };
                },
                getItemProps(item) {
                    const itemProps = itemPropsByMetric[item.metric.id];
                    return itemProps;
                },
            };
        },
    };
}
