import { AnyQuerySegment } from '../../../../api';
import { GREEN, ME_COLOR, RED, TEAL } from './colorConstant';

export function getSegmentColor(segment: AnyQuerySegment) {
    if (segment.kind === 'asset') {
        return ME_COLOR;
    }
    if (segment.name.toLowerCase().includes('low')) {
        return RED;
    }
    if (segment.name.toLowerCase().includes('median')) {
        return TEAL;
    }
    if (segment.name.toLowerCase().includes('peer group')) {
        return TEAL;
    }
    if (segment.name.toLowerCase().includes('high')) {
        return GREEN;
    }
    console.warn(`unmapped color for segment '${segment.name}'`);
    return TEAL;
}

const COLOR_BY_ORDER = [GREEN, RED, TEAL]

export function getColorForIndex(idx: number) {
    return COLOR_BY_ORDER[idx] || 'gray'
}