import {
    IntegrationDefinition,
    IntegrationDefinitionStatus,
} from '../../../../domain/assets';

export function getCollectionStatus(props: {
    definitions: Array<IntegrationDefinition & { status: IntegrationDefinitionStatus }>;
}): {
    text: string;
    isLoading: boolean;
} | null {
    const expedited = props.definitions.filter((item) => item.expedited);
    const expeditedIsSyncing = expedited.some((item) => item.status === 'analyzing');

    if (props.definitions.every((item) => item.status === 'ready')) {
        return null;
    }

    if (expedited.length > 0 && expeditedIsSyncing) {
        return {
            isLoading: true,
            text: 'We are analyzing your integrations (estimate up to 10 minutes)',
        };
    }

    if (expedited.length > 0 && !expeditedIsSyncing) {
        return null;
    }
    // const showStatus = !hasExpedited && !hasAtleastOneMappable;

    return {
        isLoading: false,
        text: 'We are syncing your data (estimate up to 3-4 days)',
    };
}
