import { useMatch, useLocation, useNavigate } from 'react-router';
import { chain, partition } from 'lodash';
import { useDisclosure } from '@chakra-ui/hooks';
import { useUserApi } from '../../../../app';
import { WorkspaceSelectionController } from './workspaceSelectionInterface';
import { WorkspaceSelectionConfig } from './workspaceSelectionConfig';
import React, { useMemo, useState } from 'react';

export function createWorkspaceSelectionController(
    config: WorkspaceSelectionConfig['deps']
): WorkspaceSelectionController {
    const {
        repository: { organization: orgRepository },
        hook: { useQuery },
        // api: {
        //     platform: {
        //         organizations: { get: getOrganization },
        //     },
        // },
    } = config;
    return {
        useProps(context) {
            const location = useLocation();
            const navigate = useNavigate();
            const disclosure = useDisclosure();
            const match = useMatch('/u/assets/:assetId');
            const user = useUserApi();
            const current = user.getCurrentWorkspace();
            const workspaces = user.getWorkspaces();

            const visibleWorkspaces = useMemo(
                () => workspaces.filter((item) => item.visible),
                [workspaces]
            );

            const organizationQueryV2 = orgRepository.useLookup(
                context,
                { id: context.organization.id },
                { suspense: true }
            );

            // const organizationQuery = useQuery({
            //     // TODO this query should be invalidated in accordance with the asset default view
            //     queryKey: ['organization', context.organization.id],
            //     refetchOnWindowFocus: true,
            //     staleTime: Infinity,
            //     async queryFn() {
            //         const response = await getOrganization(
            //             context,
            //             context.organization.id
            //         );
            //         return response;
            //     },
            //     suspense: true,
            // });
            const [searchTerm, setSearchTerm] = useState<string>();

            // console.log('organizationQuery', organizationQuery.data);
            // console.log('organizationQueryV2', organizationQueryV2.data);

            const [defaults, rest] = useMemo(
                () =>
                    chain(visibleWorkspaces)
                        .partition(
                            (workspace) =>
                                workspace.id ===
                                organizationQueryV2.data?.defaultAsset?.id
                        )
                        .value(),
                [visibleWorkspaces, organizationQueryV2.data?.defaultAsset?.id]
            );

            const [filteredWorkspaces] = React.useMemo(() => {
                const base = [...defaults, ...rest];
                if (searchTerm) {
                    const termLower = searchTerm.toLowerCase();
                    return [
                        base.filter(
                            (x) =>
                                current?.id == x.id ||
                                x.name.toLowerCase().startsWith(termLower)
                        ),
                    ];
                }
                return [base, base];
            }, [searchTerm, defaults, rest, current]);

            const selectedIndex = filteredWorkspaces.findIndex(
                (candidate) => candidate.id === current?.id
            );

            return {
                ...disclosure,
                selectedIndex,
                workspaces: filteredWorkspaces,
                searchTerm,
                setSearchTerm,
                getAddButtonProps() {
                    return {
                        // onClick() {
                        //     window.open(config.addBrandUrl, '_blank');
                        // },
                    };
                },
                getPopoverProps() {
                    return {
                        onOpen: disclosure.onOpen,
                        isOpen: disclosure.isOpen,
                        onClose: disclosure.onClose,
                    };
                },
                getItemProps(workspace) {
                    // TODO come up with proper routing abstraction
                    const path = location.pathname.replace(
                        /\/assets\/[A-Za-z-_0-9]+\//,
                        `/assets/${workspace.id}/`
                    );
                    return {
                        onClick() {
                            disclosure.onClose();
                            navigate(path);
                        },
                    };
                },
            };
        },
    };
}
