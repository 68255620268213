import { useQueries } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { PluginSlugs } from '../../../config';
import { toDomainPlugin } from '../../impl/platform';
import {
    PluginConnection,
    ConnectionDependency,
    MetricDefinition,
} from '../../domain/metrics';
import { castTypeToDomain } from '../../impl';
import { isReferenceType, resolveProperties } from '../../domain/attributes';
import { WorkspaceContextDeps } from './workspaceConfig';
import { WorkspaceContextLoader } from './workspaceInterface';
import { WorkspaceContextResult } from './workspaceModel';
import { chain } from 'lodash';

export function createWorkspaceContextLoader(
    deps: WorkspaceContextDeps
): WorkspaceContextLoader {
    const {
        api: {
            metric: {
                definitions: { list: listMetricDefinitions },
                collections: { list: listMetricCollections },
            },
            // dashboard: {
            //     plugins: { list: listPlugins },
            // },
        },
        adapter: {
            dashboard: { listDashboards },
            ...adapter
        },
        repository,
    } = deps;
    return {
        useData(context): WorkspaceContextResult {
            const dynamicQueries = Object.entries(deps.queries).map(([name, value]) => ({
                name,
                fn: value,
            }));

            const queryKeys = {
                collections: [
                    'workspaces',
                    'collections',
                    context.workspace.id,
                    context.organization.id,
                ],
            };
            const features = repository.feature.useLookup(context, {
                staleTime: Infinity,
            });
            const [poll, setPoll] = useState<number | null>(null);

            const responsePlugins = repository.platform.plugin.useFind(context, {}, {});
            const responseConnections = repository.platform.connection.useFind(
                context,
                {
                    workspaces: [context.workspace],
                },
                {
                    keepPreviousData: true,
                    refetchInterval: poll ?? undefined,
                    staleTime: Infinity,
                }
            );

            const responseMetricCollections = repository.metric.collection.useFind(
                context,
                {
                    asset: context.workspace.id as number,
                }
            );

            const responseMetricDefinitions = repository.metric.definition.useFind(
                context,
                {
                    plugins: responsePlugins.data ?? [],
                }
            );

            const responseDashboards = repository.metric.dashboard.useFind(context, {});

            const responsePeergroups = repository.peers.group.useFind(context, {
                workspace: context.workspace,
                plugins: responsePlugins.data?.map((item) => item.id) ?? [],
            });

            const types = useMemo(
                () =>
                    chain(responsePlugins.data)
                        .flatMap((plugin) => plugin.traits.map((trait) => trait.type))
                        .filter(isReferenceType)
                        .uniqBy((item) => item.id)
                        .value(),
                [responsePlugins.data]
            );

            const responseMembers = repository.attributes.member.useFind(
                context,
                {
                    workspace: context.workspace,
                    types,
                },
                { staleTime: Infinity }
            );

            const mergedQueryResponse = [
                responseDashboards,
                responseMetricDefinitions,
                responsePlugins,
                responsePeergroups,
                responseMembers,
            ];

            const firstError = mergedQueryResponse.find((q) => q.isError);
            if (firstError) {
                throw firstError;
            }

            useEffect(() => {
                if (
                    responseConnections.data?.some(
                        (item) =>
                            item.status === 'syncing' &&
                            // HACK we should model the "fasy-sync" capability on the plugin like we
                            // do on the integration definition model
                            item.plugin.id === PluginSlugs.FACEBOOKADS
                    )
                ) {
                    console.log(
                        'fast-sync enabled integrations syncing, enabling polling...'
                    );
                    setPoll(15000);
                } else if (
                    responseConnections.data?.some(
                        (item) =>
                            item.status === 'syncing' &&
                            // HACK media mix dependencies are messed up for some reason so
                            // we ignore it
                            item.plugin.id !== PluginSlugs.MEDIA_MIX
                    )
                ) {
                    console.log('integrations syncing, enabling polling...');
                    setPoll(
                        // refetch every 5 minutes
                        60000 * 5
                    );
                } else {
                    setPoll(null);
                }
                return;
            }, [responseConnections.data]);

            const dynamicQueriesResult = useQueries({
                queries: dynamicQueries.map((query) => query.fn(context)),
            });

            const dynamicFirstError = dynamicQueriesResult.find((q) => q.isError);

            if (dynamicFirstError) {
                throw dynamicFirstError;
            }
            const customQueriesByName = dynamicQueriesResult.reduce(
                (acc, query, index) => ({
                    ...acc,
                    [dynamicQueries[index].name]: query,
                }),
                {}
            );

            // console.log('mergedQueryResponse', mergedQueryResponse);

            const mergedStatus = useMemo(
                () => ({
                    isLoaded: [...mergedQueryResponse, ...dynamicQueriesResult].every(
                        (item) => item.isLoading
                    ),
                    isSuccess: [...mergedQueryResponse, ...dynamicQueriesResult].every(
                        (item) => item.isSuccess
                    ),
                }),
                [mergedQueryResponse, dynamicQueriesResult]
            );

            const resolvedPlugins = useMemo(
                () =>
                    responsePlugins.data?.map((plugin) => {
                        return {
                            ...plugin,
                            traits: resolveProperties(
                                responseMembers.data ?? [],
                                plugin.traits
                            ),
                        };
                    }),
                [responsePlugins.data, responseMembers.data]
            );

            return {
                ...mergedStatus,
                data: {
                    context,
                    ...customQueriesByName,
                    peergroups: responsePeergroups,
                    dashboards: responseDashboards,
                    connections: responseConnections,
                    features: features,
                    // @ts-expect-error
                    plugins: {
                        ...responsePlugins,
                        data: resolvedPlugins,
                    },
                    metric: {
                        collections: {
                            ...responseMetricCollections,
                            queryKey: queryKeys.collections,
                        },
                        definitions: responseMetricDefinitions,
                    },
                },
            };
        },
    };
}
