import { isEqual } from 'lodash';
import { MappingConnectionBulkItem, MappingConnectionBulk } from '../../../app/assets';
import { OrganizationMappingAggregate } from './mappingModel';

export function buildConnectionBulk(
    current: OrganizationMappingAggregate | null,
    updated: OrganizationMappingAggregate
): MappingConnectionBulk {
    // console.log('before', current);
    // console.log('after', updated);
    return {
        items: [
            ...(current?.mappings
                .filter(
                    (candidateCurrent) =>
                        !updated?.mappings.some((candidateUpdate) =>
                            isEqual(candidateUpdate, candidateCurrent)
                        )
                )
                .flatMap((item): MappingConnectionBulkItem[] => {
                    if (!item.id) {
                        console.warn(`item ${JSON.stringify(item)} does not have ID`);
                        return [];
                    }
                    return [
                        {
                            kind: 'remove',
                            item: {
                                id: item.id,
                            },
                        },
                    ];
                }) ?? []),
            ...updated.mappings
                .filter(
                    (candidateUpdate) =>
                        !current?.mappings.some((candidateCurrent) =>
                            isEqual(candidateUpdate, candidateCurrent)
                        )
                )
                .map(
                    (item): MappingConnectionBulkItem => ({
                        kind: 'create',
                        item,
                    })
                ),
        ],
    };
}
