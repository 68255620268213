import { Text, Box, BoxProps, HStack, Tooltip, VStack } from '@chakra-ui/react';
import { chain, first, last, max, min } from 'lodash';
import React from 'react';
import {
    applyToBucket,
    normalize,
    normalizeBuckets,
    Scale,
} from '../../../domain/visualization';
import { PercentileRankConfig } from './percentileRankConfig';
import { Bucket, PercentileRankProps, Tick } from './percentileRankProps';

export function createPercentileRank(
    config: PercentileRankConfig
): React.FC<{ children?: React.ReactNode | undefined } & PercentileRankProps> {
    return (props) => {
        const { value, ticks, buckets } = props;

        const scale: Scale = {
            points: ticks.flatMap((item) => (item.value === null ? [] : [item.value])),
        };

        // const pointsNormalized = ticks.map((item) => normalize(scale, item.value, false));

        // const minX = first(buckets)?.lower!;
        // const maxX = last(buckets)?.upper!;

        let normalValue = value === null ? null : normalize(scale, value);

        let inversedValue =
            normalValue === null ? null : props.inverse ? 100 - normalValue : normalValue;

        // const clampedvalue = Math.max(minX, Math.min(normalValue, maxX));

        // const bucketScale: Scale = {
        //     points: chain(buckets)
        //         .flatMap((bucket) => [bucket.lower, bucket.upper])
        //         .uniq()
        //         .orderBy((item) => item, 'asc')
        //         .value(),
        // };

        // console.log('bucketScale', bucketScale);

        // console.log('buckets', buckets, scale);
        // const scalebuckets = buckets;

        const normalBuckets = normalizeBuckets(buckets);
        // console.log('normalBuckets', normalBuckets);

        // const scalebuckets = buckets.map((item, index, array) => ({
        //     ...item,
        //     lower: item.threshold,
        //     upper: array[index + 1]?.threshold ?? 100,
        // }));

        // console.log('nornal bucket', normalBuckets);

        let currentBucket =
            normalValue === null ? null : applyToBucket(normalBuckets, normalValue);
        // if (!currentBucket && normalValue === 0) {
        //     currentBucket =
        //         (props.inverse ? last(normalBuckets) : first(normalBuckets)) ?? null;
        //     normalValue = props.inverse ? 100 : 0;
        // }
        // if (!currentBucket && normalValue === 100) {
        //     currentBucket =
        //         (props.inverse ? first(normalBuckets) : last(normalBuckets)) ?? null;
        //     normalValue = props.inverse ? 0 : 100;
        // }

        // console.log('BUCKET', value, normalValue, currentBucket, normalBuckets);
        const segmentColor = currentBucket?.color ?? null;
        if (!segmentColor) {
        }
        // console.log('normalValue', props.inverse, value, normalValue);
        // console.log('currentBucket', currentBucket);

        // const flexValue = 100 / buckets.length;

        return (
            <VStack minW="12rem" w="full">
                <HStack lineHeight={1} w="full" spacing={0}>
                    {ticks
                        .filter((tick) => tick.label)
                        .map((item, index, array) => {
                            let align: BoxProps['textAlign'] = 'center';
                            if (index === 0) {
                                align = 'start';
                            }
                            if (index === array.length - 1) {
                                align = 'end';
                            }
                            return (
                                <Box
                                    key={item.value}
                                    flexShrink={0}
                                    flex={1}
                                    textAlign={align}
                                >
                                    {!item.hidden && (
                                        <Text
                                            color="whiteAlpha.700"
                                            fontSize="sm"
                                            fontWeight="medium"
                                        >
                                            {item.label}
                                        </Text>
                                    )}
                                </Box>
                            );
                        })}
                </HStack>
                <Box w="full" display="flex" alignItems="center" width="100%">
                    {/* Main Bar */}
                    <Box display="flex" flex="1" height={2} position="relative">
                        {buckets.map((bucket, index, array) => {
                            const flexValue = bucket.upper - bucket.lower;
                            // console.log('flexValue', flexValue, bucket);
                            // const flexValue =
                            //     index === 0
                            //         ? array[index + 1].threshold
                            //         : bucket.threshold - array[index - 1].threshold;

                            const style: BoxProps = {};
                            if (index === 0) {
                                style['borderLeftRadius'] = 'full';
                            }
                            if (array.length === index + 1) {
                                style['borderRightRadius'] = 'full';
                            }

                            // console.log('bucket', bucket, index, flexValue, style);
                            return (
                                <Box
                                    {...style}
                                    key={index}
                                    bgColor={bucket.color}
                                    flex={flexValue}
                                    height={2}
                                />
                            );
                        })}

                        {/* Slider */}
                        {inversedValue !== null && (
                            <Tooltip
                                label={value}
                                isDisabled={true}
                                placement="top"
                                hasArrow={true}
                                openDelay={0}
                            >
                                <Box
                                    // bgColor={sliderColor ?? segmentColor ?? undefined}
                                    bgColor={segmentColor ?? undefined}
                                    borderWidth={3}
                                    borderStyle="solid"
                                    borderColor="black"
                                    height={6}
                                    width={3}
                                    borderTopRadius="full"
                                    borderBottomRadius="full"
                                    position="absolute"
                                    top="50%"
                                    left={`${inversedValue}%`}
                                    transform="translate(-50%, -50%)"
                                />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            </VStack>
        );
    };
}
