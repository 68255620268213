import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GroupEditFormValues } from '../../../../view/peer';
import { getAppliedConditions } from '../../../../domain/peers';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { PluginItemLoader } from './pluginInterface';
import { PluginItemData } from './pluginModel';

export function createPluginItemLoader(
    config: OnboardingPeerGroupContainerConfig
): PluginItemLoader {
    const { repository } = config;
    return {
        useData(context, props): PluginItemData {
            const [initialLoad, setInitialLoad] = useState(false);

            const form = {
                group: useForm<GroupEditFormValues>({
                    defaultValues: {
                        conditions: getAppliedConditions(
                            props.item.plugin,
                            props.item.peergroup
                        ),
                    },
                    reValidateMode: 'onChange',
                }),
            };

            const formValues = form.group.watch();

            const query = {
                competitive: repository.competitive.useLookup(
                    context,
                    {
                        plugin: props.item.plugin,
                        conditions: formValues.conditions,
                    },
                    {
                        // enabled: formValues.conditions.length > 0,
                        suspense: !initialLoad,
                        staleTime: Infinity,
                        retry: false,
                        keepPreviousData: true,
                    }
                ),
            };

            const mutation = {
                updatePeerGroup: repository.peergroups.useUpsert(context),
            };

            useEffect(() => {
                setInitialLoad(true);
            });

            return {
                form,
                query,
                mutation,
            };
        },
    };
}
