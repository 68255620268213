import React from 'react';
import { Box, PopoverBody, PopoverContent, useColorModeValue } from '@chakra-ui/react';
import { MenuDropdownProps } from './MenuDropdownProps';

export const MenuDropdown: React.FC<MenuDropdownProps> = ({ ...props }) => {
    const bgColor = useColorModeValue('white', '#292929');
    return (
        <Box zIndex="popover">
            <PopoverContent
                bg={bgColor}
                _focus={{ outline: 'none' }}
                maxW={props.maxW}
                width="auto"
            >
                <PopoverBody p={0}>{props.children}</PopoverBody>
            </PopoverContent>
        </Box>
    );
};
