import { createOrganizationProvider } from '../../app';
import { createLegacyOrganizationImpl, createChartApiImpl } from '../../impl';

export function configureLegacyOrganizationProvider() {
    return createOrganizationProvider({
        getDeps: (config) => {
            return {
                organization: createLegacyOrganizationImpl({
                    organization: config.organization,
                }),
                chart: createChartApiImpl({
                    axios: config.api,
                }),
            };
        },
    });
}
