import { chain, uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { PeerLoaderConfig } from '../../base';
import { isCompanyInvitation } from '../../../../domain/accounts';
import { PeerMemberEditLoader } from './peerMemberEditInterface';
import { PeerMemberEditAggregate } from './peerMemberEditModel';

export function createPeerMemberEditLoader(
    config: PeerLoaderConfig
): PeerMemberEditLoader {
    const {
        repository: {
            company: companyRepository,
            peerset: peerSetRepository,
            asset: assetRepository,
            invitation: invitationRepository,
        },
        macro: {
            company: {
                select: { loader: companyLoader },
            },
        },
    } = config;
    return {
        useLoad(context, props) {
            const assetQuery = assetRepository.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );

            const invitationQuery = invitationRepository.useFind(
                context,
                {
                    source: [
                        {
                            kind: 'group',
                            id: props.peerset.id,
                        },
                    ],
                },
                {
                    suspense: true,
                }
            );

            const peerSetQuery = peerSetRepository.useLookup(context, props.peerset, {
                suspense: true,
            });

            assert(peerSetQuery.status === 'success', 'expected suspense');
            assert(assetQuery.status === 'success', 'expected suspense');
            assert(invitationQuery.status === 'success', 'expected suspese');

            const invitedCompanyIds = useMemo(
                () =>
                    invitationQuery.data.flatMap((item) =>
                        isCompanyInvitation(item) ? [item.company.id] : []
                    ),
                [invitationQuery.data]
            );

            const groupCompanyLookupQuery = companyRepository.useFind(
                context,
                {
                    assets: peerSetQuery.data.members.map((item) => item.asset.id),
                },
                { suspense: true }
            );

            assert(groupCompanyLookupQuery.status === 'success', 'expected suspese');

            const initialLoadedCompanyIds = useMemo(() => {
                return uniq([...groupCompanyLookupQuery.data.ids, ...invitedCompanyIds]);
            }, []);

            const initiallySelectedCompanies = companyRepository.useLookupCache(
                context,
                initialLoadedCompanyIds.map((item) => ({ id: item }))
            );

            useEffect(() => {
                props.form.setValue('companies', initialLoadedCompanyIds);
                return;
            }, []);

            const companyData = companyLoader.useLoad(context, {
                form: props.form,
            });

            const aggregate = useMemo((): PeerMemberEditAggregate => {
                return {
                    peerset: peerSetQuery.data,
                    company: companyData,
                    invitations: invitationQuery.data.filter(isCompanyInvitation),
                    initial: [
                        ...companyData.company.items,
                        ...initiallySelectedCompanies.map((company) => {
                            const [assetId] = company.profiles.flatMap((profile) =>
                                profile.kind == 'varos' ? [Number(profile.id)] : []
                            );
                            return {
                                company,
                                asset: assetId
                                    ? {
                                          id: assetId,
                                      }
                                    : null,
                            };
                        }),
                    ],
                };
            }, [peerSetQuery.data, invitationQuery.data, companyData]);

            return aggregate;
        },
    };
}
