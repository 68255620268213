import { DatasetActiveIssue, IntegrationActiveIssue } from '../../api/account-status';

export const IntegrationIssueMessages: Record<IntegrationActiveIssue['reason'], string> = {
    broken: 'Our platform fails to sync data from your integration. please contact us for more detail',
    resource_monitor:
        'We stopped syncing data from one of your integrations because it exceeds our maximum storage capacity',
    discrepancy: "We detected issues to sync data from one or more of these integrations. we're working on a fix!",
    inactive_mappable:
        'The source data for this dashboard appear to be no longer active. please re-integrate your data.',
};

export const DatasetIssueMessage =
    'We are currently experiencing data sync issues as some of our services. We`re working on a fix';
export const DefaultIssueMessage =
    "We're experiencing issues syncing data for this dashboard. please contact us for additional information";

export const InFlightMessage = 'Your data is currently syncing to this dashboard.';
