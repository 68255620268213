import {
    AnyMappableEntity,
    Asset,
    Integration,
    MappingConnection,
} from '../../../../domain/assets';
import { OrganizationMappingAggregate } from '../../../../service/assets';
import { OrganizationMappingFormValues } from './assetMappingModel';

export function buildConnections(
    item: OrganizationMappingAggregate | null,
    entitiesByKey: Record<string, AnyMappableEntity | undefined>,
    integrationById: Record<string, Integration | undefined>,
    asset: Pick<Asset, 'id'>,
    formValues: OrganizationMappingFormValues
): MappingConnection[] {
    return [
        ...(item?.mappings.filter((item) => item.asset.id !== asset.id) ?? []),
        ...(item?.definitions.flatMap((definition): MappingConnection[] => {
            const mapping = formValues.entityByDefinition[definition.id];
            if (mapping === null) {
                return [];
            }
            if (mapping === undefined) {
                return item.mappings.flatMap((candidate) => {
                    const entity = entitiesByKey[candidate.entity.key];
                    if (!entity) {
                        // console.warn(
                        //     `entity ${candidate.entity.key} not found`,
                        //     entitiesByKey
                        // );
                        return [];
                    }
                    const integration = integrationById[entity.integrationId];
                    if (!integration) {
                        console.warn(
                            `integration ${entity.integrationId} not found`,
                            integrationById
                        );
                        return [];
                    }
                    return candidate.asset.id === asset.id &&
                        integration.definitionId === definition.id &&
                        entity.integrationId === candidate.integration.id
                        ? [candidate]
                        : [];
                });
            }
            const entity = entitiesByKey[mapping.key];
            if (!entity) {
                console.warn(`entity ${mapping.key} not found`, entitiesByKey);
                return [];
            }
            return [
                {
                    id: mapping.id,
                    asset: {
                        id: asset.id,
                    },
                    entity: {
                        key: mapping.key,
                        type: entity.type,
                    },
                    integration: {
                        id: entity.integrationId,
                    },
                    // HACK fixed
                    createdAt: new Date(),
                },
            ];
        }) ?? []),
    ];
}
