import { FEATURES_BY_PLAN } from '../../../../../config';
import { PlanId } from '../../../../../api';
import { AnyBillingPlan, BillingUsagePlan } from '../../../../domain/billing';
import { PlanAdapter } from '../../../../app/billing';
import { BillingPlanDto } from '../../../../api/billing/plan';
import { PlanApiAdapterConfig } from './planApiConfig';

export function createSubscriptionPlanImpl(config: PlanApiAdapterConfig): PlanAdapter {
    const {
        api: { billing: api },
    } = config;

    function toEntity(dto: BillingPlanDto): AnyBillingPlan {
        const features =
            FEATURES_BY_PLAN[dto.id as PlanId]?.map((feature) => ({
                id: 'feature',
                name: feature.text,
                description: feature.caption ?? null,
                enabled: true,
            })) ?? [];
        if (dto.kind === 'usage') {
            return {
                ...dto,
                features,
            };
        }
        return {
            ...dto,
            features,
        };
    }

    return {
        async find(context, query) {
            const response = await api.plan.find(context, {
                version: query.version,
            });

            return response.data.map(toEntity);
        },
    };
}
