import {
    QueryClient,
    DefinedUseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useStore } from '../../../stores/setupContext';
import { Organization } from '../../../models/Account';
import { SubscriptionDto } from '../../../api';
import { ApplicationContext } from '../../../base';
import { PricingPlan } from '../plan';
import { createSubscriptonMutation } from './subscriptionMutation';
import { createGetSubscriptionHandler } from './subscriptionQuery';
import React from 'react';

export function getSubscriptionQueryKey(
    context: Pick<ApplicationContext, 'api' | 'organization'>
) {
    return [
        'subscription',
        context.organization?.id,
        context.organization?.finished_on_boarding,
    ];
}

export function resetSubscriptionCache(
    context: Pick<ApplicationContext, 'api' | 'organization'>,
    client: QueryClient
) {
    const key = getSubscriptionQueryKey(context);
    client.resetQueries(key);
}

// const ROLLOUT_RATIO = process.env.VAROS_ENV !== 'production' ? 1.0 : 0.4;
const ROLLOUT_RATIO = 1;

// normal distribution and seeded random number generator
function random(seed: number) {
    var x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
}

let autosubscribed = false;

function shouldBackfill(organization: Organization) {
    if (!organization.id) {
        return false;
    }
    if (!organization.finished_on_boarding) {
        // only backfill existing users
        return false;
    }
    const randomValue = random(organization.id);
    if (randomValue <= ROLLOUT_RATIO) {
        return true;
    }
    return false;
}

export const useSubscription = (
    context: Pick<ApplicationContext, 'api' | 'organization'>
) => {
    const { auth } = useStore();
    const client = useQueryClient();
    const actions = useSubscriptionActions(context);
    const handler = createGetSubscriptionHandler(context);
    const queryKey = getSubscriptionQueryKey(context);
    // console.log('sub queryKey', queryKey);
    const query = useQuery(queryKey, handler, {
        suspense: true,

        // refetchOnWindowFocus: 'always',
        // staleTime: 60000,
        // staleTime: Infinity,
        // there is an issue with a lot of refetches on V2
        ...(window.location.href.includes('v2/assets')
            ? {
                  staleTime: Infinity,
              }
            : { refetchOnWindowFocus: 'always', staleTime: 60000 }),
        async onSuccess(data) {
            // temporary hack to backfill subscriptions on free plan
            if (
                !data.active &&
                !autosubscribed &&
                context.organization &&
                shouldBackfill(context.organization)
            ) {
                autosubscribed = true;
                console.info('automatically subscribing to free plan');
                const free = await actions.create({
                    id: 'free',
                });
                console.info('succesfully subscribed to free plan');
                client.setQueryData(queryKey, () => free);

                // we want to refetch the account/onboarding status as well as it depends on the
                // current subscription and it has not yet been integrated with RQ
                console.log('getting sub acc');
                await auth.getMyAccount();
            }
            return;
        },
    });

    React.useEffect(() => {
        return () => {
            client.cancelQueries(queryKey);
        };
    }, []);
    return query.data;
};

export const useSubscriptionActions = (
    context: Pick<ApplicationContext, 'api' | 'organization'>
) => {
    const createHandler = createSubscriptonMutation(context);
    const createMutation = useMutation(createHandler);
    const idempotencyKey = Date.now().toString();

    const create = async (plan: Pick<PricingPlan, 'id'>) => {
        return createMutation.mutateAsync({
            payload: { plan: plan.id },
            options: { wait: 15000, idempotency_key: idempotencyKey },
        });
    };

    return { create };
};
