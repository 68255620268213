import React, { useMemo } from 'react';
import { Dashboard } from '../../api';
import { DEFAULT_PIXEL_RATIO, PixelRatio } from '../../models/Card';
import { KeyValRequest } from '../../models/DataSet';
import { useRect } from '../../utils/hooks';
import DashboardParamsForm, { DashboardParamsFormProps } from './DashboardParamsForm';
import { ErrorInfo } from '../../models/Errors';
import {
    MultiFilterRule,
    MetadataRule,
    Transparancy,
    ChangedItem,
} from '../../models/Metadata';
import varosIconSVG from '../../svg/varos-logo-small-white.svg';
import { AssetContext, DashboardProps, useExports, DataFiltersProps } from '../../hooks';
import { Card as CardV2, isAggregatedLineVisualization } from '../../api/v2';
import { Button } from '../../ui';
import {
    CardGrid,
    CardGridView,
    CardGridViewProps,
    CardItem,
    daterangeSubtract,
    durationToRange,
    isAbsolutePeriod,
    isRelativeDuration,
    Period,
    DependentPeriod,
    useCardGrid,
} from '../../domain';
import {
    Box,
    Flex,
    HStack,
    Text,
    VStack,
    useColorModeValue,
    Tooltip,
    Image,
    propNames,
} from '@chakra-ui/react';
import { useDashboardExport, DashboardContext } from '../../hooks';
import DashboardOptions from './DashboardOptions';
import { AnalyticsEventFunc } from '../../models/Analytics';
import EvalResponseView from './EvalResponseView';
import { TypeFiltersContainer } from '../AddDatasetQueryFilterForm/TypeFIlters/TypeFiltersContainer';
import { useDashboardCardContext } from './card';
import {
    BarVisualization,
    LineVisualization,
    StatVisualization,
    TableVisualization,
    TableVisualizationProps,
    VisualizationContainer,
    VisualizationContainerProps,
} from '../../strategy';
import { useFeature } from '../../domain';
import { useAccess, useDynamicAccess } from '../../container';
import { ParamField } from './DashboardParamField';
import { VarosIconButton } from '../../ui';
import { DateFilterProps } from '../../ui/datefilter/component/DateFilter';
import { DateFilterFieldsV2 } from '../AddDatasetQueryFilterForm/TypeFIlters/DateFilterV2';
import { COMPARISON_OPTIONS, PERIOD_OPTIONS } from '../../config';
import { DownloadIcon } from '@chakra-ui/icons';
import { filterToPeriod, periodToFilter, periodToTimestamp } from './filter';
import { MembersProps } from '../../hooks/types';

// Hack, should be calculated dynamically by inspecting the card header height
const CARD_TABLE_OFFSET = 105;

const CARC_HEADER_HEIGHT = 64;

export const DEFAULT_COMPARISON: DependentPeriod = {
    interval: 'period',
    amount: 1,
};

export interface DashboardViewProps
    extends Omit<DashboardProps, 'traitFilters' | 'dashboard'> {
    context: DashboardContext;
    width: React.CSSProperties['width'];
    dashboard: Dashboard;
    overrideFilterSetId?: number | MultiFilterRule | MetadataRule | null;
    err?: ErrorInfo;
    extraParams: React.ReactElement;
    saveFiltersButton: React.ReactElement;
    transparancy?: Transparancy;
    assetId: number;
    memberProps: MembersProps;
    messagesRenderer: () => React.ReactNode;
}

export class Loc {
    x: number;
    y: number;
    width: number;
    height: number;
    isDirty: boolean;

    constructor(x: number, y: number, width: number, height: number, isDirty: boolean) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.isDirty = isDirty;
    }

    getActualSize(pixelRatio: PixelRatio): { width: number; height: number } {
        return {
            width: this.width * pixelRatio.positionX - pixelRatio.gap,
            height: this.height * pixelRatio.positionY - pixelRatio.gap,
        };
    }

    getActualPosition(pixelRatio: PixelRatio): { x: number; y: number } {
        return {
            x: this.x * pixelRatio.positionX,
            y: this.y * pixelRatio.positionY,
        };
    }
}

function normalizeVal(v: number, constraint: number) {
    const devision = v / constraint;
    const baseWidth = constraint * Math.floor(devision);
    // baseWidth
    return Math.max(
        0,
        baseWidth + constraint * Math.round((v % constraint) / constraint)
    );
}
const DashboardView: React.FC<
    { children?: React.ReactNode | undefined } & DashboardViewProps
> = ({
    assetId,
    width,
    dashboard,
    err,
    overrideFilterSetId,
    extraParams,
    saveFiltersButton,
    transparancy: evalResponse,
    context,
    dataFilters,
    memberProps,
    messagesRenderer,
}) => {
    const [pixelRatio, setPixelRatio] = React.useState<PixelRatio>(DEFAULT_PIXEL_RATIO);

    const period = dataFilters.timestampFilter
        ? filterToPeriod(dataFilters.timestampFilter.current) ?? undefined
        : undefined;
    const [comparison, setComparison] = React.useState<Period>(DEFAULT_COMPARISON);

    const cardcontext = useDashboardCardContext(context, {
        partition: period
            ? {
                  period,
                  comparison,
                  granularity: dataFilters.dateGranularity,
              }
            : undefined,
        dashboard,
        assetId,
        currParamArr: dataFilters.dataFilters,
        overrideFilterSetId,
        granularity: dataFilters.dateGranularity,
        comparison: periodToFilter(comparison),
        timestampFilter: dataFilters.timestampFilter,
    });

    const cardGridProps: Pick<CardGridViewProps, 'context' | 'cards'> =
        useCardGrid(cardcontext);

    // const cardYOffset = useMemo(
    //     () => cardGridProps.cards.reduce((acc, card) => acc + card.layout.height, 0),
    //     [cardGridProps.cards.length]
    // );

    const dashboardExport = useDashboardExport(cardcontext, dashboard);

    // const fetchCardData = React.useCallback<ModeledCardProps['requestData']>(
    //     (cardId, overridePG, overrideFilters, gran) =>
    //         baseFetchCardData(participantId, dashboard.id, cardId, overridePG, overrideFilters, gran),
    //     [dashboard.id, participantId]
    // );
    // const minHeight = React.useMemo(() => {
    //     const highestPoint = Object.values(locMap).reduce((agg, curr) => {
    //         const retVal = Math.max(agg, curr.getActualPosition(pixelRatio).y + curr.getActualSize(pixelRatio).height);
    //         return isNaN(retVal) ? agg : retVal;
    //     }, 400);
    //     return highestPoint + (isNaN(pixelRatio.positionY) ? 0 : pixelRatio.positionY * 3);
    // }, [locMap, pixelRatio]);

    const containerElm = React.useRef<HTMLDivElement>(null);
    const rect = useRect(containerElm);

    React.useEffect(() => {
        if (rect && rect.width) {
            const { width: contWidth } = rect;
            const newPosX = contWidth / 16;
            const gap = newPosX * pixelRatio.gapPercentage;
            const newPixelWidth = newPosX - gap;
            // const newPixelWidthIncGap =
            // const newPixelHeight = newPixelWidth * pixelRatio.ratio;
            setPixelRatio(new PixelRatio(newPixelWidth, pixelRatio.height, gap));
        }
    }, [rect?.width]);

    const features = {
        exports: useFeature(context, 'exports'),
        data_filters: useFeature(context, 'data_filters'),
        date_ranges: useFeature(context, 'date_ranges'),
    };

    const access = {
        exports: useAccess(context, features.exports),
        datafilters: useAccess(context, features.data_filters),
        dateranges: useDynamicAccess(context, features.date_ranges),
    };

    const bgColor = useColorModeValue('white', '#0A0A0A');

    const v2CardIds = useMemo(
        () => new Set(cardGridProps.cards.map((card) => card.id.toString())),
        [cardGridProps.cards]
    );
    const dashboardCards = useMemo(
        // Ignore cards from the new cards endpoint when using the old dashboard logic
        () =>
            dashboard.cards?.filter(
                (dashboardCard) => !v2CardIds.has(dashboardCard.id.toString())
            ),
        [dashboard.cards]
    );

    const isV2Dashboard = dashboardCards?.length === 0;

    function getCardSize(card: CardV2) {
        const location = new Loc(
            card.layout.column,
            card.layout.row,
            card.layout.width,
            card.layout.height,
            false
        );
        const size = location.getActualSize(pixelRatio);
        const position = location.getActualPosition(pixelRatio);
        if (rect && location.width == 16) {
            size.width = rect.width;
        } else if (location.x + location.width == 16) {
            position.x += pixelRatio.gap;
        }
        return size;
    }

    function getVisualizationSize(card: CardV2) {
        const size = getCardSize(card);
        return { ...size, height: size.height - CARC_HEADER_HEIGHT };
    }

    function getDateFilterOptions(current: Period) {
        const comparisonBase = isRelativeDuration(current)
            ? durationToRange(current)
            : current;
        const comparisonValue =
            isRelativeDuration(current) && isAbsolutePeriod(comparisonBase)
                ? daterangeSubtract(daterangeSubtract(comparisonBase, current), {
                      amount: 1,
                      interval: 'day',
                  })
                : comparisonBase;
        return {
            period: [
                ...PERIOD_OPTIONS,
                {
                    key: 'custom',
                    text: 'Custom',
                    // The default of the custom period automatically gets set
                    // to the last expanded value of the previously selected
                    // relative period
                    value: isRelativeDuration(current)
                        ? durationToRange(current)
                        : current,
                },
            ],
            comparison: [
                ...COMPARISON_OPTIONS,
                {
                    key: 'custom',
                    text: 'Custom',
                    // The default of the custom period automatically gets set
                    // to the last expanded value of the previously selected
                    // relative period
                    value: comparisonValue,
                },
            ],
        };
    }

    /**
     * Dynamically calculates the height of each table row
     */
    function getTableCardRowHeight(card: CardV2, config: TableVisualizationProps) {
        const cardHeight = getCardSize(card).height;
        return (cardHeight - CARD_TABLE_OFFSET) / config.data.length;
    }

    /**
     * We restrict date range filter when attempting to change the filter value
     */
    const daterangeRenderer: DashboardParamsFormProps['dateFilterRenderer'] = (
        tsFilter
    ) => {
        if (!tsFilter.current) {
            return <></>;
        }
        const period = filterToPeriod(tsFilter.current);
        if (!period) {
            console.warn('failed to map filter to period');
            return <></>;
        }
        return (
            <DateFilterFieldsV2
                granularity={dataFilters.dateGranularity}
                period={period}
                comparison={comparison}
                context={context}
                getPresets={getDateFilterOptions}
                column={{ name: 'Date Range' }}
                // @ts-expect-error
                onChange={access.dateranges.handleSubmit(handleDateChange)}
            />
        );
    };

    /**
     * We restrict data filters on click, before we show dropdown
     */
    const datafilterRenderer: DashboardParamsFormProps['paramRenderer'] = (
        paramProps
    ) => {
        return (
            <ParamField
                {...paramProps}
                members={memberProps.memberByKey[paramProps.param.type_slug]?.data}
                optionRenderer={paramProps.optionRenderer}
                restricted={access.datafilters?.restricted}
                tooltipProps={
                    access.datafilters?.restricted
                        ? { label: access.datafilters.hint, openDelay: 0 }
                        : {}
                }
                onClick={access.datafilters?.handleSubmit(paramProps.onClick)}
            />
        );
    };

    const visualizationRenderer =
        (card: CardV2): VisualizationContainerProps['renderVisualization'] =>
        (props) => {
            const size = getVisualizationSize(card);
            switch (props.kind) {
                case 'table': {
                    return (
                        <TableVisualization
                            {...props}
                            overrides={{
                                thead: {},
                                th: {
                                    bg: '#1d1c21',
                                    color: 'whiteAlpha.700',
                                    borderColor: '#292929',
                                },
                                td: {
                                    height: `${getTableCardRowHeight(card, props)}px`,
                                    borderBottomWidth: 0,
                                    borderTopWidth: 1,
                                    borderColor: '#292929',
                                },
                            }}
                        />
                    );
                }
                case 'bar': {
                    return <BarVisualization {...props} {...size} />;
                }
                case 'line': {
                    if (isAggregatedLineVisualization(card.visualization)) {
                        // This is a hack to set the proper line chart width
                        // when stat cards are being displayed as well
                        return (
                            <LineVisualization
                                {...props}
                                {...size}
                                width={size.width * (10 / 12)}
                                // Padding is handled by the grid layout of multiple visualizations
                                padding={{ right: 0 }}
                            />
                        );
                    }
                    return <LineVisualization {...props} {...size} />;
                }
                case 'stat': {
                    // return <Box>asdad</Box>;
                    return <StatVisualization {...props} {...size} />;
                }
            }
        };

    const handleDateChange: DateFilterProps['onChange'] = (values) => {
        const filter = periodToTimestamp(values.period);

        if (values.comparison) {
            setComparison(values.comparison);
        }
        dataFilters.changeTimestampFilter.mutate(filter);
    };

    const handleDashboardExportClick = access.exports?.handleSubmit(() => {
        return dashboardExport.create({ download: true });
    });

    return (
        <Box w="100%" h="100%">
            <Box
                // mt={5}
                // mb={2}
                w="100%"
                h="100%"
                bg={bgColor}
                position="sticky"
                top="49px"
                zIndex={150}
                // borderBottom="1px solid #46454A"
            >
                <Flex
                    flexDir={{ base: 'column', md: 'row' }}
                    justifyContent="flex-start" //'space-between' : 'flex-end'}
                    flexWrap="wrap"
                    w="full"
                >
                    <VStack spacing={4} align="flex-start" w="100%">
                        <Flex justify="space-between" width="full">
                            <Box>
                                <Image src={varosIconSVG} />
                            </Box>
                            {dataFilters.dataFilters.length === 0 && saveFiltersButton}
                        </Flex>
                        {messagesRenderer()}
                        <HStack align="stretch" w="100%">
                            {extraParams}
                            {dataFilters.dataFilters.length === 0 ? (
                                <DashboardParamsForm
                                    params={dataFilters.dataFilters}
                                    onParamChanged={dataFilters.changeDataFilter.mutate}
                                    onClearParam={dataFilters.changeDataFilter.mutate}
                                    optionRenderer={(param) => (
                                        <TypeFiltersContainer
                                            context={context}
                                            param={param}
                                            memberProps={memberProps}
                                        />
                                    )}
                                    dateFilter={dataFilters.timestampFilter}
                                    dateFilterRenderer={daterangeRenderer}
                                    paramRenderer={datafilterRenderer}
                                />
                            ) : (
                                saveFiltersButton
                            )}
                            {dashboardExport.canExport && (
                                <Button
                                    tooltipProps={{
                                        label: access.exports?.restricted
                                            ? access.exports.hint
                                            : 'Export dashboard data',
                                    }}
                                    allowClickWhenDisabled={true}
                                    leftIcon={<DownloadIcon />}
                                    size="sm"
                                    onClick={handleDashboardExportClick}
                                    disabled={access.exports?.restricted}
                                >
                                    Export
                                </Button>
                            )}
                        </HStack>

                        {dataFilters.dataFilters.length > 0 && (
                            <HStack w="100%" spacing={4} mt={2}>
                                {/* TODO: a hack need a fix */}
                                <Text width="139px" fontWeight="bold">
                                    Data Filters:
                                </Text>
                                <DashboardParamsForm
                                    // onClearParam={setCurrentParams(currentParams.reduce())}
                                    onParamChanged={dataFilters.changeDataFilter.mutate}
                                    onClearParam={dataFilters.changeDataFilter.mutate}
                                    optionRenderer={(param) => (
                                        <TypeFiltersContainer
                                            context={context}
                                            param={param}
                                            memberProps={memberProps}
                                        />
                                    )}
                                    params={dataFilters.dataFilters}
                                    dateFilter={dataFilters.timestampFilter}
                                    dateFilterRenderer={daterangeRenderer}
                                    paramRenderer={datafilterRenderer}
                                />
                            </HStack>
                        )}
                    </VStack>
                </Flex>
                <Flex
                    pb={2}
                    mt={4}
                    flexDir={{ base: 'column', md: 'row' }}
                    justifyContent={evalResponse ? 'space-between' : 'flex-end'}
                >
                    {/* Samfpels Indicator */}
                    {evalResponse && <EvalResponseView {...evalResponse} />}
                    {/* day week month + show percentiles */}
                    {dashboard.show_date_granularity && (
                        <HStack spacing={4} position="relative">
                            <DashboardOptions
                                defaultConf={{
                                    date_granularity: dataFilters.dateGranularity,
                                    show_primaries: false,
                                }}
                                dashboardConf={{
                                    date_granularity: dataFilters.dateGranularity,
                                }}
                                changeConfig={(conf) => {
                                    conf.date_granularity &&
                                        dataFilters.setDateGranularity(
                                            conf.date_granularity
                                        );
                                }}
                                onEditMode={false}
                            />
                        </HStack>
                    )}
                </Flex>
            </Box>
            <Box
                position="relative"
                ref={containerElm}
                width={width}
                // minHeight={isV2Dashboard ? undefined : minHeight}
                // backgroundImage={onEditMode ? `url(${DashboardEditImg})` : undefined}
            >
                <CardGridView
                    {...cardGridProps}
                    renderContainer={(containerProps) => (
                        <CardGrid
                            {...containerProps}
                            spacing={4}
                            pb={isV2Dashboard ? 64 : undefined}
                        />
                    )}
                    renderCard={({ card, onExport, children, visible, ...restProps }) => (
                        <CardItem
                            {...restProps}
                            overrides={{
                                divider: {
                                    borderColor: '#505053',
                                },
                            }}
                            size={getCardSize(card)}
                            card={card}
                            noParams={false}
                            // rightContent={
                            //     <Button
                            //         tooltipProps={{
                            //             label: access.exports?.restricted
                            //                 ? access.exports.hint
                            //                 : 'Export dashboard data',
                            //         }}
                            //         allowClickWhenDisabled={true}
                            //         leftIcon={<DownloadIcon />}
                            //         size="md"
                            //         aria-label="Export card"
                            //         onClick={access.exports?.handleSubmit(onExport)}
                            //         disabled={access.exports?.restricted}
                            //     />
                            // }
                        >
                            {visible ? children : null}
                        </CardItem>
                    )}
                    renderChart={({ card, ...chartProps }) => (
                        <VisualizationContainer
                            {...chartProps}
                            card={card}
                            renderVisualization={visualizationRenderer(card)}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default DashboardView;
