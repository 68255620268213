import { assert } from '../../../util/assert';
import { ImportDataDto, ImportDto } from '../../../api';
import { FormDataAdapter, FormDataEntity } from '../../../app/forms';
import { FormDataImplConfig } from './formDataConfig';

/**
 * Form submission adapter that models form submissions using dataset imports
 * @param config
 * @returns
 */
export function createFormDataImpl(config: FormDataImplConfig): FormDataAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function toEntity(dto: ImportDataDto): FormDataEntity {
        // @ts-expect-error
        return dto;
    }

    return {
        async lookup(context, query) {
            try {
                const response = await api.importData.list(context, query.submission.id);
                const [first, ...rest] = response.data;
                assert(first, `expected one form submission data row but found none`);
                if (rest.length > 0) {
                    console.warn(`expected form submission to only have a single row`);
                }
                return toEntity(first);
            } catch (error) {
                console.error(error);
                return null;
            }
        },
    };
}
