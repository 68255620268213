import { createChakraBarChart } from '../../../vendor/chakra-chart';
import { VisualizationUI } from '../../../ui';
import { createBarChart } from './bar';
import { createLineChart } from './line';
import { VisualizationUIConfig } from './visualizationUIConfig';

export function createVisualizationUI(
    config: VisualizationUIConfig = {}
): VisualizationUI {
    return {
        BarChart: createBarChart(),
        LineChart: createLineChart(),
        BarChartV2: createChakraBarChart({}),
    };
}
