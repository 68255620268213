import { AnyQuerySegment } from '../../api';

export function getSegmentDescription(segment: AnyQuerySegment) {
    if (segment.kind !== 'expression') {
        return segment.description;
    }
    if (segment.reducer?.kind !== 'percentile') {
        return segment.description;
    }
    return `${segment.reducer?.value}th percentile of companies that match peer group filters`;
}
