import { PeerMemberListAggregate, PeerMemberListStatus } from './peerMemberListModel';

export function getPeerMemberListStatus(
    item: PeerMemberListAggregate
): PeerMemberListStatus {
    const invitations = item.items.filter((candidate) => !!candidate.invitation);
    if (invitations.length > 0) {
        return 'processing';
    }
    return 'ready';
}
