import { Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { UserRouteConfig } from './userRouteConfig';

export function createUserRoute(
    config: UserRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        Layout,
        provider: { Auth, User, Workspaces },
    } = config;
    return () => {
        const auth = Auth.useContext();
        const user = User.useContext();
        const workspaces = Workspaces.useContext();
        return (
            <Layout.Container>
                <Layout.Header>
                    <VStack alignItems="flex-start">
                        <Heading size="lg">Notifications</Heading>
                        <Text color="whiteAlpha.700">
                            Subscribe to and receive notifications for specific brands in
                            your email inbox
                        </Text>
                    </VStack>
                </Layout.Header>
                <Layout.Body>
                    {config.views.map(({ name, Component }) => (
                        <Component
                            key={name}
                            context={{
                                auth,
                                user,
                                workspaces,
                            }}
                        />
                    ))}
                </Layout.Body>
            </Layout.Container>
        );
    };
}
