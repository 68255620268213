import { DeepParitalStores, Stores } from './createStore';
import { ErrorInfo } from '../models/Errors';
import { action, makeObservable, observable } from 'mobx';
import { StoresWithReset } from './RootStore';
import { convertAxiosToError, makeError } from '../utils/axiosUtils';
import { ContextType } from '../models/Account';
import { HomeContext } from '../models/Context';

class StoreBase {
    public rootStore!: StoresWithReset;
    err: ErrorInfo | null = null;

    constructor(externalStore?: DeepParitalStores[keyof Stores]) {
        if (externalStore) {
            Object.assign(this, { ...externalStore });
        }
        makeObservable(this, {
            err: observable,
            setError: action,
        });
    }
    public start() {}

    public init(rootStore: StoresWithReset) {
        this.rootStore = rootStore;
        return this;
    }
    async onContextChanged(
        contextName: ContextType,
        context?: HomeContext | null
    ): Promise<void> {}

    handleAxiosError(e: any, allowGlobalMessage: boolean = false) {
        const err = convertAxiosToError(e);
        this.setError(err, allowGlobalMessage);
    }

    setErrType(e: ErrorInfo['errType']) {
        this.setError(makeError(e));
    }
    setError(e: ErrorInfo | null, allowGlobalMessage: boolean = false) {
        this.err = e;
        if (this.err && this.err.message && allowGlobalMessage) {
            this.rootStore.auth.setGlobalErrorMessage(this.err.message);
        }
    }
}

export default StoreBase;
