import { assert } from '../../../../../util/assert';
import { AnyReportDependency, ReportDependencyStatus } from '../../../../../app';
import { ReportItemAggregate } from '../../item';
import { StudyItemStatusProps } from '../../status';
import { StudyDependencyItemProps } from './reportDependencyItemProps';

export function buildDependencyItemProps(
    item: ReportItemAggregate,
    dependency: AnyReportDependency
): StudyDependencyItemProps | null {
    let fulfilled: StudyItemStatusProps<ReportDependencyStatus> = {
        color: 'green',
        label: 'Fulfilled',
        kind: 'fulfilled',
        description: null,
    };

    let unfulfilled: StudyItemStatusProps<ReportDependencyStatus> = {
        color: 'orange',
        label: 'Unfulfilled',
        kind: 'unfulfilled',
        description: `This dependency has not been met`,
    };

    if (dependency.kind === 'invite') {
        const isFulfilled = !!item.invitation;
        const status = isFulfilled ? fulfilled : unfulfilled;
        return {
            kind: 'invite',
            label: dependency.label ?? 'Invitation',
            description:
                dependency.description ??
                `An invitation is required to access this report`,
            status,
            isFulfilled,
        };
    }

    if (dependency.kind === 'survey' && item.dataset?.configuration.mode == 'interview') {
        if (!item.dataset) {
            console.warn('expected survey-based report tot have loaded dataset');
            return null;
        }
        const isSubmitted = !!item.submission;
        const isFulfilled = isSubmitted;

        return {
            kind: 'interview',
            label: 'Complete interview',
            description: `You need to complete an interview to access this report.`,
            status: fulfilled,
            isFulfilled: isFulfilled,
            item: {
                label: item.dataset.name,
                link: {
                    to: `/u/assets/${item.asset.id}/reporting/surveys/${dependency.dataset}`,
                },
            },
            link: {
                form: {
                    to: `/u/assets/${item.asset.id}/reporting/surveys/submissions/new?survey=${dependency.dataset}`,
                },
                submission: item.submission
                    ? {
                          to: `/u/assets/${item.asset.id}/reporting/surveys/submissions/${item.submission.id}`,
                      }
                    : null,
            },
        };
    }
    if (dependency.kind === 'survey') {
        if (!item.dataset) {
            console.warn('expected survey-based report tot have loaded dataset');
            return null;
        }
        const isSubmitted = !!item.submission;
        const isFulfilled = isSubmitted;

        return {
            kind: 'survey',
            label: 'Complete survey',
            description: `You must fill out a survey to access this report`,
            status: fulfilled,
            isFulfilled: isFulfilled,
            item: {
                label: item.dataset.name,
                link: {
                    to: `/u/assets/${item.asset.id}/reporting/surveys/${dependency.dataset}`,
                },
            },
            link: {
                form: {
                    to: `/u/assets/${item.asset.id}/reporting/surveys/submissions/new?survey=${dependency.dataset}`,
                },
                submission: item.submission
                    ? {
                          to: `/u/assets/${item.asset.id}/reporting/surveys/submissions/${item.submission.id}`,
                      }
                    : null,
            },
        };
    }
    return {
        kind: 'integration',
        isFulfilled: false,
        label: 'Connect integration',
        description: null,
        status: {
            color: 'green',
            label: 'Fulfilled',
            kind: 'fulfilled',
            description: null,
        },
    };
}
