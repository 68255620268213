import { useForm } from 'react-hook-form';
import { GroupFormViewController } from './groupsFromInterface';
import { GroupsState } from '../loader';
import React from 'react';
import { GroupCardViewProps } from './groupsFormProps';
import { GroupsService } from '../../../../service/groups';
import { AccountProfileRepository } from '../../../../app/accounts/profile';

export const createGroupsFormController = ({
    groups: groupsService,
    profile: profileRepository,
}: {
    groups: GroupsService;
    profile: AccountProfileRepository;
}): GroupFormViewController => {
    return {
        useProps(ctx, props) {
            const form = useForm<GroupsState>({
                mode: 'onChange',
                reValidateMode: 'onChange',
                defaultValues: props.data.initial,
            });
            React.useEffect(() => {
                form.reset(props.data.initial);
            }, [props.data.initial.state, props.data.initial.optinAssets]);
            const profile = profileRepository.useLookup(ctx, { suspense: true });
            const groupOptions: GroupCardViewProps[] = React.useMemo(() => {
                const retVal: GroupCardViewProps[] = [];
                if (props.data.optinGroup) {
                    retVal.push({
                        value: 'opt_in',
                        title: 'Co-Op',
                        description:
                            'Identify top creatives across co-op participants. Your creatives may be shared with other companies using Varos, excluding your competitors. You can manually choose which companies to include in the co-op.',
                    });
                }
                if (
                    props.data.initial.state == 'internal' ||
                    (profile.status === 'success' &&
                        profile.data.organizationType === 'ad_agency')
                ) {
                    retVal.push({
                        value: 'internal',
                        title: 'Internal',
                        description:
                            'Identify top creatives across companies you manage. You will not share creatives with external entities.',
                    });
                }
                return retVal;
            }, [props.data.optinGroup, profile.status, props.data.initial.state]);

            const saveCb = groupsService.useSave(ctx);
            const clearCb = groupsService.useClear(ctx);

            const onSave = React.useCallback(
                async (state: GroupsState) => {
                    if (state.state === 'pending') {
                        return clearCb.mutateAsync(null);
                    } else if (state.state === 'opt_in') {
                        if (state.optinAssets && state.optinAssets.length > 0) {
                            return saveCb.mutateAsync({
                                type: state.state,
                                asset_ids: state.optinAssets?.map((x) => x.id),
                            });
                        } else {
                            throw new Error('assets list is empty');
                        }
                    } else {
                        return saveCb.mutateAsync({
                            type: state.state,
                        });
                    }
                },
                [props.data.initial]
            );

            return {
                ...form,
                onSave,
                onClear: () => clearCb.mutateAsync(null),
                groupOptions,
                assetOptions: props.data.optinAssetOptions,
                isSaving: saveCb.isLoading,
                error: saveCb.error?.message || clearCb.error?.message,
                isClearing: clearCb.isLoading,
                isDisabled: false,
                isLoading: props.data.isLoading,
            };
        },
    };
};
