import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import { VarosRoutes } from '../../../infra';
import { useStore } from '../../../stores/setupContext';
import { useNavigate } from 'react-router-dom';
import {
    Integration,
    IntegrationConf,
    IntegrationWithConf,
} from '../../../models/integration';
import { ErrorInfo as Err } from '../../../models/Errors';
import {
    IntegrationSelector,
    IntegrationSelectorProps,
} from '../../../components/IntegrationSelector/IntegrationSelector';
import { OAuthLinkPayload } from '../../../models/OAuth';
// import IntegrationGuides from './IntegrationGuides';
import {
    makeNewIntegrateHandler,
    makeReconnectHandler,
} from '../../../hooks/integrations/funcs';
import { observer } from 'mobx-react';
import SingleIntegPage from './SingleIntegPage';
import { useHome } from '../main/context';
import { ASSET_TYPE_DICT } from '../../../utils/strings';
import ConnectCardPage from './ConnectCardPage';

interface InternalProps {
    organizationId: number;
    isLoading: boolean;
    definitions: Array<IntegrationConf> | null;
    err: Err | null;
    currentIntegrations: Array<Integration> | null;
    fetchIntegrations: (definitionId: number) => void;
    clearIntegrations: () => void;
    // urlPrefix: string;
    hideIntegrated?: boolean;
    mandatoryOnly: 'all' | 'none' | null;
    connect: (integDefId: number) => void;
    reconnect: (integDefId: number, integId: number) => void;
    isLoadingConnection?: boolean;
    isLoadingReconnection?: boolean;
    isLoadingId?: number;
}

const Observed: React.FC<{ children?: React.ReactNode } & InternalProps> = ({
    organizationId,
    // urlPrefix,
    err,
    isLoading,
    fetchIntegrations,
    currentIntegrations,
    clearIntegrations,
    definitions,
    hideIntegrated,
    mandatoryOnly,
    connect,
    reconnect,
    isLoadingConnection,
    isLoadingReconnection,
    isLoadingId,
}) => {
    const navigate = useNavigate();
    const integReqHandler = makeNewIntegrateHandler(navigate, connect, { newTab: true });
    const reconnectHandler = makeReconnectHandler(navigate, reconnect, { newTab: true });
    return (
        <VarosRoutes>
            <Route
                path="/"
                element={
                    <IntegrationSelector
                        hideIntegrated={hideIntegrated}
                        isLoading={isLoading}
                        integrationDefinitions={definitions}
                        mandatoryOnly={mandatoryOnly}
                        err={err}
                        fetchIntegrations={fetchIntegrations}
                        currentIntegrations={currentIntegrations}
                        clearIntegrations={clearIntegrations}
                        onIntegrationRequest={integReqHandler}
                        onReconnectRequest={reconnectHandler}
                        isLoadingConnection={isLoadingConnection}
                        isLoadingReconnection={isLoadingReconnection}
                        isLoadingId={isLoadingId}
                        onEdit={(integrationId: number) => {
                            navigate(`${integrationId}/settings`);
                        }}
                    />
                }
            />
            <Route path=":integrationId/connect-card" element={<ConnectCardPage />} />
            <Route path="definitions">
                <Route
                    path=":integrationDefinitionId/connect-card"
                    element={<ConnectCardPage />}
                />
                <Route path=":integrationDefinitionId" element={<SingleIntegPage />} />
            </Route>
        </VarosRoutes>
    );
};

/* <Route path={`${urlPrefix}/integrations/:integrationKey/guide/`} exact>
    <IntegrationGuides prefix={urlPrefix} />
</Route> */
/* <Route></Route> */
export interface IntegrationProps
    extends Pick<InternalProps, 'hideIntegrated' | 'mandatoryOnly'> {}

const Main: React.FC<{ children?: React.ReactNode } & IntegrationProps> = ({
    hideIntegrated,
    mandatoryOnly,
}) => {
    const rootStore = useStore();
    const {
        integrations: {
            isLoading,
            definitions,
            err,
            clearIntegration,
            redirectToConnectLink,
            redirectToReconnecLink,
            isLoadingIntegrateLink,
            isLoadingReconnectLink,
            isLoadingId,
            fetchIntegrations,
            clearIntegrations,
            currentIntegrations,
        },
        menu,
    } = rootStore;
    // React.useEffect(() => {
    //     menu.setSelectedCategory(`integrations`);
    //     return () => {
    //         menu.setSelectedCategory(null);
    //     }
    // }, []);
    return (
        <Observed
            {...{
                organizationId: rootStore.auth.getOrgId(),
                isLoading: isLoading,
                definitions,
                err: err,
                clearIntegration,
                hideIntegrated,
                mandatoryOnly,
                connect: redirectToConnectLink,
                reconnect: redirectToReconnecLink,
                isLoadingConnection: isLoadingIntegrateLink,
                isLoadingReconnection: isLoadingReconnectLink,
                fetchIntegrations,
                clearIntegrations,
                currentIntegrations,
                isLoadingId,
            }}
        />
    );
};

export default observer(Main);
