import { useQuery } from '@tanstack/react-query';
import { MovementDataValue, QueryRunPayload, runQuery } from '../../../../api';
import { MetricCalculation, MetricCalculationSegment } from '../../../domain/metrics';
import { ViewDto } from '../../../api';
import { CalculationAdapterHook } from '../../../app/metrics';
import { StatisticConfig } from './calculationConfig';
import { chain, isEqual } from 'lodash';
import { castQueryToApi } from '../../api';
import { useSystemContextV2, useWorkspaceContextV2 } from '../../../context';
import { useMemo } from 'react';
import { ViewEntity } from '../../../domain';

export function createCalculationImpl(config: StatisticConfig): CalculationAdapterHook {
    const {
        axios,
        api: {
            query: { queries },
        },
    } = config;
    return ({ builder }, context) => {
        const authToken =
            context.auth.scheme.kind === 'legacy' && context.auth.scheme.store.authToken;

        if (!authToken) {
            throw new Error('only legacy auth supported');
        }

        const contextdata = useSystemContextV2().data;
        const workspace = useWorkspaceContextV2();
        // console.log('DEBUG workspace.exampleMode', workspace.exampleMode);

        const viewsById = useMemo(() => {
            return (
                contextdata.views.data?.reduce(
                    (acc, view) => ({ ...acc, [view.id]: view }),
                    {} as Record<string, ViewEntity | undefined>
                ) ?? {}
            );
        }, [contextdata.views.data]);

        const columnByViewId = useMemo(() => {
            return (
                contextdata.views.data?.reduce(
                    (accView, itemView) =>
                        itemView.columns.reduce((accColumn, itemColumn) => {
                            const id = `${itemView.plugin}.${itemColumn.key}`;
                            if (accColumn[id]) {
                                // we might have duplicate columns across views
                                // so we assume the first one has priority
                                return accColumn;
                            }
                            return {
                                ...accColumn,
                                [id]: {
                                    ...itemColumn,
                                    view: itemView,
                                },
                            };
                        }, accView),
                    {} as Record<
                        string,
                        (ViewEntity['columns'][number] & { view: ViewEntity }) | undefined
                    >
                ) ?? {}
            );
        }, [contextdata.views.data]);

        // console.log('columnByViewId', columnByViewId);
        // console.log('preferencesByDashboard', preferencesByView);

        return {
            async find(context, query, options) {
                // console.log('find statistics', toJS(query.date.period));

                const metricsByView = chain(query.metrics)
                    .groupBy((item) => {
                        const column = columnByViewId[item.definition.id];
                        if (!column) {
                            throw new Error(
                                `metric ${item.definition.id} has not view mapping`
                            );
                        }
                        return column.view.id;
                    })
                    .value();

                const queries = chain(metricsByView)
                    .entries()
                    .map(([viewId, metrics]) => {
                        const view = viewsById[viewId];
                        if (!view) {
                            throw new Error(`view ${viewId} not found`);
                        }

                        const [sampleConfiguration, ...rest] = metrics.map((item) => ({
                            filters: item.configuration.filters,
                            ranking: item.configuration.ranking,
                            competitiveSet: item.configuration.competitiveSet,
                        }));
                        const isAllTheSame = rest.every((item) =>
                            isEqual(item, sampleConfiguration)
                        );
                        if (!isAllTheSame) {
                            throw new Error(
                                `expected all segments to be the same but found different ones ${JSON.stringify(
                                    [sampleConfiguration, ...rest]
                                )}`
                            );
                        }

                        // console.log('view', view, metrics);
                        const built = builder.metric.build(context, {
                            configuration: sampleConfiguration,
                            date: query.date,
                            view,
                            metrics,
                        });
                        const { segment, ...mapped } = castQueryToApi(built);
                        const payload: QueryRunPayload = {
                            asset: context.workspace.id as number,
                            query: mapped,
                            segment,
                            example: workspace.exampleMode
                                ? view.example_mode
                                : undefined,
                        };

                        return {
                            ...payload,
                            metadata: {
                                view,
                                metrics,
                                built,
                            },
                        };
                    })
                    // .map(async (item) => {
                    //     return item;
                    // })
                    .value();

                const responsesreal = await Promise.all(
                    queries.map(async (query) => {
                        try {
                            // console.log('QUERY', query.query.source.view, query.query);
                            // console.log('sending query', query);
                            const response = await runQuery(axios, query, {
                                signal: options.signal,
                                headers: {
                                    Authorization: `bearer ${authToken}`,
                                },
                                version: 'v3',
                            });
                            // console.log('response', query);
                            // console.log(
                            //     'RESPONSE',
                            //     response.metadata.scalar,
                            //     response.queries[0].series.items
                            // );
                            return { metadata: query.metadata, request: query, response };
                        } catch (error) {
                            if (error instanceof Error) {
                                console.error(error);
                                return {
                                    metadata: query.metadata,
                                    request: query,
                                    response: error,
                                };
                            }
                            throw error;
                        }
                    })
                );

                const responses = await Promise.all(
                    responsesreal.flatMap(({ metadata, request, response }) => {
                        if (response instanceof Error) {
                            console.info(
                                `request failed, discarding metrics...`,
                                response,
                                request
                            );
                            return [];
                        }
                        return metadata.metrics.flatMap((metric, index) => {
                            return response.queries.map((_query): MetricCalculation => {
                                // const scalarKey = response.metadata.scalar[index];
                                const scalarKey = metric.definition.key;

                                const [
                                    meSegment,
                                    // minSegment,
                                    lowSegment,
                                    midSegment,
                                    highSegment,
                                    // maxSegment,
                                ] = request.query.segments ?? [];

                                const [
                                    meSeries,
                                    // minSeries,
                                    lowSeries,
                                    midSeries,
                                    highSeries,
                                    // maxSeries,
                                ] = _query.series.items;

                                const current: MetricCalculationSegment = {
                                    value: meSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: meSegment,
                                };

                                const min: MetricCalculationSegment = {
                                    value: lowSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: lowSegment,
                                };

                                const low: MetricCalculationSegment = {
                                    value: lowSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: lowSegment,
                                };

                                const mid: MetricCalculationSegment = {
                                    value: midSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: midSegment,
                                };

                                const high: MetricCalculationSegment = {
                                    value: highSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: highSegment,
                                };

                                const max: MetricCalculationSegment = {
                                    value: highSeries.data[0][
                                        scalarKey
                                    ] as MovementDataValue,
                                    definition: highSegment,
                                };

                                // console.log(
                                //     'SCALARKEY',
                                //     index,
                                //     scalarKey,
                                //     metric.name,
                                //     current.value.base
                                // );

                                return {
                                    definition: metric.definition,
                                    peers: metadata.built.segment ?? [],
                                    period: query.date.period,
                                    comparison: query.date.comparison,
                                    current,
                                    min,
                                    low,
                                    mid,
                                    high,
                                    max,
                                };
                            });
                        });
                    })
                );
                return responses;
            },
        };
    };
}
