import { ModalControllerConfig } from './modalConfig';
import { ModalController } from './modalInterface';

export function createModalController(config: ModalControllerConfig): ModalController {
    return {
        useProps(props) {
            return {
                id: config.id,
                containerProps: {
                    isOpen: props.disclosure.isOpen,
                    onClose: props.disclosure.onClose,
                },
                triggerProps: {
                    onClick() {
                        props.disclosure.onOpen();
                    },
                },
                closeProps: {
                    onClick() {
                        props.disclosure.onClose();
                    },
                },
                disclosure: props.disclosure,
            };
        },
    };
}
