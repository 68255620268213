import { AxiosError } from 'axios';

export interface ErrorInfo {
    name: string | null;
    description: string;
    hint: string | null;
}

export class ApplicationError extends Error {
    constructor(
        public kind: string,
        message?: string
    ) {
        super(message);
        Object.setPrototypeOf(this, ApplicationError.prototype);
        this.name = 'ApplicationError';
    }
}

export class ApiError extends Error {
    public readonly statusCode: number | null;
    public readonly axiosError: AxiosError | null;

    constructor(message?: string, axios?: AxiosError) {
        super(message);
        Object.setPrototypeOf(this, ApiError.prototype);
        this.statusCode = axios?.response?.status ?? null;
        this.axiosError = axios ?? null;
    }
}

export class RateLimitError extends ApiError {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, RateLimitError.prototype);
    }
}

export class ServiceUnavailableError extends ApiError {
    constructor(
        axios: AxiosError,
        message: string = 'There is a temporary issue reaching our servers'
    ) {
        super(message, axios);
        Object.setPrototypeOf(this, ServiceUnavailableError.prototype);
    }
}
