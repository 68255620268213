import React, { Suspense, useEffect } from 'react';
import {
    HStack,
    Button,
    Tooltip,
    Heading,
    SimpleGrid,
    Box,
    Grid,
    GridItem,
    Image,
} from '@chakra-ui/react';
import { ErrorBoundaryProps } from '@sentry/react';
import infoSVG from '../../../../svg/info.svg';
import { ErrorBoundary } from '../../../../infra';
import { isNotEnoughDataError, isValidationError } from '../../../../utils/axiosUtils';
import { CardEmptyState } from '../../../../domain';
import { CardSpinner } from '../../../../domain';
import { CompositionSection, Card } from '../../../domain';
import { CardListViewProps, CardItemViewProps } from './cardProps';
import { NotEnoughDataError } from '../../../../api/v2/query';
import { FallbackProps } from 'react-error-boundary';
import { useStorageContext } from '../../../app';

export const CardListView: React.FC<
    { children?: React.ReactNode | undefined } & CardListViewProps
> = ({ ...props }) => {
    return (
        <Grid
            {...props.style?.list?.wrapper}
            gridTemplateColumns={{
                md: '1 1fr',
            }}
        >
            {props.cards.map((item) => {
                return <GridItem key={item.id}>{props.renderItem(item)}</GridItem>;
            })}
        </Grid>
    );
};

export const CardItemView: React.FC<
    { children?: React.ReactNode | undefined } & CardItemViewProps
> = ({ composition, ...props }) => {
    return (
        <Grid {...props.style?.wrapper}>
            <GridItem {...props.style?.header}>
                <HStack spacing={2}>
                    <Heading fontSize="md">{props.card.title}</Heading>
                    {props.card.description && (
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            label={
                                <Box p={2}>
                                    {/* <ReactMarkdown
                                        components={ChakraUIRenderer()}
                                        children={props.card.description}
                                        skipHtml={true}
                                    /> */}
                                    {props.card.description}
                                </Box>
                            }
                        >
                            <Image src={infoSVG} />
                        </Tooltip>
                    )}
                </HStack>
            </GridItem>
            <GridItem {...props.style?.body}>
                <ErrorBoundary fallback={renderCardError} key={props.stateHash}>
                    <Suspense fallback={<CardSpinner />}>
                        <SimpleGrid
                            h="100%"
                            columns={12}
                            // overflowY="auto"
                        >
                            {composition.map((section, index) => (
                                <GridItem
                                    alignItems="center"
                                    // TODO: replace hardcoded layout with dynamic layout planning mechanism
                                    colSpan={getColspan(composition, index)}
                                    key={index}
                                >
                                    {props.renderChart(section)}
                                </GridItem>
                            ))}
                        </SimpleGrid>
                    </Suspense>
                </ErrorBoundary>
            </GridItem>
        </Grid>
    );
};

function getColspan(sections: CompositionSection[], index: number) {
    if (sections.length <= 1) {
        return 12;
    }
    if (index === 1) {
        return 2;
    }
    return 10;
}

export const renderCardError: ErrorBoundaryProps['fallback'] = ({
    error,
    resetError,
    ...rest
}) => {
    if (isNotEnoughDataError(error) || error instanceof NotEnoughDataError) {
        return (
            <CardEmptyState
                level="info"
                title="Insufficient Sample Size"
                hint="Please adjust your filters"
            />
        );
    }
    if (isValidationError(error)) {
        return <CardEmptyState level="warn" title={error.message ?? error.errType} />;
    }
    return (
        <CardEmptyState level="error" title={error.message ?? error.constructor.name} />
    );
};
