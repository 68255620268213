import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { AnyReportConfiguration } from '../../../middleware';

export const DemographicsKPIReport: AnyReportConfiguration = {
    kind: 'external',
    entity: {
        id: 'demographics_kpis',
        name: 'Demographics KPIs',
        status: 'draft',
        tagline: null,
        description: `This report presents breakdowns of ad performance KPIs by demographics.`,
        category: 'marketing',
        tags: ['strategy', 'demographics'],
        dependencies: [],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [
            // {
            //     description: null,
            //     url: screenshot_spend_over_revenue,
            // },
            // {
            //     description: null,
            //     url: screenshot_spend_over_budget,
            // },
            // {
            //     description: null,
            //     url: screenshot_compensation_model,
            // },
        ],
    },
    getTargetUrl(context) {
        return {
            url: `https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/0acd2451-0431-4167-ae96-144d66ac597d/latest`,
            params: { asset: context.asset, token: context.token },
        };
    },
};
