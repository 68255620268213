import { PageIds } from '../../../../config';
import { PeerControllerConfig } from '../../base';
import { PeerGroupListRouteController } from './peerGroupListInterface';

export function createPeerGroupListController(
    config: PeerControllerConfig
): PeerGroupListRouteController {
    const {
        controller: {
            common: { page: pageController },
            group: { list: groupController },
        },
    } = config;
    return {
        useProps(context, item, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.PEER_COMPETITIVE_SET_LIST,
                    breadcrumbs: [],
                },
            });
            const groupProps = groupController.useProps(context, item.group);
            return {
                page: pageProps,
                list: groupProps,
                links: {
                    new: {
                        to: 'new',
                    },
                },
            };
        },
    };
}
