import React from 'react';
import { FiArrowLeft, FiLogOut } from 'react-icons/fi';
import { Button, Heading, HStack, Tag, Text, VStack } from '@chakra-ui/react';
import {
    OnboardingOrgaizationRouteConfig,
    OnboardingAssetRouteConfig,
} from './onboardingRouteConfig';
import {
    OnboardingAssetRouteViewProps,
    OnboardingOrganizationRouteViewProps,
} from './onboardingRouteProps';
import {
    OnboardingOrganizationRouteStrategy,
    OnboardingOrganizationStrategyControllerProps,
    OnboardingAssetRouteStrategy,
    OnboardingAssetStrategyControllerProps,
} from './strategy';
import { Link } from 'react-router-dom';

export function createOnboardingOrganizationRouteView(
    config: OnboardingOrgaizationRouteConfig,
    instances: Array<
        OnboardingOrganizationRouteStrategy & {
            Component: React.FC<
                {
                    children?: React.ReactNode | undefined;
                } & OnboardingOrganizationStrategyControllerProps
            >;
        }
    >
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingOrganizationRouteViewProps
> {
    const { Layout } = config;
    return (props) => {
        return (
            <Layout.Container>
                <Layout.Navigation>
                    {props.backPath ? (
                        <Link to={props.backPath}>
                            <Button variant="ghost" leftIcon={<FiArrowLeft />}>
                                To platform
                            </Button>
                        </Link>
                    ) : (
                        <></>
                    )}
                </Layout.Navigation>
                <Layout.Session>
                    <Button
                        variant="ghost"
                        rightIcon={<FiLogOut />}
                        onClick={props.onSignout}
                    >
                        Logout
                    </Button>
                </Layout.Session>

                <Layout.Step.List selected={props.selected}>
                    {instances
                        .filter((instance) => instance.id === props.selected)
                        .map(({ Component, ...rest }) => (
                            <Layout.Step.Item key={rest.id} id={rest.id}>
                                <Component id={rest.id} />
                            </Layout.Step.Item>
                        ))}
                </Layout.Step.List>
            </Layout.Container>
        );
    };
}

export function createOnboardingAssetRouteView(
    config: OnboardingAssetRouteConfig,
    instances: Array<
        OnboardingAssetRouteStrategy & {
            Component: React.FC<
                {
                    children?: React.ReactNode | undefined;
                } & OnboardingAssetStrategyControllerProps
            >;
        }
    >
): React.FC<{ children?: React.ReactNode | undefined } & OnboardingAssetRouteViewProps> {
    const { Layout } = config;
    return (props) => {
        return (
            <Layout.Container>
                <Layout.Navigation>
                    {props.backPath ? (
                        <Link to={props.backPath}>
                            <Button variant="ghost" leftIcon={<FiArrowLeft />}>
                                To platform
                            </Button>
                        </Link>
                    ) : (
                        <></>
                    )}
                </Layout.Navigation>
                <Layout.Context>
                    <HStack spacing={3}>
                        <Heading fontSize="3xl">{props.workspace.name}</Heading>
                        {props.workspace.url && (
                            <Tag size="lg">{props.workspace.url}</Tag>
                        )}
                    </HStack>
                </Layout.Context>
                <Layout.Session>
                    <Button
                        variant="ghost"
                        rightIcon={<FiLogOut />}
                        onClick={props.onSignout}
                    >
                        Logout
                    </Button>
                </Layout.Session>
                <Layout.Step.List selected={props.selected}>
                    {instances
                        .filter((instance) => instance.id === props.selected)
                        .map(({ Component, ...rest }) => (
                            <Layout.Step.Item key={rest.id} id={rest.id}>
                                <Component
                                    id={rest.id}
                                    asset={{
                                        ...props.workspace,
                                        id: props.workspace.id as number,
                                    }}
                                />
                            </Layout.Step.Item>
                        ))}
                </Layout.Step.List>
            </Layout.Container>
        );
    };
}
