import { createChakraStepperController } from '../../../vendor/chakra-stepper';
import { RegistrationControllerConfig } from '../base';
import { RegistrationAcceptCreateController } from './registrationAcceptCreateInterface';
import { RegistrationAcceptCreateViewProps } from './registrationAcceptCreateProps';

export function createRegistrationAcceptCreateController(
    config: RegistrationControllerConfig
): RegistrationAcceptCreateController {
    const { useProps: useStepperProps } = createChakraStepperController();
    return {
        useProps(item, props): RegistrationAcceptCreateViewProps {
            const stepper = useStepperProps(props.stepper);
            return {
                stepper,
                button: {
                    home: {
                        onClick() {
                            stepper.onChange(0);
                        },
                    },
                },
                link: {
                    login: {
                        id: 'onboarding_invitation_login',
                        to: '/login',
                    },
                },
                anchor: {
                    home: {
                        id: 'onboarding_invitation_home',
                        href: 'https://varos.com',
                        target: '_self',
                    },
                },
            };
        },
    };
}
