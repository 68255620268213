import { compose } from 'lodash/fp';
import React, { createContext, useMemo } from 'react';
import { Route, Outlet } from 'react-router-dom';
import { createFormatter } from '../../../hooks';
import { VarosRoutes } from '../../../infra';
import { createApi } from '../../../api/axios';
import { NotEnoughDataError } from '../../../api';
import { isErrorInfo, convertAxiosToError } from '../../../utils/axiosUtils';
import { DEMO_API_KEY, DEMO_CONFIG } from '../../config/demo';
import { ApplicationError } from '../../base';
import { SegmentListView, SegmentItemView } from '../../view/demo';
import { createDemoChartService } from '../../service/demo';
import { LineChartView } from '../../view/demo/chart';
import { createErrorFormatterImpl } from '../../impl';
import { createDemoChartMockImpl } from '../../impl/mock';
import { createDemoChartApiImpl } from '../../impl/api';
import { ApiError, RateLimitError } from '../../base';
import { createDatasetStatusApiImpl } from '../../impl/api';
import {
    createDemoChartContainer,
    createChartController,
    createDemoRouteController,
} from '../../controller/demo';
import {
    createDemoCancellationStrategy,
    createDemoTrackingStrategy,
    createDemoFeatureStrategy,
    DemoFeatureModalView,
} from '../../strategy';
import { createDemoRoute } from '../../route';
import { DemoEntryEnhancer, createDemoEntrypoint } from '../../entrypoint';
import { createDemoSegmentTrackerImpl } from '../../impl/segment';

// const GREEN = '#39BB68';
const ACCENT_COLOR = '#7164ff';
const PURPLE = '#7756ff';
const TEAL = '#7fe1da';
const RED = '#fe476a';
const GREEN = '#2bd19a';
const LEGEND_COLORS = [RED, PURPLE, GREEN].reverse();
const REDIRECT_URL = 'https://app.varos.com/register';

// set to true to use the mock chart adapter
const MOCK_CHART = false;

export function configureDemoEntrypoint() {
    const axios = createApi({
        transformError(error) {
            const info = isErrorInfo(error) ? error : convertAxiosToError(error);
            if (info.errType === 'rateLimited') {
                return new RateLimitError();
            }
            return error;
        },
    });
    const enhancer: DemoEntryEnhancer = compose(
        createDemoCancellationStrategy(),
        createDemoFeatureStrategy({
            highlights: [
                {
                    text: `Your data vs. competitive set`,
                },
                {
                    text: `250+ granular sub-verticals`,
                },
                {
                    text: `Real-time market trends`,
                },
                {
                    text: `Competitors’ spend distribution across platforms`,
                },
                {
                    text: `3-year data lookback period`,
                },
                {
                    text: `50+ different metrics`,
                },
            ],
            redirectUrl: REDIRECT_URL,
            lockedDashboardIds: [],
            view: {
                Modal: (props) => (
                    <DemoFeatureModalView
                        style={{
                            modal: {
                                content: {
                                    w: 'full',
                                    minW: '300px',
                                    maxW: {
                                        lg: '75%',
                                    },
                                },
                            },
                            wrapper: {
                                p: 16,
                                gap: 8,
                            },
                            heading: {
                                fontSize: '3xl',
                            },
                            divider: {
                                opacity: 'inherit',
                                boxShadow: 'none',
                                borderBottom: 'none',
                                bg: `linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)`,
                                h: '2px',
                            },
                            emphasize: {
                                color: ACCENT_COLOR,
                            },
                            list: {
                                spacing: 4,
                                fontWeight: 'medium',
                            },
                            button: {
                                px: 16,
                                bg: ACCENT_COLOR,
                                borderRadius: 0,
                                color: 'whiteAlpha.900',
                                _hover: {
                                    bg: ACCENT_COLOR,
                                    color: 'whiteAlpha.900',
                                },
                                _active: {
                                    bg: ACCENT_COLOR,
                                    color: 'whiteAlpha.900',
                                },
                            },
                        }}
                        {...props}
                    />
                ),
            },
        }),
        createDemoTrackingStrategy({
            useAdapter() {
                const adapter = createDemoSegmentTrackerImpl({
                    logging: true,
                    debug: process.env.VAROS_ENV !== 'production',
                    analytics: window.analytics,
                });
                return adapter;
            },
        })
    );
    return createDemoEntrypoint(
        {
            platforms: DEMO_CONFIG.dashboards,
            components: {
                Provider(props) {
                    // noop provider
                    return <>{props.children}</>;
                },
                Routes: VarosRoutes,
                Route,
                Outlet,
            },
            infra: {
                createErrorFormatter: createErrorFormatterImpl,
            },
            route: {
                demo: {
                    config: {
                        intl: {
                            header: {
                                title: 'Varos Lite: One-Metric Introduction to Our Comprehensive Suite',
                                subtitle:
                                    'Sign up to Varos to access the full product and get a range of metrics, reports, industries and breakdowns',
                            },
                            cta: {
                                title: 'Connect your data to access more metrics, industries, breakdowns, and much more',
                                button: 'Try the Complete Varos Platform Free',
                            },
                        },
                        style: {
                            theme: {
                                accentColor: ACCENT_COLOR,
                            },
                            ui: {
                                label: {
                                    fontWeight: 'bold',
                                    fontSize: 'sm',
                                    color: 'blackAlpha.800',
                                },
                                tab: {
                                    fontSize: 'sm',
                                    fontWeight: 'bold',
                                    px: 8,
                                    borderColor: 'gray.200',
                                    _focus: { outline: 'none' },
                                    _selected: {
                                        color: '#7164ff',
                                        borderColor: '#7164ff',
                                    },
                                    _hover: {
                                        bg: 'blackAlpha.100',
                                    },
                                    _disabled: {
                                        color: 'blackAlpha.400',
                                        _hover: {
                                            bg: 'blackAlpha.100',
                                        },
                                    },
                                },
                                button: {
                                    list: {
                                        spacing: 4,
                                    },
                                    item: {
                                        px: 4,
                                        py: 3,
                                        borderWidth: 2,
                                        _checked: {
                                            borderColor: ACCENT_COLOR,
                                        },
                                    },
                                },
                            },
                            layout: {
                                control: { spacing: 4 },
                                wrapper: {
                                    pb: 32,
                                },
                                banner: {
                                    px: 4,
                                    py: 2,
                                    bg: 'black',
                                    color: 'whiteAlpha.900',
                                    fontWeight: 'semibold',
                                },
                                main: {
                                    p: 8,
                                    m: 'auto',
                                    maxWidth: { lg: '75%', md: '100%' },
                                    minWidth: { lg: '800px', md: '100%' },
                                    gap: 8,
                                },
                                visualization: {
                                    minH: {
                                        sm: '250px',
                                        '2xl': '300px',
                                    },
                                },
                                errorState: {
                                    stack: { spacing: 2 },
                                    icon: {
                                        w: 10,
                                        h: 10,
                                    },
                                },
                                heading: {
                                    fontSize: '2xl',
                                },
                                text: {
                                    secondary: {
                                        color: 'blackAlpha.600',
                                    },
                                },
                                button: {
                                    cta: {
                                        bg: ACCENT_COLOR,
                                        borderRadius: 0,
                                        color: 'whiteAlpha.900',
                                        _hover: {
                                            bg: ACCENT_COLOR,
                                            color: 'whiteAlpha.900',
                                        },
                                        _active: {
                                            bg: ACCENT_COLOR,
                                            color: 'whiteAlpha.900',
                                        },
                                    },
                                },
                            },
                        },
                        adapter: {
                            createChartAdapter() {
                                return MOCK_CHART
                                    ? createDemoChartMockImpl({
                                          delayMs: 2000,
                                          error: new NotEnoughDataError(),
                                      })
                                    : createDemoChartApiImpl({
                                          axios,
                                          apiKey: DEMO_API_KEY,
                                      });
                            },
                        },
                        service: {
                            createChartService(adapter) {
                                return createDemoChartService({
                                    adapter,
                                });
                            },
                        },
                        controller: {
                            createChartControllerHook(service) {
                                return createChartController({
                                    service,
                                    formatter: createFormatter(),
                                });
                            },
                            createRouteControllerHook() {
                                return createDemoRouteController({
                                    dashboards: DEMO_CONFIG.dashboards,
                                    url: {
                                        registration: REDIRECT_URL,
                                    },
                                    adapter: {
                                        dataset: createDatasetStatusApiImpl({
                                            axios,
                                            apiKey: DEMO_API_KEY,
                                        }),
                                    },
                                });
                            },
                        },
                        view: {
                            createRouteProvider() {
                                return (props) => <>{props.children}</>;
                            },
                        },
                        container: {
                            createChartContainer(controller) {
                                return createDemoChartContainer({
                                    useController: controller,
                                    as: (props) => (
                                        <LineChartView
                                            {...props}
                                            style={{
                                                wrapper: {
                                                    gap: 4,
                                                },
                                                tick: {
                                                    color: 'blackAlpha.400',
                                                    fontSize: 'sm',
                                                },
                                                line: {
                                                    color: 'blackAlpha.100',
                                                },
                                                label: {
                                                    fontSize: 'sm',
                                                    fontWeight: 'medium',
                                                    color: 'blackAlpha.600',
                                                    _disabled: {
                                                        color: 'blackAlpha.400',
                                                    },
                                                },
                                                getLegend(index) {
                                                    return {
                                                        indicator: {
                                                            bg: LEGEND_COLORS.slice().reverse()[
                                                                index %
                                                                    LEGEND_COLORS.length
                                                            ],
                                                            borderRadius: 'full',
                                                        },
                                                    };
                                                },
                                            }}
                                            {...props}
                                        />
                                    ),
                                });
                            },
                        },
                    },
                    create(config) {
                        const DemoRoute = createDemoRoute(config);
                        return DemoRoute;
                    },
                },
            },
        },
        enhancer
    );
}
