import React from 'react';
import { LockIcon } from '@chakra-ui/icons';
import { IGNORE_EXPORT_DASHBOARDS } from '../../../../container';
import { DashboardRouteEnhancer } from '../../../entrypoint';
import { useDashboardContext, useFeatureApi } from '../../../app';

export function createFeatureExportStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useActions() {
                    const { dashboard } = useDashboardContext();
                    const api = useFeatureApi();
                    const featureset = api.getFeatureset();
                    const mapped = config.controller.useActions();
                    if (featureset.exports && !featureset.exports.enabled) {
                        return {
                            ...mapped,
                            getExportProps() {
                                return {
                                    ...mapped.getExportProps?.(),
                                    allowClickWhenDisabled: true,
                                    disabled: true,
                                    tooltipProps: {
                                        label: 'Upgrade to use export',
                                    },
                                    onClick: api.restrictClick(featureset.exports!),
                                    rightIcon: <LockIcon />,
                                };
                            },
                        };
                    }
                    if (IGNORE_EXPORT_DASHBOARDS.includes(dashboard.id)) {
                        return {
                            ...mapped,
                            getExportProps() {
                                return {
                                    ...mapped.getExportProps?.(),
                                    disabled: true,
                                    tooltipProps: {
                                        label: 'This dashboard does not yet support exports',
                                    },
                                    rightIcon: <LockIcon />,
                                };
                            },
                        };
                    }
                    return mapped;
                },
            },
        });
    };
}
