import React from 'react';
import { UrlOptionParser } from '../../../../base';
import { StudiesContextProvider } from '../../base';
import {
    StudiesSubmissionNewController,
    StudiesSubmissionNewDepsProvider,
    StudiesSubmissionNewLoader,
} from './studiesSubmissionNewInterface';
import { StudiesSubmissionViewProps } from './studiesSubmissionNewProps';
import { StudiesSubmissionNewParamsSchema } from './studiesSubmissionNewSchema';

export function createStudiesSubmissionNewRoute(
    options: UrlOptionParser,
    context: StudiesContextProvider,
    deps: StudiesSubmissionNewDepsProvider,
    controller: StudiesSubmissionNewController,
    loader: StudiesSubmissionNewLoader,
    View: React.FC<
        { children?: React.ReactNode | undefined } & StudiesSubmissionViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (containerProps) => {
        const viewParams = options.useOptions(StudiesSubmissionNewParamsSchema);
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext, {
            dataset: { id: viewParams.survey },
        });
        const viewDeps = deps.useDeps(viewContext, viewData);
        const viewProps = controller.useProps(viewContext, viewDeps, {
            item: viewData.data,
        });
        return <View {...viewProps} />;
    };
}
