import { DashboardListItem, Asset, DashboardListResponse } from '../../../api';
import {
    DASHBOARD_OVERRIDES,
    DASHBOARD_V2_SLUGS,
    HARDCODED_ASSET_IDS,
    HARDCODED_ASSET_WITH_BOTH_IDS,
} from './v2Constant';

/**
 * Replace overriden dashboards with the V2 version for eligble assets
 * @param assetId
 * @param response
 * @returns
 */
export function applyV2Dashboards(assetId: number, response: DashboardListResponse): DashboardListResponse {
    return response;
    // const shouldGetV2 = shouldGetV2Dashboard({ id: assetId });
    // // by default do not include dashboards marked as "v2"
    // const v1Dashboards = response.items.filter((item) => !isV2Dashboard(item));
    // // if (HARDCODED_ASSET_WITH_BOTH_IDS.includes(assetId)) {
    // //     return response;
    // // }

    // if (shouldGetV2) {
    //     console.log('replacing V2 dashboards');
    //     return {
    //         ...response,
    //         items: v1Dashboards.map((item) => {
    //             if (!item.slug) {
    //                 return item;
    //             }
    //             const replacement = DASHBOARD_OVERRIDES[item.slug];
    //             if (!replacement) {
    //                 return item;
    //             }
    //             return response.items.find((item) => item.slug === replacement.target) ?? item;
    //         }),
    //     };
    // }
    // return { ...response, items: v1Dashboards };
}

function isV2Dashboard(dashboard: Pick<DashboardListItem, 'slug'>) {
    if (!dashboard.slug) {
        // V2 dashboards always have slug
        return false;
    }
    return DASHBOARD_V2_SLUGS.includes(dashboard.slug);
}

function shouldGetV2Dashboard(asset: Pick<Asset, 'id'>) {
    return true;

    // if (HARDCODED_ASSET_IDS.includes(asset.id)) {
    //     return true;
    // }
    // // Roll out to 20% of assets
    // if (asset.id % 5 === 0) {
    //     return true;
    // }
    // return false;
}
