import StoreBase from './StoreBase';
import { action, computed, makeObservable, observable } from 'mobx';
import { registerUserWithOrg, updateUserWithOrg } from '../api/users';
import { User, RegistrationRequest, SocialLogin, UserUpdateRequest } from '../models/Account';

export class RegistrationStore extends StoreBase {
    isLoading: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            // Computed
            // valuesByDefinitionId: computed,
            // Actions
            register: action,
            setLoading: action,

            // XHR -
            //   getInvoiceData: action,
            //   payWithCreditCard: action,
        });
    }

    setLoading(flag: boolean) {
        if (flag && this.err) {
            this.err = null;
        }
        this.isLoading = flag;
    }

    updateUser = async (user: UserUpdateRequest) => {
        this.setLoading(true);
        try {
            const { data } = await updateUserWithOrg(user);
            await this.rootStore.auth.loginRefreshToken();
            // await this.rootStore.assets.fetchAllAssets();
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.updateUser(user);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    register = async (user: RegistrationRequest, socialLogin?: SocialLogin) => {
        this.setLoading(true);
        const authPayload = user.password || socialLogin;
        if (!authPayload) {
            throw new Error('either password or social login is required');
        }
        try {
            const { data } = await registerUserWithOrg(user);
            this.rootStore.auth.setUserDetails(data, true);
            await this.rootStore.auth.login(user.email, authPayload);
            this.rootStore.auth.reportEvent('registration', {
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                is_social_registration: !!socialLogin,
                USER_ID: data.id,
            });
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.register(user);
            } else {
                if (e) this.handleAxiosError(e);
                if (this.err?.errType == 'validationErr' && this.err.message?.indexOf('already exists')) {
                    await this.rootStore.auth.login(user.email, authPayload);
                }
            }
        } finally {
            this.setLoading(false);
        }
    };
}
