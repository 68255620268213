import { useMemo } from 'react';
import { Series } from '../../../../../api';
import { MetricDefinition } from '../../../../domain/metrics';
import { ValueFormatter } from '../../../../app/attributes';
import { AnyValue } from '../../../../domain/attributes';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { VisualizationController } from './visualizationInterface';
import {
    VisualizationScalarLabelProps,
    VisualizationViewProps,
} from './visualizationProps';

function formattedSeries(
    formatter: ValueFormatter,
    metrics: MetricDefinition[],
    series: Series
): Series<VisualizationScalarLabelProps | null> {
    return {
        ...series,
        data: series.data.map((row) => {
            return metrics.reduce((acc, metric) => {
                const value = row[metric.key] as AnyValue;
                if (!value) {
                    return acc;
                }
                const labelProps: VisualizationScalarLabelProps = {
                    label: formatter.format(metric.type, value),
                    value,
                };
                return {
                    ...acc,
                    [metric.key]: labelProps,
                };
            }, {});
        }),
    };
}

export function createVisualizationController(
    config: AnalysisRouteDeps
): VisualizationController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(data, props): VisualizationViewProps {
            const formattedSeriesByName = useMemo(() => {
                return data.response.queries.reduce((acc, query) => {
                    return query.series.items.reduce((acc, series) => {
                        const formatted = formattedSeries(
                            formatter,
                            props.visualization.metrics,
                            series
                        );
                        return { ...acc, [series.name]: formatted };
                    }, acc);
                }, {} as Record<string, Series<VisualizationScalarLabelProps | null>>);
            }, [data.response]);
            return {
                visualization: props.visualization,
                getTableProps(visualization) {
                    return {
                        context: props.context,
                        request: props.request,
                        response: data.response,
                        visualization,
                        getHeaderProps(series, index) {
                            return {
                                label: series.name,
                                description:
                                    props.request.segments?.[index]?.description ?? null,
                            };
                        },
                    };
                },
                getLineProps(visualization) {
                    return {
                        context: props.context,
                        request: props.request,
                        response: data.response,
                        visualization,
                    };
                },
                getDistributionProps(visualization) {
                    return {
                        context: props.context,
                        view: props.view,
                        request: props.request,
                        response: data.response,
                        visualization,
                    };
                },
                getScalarLabelProps(metric, series, value) {
                    return (
                        formattedSeriesByName[series.name]?.data[0]?.[metric.key] ?? {
                            label: 'N/A',
                            value: null,
                        }
                    );
                },
            };
        },
    };
}
