import { useState } from 'react';
import { IntegrationListStateProvider } from './integrationListInterface';
import { IntegrationListStateValue } from './integrationListModel';

export function createIntegrationListStateProvider(): IntegrationListStateProvider {
    return {
        useState(context, initialValues) {
            const [state, setState] = useState<IntegrationListStateValue>({
                search: '',
                ...initialValues,
            });
            return {
                value: state,
                onChange: setState,
            };
        },
    };
}
