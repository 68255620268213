import React from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { fetchElements } from '../../../util';
import { InstallLayout as InstallLayoutInterface } from '../../../route';

const InstallLayoutWrapper: InstallLayoutInterface['Wrapper'] = (props) => {
    const { Main } = fetchElements(props.children, {
        Main: InstallLayoutMain,
    });
    return (
        <Grid
            {...props.style?.wrapper}
            justifyContent="center"
            alignItems="center"
            h="full"
        >
            <GridItem {...props.style?.main} h="full">
                <Flex h="full" justifyContent="center" alignItems="center">
                    {Main}
                </Flex>
            </GridItem>
        </Grid>
    );
};

const InstallLayoutMain: InstallLayoutInterface['Main'] = (props) => {
    return <>{props.children}</>;
};

export const InstallLayoutImpl: InstallLayoutInterface = {
    Wrapper: InstallLayoutWrapper,
    Main: InstallLayoutMain,
};
