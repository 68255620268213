import { useMemo } from 'react';
import { PluginEntity } from '../../../domain';
import { PluginRepository, WorkspaceContextV2 } from '../../../app';
import {
    OpportunityLookup,
    OpportunityQuery,
    OpportunityRepository,
    OpportunityRepositoryOptions,
} from '../../../app/insight';
import { AnyCollection, AnyLookup, ApplicationError } from '../../../base';
import { OpportunityAggregate } from './insightModel';

export function createOpportunityService(repository: {
    opportunity: OpportunityRepository;
    plugin: PluginRepository;
}) {
    return {
        useFind(
            context: WorkspaceContextV2,
            command: OpportunityQuery,
            options: OpportunityRepositoryOptions = {}
        ): AnyCollection<OpportunityAggregate> {
            const query = {
                opportunity: repository.opportunity.useFind(context, command, {
                    staleTime: Infinity,
                    ...options,
                }),
                plugins: repository.plugin.useFind(
                    context,
                    {},
                    {
                        staleTime: Infinity,
                        ...options,
                    }
                ),
            };

            const pluginsById = useMemo(
                () =>
                    query.plugins.data?.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: item,
                        }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [query.plugins.data]
            );

            const items = useMemo(() => {
                return (
                    query.opportunity.data?.items.flatMap(
                        (item): OpportunityAggregate[] => {
                            const plugin = pluginsById[item.definition.plugin];
                            if (!plugin) {
                                console.warn(
                                    `insight plugin ${item.definition.plugin} not found`
                                );
                                return [];
                            }
                            return [{ insight: item, plugin }];
                        }
                    ) ?? []
                );
            }, [query.opportunity.data?.items, pluginsById]);

            if (query.opportunity.status !== 'loaded') {
                return {
                    ...query.opportunity,
                };
            }

            return {
                ...query.opportunity,
                data: {
                    ...query.opportunity.data,
                    items,
                },
            };
        },
        useLookup(
            context: WorkspaceContextV2,
            command: OpportunityLookup,
            options: OpportunityRepositoryOptions = {}
        ): AnyLookup<OpportunityAggregate> {
            const query = {
                opportunity: repository.opportunity.useLookup(context, command, {
                    staleTime: Infinity,
                    ...options,
                }),
                plugins: repository.plugin.useFind(
                    context,
                    {},
                    {
                        staleTime: Infinity,
                        ...options,
                    }
                ),
            };

            const pluginsById = useMemo(
                () =>
                    query.plugins.data?.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: item,
                        }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [query.plugins.data]
            );

            if (query.opportunity.status !== 'loaded') {
                return {
                    ...query.opportunity,
                };
            }

            const plugin = pluginsById[query.opportunity.data.definition.plugin];
            if (!plugin) {
                console.warn(
                    `plugin ${query.opportunity.data.definition.plugin} not found`
                );
                return {
                    ...query.opportunity,
                    data: null,
                    status: 'error',
                    error: new ApplicationError(
                        `plugin ${query.opportunity.data.definition.plugin} not found`
                    ),
                };
            }
            const item: OpportunityAggregate = {
                insight: query.opportunity.data,
                plugin,
            };

            return {
                ...query.opportunity,
                data: item,
            };
        },
    };
}
