import React from 'react';
import { Icon, Tooltip } from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { useAuthenticatedContext } from '../../../../container';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { useFeatureApi } from '../../../app';
import { AssetListController } from '../../../view/assets';
import { FeatureMiddleware } from '../featureInterface';

export function createFeatureMultiAssetMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(controller: AssetListController): AssetListController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, deps, props] = args;
                    const viewProps = controller.useProps(...args);

                    const { feature, restrict } = api.useFeature('multi_assets');

                    if (feature?.enabled) {
                        return viewProps;
                    }

                    return {
                        ...viewProps,
                        getItemProps(...args) {
                            const itemProps = viewProps.getItemProps(...args);
                            if (itemProps.isDefault) {
                                return itemProps;
                            }
                            return {
                                ...itemProps,
                                getDataSourceProps() {
                                    const sourceProps = itemProps.getDataSourceProps();
                                    if (sourceProps === null) {
                                        return sourceProps;
                                    }
                                    return { ...sourceProps, to: null };
                                },
                                getButtonTooltipProps() {
                                    return {
                                        label: 'Upgrade for multiple assets',
                                        isDisabled: false,
                                    };
                                },
                                getButtonProps() {
                                    return {
                                        ...itemProps.getButtonProps(),
                                        children: 'Manage',
                                        leftIcon: <Icon as={LockIcon} />,
                                        onClick(event) {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            restrict({
                                                ...feature!,
                                                value: null,
                                            });
                                        },
                                    };
                                },
                            };
                        },
                    };
                },
            };
        }
        return create({
            ...config,
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createList(...args) {
                        const controller = config.controller.assets.createList(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });
    };
}

export function createFeatureMultiAssetStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            module: {
                ...config.module,
                createWorkspaceSelectionModule(moduleConfig) {
                    return config.module.createWorkspaceSelectionModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(context) {
                                        const mapped = controller.useProps(context);
                                        const appcontext = useAuthenticatedContext();
                                        const api = useFeatureApi();
                                        const featureset = api.getFeatureset();
                                        const response = appcontext.access.canAccess({
                                            ...featureset.multi_assets!,
                                            value: null,
                                        });
                                        return {
                                            ...mapped,
                                            // getAddButtonProps() {
                                            //     if (response.restricted) {
                                            //         return {
                                            //             ...mapped.getAddButtonProps?.(),
                                            //             allowClickWhenDisabled: true,
                                            //             disabled: true,
                                            //             tooltipProps: {
                                            //                 label: 'Upgrade for multiple assets',
                                            //             },
                                            //             onClick: api.restrictClick(
                                            //                 featureset.multi_assets!
                                            //             ),
                                            //         };
                                            //     }
                                            //     return mapped.getAddButtonProps?.() ?? {};
                                            // },
                                            getItemProps(workspace, index) {
                                                const original = mapped.getItemProps?.(
                                                    workspace,
                                                    index
                                                );
                                                if (response.restricted && index > 0) {
                                                    return {
                                                        ...original,
                                                        disabled: true,
                                                        allowClickWhenDisabled: true,
                                                        rightSection: (
                                                            <Tooltip label="Upgrade for multiple assets">
                                                                <LockIcon />
                                                            </Tooltip>
                                                        ),
                                                        onClick: api.restrictClick(
                                                            featureset.multi_assets!
                                                        ),
                                                    };
                                                }

                                                return (
                                                    mapped.getItemProps?.(
                                                        workspace,
                                                        index
                                                    ) ?? {}
                                                );
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
            // controller: {
            //     ...config.controller,
            //     workspaceSelection: {
            //         useProps() {
            //             const context = useAuthenticatedContext();
            //             const mapped = config.controller.workspaceSelection.useProps();
            //             const api = useFeatureApi();
            //             const featureset = api.getFeatureset();
            //             const response = context.access.canAccess({
            //                 ...featureset.multi_assets,
            //                 value: null,
            //             });
            //             return {
            //                 ...mapped,
            //                 getAddButtonProps() {
            //                     if (response.restricted) {
            //                         return {
            //                             ...mapped.getAddButtonProps?.(),
            //                             allowClickWhenDisabled: true,
            //                             disabled: true,
            //                             tooltipProps: {
            //                                 label: 'Upgrade for multiple assets',
            //                             },
            //                             onClick: api.restrictClick(
            //                                 featureset.multi_assets
            //                             ),
            //                         };
            //                     }
            //                     return mapped.getAddButtonProps?.() ?? {};
            //                 },
            //                 getItemProps(workspace, index) {
            //                     if (response.restricted && index > 0) {
            //                         return {
            //                             ...mapped.getItemProps?.(workspace, index),
            //                             rightSection: (
            //                                 <Tooltip label="Upgrade for multiple assets">
            //                                     <LockIcon />
            //                                 </Tooltip>
            //                             ),
            //                             onClick: api.restrictClick(
            //                                 featureset.multi_assets
            //                             ),
            //                         };
            //                     }

            //                     return mapped.getItemProps?.(workspace, index) ?? {};
            //                 },
            //             };
            //         },
            //     },
            // },
        });
    };
}
