import React, { useEffect, useState } from 'react';
import { createRangeInputController } from './inputRangeController';
import { RangeInputDeps } from './inputRangeInterface';
import { RangeInputContainerPropsV2 } from './inputRangeProps';

export function createRangeInputContainer(): React.FC<
    { children?: React.ReactNode | undefined } & RangeInputContainerPropsV2
> {
    const controller = createRangeInputController();
    return ({ as: View, ...props }) => {
        const [localValue, setLocalValue] = useState(props.value);
        const deps: RangeInputDeps = {
            state: {
                value: localValue,
                onChange: setLocalValue,
            },
        };
        const viewProps = controller.useProps(deps, props);
        return <View {...viewProps} />;
    };
}
