import { ReportTableVisualization } from './visualizationModel';
import { ReportTableVisualizationProps } from './visualizationProps';

export function buildTableVisualization(
    props: ReportTableVisualizationProps
): ReportTableVisualization {
    return {
        kind: 'table',
        data: props.data,
        columns: props.columns.map((column) => ({
            ...column,
            description: column.description ?? null,
        })),
    };
}
