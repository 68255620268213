import React from 'react';
import { createEmail } from '../../../vendor';
import { EmailInsightViewProps } from './emailInsightProps';
import { EmailReportViewConfig } from './emailInsightConfig';
// @ts-expect-error
import CSS from '!!raw-loader!./emailInsightView.css';

const Email = createEmail({
    css: CSS,
    frameName: 'template',
});

export function createEmailInsightView(
    config: EmailReportViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & EmailInsightViewProps> {
    return (props) => {
        const {
            formatPeriod = ({ start, end }) =>
                `${start.toISOString()} - ${end.toISOString()}`,
        } = props;
        const title = props.current.insight.title.replace(/\.$/, '');
        const subject = `Varos Insight: ${title} (${formatPeriod(props.period)})`;
        const ctaProps = props.getCtaProps();
        return (
            <Email.Html id="template" className="template-container">
                <Email.Meta title={subject} metadata={props.metadata} />
                <Email.Container className="container">
                    <div id="container-bar" />
                    <Email.Section className="section">
                        <Email.Row>
                            <Email.Column align="center">
                                <Email.Image
                                    width={120}
                                    height={40}
                                    src="https://cdn.varos.io/assets/common/varos-logo-text.png"
                                />

                                <Email.Heading level={3}>
                                    {formatPeriod(props.period)} &#x2022;{' '}
                                    {props.workspace.name}
                                </Email.Heading>
                            </Email.Column>
                        </Email.Row>
                    </Email.Section>
                    <Email.Divider className="divider" />
                    <Email.Section className="section" spacing={24}>
                        <Email.Row>
                            <Email.Column className="column" spacing={24} align="center">
                                <Email.Row width="inherit">
                                    {props.current.plugin.iconUrl && (
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                borderRadius: 3,
                                                                padding: 8,
                                                                background: '#eaeaea',
                                                                lineHeight: 0,
                                                            }}
                                                        >
                                                            <Email.Image
                                                                width={24}
                                                                height={24}
                                                                src={
                                                                    props.current.plugin
                                                                        .iconUrl
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    )}
                                    <td
                                        style={{
                                            paddingLeft: 16,
                                        }}
                                    >
                                        <Email.Heading level={1}>{title}</Email.Heading>
                                    </td>
                                    <td></td>
                                </Email.Row>
                                <Email.Text>
                                    <strong>📊 Market situation</strong>{' '}
                                    {props.current.insight.body}
                                </Email.Text>
                                {props.current.insight.hint ? (
                                    <Email.Text>
                                        💡 <strong>Varos idea:</strong>{' '}
                                        {props.current.insight.hint}
                                    </Email.Text>
                                ) : null}
                            </Email.Column>
                        </Email.Row>
                        <Email.Row>
                            <Email.Column align="center" spacing={12}>
                                <Email.Link
                                    className="button button-cta"
                                    href={ctaProps.href}
                                >
                                    {ctaProps.label}
                                </Email.Link>
                                <Email.Text className="mute">
                                    Or turn off these notifications{' '}
                                    <Email.Link href={props.notificationSettingsHref}>
                                        here
                                    </Email.Link>
                                </Email.Text>
                            </Email.Column>
                        </Email.Row>
                    </Email.Section>
                </Email.Container>
            </Email.Html>
        );
    };
}
