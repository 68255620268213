import { AxiosError, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { snakeCaseKeys } from '../../../v2/util';
import { isDomainErrorDto } from '../../../v2/api/common/error';
import { ApplicationError } from '../../../v2/base/error/errorModel';
import { isErrorInfo } from '../../../utils/axiosUtils';
import { QueryRunPayload, QueryResponse } from './queryModel';
import { serializeComparison, serializeCondition } from './querySerializer';
import { NotEnoughDataError } from './queryError';
import { QUERY_FIXTURE } from './queryFixture';
import moment from 'moment';

export async function runQuery(
    api: AxiosInstance,
    payload: QueryRunPayload,
    options: {
        signal?: AbortSignal;
        apiKey?: string;
        organization?: number;
        headers?: AxiosRequestConfig['headers'];
        version?: 'v2' | 'v3';
    } = {}
): Promise<QueryResponse> {
    const { signal } = options;
    signal?.addEventListener('abort', () => {
        console.log('Query was cancelled by TanStack Query');
    });

    // await new Promise((resolve) => setTimeout(resolve, 1000));

    // return QUERY_FIXTURE(payload);

    try {
        // @ts-expect-error
        const headers: Record<string, string> = { ...options.headers };
        if (options.organization) {
            headers['x-varos-organization'] = options.organization.toString();
        }
        const response = await api.post<QueryRunPayload, AxiosResponse<QueryResponse>>(
            `/api/${payload.example ? 'v3' : options.version || 'v2'}/query`,
            {
                ...payload,
                query: {
                    ...payload.query,
                    filters: payload.query.filters?.map((condition) => {
                        return serializeCondition(condition);
                    }),
                    comparison: payload.query.comparison
                        ? serializeComparison(payload.query.comparison)
                        : payload.query.comparison,
                },
            },
            {
                headers,
                params: options.apiKey ? { 'api-key': options.apiKey } : {},
                signal,
            }
        );

        return {
            ...response.data,
            // queries: response.data.queries.map((query) => ({
            //     ...query,
            //     series: {
            //         ...query.series,
            //         items: query.series.items.map((series) => {
            //             return { ...series, data: series.data.map(snakeCaseKeys) };
            //         }),
            //     },
            // })),
            metadata: {
                ...response.data.metadata,
                period: response.data.metadata.period
                    ? {
                          start: parseUTCFromDateString(
                              // @ts-expect-error
                              response.data.metadata.period.start
                          ),
                          // @ts-expect-error
                          end: parseUTCFromDateString(response.data.metadata.period.end),
                      }
                    : null,
                comparison: response.data.metadata.comparison
                    ? {
                          start: parseUTCFromDateString(
                              // @ts-expect-error
                              response.data.metadata.comparison.start
                          ),
                          end: parseUTCFromDateString(
                              // @ts-expect-error
                              response.data.metadata.comparison.end
                          ),
                      }
                    : null,
            },
        };
    } catch (error) {
        if (
            isErrorInfo(error) &&
            isAxiosError(error.internalErr) &&
            // @ts-expect-error
            error.internalErr.response?.data?.detail === 'not_enough_data'
        ) {
            throw new NotEnoughDataError();
        }
        if (
            isAxiosError(error) &&
            error.response &&
            isDomainErrorDto(error.response.data) &&
            error.response.data?.detail === 'not_enough_data'
        ) {
            throw new NotEnoughDataError();
        }
        if (
            isAxiosError(error) &&
            error.response &&
            isDomainErrorDto(error.response.data)
        ) {
            throw new ApplicationError('server', error.response.data.detail);
        }
        throw error;
    }
}

function isAxiosError(error: Error | unknown): error is AxiosError {
    return !!(error as AxiosError)?.isAxiosError;
}

function toIsoDate(date: Date) {
    return date.toISOString().split('T')[0];
}

function parseUTCFromDateString(dateString: string) {
    // return new Date(dateString);
    return moment.utc(dateString).startOf('day').toDate();
}

window.moment = moment;
