import {
    InFlightMessage,
    IntegrationIssueMessages,
    DefaultIssueMessage,
    DatasetIssueMessage,
} from '../../config/account-status';
import { AssetActiveIssue } from '../../api/account-status';
import { AccountStatusDto } from '../../api/account-status';
import { AccountStatus, AccountStatusNotFound } from './accountStatusProps';

export function getMessage(assetIssue: AssetActiveIssue) {
    switch (assetIssue.type) {
        case 'integration': {
            return IntegrationIssueMessages[assetIssue.reason];
        }
        case 'dataset':
            return DatasetIssueMessage;
        default:
            return DefaultIssueMessage;
    }
}

export function getStatusDetail(
    assetId: number,
    statuses: AccountStatusDto[]
): AccountStatus | AccountStatusNotFound {
    const currentAssetDto = statuses.find((x) => x.asset_id == assetId);
    if (currentAssetDto) {
        if (currentAssetDto.issues && currentAssetDto.issues.length > 0) {
            const [first] = currentAssetDto.issues;
            return {
                status: 'retrieved',
                details: {
                    level: 'warning',
                    message: getMessage(first),
                },
            };
        } else if (currentAssetDto.is_sync_in_flight) {
            return {
                status: 'retrieved',
                details: {
                    level: 'info',
                    message: InFlightMessage,
                },
            };
        }
    }
    return {
        status: 'not_found',
    };
}
