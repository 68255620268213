import { isMovement, Calculation, formatType, Schema } from '../../../domain';
import { StatVisualizationStrategy } from './statModel';
import { StatMetricProps } from './statProps';

export const statStrategy: StatVisualizationStrategy = (
    context,
    visualization,
    request,
    response,
    config,
    entities
) => {
    const [valueQuery, captionQuery, ...rest] = response.queries;

    if (rest.length > 0) {
        // console.warn('stat chart does not support more than 2 queries');
    }

    const [scalarKey, captionKey, ...restScalars] = response.metadata.scalar;

    if (!scalarKey) {
        throw new Error(`scalar key not found for stat query`);
    }

    if (restScalars.length > 0) {
        console.warn('stat chart does not support multiple scalar values');
    }

    const movementQueryIndex = response.queries.findIndex((candidate) =>
        isMovement(candidate.schema.properties[scalarKey].type)
    );

    const previousQueryIndex = response.queries.findIndex((candidate) =>
        // hack, need a proper way to deterministically select the query with the previous value
        candidate.key.includes('_previous')
    );

    const movementQuery =
        movementQueryIndex >= 0 ? response.queries[movementQueryIndex] : null;
    const previousQuery =
        previousQueryIndex >= 0 ? response.queries[previousQueryIndex] : null;

    const valueProperties: Schema['properties'] = Object.entries(
        valueQuery.schema.properties ?? {}
    ).reduce((propAcc, [key, value]) => ({ ...propAcc, [key]: value }), {});

    const movementProperties: Schema['properties'] = Object.entries(
        movementQuery?.schema.properties ?? {}
    ).reduce((propAcc, [key, value]) => ({ ...propAcc, [key]: value }), {});

    const scalarType = valueProperties[scalarKey].type;
    const movementType = movementProperties[scalarKey]?.type;

    const getCalculation = (metric: StatMetricProps): Calculation => ({
        property: {
            // use this to use the name of the underlying metric as the label which is eventually what we want:
            // name: metric.metric,
            name: entities.card?.title ?? 'unknown',
            type: metric.type,
        },
        period: response.metadata.period,
        comparison: response.metadata.comparison,
        value: metric.value,
        segment: metric.segment,
    });

    return {
        getCalculation,
        context,
        metrics: valueQuery.series.items.map<StatMetricProps>(
            (series, indexSeries): StatMetricProps => {
                const indexRow = 0;
                const valueValue = series.data[indexRow]?.[scalarKey] ?? null;
                const movementValue =
                    movementQuery?.series.items[indexSeries].data[indexRow]?.[
                        scalarKey
                    ] ?? null;
                const previousValue =
                    previousQuery?.series.items[indexSeries].data[indexRow]?.[
                        scalarKey
                    ] ?? null;

                if (valueValue === null) {
                    return {
                        inverse: response.metadata.inverse.includes(scalarKey),
                        segment: null,
                        metric: valueProperties[scalarKey]?.name ?? scalarKey,
                        label: series.name,
                        type: scalarType,
                        value: {
                            current: null,
                            change: null,
                            previous: null,
                        },
                    };
                }

                if (typeof valueValue !== 'number') {
                    throw new Error(
                        `series '${series.name}' has invalid value ${JSON.stringify(
                            valueValue
                        )}`
                    );
                }
                let type: StatMetricProps['direction'] = undefined;
                if (valueValue !== null && previousValue !== null) {
                    type =
                        valueValue > (previousValue as number) ? 'increase' : 'decrease';
                }

                let secondary: StatMetricProps['secondary'] = undefined;
                if (movementQuery) {
                    secondary =
                        movementValue && movementType
                            ? formatType(context, movementType, movementValue, {
                                  notation: 'short',
                                  movementPrefix: false,
                              })
                            : null;
                }

                const segment = request.segments?.[indexSeries];
                return {
                    inverse: response.metadata.inverse.includes(scalarKey),
                    segment: segment ?? null,
                    direction: type,
                    type: scalarType,
                    metric: valueProperties[scalarKey]?.name ?? scalarKey,
                    label: series.name,
                    value: {
                        current: Number(valueValue) ?? null,
                        change: Number(movementValue) ?? null,
                        previous: Number(previousValue),
                    },
                    secondary,
                };
            }
        ),
    };
};
