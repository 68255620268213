import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { ListBoxItemProps } from '../item';
import { ListBoxConfig } from '../listBoxConfig';
import { ListBoxListController } from './listBoxListInterface';
import { ListBoxListControllerProps } from './listBoxListProps';

export function createListBoxListController<TItem>(
    config: ListBoxConfig<TItem>
): ListBoxListController<TItem> {
    const { getValue } = config;

    function buildItemProps(
        props: ListBoxListControllerProps<TItem>,
        item: TItem
    ): ListBoxItemProps {
        // const baseProps = config.getItemProps(item);
        const itemValue = getValue(item);
        const isSelected = props.cache.has(itemValue);
        return {
            id: itemValue,
            value: itemValue,
            isSelected,
            button: {
                onClick: props.onToggle.bind(null, itemValue),
            },
        };
    }

    return {
        useProps(item, props) {
            const itemPropsByValue = useMemo(() => {
                return item.options.reduce(
                    (acc, item) => {
                        const itemProps = buildItemProps(props, item);
                        return {
                            ...acc,
                            [itemProps.id]: itemProps,
                        };
                    },
                    {} as Record<string, ListBoxItemProps | undefined>
                );
            }, [props, item.options]);

            return {
                items: item.options,
                getItemProps(item) {
                    const itemValue = getValue(item);
                    const itemProps = itemPropsByValue[itemValue];
                    assert(itemProps, 'no items props');
                    return itemProps;
                },
            };
        },
    };
}
