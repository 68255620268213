import { useMemo } from 'react';
import {
    buildStudyItemViewProps,
    StudyDependencyItemProps,
} from '../../../../view/studies';
import { PageIds } from '../../../../config';
import { assert } from '../../../../util/assert';
import { DemographicsKPIReport } from '../../../../config/report/fixture';
import { StudiesDetailController } from './studiesReportDetailInterface';
import { StudiesDetailViewProps } from './studiesReportDetailProps';
import { StudiesControllerBaseConfig } from '../../base';

export function createStudiesDetailController(
    config: StudiesControllerBaseConfig
): StudiesDetailController {
    const {
        controller: { help: helpController, image: imageConroller },
        provider: { createPageController },
        navigate,
    } = config;

    const pageController = createPageController({
        id: PageIds.REPORTS_DETAIL,
        title: 'Report',
        description: null,
        provider: config.provider,
    });

    return {
        useProps(context, item, props): StudiesDetailViewProps {
            const {
                report: { dataset },
            } = item;
            const pageProps = pageController.useProps(
                {
                    entity: item.report.report,
                },
                {}
            );

            const imageProps = imageConroller.useProps(item.image, {});

            const helpProps = helpController.useProps({
                id: 'request_a_report',
            });

            const studyProps = useMemo(
                () => buildStudyItemViewProps(context, item.report),
                [context, item.report]
            );

            return {
                page: pageProps,
                image: imageProps,
                study: {
                    ...studyProps,
                    dependencies: {
                        ...studyProps.dependencies,
                        items: studyProps.dependencies.items.map(
                            (dependency): StudyDependencyItemProps => {
                                if (dependency.kind === 'interview') {
                                    assert(
                                        dataset,
                                        'expected dataset to have been loaded for interview dependency'
                                    );
                                    return {
                                        ...dependency,
                                        link: {
                                            ...dependency.link,
                                            form: {
                                                id: 'report_dependency_start_link',
                                                ...navigate.assistant.thread.create({
                                                    location: props.location,
                                                    thread: {
                                                        type: 'interview',
                                                        data: {
                                                            interview: dataset.id,
                                                            asset: context.workspace
                                                                .id as number,
                                                        },
                                                    },
                                                }),
                                            },
                                        },
                                    };
                                }
                                return dependency;
                            }
                        ),
                    },
                },
                legal: {
                    terms: {
                        link: navigate.legal.termsOfService({
                            location: props.location,
                        }),
                    },
                },
                help: helpProps,
                highlight:
                    item.report.report.kind === 'external'
                        ? {
                              label: 'Beta',
                              description:
                                  'This report is in beta and is actively being tested using internal tools',
                          }
                        : null,
                actions: {
                    open:
                        item.report.report.kind === 'external' &&
                        item.report.report.id === DemographicsKPIReport.entity.id
                            ? // TODO proper feature flag
                              {
                                  caption: null,
                                  links: {
                                      response: {
                                          to: `/u/reports?template=demographics`,
                                      },
                                  },
                                  anchors: {
                                      response: null,
                                  },
                              }
                            : item.report.report.kind === 'dataset'
                              ? {
                                    caption: null,
                                    links: {
                                        response: {
                                            to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.report.id}/data`,
                                        },
                                    },
                                    anchors: {
                                        response: null,
                                    },
                                }
                              : {
                                    caption: {
                                        label: 'External report',
                                        description: `This report is in beta will opened in a separate tab using the Hex data platform`,
                                    },
                                    links: {
                                        response: null,
                                    },
                                    anchors: {
                                        response: {
                                            id: 'open_report',
                                            href: item.report.report.url,
                                            target: '_blank',
                                        },
                                    },
                                },
                },
            };
        },
    };
}
