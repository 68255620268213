import { PeerContextConfig } from './peerBaseConfig';
import { CompanyContextProvider } from './peerBaseInterface';

export function createCompanyContextProvider(
    config: PeerContextConfig
): CompanyContextProvider {
    const { hook } = config;
    return {
        useContext() {
            return {
                organization: hook.useOrganization(),
                workspace: hook.useWorkspace(),
                auth: hook.useAuth(),
            };
        },
    };
}
