import {
    Link as ChakraLink,
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    StackDivider,
    Tag,
    Text,
    useDisclosure,
    VStack,
    Button,
    Center,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    WrapItem,
    Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../../route';
import { SettingDatasetConnectionConfig } from '../../connectionConfig';
import { SettingDatasetConnectionViewProps } from '../../connectionProps';
import { DatasetConnectionItemProps } from '../../../../../../view/datasets';

export function createSettingAssetConnectionEmptyView(
    init: SettingDatasetConnectionConfig,
    config: SettingAccountItemProviderConfig
): React.FC<
    { children?: React.ReactNode | undefined } & SettingDatasetConnectionViewProps
> {
    const { controller } = init;
    const {
        UI: {
            Application: { Link, Heading, Tooltip },
        },
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const viewProps = props.getViewProps();

        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem
                                key={index}
                                to={item.to ?? undefined}
                                description={item.description}
                            >
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                </Header>
                <Content>
                    <Alert status="warning">
                        <AlertIcon></AlertIcon>
                        <AlertTitle w="full">
                            <HStack w="full" justify="space-between">
                                <ChakraLink as={Link} {...props.getNewLinkProps()}>
                                    Data sources are not configured
                                </ChakraLink>
                                <ChakraLink as={Link} {...props.getNewLinkProps()}>
                                    Add data source
                                </ChakraLink>
                            </HStack>
                        </AlertTitle>
                    </Alert>
                </Content>
            </Container>
        );
    };
}
