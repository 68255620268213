import { useQuery } from '@tanstack/react-query';
import { normalizeCollection } from '../../../base';
import { IndustryAdapter } from './industryAdapter';
import { IndustryRepository } from './industryInterface';

export function createIndustryRepository(adapter: IndustryAdapter): IndustryRepository {
    const PREFIX = ['companies', 'industries'];
    return {
        useFind(context, props, options) {
            const query = useQuery({
                queryKey: [...PREFIX, 'find', props],
                async queryFn() {
                    const response = await adapter.find(context, props);
                    return normalizeCollection(response);
                },
                staleTime: Infinity,
                ...options,
            });
            return query;
        },
    };
}
