import { isCurrencyType, isNumericType, isTreeType } from '../../../../domain/attributes';
import { castTypeToDomain } from '../../../../impl';
import { AnyFilter } from '../../../../domain';
import { SegmentController } from './segmentInterface';
import { AnalysisRouteDeps } from '../analysisRouteConfig';

export function createSegmentController(config: AnalysisRouteDeps): SegmentController {
    const {
        controller,
        hook: { useFormatter },
    } = config;
    return {
        useProps(state, data, props) {
            // const formatter = useFormatter();
            // console.log('DEBUG segment data', data);

            const groupProps = controller.group.useProps({
                query: {
                    competitive: data.query.competitive,
                },
                form: data.form.group,
                item: {
                    plugin: props.plugin,
                    group: {
                        plugin: {
                            id: props.plugin.id,
                        },
                        default: false,
                        conditions: [],
                    },
                },
                async onSubmit(values) {
                    console.log('changed');
                },
            });

            const filterProps = controller.trait.useProps(groupProps.getFilterProps());

            // console.log('DEBUG filterProps', filterProps);

            return filterProps;

            // return {
            //     filters:
            //         props.plugin.traits.flatMap((item): AnyFilter[] => {
            //             // const itemType = castTypeToDomain(item.type);
            //             const itemType = item.type;
            //             if (isCurrencyType(itemType)) {
            //                 return [
            //                     {
            //                         property: {
            //                             key: item.key,
            //                             name: item.name,
            //                             type: itemType,
            //                             description: item.description ?? null,
            //                             constraints: itemType.constraints,
            //                         },
            //                         default: {
            //                             operator: 'between',
            //                         },
            //                     },
            //                 ];
            //             }
            //             // const resolved = data.members.find(
            //             //     (member) => member.key === item.key
            //             // );
            //             // if (!resolved) {
            //             //     console.error(
            //             //         `failed to resolve type members for ${item.key}`
            //             //     );
            //             //     return [];
            //             // }
            //             if (isTreeType(item.type)) {
            //                 return [
            //                     {
            //                         property: {
            //                             key: item.key,
            //                             name: item.name,
            //                             type: item.type,
            //                             description: item.description ?? null,
            //                             constraints: [],
            //                         },
            //                         default: {
            //                             operator: 'in',
            //                         },
            //                     },
            //                 ];
            //             }
            //             console.warn(`unexpected filter item`, item);
            //             return [
            //                 // {
            //                 //     property: {
            //                 //         key: item.key,
            //                 //         name: item.name,
            //                 //         type: resolved,
            //                 //         description: item.description ?? null,
            //                 //         constraints: [],
            //                 //     },
            //                 //     default: {
            //                 //         operator:
            //                 //     },
            //                 // },
            //             ];
            //         }) ?? [],
            //     getItemProps(disclosure, filter, index) {
            //         const value = state.value[filter.property.key];
            //         return {
            //             disclosure,
            //             filter,
            //             getInputProps(props) {
            //                 return props;
            //             },
            //             value: { value },
            //             onChange(condition) {
            //                 if (
            //                     Array.isArray(condition.value) &&
            //                     condition.value.length === 0
            //                 ) {
            //                     const { [filter.property.key]: _removed, ...rest } =
            //                         state.value;
            //                     return state.onChange(rest);
            //                 }
            //                 state.onChange({
            //                     ...state.value,
            //                     [filter.property.key]: condition.value,
            //                 });
            //             },
            //             onClear() {
            //                 state.onChange({
            //                     ...state.value,
            //                     [filter.property.key]: null,
            //                 });
            //             },
            //             format(type, value, options) {
            //                 // return JSON.stringify(value);
            //                 if (
            //                     isCurrencyType(type) &&
            //                     typeof value === 'number' &&
            //                     value === 0
            //                 ) {
            //                     return formatter.currency(1, type.currency, options);
            //                 }
            //                 if (isCurrencyType(type) && typeof value === 'number') {
            //                     return formatter.currency(value, type.currency, options);
            //                 }
            //                 // @ts-expect-error
            //                 return value.toString();
            //             },
            //         };
            //     },
            // };
        },
    };
}
