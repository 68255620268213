import { useMemo } from 'react';
import { assert } from '../../../util/assert';
import { BillingChargeCompleteConfig } from './chargeCompleteConfig';
import { ChargeCompletedLoader } from './chargeCompleteInterface';

export function createChargeCompletedLoader(
    config: Pick<BillingChargeCompleteConfig, 'repository'>
): ChargeCompletedLoader {
    const { repository } = config;
    return {
        useLoad(context, sessionId) {
            const subscription = repository.subscription.useLookup(context, {
                suspense: true,
            });
            const charge = repository.charge.useCharge(context, { sessionId}, {
                suspense: true
            });
            
            assert(subscription.status === 'success', 'expected suspense query');
            assert(charge.status === 'success', 'expected suspense query');
            
            return useMemo(
                () => ({
                    subscription: subscription.data,
                    charge: charge.data,
                }),
                [subscription.data, charge.data]
            );
        },
    };
}
