import { SubscriptionDto } from './subscriptionModel';

export function assertSubscriptionExpanded<
    TEntity extends SubscriptionDto,
    TKey extends keyof Pick<TEntity, 'asset' | 'definition'>
>(
    entity: TEntity,
    keys: TKey[]
): asserts entity is TEntity & {
    [TLoaded in TKey]: Exclude<TEntity[TLoaded], string | number>;
} {
    for (const key of keys) {
        if (typeof entity[key] === 'number' || typeof entity[key] === 'string') {
            throw new Error(`key not loaded`);
        }
    }
}
