import { ValueFormatter } from '../../../app/attributes';
import { QueryRequest, AnyQueryResponse, isSuccessResponse } from '../../../domain/query';
import { ReportTableViewProps } from './reportTableProps';

export interface ReportTableControllerOptions {
    responses: AnyQueryResponse[];
}

export interface ReportTableChartControllerConfig {
    formatter: ValueFormatter;
}

export interface ReportTableController {
    useProps(options: ReportTableControllerOptions): ReportTableViewProps;
}
export function createReportTableController(): ReportTableController {
    return {
        useProps({ responses }) {
            const [{ response: first }, ...rest] = responses.flatMap((response) =>
                isSuccessResponse(response) ? [response] : []
            );
            const [sampleQuery] = first.queries;
            return {
                columns: Object.values(sampleQuery.schema.properties)
                    .filter((property) => !first.metadata.scalar.includes(property.key))
                    .flatMap((property) => ({
                        key: property.key,
                        title: property.name,
                        description: property.description ?? null,
                    })),
                data: responses.flatMap((response) =>
                    isSuccessResponse(response)
                        ? response.response.queries.flatMap((query) =>
                              query.series.items.flatMap((series) => series.data)
                          )
                        : []
                ),
            };
        },
    };
}
