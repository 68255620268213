import { PeerLoaderConfig } from '../base';
import { PeerOverviewRouteLoader } from './peerOverviewInterface';
import { PeerOverviewRouteAggregate } from './peerOverviewModel';

export function createPeerOverviewLoader(
    config: PeerLoaderConfig
): PeerOverviewRouteLoader {
    return {
        useLoad(context): PeerOverviewRouteAggregate {
            return {};
        },
    };
}
