import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { MappingConnection } from '../../../../domain/assets';
import { ReportSelectConfig } from './reportSelectConfig';
import { ReportSelectLoader } from './reportSelectInterface';
import { ReportSelectAggregate, ReportSelectAssetAggregate } from './reportSelectModel';

export function createReportSelectLoader(config: ReportSelectConfig): ReportSelectLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const assets = {
                find: repository.asset.useFind(context, {}, { staleTime: Infinity }),
                create: repository.asset.useCreate(context),
            };
            const integrations = repository.integration.useFind(context, {}, {});
            const mappings = repository.mapping.useFind(
                context,
                {
                    integrations: integrations.data?.items ?? [],
                },
                { enabled: integrations.status === 'success' }
            );

            const mappingsByAsset = useMemo(
                () =>
                    groupBy(mappings.data?.items, (item) => item.asset.id) as Record<
                        string,
                        MappingConnection[] | undefined
                    >,
                [mappings.data?.items]
            );

            const status = [
                assets.find.status,
                integrations.status,
                mappings.status,
            ].some((item) => item === 'loading')
                ? 'loading'
                : 'success';

            return {
                status,
                asset: {
                    create: assets.create,
                },
                item: useQuery({
                    enabled: status === 'success',
                    queryKey: [
                        'report',
                        'select',
                        'aggregate',
                        status,
                        context.organization.id,
                    ],
                    queryFn(): ReportSelectAggregate {
                        return {
                            items:
                                assets.find.data?.flatMap(
                                    (item): ReportSelectAssetAggregate[] => {
                                        const mappings = mappingsByAsset[item.id] ?? [];
                                        // if (!mappings) {
                                        //     console.warn(
                                        //         `asset '${item.id}' does not have any mappings`
                                        //     );
                                        //     return [];
                                        // }
                                        return [
                                            {
                                                asset: item,
                                                mappings,
                                            },
                                        ];
                                    }
                                ) ?? [],
                        };
                    },
                }),
            };
        },
    };
}
