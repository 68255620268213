import { ScheduledReportsModuleConfig } from './scheduleConfig';

export function createScheduledReportsModule(config: ScheduledReportsModuleConfig) {
    return {
        Container: {
            List: config.provider.list.createContainer({
                hook: {
                    useAuth: config.deps.hook.useAuth,
                    useOrganization: config.deps.hook.useOrganization,
                },
                controller: config.provider.list.createController({
                    service: config.deps.service,
                }),
                View: config.provider.list.createView({
                    UI: config.deps.UI,
                    Layout: config.deps.Layout,
                    Container: {
                        ScheduleForm: config.provider.edit.createContainer({
                            hooks: config.deps.hook,
                            controller: config.provider.edit.createController({
                                service: {
                                    collaborator: config.deps.service.collaborator,
                                },
                            }),
                            View: config.provider.edit.createView({ UI: config.deps.UI }),
                        }),
                    },
                }),
            }),
        },
    };
}
