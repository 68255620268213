import { compose } from 'lodash/fp';
import { createAutohideMiddleware } from './autohide';
import { ApplicationMiddlewareConfig } from './middlewareConfig';
import { createPersistedAlertsMiddleware } from './persisted-alerts';
import { createPreloadImagesMiddleware } from './preload-images';
import { createCompanyRecommendationEnhancer } from './recommendation';
import { createReportInvitationMiddleware } from './report-invitation';
import { createAccountActionsEnhancer } from './account-actions';

export function createApplicationMiddleware(config: ApplicationMiddlewareConfig) {
    return compose(
        createAutohideMiddleware(config.autohide),
        createPreloadImagesMiddleware(),
        createCompanyRecommendationEnhancer(),
        createReportInvitationMiddleware(config.top),
        createPersistedAlertsMiddleware(),
        createAccountActionsEnhancer(),
    );
}
