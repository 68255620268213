import { CompetitiveSetImplConfig } from './competitiveSetConfig';
import {
    DashboardPreferenceScope,
    PreferencQuerySegmentsSectionDto,
    PreferencTraitFiltersSectionDto,
} from '../../api';
import { CompetitiveSetProvider, useWorkspaceApi } from '../../app';
import {
    buildCohortState,
    CohortMode,
    CohortState,
    CompetitiveSet,
    DynamicCohortStateItem,
    FixedCohortState,
    FixedCohortStateItem,
} from '../../domain';
import { AnyCondition } from '../../domain/attributes';
import { chain } from 'lodash';
import { DashboardSlugs } from '../../../config/dashboard';

const COHORT_DEFAULTS_BY_DASHBOARD: Record<string, CohortState | undefined> = {
    [DashboardSlugs.TIKTOK_V2]: buildCohortState({
        mode: 'fixed',
        comparison: 'cost_per_conversion',
    }),
    [DashboardSlugs.EXECUTIVE_SUMMARY]: buildCohortState({
        mode: 'fixed',
        comparison: 'marketing_efficiency_ratio',
    }),
};

/**
 * Maps trait preferences to competitive set model
 * @param config
 * @returns
 */
export function createCompetitiveSetImpl(
    config: CompetitiveSetImplConfig
): CompetitiveSetProvider {
    const {
        hooks: { useQuery },
        api: {
            platform: {
                preferences: { list: listPreferences },
            },
        },
    } = config;
    return {
        useAdapter(context) {
            const dashboards = useWorkspaceApi().getDashboards().items;

            const competitivesets = useQuery({
                queryKey: ['competitive-set', context.workspace.id],
                async queryFn() {
                    const preferences = await listPreferences(context, {
                        assetId: context.workspace.id as number,
                        // we want to load all preferences
                        pageSize: 1000,
                        sections: ['trait_filters', 'query_segments'],
                    });

                    const preferencesByDashboard = chain(preferences.data)
                        .flatMap((item) =>
                            item.scopes.map((scope) => ({ ...scope, ...item }))
                        )
                        .filter((item) => item.subject_type === 'dashboard')
                        .groupBy((item) => item.subject_id)
                        .value();

                    // console.log('preferencesByDashboard', preferencesByDashboard);

                    const items = chain(dashboards)
                        .flatMap((item) => {
                            const preferences = preferencesByDashboard[item.id] ?? [];
                            const trait = preferences.find(
                                (item) => item.section_type === 'trait_filters'
                            ) as PreferencTraitFiltersSectionDto | undefined;
                            const cohorts = preferences.find(
                                (item) => item.section_type === 'query_segments'
                            ) as PreferencQuerySegmentsSectionDto | undefined;
                            return [
                                preferenceToCompetitiveSet(
                                    { subject_id: item.id },
                                    trait ?? null,
                                    cohorts ?? null
                                ),
                            ];
                        })
                        .value();

                    return items;
                },
                staleTime: Infinity,
                retry: false,
                suspense: true,
            });
            return {
                async find(_context) {
                    // console.log('competitivesets.data', competitivesets.data);
                    return competitivesets.data ?? [];
                },
            };
        },
    };
}

function preferenceToCompetitiveSet(
    scope: Pick<DashboardPreferenceScope, 'subject_id'>,
    trait: PreferencTraitFiltersSectionDto | null,
    cohort: PreferencQuerySegmentsSectionDto | null
): CompetitiveSet {
    let defaultCohort = COHORT_DEFAULTS_BY_DASHBOARD[scope.subject_id];
    if (!defaultCohort) {
        // console.warn(
        //     `default cohort state for dashboard ${scope.subject_id} not found`,
        //     COHORT_DEFAULTS_BY_DASHBOARD
        // );
        defaultCohort = buildCohortState({ mode: 'dynamic', comparison: null });
    }

    return {
        name: 'default',
        dashboard: {
            id: scope.subject_id,
        },
        conditions:
            trait?.section.rules.sub_rules.map((rule): AnyCondition => {
                if (Array.isArray(rule.value) && rule.operator === 'between') {
                    const [left, right] = rule.value;
                    return {
                        key: rule.metadata_definition_key,
                        operator: rule.operator as any,
                        value: { from: left, to: right },
                    };
                }
                return {
                    key: rule.metadata_definition_key,
                    operator: rule.operator as any,
                    value: rule.value as any,
                };
            }) ?? [],
    };
}
