import { FormatCurrencyOptions } from './currencyOptions';

export function formatCurrency(amount: number, { currency = 'usd', ...options }: Partial<FormatCurrencyOptions> = {}) {
    function isInt(number: number) {
        return number % 1 === 0;
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: isInt(amount) ? 0 : undefined,
    });
    return formatter.format(amount);
}
