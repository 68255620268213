import { AnyVisualizationCreateProps } from '../../../domain/visualization';
import { encodeVisualization, toVisualizationDto } from '../../../service/visualizations';
import { WorkspaceContextV2 } from '../../../app';

export function getAnalysisMetricLink(
    context: Pick<WorkspaceContextV2, 'workspace'>,
    props: AnyVisualizationCreateProps
): string {
    const queryString = encodeVisualization(toVisualizationDto(props));
    return `/u/assets/${context.workspace.id}/metrics/analyze?${queryString}`;
}
