import React, { useState } from 'react';
import { capitalize, chain } from 'lodash';
import {
    Wrap,
    WrapItem,
    VStack,
    Box,
    Text,
    Heading,
    BoxProps,
    Button,
} from '@chakra-ui/react';
import { AnyPlan, PlanCategory, PricingPlan } from '../planType';
import { PlanCategoryItemProps } from './PlanCategoryItem';
import { formatPlanName } from '../planHelper';

const COLORS = {
    PURPLE: '#7164ff',
    GREEN: '#95EF93',
    DARK_GREEN: '#3abb68',
};

export interface PlanCategoryListProps {
    containerProps?: BoxProps;
    title: string;
    caption?: string;
    subtitle?: string;
    currentPlan?: AnyPlan | null;
    categories: PlanCategory[];
    itemRenderer(
        plan: PlanCategory,
        variant: 'default' | 'ghost',
        index: number
    ): React.ReactElement<PlanCategoryItemProps>;
    onBookDemo(): void;
    variant?: 'default' | 'ghost';
    defaultValue?: Partial<PlanListState>;
    children?: React.ReactNode;
}

interface PlanListState {}

export const PlanCategoryList: React.FC<
    { children?: React.ReactNode | undefined } & PlanCategoryListProps
> = ({
    title,
    subtitle,
    caption,
    currentPlan,
    categories,
    itemRenderer,
    variant = 'default',
    containerProps = { paddingY: '32px' },
    ...props
}) => {
    return (
        <VStack color="black" width="100%" spacing={8} {...containerProps}>
            <VStack spacing={2}>
                <Heading textAlign="center" fontSize="42px" fontWeight={600}>
                    {title}
                </Heading>
                {subtitle && (
                    <Heading
                        fontSize="lg"
                        textAlign="center"
                        fontWeight="normal"
                        color="blackAlpha.700"
                    >
                        {subtitle}
                    </Heading>
                )}
                {caption && (
                    <Text fontSize="md" textAlign="center" fontWeight="semibold">
                        {caption}
                    </Text>
                )}
                {currentPlan && (
                    <Heading
                        fontSize="lg"
                        textAlign="center"
                        fontWeight="normal"
                        color="blackAlpha.700"
                    >
                        Currently Subscribed to{' '}
                        {formatPlanName(
                            categories.find((x) =>
                                x.plans.some((p) => p.id == currentPlan.id)
                            )?.name,
                            currentPlan
                        )}
                    </Heading>
                )}
            </VStack>
            <Wrap mx="auto" w="full" spacing={8} justify="center">
                {categories.map((category, index) => (
                    <WrapItem key={category.key} maxW="300px" w="full">
                        <VStack
                            spacing={0}
                            h="full"
                            w="full"
                            bg={
                                index === 0
                                    ? 'linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)'
                                    : undefined
                            }
                            borderRadius="xl"
                        >
                            <Box w="full" h={2}></Box>
                            <Box
                                w="full"
                                h="full"
                                p={8}
                                bg="white"
                                borderStyle="solid"
                                borderColor="blackAlpha.200"
                                borderWidth={1}
                                borderTopStartRadius={index === 0 ? undefined : 'xl'}
                                borderTopEndRadius={index === 0 ? undefined : 'xl'}
                                borderBottomStartRadius="xl"
                                borderBottomEndRadius="xl"
                            >
                                {itemRenderer(category, variant, index)}
                            </Box>
                        </VStack>
                    </WrapItem>
                ))}
            </Wrap>
            <Button
                onClick={props.onBookDemo}
                minW={48}
                // boxShadow="0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.12);"
                borderWidth={1}
                borderStyle="solid"
                bg="transparent"
                borderColor={COLORS.DARK_GREEN}
                color={COLORS.DARK_GREEN}
                _hover={{
                    borderColor: COLORS.DARK_GREEN,
                    color: COLORS.DARK_GREEN,
                }}
            >
                Book a demo
            </Button>
            {props.children}
        </VStack>
    );
};
