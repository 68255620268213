import { UseQueryResult } from '@tanstack/react-query';
import { AnyLookup } from './lookupModel';

export function lookupFromQuery<TItem>(
    query: UseQueryResult<TItem>,
    options: { keeyData?: boolean } = {}
): AnyLookup<TItem> {
    if (query.isLoading || query.data === undefined) {
        return {
            status: 'loading',
            data: null,
        };
    }
    if (!options.keeyData && query.isFetching) {
        return {
            status: 'loading',
            data: null,
        };
    }
    if (query.isError) {
        return {
            status: 'error',
            data: null,
            error: query.error as Error,
        };
    }
    return {
        status: 'loaded',
        data: query.data,
    };
}
