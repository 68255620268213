import { CheckIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import {
    Tag,
    TagLabel,
    TagLeftIcon,
    Box,
    HStack,
    StackProps,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { capitalize } from '../../../../utils/strings';
import React from 'react';
import { AnyIntegrationStatus } from '../../integrationType';

export interface IntegrationCardStatusProps {
    status: AnyIntegrationStatus;
    onClick?(): void;
    minimal?: boolean;
}

export const IntegrationCardStatus: React.FC<
    { children?: React.ReactNode | undefined } & IntegrationCardStatusProps
> = ({ ...props }) => {
    const styling: StackProps = { cursor: props.onClick ? 'pointer' : undefined };
    const statusText = capitalize(
        props.minimal ? props.status.kind : props.status.text ?? ''
    );

    if (props.status.kind === 'pending' && props.minimal) {
        return (
            <Tooltip
                placement="top"
                label={props.status.description}
                hasArrow={true}
                arrowSize={8}
                openDelay={200}
            >
                <Tag size="md" colorScheme="orange" borderRadius="full" px={3} py={1}>
                    <TagLeftIcon boxSize="12px" as={CheckIcon} />
                    <TagLabel userSelect="none">{statusText}</TagLabel>
                </Tag>
            </Tooltip>
        );
    }
    if (props.status.kind === 'connected' && props.status.title && props.minimal) {
        return (
            <Tooltip
                placement="top"
                label={props.status.description}
                hasArrow={true}
                arrowSize={8}
                openDelay={200}
            >
                <Tag size="md" colorScheme="green" borderRadius="full" px={3} py={1}>
                    <TagLeftIcon boxSize="12px" as={CheckIcon} />
                    <TagLabel userSelect="none">{statusText}</TagLabel>
                </Tag>
            </Tooltip>
        );
    }
    if (props.status.kind === 'connected') {
        return (
            <HStack {...styling} alignItems="center" spacing={2} onClick={props.onClick}>
                <Text colorScheme="varosGreen">
                    <CheckIcon />
                </Text>
                <Text colorScheme="varosGreen" fontWeight="medium">
                    {props.status.title}
                </Text>
                {props.children}
            </HStack>
        );
    }
    if (props.status.kind === 'ignored' && props.status.title) {
        // const noun = pluralize('account', props.status.count);
        return (
            <HStack {...styling} alignItems="center" spacing={2} onClick={props.onClick}>
                <Text color="gray.500" fontWeight="medium">
                    {props.status.title}
                </Text>
                {/* <Tooltip openDelay={0} label={props.status.hint} isDisabled={!props.status.hint}>
                    <WarningIcon h={4} w={4} color="gray.500" />
                </Tooltip> */}
                {props.children}
            </HStack>
        );
    }
    if (props.status.kind === 'error' && props.status.title) {
        // const noun = pluralize('account', props.status.count);
        return (
            <HStack {...styling} alignItems="center" spacing={2} onClick={props.onClick}>
                <Text color="red.500" fontWeight="medium">
                    {props.status.title}
                </Text>
                {/* <Tooltip openDelay={0} label={props.status.reason} isDisabled={!props.status.reason}>
                    <WarningIcon h={4} w={4} color="red.500" />
                </Tooltip> */}
                {props.children}
            </HStack>
        );
    }
    return <></>;
};
