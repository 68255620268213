import React from 'react';
import { ApplicationUI } from '../../ui';
import { MetricModuleConfnig } from './metricsConfig';

export function createMetricModule(config: MetricModuleConfnig) {
    const {
        provider: {
            benchmark: { list: benchmarkList },
            definition: { list: definitionList, select: definitionSelect },
        },
    } = config;
    const loaders = {
        benchmark: benchmarkList.createLoader(config.deps),
        definition: definitionList.createLoader({
            repository: {
                collection: config.deps.repository.metric.collection,
            },
        }),
    };
    return {
        Benchmark: {
            List: {
                loader: loaders.benchmark,
                controller: benchmarkList.createController(
                    config.deps,
                    loaders.benchmark
                ),
                Component: benchmarkList.createView(config.deps),
                createView(viewConfig: { UI: ApplicationUI }) {
                    return benchmarkList.createView({
                        ...config.deps,
                        UI: viewConfig.UI,
                    });
                },
            },
        },
        Definition: {
            List: {
                controller: definitionList.createController(
                    config.deps,
                    loaders.definition
                ),
                createView(viewConfig: { UI: ApplicationUI }) {
                    return definitionList.createView({
                        ...config.deps,
                        UI: viewConfig.UI,
                    });
                },
            },
            Select: {
                controller: definitionSelect.createController(),
                createView(viewConfig: { UI: ApplicationUI }) {
                    return definitionSelect.createView();
                },
            },
        },
    };
}
