import React from 'react';
import { ContextType } from '../models/Account';
// import { Provider } from 'mobx-react';
import { DeepParitalStores, makeStores, Stores } from './createStore';
import { RootStore, assignStores, WithReset, StoresWithReset } from './RootStore';
import StoreBase from './StoreBase';
import { HomeContext } from '../models/Context';

class ResetableStore extends RootStore<Stores> implements WithReset<Stores> {
    constructor(stores: Stores) {
        super(stores);
    }
    async onContextChanged(
        contextName: ContextType,
        ctx?: HomeContext | null,
        stores?: Partial<Stores>
    ): Promise<void> {
        if (!stores) {
            return;
        }
        // @ts-ignore
        const storeItems: Array<StoreBase> = Object.values(stores);
        for (const si of storeItems) {
            if (si) {
                await si.onContextChanged(contextName, ctx);
            }
        }
    }

    reset(stores?: DeepParitalStores): void {
        assignStores(this, makeStores(stores));
    }
}

export const store: StoresWithReset = new ResetableStore(makeStores()) as StoresWithReset;

const storeContext = React.createContext(store);

export const StoreProvider: React.FC<
    { children?: React.ReactNode } & { externalStores?: Stores }
> = ({ externalStores, children }) => {
    React.useEffect(() => {
        if (externalStores) {
            store.reset(externalStores);
        }
    }, [externalStores]);

    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
    const _store = React.useContext(storeContext);
    // @ts-ignore
    window.gndn = _store;
    if (!_store) {
        throw new Error('You have forgotten to use StoreProvider, go fish');
    }
    return _store;
};
