import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { createTrackingOnboardingAssetStrategy } from './asset';
import { createTrackingOnboardingCompetitiveSetStrategy } from './competitive-set';
import { createTrackingOnboardingIntegrationStrategy } from './integration';
import { createTrackingOnboardingMappingStrategy } from './mapping';
import { createTrackingOnboardingMetricStrategy } from './metric';
import { createTrackingOnboardingPeerStrategy } from './peer';
import { createTrackingOnboardingPrepareStrategy } from './prepare';
import { createTrackingOnboardingProfileStrategy } from './profile';
import { createTrackingOnboardingReceiptStrategy } from './receipt';
import { createTrackingOnboardingReportStrategy } from './report';

export function createTrackingOnboardingStrategy(): ApplicationEntryEnhancer {
    return compose(
        createTrackingOnboardingProfileStrategy(),
        createTrackingOnboardingIntegrationStrategy(),
        createTrackingOnboardingAssetStrategy(),
        createTrackingOnboardingMappingStrategy(),
        createTrackingOnboardingPeerStrategy(),
        createTrackingOnboardingPrepareStrategy(),
        createTrackingOnboardingMetricStrategy(),
        createTrackingOnboardingCompetitiveSetStrategy(),
        createTrackingOnboardingReportStrategy(),
        createTrackingOnboardingReceiptStrategy()
    );
}
