import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    menuAnatomy.keys
);

const baseStyle = definePartsStyle({
    list: {
        bg: '#292929',
    },
    item: {
        bg: 'none',
        _hover: {
            bg: 'whiteAlpha.100',
        },
    },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
