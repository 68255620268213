import React, { CSSProperties } from 'react';
import {
    Wrap,
    WrapItem,
    Box,
    HStack,
    Tag,
    Table,
    Tbody,
    Thead,
    Tr,
    Td,
    Th,
    Text,
    Button,
} from '@chakra-ui/react';
import { ApplicationUI } from '../../../ui';

export function createSettingsUI(Base: ApplicationUI): ApplicationUI {
    return {
        ...Base,
        Table(props) {
            return <Table {...props} />;
        },
        Thead({ ...props }) {
            return <Thead {...props} bg="whiteAlpha.300" />;
        },
        Tbody({ ...props }) {
            return <Tbody {...props} />;
        },
        Tr({ ...props }) {
            return <Tr {...props} />;
        },
        Th({ ...props }) {
            return <Th color="whiteAlpha.700" borderBottom="none" {...props}></Th>;
        },
        Td({ ...props }) {
            return <Td borderColor="whiteAlpha.300" borderBottomWidth="2px" {...props} />;
        },
        Text({ ...props }) {
            return <Text {...props}>{props.children}</Text>;
        },
        Button({ ...props }) {
            return <Button {...props} />;
        },
        Icon: {
            ...Base.Icon,
            IconGroup(props) {
                return <Base.Icon.IconGroup bg="whiteAlpha.300" {...props} />;
            },
        },
        Tag: {
            List(props) {
                const childrenArray = React.Children.toArray(props.children);
                const visible = childrenArray.slice(0, props.maxItems);
                const truncated = childrenArray.slice(props.maxItems);
                return (
                    <Wrap spacing={2}>
                        {visible.map((child, index) => (
                            <WrapItem key={index}>
                                <Box key={index}>{child}</Box>
                            </WrapItem>
                        ))}
                        {truncated.length > 0 ? (
                            <WrapItem>
                                <Box>and {truncated.length} more</Box>
                            </WrapItem>
                        ) : null}
                    </Wrap>
                );
            },
            Item(props) {
                return <Tag {...props} />;
            },
        },
    };
}
