import { BoxProps } from '@chakra-ui/react';

export const SCROLLBAR_STYLE: BoxProps['__css'] = {
    // 'scrollbar-gutter': 'auto both-edges',
    // 'scrollbar-gutter': 'stable',
    '&::-webkit-scrollbar': {
        w: '2',
    },
    '&::-webkit-scrollbar-track': {
        w: '6',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10',
        bg: `whiteAlpha.600`,
    },
};
