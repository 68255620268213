import { SettingAssetController } from './assetInterface';
import { SettingAssetConfig } from './assetConfig';

export function createSettingAssetController(): SettingAssetController {
    return {
        useProps() {
            return {
                isVisible(item) {
                    return true;
                },
            };
        },
    };
}
