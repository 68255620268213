import { MetricDefinition } from 'src/v2/domain/metrics';
import { VisualizableStrategy } from '../../../app/visualizations';
import { VisualizableMetricConfig } from './visualizableMetricConfig';
import { VisualizableMetric } from './visualizableMetricModel';

export function createMetricVisualizable(
    config: VisualizableMetricConfig
): VisualizableStrategy<VisualizableMetric> {
    return {
        toVisualization(item) {
            // const entry = config.rules[item.definition.id];
            // if (!entry) {
            //     return null;
            // }
            return {
                key: item.definition.id,
                name: item.definition.name,
                description: item.definition.description,
                kind: 'line',
                view: item.definition.view,
                metrics: [
                    {
                        id: item.definition.id,
                    },
                ],
                granularity: 'week',
                period: null,
                filters: item.configuration.filters,
                ranking: item.configuration.ranking,
                breakdown: null,
            };
        },
    };
}
