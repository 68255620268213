import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingPaymentMethodResource } from './paymentMethodInterface';
import { BillingPaymentMethodListDtoSchema } from './paymentMethodSchema';

export function createBillingPaymentMethodResource(
    client: AxiosInstance
): BillingPaymentMethodResource {
    return {
        async list(context) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.get('/api/v1/billing/payment_methods', {
                headers,
                params,
            });
            const parsed = BillingPaymentMethodListDtoSchema.parse(response.data);

            return parsed;
        },
    };
}
