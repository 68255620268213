import React from 'react';
import { AssetMappingModuleConfig } from './mappingConfig';

export function createAssetMappingModule(config: AssetMappingModuleConfig) {
    const controller = config.provider.createController(config.deps);
    const View = config.provider.createView(config.deps);
    return {
        controller,
        View,
    };
}
