import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { SettingAssetProfileConfig } from './profileConfig';
import { createSettingAssetProfileContainer } from './profileContainer';
import { createSettingAssetProfileController } from './profileController';
import { createSettingAssetProfileLoader } from './profileLoader';
import { createSettingAssetProfileView } from './profileView';

export function createSettingAssetProfileStrategy(
    init: SettingAssetProfileConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const loader = createSettingAssetProfileLoader(init, config);
            const controller = createSettingAssetProfileController(init, config);
            const Container = createSettingAssetProfileContainer(
                init,
                config,
                loader,
                controller
            );
            const View = createSettingAssetProfileView(init, config);
            return [
                {
                    scope: 'company',
                    path: 'general-settings',
                    key: 'general',
                    title: 'General Settings',
                    Component() {
                        return <Container as={View} />;
                    },
                },
            ];
        },
    };
}
