import React from 'react';
import { WorkspaceSelectionConfig } from './workspaceSelectionConfig';

export function createWorkspaceSelectionModule(config: WorkspaceSelectionConfig) {
    const controller = config.provider.createController(config.deps);
    const View = config.provider.createView();
    return {
        Container: config.provider.createContainer(config.deps, controller, View),
    };
}
