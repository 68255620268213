import { isMaybePartialNumericRangeValue, isTreeValue } from './valueGuard';
import { AnyValue, NumberRangeValue, PartialNumberRangeValue } from './valueModel';

export function assertMaybePartialNumberRangeValue(
    value: AnyValue
): asserts value is PartialNumberRangeValue {
    if (!isMaybePartialNumericRangeValue(value)) {
        throw new Error(`invalid numeric range value ${JSON.stringify(value)}`);
    }
}

export function assertTreeValue(value: AnyValue): asserts value is string[] {
    if (!isTreeValue(value)) {
        throw new Error(`invalid tree value`);
    }
}
