import { useToken } from '@chakra-ui/react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { RegistrationLayoutController } from './registrationLayoutInterface';

export function createRegistrationLayoutController(): RegistrationLayoutController {
    return {
        useProps(props) {
            const containerRef = useRef<HTMLDivElement>(null);
            const location = useLocation();
            const blackAlpha900 = useToken('colors', 'blackAlpha.900');

            useEffect(() => {
                // Store the original background colors
                const originalHtmlBgColor =
                    document.documentElement.style.backgroundColor;
                const originalBodyBgColor = document.body.style.backgroundColor;

                const originalHtmlColor = document.documentElement.style.color;
                const originalBodyColor = document.body.style.color;

                // Set the new background color
                document.documentElement.style.backgroundColor = 'white';
                document.body.style.backgroundColor = 'white';

                document.documentElement.style.color = blackAlpha900;
                document.body.style.color = blackAlpha900;

                // Cleanup function to reset the background colors to their original values
                return () => {
                    document.documentElement.style.backgroundColor = originalHtmlBgColor;
                    document.body.style.backgroundColor = originalBodyBgColor;

                    document.documentElement.style.color = originalHtmlColor;
                    document.body.style.color = originalBodyColor;
                };
            }, []);

            useLayoutEffect(() => {
                if (containerRef.current) {
                    containerRef.current.scrollTo(0, 0);
                }
            }, [
                location.pathname,
                // TODO this should only trigger for a subset of query params like the current step
                location.search,
            ]);

            return { containerRef };
        },
    };
}
