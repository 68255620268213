import {
    Tooltip,
    useBreakpointValue,
    Button,
    Text,
    Box,
    HStack,
    StackProps,
    useDisclosure,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Tag,
} from '@chakra-ui/react';
import { useNavigate, useMatch, Link } from 'react-router-dom';
import React from 'react';
import { Menu, useCollapseList, createCollapseList } from '../../../../../../ui';
import { Dashboard, getDashboardConnectionError } from '../../../../../domain';
import { Image, ImageBadge } from '../../../../../ui';
import {
    WorkspaceNavigationViewProps,
    WorkspaceNavigationItemProps,
} from '../navigationProps';
import type {
    WorkspaceNavigationItem,
    WorkspaceNavigationPackageItem,
} from '../navigationModel';
import { WorkspaceNavigationViewConfig } from '../navigationConfig';

export function createWorkspaceNavigationView(
    config: WorkspaceNavigationViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & WorkspaceNavigationViewProps> {
    const {
        style = {},
        UI: { Icon },
    } = config;
    const GroupItem = createWorkspaceNavigationGroupItem(config);
    const CollapseList = createCollapseList<WorkspaceNavigationItem>();
    return (props) => {
        const navigate = useNavigate();
        const disclosure = useDisclosure();

        const maxItems = useBreakpointValue({
            base: null,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 6,
            '2xl': 9,
        });

        const collapseProps = useCollapseList({
            maxItems: maxItems ?? 5,
        });

        const handleClick = (dashboard: Dashboard) => {
            disclosure.onClose();
            navigate(props.getPath(dashboard));
        };
        return (
            <CollapseList.Wrapper {...style.wrapper} {...collapseProps}>
                <CollapseList.Items
                    {...style.list}
                    items={props.items}
                    renderItem={(item) => {
                        if (item.kind === 'dashboard') {
                            return (
                                <WorkspaceNavigationItem
                                    style={style.item}
                                    dashboard={item.dashboard}
                                    isActive={props.isActive(item)}
                                    installed={props.isInstalled(item)}
                                    to={props.getPath(item.dashboard)}
                                />
                            );
                        } else {
                            return <GroupItem {...item} {...props} />;
                        }
                    }}
                />
                <CollapseList.Dropdown
                    zIndex={100}
                    renderItems={(dashboards) => (
                        <Menu.Container
                            placement="bottom"
                            isOpen={disclosure.isOpen}
                            onClose={disclosure.onClose}
                        >
                            <Menu.Target>
                                <Button
                                    onClick={disclosure.onOpen}
                                    fontSize={style.wrapper?.fontSize}
                                    aria-label="see all"
                                    variant="link"
                                >
                                    See all
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.List>
                                    <Menu.Label>Dashboards</Menu.Label>
                                    {dashboards
                                        .flatMap((item) =>
                                            item.kind === 'dashboard'
                                                ? [item]
                                                : item.dashboards.map(
                                                      (dashboard) =>
                                                          ({
                                                              kind: 'dashboard',
                                                              dashboard,
                                                          }) as const
                                                  )
                                        )
                                        .map((item) => {
                                            const { dashboard } = item;
                                            const error =
                                                getDashboardConnectionError(dashboard);
                                            return (
                                                <Menu.Item
                                                    style={{
                                                        spacing: 4,
                                                    }}
                                                    key={dashboard.id}
                                                    onClick={handleClick.bind(
                                                        null,
                                                        dashboard
                                                    )}
                                                    icon={
                                                        <HStack>
                                                            <Image
                                                                width={5}
                                                                alt={dashboard.title}
                                                                src={dashboard.iconUrl}
                                                                filter={
                                                                    props.isInstalled(
                                                                        item
                                                                    )
                                                                        ? undefined
                                                                        : 'grayscale(100%)'
                                                                }
                                                            >
                                                                {error ? (
                                                                    <ImageBadge
                                                                        bg="red.500"
                                                                        variant="solid"
                                                                        zIndex={10}
                                                                    >
                                                                        <Text
                                                                            position="relative"
                                                                            top="-1px"
                                                                        >
                                                                            !
                                                                        </Text>
                                                                    </ImageBadge>
                                                                ) : null}
                                                            </Image>
                                                        </HStack>
                                                    }
                                                >
                                                    {dashboard.title}
                                                </Menu.Item>
                                            );
                                        })}
                                </Menu.List>
                            </Menu.Dropdown>
                        </Menu.Container>
                    )}
                />
            </CollapseList.Wrapper>
        );
    };
}

function createWorkspaceNavigationGroupItem(
    config: WorkspaceNavigationViewConfig
): React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceNavigationPackageItem &
        Pick<WorkspaceNavigationViewProps, 'isActive' | 'isInstalled' | 'getPath'>
> {
    const {
        style,
        UI: { Icon },
    } = config;
    return (props) => {
        const { dashboards } = props;
        const disclosure = useDisclosure();
        const navigate = useNavigate();

        const handleClick = (dashboard: Dashboard) => {
            disclosure.onClose();
            navigate(props.getPath(dashboard));
        };

        function getFilter(item: WorkspaceNavigationItem) {
            return props.isInstalled(props) ? undefined : 'grayscale(100%)';
        }

        return (
            <Box h="full" position="relative" alignItems="center" display="flex">
                <Menu.Container
                    placement="bottom"
                    onOpen={disclosure.onOpen}
                    isOpen={disclosure.isOpen}
                    onClose={disclosure.onClose}
                >
                    <Menu.Target>
                        <Button
                            leftIcon={
                                <HStack>
                                    <Image
                                        width={5}
                                        alt={dashboards[0]?.title}
                                        src={dashboards[0]?.iconUrl}
                                        filter={getFilter(props)}
                                    >
                                        {getDashboardConnectionError(
                                            props.dashboards[0]
                                        ) ? (
                                            <ImageBadge
                                                bg="red.500"
                                                variant="solid"
                                                zIndex={10}
                                            >
                                                <Text position="relative" top="1px">
                                                    !
                                                </Text>
                                            </ImageBadge>
                                        ) : null}
                                    </Image>
                                </HStack>
                            }
                        >
                            {props.package.name}
                        </Button>
                    </Menu.Target>
                    <Menu.Dropdown maxW="sm">
                        <Menu.List>
                            <Menu.Label>{props.package.name}</Menu.Label>
                            {dashboards.map((dashboard) => (
                                <Menu.Item
                                    key={dashboard.id}
                                    onClick={handleClick.bind(null, dashboard)}
                                    caption={
                                        dashboard.description
                                            ? dashboard.description
                                            : undefined
                                    }
                                    icon={
                                        <Icon.IconGroup mt={0.5}>
                                            <Icon.Icon
                                                width={5}
                                                src={dashboard.iconUrl}
                                                filter={getFilter(props)}
                                            />
                                            {getDashboardConnectionError(dashboard) ? (
                                                <Icon.Badge colorScheme="red">
                                                    !
                                                </Icon.Badge>
                                            ) : null}
                                        </Icon.IconGroup>
                                    }
                                    rightSection={
                                        dashboard.status === 'beta' ? (
                                            <Tag
                                                fontWeight="medium"
                                                bg="whiteAlpha.100"
                                                color="#c6e74f"
                                            >
                                                Beta
                                            </Tag>
                                        ) : undefined
                                    }
                                >
                                    {dashboard.title}
                                </Menu.Item>
                            ))}
                        </Menu.List>
                    </Menu.Dropdown>
                </Menu.Container>
                <span>
                    <Box
                        w="full"
                        position="absolute"
                        bottom={0}
                        left={0}
                        right={0}
                        h={(style?.item?._selected?.borderWidth as string) ?? '1px'}
                        bg={
                            props.isActive(props)
                                ? (style?.item?._selected?.borderColor as string)
                                : 'transparent'
                        }
                    />
                </span>
            </Box>
        );
    };
}

const WorkspaceNavigationItem: React.FC<
    { children?: React.ReactNode } & WorkspaceNavigationItemProps
> = ({ dashboard, isActive, ...props }) => {
    const match = useMatch({
        path: props.to,
    });
    const activeStyle: Partial<StackProps> = {};
    const style = { ...props.style, ...(isActive ? activeStyle : {}) };
    const error = getDashboardConnectionError(dashboard);

    return (
        <Link to={props.to}>
            <Tooltip
                label={error?.message}
                // isDisabled={!props.error}
                isDisabled={true}
                placement="bottom"
                offset={[0, 0]}
            >
                <HStack h="full" {...style} position="relative">
                    <Image
                        width={5}
                        alt={dashboard.title}
                        src={dashboard.iconUrl}
                        filter={props.installed ? undefined : 'grayscale(100%)'}
                    >
                        {error ? (
                            <ImageBadge bg="red.500" variant="solid" zIndex={10}>
                                <Text position="relative" top="-1px">
                                    !
                                </Text>
                            </ImageBadge>
                        ) : null}
                    </Image>
                    <Box>
                        <Text whiteSpace="nowrap" color="link">
                            {dashboard.title}
                        </Text>
                    </Box>
                    <span>
                        <Box
                            w="full"
                            position="absolute"
                            bottom={0}
                            left={0}
                            right={0}
                            h={(style._selected?.borderWidth as string) ?? '1px'}
                            // @ts-expect-error
                            bg={isActive ? style._selected?.borderColor : 'transparent'}
                        />
                    </span>
                </HStack>
            </Tooltip>
        </Link>
    );
};
