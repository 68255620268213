export interface DataSet {
    id: number;
    name: string;
}
export interface PeerGroupDataSet {
    id: number;
    dataset: DataSet;
    last_change: Date;
}

export interface DataSetQuery {
    id: number;
    key: string;
    name?: string;
}

export enum ColumnType {
    date = 'date',
    float = 'float',
    boolean = 'boolean',
    double = 'double',
    int = 'int',
    str = 'str',
    datetime = 'datetime',
}

export interface ColumnDef {
    key: string;
    tp: ColumnType;
    is_key: string;
    display_name?: string;
    display_format?: 'money' | 'percentage' | 'counter' | 'pct_change';
    description?: string;
}

export interface TableSummary {
    table_name: string;
    keys: Array<string>;
    non_keys: Array<string>;
    col_mapping: Record<string, ColumnDef | undefined>;
    header: Array<string>;
}

export enum ExecFilterOperator {
    eq = 'eq',
    neq = 'neq',
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    between = 'between',
    in = 'in',
    nin = 'nin',
    empty = 'empty',
    not_empty = 'not_empty',
    current = 'current',
    previous = 'previous',
    next = 'next',
    before = 'before',
    after = 'after',
    on = 'on',
    contains = 'contains',
    rgx = 'rgx',
    startsw = 'startsw',
    nstartsw = 'nstartsw',
}

export const OPERATOR_DISP: Record<ExecFilterOperator, string> = {
    [ExecFilterOperator.contains]: 'Contains',
    [ExecFilterOperator.rgx]: 'Regular Expression',
    [ExecFilterOperator.startsw]: 'Starts With',
    [ExecFilterOperator.nstartsw]: 'Not Starts With',
    [ExecFilterOperator.not_empty]: 'Not Empty',
    [ExecFilterOperator.empty]: 'Empty',
    [ExecFilterOperator.eq]: 'Equals',
    [ExecFilterOperator.neq]: 'Not Equals',
    [ExecFilterOperator.gt]: 'Greater Than',
    [ExecFilterOperator.gte]: 'Greater Than or Equal To',
    [ExecFilterOperator.lt]: 'Less Than',
    [ExecFilterOperator.lte]: 'Less Than or Equal To',
    [ExecFilterOperator.between]: 'Between',
    [ExecFilterOperator.in]: 'In',
    [ExecFilterOperator.nin]: 'Not in',
    [ExecFilterOperator.current]: 'Current',
    [ExecFilterOperator.previous]: 'Previous',
    [ExecFilterOperator.next]: 'Next',
    [ExecFilterOperator.before]: 'Before',
    [ExecFilterOperator.after]: 'After',
    [ExecFilterOperator.on]: 'On',
};

// export const EXEC_FILTER_OPERATOR_NAMES = {

// };

export interface ExecuteQueryFilter<T = any> {
    key: string;
    operator: ExecFilterOperator;
    value: T | Array<T> | null;
}
export interface QueryExecutionResult {
    data: Array<Array<any>>;
    query_info?: any;
}

export interface ParticipantDataSetState {
    peer_group_datasets_requires_update: Array<number>;
}

export interface OrderBy {
    col_key: string;
    is_desc: boolean;
}

export interface ExecuteQueryDescriptor {
    select_keys: Array<string>;
    filters?: Array<ExecuteQueryFilter<string>>;
    keys: Array<string>;
    limit?: number;
    sort_order?: Array<OrderBy>;
}

export interface MakeQueryRequest {
    exec_query_desc: ExecuteQueryDescriptor;
    dw_query_id: number;
    dataset_id: number;
    peer_group_filter_id?: number;
}

export function isTableSummary(opt: TableSummary | boolean): opt is TableSummary {
    return (opt as TableSummary).keys !== undefined;
}

export interface KeyValRequest {
    columnKey: string;
    participantId: number;
    peerGroupFilterId?: number;
}
