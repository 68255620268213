import { useQuery } from '@tanstack/react-query';
import { QueryResponseRepositoryConfig } from './responseConfig';
import { QueryResponseRepository } from './responseInterface';

export function createQueryResponseRepository(
    config: QueryResponseRepositoryConfig
): QueryResponseRepository {
    const { adapter } = config;
    return {
        useFind(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['query', 'response', query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
        },
    };
}
