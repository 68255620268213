import {
    WorkspaceLayoutMain,
    WorkspaceLayoutWrapper,
    WorkspaceLayoutNavigation,
    WorkspaceLayoutHeader,
    WorkspaceLayoutLoader,
} from './component';
import { WorkspaceLayoutDropdown } from './component/WorkspaceLayoutCommon';
import { WorkspaceLayoutToolbar } from './component/WorkspaceLayoutToolbar';

export const WorkspaceLayout = {
    Wrapper: WorkspaceLayoutWrapper,
    Main: WorkspaceLayoutMain,
    Navigation: WorkspaceLayoutNavigation,
    Toolbar: {
        Container: WorkspaceLayoutToolbar,
        Item: WorkspaceLayoutDropdown,
    },
    Header: WorkspaceLayoutHeader,
    Loader: WorkspaceLayoutLoader,
};

export function createWorkspaceLayout() {
    return WorkspaceLayout;
}
