import React from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { MenuItemProps } from './MenuItemProps';

export const MenuItem: React.FC<
    { children?: React.ReactNode | undefined } & MenuItemProps
> = ({ ...props }) => {
    const handleClick: MenuItemProps['onClick'] = (event) => {
        if (props.disabled && !props.allowClickWhenDisabled) {
            return;
        }
        return props.onClick?.(event);
    };
    return (
        <HStack
            px={4}
            py={1.5}
            cursor="pointer"
            w="full"
            {...props.style}
            color={props.disabled ? 'whiteAlpha.400' : undefined}
            _hover={props.disabled ? undefined : { bg: 'whiteAlpha.100' }}
            onClick={handleClick}
            spacing={4}
            alignItems="flex-start"
        >
            <Box mt={1} minW={5} visibility={props.icon ? 'visible' : 'hidden'}>
                {props.icon}
            </Box>
            <VStack flex={1} alignItems="flex-start" spacing={0.25}>
                <Text
                    color={props.disabled ? 'whiteAlpha.400' : 'whiteAlpha.900'}
                    isTruncated={true}
                    // overflow="hidden"
                    // textOverflow="ellipsis"
                    // whiteSpace="nowrap"
                    fontSize="md"
                >
                    {props.children}
                </Text>
                {props.caption && (
                    <Text
                        fontWeight="normal"
                        color={props.disabled ? 'whiteAlpha.400' : 'whiteAlpha.700'}
                        // overflow="hidden"
                        // textOverflow="ellipsis"
                        noOfLines={2}
                        fontSize="sm"
                        // style={{ lineClamp: 1 }}
                    >
                        {props.caption}
                    </Text>
                )}
            </VStack>
            {props.rightSection && <Box>{props.rightSection}</Box>}
        </HStack>
    );
};
