import React from 'react';
import { useLocation } from 'react-router';
import { Integration, IntegrationConf } from '../../models/integration';

export const OAUTH_REDIRECT_SESSION_STORAGE_KEY = 'oauth_redirect_href';

function useBaseUrl(): string {
    const location = useLocation();
    return React.useMemo(
        () => `/${location.pathname.split('/').filter((x) => x.length > 0)[0]}`,
        [location.pathname]
    );
}

export const INTEGRATED_QUERY_PARAM_KEY = 'integrated';

export const makeNewIntegrateHandler = (
    navigate: (to: string) => void,
    connect: (
        integDefId: number,
        keys?: Record<string, string>,
        options?: { newTab?: boolean; implementationId?: number }
    ) => void,
    { ...options }: { newTab?: boolean; implementationId?: number }
) => {
    const baseUrl = useBaseUrl();
    return (conf: IntegrationConf, keys?: Record<string, string>) => {
        const key = INTEGRATED_QUERY_PARAM_KEY;
        const href = location.search.startsWith('?')
            ? `${location.pathname}${location.search}&${key}=true`
            : `${location.pathname}?${key}=true`;
        const storageKey = OAUTH_REDIRECT_SESSION_STORAGE_KEY;

        if (!location.pathname.includes('connect-card')) {
            // HACK when on the shopify connect card we want do go to integrations page afterwards
            console.info(`setting '${storageKey}' to ${href}`);
            sessionStorage.setItem(storageKey, href);
        }

        if (
            !keys &&
            conf.implementation.required_keys &&
            Object.keys(conf.implementation.required_keys).length > 0
        ) {
            console.log('navigating to connect card');
            navigate(`${baseUrl}/integrations/definitions/${conf.id}/connect-card`);
            return;
        }
        // switch (conf.implementation.auth_method) {
        // case 'api_keys':
        // return connect(conf.id, keys, options);
        // navigate(`${conf.integration_type}/guide`);
        // default:

        return connect(conf.id, keys, {
            implementationId: conf.implementation.id,
            ...options,
        });
    };
};

export const makeReconnectHandler = (
    navigate: (to: string) => void,
    reconnect: (
        definitionId: number,
        integId: number,
        keys?: Record<string, string>,
        options?: { newTab?: boolean; implementationId?: number }
    ) => void,
    { ...options }: { newTab?: boolean }
) => {
    const baseUrl = useBaseUrl();
    return (
        conf: IntegrationConf,
        reconnectInteg: Integration,
        keys?: Record<string, string>
    ) => {
        const href = `${location.pathname}${location.search}`;
        const storageKey = OAUTH_REDIRECT_SESSION_STORAGE_KEY;

        if (!location.pathname.includes('connect-card')) {
            // HACK when on the shopify connect card we want do go to integrations page afterwards
            console.info(`setting '${storageKey}' to ${href}`);
            sessionStorage.setItem(storageKey, href);
        }

        const reqKeys =
            reconnectInteg.implementation?.required_keys ||
            conf.implementation?.required_keys;
        if (!keys && reqKeys && Object.keys(reqKeys).length > 0) {
            navigate(`${baseUrl}/integrations/${reconnectInteg.id}/connect-card`);
            return;
        }
        return reconnect(conf.id, reconnectInteg.id, keys, {
            implementationId: conf.implementation.id,
            ...options,
        });
    };
};
