import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { convertAxiosToError } from '../utils/axiosUtils';

export interface ApiConfig {
    /**
     * Introduce artifical delay for simulating slower requests
     */
    delayMs?: number;
    /**
     * If true
     */
    transformError?(error: Error): unknown;
    prefix?: string;
    getToken?(): Promise<string | null>;
    refreshToken?(): Promise<string | null>;
}

const axiosErrorHandler = (
    { transformError = (error) => error, ...config }: Pick<ApiConfig, 'transformError'>,
    error: AxiosError
) => {
    Sentry.captureException(error, {
        extra: {
            url: error.response?.config?.url,
            method: error.response?.config?.method,
            params: error.response?.config?.params,
            response_payload: error.response?.data,
            request_payload: error.response?.config.data,
        },
    });
    error.response;
    const internalError = transformError(error);
    return Promise.reject(internalError);
};
const axiosResponseHandler = (response: AxiosResponse) => {
    return response;
};

const NAMESPACE = 'varos';

class VarosRequestState {
    retryCount: number = 0;
}

function getCurrentState(config: AxiosRequestConfig): VarosRequestState {
    // @ts-ignore
    const currentState: VarosRequestState = config[NAMESPACE] || new VarosRequestState();
    currentState.retryCount = currentState.retryCount || 0;
    // @ts-ignore
    config[NAMESPACE] = currentState;
    return currentState;
}

export function createApi({
    prefix = '',
    getToken,
    refreshToken,
    transformError,
    delayMs,
    ...config
}: ApiConfig = {}) {
    const client = axios.create({
        baseURL: prefix,
        timeout: 60 * 1000,
        headers: {
            'Content-Type': 'application/json',
            common: {
                Authorization:
                    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOm51bGwsImJpZCI6MTEsImVtYWlsIjoiYW1vdW50XzIyMjBAZXhhbXBsZS5jb20iLCJyb2xlIjoyNDgsImlhdCI6MTYwMDg1Njg1MiwiZXhwIjoxNjAwOTQzMjUyfQ.lF9yv218QKlBpbLDfFmM60Pq3A216nIAKDT4Qbk0BP4',
            },
        },
    });

    // // test rate limit error
    // client.interceptors.response.use(async (response) => {
    //     if (response.config.url?.includes('/query')) {
    //         throw {
    //             errType: 'rateLimited',
    //         };
    //     }
    //     return response;
    // });

    if (delayMs) {
        client.interceptors.request.use(async (request) => {
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(null);
                }, delayMs);
            });
            return request;
        });
    }
    if (getToken) {
        client.interceptors.request.use(async (request) => {
            const token = await getToken();
            const value = `Bearer ${token}`;
            request.headers.Authorization = value;
            return request;
        });
        if (refreshToken)
            client.interceptors.response.use(
                (response) => response,
                async (error) => {
                    const status = error.response ? error.response.status : null;

                    if (status === 401) {
                        const currentState = getCurrentState(error.config);
                        if (currentState.retryCount < 2) {
                            const newToken = await refreshToken();
                            error.config.headers['Authorization'] = 'Bearer ' + newToken;
                            if (currentState.retryCount > 0) {
                                currentState.retryCount += 1;
                                return new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve(null);
                                    }, currentState.retryCount * 500);
                                }).then(() => client.request(error.config));
                            } else {
                                currentState.retryCount += 1;
                                return client.request(error.config);
                            }
                        }
                    }
                    return Promise.reject(error);
                }
            );
    }
    client.interceptors.response.use(
        axiosResponseHandler,
        axiosErrorHandler.bind(null, { ...config, transformError })
    );
    return client;
}

export const apiV1 = createApi({ prefix: '/api/v1' });
export const apiV1NoPrefix = createApi();

export const setToken = (token: string) => {
    // this.axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiV1.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiV1NoPrefix.defaults.headers.common.Authorization = `Bearer ${token}`;
};
