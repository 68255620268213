import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetricDefinitionConfig } from './definitionConfig';
import { MetricDefinitionRepository } from './definitionInterface';

export function createMetricDefinitionRepository(
    config: MetricDefinitionConfig
): MetricDefinitionRepository {
    const { adapter } = config;
    const PREFIX = ['metric', 'definitions'];
    return {
        useFind(context, query, options) {
            return useQuery({
                ...options,
                queryKey: [...PREFIX, query],
                async queryFn() {
                    const response = await adapter.definition.find(context, query);
                    return response;
                },
            });
        },
    };
}
