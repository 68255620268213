import { makeObservable, observable } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { DashboardReference, AnyFilter } from '../../../domain';
import { DashboardStore } from '../../../app';
import { DashboardStoreConfig } from './dashboardStoreConfig';

interface SegmentState {
    isOpen: boolean;
}

export interface DashboardStateValue {
    segments?: Record<string, SegmentState | undefined>;
}

export class DashboardStoreImpl extends StoreBase implements DashboardStore {
    _state: Record<string, DashboardStateValue | undefined>;

    constructor(public config: DashboardStoreConfig) {
        super();
        this._state = config.initialValues;
        makeObservable(this, {
            _state: observable,
        });
    }

    toggleExpanded(dashboard: DashboardReference, filter: AnyFilter) {
        this._state = {
            ...this._state,
            [dashboard.id]: {
                ...this._state[dashboard.id],
                segments: {
                    ...this._state[dashboard.id]?.segments,
                    [filter.property.key]: {
                        isOpen: !this.isExpanded(dashboard, filter),
                    },
                },
            },
        };
    }

    isExpanded(dashboard: DashboardReference, filter: AnyFilter) {
        return this._state[dashboard.id]?.segments?.[filter.property.key]?.isOpen ?? true;
    }
}
