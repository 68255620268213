import { EnumMember } from './member';

export interface TimestampPrevious {
    operator: 'previous';
    value: number;
    granularity: 'day' | 'week' | 'month' | 'year';
}

export interface TimestampSinceCondition {
    operator: 'since';
    since: string;
}

export interface TimestampBetweenCondition {
    operator: 'between';
    since: string;
    until: string;
}

export interface TimestampFilter {
    type: 'timestamp';
    condition: TimestampPrevious | TimestampSinceCondition | TimestampBetweenCondition;
}
export function isTimestampPrevFilter(
    filter: TimestampFilter['condition']
): filter is TimestampPrevious {
    return (filter as TimestampPrevious)?.operator == 'previous';
}
export function isTimestampSinceFilter(
    filter: TimestampFilter['condition']
): filter is TimestampSinceCondition {
    return (filter as TimestampSinceCondition)?.operator == 'since';
}

export function isTimestampBetweenFilter(
    filter: TimestampFilter['condition']
): filter is TimestampBetweenCondition {
    return (filter as TimestampBetweenCondition)?.operator == 'between';
}

export interface InFilter<T> {
    operator: 'in' | 'nin';
    values: T[];
}
export interface StringInFilter extends InFilter<string> {}

export interface NumberInFilter extends InFilter<number> {}

export interface BetweenFilter {
    operator: 'between';
    start: number;
    end: number;
}

const RefOpNumberFilterList = ['gt', 'gte', 'lt', 'lte', 'eq', 'neq'] as const;
const RefOpNumberFilterSet = new Set<string>(RefOpNumberFilterList);
export type RefNumberOperator = typeof RefOpNumberFilterList[number];

export interface ReferenceNumberFilter {
    operator: RefNumberOperator;
    value: number;
}

export type NumericFilter = NumberInFilter | BetweenFilter | ReferenceNumberFilter;

export type AnyFilter = NumericFilter | StringInFilter;

export interface SavedDataFilter {
    type_slug: string;
    filter: AnyFilter;
}

export function isStringFilter(filter: AnyFilter): filter is StringInFilter {
    const candidate = filter as StringInFilter;
    return Array.isArray(candidate.values) && typeof candidate.values[0] === 'string';
}

export function isNumberInFilter(filter: AnyFilter): filter is NumberInFilter {
    const candidate = filter as NumberInFilter;
    return Array.isArray(candidate.values) && typeof candidate.values[0] === 'number';
}

export function isBetweenFilter(filter: AnyFilter): filter is BetweenFilter {
    return (filter as BetweenFilter).operator == 'between';
}

export function isReferenceNumberFilter(
    filter: AnyFilter
): filter is ReferenceNumberFilter {
    return RefOpNumberFilterSet.has((filter as ReferenceNumberFilter).operator);
}

export function getFilterValueArray(filter: AnyFilter): string[] {
    if (isStringFilter(filter)) {
        return filter.values;
    }
    if (isNumberInFilter(filter)) {
        return filter.values.map((x) => x.toString());
    }
    if (isBetweenFilter(filter)) {
        return [filter.end.toString(), filter.start.toString()];
    }
    if (isReferenceNumberFilter(filter)) {
        return [filter.value.toString()];
    }
    return [];
}
export function getTimestampValueArray(filter: TimestampFilter): string[] {
    if (isTimestampPrevFilter(filter.condition)) {
        return [filter.condition.value.toString(), filter.condition.granularity];
    }
    if (isTimestampBetweenFilter(filter.condition)) {
        return [filter.condition.since, filter.condition.until];
    }
    if (isTimestampSinceFilter(filter.condition)) {
        return [filter.condition.since];
    }
    return [];
}

export function stringifyFilter(filter: AnyFilter, members?: EnumMember[]) {
    if (isStringFilter(filter)) {
        const memberByVal =
            members?.reduce<Record<string, EnumMember>>((agg, curr) => {
                agg[curr.value] = curr;
                return agg;
            }, {}) || {};
        const retVal = filter.values.map((x) => memberByVal[x]?.name || x).join(', ');
        return retVal;
    }
    if (isNumberInFilter(filter)) {
        return filter.values.map((x) => x.toString()).join(', ');
    }
    if (isBetweenFilter(filter)) {
        return `${filter.start} - ${filter.end}`;
    }
    if (isReferenceNumberFilter(filter)) {
        switch (filter.operator) {
            case 'eq':
                return `= ${filter.value}`;
            case 'neq':
                return `≠ ${filter.value}`;
            case 'gt':
                return `> ${filter.value}`;
            case 'gte':
                return `>= ${filter.value}`;
            case 'lt':
                return `< ${filter.value}`;
            case 'lte':
                return `<= ${filter.value}`;
        }
    }
    return 'All';
}

const ALL_FILTER_TYPE_CHECKS = [
    isStringFilter,
    isBetweenFilter,
    isNumberInFilter,
    isReferenceNumberFilter,
];

export function areFiltersEqual(first: AnyFilter, second: AnyFilter): boolean {
    if (first == second) {
        // same reference
        return true;
    }
    if (first.operator != second.operator) {
        return false;
    }
    const areNotSame = ALL_FILTER_TYPE_CHECKS.some(
        (check) => check(first) != check(second)
    );
    if (areNotSame) {
        return false;
    }
    const firstValues = getFilterValueArray(first);
    const secondValues = getFilterValueArray(second);
    if (firstValues.length != secondValues.length) {
        return false;
    }
    for (let i = 0; i < firstValues.length; i++) {
        const firstV = firstValues[i];
        const secondV = secondValues[i];
        if (firstV !== secondV) {
            return false;
        }
    }
    return true;
}

export function areTimestampEqual(
    first: TimestampFilter,
    second: TimestampFilter
): boolean {
    if (first == second) {
        // same reference
        return true;
    }
    if (first.type != second.type) {
        return false;
    }

    const firstValues = getTimestampValueArray(first);
    const secondValues = getTimestampValueArray(second);

    if (firstValues.length != secondValues.length) {
        return false;
    }
    for (let i = 0; i < firstValues.length; i++) {
        const firstV = firstValues[i];
        const secondV = secondValues[i];
        if (firstV !== secondV) {
            return false;
        }
    }
    return true;
}
