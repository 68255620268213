import { useMutation } from '@tanstack/react-query';
import { PeerGroup } from '../../../domain/peers';
import { PeerGroupAdapter, WorkspaceContextV2 } from '../../../app';

export function createPeerGroupService(adapter: PeerGroupAdapter) {
    return {
        useUpsertAll(context: WorkspaceContextV2) {
            return useMutation({
                async mutationFn(groups: PeerGroup[]) {
                    const response = await adapter.putAll(context, groups);
                    return response;
                },
            });
        },
    };
}
