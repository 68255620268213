import { useContext } from 'react';
import { SubmissionItemController } from '../../../view/studies';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FeatureFlagContext } from '../featureFlagConstant';

export function createFeatureFlagChatMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: SubmissionItemController
        ): SubmissionItemController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    return {
                        ...viewProps,
                        study: {
                            ...viewProps.study,
                            link: context.flags.chat?.enabled
                                ? viewProps.study.link
                                : null,
                        },
                    };
                },
            };
        }

        return create({
            ...config,
            router: {
                ...config.router,
                createReporting(routerConfig) {
                    return config.router.createReporting({
                        ...routerConfig,
                        controller: {
                            ...routerConfig.controller,
                            submissions: {
                                ...routerConfig.controller.submissions,
                                item: enhanceController(
                                    routerConfig.controller.submissions.item
                                ),
                            },
                        },
                    });
                },
            },
        });
    };
}
