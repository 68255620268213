import React, { useMemo } from 'react';
import { chain } from 'lodash';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import { MdBolt } from 'react-icons/md';
import { RecommendationDto } from '../../../api';
import {
    useWorkspaceContextV2,
    WorkspaceContextConfig,
    WorkspaceContextLoader,
} from '../../context';
import { WorkspaceContextDeps } from '../../app';
import { InputOption, TreeInputOption } from '../../ui';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { PeerFilterListController } from '../../view/peer';
import { RecommendationEnhancerConfig } from './recommendationConfig';
import { UseQueryResult } from '@tanstack/react-query';
import { FilterItem, FilterListController, OptionItem } from '../../view/common';

export function createRecommendationEnhancer(
    init: RecommendationEnhancerConfig = {}
): ApplicationEntryEnhancer {
    const TOOLTIP_LABEL = `This vertical was automatically suggested based on your brand profile`;

    return (create) => (config) => {
        const {
            infra,
            context: { createWorkspace: createWorkspaceContext },
            controller: {
                segment: { createItem: createSegmentItemController },
            },
        } = config;

        function enhanceLoader(config: WorkspaceContextConfig): WorkspaceContextLoader {
            return config.provider.createLoader({
                ...config.deps,
                queries: {
                    ...config.deps.queries,
                    recommendations: (context) => {
                        return {
                            queryKey: ['recommendations', 'v2', context.workspace.id],
                            async queryFn() {
                                const response =
                                    await instance.api.platform.recommendations.list(
                                        context,
                                        {
                                            scope: {
                                                kind: 'asset',
                                                id: context.workspace.id as number,
                                            },
                                            model: 'enum',
                                            options: {
                                                type: 'vertical-v2',
                                            },
                                        }
                                    );
                                return response.data;
                            },
                            staleTime: Infinity,
                            retry: false,
                        };
                    },
                },
            });
        }

        function enhanceFilterListController(
            controller: FilterListController
        ): FilterListController {
            return {
                ...controller,
                useProps(item, props) {
                    const {
                        recommendations: { data: recommendations = [] },
                    } = useWorkspaceContextV2<{
                        recommendations: UseQueryResult<RecommendationDto[]>;
                    }>();

                    const items = useMemo(
                        () =>
                            item.items.map((item): FilterItem => {
                                if (item.input.kind !== 'multi_select') {
                                    return item;
                                }
                                const recommendedOptions = chain(item.input.options)
                                    .flatMap(({ children, ...option }) => children ?? [])
                                    .filter((option) =>
                                        recommendations.some(
                                            (recommendation) =>
                                                recommendation.object.id === option.value
                                        )
                                    )
                                    .map((option): InputOption => {
                                        return {
                                            children: [],
                                            ...option,
                                            icons: [
                                                <Tooltip
                                                    label={
                                                        <Box p={2}>{TOOLTIP_LABEL}</Box>
                                                    }
                                                    placement="top"
                                                >
                                                    <span>
                                                        <Icon
                                                            as={MdBolt}
                                                            color="whiteAlpha.700"
                                                        />
                                                    </span>
                                                </Tooltip>,
                                                ...(option.icons ?? []),
                                            ],
                                        };
                                    })
                                    .unionBy((option) => option.value)
                                    .value();

                                return {
                                    ...item,
                                    input: {
                                        ...item.input,
                                        options: [
                                            ...recommendedOptions,
                                            ...item.input.options,
                                        ],
                                    },
                                };
                            }),
                        [item.items, recommendations]
                    );
                    const viewProps = controller.useProps({ ...item, items }, props);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...config,
            module: {
                ...config.module,
            },
            context: {
                ...config.context,
                createWorkspace(config) {
                    return createWorkspaceContext({
                        ...config,
                        provider: {
                            createLoader(deps) {
                                return enhanceLoader({ ...config, deps });
                            },
                        },
                    });
                },
            },
            controller: {
                ...config.controller,
                common: {
                    ...config.controller.common,
                    createFilterList(...args) {
                        const controller = config.controller.common.createFilterList(
                            ...args
                        );
                        return enhanceFilterListController(controller);
                    },
                },
                // peer: {
                //     ...config.controller.peer,
                //     createFilterList(...args) {
                //         const controller = config.controller.peer.createFilterList(
                //             ...args
                //         );
                //         return enhanceFilterListController(controller);
                //     },
                // },
                segment: {
                    createItem(...args) {
                        const controller = createSegmentItemController(...args);
                        return {
                            ...controller,
                            useProps(props) {
                                const {
                                    recommendations: { data: recommendations = [] },
                                } = useWorkspaceContextV2<{
                                    recommendations: UseQueryResult<RecommendationDto[]>;
                                }>();
                                const mapped = controller.useProps(props);
                                return {
                                    ...mapped,
                                    getInputProps(props) {
                                        const inputProps = mapped.getInputProps(props);
                                        if (inputProps.type === 'tree') {
                                            const optionsRecommended: TreeInputOption[] =
                                                chain(inputProps.options)
                                                    .flatMap(
                                                        ({ children, ...option }) =>
                                                            children
                                                    )
                                                    .filter((option) =>
                                                        recommendations.some(
                                                            (recommendation) =>
                                                                recommendation.object
                                                                    .id === option.value
                                                        )
                                                    )
                                                    .map((option): TreeInputOption => {
                                                        return {
                                                            children: [],
                                                            ...option,
                                                            icons: [
                                                                <Tooltip
                                                                    label={
                                                                        <Box p={2}>
                                                                            {
                                                                                TOOLTIP_LABEL
                                                                            }
                                                                        </Box>
                                                                    }
                                                                    placement="top"
                                                                >
                                                                    <span>
                                                                        <Icon
                                                                            as={MdBolt}
                                                                            color="whiteAlpha.700"
                                                                        />
                                                                    </span>
                                                                </Tooltip>,
                                                                ...(option.icons ?? []),
                                                            ],
                                                        };
                                                    })
                                                    .unionBy((option) => option.value)
                                                    .value();

                                            return {
                                                ...inputProps,
                                                options: [
                                                    ...optionsRecommended,
                                                    ...inputProps.options,
                                                ],
                                            };
                                        }
                                        return inputProps;
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
        return instance;
    };
}
