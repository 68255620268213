import {
    ShortTextInputControllerProps,
    LongTextInputControllerProps,
    ShortTextInputViewProps,
    LongTextInputViewProps,
} from './inputTextProps';

export function buildInputShortTextProps(
    props: ShortTextInputControllerProps
): ShortTextInputViewProps {
    return {
        value: props.value ?? '',
        onChange: props.onChange,
    };
}

export function buildInputLongTextProps(
    props: LongTextInputControllerProps
): LongTextInputViewProps {
    return {
        value: props.value ?? '',
        onChange: props.onChange,
    };
}
