import { assert } from '../../../util/assert';
import { GroupMemberDto } from '../../../api';
import { PeerSetMemberAdapter, PeerSetMemberEntity } from '../../../app';
import { PeerMemberImplConfig } from './peerMemberImplConfig';

export function createPeerSetMemberImpl(
    config: PeerMemberImplConfig
): PeerSetMemberAdapter {
    const { api } = config;

    function toEntity(member: GroupMemberDto): PeerSetMemberEntity {
        assert(member.id, `expected peerset member id to be present`);
        return {
            id: member.id,
            asset: {
                id: member.asset_id,
            },
            peerset: {
                id: member.group_id,
            },
        };
    }

    return {
        async find(context, query) {
            const response = await api.members.list(context, {
                group: query.peerset.map((item) => item.id),
                page: 1,
                page_size: 100,
                peer_comparison: true,
            });

            return response.data.filter((item) => !!item.id).map(toEntity);
        },
        async bulk(context, payload) {
            const limit = 25;
            assert(payload.items.length <= limit, `too many changes`);

            const initial = await api.members.list(context, {
                group: [payload.group.id],
                page: 1,
                page_size: limit,
            });

            let accAssetIds = initial.data.map((item) => item.asset_id);

            // Remove deleted members
            accAssetIds = accAssetIds.filter(
                (assetId) =>
                    !payload.items.some(
                        (item) => item.kind === 'delete' && item.asset.id === assetId
                    )
            );

            // Add new members
            accAssetIds = [
                ...accAssetIds,
                ...payload.items.flatMap((item) =>
                    item.kind === 'create' ? [item.data.asset.id] : []
                ),
            ];

            return api.members.bulkUpsert(context, {
                asset_ids: accAssetIds,
                group_id: payload.group.id,
            });
        },
    };
}
