import { compose } from 'lodash/fp';
import { KernelConfig, KernelInit } from './kernelConfig';
import { Kernel, KernelEnhancer } from './kernelInterface';
import { createErrorController } from './error';

export function configureKernel(config: KernelInit, enhancer?: KernelEnhancer) {
    const enhancers = enhancer ? [enhancer] : [];
    return create(
        {
            ...config,
            provider: {
                ...config.provider,
                createErrorController: createErrorController,
            },
        },
        compose(...enhancers)
    );
}

function create(config: KernelConfig, enhancer?: KernelEnhancer): Kernel {
    if (enhancer) {
        return enhancer(create)(config);
    }
    return {
        provider: config.provider,
        infra: config.infra,
    };
}
