import React, { useEffect } from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { fetchIntegrations as fetchIntegrationsApi } from '../../../api/integrations';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../../stores/setupContext';
import FormByKeys from '../../../components/FormByKeysFrom/FormByKeys';
import {
    makeNewIntegrateHandler,
    makeReconnectHandler,
} from '../../../hooks/integrations/funcs';
import { useIntegration } from '../../../hooks';
import { useAuthenticatedApi } from '../../../infra';
import { CardSpinner } from '../../../domain';

const Observed = observer(FormByKeys);

function ConnectCard({}) {
    const {
        integrations: {
            fetchIntegrations,
            redirectToConnectLink,
            redirectToReconnecLink,
            definitions,
            currentIntegrations,
            err,
            isLoadingId,
            fetchDefinitions,
        },
    } = useStore();
    const { integrationDefinitionId, integrationId } = useParams();
    const navigate = useNavigate();
    const api = useAuthenticatedApi();

    useEffect(() => {
        fetchDefinitions();
    }, []);

    // const history = useHistory()
    React.useEffect(() => {
        if (integrationDefinitionId) {
            fetchIntegrations(parseInt(integrationDefinitionId));
        }
    }, [integrationDefinitionId]);
    const { data: reconnectInteg, isLoading: isLoadingInteg } = useIntegration(
        api,
        integrationId
    );

    const currIntegDef = React.useMemo(() => {
        if (reconnectInteg?.implementation.integration_definition_id) {
            return definitions?.find(
                (x) => x.id == reconnectInteg.implementation.integration_definition_id
            );
        } else if (integrationDefinitionId) {
            return definitions?.find((x) => x.id.toString() == integrationDefinitionId);
        }
    }, [integrationDefinitionId, reconnectInteg, definitions]);
    const [keySuggestions, setKeySuggestions] = React.useState<Record<string, string[]>>(
        {}
    );
    React.useEffect(() => {
        (currIntegDef?.implementation?.required_keys || []).map((curr) => {
            if (curr.suggest?.integration_type) {
                const currIntegType = curr.suggest?.integration_type;
                fetchIntegrationsApi(undefined, api, undefined, currIntegType).then(
                    (integs) => {
                        setKeySuggestions({
                            ...keySuggestions,
                            [curr.key]: integs.data.items.reduce<string[]>(
                                (suggestionAgg, currInteg) => {
                                    if (currInteg.key) {
                                        suggestionAgg.push(currInteg.key);
                                    }
                                    return suggestionAgg;
                                },
                                []
                            ),
                        });
                    }
                );
            }
        });
    }, [currIntegDef]);
    const connectCb = makeNewIntegrateHandler(navigate, redirectToConnectLink, {
        newTab: true,
    });
    const reconnectCb = makeReconnectHandler(navigate, redirectToReconnecLink, {
        newTab: true,
    });
    const reqKeys =
        currIntegDef &&
        (currIntegDef.implementation.required_keys ||
            reconnectInteg?.implementation.required_keys);

    if (definitions === null) {
        return <CardSpinner />;
    }

    return (
        <Flex justifyContent="center" alignItems="center" p={16}>
            {reqKeys ? (
                <Observed
                    requiredKeys={reqKeys}
                    keySuggestions={keySuggestions}
                    title={currIntegDef.title}
                    logo={currIntegDef.icon_url}
                    docsLink={currIntegDef.implementation.install_guide?.link}
                    err={err}
                    isLoading={isLoadingId == currIntegDef.id}
                    onSubmit={(keys) => {
                        if (reconnectInteg) {
                            reconnectCb(currIntegDef, reconnectInteg, keys);
                        } else {
                            connectCb(currIntegDef, keys);
                        }
                    }}
                    onCancel={() => navigate(-1)}
                />
            ) : (
                <Box>
                    <Text>Not Found</Text>
                </Box>
            )}
        </Flex>
    );
}

export default observer(ConnectCard);
