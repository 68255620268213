import { BenchmarkListViewProps, MetricCollectionEditProps } from '../../../../view';
import { HomeMetricViewLoadedPayload } from './metricTrackingEvent';

export function buildMetricsViewPayload(
    props: BenchmarkListViewProps
): HomeMetricViewLoadedPayload {
    return {
        asset_id: props.data.collection.asset,
        collection_id: props.data.collection.id,
        collection_default: props.data.collection.default,
        collection_metric_ids: props.items.map((item) => item.metric.id),
        collection_metric_count: props.data.collection.metrics.length,
    };
}

export function buildCollectionViewPayload(
    props: MetricCollectionEditProps
): HomeMetricViewLoadedPayload {
    const connected = new Set(
        props.section.items.flatMap((item) =>
            item.items.flatMap((item) =>
                item.connection.status === 'ready' ? [item.metric.id] : []
            )
        )
    );
    return {
        asset_id: props.data.collection.asset,
        collection_id: props.data.collection.id,
        collection_default: props.data.collection.default,
        collection_metric_ids: props.data.collection.metrics.flatMap((item) =>
            connected.has(item.definition) ? [item.definition] : []
        ),
        collection_metric_count: props.data.collection.metrics.length,
    };
}
