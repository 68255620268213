import { ActionItemViewProps, AnyAction, isClickAction, isLinkAction } from '../item';
import { ActionListController } from './actionListInterface';
import { ActionListControllerProps } from './actionListProps';

export function createActionListController(): ActionListController {
    function buildItemProps(
        props: ActionListControllerProps,
        item: AnyAction
    ): ActionItemViewProps {
        return {
            id: item.id,
            label: item.name,
            description: item.description ?? null,
            colorScheme: item.kind === 'delete' ? 'red' : undefined,
            Icon: item.Icon ?? undefined,
            button: isClickAction(item)
                ? item.button
                : {
                      onClick() {
                          props.navigate(item.link.to);
                      },
                  },
        };
    }
    return {
        useProps(item, props) {
            return {
                items: item.items,
                getItemProps(item) {
                    return buildItemProps(props, item);
                },
            };
        },
    };
}
