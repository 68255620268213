import moment from 'moment';
import React from 'react';
import { useDashboardApi, useControlApi, useQueryApi } from '../../../app/dashboard';
import { DateViewController } from './dateContainerInterface';

export const useDateController: DateViewController = () => {
    const api = {
        dashboard: useDashboardApi(),
        query: useQueryApi(),
        control: useControlApi(),
    };

    const dashboardDateConfiguration = api.dashboard.getDateConfiguration();
    const configuration = api.control.getDateFilter(dashboardDateConfiguration);

    if (!configuration?.period) {
        console.error(`no date config found`);
        return {
            enabled: false,
            filter: configuration,
            value: {
                granularity: 'week',
                period: {
                    amount: 4,
                    interval: 'week',
                },
                comparison: null,
            },
            onChange(configuration) {
                api.control.setDateFilter(configuration);
            },
        };
    }
    const minDate = React.useMemo(() => {
        if (
            dashboardDateConfiguration?.filter &&
            dashboardDateConfiguration.filter.property.constraints.length > 0
        ) {
            const [firstConst] = dashboardDateConfiguration.filter.property.constraints;
            if (firstConst.expression.operator == 'min_lookback_days') {
                return moment().add(-firstConst.expression.value, 'day').toDate();
            }
        }
    }, [dashboardDateConfiguration?.filter]);
    return {
        enabled: true,
        filter: configuration,
        minDate,
        value: {
            granularity: configuration.granularity,
            period: configuration.period,
            comparison: configuration.comparison,
        },
        onChange(configuration) {
            api.control.setDateFilter(configuration);
        },
    };
};
