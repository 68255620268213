import { chain } from 'lodash';
import { InsightPriority } from '../../../api';
import { OpportunityAdapter } from '../../../app/insight/opportunity';
import { OpportunityImplConfig } from './opportunityConfig';

export function createOpportunityImpl(config: OpportunityImplConfig): OpportunityAdapter {
    const { api } = config;

    // NOTE ascending order
    const PRIORITY_ORDER: InsightPriority[] = ['low', 'medium', 'high'];

    return {
        async find(context, query) {
            const response = await api.insights.list(context, {
                asset: [context.workspace.id as number],
                category: ['opportunity'],
                occured_since: query.since,
                occured_before: query.before,
                limit: query.limit,
            });

            let items = response.data;

            const { aggregate } = query;
            if (aggregate) {
                items = chain(items)
                    .groupBy((item) => {
                        return aggregate.keys.map((key) => {
                            if (key === 'definition') {
                                return item.definition.id;
                            }
                            return item[key];
                        });
                    })
                    .flatMap((items) =>
                        chain(items)
                            .orderBy((item) => item.period_end_at.valueOf(), 'desc')
                            .take(aggregate.take)
                            .value()
                    )
                    .value();
            }

            items = chain(items)
                .orderBy(
                    (item) => {
                        return query.orderBy.map((order) => {
                            if (order.key === 'priority') {
                                return PRIORITY_ORDER.findIndex(
                                    (key) => item[order.key] === key
                                );
                            }
                            const value = item[order.key];
                            if (value instanceof Date) {
                                return value.valueOf();
                            }
                            return value;
                        });
                    },
                    query.orderBy.map((item) => item.direction)
                )
                .value();

            return {
                limit: query.limit,
                total: items.length,
                items: items,
            };
        },
        async findOne(context, query) {
            const response = await api.insights.get(context, query.id);
            return response;
        },
        async create(context, command) {
            const response = await api.insights.create(context, command);
            let acc = response.data;
            if (command.limit) {
                acc = acc.slice(0, command.limit);
            }
            return {
                limit: command.limit ?? 1000,
                total: acc.length,
                items: acc,
            };
        },
    };
}
