import React from 'react';
import { OrganizationRouteConfig } from './organizationRouteConfig';
import { OrganizationRouteContext } from './organizationRouteInterface';

export function createOrganizationRoute(
    config: OrganizationRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        hook,
        providers: { createController, createView, createLoader },
    } = config;
    const controller = createController(config);
    const loader = createLoader(config);
    const View = createView(config);
    return (props) => {
        const auth = hook.useAuth();
        const organization = hook.useOrganization();
        const viewData = loader.useLoad({ auth, organization });
        const viewProps = controller.useProps(viewData);
        return <View {...viewProps}>{props.children}</View>;
    };
}
