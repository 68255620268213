import React, { useMemo } from 'react';
import { OrganizationScope } from '../../../app';
import { createGroupsRouteView } from './groupsSettingsView';
import { SettingGroupConfig } from './groupsSettingsConfig';
import { createSettingsGroupController } from './groupsSettingsController';

export function createGroupsSettingsRoute(
    config: SettingGroupConfig
): React.FC<{ children?: React.ReactNode | undefined } & React.PropsWithChildren<{}>> {
    const { hook, loader, View: FormView, UI } = config;
    const controller = createSettingsGroupController(config);
    const View = createGroupsRouteView(FormView, UI);
    return () => {
        const organization = hook.useOrganization();
        const auth = hook.useAuth();
        const orgContext: OrganizationScope = {
            organization,
            auth,
        };
        const data = loader.useLoad(orgContext);
        const viewProps = controller.useProps(orgContext, {
            item: data,
        });
        return <View {...viewProps} />;
    };
}
