import { useQuery } from '@tanstack/react-query';
import { useSessionContext } from './sessionContext';
import { AuthSessionAdapter } from './sessionAdapter';

export interface SessionApi extends Pick<AuthSessionAdapter, 'signout'> {}

export const useSessionApi = (): AuthSessionAdapter => {
    const { adapter } = useSessionContext();
    return { ...adapter.session };
};
