import { PageIds } from '../../../../config';
import { StudiesSubmissionListRouteConfig } from './studiesSubmissionConfig';
import { StudiesSubmissionListController } from './studiesSubmissionInterface';

export function createStudiesSubmissionListController(
    config: Pick<StudiesSubmissionListRouteConfig, 'controller'>
): StudiesSubmissionListController {
    const {
        controller: {
            page: pageController,
            study: { submission: submissionController },
        },
    } = config;
    return {
        useProps(context, props) {
            const pageProps = pageController.useProps(context, {
                item: { id: PageIds.STUDIES_SUBMISSION_DETAIL, breadcrumbs: [] },
            });
            const listProps = submissionController.useProps(context, props.item.list);
            // const layoutProps = layoutController.useProps(context, {
            //     status: listProps.emptyState,
            // });
            return { page: pageProps, list: listProps };
        },
    };
}
