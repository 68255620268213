import { assert } from '../../../../../../util/assert';
import { SettingsBillingLoaderConfig } from '../../base';
import { PaymentMethodDetailLoader } from './paymentMethodDetailInterface';

export function createPaymentMethodDetailLoader(
    config: SettingsBillingLoaderConfig
): PaymentMethodDetailLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const paymentMethods = repository.method.useList(context, {
                suspense: true,
                refetchOnWindowFocus: 'always',
            });
            assert(paymentMethods.status === 'success', 'expected suspense');
            return { methods: paymentMethods.data };
        },
    };
}
