import { Box, HStack, StackDivider, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { getAllChildrenProps } from '../../../util';
import { AttributeItemProps, AttributeListProps } from '../../../view/common';

export const AttributeList: React.FC<
    { children?: React.ReactNode | undefined } & AttributeListProps
> = (props) => {
    const { item: itemListProps } = getAllChildrenProps(props.children, {
        item: AttributeItem,
    });
    return (
        <HStack
            w="full"
            divider={<StackDivider borderColor="whiteAlpha.200" borderWidth={1} />}
            spacing={props.spacing ?? 4}
        >
            {itemListProps.map((itemProps) => (
                <VStack
                    key={itemProps.label}
                    align="start"
                    spacing={itemProps.spacing ?? 1}
                >
                    <Text
                        fontSize="xs"
                        color="whiteAlpha.500"
                        textTransform="uppercase"
                        letterSpacing="wider"
                        fontWeight="bold"
                    >
                        {itemProps.label}
                    </Text>
                    <Box w="full" fontWeight="medium">
                        {itemProps.children}
                    </Box>
                </VStack>
            ))}
        </HStack>
    );
};

export const AttributeItem: React.FC<
    { children?: React.ReactNode | undefined } & AttributeItemProps
> = (props) => {
    return <React.Fragment />;
};
