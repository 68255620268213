import React from 'react';
import {
    Icon,
    VStack,
    Tag,
    Wrap,
    WrapItem,
    Box,
    Text,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import warningSVG from '../../../../svg/warning.svg';
import thumbsUpSVG from '../../../../svg/thumbs-up.svg';
import targetM from '../../../../images/target.png';
// import glass from '../../../../images/mglass.png';
import linechart from '../../../../images/linechart.png';
import { CompetitiveSetViewProps } from './competitiveProps';

export interface CompetitiveSetQuality {
    text: string;
    src?: string;
    tooltip: string;
    icon?: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    >;
}

export const QUALITY: Record<string, CompetitiveSetQuality | undefined> = {
    very_large_sample: {
        text: 'Complete Coverage',
        src: linechart,
        tooltip:
            'This benchmark is a very large sample size and highly representative of the market.',
    },
    highly_relevant: {
        text: 'Very Large Sample',
        src: targetM,
        tooltip: 'This benchmark is highly representative of the market.',
    },
    relevant_sample: {
        text: 'Large Sample',
        src: thumbsUpSVG,
        tooltip: 'Representative of the market.',
    },
    small_sample: {
        text: 'Relevant Sample',
        icon: SearchIcon,
        tooltip: 'You can expand your search for a larger sample.',
    },
    insufficient_sample: {
        text: 'Insufficient',
        src: warningSVG,
        tooltip: 'Not enought samples.',
    },
} as const;

export const CompetitiveSetView: React.FC<
    { children?: React.ReactNode | undefined } & CompetitiveSetViewProps
> = (props) => {
    const { transparency } = props;
    if (!transparency) {
        return <Box>not found</Box>;
    }
    const tooltipContent = () => {
        let response = [];
        if (transparency.range[0] == 0)
            return "Not enough companies in the data set. You can expand your search to get the results you're looking for.";
        response.push(
            transparency.range?.length > 1
                ? `${transparency.range[0]} - ${transparency.range[1]} companies matched.\n`
                : `${transparency.range[0]}+ companies matched.\n`
        );
        response.push(QUALITY[transparency.quality]?.tooltip);
        return response.join(' ');
    };

    return (
        <Tooltip
            // arrowPadding={1}
            borderRadius="xl"
            // hasArrow
            offset={[70, 0]}
            label={
                <Box pt={2} pb={2} pr={4} pl={4}>
                    <Text pb={1} align="center">
                        {QUALITY[transparency.quality]?.text}
                    </Text>
                    <Text align="center">{tooltipContent()}</Text>
                </Box>
            }
            bg="#1D1C21"
            border="1px solid white"
            color="white"
            placement="bottom-end"
        >
            <Wrap justify="center" align="center" spacing={2}>
                <WrapItem>
                    {QUALITY[transparency.quality]?.src ? (
                        <Image src={QUALITY[transparency.quality]?.src} />
                    ) : (
                        <Icon
                            boxSize={4}
                            color="#96FBA4"
                            as={QUALITY[transparency.quality]?.icon}
                        />
                    )}
                </WrapItem>
                <Wrap>
                    <Text textAlign="center" fontSize="md" fontWeight="semibold">
                        {QUALITY[transparency.quality]?.text}
                    </Text>
                </Wrap>
            </Wrap>
        </Tooltip>
    );
};
