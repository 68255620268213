import React from 'react';
import { CompanyListBoxViewProps } from '../../../../macro';
import { PeerViewConfig } from '../../base';
import { PeerCompanyEditViewProps } from '../../company';
import { PeerGroupCreateViewProps } from './peerGroupCreateProps';

export function createPeerGroupCreateView(
    config: PeerViewConfig & {
        company: React.FC<
            { children?: React.ReactNode | undefined } & PeerCompanyEditViewProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & PeerGroupCreateViewProps> {
    const { company: CompanyView } = config;
    return (props) => {
        return <CompanyView {...props.company} />;
    };
}
