import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReportAdapter } from './reportAdapter';
import { ReportRepository } from './reportInterface';
import { ReportQuery } from './reportQuery';

export function createReportRepository(adapter: ReportAdapter): ReportRepository {
    const PREFIX = ['reports'];
    return {
        useFind(context, query, options) {
            const client = useQueryClient();
            const applied: ReportQuery = {
                limit: query.limit ?? 100,
                asset: query.asset ?? null,
            };
            return useQuery({
                ...options,
                queryKey: [...PREFIX, context.organization.id, applied],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                onSuccess(data) {
                    for (const item of data) {
                        client.setQueryData(
                            [...PREFIX, context.organization.id, item.id],
                            item
                        );
                    }
                },
            });
        },
        useLookup(context, lookup) {
            return useQuery({
                queryKey: [...PREFIX, context.organization.id, lookup.id],
                async queryFn() {
                    const response = await adapter.findOne(context, lookup);
                    return response;
                },
            });
        },
        useCreate(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    const response = await adapter.create(context, props);
                    return response;
                },
                async onSuccess(data) {
                    await client.refetchQueries(PREFIX);
                },
            });
        },
    };
}
