import { UseDisclosureReturn } from '@chakra-ui/hooks';
import { createContext, useContext } from 'react';
import { FeatureSetAdapter } from './featureAdapter';
// import { Feature, FeatureConfiguration } from '../../domain';
// import { ChartAdapter } from '../chart';

export interface FeatureContextValue {
    adapter: FeatureSetAdapter;
    disclosure: UseDisclosureReturn;
    // adapters: {
    //     chart: ChartAdapter;
    // };
    // feature: Feature;
    // configuration: FeatureConfiguration;
}

export const FeatureContext = createContext<FeatureContextValue | null>(null);

export const useFeatureContext = () => {
    const context = useContext(FeatureContext);
    if (!context) {
        throw new Error(`feature context not available`);
    }
    return context;
};
