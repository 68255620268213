import { buildVideoViewProps } from './videoFactory';
import { VideoController } from './videoInterface';

export function createVideoController(): VideoController {
    return {
        useProps(props) {
            return buildVideoViewProps(props);
        },
    };
}
