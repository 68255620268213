import { AssetServiceConfig } from './assetConfig';
import { AssetService } from './assetInterface';

export function createAssetService(config: AssetServiceConfig): AssetService {
    const { repository } = config;
    return {
        useFind(context, query) {
            return repository.asset.useFind(context, query);
        },
        useCreate(context) {
            return repository.asset.useCreate(context);
        },
        useUpdate(context) {
            return repository.asset.useUpdate(context);
        },
    };
}
