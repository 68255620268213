import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { CountListSchema, CountSchema } from './countSchema';
import { CountResource } from './countInterface';

export function createCountResource(client: AxiosInstance): CountResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.post(`/api/v1/stats/count`, query, {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });

            const parsed = CountListSchema.parse(response.data);
            return parsed;
        },
    };
}
