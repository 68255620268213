import { PageIds } from '../../../../config';
import { RegistrationControllerConfig } from '../../base';
import { ACCEPT_PROFILE_ROLES } from './acceptProfileConstant';
import { RegistrationAcceptProfileController } from './acceptProfileInterface';
import { AcceptProfileViewProps } from './acceptProfileProps';

export function createRegistrationAcceptProfileController(
    config: RegistrationControllerConfig
): RegistrationAcceptProfileController {
    const {
        provider: { createPageController, createFormController },
        controller: {
            step: { useProps: useLayoutProps },
        },
    } = config;

    const { useProps: usePageProps } = createPageController({
        id: PageIds.REGISTRATION_ACCEPT_USER,
        title: 'User profile',
        description: `Complete your profile details to get started with your account`,
        provider: config.provider,
    });

    const { useProps: useFormProps } = createFormController({
        submitOnEnter: true,
    });

    return {
        useProps(context, props): AcceptProfileViewProps {
            const page = usePageProps({}, props.page);
            const form = useFormProps(props.form);
            return {
                page,
                form,
                layout: useLayoutProps(
                    {
                        id: page.id,
                        name: page.header.title,
                        description: page.header.description,
                        submitText: 'Continue',
                    },
                    { ...props.layout, form }
                ),
                input: {
                    select: {
                        options: ACCEPT_PROFILE_ROLES,
                    },
                },
            };
        },
    };
}
