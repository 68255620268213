import { useState } from 'react';
import { FormSubmissionController } from './formSubmissionInterface';

export function createFormSubmissionController(): FormSubmissionController {
    return {
        useProps(context, item, props) {
            const [error, setError] = useState<Error | null>(null);
            return {
                title: item.dataset.name,
                description: item.dataset.description,
                error,
                submitButtonProps: {
                    onClick: props.form.handleSubmit((values) => {
                        console.log('DEBUG values', values);
                    }),
                },
            };
        },
    };
}
