import { buildCohortState } from './cohortFactory';
import { AnyRanking, CohortState } from './cohortModel';

export function castRankingToCohortState(ranking: AnyRanking): CohortState {
    let state: CohortState;
    if (ranking.kind === 'fixed') {
        state = {
            mode: 'fixed',
            config: {
                fixed: ranking,
                dynamic: buildCohortState({ mode: 'dynamic', comparison: null }).config
                    .dynamic,
            },
        };
    } else if (ranking?.kind === 'dynamic') {
        state = buildCohortState({ mode: 'dynamic', comparison: null });
    } else {
        state = buildCohortState({ mode: 'dynamic', comparison: null });
    }
    return state;
}

export function castCohortStateToRanking(state: CohortState): AnyRanking {
    if (state.mode === 'fixed') {
        return {
            kind: 'fixed',
            comparison: state.config.fixed.comparison,
            cohorts: state.config.fixed.cohorts,
        };
    }
    return {
        kind: 'dynamic',
        cohorts: state.config.dynamic.cohorts,
    };
}
