import { ApplicationIcons } from '../../../../../../icon';
import { PaymentMethod } from '../../../../../../app';

export function getPaymentMethodImage(icons: ApplicationIcons, item: PaymentMethod) {
    if (item.kind !== 'card') {
        return null;
    }
    if (item.brand === 'visa') return icons.billing.visa;
    if (item.brand === 'mastercard') return icons.billing.mastercard;
    if (item.brand === 'american-express') return icons.billing.americanexpress;
    return null;
}
