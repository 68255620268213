import { BenchmarkListAggregate } from '../benchmarkListModel';

export const METRIC_FIXTURE_SIMPLE: BenchmarkListAggregate[] | null = null;

// export const METRIC_FIXTURE_SIMPLE: BenchmarkListViewItem[] = [
//     {
//         configuration: {
//             dashboard: {
//                 id: '',
//             },
//             definition: { id: '' },
//             filters: [],
//             competitiveSet: { conditions: [] },
//             dimensions: [],
//             ranking: {
//                 kind: 'dynamic',
//                 cohorts: [],
//             },
//         },
//         ranking: null,
//         plugin: {
//             id: 'asd',
//             name: 'asd',
//             iconUrl: null,
//         },
//         connection: { plugin: 'facebookads', status: 'ready', dependencies: [] },
//         calculation: {
//             peers: [],
//             definition: {
//                 id: 'cpm',
//                 name: 'CPM',
//             },
//             comparison: {
//                 start: new Date(),
//                 end: new Date(),
//             },
//             period: { start: new Date(), end: new Date() },
//             current: {
//                 definition: {
//                     kind: 'asset',
//                     name: 'My company',
//                 },
//                 value: {
//                     base: 5,
//                     comparison: 4,
//                     change: 0.1,
//                 },
//             },
//             min: {
//                 definition: {
//                     kind: 'expression',
//                     name: 'Low performer',
//                     expr: {
//                         kind: 'boolean',
//                         value: true,
//                     },
//                     reducer: {
//                         kind: 'percentile',
//                         value: 25,
//                     },
//                 },
//                 value: {
//                     base: 5,
//                     comparison: 4,
//                     change: 0.1,
//                 },
//             },
//             low: {
//                 definition: {
//                     kind: 'expression',
//                     name: 'Low performer',
//                     expr: {
//                         kind: 'boolean',
//                         value: true,
//                     },
//                     reducer: {
//                         kind: 'percentile',
//                         value: 25,
//                     },
//                 },
//                 value: {
//                     base: 5,
//                     comparison: 4,
//                     change: 0.1,
//                 },
//             },
//             mid: {
//                 definition: {
//                     kind: 'expression',
//                     name: 'Low performer',
//                     expr: {
//                         kind: 'boolean',
//                         value: true,
//                     },
//                     reducer: {
//                         kind: 'percentile',
//                         value: 50,
//                     },
//                 },
//                 value: {
//                     base: 15,
//                     comparison: 14,
//                     change: -0.15,
//                 },
//             },
//             high: {
//                 definition: {
//                     kind: 'expression',
//                     name: 'Low performer',
//                     expr: {
//                         kind: 'boolean',
//                         value: true,
//                     },
//                     reducer: {
//                         kind: 'percentile',
//                         value: 75,
//                     },
//                 },
//                 value: {
//                     base: 10,
//                     comparison: 9,
//                     change: 0.1,
//                 },
//             },
//             max: {
//                 definition: {
//                     kind: 'expression',
//                     name: 'Low performer',
//                     expr: {
//                         kind: 'boolean',
//                         value: true,
//                     },
//                     reducer: {
//                         kind: 'percentile',
//                         value: 25,
//                     },
//                 },
//                 value: { base: 33, comparison: 32, change: 0.1 },
//             },
//         },
//         metric: {
//             id: 'cpm',
//             key: 'cpm',
//             kind: 'outcome',
//             description: null,
//             formula: 'sum(spend) / sum(revenue) / 1000',
//             plugin: 'facebookads',
//             name: 'Cost per mille',
//             abbreviation: 'CPM',
//             type: { kind: 'currency', currency: 'usd' },
//             inverse: true,
//             created_at: new Date(),
//             updated_at: new Date(),
//         },
//     },
// ];
