import { ContextFreePeriod, periodToDays } from '../../../../domain';
import { TimeGranularityType } from '../../../../models/Common';

export function castPeriodToGranularity(period: ContextFreePeriod): TimeGranularityType {
    const days = periodToDays(period);
    // console.log('period', days, period);
    if (days >= 180) {
        return 'month';
    }
    return 'week';
}
