import {
    AnyType,
    isDateProperty,
    isDateType,
    MemberEntity,
    Property,
    resolveProperties,
} from '../../attributes';
import { AnyResolvedType } from '../../attributes';
import { ViewEntity } from './viewModel';

export function resolveView(
    view: ViewEntity,
    members: MemberEntity[]
): ViewEntity<AnyResolvedType> {
    return { ...view, columns: resolveProperties(members, view.columns) };
}

export function getPartition<TType extends AnyType>(
    view: Pick<ViewEntity<TType>, 'columns'>
): Property<TType> | null {
    const found = view.columns.find(isDateProperty) ?? null;
    return found;
}
