import { runInAction, makeObservable, observable } from 'mobx';
import StoreBase from '../../stores/StoreBase';
import { AccessResponseDenied } from '../../base';

export class AccessStore extends StoreBase {
    current: AccessResponseDenied | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            current: observable,
        });
    }

    setResponse(props: AccessResponseDenied) {
        runInAction(() => {
            this.current = props;
        });
    }

    clear() {
        runInAction(() => {
            this.current = null;
        });
    }
}
