import { Text, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { ApplicationUI, FormTheme } from '../../../ui';

export function createOnboardingApplicationTheme(Base: ApplicationUI): ApplicationUI {
    return {
        ...Base,
        Link(props) {
            let content = <></>;
            if (typeof props.children === 'string') {
                content = (
                    <Text cursor="pointer" _hover={{ textDecor: 'underline' }}>
                        {props.children}
                    </Text>
                );
            }
            return <Base.Link {...props} children={content} />;
        },
        Tooltip(props) {
            return (
                <Base.Tooltip
                    placement="top"
                    hasArrow={true}
                    p={2}
                    bg="blackAlpha.900"
                    {...props}
                />
            );
        },
        Button: React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
            if (props.colorScheme === 'green') {
                return (
                    <Base.Button
                        // @ts-expect-error
                        ref={ref}
                        bg="green.500"
                        {...props}
                        _hover={{ bg: 'green.600', ...props._hover }}
                        _active={{ bg: 'green.600', ...props._active }}
                        _focus={{ outline: 'none', ...props._focus }}
                    />
                );
            }
            return (
                <Base.Button
                    // @ts-expect-error
                    ref={ref}
                    bg="white"
                    {...props}
                    _hover={{ bg: 'gray.100', ...props._hover }}
                    _active={{ bg: 'gray.100', ...props._active }}
                    _focus={{ outline: 'none', ...props._focus }}
                />
            );
        }),
    };
}
