import { FilterForm } from '../../../../service';
import {
    InCondition,
    InFilter,
    isEnumLikeProperty,
    isInCondition,
} from '../../../../domain/attributes';
import { FilterController } from './filterInterface';
import { useMemo } from 'react';
import { AnalysisFilterViewProps } from './filterProps';

export function createAnalysisFilterController(forms: {
    filter: FilterForm;
}): FilterController {
    return {
        useProps(props): AnalysisFilterViewProps {
            const initialFilters: InFilter[] = useMemo(
                () =>
                    props.view.columns.filter(isEnumLikeProperty).map(
                        (item): InFilter => ({
                            property: item,
                            operator: 'in',
                            status: 'enabled',
                            statusReason: null,
                        })
                    ),
                [props.view.columns]
            );

            const value = useMemo(
                () => ({
                    conditions: Object.entries(props.value).map(
                        ([key, value]): InCondition => ({
                            key,
                            operator: 'in',
                            value: value as string[],
                        })
                    ),
                }),
                [props.value]
            );

            const formState = forms.filter.useFormState({
                items: initialFilters,
                value,
                onChange(value) {
                    props.onChange(
                        value.conditions.reduce(
                            (acc, condition) => ({
                                ...acc,
                                [condition.key]: condition.value,
                            }),
                            {} as Record<string, string[] | undefined>
                        )
                    );
                },
            });

            const filters = formState.getFilters();

            const filterByKey = useMemo(
                () =>
                    filters.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.property.key]: item,
                        }),
                        {} as Record<string, InFilter | undefined>
                    ),
                [filters]
            );

            return {
                context: props.context,
                filters: formState.getFilters().map((item) => ({
                    kind: 'property',
                    property: item.property,
                    default: {
                        operator: item.operator,
                    },
                })),
                getItemProps(item, index) {
                    const filter = filterByKey[item.property.key];
                    const value = props.value[item.property.key];
                    return {
                        index,
                        filter: item,
                        isDisabled: false,
                        current: {
                            value: value,
                        },
                        onApply(value) {
                            if (Array.isArray(value.value) && value.value.length === 0) {
                                const { [item.property.key]: _removed, ...rest } =
                                    props.value;
                                return props.onChange(rest);
                            }
                            props.onChange({
                                ...props.value,
                                [item.property.key]: value.value,
                            });
                        },
                        onClear() {
                            const { [item.property.key]: _removed, ...rest } =
                                props.value;
                            props.onChange(rest);
                        },
                        getTriggerProps() {
                            if (filter?.status === 'enabled') {
                                return {};
                            }
                            return {
                                disabled: true,
                                tooltipProps: {
                                    label: filter?.statusReason,
                                },
                            };
                        },
                    };
                },
            };
        },
    };
}
