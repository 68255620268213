import moment from 'moment';
import { PERIOD_OPTIONS, COMPARISON_OPTIONS } from '../../../../config';
import {
    Period,
    isRelativeDuration,
    isAbsolutePeriod,
    durationToRange,
    daterangeSubtract,
} from '../../../../domain';

function getPrevPeriods(minDate?: Date) {
    if (minDate) {
        const minDateMoment = moment(minDate);
        const current = moment();

        const filtered = PERIOD_OPTIONS.filter((x) => {
            if (isRelativeDuration(x.value)) {
                const compare = current.add(-x.value.amount, x.value.interval);
                return compare.isAfter(
                    // hack that adds some buffer because we're comparing different granularities, eg. 90 days vs 3 months
                    minDateMoment.clone().subtract(1, 'week')
                );
            }
            return true;
        });
        return filtered;
    }
    return PERIOD_OPTIONS;
}

export function getDateFilterOptions(current: Period, minDate?: Date) {
    const comparisonBase = isRelativeDuration(current)
        ? durationToRange(current)
        : current;
    const comparisonValue =
        isRelativeDuration(current) && isAbsolutePeriod(comparisonBase)
            ? daterangeSubtract(daterangeSubtract(comparisonBase, current), {
                  amount: 1,
                  interval: 'day',
              })
            : comparisonBase;
    return {
        period: [
            ...getPrevPeriods(minDate),
            {
                key: 'custom',
                text: 'Custom',
                // The default of the custom period automatically gets set
                // to the last expanded value of the previously selected
                // relative period
                value: isRelativeDuration(current) ? durationToRange(current) : current,
            },
        ],
        comparison: [
            ...COMPARISON_OPTIONS,
            {
                key: 'custom',
                text: 'Custom',
                // The default of the custom period automatically gets set
                // to the last expanded value of the previously selected
                // relative period
                value: comparisonValue,
            },
        ],
    };
}
