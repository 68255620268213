import React from 'react';
import { UserContextValue } from '../../../../app';
import { Authentication } from '../../../../api';
import { UseWorkspaceEditFormHook } from './workspaceEditInterface';
import {
    EditWorkspaceFormViewProps,
    EditWorkspaceContainerProps,
} from './workspaceEditProps';

export function createEditWorkspaceFormContainer(config: {
    hooks: {
        useAuth(): Authentication;
    };
    View: React.FC<
        { children?: React.ReactNode | undefined } & EditWorkspaceFormViewProps
    >;
    controller: UseWorkspaceEditFormHook;
}): React.FC<{ children?: React.ReactNode | undefined } & EditWorkspaceContainerProps> {
    const {
        hooks: { useAuth },
        View,
        controller,
    } = config;
    return (props) => {
        const auth = useAuth();
        const viewProps = controller.useProps({ auth }, props);
        return <View {...viewProps}></View>;
    };
}
