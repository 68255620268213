import { AxiosResponse, AxiosInstance } from 'axios';
import moment from 'moment';
import { ConditionObject } from '../condition';
import { Comparison } from './queryModel';
import { QueryRunPayload, QueryResponse } from './queryModel';

export function serializeComparison(comparison: Comparison) {
    return serializeCondition(comparison);
}

export function serializeCondition<
    TCond extends Pick<ConditionObject, 'operator' | 'value'>
>(condition: TCond): TCond {
    if (
        condition.operator === 'between' &&
        Array.isArray(condition.value) &&
        condition.value[0] instanceof Date &&
        condition.value[1] instanceof Date
    ) {
        const [start, end] = condition.value;
        return {
            ...condition,
            value: [
                moment.utc(start).format('YYYY-MM-DD'),
                moment.utc(end).format('YYYY-MM-DD'),
            ],
        };
    }
    return condition;
}
