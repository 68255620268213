import React from 'react';
import { AsyncSelect, CreatableSelect, AsyncCreatableSelect } from 'chakra-react-select';
import {
    Textarea,
    Input,
    Select,
    InputProps,
    TextareaProps,
    Checkbox,
    CheckboxProps,
    RadioGroup,
    Radio,
    HStack,
    Stack,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    NumberInputProps,
} from '@chakra-ui/react';
import {
    CheckboxControllerInputPropsV2,
    InputUI,
    NumberInputViewPropsV2,
    ShortTextInputViewProps,
} from '../../../ui';
import { InputUIConfig } from './inputUIConfig';

export function createInputUI(config: InputUIConfig = {}): InputUI {
    const numberController = {
        useProps(props: NumberInputViewPropsV2): NumberInputProps {
            return {
                value: props.value ?? undefined,
                // placeholder: props.placeholder,
                onChange(valueAsString, valueAsNumber) {
                    return props.onChange?.(valueAsString, valueAsNumber);
                },
            };
        },
    };
    const shortTextController = {
        useProps(props: ShortTextInputViewProps): InputProps {
            return {
                type: 'text',
                value: props.value,
                placeholder: props.placeholder,
                onChange(event) {
                    return props.onChange(event.target.value);
                },
            };
        },
    };
    const longTextController = {
        useProps(props: ShortTextInputViewProps): TextareaProps {
            return {
                value: props.value,
                placeholder: props.placeholder,
                onChange(event) {
                    return props.onChange(event.target.value);
                },
            };
        },
    };

    return {
        Range() {
            throw new Error('not impl');
        },
        MultiSelect(props) {
            throw new Error('not impl');
            // console.log('MultiSelect props', props);
            // return <></>;
        },
        Number(props) {
            const viewProps = numberController.useProps(props);
            return (
                <NumberInput {...viewProps}>
                    <NumberInputField
                        borderColor="whiteAlpha.300"
                        _hover={{ borderColor: 'whiteAlpha.400' }}
                        _focus={{ borderColor: 'whiteAlpha.500' }}
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            );
        },
        ShortText(props) {
            const viewProps = shortTextController.useProps(props);
            return (
                <Input
                    borderColor="whiteAlpha.300"
                    _hover={{ borderColor: 'whiteAlpha.400' }}
                    _focus={{ borderColor: 'whiteAlpha.500' }}
                    {...viewProps}
                />
            );
        },
        LongText(props) {
            const viewProps = longTextController.useProps(props);
            return (
                <Textarea
                    borderColor="whiteAlpha.300"
                    _hover={{ borderColor: 'whiteAlpha.400' }}
                    _focus={{ borderColor: 'whiteAlpha.500' }}
                    {...viewProps}
                />
            );
        },
        Dropdown(props) {
            return (
                <RadioGroup
                    value={props.value ?? undefined}
                    onChange={(value) => {
                        return props.onChange(value);
                    }}
                >
                    <Stack
                        spacing={props.spacing}
                        direction={props.direction}
                        color={props.color}
                    >
                        {props.options.map((item) => (
                            <Radio
                                key={item.value}
                                value={item.value}
                                _focus={{ outline: 'none' }}
                            >
                                {item.label}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            );
            // return (
            //     <Select placeholder="No selection">
            //         {props.options.map((item) => (
            //             <option key={item.value} value={item.value}>
            //                 {item.label}
            //             </option>
            //         ))}
            //     </Select>
            // );
        },
        Checkbox(props) {
            let value = undefined;
            if (props.value === true) {
                value = 'yes';
            }
            if (props.value === false) {
                value = 'no';
            }
            return (
                <RadioGroup
                    value={value}
                    onChange={(nextValue) => {
                        return props.onChange(nextValue === 'yes');
                    }}
                >
                    <Stack
                        spacing={props.spacing}
                        direction={props.direction}
                        color={props.color}
                    >
                        <Radio value="yes" _focus={{ outline: 'none' }}>
                            Yes
                        </Radio>
                        <Radio value="no" _focus={{ outline: 'none' }}>
                            No
                        </Radio>
                    </Stack>
                </RadioGroup>
            );
        },
    };
}
