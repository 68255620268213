import { useMemo } from 'react';
import { buildTableVisualization } from '../../../domain/report';
import { ChartServiceConfig } from './visualizationConfig';
import { ReportVisualizationService } from './visualizationInterface';

export function createVisualizationService(
    init: ChartServiceConfig
): ReportVisualizationService {
    return {
        useVisualization(props) {
            if (props.responses.length === 0) {
                return buildTableVisualization({
                    data: [],
                    columns: [],
                });
            }
            const [first, ...rest] = props.responses;
            const [sampleQuery] = first.queries;
            if (!sampleQuery) {
                return buildTableVisualization({
                    data: [],
                    columns: [],
                });
            }

            return useMemo(() => {
                return buildTableVisualization({
                    columns: Object.values(sampleQuery.schema.properties)
                        .filter(
                            (property) => !first.metadata.scalar.includes(property.key)
                        )
                        .flatMap((property) => ({
                            key: property.key,
                            title: property.name,
                        })),
                    data: props.responses.flatMap((response) =>
                        response.queries.flatMap((query) =>
                            query.series.items.flatMap((series) => series.data)
                        )
                    ),
                });
            }, [props.responses]);
        },
    };
}
