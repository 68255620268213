import { SettingAssetItemConfig } from '../assetConfig';
import { SettingAssetNewController } from './newInterface';

export function createSettingAssetNewController(
    config: Pick<SettingAssetItemConfig, 'controller'>
): SettingAssetNewController {
    const {
        controller: {
            asset: { new: newController },
        },
    } = config;
    return {
        useProps(context, deps, props) {
            const newProps = newController.useProps(context, deps.new, props);
            const formProps = newProps.getFormProps();
            return {
                getNewProps() {
                    return newProps;
                },
                getFormProps() {
                    return {
                        onSubmit: formProps.handleSubmit(async (values) => {
                            const response = await formProps.onSubmit(values);
                            deps.toast({
                                kind: 'success',
                                description: 'Company created',
                            });
                            deps.navigate(
                                `/u/assets/${response.id}/settings/data-sources`
                            );
                            return response;
                        }),
                    };
                },
            };
        },
    };
}
