import { DistributiveOmit } from '@emotion/react';
import { AnyCondition } from '../../../domain/attributes';

export const AOV_OPTIONS: Record<
    string,
    {
        label: string;
        condition: DistributiveOmit<AnyCondition, 'key'>;
    }
> = {
    '0-50': {
        label: '$0-$50',
        condition: {
            operator: 'between',
            value: {
                from: 0,
                to: 50,
            },
        },
    },
    '50-100': {
        label: '$50-$100',
        condition: {
            operator: 'between',
            value: {
                from: 50,
                to: 100,
            },
        },
    },
    '100-250': {
        label: '$100-$200',
        condition: {
            operator: 'between',
            value: {
                from: 100,
                to: 250,
            },
        },
    },
    '>250': {
        label: '$250+',
        condition: {
            operator: 'gt',
            value: 250,
        },
    },
};

export const MONTHLY_SPEND_OPTIONS: Record<
    string,
    {
        label: string;
        condition: DistributiveOmit<AnyCondition, 'key'>;
    }
> = {
    '0-10000': {
        label: '$0-$10,000',
        condition: {
            operator: 'between',
            value: {
                from: 0,
                to: 10000,
            },
        },
    },
    '10000-50000': {
        label: '$10,000-$50,000',
        condition: {
            operator: 'between',
            value: {
                from: 10000,
                to: 50000,
            },
        },
    },
    '50000-100000': {
        label: '$50,000-$100,000',
        condition: {
            operator: 'between',
            value: {
                from: 50000,
                to: 100000,
            },
        },
    },
    '>100000': {
        label: '$100,000+',
        condition: {
            operator: 'gt',
            value: 100000,
        },
    },
};

export const MONTHLY_REVENUE_OPTIONS = MONTHLY_SPEND_OPTIONS;
