import { ThreadObject } from '@varos/assistant-sdk';
import { ThreadEntity } from '../../../app/assistant';

export function toThreadEntity(dto: ThreadObject): ThreadEntity {
    return {
        id: dto.id,
        workflow: {
            id: dto.workflow,
        },
        createdAt: dto.created_at,
    };
}
