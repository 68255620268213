import { URLSearchParamsInit } from 'react-router-dom';
import moment from 'moment';
import { isAbsolutePeriod, isRelativePeriod } from '../../../domain';
import { ReportControl } from '../../domain/report';
import { ReportInputSchema } from './queryParamSchema';

export function toQueryParams(control: ReportControl): URLSearchParamsInit {
    if (isAbsolutePeriod(control.date.period)) {
        return {
            from: moment(control.date.period.start).format('YYYY-MM-DD'),
            to: moment(control.date.period.end).format('YYYY-MM-DD'),
        };
    }
    if (isRelativePeriod(control.date.period)) {
        return {
            interval: control.date.period.interval,
            amount: control.date.period.amount.toString(),
        };
    }
    return {};
}

export function fromSearchParams(
    value: URLSearchParams
): Partial<ReportControl['date']> | null {
    const mapped = [...value.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {} as Record<string, string>
    );
    const parsed = ReportInputSchema.safeParse(mapped);
    if (!parsed.success) {
        console.error(parsed.error);
        return null;
    }
    if (parsed.data.from && parsed.data.to) {
        return {
            period: { start: parsed.data.from, end: parsed.data.to },
        };
    }
    if (parsed.data.amount && parsed.data.interval) {
        return {
            period: { interval: parsed.data.interval, amount: parsed.data.amount },
        };
    }
    return null;
}
