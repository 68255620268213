import React from 'react';
import { useForm } from 'react-hook-form';
import { OrganizationMappingFormValues } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import {
    SettingDatasetConnectionController,
    SettingDatasetConnectionDeps,
    SettingDatasetConnectionLoader,
} from './connectionInterface';
import { SettingDatasetConnectionContainerProps } from './connectionProps';
import { SettingDatasetConnectionConfig } from './connectionConfig';

export function createSettingDatasetConnectionContainer(
    init: Pick<SettingDatasetConnectionConfig, 'infra'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingDatasetConnectionLoader,
    controller: SettingDatasetConnectionController
): React.FC<
    { children?: React.ReactNode | undefined } & SettingDatasetConnectionContainerProps
> {
    const {
        infra: { toaster },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const deps: SettingDatasetConnectionDeps = {};
        const viewProps = controller.useProps(viewContext, deps, viewData);
        if (viewProps.getViewProps().items.length === 0) {
            return <Component.Empty {...viewProps}>{children}</Component.Empty>;
        }
        return <Component.List {...viewProps}>{children}</Component.List>;
    };
}
