import { PluginConnection } from '../../../../domain/metrics';
import { PluginEntity } from '../../../../domain';
import { assert } from '../../../../util/assert';
import {
    MetricCollectionNewDependencies,
    MetricCollectionNewLoader,
} from './collectionNewInterface';
import { CollectionNewAggregate } from './collectionNewModel';
import { chain } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useWorkspaceContextV2 } from '../../../../context';
import { MetricCollectionNewConfig } from './collectionNewConfig';
import { useMemo } from 'react';

export function createMetricCollectionNewLoader(
    config: MetricCollectionNewConfig
): MetricCollectionNewLoader {
    const { repository } = config;
    return {
        useLoad(context): MetricCollectionNewDependencies {
            const {
                plugins,
                metric: { definitions, collections },
            } = useWorkspaceContextV2();

            const connections = repository.connection.useFind(context, {
                workspaces: [
                    {
                        id: context.workspace.id as number,
                    },
                ],
            });

            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const connectionsByPlugin = useMemo(
                () =>
                    connections.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [connections.data]
            );

            const aggregateQuery = useQuery({
                queryKey: ['metric-new', context.organization, context.workspace],
                async queryFn() {
                    const items = chain(definitions.data ?? [])
                        .flatMap<CollectionNewAggregate>((item) => {
                            const plugin = pluginsById[item.plugin];
                            const connection = connectionsByPlugin[item.plugin];
                            if (!plugin) {
                                return [];
                            }
                            // if (!connection) {
                            //     return [];
                            // }
                            return [
                                {
                                    connection: connection ?? null,
                                    plugin,
                                    metric: item,
                                },
                            ];
                        })
                        .orderBy((item) => [item.connection === null ? -1 : 1], 'desc')
                        .value();

                    return items;
                },
                enabled: connections.status === 'success' && plugins.status === 'success',
            });

            const collectionQuery = useQuery({
                queryKey: ['collection', 'new', collections.data],
                async queryFn() {
                    const [item, ...rest] =
                        collections.data?.filter((item) => item.default) ?? [];
                    assert(rest.length === 0, 'more than 1 default collection found');
                    if (!item) {
                        return null;
                    }
                    return item;
                },
            });

            return {
                status: aggregateQuery.status,
                queries: {
                    metrics: aggregateQuery,
                    collection: {
                        default: collectionQuery,
                    },
                },
                mutations: {
                    upsert: repository.collection.useUpsert(context),
                },
            };
        },
    };
}
