import { keyBy as lodashKeyBy } from 'lodash';

type KeyValueCallback<TItem, TValue> = (item: TItem) => [string, TValue];

/**
 * Properly sets nullable type for members which is currently not done by
 * native lodash types
 * @param items
 * @param keyValueCallback
 * @returns
 */
export function keyByMapSafe<TItem, TValue>(
    items: TItem[],
    keyValueCallback: KeyValueCallback<TItem, TValue>
): Record<string, TValue | undefined> {
    return items.reduce<Record<string, TValue | undefined>>((acc, item) => {
        const [key, value] = keyValueCallback(item);
        acc[key] = value;
        return acc;
    }, {});
}
