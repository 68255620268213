import { z } from 'zod';

export const AssetTypeSchema = z.union([
    z.literal('mobile_app'),
    z.literal('ecommerce_website'),
    z.literal('saas'),
    z.literal('website'),
    z.literal('marketplace'),
]);

export const AssetDtoSchema = z.object({
    id: z.number(),
    organization_id: z.number(),
    name: z.string(),
    asset_type: AssetTypeSchema,
    url: z.string(),
    external_identifier: z.string(),
    icon_url: z.nullable(z.string()),
    is_visible: z.boolean(),
    created_at: z.date({ coerce: true }),
    updated_at: z.date({ coerce: true }),
});

export const AssetDtoListSchema = z.object({
    data: z.array(AssetDtoSchema),
});
