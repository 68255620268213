import React from 'react';
import { observer } from 'mobx-react';
import { apiV1 } from '../../../api/axios';
import isEmail from 'validator/es/lib/isEmail';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { CreatableSelect } from 'chakra-react-select';
import { useForm, Controller } from 'react-hook-form';
import { useStore } from '../../../stores/setupContext';
import {
    VStack,
    Heading,
    Flex,
    FormControl,
    FormLabel,
    Button,
    Center,
    FormErrorMessage,
    Text,
    Container,
    SimpleGrid,
    Link,
    GridItem,
    Image,
} from '@chakra-ui/react';
import Input from '../../../components/FormControls/Input';
import playSVG from '../../../svg/play.svg';

interface MultiSelectValue {
    value: string;
}
interface UserInvitesForm {
    companies?: MultiSelectValue[];
    emails?: MultiSelectValue[];
    referals?: string;
}

interface Segment {
    name: string;
    data: string[] | string;
}

interface UserInvitesModel {
    title?: string;
    subtitle?: string;
}

const UserInvites: React.FC<
    { children?: React.ReactNode | undefined } & UserInvitesModel
> = observer(({ title = 'Optional Step', subtitle = 'Share the data love' }) => {
    const { control, handleSubmit, formState, register } = useForm<UserInvitesForm>({});
    const { auth } = useStore();

    const sendEmailList = async (emails: string[]) => {
        await auth.waitAuthenticated(); // check if token is expired, and refresh it if needed
        return apiV1.post(`/user-invites`, { email_list: emails });
    };

    const sendSegmentEvent = (segments: Segment[]) => {
        segments.forEach((segment) => {
            auth.reportEvent(segment.name, { [segment.name]: segment.data });
        });
    };

    const nextStep = async () => {
        await auth.skipCurrentStage();
    };

    const onSubmit = async (data: UserInvitesForm) => {
        let promises = [];
        let segments = [];
        let done = false;
        if (data?.companies && data.companies.length > 0) {
            const comps = [...new Set(data.companies.map((c) => c.value))];
            segments.push({
                name: 'company_referred',
                data: comps,
            });
        }
        if (data?.referals && data.referals.length > 0) {
            segments.push({
                name: 'referal_source',
                data: data?.referals,
            });
        }
        sendSegmentEvent(segments);
        done = true;
        if (data?.emails && data?.emails?.length > 0) {
            const emails = [...new Set(data.emails.map((e) => e.value))];
            promises.push(sendEmailList(emails));
        }
        const result = await Promise.all(promises);
        if (done || result?.length > 0) await auth.checkOnBoardingStatus();

        await nextStep();
    };

    const customStyles = {
        valueContainer: (provider: any) => ({
            ...provider,
            backgroundColor: 'white',
        }),
    };

    return (
        <Flex h="full" justify="center" alignItems="center">
            <Container centerContent maxW="xl">
                <VStack
                    w="full"
                    h="full"
                    p={10}
                    spacing={8}
                    alignItems="flex-center"
                    border="1px"
                    borderColor="frame"
                    borderRadius={10}
                    bg="box"
                >
                    <VStack>
                        <Heading size="lg" textAlign="center" color="onboarding.title">
                            {title}
                        </Heading>
                        <Text
                            w="sm"
                            fontSize="lg"
                            color="onboarding.subtitle"
                            textAlign="center"
                        >
                            {subtitle}
                        </Text>
                    </VStack>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <SimpleGrid columns={1} columnGap={4} rowGap={5} w="full">
                            <GridItem colSpan={1}>
                                <FormControl isInvalid={!!formState.errors?.companies}>
                                    <FormLabel>
                                        Would you like to refer anyone to Varos?
                                    </FormLabel>
                                    <Controller
                                        name="companies"
                                        control={control}
                                        rules={{
                                            required: !(
                                                formState.errors?.emails &&
                                                (formState.errors?.emails as any).required
                                            )
                                                ? false
                                                : 'This field is required!',
                                            validate: (v) => {
                                                let invalidEmails: any[] = [];
                                                if (v && v.length > 0) {
                                                    v.forEach((e: any) => {
                                                        if (!isEmail(e.value))
                                                            invalidEmails.push(e.value);
                                                    });
                                                }
                                                if (invalidEmails.length > 0) {
                                                    if (invalidEmails.length == 1)
                                                        return `${invalidEmails.join(', ')} is an invalid email!`;
                                                    return `Invalid emails: ${invalidEmails.join(', ')}`;
                                                }
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CreatableSelect
                                                {...field}
                                                isMulti
                                                styles={customStyles}
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                                placeholder="Add emails here"
                                                formatCreateLabel={(userInput: any) =>
                                                    `Press enter to add ${userInput}`
                                                }
                                                noOptionsMessage={() => null}
                                            />
                                        )}
                                    />
                                    {formState.errors?.companies && (
                                        <FormErrorMessage>
                                            <WarningTwoIcon mr={1} />
                                            {(formState.errors.companies as any).message}
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                            </GridItem>
                            {/* <FormControl isInvalid={!!formState.errors?.emails}>
                                    <FormLabel>Would you like us to send access to any of your colleagues?</FormLabel>
                                    <Controller
                                        name="emails"
                                        control={control}
                                        rules={{
                                            required: !formState.errors?.companies ? false : 'This field is required!',
                                            validate: (v) => {
                                                let invalidEmails: any[] = [];
                                                if (v && v.length > 0) {
                                                    v.forEach((e: any) => {
                                                        if (!isEmail(e.value)) invalidEmails.push(e.value);
                                                    });
                                                }
                                                if (invalidEmails.length > 0) {
                                                    if (invalidEmails.length == 1)
                                                        return `${invalidEmails.join(', ')} is an invalid email!`;
                                                    return `Invalid emails: ${invalidEmails.join(', ')}`;
                                                }
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CreatableSelect
                                                {...field}
                                                isMulti
                                                styles={customStyles}
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                                formatCreateLabel={(userInput: any) =>
                                                    `Press enter to add ${userInput}`
                                                }
                                                placeholder="Add emails here"
                                                noOptionsMessage={() => null}
                                            />
                                        )}
                                    />
                                    {formState.errors?.emails && (
                                        <FormErrorMessage>
                                            <WarningTwoIcon mr={1} />
                                            {(formState.errors.emails as any).message}
                                        </FormErrorMessage>
                                    )}
                                </FormControl> */}
                            <FormControl pb={2}>
                                <Input
                                    placeholder="For example: Referral, LinkedIn, News Article"
                                    {...register('referals')}
                                    label="Where did you hear about Varos?"
                                    type="text"
                                    bg="button.white"
                                />
                                {formState.errors?.referals && (
                                    <FormErrorMessage>
                                        <WarningTwoIcon mr={1} />
                                        {(formState.errors.referals as any).message}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                            <GridItem colSpan={1}>
                                <Center>
                                    <Button
                                        w="100%"
                                        textColor="button.white"
                                        rightIcon={<Image pt={0.5} src={playSVG} />}
                                        boxShadow="lg"
                                        bg="button.green"
                                        type="submit"
                                        _hover={{ bg: 'button.hoverGreen' }}
                                        isDisabled={!formState.isDirty}
                                    >
                                        Continue
                                    </Button>
                                </Center>
                            </GridItem>
                            <Center>
                                <Link
                                    fontSize="lg"
                                    color="onboarding.subtitle"
                                    onClick={nextStep}
                                >
                                    Skip
                                </Link>
                            </Center>
                        </SimpleGrid>
                    </form>
                </VStack>
            </Container>
        </Flex>
    );
});

export default UserInvites;
