import { AnyMappableEntity } from '../../../domain/assets';
import { MappableEntityAdapter } from '../../../app/assets';
import { MappableEntityImplConfig } from './entityImplConfig';
import { chain, uniqBy } from 'lodash';

export function createMappableEntityImpl(
    config: MappableEntityImplConfig
): MappableEntityAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.asset.entities.list(context, {
                integrations: query.integrations.map((item) => item.id),
            });
            return {
                total: response.data.length,
                items: chain(response.data)
                    .map((item): AnyMappableEntity => {
                        const normal = item.id_type.toLowerCase();
                        const name = item.name?.trim();
                        return {
                            id: item.id,
                            key: item.entity_id,
                            integrationId: item.integration_id,
                            status:
                                item.is_eligible && item.is_active ? 'valid' : 'invalid',
                            kind: normal as any,
                            type: item.id_type,
                            name: !!name ? name : 'N/A',
                        };
                    })
                    .orderBy(
                        (item) =>
                            query.orderBy.map((order) => {
                                if (order.key === 'name') {
                                    return item[order.key]?.toLowerCase();
                                }
                                return item[order.key];
                            }),
                        query.orderBy.map((order) => order.direction)
                    )
                    .uniqBy((item) => [item.type, item.key].join(':'))
                    .value(),
                limit: 0,
            };
        },
    };
}
