import { useEffect, useMemo, useRef, useState } from 'react';
import { BsPercent } from 'react-icons/bs';
import { HiOutlineHashtag } from 'react-icons/hi';
import { assert } from '../../../../util/assert';
import { buildMetricItemProps } from '../../../../view';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesResponseItemController } from './studiesResponseItemInterface';
import { StudiesResponseItemViewProps } from './studiesResponseItemProps';

export function createStudiesResponseItemController(
    config: Pick<StudiesBaseRouteConfig, 'controller'>
): StudiesResponseItemController {
    const RelativeIcon = BsPercent;
    const AbsoluteIcon = HiOutlineHashtag;
    return {
        useProps(context, item, props): StudiesResponseItemViewProps {
            // console.log('item.dataset', item.dataset);

            const [breakdown] = item.visualization.query.breakdown ?? [];
            const [metric] = item.visualization.metrics;
            assert(metric, 'missing metric');
            assert(breakdown, 'expected study chart to have breakdown');
            assert(breakdown.kind === 'dimension', 'expected dimension breakdown');

            const [property, ...rest] = useMemo(
                () =>
                    item.dataset.configuration.schema.filter(
                        (candidate) => breakdown.key === candidate.key
                    ),
                [item.dataset.configuration.schema, breakdown.key]
            );

            assert(property, () => {
                console.info(
                    'item.dataset.configuration.schema',
                    item.dataset.configuration.schema
                );
                return `no property matches visualization breakdown key ${breakdown.key}`;
            });

            const visualizationKey = useMemo(() => {
                return JSON.stringify({ ...item.visualization, ...props.control });
            }, [item.visualization, props.control]);

            return {
                errorBoundary: {
                    key: visualizationKey,
                },
                metric: {
                    label: item.visualization.name ?? 'N/A',
                    description: item.visualization.description ?? null,
                },
                question: {
                    label: property.name,
                },
                segment: {
                    options: [
                        {
                            onClick: props.segment.onChange.bind(null, 'all'),
                            isSelected: props.segment.value === 'all',
                            label: 'All responses',
                            isDisabled: false,
                            disabledReason: null,
                            Icon: AbsoluteIcon,
                        },
                        // {
                        //     onClick: props.segment.onChange.bind(null, 'peers'),
                        //     isSelected: props.segment.value === 'peers',
                        //     label: 'Peer group',
                        //     isDisabled: props.control.segment.length === 0,
                        //     disabledReason: `No peer group filters specified`,
                        //     Icon: RelativeIcon,
                        // },
                    ],
                },
                mode:
                    item.visualization.kind === 'category' &&
                    // @ts-expect-error TODO fix row aggregation typing
                    metric.type === 'count'
                        ? {
                              options: [
                                  {
                                      onClick: props.mode.onChange.bind(null, 'absolute'),
                                      isSelected: props.mode.value === 'absolute',
                                      label: 'Responses',
                                      Icon: AbsoluteIcon,
                                  },
                                  {
                                      onClick: props.mode.onChange.bind(null, 'relative'),
                                      isSelected: props.mode.value === 'relative',
                                      label: 'Percentage',
                                      Icon: RelativeIcon,
                                  },
                              ],
                          }
                        : null,
            };
        },
    };
}
