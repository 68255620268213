import { ReportInvitationAdapter } from '../../../app';
import { ReportInvitationImplConfig } from './reportInvitationConfig';

export function createReportInvitationImpl(
    config: ReportInvitationImplConfig
): ReportInvitationAdapter {
    const {
        api: { report: api },
    } = config;

    return {
        async find(context, query) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            return [];
        },
    };
}
