import { useLocalStorage } from 'react-use';
import { NetworkOverviewRouteConfig } from './networkOverviewConfig';
import { NetworkOverviewLoader } from './networkOverviewInterface';

export function createNetworkOverviewLoader(
    config: Pick<NetworkOverviewRouteConfig, 'repository'>
): NetworkOverviewLoader {
    const {
        repository: {},
    } = config;
    return {
        useLoad(context) {
            return {};
        },
    };
}
