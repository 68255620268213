import { AxiosInstance } from 'axios';
import { DashboardDepsCheckResultDto } from './dashboardDepModel';
import qs from 'qs';

export async function getDashboardDeps(
    api: AxiosInstance,
    dashboard: string,
    assetIds: number[],
    options?: { organizationId?: number; apiKey?: string }
): Promise<DashboardDepsCheckResultDto> {
    const params: Record<string, unknown> = {
        asset_ids: assetIds,
    };
    if (options?.organizationId) {
        params['organization'] = options.organizationId;
    }
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    const response = await api.get<DashboardDepsCheckResultDto>(
        `/api/v2/dashboards/${dashboard}/dep-rules`,
        {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            },
        }
    );
    return response.data;
}
