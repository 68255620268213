import { z } from 'zod';
import { AssistantLayoutControllerProps } from './page/layout';

export const AssistantUrlParamSchema = z
    .object({
        return_path: z.string().optional(),
    })
    .transform(
        (item): Partial<AssistantLayoutControllerProps> => ({
            returnPath: item.return_path ?? null,
        })
    )
    .pipe(
        z.object({
            returnPath: z.string().nullable(),
        })
    );
