import React from 'react';
import {
    Box,
    Heading,
    HStack,
    TableContainer,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
    Spinner,
    Image,
    Icon,
    Tooltip,
    Thead,
    Th,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { TraitPreviewViewProps } from '../../../../view/peer';

export function createPreviewView(
    config: OnboardingPeerGroupContainerConfig
): React.FC<{ children?: React.ReactNode | undefined } & TraitPreviewViewProps> {
    return (props) => {
        if (props.emptyState) {
            return (
                <Alert
                    // colorScheme={props.emptyState.colorScheme}
                    status={props.emptyState.status}
                    colorScheme={props.emptyState.status === 'info' ? 'gray' : undefined}
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"
                >
                    {props.emptyState.Icon && (
                        <Icon fontSize="3xl" as={props.emptyState.Icon} />
                    )}
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                        {props.emptyState.title}
                    </AlertTitle>
                    <AlertDescription
                        maxW="sm"
                        color="blackAlpha.700"
                        fontWeight="medium"
                    >
                        {props.emptyState.description}
                    </AlertDescription>
                </Alert>
            );
        }
        // if (props.emptyState) {
        //     return (
        //         <HStack w="full" justify="center">
        //             <Tag
        //                 w="full"
        //                 colorScheme={props.emptyState.colorScheme}
        //                 borderRadius="lg"
        //                 p={6}
        //                 justifyContent="center"
        //                 minH={36}
        //             >
        //                 {props.isLoading ? (
        //                     <VStack>
        //                         <Spinner size="md" m={0} speed="0.75s" />
        //                         <Text
        //                             color="orange.800"
        //                             fontSize="md"
        //                             fontWeight="semibold"
        //                         >
        //                             Calculating peer group
        //                         </Text>
        //                     </VStack>
        //                 ) : (
        //                     <VStack>
        //                         {props.emptyState.Icon && (
        //                             <Box borderRadius="md" flexShrink={0}>
        //                                 <Icon fontSize="3xl" as={props.Icon} />
        //                             </Box>
        //                         )}
        //                         <Heading color="orange.800" fontSize="lg">
        //                             {props.emptyState.title}
        //                         </Heading>
        //                         <Text color="orange.700" fontSize="medium">
        //                             {props.emptyState.description}
        //                         </Text>
        //                     </VStack>
        //                 )}
        //             </Tag>
        //         </HStack>
        //     );
        // }
        const exampleProps = props.getExampleProps();
        return (
            <VStack w="full" align="start" spacing={4}>
                <HStack justify="space-between" w="full">
                    <HStack spacing={3}>
                        {props.pluginImageUrl && (
                            <Box p={2} bg="gray.100" borderRadius="md" flexShrink={0}>
                                <Image w={5} h={5} alt="" src={props.pluginImageUrl} />
                            </Box>
                        )}
                        <Heading fontSize="md" fontWeight="bold">
                            {props.matchedText}
                        </Heading>
                    </HStack>
                    <Tooltip
                        placement="top"
                        hasArrow={true}
                        label={
                            <Box p={2}>
                                <Text>{props.description}</Text>
                            </Box>
                        }
                    >
                        <Tag
                            whiteSpace="nowrap"
                            px={4}
                            h={8}
                            colorScheme={props.colorScheme}
                            fontWeight="semibold"
                            minW={32}
                        >
                            <HStack w="full" justify="center" align="center" spacing={2}>
                                {props.isLoading ? (
                                    <Spinner size="sm" m={0} />
                                ) : (
                                    <>
                                        {props.Icon && (
                                            <Icon fontSize="lg" as={props.Icon} />
                                        )}
                                        <Text>{props.statusName}</Text>
                                    </>
                                )}
                            </HStack>
                        </Tag>
                    </Tooltip>
                </HStack>
                {exampleProps.data.length > 0 && (
                    <>
                        <TableContainer w="full" maxW="full">
                            <Table
                                w="full"
                                color="gray.500"
                                variant="simple"
                                fontWeight="medium"
                                fontSize="sm"
                            >
                                <Thead>
                                    <Tr>
                                        <Th whiteSpace="nowrap"></Th>
                                        {exampleProps.columns.map((property) => (
                                            <Th key={property.key} whiteSpace="nowrap">
                                                {property.name}
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {exampleProps.data.map((company) => (
                                        <Tr key={company.name}>
                                            <Td whiteSpace="nowrap">{company.name}</Td>
                                            {exampleProps.columns.map((column) => (
                                                <Td key={column.key} whiteSpace="nowrap">
                                                    {exampleProps.format(
                                                        column,
                                                        company.data[column.key]
                                                    )}
                                                </Td>
                                            ))}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <HStack w="full" justify="start" color="gray.400">
                            <Icon as={InfoOutlineIcon}></Icon>
                            <Text fontWeight="medium" fontSize="sm">
                                The companies above are examples and serve only an
                                illustrative purpose
                            </Text>
                        </HStack>
                    </>
                )}
            </VStack>
        );
    };
}
