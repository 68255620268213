import { useMemo } from 'react';
import { InCondition } from '../../../domain/attributes';
import { FilterForm, FilterFormState } from './filterInterface';

export function createFilterForm(): FilterForm {
    return {
        useFormState(props): FilterFormState {
            const conditionByKey = useMemo(
                () =>
                    props.value.conditions.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.key]: item,
                        }),
                        {} as Record<string, InCondition | undefined>
                    ),
                [props.value.conditions]
            );
            const instance: FilterFormState = {
                setFilter(item, value) {
                    if (value.length === 0) {
                        return instance.removeFilter(item);
                    }
                    const current = instance.getValue(item);
                    if (current) {
                        return props.value.conditions.map((item) =>
                            item.key === current.key ? { ...item, value } : item
                        );
                    }
                    props.onChange({
                        ...props.value,
                        conditions: [
                            ...props.value.conditions,
                            { key: item.property.key, operator: 'in', value },
                        ],
                    });
                },
                removeFilter(item) {
                    props.onChange({
                        ...props.value,
                        conditions: props.value.conditions.filter(
                            (condition) => condition.key !== item.property.key
                        ),
                    });
                },
                isActive(item) {
                    return !!instance.getValue(item);
                },
                getFilters() {
                    return props.items;
                },
                getValue(item) {
                    return conditionByKey[item.property.key] ?? null;
                },
                getOptions(item) {
                    return item.property.type.members.map((member) => ({
                        label: member.label,
                        value: member.value,
                    }));
                },
            };
            return instance;
        },
    };
}
