import pluralize from 'pluralize';
import { useMemo } from 'react';
import { PluginControllerConfig } from '../pluginConfig';
import { COLORS } from '../status/pluginStatusConstant';
import { buildPluginStatusProps } from '../status/pluginStatusFactory';
import { PluginItemController } from './pluginItemInterface';

export function createPluginItemController(
    config: PluginControllerConfig
): PluginItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props) {
            const statusProps = buildPluginStatusProps(props.item);
            return {
                id: props.item.plugin.id,
                title: props.item.plugin.name,
                iconUrl: props.item.plugin.iconUrl ?? '',
                status: statusProps,
            };
        },
    };
}
