import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormDataAdapter } from './formDataAdapter';
import { FormDataRepository } from './formDataInterface';
import { FormDataEntity } from './formDataModel';
import { FormDataCreateProps } from './formDataProps';
import { FormDataLookupQuery } from './formDataQuery';

export function createFormDataRepository(adapter: FormDataAdapter): FormDataRepository {
    const PREFIX = ['forms', 'submissions'];
    function getLookupKey(item: FormDataEntity | FormDataLookupQuery) {
        return [...PREFIX, 'lookup', item];
    }
    return {
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: getLookupKey(query),
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
        useCreate(context) {
            throw new Error('not impl');
            // const queryClient = useQueryClient();
            // const result = useMutation({
            //     async mutationFn(props: FormSubmissionCreateProps) {
            //         const response = await adapter.create(context, props);
            //         return response;
            //     },
            //     onSuccess(data, variables, context) {
            //         const queryKey = getLookupKey(data);
            //         queryClient.setQueryData(queryKey, data);
            //     },
            // });
            // return result;
        },
    };
}
