import React, { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { StudiesResponseDetailContextProvider } from './studiesResponseDetailInterface';
import { StudiesDetailContextValue } from './studiesResponseDetailModel';

export function createStudiesDeteilContextProvider(): StudiesResponseDetailContextProvider {
    const Context = React.createContext<StudiesDetailContextValue | null>(null);
    return {
        useContext() {
            const value = React.useContext(Context);
            assert(value, 'not inside detail page context');
            return value;
        },
        Provider: Context.Provider,
    };
}
