import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { ReportV2Visualization } from '../../../api';
import { DatasetSlugs } from '../../dataset';
import { AnyReportConfiguration } from '../../../middleware';

const dimensionsWithDescriptions = [
    {
        key: 'number_of_agencies',
        title: 'Number of marketing agencies your company currently works with',
        description: null,
    },
    {
        key: 'agency_compensation',
        title: 'Total annual compensation for marketing agencies',
        description: null,
    },
    {
        key: 'compensation_structure',
        title: 'Structure of your agency compensation',
        description: null,
    },
    {
        key: 'satisfaction_rating',
        title: 'Satisfaction with your marketing agencies',
        description: null,
    },
];

export const AgencyCompensationReport: AnyReportConfiguration = {
    kind: 'dataset',
    entity: {
        id: 'agency_compensation',
        name: 'Agency Compensation',
        status: 'published',
        tagline: null,
        description: `Benchmark agency compensation relative to company stage (revenue, ad spend, etc.)`,
        category: 'marketing',
        dataset: DatasetSlugs.AGENCY_COMP,
        traits: [],
        tags: ['compensation'],
        dependencies: [
            {
                kind: 'survey',
                dataset: DatasetSlugs.AGENCY_COMP,
            },
        ],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [
            {
                description: null,
                url: screenshot_spend_over_revenue,
            },
            {
                description: null,
                url: screenshot_spend_over_budget,
            },
            {
                description: null,
                url: screenshot_compensation_model,
            },
        ],
        visualizations: dimensionsWithDescriptions.map(
            (dimension): ReportV2Visualization => ({
                key: dimension.key,
                name: dimension.title,
                description: dimension.description ?? undefined,
                kind: 'category',
                metrics: [
                    {
                        kind: 'aggregation',
                        key: 'total',
                        namespace: 'varos',
                        table: 'agency_compensation',
                        type: 'count',
                    },
                ],
                query: {
                    breakdown: [
                        {
                            kind: 'dimension',
                            key: dimension.key,
                        },
                    ],
                    filter: {
                        key: dimension.key,
                        operator: 'neq',
                        value: null,
                    },
                    period: {
                        interval: 'week',
                        amount: 4,
                    },
                },
            })
        ),
    },
};
