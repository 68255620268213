import React from 'react';
import { Integration } from '../../models/integration';
import { Box, Divider, Flex, HStack, VStack, Spacer } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import moment from 'moment';
import IntegrationButton, { IntegrationButtonProps } from './IntegrationButton';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export interface IntegrationsListProps
    extends Omit<IntegrationButtonProps, 'hasAnyIntegrations' | 'integration'> {
    integrations?: Integration[];
}

interface IntegrationViewProps
    extends Omit<IntegrationButtonProps, 'hasAnyIntegrations'> {
    integration: Integration;
}

export function IntegrationView(props: IntegrationViewProps) {
    const {
        integration: { id, name, key, is_connected, is_active, created_at, last_event },
    } = props;
    return (
        <HStack spacing={4} key={id} w="100%" py={2}>
            <VStack alignItems="stretch" spacing={1} w="100%">
                {(key || name) && (
                    <Text
                        maxWidth="150px"
                        fontSize="sm"
                        fontWeight="bold"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                    >
                        {key || name}
                    </Text>
                )}
                <Text fontSize="xs">
                    Created:&nbsp;
                    {created_at ? moment.utc(created_at).format('YYYY-MM-DD') : 'N/A'}
                </Text>
                {!is_active && (
                    <Text fontSize="xs" fontStyle="italic">
                        Paused
                    </Text>
                )}
                {last_event && (
                    <Text fontSize="xs">
                        Last event recieved {moment.utc(last_event).fromNow()}
                    </Text>
                )}
            </VStack>
            <Spacer />
            <Flex justifyContent="center" alignItems="center" flexDir="column">
                <IntegrationButton {...props} />
            </Flex>
        </HStack>
    );
}

// export default function ({ integrations, ...props }: IntegrationsListProps): React.ReactElement {
//     return integrations && integrations.length > 0 ? (
//         <VStack h="100%" align="stretch" alignItems="flex-end">
//             <Popover>
//                 <PopoverTrigger>
//                     <InfoOutlineIcon cursor="pointer" m={1} />
//                 </PopoverTrigger>
//                 <PopoverContent p={7} w="100%">
//                     <PopoverArrow />
//                     <PopoverCloseButton />
//                     <VStack spacing={2} alignItems="flex-end">
//                         {integrations.map((i) => (
//                             <React.Fragment key={i.id}>
//                                 <IntegrationView
//                                     {...{
//                                         integration: i,
//                                         hasAnyIntegrations: true,
//                                         ...props,
//                                     }}
//                                 />
//                                 <Divider orientation="horizontal" />
//                             </React.Fragment>
//                         ))}
//                     </VStack>
//                 </PopoverContent>
//             </Popover>
//             <Spacer />
//             <Flex flexDir="row-reverse">
//                 <IntegrationButton {...props} hasAnyIntegrations={true} />
//             </Flex>
//         </VStack>
//     ) : (
//         <Flex flexDir="row-reverse">
//             <IntegrationButton {...props} hasAnyIntegrations={false} />
//         </Flex>
//     );
// }
