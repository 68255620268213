import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { Button, ButtonProps } from '../../button';
import React from 'react';

export function applyLabelChildDefaults(element: React.ReactElement) {
    if (element.type === ChakraButton || element.type === Button) {
        const buttonProps: ChakraButtonProps & ButtonProps = {
            size: 'sm',
            _focus: { outline: 'none' },
            ...element.props,
        };
        return React.cloneElement(element, buttonProps);
    }
    return element;
}
