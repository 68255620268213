import { z } from 'zod';

export const LinkedInProfileSchema = z.object({
    kind: z.literal('linkedin'),
    id: z.string().nullable().default(null),
    url: z.string(),
});


export const InstagramInProfileSchema = z.object({
    kind: z.literal('instagram'),
    id: z.string().nullable().default(null),
    url: z.string(),
});
export const FacebookInProfileSchema = z.object({
    kind: z.literal('facebook'),
    id: z.string().nullable().default(null),
    url: z.string(),
});

export const TwitterInProfileSchema = z.object({
    kind: z.literal('twitter'),
    id: z.string().nullable().default(null),
    url: z.string(),
});

export const YoutubeProfileSchema = z.object({
    kind: z.literal('youtube'),
    id: z.string().nullable().default(null),
    url: z.string(),
});


export const VarosProfileSchema = z.object({
    kind: z.literal('varos'),
    id: z.string(),
    url: z.nullable(z.string()).default(null),
});

export const CompanyProfileSchema = z.discriminatedUnion('kind', [
    LinkedInProfileSchema,
    VarosProfileSchema,
    InstagramInProfileSchema,
    FacebookInProfileSchema,
    TwitterInProfileSchema,
    YoutubeProfileSchema
]);
