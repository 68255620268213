import { ConversationWorkflowAdapter } from './conversationWorkflowAdapter';
import { WorkflowRepository } from './conversationWorkflowInterface';
import { Kernel } from '../../../base';

export function createWorkflowRepository(
    kernel: Kernel,
    adapter: ConversationWorkflowAdapter
): WorkflowRepository {
    const PREFIX = ['conversations', 'workflows'];
    return {
        useFind(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.auth.user, query],
                queryFn: async () => {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.auth.user, query],
                queryFn: async () => {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}
