import { DatasetDto, DatasetFlowType, DatasetQueryDto } from '../../../api';
import { DatasetProperty } from '../../../domain/datasets';
import { DatasetAdapter, DatasetEntity } from '../../../app/datasets';
import { assert } from '../../../util/assert';
import { ReferenceType } from '../../../domain/attributes';
import { castTypeToDomain } from '../../api';
import { DatasetImplConfig } from './datasetConfig';
import { getDatasetImportMode } from './datasetHelper';

export function createDatasetImpl(config: DatasetImplConfig): DatasetAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function toEntity(dto: DatasetDto): DatasetEntity | null {
        if (dto.data.kind !== 'physical') {
            return null;
        }
        if (!dto.data.ingest_types?.includes('direct')) {
            // for now only show dataset with direct ingest type for studies
            return null;
        }
        const partition = dto.data.columns.find((candidate) =>
            candidate.flags.includes('time_partition')
        );
        const time = dto.data.columns.find((candidate) => candidate.type === 'date');
        assert(partition, `dataset ${dto.slug} does not have partition specified`);
        return {
            id: dto.slug,
            name: dto.name,
            // tags: dto.tags.map((tag) => ({ value: tag.value })),
            tags: [],
            description: null,
            plugin: dto.plugin ? { id: dto.plugin } : null,
            configuration: {
                mode: getDatasetImportMode(dto),
                partitionKey: partition.name,
                timeKey: time?.name ?? null,
                schema: dto.data.columns.flatMap((item): DatasetProperty[] => {
                    if (item.flags.includes('asset')) {
                        // skip asset column
                        return [];
                    }
                    if (item.flags.includes('time_partition')) {
                        // skip partition column
                        return [];
                    }
                    return [
                        {
                            key: item.name,
                            name: item.title,
                            description: item.description,
                            type: castTypeToDomain(item.type),
                            constraints: [],
                            isDisabled: false,
                            disabledReason: null,
                            isRequired: item.flags.includes('required'),
                        },
                    ];
                }),
            },
        };
    }

    return {
        async find(context, query) {
            const mapped_types: DatasetFlowType[] = [];
            if (query.category === 'survey') {
                mapped_types.push('direct');
            }
            const mapped: DatasetQueryDto = {
                types: mapped_types,
            };
            const response = await api.dataset.list(context, mapped);
            console.log('response', response.data);
            return response.data.flatMap((item) => {
                const mapped = toEntity(item.dataset);
                if (!mapped) {
                    return [];
                }
                return [mapped];
            });
        },
        // TODO implement lookup endpoint
        async lookup(context, query) {
            const items = await this.find(context, { category: 'survey' });
            console.log('items', items);
            const found = items.find((candidate) => candidate.id === query.id) ?? null;
            if (!found) {
                throw new Error(`dataset '${query.id}' not found`);
            }
            return found;
        },
    };
}
