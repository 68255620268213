import { AxiosInstance, AxiosResponse } from 'axios';

export interface PortalDto {
    id: string;
    url: string;
}

export interface PortalCreatePayload {
    return_path: string;
    plan?: string;
    payment_methods?: boolean;
}

export const createPortal = async (api: AxiosInstance, payload: PortalCreatePayload) => {
    const response = await api.post<unknown, AxiosResponse<PortalDto>>(
        `/api/v1/billing/portal`,
        payload
    );
    return response.data;
};
