import { AssetMappingContainerConfigV2 } from '../../onboardingMappingConfig';
import { OnboardingEntitySelectController } from './entitySelectInterface';

export function createOnboardingEntitySelectController(
    config: Pick<AssetMappingContainerConfigV2, 'controller'>
): OnboardingEntitySelectController {
    const { controller } = config;
    return {
        useProps(context, deps, props) {
            const controlProps = controller.entity.control.useProps(
                context,
                deps.control,
                props
            );
            return {
                control: controlProps,
                getPopoverProps() {
                    return {
                        isOpen: deps.control.disclosure.isOpen,
                        onOpen: deps.control.disclosure.onOpen,
                        onClose: deps.control.disclosure.onClose,
                    };
                },
            };
        },
    };
}
