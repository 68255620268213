import { createContext, useContext } from 'react';
import { RecommendationAdapterLegacy } from './recommendationAdapter';

export interface RecommendationContextValue {
    adapter: RecommendationAdapterLegacy;
}

export const RecommendationContext = createContext<RecommendationContextValue | null>(
    null
);

export const useRecommendationContext = (): RecommendationContextValue => {
    const context = useContext(RecommendationContext);
    if (!context) {
        throw new Error(`recommendation context not available`);
    }
    return context;
};
