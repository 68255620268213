import { StudiesSubmissionNewService } from './studiesSubmissionNewInterface';

export function createStudiesSubmissionService(): StudiesSubmissionNewService {
    return {
        async create(context, deps, props) {
            const { mutation: mutations } = deps;
            console.log('creating submission...');
            const response = await mutations.submission.mutateAsync({
                dataset: {
                    id: props.dataset,
                },
                data: props.payload,
            });
            // console.log('creating group...');
            // const responseGroup = await mutations.group.mutateAsync({
            //     name: 'Unnamed group',
            //     membershipType: 'internal',
            //     dataset: props.dataset,
            // });
            // console.log('invite created', responseGroup);
            // console.log('creating invite...', props.invite);
            // const responseInvite = await mutations.invite.mutateAsync({
            //     ...props.invite,
            //     kind: 'fuzzy',
            //     source: { kind: 'group', id: responseGroup.id },
            // });
            // console.log('invite created', responseInvite);
            return response;
        },
    };
}
