import moment from 'moment';
import { DatasetImportStatusKind } from '../../../../app/datasets';
import { Formatter } from '../../../../../hooks';
import { assertNever } from '../../../../util';
import { buildStatusProps, StatusInfo, StatusProps } from '../../../common';
import { buildDatasetItemViewProps } from '../../dataset';
import { ImportAggregate } from './importItemModel';
import { ImportItemViewProps } from './importItemProps';

export function buildImportItemProps(
    formatter: Formatter,
    item: ImportAggregate
): ImportItemViewProps {
    const absoluteDateLabel = formatter.date(item.import.createdAt, {});
    const absoluteTimeLabel = formatter.time(item.import.createdAt, {});
    const relativeLabel = formatter.timeago(item.import.createdAt, {});

    const statusInfo = buildImportStatusProps(item);
    return {
        id: item.import.id,
        name: [
            item.dataset.dataset.name,
            moment(item.import.createdAt).format('YYYY_MM_DD'),
        ]
            .join('_')
            .replace(' ', '_')
            .toLowerCase(),
        secondary: '',
        iconUrl: 'N/A',
        status: buildStatusProps(statusInfo),
        dataset: buildDatasetItemViewProps(item.dataset),
        updated: {
            relative: {
                label: relativeLabel,
            },
            absolute: {
                description: `Data source was last updated on ${absoluteDateLabel} at ${absoluteTimeLabel}`,
            },
        },
        getMetadataListProps() {
            return {
                items: [],
            };
        },
    };
}

function buildImportStatusProps(item: ImportAggregate): StatusInfo {
    if (item.import.status.kind === 'complete') {
        return {
            kind: 'success',
            label: 'Complete',
            description: null,
            ordinal: null,
            value: item.import.status.kind,
        };
    }
    if (item.import.status.kind === 'failed') {
        return {
            kind: 'error',
            label: 'Error',
            description: item.import.status.message,
            ordinal: null,
            value: item.import.status.kind,
            reason: null,
        };
    }
    if (item.import.status.kind === 'in_progress') {
        return {
            kind: 'warning',
            label: 'In Progress',
            description: null,
            ordinal: null,
            value: item.import.status.kind,
        };
    }
    if (item.import.status.kind === 'created') {
        return {
            kind: 'warning',
            label: 'In Progress',
            description: null,
            ordinal: null,
            value: item.import.status.kind,
        };
    }
    assertNever(item.import.status);
}
