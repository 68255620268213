import { ApplicationContext } from '..';
import { IntegrationContext } from '../../domain';
import { useIntegrationDefinitions } from './hooks';
import { useStore } from '../../stores/setupContext';
import { useNavigate } from 'react-router';
import { makeNewIntegrateHandler, makeReconnectHandler } from './funcs';

export function useIntegrationContext(appContext: ApplicationContext): IntegrationContext {
    const integrationDefinitions = useIntegrationDefinitions(appContext);
    const {
        integrations: {
            isLoadingId,
            isLoadingIntegrateLink,
            isLoadingReconnectLink,
            redirectToConnectLink,
            redirectToReconnecLink,
        },
    } = useStore();
    const navigate = useNavigate();
    const integReqHandler = makeNewIntegrateHandler(navigate, redirectToConnectLink, {
        newTab: true,
    });
    const reconnectHandler = makeReconnectHandler(navigate, redirectToReconnecLink, {
        newTab: true,
    });
    return {
        ...appContext,
        integrationDefinitions,
        isLoadingId,
        isLoadingConnectLink: isLoadingIntegrateLink,
        isLoadingReconnectLink,
        onConnect: integReqHandler,
        onReconnect: reconnectHandler,
    };
}
