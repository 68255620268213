import { UseFormReturn } from 'react-hook-form';
import { getAssetDomain } from '../../../../../domain/assets';
import { ReportSelectFormValues } from '../form';
import { ReportSelectAggregate } from '../reportSelectModel';
import { ReportSelectAssetProps } from './reportAssetProps';

export function buildReportSelectAssetProps(
    form: UseFormReturn<ReportSelectFormValues>,
    values: ReportSelectFormValues,
    item: ReportSelectAggregate
): ReportSelectAssetProps {
    return {
        items: item.items.map((item) => {
            return {
                value: item.asset.id,
                label: item.asset.name,
                secondary: getAssetDomain(item.asset) ?? 'N/A',
            };
        }),
        getOptionProps(option) {
            return {
                isSelected: option.value === values.asset,
                onClick() {
                    form.setValue('asset', option.value);
                },
            };
        },
    };
}
