import {
    TreeInputNestedOption,
    AnyInputProps,
    RangeInputProps,
    TreeInputProps,
} from '../../../ui';
import {
    assertMaybePartialNumberRangeValue,
    assertTreeValue,
    getIntervalByConstraints,
    isCurrencyProperty,
    isTreeProperty,
    NullEndNumberRangeValue,
    NullStartNumberRangeValue,
    NumberRangeValue,
} from '../../../domain/attributes';
import { ValueFormatter } from '../../../app/attributes';
import { AnyPropertyInputControllerProps } from './propertyProps';
import { first, last } from 'lodash';

export function castPropertyToInputProps(
    formatter: ValueFormatter,
    props: AnyPropertyInputControllerProps
): AnyInputProps {
    if (isTreeProperty(props.property)) {
        if (props.value !== null) {
            assertTreeValue(props.value);
        }
        const mapped: TreeInputProps = {
            type: 'tree',
            theme: props.theme?.tree,
            value: props.value ?? [],
            onChange: props.onChange,
            options: props.property.type.members.map((member) => ({
                label: member.label,
                value: member.value,
                children: member.children.map(
                    (child): TreeInputNestedOption => ({
                        disabled: child.isDisabled,
                        allowClickWhenDisabled: true,
                        label: child.label,
                        value: child.value,
                    })
                ),
            })),
        };
        return mapped;
    }
    if (isCurrencyProperty(props.property)) {
        if (props.value !== null) {
            assertMaybePartialNumberRangeValue(props.value);
        }

        let intervals = getIntervalByConstraints(props.property.type.constraints);
        if (!intervals) {
            console.warn('no range constraint found for numeric input');
            intervals = [1, 1000];
        }

        // console.log('DEBUG prop props', props);

        // const actual = props.value ?? [null, null];

        const min = first(intervals)!;
        const max = last(intervals)!;

        // @ts-expect-error
        function isLessThan(start: number, end: number) {
            return start === first(intervals);
        }

        // @ts-expect-error
        function isGreaterThan(start: number, end: number) {
            return end === last(intervals);
        }

        const value: [number, number] | null = props.value
            ? [props.value?.from ?? min, props.value?.to ?? max]
            : [min, max];

        // console.log('DEBUG applied', value);

        return {
            type: 'range',
            variant: 'slider',
            theme: props.theme?.range,
            // value: props.value,
            // onChange: props.onChange,
            intervals: intervals,
            isDisabled: props.status?.isDisabled ?? false,
            // type: 'range',
            // variant: 'slider',
            // style: props.style,
            min,
            max,
            value,
            // steps: intervals.length,
            // intervals: intervals,
            onChangeEnd([start, end]) {
                // console.log('DEBUG stat end', start, end, { min, max });
                if (start === min && end === max) {
                    return props.onClear();
                }
                if (isGreaterThan(start, end)) {
                    const value: NullEndNumberRangeValue = {
                        from: start,
                        to: null,
                    };
                    return props.onChange(value);
                }
                if (isLessThan(start, end)) {
                    const value: NullStartNumberRangeValue = {
                        from: null,
                        to: end,
                    };
                    return props.onChange(value);
                }
                const value: NumberRangeValue = {
                    from: start,
                    to: end,
                };
                return props.onChange(value);
            },
            getLabel([start, end]) {
                // console.log('range', start, end);
                let formatStart = formatter.format(props.property.type, start, {
                    notation: 'short',
                });
                let formatEnd = formatter.format(props.property.type, end, {
                    notation: 'short',
                });
                if (isLessThan(start, end)) {
                    formatStart = formatter.format(props.property.type, start, {
                        notation: 'short',
                    });
                }
                if (isGreaterThan(start, end)) {
                    formatEnd = `${formatEnd}+`;
                }
                return `${formatStart} - ${formatEnd}`;
            },
        };
    }
    throw new Error('not impl');
}
