import { MemberEntity } from '..';
import { AnyResolvedType, resolveTypes } from '../type';
import { Property } from './propertyModel';

export function resolveProperties(
    members: MemberEntity[],
    properties: Property[]
): Property<AnyResolvedType>[] {
    return properties.map((property) => ({
        ...property,
        type: resolveTypes(members, [property.type])?.[0]!,
    }));
}
