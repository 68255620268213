import { PageIds } from '../../../../config';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingAssetPluginConfig } from './pluginConfig';
import { SettingAssetPluginController } from './pluginInterface';

export function createSettingAssetPluginController(
    init: Pick<SettingAssetPluginConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingAssetPluginController {
    const {
        controller: {
            plugin: { list: pluginController },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ASSET_PLUGIN,
                    breadcrumbs: [
                        {
                            label: 'Plugins',
                        },
                    ],
                },
            });
            const listProps = pluginController.useProps(context, deps.list, props);
            return {
                items: listProps.items,
                getItemProps(item) {
                    const itemProps = listProps.getItemProps(item);
                    return itemProps;
                },
                getBreadcrumbsProps() {
                    return pageProps.getBreadcrumbProps().items;
                },
                getPageActionProps() {
                    return {
                        to: `/u/assets/${context.workspace.id}/settings/data-sources`,
                    };
                },
            };
        },
    };
}
