import { GroupMemberEntity } from '../../../domain/groups';
import { CountEntity } from '../../platform';
import { PeerSetEntity, PeerSetStatus } from './peersetModel';

const PEER_SET_MIN_MEMBER_COUNT = 5;

export function getPeerSetStatus(item: {
    peerset: PeerSetEntity;
    invitation: Pick<CountEntity, 'value'> | null;
}): PeerSetStatus {
    const { peerset, invitation } = item;
    const isEligible = peerset.members.length >= PEER_SET_MIN_MEMBER_COUNT;
    if (isEligible && invitation && invitation.value > 0) {
        return {
            kind: 'updating',
        };
    }
    if (isEligible) {
        return {
            kind: 'eligible',
        };
    }
    if (invitation && invitation.value > 0) {
        return {
            kind: 'creating',
        };
    }
    return {
        kind: 'ineligible',
    };
}
