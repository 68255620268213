import React from 'react';
import { VisualConfiguration } from '../../../domain/dashboard';
import { AnyCondition } from '../../../domain/attributes';
import { Dashboard } from '../../../domain/demo';
import { LineChartViewProps } from '../../../view/demo';
import { DemoChartService } from '../../../service';
import { DemoChartControllerHook } from './chartInterface';

export interface DemoChartContainerProps {
    visualization: VisualConfiguration;
    dashboard: Dashboard;
    segment: AnyCondition[];
    filters: AnyCondition[];
}

export function createDemoChartContainer(config: {
    as: React.FC<
        { children?: React.ReactNode | undefined } & Omit<LineChartViewProps, 'style'>
    >;
    useController: DemoChartControllerHook;
}): React.FC<{ children?: React.ReactNode | undefined } & DemoChartContainerProps> {
    const { as: Comp, useController } = config;
    return (props) => {
        const viewProps = useController({ ...props });
        return <Comp {...viewProps} />;
    };
}
