import { createInstallRoute } from '../../route';
import { InstallLayoutImpl } from '../../layout';
import { ApplicationEntrypointConfig } from '../../entrypoint';

export const INSTALL_ROUTE: ApplicationEntrypointConfig['route']['install'] = {
    create: createInstallRoute,
    config: {
        style: {
            wrapper: {
                // p: 4,
            },
            main: {
                p: 4,
            },
        },
        components: {
            views: {},
            layout: InstallLayoutImpl,
        },
    },
};
