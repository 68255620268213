import { capitalize, chain, groupBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import {
    AnyMappableEntity,
    Asset,
    getDefinitionStatus,
    Integration,
    IntegrationDefinition,
    IntegrationDefinitionStatus,
    MappingConnection,
} from '../../../../domain/assets';
import {
    getInvariantErrors,
    OrganizationMappingAggregate,
} from '../../../../service/assets';
import { assert } from '../../../../util/assert';
import { OrganizationMappingViewConfig } from './assetMappingConfig';
import { OrganizationMappingController } from './assetMappingInterface';
import {
    OrganizationMappingViewProps,
    OrganizationMappableListProps,
    MappingConflictItemProps,
    OrganizationMappingFormStateProps,
} from './assetMappingProps';
import { buildConnections } from './assetMappingFactory';
import pluralize, { plural } from 'pluralize';
import { getCollectionStatus } from './assetMappingHelper';
import { EntityControlControllerProps } from '../../../integrations';
import {
    buildMappingItemProps,
    OrganizationDefinitionIntegrationsProps,
    OrganizationDefinitionItemProps,
    OrganizationDefinitionStatusProps,
} from '../item';

export function createOrganizationMappingController(
    config: OrganizationMappingViewConfig
): OrganizationMappingController {
    const STATUS_ORDER: IntegrationDefinitionStatus[] = [
        'ready',
        'analyzing',
        'pending',
        // 'syncing',
        // 'empty',
        // 'pending',
    ];
    return {
        useProps(context, form, data, props): OrganizationMappingViewProps {
            const { allowSubmitEmpty = false } = props;
            // const aggregate = service.mapping.useLookup(context);
            // console.log('DEBUG aggregate', data.aggregate);

            const formValues = form.watch();

            const selected = {
                asset: useMemo(
                    () =>
                        data.aggregate.data?.assets.find(
                            (item) => item.id === props.asset.id
                        ) ?? null,
                    [props.asset.id, data.aggregate.data?.assets]
                ),
            };

            const integrationById = useMemo(
                () =>
                    data.aggregate.data?.integrations.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, Integration | undefined>
                    ) ?? {},
                [data.aggregate.data?.integrations]
            );

            const integrationsByDefinitionId = useMemo(
                () =>
                    groupBy(
                        data.aggregate.data?.integrations,
                        (item) => item.definitionId
                    ) as Record<string, Integration[] | undefined>,
                [data.aggregate.data?.integrations]
            );

            const definitionById = useMemo(
                () =>
                    data.aggregate.data?.definitions.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, IntegrationDefinition | undefined>
                    ) ?? {},
                [data.aggregate.data?.definitions]
            );

            const assetsById = useMemo(
                () =>
                    data.aggregate.data?.assets.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, Asset | undefined>
                    ) ?? {},
                [data.aggregate.data?.assets]
            );

            const entityByKey = useMemo(
                () =>
                    data.aggregate.data?.entities.reduce(
                        (acc, item) => ({ ...acc, [item.key]: item }),
                        {} as Record<string, AnyMappableEntity | undefined>
                    ) ?? {},
                [data.aggregate.data?.entities]
            );

            const entityById = useMemo(
                () =>
                    data.aggregate.data?.entities.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, AnyMappableEntity | undefined>
                    ) ?? {},
                [data.aggregate.data?.entities]
            );

            const entitiesByDefinitionId = useMemo(
                () =>
                    data.aggregate.data?.entities.reduce(
                        (acc, item) => {
                            const integration = integrationById[item.integrationId];
                            if (!integration) {
                                console.warn(`integration not found`);
                                return acc;
                            }
                            return {
                                ...acc,
                                [integration.definitionId]: [
                                    ...(acc[integration.definitionId] ?? []),
                                    item,
                                ],
                            };
                        },
                        {} as Record<string, AnyMappableEntity[] | undefined>
                    ) ?? {},
                [data.aggregate.data?.entities, integrationById]
            );

            const organizationMappings = useMemo(() => {
                return buildConnections(
                    data.aggregate.data,
                    entityByKey,
                    integrationById,
                    props.asset,
                    formValues
                );
            }, [
                data.aggregate.data,
                entityByKey,
                integrationById,
                props.asset,
                formValues,
            ]);

            const mappingsByAsset = useMemo(
                () =>
                    groupBy(organizationMappings, (item) => item.asset.id) as Record<
                        string,
                        MappingConnection[] | undefined
                    >,
                [organizationMappings]
            );

            const assetMappings = mappingsByAsset[props.asset.id] ?? [];

            const assetsByEntityKey = useMemo(
                () =>
                    organizationMappings.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.entity.key]: [
                                ...(acc[item.entity.key] ?? []),
                                item.asset,
                            ],
                        }),
                        {} as Record<string, Pick<Asset, 'id'>[] | undefined>
                    ),
                [organizationMappings]
            );

            const editting = useMemo(
                (): OrganizationMappingAggregate | null =>
                    data.aggregate.data
                        ? {
                              ...data.aggregate.data,
                              mappings: organizationMappings,
                          }
                        : null,
                [data.aggregate.data, organizationMappings]
            );

            const conflicts = useMemo(
                () =>
                    chain(assetsByEntityKey)
                        .toPairs()
                        .filter(([key, value]) => (value ? value.length > 1 : false))
                        .flatMap(([key, items]) => {
                            const entity = entityByKey[key];
                            if (!entity) {
                                console.warn(`entity ${key} not found`);
                                return [];
                            }
                            const integration = integrationById[entity.integrationId];
                            if (!integration) {
                                console.warn(
                                    `integration ${entity.integrationId} not found`
                                );
                                return [];
                            }
                            const definition = definitionById[integration.definitionId];
                            if (!definition) {
                                console.warn(
                                    `definition ${integration.definitionId} not found`
                                );
                                return [];
                            }
                            const mappings = mappingsByAsset[props.asset.id];
                            const isSelected = mappings?.some(
                                (mapping) => mapping.entity.key === entity.key
                            );

                            if (!isSelected) {
                                return [];
                            }
                            return (
                                items
                                    ?.filter((item) => item.id !== props.asset.id)
                                    ?.flatMap((reference): MappingConflictItemProps[] => {
                                        const asset = assetsById[reference.id];
                                        if (!asset) {
                                            console.warn(
                                                `asset ${reference.id} not found`
                                            );
                                            return [];
                                        }
                                        const kind = entity.kind.replaceAll('_', ' ');

                                        return [
                                            {
                                                asset,
                                                entity,
                                                text: `${definition.entityName} "${entity.name}" is already mapped to company "${asset.name}"`,
                                            },
                                        ];
                                    }) ?? []
                            );
                        })
                        .value(),
                [assetsByEntityKey, entityByKey, assetsById, props.asset.id]
            );

            // console.log('DEBUG editting', editting);

            const mappingsByDefinition = useMemo(
                () =>
                    assetMappings.reduce(
                        (acc, connection) => {
                            const entity = entityByKey[connection.entity.key];
                            if (!entity) {
                                console.warn(
                                    `entity ${connection.entity.key} not found`,
                                    entityByKey
                                );
                                return acc;
                            }
                            const integration = integrationById[entity.integrationId];
                            if (!integration) {
                                console.warn(
                                    `integration ${entity.integrationId} not found`,
                                    integrationById
                                );
                                return acc;
                            }
                            return {
                                ...acc,
                                [integration.definitionId]: [
                                    ...(acc[integration.definitionId] ?? []),
                                    connection,
                                ],
                            };
                        },
                        {} as Record<string, MappingConnection[] | undefined>
                    ),
                [entityByKey, assetMappings]
            );

            const definitions = useMemo(
                () =>
                    chain(data.aggregate.data?.definitions ?? [])
                        .filter((item) => item.mode === 'live')
                        .map((item) => {
                            const entities = entitiesByDefinitionId[item.id] ?? [];
                            const integrations =
                                data.aggregate.data?.integrations.filter(
                                    (candidate) => candidate.definitionId === item.id
                                ) ?? [];

                            const status = getDefinitionStatus(
                                item,
                                entities,
                                integrations
                            );
                            return { ...item, status };
                        })
                        .filter((item) => item.status !== 'pending')
                        .orderBy(
                            (item) => {
                                return [
                                    item.expedited ? 1 : 0,
                                    STATUS_ORDER.findIndex(
                                        (candidate) => candidate === item.status
                                    ),
                                    item.name.toLowerCase(),
                                ];
                            },
                            ['desc', 'asc', 'asc']
                        )

                        .value(),
                [
                    entitiesByDefinitionId,
                    data.aggregate.data?.definitions,
                    data.aggregate.data?.integrations,
                ]
            );

            const collectionStatus = useMemo(() => {
                const status = getCollectionStatus({ definitions });
                return status;
            }, [definitions]);

            // console.log('collectionStatus', collectionStatus);

            const invariantErrors = useMemo(() => {
                const [conflict] = conflicts;
                if (conflict) {
                    return [{ message: `Resolve mapping conflicts` }];
                }
                if (allowSubmitEmpty === false && assetMappings.length === 0) {
                    return [{ message: `At least one mapping must be selected` }];
                }
                return [];
            }, [props.asset, conflicts, assetMappings]);

            // console.log('errors', errors);

            const onSubmit = useCallback(async () => {
                if (editting) {
                    await data.save.mutateAsync(editting);
                }
                await new Promise((resolve) => setTimeout(resolve, 500));
                return;
            }, [data.save.mutateAsync, editting]);

            const formStateProps = useMemo<OrganizationMappingFormStateProps>(() => {
                const [invariantError] = invariantErrors;
                if (!editting || invariantError) {
                    return {
                        isSubmittable: false,
                        isSubmitting: false,
                        error: data.save.error,
                        invariant: invariantError,
                        onSubmit: onSubmit,
                        handleSubmit: form.handleSubmit,
                    };
                }
                if (invariantError) {
                    return {
                        isSubmittable: false,
                        isSubmitting: false,
                        error: data.save.error,
                        invariant: invariantError,
                        onSubmit: onSubmit,
                        handleSubmit: form.handleSubmit,
                    };
                }
                return {
                    isSubmittable: true,
                    isSubmitting: form.formState.isSubmitting,
                    error: data.save.error,
                    invariant: null,
                    onSubmit: onSubmit,
                    handleSubmit: form.handleSubmit,
                };
            }, [editting, form.formState.isSubmitting]);

            const itemPropsByDefinitionId = useMemo(() => {
                return (
                    data.aggregate.data?.definitions.reduce(
                        (acc, item) => {
                            const entities = (
                                entitiesByDefinitionId[item.id] ?? []
                            ).filter((item) => item.status === 'valid');
                            const integrations =
                                integrationsByDefinitionId[item.id] ?? [];
                            const mappings = mappingsByDefinition[item.id] ?? [];
                            return {
                                ...acc,
                                [item.id]: buildMappingItemProps({
                                    asset: selected.asset,
                                    definition: item,
                                    entities,
                                    integrations,
                                    mappings,
                                }),
                            };
                        },
                        {} as Record<string, OrganizationDefinitionItemProps | undefined>
                    ) ?? {}
                );
            }, [
                data.aggregate.data?.definitions,
                entitiesByDefinitionId,
                integrationsByDefinitionId,
            ]);

            return {
                lookup: data.aggregate,
                getConflictListProps() {
                    return conflicts;
                },
                getFormStateProps() {
                    return formStateProps;
                },
                getEmptyStateProps() {
                    if (data.aggregate.status === 'loading') {
                        return {
                            kind: 'loading',
                        };
                    }
                    if (definitions.length === 0) {
                        return {
                            kind: 'empty',
                        };
                    }
                    return null;
                },
                getDefinitionListProps() {
                    return {
                        items: definitions,
                        status: collectionStatus,
                        onHelpClick() {},
                    };
                },
                getDefinitionItemProps(item) {
                    const itemProps = itemPropsByDefinitionId[item.id];
                    assert(itemProps, 'item props not found');
                    return itemProps;
                },
                getEntityControlProps(item): EntityControlControllerProps {
                    const entities = entitiesByDefinitionId[item.id] ?? [];
                    return {
                        item: {
                            asset: selected.asset,
                            definition: item,
                            mappings: mappingsByDefinition[item.id] ?? [],
                            entities: entitiesByDefinitionId[item.id] ?? [],
                            integrations: integrationsByDefinitionId[item.id] ?? [],
                        },
                        value: entities,
                        onClear() {
                            const { [item.id]: _removed, ...rest } =
                                formValues.entityByDefinition;
                            form.setValue(
                                'entityByDefinition',
                                {
                                    ...formValues.entityByDefinition,
                                    [item.id]: null,
                                },
                                { shouldDirty: true, shouldTouch: true }
                            );
                        },
                        onChange(value) {
                            if (value === null) {
                                return;
                            }
                            const entity = entityById[value.id];
                            if (!entity) {
                                console.warn(`entity ${value.id} not found`);
                                return;
                            }
                            form.setValue(
                                'entityByDefinition',
                                {
                                    ...formValues.entityByDefinition,
                                    [item.id]: {
                                        key: entity.key,
                                    },
                                },
                                { shouldDirty: true, shouldTouch: true }
                            );
                        },
                    };
                },
                getMappableListProps(item): OrganizationMappableListProps {
                    const parent = item;
                    const entities = entitiesByDefinitionId[item.id] ?? [];
                    // const mapping = formValues.entityByDefinition[item.id];
                    const entityName = item.entityName.toLowerCase();
                    const title = `Select ${entityName}`;
                    const definitionMappings = mappingsByDefinition[item.id] ?? [];
                    const values = definitionMappings.flatMap((mapping) => {
                        const entity = entityByKey[mapping.entity.key];
                        if (!entity) {
                            return [];
                        }
                        return [
                            {
                                value: entity.id,
                                label: entity.name,
                                secondary: entity.key,
                                iconUrl: item.iconUrl,
                            },
                        ];
                    });

                    return {
                        title,
                        targetLabel: selected.asset?.url ?? 'N/A',
                        tooltipLabel: item.requirement?.text ?? null,
                        placeholder: `Search ${plural(entityName)}`,
                        value: values,
                        options: chain(entities)
                            .map((entity) => ({
                                value: entity.id,
                                label: entity.name,
                                secondary: entity.key,
                                iconUrl: item.iconUrl,
                                isDisabled: entity.status === 'invalid',
                            }))
                            .orderBy(
                                (item) => [
                                    item.isDisabled ? 1 : -1,
                                    item.label.toLowerCase(),
                                ],
                                ['asc', 'asc']
                            )
                            .value(),
                        onClear() {
                            const { [item.id]: _removed, ...rest } =
                                formValues.entityByDefinition;
                            form.setValue(
                                'entityByDefinition',
                                {
                                    ...formValues.entityByDefinition,
                                    [item.id]: null,
                                },
                                { shouldDirty: true, shouldTouch: true }
                            );
                        },
                        getOptionProps(item) {
                            return {
                                ...item,
                                onSelect() {
                                    const entity = entityById[item.value];
                                    if (!entity) {
                                        console.warn(`entity ${item.value} not found`);
                                        return;
                                    }
                                    form.setValue(
                                        'entityByDefinition',
                                        {
                                            ...formValues.entityByDefinition,
                                            [parent.id]: {
                                                key: entity.key,
                                            },
                                        },
                                        { shouldDirty: true, shouldTouch: true }
                                    );
                                },
                            };
                        },
                    };
                },
                getSubmitButtonProps() {
                    return {
                        isLoading: formStateProps.isSubmitting,
                        isDisabled: !formStateProps.isSubmittable,
                    };
                },
                getSubmitTooltipProps() {
                    const [error] = invariantErrors;
                    if (!error) {
                        return {
                            isDisabled: true,
                        };
                    }
                    return {
                        isDisabled: false,
                        label: error.message,
                    };
                },
            };
        },
    };
}
