import { Feature } from '../../domain';
import { ApplicationContext } from '../context';
import { AccessResponse, AccessResponseDenied, AnyAccessRequest } from './accessType';

/**
 * Returns whether access to a given feature is restricted.
 */
export const useAccess = <TVal>(context: ApplicationContext, feature: Feature | null, value: TVal | null = null) => {
    if (!feature) {
        return null;
    }

    let attempt: AccessResponse = context.access.canAccess({
        ...feature,
        value,
    } as any);

    /**
     * Wraps the callback in an error handler if the feature called with a restricted feature
     */
    const handleSubmit = <TCallback extends Function>(callback?: TCallback): TCallback => {
        // @ts-expect-error
        return (...args: any[]) => {
            if (attempt.restricted) {
                return context.access.restrict(attempt);
            }
            return callback?.(...args);
        };
    };

    return {
        ...attempt,
        handleSubmit,
    };
};

/**
 * Similar to `useAccess` but this one allows to to restrict features that depend
 * on dynamic data such as the values as part of a form submission
 */
export const useDynamicAccess = <TRequest extends AnyAccessRequest>(
    context: ApplicationContext,
    feature: Omit<TRequest, 'value'> | null
) => {
    /**
     * Returns an feature access attempt for the given value
     */
    const getAccess = (value: TRequest['value']): AccessResponse => {
        if (!feature) {
            return {
                restricted: false,
                request: feature,
            };
        }
        return context.access.canAccess(
            // @ts-expect-error
            { ...feature, value: value as any }
        );
    };

    const restrict = (result: AccessResponseDenied) => {
        return context.access.restrict(result);
    };

    /**
     *
     * const onError = createErrorHandler(context)
     * Returns a function that wraps the callback
     * in error handling for when the feature is restricted for the passed in data
     */
    const handleSubmit = <TCallback extends Function>(callback?: TCallback) => {
        return (value: TRequest['value']): TCallback => {
            const result = getAccess(value);
            if (result.restricted) {
                // @ts-expect-error
                return context.access.restrict(result);
            }
            return callback?.(value);
        };
    };

    return {
        restrict,
        handleSubmit,
        getAccess,
    };
};
