import { QueryFunction } from '@tanstack/react-query';
import { convertAxiosToError } from '../../../../utils/axiosUtils';

import {
    DashboardDepsCheckResultDto,
    getDashboardDeps,
} from '../../../../api/v2/dashboard/deps';
import { DashboardContext } from '../../../../hooks/dashboards/dashboardContext';

export function listDashboardHandler(
    context: DashboardContext
): QueryFunction<DashboardDepsCheckResultDto> {
    return async () => {
        try {
            const response = await getDashboardDeps(context.api, context.slug, [
                context.assetId,
            ]);
            return response;
        } catch (e) {
            throw convertAxiosToError(e);
        }
    };
}
