import React from 'react';
import {
    ReportInstanceDetailContainerProps,
    ReportInstanceDetailViewProps,
} from './instanceDetailProps';
import {
    ReportInstanceDetailController,
    ReportInstanceDetailLoader,
} from './instanceDetailInterface';
import { ReportContainerBaseConfig } from '../../../base';
import { InstanceReportDetailParameterSchema } from './instanceDetailSchema';

export function createReportInstanceDetailContainer(
    config: ReportContainerBaseConfig,
    loader: ReportInstanceDetailLoader,
    controller: ReportInstanceDetailController,
    View: React.FC<ReportInstanceDetailViewProps>
): React.FC<ReportInstanceDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const options = useOptions(InstanceReportDetailParameterSchema);
        const context = useContext();
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, { period: options.period });
        return <View {...props} />;
    };
}
