import { useMemo } from 'react';
import { PageIds } from '../../../../config';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingDataSourceConfig } from './sourceConfig';
import { SettingDataSourceController } from './sourceInterface';

export function createSettingDataSourceController(
    init: Pick<SettingDataSourceConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingDataSourceController {
    const {
        controller: {
            dataset: {
                source: { list: viewController },
            },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_DATA_SOURCE,
                    breadcrumbs: [
                        {
                            label: 'Data Sources',
                        },
                    ],
                },
            });

            const viewProps = viewController.useProps(context, props);

            return {
                getViewProps() {
                    return viewProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getNewLinkProps() {
                    return {
                        to: `/u/assets/${context.workspace.id}/settings/data-sources/edit`,
                    };
                },
            };
        },
    };
}
