import { HomeRouteController } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { AutohideMiddlewareConfig } from './autohideConfig';
import { isAutohidden } from './autohideHelper';
import { useMemo } from 'react';

/**
 * Automatically hides home sections after some time after registration
 * @param init
 * @returns
 */
export function createAutohideMiddleware(
    init: AutohideMiddlewareConfig
): ApplicationEntryEnhancer {
    const now = new Date();
    return (create) => (config) => {
        function enhanceController(controller: HomeRouteController): HomeRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const {
                        organization: { useContext: useOrganizationData },
                    } = instance.context;
                    const viewProps = controller.useProps(...args);
                    const {
                        data: { organization },
                    } = useOrganizationData();

                    const isHidden = useMemo(() => {
                        const value = isAutohidden(init, organization, now);
                        if (value) {
                            console.info(
                                `organization older than threshold, auto-hiding home sections...`
                            );
                        }
                        return value;
                    }, [init, organization, now]);

                    return {
                        ...viewProps,
                        getGuideProps(...args) {
                            const guideProps = viewProps.getGuideProps(...args);
                            return {
                                ...guideProps,
                                isHidden: isHidden,
                            };
                        },
                    };
                },
            };
        }
        const instance = create({
            ...config,
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        deps: {
                            ...routeConfig.deps,
                            controller: {
                                ...routeConfig.deps.controller,
                            },
                        },
                        providers: {
                            ...routeConfig.providers,
                            root: {
                                ...routeConfig.providers.root,
                                createController(...args) {
                                    const controller =
                                        routeConfig.providers.root.createController(
                                            ...args
                                        );
                                    return enhanceController(controller);
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
