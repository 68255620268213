import { useMemo } from 'react';
import {
    getMetricBuckets,
    getScalarColorScheme,
    getScalarStatus,
    getScalarStatusColorScheme,
} from '../../../domain/metrics';
import { isAbsolutePeriod } from '../../../../domain';
import { assert } from '../../../util/assert';
import { VisualizationRangeItemControllerConfig } from './rangeConfig';
import { VisualizationRangeItemController } from './rangeInterface';
import { VisualizationRangeItemViewProps } from './rangeProps';

export function createVisualizationRangeItemController(
    config: VisualizationRangeItemControllerConfig
): VisualizationRangeItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(props): VisualizationRangeItemViewProps {
            const itemProps = useMemo((): VisualizationRangeItemViewProps => {
                assert(
                    isAbsolutePeriod(props.item.calculation.period),
                    'expected absolute date period'
                );

                const buckets = getMetricBuckets(props.item.metric).map(
                    (item, index) => ({
                        ...item,
                        color: ['red', 'yellow', 'green'][index],
                    })
                );

                const rankingProperty =
                    props.item.ranking?.kind === 'fixed'
                        ? props.item.properties[props.item.ranking.comparison]
                        : undefined;

                const scalarStatus = getScalarStatus(
                    props.item.metric,
                    props.item.calculation
                );

                const bucket = scalarStatus
                    ? {
                          success: props.item.calculation.high,
                          warn: props.item.calculation.mid,
                          danger: props.item.calculation.low,
                      }[scalarStatus]
                    : null;

                const scalarColorScheme = scalarStatus
                    ? getScalarStatusColorScheme(scalarStatus)
                    : undefined;

                return {
                    metric: {
                        label: props.item.metric.name,
                    },
                    plugin: {
                        label: props.item.plugin.name,
                        iconUrl: props.item.plugin.iconUrl ?? '',
                    },
                    ranking:
                        rankingProperty && rankingProperty.key !== props.item.metric.key
                            ? {
                                  label: rankingProperty.name,
                              }
                            : null,
                    date: {
                        label: formatter.format(
                            'daterange',
                            {
                                from: props.item.calculation.period.start,
                                to: props.item.calculation.period.end,
                            },
                            { notation: 'short' }
                        ),
                    },
                    description: {
                        label: 'asdadsdda',
                    },
                    bucket: {
                        definition: bucket ?? null,
                        label: bucket?.definition.name.toLowerCase() ?? 'N/A',
                    },
                    value: {
                        colorScheme: scalarColorScheme,
                        value: props.item.calculation.current.value.base,
                        label: props.item.calculation.current.value.base
                            ? formatter.format(
                                  props.item.metric.type,
                                  props.item.calculation.current.value.base
                              )
                            : 'N/A',
                    },
                    range: {
                        low: {
                            colorScheme: 'red',
                            value: props.item.calculation.low.value.base,
                            label: props.item.calculation.low.value.base
                                ? formatter.format(
                                      props.item.metric.type,
                                      props.item.calculation.low.value.base
                                  )
                                : 'N/A',
                        },
                        mid: {
                            colorScheme: 'yellow',
                            value: props.item.calculation.mid.value.base,
                            label: props.item.calculation.mid.value.base
                                ? formatter.format(
                                      props.item.metric.type,
                                      props.item.calculation.mid.value.base
                                  )
                                : 'N/A',
                        },
                        high: {
                            colorScheme: 'green',
                            value: props.item.calculation.high.value.base,
                            label: props.item.calculation.high.value.base
                                ? formatter.format(
                                      props.item.metric.type,
                                      props.item.calculation.high.value.base
                                  )
                                : 'N/A',
                        },
                    },
                    isInverse: props.item.metric.inverse,
                    buckets,
                };
            }, [props.item]);
            return {
                ...itemProps,
            };
        },
    };
}
