import { buildReturnUrl } from './paymentMethodEditFactory';
import { PaymentMethodEditService } from './paymentMethodEditInterface';

export function createPaymentMethodEditService(): PaymentMethodEditService {
    return {
        async run(props, options) {
            const returnUrl = buildReturnUrl({
                location: props.location,
                window: props.window,
                returnPath: options.returnPath ?? null,
            });
            const result = await props.stripe.confirmSetup({
                elements: props.elements,
                clientSecret: props.clientSecret,
                confirmParams: {
                    return_url: returnUrl,
                    payment_method_data: {},
                },
            });
            return;
        },
    };
}
