import { useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchControllerConfig } from './searchConfig';
import { SearchController } from './searchInterface';
import { SearchViewProps } from './searchProps';

export function createSearchController<TItem>(
    config: SearchControllerConfig
): SearchController<TItem> {
    const {} = config;
    return {
        useProps(item, props): SearchViewProps<TItem> {
            const [localValue, setLocalValue] = useState(props.value);

            const [] = useDebounce(
                () => {
                    props.onChange(localValue.trim());
                },
                500,
                [localValue]
            );

            const appliedValue = localValue;

            return {
                isLoading: item.items.status === 'loading',
                input: {
                    value: appliedValue,
                    onChange(event) {
                        setLocalValue(event.target.value);
                    },
                },
                clear:
                    appliedValue.length === 0
                        ? null
                        : {
                              onClick() {
                                  setLocalValue('');
                                  props.onChange('');
                              },
                          },
            };
        },
    };
}
