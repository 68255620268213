import { DashboardSlugs } from '../../../config/dashboard';
import { ViewSources } from '../../../config/view';
import { ReportDefinition } from '../../domain/report';

export function createReports(): Record<string, ReportDefinition | undefined> {
    return {
        summary: {
            id: 'summary',
            name: 'Overview',
            blocks: [
                {
                    name: 'Overview',
                    kind: 'query',
                    metrics: [
                        // Facebook
                        {
                            key: 'cpm',
                            source: ViewSources.FACEBOOK_DAILY_PERFORMANCE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.FACEBOOK_V2,
                                    pluginId: 'facebookads',
                                },
                            },
                        },
                        {
                            key: 'spend',
                            source: ViewSources.FACEBOOK_DAILY_PERFORMANCE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.FACEBOOK_V2,
                                    pluginId: 'facebookads',
                                },
                            },
                        },
                        {
                            key: 'roas',
                            source: ViewSources.FACEBOOK_DAILY_PERFORMANCE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.FACEBOOK_V2,
                                    pluginId: 'facebookads',
                                },
                            },
                        },
                        // Google
                        {
                            key: 'cpm',
                            source: ViewSources.GOOGLE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.GOOGLE_V2,
                                    pluginId: 'googleads',
                                },
                            },
                        },
                        {
                            key: 'spend',
                            source: ViewSources.GOOGLE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.GOOGLE_V2,
                                    pluginId: 'googleads',
                                },
                            },
                        },
                        {
                            key: 'roas',
                            source: ViewSources.GOOGLE,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.GOOGLE_V2,
                                    pluginId: 'googleads',
                                },
                            },
                        },
                        // TikTok
                        {
                            key: 'cpm',
                            source: ViewSources.TIKTOK,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.TIKTOK_V2,
                                    pluginId: 'tiktokads',
                                },
                            },
                        },
                        {
                            key: 'spend',
                            source: ViewSources.TIKTOK,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.TIKTOK_V2,
                                    pluginId: 'tiktokads',
                                },
                            },
                        },
                        {
                            key: 'cost_per_conversion',
                            source: ViewSources.TIKTOK,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.TIKTOK_V2,
                                    pluginId: 'tiktokads',
                                },
                            },
                        },
                        // Shopify
                        {
                            key: 'revenue',
                            source: ViewSources.SHOPIFY_ORDERS,
                            dependency: {
                                dashboard: {
                                    id: DashboardSlugs.SHOPIFY_V2,
                                    pluginId: 'shopify',
                                },
                            },
                        },
                        // // Executive summary
                        // {
                        //     key: 'revenue',
                        //     source: ViewSources.EXECUTIVE_SUMMARY,
                        //     dependency: {
                        //         dashboard: {
                        //             id: DashboardSlugs.EXECUTIVE_SUMMARY,
                        //             pluginId: 'executive-summary',
                        //         },
                        //     },
                        // },
                        // {
                        //     key: 'cac',
                        //     source: ViewSources.EXECUTIVE_SUMMARY,
                        //     dependency: {
                        //         dashboard: {
                        //             id: DashboardSlugs.EXECUTIVE_SUMMARY,
                        //             pluginId: 'executive-summary',
                        //         },
                        //     },
                        // },
                        // {
                        //     key: 'marketing_efficiency_ratio',
                        //     source: ViewSources.EXECUTIVE_SUMMARY,
                        //     dependency: {
                        //         dashboard: {
                        //             id: DashboardSlugs.EXECUTIVE_SUMMARY,
                        //             pluginId: 'executive-summary',
                        //         },
                        //     },
                        // },
                        // {
                        //     key: 'spend',
                        //     source: ViewSources.EXECUTIVE_SUMMARY,
                        //     dependency: {
                        //         dashboard: {
                        //             id: DashboardSlugs.EXECUTIVE_SUMMARY,
                        //             pluginId: 'executive-summary',
                        //         },
                        //     },
                        // },
                    ],
                },
            ],
            // TODO: infer dependencies automatically by joining source views with dashboards
            dependencies: [
                {
                    kind: 'dashboard',
                    dashboard: {
                        id: DashboardSlugs.FACEBOOK_V2,
                        pluginId: 'facebookads',
                    },
                },
                {
                    kind: 'dashboard',
                    dashboard: { id: DashboardSlugs.GOOGLE_V2, pluginId: 'googleads' },
                },
                {
                    kind: 'dashboard',
                    dashboard: { id: DashboardSlugs.SHOPIFY_V2, pluginId: 'shopify' },
                },
                {
                    kind: 'dashboard',
                    dashboard: { id: DashboardSlugs.TIKTOK_V2, pluginId: 'tiktokads' },
                },
                {
                    kind: 'dashboard',
                    dashboard: {
                        id: DashboardSlugs.EXECUTIVE_SUMMARY,
                        pluginId: 'executive-summary',
                    },
                },
            ],
        },
    };
}
