import { ApplicationContext } from '../../../hooks';
// import { QueryResponse, Schema } from '../../../api/v2';
import { Schema } from '../../../v2/domain/dashboard';
import { QueryResponse } from '../../../v2/domain/query';
import { ColumnData, CellData, RowData } from '../../../ui';
import { formatType } from '../../type';
import { RenderContext } from './contextModel';

function fetchColumn(schema: Schema, key: string) {
    const found = schema.properties[key];
    if (!found) {
        const candidates = Object.keys(schema.properties).join(', ');
        throw new Error(`property '${key}' not found in schema. found: ${candidates}`);
    }
    return found;
}

export function buildRenderContext(
    context: Pick<ApplicationContext, 'formatter'>,
    config: {
        response: QueryResponse;
        rowData: RowData;
        columnData: ColumnData;
        cellData: CellData;
    }
): RenderContext {
    return {
        self: config.response.queries.reduce(
            (acc, query) => ({
                ...acc,
                [query.key]: {
                    value:
                        query.series.items?.[config.rowData.index]?.data?.[0]?.[
                            config.columnData.key
                        ] ?? null,
                    format: formatType(
                        context,
                        fetchColumn(query.schema, config.columnData.key).type,
                        query.series.items?.[config.rowData.index]?.data?.[0]?.[
                            config.columnData.key
                        ]
                    ),
                },
            }),
            {}
        ),
    };
}
