import { ReportChart } from './chartModel';
import { ReportChartCreateProps } from './chartProps';

export function buildChart(props: ReportChartCreateProps): ReportChart {
    return {
        metrics: props.metrics,
        segments: props.segments,
        date: props.date,
    };
}
