import { ServerValidationError } from './Common';

export interface ErrorInfo {
    errType:
        | 'serverErr'
        | 'validationErr'
        | 'unauthorized'
        | 'noPerm'
        | 'notFound'
        | 'rateLimited';
    message?: string;
    fieldErrors?: Array<ServerValidationError>;
    internalErr?: any;
    extra?: any;
}

export function isErrorInfo(value: unknown): value is ErrorInfo {
    return (
        typeof (value as ErrorInfo).errType === 'string' &&
        typeof (value as ErrorInfo).internalErr === 'object'
    );
}
