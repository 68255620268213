import { useStorageContext } from '../storage';
import { useDashboardContext } from '../dashboard';
import { useWorkspaceContext } from '../workspace';
import { DashboardStateApi } from './stateInterface';

export const useDashboardStateApi = (): DashboardStateApi => {
    const context = {
        storage: useStorageContext(),
        workspace: useWorkspaceContext(),
        dashboard: useDashboardContext(),
    };
    const entities = {
        dashboard: context.dashboard.dashboard,
    };
    return {
        isExpanded(filter) {
            return context.storage.dashboard.isExpanded(entities.dashboard, filter);
        },
        toggleExpanded(filter) {
            context.storage.dashboard.toggleExpanded(entities.dashboard, filter);
        },
    };
};
