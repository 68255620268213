import {
    Box,
    Link as ChakraLink,
    Button,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    StackDivider,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { EntityControlViewProps } from '../../../../../view';

export function createOnboardingEntityListView(): React.FC<
    { children?: React.ReactNode | undefined } & EntityControlViewProps
> {
    return (props) => {
        const listProps = props.getListProps();
        const emptyProps = listProps.getEmptyState();
        const alertProps = listProps.getAlertProps();
        console.log('emptyProps', emptyProps);
        console.log('alertProps', alertProps);
        return (
            <Grid templateRows="min-content 1fr">
                <GridItem>
                    <HStack pt={3} px={3} w="full" justify="space-between">
                        <HStack spacing={2}>
                            <Heading fontWeight="semibold" fontSize="md">
                                {listProps.title}
                            </Heading>
                            <Tag size="md">{listProps.targetLabel}</Tag>
                        </HStack>
                        {listProps.value && (
                            <Button
                                {...props.getClearProps()}
                                variant="outline"
                                size="sm"
                                _focus={{ outline: 'none' }}
                            >
                                Clear
                            </Button>
                        )}
                    </HStack>
                </GridItem>
                <GridItem>
                    <VStack align="start" w="full" py={3} spacing={3}>
                        <Box px={3} w="full">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                        <Icon color="gray.300" as={AiOutlineSearch} />
                                    }
                                />
                                <Input
                                    {...listProps.getSearchProps()}
                                    borderColor="gray.300"
                                    _placeholder={{
                                        color: 'gray.300',
                                    }}
                                />
                            </InputGroup>
                        </Box>
                        {emptyProps ? (
                            <Center w="full" px={3}>
                                <VStack w="full" p={3} spacing={2} fontWeight="medium">
                                    <VStack w="full" spacing={1}>
                                        <Text color="gray.700">{emptyProps.title}</Text>
                                    </VStack>
                                </VStack>
                            </Center>
                        ) : null}
                        {listProps.items.length > 0 && (
                            <>
                                <VStack
                                    spacing={0}
                                    align="start"
                                    w="full"
                                    divider={<StackDivider borderColor="gray.300" />}
                                >
                                    {listProps.items.map((item, index, array) => {
                                        const itemProps = props.getItemProps(item);
                                        return (
                                            <HStack
                                                key={itemProps.value}
                                                p={3}
                                                w="full"
                                                justify="space-between"
                                                onClick={itemProps.onClick}
                                                cursor={
                                                    item.isDisabled
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }
                                                _hover={
                                                    item.isDisabled
                                                        ? undefined
                                                        : {
                                                              bg: 'gray.50',
                                                          }
                                                }
                                                borderBottomRadius={
                                                    index === array.length - 1
                                                        ? 'md'
                                                        : undefined
                                                }
                                                opacity={
                                                    item.isDisabled ? 0.5 : undefined
                                                }
                                                filter={
                                                    item.isDisabled
                                                        ? 'grayscale(100%)'
                                                        : undefined
                                                }
                                            >
                                                <HStack spacing={3} w="full">
                                                    <Box
                                                        p={2}
                                                        bg="gray.100"
                                                        borderRadius="md"
                                                        flexShrink={0}
                                                    >
                                                        <Image
                                                            w={5}
                                                            h={5}
                                                            src={itemProps.iconUrl}
                                                        />
                                                    </Box>
                                                    <Text
                                                        fontWeight="medium"
                                                        noOfLines={1}
                                                    >
                                                        {itemProps.label}
                                                    </Text>
                                                </HStack>
                                                <Tag size="md" flexShrink={0}>
                                                    <Text>{itemProps.secondary}</Text>
                                                </Tag>
                                            </HStack>
                                        );
                                    })}
                                </VStack>
                                <HStack px={3} spacing={3} align="center">
                                    <Text color="gray.400">{listProps.tooltipLabel}</Text>
                                </HStack>
                            </>
                        )}
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
