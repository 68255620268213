import { chain, keyBy } from 'lodash';
import { useMemo } from 'react';
import { AnyVisualization, buildVisualization } from '../../../domain/visualization';
import { InsightRouteConfigV2 } from './insightRouteConfig';
import { InsightRouteSortingV2 } from './insightRouteEnum';
import { InsightRouteControllerV2 } from './insightRouteInterface';
import { InsightRouteViewPropsV2 } from './insightRouteProps';
import { buildOpportunityControllerPropsV2 } from './insightRouteFactory';
import { OpportunityCollectionViewProps } from 'src/v2/view';

export function createInsightRouteControllerV2(
    config: InsightRouteConfigV2
): InsightRouteControllerV2 {
    const {
        deps: { controller, strategy },
    } = config;

    return {
        useProps(context, state, data, props): InsightRouteViewPropsV2 {
            const applied = useMemo(() => {
                return {
                    period: state.value.period ?? { amount: 4, interval: 'week' },
                    sorting: state.value.sorting ?? 'relevance',
                };
            }, [state.value]);

            const controllerProps = useMemo(
                () => buildOpportunityControllerPropsV2(applied),
                [applied]
            );

            const metricsById = useMemo(
                () => keyBy(data.metrics, (item) => item.id),
                [data.metrics]
            );

            // console.log('metricsById', metricsById);

            const visualizationByInsight = useMemo(() => {
                return (
                    data.items.data?.items.reduce(
                        (acc, item) => {
                            const visualizationProps =
                                strategy.visualizable.toVisualization(item.insight);
                            if (!visualizationProps) {
                                return acc;
                            }
                            const metrics = visualizationProps.metrics.flatMap((item) => {
                                const metric = metricsById[item.id];
                                return metric ? [metric] : [];
                            });
                            if (metrics.length === 0) {
                                return acc;
                            }
                            // console.log('visualizationProps', visualizationProps);
                            return {
                                ...acc,
                                [item.insight.id]: buildVisualization(
                                    metrics,
                                    visualizationProps
                                ),
                            };
                        },
                        {} as Record<string, AnyVisualization | undefined>
                    ) ?? {}
                );
            }, [data.items.data?.items, metricsById]);

            // console.log('visualizationByInsight', visualizationByInsight);

            const viewProps = {
                opportunity: controller.opportunity.collection.useProps(
                    context,
                    data,
                    controllerProps
                ),
                date: controller.date.useProps({
                    value: applied.period,
                    onChange(value) {
                        state.onChange({ ...state.value, period: value });
                    },
                }),
            };

            const opportunityProps = useMemo<OpportunityCollectionViewProps>(() => {
                if (viewProps.opportunity.collection.status !== 'loaded') {
                    return viewProps.opportunity;
                }
                return {
                    ...viewProps.opportunity,
                    collection: {
                        ...viewProps.opportunity.collection,
                        data: {
                            ...viewProps.opportunity.collection.data,
                            items: chain(viewProps.opportunity.collection.data?.items)
                                .orderBy((item) => {
                                    const visualization =
                                        visualizationByInsight[item.insight.id] ?? null;
                                    return visualization ? 1 : -1;
                                }, 'desc')
                                .value(),
                        },
                    },
                };
            }, [viewProps.opportunity]);

            return {
                getItemProps(item) {
                    return { item };
                },
                getVisualizationProps(item) {
                    const visualization = visualizationByInsight[item.insight.id] ?? null;
                    if (!visualization) {
                        return null;
                    }
                    return {
                        visualization,
                    };
                },
                getOpportunityProps() {
                    return opportunityProps;
                },
                getDateProps() {
                    return viewProps.date;
                },
                getSortingProps() {
                    return {
                        value: applied.sorting,
                        onChange(event) {
                            state.onChange({
                                period: state.value?.period ?? null,
                                sorting: event.target.value as InsightRouteSortingV2,
                            });
                        },
                        options: [
                            {
                                label: 'Relevance',
                                value: 'relevance',
                            },
                            {
                                label: 'Most recent',
                                value: 'recent',
                            },
                        ],
                    };
                },
            };
        },
    };
}
