import { AxiosInstance } from 'axios';
import moment from 'moment';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingSessionResource } from './sessionInterface';
import { BillingSessionDtoSchema, BillinSessionCompleteSchemaDto } from './sessionSchema';

export function createBillingSessionResource(
    client: AxiosInstance
): BillingSessionResource {
    return {
        async create(context, payload) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(
                '/api/v1/billing/session',
                {
                    ...payload,
                    metadata: payload.metadata ? {
                        ...payload.metadata,
                        period_start_at: moment(payload.metadata.period_start_at).format(
                            'YYYY-MM-DD'
                        ),
                        period_end_at: moment(payload.metadata.period_end_at).format(
                            'YYYY-MM-DD'
                        ),
                    }: undefined,
                },
                {
                    headers,
                    params,
                }
            );
            const parsed = BillingSessionDtoSchema.parse(response.data);

            return parsed;
        },
        async get(context, sessionId) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(
                `/api/v1/billing/session/${sessionId}`,
                {
                    headers,
                    params,
                }
            );
            const parsed = BillinSessionCompleteSchemaDto.parse(response.data);

            return parsed;
        },
    };
}
