import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../../stores/setupContext';
import {
    SetPasswordForm,
    ResponseForgotPasswordProps,
} from '../../../../components/ForgotPassword/Response';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { ChangePasswordIdentifiedUser } from '../../../../models/Account';
import { ErrorInfo } from '../../../../models/Errors';
import ThankYouMessage from './ThankYou';
import { Flex, Heading } from '@chakra-ui/react';

interface ForgotPasswordResponseProps {
    usr: ChangePasswordIdentifiedUser | null;
    isLoading: boolean;
    setPassword: (newPassword: string) => void;
    err: ErrorInfo | null;
    completed: boolean;
}

export const RespWrapper: React.FC<
    { children?: React.ReactNode } & ForgotPasswordResponseProps
> = ({ setPassword, isLoading, err, completed, usr }) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            <Heading fontSize="lg" marginBottom={4}>
                Save New Password
            </Heading>
            {completed ? (
                <ThankYouMessage
                    message="New Password Saved Successfully!"
                    link="/login"
                    linkTxt="Continue To Login"
                />
            ) : (
                <SetPasswordForm
                    {...{ doSetForgotPwd: setPassword, isLoading, err, usr }}
                />
            )}
        </Flex>
    );
};

const Observed = observer(RespWrapper);
function isStringArray(opt: string | string[]): opt is string[] {
    return Array.isArray(opt);
}
const WithStoreConnection = () => {
    const { setPassword } = useStore();
    const {
        isLoading,
        identifyPasswordChangeRequest,
        setNewPassword,
        identifiedUser,
        completed,
        err,
    } = setPassword;
    const loc = useLocation();
    React.useEffect(() => {
        if (loc.search) {
            const qs = queryString.parse(loc.search.substring(1));
            const token = qs['token'];
            if (token) {
                identifyPasswordChangeRequest(isStringArray(token) ? token[0] : token);
            }
        }
    }, [loc.state]);

    return (
        <Observed
            {...{
                setPassword: setNewPassword,
                isLoading,
                err,
                completed,
                usr: identifiedUser,
            }}
        />
    );
};

export default observer(WithStoreConnection);
