import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Table, TableProps } from '../../../../ui';

export interface TableVisualizationProps extends TableProps {}

export const TableVisualization: React.FC<
    { children?: React.ReactNode | undefined } & TableVisualizationProps
> = ({ ...tableProps }) => {
    return <Table {...tableProps} />;
};
