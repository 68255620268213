import {
    CohortMode,
    CohortState,
    DynamicCohortState,
    FixedCohortState,
    FixedRanking,
} from './cohortModel';
import { CohortModeProps, FixedRankingProps } from './cohortProps';

export function buildFixedRanking(props: FixedRankingProps): FixedRanking {
    return {
        kind: 'fixed',
        comparison: props.comparison,
        cohorts: [
            {
                name: 'Low performers',
                visible: true,
                lower: 0,
                upper: 33,
            },
            {
                name: 'Median',
                visible: true,
                lower: 33,
                upper: 66,
            },
            {
                name: 'High performers',
                visible: true,
                lower: 66,
                upper: 100,
            },
        ],
    };
}

export function buildCohortState(props: CohortModeProps): CohortState {
    if (props.mode === 'fixed') {
        return {
            mode: props.mode,
            config: {
                fixed: {
                    kind: 'fixed',
                    comparison: props.comparison,
                    cohorts: [
                        {
                            name: 'Low performers',
                            visible: true,
                            lower: 0,
                            upper: 33,
                        },
                        {
                            name: 'Median',
                            visible: true,
                            lower: 33,
                            upper: 66,
                        },
                        {
                            name: 'High performers',
                            visible: true,
                            lower: 66,
                            upper: 100,
                        },
                    ],
                },
                dynamic: {
                    kind: 'dynamic',
                    cohorts: [
                        {
                            name: 'Low performers',
                            visible: true,
                            value: 25,
                        },
                        {
                            name: 'Median',
                            visible: true,
                            value: 50,
                        },
                        {
                            name: 'High performers',
                            visible: true,
                            value: 75,
                        },
                    ],
                },
            },
        };
    }
    const dynamic: DynamicCohortState = {
        mode: props.mode,
        config: {
            fixed: {
                comparison: null,
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: true,
                        lower: 0,
                        upper: 33,
                    },
                    {
                        name: 'Median',
                        visible: true,
                        lower: 33,
                        upper: 66,
                    },
                    {
                        name: 'High performers',
                        visible: true,
                        lower: 66,
                        upper: 100,
                    },
                ],
            },
            dynamic: {
                kind: 'dynamic',
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: true,
                        value: 25,
                    },
                    {
                        name: 'Median',
                        visible: true,
                        value: 50,
                    },
                    {
                        name: 'High performers',
                        visible: true,
                        value: 75,
                    },
                ],
            },
        },
    };
    return dynamic;
}
