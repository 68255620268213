import { z, ZodOptional } from 'zod';
import { MetricListResponseSchema, MetricSchema } from '../metric';

export const MetricCollectionSchema = z.object({
    id: z.number(),
    name: z.string(),
    asset: z.number(),
    default: z.boolean(),
    metrics: z.array(MetricSchema),
    created_at: z.date({ coerce: true }),
    updated_at: z.date({ coerce: true }),
});

export const MetricCollectionListResponseSchema = z.object({
    data: z.array(MetricCollectionSchema),
});
