import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse, isDomainErrorDto } from '../../common';
import { ReportResource } from './reportInterface';
import { ReportListSchema, ReportSchema } from './reportSchema';

export function createReportResource(client: AxiosInstance): ReportResource {
    return {
        async list(context, query, { signal, ...options } = {}) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get('/api/v1/reports/instances', {
                signal: signal,
                params: {
                    ...options,
                    ...query,
                },
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ReportListSchema.parse(response.data);
            return parsed;
        },
        async get(context, id) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get(`/api/v1/reports/instances/${id}`, {
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ReportSchema.parse(response.data);
            return parsed;
        },
        async create(context, payload, options) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.post(`/api/v1/reports/instances`, payload, {
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ReportSchema.parse(response.data);
            return parsed;
        },
    };
}
