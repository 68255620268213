import { chain } from 'lodash';
import { useMemo } from 'react';
import { CompanyItemAggregate } from '../../../view/companies';
import { assert } from '../../../util/assert';
import { keyByMapSafe } from '../../../util';
import { PeerLoaderConfig } from '../base';
import { CompanyListBoxLoader } from './companyListBoxInterface';
import { CompanyListBoxAggregate } from './companyListBoxModel';

export function createCompanyListBoxLoader(
    config: PeerLoaderConfig
): CompanyListBoxLoader {
    const {
        repository: { company: companyRepository, asset: assetRepository },
    } = config;
    return {
        useLoad(context, props) {
            const assetQuery = assetRepository.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );

            assert(assetQuery.status === 'success', 'expected suspense');
            const formValues = props.form.watch();

            // NOTE we use the query cache which means we assume that the
            // companies have already been loaded as part of a parent component
            const selectedCompanies = companyRepository.useLookupCache(
                context,
                formValues.companies.map((item) => ({ id: item }))
            );

            const assetByCompany = useMemo(
                () =>
                    keyByMapSafe(selectedCompanies, (item) => {
                        const profile = item.profiles.find(
                            (candidate) => candidate.kind === 'varos'
                        );
                        const asset = profile ? { id: Number(profile.id) } : undefined;
                        return [item.id, asset];
                    }),
                [selectedCompanies]
            );

            const aggregate = useMemo((): CompanyListBoxAggregate => {
                return {
                    company: {
                        items: chain(selectedCompanies)
                            .map(
                                (company): CompanyItemAggregate => ({
                                    company,
                                    asset: assetByCompany[company.id] ?? null,
                                })
                            )
                            .value(),
                    },
                    industry: {
                        items: [],
                    },
                };
            }, [selectedCompanies, assetQuery.data]);

            return aggregate;
        },
    };
}
