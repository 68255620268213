import React, { useMemo } from 'react';
import { PERIOD_INTERVALS } from '../../../home';
import { StudiesContextProvider } from '../../base';
import {
    StudiesResponseDetailRouteController,
    StudiesResponseDetaillRouteDetailLoader,
    StudiesResponseDetailStateProvider,
    StudiesResponseDetailContextProvider,
} from './studiesResponseDetailInterface';
import {
    StudiesResponseDetailContainerProps,
    StudiesResponseDetailViewProps,
} from './studiesResponseDetailProps';
import { StudiesDetailContextValue } from './studiesResponseDetailModel';

export function createStudiesResponseDetailContainer(
    context: {
        root: StudiesContextProvider;
        child: StudiesResponseDetailContextProvider;
    },
    state: StudiesResponseDetailStateProvider,
    controller: StudiesResponseDetailRouteController,
    loader: StudiesResponseDetaillRouteDetailLoader,
    View: React.FC<
        { children?: React.ReactNode | undefined } & StudiesResponseDetailViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & StudiesResponseDetailContainerProps
> {
    const {
        root: rootContext,
        child: { Provider: ChildContextProvider },
    } = context;
    return (props) => {
        const viewState = state.useState();
        const viewContext = rootContext.useContext();
        const viewData = loader.useLoad(viewContext, {
            report: props.report,
            control: {
                date: viewState.value.date,
                segment: viewState.value.filters,
            },
        });

        const childContext = useMemo(
            (): StudiesDetailContextValue => ({
                ...viewContext,
                data: viewData.data,
                state: viewState.value,
            }),
            [
                viewContext,
                viewData.data,
                viewState.value.date,
                viewState.value.segment,
                viewState.value.filters,
            ]
        );

        const viewProps = controller.useProps(viewContext, viewData.data, {
            control: {
                date: viewState.value.date,
                segment: viewState.value.filters,
            },
            mode: {
                value: viewState.value.mode,
                onChange(value) {
                    return viewState.onChange({ ...viewState.value, mode: value });
                },
            },
            segment: {
                value: viewState.value.segment,
                onChange(value) {
                    return viewState.onChange({ ...viewState.value, segment: value });
                },
            },
            filter: {
                value: viewState.value.filters,
                onChange(value) {
                    viewState.onChange({ ...viewState.value, filters: value });
                },
            },
            date: {
                options: {
                    period: PERIOD_INTERVALS,
                },
                value: viewState.value.date,
                onChange(value) {
                    return viewState.onChange({ ...viewState.value, date: value });
                },
            },
        });

        return (
            <ChildContextProvider value={childContext}>
                <View {...viewProps} />
            </ChildContextProvider>
        );
    };
}
