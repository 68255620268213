import { useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FeedbackRatingController, FeedbackRatingFormValue } from '../../../view';
import { Tracker } from '../../../base';
import { OpportunityAggregate } from '../../../service/insight';
import { AnyInsightTrackingEvent } from '../../../strategy/tracking/insight/insightTrackingEvent';
import { getInsightTrackingPayload } from '../../../strategy';
import { InsightFeedbackController } from './insightFeedbackInterface';

export function createInsightFeedbackController(
    useTracker: () => Tracker<AnyInsightTrackingEvent>,
    controller: FeedbackRatingController
): InsightFeedbackController {
    return {
        useProps(props) {
            const tracker = useTracker();
            const disclosure = useDisclosure();
            const form = useForm<FeedbackRatingFormValue>({
                defaultValues: {
                    rating: undefined,
                },
            });

            const handleFormClose = () => {
                disclosure.onClose();

                // delay form reset to avoid flickering before popover close animation is done
                setTimeout(
                    () => form.reset({ rating: undefined }, { keepIsSubmitted: false }),
                    100
                );
            };

            const ratingProps = controller.useProps<OpportunityAggregate>(form, {
                async onSubmit(item, values) {
                    // HACK we should not couple ourselves to the tracking
                    const payload = getInsightTrackingPayload(item.insight);
                    tracker.track('insight_feedback_submitted', {
                        ...payload,
                        feedback_rating: values.rating,
                    });
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    setTimeout(() => handleFormClose(), 1500);
                },
            });

            return {
                item: props.item,
                disclosure,
                getRatingProps() {
                    return ratingProps;
                },
            };
        },
    };
}
