import { ConfirmationControllerConfig } from './confirmationConfig';
import { ConfirmationController } from './confirmationInterface';
import { ConfirmationViewProps } from './confirmationProps';

export function createConfirmationController(
    config: Partial<ConfirmationControllerConfig> = {}
): ConfirmationController {
    return {
        useProps(props): ConfirmationViewProps {
            return {
                modal: {
                    isOpen: props.disclosure.isOpen,
                    onClose: props.disclosure.onClose,
                },
                trigger: {
                    button: {
                        onClick() {
                            return props.disclosure.onOpen();
                        },
                    },
                },
                submit: {
                    button: {
                        isLoading: props.isSubmitting,
                        async onClick() {
                            const result = await props.onSubmit();
                            props.disclosure.onClose();
                            return result;
                        },
                    },
                },
                cancel: {
                    button: {
                        onClick() {
                            return props.disclosure.onClose();
                        },
                    },
                },
            };
        },
    };
}
