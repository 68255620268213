import React from 'react';
import { Tooltip, FormLabel, Select, InputGroup } from '@chakra-ui/react';
import { isEnumType } from '../../../../../domain/attributes';
import { TypeInputViewProps } from '../inputProps';

export const TypeInputView: React.FC<
    { children?: React.ReactNode | undefined } & TypeInputViewProps
> = (props) => {
    if (!isEnumType(props.item.type)) {
        return <div>nbot supported</div>;
    }
    return (
        <Tooltip
            label={props.item.description}
            isDisabled={!props.item.description}
            placement="top"
        >
            <InputGroup {...props.style?.wrapper} flexDirection="column">
                <FormLabel {...props.style?.label}>{props.item.name}</FormLabel>
                <Select
                    placeholder="All"
                    value={props.value ?? undefined}
                    onChange={(event) => props.onChange(event.target.value)}
                >
                    {props.item.type.members.map((member) => (
                        <option key={member.value} value={member.value}>
                            {member.label}
                        </option>
                    ))}
                </Select>
            </InputGroup>
        </Tooltip>
    );
};
