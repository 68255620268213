import { makeObservable, observable, runInAction } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { ReportStore } from '../../../app';
import { ReportStoreConfig } from './reportStoreConfig';
import { ReportControl } from '../../../domain/report';

export interface ReportStoreStateValue extends ReportControl {}

export class ControlStoreImpl extends StoreBase implements ReportStore {
    _state: ReportStoreStateValue;

    constructor(public config: ReportStoreConfig) {
        super();
        this._state = config.initialValues;
        makeObservable(this, {
            _state: observable,
        });
    }
    setControl(value: ReportStoreStateValue) {
        runInAction(() => {
            this._state = value;
        });
    }

    getControl() {
        return this._state;
    }

    resetControl() {
        return (this._state = this.config.initialValues);
    }
}
