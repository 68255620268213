import React from 'react';
import { useForm } from 'react-hook-form';
import { OrganizationMappingFormValues } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import {
    SettingAssetMappingController,
    SettingAssetMappingDeps,
    SettingAssetMappingLoader,
} from './mappingInterface';
import { SettingAssetMappingContainerProps } from './mappingProps';
import { SettingAssetMappingConfig } from './mappingConfig';

export function createSettingAssetMappingContainer(
    init: Pick<SettingAssetMappingConfig, 'service' | 'infra'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingAssetMappingLoader,
    controller: SettingAssetMappingController
): React.FC<
    { children?: React.ReactNode | undefined } & SettingAssetMappingContainerProps
> {
    const {
        infra: { toaster },
        service,
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const deps: SettingAssetMappingDeps = {
            toast: toaster.useToast(),
            mutation: {
                save: service.mapping.useSave(viewContext, viewData.data),
            },
            form: useForm<OrganizationMappingFormValues>({
                defaultValues: {
                    entityByDefinition: {},
                },
            }),
        };
        const viewProps = controller.useProps(viewContext, deps, {
            item: viewData.data,
        });
        return <Component {...viewProps}>{children}</Component>;
    };
}
