import { AccountExternalInvitation, InvitationUser } from './invitationModel';

export function isPlatformInvitation(
    item: AccountExternalInvitation
): item is AccountExternalInvitation & { invitee: null } {
    return item.invitee === null;
}

export function isTeamInvitation(
    item: AccountExternalInvitation
): item is AccountExternalInvitation & { invitee: InvitationUser } {
    return !!item.invitee;
}
