import { PluginSlugs } from '../../../../config';
import { HomeIntegrationAggregate } from './homeIntegrationModel';

export function isHighlighted(item: HomeIntegrationAggregate) {
    // HACK there is something wrong with the dependencies of mediamix.
    // it will be marked as "data is still syncing" even though
    // not all integrations are fulfulled
    return item.plugin.id !== PluginSlugs.MEDIA_MIX;
}

export function isSyncing(item: HomeIntegrationAggregate) {
    return item.connection.status === 'syncing';
}
