import { getReportName, getReportAccessStatus } from '../../../../../app';
import { capitalize } from '../../../../../../utils/strings';
import { ValueFormatter } from '../../../../../app/attributes';
import { ReportListItemAggregate } from '../reportListModel';
import { ReportListItemProps } from './reportItemProps';

export function buildReportItemProps(
    formatter: ValueFormatter,
    item: ReportListItemAggregate
): ReportListItemProps {
    throw new Error('not impl');
    // @ts-expect-error
    const status = getReportAccessStatus(item);
    return {
        path: `${item.report.id.toString()}`,
        report: {
            title: getReportName(item),
        },
        label: {
            period: formatter.format('daterange', item.report.period, {
                notation: 'short',
            }),
            createdAt: formatter.format('date', item.report.createdAt, {
                notation: 'short',
                relative: true,
            }),
        },
        user: {
            label: item.collaborator
                ? [item.collaborator?.first_name, item.collaborator?.last_name]
                      .filter(Boolean)
                      .join(' ')
                : 'N/A',
        },
        status: {
            isReady: status === 'available',
            colorScheme: status === 'available' ? 'green' : 'orange',
            label: capitalize(status),
        },
    };
}
