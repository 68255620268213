import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { Workspace, Dashboard, Card } from '../../../domain';
import { QueryRequest, QueryResponse } from '../../../domain/query';
import { ChartAdapter } from './chartAdapter';

export const QUERY_CACHE_KEY_PREFIX = 'queries';

export function invalidateChartQueries(queryClient: QueryClient) {
    return queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEY_PREFIX] });
}

export function cancelChartQueries(
    queryClient: QueryClient,
    workspace: Workspace,
    dashboard: Dashboard
) {
    return queryClient.cancelQueries({
        queryKey: [QUERY_CACHE_KEY_PREFIX, workspace.id.toString(), dashboard.id],
    });
}

export function getChartQueryKey(
    workspace: Workspace,
    dashboard: Dashboard,
    card: Card,
    query: QueryRequest,
    example?: 'default' | 'generate' | null
) {
    const key = JSON.stringify([workspace.id, card.id, query, example]);
    return [QUERY_CACHE_KEY_PREFIX, workspace.id.toString(), dashboard.id, card.id, key];
}

export const createExecuteQueryOptions = (
    adapter: ChartAdapter,
    workspace: Workspace,
    dashboard: Dashboard,
    card: Card,
    query: QueryRequest,
    example?: 'default' | 'generate' | null
): UseQueryOptions<QueryResponse, unknown, QueryResponse, string[]> => {
    return {
        queryKey: getChartQueryKey(workspace, dashboard, card, query, example),
        queryFn: ({ signal }) => {
            return adapter.executeQuery(workspace!, dashboard, card, query, {
                signal,
                example,
            });
        },
        suspense: true,
        staleTime: Infinity,
        retry: false,
    };
};
