import { Box, VStack, StackProps } from '@chakra-ui/react';
import React from 'react';

export interface DashboardLayoutDateProps {
    as?: React.ReactElement<StackProps>;
}

export const DashboardLayoutDate: React.FC<
    { children?: React.ReactNode | undefined } & DashboardLayoutDateProps
> = (props) => <>{props.children}</>;
