import React, { useEffect } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AlertListController } from '../../../view/common';
import { AnyAlertEvent } from './trackingAlertEvent';

export function createAlertTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyAlertEvent>();
            return tracker;
        }

        function enhanceController(controller: AlertListController): AlertListController {
            return {
                ...controller,
                useProps(item, props, ...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(
                        item,
                        {
                            ...props,
                            onDismiss(item) {
                                tracker.track('alert_dismissed', {
                                    alert_id: item.id,
                                });
                                props.onDismiss?.(item);
                            },
                        },
                        ...args
                    );
                    useEffect(() => {
                        if (viewProps.containerRef.current) {
                            for (const item of viewProps.items) {
                                tracker.track('alert_viewed', {
                                    alert_id: item.id,
                                });
                            }
                        }
                    }, [viewProps.containerRef.current]);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            v2: {
                ...init.v2,
                common: {
                    ...init.v2.common,
                    alert: {
                        ...init.v2.common.alert,
                        createController(...args) {
                            const controller = init.v2.common.alert.createController(
                                ...args
                            );
                            return enhanceController(controller);
                        },
                    },
                },
            },
        });
        return instance;
    };
}
