import { z } from 'zod';

export const SettingIntegrationDetailUrlParamsSchema = z
    .object({
        integrationId: z.number({ coerce: true }),
    })
    .transform((item) => ({
        integration: {
            id: item.integrationId,
        },
    }));
