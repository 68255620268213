import { StepLayoutController } from './stepLayoutInterface';

export function createStepLayoutController(): StepLayoutController {
    return {
        useProps(item, props) {
            return {
                title: item.name,
                description: item.description,
                back:
                    props.stepper.selected > 0
                        ? {
                              onClick() {
                                  props.stepper.onChange(props.stepper.selected - 1);
                              },
                          }
                        : null,
                form: props.form,
            };
        },
    };
}
