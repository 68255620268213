import { DashboardAccess, DashboardListItem } from '../../api';
import { Dashboard } from '../../api/v2/dashboard';
import { DASHBOARD_OVERRIDES } from '../../config/dashboard';
import { groupBy } from 'lodash';

/**
 * Returns true if dashboard should be colored in the sidebar
 * @param dashboard
 * @returns
 */
export function isItemColored(
    dashboard: Pick<DashboardAccess, 'has_access'> & Pick<Dashboard, 'slug'>
) {
    if (dashboard.has_access) {
        return true;
    }
    const overrides = dashboard.slug ? DASHBOARD_OVERRIDES[dashboard.slug] : null;
    if (overrides?.isDefault) {
        return true;
    }
    return false;
}

/**
 * Sort sidebar items by 1) co-locating items with the same icon and 2) placing default dashboards at the end of the list
 * @param items
 * @returns
 */
export function sortItems(items: DashboardListItem[]) {
    const dashboardsByIconUrls = groupBy(items, (dashboard) => dashboard.icon_url);
    // first sort dashboards according to their ordering as returned by the API (based on ordinal), but
    // make sure that dashboards with the same icon are adjacent
    let sorted = items.reduce<DashboardListItem[]>((acc, dashboard) => {
        if (acc.some((candidate) => candidate.slug === dashboard.slug)) {
            return acc;
        }
        const dashboards: Array<DashboardListItem> = dashboard.icon_url
            ? dashboardsByIconUrls[dashboard.icon_url]
            : [dashboard];
        return [...acc, ...dashboards];
    }, [] as DashboardListItem[]);

    // secondly ensure that default dashboards are placed at the end of the list
    const dashboardsWithoutDefault = sorted.filter((dashboard) => {
        if (!dashboard.slug) {
            return true;
        }
        const override = DASHBOARD_OVERRIDES[dashboard.slug];
        return !override?.isDefault;
    });
    const dashboardsDefault = sorted.filter((dashboard) => {
        if (!dashboard.slug) {
            return false;
        }
        const override = DASHBOARD_OVERRIDES[dashboard.slug];
        return override?.isDefault;
    });
    return [...dashboardsWithoutDefault, ...dashboardsDefault];
}
