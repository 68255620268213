import { UseTrackerHook } from '../../../../base';
import { useDashboardContext } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';

export function createDashboardDiscardChangesTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useActions() {
                    const tracker = config.deps.useTracker();
                    const mapped = config.controller.useActions();
                    const { dashboard } = useDashboardContext();
                    return {
                        ...mapped,
                        onReset() {
                            const returned = mapped.onReset();
                            tracker.track(
                                'dashboard_changes_discarded',
                                // @ts-expect-error
                                {
                                    dashboard: dashboard.id,
                                    dashboard_name: dashboard.title,
                                }
                            );
                            return returned;
                        },
                    };
                },
            },
        });
    };
}
