import { useEffect, useMemo } from 'react';
import { isIntegrationRequirement } from '../../../../../views/dashboard/deps/hooks/helpers';
import { QUERYBUILDER } from '../../../../../container';
import { useDashboardDeps } from '../../../../../views';
import { useAuthenticatedContext } from '../../../../../container';
import { useWorkspaceApi, useDashboardApi, useQueryApi } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';

export function createDashboardLoadTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useCardList() {
                    const context = useAuthenticatedContext();
                    const tracker = config.deps.useTracker();
                    const api = {
                        workspace: useWorkspaceApi(),
                        dashboard: useDashboardApi(),
                        query: useQueryApi(),
                    };
                    const workspace = api.workspace.getWorkspace();
                    const dashboard = api.dashboard.getDashboard();
                    const mapped = config.controller.useCardList();

                    const dashDeps = useDashboardDeps(
                        {
                            ...context,
                            querybuilder: QUERYBUILDER,
                            slug: dashboard.id,
                            assetId: workspace.id as number,
                        },
                        { suspense: true, refetchOnWindowFocus: true }
                    );
                    const dependentDefinitionSlugs = useMemo(
                        () =>
                            new Set(
                                dashDeps.data?.tests
                                    ?.flatMap((x) =>
                                        Object.values(
                                            x.test.requirements_by_condition_name
                                        )
                                    )
                                    .flatMap((x) =>
                                        isIntegrationRequirement(x) ? x.integrations : []
                                    )
                            ),
                        [dashDeps]
                    );

                    useEffect(() => {
                        tracker.track(
                            'dashboardLoad',
                            // @ts-expect-error
                            {
                                dashboardId: dashboard.id,
                                integration_types: Array.from(dependentDefinitionSlugs),
                                subjectId: workspace.id,
                                subjectType: 'asset',
                                subjectTitle: workspace.name,
                            }
                        );
                        return () => {
                            tracker.track(
                                'dashboardUnLoad',
                                // @ts-expect-error
                                {
                                    dashboardId: dashboard.id,
                                    integration_definition_ids: Array.from(
                                        dependentDefinitionSlugs
                                    ),
                                    subjectId: workspace.id,
                                    subjectType: 'asset',
                                    subjectTitle: workspace.name,
                                }
                            );
                        };
                    }, [dashboard.id]);

                    return { ...mapped };
                },
            },
        });
    };
}
