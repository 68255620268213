import React, { useContext } from 'react';
import { assert } from '../../../../util/assert';
import { InsightRouteConfigV2 } from '../insightRouteConfig';
import { InsightRouteContextV2 } from '../insightRouteContext';
import { createInsightRouteItemController } from './insightItemController';
import { InsightRouteItemContainerProps } from './insightItemProps';

export function createInsightItemContainer(
    config: InsightRouteConfigV2
): React.FC<{ children?: React.ReactNode | undefined } & InsightRouteItemContainerProps> {
    const controller = createInsightRouteItemController(config.deps);
    return ({ as: Component, ...props }) => {
        const viewContext = useContext(InsightRouteContextV2);
        assert(viewContext, 'no context');
        const viewProps = controller.useProps(viewContext, props);
        return <Component {...viewProps}>{props.children}</Component>;
    };
}
