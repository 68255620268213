import { useRef } from 'react';
import { LazySuspenseProps } from '.';

export const useLazySuspense = () => {
    const elementRef = useRef(null);
    const suspenseProps: Pick<LazySuspenseProps, 'initialLoaded'> = {
        initialLoaded: !!elementRef.current,
    };
    return { elementRef, suspenseProps };
};
