import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildStudyResponseItemViewProps, StudyResponseItemViewProps } from '../item';
import { StudyResponseListControllerConfig } from './responseListConfig';
import { StudyResponseListController } from './responseListInterface';

export function createStudyResponseListController(
    config: Pick<StudyResponseListControllerConfig, 'infra'>
): StudyResponseListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const items = useMemo(() => chain(item.items).value(), [item.items]);

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item) => {
                            const props = buildStudyResponseItemViewProps(
                                formatter,
                                context,
                                item
                            );
                            return {
                                ...acc,
                                [props.id]: props,
                            };
                        },
                        {} as Record<string, StudyResponseItemViewProps | undefined>
                    ),
                [context, items]
            );

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.metric.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
            };
        },
    };
}
