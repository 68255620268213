import { Box, ButtonGroup, Text, Tooltip, Button, Icon } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AccountProfileFormValues, AccountProfileFormValuesSchema } from '../../../view';
import { OnboardingUserRouteStrategy } from '../../../route';
import { OnboardingProfileConfig } from './onboardingProfileConfig';
import { createOnboardingProfileView } from './onboardingProfileView';

export function createOnboardingProfileItem(
    config: OnboardingProfileConfig
): OnboardingUserRouteStrategy {
    const { router, loader, hooks, provider } = config;
    const controller = provider.createController(config);
    const Component = createOnboardingProfileView();
    return {
        id: 'profile',
        name: 'Create profile',
        slug: 'profile',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const auth = hooks.useAuth();
                    const context = { auth };

                    const data = loader.profile.useData(context, {});
                    const form = useForm<AccountProfileFormValues>({
                        defaultValues: {},
                        mode: 'onSubmit',
                        resolver: zodResolver(AccountProfileFormValuesSchema),
                    });

                    const viewProps = controller.useProps({ data, form }, {});
                    const formProps = viewProps.getFormProps();

                    return (
                        <Container
                            title="Account information"
                            rightContent={
                                <ButtonGroup>
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        label={<Box p={2}>{formProps.submitLabel}</Box>}
                                        isDisabled={formProps.isSubmittable}
                                    >
                                        <span>
                                            <Button
                                                colorScheme="green"
                                                onClick={form.handleSubmit(
                                                    formProps.onSubmit
                                                )}
                                                isDisabled={!formProps.isSubmittable}
                                                isLoading={formProps.isLoading}
                                                rightIcon={
                                                    <Icon as={AiOutlineArrowRight} />
                                                }
                                            >
                                                Continue
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </ButtonGroup>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps.getViewProps()} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
