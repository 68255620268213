import { TagProps } from '@chakra-ui/react';
import { StatusInfo, StatusInfoKind } from './statusModel';
import { StatusProps } from './statusProps';

function getColorScheme(status: StatusInfo): TagProps['colorScheme'] {
    const mapping = {
        info: 'blue',
        warning: 'orange',
        error: 'red',
        success: 'green',
        loading: 'gray',
    } satisfies Record<StatusInfoKind, Required<TagProps>['colorScheme']>;
    return status.kind ? mapping[status.kind] : 'gray';
}

export function buildStatusProps<TValue>(
    status: StatusInfo<TValue>
): StatusProps<TValue> {
    const statusId = status.id ? `${status.id}_status` : null;
    return {
        ...status,
        id: statusId,
        secondary: status.kind === 'error' ? status.reason : null,
        ordinal: status.ordinal ?? 0,
        kind: status.kind ? status.kind : 'info',
        description: status.description ?? undefined,
        colorScheme: getColorScheme(status),
        isDisabled: status.isDisabled ?? false,
        getTagProps() {
            return {
                colorScheme: getColorScheme(status),
                children: status.label,
            };
        },
        getTooltipProps() {
            return {
                id: status.id ? `${statusId}_tooltip` : undefined,
                isDisabled: status.description === null,
                label: status.description,
            };
        },
        getAlertProps() {
            return {
                status: status.kind ?? undefined,
            };
        },
    };
}
