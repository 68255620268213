import React, { useMemo } from 'react';
import {
    createWorkspaceStore,
    createDashboardStore,
    createControlStore,
} from '../../impl';
import { StorageContext, StorageContextValue } from '../../app';
import { ReportStore } from '../../app/_reportold';

export interface StorageProviderConfig {
    report: ReportStore;
}

export function configureStorageProvider(
    config: StorageProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const value: StorageContextValue = useMemo(
            () => ({
                workspace: createWorkspaceStore(),
                dashboard: createDashboardStore(),
                control: createControlStore(),
                report: config.report,
            }),
            []
        );
        return (
            <StorageContext.Provider value={value}>
                {props.children}
            </StorageContext.Provider>
        );
    };
}
