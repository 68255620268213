import { TextProps } from '@chakra-ui/react';
import { capitalize, chain, filter, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pluralize } from '../../../../../utils/strings';
import {
    ConnectionDependencyStatus,
    PluginConnectionStatus,
} from '../../../../domain/metrics';
import { MetricCollectionUpdatePayload, MetricDefinitionKind } from '../../../../api';
import { PluginSlugs } from '../../../../../config';
import { MetricStaticDeps } from '../../metricsConfig';
import {
    MetricCollectionEditController,
    MetricCollectionEditLoader,
} from './collectionEditInterface';
import {
    MetricDefinitionListFormState,
    MetricDefinitionListItem,
    MetricDefinitionListUIState,
    MetricDefinitionSection,
} from './collectionEditModel';
import { MetricCollectionEditProps } from './collectionEditProps';

export const PLUGIN_ORDERING = [
    PluginSlugs.FACEBOOKADS,
    PluginSlugs.GOOGLEADS,
    PluginSlugs.TIKTOKADS,
    PluginSlugs.SHOPIFY,
    PluginSlugs.EXECUTIVE_SUMMARY,
    PluginSlugs.GOOGLE_ANALYTICS,
];

export const METRIC_ORDERING: MetricDefinitionKind[] = [
    'performance',
    'outcome',
    'diagnostic',
    'input',
];

export const CONNECTION_STATUS_ORDERING: PluginConnectionStatus[] = [
    'ready',
    'disconnected',
    'pending',
];

export function createCollectionEditController(
    init: MetricStaticDeps,
    loader: MetricCollectionEditLoader
): MetricCollectionEditController {
    const {
        hook: { useQuery },
        api: {
            metric: {
                collections: { update: updateCollection },
            },
        },
    } = init;

    function searchItems(
        items: MetricDefinitionListItem[],
        searchTerm: string
    ): MetricDefinitionListItem[] {
        searchTerm = searchTerm.toLowerCase(); // Normalize the search term for case-insensitive comparison
        return items.filter(
            (item) =>
                item.metric.name.toLowerCase().includes(searchTerm) ||
                item.metric.description?.toLowerCase().includes(searchTerm) ||
                item.plugin.name?.toLowerCase().includes(searchTerm)
        );
    }

    return {
        useProps(context, data, collection, options): MetricCollectionEditProps {
            // const queryKey = [
            //     'metric',
            //     'definition',
            //     context.organization.id,
            //     context.workspace.id,
            // ];
            // const query = useQuery({
            //     queryKey,
            //     async queryFn() {
            //         const response = await loader.load(adapter, context);
            //         return response;
            //     },
            //     suspense: true,
            //     staleTime: Infinity,
            // });

            const metricById = useMemo(
                () =>
                    data.item.data?.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.metric.id]: item,
                        }),
                        {} as Record<string, MetricDefinitionListItem | undefined>
                    ) ?? {},
                [data.item.data]
            );

            const initialCurrent = useMemo(
                () =>
                    chain(collection.metrics ?? [])
                        .filter((item) => {
                            // only include metrics that are returned from the API
                            const definition = metricById[item.definition];
                            return definition?.connection.status === 'ready';
                        })
                        .map((item) => item.definition)
                        .value(),
                [collection.metrics, metricById]
            );

            const initialValues: MetricDefinitionListFormState = {
                current: initialCurrent,
            };

            // useEffect(() => {
            //     form.reset(initialValues);
            // }, [...queryKey]);

            const [state, setState] = useState<MetricDefinitionListUIState>({
                selected:
                    data.item.data?.filter(
                        (item) => item.metric.kind === 'performance'
                    )[0]?.metric.id ?? null,
                searchTerm: '',
            });

            const form = useForm<MetricDefinitionListFormState>({
                defaultValues: initialValues,
            });

            const formValues = form.watch();

            const filtered = useMemo(
                () => searchItems(data.item.data ?? [], state.searchTerm),
                [data.item.data, state.searchTerm]
            );

            const grouped = useMemo(
                () =>
                    chain(filtered)
                        .groupBy((item) => item.plugin.id)
                        .map<MetricDefinitionSection>((items) => {
                            const [sample] = items;
                            return {
                                id: sample.plugin.id,
                                title: sample.plugin.name,
                                items: chain(items)
                                    .orderBy(
                                        (item) =>
                                            METRIC_ORDERING.findIndex(
                                                (kind) => item.metric.kind === kind
                                            ),
                                        'asc'
                                    )
                                    // .orderBy(
                                    //     (item) =>
                                    //         CONNECTION_STATUS_ORDERING.findIndex(
                                    //             (status) =>
                                    //                 status === item.connection.status
                                    //         ),
                                    //     'asc'
                                    // )
                                    .value(),
                            } as const;
                        })
                        .orderBy((item) => {
                            const [sample] = item.items;
                            return CONNECTION_STATUS_ORDERING.findIndex(
                                (status) => status === sample.connection.status
                            );
                        }, 'asc')
                        .value(),
                [filtered]
            );

            const orderedOptions = useMemo(() => {
                return (
                    chain(formValues.current)
                        .flatMap((definitionId) => {
                            const definition = metricById[definitionId];
                            if (!definition) {
                                console.log(
                                    `metric definition ${definitionId} not found`
                                );
                                return [];
                            }
                            return [definition];
                        })
                        // .orderBy(
                        //     (item) => {
                        //         return [
                        //             PLUGIN_ORDERING.findIndex(
                        //                 (plugin) => plugin === item.plugin.id
                        //             ),
                        //             METRIC_ORDERING.findIndex(
                        //                 (kind) => item.metric.kind === kind
                        //             ),
                        //         ];
                        //     },
                        //     ['asc', 'asc']
                        // )
                        .value()
                );
            }, [formValues.current, metricById]);

            const props: MetricCollectionEditProps = {
                data: {
                    collection,
                },
                section: {
                    items: grouped,
                },
                search: {
                    filtered,
                    isEmpty: filtered.length === 0,
                },
                collection: {
                    items: data.item.data ?? [],
                    selected: state.selected ? metricById[state.selected] ?? null : null,
                    onSelect(item) {
                        return setState({ ...state, selected: item.metric.id });
                    },
                    isSelected(item) {
                        return item.metric.id === state.selected;
                    },
                },
                fields: {
                    search: {
                        value: state.searchTerm,
                        onChange(value) {
                            setState({ ...state, searchTerm: value });
                        },
                    },
                    current: {
                        value: formValues.current,
                        options: orderedOptions,
                        onToggle(item) {
                            if (props.fields.current.isAdded(item)) {
                                // remove from set
                                return form.setValue(
                                    'current',
                                    formValues.current.filter(
                                        (candidate) => candidate !== item.metric.id
                                    )
                                );
                            }
                            // add to set
                            return form.setValue('current', [
                                ...formValues.current,
                                item.metric.id,
                            ]);
                        },
                        isAdded(item) {
                            return formValues.current.includes(item.metric.id);
                        },
                    },
                },
                label: {
                    getStatus({ status }) {
                        return capitalize(status);
                    },
                    getMetricKind(metric) {
                        return capitalize(metric.kind);
                    },
                    selectionLabel: (() => {
                        const selected = formValues.current.length;
                        const pluralized = pluralize('metric', selected);
                        return selected > 0 ? `${selected} ${pluralized} selected` : null;
                    })(),
                },
                color: {
                    getStatus({ status }) {
                        const mapping: Record<
                            ConnectionDependencyStatus,
                            TextProps['colorScheme']
                        > = {
                            fulfilled: 'green',
                            unfulfilled: 'red',
                        } as const;
                        return mapping[status] ?? undefined;
                    },
                },
                form: {
                    isSubmitting() {
                        return form.formState.isSubmitting;
                    },
                    isDirty() {
                        return !isEqual(formValues, initialValues);
                    },
                    onReset() {
                        form.reset(initialValues);
                    },
                    isValid() {
                        if (
                            props.form.isDirty() &&
                            props.fields.current.value.length > 0
                        ) {
                            return true;
                        }

                        return false;
                    },
                    onSubmit: form.handleSubmit(async (values) => {
                        const payload: MetricCollectionUpdatePayload = {
                            metrics: values.current.map((item) => ({
                                name: null,
                                definition: item,
                            })),
                        };
                        const response = await data.update.mutateAsync([
                            collection.id,
                            payload,
                        ]);
                        await options.onSave(response!);
                    }),
                },
            };
            return props;
        },
    };
}
