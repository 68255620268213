import {
    ApplicationEntryEnhancer,
    OrganizationRouteEnhancer,
} from '../../../../entrypoint';

export function createAssetChangedTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const useTracker = config.infra.createUseTracker();
        return create({
            ...config,
            // controller: {
            //     ...config.controller,
            // },
            module: {
                ...config.module,
                createWorkspaceSelectionModule(moduleConfig) {
                    return config.module.createWorkspaceSelectionModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(context) {
                                        const original = controller.useProps(context);
                                        const tracker = useTracker();
                                        return {
                                            ...original,
                                            getItemProps(workspace, index) {
                                                const itemsProps =
                                                    original.getItemProps?.(
                                                        workspace,
                                                        index
                                                    );
                                                return {
                                                    ...itemsProps,
                                                    onClick(event) {
                                                        tracker.track(
                                                            'dashboardAssetChanged',
                                                            // @ts-expect-error
                                                            {
                                                                assetId: workspace.id,
                                                                assetName: workspace.name,
                                                            }
                                                        );
                                                        itemsProps?.onClick?.(event);
                                                    },
                                                };
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
    };
}
