import {
    Box,
    Button,
    Grid,
    Heading,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import React from 'react';
// import { AiOutlineBulb } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AiOutlineBulb, AiFillBulb } from 'react-icons/ai';
import { HomeItemConfig, HomeRouteItemProps } from '../..';
import { InsightViewProps } from '../../../view';
import { isEmptyCollection, isLoadedCollection } from '../../../base';
import { HomeOpportunityViewProps } from '../../../strategy/home/opportunities/homeOpportunityProps';

export function createHomeOpportunityView(
    init: HomeItemConfig
): React.FC<{ children?: React.ReactNode | undefined } & HomeOpportunityViewProps> {
    const {
        UI: {
            Tooltip,
            Icon: { IconGroup, Icon },
            EmptyState,
            Grid: {
                GridContainer,
                GridList,
                GridItem,
                GridContent,
                GridHeader,
                GridBody,
                GridFooter,
            },
        },
    } = init;
    return (props) => {
        const { collection, ...collectionProps } = props.getCollectionProps();
        const statusProps = collectionProps.getStatusProps();
        if (isEmptyCollection(collection)) {
            return (
                <EmptyState
                    icon={<Icon as={AiOutlineBulb} />}
                    title="No opportunities found"
                    description={props.emptyDescription}
                />
            );
        }
        if (!isLoadedCollection(collection)) {
            return <Box>loading...</Box>;
        }
        return (
            <>
                <GridContainer>
                    <GridList>
                        {collection.data.items.map((item, index) => {
                            const { insight, plugin } = item;
                            const dateProps = collectionProps.getDateProps(item);
                            const routeProps = collectionProps.getRouteProps(item);
                            const actionProps = collectionProps.getActionProps(item);
                            return (
                                <GridItem key={insight.id} id={insight.id}>
                                    <GridContent>
                                        <GridHeader>
                                            <HStack w="full" justify="space-between">
                                                <HStack spacing={3}>
                                                    {plugin.iconUrl ? (
                                                        <IconGroup p={2} flexShrink={0}>
                                                            <Icon
                                                                as={Image}
                                                                w={5}
                                                                h={5}
                                                                src={plugin.iconUrl}
                                                            />
                                                        </IconGroup>
                                                    ) : null}
                                                    <Heading
                                                        fontSize="md"
                                                        fontWeight="medium"
                                                    >
                                                        {insight.title}
                                                    </Heading>
                                                </HStack>
                                                {insight.hint && (
                                                    <Tooltip
                                                        id="opportunity_hint_tooltip"
                                                        bg="#1d1c21"
                                                        placement="top"
                                                        hasArrow={true}
                                                        px={3}
                                                        py={2}
                                                        label={
                                                            <Box
                                                                position="relative"
                                                                color="whiteAlpha.900"
                                                            >
                                                                <Text>
                                                                    <Box
                                                                        position="relative"
                                                                        display="inline"
                                                                        as="span"
                                                                        top={0.5}
                                                                        mr={2}
                                                                    >
                                                                        <Icon
                                                                            fontSize="md"
                                                                            color="whiteAlpha.800"
                                                                            as={
                                                                                AiFillBulb
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Text as="span">
                                                                        {insight.hint}
                                                                    </Text>
                                                                </Text>
                                                            </Box>
                                                        }
                                                    >
                                                        <Box as="span">
                                                            <Icon
                                                                fontSize="xl"
                                                                color="whiteAlpha.800"
                                                                as={AiFillBulb}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </HStack>
                                        </GridHeader>
                                        <GridBody>
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.700"
                                            >
                                                {insight.body}
                                            </Text>
                                        </GridBody>
                                        <GridFooter>
                                            <HStack justify="space-between" w="full">
                                                <HStack
                                                    spacing={2}
                                                    color="whiteAlpha.500"
                                                    divider={
                                                        <Box border="none">&#x2022;</Box>
                                                    }
                                                >
                                                    <Tooltip
                                                        id="opportunity_date_tooltip"
                                                        hasArrow={true}
                                                        placement="top"
                                                        bg="#1d1c21"
                                                        px={3}
                                                        py={2}
                                                        label={
                                                            <Text color="whiteAlpha.900">
                                                                {dateProps.description}
                                                            </Text>
                                                        }
                                                    >
                                                        <Text
                                                            cursor="default"
                                                            color="whiteAlpha.500"
                                                            fontWeight="medium"
                                                            lineHeight="shorter"
                                                        >
                                                            {dateProps.label}
                                                        </Text>
                                                    </Tooltip>
                                                    {actionProps.map((action) => (
                                                        <Box key={action.key}>
                                                            {action.label}
                                                        </Box>
                                                    ))}
                                                </HStack>
                                                {routeProps.path ? (
                                                    <Link to={routeProps.path}>
                                                        <Button
                                                            onClick={routeProps.onClick}
                                                            colorScheme="blue"
                                                            color="blue.400"
                                                            variant="link"
                                                            _focus={{ outline: 'none' }}
                                                            size="sm"
                                                            fontWeight="bold"
                                                            letterSpacing="wide"
                                                        >
                                                            Open
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                    <Button
                                                        onClick={routeProps.onClick}
                                                        colorScheme="blue"
                                                        color="blue.400"
                                                        variant="link"
                                                        _focus={{ outline: 'none' }}
                                                        size="sm"
                                                        fontWeight="bold"
                                                        letterSpacing="wide"
                                                    >
                                                        Open
                                                    </Button>
                                                )}
                                            </HStack>
                                        </GridFooter>
                                    </GridContent>
                                </GridItem>
                            );
                        })}
                        {statusProps ? (
                            <GridItem id="status" variant="ghost">
                                <GridContent>
                                    <GridBody>
                                        <VStack spacing={3}>
                                            <Box
                                                p={3}
                                                bg="whiteAlpha.300"
                                                borderRadius="md"
                                            >
                                                <Icon as={LockIcon} />
                                            </Box>
                                            <Text fontWeight="medium">
                                                {statusProps.title}
                                            </Text>
                                            {statusProps.button && (
                                                <Button
                                                    colorScheme="green"
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={statusProps.button.onClick}
                                                >
                                                    {statusProps.button.children}
                                                </Button>
                                            )}
                                        </VStack>
                                    </GridBody>
                                </GridContent>
                            </GridItem>
                        ) : null}
                    </GridList>
                </GridContainer>
                {props.children}
            </>
        );
    };
}
