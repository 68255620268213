import { action, computed, makeObservable, observable } from 'mobx';
import StoreBase from '../StoreBase';
import { AdAccount } from '../../models/ext/fbads';
import { fetchAdAccounts } from '../../api/ext/facebook_ads';

class FacebookAdsIntegrationStore extends StoreBase {
    isLoading: boolean = false;
    adAccounts: Array<AdAccount> | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            adAccounts: observable,

            // computed
            // Actions
            setAdAccounts: action,
            getAdAccounts: action,
            setLoading: action,
        });
    }

    setLoading(flag: boolean) {
        this.isLoading = flag;
    }
    setAdAccounts(adAccounts: Array<AdAccount> | null) {
        this.adAccounts = adAccounts;
    }

    getAdAccounts = async (integId: number) => {
        await this.rootStore.auth.waitAuthenticated();
        this.setLoading(true);
        try {
            const { data } = await fetchAdAccounts(integId);
            this.setAdAccounts(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getAdAccounts(integId);
            } else {
                this.handleAxiosError(e, true);
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default FacebookAdsIntegrationStore;
