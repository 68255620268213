import {
    HeadingProps,
    IconProps,
    StackProps,
    Heading,
    Text,
    VStack,
    Flex,
    Center,
    Image,
} from '@chakra-ui/react';
import { WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import React from 'react';
import { ErrorInfo, isApplicationError, ApplicationError } from '../../../base';

export interface DemoLayoutErrorStateProps {
    style?: Partial<{
        stack: Omit<StackProps, 'children'>;
        icon: Omit<IconProps, 'children'>;
        heading: Omit<HeadingProps, 'children'>;
    }>;
    error: ErrorInfo;
}

export const DemoLayoutErrorState: React.FC<
    { children?: React.ReactNode | undefined } & DemoLayoutErrorStateProps
> = ({ error, ...props }) => {
    return (
        <Flex height="full" justifyContent="center" alignItems="center">
            <VStack {...props.style?.stack}>
                <Center>
                    <WarningTwoIcon
                        w={props.style?.icon?.w}
                        h={props.style?.icon?.h}
                        color="red.400"
                    />
                </Center>
                {error.name && (
                    <Heading
                        fontSize="lg"
                        fontWeight="bold"
                        color="red.500"
                        {...props.style?.heading}
                    >
                        {error.name}
                    </Heading>
                )}
                {error.description && (
                    <Text fontSize="md" fontWeight="bold" color="red.500">
                        {error.description}
                    </Text>
                )}
                {error.hint && (
                    <Text fontSize="md" fontWeight="bold" color="red.500">
                        {error.hint}
                    </Text>
                )}
            </VStack>
        </Flex>
    );
};
