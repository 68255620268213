import { chain } from 'lodash';
import { useMemo } from 'react';
import { getVisualizationName } from '../../../../domain/visualization';
import { MetricDefinitionKind } from '../../../../api';
import { AnyResolvedType, isNumericType, Property } from '../../../../domain/attributes';
import { MetricDefinition } from '../../../../domain/metrics';
import { ContentDeps } from './contentConfig';
import { ContentController, ContentFactory } from './contentInterface';

export function createContentController(
    config: ContentDeps,
    factory: ContentFactory
): ContentController {
    const ORDER: MetricDefinitionKind[] = [
        'performance',
        'outcome',
        'diagnostic',
        'input',
    ];

    return {
        useProps(state, props) {
            const formValues = props.context.data.form.group.watch();

            const hash = JSON.stringify([
                props.definitions,
                props.context.workspace.id,
                props.view.id,
                props.visualization,
                // state.date.value,
                // state.filter.value,
                // state.segment.value,
                formValues,
                // state.ranking.value,
                // state.breakdown.value,
            ]);

            const metricsByKey = useMemo(() => {
                return props.context.data.metrics
                    .filter((item) => item.view === props.view.id)
                    .reduce(
                        (acc, item) => ({ ...acc, [item.key]: item }),
                        {} as Record<string, MetricDefinition | undefined>
                    );
            }, [props.context.data.metrics, props.view.id]);

            const propertiesByKey = useMemo(() => {
                return props.view.columns.reduce(
                    (acc, item) => ({ ...acc, [item.key]: item }),
                    {} as Record<string, Property<AnyResolvedType> | undefined>
                );
            }, [props.view.columns]);

            const options = useMemo(() => {
                return chain(props.view.columns)
                    .filter((item) => isNumericType(item.type))
                    .orderBy((item) => {
                        const metric = metricsByKey[item.key];
                        return ORDER.findIndex((candidate) => candidate === metric?.kind);
                    }, 'asc')
                    .map((item) => ({ label: item.name, value: item.key }))
                    .value();
            }, [props.view.id]);

            const request = useMemo(() => {
                return factory.build(props.view, props.visualization, {
                    plugin: props.plugin,
                    segment: formValues.conditions,
                    options,
                });
            }, [hash]);

            return {
                plugin: props.plugin,
                definitions: props.definitions,
                getShowTitle() {
                    return props.visualization.metrics.length === 1;
                },
                getQueryRequestLabel() {
                    return getVisualizationName(
                        props.plugin,
                        propertiesByKey,
                        props.visualization
                    );
                },
                getVisualizationProps() {
                    return {
                        context: props.context,
                        view: props.view,
                        request,
                        statehash: hash,
                        visualization: props.visualization,
                    };
                },
            };
        },
    };
}
