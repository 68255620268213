import { ListItem, UnorderedList, VStack, Text } from '@chakra-ui/react';
import React from 'react';
// import { Typography } from '../../ui-lib';
import { ErrorInfo } from '../../models/Errors';

export default function (err: ErrorInfo): React.ReactElement {
    let comp = null;
    switch (err.errType) {
        case 'validationErr':
            if (err.message) {
                comp = (
                    <Text fontSize="md" textAlign="center" color="error">
                        {err.message}
                    </Text>
                );
            } else if (err.fieldErrors) {
                comp = (
                    <VStack spacing={2}>
                        <Text fontSize="md" color="error">
                            Validation Errors:
                        </Text>
                        <UnorderedList>
                            {err.fieldErrors.map((e) => (
                                <ListItem key={e.msg} color="error">
                                    {e.loc.join(' -> ')}: {e.msg}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </VStack>
                );
            } else {
                comp = (
                    <Text fontSize="md" color="error">
                        Invalid Request To Server, check your form again or contact Varos
                        for help
                    </Text>
                );
            }
            break;
        case 'serverErr':
            if (err.message) {
                comp = (
                    <Text fontSize="md" color="error">
                        {err.message}
                    </Text>
                );
            } else {
                comp = (
                    <Text fontSize="md" color="error">
                        Internal Server Error
                    </Text>
                );
            }
            break;
        case 'notFound':
            if (err.message) {
                comp = (
                    <Text fontSize="md" color="error">
                        {err.message}
                    </Text>
                );
            } else {
                comp = (
                    <Text fontSize="md" color="error">
                        Object was not found
                    </Text>
                );
            }
            break;
        case 'noPerm':
            if (err.message) {
                comp = (
                    <Text fontSize="md" color="error">
                        {err.message}
                    </Text>
                );
            } else {
                comp = (
                    <Text fontSize="md" color="error">
                        Permission Denied.
                    </Text>
                );
            }
            break;
        case 'noPerm':
            if (err.message) {
                comp = (
                    <Text fontSize="md" color="error">
                        {err.message}
                    </Text>
                );
            } else {
                comp = (
                    <Text fontSize="md" color="error">
                        Unauthenticated Request
                    </Text>
                );
            }
            break;
        default:
            comp = (
                <Text fontSize="md" color="error">
                    {err.message || err.errType}
                </Text>
            );
            break;
    }
    return comp;
}
