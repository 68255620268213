import React from 'react';
import { HomeViewProvider } from '../../../route';
import { createMetricMainContainer } from './main';
import { createMetricFallbackContainer } from './fallback';
import { createMetricEditContainer } from './edit';
import { HomeMetricConfig } from './homeMetricConfig';
import { createHomeMetricController, HomeMetricData } from './homeMetricController';
import { HOME_OVERVIEW_ID } from './homeMetricConstant';

export function createHomeMetricItem(
    init: HomeMetricConfig
): HomeViewProvider<HomeMetricData> {
    return {
        id: HOME_OVERVIEW_ID,
        create(config) {
            const { UI } = config;

            const Main = createMetricMainContainer({ ...init, UI });
            const Fallback = createMetricFallbackContainer({ ...init, UI });
            const Edit = createMetricEditContainer({ ...init, UI });

            return {
                id: 'overview',
                title: 'Overview',
                controller: createHomeMetricController({ ...init }),
                layout: {
                    fullWidth: true,
                    showDivider: true,
                },
                actions: [
                    {
                        id: 'add-metric',
                        name: 'Edit metrics',
                        Component(props) {
                            if (!props.data?.collection) {
                                return null;
                            }
                            return (
                                <Edit
                                    {...props}
                                    data={{
                                        ...props.data,
                                        collection: props.data.collection,
                                    }}
                                />
                            );
                        },
                    },
                ],
                Component(props) {
                    if (!props.data?.collection) {
                        return <Fallback {...props} />;
                    }
                    return (
                        <Main
                            {...props}
                            data={{ ...props.data, collection: props.data.collection }}
                        />
                    );
                },
            };
        },
    };
}
