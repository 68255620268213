import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    VStack,
} from '@chakra-ui/react';
import varosLogoWhiteSVG from '../../../../../svg/varos-logo-large-white.svg';
import React from 'react';
import { OrganizationLayoutV2 } from '../../../../route';
import { buildLayoutProps } from './organizationLayoutFactory';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export function createOrganizationAuditLayout(): OrganizationLayoutV2 {
    const instance: OrganizationLayoutV2 = {
        Container(props) {
            const layoutProps = buildLayoutProps(instance, props);
            return (
                <Grid w="full" h="100vh" templateRows="min-content 1fr">
                    <GridItem
                        borderBottom={2}
                        borderColor="whiteAlpha.300"
                        borderStyle="solid"
                    >
                        <Box
                            w="full"
                            mx="auto"
                            px={{ base: 6, md: 0 }}
                            maxW={{
                                sm: '100vw',
                                md: '90vw',
                                lg: '90vw',
                                xl: '90vw',
                                '2xl': '80vw',
                                '3xl': '75vw',
                            }}
                        >
                            <SimpleGrid w="full" columns={3} py={6}>
                                <HStack align="center">
                                    <Link to="/r">
                                        <Image src={varosLogoWhiteSVG} width={24} />
                                    </Link>
                                </HStack>
                                <Box></Box>
                                <HStack justify="end" w="full" spacing={8}>
                                    {layoutProps.links.map((item, index) => (
                                        <Link key={index} to={item.to}>
                                            <Button
                                                variant="link"
                                                _focus={{ outline: 'none' }}
                                            >
                                                {item.children}
                                            </Button>
                                        </Link>
                                    ))}
                                    <Link to="/u">
                                        <Button
                                            variant="outline"
                                            colorScheme="green"
                                            size="sm"
                                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                                            _focus={{ outline: 'none' }}
                                        >
                                            To platform
                                        </Button>
                                    </Link>
                                </HStack>
                            </SimpleGrid>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box
                            w="full"
                            h="full"
                            mx="auto"
                            // py={6}
                            px={{ base: 6, md: 0 }}
                            maxW={{
                                sm: '100vw',
                                md: '90vw',
                                lg: '90vw',
                                xl: '90vw',
                                '2xl': '80vw',
                                '3xl': '75vw',
                            }}
                        >
                            {layoutProps.content.children}
                        </Box>
                    </GridItem>
                </Grid>
            );
        },
        Content() {
            return <></>;
        },
        Navigation: {
            List() {
                return <></>;
            },
            Item() {
                return <></>;
            },
            Link() {
                return <></>;
            },
        },
    };
    return instance;
}
