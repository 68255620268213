import { capitalize } from 'lodash';
import { PageIds } from '../../../../../../config';
import { SettingsBillingControllerConfig } from '../../base';
import { getPaymentMethodImage } from './paymentMethodDetailHelper';
import { PaymentMethodDetailController } from './paymentMethodDetailInterface';
import { PaymenyMethodItemProps } from './paymentMethodDetailProps';

export function createPaymentMethodDetailController(
    config: SettingsBillingControllerConfig
): PaymentMethodDetailController {
    const {
        controller,
        infra: { icons },
    } = config;
    return {
        useProps(context, item, props) {
            const pageProps = controller.page.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ACCOUNT_BILLING_PAYMENT,
                    breadcrumbs: [
                        {
                            label: 'Payment',
                        },
                    ],
                },
            });
            return {
                page: pageProps,
                link: {
                    new: {
                        to: 'edit',
                    },
                },
                methods: item.methods.map((method): PaymenyMethodItemProps => {
                    if (method.kind === 'card') {
                        return {
                            id: method.id,
                            kind: 'card',
                            brand: getPaymentMethodImage(icons, method),
                            number: `**** **** **** ${method.last4}`,
                            expires: `Expires on ${method.expMonth}/${method.expYear}`,
                        };
                    }
                    return {
                        id: method.id,
                        kind: 'custom',
                        type: capitalize(method.type),
                    };
                }),
            };
        },
    };
}
