import { EnumType, CurrencyType } from '../../../domain/attributes';
import { Property } from '../../../domain/demo';
import * as Type from '../type';

export const VERTICAL_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'vertical-v2',
    alias: 'industry',
    name: 'Industry',
    description: `Only a limited number of Industries are included in this tool. Sign up to Varos to access the full 250+ industries.`,
    type: Type.VERTICAL_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

//

export const FACEBOOK_MONTHLY_SPEND_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'avg_monthly_spend',
    alias: 'monthly_spend',
    name: 'Monthly Spend',
    description: null,
    type: Type.MONTHLY_SPEND_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const FACEBOOK_AOV_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'aov',
    name: 'AOV',
    description: null,
    type: Type.AOV_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const FACEBOOK_OBJECTIVE_PROPERTY: Property<EnumType> = {
    kind: 'data',
    key: 'objective',
    name: 'Objective',
    description: null,
    type: Type.FACEBOOK_OBJECTIVE_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

// Google

export const GOOGLE_MONTHLY_SPEND_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'googlealds_avg_monthly_spend',
    alias: 'monthly_spend',
    name: 'Monthly Spend',
    description: null,
    type: Type.MONTHLY_SPEND_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const GOOGLE_AOV_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'googleads_aov',
    alias: 'aov',
    name: 'AOV',
    description: null,
    type: Type.AOV_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const GOOGLE_NETWORK_PROPERTY: Property<EnumType> = {
    kind: 'data',
    key: 'NETWORK',
    alias: 'network',
    name: 'Ad Network',
    description: null,
    type: Type.GOOGLE_NETWORK_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

// Tiktok

export const TIKTOK_MONTHLY_SPEND_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'tiktok_avg_monthly_spend',
    alias: 'monthly_spend',
    name: 'Monthly Spend',
    description: null,
    type: Type.MONTHLY_SPEND_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const TIKTOK_OPTIMIZATION_GOAL_PROPERTY: Property<EnumType> = {
    kind: 'data',
    key: 'OPTIMIZE_GOAL',
    alias: 'optimization_goal',
    name: 'Optimization Goal',
    description: null,
    type: Type.TIKTOK_OPTIMIZATION_GOAL_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

// Shopify

export const SHOPIFY_MONTHLY_REVENUE_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'shopify_6mo_revenue',
    alias: 'revenue',
    name: 'Monthly Revenue',
    description: null,
    type: Type.MONTHLY_SPEND_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};

export const AOV_SHOPIFY_PROPERTY: Property<EnumType> = {
    kind: 'trait',
    key: 'shopify_aov',
    alias: 'aov',
    name: 'AOV',
    description: null,
    type: Type.AOV_TYPE,
    constraints: [],
    isDisabled: false,
    disabledReason: null,
};
