export const ViewIds = {
    EXECUTIVE_SUMMARY: 'executive_summary',
    FACEBOOK: 'facebookads',
    FACEBOOK_VIDEO: 'facebookads-video',
    FACEBOOK_CPA: 'facebookads-actions',
    GOOGLE: 'googleads',
    GOOGLE_ADS_BRANDED: 'googleads-spend-branded',
    GOOGLE_KEYWORDS: 'googleads-keyword-metrics',
    TIKTOK: 'tiktok_v2',
    SHOPIFY_ORDERS: 'shopify_v2_orders',
    SHOPIFY_ABANDONED_CHECKOUT: 'shopify_v2_abandoned_checkout',
    GOOGLE_ANALYTICS_USERS: 'ga4.users',
    GOOGLE_ANALYTICS_NEW_VS_REPEAT: 'ga4.new_vs_repeat',
    GOOGLE_ANALYTICS_ECOMMERCE: 'ga4.ecom',
    GOOGLE_ANALYTICS_CHANNEL: 'ga4.channel',
    IT_BENCHMARKS: 'it_benchmarks.it-benchmarks-main',
} as const;

export const ViewSources = {
    EXECUTIVE_SUMMARY: 'executive_summary',
    FACEBOOK_DAILY_PERFORMANCE: 'facebookads-daily-performance',
    GOOGLE: 'google_v2',
    TIKTOK: 'tiktok_v2',
    SHOPIFY_ORDERS: 'shopify_v2_orders',
    SHOPIFY_REVENUE_GROWTH: 'shopify_v2_revenue_growth',
    GOOGLE_ANALYTICS_ACTIVE_USERS: 'ga4-active-users',
    GOOGLE_ANALYTICS_ECOMMERCE: 'ga4_ecommerce',
    GOOGLE_ANALYTICS_REPEAT_USERS: 'ga4_repeat_users',
} as const;

// TODO hack. we should ensure the same partition key across all views
export const ViewPartitionKeyBySlug = {
    [ViewSources.EXECUTIVE_SUMMARY]: 'ts',
    [ViewSources.GOOGLE_ANALYTICS_ACTIVE_USERS]: 'timestamp',
    [ViewSources.GOOGLE_ANALYTICS_ECOMMERCE]: 'timestamp',
    [ViewSources.GOOGLE_ANALYTICS_REPEAT_USERS]: 'timestamp',
} as Record<string, string | undefined>;
