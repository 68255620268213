import {
    TreeInputOption,
    AnyInputProps,
    InputGroupProps,
} from '../../../ui/application/impl/forms';
import {
    AnyFilter,
    Dashboard,
    isAssetFilter,
    isPropertyFilter,
} from '../../../domain/dashboard';
import {
    isNumericType,
    isStringType,
    isIntegerType,
    isCurrencyType,
    isTreeType,
} from '../../../domain/attributes';
import { castFilterToRangeInputProps } from './segmentCast';
import {
    SegmentListViewProps,
    SegmentItemControllerProps,
    SegmentItemViewProps,
} from './segmentProps';
import { SegmentItemController } from './segmentInterface';
import { useMemo } from 'react';

export function createSegmentItemController() {
    return {
        useProps: useSegment,
    };
}

export const useSegment = (props: SegmentItemControllerProps): SegmentItemViewProps => {
    const inputProps = useMemo((): AnyInputProps => {
        const filter = props.filter;
        if (isAssetFilter(filter)) {
            return {
                type: 'tree',
                truncateCount: 2,
                showSelectAll: true,
                value: props.value?.value ?? [],
                onChange(value) {
                    return props.onChange({
                        // key: props.filter.property.key,
                        // operator: 'in',
                        value: value,
                    });
                },
                options: [
                    ...props.item.assets
                        .filter(
                            (item) => item.visible && item.id !== props.item.current.id
                        )
                        .map((item) => ({
                            value: item.id,
                            label: item.name,
                            children: [],
                        })),
                ],
            };
        }
        // console.log('property', filter);
        if (isTreeType(filter.property.type)) {
            return props.getInputProps({
                type: 'tree',
                truncateCount: 6,
                value: props.value?.value ?? [],
                onChange(value) {
                    return props.onChange({
                        // key: props.filter.property.key,
                        // operator: 'in',
                        value: value,
                    });
                },
                options: filter.property.type.members.map<TreeInputOption>((member) => ({
                    label: member.label,
                    value: member.value,
                    children: member.children.map((child) => ({
                        label: child.label,
                        value: child.value,
                    })),
                })),
            });
        }
        if (isStringType(filter.property.type)) {
            return props.getInputProps({
                type: 'string',
            });
        }
        if (
            isNumericType(filter.property.type) &&
            filter.default?.operator === 'between'
        ) {
            return props.getInputProps(castFilterToRangeInputProps(props, filter));
        }
        if (isNumericType(filter.property.type)) {
            return props.getInputProps({
                type: 'number',
                variant: 'slider',
                // value: values[index],
                // value: values[filter.property.key],
                // onChange: (value) => form.setValue(filter.property.key, value),
            });
        }
        if (isCurrencyType(filter.property.type)) {
            return props.getInputProps({
                type: 'number',
                variant: 'slider',
                // value: values[filter.property.key],
                // onChange: (value) => form.setValue(filter.property.key, value),
            });
        }
        throw new Error(
            `unexpected property type ${JSON.stringify(filter.property.type)}`
        );
    }, [props.filter, props.value, props.item, props.getInputProps]);

    return {
        getInputGroupProps(filter): InputGroupProps {
            return { label: filter.property.name };
        },
        getInputProps(filter): AnyInputProps {
            return inputProps;
        },
    };
};
