import React from 'react';
import {
    TextProps,
    HeadingProps,
    GridItemProps,
    VStack,
    Button,
    Box,
    Stack,
    Center,
    Text,
    ButtonProps,
    StackProps,
    Grid,
    GridItem,
    GridProps,
    Heading,
    useColorMode,
} from '@chakra-ui/react';
import { getChildrenProps, getElements, fetchElements } from '../../../util';
import { DemoLayoutPlatforms } from './DemoLayoutPlatforms';
import { DemoLayoutHeader } from './DemoLayoutHeader';
import { DemoLayoutBanner } from './DemoLayoutBanner';
import { DemoLayoutVisualization } from './DemoLayoutVisualization';
import { DemoLayoutFilters } from './DemoLayoutFilters';
import { DemoLayoutMetrics } from './DemoLayoutMetrics';
import { DemoLayoutTime } from './DemoLayoutTime';
import { DemoLayoutFooter } from './DemoLayoutFooter';
import { DemoLayoutErrorStateProps } from './DemoLayoutErrorState';

export interface DemoLayoutStyle {
    wrapper?: Omit<GridProps, 'children' | 'templateAreas'>;
    banner?: Omit<GridProps, 'children' | 'templateAreas'>;
    header?: Omit<GridProps, 'children' | 'area'>;
    body?: Omit<GridProps, 'children' | 'area'>;
    main?: Omit<GridProps, 'children' | 'area'>;
    visualization?: Omit<GridItemProps, 'children' | 'area'>;
    scroll?: Pick<GridProps, '__css'>;
    left?: Omit<StackProps, 'children' | 'area'>;
    heading?: Omit<HeadingProps, 'children'>;
    button?: {
        default?: Omit<ButtonProps, 'children' | 'onClick'>;
        cta?: Omit<ButtonProps, 'children' | 'onClick'>;
    };
    text?: {
        secondary?: Omit<TextProps, 'children'>;
    };
    navigation?: Omit<StackProps, 'children'>;
    errorState?: DemoLayoutErrorStateProps['style'];
}

export interface DemoLayoutWrapperProps {
    style?: Partial<DemoLayoutStyle>;
    children: Array<React.ReactElement | null> | React.ReactElement;
}

export const DemoLayoutWrapper: React.FC<
    { children?: React.ReactNode | undefined } & DemoLayoutWrapperProps
> = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();
    React.useEffect(() => {
        if (colorMode === 'dark') {
            toggleColorMode();
        }
        // this is a hack to fix this issue
        // https://github.com/chakra-ui/chakra-ui/issues/5925
        if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
            localStorage.setItem('chakra-ui-color-mode', 'light');
        }
    }, []);

    const { Footer, Platforms, Filters, Metrics, Time, Visualization } = fetchElements(
        props.children,
        {
            Platforms: DemoLayoutPlatforms,
            Filters: DemoLayoutFilters,
            Metrics: DemoLayoutMetrics,
            Time: DemoLayoutTime,
            Visualization: DemoLayoutVisualization,
            Footer: DemoLayoutFooter,
        }
    );

    const { header: headerProps, banner: bannerProps } = getChildrenProps(
        props.children,
        {
            header: DemoLayoutHeader,
            banner: DemoLayoutBanner,
        }
    );
    return (
        <Grid
            {...props.style?.wrapper}
            templateAreas={`
                "banner"
                "body"  
                "footer"
            `}
            gridTemplateRows="min-content min-content min-content"
            w="100%"
            minH="100vh"
        >
            {bannerProps && (
                <GridItem {...props.style?.banner} area="banner">
                    <Center>
                        <Text>{bannerProps.text}</Text>
                    </Center>
                </GridItem>
            )}
            <GridItem
                {...props.style?.body}
                {...props.style?.scroll}
                area="body"
                // overflowY="auto"
            >
                <Grid
                    {...props.style?.main}
                    templateAreas={`
                        "header header"
                        "platforms platforms"
                        "filters filters"
                        "visualization visualization"
                    `}
                    gridTemplateRows="min-content min-content min-content 1fr"
                    w="full"
                    h="full"
                >
                    <GridItem area="header">
                        <VStack alignItems="flex-start" spacing={1}>
                            <Heading {...props.style?.heading} m={0}>
                                {headerProps?.title}
                            </Heading>
                            <Text {...props.style?.text?.secondary}>
                                {headerProps?.subtitle}
                            </Text>
                        </VStack>
                    </GridItem>
                    <GridItem area="platforms">{Platforms}</GridItem>
                    <GridItem area="filters">{Filters}</GridItem>
                    <GridItem area="visualization">
                        <Grid
                            w="full"
                            templateAreas={`
                                "metrics"
                                "visualization"
                            `}
                            gridTemplateRows="min-content 1fr"
                            gap={props.style?.main?.gap}
                        >
                            <GridItem area="metrics">
                                <Grid gridTemplateColumns="1fr min-content" gap={8}>
                                    <GridItem overflowX="auto">{Metrics}</GridItem>
                                    <GridItem>{Time}</GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem
                                {...props.style?.visualization}
                                area="visualization"
                                w="full"
                            >
                                {Visualization}
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </GridItem>
            <GridItem area="footer">{Footer}</GridItem>
        </Grid>
    );
};
