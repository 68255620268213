import { convertAxiosToError, isErrorInfo } from '../../utils/axiosUtils';
import { createApi } from '../../api/axios';
import { useStore } from '../../stores/setupContext';
import { ApiHookConfig } from './apiConfig';
import { getApplicationEnv } from '..';
import { AxiosInstance } from 'axios';
import AuthStore from '../../stores/AuthStore';

// const DELAY_MS = getApplicationEnv() === 'development' ? 250 : undefined;
const DELAY_MS = 0;

export const getAuthenticatedApi = (
    auth: AuthStore,
    _config: ApiHookConfig = {}
): AxiosInstance => {
    const getToken = async () => {
        await auth.waitAuthenticated();
        return auth.authToken;
    };
    const refreshToken = async () => {
        await auth.loginRefreshToken();
        return auth.authToken;
    };

    const client = createApi({
        delayMs: DELAY_MS,
        getToken,
        refreshToken,
        transformError(error) {
            if (isErrorInfo(error)) {
                return error;
            }
            return convertAxiosToError(error);
        },
    });
    return client;
};

export const useAuthenticatedApi = (_config: ApiHookConfig = {}): AxiosInstance => {
    const { auth } = useStore();
    return getAuthenticatedApi(auth, _config);
};
