import { DateForm } from '../../../../service/query';
import { DateController, DateFactory } from './dateInterface';

export function createDateController(
    forms: { date: DateForm },
    factory: DateFactory
): DateController {
    return {
        useProps(data, props) {
            // const initialValue = factory.build(state.value);

            // const formValues = data.form.visualization.watch();

            const initialValue = factory.build({
                // period: props.visualization.period ??
                //     state.value?.period ?? { amount: 4, interval: 'week' },
                // granularity: props.visualization.granularity,
                period: props.data.visualization.period ?? {
                    amount: 4,
                    interval: 'week',
                },
                granularity: props.data.visualization.granularity,
                comparison: { amount: 1, interval: 'period' },
            });
            const formFields = forms.date.useFormState({
                value: initialValue,
                onChange(value) {
                    console.log('changing', value);
                    // state.onChange(value);
                    data.form.visualization.setValue('period', value.period);
                    data.form.visualization.setValue('granularity', value.granularity);
                },
            });

            const value = formFields.getValue();

            return {
                context: props.context,
                value: { ...value, period: value.period },
                onChange: formFields.setValue,
            };
        },
    };
}
