import { useQuery } from '@tanstack/react-query';
import { MetricSummaryRepositoryConfig } from './summaryConfig';
import { MetricSummaryRepository } from './summaryInterface';

export function createMetricSummaryRepository(
    config: MetricSummaryRepositoryConfig
): MetricSummaryRepository {
    const { adapter } = config;
    return {
        useBulk(context, queries, options) {
            return useQuery({
                ...options,
                queryKey: ['metric', 'summary', queries],
                async queryFn() {
                    const responses = await Promise.all(
                        queries.map(async (query) => {
                            query;
                            const response = await adapter.find(context, query);
                            return response;
                        })
                    );
                    return responses.flat();
                },
            });
        },
    };
}
