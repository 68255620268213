import React from 'react';
import { Authentication } from '../../../../api';
import { UseReportScheduleEditFormHook } from './scheduleEditInterface';
import {
    EditScheduleFormViewProps,
    EditScheduleFormContainerProps,
} from './scheduleEditProps';

export function createEditScheduleFormContainer(config: {
    hooks: {
        useAuth(): Authentication;
    };
    View: React.FC<
        { children?: React.ReactNode | undefined } & EditScheduleFormViewProps
    >;
    controller: UseReportScheduleEditFormHook;
}): React.FC<
    { children?: React.ReactNode | undefined } & EditScheduleFormContainerProps
> {
    const {
        hooks: { useAuth },
        View,
        controller,
    } = config;
    return (props) => {
        const auth = useAuth();
        const viewProps = controller.useProps({ auth }, props);
        return <View {...viewProps}>{props.children}</View>;
    };
}
