import { GroupsIntroController } from './groupsIntroInterface';
import { AccountProfileRepository } from '../../../../app/accounts/profile';

export const createGroupsIntroController = (config: {
    repository: {
        profile: AccountProfileRepository;
    }
}): GroupsIntroController => {
    return {
        useProps(ctx, data) {
            const profile = config.repository.profile.useLookup(ctx, { suspense: true });

            
            return {
                status: data.initial.state,
                settingsUrl: '/u/settings/privacy',
                showInternal: data.initial.state == 'internal' || (profile.status === 'success' &&
                    profile.data.organizationType === 'ad_agency'),
                videoUrl:
                    'https://www.loom.com/embed/4d347b58a34540598d38fc1db815d440?sid=b2387276-b7ae-49a1-9c8c-7d51606bcf4c',
                
            };
        },
    };
};
