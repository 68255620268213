import { AxiosResponse, AxiosInstance } from 'axios';
import { EvaluationResponse, MetadataRule, MultiFilterRule } from '../../models/Metadata';

export const evaluateFilter = async (
    api: AxiosInstance,
    assetId: number,
    filters?: MultiFilterRule | MetadataRule | null,
    dashboardId?: number | string,
    options?: {
        headers?: Record<string, string>;
    }
) => {
    return api.post<unknown, AxiosResponse<EvaluationResponse>>(
        `/api/v1/peer-group-filters/evaluate`,
        {
            filters,
            participant_ids: [assetId],
            dashboard: dashboardId,
        },
        {
            headers: options?.headers,
        }
    );
};
