import { useQuery } from '@tanstack/react-query';
import { normalizeCollection } from '../../../base';
import { PluginAdapter } from './pluginAdapter';
import { PluginRepository } from './pluginInterface';

export function createPluginRepository(adapter: PluginAdapter): PluginRepository {
    return {
        useCollection(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['plugin-traits-v2', 'collection', query],
                async queryFn() {
                    let response = await adapter.find(context, query);
                    if (query.ignoreIds) {
                        response = response.filter(
                            (item) => !query.ignoreIds?.includes(item.id)
                        );
                    }
                    if (query.includeIds) {
                        response = response.filter((item) =>
                            query.includeIds?.includes(item.id)
                        );
                    }
                    return response;
                },
                select(data) {
                    return normalizeCollection(data);
                },
            });
        },
        useFind(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['plugin-traits-v2', 'find', query],
                async queryFn() {
                    let response = await adapter.find(context, query);
                    if (query.ignoreIds) {
                        response = response.filter(
                            (item) => !query.ignoreIds?.includes(item.id)
                        );
                    }
                    if (query.includeIds) {
                        response = response.filter((item) =>
                            query.includeIds?.includes(item.id)
                        );
                    }
                    return response;
                },
            });
        },
    };
}
