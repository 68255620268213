import React from 'react';
import {
    Image,
    HStack,
    Spacer,
    Divider,
    Flex,
    VStack,
    useColorMode,
    DarkMode,
} from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Button } from '../../../../ui';
import plusSVG from '../../../../svg/plus.svg';
import { IntegrationConf } from '../../../../models';
import { UserIntegrationDefinition } from '../../integrationType';
import { IntegrationCardActionProps } from '../item';

export interface IntegrationModalProps {
    isOpen: boolean;
    onClose(): void;
    definition: UserIntegrationDefinition;
    onConnect: (integ: IntegrationConf) => void;
}

export const IntegrationModal: React.FC<
    { children?: React.ReactNode | undefined } & IntegrationModalProps
> = (props) => {
    const onConnectClick: IntegrationCardActionProps['onConnect'] = (event) => {
        event.stopPropagation();
        return props.onConnect(props.definition);
    };
    return (
        <DarkMode>
            <Modal
                isOpen={props.isOpen}
                onClose={props.onClose}
                scrollBehavior="inside"
                size="md"
            >
                <ModalOverlay />
                <ModalContent pb={2} bg="#1D1C21">
                    <ModalHeader
                        bg="#292929"
                        borderTopRadius="md"
                        pb={3}
                        pl={3}
                        pr={3}
                        pt={1}
                    >
                        <HStack mt={2} spacing={3}>
                            <Image
                                alt={props.definition.title}
                                boxSize="30px"
                                src={props.definition.icon_url}
                            />
                            <Text>{props.definition.title}</Text>
                            <Spacer />
                            <Button
                                colorScheme="gray"
                                variant="solid"
                                size="sm"
                                disabled={!props.definition.is_available}
                                leftIcon={<Image src={plusSVG} />}
                                onClick={onConnectClick}
                            >
                                Add
                            </Button>
                            <Divider
                                height="30px"
                                orientation="vertical"
                                border=".5px"
                                mx={2}
                            />
                            <IconButton
                                size="sm"
                                bg="none"
                                aria-label={'close'}
                                icon={<CloseIcon />}
                                onClick={props.onClose}
                            />
                        </HStack>
                    </ModalHeader>
                    <Divider />
                    <ModalBody bg="#1D1C21" w="100%" borderBottomRadius="md">
                        <Flex justifyContent="space-between" width="100%">
                            <VStack spacing={5} w="100%">
                                {props.children}
                            </VStack>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </DarkMode>
    );
};
