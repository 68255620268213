import {
    useMutation,
    useQueries,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { UserIntegrationDefinition } from '../../../domain';
import { IntegrationPreference } from '../../../models';
import { ApplicationContext, AssetContext } from '../..';
import {
    IntegrationEventType,
    IntegrationHistoryDto,
    listIntegrationHistory,
    listPreferences,
} from '../../../api/integration';
import * as mutations from './integrationMutation';
import * as queries from './integrationQuery';
import React from 'react';

const KEY = ['integration/definitions'];

const PREFERNCES_KEY = (asset_id: number) => ['integration/preferences/asset', asset_id];

export const useIntegrationDefinitions = (
    context: ApplicationContext,
    options: Pick<UseQueryOptions, 'suspense'> = {}
) => {
    const handler = queries.createListHandler(context);
    const query = useQuery(KEY, handler, {
        ...options,
        // when connecting new integrations we open a new tab. on completetion we want
        // to refresh the integration state in the original browser window
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });
    return query;
};

export const useAssetIntegrationDefinitionPreferences = (
    context: AssetContext,
    options: Pick<UseQueryOptions, 'suspense'> = {}
) => {
    return useQuery({
        queryKey: PREFERNCES_KEY(context.assetId),
        queryFn: () => listPreferences(context.api, context.assetId),
        suspense: options.suspense,
        // refetchOnWindowFocus: true,
    });
};

export const useAssetIntegrationDefinition = (
    context: AssetContext,
    definition: UserIntegrationDefinition
) => {
    const client = useQueryClient();
    const handler = mutations.createIgnoreHandler(context, definition);

    const ignore = useMutation(handler, {
        onSuccess(updated) {
            client.setQueryData<IntegrationPreference[]>(
                PREFERNCES_KEY(context.assetId),
                (preferences) => {
                    const index =
                        preferences?.findIndex(
                            (candidate) => candidate.definition_id === updated.id
                        ) ?? -1;
                    if (index == -1) {
                        // didnt exist in cache, insert
                        return [...(preferences ?? []), updated];
                    }
                    return preferences?.map((preference) =>
                        preference.definition_id === updated.id
                            ? { ...preference, is_ignored: updated.is_ignored }
                            : preference
                    );
                }
            );
        },
    });

    return { actions: { ignore } };
};

export const useIntegrationHistory = (
    context: ApplicationContext,
    integrationId: number,
    eventTypes?: IntegrationEventType[],
    page: number = 1,
    pageSize: number = 200
) => {
    return useQuery({
        queryKey: [
            'integrations/history',
            integrationId,
            ...(eventTypes || []),
            page,
            pageSize,
        ],
        queryFn: () =>
            listIntegrationHistory(
                context.api,
                integrationId,
                eventTypes,
                page,
                pageSize
            ),
        suspense: false,
        refetchOnWindowFocus: false,
    });
};

const CONNECTED_EVENTS: IntegrationEventType[] = ['connected', 'reconnected'];

export const useConnectedBy = (
    context: ApplicationContext,
    integrationIds: number[]
): Record<number, UseQueryResult<IntegrationHistoryDto | null>> => {
    const connectedEvents = useQueries({
        queries: integrationIds.map((integId) => ({
            queryKey: ['integrations/history/connected_by', integId],
            queryFn: async () => {
                const conEvents = await listIntegrationHistory(
                    context.api,
                    integId,
                    CONNECTED_EVENTS,
                    1,
                    100
                );
                if (conEvents.length > 0) {
                    const [first] = conEvents;
                    return first;
                }
                return null;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        })),
    });
    return React.useMemo(() => {
        return connectedEvents.reduce<
            Record<number, UseQueryResult<IntegrationHistoryDto | null>>
        >((agg, curr, idx) => {
            agg[integrationIds[idx]] = curr;
            return agg;
        }, {});
    }, [connectedEvents]);
};
