import React from 'react';
import { OrganizationReference, AuthenticationV2 } from '../../../../domain/platform';
import { WorkspaceListController } from './workspaceListInerface';
import { WorkspaceListViewProps } from './workspaceListProps';

export function createWorkspaceListContainer(config: {
    hook: {
        useOrganization(): OrganizationReference;
        useAuth(): AuthenticationV2;
    };
    controller: WorkspaceListController;
    View: React.FC<{ children?: React.ReactNode | undefined } & WorkspaceListViewProps>;
}): React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> {
    const {
        View,
        controller: { useProps: useView },
        hook: { useOrganization, useAuth },
    } = config;
    return (_props) => {
        const organization = useOrganization();
        const auth = useAuth();
        const viewProps = useView({ organization, auth }, {});
        return <View {...viewProps} />;
    };
}
