import React, { Suspense, useState } from 'react';
import { VStack, Switch, Box, Heading } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { SettingsSubscriptionConfig } from './scheduleListConfig';
import { ScheduleListViewProps } from './scheduleListProps';

export function createScheduleListView(
    config: SettingsSubscriptionConfig
): React.FC<{ children?: React.ReactNode | undefined } & ScheduleListViewProps> {
    const {
        UI: { Table, Tbody, Thead, Tr, Td, Th, Tag, Button, Text },
        Container: { ScheduleForm },
    } = config;
    return (props) => {
        return (
            <VStack alignItems="flex-start" w="full" spacing={4}>
                <VStack alignItems="flex-start" spacing={2}>
                    <Heading size="lg">Scheduled reports</Heading>
                    <Text color="whiteAlpha.700">
                        Subscribe to and receive scheduled reports in your email inbox
                    </Text>
                </VStack>
                <Table w="full">
                    <Thead>
                        <Tr>
                            <Th>Brand</Th>
                            <Th>Name</Th>
                            <Th>Recipients</Th>
                            <Th>Active</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {props.schedules.map((notification, index) => {
                            const childProps = props.getChildProps(notification, index);
                            return (
                                <Tr key={index}>
                                    <Td whiteSpace="nowrap">
                                        {notification.workspace.name}
                                    </Td>
                                    <Td>{notification.name}</Td>
                                    <Td onClick={childProps.onEdit} cursor="pointer">
                                        <Tag.List maxItems={5}>
                                            {notification.recipients
                                                .map((recipient) => {
                                                    if (
                                                        typeof recipient.user !== 'object'
                                                    ) {
                                                        throw new Error(
                                                            'expected recipient user to have been expanded'
                                                        );
                                                    }
                                                    return recipient.user;
                                                })
                                                .map((user) => (
                                                    <Tag.Item
                                                        key={user.email}
                                                        colorScheme="green"
                                                        variant="subtle"
                                                    >
                                                        {user.first_name
                                                            ? [
                                                                  user.first_name,
                                                                  user.last_name,
                                                              ]
                                                                  .filter(Boolean)
                                                                  .join(' ')
                                                            : user.email}
                                                    </Tag.Item>
                                                ))}
                                        </Tag.List>
                                    </Td>
                                    <Td>
                                        <Switch {...childProps.toggleProps} />
                                    </Td>
                                    <Td>
                                        <SettingsIcon
                                            cursor="pointer"
                                            onClick={childProps.onEdit}
                                        />
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                {props.modalProps ? (
                    <Suspense
                        fallback={
                            // temp hack that wraps in suspense to prevent bubbling loading states from inside modal content
                            <></>
                        }
                    >
                        <ScheduleForm {...props.modalProps} />
                    </Suspense>
                ) : null}
            </VStack>
        );
    };
}
