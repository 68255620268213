import { chain } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import {
    buildGroupMemberItemViewProps,
    GroupMemberAggregate,
    GroupMemberItemViewProps,
} from '../item';
import { GroupMemberListController } from './groupMemberListInterface';

export function createGroupMemberListController(): GroupMemberListController {
    return {
        useProps(context, item) {
            const { current } = item;
            const items = useMemo(() => {
                // const current: GroupMemberAggregate | null = item.current
                //     ? {
                //           group: item.group,
                //           member: {
                //               id: '',
                //               membership: 'opt_in',
                //               asset_id: item.current.id,
                //               group_id: item.group.id,
                //               created_at: new Date(),
                //           },
                //           asset: item.current,
                //       }
                //     : null;

                let aggregates = [...item.items];
                // if (current) {
                //     aggregates = [current, ...aggregates];
                // }

                return chain(aggregates).value();
            }, [item.items]);

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item, index) => ({
                            ...acc,
                            [item.peerset.id]: buildGroupMemberItemViewProps(
                                context,
                                current,
                                item,
                                index
                            ),
                        }),
                        {} as Record<string, GroupMemberItemViewProps | undefined>
                    ),
                [context, current, items]
            );

            const plural = {
                label: pluralize('member', items.length),
            };

            return {
                status: {
                    label: items.length > 0 ? `${items.length} ${plural}` : 'No members',
                },
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.peerset.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
            };
        },
    };
}
