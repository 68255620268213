import React from 'react';
import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    InputGroup,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    buildInputLongTextProps,
    buildInputNumberProps,
    buildInputShortTextProps,
    InputUI,
} from '../../../ui';
import { FormFieldItemViewProps } from '../../../view/forms';

export function createFieldItemView(
    UI: InputUI
): React.FC<{ children?: React.ReactNode | undefined } & FormFieldItemViewProps> {
    return (props) => {
        let content = null;
        if (props.input.kind === 'number') {
            content = <UI.Number {...buildInputNumberProps(props.input)} />;
        }
        if (props.input.kind === 'short_text') {
            content = <UI.ShortText {...buildInputShortTextProps(props.input)} />;
        }
        if (props.input.kind === 'long_text') {
            content = <UI.LongText {...buildInputLongTextProps(props.input)} />;
        }
        if (props.input.kind === 'dropdown') {
            content = (
                <UI.Dropdown
                    direction={props.input.options.length > 2 ? 'column' : 'row'}
                    spacing={4}
                    color="whiteAlpha.900"
                    {...props.input}
                />
            );
        }
        if (props.input.kind === 'checkbox') {
            content = (
                <UI.Checkbox
                    color="whiteAlpha.700"
                    spacing={4}
                    direction="row"
                    {...props.input}
                />
            );
        }
        return (
            <FormControl isInvalid={!!props.error}>
                <VStack w="full" align="start" spacing={3}>
                    <FormLabel color="whiteAlpha.700">
                        <HStack w="full" spacing={3} align="start" maxW="32rem">
                            {props.icon}
                            <Text>
                                {props.label}{' '}
                                {props.isRequired ? null : (
                                    <Box as="span" color="#c6e74f" fontSize="sm">
                                        (optional)
                                    </Box>
                                )}
                            </Text>
                        </HStack>
                    </FormLabel>
                    <InputGroup>{content}</InputGroup>
                </VStack>
                {props.error?.message && (
                    <FormErrorMessage>{props.error?.message}</FormErrorMessage>
                )}
            </FormControl>
        );
    };
}
