import React from 'react';
import { OrganizationReference, AuthenticationV2 } from '../../../../domain/platform';
import { UserListController } from './userListInerface';
import { UserListViewProps } from './userListProps';
import { UserContextValue } from '../../../../app';

export function createUserListContainer(config: {
    hook: {
        useOrganization(): OrganizationReference;
        useAuth(): AuthenticationV2;
        useUser(): UserContextValue;
    };
    controller: UserListController;
    View: React.FC<{ children?: React.ReactNode | undefined } & UserListViewProps>;
}): React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> {
    const {
        View,
        controller: { useProps: useView },
        hook: { useOrganization, useAuth, useUser },
    } = config;
    return (_props) => {
        const organization = useOrganization();
        const auth = useAuth();
        const user = useUser();
        const viewProps = useView({ organization, auth }, { user: user.user });
        return <View {...viewProps} />;
    };
}
