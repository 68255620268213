import { MetricSummaryQuery } from '../../../../app/metrics';
import { MetricConfiguration, MetricDefinition } from '../../../../domain/metrics';
import { ViewEntity } from '../../../../domain';
import { PeerGroup } from '../../../../domain/peers';
import { ReportEntity } from '../../../../app';
import { chain } from 'lodash';

export function buildQueries(props: {
    report: ReportEntity;
    definitions: MetricDefinition[];
    peersByPlugin: Record<string, PeerGroup | undefined>;
    viewsByMetric: Record<string, ViewEntity | undefined>;
    configurationByMetric: Record<
        string,
        Pick<MetricConfiguration, 'ranking'> | undefined
    >;
}): MetricSummaryQuery[] {
    return chain(props.definitions)
        .flatMap((item) => {
            const view = props.viewsByMetric[item.id];
            if (!view) {
                console.warn(`no view found for metric ${item.id}`);
                return [];
            }
            return [
                {
                    item,
                    view,
                },
            ];
        })
        .groupBy((item) => item.view.id)
        .flatMap((items, view): MetricSummaryQuery[] => {
            const [sample] = items;
            const peer = props.peersByPlugin[sample.item.plugin];
            const configuration = props.configurationByMetric[sample.item.id];
            if (!peer) {
                console.warn(`no peer found for plugin ${sample.item.plugin}`);
                return [];
            }
            return [
                {
                    workspace: props.report.asset,
                    view: sample.view,
                    peer,
                    metrics: items.map((item) => ({
                        id: item.item.id,
                        key: item.item.key,
                        name: item.item.name,
                    })),
                    period: {
                        start: props.report.period.from!,
                        end: props.report.period.to!,
                    },
                    comparison: {
                        interval: 'period',
                        amount: 1,
                    },
                    ranking: configuration?.ranking ?? {
                        kind: 'dynamic',
                        cohorts: [
                            {
                                visible: true,
                                name: 'Low performers',
                                value: 25,
                            },
                            {
                                visible: true,
                                name: 'Median',
                                value: 50,
                            },
                            {
                                visible: true,
                                name: 'High performers',
                                value: 75,
                            },
                        ],
                    },
                },
            ];
        })
        .value();
}
