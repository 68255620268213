import { NotificationModuleConfig } from './notificationConfig';

export function createNotificationModule(config: NotificationModuleConfig) {
    return {
        Subscription: {
            List: {
                controller: config.provider.list.createController(config),
                View: config.provider.list.createView({
                    UI: config.deps.UI,
                    // Layout: config.deps.Layout,
                    // Container: {
                    //     ScheduleForm: config.provider.edit.createContainer({
                    //         hooks: config.deps.hook,
                    //         controller: config.provider.edit.createController({
                    //             service: {
                    //                 // collaborator: config.deps.service.collaborator,
                    //             },
                    //         }),
                    //         View: config.provider.edit.createView({ UI: config.deps.UI }),
                    //     }),
                    // },
                }),
            },
        },
    };
}
