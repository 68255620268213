import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../stores/setupContext';
import { useNavigate, Route } from 'react-router-dom';
import { VarosRoutes } from '../../../infra';
// import OrganizationWizard from './OrganizationWizard.tsx.dis';
import AdvancedForm from './AdvancedForm';
import AuthStore from '../../../stores/AuthStore';
import { ReceiptPage } from '../../../pages';
import Integrations from './AddIntegration';
import UserInvites from './UserInvites';
// import ReferralsPage from './Referrals';
import {
    Text,
    Box,
    Container,
    HStack,
    IconButton,
    Spinner,
    Tooltip,
    Flex,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import NoPeerGroups from './NoPeerGroups';
import { User } from '../../../models/Account';
import ChooseOrganization from './ChooseOrganization';
import ConnectCardPage from '../Integrations/ConnectCardPage';
import { ChoosePlanView } from './choosePlan';
import { SocialCallback } from './SocialCallback';
import { useSessionStorage } from 'react-use';
import { AnyOnboardingStep } from 'src/v2/domain/onboarding';

export interface MainProps {
    isLoading: boolean;
    requiredState: AuthStore['requiredState'];
    orgId?: number;
    logout: () => void;
    currentUser: User | null;
}

export const ActualMain: React.FC<{ children?: React.ReactNode } & MainProps> = ({
    isLoading,
    requiredState,
    logout,
    currentUser,
}) => {
    const navigate = useNavigate();
    const { auth } = useStore();

    const hasIntegrated = auth.onBoardingSteps?.some(
        (step) => step.state === 'req_integration' && step.can_skip
    );
    const reportedIntegration = React.useMemo(() => {
        if (hasIntegrated && currentUser?.id) {
            const uIdStr = currentUser.id.toString();
            if (localStorage.getItem('reportedIntegrateUser') == uIdStr) {
                return true;
            }
        }
        return false;
    }, [hasIntegrated, currentUser?.id]);

    React.useEffect(() => {
        const uIdStr = currentUser?.id?.toString();
        if (hasIntegrated && !reportedIntegration && uIdStr) {
            localStorage.setItem('reportedIntegrateUser', uIdStr);
        }
    }, [hasIntegrated, reportedIntegration, currentUser?.id]);

    const [onboardingStatus, setOnboardingStatus] = useSessionStorage<
        AnyOnboardingStep['id'] | null
    >('vs_onboarding_step_redirect', null);

    const routingFunc = React.useCallback(() => {
        if (
            onboardingStatus === 'integration'
            // && location.pathname.includes('on-boarding')
        ) {
            console.info('navigating back to onboarding integrations');
            setOnboardingStatus(null);
            return navigate(`/u/onboarding?step=integration&integrated=true`);
        }
        if (isLoading) {
            return;
        }
        if (window.location.pathname.includes('receipt')) {
            return;
        }
        switch (requiredState?.state) {
            case 'login':
                navigate('../login');
                break;
            case 'req_organization':
                if (currentUser?.organizations && currentUser.organizations[0]) {
                    navigate('select-org');
                } else {
                    navigate('advanced');
                }
                break;
            case 'req_organization_info':
                navigate('organization-info');
                break;
            case 'req_tenant':
                navigate('tenants/add');
                break;
            case 'req_tenant_info':
                navigate(`tenants/${requiredState.subject_id}/info`);
                break;
            case 'req_asset':
                // navigate(`assets/new`);
                navigate('advanced');
                break;
            case 'req_asset_info':
                navigate(`assets/${requiredState.subject_id}/info`);
                break;
            case 'req_integration':
                if (hasIntegrated && !reportedIntegration) {
                    navigate(`integrations?integrated=true`);
                } else {
                    navigate(`integrations`);
                }
                break;
            case 'req_optional_integration':
                navigate(`integrations?mandatory=0`);
                break;
            case 'user_invites':
                navigate(`user-invites`);
                break;
            case 'subscription':
                navigate(`subscription`);
                break;
            case 'pre_assigned_peer_groups':
                navigate(`pending-peer-groups`);
                break;
            case 'req_referrals':
                navigate('referrals');
                break;
            default:
                break;
        }
    }, [requiredState, isLoading]);

    React.useEffect(routingFunc, [requiredState, isLoading]);
    React.useEffect(routingFunc, []);
    return isLoading ? (
        <Spinner />
    ) : (
        <>
            <Box w="100%" top="0px" position="fixed" zIndex="999">
                <HStack flexDir="row-reverse">
                    <Tooltip label="Logout">
                        <IconButton aria-label="Logout" onClick={logout}>
                            <FiLogOut />
                        </IconButton>
                    </Tooltip>
                </HStack>
            </Box>
            <Container maxW="container.xl">
                <VarosRoutes>
                    <Route path="select-org" element={<ChooseOrganization />} />
                    <Route path="advanced" element={<AdvancedForm />} />
                    {/* <Route path="assets/new" element={<AddAsset />} /> */}
                    <Route path="integrations">
                        <Route
                            path="definitions/:integrationDefinitionId/connect-card"
                            element={<ConnectCardPage />}
                        />
                        <Route index element={<Integrations />} />
                    </Route>
                    <Route path="receipt" element={<ReceiptPage />} />
                    <Route
                        path="pending-peer-groups"
                        element={
                            <Flex h="full" justify="center" alignItems="center">
                                <NoPeerGroups
                                    redirect={() =>
                                        auth.changeToPreviousState('req_integration')
                                    }
                                />
                            </Flex>
                        }
                    />
                    <Route path="user-invites" element={<UserInvites />} />
                    <Route index element={<Text>Page Not Found</Text>} />
                </VarosRoutes>
            </Container>
            <Container maxW="100%">
                <VarosRoutes>
                    <Route path="subscription" element={<ChoosePlanView />} />
                </VarosRoutes>
            </Container>
        </>
    );
};

const Observed = observer(ActualMain);

const WithStoreConnection = () => {
    const mainStore = useStore();
    const { auth, users } = mainStore;
    const { requiredState, loading, currentUser } = auth;
    React.useEffect(() => {
        if (auth.currentUser) {
            if (auth.loggedInOrganization) {
                mainStore.onContextChanged('on-boarding', null, mainStore);
            } else {
                mainStore.onContextChanged('on-boarding-no-org', null, mainStore);
            }
        } else {
            mainStore.onContextChanged('anonymous', null, mainStore);
        }
    }, [auth.currentUser]);
    //   React.useEffect(() => {
    //     if(prevReqState !== null && requiredState === null) {
    //         // switched from on boarding to dashboard
    //         history.push('')
    //     }
    //   }, [requiredState]);
    return (
        <Observed
            isLoading={users.isLoading || loading}
            requiredState={requiredState}
            orgId={auth.loggedInOrganization?.id}
            logout={auth.logout}
            currentUser={currentUser}
        />
    );
};

export default observer(WithStoreConnection);
