import { chain } from 'lodash';
import { EnumType } from '../../../domain/attributes';
import { AOV_OPTIONS, MONTHLY_SPEND_OPTIONS } from '../trait';

// Facebook

export const FACEBOOK_OBJECTIVE_TYPE: EnumType = {
    kind: 'enum',
    members: [
        {
            label: 'App Promotion',
            value: 'app_promotion',
        },
        {
            label: 'Awareness',
            value: 'awareness',
        },
        {
            label: 'Engagement',
            value: 'engagement',
        },
        {
            label: 'Leads',
            value: 'leads',
        },
        {
            label: 'Sales',
            value: 'sales',
        },
        {
            label: 'Traffic',
            value: 'traffic',
        },
    ],
};

// Google

export const GOOGLE_NETWORK_TYPE: EnumType = {
    kind: 'enum',
    members: [
        {
            label: 'Content',
            value: 'CONTENT',
        },
        {
            label: 'Mixed',
            value: 'MIXED',
        },
        {
            label: 'Search',
            value: 'SEARCH',
        },
        {
            label: 'Search (Partner)',
            value: 'SEARCH_PARTNERS',
        },
        {
            label: 'YouTube (Search)',
            value: 'YOUTUBE_SEARCH',
        },
        {
            label: 'YouTube (Watch)',
            value: 'YOUTUBE_WATCH',
        },
    ],
};

// Tiktok

export const TIKTOK_OPTIMIZATION_GOAL_TYPE: EnumType = {
    kind: 'enum',
    members: [
        {
            label: 'Value',
            value: 'VALUE',
        },
        {
            label: 'Engaged View',
            value: 'ENGAGED_VIEW',
        },
        {
            label: 'Clicks',
            value: 'CLICK',
        },
        {
            label: 'Installs',
            value: 'INSTALL',
        },
        {
            label: 'Lead Generation',
            value: 'LEAD_GENERATION',
        },
        {
            label: 'Product Click in live',
            value: 'PRODUCT_CLICK_IN_LIVE',
        },
        {
            label: 'In-App Events',
            value: 'IN_APP_EVENT',
        },
        {
            label: 'Followers',
            value: 'FOLLOWERS',
        },
        {
            label: 'Video Views',
            value: 'VIDEO_VIEW',
        },
        {
            label: 'Convert',
            value: 'CONVERT',
        },
        {
            label: 'Profile Views',
            value: 'PROFILE_VIEWS',
        },
        {
            label: 'Traffic Landing Page Views',
            value: 'TRAFFIC_LANDING_PAGE_VIEW',
        },
        {
            label: 'Reach',
            value: 'REACH',
        },
        {
            label: 'MT Live Room',
            value: 'MT_LIVE_ROOM',
        },
        {
            label: 'Unknown',
            value: 'n/a',
        },
    ],
};

// Common

export const AOV_TYPE: EnumType = {
    kind: 'enum',
    members: Object.entries(AOV_OPTIONS).map(([key, { label }]) => ({
        value: key,
        label,
    })),
};

export const MONTHLY_SPEND_TYPE: EnumType = {
    kind: 'enum',
    members: Object.entries(MONTHLY_SPEND_OPTIONS).map(([key, { label }]) => ({
        value: key,
        label,
    })),
};

export const VERTICAL_TYPE: EnumType = {
    kind: 'enum',
    members: chain([
        { label: 'Activewear', value: 'activewear' },
        { label: 'Apparel', value: 'apparel' },
        { label: 'Consumer Electronics', value: 'consumer_electronics' },
        { label: 'Cosmetics', value: 'cosmetics' },
        { label: 'Cruelty-Free beauty', value: 'cruelty_free_beauty' },
        { label: 'Electronics', value: 'electronics' },
        { label: 'Fashion Accessories', value: 'fashion_accessories' },
        { label: 'Food', value: 'food' },
        {
            label: 'Food & Beverage Subscription',
            value: 'food_26_beverage_subscription',
        },
        { label: 'Furniture', value: 'furniture' },
        { label: 'Gifts', value: 'gifts' },
        { label: 'Hair Care', value: 'hair_care' },
        { label: 'Home Decor', value: 'home_decor' },
        { label: 'Jewelry', value: 'jewelry' },
        { label: 'Mens Apparel', value: 'mens_apparel' },
        { label: 'Natural Wellness Products', value: 'natural_wellness_products' },
        { label: 'Non-alcoholic Beverages', value: 'non_alcoholic_beverages' },
        { label: 'Nutrition Products', value: 'nutrition_products' },
        { label: 'Organic Food', value: 'organic_food' },
        { label: 'Personal Care', value: 'personal_care' },
        { label: 'Vitamin Supplements', value: 'vitamins_26_supplements' },
        { label: `Women's Skincare`, value: 'women_27s_skincare' },
        { label: `Women's apparel`, value: 'womens_apparel' },
        { label: 'B2B SaaS', value: 'b2b_saas' },
        { label: 'B2C SaaS', value: 'b2c_saas' },
        { label: 'Baby & Children', value: 'baby_26_children' },
        { label: 'Beauty', value: 'beauty' },
        { label: 'Fashion', value: 'fashion' },
        { label: 'Food & Beverage', value: 'food_26_beverage' },
        { label: 'Healthcare', value: 'healthcare' },
        { label: 'Home & Garden', value: 'home_26_garden' },
        { label: 'Offline services', value: 'offline_services' },
        { label: 'Other', value: 'other' },
        { label: 'Sports & Fitness', value: 'sports_26_fitness' },
        { label: 'Subscriptions', value: 'subscriptions' },
        { label: 'Wellness', value: 'wellness' },
    ])
        .orderBy((member) => member.label, 'asc')
        .value(),
};
