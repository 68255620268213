import { z } from 'zod';

// Item

export const ReportSchema = z.object({
    id: z.string(),
    kind: z.string(),
    name: z.nullable(z.string()),
    creator: z.number(),
    asset: z.number(),
    period_start_at: z.date({ coerce: true }),
    period_end_at: z.date({ coerce: true }),
    created_at: z.date({ coerce: true }),
});

export const ReportListSchema = z.object({
    data: z.array(ReportSchema),
});
