import React, { useMemo } from 'react';
import { getDescription } from '../../../views';
import {
    isEnumType,
    isMovementType,
    isMovementValue,
    isEnumValue,
} from '../../domain/attributes';
import { useDashboardApi } from '../../app';
import type { ReportTableController } from '../../view/_reportsold';
import { createCalculationFromResponse, isSuccessResponse } from '../../domain/query';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { RenderStrategyConfig } from './renderConfig';
import { useSystemContextV2 } from '../../context';
import { ViewPropertyDto } from 'src/v2/api';

export function createRendererStrategy(
    init: RenderStrategyConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        // this is a hack
        // we need to expose each metric, its type and label from a new endpoint that is not coupled to cards
        // const FORMAT: Record<string, string | undefined> = {
        //     roas: 'ROAS',
        //     cpp: 'CPP',
        //     revenue: 'Revenue',
        //     marketing_efficiency_ratio: 'MER',
        //     cost_per_purchase: 'Cost per purchase',
        //     cost_per_conversion: 'Cost per conversion',
        // };
        return create({
            ...config,
            module: {
                ...config.module,
                // createCohortModule(moduleConfig) {
                //     return config.module.createCohortModule({
                //         ...moduleConfig,
                //         provider: {
                //             ...moduleConfig.provider,
                //             createController(controllerConfig) {
                //                 const controller =
                //                     moduleConfig.provider.createController(
                //                         controllerConfig
                //                     );
                //                 return {
                //                     ...controller,
                //                     useProps(context, adapter, options) {
                //                         const props = controller.useProps(
                //                             context,
                //                             adapter,
                //                             options
                //                         );

                //                         const {
                //                             data: { views },
                //                         } = useSystemContextV2();

                //                         const columnsByKey = useMemo(() => {
                //                             return (
                //                                 views.data?.reduce(
                //                                     (acc, view) =>
                //                                         view.columns.reduce(
                //                                             (acc, column) => ({
                //                                                 ...acc,
                //                                                 [column.key]: column,
                //                                             }),
                //                                             acc
                //                                         ),
                //                                     {} as Record<
                //                                         string,
                //                                         ViewProperty | undefined
                //                                     >
                //                                 ) ?? {}
                //                             );
                //                         }, [views.data]);

                //                         // const dashboard = useDashboardApi();
                //                         // console.log(
                //                         //     'dashboard',
                //                         //     dashboard.getConfiguration()
                //                         // );
                //                         return {
                //                             ...props,
                //                             fields: {
                //                                 ...props.fields,
                //                                 comparison: {
                //                                     ...props.fields.comparison,
                //                                     options:
                //                                         props.fields.comparison.options.map(
                //                                             (item) => {
                //                                                 const column =
                //                                                     columnsByKey[
                //                                                         item.value
                //                                                     ];
                //                                                 if (!column) {
                //                                                     console.warn(
                //                                                         `column '${item.value}' not found for formatting. falling back to casing...`
                //                                                     );
                //                                                 }
                //                                                 return {
                //                                                     ...item,
                //                                                     label:
                //                                                         column?.name ??
                //                                                         FORMAT[
                //                                                             item.value
                //                                                         ] ??
                //                                                         item.label.toUpperCase(),
                //                                                 };
                //                                             }
                //                                         ),
                //                                 },
                //                             },
                //                         };
                //                     },
                //                 };
                //             },
                //         },
                //     });
                // },
                createReportModule(moduleConfig) {
                    return config.module.createReportModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createTableController() {
                                const original =
                                    moduleConfig.provider.createTableController();
                                return enhanceController(init, original);
                            },
                        },
                    });
                },
            },
        });
    };
}

function enhanceController(
    config: RenderStrategyConfig,
    controller: ReportTableController
): ReportTableController {
    const {
        components: {
            Tooltip,
            Movement: MovementComponent,
            Enum: EnumComponent,
            Calculation: CalculationComponent,
        },
    } = config;
    return {
        useProps(options) {
            const viewProps = controller.useProps(options);
            const [sampleResponse] = options.responses.filter(isSuccessResponse);

            const queriesAndSources = useMemo(
                () =>
                    options.responses.flatMap(
                        (response) =>
                            isSuccessResponse(response)
                                ? response.response.queries.flatMap((query) => ({
                                      source: response.request.source,
                                      query,
                                  }))
                                : [],
                        {}
                    ),
                [options]
            );

            return {
                ...viewProps,
                columns: viewProps.columns.map((column) => {
                    return {
                        ...column,
                        renderValue(value, row, index) {
                            const queryAndSource = queriesAndSources[index];
                            if (!queryAndSource) {
                                throw new Error(`query not found for row index ${index}`);
                            }

                            const { query } = queryAndSource;
                            const metricProperty = query.schema.properties['metric'];
                            const property = query.schema.properties?.[column.key];

                            let metricName: string =
                                (row['metric'] as string) ?? 'some metric';

                            if (metricProperty && isEnumType(metricProperty.type)) {
                                metricName =
                                    metricProperty.type.members.find(
                                        (member) => member.value === metricName
                                    )?.label ?? metricName;
                            }

                            if (isEnumType(property.type) && isEnumValue(value)) {
                                return (
                                    <EnumComponent type={property.type} value={value} />
                                );
                            }
                            if (
                                isMovementType(property.type) &&
                                property.type.base &&
                                isMovementValue(value)
                            ) {
                                const baseType = property.type.base;

                                const calculation = createCalculationFromResponse({
                                    segment:
                                        sampleResponse.request.segments?.find(
                                            (segment) => segment.name === property.name
                                        ) ?? null,
                                    request: sampleResponse.request,
                                    response: sampleResponse.response,
                                    property: {
                                        ...property,
                                        // TODO: this is a hack that assumes a property containing the pivoted metric is preset in the row
                                        name: metricName,
                                    },
                                    value,
                                });

                                const description = getDescription(
                                    (value, options) =>
                                        config.formatter.format(baseType, value, {
                                            notation: options?.format,
                                        }),
                                    calculation
                                );

                                return (
                                    <Tooltip
                                        label={
                                            <CalculationComponent
                                                {...calculation}
                                                description={description}
                                                formatPeriod={(period) =>
                                                    config.formatter.format(
                                                        'daterange',
                                                        {
                                                            from: period.start,
                                                            to: period.end,
                                                        },
                                                        { notation: 'long' }
                                                    )
                                                }
                                                formatValue={(value) =>
                                                    config.formatter.format(
                                                        baseType,
                                                        value,
                                                        {
                                                            notation: 'long',
                                                        }
                                                    )
                                                }
                                            />
                                        }
                                    >
                                        <MovementComponent
                                            style={{
                                                alignItems: 'flex-end',
                                            }}
                                            type={property.type}
                                            value={value}
                                            format={(type, value) =>
                                                config.formatter.format(type, value, {
                                                    notation: 'short',
                                                })
                                            }
                                        />
                                    </Tooltip>
                                );
                            }
                            return (
                                column.renderValue?.(value, row, index) ?? (
                                    <>not rendered</>
                                )
                            );
                        },
                    };
                }),
            };
        },
    };
}
