import { QueryFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { createPortal, PortalCreatePayload, PortalDto } from '../../../api';

export function createPortalHandler(
    context: ApplicationContext,
    payload: PortalCreatePayload
): QueryFunction<PortalDto> {
    return () => {
        return createPortal(context.api, payload);
    };
}
