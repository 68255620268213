import { MetricCollectionDto } from '../../../api';
import { PluginSlugs } from '../../../../config';
import { ViewIds } from '../../../../config/view';
import { InsightEntity } from '../../../domain/insights';

const CREATED_AT = new Date(2024, 3, 6);

const PERIOD_START = new Date(2024, 3, 6);
const PERIOD_END = new Date(2024, 4, 6);

export const MOCK_INSIGHTS: InsightEntity[] = [
    {
        id: '1',
        title: 'Overspending in application maintenance',
        kind: 'asd',
        body: 'Application maintenance represents ~25% of expense which is above the benchmark of top performing peers at ~15% to 20%',
        hint: null,
        status: 'info',
        confidence: 'medium',
        priority: 'medium',
        tags: [],
        period_start_at: PERIOD_START,
        period_end_at: PERIOD_END,
        comparison_start_at: new Date(),
        comparison_end_at: new Date(),
        definition: {
            id: '',
            name: '',
            plugin: PluginSlugs.IT_BENCHMARKS,
            view: ViewIds.IT_BENCHMARKS,
            category: 'opportunity',
            metrics: [
                {
                    key: 'it_spend',
                },
            ],
            version: 1,
            visualization: null,
            created_at: CREATED_AT,
        },
        created_at: CREATED_AT,
    },
    {
        id: '2',
        title: 'IT headcount above peers',
        kind: 'asd',
        body: 'Overall IT headcount at 10% of company headcount is well above best performing peers at 8%',
        hint: null,
        status: 'info',
        confidence: 'medium',
        priority: 'medium',
        tags: [],
        period_start_at: PERIOD_START,
        period_end_at: PERIOD_END,
        comparison_start_at: new Date(),
        comparison_end_at: new Date(),
        definition: {
            id: '',
            name: '',
            plugin: PluginSlugs.IT_BENCHMARKS,
            view: ViewIds.IT_BENCHMARKS,
            category: 'opportunity',
            metrics: [
                {
                    key: 'it_spend',
                },
            ],
            version: 1,
            visualization: null,
            created_at: CREATED_AT,
        },
        created_at: CREATED_AT,
    },
    {
        id: '3',
        title: 'Low percentage of staff at low-cost locations',
        kind: 'asd',
        body: 'Overall staff is ~15% at low-cost locations while best performing peers are at >20% and as high as ~40%',
        hint: null,
        status: 'info',
        confidence: 'medium',
        priority: 'medium',
        tags: [],
        period_start_at: PERIOD_START,
        period_end_at: PERIOD_END,
        comparison_start_at: new Date(),
        comparison_end_at: new Date(),
        definition: {
            id: '',
            name: '',
            plugin: PluginSlugs.IT_BENCHMARKS,
            view: ViewIds.IT_BENCHMARKS,
            category: 'opportunity',
            metrics: [
                {
                    key: 'it_spend',
                },
            ],
            version: 1,
            visualization: null,
            created_at: CREATED_AT,
        },
        created_at: CREATED_AT,
    },
];

export function createMockCollections(query: { asset: number }): MetricCollectionDto[] {
    return [
        {
            id: 1,
            default: true,
            name: 'default',
            metrics: [
                {
                    name: null,
                    definition: `${PluginSlugs.IT_BENCHMARKS}.it_spend`,
                },
            ],
            asset: query.asset,
            created_at: CREATED_AT,
            updated_at: CREATED_AT,
        },
    ];
}
