import { createContext, useContext } from 'react';
import { Organization, OrganizationReference, AuthenticationV2 } from '../../../domain';
import { OrganizationDependencies } from './organizationInterface';

export interface UserScope {
    auth: AuthenticationV2;
}

export interface OrganizationScope extends UserScope {
    organization: OrganizationReference;
}

export interface OrganizationScopeValue {
    organization: Pick<Organization, 'id'>;
    adapter: OrganizationDependencies;
}

export const OrganizationScopeContext = createContext<OrganizationScopeValue | null>(
    null
);

export const useOrganizationScope = () => {
    const context = useContext(OrganizationScopeContext);
    if (!context) {
        throw new Error(`organization context not available`);
    }
    return context;
};
