import { LegalControllerConfig } from '../../base';
import { TermsOfServiceController } from './termsOfServiceInterface';

export function createTermsOfServiceController(
    config: LegalControllerConfig
): TermsOfServiceController {
    const {
        kernel: {
            provider: { createPageController },
        },
    } = config;

    const pageController = createPageController({
        id: 'legal_terms_of_service',
    });

    return {
        useProps(props) {
            const page = pageController.useProps({}, {});

            return {};
        },
    };
}
