import { TraitFilterController } from './traitInterface';
import { TraitFilterViewProps } from './traitProps';
import { castPropertyToInputProps } from './traitCast';

export function createTraitController(): TraitFilterController {
    return {
        useProps(props): TraitFilterViewProps {
            return {
                items: props.plugin.traits,
                getPropertyInputProps(item) {
                    const mapped = castPropertyToInputProps(props, item);
                    return mapped;
                },
            };
        },
    };
}
