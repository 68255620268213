import { PeerSetDto } from '../../../api';
import { PeerSetAdapter, PeerSetEntity } from '../../../app';
import { PeerSetImplConfig } from './peerSetImplConfig';

export function createPeerSetImpl(config: PeerSetImplConfig): PeerSetAdapter {
    const { platform } = config;

    function toEntity(dto: PeerSetDto): PeerSetEntity {
        return {
            id: dto.id,
            name: dto.name,
            members: dto.members.map((item) => ({
                id: item.id,
                peerset: {
                    id: item.competitive_set,
                },
                asset: {
                    id: item.asset,
                },
            })),
            createdBy: {
                id: dto.creator,
            },
            createdAt: dto.created_at,
        };
    }

    return {
        async find(context, query) {
            const peersets = await platform.peerset.list(context, {
                asset: context.workspace.id as number,
            });
            return {
                limit: 0,
                total: peersets.data.length,
                items: peersets.data.map(toEntity),
            };
        },
        async lookup(context, query) {
            const response = await platform.peerset.lookup(context, query.id);
            return toEntity(response);
        },
        async create(context, props) {
            const response = await platform.peerset.create(context, {
                asset: context.workspace.id as number,
                name: props.name,
                members: props.members,
            });
            return toEntity(response);
        },
        async update(context, item, props) {
            const response = await platform.peerset.update(context, item.id, {
                name: props.name,
                members: props.members,
            });
            return toEntity(response);
        },
        async remove(context, entity) {
            const response = await platform.peerset.remove(context, entity.id);
            return;
        },
    };
}
