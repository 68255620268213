import React from 'react';
import { Box, HStack, StackProps } from '@chakra-ui/react';

export interface WorkspaceLayoutToolbarProps extends Pick<StackProps, 'bg' | 'px'> {
    children: React.ReactElement | Array<React.ReactElement | null>;
}

export const WorkspaceLayoutToolbar: React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceLayoutToolbarProps
> = (props) => <>{props.children}</>;
