import { MutationFunction } from '@tanstack/react-query';
import { UserIntegrationDefinition } from '../../../domain';
import { updatePreference } from '../../../api/integration';
import { IntegrationPreference } from '../../../models';
import { AssetContext } from '../..';

export function createIgnoreHandler(
    context: AssetContext,
    definition: UserIntegrationDefinition
): MutationFunction<IntegrationPreference, boolean> {
    return async (is_ignored) => {
        const preference = await updatePreference(context.api, definition.id, {
            is_ignored,
            asset_id: context.assetId,
        });
        return preference;
    };
}
