import { useMemo } from 'react';
import { isEqual } from 'lodash';
import { isRelativePeriod } from '../../../../../domain';
import { AnyCondition } from '../../../../domain/attributes';
import { castConditionToApi } from '../../../../impl';
import { useDashboardApi, useQueryApi, useRecommendationApi } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';
import { Tracker, UseTrackerHook } from '../../../../base';

export function createFilterAppliedTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useSegmentItem(...args) {
                    const tracker = config.deps.useTracker();
                    const api = {
                        dashboard: useDashboardApi(),
                        query: useQueryApi(),
                        recommendation: useRecommendationApi(),
                    };
                    const dashboard = api.dashboard.getDashboard();
                    const mapped = config.controller.useSegmentItem(...args);
                    const [, filter, index] = args;

                    const recommendations = api.recommendation.useRecommendations(
                        filter.property
                    );

                    const recommendedValues = useMemo(() => {
                        return new Set(recommendations.map((item) => item.object.id));
                    }, [recommendations]);

                    return {
                        ...mapped,
                        onChange(condition) {
                            const conditionMapped = castConditionToApi({
                                key: filter.property.key,
                                operator: filter.default.operator,
                                ...condition,
                            });
                            tracker.track(
                                'filter_applied',
                                // @ts-expect-error
                                {
                                    action: 'change',
                                    filter_type: conditionMapped.key,
                                    filter_value: conditionMapped.value,
                                    filter_operator: conditionMapped.operator,
                                    data_param: false,
                                    dashboard_id: dashboard.id,
                                    dashboard_name: dashboard.title,
                                    has_suggestion: Array.isArray(conditionMapped.value)
                                        ? conditionMapped.value.some((candidate) =>
                                              recommendedValues.has(candidate)
                                          )
                                        : false,
                                }
                            );
                            return mapped.onChange(condition);
                        },
                    };
                },
                useDateFilter() {
                    const tracker = config.deps.useTracker();
                    const api = { dashboard: useDashboardApi() };
                    const dashboard = api.dashboard.getDashboard();
                    const mapped = config.controller.useDateFilter();

                    return {
                        ...mapped,
                        onChange(values) {
                            if (isRelativePeriod(values.period)) {
                                values.period;
                            }
                            // const conditionMapped = castConditionToApi(values.period);
                            if (!isEqual(values.period, mapped.value.period)) {
                                tracker.track(
                                    'filter_applied',
                                    // @ts-expect-error
                                    {
                                        action: 'change',
                                        filter_type: 'date range',
                                        filter_value: isRelativePeriod(values.period)
                                            ? [
                                                  values.period.amount,
                                                  values.period.interval,
                                              ]
                                            : [values.period.start, values.period.end],
                                        filter_operator: isRelativePeriod(values.period)
                                            ? 'previous'
                                            : 'between',
                                        data_param: true,
                                        dashboard_id: dashboard.id,
                                        dashboard_name: dashboard.title,
                                    }
                                );
                            }
                            if (values.granularity !== mapped.value.granularity) {
                                tracker.track(
                                    'dashboard_conf_applied',
                                    // @ts-expect-error
                                    {
                                        conf_type: 'date_granularity',
                                        conf_value: values.granularity,
                                        dashboard_id: dashboard.id,
                                        dashboard_name: dashboard.title,
                                    }
                                );
                            }
                            return mapped.onChange(values);
                        },
                    };
                },
                useFilterItem(props) {
                    const tracker = config.deps.useTracker();
                    const api = { dashboard: useDashboardApi() };
                    const dashboard = api.dashboard.getDashboard();
                    const mapped = config.controller.useFilterItem(props);

                    function getPayload(
                        condition: AnyCondition,
                        action: 'change' | 'clear'
                    ) {
                        const mapped = castConditionToApi(condition);
                        return {
                            action,
                            filter_type: props.filter.property.name,
                            filter_value: mapped.value,
                            filter_operator: mapped.operator,
                            data_param: true,
                            dashboard_id: dashboard.id,
                            dashboard_name: dashboard.title,
                        };
                    }

                    return {
                        ...mapped,
                        onApply(condition) {
                            const payload = getPayload(
                                {
                                    key: mapped.filter.property.key,
                                    operator: mapped.filter.default?.operator ?? 'in',
                                    value: condition.value,
                                },
                                'change'
                            );
                            tracker.track(
                                'filter_applied',
                                // @ts-expect-error
                                payload
                            );
                            return mapped.onApply(condition);
                        },
                        onClear() {
                            if (mapped.current) {
                                const payload = getPayload(
                                    {
                                        key: mapped.filter.property.key,
                                        operator: mapped.filter.default?.operator ?? 'in',
                                        value: mapped.current.value,
                                    },
                                    'clear'
                                );
                                tracker.track(
                                    'filter_applied',
                                    // @ts-expect-error
                                    payload
                                );
                            }
                            return mapped.onClear();
                        },
                    };
                },
            },
        });
    };
}
