import React, { ReactElement, useCallback, useContext } from 'react';
import { Box, BoxProps, HStack, Icon, VStack } from '@chakra-ui/react';
import { assert } from '../../util/assert';
import { ChakraStepperComponents } from './chakraStepperInterface';
import { ChakraStepperStepProps } from './step';

type StepperStatus = 'complete' | 'active' | 'incomplete';

interface ChakraStepperContextValue {
    isChecked: boolean;
    status: StepperStatus;
    currentIndex: number;
    stepIndex: number;
    onChange(selected: number): void;
}

export function createChakraStepperComponents(): ChakraStepperComponents {
    const Context = React.createContext<ChakraStepperContextValue | null>(null);

    const instance: ChakraStepperComponents = {
        Container(props) {
            const { index: currentStepIndex, onChange: _, ...rest } = props;
            const onChange = useCallback(
                (selected: number) => {
                    return props.onChange(selected);
                },
                [props.onChange]
            );
            return (
                <VStack position="relative" align="start" {...rest}>
                    {React.Children.map(
                        props.children as ReactElement<ChakraStepperStepProps>[],
                        (child, stepIndex) => {
                            let status: StepperStatus = 'incomplete';
                            if (stepIndex < currentStepIndex) {
                                status = 'complete';
                            } else if (stepIndex === currentStepIndex) {
                                status = 'active';
                            }

                            if (child.props.isVisible === false) {
                                return <></>;
                            }

                            return (
                                <Context.Provider
                                    value={{
                                        status,
                                        onChange: onChange,
                                        stepIndex,
                                        currentIndex: props.index,
                                        isChecked: status === 'complete',
                                    }}
                                >
                                    {child}
                                </Context.Provider>
                            );
                        }
                    )}
                </VStack>
            );
        },
        Step(props) {
            const { children, _current, isChecked, ...stackProps } = props;
            const context = useContext(Context);
            assert(context, 'not inside stepper context');

            const isDisabled = context.currentIndex < context.stepIndex;

            // console.log('context.stepIndex isDisabled', context.stepIndex, isDisabled);
            const stepChildProps = {
                'aria-disabled': isDisabled,
                'aria-checked': isChecked,
                'aria-current': context.status === 'active',
            };

            const currentProps = context.status === 'active' ? _current : {};

            return (
                <HStack
                    cursor="pointer"
                    {...stepChildProps}
                    {...stackProps}
                    {
                        // these must override base stack props
                        ...currentProps
                    }
                    onClick={
                        isDisabled
                            ? undefined
                            : context.onChange.bind(null, context.stepIndex)
                    }
                    _disabled={{ cursor: 'default', ...stackProps._disabled }}
                >
                    {React.Children.map(children, (child: any) =>
                        React.cloneElement(child, {
                            ...stepChildProps,
                            ...child.props,
                        })
                    )}
                </HStack>
            );
        },
        Indicator(props) {
            const context = useContext(Context);
            assert(context, 'not inside stepper context');
            const { children, _current, ...boxProps } = props;
            const currentProps = context.status === 'active' ? _current : {};
            return (
                <Box aria-checked={context.isChecked} {...boxProps} {...currentProps}>
                    {props.children}
                </Box>
            );
        },
        Status(props) {
            const context = useContext(Context);
            assert(context, 'not inside stepper context');
            if (context.status === 'incomplete') {
                return React.cloneElement(props.incomplete, {
                    ...props.incomplete.props,
                    ...props,
                });
            }
            if (context.status === 'active') {
                return React.cloneElement(props.active, {
                    ...props.active.props,
                    ...props,
                });
            }
            return React.cloneElement(props.complete, {
                ...props.complete.props,
                ...props,
            });
        },
        Icon(props) {
            const context = useContext(Context);
            assert(context, 'not inside stepper context');
            return <Icon aria-checked={context.isChecked} {...props} />;
        },
        Divider(props) {
            const { ...boxProps } = props;
            return <></>;
        },
    };
    return instance;
}
