import { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import React from 'react';
import { ButtonBadge } from '../badge';
import { ButtonIndicator } from '../indicator';

/**
 * Pops any internal badge/indicator components from the component child array
 * @param children
 * @returns
 */
export function popInternal(children?: ChakraButtonProps['children']): [React.ReactElement | null, React.ReactChild[]] {
    const childrenArray = React.Children.toArray(children);

    const badgeChild =
        // @ts-expect-error
        childrenArray.find((child) => child.type === ButtonBadge) ?? null;

    const indicatorChild =
        // @ts-expect-error
        childrenArray.find((child) => child.type === ButtonIndicator) ?? null;

    // @ts-expect-error
    const restChildren = childrenArray.filter((child) => child.type !== ButtonIndicator);

    // @ts-expect-error
    return [badgeChild ?? indicatorChild ?? null, restChildren];
}
