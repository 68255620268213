import { useMemo } from 'react';
import { useOrganizationContextV2 } from '../../../context';
import {
    AnyOnboardingOrganizationStep,
    OrganizationOnboardingState,
} from '../../../domain/onboarding';
import { OnboardingOrganizationStateServiceConfig } from './organizationConfig';
import { OnboardingOrganizationStateService } from './organizationInterface';

export function createOnboardingOrganizationStateService(
    config: OnboardingOrganizationStateServiceConfig
): OnboardingOrganizationStateService {
    return {
        useLookup(context) {
            const {
                data: { workspaces },
            } = useOrganizationContextV2();

            const onboardingstate = useMemo((): OrganizationOnboardingState => {
                let steps: AnyOnboardingOrganizationStep[] = [];
                if (workspaces.length === 0) {
                    steps = [
                        ...steps,
                        {
                            id: 'integration',
                            slug: 'integration',
                            status: 'force',
                            config: null,
                        },
                        {
                            id: 'asset',
                            slug: 'asset',
                            status: 'force',
                            config: null,
                        },
                    ];
                }
                return { scope: 'organization', steps };
            }, [workspaces]);

            return {
                status: 'loaded',
                data: onboardingstate,
            };
        },
    };
}
