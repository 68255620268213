import pluralize from 'pluralize';
import { toVisualization } from '../../../../app/visualizations';
import { getAnalysisMetricLink } from '../../../../route';
import { isLoadedCollection } from '../../../../base';
import { OpportunityCollectionConfig } from './collectionConfig';
import { OpportunityCollectionController } from './collectionInterface';

export function createOpportunityCollectionController(
    config: OpportunityCollectionConfig
): OpportunityCollectionController {
    const {
        strategy,
        service,
        infra: { formatter },
    } = config;
    return {
        useProps(context, queries, props) {
            return {
                collection: queries.items,
                getActionProps() {
                    return [];
                },
                getCollectionLabel() {
                    if (!isLoadedCollection(queries.items)) {
                        return null;
                    }
                    const pluralized = pluralize('opportunity', queries.items.data.total);
                    if (queries.items.data.items.length === queries.items.data.limit) {
                        return `${queries.items.data.total}+ ${pluralized} found`;
                    } else {
                        return `${queries.items.data.total} ${pluralized} found`;
                    }
                },
                getStatusProps() {
                    return null;
                },
                getRouteProps(item) {
                    const visualization = toVisualization(
                        strategy.visualizable,
                        item.insight
                    );
                    return {
                        label: 'Open',
                        path: visualization
                            ? getAnalysisMetricLink(context, visualization)
                            : null,
                    };
                },
                getDateProps(item) {
                    const timeago = formatter.format('date', item.insight.period_end_at, {
                        relative: true,
                    });
                    const range = formatter.format('daterange', {
                        from: item.insight.period_start_at,
                        to: item.insight.period_end_at,
                    });
                    return {
                        label: `${timeago}`,
                        description: `Insight was detected ${timeago} and is based on data for the period ${range}`,
                    };
                },
            };
        },
    };
}
