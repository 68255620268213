import { ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    useToken,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { GoOrganization } from 'react-icons/go';
import React from 'react';
import { PeerViewConfig } from '../../base';
import { CompanyItemViewProps } from '../../../../view/companies';

export function createPeerCompanyCardView(
    config: PeerViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & CompanyItemViewProps> {
    const {
        UI: {
            Application: { Anchor },
        },
    } = config;
    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <VStack align="start" w="full" spacing={3}>
                <HStack spacing={12} w="full" justify="space-between" align="start">
                    <HStack spacing={4} w="full">
                        {props.iconUrl && (
                            <Box flexShrink={0}>
                                <Image
                                    borderRadius="sm"
                                    w={10}
                                    h={10}
                                    src={props.iconUrl}
                                />
                            </Box>
                        )}
                        {!props.iconUrl && (
                            <Box
                                p={2.5}
                                bg="whiteAlpha.300"
                                borderRadius="sm"
                                flexShrink={0}
                            >
                                <Icon w={5} h={5} as={GoOrganization} />
                            </Box>
                        )}
                        <VStack align="start" w="full" spacing={0}>
                            <Text
                                fontWeight="semibold"
                                noOfLines={1}
                                wordBreak="break-word"
                            >
                                {props.name}
                            </Text>
                            <Anchor
                                {...props.anchor.website}
                                _hover={{
                                    textDecor: 'underline',
                                    textDecorationColor: 'whiteAlpha.700',
                                }}
                            >
                                <Text
                                    fontWeight="medium"
                                    color="whiteAlpha.700"
                                    cursor="pointer"
                                >
                                    {props.domain}
                                </Text>
                            </Anchor>
                        </VStack>
                    </HStack>
                    <Text
                        textTransform="uppercase"
                        fontSize="xs"
                        fontWeight="semibold"
                        color="whiteAlpha.600"
                        letterSpacing="wider"
                    >
                        Company
                    </Text>
                </HStack>
                {props.industry && (
                    <VStack spacing={1} w="full" align="start">
                        <Wrap shouldWrapChildren={true}>
                            <Tag size="sm" borderRadius="sm" colorScheme="blue">
                                {props.industry}
                            </Tag>
                        </Wrap>
                    </VStack>
                )}
                {props.description && (
                    <Box
                        w="full"
                        maxH="15vh"
                        overflowY="auto"
                        css={{
                            '&::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '0.5rem',
                            },
                            '&::-webkit-scrollbar-track': {},
                            '&::-webkit-scrollbar-corner': {
                                '-webkit-appearance': 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '1rem',
                                background: scrollbarBg,
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: scrollbarHoverBg,
                            },
                        }}
                    >
                        <Text color="whiteAlpha.700" wordBreak="break-word">
                            {props.description}
                        </Text>
                    </Box>
                )}
                {!props.description && (
                    <Text color="whiteAlpha.600" fontStyle="italic">
                        No description
                    </Text>
                )}
                <Wrap
                    // divider={<Box border="none">&#x2022;</Box>}
                    spacingX={3}
                    spacingY={1}
                    color="whiteAlpha.800"
                    fontSize="sm"
                    fontWeight="md"
                    whiteSpace="nowrap"
                >
                    {props.employees && (
                        <WrapItem>
                            <Text>{props.employees} employees</Text>
                        </WrapItem>
                    )}
                    {props.revenue && (
                        <WrapItem>
                            <Text>{props.revenue} revenue</Text>
                        </WrapItem>
                    )}
                    {props.visitors && (
                        <WrapItem>
                            <Text>{props.visitors} monthly visitors</Text>
                        </WrapItem>
                    )}
                </Wrap>
            </VStack>
        );
    };
}
