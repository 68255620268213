import { compose } from 'lodash/fp';
import { DashboardRouteEnhancer } from './dashboardInterface';
import { ApplicationEntryEnhancer } from '../applicationMiddleware';

export function applyDashboardEnhancers(
    ...enhancers: DashboardRouteEnhancer[]
): ApplicationEntryEnhancer {
    const enhancer: DashboardRouteEnhancer = compose(...enhancers);
    return (create) => (config) => {
        // NOTE this is a hack, we should refactor the route
        // dependencies to the top-level so that
        // the dashboard enhancers can access it normally
        const useTracker = config.infra.createUseTracker();
        const instance = create({
            ...config,
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    const enhanced = enhancer(config.route.createDashboardRoute);
                    const route = enhanced({ ...routeConfig, deps: { useTracker } });
                    return route;
                },
                // dashboard: {
                //     config: {
                //         ...config.route.dashboard.config,
                //         // @ts-expect-error
                //         deps: { useTracker },
                //     },
                //     create: enhancer(config.route.dashboard.create),
                // },
            },
        });
        return instance;
    };
}
