import React, { useCallback, useMemo, useState } from 'react';
import {
    Button,
    Icon,
    Box,
    TextProps,
    StackProps,
    Text,
    Checkbox,
    VStack,
    HStack,
    CheckboxProps,
    ButtonProps,
    useCheckboxGroup,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useCollapseList, useTreeSelect } from '../../../../../util';
import { BaseProps } from '../base';

export interface TreeInputNestedOption {
    label: string;
    value: string | number;
    icons?: React.ReactElement[];
    disabled?: boolean;
    allowClickWhenDisabled?: boolean;
}

export interface TreeInputOption extends TreeInputNestedOption {
    children: TreeInputNestedOption[];
}

export type AnyInputOption = TreeInputOption | TreeInputNestedOption;

export interface TreeInputProps extends BaseProps {
    theme?: {
        item?: {
            label?: Omit<TextProps, 'children'>;
        };
        caption?: {
            label?: Omit<TextProps, 'children'>;
        };
        more?: {
            label?: Omit<ButtonProps, 'children'>;
        };
    };
    type: 'tree';
    options: TreeInputOption[];
    value?: Array<string | number>;
    onChange(value: Array<string | number>): void;
    getCaption?(config: { isExpanded: boolean }): React.ReactNode;
    truncateCount?: number;
    showSelectAll?: boolean;
}

export const TreeInput: React.FC<
    { children?: React.ReactNode | undefined } & TreeInputProps
> = (props) => {
    const {
        theme = {
            item: {
                label: { fontSize: 'sm', color: 'whiteAlpha.700' },
            },
            caption: {
                label: {
                    color: 'whiteAlpha.500',
                },
            },
            more: {
                label: {
                    color: 'whiteAlpha.700',
                },
            },
        },
    } = props;

    const collapse = useCollapseList({
        items: props.options,
        maxItems: props.truncateCount ?? 1000,
        renderItem() {
            return <div></div>;
        },
    });

    const tree = useTreeSelect<AnyInputOption>({
        value: props.value ?? [],
        options: props.options,
        onChange(value) {
            props.onChange(value);
        },
        getValue(item) {
            return item.value;
        },
        getChildren(item) {
            if (Array.isArray((item as TreeInputOption).children)) {
                return (item as TreeInputOption).children;
            }
            return null;
        },
    });

    const treeItems = tree.getItems();

    // console.log('tree value', JSON.stringify(tree.value, null, 2));

    const caption = props.getCaption?.(collapse) ?? null;

    const selected = useMemo(() => new Set(props.value), [props.value]);

    const isAllChecked = useMemo(
        () => treeItems.every((item) => selected.has(item.data.value)),
        [treeItems, selected]
    );

    const isSomeChecked = useMemo(
        () => treeItems.some((item) => selected.has(item.data.value)),
        [treeItems, selected]
    );

    const isIndeterminate = useMemo(() => {
        if (isAllChecked) {
            return false;
        }
        if (isSomeChecked) {
            return true;
        }
        return false;
    }, [isAllChecked, isSomeChecked]);

    const handleAllToggle = useCallback(() => {
        if (isAllChecked || isSomeChecked) {
            props.onChange([]);
            return;
        }

        props.onChange(treeItems.map((item) => item.data.value));
    }, [isAllChecked, isSomeChecked, treeItems]);

    return (
        <VStack alignItems="flex-start" spacing={3} w="full">
            <VStack alignItems="flex-start" spacing={3} w="full">
                {props.showSelectAll && (
                    <HStack w="full" justify="space-between" onClick={handleAllToggle}>
                        <Checkbox
                            cursor="pointer"
                            pointerEvents="none"
                            isChecked={isAllChecked}
                            isIndeterminate={isIndeterminate}
                        />
                        <Text
                            cursor="pointer"
                            flex={1}
                            maxW="full"
                            fontWeight="bold"
                            userSelect="none"
                            {...theme.caption?.label}
                            fontSize="xs"
                            textTransform="uppercase"
                            color="whiteAlpha.500"
                        >
                            Select all
                        </Text>
                    </HStack>
                )}
                {collapse.getVisible().map((option, index) => {
                    const treeItem = treeItems[index];
                    return (
                        // @ts-expect-error
                        <TreeInputItem
                            key={option.value}
                            style={{
                                label: theme.item?.label,
                                // label: {
                                //     fontSize: 'sm',
                                //     color: 'whiteAlpha.700',
                                // },
                            }}
                            label={option.label}
                            input={treeItem.getInputProps()}
                            onClick={treeItem.getButtonProps().onClick}
                            disabled={option.disabled}
                            icons={option.icons}
                            allowClickWhenDisabled={option.allowClickWhenDisabled}
                        >
                            {treeItem.getChildren()?.map((child) => (
                                // @ts-expect-error
                                <TreeInputItem
                                    key={child.data.value}
                                    style={{
                                        wrapper: {
                                            pl: 6,
                                        },
                                        label: theme.item?.label,
                                        // label: {
                                        //     fontSize: 'sm',
                                        //     color: 'whiteAlpha.700',
                                        // },
                                    }}
                                    input={child.getInputProps()}
                                    onClick={child.getButtonProps().onClick}
                                    label={child.data.label}
                                    disabled={child.data.disabled}
                                    icons={child.data.icons}
                                    allowClickWhenDisabled={
                                        child.data.allowClickWhenDisabled
                                    }
                                />
                            ))}
                        </TreeInputItem>
                    );
                })}
                {caption ? (
                    <Box {...theme.caption?.label} fontWeight="medium" fontSize="sm">
                        {caption}
                    </Box>
                ) : null}
            </VStack>
            {(collapse.hiddenCount > 0 ||
                (collapse.hiddenCount > 0 && tree.value.length > 0)) && (
                <HStack w="full" spacing={2} justifyContent="space-between">
                    {collapse.hiddenCount > 0 && (
                        <Button
                            {...theme.more?.label}
                            // color="whiteAlpha.900"
                            size="sm"
                            variant="link"
                            {...collapse.getTriggerProps()}
                            _focus={{ outline: 'none' }}
                        >
                            {collapse.isExpanded
                                ? `Show less`
                                : `Show ${collapse.hiddenCount} hidden`}
                        </Button>
                    )}
                    <Text
                        ml="auto"
                        position="relative"
                        // align with underline of sibling button
                        top="0.5px"
                        visibility={
                            collapse.hiddenCount > 0 && tree.value.length > 0
                                ? 'visible'
                                : 'hidden'
                        }
                        fontWeight="semibold"
                        fontSize="sm"
                        colorScheme="varosGreen"
                    >
                        {tree.value.length} selected
                    </Text>
                </HStack>
            )}
        </VStack>
    );
};

const TreeInputItem: React.FC<
    { children?: React.ReactNode | undefined } & TreeInputOption & {
            style?: {
                wrapper?: Omit<StackProps, 'children'>;
                label?: Omit<TextProps, 'children'>;
            };
            children: React.ReactElement[];
            input: CheckboxProps;
            onClick?: React.MouseEventHandler;
        }
> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const actualDisabled = !props.allowClickWhenDisabled && props.disabled;
    return (
        <VStack
            {...props.style?.wrapper}
            w="full"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <HStack
                w="full"
                spacing={props.children ? 2 : 4}
                opacity={props.disabled ? 0.5 : 'inherit'}
            >
                <Checkbox {...props.input} disabled={actualDisabled} />
                {props.children?.length > 0 && (
                    <ChevronRightIcon
                        color={props.style?.label?.color}
                        cursor="pointer"
                        transform={open ? 'rotate(90deg)' : undefined}
                        onClick={setOpen.bind(null, !open)}
                    />
                )}
                <Text
                    {...props.style?.label}
                    onClick={props.onClick}
                    cursor={typeof props.onClick === 'function' ? 'pointer' : 'inherit'}
                    flex={1}
                    maxW="full"
                    // noOfLines={1}
                    fontWeight="medium"
                    userSelect="none"
                >
                    {props.label}
                </Text>
                {props.icons && (
                    <HStack spacing={2}>
                        {props.icons.map((icon, index) => (
                            <React.Fragment key={index}>{icon}</React.Fragment>
                        ))}
                    </HStack>
                )}
            </HStack>
            {open ? <VStack spacing={3}>{props.children}</VStack> : null}
        </VStack>
    );
};
