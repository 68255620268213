import { useMemo } from 'react';
import { resolveView } from '../../../../domain';
import { isReferenceProperty } from '../../../../domain/attributes';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { FilterLoader } from './filterInterface';

export function createFilterLoader(config: AnalysisRouteDeps): FilterLoader {
    const {
        hook: { useQuery },
        repository: { member: repository },
    } = config;
    return {
        useData(props) {
            throw new Error('not impl');
            // const dimensions = useMemo(
            //     () =>
            //         props.view.columns
            //             .filter(isReferenceProperty)
            //             .map((item) => item.type),
            //     [props.view.columns]
            // );

            // const members = repository.useFind(
            //     props.context,
            //     {
            //         types: dimensions,
            //     },
            //     { suspense: true, staleTime: Infinity }
            // );

            // const view = useMemo(
            //     () => resolveView(props.view, members.data ?? []),
            //     [props.view, members.data]
            // );

            // return {
            //     view,
            // };
        },
    };
}
