import { DashboardSlugs } from '../dashboard';

export const PluginSlugs = {
    FACEBOOKADS: 'facebookads',
    GOOGLEADS: 'googleads',
    TIKTOKADS: 'tiktokads',
    SHOPIFY: 'shopify',
    EXECUTIVE_SUMMARY: 'executive-summary',
    GOOGLE_ANALYTICS: 'googleanalytics',
    LINKEDIN: 'linkedin',
    IT_BENCHMARKS: 'it_benchmarks',
    VAROSTEST: 'varostest',
    MEDIA_MIX: 'media-mix',
} as const;

export const PluginsByDashboard = {
    [DashboardSlugs.SHOPIFY_V2]: PluginSlugs.SHOPIFY,
    [DashboardSlugs.SHOPIFY_LTV_V2]: PluginSlugs.SHOPIFY,
    [DashboardSlugs.FACEBOOK_V2]: PluginSlugs.FACEBOOKADS,
    [DashboardSlugs.FACEBOOK_BREAKDOWN]: PluginSlugs.FACEBOOKADS,
    [DashboardSlugs.FACEBOOK_VIDEO]: PluginSlugs.FACEBOOKADS,
    [DashboardSlugs.TIKTOK_V2]: PluginSlugs.TIKTOKADS,
    [DashboardSlugs.GOOGLE_V2]: PluginSlugs.GOOGLEADS,
    [DashboardSlugs.EXECUTIVE_SUMMARY]: PluginSlugs.EXECUTIVE_SUMMARY,
    [DashboardSlugs.GA4_ECOMMERCE]: PluginSlugs.GOOGLE_ANALYTICS,
    [DashboardSlugs.GA4_REVENUE_BREAKDOWN]: PluginSlugs.GOOGLE_ANALYTICS,
    [DashboardSlugs.GA4_TRAFFIC]: PluginSlugs.GOOGLE_ANALYTICS,
    [DashboardSlugs.LINKEDIN_ADS_V2]: PluginSlugs.LINKEDIN,
    [DashboardSlugs.IT_BENCHMARKS_PERFORMANCE]: PluginSlugs.IT_BENCHMARKS,
} as const;

export const DashboardsByPlugin: Record<string, string | undefined> = {
    [PluginSlugs.FACEBOOKADS]: DashboardSlugs.FACEBOOK_V2,
    [PluginSlugs.GOOGLEADS]: DashboardSlugs.GOOGLE_V2,
    [PluginSlugs.TIKTOKADS]: DashboardSlugs.TIKTOK_V2,
    [PluginSlugs.GOOGLE_ANALYTICS]: DashboardSlugs.GA4_ECOMMERCE,
    [PluginSlugs.SHOPIFY]: DashboardSlugs.SHOPIFY_V2,
    [PluginSlugs.EXECUTIVE_SUMMARY]: DashboardSlugs.EXECUTIVE_SUMMARY,
    [PluginSlugs.LINKEDIN]: DashboardSlugs.LINKEDIN_ADS_V2,
    [PluginSlugs.VAROSTEST]: DashboardSlugs.FACEBOOK_V2,
    [PluginSlugs.IT_BENCHMARKS]: DashboardSlugs.IT_BENCHMARKS_PERFORMANCE,
    [PluginSlugs.MEDIA_MIX]: DashboardSlugs.MEDIA_MIX,
};
