import { assert } from '../../../util/assert';
import { HomeGuideStrategyConfig } from './homeGuideConfig';
import { HomeGuideLoader } from './homeGuideInterface';

export function createHomeGuideLoader(init: HomeGuideStrategyConfig): HomeGuideLoader {
    const {
        repository: {
            asset: assetRepository,
            account: { profile: profileRepository },
        },
    } = init;
    return {
        useLoad(context) {
            const profileQuery = profileRepository.useLookup(context, { suspense: true });
            const assetQuery = assetRepository.useFind(context, {}, { suspense: true });
            assert(profileQuery.status === 'success', 'expected suspense');
            assert(assetQuery.status === 'success', 'expected suspense');
            return {
                items: init.items.map((item, index) => {
                    const url = new URL(item.url);
                    url.searchParams.set('hideEmbedTopBar', 'true');
                    url.searchParams.set('hide_share', 'true');
                    url.searchParams.set('hide_title', 'true');
                    url.searchParams.set('hide_owner', 'true');
                    url.searchParams.set('autoplay', 'false');
                    const paramString = url.searchParams.toString();
                    return {
                        ...item,
                        url: `${item.url}${paramString}`,
                    };
                }),
                profile: profileQuery.data,
                assets: assetQuery.data,
            };
        },
    };
}
