import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import StoreBase from '../../../stores/StoreBase';
import { PaywallStateProvider, PaywallStore } from './paywallStateInterface';
import { PaywallStateValue } from './paywallStateModel';

class StoreImpl extends StoreBase implements PaywallStore {
    state: PaywallStateValue;

    constructor() {
        super();
        this.state = { featureId: null };
        makeObservable(this, {
            state: observable,
        });
    }

    getState() {
        return this.state;
    }

    setState(value: PaywallStateValue) {
        runInAction(() => {
            this.state = value;
        });
    }
}

const store = new StoreImpl();

// HACK using singleton to interface with legacy code not using DI
export function createPaywallStore() {
    return store;
}

export function createPaywallStateProvider(store: PaywallStore): PaywallStateProvider {
    return {
        useState() {
            return {
                value: store.getState(),
                onChange(value) {
                    store.setState(value);
                },
            };
        },
        connect(Component) {
            return observer(Component);
        },
    };
}
