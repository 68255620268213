import {
    QueryKey,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { ApplicationRepository, ServiceUnavailableError } from '../../base';

export function createRepositoryImpl(): ApplicationRepository {
    const maxRetryAttempts = 4;

    function useQueryCustom<
        TQueryFnData = unknown,
        TError = unknown,
        TData = TQueryFnData,
        TQueryKey extends QueryKey = QueryKey,
    >(
        options: Omit<
            UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
            'initialData'
        > & { initialData?: () => undefined }
    ): UseQueryResult<TData, TError> {
        return useQuery({
            retry(failureCount, error) {
                if (failureCount >= maxRetryAttempts - 1) {
                    return false;
                }
                if (error instanceof ServiceUnavailableError) {
                    console.info(
                        `service unavailable, retrying query #${failureCount + 1}...`
                    );
                    return true;
                }
                return false;
            },
            ...options,
        });
    }

    function useMutationCustom<
        TData = unknown,
        TError = unknown,
        TVariables = void,
        TContext = unknown,
    >(
        options: UseMutationOptions<TData, TError, TVariables, TContext>
    ): UseMutationResult<TData, TError, TVariables, TContext> {
        return useMutation({
            retry(failureCount, error) {
                if (failureCount >= maxRetryAttempts - 1) {
                    return false;
                }
                if (error instanceof ServiceUnavailableError) {
                    console.info(
                        `service unavailable, retrying mutation #${failureCount + 1}...`
                    );
                    return true;
                }
                return false;
            },
            ...options,
        });
    }

    return {
        // @ts-expect-error
        useQuery: useQueryCustom,
        useMutation: useMutationCustom,
    };
}
