import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import { VarosRoutes } from './infra';
import WithAllAppWrappers, { WithMobx } from './wrappers';
import { WithReactQuery } from './wrappers/WithReactQuery';
import { ApplicationRouter } from './sections/dashboard/ApplicationRouter';
import { configureDemoEntrypoint, configureEmailEntrypoint } from './v2';
import { WithRouterForApp } from './wrappers/WithRouter';

const TemplateEntrypoint = configureEmailEntrypoint();
const DemoEntrypoint = configureDemoEntrypoint();

function App() {
    return (
        <WithRouterForApp>
            <VarosRoutes>
                <Route
                    path="template"
                    element={
                        <WithReactQuery>
                            <Outlet />
                        </WithReactQuery>
                    }
                >
                    {TemplateEntrypoint}
                </Route>
                <Route
                    path="tools"
                    element={
                        <WithAllAppWrappers>
                            <Outlet />
                        </WithAllAppWrappers>
                    }
                >
                    {DemoEntrypoint}
                </Route>
                <Route
                    path="*"
                    element={
                        <WithAllAppWrappers>
                            <ApplicationRouter />
                        </WithAllAppWrappers>
                    }
                />
            </VarosRoutes>
        </WithRouterForApp>
    );
}

export default App;
