import { z } from 'zod';

const MIN_MEMBER_COUNT = 5;
const MAX_MEMBER_COUNT = 150;

export const PeerGroupCreateFormValueSchema = z.object({
    companies: z
        .array(z.string())
        .min(MIN_MEMBER_COUNT, {
            message: `A group must have at least ${MIN_MEMBER_COUNT} companies`,
        })
        .max(MAX_MEMBER_COUNT, {
            message: `A group must have at most ${MAX_MEMBER_COUNT} companies`,
        }),
});
