import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    CardAggregatedLineVisualization,
    isAggregatedLineVisualization,
    isTypeReference,
} from '../../../../api/v2';
import { getStatusDetail } from '../../../../hooks/account-status/helpers';
import { createExecuteQueryOptions } from '..';
import {
    DashboardConfiguration,
    Dashboard,
    Card,
    CompositionSection,
    AnyFilter,
    Composition,
    AnyDashboardIssue,
    DashboardDateConfiguration,
} from '../../../domain/dashboard';
import { QueryRequest, QueryResponse, ViewEntity } from '../../../domain/query';
import { castPeriodToGranularity, Comparison } from '../../../domain/query';
import {
    TimeValue,
    isDateRangeValue,
    DateRangeValue,
    Property,
    AnyResolvedType,
    isCurrencyProperty,
    isTreeProperty,
    isEnumLikeProperty,
} from '../../../domain/attributes';
import { useWorkspaceApi } from '../workspace';
import { useDashboardContext } from './dashboardContext';
import { createGetDashboardConfigurationQuery } from '../configuration';
import { useOrganizationScope } from '../../platform';
import { useStore } from '../../../../stores/setupContext';
import {
    useOrganizationContextV2,
    useSystemContextV2,
    useWorkspaceContextV2,
} from '../../../context';

function getGranularity(value: TimeValue) {
    if (isDateRangeValue(value)) {
        if (!value.to) {
            throw new Error(`unexpected partial date range`);
        }
        return castPeriodToGranularity({
            start: value.from,
            end: value.to,
        });
    }
    return castPeriodToGranularity(value);
}

export interface DashboardApi {
    getKey(): string;
    getDashboard(): Dashboard;
    getConfiguration(): DashboardConfiguration;
    getDateConfiguration(): DashboardDateConfiguration | null;
    getComparison(): Comparison | null;
    getFilters(): AnyFilter[];
    getTraits(): AnyFilter[];
    getCards(): Card[];
    getComposition(card: Card): Composition;
    useCardQuery(card: Card, query: QueryRequest): QueryResponse;
    useIssue(): AnyDashboardIssue | null;
}

export const useDashboardApi = (): DashboardApi => {
    const { workspace, dashboard, adapter } = useDashboardContext();

    const { auth } = useStore();
    const apis = {
        organization: useOrganizationScope(),
        workspace: useWorkspaceApi(),
    };

    const {
        data: {
            views: { data = [] },
        },
    } = useSystemContextV2();

    const {
        data: { workspaces },
    } = useOrganizationContextV2();

    const {
        plugins: { data: plugins },
    } = useWorkspaceContextV2();

    const { data: configuration } = useQuery({
        queryKey: ['workspace', workspace.id, 'configuration', dashboard.id],
        queryFn: createGetDashboardConfigurationQuery(
            adapter.configuration,
            data,
            workspace,
            dashboard.id
        ),
        suspense: true,
        staleTime: Infinity,
        retry: false,
    });

    if (!configuration) {
        throw new Error(`configuration '${dashboard.id}' not found`);
    }

    const plugin = useMemo(
        () => plugins?.find((candidate) => candidate.id === dashboard.pluginId),
        [plugins, dashboard.pluginId]
    );

    const traitByKey = useMemo(() => {
        return (
            plugin?.traits.reduce(
                (acc, item) => ({ ...acc, [item.key]: item }),
                {} as Record<string, Property<AnyResolvedType> | undefined>
            ) ?? {}
        );
    }, [plugins]);

    const traitListProps = useMemo(
        () => [
            ...configuration.properties.traits
                .filter((trait) => {
                    if (trait.kind !== 'asset') {
                        return true;
                    }
                    // only show asset trait option when more than one asset is available
                    return workspaces.filter((workspace) => workspace.visible).length > 1;
                })
                .map((trait): AnyFilter => {
                    // HACK temporary hack because the isDisabled flag is set
                    // on the plugin repository but the dashboard logic is stil based
                    // on the old dashboard configuration model and adapter
                    // @ts-expect-error
                    return {
                        ...trait,
                        property: {
                            ...trait.property,
                            isDisabled:
                                traitByKey[trait.property.key]?.isDisabled ??
                                trait.property.isDisabled,
                        },
                    };
                }),
        ],
        [configuration.properties.traits, workspaces, traitByKey]
    );

    const dateConfigurationProps = useMemo((): DashboardDateConfiguration | null => {
        if (!configuration.properties.timestamp) {
            return null;
        }
        if (!configuration.defaults.granularity) {
            return null;
        }
        return {
            filter: configuration.properties.timestamp,
            // granularity: configuration.defaults.granularity,
            granularity: (configuration.properties.timestamp.default
                ?.value as DateRangeValue)
                ? getGranularity(
                      configuration.properties.timestamp.default.value as DateRangeValue
                  )
                : configuration.defaults.granularity,
            comparison: configuration.defaults.comparison,
        };
    }, [configuration]);

    return {
        useCardQuery(card, query) {
            let exampleMode: ViewEntity['example_mode'] | null = null;
            if (apis.workspace.inExampleMode()) {
                const view = data.find((x) => x.source === query.source.view);
                exampleMode = view?.example_mode || 'generate';
            }
            const { data: queryResponse } = useQuery(
                createExecuteQueryOptions(
                    adapter.chart,
                    apis.workspace.getWorkspace(),
                    dashboard,
                    card,
                    query,
                    exampleMode
                )
            );
            if (!queryResponse) {
                throw new Error('query result not found');
            }
            return queryResponse;
        },
        useIssue() {
            const queryAccountStatus = useQuery({
                queryKey: ['account-status', workspace.id, dashboard.id],
                async queryFn() {
                    const response = await adapter.dashboard.getStatus(
                        {
                            auth: { user: null, scheme: { kind: 'legacy', store: auth } },
                            organization: apis.organization.organization,
                        },
                        workspace,
                        dashboard
                    );
                    return response;
                },
                suspense: false,
                refetchOnWindowFocus: true,
            });

            const props = React.useMemo<AnyDashboardIssue | null>(() => {
                const didPassDependencyCheck =
                    dashboard.dependencies?.asset_results[workspace.id as number] ??
                    false;

                if (!didPassDependencyCheck && dashboard.access !== 'active') {
                    // active dashboards status override dependency status
                    return {
                        kind: 'dependency',
                    };
                }

                if (queryAccountStatus.isLoading) {
                    return null;
                }
                if (queryAccountStatus.isError) {
                    return {
                        kind: 'error',
                        status: 'error',
                        message: 'failed to fetch account status data',
                    };
                }
                if (queryAccountStatus.data) {
                    const detail = getStatusDetail(workspace.id as number, [
                        queryAccountStatus.data,
                    ]);
                    if (detail.status !== 'retrieved') {
                        return null;
                    }
                    return { kind: 'asset', ...detail };
                }
                return null;
            }, [
                queryAccountStatus.isLoading,
                queryAccountStatus.isError,
                queryAccountStatus.data,
                workspace.id,
            ]);

            return props;
        },
        getConfiguration() {
            return configuration;
        },
        getKey() {
            return dashboard.id;
        },
        getDashboard() {
            return dashboard;
        },
        getDateConfiguration() {
            return dateConfigurationProps;
        },
        getComparison() {
            return configuration.defaults.comparison;
        },
        getFilters() {
            return configuration.properties.data;
        },
        getTraits() {
            return traitListProps;
        },
        getCards() {
            return configuration.cards;
        },
        getComposition(card) {
            if (isAggregatedLineVisualization(card.visualization)) {
                return {
                    sections: [
                        {
                            query: card.query,
                            visualization: card.visualization,
                        },
                        {
                            query: createStatQuery(card, card.visualization),
                            visualization: { kind: 'stat' },
                        },
                    ],
                };
            }
            return {
                sections: [
                    {
                        query: card.query,
                        visualization: card.visualization,
                    },
                ],
            };
        },
    };
};

function createStatQuery(
    card: Card,
    visualization: CardAggregatedLineVisualization
): Card['query'] {
    const [projection, ...rest] = card.query.projections;
    return {
        ...card.query,
        projections: [
            {
                ...projection,
                name: `${projection.name}_stat`,
                // only consider the columns that are both aggregated and projected in the source query
                // as stats
                columns: [
                    ...projection.columns.filter((column) => column.key === 'segment'),
                    ...projection.columns.filter((column) =>
                        card.query.aggregations?.some(
                            (aggregation) => aggregation.name === column.key
                        )
                    ),
                ],
            },
            {
                ...projection,
                name: `${projection.name}_stat_relative`,
                // only consider the columns that are both aggregated and projected in the source query
                // as stats
                columns: projection.columns
                    .filter((column) =>
                        card.query.aggregations?.some(
                            (aggregation) => aggregation.name === column.key
                        )
                    )
                    .map((column) => ({
                        ...column,
                        // expr: `(${column.key} - compare(${column.key})) / ${column.key}`,
                        expr: `(${column.key} - compare(${column.key})) / compare(${column.key})`,
                        type: {
                            kind: 'movement',
                            inverse:
                                card.query.aggregations?.find(
                                    (aggregation) => aggregation.name === column.key
                                )?.inverse ?? false,
                        },
                    })),
            },
            {
                ...projection,
                name: `${projection.name}_stat_previous`,
                // only consider the columns that are both aggregated and projected in the source query
                // as stats
                columns: projection.columns
                    .filter((column) =>
                        card.query.aggregations?.some(
                            (aggregation) => aggregation.name === column.key
                        )
                    )
                    .map((column) => ({
                        ...column,
                        expr: `compare(${column.key})`,
                    })),
            },
        ],
        // Remove facetting to get global aggregate
        facet: null,
        // Result should be a scalar value and not a timeseries
        timeseries: null,
        segments: visualization.aggregate,
    };
}
