import { useQuery } from '@tanstack/react-query';
import { SetupIntentAdapter } from './setupIntentAdapter';
import { SetupIntentRepository } from './setupIntentInterface';

export function createSetupIntentRepository(
    adapter: SetupIntentAdapter
): SetupIntentRepository {
    return {
        useCreate(context, props, options) {
            const query = useQuery({
                ...options,
                queryKey: ['setup-intents', context.organization, props],
                async queryFn() {
                    const response = await adapter.create(context, props);
                    return response;
                },
                keepPreviousData: true,
                staleTime: Infinity,
            });
            return query;
        },
    };
}
