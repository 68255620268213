import { GroupDto } from '../../../api';
import { GroupEntity } from '../../../domain/groups';
import { GroupAdapter } from '../../../app/groups';
import { GroupsImplConfig } from './groupConfig';
import { chain } from 'lodash';

export function createGroupsImpl(config: GroupsImplConfig): GroupAdapter {
    const { api } = config;
    const NOW = new Date();

    function toEntity(dto: GroupDto): GroupEntity {
        if (dto.dataset_ids) {
            return {
                kind: 'dataset',
                id: dto.id,
                name: dto.name,
                creator: null,
                // creator: {
                //     id: dto.creator_id,
                // },
                membership: dto.membership,
                datasets: dto.dataset_ids,
                privacy: dto.privacy_restrictions,
                createdAt: dto.created_at ?? NOW,
            };
        }
        return {
            kind: 'global',
            id: dto.id,
            name: dto.name,
            creator: null,
            // creator: {
            //     id: dto.creator_id,
            // },
            membership: dto.membership,
            privacy: dto.privacy_restrictions,
            createdAt: dto.created_at ?? NOW,
        };
    }

    return {
        async find(context, query) {
            const response = await api.groups.list(context, query);
            let acc = chain(response.data)
                .filter(
                    (response) =>
                        // automatic groups are modelled separately in terms of "peer sets"
                        response.membership.kind !== 'automatic'
                )
                .map(toEntity)
                .filter((candidate) =>
                    query.kind ? candidate.kind === query.kind : true
                )
                .value();

            return {
                limit: response.pagination?.page_size || acc.length,
                total: acc.length + (response.pagination?.count_left || 0),
                items: acc,
            };
        },
        async lookup(context, query) {
            const response = await api.groups.lookup(context, query.id);
            return toEntity(response);
        },
        async create(context, entity) {
            const response = await api.groups.create(context, entity);
            return toEntity(response);
        },
        async remove(context, entity) {
            const response = await api.groups.remove(context, entity);
            return;
        },
    };
}
