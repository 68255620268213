import { isSuccessResponse } from '../../../domain/query';
import { EmailVisualizationControllerConfig } from './emailVisualizationConfig';
import { EmailVisualizationController } from './emailVisualizationInterface';

export function createEmailVisualizationController(
    config: EmailVisualizationControllerConfig
): EmailVisualizationController {
    const {
        getProps,
        service: {
            visualization: { useVisualization },
            chart: { useChart },
            query: { useResponse },
        },
    } = config;
    return {
        useProps(payload) {
            const chart = useChart(payload);
            const responses = useResponse(chart);
            const visualization = useVisualization({
                responses: responses
                    .filter(isSuccessResponse)
                    .map((response) => response.response),
            });
            return getProps({ chart, responses, visualization });
        },
    };
}
