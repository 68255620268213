import { useQuery } from '@tanstack/react-query';
import { CollaboratorRepositoryConfig } from './collaboratorConfig';
import { CollaboratorRepository } from './collaboratorInterface';

export function createCollaboratorRepository(
    config: CollaboratorRepositoryConfig
): CollaboratorRepository {
    const { adapter } = config;
    return {
        useFind(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['collaborator', context.organization.id],
                async queryFn() {
                    if (query.workspaces.length === 0) {
                        return [];
                    }
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
        },
    };
}
