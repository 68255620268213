import { chain } from 'lodash';
import { MetricConfiguration } from '../../../domain/metrics';
import { MetricConfigurationV2Adapter } from '../../../app/metrics';
import {
    AnyRanking,
    FixedCohortStateItem,
    DynamicCohortStateItem,
    ViewEntity,
} from '../../../domain/dashboard';
import {
    AnyPreferenceMatchSection,
    PreferencDataFiltersSectionMatchDto,
    PreferencQuerySegmentsSectionMatchDto,
} from '../../../api';
import { DashboardByViewId } from '../configuration/configurationImpl';
import { AnyCondition, Property } from '../../../domain/attributes';
import { castFilterToConditionDomain } from '../../api';
import { PluginSlugs } from '../../../../config';
import { DashboardSlugs } from '../../../../config/dashboard';
import { MetricConfigurationV2Config } from './configurationConfig';
import { RANKING_BY_DASHBOARD } from '../configuration';

export function createMetricConfigurationV2Impl(
    config: MetricConfigurationV2Config
): MetricConfigurationV2Adapter {
    const {
        // hooks: { useQuery },
        api: { platform },
        adapter,
    } = config;
    return {
        async find(context, query) {
            const [views, preferences] = await Promise.all([
                adapter.view.find(context, {}),
                platform.preferences.match(context, {
                    assetId: query.workspace.id as number,
                    plugins: Object.values(PluginSlugs),
                    dashboards: Object.values(DashboardSlugs),
                    pageSize: 1000,
                    sections: ['query_segments', 'data_filters', 'query_segments'],
                }),
            ]);

            const preferencesByDashboard = chain(preferences.data ?? [])
                .reduce<Record<string, AnyPreferenceMatchSection[]>>((agg, curr) => {
                    const dashContext = curr.context.find(
                        ([subj, subjId]) => subj === 'dashboard'
                    );
                    if (dashContext) {
                        const dashboard = dashContext[1];
                        const sections = agg[dashboard] || [];
                        sections.push(...curr.sections);
                        agg[dashboard] = sections;
                    }
                    return agg;
                }, {})
                .value();

            const propertyKeyByTypeSlug =
                views.reduce(
                    (accView, itemView) =>
                        itemView.columns.reduce((accColumn, itemColumn) => {
                            const id = `${itemView.plugin}.${itemColumn.key}`;
                            if (accColumn[id]) {
                                // we might have duplicate columns across views
                                // so we assume the first one has priority
                                return accColumn;
                            }
                            if (
                                typeof itemColumn.type === 'object' &&
                                itemColumn.type.kind === 'reference'
                            ) {
                                return {
                                    ...accColumn,
                                    [itemColumn.type.id]: itemColumn,
                                };
                            }
                            return accColumn;
                        }, accView),
                    {} as Record<string, Property | undefined>
                ) ?? {};

            return query.metrics.flatMap(
                (
                    item
                ): Pick<
                    MetricConfiguration,
                    'ranking' | 'definition' | 'dashboard' | 'filters'
                >[] => {
                    // const dashboard = DashboardsByPlugin[item.plugin];
                    const dashboard = DashboardByViewId[item.view];
                    if (!dashboard) {
                        console.warn(`no dashboard found for view ${item.view}`);
                        return [];
                    }
                    const preferences = preferencesByDashboard[dashboard.id];
                    // if (!preferences) {
                    //     console.warn(`no preference found for dashboard ${dashboard}`);
                    //     return [];
                    // }

                    const cohorts = preferences?.find(
                        (item) => item.section_type === 'query_segments'
                    ) as PreferencQuerySegmentsSectionMatchDto | undefined;

                    const datafilters = preferences?.find(
                        (item) => item.section_type === 'data_filters'
                    ) as PreferencDataFiltersSectionMatchDto | undefined;

                    // if (item.name.toLowerCase().includes('thru')) {
                    //     console.log('DEBUG cohorts', cohorts);
                    // }

                    // if (!cohorts) {
                    //     console.warn(`cohort not found for metric ${item.id}`);
                    //     return [];
                    // }

                    let ranking: AnyRanking | null = null;
                    let filters: AnyCondition[] | null = null;

                    if (datafilters?.value) {
                        filters = datafilters.value.filters.flatMap((filter) => {
                            const condition = castFilterToConditionDomain(
                                propertyKeyByTypeSlug,
                                filter
                            );
                            if (!condition) {
                                console.warn(
                                    `failed to cast filter to condition`,
                                    filter
                                );
                            }
                            return condition ? [condition] : [];
                        });
                    }

                    if (cohorts?.value.mode === 'fixed') {
                        ranking = {
                            kind: 'fixed',
                            // Here the assumption is that every range segment shares the same comparison metric.
                            comparison: cohorts.value.fixed[0].range.column,
                            cohorts: cohorts?.value.fixed.map(
                                (item): FixedCohortStateItem => ({
                                    name: item.name,
                                    lower: item.range.start.value,
                                    upper: item.range.end.value,
                                    visible: true,
                                })
                            ),
                        };
                    } else if (cohorts?.value.mode === 'dynamic') {
                        ranking = {
                            kind: 'dynamic',
                            cohorts: cohorts?.value.dynamic.map(
                                (item): DynamicCohortStateItem =>
                                    item.reducer?.kind ===
                                    // HACK
                                    'percentile'
                                        ? {
                                              name: item.name,
                                              value: item.reducer!.value,
                                              visible: true,
                                          }
                                        : {
                                              name: item.name,
                                              value: 50,
                                              visible: true,
                                          }
                            ),
                        };
                    } else {
                        ranking = (dashboard
                            ? RANKING_BY_DASHBOARD[dashboard.id]
                            : null) ?? {
                            kind: 'dynamic',
                            cohorts: [
                                {
                                    name: 'Low performers',
                                    value: 25,
                                    visible: true,
                                },
                                {
                                    name: 'Median',
                                    value: 50,
                                    visible: true,
                                },
                                {
                                    name: 'High performers',
                                    value: 75,
                                    visible: true,
                                },
                            ],
                        };
                    }

                    return [
                        {
                            dashboard: dashboard,
                            filters: filters ?? [],
                            definition: {
                                id: item.id,
                            },
                            ranking: ranking ?? {
                                kind: 'dynamic',
                                cohorts: [
                                    {
                                        name: 'Low performers',
                                        value: 25,
                                        visible: true,
                                    },
                                    {
                                        name: 'Median',
                                        value: 50,
                                        visible: true,
                                    },
                                    {
                                        name: 'High performers',
                                        value: 75,
                                        visible: true,
                                    },
                                ],
                            },
                        },
                    ];
                }
            );
        },
    };
}
