import React, { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { FormIds, PageIds } from '../../../config';
import { WorkspaceContextV2 } from '../../../app';
import { CompanyListBoxFormValues } from '../../../macro';
import { OnboardingCompetitiveSetConfig } from './onboardingCompetitiveSetConfig';
import {
    OnboardingCompetitiveSetController,
    OnboardingCompetitiveSetLoader,
} from './onboardingCompetitiveSetInterface';
import {
    OnboardingCompetitiveSetContainerProps,
    OnboardingCompetitiveSetViewProps,
} from './onboardingCompetitiveSetProps';

export function createOnboardingCompetitiveSetContainer(
    config: OnboardingCompetitiveSetConfig,
    loader: OnboardingCompetitiveSetLoader,
    controller: OnboardingCompetitiveSetController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & OnboardingCompetitiveSetViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingCompetitiveSetContainerProps
> {
    const {
        infra: { toaster },
        hooks,
        service: { peerset: peersetService },
        repository: { peerset: peerSetRepository, invitation: invitationRepository },
    } = config;
    return (containerProps) => {
        const toast = toaster.useToast();
        const context: WorkspaceContextV2 = {
            organization: hooks.useOrganization(),
            auth: hooks.useAuth(),
            workspace: hooks.useWorkspace(),
        };

        const form = useForm({
            defaultValues: {
                companies: [] as string[],
            },
        });
        const formValues = form.watch();

        const data = loader.useLoad(context, {
            company: {
                form,
            },
        });

        const peersetMutation = peerSetRepository.useCreate(context);
        const invitationMutation = invitationRepository.useBulk(context);

        const mutationCreate = useMutation({
            mutationFn(values: CompanyListBoxFormValues) {
                return peersetService.create(context, {
                    name: 'Competitors',
                    companies: values.companies,
                    createPeerset(props) {
                        return peersetMutation.mutateAsync(props);
                    },
                    createInvitations(props) {
                        return invitationMutation.mutateAsync(props);
                    },
                });
            },
        });

        const selected = useMemo(
            () => new Set(formValues.companies),
            [formValues.companies]
        );

        const props = controller.useProps(context, data, {
            onSkip() {
                containerProps.onContinue?.();
            },
            company: {
                form: {
                    id: FormIds.ONBOARDING_COMPETTIVE_SET,
                    form,
                    async onSubmit(values) {
                        // await new Promise((resolve) => setTimeout(resolve, 500));
                        const response = await mutationCreate.mutateAsync(values);
                        console.log('competitive set created', response);
                        return containerProps.onContinue?.({});
                    },
                    onSubmitError(error) {
                        console.log('error', error);
                        toast({
                            kind: 'error',
                            description: `Form submission failed`,
                        });
                    },
                },
                match: {
                    company: {
                        metadata: {},
                    },
                    selected,
                    onAdd(item) {
                        form.setValue('companies', [
                            // add company to start of list for proper UX
                            item.company.id,
                            ...formValues.companies,
                        ]);
                    },
                },
                select: {
                    selected: formValues.companies,
                    onRemove(item) {
                        form.setValue(
                            'companies',
                            formValues.companies.filter(
                                (candidate) => candidate !== item.company.id
                            )
                        );
                    },
                    onClear() {
                        form.setValue('companies', []);
                    },
                },
            },
        });
        return <View {...props} />;
    };
}
