import { AccountInviteFormController } from './inviteFormInterface';
import { AccountInviteFormViewProps } from './inviteFormProps';

export function createInviteFormController(): AccountInviteFormController {
    return {
        useProps(context, props): AccountInviteFormViewProps {
            return {
                fields: {
                    text: {
                        id: 'description',
                        label: 'Description',
                        description: null,
                        isRequired: true,
                        input: {
                            kind: 'long_text',
                            value: props.fieldset.value.text,
                            onChange(value) {
                                props.fieldset.onInputChange('text', value);
                            },
                        },
                        error: props.fieldset.errors.text?.message
                            ? { message: props.fieldset.errors.text.message }
                            : null,
                    },
                },
            };
        },
    };
}
