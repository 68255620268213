import React from 'react';
import { Box, HStack, StackProps } from '@chakra-ui/react';

export interface WorkspaceLayoutHeaderProps extends Pick<StackProps, 'bg' | 'px'> {}

export const WorkspaceLayoutHeader: React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceLayoutHeaderProps
> = (props) => (
    <Box bg={props.bg} px={props.px}>
        {props.children}
    </Box>
);
