import StoreBase from './StoreBase';
import { action, computed, keys, makeObservable, observable, runInAction } from 'mobx';
import {
    // fetchIntegrationForSubject,
    fetchIntegrations,
    saveCredentials,
    removeIntegration,
    fetchIntegrationDefs,
    getIntegrationConfs,
    createIntegrationConf,
    removeIntegrationConf,
    refreshIntegration,
    fetchIntegrationById,
    getConnectLink,
    getReConnectLink,
} from '../api/integrations';
import { IntegrationConf, IntegrationCredentials, IntegrationWithConf, Integration } from '../models/integration';
import { IntegrationConfigItem } from '../models/integration-conf';
import { ContextType } from '../models/Account';

export class IntegrationStore extends StoreBase {
    lastCreatedIntegration: Integration | null = null;
    integConfs: Array<IntegrationConfigItem> | null = null;
    isLoading: boolean = false;
    currentIntegrations: Array<Integration> | null = null;
    definitions: Array<IntegrationConf> | null = null;
    requestingIntegrations: boolean = false;
    loaded: boolean = false;
    isLoadingIntegrateLink: boolean = false;
    isLoadingReconnectLink: boolean = false;
    isLoadingId: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            isLoadingIntegrateLink: observable,
            isLoadingReconnectLink: observable,
            requestingIntegrations: observable,
            lastCreatedIntegration: observable,
            definitions: observable,
            currentIntegrations: observable,
            integConfs: observable,
            loaded: observable,
            isLoadingId: observable,

            // Computed
            integrationByKey: computed,

            // Actions
            fetchDefinitions: action,
            // fetchIntegration: action,
            setLoading: action,
            setIsLoadingIntegrateLink: action,
            setIsLoadingReconnectLink: action,
            setCurrentIntegrations: action,
            saveCredentials: action,
            clearIntegration: action,
            setRequestingIntegrations: action,
            setIntegConfs: action,
            setLoaded: action,
            setLastCreatedIntegration: action,
            setDefinitions: action,
            clearIntegrations: action,
        });
    }
    async onContextChanged(contextName: ContextType) {
        switch (contextName) {
            case 'on-boarding':
            case 'dashboard':
                if (!this.isLoading && !this.definitions) {
                    await this.fetchDefinitions();
                }
            default:
                break;
        }
    }
    setCurrentIntegrations(allInteg: Array<Integration>) {
        this.currentIntegrations = allInteg;
    }

    setLastCreatedIntegration(integ: Integration | null) {
        this.lastCreatedIntegration = integ;
    }

    setRequestingIntegrations(req: boolean) {
        this.requestingIntegrations = req;
    }

    get integrationByKey(): Record<string, IntegrationConf> {
        return this.definitions
            ? this.definitions.reduce<Record<string, IntegrationConf>>((agg, curr) => {
                  agg[curr.integration_type] = curr;
                  return agg;
              }, {})
            : {};
    }

    setLoading(flag: boolean) {
        this.isLoading = flag;
        if (flag) {
            this.setError(null);
        }
    }

    setIsLoadingIntegrateLink(flag: boolean) {
        this.isLoadingIntegrateLink = flag;
    }

    setIsLoadingReconnectLink(flag: boolean) {
        this.isLoadingReconnectLink = flag;
    }

    setIsLoadingId(id: number) {
        this.isLoadingId = id;
    }

    setLoaded() {
        this.loaded = true;
    }

    setDefinitions(defs: Array<IntegrationConf>) {
        this.definitions = defs;
    }

    fetchDefinitions = async () => {
        if (this.definitions) {
            return;
        }
        await this.rootStore.auth.waitAuthenticated();
        this.setLoading(true);
        try {
            const { data } = await fetchIntegrationDefs();
            this.setDefinitions(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.fetchDefinitions();
            }
        } finally {
            this.setLoading(false);
        }
    };
    clearIntegrations = () => {
        this.currentIntegrations = null;
    };
    fetchIntegrations = async (integrationDefinitionId: number) => {
        await this.rootStore.auth.waitAuthenticated();
        this.setIsLoadingId(integrationDefinitionId);
        try {
            const { data } = await fetchIntegrations(integrationDefinitionId);
            this.setCurrentIntegrations(data.items);
            return data;
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.fetchIntegrations(integrationDefinitionId);
            }
        } finally {
            this.setIsLoadingId(0);
        }
    };
    // fetchIntegrations = async () => {
    //     await this.rootStore.auth.waitAuthenticated();
    //     this.setLoading(true);
    //     try {
    //         await this.fetchDefinitions();
    //         if (!this.definitions) {
    //             return;
    //         }
    //         const { data } = await fetchIntegrations();
    //         const intById = data.reduce<Record<string, Array<Integration>>>((agg, curr) => {
    //             const arr = agg[curr.integration_definition_id] || [];
    //             arr.push(curr);
    //             agg[curr.integration_definition_id] = arr;
    //             return agg;
    //         }, {});
    //         this.setAllIntegrations(data);
    //         const integrations: Array<IntegrationWithConf> = this.definitions.map((conf) => ({
    //             conf,
    //             integrations: intById[conf.id],
    //         }));
    //         const integs_that_not_synced_yet = integrations.filter(
    //             (d) => !!d.integrations?.find((i) => i.is_connected && !i.is_data_synced)
    //         );
    //         if (integs_that_not_synced_yet.length > 0) {
    //             this.rootStore.auth.setGlobalErrorMessage(
    //                 `We are currently syncing your data from ${integs_that_not_synced_yet
    //                     .map((x) => x.conf.title)
    //                     .join(', ')}`
    //             );
    //         }
    //         this.setIntegrations(integrations);
    //     } catch (e) {
    //         console.log(e);
    //         const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
    //         if (isDone) {
    //             await this.fetchIntegrations();
    //         } else {
    //             this.handleAxiosError(e);
    //         }
    //     } finally {
    //         this.setLoading(false);
    //         this.setLoaded();
    //     }
    // };

    // fetchIntegration = async (integartionId: number) => {
    //     await this.rootStore.auth.waitAuthenticated();
    //     this.setLoading(true);
    //     try {
    //         this.fetchDefinitions();
    //         if (!this.definitions) {
    //             this.setError({ errType: 'validationErr', message: 'definitions not found' });
    //             this.setLoading(false);
    //             return;
    //         }
    //         const { data } = await fetchIntegrationById(integartionId);
    //         const conf = this.definitions.find((i) => i.id == data.integration_definition_id);
    //         if (!conf) {
    //             this.setError({ errType: 'validationErr', message: 'unknown integration type' });
    //             return;
    //         }
    //         this.setSingleIntegration(conf, data);
    //         this.setLastCreatedIntegration(data);
    //     } catch (e) {
    //         if (isAxiosErr(e)) {
    //             if (e.response?.status == 404) {
    //                 await this.fetchIntegrations();
    //                 return;
    //             }
    //         }
    //         console.log(e);
    //         const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
    //         if (isDone) {
    //             await this.fetchIntegration(integartionId);
    //         } else {
    //             this.handleAxiosError(e);
    //         }
    //     } finally {
    //         this.setLoading(false);
    //     }
    // };

    saveCredentials = async (creds: IntegrationCredentials) => {
        this.setLoading(true);
        try {
            this.fetchDefinitions();
            if (!this.definitions) {
                this.setLoading(false);
                this.setError({ errType: 'validationErr', message: 'no integrations found' });
                return;
            }

            const { data } = await saveCredentials(creds);
            const conf = this.definitions.find((i) => i.id == data.implementation.integration_definition_id);
            if (!conf) {
                this.setError({ errType: 'validationErr', message: 'unknown integration type' });
                return;
            }
            this.setLastCreatedIntegration(data);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.saveCredentials(creds);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    clearIntegration = async (integrationId: number) => {
        this.setLoading(true);
        try {
            await removeIntegration(integrationId);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.clearIntegration(integrationId);
            }
        } finally {
            this.setLoading(false);
        }
    };
    setIntegConfs(integConfs: Array<IntegrationConfigItem> | null) {
        this.integConfs = integConfs;
    }

    getConfs = async (integrationId: number) => {
        this.setLoading(true);
        try {
            const { data } = await getIntegrationConfs(integrationId);
            this.setIntegConfs(data);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getConfs(integrationId);
            }
        } finally {
            this.setLoading(false);
        }
    };

    createConf = async (integrationId: number, conf: IntegrationConfigItem) => {
        this.setLoading(true);
        try {
            await createIntegrationConf(integrationId, conf);
            const { data } = await getIntegrationConfs(integrationId);
            this.setIntegConfs(data);
            await this.getConfs(integrationId);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.createConf(integrationId, conf);
            }
        } finally {
            this.setLoading(false);
        }
    };

    deleteConf = async (integrationId: number, confId: number) => {
        this.setLoading(true);
        try {
            const { data } = await removeIntegrationConf(integrationId, confId);
            this.setIntegConfs(data);
            await this.getConfs(integrationId);
        } catch (e) {
            console.log(e);
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.deleteConf(integrationId, confId);
            }
        } finally {
            this.setLoading(false);
        }
    };

    redirectToConnectLink = async (
        integDefId: number,
        keys?: Record<string, string>,
        { newTab = false, implementationId = undefined, ...rest }: { newTab?: boolean, implementationId?: number } = {}
    ) => {
        this.setIsLoadingIntegrateLink(true);
        this.setIsLoadingId(integDefId);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const {
                data: { link },
            } = await getConnectLink(integDefId, keys, implementationId);
            if (link && newTab) {
                // window.open(link, '_blank', 'noopener,noreferrer');
                document.location.href = link;
            } else if (link && !newTab) {
                document.location.href = link;
            } else {
                this.setErrType('serverErr');
            }
        } catch (e) {
            this.handleAxiosError(e);
        } finally {
            this.setIsLoadingIntegrateLink(false);
            this.setIsLoadingId(0);
        }
    };

    redirectToReconnecLink = async (
        definitionId: number,
        integId: number,
        keys?: Record<string, string>,
        { newTab = false, implementationId = undefined, ...rest }: { newTab?: boolean, implementationId?: number } = {}
    ) => {
        this.setIsLoadingReconnectLink(true);
        this.setIsLoadingId(definitionId || 0);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const {
                data: { link },
            } = await getReConnectLink(definitionId, integId, keys, implementationId);
            if (link && newTab) {
                // window.open(link, '_blank', 'noopener,noreferrer');
                document.location.href = link;
            } else if (link && !newTab) {
                document.location.href = link;
            } else {
                this.setErrType('serverErr');
            }
        } catch (e) {
            this.handleAxiosError(e);
            this.setIsLoadingId(0);
        } finally {
            this.setIsLoadingReconnectLink(false);
        }
    };
}
