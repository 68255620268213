import React from 'react';
import { GroupEntity } from '../../../../domain/groups';
import { GroupsLoaderConfig } from './groupsLoaderConfig';
import { GroupReference, GroupsLoadedData, GroupsLoader } from './groupsInterface';

export function createGroupsLoader(
    config: Pick<GroupsLoaderConfig['deps'], 'service'>
): GroupsLoader {
    const { service } = config;
    return {
        useLoad(context) {
            const groups = service.groups.useFind(
                context,
                { kind: 'global' },
                { staleTime: Infinity, suspense: true }
            );
            const assets = service.assets.useFind(context, { visible: true });

            const retVal: Omit<
                GroupsLoadedData,
                'optinAssetOptions' | 'isLoading' | 'allowExtendedVersion'
            > = React.useMemo(() => {
                if (groups.status === 'success') {
                    const internalGroupEntity = groups.data.items.find(
                        (x) => x.membership.kind === 'internal'
                    );
                    const internalGroup: GroupEntity | undefined = internalGroupEntity;
                    const optinGroup = groups.data.items.find(
                        (x) => x.membership.kind == 'opt_in'
                    );
                    if (internalGroup) {
                        return {
                            initial: {
                                state: 'internal',
                            },
                            internalGroup,
                            optinGroup: optinGroup,
                            group: internalGroup,
                        };
                    } else if (optinGroup && optinGroup.members.length > 0) {
                        return {
                            initial: {
                                state: 'opt_in',
                                optinAssets: optinGroup.members.map((x) => ({
                                    id: x.asset_id,
                                    name: x.asset_id.toString(),
                                    ...(assets.data?.find((a) => a.id == x.asset_id) ||
                                        {}),
                                })),
                            },
                            internalGroup,
                            optinGroup: optinGroup,
                            group: optinGroup,
                        };
                    } else {
                        return {
                            initial: {
                                state: 'pending',
                            },
                            internalGroup,
                            optinGroup,
                        };
                    }
                } else {
                    return {
                        initial: {
                            state: 'pending',
                        },
                    };
                }
            }, [groups.status, assets.status]);

            return {
                ...retVal,
                isLoading: assets.isLoading || groups.status !== 'success',
                optinAssetOptions: assets,
                allowExtendedVersion: false,
            };
        },
    };
}
