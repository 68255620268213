import { FormFieldListController } from './formFieldListInterface';

export function createFormFieldListController(): FormFieldListController {
    return {
        useProps(props) {
            return {
                ...props,
                columns: props.columns ?? 1,
            };
        },
    };
}
