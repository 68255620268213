export const MetricIds = {
    FACEBOOKADS_CPM: 'facebookads.cpm',
    FACEBOOKADS_COST_PER_PURCHASE: 'facebookads.cost_per_purchase',
    FACEBOOKADS_CVR: 'facebookads.cvr',
    FACEBOOKADS_SPEND: 'facebookads.spend',
    FACEBOOKADS_AOV: 'facebookads.aov',
    FACEBOOKADS_CPC: 'facebookads.cpc',
    FACEBOOKADS_CTR: 'facebookads.ctr',
    FACEBOOKADS_ROAS: 'facebookads.roas',
    FACEBOOKADS_HOOK_RATE: 'facebookads.hook_rate',
    FACEBOOKADS_RATE_OF_VIDEO_PLAYS_AT_50: 'facebookads.rate_of_video_plays_at_50',
    FACEBOOKADS_RATE_OF_VIDEO_PLAYS_AT_75: 'facebookads.rate_of_video_plays_at_75',
    FACEBOOKADS_THRUPLAYS: 'facebookads.thruplays',
    FACEBOOKADS_VIDEO_AVG_TIME_WATCHED: 'facebookads.video_avg_time_watched',
    FACEBOOKADS_CPA: 'facebookads.cpa',
    GOOGLEADS_COST_PER_CONVERSION: 'googleads.cost_per_conversion',
    GOOGLEADS_COST_PER_PURCHASE: 'googleads.cost_per_purchase',
    GOOGLEADS_CPM: 'googleads.cpm',
    GOOGLEADS_CVR: 'googleads.cvr',
    GOOGLEADS_SPEND: 'googleads.spend',
    GOOGLEADS_CTR: 'googleads.ctr',
    GOOGLEADS_ROAS: 'googleads.roas',
    TIKTOKADS_COST_PER_CONVERSION: 'tiktokads.cost_per_conversion',
    TIKTOKADS_CPM: 'tiktokads.cpm',
    TIKTOKADS_CVR: 'tiktokads.cvr',
    TIKTOKADS_SPEND: 'tiktokads.spend',
    TIKTOKADS_CPC: 'tiktokads.cpc',
    TIKTOKADS_CTR: 'tiktokads.ctr',
    EXECUTIVE_SUMMARY_REVENUE: 'executive-summary.revenue',
    EXECUTIVE_SUMMARY_CAC: 'executive-summary.cac',
    EXECUTIVE_SUMMARY_MARKETING_EFFICIENCY_RATIO:
        'executive-summary.marketing_efficiency_ratio',
    EXECUTIVE_SUMMARY_SPEND_OVER_REVENUE: 'executive-summary.spend_over_revenue',
    EXECUTIVE_SUMMARY_SPEND: 'executive-summary.spend',
    SHOPIFY_REPEATED_ORDERS_RATIO: 'shopify.repeated_orders_ratio',
    SHOPIFY_REFUNDS_RATE: 'shopify.refunds_rate',
    SHOPIFY_AOV: 'shopify.aov',
    SHOPIFY_REVENUE: 'shopify.revenue',
    SHOPIFY_ABANDONED_CHECKOUT_RATE: 'shopify.abandoned_checkout_rate',
    GOOGLEANALYTICS_ACTIVE_1_DAY_USERS: 'googleanalytics.active_1_day_users',
    GOOGLEANALYTICS_ACTIVE_7_DAY_USERS: 'googleanalytics.active_7_day_users',
    GOOGLEANALYTICS_ACTIVE_28_DAY_USERS: 'googleanalytics.active_28_day_users',
    GOOGLEANALYTICS_ENGAGEMENT_RATE: 'googleanalytics.engagement_rate',
    GOOGLEANALYTICS_REVENUE_PER_1D_USER: 'googleanalytics.revenue_per_1d_user',
    GOOGLEANALYTICS_REVENUE: 'googleanalytics.revenue',
    GOOGLEANALYTICS_CART_VIEW_RATE: 'googleanalytics.cart_view_rate',
    GOOGLEANALYTICS_PURCHASE_VIEW_RATE: 'googleanalytics.purchase_view_rate',
    GOOGLEANALYTICS_ITEMS_PURCHASED: 'googleanalytics.items_purchased',
} as const;
