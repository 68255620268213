import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { ViewDto } from './viewModel';
import { ViewResource } from './viewInterface';
import { ViewListResponseSchema } from './viewSchema';

export function createViewResource(client: AxiosInstance): ViewResource {
    return {
        async list(context, query): Promise<ListResponse<ViewDto>> {
            let params: Record<string, string | string[]> = {};
            let headers: Record<string, string> = {};
            if (context.auth.scheme.kind == 'legacy') {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers['Authorization'] = `bearer ${scheme.store.authToken}`;
            } else if (context?.auth.scheme.kind === 'apiKey') {
                if (context.auth.scheme.assume?.id) {
                    headers['x-varos-organization'] =
                        context.auth.scheme.assume?.id?.toString();
                }
                params['api-key'] = context.auth.scheme.token;
            }
            // if (query.kinds !== undefined) {
            //     params['kind'] = query.kinds;
            // }

            // if (query.sources !== undefined) {
            //     params['source'] = query.sources;
            // }

            // if (query.plugins !== undefined) {
            //     params['plugin'] = query.plugins;
            // }

            const response = await client.get('/api/v2/views', {
                params,
                headers,
            });

            try {
                const parsed = ViewListResponseSchema.parse(response.data);
                return parsed;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    };
}
