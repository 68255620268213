import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { collectionFromQuery, lookupFromQuery } from '../../../base';
import { OrganizationScope } from '../../platform';
import { GroupInviteAdapter } from './inviteAdapter';
import { GroupInviteRepository } from './inviteInterface';
import {
    GroupInviteCreatePayload,
    GroupInviteQuery,
    GroupInviteRemovePayload,
} from './inviteQuery';
import { GroupEntity } from '../../../domain/groups';

export function createGroupInviteRepository(
    adapter: GroupInviteAdapter
): GroupInviteRepository {
    function getReadPrefix(context: OrganizationScope, query: GroupInviteQuery) {
        return ['groups', 'invite', context.organization.id, query];
    }
    function getWritePrefix(
        context: OrganizationScope,
        command: GroupInviteCreatePayload
    ) {
        return ['groups', context.organization.id, command];
    }
    return {
        useFind(context, query, options) {
            const applied: GroupInviteQuery = {
                page_size: query.page_size ?? 20,
                ...query,
            };
            const data = useQuery({
                queryKey: [...getReadPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return data;
        },
        useCreate(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation({
                async mutationFn(payload: GroupInviteCreatePayload) {
                    const response = await adapter.create(context, payload);
                    return response;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['groups']);
                },
            });
            return mutationState;
        },
        useRemove(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation({
                mutationFn(payload: GroupInviteRemovePayload) {
                    return adapter.remove(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['groups']);
                },
            });
            return mutationState;
        },
    };
}
