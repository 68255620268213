import { usePlanList } from '../../domain';
import { AnyAccessRequest, ApplicationContext, useAccess } from '../../base';
import { AccessRestrictViewProps } from './AccessRestrictView';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

type Action = AccessRestrictViewProps['actions'][number];

export const useAccessView = (
    context: ApplicationContext,
    request: AnyAccessRequest | null
): AccessRestrictViewProps => {
    const plans = usePlanList(context, {
        absoluteSuccessPath: location.pathname,
        absoluteCancelPath: location.pathname,
        returnPath: location.pathname,
        excludeDefault: true,
    });
    const navigate = useNavigate();
    const actions = useMemo<Action[]>(
        () => [
            // ...plans.plans
            //     .filter((plan) => plan.amount !== 0)
            //     .map<Action>((plan) => ({
            //         variant: 'primary',
            //         text: `${plan.name} ${context.formatter.currency(plan.amount / 100, plan.currency)}`,
            //         onClick() {
            //             return plans.onSelect(plan);
            //         },
            //     })),
            {
                variant: 'primary',
                text: 'See plan details',
                onClick() {
                    // window.open('/settings/plan', '_blank');
                    // window.open('/u/settings/subscription', '_blank');
                    if (location.pathname.includes('onboarding')) {
                        // legacy UI to support onboarding flow
                        // window.open('/settings/plan', '_blank');
                        window.open('/u/settings/subscription', '_blank');
                    } else {
                        navigate('/u/settings/subscription');
                    }
                    context.access.clear();
                },
            },
        ],
        [plans]
    );

    const attempt = useAccess(context, request, request?.value);

    useEffect(() => {
        return () => {
            // clear up any global feature lock when navigating away
            context.access.clear();
        };
    }, []);

    if (context.access.current) {
        // a global access request has been denied
        return {
            context,
            actions,
            attempt: context.access.current,
            onClear: context.access.clear,
        };
    }

    if (!request) {
        return {
            context,
            attempt: null,
            actions,
        };
    }

    return {
        context,
        attempt,
        actions,
    };
};
