import { isAxiosErr } from '../../../../utils/axiosUtils';
import { AxiosError, AxiosInstance } from 'axios';
import { ApplicationError } from '../../../base';
import { LegacyAuthenticationScheme, ListResponse, isDomainErrorDto } from '../../common';
import { ReportScheduleDto } from './scheduleModel';
import { ScheduleResource } from './scheduleInterface';
import { CreateReportScheduleDto, UpdateReportScheduleDto } from './scheduleProps';

export function createReportScheduleResource(client: AxiosInstance): ScheduleResource {
    return {
        async list(context, query, { signal, ...options } = {}) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get<ListResponse<ReportScheduleDto>>(
                '/api/v1/reports/schedules',
                {
                    signal: signal,
                    params: {
                        asset: query.assetIds,
                        ...options,
                    },
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            return response.data;
        },
        async create(context, payload, options) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.post<
                CreateReportScheduleDto,
                ReportScheduleDto
            >('/api/v1/reports/schedules', payload, {
                params: { ...options },
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            return response;
        },
        async update(context, id, payload, options) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await client.patch<
                    UpdateReportScheduleDto,
                    ReportScheduleDto
                >(`/api/v1/reports/schedules/${id}`, payload, {
                    validateStatus(status) {
                        return status < 400; //  Resolve only if the status code is less than 400;
                    },
                    params: { ...options },
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                });
                return response;
            } catch (error) {
                if (
                    isAxiosErr<any>(error) &&
                    error.response &&
                    isDomainErrorDto(error.response.data)
                ) {
                    throw new ApplicationError('server', error.response.data.detail);
                }
                throw error;
            }
        },
    };
}
