import { keyBy } from 'lodash';
import { assert } from '../../../../util/assert';
import { QueryResponse, ViewEntity } from '../../../../domain/query';
import { ComparisonVisualization } from '../../../../domain/visualization';
import {
    VisualizationResultSeries,
    ComparisonVisualizationResult,
    ComparisonVisualizationEntry,
    ComparisonVisualizationResultEntry,
} from '../../../../domain/visualization/result';
import { AnyResolvedType } from '../../../../domain/attributes';

export function buildComparisonResult(
    view: Pick<ViewEntity<AnyResolvedType>, 'id' | 'columns'>,
    visualization: ComparisonVisualization,
    response: QueryResponse
): ComparisonVisualizationResult {
    const [partitionKey] = response.metadata.partition;
    // const [metric] = visualization.metrics;

    assert(partitionKey, `no partition key found for view ${view.id}`);
    const [queryDist] = response.queries;

    assert(response.metadata.period, 'no period found in metadata');
    return {
        kind: 'comparison',
        period: {
            from: response.metadata.period.start,
            to: response.metadata.period.end,
        },
        dimensions: [],
        schema: keyBy(view.columns, (item) => item.key),
        entries: visualization.metrics.map(
            (metric): ComparisonVisualizationResultEntry => {
                const scalarKey = metric.key;
                return {
                    metric,
                    series: queryDist.series.items.map(
                        (
                            series
                        ): VisualizationResultSeries<ComparisonVisualizationEntry> => {
                            return {
                                key: series.name.toLocaleLowerCase(),
                                name: series.name,
                                data: series.data.map((row) => {
                                    const value = row[scalarKey];
                                    assert(
                                        typeof value === 'number' || value === null,
                                        () => {
                                            console.error(row);
                                            return `no value found in row`;
                                        }
                                    );
                                    return { value };
                                }),
                            };
                        }
                    ),
                };
            }
        ),
    };
}
