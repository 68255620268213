import React from 'react';
import { LayoutInfoViewProps } from '../../../view/common';

export function createDomainLayoutInfoView(): React.FC<
    { children?: React.ReactNode | undefined } & LayoutInfoViewProps
> {
    return (props) => {
        return <></>;
    };
}
