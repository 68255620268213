import { assert } from '../../../util/assert';
import { MetricDefinition } from '../../metrics';
import { buildCohortState, castRankingToCohortState, CohortState } from '../../dashboard';
import { AnyVisualization } from './visualizationModel';
import { AnyVisualizationCreateProps } from './visualizationProps';
import { AnyCondition, DateCondition } from '../../attributes';

export function buildVisualization(
    metrics: MetricDefinition[],
    props: AnyVisualizationCreateProps
): AnyVisualization {
    const filters = (props.filters as Exclude<AnyCondition, DateCondition>[]) ?? [];
    if (props.kind === 'comparison') {
        return {
            ...props,
            metrics: metrics,
            view: props.view ?? null,
            period: props.period ?? null,
            filters,
            breakdown: props.breakdown ?? null,
            ranking: props.ranking ?? {
                kind: 'dynamic',
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: true,
                        value: 25,
                    },
                    {
                        name: 'Median',
                        visible: true,
                        value: 50,
                    },
                    {
                        name: 'High performers',
                        visible: true,
                        value: 75,
                    },
                ],
            },
            // ranking: castRankingToCohortState(
            //     props.ranking ??
            //         buildCohortState({ mode: 'dynamic', comparison: null }).config.dynamic
            // ),
        };
    }
    const [metric] = metrics;
    assert(metric, 'missing metric');

    if (props.kind === 'bar') {
        // @ts-expect-error
        return {
            ...props,
            metrics: [metric],
            view: props.view ?? null,
            period: props.period ?? null,
            filters,
            aggregation: props.aggregation!,
            breakdown: props.breakdown!,
            ranking: props.ranking ?? {
                kind: 'dynamic',
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: true,
                        value: 25,
                    },
                    {
                        name: 'Median',
                        visible: true,
                        value: 50,
                    },
                    {
                        name: 'High performers',
                        visible: true,
                        value: 75,
                    },
                ],
            },
        };
    }

    return {
        ...props,
        metrics: [metric],
        view: props.view ?? null,
        period: props.period ?? null,
        filters,
        breakdown: props.breakdown ?? null,
        ranking: props.ranking ?? {
            kind: 'dynamic',
            cohorts: [
                {
                    name: 'Low performers',
                    visible: true,
                    value: 25,
                },
                {
                    name: 'Median',
                    visible: true,
                    value: 50,
                },
                {
                    name: 'High performers',
                    visible: true,
                    value: 75,
                },
            ],
        },
    };
}
