import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStorageContext } from '../../../app';
import { StateAdapter } from '../../../route';

export function createAnalysisStateImpl(): StateAdapter {
    return {
        date: {
            useState(context) {
                const storage = useStorageContext();
                return {
                    value: storage.workspace.getDashboardDateState(context.workspace),
                    onChange(value) {
                        return storage.workspace.setDashboardDateState(
                            context.workspace,
                            {
                                period: value.period,
                                comparison: value.comparison!,
                                granularity: value.granularity,
                            }
                        );
                    },
                };
            },
        },
        ranking: {
            useState(context) {
                const [searchParams, setQueryParams] = useSearchParams();
                const URL_KEY = 'ranking';

                const params = useMemo(() => {
                    const encoded = searchParams.get(URL_KEY);
                    if (!encoded) {
                        return null;
                    }
                    const decoded = decodeURIComponent(encoded);
                    const parsed = JSON.parse(decoded);
                    return parsed;
                }, [searchParams]);

                return {
                    value: params,
                    onChange(values) {
                        console.log('changing', values);
                        searchParams.set(
                            URL_KEY,
                            encodeURIComponent(JSON.stringify(values))
                        );
                        setQueryParams(searchParams, { replace: true });
                    },
                };
            },
        },
        breakdown: {
            useState(context) {
                const [searchParams, setQueryParams] = useSearchParams();
                const URL_KEY = 'breakdown';

                const params = useMemo(() => {
                    const encoded = searchParams.get(URL_KEY);
                    if (!encoded) {
                        return null;
                    }
                    const decoded = decodeURIComponent(encoded);
                    const parsed = JSON.parse(decoded);
                    return parsed;
                }, [searchParams]);

                return {
                    value: params,
                    onChange(values) {
                        console.log('changing', values);
                        searchParams.set(
                            URL_KEY,
                            encodeURIComponent(JSON.stringify(values))
                        );
                        setQueryParams(searchParams, { replace: true });
                    },
                };
            },
        },
        filter: {
            useState(context) {
                const [searchParams, setQueryParams] = useSearchParams();
                const URL_KEY = 'filters';

                const params = useMemo(() => {
                    const encoded = searchParams.get(URL_KEY) ?? '{}';
                    const decoded = decodeURIComponent(encoded);
                    const parsed = JSON.parse(decoded);
                    return parsed;
                }, [searchParams]);

                return {
                    value: params,
                    onChange(values) {
                        searchParams.set(
                            URL_KEY,
                            encodeURIComponent(
                                JSON.stringify(
                                    Object.entries(values).reduce(
                                        (acc, [key, value]) =>
                                            value ? { ...acc, [key]: value } : acc,
                                        {}
                                    )
                                )
                            )
                        );
                        setQueryParams(searchParams, { replace: true });
                    },
                };
            },
        },
        segment: {
            useState(context) {
                const [searchParams, setQueryParams] = useSearchParams();
                const URL_KEY = 'segment';

                const params = useMemo(() => {
                    const encoded = searchParams.get(URL_KEY) ?? '{}';
                    const decoded = decodeURIComponent(encoded);
                    const parsed = JSON.parse(decoded);
                    return parsed;
                }, [searchParams]);

                return {
                    value: params,
                    onChange(values) {
                        searchParams.set(
                            URL_KEY,
                            encodeURIComponent(
                                JSON.stringify(
                                    Object.entries(values).reduce(
                                        (acc, [key, value]) =>
                                            value ? { ...acc, [key]: value } : acc,
                                        {}
                                    )
                                )
                            )
                        );
                        setQueryParams(searchParams, { replace: true });
                    },
                };
            },
        },
    };
}
