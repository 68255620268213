import { useMemo } from 'react';
import { buildChart } from '../../../domain/report';
import { ChartServiceConfig } from './chartConfig';
import { ReportChartService } from './chartInterface';

export function createChartService(init: ChartServiceConfig): ReportChartService {
    return {
        useChart(props) {
            const chart = buildChart(props);
            return chart;
        },
    };
}
