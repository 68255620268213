import { VisualizationController } from '../../../route';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { useEffect } from 'react';
import { AnyAnalysisTrackingEvent } from './trackingMetricEvent';

export function createMetricTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyAnalysisTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: VisualizationController
        ): VisualizationController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();
                    useEffect(() => {
                        tracker.track('metric_visualization_view_loaded', {
                            visualization_kind: viewProps.visualization.kind,
                            metric_definition_ids: viewProps.visualization.metrics.map(
                                (item) => item.id
                            ),
                        });
                    }, []);
                    return viewProps;
                },
            };
        }
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                analysis: {
                    ...config.controller.analysis,
                    createVisualization(...args) {
                        const controller = config.controller.analysis.createVisualization(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
