import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
// import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';
import React from 'react';

export type SentryErrorBoundaryProps = ErrorBoundaryProps;

// export { ErrorBoundary };

export const ErrorBoundary: React.FC<
    { children?: React.ReactNode | undefined } & SentryErrorBoundaryProps
> = ({ fallback = <p>An error has occurred</p>, ...props }) => {
    return (
        <SentryErrorBoundary fallback={fallback}>{props.children}</SentryErrorBoundary>
    );
};

// interface ErrorBoundaryProps {
//     fallback?: React.ReactNode;
//     children: React.ReactNode;
// }

// interface ErrorBoundaryState {
//     hasError: boolean;
// }

// export class ErrorBoundary extends React.Component<
//     ErrorBoundaryProps,
//     ErrorBoundaryState
// > {
//     state: ErrorBoundaryState = { hasError: false };

//     static getDerivedStateFromError(error: Error): ErrorBoundaryState {
//         // Update state to show fallback UI on next render
//         return { hasError: true };
//     }

//     componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
//         // You can log the error to an error reporting service here
//         // For example, to Sentry:
//         // Sentry.captureException(error, { extra: errorInfo });
//         console.error('ErrorBoundary caught an error', error, errorInfo);
//     }

//     componentDidMount(): void {
//         console.log('mounting error');
//     }

//     render() {
//         console.log('rendering error boundary');
//         if (this.state.hasError) {
//             // Render the fallback UI
//             return this.props.fallback || <p>An error has occurred.</p>;
//         }
//         return this.props.children;
//     }
// }
