import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { getPluginTraits } from '../../../../domain';
import {
    castConditionsToTraitFilters,
    castTraitFiltersToConditions,
} from './groupEditCast';
import { GroupEditController } from './groupEditInterface';
import { isPeerGroupAggregateValid } from './groupEditInvariant';
import { GroupEditViewProps } from './groupEditProps';

export function createGroupEditController(): GroupEditController {
    return {
        useProps(props): GroupEditViewProps {
            const formValues = props.form.watch();

            const fields = {
                conditions: useFieldArray({
                    name: 'conditions',
                    control: props.form.control,
                }),
            };

            const lookups = {
                property: useMemo(
                    () => getPluginTraits(props.item.plugin),
                    [props.item.plugin]
                ),
            };

            const values = {
                filter: useMemo(
                    () => castConditionsToTraitFilters(formValues.conditions),
                    [formValues.conditions]
                ),
            };

            const formState = {
                isValid: isPeerGroupAggregateValid(
                    {
                        ...props.item,
                        group: { ...props.item.group, conditions: formValues.conditions },
                    },
                    props.query.competitive.data ?? null
                ),
                isDirty: props.form.formState.isDirty,
            };

            return {
                isSubmitting: props.form.formState.isSubmitting,
                isSubmittable:
                    formState.isDirty &&
                    formState.isValid &&
                    !props.query.competitive.isFetching,
                isDirty: formState.isDirty,
                isValid: formState.isValid,
                onSave: props.form.handleSubmit(async (values) => {
                    await props.onSubmit(values);
                    props.form.reset(values);
                }),
                onReset() {
                    props.form.reset();
                    return;
                },
                getFilterProps() {
                    return {
                        plugin: props.item.plugin,
                        value: values.filter,
                        onChange(value) {
                            const conditions = castTraitFiltersToConditions(
                                lookups.property,
                                value
                            );
                            fields.conditions.replace(conditions);
                        },
                    };
                },
                getPreviewProps() {
                    return {
                        item: {
                            plugin: props.item.plugin,
                            conditions: formValues.conditions,
                        },
                        query: {
                            competitive: props.query.competitive,
                        },
                    };
                },
            };
        },
    };
}
