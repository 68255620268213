import { useAuthenticatedContext } from '../../../../container';
import {
    SegmentListViewProps,
    SegmentItemControllerProps,
} from '../../../view/dashboard';
import { AnyFilter, isAssetFilter, isPropertyFilter } from '../../../domain/dashboard';
import { isCurrencyType } from '../../../domain/attributes';
import {
    useControlApi,
    useQueryApi,
    useDashboardApi,
    useDashboardStateApi,
} from '../../../app/dashboard';
import { AssetRepository } from '../../../app/assets';
import { useOrganizationScope } from 'src/v2/app';
import { SegmentListAggregate } from 'src/v2/view/dashboard/segment/segmentModel';

export interface SegmentListViewController {
    (): Omit<SegmentListViewProps, 'renderItem'>;
}

export interface SegmentItemViewController {
    (
        item: SegmentListAggregate,
        filter: AnyFilter,
        index: number
    ): SegmentItemControllerProps;
}

export const useSegmentListController: SegmentListViewController = () => {
    const api = {
        dashboard: useDashboardApi(),
        query: useQueryApi(),
    };
    return {
        hash: api.query.getSegmentHash(),
        value: api.query.getSegment(),
        filters: api.dashboard.getTraits(),
    };
};

export const useSegmentItemViewController: SegmentItemViewController = (
    item,
    filter,
    index
) => {
    const appcontext = useAuthenticatedContext();
    const api = {
        dashboard: useDashboardApi(),
        query: useQueryApi(),
        state: useDashboardStateApi(),
        control: useControlApi(),
    };
    return {
        item: item,
        isDisabled: isPropertyFilter(filter) ? filter.property.isDisabled : false,
        disabledReason: isPropertyFilter(filter) ? filter.property.disabledReason : null,
        disclosure: {
            isOpen: api.state.isExpanded(filter),
            onToggle: api.state.toggleExpanded.bind(null, filter),
        },
        description: isAssetFilter(filter)
            ? `Benchmark against your own companies. To compare exclusively against your own companies, unselect the verticals. If you don't, the selected companies will be added to companies that are part of the selected verticals.`
            : null,
        filter,
        hash: api.query.getSegmentHash(),
        value: api.control.getSegmentFilterValue(filter) ?? null,
        onClear() {
            api.control.setSegmentFilterValue(filter, null);
            return;
        },
        onChange(condition) {
            if (Array.isArray(condition.value) && condition.value.length === 0) {
                api.control.clearSegmentFilterValue(filter);
                return;
            }
            api.control.setSegmentFilterValue(filter, {
                key: filter.property.key,
                operator: filter.default.operator,
                value: condition.value,
            });
        },
        format(type, value, options) {
            if (isCurrencyType(type) && typeof value === 'number') {
                return appcontext.formatter.currency(value, type.currency, options);
            }
            // @ts-expect-error
            return value.toString();
        },
        getInputProps(props) {
            return props;
        },
    };
};
