import { useMemo } from 'react';
import { isHighlighted, isSyncing } from './homeIntegrationHelper';
import { HomeIntegrationController } from './homeIntegrationInterface';
import { HomeIntegrationItemProps } from './homeIntegrationProps';

export function createHomeIntegrationController(): HomeIntegrationController {
    return {
        useProps(data, props) {
            const items = useMemo(
                () => data.item.data?.items.filter(isHighlighted).filter(isSyncing) ?? [],
                [data.item.data?.items]
            );

            const itemPropsByPlugin = useMemo(() => {
                return (
                    items.reduce((acc, item) => {
                        const itemProps: HomeIntegrationItemProps = {
                            status: {
                                title: 'Syncing',
                                description: `Integration data is still being synced (estimate up to 3-4 days)`,
                                colorScheme: 'orange',
                                isLoading: true,
                            },
                        };
                        return { ...acc, [item.plugin.id]: itemProps };
                    }, {} as Record<string, HomeIntegrationItemProps>) ?? {}
                );
            }, [items]);

            return {
                items: items,
                getItemProps(item) {
                    const props = itemPropsByPlugin[item.plugin.id];
                    return props;
                },
            };
        },
    };
}
