import * as React from 'react';
import { observer } from 'mobx-react';
import { OrganizationRole } from '../../../models/Account';
import { Box, VStack, Text, Heading, Flex, Spinner } from '@chakra-ui/react';
import { useStore } from '../../../stores/setupContext';
import { ErrorInfo } from '../../../models/Errors';
import ErrorView from '../../../components/ErrorView/ErrorView';
import LoadingWrapper from '../../../components/LoadingWrapper/LoadingWrapper';

export interface ChooseOrganizationProps {
    organizations: Array<OrganizationRole>;
    isLoading: boolean;
    connectToOrg: (orgId: number) => void;
    err: ErrorInfo | null;
}
const RoleNames: Record<OrganizationRole['role'], string> = {
    admin: 'Admin',
    standard: 'Default',
    viewer: 'Viewer',
};
const ChooseOrganization: React.FC<
    { children?: React.ReactNode | undefined } & ChooseOrganizationProps
> = ({ organizations, isLoading, connectToOrg, err }) => {
    return (
        <Box height="full">
            <LoadingWrapper isLoading={isLoading}>
                <Flex
                    height="full"
                    width="full"
                    justifyContent="center"
                    alignItems="center"
                >
                    <VStack spacing={2} width={{ base: 'full', md: '450px' }}>
                        <Box mb={2}>
                            <Heading fontSize="md">Connect to an organization</Heading>
                        </Box>
                        {organizations.map((o) => (
                            <Box
                                width="full"
                                onClick={() =>
                                    o.organization?.id && connectToOrg(o.organization.id)
                                }
                                _hover={{ borderColor: 'yellow.600', cursor: 'pointer' }}
                                boxShadow="md"
                                padding={3}
                                key={o.organization?.id}
                                borderWidth={1}
                                borderColor="gray.300"
                                borderRadius={3}
                                display={'flex'}
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <Heading fontSize="md">{o.organization?.name}</Heading>
                                <Text fontStyle="italic" fontSize="sm">
                                    {RoleNames[o.role]}
                                </Text>
                            </Box>
                        ))}
                        {err && <ErrorView {...err} />}
                    </VStack>
                </Flex>
            </LoadingWrapper>
        </Box>
    );
};

const Observed = observer(ChooseOrganization);

const WithStoreConnection = () => {
    const {
        auth: { loading: isLoading, currentUser, connectToOrganization, err },
    } = useStore();

    React.useEffect(() => {
        if (currentUser?.organizations && currentUser?.organizations?.length == 1) {
            const firstOrg = currentUser?.organizations[0];
            if (firstOrg && firstOrg.organization?.id) {
                connectToOrganization(firstOrg.organization.id);
            }
        }
    }, [currentUser?.organizations]);
    if (
        currentUser &&
        currentUser.organizations &&
        currentUser.organizations.length > 1
    ) {
        return (
            <Observed
                {...{
                    organizations: currentUser.organizations,
                    isLoading,
                    err,
                    connectToOrg: connectToOrganization,
                }}
            />
        );
    }
    return <Spinner />;
};

export default observer(WithStoreConnection);
