import { useMemo } from 'react';
import { buildReportItemProps, ReportListItemProps } from './item';
import { ReportListConfig } from './reportListConfig';
import { ReportListController } from './reportListInterface';
import { ReportListItemAggregate } from './reportListModel';
import { ReportListStatusProps } from './status';

export function createReportListController(
    config: Pick<ReportListConfig, 'infra'>
): ReportListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, data, props) {
            const itemPropsById = useMemo(() => {
                return (
                    data.item.data?.reduce((acc, item) => {
                        return {
                            ...acc,
                            [item.report.id]: buildReportItemProps(formatter, item),
                        };
                    }, {} as Record<string, ReportListItemProps>) ?? {}
                );
            }, [data.item.data]);

            const statusProps = useMemo((): ReportListStatusProps => {
                if (data.item.data && data.item.data.length === 0) {
                    return {
                        status: 'empty',
                    };
                }
                return {
                    status: data.item.status,
                };
            }, [data.item]);

            return {
                items: data.item.data ?? [],
                getItemProps(item) {
                    const props = itemPropsById[item.report.id];
                    return props;
                },
                getStatusProps() {
                    return statusProps;
                },
            };
        },
    };
}
