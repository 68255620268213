import React from 'react';
import { StudiesResponseDetailContextProvider } from '../../response/detail/studiesResponseDetailInterface';
import {
    StudiesChartRouteController,
    StudiesChartRouteLoader,
} from './studiesChartItemInterface';
import { StudiesChartRouteContainerProps } from './studiesChartItemProps';

export function createStudiesChartRoute(
    context: StudiesResponseDetailContextProvider,
    loader: StudiesChartRouteLoader,
    controller: StudiesChartRouteController
): React.FC<
    { children?: React.ReactNode | undefined } & StudiesChartRouteContainerProps
> {
    return ({ as: As, ...props }) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext, props);
        const viewProps = controller.useProps(viewContext, viewData, props);
        return <As {...viewProps} />;
    };
}
