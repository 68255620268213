import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import {
    BenchmarkListViewProps,
    MetricCollectionEditController,
    MetricCollectionEditProps,
} from '../../../../view';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyHomeMetricTrackingEvent } from './metricTrackingEvent';
import {
    buildCollectionViewPayload,
    buildMetricsViewPayload,
} from './metricTrackingFactory';

export function createHomeMetricTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            strategy: {
                home: { createMetricItem },
            },
        } = config;

        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeMetricTrackingEvent>();
            return tracker;
        }

        function enhanceCollectionController(
            controller: MetricCollectionEditController
        ): MetricCollectionEditController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, data, collection, options] = args;
                    const tracker = useTracker();
                    const props = controller.useProps(context, data, collection, {
                        ...options,
                        onSave(collection) {
                            const payload = buildCollectionViewPayload({
                                ...props,
                                data: { collection },
                            });
                            tracker.track('home_view_metric_edit_submitted', payload);
                            return options.onSave(collection);
                        },
                    });
                    return props;
                },
            };
        }

        function enhanceCollectionComponent(
            Component: React.FC<
                { children?: React.ReactNode | undefined } & MetricCollectionEditProps
            >
        ): React.FC<
            { children?: React.ReactNode | undefined } & MetricCollectionEditProps
        > {
            return (props) => {
                const tracker = useTracker();
                useEffectOnce(() => {
                    const payload = buildCollectionViewPayload(props);
                    tracker.track('home_view_metric_edit_loaded', payload);
                });
                return <Component {...props} />;
            };
        }

        function enhanceBenchmarkComponent(
            Component: React.FC<
                { children?: React.ReactNode | undefined } & BenchmarkListViewProps
            >
        ): React.FC<{ children?: React.ReactNode | undefined } & BenchmarkListViewProps> {
            return (props) => {
                const tracker = useTracker();
                useEffectOnce(() => {
                    const payload = buildMetricsViewPayload(props);
                    tracker.track('home_view_metric_list_loaded', payload);
                });
                return <Component {...props} />;
            };
        }

        const instance = create({
            ...config,
            strategy: {
                ...config.strategy,
                home: {
                    ...config.strategy.home,
                    createMetricItem(config) {
                        return createMetricItem({
                            ...config,
                            createView(viewConfig) {
                                const views = config.createView({ ...viewConfig });
                                return {
                                    ...views,
                                    collection: {
                                        ...views.collection,
                                        controller: enhanceCollectionController(
                                            views.collection.controller
                                        ),
                                        Component: enhanceCollectionComponent(
                                            views.collection.Component
                                        ),
                                    },
                                    benchmark: {
                                        ...views.benchmark,
                                        Component: enhanceBenchmarkComponent(
                                            views.benchmark.Component
                                        ),
                                    },
                                };
                            },
                        });
                    },
                },
            },
        });
        return instance;
    };
}
