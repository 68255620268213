import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { AnyPersona, BillingUsagePlan } from '../../../../domain/billing';
import { SubscriptionEditFormProvider } from './subscriptionFormInterface';
import { SubscriptionFormValues } from './subscriptionFormModel';

export function createSubscriptionFormProvider(): SubscriptionEditFormProvider {
    function getDefaultPersona(plan: BillingUsagePlan): AnyPersona['kind'] {
        if (plan.amount === 0) {
            return 'free';
        }
        return plan.type;
    }
    return {
        useForm(context, item, options) {
            // const plan = item.plans.find((plan) => plan.id === item.subscription.plan);
            const initialValues: SubscriptionFormValues = {
                // persona: plan ? getDefaultPersona(plan) : 'free',
                plan: item.subscription.plan,
                addons: item.subscription.add_ons,
                ...options.initialValues,
            };
            const form = useForm<SubscriptionFormValues>({
                defaultValues: initialValues,
            });
            // const formValues = form.watch();
            return form;
            // return useMemo(
            //     () => ({
            //         ...form,
            //         formState: {
            //             ...form.formState,
            //             isDirty: !isEqual(initialValues, formValues),
            //         },
            //     }),
            //     [form, form.formState, initialValues, formValues]
            // );
        },
    };
}
