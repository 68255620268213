import { GroupEditController, TraitFilterController } from '../../../../view/peer';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { isMaybePartialNumericRangeValue } from '../../../../domain/attributes';
import { AnyPeerGroupEvent } from './trackingPeerGroupEvent';

export function createPeerGroupTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyPeerGroupEvent>();
            return tracker;
        }

        function enhanceGroupController(
            controller: GroupEditController
        ): GroupEditController {
            return {
                ...controller,
                useProps(props) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(props);
                    return {
                        ...viewProps,
                        async onSave(...args) {
                            const response = await viewProps.onSave(...args);
                            tracker.track('peer_group_edit_submitted', {
                                peer_group_plugin_id: props.item.plugin.id,
                                peer_group_condition_keys: props.form
                                    .getValues()
                                    .conditions.map((item) => item.key),
                            });
                            return response;
                        },
                    };
                },
            };
        }

        function enhanceTraitController(
            controller: TraitFilterController
        ): TraitFilterController {
            return {
                ...controller,
                useProps(props) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(props);
                    return {
                        ...viewProps,
                        getPropertyInputProps(...args) {
                            const [item] = args;
                            const original = viewProps.getPropertyInputProps(...args);
                            return {
                                ...original,
                                onChange(value) {
                                    let serialized = value;
                                    if (isMaybePartialNumericRangeValue(value)) {
                                        serialized = [value.from, value.to];
                                    }
                                    tracker.track('peer_group_edit_filter_changed', {
                                        peer_group_plugin_id: props.plugin.id,
                                        filter_key: item.key,
                                        filter_value: serialized,
                                    });
                                    return original.onChange(value);
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                peer: {
                    ...config.controller.peer,
                    createGroupEdit(...args) {
                        const controller = config.controller.peer.createGroupEdit(
                            ...args
                        );
                        return enhanceGroupController(controller);
                    },
                    createTrait(...args) {
                        const controller = config.controller.peer.createTrait(...args);
                        return enhanceTraitController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
