import React from 'react';
import { AssistantContainerBaseConfig } from '../../base';
import { AssistantUrlParamSchema } from '../../assistantSchema';
import {
    AssistantLayoutController,
    AssistantLayoutLoader,
    AssistantLayoutStateProvider,
} from './assistantLayoutInterface';
import {
    AssistantLayoutContainerProps,
    AssistantLayoutViewProps,
} from './assistantLayoutProps';

export function createAssistantLayoutContainer(
    config: AssistantContainerBaseConfig,
    states: AssistantLayoutStateProvider,
    loader: AssistantLayoutLoader,
    controller: AssistantLayoutController,
    View: React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: {
            root: { useContext },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const options = useOptions(AssistantUrlParamSchema);
        const state = states.useState();
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            ...options,
            detail: state.detail,
        });
        return (
            <View {...containerProps} {...props}>
                {children}
            </View>
        );
    };
}
