import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { assert } from '../../util/assert';
import {
    dependentPeriodToAbsolute,
    isAbsolutePeriod,
    isDependentPeriod,
    isRelativePeriod,
    Period,
    periodToString,
    relativePeriodToDates,
} from '../../../domain';
import { DatePickerConfig } from './chakraDatePickerConfig';
import { DatePickerController } from './chakraDatePickerInterface';
import { DatePickerViewProps } from './chakraDatePickerProps';

export function createDatePickerController(
    config: DatePickerConfig
): DatePickerController {
    const {
        infra: { formatter, legacyFormatter },
    } = config;
    return {
        useProps(props): DatePickerViewProps {
            const options = props.options?.period ?? config.config?.options.period ?? [];
            assert(options.length > 0, 'no date options given to selector');

            function getAbsoluteRange(period: Period) {
                if (isDependentPeriod(period)) {
                    const range = dependentPeriodToAbsolute(props.value, period);
                    return range;
                }
                if (isRelativePeriod(period)) {
                    const range = relativePeriodToDates(period);
                    return range;
                }
                return period;
            }

            const [startDate, setStartDate] = useState<Date>(
                () => getAbsoluteRange(props.value).start ?? new Date()
            );
            const [endDate, setEndDate] = useState<Date | null>(null);

            useEffect(() => {
                // update local date picker selected range state
                const range = getAbsoluteRange(props.value);
                setStartDate(range.start);
                setEndDate(range.end);
            }, [props.value]);

            const viewProps: DatePickerViewProps = {
                value: props.value,
                getOptions() {
                    return options;
                },
                getPeriodOptionProps(option) {
                    return {
                        label: option.label,
                        value: option.value,
                        isSelected: isEqual(props.value, option.value),
                        isDisabled: false,
                        Icon: null,
                        onClick() {
                            props.onChange(option.value);
                        },
                    };
                },
                getRangeProps() {
                    return {
                        selectsRange: true,
                        startDate: startDate,
                        endDate: endDate,
                        onChange([start, end]) {
                            setStartDate(start ?? new Date());
                            setEndDate(end);
                            if (start && end) {
                                props.onChange({
                                    start,
                                    end,
                                });
                            }
                        },
                    };
                },
                getPeriodLabel() {
                    return (
                        options.find((item) => isEqual(props.value, item.value))?.label ??
                        'Custom'
                    );
                },
                getAbsoluteRange(period) {
                    if (isRelativePeriod(period)) {
                        const range = relativePeriodToDates(period);
                        return range;
                    }
                    return period;
                },
                getAbsoluteLabel(period, expand) {
                    if (isRelativePeriod(period) && expand) {
                        const range = getAbsoluteRange(period);
                        return formatter.format(
                            'daterange',
                            {
                                from: range.start,
                                to: range.end,
                            },
                            { notation: 'short' }
                        );
                    }
                    if (isRelativePeriod(period)) {
                        return periodToString({ formatter: legacyFormatter }, period);
                    }
                    if (isAbsolutePeriod(period)) {
                        return formatter.format(
                            'daterange',
                            {
                                from: period.start,
                                to: period.end,
                            },
                            { notation: 'short' }
                        );
                    }
                    throw new Error('unexpected date range');
                },
            };

            return viewProps;
        },
    };
}
