import React, { useState } from 'react';
import {
    Box,
    ButtonGroup,
    Text,
    VStack,
    Button as ChakraButton,
    Heading,
    Icon,
    HStack,
    Tooltip,
    StackDivider,
    Center,
    Grid,
    GridItem,
    InputGroup,
    Input,
    SimpleGrid,
    InputLeftElement,
    InputRightElement,
    Spinner,
    IconButton,
    Image,
    Wrap,
    Tag,
    WrapItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useToken,
    Portal,
    Alert,
    AlertTitle,
    AlertIcon,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import {
    AiOutlineArrowRight,
    AiOutlineClose,
    AiOutlinePlus,
    AiOutlineSearch,
} from 'react-icons/ai';
import { CardSpinner } from '../../../../domain';
import { Icons } from '../../../../config';
import {
    PeerCompanyItemViewProps,
    CompanyListBoxMacro,
    CompanyListboxMatchViewProps,
    CompanyListboxSelectViewProps,
} from '../../../macro';
import { VAROS_GREEN, VAROS_YELLOW } from '../../../config';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingCompetitiveSetViewProps } from './onboardingCompetitiveSetProps';
import { OnboardingCompetitiveSetConfig } from './onboardingCompetitiveSetConfig';

export function createOnboardingCompetitiveSetView(
    config: OnboardingCompetitiveSetConfig & OnboardingStrategyConfig
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingCompetitiveSetViewProps
> {
    const {
        UI: { Anchor },
        macro: {
            company: {
                select: {
                    Component: { Match: MatchContainer, Select: SelectContainer },
                },
            },
        },
        Layout: { Container, Content, Button },
    } = config;

    const CompanyCard: React.FC<
        { children?: React.ReactNode | undefined } & PeerCompanyItemViewProps
    > = (props) => {
        const scrollbarBg = useToken('colors', 'gray.400');
        const scrollbarHoverBg = useToken('colors', 'gray.500');
        return (
            <VStack align="start" w="full" spacing={3}>
                <HStack spacing={4} color="gray.700">
                    {props.company.iconUrl && (
                        <Box borderWidth={1} borderColor="gray.200" flexShrink={0}>
                            <Image
                                w={9}
                                h={9}
                                src={props.company.iconUrl}
                                borderRadius="sm"
                            />
                        </Box>
                    )}
                    {!props.company.iconUrl && (
                        <Box p={3} bg="gray.100" borderRadius="sm" flexShrink={0}>
                            <Icon as={Icons.Peers.Company} />
                        </Box>
                    )}
                    <VStack align="start" w="full" spacing={0}>
                        <Text
                            fontWeight="semibold"
                            noOfLines={1}
                            wordBreak="break-word"
                            color="black"
                        >
                            {props.company.name}
                        </Text>
                        <Anchor
                            {...props.company.anchor.website}
                            _hover={{
                                textDecor: 'underline',
                                textDecorationColor: 'gray.600',
                            }}
                        >
                            <Text color="gray.600" cursor="pointer">
                                {props.company.domain}
                            </Text>
                        </Anchor>
                    </VStack>
                </HStack>
                {props.company.industry && (
                    <VStack spacing={1} w="full" align="start">
                        <Wrap shouldWrapChildren={true}>
                            <Tag size="sm" borderRadius="sm" colorScheme="blue">
                                {props.company.industry}
                            </Tag>
                        </Wrap>
                    </VStack>
                )}
                {props.company.description && (
                    <Box
                        w="full"
                        maxH="15vh"
                        overflowY="auto"
                        css={{
                            '&::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '0.5rem',
                            },
                            '&::-webkit-scrollbar-track': {},
                            '&::-webkit-scrollbar-corner': {
                                '-webkit-appearance': 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '1rem',
                                background: scrollbarBg,
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: scrollbarHoverBg,
                            },
                        }}
                    >
                        <Text color="gray.700" wordBreak="break-word">
                            {props.company.description}
                        </Text>
                    </Box>
                )}
                <Wrap
                    spacingX={3}
                    spacingY={1}
                    color="gray.600"
                    fontSize="sm"
                    fontWeight="semibold"
                    whiteSpace="nowrap"
                >
                    {props.company.employees && (
                        <WrapItem>
                            <Text>{props.company.employees} employees</Text>
                        </WrapItem>
                    )}
                    {props.company.revenue && (
                        <WrapItem>
                            <Text>{props.company.revenue} revenue</Text>
                        </WrapItem>
                    )}
                    {props.company.visitors && (
                        <WrapItem>
                            <Text>{props.company.visitors} monthly visitors</Text>
                        </WrapItem>
                    )}
                </Wrap>
            </VStack>
        );
    };

    const CompanyPopover: React.FC<
        { children?: React.ReactNode | undefined } & PeerCompanyItemViewProps
    > = (props) => {
        return (
            <Popover
                placement="top"
                trigger="hover"
                isLazy={true}
                openDelay={500}
                lazyBehavior="unmount"
            >
                <PopoverTrigger>{props.children}</PopoverTrigger>
                <Portal>
                    <PopoverContent
                        p={6}
                        minW="28rem"
                        _focus={{ outline: 'none' }}
                        borderWidth={1}
                        borderColor="gray.300"
                    >
                        <CompanyCard {...props} />
                    </PopoverContent>
                </Portal>
            </Popover>
        );
    };

    const CompanySearchItem: React.FC<
        { children?: React.ReactNode | undefined } & PeerCompanyItemViewProps
    > = (props) => {
        return (
            <HStack
                borderWidth={1}
                bg="white"
                borderColor="gray.300"
                borderRadius="md"
                p={6}
                justify="space-between"
                boxShadow="sm"
            >
                <HStack spacing={4}>
                    {props.company.iconUrl && (
                        <Box borderWidth={1} borderColor="gray.200">
                            <Image
                                w={9}
                                h={9}
                                src={props.company.iconUrl}
                                borderRadius="sm"
                            />
                        </Box>
                    )}
                    {!props.company.iconUrl && (
                        <Box p={3} bg="gray.100" borderRadius="sm">
                            <Icon as={Icons.Peers.Company} />
                        </Box>
                    )}
                    <VStack align="start" spacing={0}>
                        <HStack spacing={3}>
                            <CompanyPopover {...props}>
                                <Text
                                    fontWeight="semibold"
                                    noOfLines={1}
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                >
                                    {props.company.name}
                                </Text>
                            </CompanyPopover>
                            {props.company.highlight && (
                                <Tooltip
                                    placement="top"
                                    p={3}
                                    hasArrow={true}
                                    label={props.company.highlight.description}
                                >
                                    <HStack
                                        fontWeight="medium"
                                        cursor="default"
                                        spacing={1}
                                        userSelect="none"
                                        color="yellow.500"
                                    >
                                        <Icon as={props.company.highlight.Icon} />
                                        <Text>{props.company.highlight.label}</Text>
                                    </HStack>
                                </Tooltip>
                            )}
                        </HStack>
                        <Text color="gray.500" noOfLines={1}>
                            {props.company.domain}
                        </Text>
                    </VStack>
                </HStack>
                <IconButton
                    {...props.select?.button}
                    size="md"
                    aria-label="add"
                    _focus={{
                        outline: 'none',
                    }}
                    icon={<Icon as={AiOutlinePlus} />}
                />
            </HStack>
        );
    };

    const CompanySelectItem: React.FC<
        { children?: React.ReactNode | undefined } & PeerCompanyItemViewProps
    > = (props) => {
        return (
            <HStack w="full" justify="space-between">
                <HStack spacing={3}>
                    {props.company.iconUrl && (
                        <Box borderWidth={1} borderColor="gray.200">
                            <Image w={4} h={4} src={props.company.iconUrl} />
                        </Box>
                    )}
                    {!props.company.iconUrl && <Icon as={Icons.Peers.Company} />}
                    <CompanyPopover {...props}>
                        <Text
                            cursor="default"
                            noOfLines={1}
                            _hover={{ textDecor: 'underline' }}
                        >
                            {props.company.name}
                        </Text>
                    </CompanyPopover>
                </HStack>
                <IconButton
                    {...props.select?.button}
                    size="sm"
                    variant="ghost"
                    aria-label="remove"
                    icon={<Icon as={AiOutlineClose} />}
                    _focus={{ outline: 'none' }}
                />
            </HStack>
        );
    };

    const MatchView: React.FC<
        { children?: React.ReactNode | undefined } & CompanyListboxMatchViewProps
    > = (props) => {
        return (
            <Grid w="full" h="full" templateRows="min-content 1fr" gap={6}>
                <GridItem>
                    <HStack w="full">
                        <InputGroup>
                            <InputLeftElement>
                                <Icon color="gray.400" as={AiOutlineSearch} />
                            </InputLeftElement>
                            <Input
                                {...props.search.input}
                                bg="white"
                                borderColor="gray.300"
                                placeholder="Search companies"
                                _hover={{ borderColor: 'gray.400' }}
                                _placeholder={{ color: 'gray.400' }}
                                borderRadius="full"
                                _focus={{
                                    outline: 'none',
                                    borderColor: 'gray.500',
                                }}
                            />
                            {props.search.isLoading && (
                                <InputRightElement mx={1}>
                                    <HStack justify="end">
                                        <Spinner
                                            color="gray.400"
                                            size="sm"
                                            speed="0.5s"
                                        />
                                    </HStack>
                                </InputRightElement>
                            )}
                            {!props.search.isLoading && props.search.clear && (
                                <InputRightElement mx={1}>
                                    <IconButton
                                        {...props.search.clear}
                                        variant="link"
                                        aria-label="clear"
                                        icon={<Icon as={AiOutlineClose} />}
                                        color="gray.400"
                                        _focus={{ outline: 'none' }}
                                        _hover={{ color: 'gray.500' }}
                                    />
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </HStack>
                </GridItem>
                <GridItem>
                    <SimpleGrid w="full" h="full" columns={1} overflowY="scroll" gap={3}>
                        {props.items.map((item) => (
                            <CompanySearchItem
                                key={item.company.id}
                                {...props.getItemProps(item)}
                            />
                        ))}
                    </SimpleGrid>
                </GridItem>
            </Grid>
        );
    };

    const SelectView: React.FC<
        { children?: React.ReactNode | undefined } & CompanyListboxSelectViewProps
    > = (props) => {
        if (props.collection.status === 'empty') {
            return (
                <Center w="full" py={12}>
                    <VStack
                        align="start"
                        h="full"
                        justify="center"
                        maxW="16rem"
                        spacing={3}
                    >
                        <Box p={3} bg="gray.200">
                            <Icon w={6} h={6} as={Icons.Peers.Company} />
                        </Box>
                        <VStack align="start" spacing={1}>
                            <Text cursor="default" fontWeight="semibold" color="gray.800">
                                {props.collection.label}
                            </Text>
                            <Text cursor="default" color="gray.600">
                                {props.collection.description}
                            </Text>
                        </VStack>
                    </VStack>
                </Center>
            );
        }
        return (
            <VStack align="start" w="full">
                <VStack
                    align="start"
                    w="full"
                    divider={<StackDivider borderColor="gray.200" borderWidth={1} />}
                    spacing={4}
                >
                    <HStack w="full" justify="space-between">
                        <Text fontWeight="medium" color="gray.600">
                            {props.collection.label}
                        </Text>
                        {props.action.clear && (
                            <ChakraButton
                                {...props.action.clear}
                                variant="link"
                                _focus={{ outline: 'none' }}
                                size="sm"
                            >
                                Clear
                            </ChakraButton>
                        )}
                    </HStack>
                    <VStack align="start" w="full" fontWeight="medium">
                        {props.items.map((item) => (
                            <CompanySelectItem
                                key={item.company.id}
                                {...props.getItemProps(item)}
                            />
                        ))}
                    </VStack>
                </VStack>
            </VStack>
        );
    };

    return (props) => {
        return (
            <form
                {...props.company.form.getFormElementProps()}
                style={{ width: '100%', height: '100%' }}
            >
                <Container
                    title={
                        <HStack spacing={3}>
                            <Text>Choose your competitors</Text>
                            <Tooltip
                                placement="top"
                                hasArrow={true}
                                p={2}
                                label="This feature is in beta and is still being tested"
                            >
                                <Tag
                                    size="lg"
                                    colorScheme="green"
                                    textTransform="uppercase"
                                    letterSpacing="wide"
                                    fontWeight="semibold"
                                    cursor="default"
                                >
                                    Beta
                                </Tag>
                            </Tooltip>
                        </HStack>
                    }
                    description={
                        <VStack align="start">
                            <Text>
                                Handpick specific companies for a more relevant and
                                tailored
                                <br />
                                benchmarking experience
                            </Text>
                            <Anchor
                                {...props.company.anchor.documentation}
                                target="_blank"
                                fontWeight="medium"
                                color="blue.500"
                                cursor="pointer"
                                _hover={{ textDecor: 'underline' }}
                            >
                                Learn more about competitive sets
                            </Anchor>
                        </VStack>
                    }
                    rightContent={
                        <HStack align="center" spacing={3}>
                            <ChakraButton {...props.action.skip} variant="ghost">
                                Skip
                            </ChakraButton>
                            <Tooltip
                                {...props.action.submit.tooltip}
                                placement="top"
                                hasArrow={true}
                                p={2}
                            >
                                <Box as="span">
                                    <ChakraButton
                                        {...props.action.submit.button}
                                        colorScheme="green"
                                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Continue
                                    </ChakraButton>
                                </Box>
                            </Tooltip>
                        </HStack>
                    }
                >
                    <Content isLoading={false}>
                        <Grid
                            pt={6}
                            w="full"
                            h="full"
                            templateColumns="5fr 3fr"
                            gap={12}
                            // minHeight="calc(100vh - 200px)"
                        >
                            <GridItem>
                                <MatchContainer {...props.company.match} as={MatchView} />
                            </GridItem>
                            <GridItem
                                position="relative"
                                borderLeftWidth={2}
                                borderColor="gray.200"
                                pl={12}
                            >
                                <VStack
                                    align="start"
                                    w="full"
                                    spacing={6}
                                    position="sticky"
                                    top={12}
                                    maxH="calc(100vh - 200px)"
                                    overflowY="auto"
                                >
                                    {props.company.form.error && (
                                        <Alert
                                            w="full"
                                            status={props.company.form.error.status}
                                        >
                                            <AlertIcon />
                                            <AlertTitle>
                                                {props.company.form.error.label}
                                            </AlertTitle>
                                        </Alert>
                                    )}
                                    <Box w="full">
                                        <SelectContainer
                                            {...props.company.select}
                                            as={SelectView}
                                        />
                                    </Box>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Content>
                </Container>
            </form>
        );
    };
}
