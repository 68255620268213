import { EmailWorkspaceServiceConfig } from './emailWorkspaceConfig';
import { EmailWorkspaceService } from './emailWorkspaceInterface';

export function createWorkspaceService(
    config: EmailWorkspaceServiceConfig
): EmailWorkspaceService {
    const {
        adapter: {
            workspace: { useWorkspace },
        },
    } = config;
    return {
        useWorkspace(reference) {
            const workspace = useWorkspace({
                workspace: reference,
            });
            return workspace;
        },
    };
}
