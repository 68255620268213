import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { SettingDataSourceConfig } from './sourceConfig';
import { createSettingDataSourceContainer } from './sourceContainer';
import { createSettingDataSourceController } from './sourceController';
import { createSettingDataSourceLoader } from './sourceLoader';
import { createSettingDataSourceEmptyView } from './view/empty';
import { createSettingDataSourceListView } from './view/list/sourceListView';

export function createSettingDataSourceStrategy(
    init: SettingDataSourceConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const controller = createSettingDataSourceController(init, config);
            const loader = createSettingDataSourceLoader(init, config);
            const Container = createSettingDataSourceContainer(
                init,
                config,
                loader,
                controller
            );
            const View = {
                List: createSettingDataSourceListView(init, config),
                Empty: createSettingDataSourceEmptyView(init, config),
            };
            return [
                {
                    scope: 'data',
                    path: 'data-sources',
                    key: 'sources',
                    title: 'Sources',
                    Component() {
                        return <Container as={View} />;
                    },
                    children: [
                        {
                            ...init.strategy.mapping.create(config)[0],
                            path: 'edit',
                            key: 'edit',
                            title: 'Edit',
                        },
                    ],
                },
            ];
        },
    };
}
