import React from 'react';
import { RegistrationContainerConfig } from '../base';
import { RegistrationLayoutController } from './registrationLayoutInterface';
import {
    RegistrationLayoutContainerProps,
    RegistrationLayoutViewProps,
} from './registrationLayoutProps';

export function createRegisetrationLayoutContainer(
    config: RegistrationContainerConfig,
    controller: RegistrationLayoutController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & RegistrationLayoutViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & RegistrationLayoutContainerProps
> {
    return (containerProps) => {
        const props = controller.useProps({});
        return <View {...props}>{containerProps.children}</View>;
    };
}
