import { TypeOf } from 'zod';
import { NavigationState } from './navigationTrackingModel';
import { StateSchema } from './navigationTrackingSchema';

export function fromRaw(value: unknown) {
    const parsed = StateSchema.safeParse(value);
    if (!parsed.success) {
        return null;
    }
    return parsed.data;
}

export function toRaw(state: NavigationState): TypeOf<typeof StateSchema> {
    return {
        source_link_id: state.source_link_id,
        source_path: state.source_path,
        source_page_title: state.source_page_title,
    };
}
