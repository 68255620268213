import React from 'react';
import { Select, SelectProps } from '@chakra-ui/react';
import FormCtrl from './FormCtrl';
import { Option } from '../../models/Common';

export interface FormCtrlSelectProps extends SelectProps {
    label?: string;
    helperText?: string;
    err?: string;
    options?: Option[];
    children?: React.ReactNode;
}
function isOptions(opt: React.ReactNode | Option[]): opt is Option[] {
    return (Array.isArray(opt) && opt.length > 0 && (opt[0] as Option).value) !== undefined;
}

export default React.forwardRef<HTMLSelectElement, FormCtrlSelectProps>(function (
    { label, helperText, err, options, children, ...rest }: FormCtrlSelectProps,
    ref
) {
    return (
        <FormCtrl {...{ label, helperText, err }}>
            <Select ref={ref} {...rest}>
                {options
                    ? options.map(({ name, value }) => (
                          <option key={value} value={value}>
                              {name}
                          </option>
                      ))
                    : children}
            </Select>
        </FormCtrl>
    );
});
