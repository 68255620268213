import {
    OrganizationLayoutBody,
    createOrganizationLayoutWrapper,
    createOrganizationLayoutLink,
    OrganizationLayoutContext,
    OrganizationLayoutBrand,
    OrganizationLayoutNotifications,
} from './component';
import { OrganizationLayoutItem } from './component/OrganizationLayoutItem';
import { OrganizationLayoutNavigation } from './component/OrganizationLayoutNavigation';
import { OrganizationLayoutMenu } from './component/OrganizationLayoutMenu';
import { OrganizationLayoutDatasetText } from './component/OrganizationLayoutText';
import { OrganizationLayoutConfig } from './organizationLayoutConfig';

export function createOrganizationLayout(config: OrganizationLayoutConfig) {
    const Link = createOrganizationLayoutLink(config);
    return {
        Wrapper: createOrganizationLayoutWrapper({
            ...config,
            OrganizationLayoutLink: Link,
        }),
        Context: OrganizationLayoutContext,
        Menu: OrganizationLayoutMenu,
        Body: OrganizationLayoutBody,
        Brand: OrganizationLayoutBrand,
        Navigation: OrganizationLayoutNavigation,
        Item: OrganizationLayoutItem,
        Link,
        DatasetText: OrganizationLayoutDatasetText,
        Dropdown: OrganizationLayoutNotifications,
    };
}

export type OrganizationLayout = ReturnType<typeof createOrganizationLayout>;
