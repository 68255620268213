import { TaxonomyLike } from '../models/Taxonomy';
import * as lodash from 'lodash';
import { plural } from 'pluralize';

export const ASSET_TYPE_DICT: Record<string, string> = {
    mobile_app: 'Mobile Apps',
    website: 'Websites',
    ecommerce_website: 'Online Stores',
    saas: 'SaaS',
};

export function getValueByAnswer(val: any, yAxisQuestion: TaxonomyLike): string {
    if (val === null || val === undefined) {
        return 'N/A';
    }
    switch (yAxisQuestion.value_type) {
        case 'money':
            let retVal = `$${numberWithCommas(val, true)}`;
            return retVal;
        case 'percentage':
            const vvalue = Math.abs(val);

            return `${vvalue >= 10 || vvalue == 0 ? val.toFixed(0) : val.toFixed(2)}%`;
        case 'counter':
            return numberWithCommas(val, false);
        default:
            return val.toFixed(2);
    }
}

export function getValueByAnswerNew(val: any, yAxisQuestion: TaxonomyLike): string {
    if (val === null || val === undefined) {
        return 'N/A';
    }
    switch (yAxisQuestion.value_type) {
        case 'money':
            let retVal = `$${numberWithCommas(val, true)}`;
            return retVal;
        case 'percentage':
            const vvalue = Math.abs(val);

            return `${vvalue >= 10 || vvalue == 0 ? (val * 100).toFixed(0) : (val * 100).toFixed(2)}%`;
        case 'counter':
            return numberWithCommas(val, false);
        default:
            return val.toFixed(2);
    }
}

function numberWithCommas(x: number, allowDecimalPoint: boolean) {
    let retVal: string = allowDecimalPoint ? (Math.abs(x) > 100 ? x.toFixed(0) : x.toFixed(2)) : x.toFixed(0);
    return retVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function beutify(key: string) {
    return key
        .split('_')
        .map((x) => x.trim())
        .filter((x) => x.length > 0)
        .map((x) => x[0].toUpperCase() + x.substring(1))
        .join(' ');
}

export function pluralize(string: string, count: number) {
    return count > 1 ? plural(string) : string;
}

export function capitalize(value: string) {
    return lodash.capitalize(value);
}
