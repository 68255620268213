import React from 'react';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { VisualizationController, VisualizationLoader } from './visualizationInterface';
import {
    VisualizationControllerProps,
    VisualizationViewProps,
} from './visualizationProps';

export function createVisualizationContainer(
    config: AnalysisRouteDeps,
    loader: VisualizationLoader,
    controller: VisualizationController,
    Component: React.FC<
        { children?: React.ReactNode | undefined } & VisualizationViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & VisualizationControllerProps> {
    const {
        hook: { useQuery },
    } = config;
    return (props) => {
        const query = useQuery({
            queryKey: [
                'analyze',
                'visualization',
                props.context.workspace,
                props.request,
            ],
            queryFn() {
                return loader.load(props);
            },
            retry: false,
            suspense: true,
            staleTime: Infinity,
        });
        const viewProps = controller.useProps(query.data!, props);
        return <Component {...viewProps} />;
    };
}
