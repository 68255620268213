import { CreateEnumRecommendationRequest, getRecommendations } from '../../../api';
import { createRecommendationProvider } from '../../app';

export function configureRecommendationProvider() {
    return createRecommendationProvider({
        createAdapter({ axios }) {
            return {
                async getRecommendations(request: CreateEnumRecommendationRequest) {
                    const response = await getRecommendations(axios, request);
                    return response.data;
                },
            };
        },
    });
}
