import React from 'react';
import { fetchAllChildrenProps } from '../../../util';
import { ButtonSelectConfig } from './buttonSelectConfig';
import {
    ButtonSelectItemProps,
    ButtonSelectOptionProps,
    ButtonSelectProps,
} from './buttonSelectProps';

export function createButtonSelect<TValue>(config: ButtonSelectConfig<TValue>) {
    const {
        Component: { List, Item },
    } = config;

    function getItemProps(
        listProps: ButtonSelectProps<TValue>,
        optionProps: ButtonSelectOptionProps<TValue>
    ): ButtonSelectItemProps<TValue> {
        return {
            ...optionProps,
            isSelected: optionProps.value == listProps.value,
            onClick: listProps.onChange.bind(null, optionProps.value),
        };
    }

    const _Select: React.FC<
        { children?: React.ReactNode | undefined } & ButtonSelectProps<TValue>
    > = (props) => {
        const { option: optionProps } = fetchAllChildrenProps(props.children, {
            option: _Option,
        });
        return (
            <List>
                {optionProps.map((item, index) => (
                    <Item key={index} {...getItemProps(props, item)} />
                ))}
            </List>
        );
    };

    const _Option: React.FC<
        { children?: React.ReactNode | undefined } & ButtonSelectOptionProps<TValue>
    > = () => {
        return <></>;
    };

    return {
        Select: _Select,
        Option: _Option,
    };
}
