import { keyBySafe } from '../../util';
import { Pagination } from '../pagination';

export interface CollectionOld<TEntity> {
    items: TEntity[];
    getPath(entity: TEntity): string;
    isActive(entity: TEntity): boolean;
}

export interface LoadingCollection {
    status: 'loading';
    data: null;
}

// export interface RefreshingCollection {
//     status: 'refreshing';
//     data: null;
// }

export interface EmptyCollection {
    status: 'empty';
    data: null;
}

export interface ErrorCollection {
    status: 'error';
    data: null;
    error: Error;
}

export interface LoadedCollection<TEntity> {
    status: 'loaded';
    data: Pagination<TEntity>;
}

export type AnyCollection<TEntity> =
    | LoadingCollection
    // | RefreshingCollection
    | EmptyCollection
    | ErrorCollection
    | LoadedCollection<TEntity>;

export type CollectionStatus = AnyCollection<unknown>['status'];

export interface NormalizedCollection<TItem> {
    ids: string[];
    byId: Record<string, TItem | undefined>;
}

export interface NormalizedCollectionItem {
    id: string | number;
}

export function getCollectionItems<TItem extends NormalizedCollectionItem>(
    collection: NormalizedCollection<TItem>
): TItem[] {
    return collection.ids.flatMap((id) => {
        const found = collection.byId[id];
        if (!found) {
            return [];
        }
        return [found];
    });
}

export function normalizeCollection<TItem extends NormalizedCollectionItem>(
    items: TItem[]
): NormalizedCollection<TItem> {
    return {
        ids: items.map((item) => item.id.toString()),
        byId: keyBySafe(items, (item) => item.id.toString()),
    };
}
