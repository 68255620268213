import { useState } from 'react';
import { AssetListStateProvider } from './assetListInterface';
import { AssetListStateValue } from './assetListModel';

export function createAssetStateListProvider(): AssetListStateProvider {
    return {
        useState(context, initialValues) {
            const [state, setState] = useState<AssetListStateValue>({
                search: '',
                ...initialValues,
            });
            return {
                value: state,
                onChange: setState,
            };
        },
    };
}
