import { ComparisonOption, PeriodOption } from './homeRouteProps';

export const PERIOD_INTERVALS: PeriodOption[] = [
    {
        label: 'Last 7 days',
        value: {
            amount: 7,
            interval: 'day',
        },
    },
    {
        label: 'Last 4 weeks',
        value: {
            amount: 4,
            interval: 'week',
        },
    },
    {
        label: 'Last 3 months',
        value: {
            amount: 3,
            interval: 'month',
        },
    },
    {
        label: 'Last 6 months',
        value: {
            amount: 6,
            interval: 'month',
        },
    },
    {
        label: 'Last 12 months',
        value: {
            amount: 12,
            interval: 'month',
        },
    },
];

export const COMPARISON_INTERVALS: ComparisonOption[] = [
    {
        label: 'Previous period',
        value: {
            amount: 1,
            interval: 'period',
        },
    },
    {
        label: 'Previous week',
        value: {
            amount: 1,
            interval: 'week',
        },
    },
    {
        label: 'Previous month',
        value: {
            amount: 1,
            interval: 'month',
        },
    },
    {
        label: 'Previous year',
        value: {
            amount: 1,
            interval: 'year',
        },
    },
];
