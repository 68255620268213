import { runInAction, makeObservable, observable } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { DashboardReference, AnyFilter } from '../../../domain/dashboard';
import { AnyCondition } from '../../../domain/attributes';
import { CohortState } from '../../../domain/dashboard';
import { ControlStore } from '../../../app';
import { ControlStoreConfig } from './controlStoreConfig';

export interface ControlStateValue {
    filters?: Record<string, AnyCondition | null | undefined>;
    segments?: Record<string, AnyCondition | null | undefined>;
    cohort?: CohortState | null;
    group?: string[];
    filterMode?: 'fixed' | 'dynamic';
}

export class ControlStoreImpl extends StoreBase implements ControlStore {
    _state: Record<string, ControlStateValue | undefined>;
    _generation: number;

    constructor(public config: ControlStoreConfig) {
        super();
        this._state = config.initialValues;
        this._generation = 0;
        makeObservable(this, {
            _state: observable,
            _generation: observable,
        });
    }

    getStateHash(): string {
        return this._generation.toString();
    }

    reset(dashboard: DashboardReference) {
        runInAction(() => {
            this._state[dashboard.id] = this.config.initialValues[dashboard.id] ?? null;
            this._generation += 1;
        });
    }

    // Filters

    setDataFilter(
        dashboard: DashboardReference,
        filter: AnyFilter,
        value: AnyCondition | null
    ) {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    filters: {
                        ...this._state[dashboard.id]?.filters,
                        [filter.property.key]: value,
                    },
                },
            };
            this._generation += 1;
        });
    }

    clearDataFilter(dashboard: DashboardReference, filter: AnyFilter) {
        runInAction(() => {
            delete this._state[dashboard.id]?.filters?.[filter.property.key];
            this._generation += 1;
        });
    }

    getDataFilter(dashboard: DashboardReference, filter: AnyFilter) {
        return this._state[dashboard.id]?.filters?.[filter.property.key];
    }

    // Segment

    setSegmentFilter(
        dashboard: DashboardReference,
        filter: AnyFilter,
        value: AnyCondition | null
    ): void {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    segments: {
                        ...this._state[dashboard.id]?.segments,
                        [filter.property.key]: value,
                    },
                },
            };
            this._generation += 1;
        });
    }

    removeSegmentFilter(dashboard: DashboardReference, filter: AnyFilter): void {
        runInAction(() => {
            delete this._state[dashboard.id]?.segments?.[filter.property.key];
            this._generation += 1;
        });
    }

    getSegmentFilter(
        dashboard: DashboardReference,
        filter: AnyFilter
    ): AnyCondition | null | undefined {
        return this._state[dashboard.id]?.segments?.[filter.property.key];
    }

    // Group

    setGroups(dashboard: DashboardReference, value: string[]): void {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    group: value,
                },
            };
            this._generation += 1;
        });
    }

    removeGroups(dashboard: DashboardReference): void {
        runInAction(() => {
            runInAction(() => {
                delete this._state[dashboard.id]?.group;
                this._generation += 1;
            });
        });
    }

    getGroups(dashboard: DashboardReference): string[] | undefined {
        return this._state[dashboard.id]?.group;
    }

    // Filter mode

    setFilterMode(dashboard: DashboardReference, value: 'dynamic' | 'fixed'): void {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    filterMode: value,
                },
            };
            this._generation += 1;
        });
    }

    getFilterMode(dashboard: DashboardReference): 'dynamic' | 'fixed' | undefined {
        return this._state[dashboard.id]?.filterMode;
    }

    // Cohort

    setCohort(dashboard: DashboardReference, state: CohortState) {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    cohort: state,
                },
            };
            this._generation += 1;
        });
    }

    clearCohort(dashboard: DashboardReference) {
        runInAction(() => {
            delete this._state[dashboard.id]?.cohort;
            this._generation += 1;
        });
    }

    getCohort(dashboard: DashboardReference) {
        return this._state[dashboard.id]?.cohort ?? null;
    }
}
