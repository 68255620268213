import React from 'react';
import { FilterDateView } from '../dashboard';
import { ReportModuleConfig } from './reportConfig';

export function createReportModule(config: ReportModuleConfig) {
    return {
        Container: config.provider.createContainer({
            ...config.deps,
            controller: config.provider.createController({
                hook: {
                    useRouter: config.deps.hook.useRouter,
                    useStore: config.deps.hook.useStore,
                },
                service: config.deps.service,
            }),
            Component: config.provider.createView({
                component: {
                    Layout: config.provider.createLayout(),
                    Chart: {
                        Table: config.provider.createTableContainer({
                            controller: {
                                query: config.provider.createQueryController({
                                    hook: config.deps.hook,
                                }),
                                table: config.provider.createTableController(),
                            },
                            View: config.provider.createTableView({
                                components: config.deps.UI,
                            }),
                        }),
                    },
                    Input: {
                        Date(props) {
                            return (
                                <FilterDateView
                                    enabled={true}
                                    value={props.value}
                                    onChange={props.onChange}
                                />
                            );
                        },
                    },
                },
            }),
        }),
    };
}
