import { useEffect, useMemo } from 'react';
import { buildInputRangeProps } from './inputRangeFactory';
import { RangeInputController } from './inputRangeInterface';

export function createRangeInputController(): RangeInputController {
    return {
        useProps(deps, props) {
            const viewProps = useMemo(
                () => buildInputRangeProps(deps, props),
                [deps, props]
            );
            return viewProps;
        },
    };
}
