import { periodToAbsoluteDateString } from '../../../../domain';
import { InsightController } from '../../../view';
import { HomeAlertConfig } from './homeAlertConfig';
import { HomeAlertController } from './homeAlertInterface';

const TRUNCATE = 8;

export function createHomeAlertController(
    init: HomeAlertConfig,
    controller: InsightController
): HomeAlertController {
    const { hooks } = init;
    return {
        useProps(context, queries, props) {
            const formatter = hooks.useFormatter();
            const viewProps = controller.useProps(context, formatter, queries, {});

            const items = viewProps.items.slice(0, TRUNCATE);
            const truncated = viewProps.items.slice(TRUNCATE);

            return {
                ...viewProps,
                items,
                truncateText:
                    truncated.length > 0 ? `and ${truncated.length} more` : null,
                emptyDescription: periodToAbsoluteDateString({ formatter }, props.period),
            };
        },
    };
}
