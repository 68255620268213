export const IntegrationReasonTypeList = [
    'integrated',
    'connected_integ_no_data_current_asset',
    'connected_integ_no_data',
    'connected_integ_other_brands',
    'connected_integ_unmapped',
    'connected_integ_syncing',
    'connected_integ_empty',
    'connected_integ_delay',
    'no_integration',
] as const;

export type IntegrationReasonType = typeof IntegrationReasonTypeList[number];

export type AnyReasonType = IntegrationReasonType | 'not_sharing' | 'unknown';

export interface IntegrationsRequirement {
    integrations: string[];
}

export interface ViewRequirement {
    views: string[];
}

export interface PipelineRequirement {
    integration_types: string[];
}

export type AnyRequirement = IntegrationsRequirement | ViewRequirement | PipelineRequirement;

interface ExplainedTest {
    test_name: string;
    acl_expression: string;
    requirements_by_condition_name: Record<string, AnyRequirement>;
    mandatory_requirements: AnyRequirement[];
    description?: string;
}

export interface DashboardDependencyDto {
    tests: ExplainedTest[];
}

export interface IntegrationAssetState {
    asset_id: number;
    integration: string;
    integration_id?: number;
    status: IntegrationReasonType;
    reason_text?: string;
    instructions: string[];
}

export interface IntegrationDepState {
    by_integration: Record<string, IntegrationAssetState[]>;
}

export interface ViewAssetState {
    asset_id: number;
    query: string;
    can_execute: boolean;
}

export interface ViewExecutionDepState {
    by_view: Record<string, ViewAssetState[]>;
}


export interface PipelineAssetState {
    dataset: string;
    state: "in_sync" | "in_flight" | "has_issues";
    issue_detail: string | null;
    integration_id: number;
    asset_id: number;
    is_outdated: boolean;
    last_sync: string | null;
}

export interface PipelineDepState {
    type: "pipeline";
    datasets: PipelineAssetState[];
}

export type AnyDepState = IntegrationDepState | ViewExecutionDepState | PipelineDepState;

interface GenericTestExecutionDetails<Requirement, State> {
    requirement: Requirement;
    state: State;
}

export type IntegrationTestExecutionDetails = GenericTestExecutionDetails<
    IntegrationsRequirement,
    IntegrationDepState
>;
export type ViewTestExecutionDetails = GenericTestExecutionDetails<
    ViewRequirement,
    ViewExecutionDepState
>;

export type PipelineTestExecutionDetails = GenericTestExecutionDetails<PipelineRequirement, PipelineDepState>;

export type TestExecutionDetails =
    | IntegrationTestExecutionDetails
    | ViewTestExecutionDetails
    | PipelineTestExecutionDetails;

export interface TestCheckResult {
    test: ExplainedTest;
    execution_details: Array<TestExecutionDetails>;
    test_pass: boolean;
}

export interface DashboardDepsCheckResultDto {
    asset_results: Record<number, boolean | undefined>;
    tests: TestCheckResult[];
}
