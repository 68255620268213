import { z } from 'zod';

export const NetworkRequestNewFormValuesSchema = z.object({
    metric: z.nullable(z.string()),
    // expert_type: z.nullable(z.union([z.literal('operator'), z.literal('agency')])),
    note: z.nullable(z.string().transform((value) => value.trim())),
    budget: z.nullable(z.tuple([z.number(), z.number()])),
    threshold: z.nullable(z.number()),
    filters: z
        .array(
            z.discriminatedUnion('operator', [
                z.object({
                    key: z.string(),
                    operator: z.literal('in'),
                    value: z.array(z.string()),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('between'),
                    value: z.object({
                        from: z.number(),
                        to: z.number(),
                    }),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('gte'),
                    value: z.number(),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('gt'),
                    value: z.number(),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('lte'),
                    value: z.number(),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('lt'),
                    value: z.number(),
                }),
                z.object({
                    key: z.string(),
                    operator: z.literal('eq'),
                    value: z.number(),
                }),
            ])
        )
        .min(1, { message: 'At least one candidate filter must be specified' }),
});
