import { useMemo } from 'react';
import { chain } from 'lodash';
import pluralize, { plural } from 'pluralize';
import { assert } from '../../../../util/assert';
import { IntegrationStatus } from '../../../../domain/assets';
import { PluginConnectionStatus, PluginConnection } from '../../../../domain/metrics';
import { PluginItemControllerProps } from '../item';
import {
    PluginListCollectionProps,
    PluginListSearchProps,
    PluginListViewProps,
} from './pluginListProps';
import { PluginListController } from './pluginListInterface';

export function createPluginListController(): PluginListController {
    const STATUS_ORDER_ASC: PluginConnectionStatus[] = [
        'ready',
        'disconnected',
        'syncing',
        'pending',
    ];
    return {
        useProps(context, deps, props): PluginListViewProps {
            const itemPropsById = useMemo(
                () =>
                    props.item.items.reduce((acc, item) => {
                        const itemProps: PluginItemControllerProps = {
                            item,
                        };
                        return {
                            ...acc,
                            [item.plugin.id]: itemProps,
                        };
                    }, {} as Record<string, PluginItemControllerProps | undefined>),
                [props.item.items]
            );

            const filtered = useMemo(
                () =>
                    chain(props.item.items)
                        .orderBy(
                            (item) => {
                                return [
                                    STATUS_ORDER_ASC.findIndex(
                                        (candidate) =>
                                            candidate === item.connection.status
                                    ),
                                    item.plugin.name,
                                ];
                            },
                            ['asc', 'asc']
                        )
                        .value(),
                [props.item.items]
            );

            // const searchTerm = useMemo(
            //     () => deps.state.value.search.toLocaleLowerCase(),
            //     [deps.state.value.search]
            // );

            // const searched = useMemo(
            //     () =>
            //         chain(filtered)
            //             .filter(
            //                 (item) =>
            //                     (item.integration.key
            //                         ?.toLowerCase()
            //                         .includes(searchTerm) ||
            //                         item.plugin.name
            //                             .toLowerCase()
            //                             .includes(searchTerm)) ??
            //                     true
            //             )
            //             .value(),
            //     [filtered, searchTerm]
            // );

            // const searchProps = useMemo<PluginListSearchProps>(
            //     () => ({
            //         value: deps.state.value.search,
            //         onChange(event) {
            //             deps.state.onChange({
            //                 ...deps.state.value,
            //                 search: event.target.value,
            //             });
            //         },
            //     }),
            //     [deps.state.value.search]
            // );

            // const collectionProps = useMemo<PluginListCollectionProps>(
            //     () =>
            //         searched.length === 0
            //             ? {
            //                   status: 'empty',
            //                   label: null,
            //               }
            //             : {
            //                   status: 'loaded',
            //                   label: `${searched.length} found`,
            //                   //   label: `${searched.length} ${pluralize(
            //                   //       'integration',
            //                   //       searched.length
            //                   //   )} found`,
            //               },
            //     [searched]
            // );

            return {
                items: filtered,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.plugin.id];
                    assert(
                        itemProps,
                        `no item props found for integration ${item.plugin.id}`
                    );
                    return itemProps;
                },
                // getSearchProps() {
                //     return searchProps;
                // },
                // getCollectionProps() {
                //     return collectionProps;
                // },
            };
        },
    };
}
