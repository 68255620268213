import { z } from 'zod';

export const MappableEntityDtoSchema = z.object({
    id: z.number(),
    is_active: z.boolean(),
    id_type: z.string(),
    integration_id: z.number(),
    name: z.nullable(z.string()),
    entity_id: z.string(),
    reason_type: z.string(),
    reason_value: z.nullable(z.string()),
    is_eligible: z.boolean(),
});

export const MappableEntityListResponseSchema = z.object({
    count: z.number(),
    items: z.array(MappableEntityDtoSchema),
    num_pages: z.number(),
    page: z.number(),
    page_size: z.number(),
});
