import {
    Box,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    Text,
    BoxProps,
    Flex,
    LightMode,
    Heading,
    Tooltip,
    useBreakpointValue,
    Icon,
    HStack,
    Divider,
} from '@chakra-ui/react';
import React, { RefObject, useState } from 'react';
import { ArrowForwardIcon, ArrowRightIcon, LockIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
// import { useLockedBody } from '../../scroll';
import { Button } from '../../../ui';
import { isErrorInfo } from '../../../utils/axiosUtils';
import { MdOutlineHelpOutline } from 'react-icons/md';

const Z_INDEX_BACKGROUND = 170;
// const Z_INDEX_MODAL = 175;
const Z_INDEX_MODAL = 10;

export interface FeatureLockProps {
    containerProps?: BoxProps;
    cta?: {
        hint: string;
        error?: string;
    };
    errors?: { message: string; hint?: string | null }[];
    feature: { name: string; description?: React.ReactChild };
    initialLocked: boolean;
    canContinue: boolean;
    onClick?: () => Promise<void> | void;
    content: React.ReactElement;
    status?: React.ReactElement;
}

export const FeatureLock: React.FC<
    { children?: React.ReactNode | undefined } & FeatureLockProps
> = ({ ...props }) => {
    const ctaOnBottom = useBreakpointValue({
        lg: false,
        xl: false,
        '2xl': false,
        '3xl': true,
    });
    const form = useForm({ defaultValues: { locked: props.initialLocked } });
    React.useEffect(() => {
        form.reset({ locked: props.initialLocked });
    }, [props.initialLocked]);
    const values = form.watch();
    const ref = React.useRef<HTMLDivElement>(null);
    const showTooltip = !props.initialLocked && !!props.cta?.hint;
    const handleAccessClick = async () => {
        try {
            props.onClick && (await props.onClick());
            form.setValue('locked', false);
        } catch (error) {
            if (isErrorInfo(error)) {
                return form.setError('locked', { message: error.message });
            }
            if (error instanceof Error) {
                return form.setError('locked', error);
            }

            throw error;
        }
    };

    const hasSubmissionError = !!form.formState.errors.locked?.message;
    const errorMessage = form.formState.errors.locked?.message ?? props.cta?.error;

    const ctaContent = (
        <>
            <VStack mx="auto">
                <Tooltip
                    placement="top"
                    hasArrow={true}
                    label={props.cta?.hint}
                    isDisabled={showTooltip}
                    openDelay={0}
                >
                    <span>
                        <Button
                            // maxW="50%"
                            size={ctaOnBottom ? 'lg' : undefined}
                            rightIcon={<ArrowForwardIcon />}
                            colorScheme="varosGreen"
                            disabled={!props.canContinue}
                            onClick={handleAccessClick}
                        >
                            Access Dashboard
                        </Button>
                    </span>
                </Tooltip>
                <>
                    {(!props.canContinue || hasSubmissionError) && errorMessage && (
                        <Text color="red.500" maxW="66%" textAlign="center">
                            {errorMessage}
                        </Text>
                    )}
                    {props.errors?.map((error, index) => (
                        <HStack
                            key={index}
                            alignItems="center"
                            color="red.500"
                            textAlign="center"
                            spacing={1}
                        >
                            <Text>
                                {error.message}{' '}
                                {error.hint ? (
                                    <Tooltip
                                        placement="top"
                                        openDelay={0}
                                        label={error.hint}
                                        isDisabled={!error.hint}
                                        hasArrow={true}
                                        py={2}
                                        px={4}
                                    >
                                        <span>
                                            <Icon ml={1} as={MdOutlineHelpOutline} />
                                        </span>
                                    </Tooltip>
                                ) : null}
                            </Text>
                        </HStack>
                    ))}
                </>
            </VStack>
        </>
    );

    return (
        <Box position="relative">
            {values.locked && (
                <>
                    <Flex
                        zIndex={Z_INDEX_MODAL}
                        minHeight="100%"
                        justifyContent="center"
                        alignItems={{ xl: 'center' }}
                    >
                        <LightMode>
                            <Box
                                zIndex={Z_INDEX_MODAL}
                                bg="white"
                                mb={48}
                                {...props.containerProps}
                            >
                                <VStack spacing={8}>
                                    <VStack
                                        spacing={4}
                                        maxWidth={[
                                            '100%',
                                            '100%',
                                            '100%',
                                            '100%',
                                            '75%',
                                            '66%',
                                        ]}
                                    >
                                        <Heading fontSize="3xl" m={0}>
                                            {props.feature.name}
                                        </Heading>
                                        {props.feature.description &&
                                            (typeof props.feature.description ==
                                            'string' ? (
                                                <Text align="center">
                                                    {props.feature.description}
                                                </Text>
                                            ) : (
                                                <Box textAlign="center">
                                                    {props.feature.description}
                                                </Box>
                                            ))}
                                        {props.status}
                                    </VStack>
                                    <VStack spacing={8} width="100%">
                                        {!ctaOnBottom && <Box>{ctaContent}</Box>}
                                        {props.content}
                                        {ctaOnBottom && <Box>{ctaContent}</Box>}
                                    </VStack>
                                </VStack>
                            </Box>
                        </LightMode>
                    </Flex>
                    {values.locked ? null : <Box>{props.children}</Box>}
                    {/* <Box
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        height="100%"
                        width="100%"
                        ref={ref}
                        overflow="hidden"
                        {...childrenProps}
                    >
                        <Box
                            zIndex={Z_INDEX_BACKGROUND}
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            bg="blackAlpha.300"
                            backdropFilter="blur(10px) grayscale(100%)"
                        ></Box>
                        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                            {props.children}
                        </Box>
                    </Box> */}
                </>
            )}
            {!values.locked && <Box>{props.children}</Box>}
        </Box>
    );
};
