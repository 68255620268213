import { RegistrationOptionAdapter } from '../../../app';
import { RegistrationOptionImplConfig } from './optionImplConfig';
import { RegistrationOptionDaoSchema } from './optionImplSchema';

export function createRegistrationOptionImpl(
    config: RegistrationOptionImplConfig = {}
): RegistrationOptionAdapter {
    const {} = config;
    const KEY = `vs_registration_options`;
    return {
        async get(context) {
            const raw = window.localStorage.getItem(KEY);
            if (!raw) {
                return null;
            }
            try {
                const json = JSON.parse(raw);
                const parsed = RegistrationOptionDaoSchema.parse(json);
                return {
                    source: parsed.source,
                };
            } catch (error) {
                console.error(`failed to parse registration options`, raw, error);
                window.localStorage.removeItem(KEY);
                return null;
            }
        },
        async put(context, value) {
            if (value === null) {
                window.localStorage.removeItem(KEY);
            } else {
                window.localStorage.setItem(KEY, JSON.stringify(value));
            }
            return;
        },
    };
}
