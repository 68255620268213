import { useQueries } from '@tanstack/react-query';
import React from 'react';
import { WorkspaceLayout as Layout } from '../../layout';
import { WorkspaceRouteConfig } from './workspaceRouteConfig';
import { createWorkspaceRouteLoader } from './workspaceRouteLoader';

export function createWorkspaceRoute(
    config: WorkspaceRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        config: { items: toolbar },
        providers: { createController },
        deps: { layout, container: Container },
    } = config;
    const loader = createWorkspaceRouteLoader();
    const controller = createController(config);
    return (props) => {
        const routeProps = controller.useProps();

        // const queries = Object.entries(loader.queries).map(([key, value]) => ({
        //     name: key,
        //     ...value,
        // }));

        // console.log('QUERIES!!!!', queries);
        // const responses = useQueries({ queries: queries });

        // const responsesMapped = responses.reduce(
        //     (acc, response, index) => ({ ...acc, [queries[index].name]: response }),
        //     {}
        // );
        // console.log('responses', responses);
        // console.log('responsesMapped', responsesMapped);

        return (
            <Layout.Wrapper style={layout}>
                <Layout.Navigation>
                    <Container.Navigation />
                </Layout.Navigation>
                <Layout.Toolbar.Container>
                    {toolbar.map((item, index) => (
                        <Layout.Toolbar.Item key={index} {...item} />
                    ))}
                </Layout.Toolbar.Container>
                <Layout.Loader />
                <Layout.Main>{props.children}</Layout.Main>
            </Layout.Wrapper>
        );
    };
}
