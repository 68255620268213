import { StudyFilterItemViewProps } from '../item';
import { StudyFilterListControllerConfig } from './studyFilterListConfig';
import { StudyFilterListController } from './studyFilterListInterface';

export function createStudyFilterListController(
    config: StudyFilterListControllerConfig = {}
): StudyFilterListController {
    return {
        useProps(context, props) {
            return {
                items: props.item.items,
                getItemProps(item): StudyFilterItemViewProps {
                    return {
                        id: item.property.key,
                        label: item.property.name,
                        options: item.members.map((item) => ({
                            label: item.label,
                            value: item.value,
                        })),
                    };
                },
            };
        },
    };
}
