export const VAROS_GREEN = {
    200: '#02C380',
};

export const VAROS_YELLOW = {
    200: '#c6e74f',
};

export const GRAY = {
    500: '#46454a',
    600: '#2d2d2e',
    700: '#1f1f1f',
    800: '#161518',
    900: '#151515',
};
