import { PageIds } from '../../../../../../config';
import { SettingsBillingControllerConfig } from '../../base';
import { PaymentMethodEditController } from './paymentMethodEditInterface';

export function createPaymentMethodEditController(
    config: SettingsBillingControllerConfig
): PaymentMethodEditController {
    const { controller } = config;
    return {
        useProps(context, item, props) {
            const pageProps = controller.page.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ACCOUNT_BILLING_PAYMENT_METHOD_ADD,
                    breadcrumbs: [
                        {
                            label: 'Add payment method',
                        },
                    ],
                },
            });
            const form = controller.form.useProps(props.form);
            return {
                page: pageProps,
                form,
                elements: props.elements,
                payment: {
                    options: {
                        layout: 'tabs',
                    },
                },
            };
        },
    };
}
