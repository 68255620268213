import { AnyConstraint } from '../dashboard';

export interface CurrencyType {
    kind: 'currency';
    currency: string;
    constraints: AnyConstraint[];
}

// export interface MetricType {
//     kind: 'metric';
//     title?: string;
// }

export interface ReferenceType {
    kind: 'reference';
    id: string;
}

export interface MovementType {
    kind: 'movement';
    base?: Exclude<AnyType, { kind: 'movement' }>;
    inverse?: boolean;
    fraction?: boolean;
}

export interface EnumType {
    kind: 'enum';
    name?: string | null;
    namespace?: string | null;
    members: Array<{
        name: string;
        value: string;
    }>;
}

export interface ComplexPercentType {
    kind: 'percent';
    fraction: boolean;
}

export type SimpleType =
    | 'string'
    | 'integer'
    | 'float'
    | 'boolean'
    | 'percent'
    | 'date'
    | 'datetime'
    | 'url'
    | 'unknown';

export type ComplexType =
    | CurrencyType
    | MovementType
    | ComplexPercentType
    | EnumType
    | ReferenceType;
// | MetricType;

export type NumericType = CurrencyType | 'integer' | 'float';

export type AnyType = SimpleType | ComplexType;

export function isTypeReference(type: AnyType): type is ReferenceType {
    return (type as ReferenceType).kind === 'reference';
}

export function isComplexType(type: AnyType): type is ComplexType {
    return !!(type as ComplexType).kind;
}

export function isMovementType(type: AnyType): type is MovementType {
    return isComplexType(type) && type.kind == 'movement';
}
