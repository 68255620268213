import React from 'react';

import { MemoryRouter, BrowserRouter as Router } from 'react-router-dom';
import { router } from '../stores/Router';
// import history from 'src/utils/history/history-utils'

export const WithRouterForTesting: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return <MemoryRouter>{children}</MemoryRouter>;
};

// const history: any = [];

export const WithRouterForApp: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return <Router {...{ history: router.history }}>{children}</Router>;
};
