import { AxiosInstance, AxiosResponse } from 'axios';
import {
    Integration,
    IntegrationConf,
    IntegrationCredentials,
} from '../models/integration';
import { apiV1 } from './axios';
import { IntegrationConfigItem } from '../models/integration-conf';
import { MapModel } from 'src/models/IntegMapping';
import { PagingModel } from 'src/models/Common';

export const fetchIntegrationDefs = async (axiosInstance?: AxiosInstance) => {
    const prefix = axiosInstance ? '/api/v1' : '';
    return (axiosInstance || apiV1).get<unknown, AxiosResponse<Array<IntegrationConf>>>(
        `${prefix}/integrations/definitions`
    );
};

export const fetchIntegrations = async (
    definitionId?: number,
    axiosInstance?: AxiosInstance,
    apiPrefix?: string,
    definitionType?: string
) => {
    const api = axiosInstance || apiV1;
    const prefix = axiosInstance ? '/api/v1' : '';
    const limit = 1000;
    const params: Record<string, unknown> = {page_size: limit};
    if(definitionId) {
        params['definition_id'] = definitionId;
    }
    if(definitionType) {
        params['definition_type'] = definitionType;
    }
    const response = await api.get<unknown, AxiosResponse<PagingModel<Integration>>>(
        `${prefix}/integrations`, {
            params
        }
    );
    if (response.data.items.length === limit) {
        console.warn('truncating integration list response...');
    }
    return response;
};

export const fetchIntegrationById = async (integId: number) => {
    return apiV1.get<unknown, AxiosResponse<Integration>>(`/integrations/${integId}`);
};

export const saveCredentials = async (creds: IntegrationCredentials) => {
    return apiV1.post<unknown, AxiosResponse<Integration>>(`/integrations`, creds);
};

export const removeIntegration = async (integrationId: number) => {
    return apiV1.delete(`/integrations/me/${integrationId}`);
};

export const getIntegrationConfs = async (integrationId: number) =>
    apiV1.get<unknown, AxiosResponse<Array<IntegrationConfigItem>>>(
        `/integrations/${integrationId}/confs`
    );

export const createIntegrationConf = async (
    integId: number,
    integConf: IntegrationConfigItem
) => apiV1.post(`/integrations/${integId}/confs`, integConf);

export const removeIntegrationConf = async (integrationId: number, confId: number) => {
    return apiV1.delete(`/integrations/${integrationId}/confs/${confId}`);
};

export const refreshIntegration = async (integrationId: number) => {
    return apiV1.post(`/integrations/${integrationId}/refresh`);
};

export const getMappings = async (integrationId: number) => {
    return apiV1.get<unknown, AxiosResponse<Array<MapModel>>>(
        `/integrations/${integrationId}/mappings`
    );
};

export const putMappings = async (integrationId: number, newModels: Array<MapModel>) => {
    return apiV1.put<unknown, AxiosResponse<Array<MapModel>>>(
        `/integrations/${integrationId}/mappings`,
        newModels
    );
};

export const getConnectLink = async (
    integrationDefId: number,
    keys?: Record<string, string>,
    implementationId?: number
) => {
    return apiV1.post<unknown, AxiosResponse<{ link: string }>>(
        `/integrations/definitions/${integrationDefId}/connect-link`,
        {
            keys,
            implementation_id: implementationId,
        }
    );
};

export const getReConnectLink = async (
    integrationDefinitionId: number,
    integrationId: number,
    keys?: Record<string, string>,
    implementationId?: number
) => {
    return apiV1.post<unknown, AxiosResponse<{ link: string }>>(
        `/integrations/definitions/${integrationDefinitionId}/connect-link`,
        {
            keys,
            integration_id: integrationId,
            implementation_id: implementationId,
        }
    );
};

export const getIntegrationById = async (
    axiosInstance: AxiosInstance,
    integId: number | string
) => {
    const { data } = await axiosInstance.get<unknown, AxiosResponse<Integration>>(
        `/api/v1/integrations/${integId}`
    );
    return data;
};
