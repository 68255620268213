import { AxiosInstance, AxiosResponse } from 'axios';
import { CreateRecommendationRequest } from './recommendationRequest';
import { RecommendationListResponse } from './recommendationModel';

export async function getRecommendations(
    api: AxiosInstance,
    request: CreateRecommendationRequest
): Promise<RecommendationListResponse> {
    const response = await api.post<unknown, AxiosResponse<RecommendationListResponse>>(
        `/api/v1/personalize/recommendations`,
        request
    );
    return response.data;
}
