import { assert } from '../../../../util/assert';
import { QueryExpressionSegment, QueryRangeSegment } from '../../../../../api';
import { periodToRange } from '../../../../../domain';
import {
    BarCountVisualization,
    BarVisualization,
} from '../../../../domain/visualization';
import { castRankingToCohortState, getPartition, ViewEntity } from '../../../../domain';
import { QueryableContext, QueryableQueryRequest } from '../../../../app';

function buildBarCountRequest(
    context: QueryableContext,
    view: Pick<ViewEntity, 'source' | 'columns'>,
    visualization: BarCountVisualization
): QueryableQueryRequest {
    const partition = getPartition(view);
    assert(partition, 'no partition found on view');

    const partitionKey = partition.key;

    const period = visualization.period ?? { amount: 4, interval: 'week' };
    const daterange = periodToRange(null, period);

    const cohortstate = castRankingToCohortState(visualization.ranking);

    const [facetProp, ...rest] = visualization.breakdown.properties;
    assert(rest.length === 0, 'bar chart does not support nested breakdowns');

    return {
        flags: {
            pipeline_v2: true,
        },
        projections: [
            {
                name: 'default',
                columns: [
                    ...visualization.breakdown.properties.map((item) => ({
                        key: item.key,
                    })),
                    {
                        key: visualization.aggregation,
                    },
                ],
            },
        ],
        facet: {
            keys: visualization.breakdown.properties.map((item) => item.key),
            bucket_limit: {
                limit: 8,
                over_limit_bucket_name: 'Other',
                value_aggregation: {
                    kind: 'aggregation',
                    type: visualization.aggregation,
                    column: facetProp.key,
                },
            },
            explode_values: true,
        },
        aggregations: [
            {
                name: 'count',
                expr: {
                    kind: 'aggregation',
                    type: visualization.aggregation,
                    column: facetProp.key,
                },
            },
        ],
        filters: [
            {
                key: partitionKey,
                operator: 'between',
                value: {
                    from: daterange.start,
                    to: daterange.end,
                },
            },
            ...visualization.filters,
        ],
        timeseries: null,
        granularity: visualization.granularity,
        source: { view: view.source },
        comparison: null,
        segment: context.segment,
        // segments: [
        //     {
        //         kind: 'asset',
        //         name: 'My company',
        //     },
        //     ...(cohortstate.mode === 'fixed'
        //         ? cohortstate.config.fixed.cohorts
        //               .filter((item) => item.visible)
        //               .map((item): QueryRangeSegment => {
        //                   return {
        //                       kind: 'range',
        //                       name: item.name,
        //                       range: {
        //                           column: cohortstate.config.fixed.comparison!,
        //                           start: {
        //                               kind: 'percentile',
        //                               value: item.lower,
        //                           },
        //                           end: {
        //                               kind: 'percentile',
        //                               value: item.upper,
        //                           },
        //                       },
        //                       reducer: {
        //                           kind: 'average',
        //                       },
        //                   };
        //               })
        //         : cohortstate.config.dynamic.cohorts
        //               .filter((item) => item.visible)
        //               .map(
        //                   (item): QueryExpressionSegment => ({
        //                       kind: 'expression',
        //                       name: item.name,
        //                       expr: { kind: 'boolean', value: true },
        //                       reducer: {
        //                           kind: 'percentile',
        //                           value: item.value,
        //                       },
        //                   })
        //               )),
        // ],
    };
}

export function buildBarRequest(
    context: QueryableContext,
    view: Pick<ViewEntity, 'source' | 'columns'>,
    visualization: BarVisualization
): QueryableQueryRequest {
    assert(
        visualization.aggregation === 'count',
        'only count bar charts have been implemented'
    );
    return buildBarCountRequest(context, view, visualization);
}
