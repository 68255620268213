import { RangeInputValue, TreeInputValue } from './inputModel';

export function isTreeInputValue(value: unknown): value is TreeInputValue {
    return Array.isArray(value) && !isRangeInputValue(value);
}

export function isRangeInputValue(value: unknown): value is RangeInputValue {
    return (
        Array.isArray(value) &&
        typeof value[0] === 'number' &&
        typeof value[1] === 'number' &&
        value.length === 2
    );
}
