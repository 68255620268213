import { useDisclosure } from '@chakra-ui/hooks';
import { useForm } from 'react-hook-form';
import { WorkspaceEditConfig } from './workspaceEditConfig';
import { UseWorkspaceEditFormHook } from './workspaceEditInterface';
import {
    WorkspaceFormEditFormState,
    WorkspaceFormPermittedUserOption,
} from './workspaceEditModel';
import React from 'react';
import { ListOffsetResponse, LoadedUserDto } from 'src/v2/api';

export function createEditWorkspaceFormController(
    config: WorkspaceEditConfig
): UseWorkspaceEditFormHook {
    const {
        service: {
            collaborator: { useList: useCollaborators },
            user: { useList: useUsers, useAsyncList: useAsyncUsers },
        },
        hook: { useUser },
    } = config;
    return {
        useProps(context, input) {
            const { workspace, onSubmit } = input;
            const user = useUser();
            const users = useUsers(context, {
                page_size: 100,
            });
            const userSearchCb = useAsyncUsers(context);
            const collaborators = useCollaborators(context, {
                workspaces: [workspace.id],
            });
            const existingCollab = React.useMemo(() => (
                new Set<number>(collaborators.map(x => x.id))
            ), [collaborators]);

            const initialUserOptions = React.useMemo(() => collaborators
                .filter((usr) => usr.id !== user.user.id)
                .map((collaberator) => ({
                    isFixed: !user.user.isAdmin,
                    value: collaberator.id,
                    label: collaberator.first_name
                        ? `${collaberator.first_name} (${collaberator.email})`
                        : collaberator.email,
            })), [collaborators, user]);

            const form = useForm<WorkspaceFormEditFormState>({
                mode: 'all',
                defaultValues: {
                    _error: null,
                    users: initialUserOptions,
                },
            });

            const usersTransformer = React.useCallback(
                (users: ListOffsetResponse<LoadedUserDto>) =>
                    users.data
                        .filter((usr) => usr.id !== user.user.id)
                        .map((usr) => ({
                            isFixed: !user.user.isAdmin && existingCollab.has(usr.id),
                            value: usr.id,
                            label: usr.first_name
                                ? `${usr.first_name} (${usr.email})`
                                : usr.email,
                        })),
                [user.user.id, existingCollab]
            );
            const error = form.watch('_error');

            return {
                ...form,
                isOpen: input.isOpen,
                onOpen: input.onOpen,
                onClose() {
                    form.reset();
                    input.onClose();
                },
                error,
                users: {
                    transformSelection(values) {
                        if(user.user.isAdmin) {
                            return values;
                        } else {
                            const processedValues = [...values];
                            const idsSelected = new Set<number>(values.map(x => x.value));
                            for (const col of collaborators) {
                                if(col.id == user.user.id) {
                                    continue;
                                }
                                if(!idsSelected.has(col.id)) {
                                    processedValues.push({
                                        isFixed: true,
                                        value: col.id,
                                        label: col.first_name
                                            ? `${col.first_name} (${col.email})`
                                        : col.email,
                                    });
                                }
                            }
                            return processedValues;
                        }
                    },
                    options: usersTransformer(users),
                    onSearch: (term: string) =>
                        userSearchCb({ page_size: 100, search: term }).then(
                            usersTransformer
                        ),
                },
                formState: form.formState,
                onSubmit: form.handleSubmit(async (values) => {
                    try {
                        await onSubmit({
                            access: {
                                specific_users: [
                                    user.user.id,
                                    ...values.users.map((recipient) => recipient.value),
                                ],
                                revoke_existing_users: true,
                            },
                            id: workspace.id,
                        });
                        input.onClose();
                    } catch (error) {
                        if (error instanceof Error) {
                            // NOTE this is a hack, really we should have a way
                            // of setting a global form error, but this is not yet supported
                            // on our version of react-hook-forms
                            form.setValue('_error', {
                                message: error.message,
                            });
                            return;
                        }
                        throw error;
                    }
                }),
            };
        },
    };
}
