import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
// import { BsSortDownAlt } from 'react-icons/bs';
import { FaSortAmountDown } from 'react-icons/fa';
import {
    Button,
    HStack,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverFooter,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
    Icon,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
} from '@chakra-ui/react';
import { DashboardCohortViewProps } from './controlCohortProps';

export const DashboardCohortView: React.FC<
    { children?: React.ReactNode | undefined } & DashboardCohortViewProps
> = (props) => {
    const { disclosure } = props;
    const isSmallScreen = useBreakpointValue({
        base: true,
        '2xl': false,
    });
    if (isSmallScreen) {
        return (
            <HStack alignItems="center" spacing={4}>
                <HStack alignItems="center" spacing={2}>
                    <Text
                        textTransform="uppercase"
                        color="whiteAlpha.700"
                        fontWeight="bold"
                        fontSize="sm"
                        whiteSpace="nowrap"
                    >
                        Rank by
                    </Text>
                    <Tooltip label="If you select a metric, we'll create cohorts (low, median, and high performers) based on that metric. We'll then compute all other metrics using the median value within each cohort. If you select the 'per-metric' option; low, median, and high performers are calculated independently (e.g. high performers will not be the same companies across metrics)">
                        <InfoOutlineIcon color="whiteAlpha.700" />
                    </Tooltip>
                </HStack>
                <Button
                    onClick={disclosure.onOpen}
                    leftIcon={<Icon color="#04c380" as={FaSortAmountDown} />}
                    size="sm"
                    border="none"
                    bg="#2d2d2e"
                    _focus={{
                        outline: 'none',
                    }}
                >
                    {props.label}
                </Button>
                <Modal onClose={disclosure.onClose} isOpen={disclosure.isOpen}>
                    <ModalContent
                        bg="#292929"
                        minW="32rem"
                        _focus={{
                            outline: 'none',
                            borderColor: 'none',
                        }}
                    >
                        <ModalCloseButton top={2} />
                        <ModalHeader>Ranking</ModalHeader>
                        <ModalBody p={4} overflowY="scroll">
                            <VStack spacing={4} alignItems="flex-start">
                                {props.children}
                                <HStack justifyContent="flex-end" w="full">
                                    <Button
                                        onClick={props.onApply}
                                        size="sm"
                                        colorScheme="green"
                                    >
                                        Apply
                                    </Button>
                                </HStack>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </HStack>
        );
    }
    return (
        <HStack alignItems="center" spacing={4}>
            <HStack alignItems="center" spacing={2}>
                <Text
                    textTransform="uppercase"
                    color="whiteAlpha.700"
                    fontWeight="bold"
                    fontSize="sm"
                    whiteSpace="nowrap"
                >
                    Rank by
                </Text>
                <Tooltip label="If you select a metric, we'll create cohorts (low, median, and high performers) based on that metric. We'll then compute all other metrics using the median value within each cohort. If you select the 'per-metric' option; low, median, and high performers are calculated independently (e.g. high performers will not be the same companies across metrics)">
                    <InfoOutlineIcon color="whiteAlpha.700" />
                </Tooltip>
            </HStack>
            <Popover
                placement="bottom-end"
                onClose={disclosure.onClose}
                onOpen={disclosure.onOpen}
                isOpen={disclosure.isOpen}
                lazyBehavior="unmount"
                // need to be lazy to properly reset local state in forms
                isLazy={true}
            >
                <PopoverTrigger>
                    <Button
                        leftIcon={<Icon color="#04c380" as={FaSortAmountDown} />}
                        size="sm"
                        border="none"
                        bg="#2d2d2e"
                        _focus={{
                            outline: 'none',
                        }}
                    >
                        {props.label}
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    bg="#292929"
                    minW="32rem"
                    maxW={{
                        xs: '66vw',
                        lg: '50vw',
                        xl: '25vw',
                        '2xl': '17vw',
                    }}
                    _focus={{
                        outline: 'none',
                        borderColor: 'none',
                    }}
                >
                    <PopoverCloseButton top={2} />
                    <PopoverHeader>Ranking</PopoverHeader>
                    <PopoverBody p={4} overflowY="scroll">
                        <VStack spacing={4} alignItems="flex-start">
                            {props.children}
                            <HStack justifyContent="flex-end" w="full">
                                <Button
                                    onClick={props.onApply}
                                    size="sm"
                                    colorScheme="green"
                                >
                                    Apply
                                </Button>
                            </HStack>
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </HStack>
    );
};
