import { useMemo } from 'react';
import { buildSubmissionItemViewProps } from './submissionItemFactory';
import { SubmissionItemControllerConfig } from './submissionItemConfig';
import { SubmissionItemController } from './submissionItemInterface';

export function createSubmissionItemController(
    config: Pick<SubmissionItemControllerConfig, 'infra'>
): SubmissionItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const viewProps = useMemo(
                () => buildSubmissionItemViewProps(formatter, context, item),
                [context, item]
            );
            return viewProps;
        },
    };
}
