import { UseDisclosureReturn } from '@chakra-ui/react';
import type { SelectListControllerProps, SelectListDeps } from '../list';
import { SelectControllerConfig } from '../selectConfig';
import { SelectItemViewProps } from './selectItemProps';

export function buildSelectItemProps<TItem>(
    config: SelectControllerConfig<TItem>,
    disclosure: UseDisclosureReturn | null,
    props: SelectListControllerProps<TItem>,
    item: TItem
): SelectItemViewProps {
    const baseProps = config.getItemProps(item);
    return {
        ...baseProps,
        isSelected: props.value === baseProps.value,
        onClick() {
            disclosure?.onClose();
            props.onChange(baseProps.value);
        },
        container: {
            onClick() {
                disclosure?.onClose();
                props.onChange(baseProps.value);
            },
            cursor: 'pointer',
        },
    };
}
