import { ThreadCreatePayload } from '@varos/assistant-sdk';

export function serializeNewThreadOptions(payload: {
    return_path: string;
    thread: ThreadCreatePayload;
}) {
    return {
        ...payload.thread.data,
        type: payload.thread.type,
        return_path: payload.return_path,
    };
}

export function deserializeNewThreadOptions(raw: Record<string, unknown>) {
    const { type, return_path, ...rest } = raw;
    return {
        return_path,
        thread: {
            type,
            data: rest,
        },
    };
}
