import { z } from 'zod';

export const CompetitiveSetSchema = z.object({
    downgraded_to: z.optional(z.unknown()),
    evaluated_quality: z.object({
        quality: z.union([
            z.literal('highly_relevant'),
            z.literal('very_large_sample'),
            z.literal('relevant_sample'),
            z.literal('small_sample'),
            z.literal('insufficient_sample'),
        ]),
        range: z.union([z.tuple([z.number()]), z.tuple([z.number(), z.number()])]),
    }),
});
