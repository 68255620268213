import React from 'react';
import { AiOutlinePlus, AiOutlineSetting } from 'react-icons/ai';
import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { HomeRouteItemProps } from '../../../../route';
import { ApplicationUI } from '../../../../ui';
import { HomeMetricConfig } from '../homeMetricConfig';
import { MetricEditProps } from './metricEditProps';

export function createMetricEditContainer(
    config: HomeMetricConfig & { UI: ApplicationUI }
): React.FC<
    { children?: React.ReactNode | undefined } & HomeRouteItemProps<MetricEditProps>
> {
    const {
        createView,
        deps: { adapter },
        UI: {
            Button,
            Icon: { Icon },
        },
    } = config;

    const {
        collection: {
            controller: collectionController,
            loader: collectionLoader,
            Component: CollectionView,
        },
    } = createView(config);

    return (props) => {
        const { context } = props;

        const disclosure = useDisclosure();

        if (!props.data?.collection) {
            throw new Error(`default collection not loaded`);
        }

        const viewData = collectionLoader.useLoad(context);

        const viewProps = collectionController.useProps(
            context,
            viewData,
            props.data.collection,
            {
                async onSave() {
                    disclosure.onClose();
                },
            }
        );

        return (
            <>
                <Button
                    onClick={disclosure.onToggle}
                    // leftIcon={<Icon as={AiOutlinePlus}></Icon>}
                    leftIcon={<Icon as={AiOutlineSetting}></Icon>}
                    colorScheme="green"
                    size="sm"
                >
                    Edit metrics
                </Button>
                <Modal {...disclosure} size="6xl">
                    <ModalOverlay />
                    <ModalContent
                    // top={{ lg: '0vh', '2xl': '10vh' }}
                    >
                        <ModalCloseButton />
                        <ModalHeader
                            fontSize="md"
                            py={3}
                            // bg="#1d1c21"
                        >
                            Edit metrics
                        </ModalHeader>
                        <ModalBody
                            m={0}
                            p={0}
                            //  bg="#1d1c21"
                        >
                            <Box
                                height={{
                                    base: '80vh',
                                    xl: '48rem',
                                    '2xl': '56rem',
                                }}
                            >
                                <CollectionView {...viewProps} />
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}
