import { EditOverlayConfig } from './editOverlayConfig';
import { EditOverlayController } from './editOverlayInterface';

export function createEditOverlayController<TItem extends object, TValues extends object>(
    config: EditOverlayConfig<TItem, TValues>
): EditOverlayController<TItem, TValues> {
    return {
        useProps(item, props) {
            // const [searchParams, setSearchParams] = props.searchParams;
            // const paramId = searchParams.get(config.key);
            // const { id } = config.getItemProps(item);

            return {
                page: props.page,
                form: props.form,
                // form: {
                //     ...props.form,
                //     form: {
                //         ...props.form.form,
                //         handleSubmit(resolve, reject) {
                //             return props.form.form.handleSubmit(async (values) => {
                //                 const response = await resolve(
                //                     // @ts-expect-error
                //                     values
                //                 );
                //                 console.log('done');
                //                 searchParams.delete(config.key);
                //                 setSearchParams(searchParams, { replace: true });
                //                 return response;
                //             }, reject);
                //         },
                //     },
                // },
                trigger: {
                    onClick() {
                        // searchParams.set(config.key, id);
                        // setSearchParams(searchParams);
                        props.disclosure.onOpen?.();
                    },
                },
                variant: props.variant,
                modal: {
                    // isOpen: !!paramId,
                    isOpen: props.disclosure.isOpen,
                    onClose() {
                        // searchParams.delete(config.key);
                        // setSearchParams(searchParams, { replace: true });
                        props.disclosure.onClose();
                        props.form.reset();
                    },
                },
            };
        },
    };
}
