// import {} from 'zod'
import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { CompanyResource } from './companyInterface';
import { CompanyListSchema, CompanySchema } from './companySchema';

export function createCompanyResource(client: AxiosInstance): CompanyResource {
    return {
        async search(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.post('/api/v1/companies/search', query, {
                params,
                headers,
            });
            const parsed = CompanyListSchema.safeParse(response.data);
            if (!parsed.success) {
                console.error(parsed.error.errors);
                throw parsed;
            }
            return {
                data: parsed.data.items,
            };
        },
        async find(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get('/api/v1/companies', {
                params: {
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });
            const parsed = CompanyListSchema.parse(response.data);
            return {
                data: parsed.items,
            };
        },
        async get(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get(`/api/v1/companies/${id}`, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });
            const parsed = CompanySchema.parse(response.data);
            return parsed;
        },
    };
}
