export const INTERVALS_BY_UPPER_BOUND = {
    10_000: [1, 25, 50, 75, 100, 150, 200, 300, 400, 500, 1000, 1500, 2000, 2500, 3000],
    10_000_000: [
        1, 500, 1000, 2500, 5000, 7500, 10_000, 20_000, 30_000, 40_000, 50_000, 100_000,
        150_000, 200_000, 250_000, 300_000, 350_000, 400_000, 450_000, 500_000, 1_000_000,
        1_500_000, 2_000_000, 2_500_000, 3_000_000, 4_000_000, 5_000_000,
    ],
    default: [
        1, 500, 1000, 2500, 5000, 7500, 10_000, 20_000, 30_000, 40_000, 50_000, 100_000,
        150_000, 200_000, 250_000, 300_000, 350_000, 400_000, 450_000, 500_000, 1_000_000,
        1_500_000, 2_000_000, 2_500_000, 3_000_000, 4_000_000, 5_000_000,
    ],
};
