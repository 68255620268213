import React, { CSSProperties } from 'react';
import { Link, Text, TextProps } from '@chakra-ui/react';
import { renderToStaticMarkup } from 'react-dom/server';
import { DateFormatOptions, FormatOptions } from '../../hooks';
// import { AnyType, MovementType } from '../../api/v2/type';
import {
    isEnumType,
    isDateType,
    isCurrencyType,
    isMovementType,
    isPercentType,
    AnyType,
    MovementType,
    isTreeType,
    flattenEnumMembers,
    isIntegerType,
} from '../../v2/domain/attributes';
import { ApplicationContext } from '../../hooks';
// import { isComplexPercent, isCurrency, isDate, isEnum, isMovement } from './typeGuard';

export function formatType(
    context: Pick<ApplicationContext, 'formatter'>,
    type: AnyType,
    value: unknown,
    {
        movementPrefix = true,
        ...options
    }: { html?: boolean } & FormatOptions & DateFormatOptions = {}
): string | React.ReactElement {
    if (isTreeType(type) && typeof value === 'string') {
        const member = flattenEnumMembers(type.members).find(
            (candidate) => candidate.value === value
        );
        if (!member) {
            console.warn(`value '${value}' does not match enum ${JSON.stringify(type)}`);
            return value;
        }
        return member.label;
    }
    if (isEnumType(type) && typeof value === 'string') {
        const member = type.members.find((candidate) => candidate.value === value);
        if (!member) {
            console.warn(`value '${value}' does not match enum ${JSON.stringify(type)}`);
            return value;
        }
        return member.label;
    }
    if (isDateType(type) && typeof value === 'string') {
        return context.formatter.date(value, options);
    }
    if (type === 'url') {
        return (
            <Link href={String(value)} isExternal={true}>
                {value as React.ReactNode}
            </Link>
        );
    }
    if (typeof value !== 'number') {
        return String(value);
    }
    if (isCurrencyType(type)) {
        return context.formatter.currency(value, type.currency, options);
    }
    if (isMovementType(type)) {
        // const normal = !type.fraction ? value * 100 : value;
        const normal = value;
        // const rounded = Math.round(normal);
        // const absolute = Math.abs(rounded);
        const absolute = Math.abs(normal);
        const percent = context.formatter.percent(absolute, {});
        // console.log('movement', value, percent);
        if (value === 0) {
            return percent;
        }
        const sign = value > 0 ? '+' : '-';
        const content = movementPrefix ? `${sign}${percent}` : percent;
        if (!options.html) {
            return content;
        }
        return renderToStaticMarkup(
            <Movement value={value} type={type} highlight={true} children={percent} />
        );
    }
    if (isPercentType(type)) {
        return context.formatter.percent(value);
    }
    if (type === 'float') {
        return context.formatter.float(value);
    }
    if (isIntegerType(type)) {
        return Math.floor(value).toString();
    }

    return String(value);
}

const Movement: React.FC<
    { children?: React.ReactNode | undefined } & {
        type: MovementType;
        value: number;
        highlight?: boolean;
    }
> = ({ highlight = false, ...props }) => {
    const sign = props.value > 0 ? '+' : '-';
    // console.log('getColor', getColor(props.type, props.value));
    return (
        <Text
            display="inline-block"
            color={highlight ? getColor(props.type, props.value) : undefined}
        >
            {sign}
            {props.children}
        </Text>
    );
};

function getColor(type: MovementType, value: number): TextProps['color'] {
    if (type.inverse === false && value > 0) {
        return 'green.500';
    }
    if (type.inverse === true && value > 0) {
        return 'red.500';
    }
    if (type.inverse === false && value < 0) {
        return 'red.500';
    }
    if (type.inverse === true && value < 0) {
        return 'green.500';
    }
    return;
}
