import { useMutation, useQuery } from '@tanstack/react-query';
import { ReportInstanceCreateProps } from './reportInstanceProps';
import { ReportInstanceAdapter } from './reportInstanceAdapter';
import { ReportInstanceRepository } from './reportInstanceInterface';

export function createReportInstanceRepository(
    adapter: ReportInstanceAdapter
): ReportInstanceRepository {
    const PREFIX = ['report', 'instance'];
    return {
        useLookup(context, props, options) {
            const mutation = useQuery({
                ...options,
                queryKey: [...PREFIX, context.auth.user?.id, props],
                async queryFn() {
                    const response = await adapter.create(context, props);
                    return response;
                },
            });
            return mutation;
        },
        useCreate(context) {
            const mutation = useMutation({
                async mutationFn(props: ReportInstanceCreateProps) {
                    const response = await adapter.create(context, props);
                    return response;
                },
            });
            return mutation;
        },
    };
}
