import { useEffect, useMemo } from 'react';
import { chain } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDashboardApi } from '../../../app';
import { CohortModuleConfig } from './cohortConfig';
import { CohortFormValues, CohortController } from './cohortInterface';

export function createCohortController(
    config: CohortModuleConfig['deps']
): CohortController {
    const {
        hook: { useQuery },
    } = config;

    return {
        useProps(context, options) {
            const { onSubmit = (values) => {}, options: comparisonOptions } = options;
            // TODO refactor this out to be based on a cached react query and not a nested context call
            // const dashboard = useDashboardApi();
            // const configuration = dashboard.getConfiguration();

            // configuration.cohort.options

            // console.log('configuration.viewSchemas', configuration.viewSchemas);
            // const comparisonOptions = useMemo(() => {
            //     return configuration.cohort.options.map((item) => ({
            //         label: item.label,
            //         value: item.value,
            //     }));
            // }, [configuration.cohort.options]);

            // const state = adapter.get(context);

            const form = useForm<CohortFormValues>({
                defaultValues: options.value ?? {},
            });
            const values = form.watch();

            useEffect(() => {
                form.reset(options.value);
            }, [options.formKey]);

            // console.log('conf', configuration.cohort.modes);

            return {
                initialValues: options.value ?? null,
                onSaveClick: form.handleSubmit(async (values) => {
                    form.reset(values);
                    options.onChange(values);
                    onSubmit(values);
                }),
                getFixedCohortProps(item, index) {
                    return {
                        name: {
                            value: item.name,
                            onChange(value) {},
                        },
                        lower: {
                            value: item.lower,
                            onChange(value) {},
                        },
                        upper: {
                            value: item.upper,
                            onChange(value) {},
                        },
                        visible: {
                            value: item.visible,
                            onChange(value) {
                                form.setValue(
                                    `config.fixed.cohorts.${index}.visible`,
                                    value
                                );
                            },
                        },
                    };
                },
                getDynamicCohortProps(item, index) {
                    return {
                        name: {
                            value: item.name,
                            onChange(value) {},
                        },
                        value: {
                            value: item.value,
                            onChange(value) {},
                        },
                        visible: {
                            value: item.visible,
                            onChange(value) {
                                form.setValue(
                                    `config.dynamic.cohorts.${index}.visible`,
                                    value
                                );
                            },
                        },
                    };
                },
                fields: {
                    mode: {
                        value: values.mode,
                        onChange(value) {
                            form.setValue('mode', value);
                        },
                        options: [
                            {
                                label: 'Per metric',
                                caption: 'Peers vary for each metric',
                                value: 'dynamic',
                                isDisabled: !options.modes.includes('dynamic'),
                            },
                            {
                                label: 'Fixed',
                                caption: 'Fixed peers across metrics',
                                value: 'fixed',
                                isDisabled: !options.modes.includes('fixed'),
                            },
                        ],
                    },
                    comparison: {
                        value: values.config.fixed.comparison,
                        options: comparisonOptions,
                        onChange(value) {
                            form.setValue('config.fixed.comparison', value);
                        },
                    },
                    fixedCohorts: {
                        value: values.config.fixed.cohorts,
                        onChange(value) {
                            form.setValue('config.fixed.cohorts', value);
                        },
                    },
                    dynamicCohorts: {
                        value: values.config.dynamic.cohorts,
                        onChange(value) {
                            form.setValue('config.dynamic.cohorts', value);
                        },
                    },
                },
            };
        },
    };
}
