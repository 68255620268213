import { Flex, VStack } from '@chakra-ui/react';
import React from 'react';

export interface CardTooltipContainerProps {}

export const CardTooltipContainer: React.FC<
    { children?: React.ReactNode | undefined } & CardTooltipContainerProps
> = ({ children, ...props }) => {
    return (
        <VStack p={3} alignItems="flex-start">
            {children}
        </VStack>
    );
};
