import {
    PluginDependencyAnyGroupItem,
    PluginDependencyItemIntegration,
    PluginDependencyItemNestedGroup,
} from './pluginModel';

export function isPluginIntegrationGroupItem(
    item: PluginDependencyAnyGroupItem
): item is PluginDependencyItemIntegration {
    return typeof (item as PluginDependencyItemIntegration).integration === 'string';
}

export function isPluginNestedGroupItem(
    item: PluginDependencyAnyGroupItem
): item is PluginDependencyItemNestedGroup {
    return Array.isArray((item as PluginDependencyItemNestedGroup).items);
}
