import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { LegalViewConfig } from '../../base';
import { TermsOfServiceViewProps } from './termsOfServiceProps';

export function createTermsOfServiceView(
    config: LegalViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & TermsOfServiceViewProps> {
    const { Layout } = config;
    return (props) => {
        return (
            <Layout>
                <VStack spacing={12} pt={12} pb={36} color="whiteAlpha.700">
                    <Heading fontWeight="bold" fontSize="2xl" color="whiteAlpha.900">
                        Terms of service
                    </Heading>
                    <VStack align="start" spacing={6}>
                        <Text fontWeight="semibold" color="whiteAlpha.800">
                            TERMS AND CONDITIONS OF NETWORK MEMBERSHIP THIS IS A BINDING
                            CONTRACT. PLEASE READ CAREFULLY. BY CLICKING ON THE "I AGREE"
                            BUTTON, OR BY USING ANY PORTION OF THE SERVICES, YOU ARE
                            AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT.
                        </Text>
                        <Text>
                            These Terms and Conditions of Network Membership (the “Terms &
                            Conditions”) in the Varos Network supersede all prior versions
                            of the Terms & Conditions previously in effect between Varos
                            Corporation and/or its subsidiaries and affiliates
                            (collectively, “Varos”) and you. You agree that your use of
                            any of Varos's websites is subject to that website's terms of
                            use, except to the extent those terms conflict with these
                            Terms & Conditions, in which case these Terms & Conditions
                            shall control. The English version of these Terms and
                            Conditions shall control over any translations.
                        </Text>

                        <Text>
                            <Box as="span">OVERVIEW AND ACTIVITIES</Box> The Varos Network
                            consists of professionals and consultants in various
                            industries and specialties who educate and share insights with
                            business leaders and other organizations and professionals
                            (“Clients”). Varos provides opportunities for Network Members
                            (also referred to as “Members”) to participate in different
                            types of projects (“Projects”), such as telephone or video
                            consultations and online surveys. Network Members who qualify
                            for additional programs may be eligible to participate in
                            additional types of Projects, including Projects that require
                            greater time commitments. Additionally, Network Members may be
                            eligible to participate in other non-Project activities and
                            interactions through Varos (“Platform Activities”), such as: •
                            Receiving rewards for referring friends and colleagues to the
                            Varos Network and Platform Activities; • Participating in
                            discussions with other Network Members and/or Clients; and •
                            Engaging in other networking and educational opportunities.
                        </Text>

                        <Text>
                            <Box as="span">MEMBERSHIP POLICIES</Box> You confirm that you
                            are not prohibited or limited in any way from participating in
                            the Varos Network by any contract (e.g., employment,
                            consulting, confidentiality, or non-disclosure agreements),
                            your current employer's policies or codes of conduct (if you
                            are employed), or any similar policies or obligations that
                            limit your conduct in any way. Further, to the extent your
                            ability to consult is limited in any way, you confirm that you
                            have obtained all necessary consents or waivers (e.g., the
                            consent of your employer, any company or organization for
                            which you have consulted, or any affiliated academic or
                            government organization) to participate as a Network Member.
                            It is your responsibility to determine whether you are
                            permitted to join the Varos Network. As a Network Member, you
                            acknowledge and agree to the following:
                        </Text>
                        <Text>
                            (i) You are a non-agent independent contractor of Varos;
                        </Text>
                        <Text>(ii) You have no authority to act on behalf of Varos;</Text>
                        <Text>
                            (iii) You are not eligible for any Varos or Client employment
                            benefits based on your participation in the Varos Network;
                            (iv) You shall not identify any Client or Varos as your
                            employer; (v) You are joining the Varos Network in your
                            individual capacity and not as a representative or on behalf
                            of any other entity (such as past or present employers),
                            except as otherwise agreed in writing between such entity and
                            Varos; and (vi) Varos, in its sole discretion, may determine
                            your eligibility for membership in the Varos Network. If you
                            are in any way unsure about your ability to comply with these
                            Terms & Conditions, then you must decline to participate as a
                            Network Member.
                        </Text>

                        <Text>
                            <Box as="span">PARTICIPATION IN THE VAROS NETWORK</Box> At all
                            times during your participation in the Varos Network, you
                            agree to act in good faith, in a professional and workmanlike
                            manner, to the best of your ability, and in accordance with
                            these Terms & Conditions and all applicable laws and
                            regulations. When engaging in Projects or Platform Activities,
                            you agree to abide by any obligations you may have that limit
                            what you can discuss or the scope of your participation in the
                            Varos Network. YOU MUST DECLINE, OR DISCONTINUE PARTICIPATION
                            IN, ANY PROJECT OR PLATFORM ACTIVITY THAT PRESENTS A CONFLICT
                            OF INTEREST OR WOULD RESULT IN A VIOLATION OF ANY APPLICABLE
                            LAW, THESE TERMS & CONDITIONS, OR YOUR OBLIGATIONS TO PAST OR
                            PRESENT EMPLOYERS OR ANY OTHER THIRD PARTY. Varos reserves the
                            right to withhold compensation in the event of disputes
                            regarding the quality of your work or compliance with these
                            Terms & Conditions.
                        </Text>

                        <Text>
                            <Box as="span">
                                CONFIDENTIALITY OF VAROS AND CLIENT INFORMATION
                            </Box>{' '}
                            While you are a Network Member and thereafter, you agree not
                            to disclose or to attempt to use or personally benefit from
                            any Restricted Information (as defined below) that is
                            disclosed to or known by you because of your participation in
                            the Varos Network until such time as the Restricted
                            Information has become publicly available through no action of
                            your own, except to the extent required by law or as expressly
                            permitted by Varos for the purpose of facilitating a
                            particular Project. Restricted Information includes but is not
                            limited to: 1. The identity of Clients (e.g., do not list
                            Clients on your resume, website, or any business networking
                            profile); 2. Information about Projects, including Project
                            invites and lists to which you are granted access; 3.
                            Information about any actual or potential business,
                            investment, or trading decisions, or transactions of any
                            Client; 4. Materials and information provided to you by
                            Clients; and 5. Any other nonpublic or proprietary information
                            of Varos or its Clients. If you are compelled by order of a
                            court or other governmental or legal body (or have notice that
                            such an order is being sought) to divulge any Restricted
                            Information to anyone, you will promptly notify Varos, unless
                            prohibited from doing so by the express terms of a government
                            subpoena or court order, and will cooperate fully with Varos
                            in protecting such information to the extent possible under
                            applicable law.
                        </Text>

                        <Text>
                            <Box as="span">OTHER TERMS</Box> • Governing Law: These Terms
                            & Conditions are governed by Delaware law without regard to
                            its conflict of law rules. • Arbitration: Any dispute will be
                            resolved through binding arbitration in accordance with JAMS
                            Streamlined Arbitration Rules. • Termination: Varos reserves
                            the right to terminate or limit your membership at any time.
                            By clicking "I Agree," or using the service, you confirm that
                            you have read, understand, and agree to these Terms &
                            Conditions.
                        </Text>
                    </VStack>
                </VStack>
            </Layout>
        );
    };
}
