import React from 'react';
import { CardSpinner } from '../../../domain';
import { ApiContext } from '../../api';
import { BootstrappedContextProvider } from '../../base';
import { SystemContextConfig } from './systemConfig';
import { SystemContext } from './systemContext';
import { SystemContextData } from './systemModel';

export function createSystemProvider(
    config: SystemContextConfig
): BootstrappedContextProvider<SystemContextData> {
    const {
        deps: { hook },
        provider: { createLoader },
    } = config;
    const loader = createLoader(config.deps);
    return {
        Provider(props) {
            const auth = hook.useAuth();
            const context: ApiContext = { auth };
            const value = loader.useData(context);
            if (value.isLoading) {
                return <CardSpinner />;
            }
            return (
                <SystemContext.Provider value={value}>
                    {props.children}
                </SystemContext.Provider>
            );
        },
        useContext() {
            throw new Error(`not impl`);
        },
    };
}
