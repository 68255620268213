import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { PageIds } from '../../../../config';
import { SettingImportConfig } from './importConfig';
import { createSettingImportListContainer } from './list/importListContainer';
import {
    createSettingImportListView,
    createSettingImportListLoader,
    createSettingImportListController,
} from './list';
import {
    createSettingImportNewContainer,
    createSettingImportNewController,
    createSettingImportNewLoader,
    createSettingImportNewView,
} from './new';

export function createSettingImportStrategy(
    init: SettingImportConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const listController = createSettingImportListController(init, config);
            const listLoader = createSettingImportListLoader(init, config);

            const newController = createSettingImportNewController(init, config);
            const newLoader = createSettingImportNewLoader(init, config);

            const Container = {
                List: createSettingImportListContainer(
                    init,
                    config,
                    listLoader,
                    listController
                ),
                New: createSettingImportNewContainer(
                    init,
                    config,
                    newLoader,
                    newController
                ),
            };

            const View = {
                List: createSettingImportListView(init, config),
                New: createSettingImportNewView(init, config),
            };

            return [
                {
                    scope: 'company',
                    path: 'imports',
                    key: PageIds.SETTINGS_ASSET_IMPORTS_LIST,
                    title: 'Imports',
                    Component() {
                        return <Container.List as={View} />;
                    },
                    children: [
                        {
                            path: 'new',
                            key: 'new',
                            title: 'New',
                            Component() {
                                return <Container.New as={View.New} />;
                            },
                            children: [],
                        },
                    ],
                },
            ];
        },
    };
}
