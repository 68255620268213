import React from 'react';
import { BarVisualizationV2 } from '../../../../../strategy';
import { BarChartControllerProps } from '../../../../vendor/chakra-chart';
import { createBarChartController } from './barController';

export function createBarChart(): React.FC<
    { children?: React.ReactNode | undefined } & BarChartControllerProps
> {
    const controller = createBarChartController();
    return (props) => {
        const viewProps = controller.useProps(props);
        // @ts-expect-error
        return <BarVisualizationV2 {...viewProps} />;
    };
}
