import React from 'react';
import { Outlet, Route } from 'react-router';
import {
    createRegistrationAcceptCreateContainer,
    createRegistrationAcceptCreateController,
    createRegistrationAcceptCreateLoader,
    createRegistrationAcceptCreateRoute,
    createRegistrationAcceptCreateView,
    createRegistrationAcceptWelcomeContainer,
    createRegistrationAcceptWelcomeView,
    createRegistrationAcceptWelcomeController,
    createRegistrationAcceptProfileContainer,
    createRegistrationAcceptProfileController,
    createRegistrationAcceptProfileView,
    createRegistrationAcceptOrganizationContainer,
    createRegistrationAcceptOrganizationController,
    createRegistrationAcceptOrganizationView,
    createRegistrationAcceptFinalizeContainer,
    createRegistrationAcceptFinalizeController,
    createRegistrationAcceptFinalizeView,
} from './accept';
import { createStepLayoutController, createStepLayoutView } from './step';
import {
    createRegistrationContextProvider,
    RegistrationContainerConfig,
    RegistrationControllerConfig,
    RegistrationLoaderConfig,
    RegistrationViewConfig,
} from './base';
import { RegistrationRouterConfig, RegistrationRouterInit } from './registrationConfig';
import { RegistrationRouter, RegistrationRouterEnhancer } from './registrationInterface';
import {
    createRegisetrationLayoutContainer,
    createRegistrationLayoutController,
    createRegistrationLayoutView,
} from './layout';
import { createAcceptContextProvider } from './accept/context';

export function configureRegistrationRouter(
    init: RegistrationRouterInit,
    enhancer?: RegistrationRouterEnhancer
) {
    return createRegistrationRouter(
        {
            ...init,
            internal: {
                accept: { createController: createRegistrationAcceptCreateController },
            },
        },
        enhancer
    );
}

function createRegistrationRouter(
    config: RegistrationRouterConfig,
    enhancer?: RegistrationRouterEnhancer
): RegistrationRouter {
    if (enhancer) {
        return enhancer(createRegistrationRouter)(config);
    }

    const context = {
        root: createRegistrationContextProvider({
            hook: {},
        }),
        accept: createAcceptContextProvider(),
    };

    const containerConfig: RegistrationContainerConfig = {
        ...config,
        context: context.root,
    };

    const loaderConfig: RegistrationLoaderConfig = {
        ...config,
    };
    const controllerConfig: RegistrationControllerConfig = {
        ...config,
        anchor: {},
        controller: {
            step: createStepLayoutController(),
        },
    };
    const viewConfig: RegistrationViewConfig = {
        ...config,
        Layout: {
            Step: createStepLayoutView(config),
        },
    };

    const AcceptRoute = createRegistrationAcceptCreateRoute(
        containerConfig,
        createRegistrationAcceptCreateContainer(
            { ...containerConfig, accept: { context: context.accept } },
            createRegistrationAcceptCreateLoader(loaderConfig),
            createRegistrationAcceptCreateController(controllerConfig),
            createRegistrationAcceptCreateView({
                ...viewConfig,
                View: {
                    Welcome: createRegistrationAcceptWelcomeContainer(
                        {
                            ...containerConfig,
                            accept: context.accept,
                        },
                        createRegistrationAcceptWelcomeController(controllerConfig),
                        createRegistrationAcceptWelcomeView(viewConfig)
                    ),
                    Profile: createRegistrationAcceptProfileContainer(
                        {
                            ...containerConfig,
                            accept: context.accept,
                        },
                        createRegistrationAcceptProfileController(controllerConfig),
                        createRegistrationAcceptProfileView(viewConfig)
                    ),
                    Organization: createRegistrationAcceptOrganizationContainer(
                        {
                            ...containerConfig,
                            accept: context.accept,
                        },
                        createRegistrationAcceptOrganizationController(controllerConfig),
                        createRegistrationAcceptOrganizationView(viewConfig)
                    ),
                    Finalize: createRegistrationAcceptFinalizeContainer(
                        {
                            ...containerConfig,
                            accept: context.accept,
                        },
                        createRegistrationAcceptFinalizeController(controllerConfig),
                        createRegistrationAcceptFinalizeView(viewConfig)
                    ),
                },
            })
        )
    );

    const Layout = createRegisetrationLayoutContainer(
        containerConfig,
        createRegistrationLayoutController(),
        createRegistrationLayoutView(viewConfig)
    );

    return (
        <Route
            path={config.mount}
            element={
                <Layout>
                    <Outlet />
                </Layout>
            }
        >
            <Route path="invitations" element={<AcceptRoute />} />
        </Route>
    );
}
