import { AxiosInstance, AxiosResponse } from 'axios';
import { AccountStatusDto, IntegrationActiveIssue } from './accountStatusDto';
import * as z from 'zod';

const IssueDtoSchema = z.object({
    detail: z.object({
        kind: z.string(),
    }),
    reference: z.object({
        integration_id: z.number(),
    }),
});

function getReason(kind: string): IntegrationActiveIssue['reason'] | null {
    switch (kind) {
        case 'integration_resources_exceeded':
            return 'resource_monitor';
        case 'integration_broken':
            return 'broken';
        case 'integration_mappable_inactive':
            return 'inactive_mappable';
    }
    return null;
}

export async function getAccountStatus(
    api: AxiosInstance,
    assetId: string | number,
    dashboard?: string | number
): Promise<AccountStatusDto> {
    const response = await api.get<unknown, AxiosResponse<unknown[]>>(
        `/api/v1/data-ingestion/issues`,
        {
            params: {
                asset_id: assetId,
                dashboard,
            },
        }
    );
    const issues: Array<IntegrationActiveIssue> = [];
    let isInFlight = false;

    // integration_sync_in_flight
    for (const issue of response.data) {
        const dto = IssueDtoSchema.safeParse(issue);
        if (dto.success) {
            const reason = getReason(dto.data.detail.kind);
            if (reason) {
                issues.push({
                    type: 'integration',
                    integration_id: dto.data.reference.integration_id,
                    reason,
                });
            } else if (dto.data.detail.kind == 'integration_sync_in_flight') {
                isInFlight = true;
            }
        } else {
            console.warn('failed to parse account status', dto.error);
        }
    }
    return {
        asset_id: assetId,
        issues,
        is_sync_in_flight: isInFlight,
    };
}
