import { OnboardingReceiptController } from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';
import { useEffect } from 'react';

export function createTrackingOnboardingReceiptStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingReceiptController
        ): OnboardingReceiptController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    useEffect(() => {
                        if (viewProps.status === 'success') {
                            tracker.track('onboarding_checkout_receipt_viewed', {});
                        }
                    }, [viewProps.status]);
                    return {
                        ...viewProps,
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createReceipt(...args) {
                        const controller = config.controller.onboarding.createReceipt(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
