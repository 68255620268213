import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { Card, CompositionSection } from '../../../domain';
import { ChartViewProps } from '../../../view/dashboard';
import { ChartController } from './chartInterface';

export interface ChartContainerProps
    extends Pick<
        ChartViewProps,
        'renderTable' | 'renderLine' | 'renderStat' | 'renderBar'
    > {
    card: Card;
    section: CompositionSection;
    as: React.FC<{ children?: React.ReactNode | undefined } & ChartViewProps>;
    useController: ChartController;
}

export const ChartContainer: React.FC<
    { children?: React.ReactNode | undefined } & ChartContainerProps
> = observer(({ as: Component, card, section, useController, ...props }) => {
    const { ...viewProps } = useController(card, section);
    return <Component {...viewProps} {...props} />;
});
