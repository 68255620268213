import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { AssetNewFormProvider } from './assetNewInterface';
import { AssetNewFormValuesSchema } from './assetNewSchema';

export function createAssetNewFormProvider(): AssetNewFormProvider {
    return {
        useForm(context, props) {
            const form = useForm({
                defaultValues: {
                    name: props.item.current?.name ?? '',
                    url: props.item.current?.url ?? '',
                },
                mode: 'onSubmit',
                resolver: zodResolver(AssetNewFormValuesSchema),
            });

            // useEffect(() => {
            //     if (asset) {
            //         form.reset({
            //             url: props.item.current.url ?? '',
            //             name: props.item.current.name,
            //         });
            //     }
            // }, [props.item.current]);

            return form;
        },
    };
}
