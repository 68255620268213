import { EditOverlayConfig, EditOverlayInit } from './editOverlayConfig';
import { createEditOverlayController } from './editOverlayController';
import { EditOverlay } from './editOverlayInterface';
import { createEditOverlayView } from './editOverlayView';

export function createEditOverlay<TItem extends object, TValues extends object>(
    init: EditOverlayInit<TItem, TValues>
): EditOverlay<TItem, TValues> {
    const config: EditOverlayConfig<TItem, TValues> = {
        ...init,
        key: init.key ?? 'edit',
    };
    return {
        controller: createEditOverlayController(config),
        View: createEditOverlayView(config),
    };
}
