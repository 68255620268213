import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { lookupFromQuery } from '../../../base';
import { SubscriptionConfig } from './subscriptionConfig';
import { SubscriptionRepository } from './subscriptionInterface';
import {
    UpdateSubscriptionRequest,
    Portal,
    PortalCreateRequest,
    BillingSubscription,
} from '../../../domain/billing';

export function createSubscriptionRepository(
    config: SubscriptionConfig
): SubscriptionRepository {
    const { adapter } = config;
    return {
        useLookup(context, options) {
            const query = useQuery({
                ...options,
                queryKey: ['subscription-v2'],
                async queryFn() {
                    const response = await adapter.lookup(context);
                    return response;
                },
                keepPreviousData: true,
                staleTime: Infinity,
            });
            // return lookupFromQuery(query, { keeyData: true });
            return query;
        },
        useUpdate(context) {
            const client = useQueryClient();
            const mutationState = useMutation<void, Error, UpdateSubscriptionRequest>({
                mutationFn(payload) {
                    return adapter.update(
                        context,
                        payload.subscriptionId,
                        payload,
                        payload.options
                    );
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['subscription-v2']);
                    await client.refetchQueries(['features']);
                    await client.refetchQueries(['feature-set']);
                },
            });
            return mutationState;
        },
        usePortal(context) {
            const client = useQueryClient();
            const mutationState = useMutation<Portal, Error, PortalCreateRequest>({
                mutationFn(payload) {
                    return adapter.createPortal(context, payload);
                },
            });
            return mutationState;
        },
        useRefresh(context) {
            const client = useQueryClient();
            const mutationState = useMutation<BillingSubscription, Error, string>({
                mutationFn(payload) {
                    return adapter.refresh(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['subscription-v2']);
                    await client.refetchQueries(['features']);
                    await client.refetchQueries(['feature-set']);
                },
            });
            return mutationState;
        },
    };
}
