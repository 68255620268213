import { useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesChartRouteLoader } from './studiesChartItemInterface';
import { StudiesChartRouteAggregate } from './studiesChartItemModel';

export function createStudiesChartItemLoader(
    config: Pick<StudiesBaseRouteConfig, 'repository' | 'strategy' | 'macro'>
): StudiesChartRouteLoader {
    const {
        macro: {
            visualization: {
                chart: { loader: visualizationLoader },
            },
        },
    } = config;

    return {
        useLoad(context, props): StudiesChartRouteAggregate {
            const {
                data: {
                    report: { dataset },
                },
            } = context;

            assert(dataset, 'expected study chart to have dataset loaded');
            const [breakdown] = props.visualization.query.breakdown ?? [];
            assert(breakdown, 'expected study chart to have breakdown');
            assert(breakdown.kind === 'dimension', 'expected dimension breakdown');

            const visualizationV2 = visualizationLoader.useLoad(context, {
                visualization: {
                    ...props.visualization,
                    query: {
                        ...props.visualization.query,
                        period: props.visualization.query.period,
                    },
                },
            });

            const total = useMemo(
                () =>
                    visualizationV2.visualization.series.reduce((acc, series) => {
                        const summed = series.values.reduce<number>(
                            (innerAcc, value) => innerAcc + (value ?? 0),
                            0
                        );
                        return acc + summed;
                    }, 0),
                [visualizationV2.visualization.series]
            );

            assert(dataset, 'expected study report to have dataset loaded');
            const columnByKey = useMemo(
                () => keyBySafe(dataset.configuration.schema, (item) => item.key),
                [dataset.configuration.schema]
            );

            const column = columnByKey[breakdown.key];
            assert(column, 'no column found');

            return {
                visualizationV2,
                // visualization,
                column: column,
                count: {
                    total,
                    answers: total,
                },
            };
        },
    };
}
