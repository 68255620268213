import { z } from 'zod';

export const MappingCreateDtoSchema = z.object({
    integration_id: z.number(),
    id_type: z.string(),
    entity_id: z.string(),
    regex: z.optional(z.string()),
    asset_id: z.number(),
    exclude: z.optional(z.boolean()),
});

export const MappingDtoSchema = z.object({
    id: z.number(),
    integration_id: z.number(),
    id_type: z.string(),
    entity_id: z.string(),
    regex: z.nullable(z.string()),
    asset_id: z.number(),
    exclude: z.boolean(),
    created_at: z.date({ coerce: true }),
});

export const MappingListResponseSchema = z.object({
    count: z.number(),
    items: z.array(MappingDtoSchema),
    num_pages: z.number(),
    page: z.number(),
    page_size: z.number(),
});
