export const DashboardIds = {
    MEDIAMIX: 2311,
    SHOPIFY_LTV: 1505,
    SHOPIFY_LTV_V2: 2608,
    LINKEDIN: 2179,
    LINKEDIN_V2: 2509,
    STRIPE: 2080,
    FACEBOOK_CPA_V2: 2542,
};

export const DashboardSlugs = {
    EXECUTIVE_SUMMARY: 'executive-summary',
    MEDIA_MIX: 'media-mix',
    KLAVIYO_FLOWS: 'klaviyo-flows',
    KLAVIYO_CAMPAIGNS: 'klaviyo-campaigns',
    SHOPIFY_LTV: 'shopify-ltv',
    SHOPIFY_LTV_V2: 'shopify-ltv-v2',
    SHOPIFY_V2: 'shopify-v2',
    LINKEDIN_ADS: 'linkedinads',
    LINKEDIN_ADS_V2: 'linkedinads-v2',
    FACEBOOK_CPA_V2: 'facebook-cpa-v2',
    FACEBOOK_BREAKDOWN: 'facebook-spend-distribution',
    FACEBOOK_V2: 'facebookads-v2',
    FACEBOOK_VIDEO: 'facebook-videos',
    IT_BENCHMARKS_PERFORMANCE: 'it-benchmarks-performance',
    IT_BENCHMARKS_SPEND_DIST: 'it-benchmarks-spend-distribution',
    GOOGLE_V2: 'google-a-d-s-v2',
    TIKTOK_V2: 'tiktokads-v2',
    STRIPE: 'stripe',
    GA4_REVENUE_BREAKDOWN: 'ga4-revenue',
    GA4_TRAFFIC: 'ga4-traffic-and-users',
    GA4_ECOMMERCE: 'ga4-ecommerce',
};

// Override the requirement configuration for individual dashboards.
export const DASHBOARD_DEPENDENCY_TEXT_BY_SLUG = {
    [DashboardSlugs.EXECUTIVE_SUMMARY]: {
        text: `You need at least Shopify and one active ads integration to access the Executive Summary. Contact Support if you need help.`,
    },
} as const;

export const DASHBOARD_OVERRIDES = {
    [DashboardSlugs.EXECUTIVE_SUMMARY]: {
        // default dashboards are always colored + placed last in the sidebar menu
        isDefault: true,
    },
    [DashboardSlugs.MEDIA_MIX]: {
        isDefault: true,
    },
} as const;
