import { QueryClient } from '@tanstack/react-query';
import { createLineStrategy } from '../../../../strategy';
import { QueryRequest } from '../../../domain/query';
import { ChartControllerInit, DemoChartControllerHook } from './chartInterface';

const QUERY_PREFIX = 'queries';

export function cancelChartQueries(queryClient: QueryClient) {
    return queryClient.cancelQueries({
        queryKey: [QUERY_PREFIX],
    });
}

export function createChartController(
    init: ChartControllerInit
): DemoChartControllerHook {
    const lineStrategy = createLineStrategy({
        intl: {
            getDescription(segment, metric) {
                return null;
            },
        },
    });
    return ({ ...props }) => {
        const queryMerged: QueryRequest = {
            ...props.dashboard.query,
            segment: props.segment,
            filters: [...props.dashboard.query.filters, ...props.filters],
        };

        const response = init.service.useQueryResponse(queryMerged);

        const { style, ...data } = lineStrategy(
            {
                formatter: init.formatter,
            },
            {
                getEvents() {
                    return [];
                },
            },
            queryMerged,
            response,
            { kind: 'line' },
            {
                // @ts-ignore
                dashboard: null,
                schema: { properties: {} },
                // TODO: remove dependency on card model for visualization strategies
                card: { id: '1', title: '' },
            }
        );

        return data;
    };
}
