import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { PeerGroupCreateFormProvider } from './peerGroupCreateInterface';
import { PeerGroupCreateFormValues } from './peerGroupCreateModel';
import { PeerGroupCreateFormValueSchema } from './peerGroupCreateSchema';

export function createPeerGroupCreateFormProvider(): PeerGroupCreateFormProvider {
    return {
        useForm(context, props) {
            const form = useForm<PeerGroupCreateFormValues>({
                defaultValues: {
                    companies: [],
                    ...props.initialValues,
                },
                resolver: zodResolver(PeerGroupCreateFormValueSchema),
            });
            return form;
        },
    };
}
