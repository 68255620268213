import { IntegrationConfMode } from '../../models/integration';
import { IntegrationCardGroupProps } from '../../domain';

export const INTEGRATION_MODES: {
    [key in IntegrationConfMode]: Pick<IntegrationCardGroupProps, 'title' | 'description'> | null;
} = {
    live: null,
    warmup: {
        title: 'Warming up',
        description: "Be the first to get these dashboards. They'll go live once enough companies connect.",
    },
};
