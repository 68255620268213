import { UserServiceConfig } from './userConfig';
import { RemoveUserFn, UserService } from './userInterface';
import {
    UserCreateDto,
    LoadedUserDto,
    ListOffsetResponse,
    UserQueryDto,
} from '../../../api';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function createUserService(init: UserServiceConfig): UserService {
    const {
        api,
        hook: { useQuery, useMutation },
    } = init;

    const PREFIX = ['v2', 'users'];

    init.cacheRegistrar.register('users', PREFIX);
    return {
        useList(context, query) {
            const response = useQuery({
                queryKey: [
                    ...PREFIX,
                    ...(query.expand || ['no']),
                    query.page_size,
                    query.page,
                    query.search,
                ],
                async queryFn() {
                    const response = await api.users.list(context, query);
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
            });
            return (
                response.data ?? {
                    data: [],
                    pagination: {
                        count: 0,
                        page: 1,
                        page_size: 10,
                        num_pages: 1,
                    },
                }
            );
        },
        useAsyncList(context) {
            const client = useQueryClient();
            const cb = useCallback(
                (query: UserQueryDto) => {
                    const key = [
                        ...PREFIX,
                        ...(query.expand || ['no']),
                        query.page_size,
                        query.page,
                        query.search,
                    ];
                    const data =
                        client.getQueryData<ListOffsetResponse<LoadedUserDto>>(key);
                    if (data) {
                        return Promise.resolve(data);
                    }
                    return api.users.list(context, query).then((data) => {
                        client.setQueryData(key, data);
                        return data;
                    });
                },
                [context.auth]
            );
            return cb;
        },
        useCreate(context) {
            const client = useQueryClient();
            const mutationCreate = useMutation<LoadedUserDto, null, UserCreateDto>({
                async mutationFn(payload) {
                    const response = await api.users.create(context, payload);
                    // console.log('created', response);
                    return response;
                },
                onSuccess(retVal, userToCreate) {
                    const ctx = { client };
                    init.cacheRegistrar.refresh(ctx, 'users');
                    if (userToCreate.access_asset_ids) {
                        init.cacheRegistrar.refresh(ctx, 'workspace');
                        init.cacheRegistrar.refresh(ctx, 'collaberator');
                    }
                },
            });

            return (payload) => mutationCreate.mutateAsync(payload);
        },
        useRemove(context) {
            const client = useQueryClient();
            const mutationRemove = useMutation<void, null, number>({
                async mutationFn(userId) {
                    const response = await api.users.remove(context, userId);
                    // console.log('created', response);
                    return response;
                },
                onSuccess() {
                    const ctx = { client };
                    init.cacheRegistrar.refresh(ctx, 'users');
                    init.cacheRegistrar.refresh(ctx, 'workspace');
                    init.cacheRegistrar.refresh(ctx, 'collaberator');
                },
            });

            return (payload) => mutationRemove.mutateAsync(payload);
        },
    };
}
