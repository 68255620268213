import React from 'react';
import { PropertyInputConfig } from './propertyConfig';
import { PropertyInputContainerProps } from './propertyProps';

export function createPropertyContainer(
    config: PropertyInputConfig
): React.FC<{ children?: React.ReactNode | undefined } & PropertyInputContainerProps> {
    const {
        provider: { createController },
    } = config;
    const controller = createController(config);
    return (props) => {
        const { children, value, onChange, onClear, property, as: As, ...rest } = props;
        const viewProps = controller.useProps({
            ...rest,
            value,
            onChange,
            onClear,
            property,
        });
        // @ts-expect-error
        return <As {...rest} {...viewProps} />;
    };
}
