export const getPageAnalyticBasedOnUrl = (pathname: string): string | undefined => {
    if (pathname === '/login') {
        return 'Login';
    } else if (pathname === '/register') {
        return 'Register Basic Step 1';
    } else if (pathname.includes('on-boarding') && pathname.includes('advanced')) {
        return 'Register Advanced Step 2';
    } else if (pathname.includes('on-boarding') && pathname.includes('integrations')) {
        return 'Register Integrations Step 3';
    } else if (pathname.includes('on-boarding') && pathname.includes('subscription')) {
        return 'Register Paywall';
    } else if (pathname.includes('on-boarding') && pathname.includes('user-invites')) {
        return 'Register Optional Step 4';
    } else if (
        pathname.includes('on-boarding') &&
        pathname.includes('pending-peer-groups')
    ) {
        return 'Register Thank You Step 5';
    } else if (pathname.includes('/u/assets') && pathname.includes('dashboards')) {
        return 'Dashboard';
    } else if (pathname.includes('/u/assets') && pathname.includes('/reports/')) {
        return 'Report';
    } else if (pathname === '/u/integrations') {
        return 'Integrations in Dashboard';
    }

    return undefined;
};
