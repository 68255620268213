import { chain } from 'lodash';
// import { PluginSlugs } from '../../../../config';
import { MetricIds } from '../../../config/metric/metricConstant';
import { MetricCollectionMetricCreatePayload } from '../../api';
import { GetCollectionPropsFn } from '../../middleware';

const METRIC_ID_VALUES = new Set<string>(Object.values(MetricIds));

const METRIC_ORDER: string[] = [
    // Facebook
    MetricIds.FACEBOOKADS_COST_PER_PURCHASE,
    MetricIds.FACEBOOKADS_ROAS,
    MetricIds.FACEBOOKADS_CPM,
    // Google
    MetricIds.GOOGLEADS_ROAS,
    MetricIds.GOOGLEADS_COST_PER_CONVERSION,
    MetricIds.GOOGLEADS_CPM,
    // Tiktok
    MetricIds.TIKTOKADS_COST_PER_CONVERSION,
    MetricIds.TIKTOKADS_CPM,
    // Shopify
    MetricIds.SHOPIFY_REVENUE,
    MetricIds.SHOPIFY_ABANDONED_CHECKOUT_RATE,
    MetricIds.SHOPIFY_REPEATED_ORDERS_RATIO,
    // GA4
    MetricIds.GOOGLEANALYTICS_ENGAGEMENT_RATE,
    MetricIds.GOOGLEANALYTICS_ACTIVE_28_DAY_USERS,
    MetricIds.GOOGLEANALYTICS_CART_VIEW_RATE,
    MetricIds.GOOGLEANALYTICS_PURCHASE_VIEW_RATE,
    // // Exec
    // MetricIds.EXECUTIVE_SUMMARY_MARKETING_EFFICIENCY_RATIO,
    // MetricIds.EXECUTIVE_SUMMARY_CAC,
];

export const DEFAULT_COLLECTION_FN: GetCollectionPropsFn = (context, definitions) => {
    const metrics = chain(definitions)
        .filter((item) => {
            if (!METRIC_ID_VALUES.has(item.id)) {
                console.warn(`metric ${item.id} not found`, METRIC_ID_VALUES);
            }
            return METRIC_ID_VALUES.has(item.id);
        })
        .filter((item) => METRIC_ORDER.includes(item.id))
        // .filter((item) => item.connection.status !== 'pending')
        .map<MetricCollectionMetricCreatePayload>((item) => ({
            name: null,
            definition: item.id,
        }))
        .orderBy(
            (item) =>
                METRIC_ORDER.findIndex((candidate) => item.definition === candidate),
            'asc'
        )
        .value();

    if (metrics.length === 0) {
        return new Error('no metrics available');
    }
    return {
        asset: context.workspace.id as number,
        default: true,
        name: 'Default',
        metrics,
    };
};
