import {
    Box,
    Grid,
    GridItem,
    HStack,
    StackDivider,
    Text,
    VStack,
    Center,
    Alert,
    AlertTitle,
    AlertIcon,
    Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { RemoteIframe } from '../../../../ui';
import { CardSpinner } from '../../../../domain';
import { AssetPrepareViewProps } from '../../../view/assets';

export function createOnboardingPrepareView(): React.FC<
    { children?: React.ReactNode | undefined } & AssetPrepareViewProps
> {
    return (props) => {
        if (props.status === 'loading') {
            return (
                <Center p={6}>
                    <CardSpinner />
                </Center>
            );
        }
        const statusProps = props.getStatusProps();
        return (
            <VStack
                w="full"
                spacing={12}
                // py={6}
                divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
            >
                {statusProps.status === 'ready' ? (
                    <Alert status="success">
                        <AlertIcon />
                        <AlertTitle fontWeight="semibold" w="full">
                            <Text>Your account is ready!</Text>
                        </AlertTitle>
                    </Alert>
                ) : (
                    <Alert status="warning">
                        <AlertIcon />
                        <AlertTitle fontWeight="semibold" w="full">
                            <HStack w="full" justify="space-between">
                                <Text fontWeight="semibold">{statusProps.label}</Text>
                                {statusProps.isLoading && (
                                    <Spinner speed="3.5s" boxSize={5} />
                                )}
                            </HStack>
                        </AlertTitle>
                    </Alert>
                )}
                <Grid w="full" templateColumns="1fe">
                    <GridItem>
                        <Center>
                            <Box borderRadius="md" overflow="hidden" shadow="md">
                                <RemoteIframe
                                    bg="blackAlpha.900"
                                    spinner={<Spinner color="whiteAlpha.900" />}
                                    width="500"
                                    height="300"
                                    src="https://www.loom.com/embed/3ff3e930d77144b78d70d638ae396948?sid=7d13759d-ef15-4dca-9357-4234e7e8261e"
                                    frameBorder="0"
                                    allowFullScreen={true}
                                />
                            </Box>
                        </Center>
                    </GridItem>
                </Grid>
            </VStack>
        );
    };
}
