import React from 'react';
import { useForm } from 'react-hook-form';
import { OrganizationMappingFormValues } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import {
    SettingAssetPluginController,
    SettingAssetPluginDeps,
    SettingAssetPluginLoader,
} from './pluginInterface';
import { SettingAssetPluginContainerProps } from './pluginProps';
import { SettingAssetPluginConfig } from './pluginConfig';

export function createSettingAssetPluginContainer(
    init: Pick<SettingAssetPluginConfig, 'infra'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingAssetPluginLoader,
    controller: SettingAssetPluginController
): React.FC<
    { children?: React.ReactNode | undefined } & SettingAssetPluginContainerProps
> {
    const {
        infra: { toaster },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const deps: SettingAssetPluginDeps = {
            toast: toaster.useToast(),
            list: {},
        };
        const viewProps = controller.useProps(viewContext, deps, {
            item: viewData,
        });
        return <Component {...viewProps}>{children}</Component>;
    };
}
