import React from 'react';
import { SettingAccountItemProvider } from '../../../../route/settings/account';
import { SettingAssetItemConfig } from './assetConfig';
import {
    createSettingAssetListLoader,
    createSettingAssetListContainer,
    createSettingAssetListController,
    createSettingAssetListView,
} from './list';
import {
    createSettingAssetNewContainer,
    createSettingAssetNewController,
    createSettingAssetNewLoader,
    createSettingAssetNewView,
} from './new';

export function createSettingAccountAssetStrategy(
    init: SettingAssetItemConfig
): SettingAccountItemProvider {
    return {
        create(config) {
            const List = {
                Container: createSettingAssetListContainer(
                    init,
                    config,
                    createSettingAssetListLoader(init),
                    createSettingAssetListController(init)
                ),
                View: createSettingAssetListView(config),
            };
            const New = {
                Container: createSettingAssetNewContainer(
                    init,
                    config,
                    createSettingAssetNewLoader(init),
                    createSettingAssetNewController(init)
                ),
                View: createSettingAssetNewView(config),
            };
            return [
                {
                    scope: 'organization',
                    path: 'companies',
                    key: 'companies',
                    title: 'Companies',
                    Component() {
                        return <List.Container as={List.View} />;
                    },
                    children: [
                        {
                            path: 'new',
                            key: 'new',
                            title: 'New company',
                            Component() {
                                return <New.Container as={New.View} />;
                            },
                        },
                    ],
                },
            ];
        },
    };
}
