import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetricCollectionConfig } from './collectionConfig';
import { MetricCollectionRepository } from './collectionInterface';

export function createMetricCollectionRepository(
    config: MetricCollectionConfig
): MetricCollectionRepository {
    const { api } = config;
    const PREFIX = ['metric', 'collection'];
    return {
        useFind(context, query, options) {
            return useQuery({
                queryKey: [...PREFIX, query],
                async queryFn() {
                    const response = await api.metric.collections.list(
                        context,
                        query,
                        options
                    );
                    return response.data;
                },
                staleTime: Infinity,
            });
        },
        useCreate(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    throw new Error('not impl');
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
        useUpdate(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    const [id, payload] = props;
                    const response = await api.metric.collections.update(
                        context,
                        { id },
                        payload
                    );
                    return response;
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
        useUpsert(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    if (props.id) {
                        const { metrics } = props;
                        console.log('updating', props);
                        const response = await api.metric.collections.update(
                            context,
                            { id: props.id },
                            { metrics }
                        );
                        console.log('updated', response);
                        return response;
                    } else {
                        const { id, ...rest } = props;
                        console.log('creating', props);
                        const response = await api.metric.collections.create(
                            context,
                            rest
                        );
                        console.log('created', response);
                        return response;
                    }
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
    };
}
