import { InvitationLoaderConfig } from './invitationLoaderConfig';
import { InvitationLoader, SingleInvitationLoadedData } from './invitationInterface';
import React from 'react';
import { GroupEntity } from 'src/v2/domain/groups';

export function createInvitationLoader(
    config: InvitationLoaderConfig
): InvitationLoader {
    const { repository } = config.deps;
    return {
        useVerify(query) {
            const invitation = repository.invitations.useVerify(query, {suspense: true, retry: 1});
            return {
                invitation
            };
        },
    };
}
