import {
    AnyCondition,
    AnyResolvedType,
    castPropertyAndValueToCondition,
    isBetweenCondition,
    isGreaterThanCondition,
    isGreaterThanOrEqualCondition,
    isInCondition,
    isLessThanCondition,
    isLessThanOrEqualCondition,
    isNumericRangeValue,
    isTreeValue,
    PropertyMap,
} from '../../../../domain/attributes';
import { TraitFilterValues } from '../../trait';

export function castConditionsToTraitFilters(
    conditions: AnyCondition[]
): TraitFilterValues {
    const initial: TraitFilterValues = {};
    return conditions.reduce((acc, condition): TraitFilterValues => {
        if (isInCondition(condition) && isTreeValue(condition.value)) {
            return { ...acc, [condition.key]: condition.value };
        }
        if (isBetweenCondition(condition) && isNumericRangeValue(condition.value)) {
            return { ...acc, [condition.key]: condition.value };
        }
        if (isLessThanCondition(condition) || isLessThanOrEqualCondition(condition)) {
            return {
                ...acc,
                [condition.key]: {
                    from: null,
                    to: condition.value,
                },
            };
        }
        if (
            isGreaterThanCondition(condition) ||
            isGreaterThanOrEqualCondition(condition)
        ) {
            return { ...acc, [condition.key]: { from: condition.value, to: null } };
        }
        throw new Error(`unmapped condition ${JSON.stringify(condition)}`);
    }, initial);
}

export function castTraitFiltersToConditions(
    properties: PropertyMap<AnyResolvedType>,
    value: TraitFilterValues
): AnyCondition[] {
    return Object.entries(value).flatMap(([key, value]) => {
        const property = properties[key];
        if (!property || !value) {
            return [];
        }
        const condition = castPropertyAndValueToCondition(property, value);
        if (!condition) {
            return [];
        }
        if (isInCondition(condition) && condition.value.length === 0) {
            return [];
        }
        return [condition];
    });
}
