import { createLocalStorageHook } from '../../util/react';
import { HomeRouteConfig } from './homeRouteConfig';
import { HomeRouteLoader, HomeRouteQueries } from './homeRouteInterface';

export function createHomeRouteLoader(config: HomeRouteConfig): HomeRouteLoader {
    const {
        deps: { loader, hook },
    } = config;
    return {
        useData(context, control): HomeRouteQueries {
            const [hidden, setHidden] = hook.useLocalStorage(
                // scope the state to the organization
                context.organization.id.toString()
            );
            return {
                page: {
                    alert: {
                        items: [],
                    },
                    entity: null,
                },
                integration: loader.integration.useData(context, {}),
                destination: loader.destination.useData(context, {}),
                opportunity: loader.opportunity.useData(context, {
                    period: control.date.period,
                }),
                alert: loader.alert.useData(context, {
                    period: control.date.period,
                }),
                guide: loader.guide.useLoad(context),
                hidden: {
                    value: hidden,
                    onChange: setHidden,
                },
            };
        },
    };
}
