import { EnumMember } from '../../../../domain/attributes';
import { hasTreeMemberValue } from '../../../../domain/peers';
import { BenchmarkListAggregate } from './benchmarkListModel';
import { BenchmarkPeerProps } from './benchmarkListProps';

export function buildPeerProps(
    subverticalsByValue: Record<string, EnumMember | undefined>,
    item: BenchmarkListAggregate
): BenchmarkPeerProps {
    const hasSubvertical = hasTreeMemberValue(
        subverticalsByValue,
        item.configuration.competitiveSet
    );
    // console.log(
    //     'adDSAD',
    //     item.metric.id,
    //     item.configuration.competitiveSet.conditions,
    //     subverticalsByValue
    // );
    return {
        status: hasSubvertical
            ? null
            : {
                  status: 'warning',
                  text: 'Narrow your peer group with sub-verticals for more accurate benchmarks',
              },
    };
}
