import { Center, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet, Route, useNavigate } from 'react-router';
import { ApplicationLinks } from '../../config';
import {
    createPeerContextProvider,
    PeerControllerConfig,
    PeerLoaderConfig,
    PeerRouteConfig,
    PeerViewConfig,
} from './base';
import {
    createCompanyEditView,
    // createPeerCompanyEditController,
    // createPeerCompanyEditLoader,
    // createPeerCompanySearchContainer,
    // createPeerCompanySearchController,
    // createPeerCompanySearchLoader,
    createPeerCompanySearchView,
    // createPeerCompanySelectContainer,
    // createPeerCompanySelectController,
    // createPeerCompanySelectLoader,
    createPeerCompanySelectView,
    // peerCompanySearchStateProvider,
} from './company';
import {
    createPeerGroupListLoader,
    createPeerGroupListRoute,
    createPeerGroupListController,
    createPeerGroupListView,
    createPeerGroupDetailRoute,
    createPeerGroupDetailRouteController,
    createPeerGroupDetailLoader,
    createPeerGroupDetailView,
    createPeerGroupCreateRoute,
    createPeerGroupCreateLoader,
    createPeerGroupCreateStateProvider,
    createPeerGroupCreateFormProvider,
    createPeerGroupCreateController,
    createPeerGroupCreateView,
    PeerGroupDetailAggregate,
    PeerGroupDetailEditFormValues,
} from './group';
import {
    createPeerLayout,
    createPeerLayoutController,
    createPeerLayoutView,
} from './layout';
import {
    createPeerMemberEditController,
    createPeerMemberEditFormProvider,
    createPeerMemberEditLoader,
    createPeerMemberEditRoute,
    createPeerMemberListController,
} from './member';
import { createPeerMemberEditView } from './member/edit/peerMemberEditView';
import {
    createPeerOverviewController,
    createPeerOverviewLoader,
    createPeerOverviewRoute,
    createPeerOverviewRouteView,
} from './overview';
import { PeerRouterConfig, PeerRouterInit } from './peerConfig';
import { PeerRouter, PeerRouterEnhancer } from './peerInterface';

export function configurePeerRouter(
    config: PeerRouterInit,
    enhancer?: PeerRouterEnhancer
) {
    return createPeerRouter(
        {
            ...config,
            provider: {
                ...config.provider,
                // match: {
                //     createController: createPeerCompanySearchController,
                // },
                group: {
                    create: {
                        createFormProvider: createPeerGroupCreateFormProvider,
                    },
                },
            },
        },
        enhancer
    );
}

function createPeerRouter(
    config: PeerRouterConfig,
    enhancer?: PeerRouterEnhancer
): PeerRouter {
    if (enhancer) {
        return enhancer(createPeerRouter)(config);
    }
    const {
        provider: {
            common: { createLayoutController },
        },
    } = config;
    const contexts = {
        root: createPeerContextProvider(config),
    };

    const Layout = createPeerLayout(
        contexts.root,
        createPeerLayoutController({
            layout: createLayoutController({
                items: [
                    {
                        id: 'overview',
                        title: 'Overview',
                        path: `${config.mount}/overview`,
                    },
                    {
                        id: 'competitive_sets',
                        title: 'Competitive Sets',
                        path: `${config.mount}/competitive_sets`,
                    },
                ],
            }),
        }),
        createPeerLayoutView(config.route)
    );

    const loaderConfig: PeerLoaderConfig = {
        macro: config.macro,
        repository: config.repository,
    };

    const viewConfig: PeerViewConfig = {
        macro: config.macro,
        UI: config.route.UI,
        Layout: config.route.Layout,
    };

    const edit = config.provider.common.createEditOverlay({
        getFieldProps(item: PeerGroupDetailAggregate) {
            return [];
        },
        getFormProps(item) {
            return { name: '' } as PeerGroupDetailEditFormValues;
        },
    });

    const controllerConfig: PeerControllerConfig = {
        macro: config.macro,
        anchor: {
            competitiveSet: {
                documentation: ApplicationLinks.Documentation.CompetitiveSet,
            },
        },
        factory: config.factory,
        provider: config.provider.common,
        controller: {
            ...config.controller,
            common: {
                action: config.provider.common.createActionItemController(),
                filter: config.provider.common.createFilterListController(),
                page: config.provider.common.createPageController(),
                modal: config.provider.common.createModalController(),
                onboarding: config.provider.common.createOnboardingController({
                    getStatus(item) {
                        return 'complete';
                    },
                }),
            },
        },
    };

    const routeConfig: PeerRouteConfig = {
        ...loaderConfig,
        ...controllerConfig,
        service: config.service,
        context: contexts.root,
        infra: config.infra,
    };

    const Overview = createPeerOverviewRoute(
        contexts.root,
        createPeerOverviewController(controllerConfig),
        createPeerOverviewLoader(loaderConfig),
        createPeerOverviewRouteView(viewConfig)
    );

    const Company = {
        Edit: createCompanyEditView(
            viewConfig,
            createPeerCompanySearchView(viewConfig),
            createPeerCompanySelectView(viewConfig)
            // createPeerCompanySearchContainer(
            //     { ...routeConfig, state: peerCompanySearchStateProvider() },
            //     createPeerCompanySearchLoader(loaderConfig),
            //     config.provider.match.createController(controllerConfig),
            //     createPeerCompanySearchView(viewConfig)
            // ),
            // createPeerCompanySelectContainer(
            //     routeConfig,
            //     createPeerCompanySelectLoader(loaderConfig),
            //     createPeerCompanySelectController(controllerConfig),
            //     createPeerCompanySelectView(viewConfig)
            // )
        ),
    };

    const Group = {
        List: createPeerGroupListRoute(
            contexts.root,
            createPeerGroupListController(controllerConfig),
            createPeerGroupListLoader(loaderConfig),
            createPeerGroupListView(viewConfig)
        ),
        Create: createPeerGroupCreateRoute(
            routeConfig,
            createPeerGroupCreateLoader({
                ...loaderConfig,
            }),
            config.provider.group.create.createFormProvider(),
            createPeerGroupCreateController({
                ...controllerConfig,
            }),
            createPeerGroupCreateView({
                ...viewConfig,
                company: Company.Edit,
            })
        ),
        Detail: createPeerGroupDetailRoute(
            routeConfig,
            config.infra.options,
            contexts.root,
            createPeerGroupDetailRouteController({
                ...controllerConfig,
                edit,
                member: {
                    list: createPeerMemberListController(controllerConfig),
                },
            }),
            createPeerGroupDetailLoader(loaderConfig),
            createPeerGroupDetailView({
                ...viewConfig,
                edit,
            })
        ),
    };

    const Member = {
        Edit: createPeerMemberEditRoute(
            routeConfig,
            createPeerMemberEditFormProvider(),
            // createPeerMemberEditService(loaderConfig),
            createPeerMemberEditController({
                ...controllerConfig,
            }),
            createPeerMemberEditLoader({
                ...loaderConfig,
            }),
            createPeerMemberEditView({
                ...viewConfig,
                company: Company.Edit,
            })
        ),
    };

    const ErrorBoundary = config.UI.Application.createErrorBoundary({
        errorClass: Error,
    });

    return (
        <Route
            path={config.mount}
            element={
                <ErrorBoundary
                    fallback={(info) => (
                        <Center w="full" h="full" p={16}>
                            <VStack align="start" color="red.500">
                                <Text fontWeight="semibold">
                                    An unexpected error occured
                                </Text>
                            </VStack>
                        </Center>
                    )}
                >
                    <Outlet />
                </ErrorBoundary>
            }
        >
            <Route
                element={
                    <HStack w="full" h="full" justify="center">
                        <Grid w="full" h="full" py={6} maxW="96rem" px={24}>
                            <GridItem>
                                <Outlet />
                            </GridItem>
                        </Grid>
                    </HStack>
                }
            >
                <Route path="competitive_sets/new" element={<Group.Create />} />
                <Route
                    path="competitive_sets/:competitiveSetId/members/edit"
                    element={<Member.Edit />}
                />
            </Route>
            <Route
                element={
                    <Layout showSidebar={true}>
                        <Outlet />
                    </Layout>
                }
            >
                <Route index={true} element={<Navigate to="overview" replace={true} />} />
                <Route path="overview" element={<Overview />} />
                <Route path="competitive_sets">
                    <Route index={true} element={<Group.List />} />
                    <Route path=":competitiveSetId" element={<Group.Detail />} />
                </Route>
            </Route>
        </Route>
    );
}
