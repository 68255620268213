import { AssetApi } from './assetInterface';
import { AssetApiConfig } from './assetConfig';
import { createIntegrationResource } from './integration';
import { createIntegrationDefinitionResource } from './definition';
import { createMappableEntityResource } from './entity';
import { createMappingResource } from './mapping';
import { createDataSyncResource } from './datasync';

export function createAssetApi(config: AssetApiConfig): AssetApi {
    const { client } = config;
    return {
        integrations: createIntegrationResource(client),
        definitions: createIntegrationDefinitionResource(client),
        entities: createMappableEntityResource(client),
        mappings: createMappingResource(client),
        datasyncs: createDataSyncResource(client),
    };
}
