import { useStore } from '../../stores/setupContext';
import {
    AccessAdapter,
    AnyAccessRequest,
    AccessResponseDenied,
    AccessResponse,
    ApplicationContext,
} from '../../base';
import { isDateRangeRestricted } from './dashboard';
import { createPaywallStore } from '../../v2/state';

const paywallStore = createPaywallStore();

export const useAccessAdapter = (
    context: Pick<ApplicationContext, 'tracker'>
): AccessAdapter => {
    const { access } = useStore();

    const restrict = (response: AccessResponseDenied) => {
        paywallStore.setState({
            featureId: response.request?.id ?? null,
            source: response.request?.source,
        });
        // console.log('DEBUG restricting', response);
        // access.setResponse({ ...response, error: response.error });
    };

    const clear = () => {
        // access.clear();
        paywallStore.setState({
            featureId: null,
        });
    };

    const canAccess = (request: AnyAccessRequest): AccessResponse => {
        if (request.id === 'dashboard') {
            const slug = request.value?.slug;
            if (!slug) {
                // We only restruct dashboards with a configured slug
                return {
                    restricted: false,
                    request,
                };
            }
            const hasMatchingExclude = request.config?.exclude?.some((pattern) => {
                // Note we only trust the regex pattern because it's administrated by admins
                // const regex = RegExp(pattern);
                return pattern === slug;
            });
            if (hasMatchingExclude) {
                // Dashboard is restricted if any of the exclude rule matches its slug
                return {
                    request: request,
                    restricted: true,
                };
            }
            return { restricted: false, request };
        }
        if (
            request.id === 'date_ranges' &&
            isDateRangeRestricted(request.config, request.value) &&
            request.value.dashboard &&
            !request.config?.exclude?.includes(request.value.dashboard.id)
        ) {
            return {
                request: request,
                restricted: true,
                error: `In your plan, you can only see ${request.config?.lookback_period_days} days back. Want to see 3 years back? Click below to upgrade`,
            };
        }
        if (request.id === 'subcategories') {
            const isSubcategory = request.value.parent !== null;
            const canAccessSubcategory = request.enabled;
            if (isSubcategory && !canAccessSubcategory) {
                return {
                    request: request,
                    restricted: true,
                    error: 'In your plan, subcategories are not enabled. Click below to upgrade',
                };
            }
            return { restricted: false, request };
        }
        if (request.id === 'numeric_trait_filters' && !request.enabled) {
            return {
                request: request,
                restricted: true,
                error: 'In your plan, numeric trait filters are not enabled. Click below to upgrade',
                hint: `Upgrade to use ${request.name?.toLowerCase()}`,
            };
        }
        if (request.id === 'private_groups' && !request.enabled) {
            return {
                request: request,
                restricted: true,
                error: 'In your plan, Top Creatives are not enabled. Click below to add it to your plan',
                hint: `Upgrade to use ${request.name?.toLowerCase()}`,
            };
        }
        
        if (!request.enabled) {
            return {
                request: request,
                restricted: true,
                error: 'To use this feature you must buy a subscription',
                hint: `Upgrade to use ${request.name?.toLowerCase()}`,
            };
        }
        return { restricted: false, request };
    };

    return {
        current: access.current,
        restrict,
        clear,
        canAccess,
    };
};
