import pluralize, { plural } from 'pluralize';
import { getDefinitionStatus } from '../../../../domain/assets';
import { OrganizationMappingItemAggregate } from './mappingItemModel';
import {
    OrganizationDefinitionIntegrationsProps,
    OrganizationDefinitionItemProps,
    OrganizationDefinitionStatusProps,
} from './mappingItemProps';

export function buildMappingItemProps(
    item: OrganizationMappingItemAggregate
): OrganizationDefinitionItemProps {
    const entities = item.entities;
    const status = getDefinitionStatus(item.definition, entities, item.integrations);

    // console.log('status', item.definition.name, status, {
    //     entities,
    //     integrations: item.integrations,
    // });

    const integrations = item.integrations;
    const entityLabel = plural(item.definition.entityName.toLocaleLowerCase());

    const pluralized = pluralize('integration', integrations.length);

    let definitionStatus: 'analyzing' | 'empty' | 'ready' = 'ready';

    // if (
    //     entities.length === 0 &&
    //     item.integrations.every((item) => item.status === 'ready')
    // ) {
    //     definitionStatus = 'empty';
    // } else if (integrations.some((integration) => integration.status === 'analyzing')) {
    //     definitionStatus = 'analyzing';
    // }

    let statusProps: OrganizationDefinitionStatusProps = {
        label: 'Connected',
        description: `Integrations are connected and ready to be mapped `,
        colorScheme: 'green',
    };

    // if (status === 'analyzing') {
    //     statusProps = {
    //         label: 'Analyzing',
    //         description: `One or more integrations are currently being analyzed`,
    //         colorScheme: 'orange',
    //     };
    // }

    // if (definitionStatus === 'empty') {
    //     const pluralEntityName = plural(item.definition.entityName.toLowerCase());
    //     statusProps = {
    //         label: 'Not eligible',
    //         description: `One or more integrations are connected but no eligible ${pluralEntityName} were found`,
    //         colorScheme: 'red',
    //     };
    // }

    const integrationProps: OrganizationDefinitionIntegrationsProps | null =
        integrations && integrations.length > 0
            ? {
                  label: `${integrations.length} ${pluralized}`,
                  getLinkProps() {
                      return {
                          to: `/u/settings/integrations?name=${item.definition.name}`,
                      };
                  },
              }
            : null;

    // if (status === 'empty') {
    //     return {
    //         kind: 'empty',
    //         label: 'Data not available',
    //         description: `This integration does not have any eligible ${entityLabel}`,
    //         title: item.definition.name,
    //         iconUrl: item.definition.iconUrl,
    //         status: statusProps,
    //         integrations: integrationProps,
    //     };
    // }

    // if (status === 'mappable' || status === 'pending') {
    //     return {
    //         kind: 'ready',
    //         label: null,
    //         title: item.definition.name,
    //         iconUrl: item.definition.iconUrl,
    //         status: statusProps,
    //         integrations: integrationProps,
    //     };
    // }

    if (status === 'ready') {
        return {
            kind: 'ready',
            label: null,
            title: item.definition.name,
            iconUrl: item.definition.iconUrl,
            status: statusProps,
            integrations: integrationProps,
        };
    }

    if (item.definition.expedited) {
        return {
            kind: 'analyzing',
            label: 'Analyzing integration',
            description: `We are analyzing your integration`,
            estimate: 'up to 10 minutes',
            title: item.definition.name,
            iconUrl: item.definition.iconUrl,
            status: statusProps,
            integrations: integrationProps,
        };
    }

    return {
        kind: 'waiting',
        label: 'Waiting for data',
        description: `Waiting for data sync to complete`,
        estimate: 'up to 3-4 days',
        title: item.definition.name,
        iconUrl: item.definition.iconUrl,
        status: statusProps,
        integrations: integrationProps,
    };
}
