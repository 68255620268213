import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ReportControl, ReportMetric, ReportSegment } from '../../../domain/report';
import { AnyQueryResponse, QueryRequest, QueryResponse } from '../../../domain/query';
import { mapMetricsToProperties } from './reportQueryHelper';
import { ReportQueryControllerConfig } from './reportQueryConfig';

export interface ReportQueryControllerInput {
    segments: ReportSegment[];
    metrics: ReportMetric[];
    control: ReportControl;
}

export interface ReportQueryController {
    useProps(input: ReportQueryControllerInput): ReportQueryControllerReturn;
}

export interface ReportQueryControllerReturn {
    responses: AnyQueryResponse[];
}

export function createReportQueryController(
    config: ReportQueryControllerConfig
): ReportQueryController {
    const {
        hook: { useHttp, useWorkspace },
    } = config;
    return {
        useProps(input) {
            const http = useHttp();
            const workspace = useWorkspace();

            const adapter = config.hook.useChart();

            const queryHash = JSON.stringify({
                workspace: workspace.id,
                granularity: input.control.date.granularity,
                period: input.control.date.period,
                comparison: input.control.date.comparison,
            });

            const chart = useQuery({
                queryKey: ['report', 'query', queryHash],
                async queryFn() {
                    const response = await adapter.execute(
                        http,
                        workspace,
                        input.control,
                        input.metrics,
                        input.segments
                    );
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
            });

            if (!chart.data) {
                throw new Error(`no data`);
            }

            const response = useMemo(
                () => mapMetricsToProperties(chart.data),
                [chart.data]
            );

            return {
                responses: response,
            };
        },
    };
}
