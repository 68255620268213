import { PluginServiceConfig } from './pluginConfig';
import { PluginService } from './pluginInterface';

export function createPluginService(init: PluginServiceConfig): PluginService {
    const {
        api,
        hook: { useQuery, useMutation },
    } = init;
    return {
        useList(context) {
            const response = useQuery({
                queryKey: ['v2', 'plugins'],
                async queryFn() {
                    const response = await api.plugins.list(context, {});
                    return response.data;
                },
                suspense: true,
                staleTime: Infinity,
            });
            return response.data ?? [];
        },
    };
}
