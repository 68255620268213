import { QueryFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { getSession, CompletedSessionDto } from '../../../api';

export function createGetSessionHandler(
    context: ApplicationContext,
    sessionId: string
): QueryFunction<CompletedSessionDto> {
    return async () => {
        const response = await getSession(context.api, sessionId);
        return response;
    };
}
