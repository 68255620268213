import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingSetupIntentResource } from './setupIntentInterface';
import { SetupIntentDtoSchema } from './setupIntentSchema';

export function createBillingSetupIntentResource(
    client: AxiosInstance
): BillingSetupIntentResource {
    return {
        async create(context, payload) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post('/api/v1/billing/setup_intents', payload, {
                headers,
                params,
            });
            const parsed = SetupIntentDtoSchema.parse(response.data);

            return parsed;
        },
    };
}
