import { assert } from '../../../util/assert';
import { GroupMemberAdapter } from '../../../app/groups';
import { GroupMemberImplConfig } from './groupMemberImplConfig';

export function createGroupMemberImpl(config: GroupMemberImplConfig): GroupMemberAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.members.list(context, query);

            let acc = response.data;
            return {
                limit: query.page_size,
                total: response.pagination?.count || acc.length,
                items: acc,
            };
        },
        async bulk(context, payload) {
            const limit = 25;
            assert(payload.items.length <= limit, `too many changes`);

            const initial = await api.members.list(context, {
                group: [payload.group.id],
                page: 1,
                page_size: limit,
            });

            let accAssetIds = initial.data.map((item) => item.asset_id);

            // Remove deleted members
            accAssetIds = accAssetIds.filter(
                (assetId) =>
                    !payload.items.some(
                        (item) => item.kind === 'delete' && item.asset.id === assetId
                    )
            );

            // Add new members
            accAssetIds = [
                ...accAssetIds,
                ...payload.items.flatMap((item) =>
                    item.kind === 'create' ? [item.data.asset.id] : []
                ),
            ];

            return api.members.bulkUpsert(context, {
                asset_ids: accAssetIds,
                group_id: payload.group.id,
            });
        },
        async bulkLegacy(context, payload) {
            return api.members.bulkUpsert(context, payload);
        },
    };
}
