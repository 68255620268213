import {
    Constraint,
    BetweenConstraintExpression,
    SingleSelectConstraintExpression,
    InConstraintExpression,
} from './constraintModel';

export function isNumericBetweenConstraint(
    constraint: Constraint
): constraint is Constraint<BetweenConstraintExpression> {
    return (
        constraint.expression.operator === 'between' &&
        typeof constraint.expression.value.from === 'number'
    );
}

export function isInConstraint(
    constraint: Constraint
): constraint is Constraint<InConstraintExpression> {
    return (
        constraint.expression.operator === 'in' &&
        Array.isArray(constraint.expression.value)
    );
}

export function isSingleSelectConstraint(
    constraint: Constraint
): constraint is Constraint<SingleSelectConstraintExpression> {
    return constraint.expression.operator === 'single_select';
}
