import React, { useEffect } from 'react';
import { isAssetIssue } from '../../../../domain';
import { useDashboardContext } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';

export function createDashboardBannerTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        const { Issue } = config.views;
        return create({
            ...config,
            views: {
                ...config.views,
                Issue(props) {
                    const { workspace, dashboard } = useDashboardContext();
                    const tracker = config.deps.useTracker();
                    useEffect(() => {
                        if (isAssetIssue(props.issue)) {
                            tracker.track(
                                'dashboard_sync_banner_shown',
                                // @ts-expect-error
                                {
                                    asset_id: workspace.id,
                                    dashboard: dashboard.id,
                                    status_level: props.issue.details.level,
                                    status_message: props.issue.details.message,
                                }
                            );
                        }
                    }, [workspace.id, dashboard.id, props.issue]);
                    return <Issue {...props} />;
                },
            },
        });
    };
}
