import { Asset, getAssetDomain } from '../../../../domain/assets';
import { WorkspaceContextV2 } from '../../../../app';
import { GroupMemberAggregate } from './groupMemberItemModel';
import { GroupMemberItemViewProps } from './groupMemberItemProps';

export function buildGroupMemberItemViewProps(
    context: WorkspaceContextV2,
    current: Asset | null,
    item: GroupMemberAggregate,
    index: number
): GroupMemberItemViewProps {
    return {
        id: item.peerset.id,
        label: `${item.company.name}`,
        secondary: 'N/A',
        status: buildStatus(item),
        links: {},
        action: {},
    };
}

function buildStatus(item: GroupMemberAggregate) {
    return {
        label: 'Member',
    };
}
