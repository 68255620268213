import { z } from 'zod';

export const ReadStatusStateSchema = z.object({
    insights: z.record(
        z.string(),
        z.object({
            last_read_at: z.date({ coerce: true }),
        })
    ),
});
