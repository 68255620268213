import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingSubscriptionResource } from './subscriptionInterface';
import { BillingSubscriptionDtoSchema } from './subscriptionSchema';

export function createBillingSubscriptionResource(
    client: AxiosInstance
): BillingSubscriptionResource {
    return {
        async get(context) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/billing/subscription`, {
                params: {
                    ...params,
                },
                headers,
            });
            const parsed = BillingSubscriptionDtoSchema.parse(response.data);
            return parsed;
        },
        async create(context, payload, options) {
            let headers: Record<string, string> = {};
            let params: Record<string, string | number> = { ...options };

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post('/api/v1/billing/subscription', payload, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = BillingSubscriptionDtoSchema.parse(response.data);
            return parsed;
        },
    };
}
