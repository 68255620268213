import { useMemo } from 'react';
import { HomeDestinationController } from '../../../home';
import { DestinationDefinitionRepository } from '../../../../app/assets';
import { isLoadedCollection, Pagination } from '../../../../base';
import { DestinationDefinition } from '../../../../domain/assets';
import { FeatureMiddleware } from '../../featureInterface';

export function createFeatureDestinationHomeMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceRepository(
            repository: DestinationDefinitionRepository
        ): DestinationDefinitionRepository {
            // disable plugin peer traits
            return {
                ...repository,
                useFind(...args) {
                    const query = repository.useFind(...args);
                    const { feature } = api.useFeature('data_destinations');

                    const mapped = useMemo<Pagination<DestinationDefinition>>(() => {
                        if (feature?.enabled) {
                            return query.data!;
                        }
                        return {
                            ...query.data!,
                            items:
                                query.data?.items?.map((item) => {
                                    return {
                                        ...item,
                                        status: 'locked',
                                    };
                                }) ?? [],
                        };
                    }, [query.data]);

                    if (feature?.enabled) {
                        return query;
                    }

                    if (!isLoadedCollection(query)) {
                        return query;
                    }

                    return {
                        ...query,
                        data: mapped!,
                    };
                },
            };
        }

        function enhanceController(
            controller: HomeDestinationController
        ): HomeDestinationController {
            // disable plugin peer traits
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const { feature, restrict } = api.useFeature('data_destinations');
                    return {
                        ...viewProps,
                        getItemProps(item) {
                            const itemProps = viewProps.getItemProps(item);
                            if (item.definition.status !== 'locked') {
                                return itemProps;
                            }
                            return {
                                ...itemProps,
                                connect: {
                                    ...itemProps.connect,
                                    tooltip: `Upgrade to use data destinations`,
                                    onClick() {
                                        restrict({
                                            ...feature!,
                                            value: null,
                                        });
                                    },
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                home: {
                    ...config.controller.home,
                    createDestination(...args) {
                        const controller = config.controller.home.createDestination(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
            repository: {
                ...config.repository,
                assets: {
                    ...config.repository.assets,
                    createDestination(...args) {
                        const repository = config.repository.assets.createDestination(
                            ...args
                        );
                        return enhanceRepository(repository);
                    },
                },
            },
        });

        return instance;
    };
}
