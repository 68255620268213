import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { AnyResolvedType, Property } from 'src/v2/domain/attributes';
import { PeerGroupItemConfig } from './groupItemConfig';
import { PeerGroupItemController } from './groupItemInterface';

export function createPeerGroupItemController(
    config: Pick<PeerGroupItemConfig, 'infra'>
): PeerGroupItemController {
    const {
        infra: { formatter },
    } = config;

    const OPERATOR_LABEL_BY_KEY: Record<string, string | null> = {
        lte: 'less than',
        lt: 'less than',
        gte: 'greater than',
        gt: 'greater than',
        eq: 'equals',
        in: 'in',
        between: 'between',
    };

    return {
        useProps(deps, props) {
            const { location } = deps;

            const traitByKey = useMemo(() => {
                return props.item.traits.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.key]: item,
                    }),
                    {} as Record<string, Property<AnyResolvedType> | undefined>
                );
            }, [props.item.traits]);

            const valueByKey = useMemo(() => {
                return props.item.group.conditions.reduce((acc, condition) => {
                    const trait = traitByKey[condition.key];
                    if (!trait) {
                        console.warn(`trait '${condition.key}' not found`, traitByKey);
                        return acc;
                    }
                    return {
                        ...acc,
                        [condition.key]: Array.isArray(condition.value)
                            ? formatter.format(
                                  {
                                      kind: 'list',
                                      element: trait.type,
                                  },
                                  condition.value,
                                  { notation: 'short', truncate: 3 }
                              )
                            : formatter.format(trait.type, condition.value, {
                                  notation: 'short',
                              }),
                    };
                }, {} as Record<string, string | undefined>);
            }, [props.item.group.conditions, traitByKey]);

            return {
                name: props.item.plugin.name,
                conditions: props.item.group.conditions.flatMap((condition, index) => {
                    const trait = traitByKey[condition.key];
                    if (!trait) {
                        console.warn(`trait '${condition.key}' not found`, traitByKey);
                        return [];
                    }
                    const value = valueByKey[condition.key] ?? 'N/A';
                    const operator =
                        OPERATOR_LABEL_BY_KEY[condition.operator] ?? condition.operator;
                    return {
                        key: trait.name,
                        operator,
                        value,
                    };
                }),
                isDefault: props.item.group.default,
                path: `/u/assets/${props.item.group.workspace.id}/onboarding?step=peer_groups&cancel_path=${location.pathname}&success_path=${location.pathname}&plugin=${props.item.plugin.id}`,
            };
        },
    };
}
