import { SearchIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { AiOutlineBarChart, AiOutlineSearch } from 'react-icons/ai';
import {
    Center,
    Box,
    Text,
    Grid,
    GridItem,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    VStack,
    Wrap,
    WrapItem,
    Checkbox,
    Icon as ChakraIcon,
    StackDivider,
    Tag,
    TagProps,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { MetricStaticDeps } from '../../metricsConfig';
import { MetricCollectionEditProps } from './collectionEditProps';
import { useHover, useHoverDirty } from 'react-use';

export function createMetricCollectionEditView(
    init: MetricStaticDeps
): React.FC<{ children?: React.ReactNode | undefined } & MetricCollectionEditProps> {
    const {
        UI: {
            Button,
            Icon: { Icon, IconGroup },
            EmptyState,
        },
    } = init;

    const StatusTag: React.FC<
        { children?: React.ReactNode | undefined } & {
            isChecked: boolean;
            onToggle(): void;
        } & TagProps
    > = ({ isChecked, onToggle, ...props }) => {
        return isChecked ? (
            <Tag variant="subtle" bg="none" {...props}>
                <HStack fontSize="sm" align="center" spacing={1} color="green.400">
                    <Text>Selected</Text>
                </HStack>
            </Tag>
        ) : (
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    return onToggle();
                }}
                variant="subtle"
                size="sm"
                bg="blackAlpha.300"
                _hover={{ bg: 'blackAlpha.400' }}
                {...props}
            >
                Select
            </Button>
        );
    };

    return (props) => {
        const {
            section: { items: sections },
            collection: { items, isSelected, selected, onSelect },
        } = props;
        return (
            <Grid
                h="full"
                templateAreas={{
                    base: `
                        "actions actions"
                        "toolbar preview"
                        "items preview"
                    `,
                    // lg: `
                    //     "toolbar preview"
                    //     "items preview"
                    //     "actions actions"
                    // `,
                }}
                templateRows={{
                    base: 'min-content min-content 1fr',
                    // lg: 'min-content 1fr min-content',
                }}
                templateColumns="1fr min-content"
            >
                <GridItem gridArea="toolbar" p={4} bg="blackAlpha.300">
                    <VStack align="start" spacing={4}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<SearchIcon color="gray.300" />}
                            />
                            <Input
                                type="text"
                                placeholder="Search metrics"
                                autoFocus={true}
                                value={props.fields.search.value}
                                onChange={(event) =>
                                    props.fields.search.onChange(event.target.value)
                                }
                            />
                        </InputGroup>
                        <Wrap spacing={2}>
                            {props.fields.current.options.map((item) => (
                                <WrapItem key={item.metric.id}>
                                    <Tag
                                        px={4}
                                        py={2}
                                        borderRadius="full"
                                        // borderColor="green.500"
                                        borderColor="whiteAlpha.300"
                                        borderWidth="2px"
                                        borderStyle="solid"
                                        // bg="blackAlpha.300"
                                        bg="none"
                                    >
                                        <HStack spacing={2}>
                                            {item.plugin.iconUrl ? (
                                                <Icon
                                                    h={4}
                                                    w={4}
                                                    src={item.plugin.iconUrl}
                                                />
                                            ) : null}
                                            <Text>{item.metric.name}</Text>
                                            <ChakraIcon
                                                cursor="pointer"
                                                as={SmallCloseIcon}
                                                onClick={props.fields.current.onToggle.bind(
                                                    null,
                                                    item
                                                )}
                                            />
                                        </HStack>
                                    </Tag>
                                </WrapItem>
                            ))}
                        </Wrap>
                    </VStack>
                </GridItem>
                <GridItem
                    gridArea="items"
                    p={4}
                    bg="blackAlpha.300"
                    overflowY="scroll"
                    // maxH={{ lg: '50vh', '2xl': '33vh' }}
                    pb={16}
                >
                    <VStack w="full" align="start" spacing={6}>
                        {props.search.isEmpty && (
                            <Center w="full" h="full">
                                <EmptyState
                                    icon={<Icon as={AiOutlineSearch} />}
                                    title="No search results"
                                />
                            </Center>
                        )}
                        {sections.map((section) => (
                            <VStack
                                key={section.title}
                                align="start"
                                w="full"
                                spacing={2}
                            >
                                <HStack
                                    pb={2}
                                    justify="start"
                                    borderBottom="2px solid"
                                    borderColor="whiteAlpha.300"
                                    w="full"
                                >
                                    <Text
                                        textTransform="uppercase"
                                        fontWeight="bold"
                                        letterSpacing="wider"
                                        color="whiteAlpha.700"
                                        fontSize="xs"
                                    >
                                        {section.title}
                                    </Text>
                                </HStack>
                                <SimpleGrid
                                    pt={2}
                                    columns={{ md: 1, lg: 2 }}
                                    gap={4}
                                    w="full"
                                >
                                    {section.items.map((item) => (
                                        <HStack
                                            cursor="pointer"
                                            key={item.metric.id}
                                            onClick={onSelect.bind(null, item)}
                                            w="full"
                                            align="start"
                                            bg="whiteAlpha.100"
                                            p={4}
                                            justify="space-between"
                                            spacing={4}
                                            aria-selected={isSelected(item)}
                                            borderWidth={2}
                                            borderStyle="solid"
                                            borderColor="transparent"
                                            // _hover={{ bg: 'whiteAlpha.200' }}
                                            _selected={{
                                                borderColor: 'blue.300',
                                            }}
                                        >
                                            <HStack
                                                // align="start"
                                                spacing={4}
                                                w="full"
                                                align="center"
                                            >
                                                <IconGroup
                                                //  mt={1}
                                                >
                                                    {item.plugin.iconUrl ? (
                                                        <Icon src={item.plugin.iconUrl} />
                                                    ) : null}
                                                </IconGroup>
                                                <VStack
                                                    flex={1}
                                                    align="start"
                                                    spacing={0}
                                                >
                                                    <HStack
                                                        justify="space-between"
                                                        w="full"
                                                    >
                                                        <Text fontWeight="medium">
                                                            {item.metric.name}
                                                        </Text>
                                                        {item.connection.status ===
                                                            'ready' && (
                                                            <StatusTag
                                                                size="sm"
                                                                isChecked={props.fields.current.isAdded(
                                                                    item
                                                                )}
                                                                onToggle={props.fields.current.onToggle.bind(
                                                                    null,
                                                                    item
                                                                )}
                                                            />
                                                        )}
                                                    </HStack>
                                                    {/* <Text
                                                        color="whiteAlpha.700"
                                                        noOfLines={1}
                                                    >
                                                        {item.metric.description ??
                                                            'No description'}
                                                    </Text> */}
                                                </VStack>
                                            </HStack>
                                        </HStack>
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        ))}
                    </VStack>
                </GridItem>
                <GridItem
                    gridArea="preview"
                    minW="24rem"
                    p={4}
                    borderLeftWidth={2}
                    borderColor="whiteAlpha.300"
                    borderStyle="solid"
                >
                    {selected && (
                        <VStack
                            align="start"
                            divider={
                                <StackDivider
                                    borderWidth="1px"
                                    borderColor="whiteAlpha.300"
                                />
                            }
                            spacing={4}
                            w="full"
                        >
                            <VStack align="start" w="full">
                                <HStack spacing={4} align="center" w="full">
                                    <IconGroup>
                                        {selected.plugin.iconUrl ? (
                                            <Icon
                                                h={6}
                                                w={6}
                                                src={selected.plugin.iconUrl}
                                            />
                                        ) : null}
                                    </IconGroup>
                                    <VStack align="start" spacing={0} w="full">
                                        <HStack justify="space-between" w="full">
                                            <Text fontWeight="medium">
                                                {selected.metric.name}
                                            </Text>
                                            {/* <StatusTag
                                                size="sm"
                                                isChecked={props.fields.current.isAdded(
                                                    selected
                                                )}
                                                onToggle={props.fields.current.onToggle.bind(
                                                    null,
                                                    selected
                                                )}
                                            /> */}
                                        </HStack>
                                        <Text color="whiteAlpha.800">
                                            {selected.plugin.name}
                                        </Text>
                                    </VStack>
                                </HStack>
                                <Box>
                                    <Tag size="sm" variant="subtle">
                                        {props.label.getMetricKind(selected.metric)}
                                    </Tag>
                                </Box>
                                <Box>
                                    <Text color="whiteAlpha.700">
                                        {selected.metric.description}
                                    </Text>
                                </Box>
                                {/* <Text
                                    as="pre"
                                    fontStyle="italic"
                                    fontSize="sm"
                                    whiteSpace="pre-wrap"
                                    bg="blackAlpha.300"
                                    color="whiteAlpha.800"
                                    borderRadius="sm"
                                    px={2}
                                    py={0.5}
                                >
                                    {selected.metric.formula}
                                </Text> */}
                            </VStack>
                            <VStack align="start" w="full">
                                <Text
                                    textTransform="uppercase"
                                    color="whiteAlpha.700"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    letterSpacing="wider"
                                >
                                    Dependencies
                                </Text>
                                <VStack align="start" w="full">
                                    {selected.connection.dependencies.map(
                                        (dependency) => (
                                            <HStack
                                                key={dependency.name}
                                                w="full"
                                                justify="space-between"
                                                bg="blackAlpha.300"
                                                py={2}
                                                px={4}
                                                borderRadius="md"
                                                align="center"
                                            >
                                                <Text fontWeight="medium">
                                                    {dependency.name}
                                                </Text>
                                                <Tag
                                                    size="md"
                                                    colorScheme={props.color.getStatus(
                                                        dependency
                                                    )}
                                                >
                                                    {props.label.getStatus(dependency)}
                                                </Tag>
                                            </HStack>
                                        )
                                    )}
                                </VStack>
                            </VStack>
                        </VStack>
                    )}
                    {!selected && (
                        <EmptyState
                            icon={<Icon as={AiOutlineBarChart} />}
                            title={<Text>No metric selected</Text>}
                        />
                    )}
                </GridItem>
                <GridItem
                    gridArea="actions"
                    p={4}
                    // borderTopWidth={{ base: 0, lg: 2 }}
                    borderBottomWidth={{
                        base: 2,
                        // lg: 0
                    }}
                    borderColor="whiteAlpha.300"
                    borderStyle="solid"
                >
                    <HStack w="full" justify="flex-end" spacing={4}>
                        {props.label.selectionLabel && (
                            <Box>
                                <Text color="whiteAlpha.700" fontWeight="medium">
                                    {props.label.selectionLabel}
                                </Text>
                            </Box>
                        )}
                        {props.form.isDirty() && (
                            <Button onClick={props.form.onReset} minW={24}>
                                Reset
                            </Button>
                        )}
                        <Button
                            onClick={props.form.onSubmit}
                            minW={24}
                            colorScheme="green"
                            isDisabled={!props.form.isValid()}
                            isLoading={props.form.isSubmitting()}
                        >
                            Save metrics
                        </Button>
                    </HStack>
                </GridItem>
            </Grid>
        );
    };
}
