import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { ClientPreferenceResource } from './clientPreferenceInterface';
import { ClientPreferenceSchema } from './clientPreferenceSchema';

export function createClientPreferenceResource(
    client: AxiosInstance
): ClientPreferenceResource {
    return {
        async get(context) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const params: Record<string, unknown> = {};
            const response = await client.get(`/api/v1/client_preferences`, {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ClientPreferenceSchema.parse(response.data);
            return parsed;
        },
        async update(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const params: Record<string, unknown> = {};
            const response = await client.put(`/api/v1/client_preferences`, payload, {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ClientPreferenceSchema.parse(response.data);
            return parsed;
        },
    };
}
