import { ZodError, ZodObject } from 'zod';
import { ParseError } from '../../file';

export class DatasetParseError extends ParseError {
    constructor(
        public row: object,
        public rowIndex: number,
        public zodError: ZodError<ZodObject<any>>
    ) {
        const errors = Object.entries(zodError.flatten().fieldErrors);
        const [[sampleKey, sampleMessage]] = errors;
        super(`Error in row ${rowIndex}: column '${sampleKey}' ${sampleMessage}`);
        Object.setPrototypeOf(this, DatasetParseError.prototype);
    }
}
