import React from 'react';
import { observer } from 'mobx-react';
import {
    SegmentListViewProps,
    SegmentItemControllerProps,
    SegmentListAggregate,
} from '../../../view/dashboard';
import { AnyFilter, AnyPropertyFilter } from '../../../domain';
import {
    SegmentListViewController,
    SegmentItemViewController,
} from './segmentController';

export interface SegmentListContainerProps
    extends Pick<SegmentListViewProps, 'renderItem'> {
    as: React.FC<{ children?: React.ReactNode | undefined } & SegmentListViewProps>;
    useController: SegmentListViewController;
}

export const SegmentListContainer: React.FC<
    { children?: React.ReactNode | undefined } & SegmentListContainerProps
> = ({ as: Component, children, useController, ...restProps }) => {
    const viewProps = useController();
    return (
        <Component {...viewProps} {...restProps}>
            {children}
        </Component>
    );
};

export interface SegmentItemContainerProps {
    as: React.FC<{ children?: React.ReactNode | undefined } & SegmentItemControllerProps>;
    useController: SegmentItemViewController;
    item: SegmentListAggregate;
    filter: AnyFilter;
    index: number;
}

export const SegmentItemContainer: React.FC<
    { children?: React.ReactNode | undefined } & SegmentItemContainerProps
> = observer(
    ({ as: Component, children, useController, item, filter, index, ...restProps }) => {
        const viewProps = useController(item, filter, index);
        return (
            <Component {...viewProps} {...restProps}>
                {children}
            </Component>
        );
    }
);
