import { ErrorInfo } from '../models/Errors';
import { AxiosError } from 'axios';
import { ServerValidationError } from '../models/Common';

const GENERAL_MESSAGE = 'Internal Server Error, contact us at support@varos.io';

export function isErrorInfo(opt: Error | ErrorInfo | string | unknown): opt is ErrorInfo {
    return (opt as ErrorInfo).errType !== undefined;
}

export function isValidationError(opt: Error | ErrorInfo | string): opt is ErrorInfo {
    return isErrorInfo(opt) && opt.errType === 'validationErr';
}

export function isNotEnoughDataError(opt: Error | ErrorInfo | string): opt is ErrorInfo {
    return isValidationError(opt) && opt.message === 'not_enough_data';
}

const ERR_CODES: Record<number, ErrorInfo['errType']> = {
    400: 'validationErr',
    422: 'validationErr',
    401: 'unauthorized',
    403: 'noPerm',
    429: 'rateLimited',
};

export function isAxiosErr<TInner>(opt: any): opt is AxiosError<TInner> {
    return (opt as AxiosError).isAxiosError !== undefined;
}

export function makeError(
    e: ErrorInfo | ErrorInfo['errType'] | null,
    err?: any,
    extra?: any
): ErrorInfo {
    if (e === null) {
        return makeError('serverErr', err, extra);
    } else if (isErrorInfo(e)) {
        return e;
    } else {
        return { errType: e, message: GENERAL_MESSAGE, internalErr: err, extra };
    }
}

export function convertAxiosToError(e: any): ErrorInfo {
    console.error(e);
    const ex: AxiosError = e;
    const extra = {
        status: ex.response?.status,
        url: ex.response?.config?.url,
        method: ex.response?.config?.method,
        params: ex.response?.config?.params,
        response_payload: ex.response?.data,
        request_payload: ex.response?.config.data,
    };
    if (ex && ex.response?.status && ERR_CODES[ex.response.status]) {
        // if(ex && ex.response?.status === 422) {
        // @ts-expect-error
        if (Array.isArray(ex.response.data.detail)) {
            // @ts-expect-error
            const errorFields: Array<ServerValidationError> = ex.response.data.detail;
            return makeError({
                errType: ERR_CODES[ex.response.status],
                fieldErrors: errorFields,
                internalErr: e,
                extra,
            });
        } else {
            return {
                errType: ERR_CODES[ex.response.status],
                // @ts-expect-error
                message: ex.response.data.detail,
                internalErr: e,
                extra,
            };
        }
    } else if (ex && ex.response?.status === 404) {
        return makeError({
            errType: 'notFound',
            // @ts-expect-error
            message: ex.response.data.detail,
            internalErr: e,
            extra,
        });
    } else {
        return makeError('serverErr', ex, extra);
    }
}
