import { z } from 'zod';

// zod does not yet support strict boolean coercing so instead we do the
// transform ourselves
// https://github.com/colinhacks/zod/issues/1630#issuecomment-1710498376
const booleanSchema = z
    .union([z.boolean(), z.literal('true'), z.literal('false')])
    .transform((value) => value === true || value === 'true');

export const UrlParametersSchema = z.object({
    show_features: z.optional(booleanSchema),
});

// export const FeatureSchema = z.object({
//     insights: z
//         .object({
//             enabled: z.boolean(),
//         })
//         .default({ enabled: false }),
//     permissions: z
//         .object({
//             enabled: z.boolean(),
//         })
//         .default({ enabled: false }),
// });
