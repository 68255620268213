import { assert } from '@varos/util-typescript';
import { ReportInstanceAdapter } from '../../../app';
import { ReportInstanceImplConfig } from './reportInstanceImplConfig';

export function createReportInstanceImpl(
    config: ReportInstanceImplConfig
): ReportInstanceAdapter {
    const {
        api: { report: api },
    } = config;

    return {
        async create(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.instanceCreate(
                {
                    instanceCreatePayload: {
                        report: props.report.id,
                        // @ts-expect-error
                        period: props.period,
                        granularity: props.granularity,
                    },
                },
                {
                    headers: {
                        Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                    },
                }
            );
            return response.data;
        },
    };
}
