import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { OrganizationMappingFormValues } from '../../../view/assets';
import { AssetMappingContainerConfigV2 } from './onboardingMappingConfig';
import { OnboardingMappingController } from './onboardingMappingInterface';
import { OnboardingMappingViewProps } from './onboardingMappingProps';

export function createOnboardingMappingController(
    config: AssetMappingContainerConfigV2
): OnboardingMappingController {
    const {
        controller,
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(deps, context, props): OnboardingMappingViewProps {
            const { data, form } = deps;

            const [, setOnboarding] = useLocalStorage(context.organization.id.toString());

            useEffect(() => {
                setOnboarding({
                    step: 'mapping',
                    organization: context.organization.id,
                    asset: props.asset.id,
                });
            }, []);

            const viewProps = controller.mapping.useProps(context, form, data, {
                asset: props.asset,
            });

            const formProps = viewProps.getFormStateProps();

            const handleSubmit = async (values: OrganizationMappingFormValues) => {
                const response = await formProps.onSubmit();
                form.reset({ entityByDefinition: {} });
                props.onContinue?.();
            };

            return {
                getFormProps() {
                    return {
                        ...formProps,
                        submitLabel: formProps.error?.message ?? null,
                        onSubmit: handleSubmit,
                        isLoading: formProps.isSubmittable,
                        error: formProps.error,
                    };
                },
                getViewProps() {
                    return viewProps;
                },
                getEntityControlProps(item) {
                    return viewProps.getEntityControlProps(item);
                },
            };
        },
    };
}
