import { TapfiliateConfig } from '../../infra';

export const AFFILIATE_CONFIG = {
    referralLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdRoRfcMMnLfFkpZ-UCxjSp2BRQf_s6L3rjqMn_Eun6SguNxw/viewform',
};

export const TAPFILIATE_CONFIG: TapfiliateConfig = {
    accountId: '35292-c194e8',
    cookieDomain: 'varos.com',
};
