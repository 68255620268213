import { z, ZodSchema } from 'zod';
import {
    isBooleanType,
    isEnumType,
    isStringType,
    isFloatType,
    isIntegerType,
    isCurrencyType,
    isPercentType,
    isReferenceType,
    isMovementType,
    isDateType,
    isDatetimeType,
    isAssetType,
    isTreeType,
    isListType,
    AnyType,
    isLazyEnum,
    isUrlType,
    isDateRangeType,
    isUnknownType,
    Property,
} from '../../../domain/attributes';
import { assertNever } from '../../../util';
import {
    DatasetFormPayload,
    FormStateSchemaStrategy,
    REQUIRED_ERROR_MESSAGE,
} from '../../../view/forms';
import { FormSchemaZodConfig } from './formSchemaZodConfig';

export function createFormsZodSchemaStrategy(
    config: FormSchemaZodConfig = {}
): FormStateSchemaStrategy {
    function buildTypeSchema(type: AnyType): ZodSchema {
        if (isStringType(type)) {
            return z.string({
                required_error: REQUIRED_ERROR_MESSAGE,
            });
        }
        if (isUrlType(type)) {
            return z.string({
                required_error: REQUIRED_ERROR_MESSAGE,
            });
        }
        if (
            isIntegerType(type) ||
            isFloatType(type) ||
            isCurrencyType(type) ||
            isPercentType(type)
        ) {
            return z.number({
                required_error: REQUIRED_ERROR_MESSAGE,
            });
        }
        if (isBooleanType(type)) {
            return z.boolean({
                required_error: REQUIRED_ERROR_MESSAGE,
            });
        }
        if (isListType(type)) {
            const elementType = buildTypeSchema(type.element);
            return z.array(elementType, {
                required_error: REQUIRED_ERROR_MESSAGE,
            });
        }
        if (isEnumType(type) || isTreeType(type)) {
            return z.union(
                // @ts-expect-error
                [...type.members.map((item) => z.literal(item.value))],
                {
                    required_error: REQUIRED_ERROR_MESSAGE,
                    // there is a bug with union types that instead of showing the "is required"
                    // error when not selected, it will show an "invalid type"
                    // Also there is an error with errorMap that makes it unable to override the default error
                    // https://github.com/colinhacks/zod/issues/2940
                    // errorMap(issue, context) {
                    //     if (issue.code === z.ZodIssueCode.invalid_union) {
                    //         console.log('DEBUG overriding error');
                    //         return { message: 'Required' };
                    //     }
                    //     return { message: context.defaultError };
                    // },
                }
            );
        }
        if (isTreeType(type)) {
            throw new Error(`tree type is not supported`);
        }
        if (isReferenceType(type) || isLazyEnum(type)) {
            // TODO deprecate the lazy enum type and instead rely on references
            throw new Error('expected reference types to have been resolved');
        }
        if (isMovementType(type)) {
            throw new Error(`computed types are not supported`);
        }
        if (isDateRangeType(type)) {
            return z.object(
                {
                    from: z.date({ coerce: true }),
                    to: z.date({ coerce: true }),
                },
                { required_error: REQUIRED_ERROR_MESSAGE }
            );
        }
        if (isDateType(type) || isDatetimeType(type)) {
            return z.date({ coerce: true, required_error: REQUIRED_ERROR_MESSAGE });
        }
        if (isAssetType(type)) {
            // asset ID
            return z.number({ required_error: REQUIRED_ERROR_MESSAGE });
        }
        if (isUnknownType(type)) {
            return z.unknown({ required_error: REQUIRED_ERROR_MESSAGE });
        }
        assertNever(type);
    }
    function buildPropertySchema(property: Property) {
        const typeSchema = buildTypeSchema(property.type);
        if (!property.isRequired) {
            return z.optional(typeSchema);
        }
        return typeSchema;
    }
    return {
        getSchema(item) {
            return z.object(
                item.fields.reduce(
                    (acc, field) => ({
                        ...acc,
                        [field.property.key]: buildPropertySchema(field.property),
                    }),
                    {}
                )
            );
        },
        getDefaultValues(item) {
            return item.fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.property.key]: undefined,
                };
            }, {} as Partial<DatasetFormPayload>);
        },
    };
}
