import { useQuery } from '@tanstack/react-query';
import { VisualizationDataRepositoryConfig } from './resultConfig';
import { VisualizationResultRepository } from './resultInterface';

export function createVisualizationResultRepository(
    config: VisualizationDataRepositoryConfig
): VisualizationResultRepository {
    const { adapter } = config;
    return {
        useCreate(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['visualization', 'result', context.organization, query],
                async queryFn() {
                    if (!query) {
                        return null;
                    }
                    const response = await adapter.create(context, query);
                    return response;
                },
            });
        },
    };
}
