import { AxiosInstance } from 'axios';
import { castResponseToDomain } from '../..';
import { runQuery } from '../../../../../api';
import { DemoChartAdapter } from '../../../../app/demo';
import { castConditionToApi, castQueryToApi } from '../../..';

export function createDemoChartApiImpl(config: {
    axios: AxiosInstance;
    apiKey: string | null;
}): DemoChartAdapter {
    return {
        async executeQuery(query, options) {
            const response = await runQuery(
                config.axios,
                {
                    // asset: config.demoAssetId,
                    segment: query.segment.map(castConditionToApi),
                    query: castQueryToApi(query),
                    // example: 'generate'
                },
                {
                    apiKey: config.apiKey ?? undefined,
                    signal: options?.signal,
                    version: 'v3',
                }
            );
            return castResponseToDomain(response);
        },
    };
}
