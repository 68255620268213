import React from 'react';
import { createContext, useContext } from 'react';
import { AuthSessionAdapter } from './sessionAdapter';

export interface SessionContextValue {
    adapter: { session: AuthSessionAdapter };
}

export const SessionContext = createContext<SessionContextValue | null>(null);

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error(`session context not available`);
    }
    return context;
};
