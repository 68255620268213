import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSessionStorage } from 'react-use';
import { useOrganizationContextV2 } from '../../../context';
import { User, Workspace } from '../../../domain';
import { useUserContext } from './userContext';

export interface UserApi {
    getCurrentWorkspace(): Workspace | null;
    getWorkspaces(): Workspace[];
    getUser(): User;
    getDefaultWorkspaceUrl(root?: boolean): string | null;
    getCurrentWorkspaceHomeUrl(root?: boolean): string | null;
}

export const useUserApi = (): UserApi => {
    const context = useUserContext();
    const { workspaceId: workspaceIdRaw, dashboardId } = useParams();

    const workspaceId = workspaceIdRaw ? Number(workspaceIdRaw) : null;

    const {
        data: { organization },
    } = useOrganizationContextV2();

    const [lastWorkspaceId, setLastWorkspaceId] = useSessionStorage<number | null>(
        'current_workspace',
        organization.defaultAsset?.id ?? null
    );

    const [currentDashboardId, setCurrentDashboard] = useSessionStorage<string | null>(
        'current_dashboard',
        null
    );

    useEffect(() => {
        // sync current workspace from URL and persist to session so that
        // we can navigate back to it from other pages without the
        // workspace context
        if (workspaceId) {
            setLastWorkspaceId(workspaceId);
        }
    }, [workspaceId]);

    useEffect(() => {
        // sync current workspace from URL and persist to session so that
        // we can navigate back to it from other pages without the
        // workspace context
        if (dashboardId) {
            setCurrentDashboard(dashboardId);
        }
    }, [dashboardId]);

    const orgDefaultWorkspace = useMemo(() => {
        return (
            context.user.workspaces.find(
                (candidate) => candidate.id === organization.defaultAsset?.id
            ) ?? null
        );
    }, [context.user.workspaces, organization.defaultAsset?.id]);

    const defaultWorkspace = orgDefaultWorkspace ?? context.user.workspaces[0];

    const lastWorkspace = useMemo(() => {
        return (
            context.user.workspaces.find(
                (candidate) => candidate.id === lastWorkspaceId
            ) ?? null
        );
    }, [context.user.workspaces, lastWorkspaceId]);

    const currentWorkspaceId =
        workspaceId ??
        lastWorkspace?.id ??
        (defaultWorkspace?.id as number | null) ??
        null;

    const currentWorkspace = useMemo(() => {
        return (
            context.user.workspaces.find(
                (candidate) => candidate.id === currentWorkspaceId
            ) ?? null
        );
    }, [context.user.workspaces, currentWorkspaceId]);

    // console.log('lastWorkspaceId', lastWorkspaceId);
    // console.log('defaultWorkspace', defaultWorkspace.id);
    // console.log('currentWorkspace', currentWorkspace?.id);

    return {
        getUser() {
            return context.user;
        },
        getCurrentWorkspace() {
            return currentWorkspace;
        },
        getWorkspaces() {
            return context.user.workspaces;
        },
        getDefaultWorkspaceUrl(rootPath?: boolean) {
            if (!currentWorkspace) {
                return null;
            }
            // if (!rootPath && currentDashboardId) {
            //     return `/u/assets/${organization.defaultAsset.id}/dashboards/${currentDashboardId}`;
            // }
            return `/u/assets/${currentWorkspace.id}/home`;
        },
        getCurrentWorkspaceHomeUrl() {
            // const workspace = currentWorkspaceId ?? context.user.defaultWorkspace;
            // const workspace = currentWorkspaceId ?? organization.defaultAsset?.id;
            if (!currentWorkspace) {
                return null;
            }
            return `/u/assets/${currentWorkspace.id}/home`;
        },
    };
};
