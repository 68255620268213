import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OrganizationScope } from '../../platform';
import { PeerSetMemberAdapter } from './peerSetMemberAdapter';
import { PeerSetMemberRepository } from './peerSetMemberInterface';
import { PeerSetMemberBulkProps } from './peerSetMemberProps';
import { PeerSetMemberFindQuery } from './peerSetMemberQuery';

export function createPeerSetMemberRepository(
    adapter: PeerSetMemberAdapter
): PeerSetMemberRepository {
    const PREFIX = ['peerset', 'members'];

    function getReadPrefix(context: OrganizationScope, query: PeerSetMemberFindQuery) {
        return [...PREFIX, context.organization, query];
    }

    return {
        useFind(context, query, options) {
            const data = useQuery({
                queryKey: [...getReadPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return data;
        },
        useBulk(context) {
            const client = useQueryClient();
            const mutationState = useMutation({
                mutationFn(payload: PeerSetMemberBulkProps) {
                    return adapter.bulk(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutationState;
        },
    };
}
