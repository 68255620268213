import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { OrganizationMappingFormValues } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import {
    SettingDataSourceController,
    SettingDataSourceDeps,
    SettingDataSourceLoader,
} from './sourceInterface';
import { SettingDataSourceContainerProps } from './sourceProps';
import { SettingDataSourceConfig } from './sourceConfig';

export function createSettingDataSourceContainer(
    init: Pick<SettingDataSourceConfig, 'infra'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingDataSourceLoader,
    controller: SettingDataSourceController
): React.FC<
    { children?: React.ReactNode | undefined } & SettingDataSourceContainerProps
> {
    const {
        infra: { toaster },
    } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const deps: SettingDataSourceDeps = {};
        const viewProps = controller.useProps(viewContext, deps, viewData);
        const underlyingProps = viewProps.getViewProps();
        if (underlyingProps.status === 'empty') {
            // return <Component.Empty {...viewProps}>{children}</Component.Empty>;
            return <Navigate to="edit" replace={true} />;
        }
        return <Component.List {...viewProps}>{children}</Component.List>;
    };
}
