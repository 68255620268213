import React from 'react';
import { useAuthenticatedContext } from '../../container';
import { useMinimalLayout as useLayout, MinimalLayout as Layout } from '../../layout';
import { ReceiptView, useReceiptView } from '../../views';

export const ReceiptPage = () => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');

    if (!sessionId) {
        throw new Error(`query parameter 'session_id' not found in receipt page`);
    }

    const context = useAuthenticatedContext();
    const layout = useLayout();
    const receipt = useReceiptView(context, {
        session: { id: sessionId },
        redirect: { to: '/on-boarding/user-invites' },
    });
    return (
        <Layout.Container {...layout.getContainerProps()}>
            <Layout.Content {...layout.getContentProps()} spacing={8}>
                <ReceiptView {...receipt.getProps()} spacing={8} />
            </Layout.Content>
        </Layout.Container>
    );
};
