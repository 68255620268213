import { ReportV2Api } from './reportsInterface';
import { ReportApiV2Config } from './reportsConfig';
import { createReportV2Resource } from './report/reportResource';

export function createReportApiV2(config: ReportApiV2Config): ReportV2Api {
    const { client } = config;
    return {
        report: createReportV2Resource(client),
    };
}
