import { useMemo } from 'react';
import { isReferenceType } from '../../../../domain/attributes';
import { castTypeToDomain } from '../../../../impl';
import { MemberRepository } from '../../../../app/attributes';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { SegmentLoader } from './segmentInterface';

export function createSegmentLoader(
    config: AnalysisRouteDeps,
    repository: MemberRepository
): SegmentLoader {
    const {
        hook: { useQuery },
    } = config;
    return {
        useData(context, props) {
            // const types = useMemo(
            //     () =>
            //         props.plugin.traits.flatMap((property) => {
            //             return isReferenceType(property.type) ? [property.type] : [];
            //         }),
            //     [props.plugin.traits]
            // );

            // const member = repository.useFind(
            //     props.context,
            //     {
            //         types,
            //     },
            //     { suspense: true, staleTime: Infinity }
            // );

            return {
                form: {
                    group: context.data.form.group,
                },
                query: context.data.query,
                // members: member.data ?? [],
            };
        },
    };
}
