import { Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export interface CardTooltipTextProps {
    children: string;
}

export const CardTooltipText: React.FC<
    { children?: React.ReactNode | undefined } & CardTooltipTextProps
> = ({ children, ...props }) => {
    return (
        <Text
            py={0}
            fontStyle="italic"
            size="sm"
            color="whiteAlpha.800"
            dangerouslySetInnerHTML={{
                __html: children ?? '',
            }}
        />
    );
};
