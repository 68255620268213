import React from 'react';
import {
    PopoverContentProps,
    BadgeProps,
    Box,
    HStack,
    StackProps,
    PopoverBodyProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface WorkspaceLayoutToolbarItemProps<TProps> {
    enabled?: boolean;
    label: React.ReactElement;
    badge?: BadgeProps;
    name?: string;
    Icon: IconType;
    props: TProps;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onOpen?(): void;
}

export interface WorkspaceLayoutDropdownProps<TProps = unknown, TPropsInput = unknown> {
    id: string;
    style?: {
        content?: Partial<PopoverContentProps>;
        body?: Partial<PopoverBodyProps>;
    };
    useProps(input: TPropsInput): WorkspaceLayoutToolbarItemProps<TProps>;
    render(props: TProps): React.ReactElement;
}

export const WorkspaceLayoutDropdown: React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceLayoutDropdownProps
> = (props) => <Box>{props.children}</Box>;
