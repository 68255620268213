import { downloadFile } from '../../../../utils/downloadFile';
import { CreateExportOptions } from '../../../../hooks/export';
import { createExport } from '../../../../api/exports';
import { Export } from '../../../../models/Export';
import { useAuthenticatedContext } from '../../../../container';
import { castConditionToApi, castQueryToApi } from '../../../impl';
import { useWorkspaceApi } from '../workspace';
import { Dashboard } from '../../../domain';
import { useExportContext } from './ExportContext';
import { useQueryApi } from '../query';
import { useDashboardApi } from '../dashboard';
import { useControlApi } from '../control';

export interface UseExportReturn {
    export(options: Partial<CreateExportOptions>): Promise<Export>;
}

export interface ExportApi {
    useExport(dashboard: Dashboard): UseExportReturn;
}

export const useExportApi = (): ExportApi => {
    const appcontext = useAuthenticatedContext();
    const { querybuilder } = useExportContext();
    const apis = {
        workspace: useWorkspaceApi(),
        dashboard: useDashboardApi(),
        control: useControlApi(),
        query: useQueryApi(),
    };
    const entity = {
        workspace: apis.workspace.getWorkspace(),
        dashboard: apis.dashboard.getDashboard(),
    };

    return {
        useExport(dashboard) {
            return {
                async export(options) {
                    const builderresult = querybuilder.dashboardExport.build(
                        {
                            getFilters(card) {
                                return apis.query
                                    .getQuery(card.queries[0].source)
                                    .filters.map(castConditionToApi);
                            },
                            granularity: apis.query.getGranularity(),
                            cohort: apis.control.getCohortValue(),
                        },
                        apis.dashboard.getCards().map((card) => ({
                            ...card,
                            queries: [
                                castQueryToApi({
                                    ...card.query,
                                    ...apis.query.getQuery(card.query.source),
                                }),
                            ],
                        }))
                    );

                    if (!builderresult.ok) {
                        throw builderresult.error;
                    }

                    // card does not matter because we use a custom query, but
                    // currently we need to send a card id along
                    const [firstCard, ...restCards] = apis.dashboard.getCards();

                    const export_ = await createExport(appcontext.api, {
                        collection: 'query',
                        columns: true,
                        format: 'csv',
                        options: {
                            name: entity.dashboard.title,
                            request: {
                                asset: entity.workspace.id as number,
                                card: firstCard.id,
                                segment: apis.query.getSegment().map(castConditionToApi),
                                query: builderresult.query,
                            },
                        },
                    });

                    if (options.download) {
                        downloadFile(export_);
                    }

                    return export_;
                },
            };
        },
    };
};
