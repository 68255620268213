import React from 'react';
import { Select } from 'chakra-react-select';
import {
    Flex,
    Text,
    Box,
    VStack,
    InputGroup,
    FormControl,
    FormLabel,
    FormErrorMessage,
    HStack,
    Grid,
    GridItem,
    Heading,
    Center,
    Spinner,
    StackDivider,
    Alert,
    AlertIcon,
    AlertDescription,
    Input,
    InputLeftAddon,
    Switch,
    Button,
} from '@chakra-ui/react';
import { InvitationRegisterViewProps } from './invitationAcceptFormProps';
// import { ApplicationUI } from '../../../ui';
import { CardSpinner } from '../../../../domain/card/component/CardSpinner';

export const createInvitationAcceptFormView = () => {
    //({ UI }: { UI: ApplicationUI }) => {
    return (props: InvitationRegisterViewProps) => {
        const formProps = props.getFormProps();
        const analyzeCurrentOrganization = formProps.watch(
            'organization.analyzeCurrentOrganization'
        );
        return (
            <React.Suspense
                fallback={
                    <Center py={16} h="100%" position="relative" userSelect="none">
                        <VStack
                            // position="relative" bottom={16}
                            spacing={4}
                        >
                            <CardSpinner />
                            <Text
                                fontWeight="medium"
                                fontSize="lg"
                                color="whiteAlpha.700"
                                letterSpacing="wide"
                            >
                                Loading
                            </Text>
                        </VStack>
                    </Center>
                }
            >
                <form onSubmit={formProps.handleSubmit(formProps.onSubmit)}>
                    <VStack
                        w="full"
                        spacing={6}
                        py={6}
                        divider={
                            <StackDivider borderColor="gray.300" borderWidth={0.5} />
                        }
                    >
                        <Heading size="md">Register to Varos</Heading>
                        <VStack
                            w="full"
                            spacing={6}
                            maxW={{ base: undefined, md: '75%', lg: '66%', xl: '50%' }}
                            // mx="auto"
                        >
                            {formProps.error && (
                                <Alert status="error">
                                    <AlertIcon />
                                    <AlertDescription>
                                        {formProps.error.message}
                                    </AlertDescription>
                                </Alert>
                            )}
                            <VStack w="full" spacing={4}>
                                <FormControl
                                    isInvalid={!!formProps.formState.errors?.email}
                                >
                                    <FormLabel htmlFor="email" fontWeight="semibold">
                                        Email
                                    </FormLabel>
                                    <InputGroup borderColor="gray.300" size="lg">
                                        <Input
                                            bg="white"
                                            placeholder="email"
                                            {...formProps.register('email', {
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {formProps.formState.errors?.email?.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <HStack w="full" spacing={4} align="start">
                                    <FormControl
                                        isInvalid={
                                            !!formProps.formState.errors?.firstName
                                        }
                                    >
                                        <FormLabel
                                            htmlFor="firstname"
                                            fontWeight="semibold"
                                        >
                                            First name
                                        </FormLabel>
                                        <InputGroup borderColor="gray.300" size="lg">
                                            <Input
                                                bg="white"
                                                placeholder="First name"
                                                {...formProps.register('firstName', {
                                                    required: 'Required',
                                                })}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {
                                                formProps.formState.errors?.firstName
                                                    ?.message
                                            }
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        isInvalid={!!formProps.formState.errors?.lastName}
                                    >
                                        <FormLabel
                                            htmlFor="lastname"
                                            fontWeight="semibold"
                                        >
                                            Last name
                                        </FormLabel>
                                        <InputGroup borderColor="gray.300" size="lg">
                                            <Input
                                                bg="white"
                                                placeholder="last name"
                                                {...formProps.register('lastName', {
                                                    required: 'Required',
                                                })}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {
                                                formProps.formState.errors?.lastName
                                                    ?.message
                                            }
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <FormControl
                                    isInvalid={!!formProps.formState.errors?.lastName}
                                >
                                    <Select
                                        bg="white"
                                        placeholder="Title"
                                        {...formProps.register('title')}
                                        // @ts-expect-error
                                        label="Title"
                                        options={formProps.titleOptions}
                                    />
                                    <InputGroup
                                        borderColor="gray.300"
                                        size="lg"
                                        w="m"
                                    ></InputGroup>
                                    <FormErrorMessage>
                                        {formProps.formState.errors?.lastName?.message}
                                    </FormErrorMessage>
                                </FormControl>
                                {formProps.requireOrganization && (
                                    <>
                                        <FormControl
                                            isInvalid={
                                                !!formProps.formState.errors.organization
                                                    ?.organizationName
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="companyName"
                                                fontWeight="semibold"
                                            >
                                                Company name
                                            </FormLabel>
                                            <InputGroup borderColor="gray.300" size="lg">
                                                <Input
                                                    bg="white"
                                                    placeholder="Company name"
                                                    {...formProps.register(
                                                        'organization.organizationName'
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {
                                                    formProps.formState.errors
                                                        .organization?.organizationName
                                                        ?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            isInvalid={
                                                !!formProps.formState.errors.organization
                                                    ?.organizationUrl
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="companyWebsite"
                                                fontWeight="semibold"
                                            >
                                                Website URL
                                            </FormLabel>
                                            <InputGroup borderColor="gray.300" size="lg">
                                                <InputLeftAddon bg="gray.200">
                                                    <Text color="gray.500">https://</Text>
                                                </InputLeftAddon>
                                                <Input
                                                    bg="white"
                                                    placeholder="Website URL"
                                                    {...formProps.register(
                                                        'organization.organizationUrl',
                                                        {
                                                            required: 'Required',
                                                        }
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {
                                                    formProps.formState.errors
                                                        .organization?.organizationUrl
                                                        ?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>
                                    </>
                                )}
                                <FormControl>
                                    <HStack mb={2}>
                                        <FormLabel fontWeight="semibold" m={0}>
                                            I want to analyze another company data
                                        </FormLabel>

                                        {/* <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            label={
                                                <Box p={2}>
                                                    Agencies can work with data from multiple
                                                    brands
                                                </Box>
                                            }
                                        >
                                            <span>
                                                <Icon color="gray.700" as={AiOutlineInfoCircle} />
                                            </span>
                                        </Tooltip> */}
                                    </HStack>
                                    <InputGroup borderColor="gray.300" size="lg">
                                        <Switch
                                            colorScheme="blue"
                                            size="lg"
                                            {...formProps.register(
                                                'organization.analyzeCurrentOrganization'
                                            )}
                                        />
                                    </InputGroup>
                                </FormControl>
                                {analyzeCurrentOrganization && (
                                    <>
                                        <Heading
                                            size={'sm'}
                                            fontWeight="bold"
                                            textAlign="start"
                                        >
                                            Company to analyze
                                        </Heading>
                                        <FormControl
                                            isInvalid={
                                                !!formProps.formState.errors.organization
                                                    ?.assetName
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="assetName"
                                                fontWeight="semibold"
                                            >
                                                Name
                                            </FormLabel>
                                            <InputGroup borderColor="gray.300" size="lg">
                                                <Input
                                                    bg="white"
                                                    placeholder="name"
                                                    {...formProps.register(
                                                        'organization.assetName'
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {
                                                    formProps.formState.errors
                                                        .organization?.assetName?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            isInvalid={
                                                !!formProps.formState.errors.organization
                                                    ?.assetUrl
                                            }
                                        >
                                            <FormLabel
                                                htmlFor="companyWebsite"
                                                fontWeight="semibold"
                                            >
                                                Website URL
                                            </FormLabel>
                                            <InputGroup borderColor="gray.300" size="lg">
                                                <InputLeftAddon bg="gray.200">
                                                    <Text color="gray.500">https://</Text>
                                                </InputLeftAddon>
                                                <Input
                                                    bg="white"
                                                    placeholder="Website URL"
                                                    {...formProps.register(
                                                        'organization.assetUrl',
                                                        {
                                                            required: 'Required',
                                                        }
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {
                                                    formProps.formState.errors
                                                        .organization?.assetUrl?.message
                                                }
                                            </FormErrorMessage>
                                        </FormControl>
                                    </>
                                )}
                            </VStack>
                            <Button w="full" size="lg" type="submit">
                                Register
                            </Button>
                        </VStack>
                    </VStack>
                </form>
            </React.Suspense>
        );
    };
};
