import { useQuery } from '@tanstack/react-query';
import { PriceDto } from '../../../api';
import { ApplicationContext } from '../../../base';
import { PricingPlan } from '../plan';
import { createSessionHandler } from './sessionMutation';

export const useSession = (
    context: ApplicationContext,
    plan: Pick<PricingPlan, 'id'>,
    options: { successPath: string; cancelPath: string }
) => {
    const handler = createSessionHandler(context, {
        plan: plan.id,
        success_path: options.successPath,
        cancel_path: options.cancelPath,
    });
    const query = useQuery(['billing', 'sessions', plan.id], handler);
    return query;
};
