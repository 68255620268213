import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { MetricResource } from './metricInterface';
import { MetricListResponseSchema } from './metricSchema';

export function createMetricResource(client: AxiosInstance): MetricResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            let params: Record<string, string | string[]> = {};
            let headers: Record<string, string> = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };
            const response = await client.get('/api/v2/metrics', {
                params,
                headers,
            });
            const parsed = MetricListResponseSchema.parse(response.data);

            return parsed;
        },
    };
}
