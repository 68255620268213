import { useMemo } from 'react';
import { PageIds } from '../../../../../config';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import { SettingImportConfig } from '../importConfig';
import { SettingImportController } from './importListInterface';

export function createSettingImportListController(
    init: Pick<SettingImportConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingImportController {
    const {
        controller: {
            dataset: {
                import: { list: viewController },
            },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ASSET_IMPORTS_LIST,
                    breadcrumbs: [
                        {
                            label: 'Imports',
                        },
                    ],
                },
            });

            const viewProps = viewController.useProps(context, props);

            return {
                getViewProps() {
                    return viewProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getAlertProps() {
                    return pageProps.getAlertProps();
                },
                getPageActionProps() {
                    return {
                        link: {
                            to: `/u/assets/${context.workspace.id}/settings/imports/new`,
                        },
                        button: {
                            children: 'New Import',
                        },
                    };
                },
            };
        },
    };
}
