import { useEffect } from 'react';
import { OnboardingAssetPrepareConfig } from './onboardingPrepareConfig';
import { OnboardingPrepareController } from './onboardingPrepareInterface';
import { OnboardingPrepareViewProps } from './onboardingPrepareProps';

export function createOnboardingPrepareController(
    config: OnboardingAssetPrepareConfig
): OnboardingPrepareController {
    const {
        controller,
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(context, data, props): OnboardingPrepareViewProps {
            const viewProps = controller.prepare.useProps(context, data, props);

            const [, setOnboardingState] = useLocalStorage(
                context.organization.id.toString()
            );

            useEffect(() => {
                setOnboardingState({
                    step: 'prepare',
                    organization: context.organization.id,
                    asset: context.workspace.id as number,
                });
            }, []);

            if (viewProps.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            const formProps = viewProps.getFormProps();

            const handleSubmitClick = async () => {
                const response = await formProps.onSubmit();
                props.onContinue?.();
            };

            return {
                status: 'success',
                getFormProps() {
                    return {
                        ...formProps,
                        isLoading: formProps.isSubmitting,
                        onSubmit: handleSubmitClick,
                        submitLabel: `Please wait for your data to sync before continuing`,
                    };
                },
                getViewProps() {
                    return viewProps;
                },
            };
        },
    };
}
