import { periodToRange } from '../../../../domain';
import { ExcludeNullFromUnions } from '../../../util';
import { OpportunityCollectionControllerProps } from '../../../view';
import { KEY_BY_SORTING } from './insightRouteConstant';
import { InsightRouteStateValue } from './insightRouteModel';

export function buildOpportunityControllerProps(
    value: ExcludeNullFromUnions<InsightRouteStateValue>
): OpportunityCollectionControllerProps {
    const absolute = periodToRange(null, value.period);
    return {
        query: {
            limit: 100,
            aggregate: {
                keys: ['definition', 'kind'],
                take: 1,
            },
            orderBy: [
                {
                    key: KEY_BY_SORTING[value.sorting],
                    direction: 'desc',
                },
            ],
            since: absolute.start,
            before: absolute.end,
        },
    };
}
