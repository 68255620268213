import { PageController } from '../../../view/page';
import { PermissionMiddleware } from '../permissionInterface';

export function createPermissionPageMiddleware(): PermissionMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(controller: PageController): PageController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, props] = args;

                    const {
                        data: { roles },
                    } = instance.hook.useOrganizationContext();

                    const permissionProps = api.controller.useProps(context, {
                        page: props.item.id,
                        item: {
                            roles,
                        },
                    });

                    const viewProps = controller.useProps(
                        context,
                        permissionProps.access === 'read' ||
                            permissionProps.access === 'none'
                            ? {
                                  ...props,
                                  status: {
                                      type: 'readonly',
                                      status: 'warning',
                                      description: permissionProps.text,
                                  },
                              }
                            : props
                    );

                    return viewProps;
                },
            };
        }
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                layout: {
                    ...config.controller.layout,
                    createPage(...args) {
                        const controller = config.controller.layout.createPage(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
