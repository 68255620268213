import { ValueFormatter } from '../../../../app/attributes';
import { OpportunityItemAggregate } from './itemModel';
import { OpportunityItemViewProps } from './itemProps';

export function buildOpportunityItemProps(
    formatter: ValueFormatter,
    item: OpportunityItemAggregate
): OpportunityItemViewProps {
    const timeAgo = formatter.format('datetime', item.insight.period_end_at, {
        relative: true,
    });
    const range = formatter.format(
        'daterange',
        { from: item.insight.period_start_at, to: item.insight.period_end_at },
        {}
    );
    return {
        status: 'success',
        id: item.insight.id,
        iconUrl: item.plugin.iconUrl ?? '',
        title: item.insight.title,
        description: item.insight.body,
        date: {
            relative: {
                label: timeAgo,
            },
            range: {
                label: range,
            },
            description: `Insight was detected ${timeAgo} and is based on data for the period ${range}`,
        },
        path: null,
        actions: [],
        onOpenClick() {},
    };
}
