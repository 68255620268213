import { StudiesBaseRouteConfig } from '../../base';
import { StudiesResponseItemLoader } from './studiesResponseItemInterface';

export function createStudiesResponseItemLoader(
    config: Pick<StudiesBaseRouteConfig, 'repository' | 'strategy'>
): StudiesResponseItemLoader {
    return {
        useLoad(context, props) {
            return {
                visualization: props.visualization,
                dataset: props.dataset,
            };
        },
    };
}
