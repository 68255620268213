import { chain } from 'lodash';
import { useMemo } from 'react';
import { AccountsHelpers } from '../../../domain/accounts';
import { HomeExpertCallController } from './homeExpertCallInterface';

export function createHomeExpertCallController(): HomeExpertCallController {
    return {
        useProps(context, props) {
            return {
                hrefProps: {
                    id: 'home_tactical_calls_learn_more_link',
                    href: 'https://win.varos.com/en/articles/9352014-varos-best-practices',
                },
                links: {
                    overview: {
                        id: 'home_tactical_calls_learn_more_link',
                        to: `/u/assets/${context.workspace.id}/tactical-calls`,
                    },
                    new: {
                        id: 'home_tactical_calls_cta_link',
                        // to: `/u/assets/${context.workspace.id}/tactical-calls/requests/new`,
                        to: `/u/assets/${context.workspace.id}/tactical-calls`,
                    },
                },
            };
        },
    };
}
