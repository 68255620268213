import { useMemo } from 'react';
import { PageIds } from '../../../../../config';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import { SettingImportConfig } from '../importConfig';
import { SettingImportNewController } from './importNewInterface';

export function createSettingImportNewController(
    init: Pick<SettingImportConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingImportNewController {
    const {
        controller: {
            dataset: {
                import: { new: viewController },
            },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;

    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ASSET_IMPORTS_LIST,
                    breadcrumbs: [
                        {
                            label: 'Imports',
                        },
                        {
                            label: 'New',
                        },
                    ],
                },
            });

            const viewProps = viewController.useProps(context, deps.view, {
                ...props,
                onFileUpload(items) {
                    deps.toast({
                        durationMs: 2500,
                        kind: 'info',
                        description: 'File succesfully uploaded',
                    });
                },
                onFileError(error) {
                    deps.toast({
                        durationMs: 5000,
                        kind: 'error',
                        description: error.message,
                    });
                },
                onFormSubmit(item) {
                    deps.toast({
                        durationMs: 2500,
                        kind: 'success',
                        description: 'Import succesfully created',
                    });
                    deps.navigate('..');
                },
                onFormError(error) {
                    deps.toast({
                        durationMs: 2500,
                        kind: 'error',
                        description: error.message,
                    });
                },
            });

            const datasetProps = viewProps.getDatasetSelectListProps();

            return {
                getViewProps() {
                    return viewProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getCancelActionProps() {
                    return {
                        link: {
                            to: `..`,
                        },
                        button: {
                            children: 'Cancel',
                        },
                    };
                },
                getLayoutProps() {
                    const showUpload = !!datasetProps.current;
                    const showPreview =
                        viewProps.getEntryListProps().getTableProps().items.length > 0;
                    return {
                        showSchema: showUpload,
                        showUpload: showUpload,
                        showPreview: showPreview,
                        showConfirm: showUpload && showPreview,
                    };
                },
            };
        },
    };
}
