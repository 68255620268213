import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { PeerSetResource } from './peerSetInterface';
import { PeerSetListDtoSchema, PeerSetDtoSchema } from './peerSetSchema';

export function createPeerSetResource(client: AxiosInstance): PeerSetResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {
                'expand[]': 'members',
            };

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get('/api/v1/traits/competitive_sets', {
                params: {
                    ...params,
                    ...query,
                },
                headers,
            });
            try {
                const parsed = PeerSetListDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async lookup(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {
                'expand[]': 'members',
            };

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get(`/api/v1/traits/competitive_sets/${id}`, {
                params,
                headers,
            });
            try {
                const parsed = PeerSetDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async create(context, props) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {
                'expand[]': 'members',
            };

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.post(`/api/v1/traits/competitive_sets`, props, {
                params,
                headers,
            });
            try {
                const parsed = PeerSetDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async update(context, id, props) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {
                'expand[]': 'members',
            };

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.patch(
                `/api/v1/traits/competitive_sets/${id}`,
                props,
                {
                    params,
                    headers,
                }
            );
            try {
                const parsed = PeerSetDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async remove(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {
                'expand[]': 'members',
            };

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.delete(
                `/api/v1/traits/competitive_sets/${id}`,
                {
                    params,
                    headers,
                }
            );

            return;
        },
    };
}
