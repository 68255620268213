import { range } from 'lodash';
import { Bucket } from '../bucket';

export interface Scale {
    points: number[];
}

// export function normalize(scale: Scale, value: number): number {
//     const { points } = scale;
//     const sortedPoints = [...points].sort((a, b) => a - b);
//     const min = sortedPoints[0];
//     const max = sortedPoints[sortedPoints.length - 1];

//     if (value <= min) return 0;
//     if (value >= max) return 100;

//     for (let i = 1; i < sortedPoints.length; i++) {
//         if (value <= sortedPoints[i]) {
//             const rangeMin = sortedPoints[i - 1];
//             const rangeMax = sortedPoints[i];
//             const positionWithinRange = (value - rangeMin) / (rangeMax - rangeMin);
//             const scaleMin = (i - 1) * (100 / (sortedPoints.length - 1));
//             const scaleMax = i * (100 / (sortedPoints.length - 1));
//             return scaleMin + positionWithinRange * (scaleMax - scaleMin);
//         }
//     }

//     // If value is beyond the range covered by points (shouldn't happen due to earlier checks)
//     return -1; // Indicates error
// }

export function normalizeBuckets<TBucket extends Bucket>(
    buckets: TBucket[],
    inverse: boolean = false
): TBucket[] {
    const min = Math.min(...buckets.map((b) => b.lower));
    const max = Math.max(...buckets.map((b) => b.upper));

    function _normalize(
        value: number,
        min: number,
        max: number,
        inverse: boolean
    ): number {
        let normalized = ((value - min) / (max - min)) * 100;
        return inverse ? 100 - normalized : normalized;
    }

    return buckets.map(
        (bucket): TBucket => ({
            ...bucket,
            lower: _normalize(bucket.lower, min, max, inverse),
            upper: _normalize(bucket.upper, min, max, inverse),
        })
    );
    // // Determine the min and max values from the buckets
    // const min = Math.min(...buckets.map((b) => b.lower));
    // const max = Math.max(...buckets.map((b) => b.upper));

    // function _normalize(value: number, min: number, max: number): number {
    //     return ((value - min) / (max - min)) * 100;
    // }
    // // Map each bucket to the new scale
    // return buckets.map((bucket) => ({
    //     ...bucket,
    //     lower: _normalize(bucket.lower, min, max),
    //     upper: _normalize(bucket.upper, min, max),
    // }));
}

export function normalize(
    scale: Scale,
    value: number
    // inverse: boolean = false
): number {
    const { points } = scale;
    const sortedPoints = [...points].sort((a, b) => a - b);
    const min = sortedPoints[0];
    const max = sortedPoints[sortedPoints.length - 1];

    if (value <= min) return 0;
    if (value >= max) return 100;

    for (let i = 1; i < sortedPoints.length; i++) {
        if (value <= sortedPoints[i]) {
            const rangeMin = sortedPoints[i - 1];
            const rangeMax = sortedPoints[i];
            const positionWithinRange = (value - rangeMin) / (rangeMax - rangeMin);
            const scaleMin = (i - 1) * (100 / (sortedPoints.length - 1));
            const scaleMax = i * (100 / (sortedPoints.length - 1));
            const score = scaleMin + positionWithinRange * (scaleMax - scaleMin);
            return score;
            // return inverse ? 100 - score : score;
        }
    }

    // If value is beyond the range covered by points (shouldn't happen due to earlier checks)
    return -1; // Indicates error
}

// const scale = {
//     points: [271, 118, 45, 13, 3],
// };

// const value = 18;
// console.log(
//     'NORMAL',
//     range(0, 100, 1).map((i) => [i, normalize(scale, i, true)])
// );
