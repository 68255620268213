import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useAuthenticatedContext } from '../../../../container';
import { useAccessView, AccessRestrictView } from '../../../../views';
import { DashboardRouteEnhancer } from '../../../entrypoint';
import { useFeatureApi } from '../../../app';
import { FeatureDashboardEnhancerConfig } from './featureDashboardConfig';

export function createFeatureDasboardStrategy(
    init: FeatureDashboardEnhancerConfig
): DashboardRouteEnhancer {
    return (create) => (config) => {
        const Component = create({
            ...config,
            controller: {
                ...config.controller,
            },
        });
        return (props) => {
            const context = useAuthenticatedContext();
            const api = useFeatureApi();
            const featureset = api.getFeatureset();

            const { [init.urlParameterNames.dashboard]: dashboardId } = useParams();
            if (!dashboardId) {
                console.warn(
                    `url parameter '${init.urlParameterNames.dashboard}' not found`
                );
                return <Component {...props} />;
            }
            
            const accessViewProps = useAccessView(
                context,
                featureset.dashboard
                    ? {
                          ...featureset.dashboard,
                          value: { slug: dashboardId },
                      }
                    : null
            );

            if (featureset.dashboard?.config?.exclude?.includes(dashboardId)) {
                return (
                    <Flex h="full" w="full" justifyContent="center">
                        <AccessRestrictView
                            layout={{
                                header: {
                                    fontSize: {
                                        base: '4xl',
                                        '2xl': '6xl',
                                    },
                                },
                                tagline: {
                                    fontSize: {
                                        base: 'xl',
                                        '2xl': '2xl',
                                    },
                                },
                                base: {
                                    fontSize: {
                                        base: 'lg',
                                        '2xl': 'xl',
                                    },
                                },
                                container: {
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    // maxW: { base: '100%', xl: '66%' },
                                    borderRadius: { base: 8, '2xl': 12 },
                                    // NOTE: the sidebar layout content padding should be refactored to the child layouts as different
                                    // views need different padding and to avoid this hack
                                    m: {
                                        base: 8,
                                        '2xl': 12,
                                        // base: 8 - SIDEBAR_CONTENT_PADDING,
                                        // '2xl': 12 - SIDEBAR_CONTENT_PADDING,
                                    },
                                },
                                list: {
                                    spacing: { base: 8, '2xl': 12 },
                                },
                                item: {
                                    p: { base: 8, '2xl': 12 },
                                },
                                actions: {
                                    spacing: { base: 4, '2xl': 6 },
                                },
                            }}
                            {...accessViewProps}
                        />
                    </Flex>
                );
            }
            // if(featureset.plugin?.config?.include()){
// 
            // }
            return <Component {...props} />;
        };
    };
}
