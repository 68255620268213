import React, { Suspense, useMemo } from 'react';
import { CardSpinner } from '../../../domain';
import { DemoEntrypointConfig } from './demoConfig';
import { DemoEntryEnhancer } from './demoMiddleware';

export function createDemoEntrypoint(
    config: DemoEntrypointConfig,
    enhancer?: DemoEntryEnhancer
): React.ReactElement {
    if (enhancer) {
        return enhancer(createDemoEntrypoint)(config);
    }
    const {
        components: { Provider, Route, Outlet },
        route: {
            demo: { config: demoConfig, create: demoProvider },
        },
    } = config;

    const infra = {
        errorFormatter: config.infra.createErrorFormatter(),
    };

    const DemoRoute = demoProvider({
        ...demoConfig,
        infra: {
            errorFormatter: infra.errorFormatter,
        },
    });

    return (
        <Route
            element={
                <Provider>
                    <Outlet />
                </Provider>
            }
        >
            <Route
                path="demo"
                element={
                    <Suspense fallback={<CardSpinner />}>
                        <DemoRoute>
                            <Outlet />
                        </DemoRoute>
                    </Suspense>
                }
            />
        </Route>
    );
}
