import { InsightApi } from './insightsInterface';
import { InsightsApiConfig } from './insightsConfig';
import { createInsightResource } from './insight';
import { createInsightDefinitionResource } from './definition';

export function createInsightApi(config: InsightsApiConfig): InsightApi {
    const { client } = config;
    return {
        insights: createInsightResource(client),
        definitions: createInsightDefinitionResource(client),
    };
}
