export function intersect<TValue>(left: Set<TValue>, right: Set<TValue>): Set<TValue> {
    return new Set([...left].filter((x) => right.has(x)));
}

export function symmetricDifference<TValue>(left: Set<TValue>, right: Set<TValue>) {
    const setA = new Set(left);
    const setB = new Set(right);

    const diffA = Array.from(setA).filter((x) => !setB.has(x));
    const diffB = Array.from(setB).filter((x) => !setA.has(x));

    return new Set([...diffA, ...diffB]);
}
