import { isArray, isObject, transform } from 'lodash';

type MapKeyFn<TObj> = (key: string, value: unknown) => string;

export function mapKeys<TObj extends object>(object: TObj, callback: MapKeyFn<TObj>) {
    return transform<any, any>(object, (acc, value, key, target) => {
        const camelKey = isArray(target) ? key : callback(key, value);
        acc[camelKey] = isObject(value) ? mapKeys(value, callback) : value;
    });
}
