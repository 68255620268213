import { camelCase, snakeCase } from 'lodash';
import { mapKeys } from '../object';

export function camelCaseKeys(value: object) {
    return mapKeys(value, (key, value) => camelCase(key));
}

export function snakeCaseKeys(value: object) {
    return mapKeys(value, (key, value) => snakeCase(key));
}

export function downcaseUnlessAllCaps(input: string): string {
    // Check if the string is not all uppercase
    if (input !== input.toUpperCase()) {
        // If not, downcase the entire string
        return input.toLowerCase();
    }
    // If it is all uppercase, return it unchanged
    return input;
}
