import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { createDestinationFeedbackEnhancer } from './destination';
import { FeedbackEnhancerConfig } from './feedbackConfig';
import { createInsightFeedbackEnhancer } from './insight';
import { createVerticalFeedbackEnhancer } from './vertical';

export function createFeedbackEnhancer(
    init: FeedbackEnhancerConfig
): ApplicationEntryEnhancer {
    return compose(
        createVerticalFeedbackEnhancer(init),
        createInsightFeedbackEnhancer(init),
        createDestinationFeedbackEnhancer(init)
    );
}
