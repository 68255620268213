import { AxiosInstance, AxiosResponse } from 'axios';

export interface SubscriptionDto {
    id: number;
    plan: string;
    active: boolean;
    customer: string;
    add_ons: {addon_id: string, product_id: string }[];
}

export interface CreateSubscriptionPayload {
    plan: string;
}

export interface UpdateSubscriptionPayload {
    add_ons?: {addon_id: string, product_id: string }[];
    plan?: string;
}

export interface CreateSubscriptionOptions {
    wait: number;
    idempotency_key: string;
}

export const getSubscription = async (api: AxiosInstance) => {
    const response = await api.get<unknown, AxiosResponse<SubscriptionDto>>(
        `/api/v1/billing/subscription`
    );
    return response.data;
};

export const createSubscription = async (
    api: AxiosInstance,
    payload: CreateSubscriptionPayload,
    options: Partial<CreateSubscriptionOptions> = {}
) => {
    const response = await api.post<unknown, AxiosResponse<SubscriptionDto>>(
        `/api/v1/billing/subscription`,
        payload,
        { params: options }
    );
    return response.data;
};
