import {
    AnyValue,
    DateRangeValue,
    DurationValue,
    MaybePartialNumberRangeValue,
    MovementValue,
    NullEndNumberRangeValue,
    NullStartNumberRangeValue,
    NumberRangeValue,
    PartialNumberRangeValue,
} from './valueModel';

export function isNumericRangeValue(value: AnyValue): value is NumberRangeValue | null {
    return (
        typeof (value as NumberRangeValue)?.from === 'number' &&
        typeof (value as NumberRangeValue)?.to === 'number'
    );
}

export function isMaybePartialNumericRangeValue(
    value: AnyValue
): value is PartialNumberRangeValue | NumberRangeValue {
    return (
        isNullStartNumericRangeValue(value) ||
        isNullEndNumericRangeValue(value) ||
        isNumericRangeValue(value)
    );
}

export function isNullStartNumericRangeValue(
    value: AnyValue
): value is NullStartNumberRangeValue {
    return (
        (value as MaybePartialNumberRangeValue)?.from === null &&
        typeof (value as MaybePartialNumberRangeValue)?.to === 'number'
    );
}

export function isNullEndNumericRangeValue(
    value: AnyValue
): value is NullEndNumberRangeValue {
    return (
        typeof (value as MaybePartialNumberRangeValue)?.from === 'number' &&
        (value as MaybePartialNumberRangeValue)?.to === null
    );
}

export function isDateRangeValue(value: AnyValue): value is DateRangeValue {
    return (
        (value as DateRangeValue | null)?.from instanceof Date &&
        (value as DateRangeValue | null)?.to instanceof Date
    );
}

export function isDurationValue(value: AnyValue): value is DurationValue {
    return (
        typeof (value as DurationValue | null)?.amount === 'number' &&
        typeof (value as DurationValue | null)?.interval === 'string'
    );
}

export function isMovementValue(value: AnyValue): value is MovementValue {
    return (
        ((value as MovementValue).base == null ||
            typeof (value as MovementValue | null)?.base === 'number') &&
        ((value as MovementValue | null)?.comparison == null ||
            typeof (value as MovementValue | null)?.comparison === 'number') &&
        ((value as MovementValue | null)?.change == null ||
            typeof (value as MovementValue | null)?.change === 'number')
    );
}

export function isEnumValue(value: AnyValue): value is string {
    return typeof value === 'string';
}

export function isTreeValue(value: AnyValue): value is string[] {
    return Array.isArray(value);
}
