import { AnyVisualizationCreateProps } from '../../../domain/visualization';
import { VisualizationCreatePropsDto } from './visualizationDto';

export function toVisualizationDto(
    props: AnyVisualizationCreateProps
): VisualizationCreatePropsDto {
    return {
        metric: props.metrics.map((item) => item.id),
        kind: props.kind,
        period: props.period ?? undefined,
        filters: props.filters ?? undefined,
        view: props.view ?? undefined,
        granularity: props.granularity,
        ranking: props.ranking ?? undefined,
        breakdown: props.breakdown ?? undefined,
    };
}

export function fromVisualizationDto(
    dto: VisualizationCreatePropsDto
): AnyVisualizationCreateProps {
    if (dto.kind === 'comparison') {
        return {
            key: dto.metric.join('_'),
            name: null,
            description: null,
            kind: dto.kind,
            metrics: dto.metric.map((item) => ({
                id: item,
            })),
            period: dto.period ?? null,
            view: dto.view ?? null,
            filters: dto.filters as any,
            granularity: dto.granularity,
            ranking: dto.ranking ?? null,
            breakdown: dto.breakdown ?? null,
        };
    }
    if (dto.kind === 'bar') {
        return {
            kind: dto.kind,
            // @ts-expect-error TODO we need to handle the aggregation property
            aggregation: dto.aggregation,
            metrics: [],
            period: dto.period ?? null,
            view: dto.view ?? null,
            filters: dto.filters as any,
            granularity: dto.granularity,
            ranking: dto.ranking ?? null,
            breakdown: dto.breakdown ?? null,
        };
    }
    // multiple metrics are not supported in this case
    const [metric, ...rest] = dto.metric;
    return {
        // TODO find a valid key, right now we assume that these visualizations won't be displayed in a list
        // for which a unique key is required
        key: metric,
        name: null,
        description: null,
        kind: dto.kind,
        metrics: [{ id: metric }],
        period: dto.period ?? null,
        view: dto.view ?? null,
        filters: dto.filters as any,
        granularity: dto.granularity,
        ranking: dto.ranking ?? null,
        breakdown: dto.breakdown ?? null,
    };
}
