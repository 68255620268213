import { Box, VStack, StackProps } from '@chakra-ui/react';
import React from 'react';
import type { DashboardLayoutComponents } from '../dashboardLayoutInterface';

export const DashboardControlList: DashboardLayoutComponents['Control']['List'] = (
    props
) => {
    return <>{props.children}</>;
};

export const DashboardControlItem: DashboardLayoutComponents['Control']['Item'] = (
    props
) => {
    return <>{props.children}</>;
};
