import { UseMinimalLayoutReturnValue } from './minimalLayoutModel';

export const useMinimalLayout = (): UseMinimalLayoutReturnValue => {
    return {
        getContainerProps() {
            return {};
        },
        getContentProps() {
            return {};
        },
    };
};
