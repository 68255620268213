import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { getChildrenProps } from '../../../../util';
import {
    IconComponents,
    isImageIconProps,
    isRawIconProps,
    GridComponents,
} from '../../../../ui';
import { QuestionIcon } from '@chakra-ui/icons';

export function createGridComponents(): GridComponents {
    const Instance: GridComponents = {
        GridContainer(props) {
            throw new Error('not impl');
        },
        GridList(props) {
            return <>{props.children}</>;
        },
        GridItem(props) {
            return <>{props.children}</>;
        },
        GridContent(props) {
            return <>{props.children}</>;
        },
        GridHeader(props) {
            return <>{props.children}</>;
        },
        GridBody(props) {
            return <>{props.children}</>;
        },
        GridFooter(props) {
            return <>{props.children}</>;
        },
    };
    return Instance;
}
