import { RolePageAccess } from '../../config/permission';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { PermissionController } from './permissionInterface';
import { PermissionViewProps } from './permissionProps';

export function createPermissionController(): PermissionController {
    return {
        useProps(context, props) {
            const permissions = useMemo(
                () =>
                    chain(props.item.roles)
                        .flatMap((role) => {
                            const permissions = RolePageAccess[role.id]?.[props.page];
                            return permissions ? permissions : [];
                        })
                        .uniq()
                        .value(),
                [props.item.roles]
            );
            const viewProps = useMemo<PermissionViewProps>(() => {
                if (permissions.includes('write')) {
                    return {
                        access: 'full',
                    };
                }
                if (permissions.includes('read')) {
                    return {
                        access: 'read',
                        text: `These settings can only be edited by organization admins`,
                    };
                }
                return {
                    access: 'none',
                    text: `These settings can only be accessed by organization admins`,
                };
            }, [permissions]);

            return viewProps;
        },
    };
}
