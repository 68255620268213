import { QueryFunction } from '@tanstack/react-query';
import {
    DashboardListItem,
    listDashboards,
    DashboardListFlags,
    DashboardFlagQueryOperator,
} from '../../api';
import { ApplicationContext } from '../context';

export function listDashboardHandler(
    context: ApplicationContext,
    assetIds: number[],
    dashbard?: string,
    operator?: DashboardFlagQueryOperator,
    flags?: Array<DashboardListFlags>
): QueryFunction<DashboardListItem[]> {
    return async () => {
        const response = await listDashboards(
            context.api,
            assetIds,
            dashbard,
            undefined,
            operator,
            flags,
            { callerName: 'handler' }
        );
        return response.items;
    };
}
