export const ACCEPT_PROFILE_ROLES = [
    'Agency - CEO',
    'Agency - VP or Director or C-Suite',
    'Agency - Account Manager or Other',
    'Brand - Marketer or other',
    'Brand - CMO/CFO| VP or Director',
    'Brand - CEO',
    'SaaS - CEO',
    'SaaS - VP or Director or C-Suite',
    'SaaS - Marketer or Other',
    'Other',
].map((item) => ({ label: item, value: item }));
