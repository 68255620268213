import normalizeUrl from 'normalize-url';
import { MappingConnection } from '../connection';
import { AssetStatus } from './assetEnum';
import { Asset } from './assetModel';

export function getAssetStatus(item: {
    asset: Asset;
    mappings: MappingConnection[];
}): AssetStatus {
    if (item.mappings.length === 0) {
        return 'unmapped';
    }
    return 'ready';
}

/**
 * Returns a normalized asset domain for display
 * @param asset
 * @returns
 */
export function getAssetDomain(asset: Pick<Asset, 'url'>) {
    let url = asset.url;
    if (!url) {
        return null;
    }
    try {
        const normalized = normalizeUrl(url);
        return new URL(normalized).hostname;
    } catch {
        return url;
    }
}
