import { useColorMode } from '@chakra-ui/react';
import { useRedirect } from '../../../../domain';
import { useSessionApi } from '../../../app/auth';
import { useUserApi } from '../../../app/platform';
import { UserNavigationViewProps } from '../../../view/platform';
import { UserNavigationController } from './userSelectionInterface';
import { UserSelectionConfig } from './userSelectionConfig';
import { useNavigate } from 'react-router';
import { useDisclosure } from '@chakra-ui/react';

export function createUserController(
    config: UserSelectionConfig
): UserNavigationController {
    return () => {
        const { colorMode, toggleColorMode, setColorMode } = useColorMode();
        const session = useSessionApi();
        const user = useUserApi();
        const navigate = useNavigate();
        const redirectPortal = useRedirect('portal');
        const inviteDisclosure = useDisclosure();
        const auth = config.hook.useAuth();
        const createUser = config.service.user.useCreate({ auth });
        const currentUser = user.getUser();
        const handleInvite: UserNavigationViewProps['onInvite'] = () => {
            // if(currentUser.isVaros) {
            inviteDisclosure.onOpen();
            // } else {
            // window.open(config.addTeamMemberUrl, '_blank');
            // }
        };

        const handleBilling: UserNavigationViewProps['onBilling'] = () => {
            navigate('/u/settings/subscription');
            // redirectPortal.actions.redirect({
            //     type: 'portal',
            //     returnPath: window.location.pathname,
            // });
        };

        const handleSettings: UserNavigationViewProps['onSettings'] = () => {
            navigate('/u/settings');
            // if(currentUser.isVaros) {
            //     navigate('/u/settings/permissions');
            // } else {
            //     navigate('/u/settings/assets/reports');
            // }
        };

        const handleNotifications: UserNavigationViewProps['onNotifications'] = () => {
            navigate('/u/settings/notifications');
        };

        const handleSignout: UserNavigationViewProps['onSignout'] = async () => {
            if (colorMode === 'dark') {
                setColorMode('light');
            }

            // this is a hack to fix this issue
            // https://github.com/chakra-ui/chakra-ui/issues/5925
            if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
                localStorage.setItem('chakra-ui-color-mode', 'light');
            }

            await session.signout();
            // TODO: for some reason navigate does not work properly here
            window.location.href = '/login';
            // navigate('/login', { replace: true });
        };

        return {
            inviteUser: {
                props: {
                    ...inviteDisclosure,
                    onSubmit: createUser,
                },
                Form: config.InviteUserForm,
                shouldShow: inviteDisclosure.isOpen,
            },
            user: currentUser,
            onBilling: handleBilling,
            onInvite: handleInvite,
            onSignout: handleSignout,
            onSettings: handleSettings,
            onNotifications: handleNotifications,
        };
    };
}
