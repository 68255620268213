import { UseDisclosureReturn } from '@chakra-ui/hooks';
import React, { useContext } from 'react';

export interface FeatureContextValue extends UseDisclosureReturn {}

export const FeatureContext = React.createContext<FeatureContextValue | null>(null);

export const useFeatureContext = () => {
    const context = useContext(FeatureContext);
    if (!context) {
        throw new Error(`feature context not found available`);
    }
    return context;
};
