import React, { useEffect } from 'react';
import { useWorkspaceContextV2, WorkspaceContextLoader } from '../../context';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { DefaultsMiddlewareConfig } from './defaultsConfig';

export function createDefaultsEnhancer(
    init: DefaultsMiddlewareConfig
): ApplicationEntryEnhancer {
    const {
        metric: { collection: getCollectionProps },
    } = init;
    return (create) => (config) => {
        const {
            context: { createWorkspace: createWorkspaceContext },
        } = config;

        // /**
        //  * Creates a default collection if none is present
        //  * @param loader
        //  * @returns
        //  */
        // function enhanceLoader(loader: WorkspaceContextLoader): WorkspaceContextLoader {
        //     return {
        //         useData(context) {
        //             const {
        //                 api: {
        //                     metric: {
        //                         collections: { create: createCollection },
        //                     },
        //                 },
        //             } = instance;

        //             const data = loader.useData(context);

        //             let defaultCollection = data.data.metric.collections.data?.find(
        //                 (item) => item.default
        //             );

        //             useEffect(() => {
        //                 if (!defaultCollection && data.isSuccess) {
        //                     createDefaultCollection();
        //                 }
        //             }, [defaultCollection, data.isSuccess]);

        //             async function createDefaultCollection() {
        //                 console.log('auto creating default collection...');
        //                 const payloadOrError = getCollectionProps(
        //                     context,
        //                     data.data.metric.definitions.data?.flatMap((item) => {
        //                         const connection =
        //                             data.data.connections.data?.find(
        //                                 (connection) => connection.plugin === item.plugin
        //                             ) ?? null;
        //                         if (!connection) {
        //                             console.warn(
        //                                 `metric ${item.id} with plugin ${item.plugin} does not have a connection, skipping...`,
        //                                 data.data.connections.data
        //                             );
        //                             return [];
        //                         }
        //                         return [{ ...item, connection }];
        //                     }) ?? []
        //                 );
        //                 if (payloadOrError instanceof Error) {
        //                     console.warn(
        //                         `cannot create default collection:`,
        //                         payloadOrError.message
        //                     );
        //                     return;
        //                 }
        //                 const response = await createCollection(context, payloadOrError);
        //                 console.log('default collection created!', response);
        //                 await data.data.metric.collections.refetch();
        //             }

        //             return {
        //                 ...data,
        //                 isLoading: data.isLoading || !defaultCollection,
        //                 isSuccess: data.isSuccess && !!defaultCollection,
        //             };
        //         },
        //     };
        // }

        function enhanceRoute(
            Component: React.FC<{ children?: React.ReactNode }>
        ): React.FC<{ children?: React.ReactNode } & { children?: React.ReactNode }> {
            return (props) => {
                const {
                    api: {
                        metric: {
                            collections: { create: createCollection },
                        },
                    },
                } = instance;

                const auth = instance.hook.useAuth();
                const workspace = instance.hook.useWorkspace();

                const {
                    connections,
                    metric: { definitions, collections },
                } = useWorkspaceContextV2();

                let defaultCollection = collections.data?.find((item) => item.default);

                // console.log('deasdads', defaultCollection);

                useEffect(() => {
                    if (!defaultCollection && collections.isSuccess) {
                        // console.log('create default');
                        createDefaultCollection();
                    }
                }, [defaultCollection, collections.isSuccess]);

                async function createDefaultCollection() {
                    console.log('auto creating default collection...');
                    const payloadOrError = getCollectionProps(
                        { workspace },
                        definitions.data?.flatMap((item) => {
                            const connection =
                                connections.data?.find(
                                    (connection) => connection.plugin.id === item.plugin
                                ) ?? null;
                            if (!connection) {
                                console.warn(
                                    `metric ${item.id} with plugin ${item.plugin} does not have a connection, skipping...`,
                                    connections.data
                                );
                                return [];
                            }
                            return [{ ...item, connection }];
                        }) ?? []
                    );
                    if (payloadOrError instanceof Error) {
                        console.warn(
                            `cannot create default collection:`,
                            payloadOrError.message
                        );
                        return;
                    }
                    const response = await createCollection({ auth }, payloadOrError);
                    console.log('default collection created!', response);
                    await collections.refetch();
                }

                return <Component {...props} />;
            };
        }

        const instance = create({
            ...config,
            // context: {
            //     ...config.context,
            //     createWorkspace(config) {
            //         return createWorkspaceContext({
            //             ...config,
            //             provider: {
            //                 ...config.provider,
            //                 createLoader(...args) {
            //                     const loader = config.provider.createLoader(...args);
            //                     return enhanceLoader(loader);
            //                 },
            //             },
            //         });
            //     },
            // },
            route: {
                ...config.route,
                createHomeRoute(...args) {
                    const { Route, ...rest } = config.route.createHomeRoute(...args);
                    return {
                        ...rest,
                        Route: enhanceRoute(Route),
                    };
                },
            },
        });
        return instance;
    };
}
