import { VideoControllerProps } from '../../view/common';

const OVERVIEW_VIDEO: VideoControllerProps = {
    id: 'study_overview',
    title: `Improvement opportunities`,
    url: `https://www.loom.com/embed/3ccaf323ed1f4b48b551d70838b4a02c?sid=767cb150-edfe-4807-8f81-841730654258`,
};

export const StudyConstant = {
    OVERVIEW_VIDEO,
    MIN_NUMBER_OF_RESPONSES: 1000,
};
