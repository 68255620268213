import { SettingIntegrationConfig } from '../settingIntegrationConfig';
import { SettingIntegrationListController } from './integrationListInterface';

export function createSettingIntegrationListController(
    config: Pick<SettingIntegrationConfig, 'controller'>
): SettingIntegrationListController {
    const {
        controller: {
            integration: { list: listController },
        },
    } = config;
    return {
        useProps(context, deps, props) {
            const listProps = listController.useProps(context, deps.list, props);
            return {
                items: listProps.items,
                getItemProps(item) {
                    const itemProps = listProps.getItemProps(item);
                    return {
                        ...itemProps,
                        link: {
                            to: `${item.integration.id}`,
                        },
                    };
                },
                getListProps() {
                    return listProps;
                },
            };
        },
    };
}
