import { PluginAdapter } from '../../../app';
import { PluginImplConfig } from './pluginConfig';
import { toDomainPlugin } from './pluginMapper';

export function createPluginImpl(config: PluginImplConfig): PluginAdapter {
    const {} = config;
    return {
        async find(context, query) {
            const response = await config.api.dashboard.plugins.list(context, query);
            return response.data.flatMap((item) => {
                const result = toDomainPlugin(item);
                if (result instanceof Error) {
                    console.warn(result.message);
                    return [];
                }
                return [result];
            });
        },
    };
}
