import { TreeType } from '../typeModel';

export function flattenTreeValues(type: TreeType): unknown[] {
    const initial: unknown[] = [];
    return type.members.reduce(
        (acc, member) => [
            ...acc,
            member.value,
            ...member.children.flatMap((child) => child.value),
        ],
        initial
    );
}
