import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEvent } from '../../container';
import { ApplicationContext } from '../../base';
import { CompletedSessionDto } from '../../api';
import { getSubscriptionQueryKey, createGetSubscriptionHandler, createGetSessionHandler } from '../../domain';
import { UseReceiptViewReturnValue } from './receiptModel';
import { RedirectViewConfig } from './receiptConfig';
import React from 'react';

export const useReceiptView = (context: ApplicationContext, config: RedirectViewConfig): UseReceiptViewReturnValue => {
    const event = useEvent(context);

    const confirm = useCallback((session: CompletedSessionDto) => {
        event.dispatch({ type: 'receiptView', session });
    }, []);

    const session = useQuery({
        queryKey: ['receipt', 'session'],
        queryFn: createGetSessionHandler(context, config.session.id),
        suspense: true,
    });
    const subscriptionQueryKey = getSubscriptionQueryKey(context);
    const client = useQueryClient();
    const subscription = useQuery({
        // note that we re-use the query key to ensure that the application context gets
        // the most recent version of the subscription
        queryKey: subscriptionQueryKey,
        queryFn: createGetSubscriptionHandler(context, { pollUntilActiveMs: 1500, maxAttempts: 15 }),
        suspense: true,
    });

    React.useEffect(() => {
        return () => {
            client.cancelQueries(subscriptionQueryKey);
        };
    }, []);
    useEffect(() => {
        if (!session.data) {
            throw new Error(`session data not found`);
        }
        confirm(session.data);
    }, [session.data]);

    return {
        getProps() {
            return {
                redirectPath: config.redirect?.to,
                session: session.data!,
            };
        },
    };
};
