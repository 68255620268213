import { ButtonElementController } from './elementButtonInterface';

export function createButtonElementController(): ButtonElementController {
    return {
        useProps(context, item, props) {
            return {
                label: item.element.label,
                button: {
                    colorScheme: item.element.variant === 'primary' ? 'green' : undefined,
                    onClick: props.onSubmit,
                },
            };
        },
    };
}
