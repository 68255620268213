import { TimeGranularity, TimeGranularityType } from '../../../../models/Common';
import { isAbsolutePeriod } from '../../../../domain';
import {
    QueryResponse as QueryResponseApi,
    Comparison as ApiComparison,
    Schema as SchemaApi,
    QueryDto,
    ConditionObject,
    SchemaProperty,
    AnyType as AnyTypeApi,
} from '../../../../api';
import {
    AnyConstraintExpression,
    BetweenConstraintExpression,
    Constraint,
    Schema as SchemaDomain,
} from '../../../domain/dashboard';
import {
    QueryRequest,
    QueryResponse as QueryResponseDomain,
} from '../../../domain/query';
import { Comparison } from '../../../domain/query';
import {
    AnyType as AnyTypeDomain,
    isCurrencyType,
    Property,
} from '../../../domain/attributes';
import { castConditionToApi, castConditionToDomain } from '../condition';
import { ViewPropertyDto } from '../../../api';

function castComparisonToApi(comparison: Comparison): ApiComparison {
    if (isAbsolutePeriod(comparison)) {
        return {
            operator: 'between',
            value: [comparison.start, comparison.end],
        };
    }
    return {
        operator: 'previous',
        value: [comparison.amount, comparison.interval],
    };
}

export function castQueryToApi({
    segment,
    ...query
}: QueryRequest): QueryDto & { segment: ConditionObject[] } {
    return {
        ...query,
        granularity: query.granularity as TimeGranularity,
        comparison: query.comparison ? castComparisonToApi(query.comparison) : undefined,
        filters: query.filters.map(castConditionToApi),
        segment: segment.map(castConditionToApi),
    };
}

export function castQueryToDomain(
    segment: ConditionObject[],
    dto: QueryDto
): QueryRequest {
    return {
        ...dto,
        comparison: null,
        granularity: dto.granularity as TimeGranularityType,
        segment: segment.map(castConditionToDomain),
        filters: dto.filters?.map(castConditionToDomain) ?? [],
    };
}

export function castResponseToDomain(response: QueryResponseApi): QueryResponseDomain {
    return {
        ...response,
        queries: response.queries.map((query) => ({
            ...query,
            schema: {
                ...query.schema,
                properties: castSchemaToDomain(query.schema).properties,
            },
        })),
    };
}

export function castSchemaToDomain(schema: Pick<SchemaApi, 'properties'>): SchemaDomain {
    return {
        properties: Object.entries(schema.properties).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: castAndDowngradeSchemaPropertyToDomain(value),
            }),
            {}
        ),
    };
}

export function castViewPropertyToDomain(property: ViewPropertyDto): Property {
    const type = castTypeToDomain(property.type);
    return {
        key: property.source ?? property.key,
        name: property.name ?? property.key,
        description: property.description ?? null,
        type: castTypeToDomain(property.type),
        // HACK we should de-duplicate the type constraints on this model
        constraints: isCurrencyType(type) ? type.constraints : [],
        isDisabled: false,
        disabledReason: null,
    };
}

export function castAndDowngradeSchemaPropertyToDomain(
    property: SchemaProperty
): Property {
    return {
        key: property.key,
        name: property.title ?? property.key,
        description: property.description ?? null,
        type: castTypeToDomain(property.type, { downgradeReference: true }),
        constraints: [],
        isDisabled: false,
        disabledReason: null,
    };
}

export function castTypeToDomain(
    type: AnyTypeApi,
    options: { downgradeReference?: boolean } = {}
): AnyTypeDomain {
    const { downgradeReference = false } = options;
    if (typeof type === 'string') {
        return type;
    }
    if (type.kind === 'enum') {
        return {
            ...type,
            members: type.members.map((member) => ({
                label: member.name,
                value: member.value,
            })),
        };
    }
    if (type.kind === 'movement') {
        if (!type.base) {
            console.warn(`missing base type for movement`);
        }
        return {
            kind: 'movement',
            base: castTypeToDomain(type.base ?? 'string', options),
            inverse: type.inverse ?? false,
        };
    }
    if (type.kind === 'percent') {
        // v2 domain does always use fractional percentage values
        return 'percent';
    }
    if (type.kind == 'reference' && downgradeReference) {
        // todo: reference type for now referes to string but there should be a reference resolution here
        return 'string';
    }
    if (type.kind === 'currency') {
        return {
            ...type,
            constraints: type.constraints.flatMap((item): Constraint[] => {
                if (item.expression.operator === 'between') {
                    return [
                        {
                            ...item,
                            expression: {
                                operator: 'between',
                                value: {
                                    from: item.expression.value[0],
                                    to: item.expression.value[1],
                                },
                            },
                        },
                    ];
                }
                console.warn('unexpected constraint from api, skipping.', item);
                return [];
            }),
        };
    }
    return { ...type };
}
