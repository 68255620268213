import React from 'react';
import { WorkspaceNavigationContainerProps } from '../navigationProps';
import { WorkspaceNavigationContainerConfig } from '../navigationConfig';

export function createWorkspaceNavigationContainer(
    config: WorkspaceNavigationContainerConfig
): React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceNavigationContainerProps
> {
    const { controller, Component } = config;
    return () => {
        const viewProps = controller.useProps();
        return <Component {...viewProps} />;
    };
}
