import { useMemo } from 'react';
import { MetricCollectionDto } from '../../../api';
import { HomeViewItemController } from '../../../route';
import { HomeMetricConfig } from './homeMetricConfig';

export type HomeMetricData = {
    collection: MetricCollectionDto | null;
};

export function createHomeMetricController(
    config: HomeMetricConfig
): HomeViewItemController<HomeMetricData> {
    const {
        deps: { repository },
    } = config;
    return {
        useProps(context) {
            const collections = repository.collection.useFind(
                context,
                {
                    asset: context.workspace.id as number,
                },
                {}
            );

            const [collection] = useMemo(
                () => collections.data?.filter((item) => item.default) ?? [null],
                [collections.data]
            );

            if (!collection) {
                return {
                    enabled: false,
                    props: null,
                };
            }

            return {
                enabled: true,
                props: {
                    collection,
                },
            };
        },
    };
}
