import React from 'react';
import { ReportChartTrendContainerProps } from './trendChartProps';
import { ReportChartTrendController } from './trendChartInterface';
import { ReportContainerBaseConfig } from '../../../base';

export function createReportTrendChartContainer(
    config: ReportContainerBaseConfig,
    controller: ReportChartTrendController
): React.FC<ReportChartTrendContainerProps> {
    const {
        context: { useContext },
    } = config;
    return ({ as: View, visualization, ...containerProps }) => {
        const context = useContext();
        const props = controller.useProps(context, { visualization }, containerProps);
        return <View {...props} />;
    };
}
