import React from 'react';
import { SettingAccountItemProvider } from '../../../../route/settings/account';
import {
    createSettingIntegrationDetailContainer,
    createSettingIntegrationDetailController,
    createSettingIntegrationDetailLoader,
    createSettingIntegrationDetailView,
} from './detail';
import { SettingIntegrationConfig } from './settingIntegrationConfig';
import { createSettingIntegrationItemContainer } from './item';
import {
    createSettingIntegrationListLoader,
    createSettingAccountIntegrationListContainer,
    createSettingIntegrationListController,
    createSettingIntegrationListView,
} from './list';
import {
    SettingsIntegrationContainerConfig,
    SettingsIntegrationControllerConfig,
    SettingsIntegrationLoaderConfig,
} from './base';
import {
    createSettingMappingNewController,
    createSettingMappingNewView,
} from './mapping';

export function createSettingIntegrationStrategy(
    init: SettingIntegrationConfig
): SettingAccountItemProvider {
    return {
        create(config) {
            const containerConfig: SettingsIntegrationContainerConfig = {
                context: {
                    useContext: config.api.useContext,
                },
                infra: init.infra,
                repository: init.repository,
            };

            const loaderConfig: SettingsIntegrationLoaderConfig = {
                repository: init.repository,
            };

            const controllerConfig: SettingsIntegrationControllerConfig = {
                provider: init.provider,
                infra: init.infra,
            };

            const List = {
                Container: createSettingAccountIntegrationListContainer(
                    init,
                    config,
                    createSettingIntegrationListLoader(init),
                    createSettingIntegrationListController(init)
                ),
                View: createSettingIntegrationListView(
                    config,
                    createSettingIntegrationItemContainer(init, config)
                ),
            };

            const Detail = {
                Container: createSettingIntegrationDetailContainer(
                    containerConfig,
                    createSettingIntegrationDetailLoader(loaderConfig),
                    createSettingIntegrationDetailController(controllerConfig),
                    createSettingIntegrationDetailView(config)
                ),
            };

            return [
                {
                    scope: 'organization',
                    path: 'integrations',
                    key: 'integrations',
                    title: 'Integrations',
                    Component() {
                        return <List.Container as={List.View} />;
                    },
                    children: [
                        {
                            scope: 'organization',
                            path: ':integrationId',
                            key: 'detail',
                            title: 'Integration',
                            Component: Detail.Container,
                        },
                    ],
                },
            ];
        },
    };
}
