import mediamixPreview1 from '../../../images/mediamix_preview_1.png';
import mediamixPreview2 from '../../../images/mediamix_preview_2.png';
import type { FeatureFixture } from '../featureFixture';

export const EXECUTIVE_SUMMARY_HIGHLIGHTS: FeatureFixture[] = [
    {
        text: 'Discover how overall business health stacks up to your peers',
    },
    {
        text: 'Align your company behind unbiased KPIs',
    },
    {
        text: 'Access blended metrics across platforms',
    },
];

export const EXECUTIVE_SUMMARY_GRAPHICS: Array<{ src: string }> = [
    {
        src: mediamixPreview1,
    },
    {
        src: mediamixPreview2,
    },
];
