import { DownloadIcon } from '@chakra-ui/icons';
import { StackDivider, Box, Text, HStack } from '@chakra-ui/react';
import { Button } from '../../../../ui';
import React from 'react';
import { BsDownload, BsFillBookmarkFill } from 'react-icons/bs';
import { ActionProps } from './actionProps';

export const ActionView: React.FC<
    { children?: React.ReactNode | undefined } & ActionProps
> = ({ style, ...props }) => {
    const spacing =
        typeof style?.wrapper?.spacing === 'number'
            ? (style?.wrapper?.spacing as number)
            : 0;

    return (
        <HStack
            {...style?.wrapper}
            spacing={spacing * 2}
            alignItems="center"
            justifyContent="flex-end"
            // divider={<StackDivider my={2} borderColor={style?.wrapper?.borderColor} />}
        >
            {props.hasUnsavedFilters && (
                <HStack
                    spacing={spacing * 2}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button variant="link" onClick={props.onReset} {...style?.button}>
                        <Text>Discard changes</Text>
                    </Button>
                    <Button
                        leftIcon={<BsFillBookmarkFill />}
                        colorScheme="green"
                        onClick={props.onSave}
                        {...style?.button}
                        {...props.getSaveProps?.()}
                    >
                        Save view
                    </Button>
                </HStack>
            )}
            <HStack
                spacing={style?.wrapper?.spacing}
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    leftIcon={<DownloadIcon />}
                    onClick={props.onExport}
                    {...style?.button}
                    {...style?.buttonExport}
                    {...props.getExportProps?.()}
                >
                    Export
                </Button>
            </HStack>
        </HStack>
    );
};
