import React from 'react';
import { ReportContainerBaseConfig } from '../../base';
import { ReportRootContainerProps } from './reportRootProps';
import { ReportErrorContainerProps } from '../common';

export function createReportRootContainer(
    config: ReportContainerBaseConfig,
    View: {
        Error: React.FC<
            { children?: React.ReactNode | undefined } & ReportErrorContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & ReportRootContainerProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        return (
            <ErrorBoundary fallback={(info) => <View.Error info={info} />}>
                {children}
            </ErrorBoundary>
        );
    };
}
