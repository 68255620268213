import React from 'react';
import { TextProps } from '@chakra-ui/react';

export interface OrganizationLayoutDatasetTextProps {
    children: React.ReactElement;
}

export const OrganizationLayoutDatasetText: React.FC<
    { children?: React.ReactNode | undefined } & OrganizationLayoutDatasetTextProps
> = (props) => <>{props.children}</>;
