import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export interface DashboardLayoutMainProps {
    as?: React.ReactElement<BoxProps>;
}

export const DashboardLayoutMain: React.FC<
    { children?: React.ReactNode | undefined } & DashboardLayoutMainProps
> = (props) => <>{props.children}</>;
