import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { SearchController, SearchControllerConfig } from '../../../view/common';
import { AnySearchEvent } from './trackingSearchEvent';

export function createSearchTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnySearchEvent>();
            return tracker;
        }

        function enhanceController(
            config: SearchControllerConfig,
            controller: SearchController
        ): SearchController {
            return {
                ...controller,
                useProps(item, props, ...rest) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(
                        item,
                        {
                            ...props,
                            onChange(value) {
                                if (value.length > 0) {
                                    tracker.track('search_applied', {
                                        search_id: config.id,
                                        search_term: value,
                                    });
                                }
                                props.onChange(value);
                            },
                        },
                        ...rest
                    );
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                common: {
                    ...init.controller.common,
                    createSearch(config) {
                        const controller = init.controller.common.createSearch(config);
                        return enhanceController(config, controller);
                    },
                },
            },
        });
        return instance;
    };
}
