import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { IntegrationTheme } from '../../../domain';

export const useIntegrationTheme = (): IntegrationTheme => {
    const { colorMode } = useColorMode();

    const cardBgColor = useColorModeValue('button.white', 'card.dark.frame');
    const cardHighlightBgColor = useColorModeValue('card.frameDarker', 'card.dark.frameLighter');
    const cardFrame = useColorModeValue('card.frame', 'card.dark.frame');
    const titleColor = useColorModeValue('onboarding.title', 'onboarding.dark.title');
    const subTitleColor = useColorModeValue('onboarding.subtitle', 'onboarding.dark.subtitle');
    const connectButtonBG = useColorModeValue('transparent', 'rgb(135, 146, 162, 0.25)');

    return {
        colorMode,
        cardBgColor,
        cardHighlightBgColor,
        subTitleColor,
        cardFrame,
        titleColor,
        connectButtonBG,
        card: { _hover: { borderColor: colorMode === 'light' ? 'card.hoverFrame' : '#46454A' } },
    };
};
