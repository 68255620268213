import { isDateType } from '../../attributes';
import {
    DateFilter,
    AnyFilter,
    DatePreviousFilter,
    DateBetweenFilter,
    AnyPropertyFilter,
    AssetFilter,
} from './filterModel';

export function isDatePreviousFilter(filter: AnyFilter): filter is DatePreviousFilter {
    return (
        isPropertyFilter(filter) &&
        isDateType(filter.property.type) &&
        filter.default?.operator === 'previous'
    );
}

export function isDateBetweenFilter(filter: AnyFilter): filter is DateBetweenFilter {
    return (
        isPropertyFilter(filter) &&
        isDateType(filter.property.type) &&
        filter.default?.operator === 'between'
    );
}

export function isDateFilter(filter: AnyFilter): filter is DateFilter {
    return isDatePreviousFilter(filter) || isDateBetweenFilter(filter);
}

export function isPropertyFilter(filter: AnyFilter): filter is AnyPropertyFilter {
    return filter.kind === 'property';
}

export function isAssetFilter(filter: AnyFilter): filter is AssetFilter {
    return filter.kind === 'asset';
}
