import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    GridProps,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    StackDivider,
    Switch,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';
import { Icons } from '../../../../../config';
import { SubmissionItemViewProps } from '../../../../view/studies';
import { createSubmissionStatusView } from '../common';
import { StudiesSubmissionListRouteConfig } from './studiesSubmissionConfig';
import { StudiesSubmissionListViewProps } from './studiesSubmissionProps';

export function createStudiesSubmissionListView(
    config: Pick<StudiesSubmissionListRouteConfig, 'Layout' | 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & StudiesSubmissionListViewProps> {
    const {
        UI: { Link },
    } = config;

    const StatusTag = createSubmissionStatusView(config);

    const SubmissionItem: React.FC<
        { children?: React.ReactNode | undefined } & SubmissionItemViewProps
    > = (props) => {
        return (
            <Tr w="full">
                <Td py={6} borderColor="whiteAlpha.300" borderBottomWidth={2}>
                    <HStack h="full" w="full" spacing={4}>
                        <Box p={2.5} borderRadius="sm" bg="whiteAlpha.300">
                            <Icon
                                w={5}
                                h={5}
                                as={Icons.Reports.Submission}
                                color="whiteAlpha.700"
                            />
                        </Box>
                        <Link {...props.links.detail}>
                            <Text _hover={{ textDecoration: 'underline' }}>
                                {props.study.label}
                            </Text>
                        </Link>
                    </HStack>
                </Td>
                {/* <Td py={6} borderColor="whiteAlpha.300" borderBottomWidth={2}>
                    <HStack h="full" w="full">
                        <Icon as={Icons.Accounts.User}></Icon>
                        <Text cursor="default">{props.user.label}</Text>
                    </HStack>
                </Td> */}
                <Td py={6} borderColor="whiteAlpha.300" borderBottomWidth={2}>
                    <HStack h="full" w="full" color="whiteAlpha.700">
                        <Text whiteSpace="nowrap">{props.date.relative.label}</Text>
                    </HStack>
                </Td>
                <Td py={6} borderColor="whiteAlpha.300" borderBottomWidth={2}>
                    <HStack h="full" w="full" justify="end">
                        {props.links.response && (
                            <Link {...props.links.response}>
                                <Button variant="outline" size="sm" colorScheme="blue">
                                    View response
                                </Button>
                            </Link>
                        )}
                        {!props.links.response && (
                            <Box flexShrink={0}>
                                <Link {...props.links.detail}>
                                    <StatusTag
                                        {...props.status}
                                        size="lg"
                                        cursor="pointer"
                                    />
                                </Link>
                            </Box>
                        )}
                    </HStack>
                </Td>
            </Tr>
        );
    };

    return (props) => {
        if (props.list.items.length === 0) {
            return (
                <Center w="full" h="full" fontWeight="medium" pt="25vh">
                    <VStack align="start" justify="center" maxW="24rem" spacing={3}>
                        <Box bg="whiteAlpha.300" p={2.5} borderRadius="sm">
                            <Icon w={6} h={6} as={Icons.Reports.Study}></Icon>
                        </Box>
                        <VStack align="start" spacing={1}>
                            <Heading size="md" fontWeight="semibold">
                                No submissions found
                            </Heading>
                            <Text color="whiteAlpha.700">
                                Submit study submissions to access reports
                            </Text>
                            <HStack>
                                <Link to="../../explore">
                                    <Button
                                        variant="link"
                                        color="blue.300"
                                        _hover={{ textDecor: 'underline' }}
                                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                                    >
                                        Explore studies
                                    </Button>
                                </Link>
                            </HStack>
                        </VStack>
                    </VStack>
                </Center>
            );
        }
        return (
            <Grid
                w="full"
                templateAreas={`
                        "header header"
                        "content content"
                    `}
                columnGap={24}
                rowGap={4}
            >
                <GridItem gridArea="header">
                    <VStack
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        <HStack
                            w="full"
                            justify="space-between"
                            spacing={12}
                            align="start"
                            pb={4}
                            borderBottomWidth={2}
                            borderColor="whiteAlpha.300"
                            borderStyle="solid"
                        >
                            <VStack w="full" align="start" spacing={1}>
                                <HStack
                                    fontWeight="semibold"
                                    spacing={2}
                                    divider={
                                        <Icon
                                            boxSize="1.20rem"
                                            color="whiteAlpha.500"
                                            as={ChevronRightIcon}
                                            border="none"
                                        />
                                    }
                                >
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Reports
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack w="full" justify="space-between">
                                    <Heading fontSize="2xl">Surveys</Heading>
                                </HStack>
                            </VStack>
                            <HStack>
                                <Link to="../../">
                                    <Button
                                        leftIcon={<Icon as={AiOutlinePlus} />}
                                        size="sm"
                                        colorScheme="blue"
                                    >
                                        New survey
                                    </Button>
                                </Link>
                            </HStack>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem gridArea="content">
                    <VStack w="full" align="start" fontWeight="medium" spacing={4}>
                        <HStack w="full" justify="end">
                            <Text
                                color="whiteAlpha.600"
                                fontWeight="semibold"
                                cursor="default"
                            >
                                {props.list.collection.label}
                            </Text>
                        </HStack>
                        <VStack
                            w="full"
                            align="start"
                            spacing={0}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            <Table w="full" fontWeight="medium">
                                <Thead bg="whiteAlpha.300">
                                    <Tr
                                        fontWeight="bold"
                                        fontSize="xs"
                                        textTransform="uppercase"
                                        letterSpacing="wider"
                                    >
                                        <Th
                                            py={3}
                                            color="whiteAlpha.600"
                                            borderColor="whiteAlpha.300"
                                            border="none"
                                        >
                                            Study
                                        </Th>
                                        {/* <Th
                                            py={3}
                                            color="whiteAlpha.600"
                                            borderColor="whiteAlpha.300"
                                            border="none"
                                        >
                                            User
                                        </Th> */}
                                        <Th
                                            py={3}
                                            color="whiteAlpha.600"
                                            borderColor="whiteAlpha.300"
                                            border="none"
                                        >
                                            Submitted
                                        </Th>
                                        <Th
                                            py={3}
                                            color="whiteAlpha.600"
                                            borderColor="whiteAlpha.300"
                                            border="none"
                                        ></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {props.list.items.map((item, index) => (
                                        <SubmissionItem
                                            key={item.submission.id}
                                            {...props.list.getItemProps(item)}
                                        />
                                    ))}
                                </Tbody>
                            </Table>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
