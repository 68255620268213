import { useMemo } from 'react';
import {
    getCalculationStatus,
    getMetricBuckets,
    getMovementColorScheme,
    getScalarColorScheme,
} from '../../../domain/metrics';
import { isAbsolutePeriod } from '../../../../domain';
import { assert } from '../../../util/assert';
import { VisualizationScalarItemControllerConfig } from './scalarConfig';
import { VisualizationScalarItemController } from './scalarInterface';
import { VisualizationScalarItemViewProps } from './scalarProps';

export function createVisualizationScalarItemController(
    config: VisualizationScalarItemControllerConfig
): VisualizationScalarItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(props): VisualizationScalarItemViewProps {
            const itemProps = useMemo((): VisualizationScalarItemViewProps => {
                assert(
                    isAbsolutePeriod(props.item.calculation.period),
                    'expected absolute date period'
                );

                return {
                    metric: {
                        label: props.item.metric.name,
                    },
                    plugin: {
                        label: props.item.plugin.name,
                        iconUrl: props.item.plugin.iconUrl ?? '',
                    },
                    date: {
                        label: formatter.format(
                            'daterange',
                            {
                                from: props.item.calculation.period.start,
                                to: props.item.calculation.period.end,
                            },
                            { notation: 'short' }
                        ),
                    },
                    description: {
                        label: 'asdadsdda',
                    },
                    value: {
                        value: props.item.calculation.current.value.base,
                        label: props.item.calculation.current.value.base
                            ? formatter.format(
                                  props.item.metric.type,
                                  props.item.calculation.current.value.base
                              )
                            : 'N/A',
                    },
                    control: {
                        colorScheme: getScalarColorScheme(
                            props.item.metric,
                            props.item.calculation
                        ),
                    },
                };
            }, [props.item]);
            return {
                ...itemProps,
            };
        },
    };
}
