import { AxiosInstance, AxiosResponse } from 'axios';

export interface CreatedSessionDto {
    id: string;
    url: string;
}

export interface CompletedSessionDto {
    id: string;
    amount: number;
    trial: boolean;
    plan: string;
    customer: string;
}

export type SessionDto = CreatedSessionDto | CompletedSessionDto;

export interface SessionCreatePayload {
    plan: string;
    success_path: string;
    cancel_path: string;
}

export const getSession = async (api: AxiosInstance, sessionId: string) => {
    const response = await api.get<unknown, AxiosResponse<CompletedSessionDto>>(
        `/api/v1/billing/session/${sessionId}`
    );
    return response.data;
};

export const createSession = async (
    api: AxiosInstance,
    payload: SessionCreatePayload
) => {
    const response = await api.post<unknown, AxiosResponse<CreatedSessionDto>>(
        `/api/v1/billing/session`,
        payload
    );
    return response.data;
};
