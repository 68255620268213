import { InsightModuleConfig } from './insightConfig';
import { InsightLoader } from './insightInterface';

export function createInsightLoader(config: InsightModuleConfig['deps']): InsightLoader {
    const {
        api: {
            insight: {
                insights: { list: listInsights },
            },
        },
        hook: { useQuery },
    } = config;
    return {
        useData(context, props) {
            const items = useQuery({
                queryKey: [
                    'insights',
                    context.workspace.id,
                    props.query.category,
                    props.query.confidence,
                    props.query.status,
                    props.query.occured_before?.toDateString(),
                    props.query.occured_since?.toDateString(),
                ],
                refetchOnWindowFocus: true,
                // refetchInterval: 60000,
                staleTime: 60000,
                async queryFn() {
                    const [base] = await Promise.all([
                        // listInsights(context, {
                        //     ...props.query,
                        //     asset: [context.workspace.id as number],
                        //     priority: ['medium', 'low'],
                        // }),
                        listInsights(context, {
                            ...props.query,
                            asset: [context.workspace.id as number],
                            // priority: ['high'],
                        }),
                    ]);
                    return [...base.data];
                    // return [...priority.data, ...base.data];
                },
                retry: false,
                // suspense: true,
            });
            return { items };
        },
    };
}
