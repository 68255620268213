import { DatasetDto } from '../../../api';
import { DatasetImportMode } from '../../../domain/datasets';

export function getDatasetImportMode(item: DatasetDto): DatasetImportMode {
    const totalColumns = item.data.columns.length;
    const stringColumnsCount = item.data.columns.filter(
        (column) => column.type === 'string'
    ).length;

    if (stringColumnsCount > totalColumns / 2) {
        // if majority of questions are string-based we confuct it as an interview
        return 'interview';
    }
    return 'multiple_choice';
}
