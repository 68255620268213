import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { CTA_ONBOARDING_BY_PLAN } from '../../../../config';
import { excludeFreePlan, PlanListView, usePlanList } from '../../../../domain';
import { useAuthenticatedContext } from '../../../../container';
import { useLocation } from 'react-router';

export const ChoosePlanView: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = (props) => {
    const context = useAuthenticatedContext();
    const location = useLocation();
    const viewProps = usePlanList(context, {
        absoluteSuccessPath: `/on-boarding/receipt`,
        absoluteCancelPath: location.pathname,
        returnPath: location.pathname,
        excludeDefault: excludeFreePlan(context),
    });
    return (
        <Flex
            h="full"
            w="full"
            overflowY="scroll"
            pt={{ sm: 8, md: 12, xl: 16, '2xl': 20 }}
        >
            <Box mx="auto" minW="75vw">
                <PlanListView
                    {...viewProps}
                    // title="Start your free trial"
                    // caption="Join 4,432 companies benchmarking on Varos"
                    title="Select your plan"
                    // subtitle="Try Varos free for 14 days"
                    getCta={(plan) => CTA_ONBOARDING_BY_PLAN[plan.id]?.text}
                    getCategoryCta={() => 'Get Started'}
                />
            </Box>
        </Flex>
    );
};
