import { first, last } from 'lodash';
import { assert } from '../../../util/assert';
import { isBetweenCondition } from '../condition';
import { INTERVALS_BY_UPPER_BOUND } from './constraintConstant';
import { isNumericBetweenConstraint } from './constraintGuard';
import {
    BetweenConstraintExpression,
    AnyConstraintExpression,
    Constraint,
} from './constraintModel';

export function getIntervalsByBetweenConstraint(
    expr: BetweenConstraintExpression | null
) {
    if (!expr) {
        return INTERVALS_BY_UPPER_BOUND.default;
    }
    if (expr.value.to && expr.value.to <= 10_000) {
        return INTERVALS_BY_UPPER_BOUND[10_000];
    }
    if (expr.value.to && expr.value.to <= 10_000_000) {
        return INTERVALS_BY_UPPER_BOUND[10_000_000];
    }
    return INTERVALS_BY_UPPER_BOUND.default;
}

export function getIntervalsByConstraint(constraint: Constraint | null) {
    if (!constraint?.expression) {
        return null;
    }
    if (isNumericBetweenConstraint(constraint)) {
        return getIntervalsByBetweenConstraint(constraint.expression);
    }
    return null;
}

export function getIntervalByConstraints(constraints: Constraint[]) {
    return constraints.map(getIntervalsByConstraint)?.[0] ?? null;
}

export function getBetweenConstraintBounds(
    constraints: Constraint[]
): [number, number] | null {
    const interval = getIntervalByConstraints(constraints);
    if (!interval) {
        return null;
    }
    return [first(interval)!, last(interval)!];
}
