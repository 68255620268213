import { EnumMember } from '../../../../domain/attributes';
import { MemberItemViewProps } from './memberItemProps';

export function buildMemberItemViewProps(item: EnumMember): MemberItemViewProps {
    return {
        label: item.label,
        description: item.description ?? null,
        value: {
            label: item.value,
        },
    };
}
