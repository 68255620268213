import { DomainLayoutController } from '../../../layout';
import { StudiesLayoutController } from './studiesLayoutInterface';

export function createStudiesLayoutController(controller: {
    layout: DomainLayoutController;
}): StudiesLayoutController {
    return {
        useProps(context, props) {
            const { layout: layoutController } = controller;
            const layout = layoutController.useProps(context, props.layout ?? {});
            return { layout };
        },
    };
}
