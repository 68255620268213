import { NetworkBaseRouteConfig } from './networkBaseConfig';
import { NetworkContextProvider } from './networkBaseInterface';

export function createNetworkContextProvider(
    config: NetworkBaseRouteConfig
): NetworkContextProvider {
    const { hook } = config;
    return {
        useContext() {
            return {
                organization: hook.useOrganization(),
                workspace: hook.useWorkspace(),
                auth: hook.useAuth(),
            };
        },
    };
}
