import { chain, groupBy } from 'lodash';
import { useMemo } from 'react';
import { groupBySafe, keyBySafe } from '../../../../../util';
import { assert } from '../../../../../util/assert';
import { IntegrationItemAggregate } from '../../../../../view';
import { SettingIntegrationConfig } from '../settingIntegrationConfig';
import { SettingIntegrationListLoader } from './integrationListInterface';

export function createSettingIntegrationListLoader(
    config: Pick<SettingIntegrationConfig, 'repository'>
): SettingIntegrationListLoader {
    const { repository } = config;
    const limit = 500;
    return {
        useLoad(context) {
            const assetQuery = repository.asset.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            const integrationQuery = repository.integration.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const definitionQuery = repository.definition.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const mappingQuery = repository.mapping.useFind(
                context,
                {
                    integrations: integrationQuery.data?.items ?? [],
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const entityQuery = repository.entity.useFind(
                context,
                {
                    // integrations: [],
                    integrations:
                        integrationQuery.data?.items.map((item) => ({
                            id: item.id,
                        })) ?? [],
                    // integrations:
                    //     integrationQuery.data?.items.map((item) => {
                    //         id: item.id;
                    //     }) ?? [],
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            assert(assetQuery.status === 'success', 'expected suspense query');
            assert(definitionQuery.status === 'success', 'expected suspense query');
            assert(mappingQuery.status === 'success', 'expected suspense query');
            assert(entityQuery.status === 'success', 'expected suspense query');

            const assetById = useMemo(
                () => keyBySafe(assetQuery.data, (item) => item.id.toString()),
                [assetQuery.data]
            );

            const mappingsByIntegrationId = useMemo(
                () =>
                    groupBySafe(mappingQuery.data.items, (item) =>
                        item.integration.id.toString()
                    ),
                [mappingQuery.data.items]
            );

            const definitionById = useMemo(
                () => keyBySafe(definitionQuery.data.items, (item) => item.id.toString()),
                [definitionQuery.data.items]
            );

            const entityByTypeAndKey = useMemo(
                () =>
                    keyBySafe(
                        entityQuery.data.items,
                        (item) => `${item.type}x${item.key}`
                    ),
                [entityQuery.data.items]
            );

            assert(integrationQuery.status === 'success', 'expected suspense query');
            return useMemo(
                () => ({
                    status: 'loaded',
                    data: {
                        total: integrationQuery.data.items.length,
                        limit,
                        items: chain(integrationQuery.data.items)
                            .flatMap((item): IntegrationItemAggregate[] => {
                                const definition =
                                    definitionById[item.definitionId] ?? null;
                                const mappings = mappingsByIntegrationId[item.id] ?? null;
                                if (!definition) {
                                    return [];
                                }

                                const mappingsWithAsset = chain(mappings)
                                    .flatMap((mapping) => {
                                        const asset = assetById[mapping.asset.id] ?? null;

                                        const entityKey = `${mapping.entity.type}x${mapping.entity.key}`;
                                        const entity =
                                            entityByTypeAndKey[entityKey] ?? null;

                                        if (!entity) {
                                            console.warn(
                                                `entity ${entityKey} not found`,
                                                entityByTypeAndKey
                                            );
                                            return [];
                                        }
                                        return [
                                            {
                                                connection: mapping,
                                                asset,
                                                entity,
                                            },
                                        ];
                                    })
                                    .orderBy(
                                        (item) => [
                                            item.entity.status === 'valid' ? 1 : -1,
                                            item.entity.name.toLowerCase(),
                                        ],
                                        ['desc', 'asc']
                                    )
                                    .value();

                                return [
                                    {
                                        integration: item,
                                        definition,
                                        mappings: mappings ?? [],
                                        mappingsWithAssets: mappingsWithAsset,
                                    },
                                ];
                            })
                            .orderBy((item) => Number(item.integration.createdAt), 'desc')
                            .value(),
                    },
                }),
                [integrationQuery.data, mappingsByIntegrationId, definitionById]
            );
        },
    };
}
