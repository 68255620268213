import { periodToRange } from '../../../../domain';
import { InsightLoader } from '../../../view';
import { HomeAlertLoader } from './homeAlertInterface';

export function createHomeAlertLoader(loader: InsightLoader): HomeAlertLoader {
    return {
        useData(context, props) {
            const period = periodToRange(null, props.period);
            return loader.useData(context, {
                query: {
                    category: ['alert'],
                    occured_since: period.start,
                    occured_before: period.end,
                    limit: 8,
                },
            });
        },
    };
}
