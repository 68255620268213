import { useEffect } from 'react';
import {
    ApiError,
    ErrorController,
    ErrorControllerConfig,
    KernelEnhancer,
} from '../../../base';
import { AnyErrorEvent } from './trackingErrorEvent';

export function createErrorTrackingStrategy(): KernelEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyErrorEvent>();
            return tracker;
        }

        function enhanceController(
            config: ErrorControllerConfig,
            instance: ErrorController
        ): ErrorController {
            return {
                ...instance,
                useProps(props, ...args) {
                    const tracker = useTracker();
                    const viewProps = instance.useProps(props, ...args);

                    useEffect(() => {
                        let status: number | null = null;
                        let requestMethod: string | null = null;
                        let requestUrl: string | null = null;
                        if (props.info.error instanceof ApiError) {
                            status = props.info.error.axiosError?.status ?? null;
                            requestMethod =
                                props.info.error.axiosError?.config?.method ?? null;
                            requestUrl = props.info.error.axiosError?.config?.url ?? null;
                        }
                        tracker.track('error_page_viewed', {
                            error_page_id: config.id,
                            error_page_title: viewProps.error.title,
                            error_page_hint: viewProps.error.hint,
                            error_page_retryable: viewProps.retryable,
                            error_page_path: props.location.pathname,
                            error_page_params: Object.fromEntries(
                                new URLSearchParams(props.location.search).entries()
                            ),
                            error_response_status_code: status,
                            error_request_method: requestMethod,
                            error_request_url: requestUrl,
                        });
                    }, []);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            provider: {
                ...init.provider,
                createErrorController(config) {
                    const instance = init.provider.createErrorController(config);
                    return enhanceController(config, instance);
                },
            },
        });
        return instance;
    };
}
