import React from 'react';
import { EmailReportRouteConfig } from './emailReportConfig';
import { EmailReportInputSchema } from './emailReportSchema';

export function createEmailReportRoute(init: EmailReportRouteConfig) {
    const {
        View,
        hook: { useQueryParams },
        provider: {
            Auth: { Provider: AuthProvider },
            Organization: { Provider: OrganizationProvider },
            Workspace: { Provider: WorkspaceProvider },
            System: { Provider: SystemProvider },
        },
    } = init;
    return () => {
        const params = useQueryParams(EmailReportInputSchema);
        return (
            <AuthProvider
                value={{
                    user: null,
                    scheme: {
                        kind: 'apiKey',
                        token: params['api-key'],
                        assume: {
                            kind: 'organization',
                            id: params.organization,
                        },
                    },
                }}
            >
                <OrganizationProvider
                    value={{
                        id: params.organization,
                    }}
                >
                    <WorkspaceProvider
                        value={{
                            id: params.workspace,
                        }}
                    >
                        <SystemProvider>
                            <View
                                definition={{ id: params.report }}
                                workspace={{ id: params.workspace }}
                                date={params.date}
                                granularity={params.granularity ?? null}
                                period={params.period}
                            />
                        </SystemProvider>
                    </WorkspaceProvider>
                </OrganizationProvider>
            </AuthProvider>
        );
    };
}
