import { ThreadDetailController } from '../../../page';
import { AssistantRouterEnhancer } from '../../../assistantInterface';
import { AnyAssistantTrackingEvent } from './assistantThreadTrackingEvent';

export function createAssistantThreadTrackingStrategy(): AssistantRouterEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = config.kernel.infra.useTracker<AnyAssistantTrackingEvent>();
            return tracker;
        }

        function enhanceDetailController(
            instance: ThreadDetailController
        ): ThreadDetailController {
            return {
                ...instance,
                useProps(context, item, props) {
                    const tracker = useTracker();
                    const viewProps = instance.useProps(context, item, {
                        ...props,
                        onSubmit(event) {
                            const result = props.onSubmit(event);
                            tracker.track('assistant_thread_message_sent', {
                                thread_id: item.thread.id,
                                thread_workflow_id: item.thread.workflow.id,
                                message_trigger: event.source,
                            });
                            return result;
                        },
                    });

                    return viewProps;
                },
            };
        }

        return create({
            ...config,
            provider: {
                ...config.provider,
                createThreadDetailController(...args) {
                    const instance = config.provider.createThreadDetailController(
                        ...args
                    );
                    return enhanceDetailController(instance);
                },
            },
        });
    };
}
