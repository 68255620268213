import { VisualizationBlockController } from './blockVisualizationInterface';

export function createVisualizationBlockController(): VisualizationBlockController {
    return {
        useProps(context, item, props) {
            return {
                label: JSON.stringify(item.block, null, 2),
            };
        },
    };
}
