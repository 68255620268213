import { ReportErrorController } from './reportErrorInterface';

export function createReportErrorController(): ReportErrorController {
    return {
        useProps(props) {
            return {
                label: props.info.error.message,
                link: {
                    back: { to: '/u' },
                },
            };
        },
    };
}
