import { useStore } from '../../stores/setupContext';
import { DialogAdapter } from '../../base';

export const useDialogAdapter = (): DialogAdapter => {
    const store = useStore();
    return {
        current: store.dialog.current,
        show(props) {
            store.dialog.create(props);
        },
        clear() {
            store.dialog.clear();
        },
    };
};
