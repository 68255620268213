import { SwitchProps } from '@chakra-ui/react';
import {
    assertSubscriptionExpanded,
    SubscriptionCreateProps,
    SubscriptionDto,
    SubscriptionUpdateProps,
} from '../../../../api/notifications';
import { NotificationModuleConfig } from '../../notificationConfig';
import { SubscriptionListController } from './subscriptionListInterface';
import { SubscriptionListViewProps } from './subscriptionListProps';

export function createSubscriptionListController(
    init: NotificationModuleConfig
): SubscriptionListController {
    const {
        deps: {
            api: {
                notification: {
                    subscriptions: {
                        list: listSubscriptions,
                        create: createSubscription,
                    },
                },
            },
            hook: { useQuery, useMutation },
        },
    } = init;
    return {
        useProps(context, props): SubscriptionListViewProps {
            const entities = useQuery({
                suspense: true,
                retry: false,
                queryKey: ['user', 'notification', 'subscription'],
                async queryFn() {
                    if (context.workspaces.length === 0) {
                        // if there's no assets this api returns error and page fails so we prevent that
                        return [];
                    }
                    const response = await listSubscriptions(context, {
                        asset_ids: context.workspaces.map(
                            (workspace) => workspace.id as number
                        ),
                    });
                    return response.data;
                },
            });

            const mutationCreate = useMutation<void, null, SubscriptionCreateProps>({
                async mutationFn(payload) {
                    const response = await createSubscription(context, payload);
                    return;
                },
                onSuccess() {
                    entities.refetch();
                },
            });

            const createHandleToggle = (
                entity: SubscriptionDto
            ): SwitchProps['onChange'] => {
                return async (event) => {
                    assertSubscriptionExpanded(entity, ['asset', 'definition']);
                    await mutationCreate.mutateAsync({
                        asset: entity.asset.id,
                        definition: entity.definition.id,
                        status: event.target.checked ? 'active' : 'disabled',
                    });
                    return;
                };
            };

            return {
                entities: entities.data ?? [],
                getChildProps(entity, index) {
                    return {
                        toggleProps: {
                            defaultChecked: entity.status === 'active',
                            onChange: createHandleToggle(entity),
                        },
                    };
                },
            };
        },
    };
}
