import React from 'react';
import { UseWorkspaceEditFormInput } from '../../../../../view/platform/workspace';
import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { AnyWorkspaceManagmentEvent } from './workspaceManagementTrackingEvent';

export function createSettingsWorkspaceManagementTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        const useTracker = init.infra.createUseTracker();

        const instance = create({
            ...init,
            module: {
                ...init.module,
                createWorkspaceManagementModule(moduleConfig) {
                    return init.module.createWorkspaceManagementModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            edit: {
                                ...moduleConfig.provider.edit,
                                createController(controllerConf) {
                                    const controller =
                                        moduleConfig.provider.edit.createController(
                                            controllerConf
                                        );
                                    return {
                                        useProps(ctx, input) {
                                            const tracker =
                                                useTracker<AnyWorkspaceManagmentEvent>();
                                            const collaborators =
                                                controllerConf.service.collaborator.useList(
                                                    ctx,
                                                    {
                                                        workspaces: [input.workspace.id],
                                                    }
                                                );

                                            const onSubmitOverride = React.useCallback<
                                                UseWorkspaceEditFormInput['onSubmit']
                                            >(
                                                async (dto) => {
                                                    const original = new Set<string>(
                                                        collaborators.map((x) =>
                                                            x.id.toString()
                                                        )
                                                    );
                                                    const newUserIds =
                                                        dto.access.specific_users.filter(
                                                            (x) =>
                                                                !original.has(
                                                                    x.toString()
                                                                )
                                                        );
                                                    const specificUsersSet =
                                                        new Set<string>(
                                                            dto.access.specific_users.map(
                                                                (x) => x.toString()
                                                            )
                                                        );
                                                    const removedUserIds = collaborators
                                                        .filter(
                                                            (x) =>
                                                                !specificUsersSet.has(
                                                                    x.id.toString()
                                                                )
                                                        )
                                                        .map((x) => x.id);
                                                    try {
                                                        const retVal =
                                                            await input.onSubmit(dto);
                                                        newUserIds.forEach(
                                                            (newUserId) => {
                                                                tracker.track(
                                                                    'asset_permissions_added',
                                                                    {
                                                                        permissions_subject_id:
                                                                            dto.id,
                                                                        permissions_outcome:
                                                                            'success',
                                                                        permissions_user_id:
                                                                            newUserId,
                                                                    }
                                                                );
                                                            }
                                                        );
                                                        removedUserIds.forEach(
                                                            (removeUserId) => {
                                                                tracker.track(
                                                                    'asset_permissions_removed',
                                                                    {
                                                                        permissions_subject_id:
                                                                            dto.id,
                                                                        permissions_outcome:
                                                                            'success',
                                                                        permissions_user_id:
                                                                            removeUserId,
                                                                    }
                                                                );
                                                            }
                                                        );
                                                        return retVal;
                                                    } catch (e) {
                                                        newUserIds.forEach(
                                                            (newUserId) => {
                                                                tracker.track(
                                                                    'asset_permissions_added',
                                                                    {
                                                                        permissions_subject_id:
                                                                            dto.id,
                                                                        permissions_outcome:
                                                                            'error',
                                                                        permissions_user_id:
                                                                            newUserId,
                                                                    }
                                                                );
                                                            }
                                                        );
                                                        removedUserIds.forEach(
                                                            (removeUserId) => {
                                                                tracker.track(
                                                                    'asset_permissions_removed',
                                                                    {
                                                                        permissions_subject_id:
                                                                            dto.id,
                                                                        permissions_outcome:
                                                                            'error',
                                                                        permissions_user_id:
                                                                            removeUserId,
                                                                    }
                                                                );
                                                            }
                                                        );
                                                        throw e;
                                                    }
                                                },
                                                [input.onSubmit]
                                            );
                                            const props = controller.useProps(ctx, {
                                                ...input,
                                                onSubmit: onSubmitOverride,
                                            });
                                            return props;
                                        },
                                    };
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
