import { AxiosResponse } from 'axios';
import {
    RegistrationRequest,
    User,
    MeUser,
    UserUpdateRequest,
    LoginResponse,
} from '../models/Account';
import { apiV1 } from './axios';

export const fetchMyAccount = async () => {
    return apiV1.get<unknown, AxiosResponse<MeUser>>('/users/me');
};

export const searchUser = async (q: string) => {
    return apiV1.get<unknown, AxiosResponse<Array<User>>>(`/users/search?q=${q}`);
};

export const registerUserWithOrg = async (user: RegistrationRequest) => {
    return apiV1.post<User>('/users/register', user);
};

export const updateUserWithOrg = async (user: UserUpdateRequest) => {
    return apiV1.patch<unknown, AxiosResponse<User>>('/users/me', user);
};

export const updateFinishedOnBoarding = async (orgId: number) => {
    return apiV1.patch<unknown, AxiosResponse<User>>('/users/me', {
        organization: {
            organization: { id: orgId, finished_on_boarding: true },
        },
    });
};

export const loginToOrg = async (orgId: number | string) => {
    return apiV1.post<unknown, AxiosResponse<LoginResponse>>(`/login/organization`, {
        org_id: orgId,
    });
};

export interface AccountProps {
    identification_props: Record<string, any>;
    event_props: Record<string, any>;
}

export const fetchAcountProps = async () => {
    return apiV1.get<unknown, AxiosResponse<AccountProps>>(`/account/properties`);
};
