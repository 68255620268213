import { uniqBy, max, maxBy, isEqual } from 'lodash';
import {
    AnyQuerySegment,
    QueryExpressionSegment,
    QueryProjectionColumn,
    QueryRangeSegment,
} from '../../api/v2';
import { isAbsolutePeriod, QueryBuilder } from '../../domain';
import { isDate, isNumeric } from '../../domain';
import { QueryBuilderConfig } from './querybuilder.config';

export function createQueryBuilder(init: QueryBuilderConfig): QueryBuilder {
    const { partitionKeyByView, ignoreDashboards } = init;
    return {
        metric: {
            build(context, config) {
                const partitionKey = partitionKeyByView[config.view.source] ?? 'TS';
                const { ranking, competitiveSet } = config.configuration;
                // console.log('config.configuration', config.configuration);

                const expressionSegments: QueryExpressionSegment[] =
                    ranking.kind === 'dynamic'
                        ? [
                              {
                                  kind: 'expression',
                                  name: 'Low performers',
                                  expr: { kind: 'boolean', value: true },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 25,
                                  },
                              },
                              {
                                  kind: 'expression',
                                  name: 'Median',
                                  expr: { kind: 'boolean', value: true },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 50,
                                  },
                              },
                              {
                                  kind: 'expression',
                                  name: 'High performers',
                                  expr: { kind: 'boolean', value: true },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 75,
                                  },
                              },
                          ]
                        : [];

                const rangeSegments: QueryRangeSegment[] =
                    ranking.kind === 'fixed'
                        ? [
                              {
                                  kind: 'range',
                                  name: 'Low performers',
                                  range: {
                                      column: ranking.comparison,
                                      start: {
                                          kind: 'percentile',
                                          value: 0,
                                      },
                                      end: {
                                          kind: 'percentile',
                                          value: 33,
                                      },
                                  },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 50,
                                  },
                              },
                              {
                                  kind: 'range',
                                  name: 'Median',
                                  range: {
                                      column: ranking.comparison,
                                      start: {
                                          kind: 'percentile',
                                          value: 33,
                                      },
                                      end: {
                                          kind: 'percentile',
                                          value: 66,
                                      },
                                  },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 50,
                                  },
                              },
                              {
                                  kind: 'range',
                                  name: 'High performers',
                                  range: {
                                      column: ranking.comparison,
                                      start: {
                                          kind: 'percentile',
                                          value: 66,
                                      },
                                      end: {
                                          kind: 'percentile',
                                          value: 100,
                                      },
                                  },
                                  reducer: {
                                      kind: 'percentile',
                                      value: 50,
                                  },
                              },
                          ]
                        : [];

                return {
                    flags: {
                        compare: true,
                    },
                    projections: [
                        {
                            name: 'default',
                            columns: config.metrics.map((metric) => ({
                                key: metric.definition.key,
                                expr: `compare(${metric.definition.key})`,
                            })),
                        },
                    ],
                    comparison: config.date.comparison as any,
                    granularity: config.date.granularity,
                    filters: [
                        ...config.configuration.filters,
                        isAbsolutePeriod(config.date.period)
                            ? {
                                  key: partitionKey,
                                  operator: 'between',
                                  value: {
                                      from: config.date.period.start,
                                      to: config.date.period.end,
                                  },
                              }
                            : {
                                  key: partitionKey,
                                  operator: 'previous',
                                  value: config.date.period,
                              },
                    ],
                    source: { view: config.view.source },
                    segment: competitiveSet.conditions,
                    segments: [
                        {
                            kind: 'asset',
                            name: 'My company',
                        },
                        ...(ranking.kind === 'fixed'
                            ? rangeSegments
                            : expressionSegments),
                        // ...(ranking.kind === 'fixed'
                        //     ? ranking.cohorts.map(
                        //           (item): QueryRangeSegment => ({
                        //               kind: 'range',
                        //               name: item.name,
                        //               range: {
                        //                   column: ranking.comparison,
                        //                   start: {
                        //                       kind: 'percentile',
                        //                       value: item.lower,
                        //                   },
                        //                   end: {
                        //                       kind: 'percentile',
                        //                       value: item.upper,
                        //                   },
                        //               },
                        //               reducer: {
                        //                   kind: 'percentile',
                        //                   value: 50,
                        //               },
                        //           })
                        //       )
                        //     : ranking.cohorts.map(
                        //           (item): QueryExpressionSegment => ({
                        //               kind: 'expression',
                        //               name: item.name,
                        //               expr: { kind: 'boolean', value: true },
                        //               reducer: {
                        //                   kind: 'percentile',
                        //                   value: item.value,
                        //               },
                        //           })
                        //       )),
                    ],
                    // segments: [
                    //     {
                    //         kind: 'asset',
                    //         name: 'My company',
                    //     },
                    //     // {
                    //     //     kind: 'expression',
                    //     //     name: 'Bottom performer',
                    //     //     expr: { kind: 'boolean', value: true },
                    //     //     reducer: {
                    //     //         kind: 'percentile',
                    //     //         value: 10,
                    //     //     },
                    //     // },
                    //     {
                    //         kind: 'expression',
                    //         name: 'Low performer',
                    //         expr: { kind: 'boolean', value: true },
                    //         reducer: {
                    //             kind: 'percentile',
                    //             value: 25,
                    //         },
                    //     },
                    //     {
                    //         kind: 'expression',
                    //         name: 'Median',
                    //         expr: { kind: 'boolean', value: true },
                    //         reducer: {
                    //             kind: 'percentile',
                    //             value: 50,
                    //         },
                    //     },
                    //     {
                    //         kind: 'expression',
                    //         name: 'High performer',
                    //         expr: { kind: 'boolean', value: true },
                    //         reducer: {
                    //             kind: 'percentile',
                    //             value: 75,
                    //         },
                    //     },
                    //     // {
                    //     //     kind: 'expression',
                    //     //     name: 'Top performer',
                    //     //     expr: { kind: 'boolean', value: true },
                    //     //     reducer: {
                    //     //         kind: 'percentile',
                    //     //         value: 90,
                    //     //     },
                    //     // },
                    // ],
                };
            },
        },
        dashboardExport: {
            canExport(context, cards) {
                return !ignoreDashboards.includes(context.dashboard.slug);
            },
            build(context, cards) {
                // for now we only consider single-query cards for export and no combined cards like audit tables
                const singleQueryCards = cards.filter(
                    (card) => card.queries.length === 1
                );

                // this is a hack to handle dashboards that currently rely on different underlying views.
                // multi-view queries are currently only supported client-side, but the export happens
                // on the backend. so for now the logic is to pick the view that most of the
                // cards in the dashboard are based on. In the future we should either A) remove multi-view dashboards or two, add multi-query support
                // to the BE as well.
                const viewWithCounts = Object.entries(
                    singleQueryCards.reduce(
                        (acc, card) => ({
                            ...acc,
                            [card.queries[0].source.view]:
                                // @ts-expect-error
                                (acc?.[card.queries[0].source.view.toString()] ?? 0) + 1,
                        }),
                        {}
                    )
                ).map(([view, count]) => ({ view, count }));

                const viewWithMostCards = maxBy(viewWithCounts, ({ count }) => count);
                const appliedViewForExport = viewWithMostCards?.view;

                if (!appliedViewForExport) {
                    throw new Error(`unable to determine view for export`);
                }

                const [firstCard] = singleQueryCards;

                // currently types are specified in the projection so we need to join each aggregation
                // with its corresponding column expr to get the type.
                // in the future the aggregations should be based on pre-defined metrics with an explicit type
                const aggregationsAndTypes = uniqBy(
                    singleQueryCards
                        .filter(
                            (card) =>
                                card.queries[0]?.source.view === appliedViewForExport
                        )
                        .flatMap(
                            (card) =>
                                card.queries[0].aggregations?.map((aggregation) => ({
                                    aggregation,
                                    column: card.queries[0].projections.map(
                                        (projection) =>
                                            projection.columns.find(
                                                (column) =>
                                                    column.key.toLowerCase() ===
                                                    aggregation.name.toLowerCase()
                                            )
                                    )[0],
                                })) ?? []
                        ),
                    ({ aggregation }) => aggregation.name
                );

                const aggregationColumns: QueryProjectionColumn[] =
                    aggregationsAndTypes.flatMap(({ aggregation, column }) =>
                        column ? [column] : []
                    );

                const segments: AnyQuerySegment[] = [
                    {
                        kind: 'asset',
                        name: 'My company',
                    },
                ];

                let segmentName = 'Median';

                if (context.cohort?.mode === 'fixed') {
                    segmentName = 'Peer group';
                    segments.push({
                        kind: 'range',
                        name: segmentName,
                        range: {
                            column: context.cohort.config.fixed.comparison,
                            start: {
                                kind: 'percentile',
                                value: 33,
                            },
                            end: {
                                kind: 'percentile',
                                value: 66,
                            },
                        },
                        reducer: {
                            kind: 'percentile',
                            value: 50,
                        },
                    });
                } else {
                    segments.push({
                        kind: 'expression',
                        name: segmentName,
                        expr: {
                            kind: 'boolean',
                            value: true,
                        },
                        reducer: {
                            kind: 'percentile',
                            value: 50,
                        },
                    });
                }

                return {
                    ok: true,
                    query: {
                        source: {
                            view: appliedViewForExport,
                        },
                        filters: context.getFilters(firstCard),
                        facet: null,
                        segments,
                        timeseries: {},
                        granularity: context.granularity ?? undefined,
                        pivot: {
                            column: 'segment',
                            values: ['My company', segmentName],
                        },
                        // Ideally this logic should not be based on cards
                        // but instead on the view definition and its exposed columns for a particular
                        // dashboard. Currently this is not possible because we the column model does not
                        // include the "formula" for computed metrics, as this instead is specified as part of card query
                        aggregations: aggregationsAndTypes.map(
                            ({ aggregation }) => aggregation
                        ),
                        projections: [
                            {
                                name: 'main',
                                columns: [
                                    { key: 'segment' },
                                    ...uniqBy(
                                        [...aggregationColumns],
                                        (column) => column.key
                                    ),
                                ],
                            },
                        ],
                    },
                };
            },
        },
    };
}
