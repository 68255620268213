import React from 'react';
import { QueryBuilder } from '../../../../domain';
import { ExportContext, ExportContextValue } from './ExportContext';

export interface ExportProviderConfig {
    querybuilder: QueryBuilder;
}

export function createExportProvider(
    config: ExportProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const context: ExportContextValue = {
            querybuilder: config.querybuilder,
        };
        return (
            <ExportContext.Provider value={context}>
                {props.children}
            </ExportContext.Provider>
        );
    };
}
