import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { CardListViewProps, CardItemViewProps } from '../../../view/dashboard';
import { CardListController, CardItemController } from './cardInterface';
import { Box } from '@chakra-ui/react';
import { useIntersection } from 'react-use';

export interface CardListContainerProps extends Pick<CardListViewProps, 'renderItem'> {
    as: React.FC<{ children?: React.ReactNode | undefined } & CardListViewProps>;
    useController: CardListController;
}

export const CardListContainer: React.FC<
    { children?: React.ReactNode | undefined } & CardListContainerProps
> = observer(({ as: Component, useController, ...restProps }) => {
    const viewProps = useController();
    return <Component {...viewProps} {...restProps} renderItem={restProps.renderItem} />;
});

export interface CardItemContainerProps
    extends Pick<CardItemViewProps, 'renderChart' | 'card'> {
    as: React.FC<{ children?: React.ReactNode | undefined } & CardItemViewProps>;
    useController: CardItemController;
}

export const CardItemContainer: React.FC<
    { children?: React.ReactNode | undefined } & CardItemContainerProps
> = observer(({ as: Component, useController, card, ...restProps }) => {
    const viewProps = useController(card);
    const containerRef = useRef(null);
    const intersection = useIntersection(containerRef, {});
    return (
        <Box ref={containerRef}>
            <Component
                {...viewProps}
                renderChart={(...args) => {
                    if (!intersection?.isIntersecting) {
                        return <></>;
                    }
                    return restProps.renderChart(...args);
                }}
            />
        </Box>
    );
});
