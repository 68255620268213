import React from 'react';
import { useLocation } from 'react-router';
import { UrlOptionParser } from '../../../../base';
import { StudiesContextProvider } from '../../base';
import {
    StudiesDetailController,
    StudiesDetailLoader,
} from './studiesReportDetailInterface';
import { StudiesDetailViewProps } from './studiesReportDetailProps';
import { StudiesDetailPathParamsSchema } from './studiesReportDetailSchema';

export function createStudiesDetailRoute(
    options: UrlOptionParser,
    context: StudiesContextProvider,
    controller: StudiesDetailController,
    loader: StudiesDetailLoader,
    View: React.FC<{ children?: React.ReactNode | undefined } & StudiesDetailViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const location = useLocation();
        const viewParams = options.useOptions(StudiesDetailPathParamsSchema);
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext, {
            report: { id: viewParams.reportId },
        });
        const viewProps = controller.useProps(viewContext, viewData, { location });
        return <View {...viewProps} />;
    };
}
