import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const WithReactQuery: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {React.Children.only(children)}
        </QueryClientProvider>
    );
};
