import { assertNever } from '../../../util';
import { VisualizationEntity } from '../../../app/visualization-v2';
import { VisualizationControllerConfig } from '../base';
import { VisualizationChartController } from './visualizationChartInterface';
import { useMemo } from 'react';
import { VisualizationBarChartViewProps } from '../bar';

export function createVisualizationChartController(
    config: VisualizationControllerConfig
): VisualizationChartController {
    function buildChartProps(
        visualization: VisualizationEntity
    ): VisualizationBarChartViewProps {
        return {
            kind: 'bar',
            chart: {
                series: visualization.series.map((series) => ({
                    name: series.label,
                    data: series.values.map((value) => ({ value })),
                    colorScheme: 'blue',
                })),
                labels: visualization.labels.map((item) => {
                    if (item.kind === 'category') {
                        return item.display_name ?? item.key;
                    }
                    if (item.kind === 'time') {
                        return `asdasd - asdasds`;
                    }
                    assertNever(item);
                }),
                formatCategory(value) {
                    return value;
                },
                formatScale(value) {
                    return value.toString();
                },
                formatTooltip(value) {
                    return value.toString();
                },
                formatValue(value) {
                    return value.toString();
                },
            },
        };
    }
    return {
        useProps(context, item, props) {
            const chartProps = useMemo(() => {
                if (props.kind === 'bar') {
                    return buildChartProps(item.visualization);
                }
                assertNever(props.kind);
            }, [item.visualization, props.kind]);
            return chartProps;
        },
    };
}
