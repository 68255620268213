import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { OnboardingIntegrationController } from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { IntegrationDefinition } from '../../../../domain/assets';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';
import { chain } from 'lodash';

export function createTrackingOnboardingIntegrationStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingIntegrationController
        ): OnboardingIntegrationController {
            return {
                ...controller,
                useProps(...args) {
                    const [context] = args;
                    const tracker = useTracker();
                    const [searchParams] = useSearchParams();
                    const viewProps = controller.useProps(...args);

                    useEffect(() => {
                        const integrated = searchParams.get('integrated');
                        if (integrated) {
                            tracker.track('onboarding_integration_connected', {});
                        }
                    }, [searchParams]);

                    const integrations = instance.repository.assets.integration.useFind(
                        context,
                        {}
                    );

                    const definitions = instance.repository.assets.definition.useFind(
                        context,
                        {}
                    );

                    const definitionById = useMemo(() => {
                        return (
                            definitions.data?.items.reduce(
                                (acc, item) => ({ ...acc, [item.id]: item }),
                                {} as Record<string, IntegrationDefinition | undefined>
                            ) ?? {}
                        );
                    }, [definitions.data]);

                    return {
                        ...viewProps,
                        getFormProps() {
                            const base = viewProps.getFormProps();
                            return {
                                ...base,
                                async onSubmit(...args) {
                                    const submitted = base.onSubmit(...args);

                                    tracker.track('onboarding_integration_submitted', {
                                        integration_connected_count:
                                            integrations.data?.items.length ?? 0,
                                        integration_types: chain(
                                            integrations.data?.items ?? []
                                        )
                                            .flatMap((item) => {
                                                const definition =
                                                    definitionById[item.definitionId];
                                                if (!definition) {
                                                    console.warn(
                                                        `definition '${item.definitionId}' not found`
                                                    );
                                                    return [];
                                                }
                                                return definition.kind;
                                            })
                                            .uniq()
                                            .value(),
                                    });
                                    return submitted;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createIntegration(...args) {
                        const controller = config.controller.onboarding.createIntegration(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
