import { useMemo } from 'react';
import { keyBySafe } from '../../../util';
import { AnyPeerHealthcheckQuery } from '../../../app';
import { assert } from '../../../util/assert';
import { DashboardGroupAggregate, GroupItemAggregate } from '../../../view';
import { DashboardGroupConfig } from './dashboardGroupConfig';
import { DashboardGroupLoader } from './dashboardGroupInterface';

export function createDashboardGroupLoader(
    config: Pick<DashboardGroupConfig, 'repository'>
): DashboardGroupLoader {
    const {
        repository: { peerset: peersetRepository, healthcheck: healthcheckRepository },
    } = config;
    return {
        useLoad(context, props): DashboardGroupAggregate {
            const peersetQuery = peersetRepository.useFind(
                context,
                {},
                { suspense: true, staleTime: Infinity }
            );
            assert(peersetQuery.status === 'success', 'expected suspense');
            const healthcheckQuery = healthcheckRepository.useMultiLookup(
                context,
                peersetQuery.data.items.map(
                    (item): AnyPeerHealthcheckQuery => ({
                        group: { id: item.id },
                        plugin: props.plugin,
                    })
                ),
                {
                    suspense: true,
                    select(data) {
                        return data.map((item, index) => ({
                            ...item,
                            group: peersetQuery.data.items[index],
                        }));
                    },
                }
            );
            assert(healthcheckQuery.status === 'success', 'expected suspense');

            const healthcheckByGroup = useMemo(
                () => keyBySafe(healthcheckQuery.data, (item) => item.group.id),
                [healthcheckQuery.data]
            );

            const aggregate: DashboardGroupAggregate = useMemo(() => {
                return {
                    group: {
                        items: peersetQuery.data.items.map(
                            (peerset): GroupItemAggregate => {
                                const healthcheck =
                                    healthcheckByGroup[peerset.id] ?? null;
                                return {
                                    peerset,
                                    user: null,
                                    count: {
                                        invitation: null,
                                    },
                                    plugin: healthcheck
                                        ? {
                                              healthcheck,
                                          }
                                        : null,
                                };
                            }
                        ),
                    },
                };
            }, [peersetQuery.data.items, healthcheckByGroup]);

            return aggregate;
        },
    };
}
