import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { DomainLayoutConfig } from './domainLayoutConfig';
import { DomainLayoutController } from './domainLayoutInterface';
import { createDomainNavigationController } from './navigation/domainNavigationController';

export function createDomainLayoutController(
    config: Pick<DomainLayoutConfig, 'items'>
): DomainLayoutController {
    const controller = {
        navigation: createDomainNavigationController(config),
    };
    return {
        useProps(context, props) {
            const scrollRef = useRef<HTMLDivElement>(null);
            const { showSidebar = true } = props;
            const location = useLocation();
            const navigationListProps = controller.navigation.useProps(context);
            const emptyStateProps = props.status ?? null;

            useLayoutEffect(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollTo(0, 0);
                }
            }, [location.pathname]);

            return {
                scrollRef,
                navigationListProps: showSidebar ? navigationListProps : null,
                emptyStateProps,
                errorBoundaryProps: {
                    // reset error state when navigating to a different page
                    key: location.pathname,
                },
            };
        },
    };
}
