import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useOrganizationContextV2, useSystemContextV2 } from '../../../../context';
import { isLoadedCollection } from '../../../../base';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { HomeOpportunityViewProps } from '../../../home';
import { AnyHomeOpportunityTrackingEvent } from './ooportunityTrackingEvent';

export function createHomeOpportunityTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeOpportunityTrackingEvent>();
            return tracker;
        }

        const Tracker: React.FC<
            { children?: React.ReactNode | undefined } & { onLoad(): void }
        > = (props) => {
            const [tracked, setTracked] = useState(false);
            const { ref, inView } = useInView();

            // We want to track when the component is actually rendered which
            // is not the same as when it's loaded because of suspense
            useEffect(() => {
                if (inView && !tracked) {
                    props.onLoad();
                    setTracked(true);
                }
            }, [inView, tracked]);
            return <Box as="span" ref={ref}></Box>;
        };

        function enhanceInsightView(
            Component: React.FC<
                { children?: React.ReactNode | undefined } & HomeOpportunityViewProps
            >
        ): React.FC<
            { children?: React.ReactNode | undefined } & HomeOpportunityViewProps
        > {
            return (props) => {
                const tracker = useTracker();
                const { collection } = props.getCollectionProps();

                const {
                    data: { features },
                } = useOrganizationContextV2();

                const handleLoad = () => {
                    if (!isLoadedCollection(collection)) {
                        return;
                    }
                    if (collection.data.items.length === 0) {
                        return;
                    }
                    tracker.track('home_view_opportunity_list_viewed', {
                        insight_visible_count: collection.data.items.length,
                        insight_ids: collection.data.items.map((item) => item.insight.id),
                        insight_definition_ids: collection.data.items.map(
                            (item) => item.insight.definition.id
                        ),
                        insight_kinds: collection.data.items.map(
                            (item) => item.insight.kind
                        ),
                        insight_statuses: collection.data.items.map(
                            (item) => item.insight.status
                        ),
                        insight_priority: collection.data.items.map(
                            (item) => item.insight.priority
                        ),
                        insight_feature_access:
                            features.insights?.config?.limit === null
                                ? 'full'
                                : 'restricted',
                    });
                };

                // we want to refresh the tracking logic when the insights change due to filtering
                const key = isLoadedCollection(collection)
                    ? collection.data.items.map((item) => item.insight.id).join('|')
                    : '';

                return (
                    <Component {...props}>
                        <Tracker key={key} onLoad={handleLoad} />
                    </Component>
                );
            };
        }

        const instance = create({
            ...config,
            view: {
                ...config.view,
                home: {
                    ...config.view.home,
                    createOpportunity(...args) {
                        const View = config.view.home.createOpportunity(...args);
                        return enhanceInsightView(View);
                    },
                },
            },
        });
        return instance;
    };
}
