import { ViewPropertyDto } from '../../../api';
import { AnyQuerySegment, QueryRangeSegment } from '../../../../api';
import { useDashboardApi, useQueryApi } from '../../../app/dashboard';
import { DashboardSlugs } from '../../../../config/dashboard';
import { QueryRequest } from '../../../domain/query';
import { ChartController } from './chartInterface';
import { useMemo } from 'react';

const LOCKED_SEGMENTS_DASHBOARD_IDS = [
    DashboardSlugs.MEDIA_MIX,
    DashboardSlugs.GA4_REVENUE_BREAKDOWN,
    DashboardSlugs.GA4_TRAFFIC,
];

export const useChartController: ChartController = (card, section) => {
    const api = {
        query: useQueryApi(),
        dashboard: useDashboardApi(),
    };

    const dashboard = api.dashboard.getDashboard();

    const overrides = api.query.getQuery(section.query.source);
    const cohort = api.query.getCohort();

    // console.log('DEBUG section.query.source', card.title, section.query);
    // console.log('DEBUG card overrides', card.title, overrides);

    const configuration = api.dashboard.getConfiguration();

    // const [defaultView] = configuration.viewSchemas;
    const view = configuration.views.find(
        (item) => item.source === card.query.source.view
    );

    const isFixedCohort = overrides.segments?.some((item) => item.kind === 'range');

    // console.log('config', card.title, view);

    const columnsById = useMemo(
        () =>
            view?.columns.reduce(
                (acc, item) => ({ ...acc, [item.key]: item }),
                {} as Record<string, ViewPropertyDto | undefined>
            ) ?? {},
        [view?.columns]
    );

    // TODO this is a hack to support fixed cohorts for multi dataset dashboards.
    // only enable fixed cohorts for cards using the "default" (first) view of the dashboard, otherwise
    // fallback to dynamic cohorts
    const supportFixedCohorts = overrides.segments?.every((item) =>
        item.kind === 'range' ? !!columnsById[item.range.column] : true
    );

    // console.log('colm', supportFixedCohorts, columnsById, overrides.segments);
    const shouldForceDynamicCohorts = isFixedCohort && !supportFixedCohorts;
    // console.log('shouldForceDynamicCohorts', shouldForceDynamicCohorts);

    if (shouldForceDynamicCohorts) {
        console.warn(
            `card ${card.title} does not support fixed cohorts, falling back to dynamic...`
        );
    }

    let segments = overrides.segments;

    // console.log('overrides', section.visualization.kind, overrides);

    if (section.visualization.kind === 'stat') {
        // HACK always show own company with median for stat cards
        segments = section.query.segments?.map(
            (segment) =>
                // this is a hack to accoutn account stat cards that only display a subset of selected segments
                overrides.segments?.find((item) => item.name === segment.name) ?? segment
        );
    } else if (
        section.visualization.kind === 'table' &&
        card.title.toLowerCase().includes('performance summary')
    ) {
        // HACK performance summary coloring requires segments to do coloring
        segments = section.query.segments?.map(
            (segment) =>
                // this is a hack to accoutn account stat cards that only display a subset of selected segments
                overrides.segments?.find((item) => item.name === segment.name) ?? segment
        );
    }

    // if (card.visualization.kind === 'bar' && card.query.facet?.explode_values) {
    else if (
        card.visualization.kind === 'bar' &&
        card.query.projections[0]?.columns.some((column) => column.type === 'percent')
    ) {
        // HACK distribution based visualizations must be based on average and not percentiles to add up.
        // we infer that a query is a distribution based on if one of the columns are a percentage
        segments =
            overrides.segments
                ?.map((item): AnyQuerySegment => {
                    if (item.kind === 'expression') {
                        return { ...item, reducer: { kind: 'average' } };
                    }
                    if (item.kind === 'range') {
                        return { ...item, reducer: { kind: 'average' } };
                    }
                    return item;
                })
                ?.filter((item, index) => {
                    if (item.kind === 'asset') {
                        // always show current asset
                        return true;
                    }
                    // exclue any segment definitions for which the corresponding cohort item
                    // is hidden
                    return (
                        cohort?.config.fixed.cohorts.some(
                            (candidate) =>
                                candidate.name === item.name && candidate.visible
                        ) ?? true
                    );
                }) ?? [];
    } else if (
        (card.visualization.kind === 'line' || card.visualization.kind === 'bar') &&
        cohort
    ) {
        // HACK this hides the hidden cohorts on line charts.
        // this should really be generalized but because the implementation of other
        // card types like performance summary requires that all
        // cohorts are visible, that is not trivial
        segments = overrides.segments?.filter((item, index) => {
            if (item.kind === 'asset') {
                // always show current asset
                return true;
            }
            // exclue any segment definitions for which the corresponding cohort item
            // is hidden
            return cohort.config.fixed.cohorts.some(
                (candidate) => candidate.name === item.name && candidate.visible
            );
        });
    }

    if (shouldForceDynamicCohorts) {
        segments = section.query.segments;
    }

    if (LOCKED_SEGMENTS_DASHBOARD_IDS.includes(dashboard.id)) {
        // HACK no not override segment configuration for these dashboards
        segments = section.query.segments;
    }

    let query: QueryRequest = {
        ...section.query,
        ...overrides,
        segments,
        // aggregations: undefined,
        // segments: shouldForceDynamicCohorts
        //     ? section.query.segments
        //     : section.query.segments
        //     ? overrides.segments
        //     : // section.query.segments.map(
        //       //       (segment) =>
        //       //           // this is a hack to accoutn account stat cards that only display a subset of selected segments
        //       //           overrides.segments?.find((item) => item.name === segment.name) ??
        //       //           segment
        //       //   )
        //       overrides.segments,
    };

    // console.log('query', query);
    // console.log('overrides.segment', overrides.segment);
    // console.log('section.query.segments', section.query.segments);

    // console.log('query', query);
    const response = api.dashboard.useCardQuery(card, query);

    return {
        dashboard: api.dashboard.getConfiguration(),
        card,
        query,
        cohort,
        visualization: section.visualization,
        response,
    };
};
