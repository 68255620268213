import { z } from 'zod';
import { ThreadDetailLoaderProps } from './threadDetailProps';

export const ThreadDetailUrlParamSchema = z
    .object({
        threadId: z.string(),
    })
    .transform(
        (item): ThreadDetailLoaderProps => ({
            thread: {
                id: item.threadId,
            },
        })
    );
