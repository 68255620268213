import { WorkspaceContextV2 } from '../../app/platform';
import { ReportControl } from '../../domain/report';
import { ReportContainerProps, ReportViewProps } from './reportProps';
import { ReportControllerConfig } from './reportConfig';

export interface ReportController {
    useProps(context: WorkspaceContextV2, config: ReportContainerProps): ReportViewProps;
}

export function createReportController(init: ReportControllerConfig): ReportController {
    const {
        service,
        hook: { useRouter, useStore },
    } = init;
    return {
        useProps(context, config) {
            const router = useRouter();
            const store = useStore();

            const control = store.getControl();
            const report = service.report.useReport(context, {
                date: new Date(),
                workspace: context.workspace,
                definition: { id: config.definitionId },
                period: control.date.period,
                granularity: control.date.granularity,
                comparison: control.date.comparison ?? undefined,
            });

            const onDateChange = (date: ReportControl['date']) => {
                store.setControl({ ...control, date });
            };

            return {
                report,
                control,
                onDateChange: onDateChange,
                workspace: context.workspace,
                getDashboardLinkProps(status) {
                    return {
                        to: router.link(context, 'dashboard', status.dashboard.id),
                    };
                },
                getDateInputProps() {
                    return {
                        value: control.date,
                        onChange: onDateChange,
                    };
                },
            };
        },
    };
}
