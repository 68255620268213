import { uniqBy } from 'lodash';
import { ExecFilterOperator } from 'src/models/DataSet';
import { z } from 'zod';

export const RecommendationConfidenceSchema = z.union([
    z.literal('high'),
    z.literal('medium'),
    z.literal('low'),
]);

export const RecommendationObjectKindSchema = z.union([
    z.literal('enum_value'),
    z.literal('company'),
]);

export const RecommendationSchema = z.object({
    confidence: RecommendationConfidenceSchema,
    object: z.object({
        id: z.string(),
        kind: RecommendationObjectKindSchema,
    }),
});

export const RecommendationListSchema = z
    .object({
        data: z.array(RecommendationSchema),
    })
    .transform((item) => ({
        ...item,
        data: uniqBy(item.data, (item) => [item.object.id, item.object.kind]),
    }));
