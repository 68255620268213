import React from 'react';
import { Box, Heading, HStack, Icon, StackDivider, Text, VStack } from '@chakra-ui/react';
import { isCurrencyProperty, isTreeProperty } from '../../../../domain/attributes';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { TraitFilterViewProps } from '../../../../view/peer';
import { AiFillQuestionCircle, AiOutlineInfo, AiOutlineInfoCircle } from 'react-icons/ai';

export function createOnboardingTraitFilterView(
    config: OnboardingPeerGroupContainerConfig
): React.FC<{ children?: React.ReactNode | undefined } & TraitFilterViewProps> {
    const {
        UI: { Tooltip },
        Container: { PropertyInput: InputContainer },
        Component: { Input },
    } = config;
    return (props) => {
        return (
            <VStack
                spacing={6}
                divider={<StackDivider borderWidth={1} borderColor="gray.200" />}
            >
                {props.items.filter(isTreeProperty).map((field) => {
                    return (
                        <VStack key={field.key} w="full" align="start" spacing={3}>
                            <VStack
                                w="full"
                                align="start"
                                spacing={2}
                                position="relative"
                            >
                                <HStack w="full" spacing={3}>
                                    <Heading color="gray.900" fontSize="lg">
                                        {field.name}
                                    </Heading>
                                </HStack>
                                <Text color="gray.500" fontWeight="medium">
                                    We classify companies into verticals using AI so that
                                    you can compare the metrics of similar companies
                                </Text>
                            </VStack>
                            <VStack w="full" fontWeight="medium" align="start">
                                <VStack w="full" align="start" spacing={3}>
                                    <InputContainer
                                        {...props.getPropertyInputProps(field)}
                                        as={Input}
                                        truncateCount={6}
                                        theme={{
                                            tree: {
                                                item: {
                                                    label: {
                                                        color: 'blackAlpha.800',
                                                    },
                                                },
                                                caption: {
                                                    label: {
                                                        color: 'gray.500',
                                                    },
                                                },
                                                more: {
                                                    label: {
                                                        color: 'gray.500',
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </VStack>
                            </VStack>
                        </VStack>
                    );
                })}
                {props.items.filter(isCurrencyProperty).map((field) => {
                    const inputProps = props.getPropertyInputProps(field);
                    return (
                        <VStack
                            key={field.key}
                            w="full"
                            align="start"
                            spacing={3}
                            // aria-disabled={inputProps.status?.isDisabled}
                            // _disabled={{ opacity: 0.5 }}
                        >
                            <HStack w="full" spacing={3} justify="space-between">
                                <Heading color="gray.900" fontSize="lg">
                                    {field.name}
                                </Heading>
                                {inputProps.status?.Icon && (
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        isDisabled={!inputProps.status.description}
                                        label={
                                            <Box p={2}>
                                                {inputProps.status?.description}
                                            </Box>
                                        }
                                    >
                                        <Icon
                                            color="gray.500"
                                            as={inputProps.status?.Icon}
                                            onClick={inputProps.status.onClick}
                                            cursor="pointer"
                                            opacity={0.5}
                                        />
                                    </Tooltip>
                                )}
                            </HStack>
                            {field.description && (
                                <Text color="gray.700">{field.description}</Text>
                            )}
                            <VStack w="full" align="stat" fontWeight="medium">
                                <Box
                                    onClick={inputProps.status?.onClick}
                                    cursor={
                                        inputProps.status?.onClick ? 'pointer' : undefined
                                    }
                                >
                                    <InputContainer
                                        {...inputProps}
                                        as={Input}
                                        theme={{
                                            range: {
                                                thumb: {
                                                    bg: 'white',
                                                    borderColor: 'gray.300',
                                                    shadow: 'none',
                                                    _disabled: { bg: 'white' },
                                                    h: 4,
                                                    w: 4,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </VStack>
                        </VStack>
                    );
                })}
            </VStack>
        );
    };
}
