import { ClientPreferenceAdapter } from '../../../app';
import { ClientPreferenceImplConfig } from './clientPreferenceConfig';

export function createClientPreferenceImpl(
    config: ClientPreferenceImplConfig
): ClientPreferenceAdapter {
    const {
        api: { platform: api },
    } = config;
    return {
        get(context, query) {
            return api.clientPreferences.get(context);
        },
        update(context, payload) {
            return api.clientPreferences.update(context, payload);
        },
    };
}
