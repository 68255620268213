import {
    Button,
    Heading,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Select,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FaSortAmountDown } from 'react-icons/fa';
import { AnalysisBreakdownViewProps } from './breakdownProps';

export function createBreakdownView(): React.FC<
    { children?: React.ReactNode | undefined } & AnalysisBreakdownViewProps
> {
    return (props) => {
        return (
            <VStack>
                <HStack w="full">
                    <Select
                        value={props.value?.properties?.[0]?.key}
                        onChange={(event) =>
                            props.onChange({
                                ...props.value,
                                properties: [
                                    {
                                        key: event.target.value,
                                    },
                                ],
                            })
                        }
                    >
                        {props.options.map((option) => (
                            <option key={option.value} value={option.value ?? undefined}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                </HStack>
                <HStack w="full" justify="end">
                    <Button size="sm" onClick={props.onChange.bind(null, null)}>
                        Clear
                    </Button>
                </HStack>
            </VStack>
        );
    };
}
