import { ReportApi } from './reportsInterface';
import { ReportApiConfig } from './reportsConfig';
import { createReportScheduleResource } from './schedule';
import { createReportResource } from './report/reportResource';

export function createReportApi(config: ReportApiConfig): ReportApi {
    const { client } = config;
    return {
        schedules: createReportScheduleResource(client),
        reports: createReportResource(client),
    };
}
