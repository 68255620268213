import { compose } from 'lodash/fp';
import {
    createFeatureExportStrategy,
    createFeatureDasboardStrategy,
} from '../../../strategy';
import { DashboardRouteEnhancer } from '../../../entrypoint';
import { createFeatureProvider } from '../../../app';
import { createFeaturesetImpl } from '../../../impl';
import { FeatureEnhancerConfig } from './featureDashboardConfig';

export function createDashboardFeatureMiddleware(
    init: FeatureEnhancerConfig
): DashboardRouteEnhancer {
    return compose(createFeatureDasboardStrategy(init), createFeatureExportStrategy());
}

export function configureFeatureProvider() {
    return createFeatureProvider({
        getDeps: (config) => {
            return createFeaturesetImpl({ axios: config.api });
        },
    });
}
