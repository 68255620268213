import { TraitAdapter } from '../../../app/peers';
import { TraitImplConfig } from './traitConfig';

export function createTraitImpl(config: TraitImplConfig = {}): TraitAdapter {
    return {
        async find(query) {
            return query.plugins.map((plugin) => ({
                plugin,
                traits: [
                    {
                        kind: 'enum',
                        id: 'vertical-v2',
                        name: 'Vertical',
                        description: `We classify companies into verticals using AI so that you can compare the metrics of similar companies`,
                    },
                    {
                        kind: 'number',
                        id: `${plugin}_spend`,
                        name: 'Spend',
                        description: `Filter companies by similar spend`,
                    },
                    {
                        kind: 'number',
                        id: `${plugin}_aov`,
                        name: 'AOV',
                        description: `Filter companies by similar AOV`,
                    },
                ],
            }));
        },
    };
}
