import { z } from 'zod';

export const GroupInviteSchema = z.object({
    id: z.string(),
    asset: z.number(),
    group: z.string(),
});

export const GroupInviteListResponseSchema = z.object({
    data: z.array(GroupInviteSchema),
});
