import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Input,
    List,
    ListItem,
    StackDivider,
    Switch,
    Tag,
    Text,
    Tooltip,
    VStack,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    Icon,
    InputLeftElement,
    IconButton,
    Alert,
    AlertTitle,
    AlertIcon,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineCheck, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { CardSpinner } from '../../../../domain';
import { OnboardingMetricViewProps } from './onboardingMetricProps';

export function createOnboardingMetricView(): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingMetricViewProps
> {
    return (props) => {
        const viewProps = props.getViewProps();
        const sectionProps = props.getSectionProps();
        const selectionProps = viewProps.getSelectionProps();
        const searchProps = viewProps.getSearchProps();
        const statusProps = viewProps.getStatusProps();

        if (statusProps.status === 'loading') {
            return <CardSpinner />;
        }

        if (statusProps.status === 'empty') {
            return (
                <VStack
                    w="full"
                    spacing={12}
                    py={3}
                    divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
                >
                    <Alert status="warning">
                        <AlertIcon />
                        <AlertTitle>{statusProps.title}</AlertTitle>
                    </Alert>
                </VStack>
            );
        }
        return (
            <VStack
                w="full"
                spacing={12}
                py={3}
                divider={<StackDivider borderColor="gray.300" borderWidth={0.5} />}
            >
                <Grid w="full" templateRows="min-content min-content 1fr" rowGap={3}>
                    <GridItem>
                        <InputGroup size="lg">
                            <InputLeftElement>
                                <Icon color="gray.300" as={AiOutlineSearch} />
                            </InputLeftElement>
                            <Input
                                {...searchProps}
                                borderColor="gray.300"
                                w="full"
                                placeholder="Search metrics"
                                bg="white"
                                _placeholder={{
                                    color: 'gray.300',
                                }}
                            />
                        </InputGroup>
                    </GridItem>
                    <GridItem>
                        <HStack spacing={2} w="full" justify="end">
                            <Text color="gray.500" fontWeight="medium">
                                {selectionProps.label}
                            </Text>
                            {selectionProps.showClear && (
                                <Button
                                    variant="link"
                                    onClick={selectionProps.onClear}
                                    _focus={{ outline: 'none' }}
                                >
                                    Clear
                                </Button>
                            )}
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <VStack spacing={6} w="full">
                            {sectionProps.sections.map((section, index) => (
                                <VStack key={section.id} align="start" w="full">
                                    <Box w="full">
                                        <Heading
                                            m={0}
                                            color="gray.500"
                                            fontSize="sm"
                                            fontWeight="bold"
                                            letterSpacing="wide"
                                            textTransform="uppercase"
                                        >
                                            {section.label}
                                        </Heading>
                                    </Box>
                                    <VStack
                                        w="full"
                                        _selected={{
                                            borderColor: 'blue.400',
                                        }}
                                        divider={<StackDivider borderColor="gray.300" />}
                                        spacing={0}
                                    >
                                        {section.items.map((item) => {
                                            const itemProps =
                                                viewProps.getItemProps(item);
                                            return (
                                                <HStack
                                                    key={item.metric.id}
                                                    w="full"
                                                    py={3}
                                                >
                                                    <HStack spacing={3} flex={1}>
                                                        <Box
                                                            p={2}
                                                            bg="gray.200"
                                                            borderRadius="sm"
                                                            flexShrink={0}
                                                        >
                                                            <Image
                                                                w={6}
                                                                h={6}
                                                                src={
                                                                    item.plugin.iconUrl ??
                                                                    ''
                                                                }
                                                            />
                                                        </Box>
                                                        <Tooltip
                                                            hasArrow={true}
                                                            placement="top"
                                                            bg="white"
                                                            color="gray.900"
                                                            p={6}
                                                            borderRadius="md"
                                                            minW="24rem"
                                                            label={
                                                                <VStack align="start">
                                                                    <HStack
                                                                        spacing={3}
                                                                        flex={1}
                                                                    >
                                                                        <Box
                                                                            p={2}
                                                                            bg="gray.100"
                                                                            borderRadius="sm"
                                                                        >
                                                                            <Image
                                                                                w={6}
                                                                                h={6}
                                                                                src={
                                                                                    item
                                                                                        .plugin
                                                                                        .iconUrl ??
                                                                                    ''
                                                                                }
                                                                            />
                                                                        </Box>
                                                                        <VStack
                                                                            align="start"
                                                                            spacing={0}
                                                                        >
                                                                            <Text
                                                                                fontWeight="semibold"
                                                                                // fontSize="md"
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .metric
                                                                                        .name
                                                                                }
                                                                            </Text>
                                                                            <Text
                                                                                fontWeight="medium"
                                                                                color="gray.500"
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .plugin
                                                                                        .name
                                                                                }
                                                                            </Text>
                                                                        </VStack>
                                                                    </HStack>
                                                                    <Text color="gray.700">
                                                                        {
                                                                            item.metric
                                                                                .description
                                                                        }
                                                                    </Text>
                                                                </VStack>
                                                            }
                                                        >
                                                            <Text
                                                                fontWeight="medium"
                                                                flexShrink={0}
                                                                whiteSpace="nowrap"
                                                            >
                                                                {item.metric.name}
                                                            </Text>
                                                        </Tooltip>
                                                    </HStack>
                                                    <HStack>
                                                        {itemProps.isSelectable &&
                                                            itemProps.isSelected && (
                                                                <IconButton
                                                                    aria-label="Add"
                                                                    colorScheme="green"
                                                                    icon={
                                                                        <Icon
                                                                            as={
                                                                                AiOutlineCheck
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={
                                                                        itemProps.onSelect
                                                                    }
                                                                    _focus={{
                                                                        outline: 'none',
                                                                    }}
                                                                />
                                                            )}
                                                        {itemProps.isSelectable &&
                                                            !itemProps.isSelected && (
                                                                <IconButton
                                                                    aria-label="Add"
                                                                    icon={
                                                                        <Icon
                                                                            as={
                                                                                AiOutlinePlus
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={
                                                                        itemProps.onSelect
                                                                    }
                                                                    _focus={{
                                                                        outline: 'none',
                                                                    }}
                                                                />
                                                            )}
                                                    </HStack>
                                                </HStack>
                                            );
                                        })}
                                    </VStack>
                                </VStack>
                            ))}
                        </VStack>
                    </GridItem>
                </Grid>
            </VStack>
        );
    };
}
