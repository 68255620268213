import { AxiosInstance } from 'axios';
import { ColumnListResponse } from './columnModel';

export async function listColumns(
    api: AxiosInstance,
    dashboardId: string,
    options?: { apiKey?: string }
): Promise<ColumnListResponse> {
    const params: Record<string, unknown> = {
        dashboard: dashboardId,
    };
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    const response = await api.get<ColumnListResponse>('/api/v2/columns', {
        params,
    });
    return response.data;
}
