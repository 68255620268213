import { AxiosInstance } from 'axios';
import qs from 'qs';
import { Dashboard } from './dashboardModel';

export async function fetchDashboard(
    api: AxiosInstance,
    slug: string,
    assetIds: number[],
    options?: { apiKey?: string; organizationId?: number }
): Promise<Dashboard> {
    const params: Record<string, unknown> = {
        asset_ids: assetIds,
    };
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    if (options?.organizationId) {
        params['organization'] = options.organizationId;
    }
    const response = await api.get<Dashboard>(`/api/v2/dashboards/${slug}`, {
        params,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
    return response.data;
}
