import { QueryClient } from "@tanstack/react-query";
import { CacheKey, CacheRegistrar, CacheSection } from "./cacheRegistrarInterface";



async function refresh(client: QueryClient, prefix: CacheKey) {
    await client.cancelQueries(prefix);
    await client.invalidateQueries(prefix, { refetchType: 'all' });
    await client.refetchQueries(prefix);
}
export function createCacheRegistrar(): CacheRegistrar {
    const registry: Record<CacheSection, Array<CacheKey>> = {users: [], collaberator: [], workspace: []};
    return {
        register(section, key) {
            const keys = registry[section];
            keys.push(key);
        },
        async refresh(context, section, extra) {
            for(let i=0; i < registry[section].length; i++) {
                const key = registry[section][i];
                const cacheKey = extra ? [...key, ...extra] : key;
                await refresh(context.client, cacheKey);
            }
        },
    }
}