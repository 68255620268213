import { AxiosInstance, AxiosResponse } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { AccountInvitationListSchema, AccountInvitationSchema } from './invitationSchema';
import { InvitationResource } from './invitationInterface';
import { InvitationAcceptDto, InvitationAcceptResponseDto } from './invitationModel';

export function createInvitationResource(client: AxiosInstance): InvitationResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {
                ...query,
            };

            const response = await client.get(`/api/v1/account/invitations`, {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });

            const parsed = AccountInvitationListSchema.parse(response.data);
            return parsed;
        },
        async create(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            // const params: Record<string, unknown> = {};
            const { source, inviter_user_id, actions, ...restInivtationData } = payload.data;
            const response = await client.post(`/api/v1/account/invitations`,
                {
                    source,
                    actions,
                    inviter_user_id,
                    data: restInivtationData,
                },
                {
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });

            const parsed = AccountInvitationSchema.parse(response.data);
            return parsed;
        },
        async delete(context, id) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const params: Record<string, unknown> = {};
            const response = await client.delete(`/api/v1/account/invitations/${id}`, {
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            return;
        },
        async verify(query) {
            const response = await client.get(`/api/v1/account/invitations/verify`, {
                params: {
                    invitation: query.invitationId,
                    token: query.token,
                },
            });
            const parsed = AccountInvitationSchema.parse(response.data);
            return parsed;
        },
        async accept(payload) {
            const response = await client.post<
                InvitationAcceptDto,
                AxiosResponse<InvitationAcceptResponseDto>
            >(`/api/v1/account/invitations/accept`, payload);
            return response.data;
        },
    };
}
