import klaviyoPreview1 from '../../../images/dashboard_preview_klaviyo_01.png';
import klaviyoPreview2 from '../../../images/dashboard_preview_klaviyo_02.png';
import type { FeatureFixture } from '../featureFixture';

export const KLAVIYO_HIGHLIGHTS: FeatureFixture[] = [
    {
        text: `Uncover where your email marketing has the most room for improvement`,
    },
    {
        text: `See how many emails you're sending compared to your peers`,
    },
    {
        text: `Track how your KPIs stack up against a granular group of your peers`,
    },
    {
        text: `Break down benchmarks by specific types of flows & campaigns`,
    },
];

export const KLAVIYO_GRAPHICS: Array<{ src: string }> = [
    {
        src: klaviyoPreview2,
    },
    {
        src: klaviyoPreview1,
    },
];
