import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { DatasetAggregate, ImportNewAggregate } from '../../../../../view/datasets';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import { PluginEntity } from '../../../../../domain';
import { SettingImportConfig } from '../importConfig';
import { SettingImportNewLoader } from './importNewInterface';

export function createSettingImportNewLoader(
    init: Pick<SettingImportConfig, 'repository'>,
    config: SettingAssetItemProviderConfig
): SettingImportNewLoader {
    const { repository } = init;
    const {} = config;
    return {
        useLoad(context) {
            const {
                api: { loader: pageLoader },
            } = config;
            const { asset } = pageLoader.useLoad(context);

            const plugins = repository.plugin.useFind(context, {}, { suspense: true });
            const datasets = repository.dataset.useFind(
                context,
                { category: 'survey' },
                { suspense: true }
            );

            const pluginsById = useMemo(
                () =>
                    keyBy(plugins.data, (item) => item.id) as Record<
                        string,
                        PluginEntity | undefined
                    >,
                [plugins.data]
            );

            const aggregate = useMemo<ImportNewAggregate>(() => {
                return {
                    datasets:
                        datasets.data?.flatMap((item): DatasetAggregate[] => {
                            if (!item.plugin) {
                                return [];
                            }
                            const plugin = pluginsById[item.plugin.id];
                            if (!plugin) {
                                console.warn(
                                    `plugin ${item.plugin.id} not found`,
                                    pluginsById
                                );
                                return [];
                            }
                            return [{ dataset: item, plugin }];
                        }) ?? [],
                };
            }, [asset, pluginsById, datasets.data]);

            return aggregate;
        },
    };
}
