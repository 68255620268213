import { useMemo } from 'react';
import { chain, groupBy, keyBy } from 'lodash';
import { ImportAggregate, ImportListAggregate } from '../../../../../view/datasets';
import { SettingAssetItemProviderConfig } from '../../../../../route';
import { PluginEntity, ViewEntity } from '../../../../../domain';
import {
    AnyMappableEntity,
    Integration,
    IntegrationDefinition,
} from '../../../../../domain/assets';
import { DatasetConnectionEntity, DatasetEntity } from '../../../../../app/datasets';
import { SettingImportConfig } from '../importConfig';
import { SettingImportLoader } from './importListInterface';

export function createSettingImportListLoader(
    init: Pick<SettingImportConfig, 'repository'>,
    config: SettingAssetItemProviderConfig
): SettingImportLoader {
    const { repository } = init;
    const {} = config;
    return {
        useLoad(context) {
            const {
                api: { loader: pageLoader },
            } = config;
            const { asset } = pageLoader.useLoad(context);

            const plugins = repository.plugin.useFind(context, {}, { suspense: true });
            const datasets = repository.dataset.useFind(
                context,
                { category: 'survey' },
                { suspense: true }
            );

            const imports = repository.import.useFind(
                context,
                { asset },
                { suspense: true }
            );

            const pluginById = useMemo(
                () =>
                    keyBy(plugins.data, (item) => item.id) as Record<
                        string,
                        PluginEntity | undefined
                    >,
                [plugins.data]
            );

            const datasetById = useMemo(
                () =>
                    keyBy(datasets.data, (item) => item.id) as Record<
                        string,
                        DatasetEntity | undefined
                    >,
                [datasets.data]
            );

            const aggregate = useMemo<ImportListAggregate>(() => {
                return {
                    items: chain(imports.data)
                        .filter((item) => item.asset.id === context.workspace.id)
                        .flatMap((item): ImportAggregate[] => {
                            const dataset = datasetById[item.dataset.id];
                            if (!dataset) {
                                console.warn(
                                    `dataset ${item.dataset.id} not found`,
                                    datasetById
                                );
                                return [];
                            }
                            if (!dataset.plugin) {
                                return [];
                            }
                            const plugin = pluginById[dataset.plugin.id];
                            if (!plugin) {
                                console.warn(
                                    `dataset plugin ${dataset.plugin.id} not found`,
                                    pluginById
                                );
                                return [];
                            }
                            return [
                                {
                                    import: item,
                                    asset,
                                    dataset: {
                                        dataset,
                                        plugin,
                                    },
                                },
                            ];
                        })
                        .value(),
                };
            }, [asset, imports.data]);

            return aggregate;
        },
    };
}
