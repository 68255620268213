import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MetricCollectionNewFormProvider } from './collectionNewInterface';

export function createMetricCollectionNewForm(): MetricCollectionNewFormProvider {
    return {
        useForm(context, queries) {
            const form = useForm({
                defaultValues: {
                    search: '',
                    selected: {},
                },
            });
            useEffect(() => {
                if (queries.collection.default.data) {
                    form.reset({
                        search: '',
                        selected: queries.collection.default.data.metrics.reduce(
                            (acc, item) => ({
                                ...acc,
                                [item.definition]: true as const,
                            }),
                            {} as Record<string, true | undefined>
                        ),
                    });
                }
            }, [queries.collection.default.data]);
            return form;
        },
    };
}
