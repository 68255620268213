import { z } from 'zod';

export const OrganizationTypeSchema = z.union([
    z.literal('mobile_app'),
    z.literal('ecommerce_website'),
    z.literal('saas'),
    z.literal('website'),
    z.literal('marketplace'),
    z.literal('ad_agency'),
]);

export const OrganizationRoleSchema = z.union([
    z.literal('admin'),
    z.literal('standard'),
    z.literal('viewer'),
]);

export const MeUserSchema = z.object({
    id: z.number(),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    email: z.string(),
    title: z.nullable(z.string()),
    created_at: z.date({ coerce: true }),
    organizations: z.array(
        z.object({
            // role: z.union(z.literal('admin')),
            role: OrganizationRoleSchema,
            organization: z.object({
                id: z.number(),
                name: z.string(),
                domain: z.string(),
                default_asset_id: z.nullable(z.number()),
                company_type: z.nullable(OrganizationTypeSchema),
                finished_on_boarding: z.boolean(),
                promo_code: z.nullable(z.string()),
                // source: 'direct',
                source: z.nullable(z.string()),
                // metadata: {
                //     paywalled_during_onboarding: true,
                // },
            }),
        })
    ),
});

export const MeDtoSchema = z.object({
    user: MeUserSchema,
    // on_boarding_states: [
    //     {
    //         state: 'req_integration',
    //         can_skip: false,
    //         subject_id: 21439,
    //     },
    //     {
    //         state: 'subscription',
    //         can_skip: false,
    //         subject_id: null,
    //     },
    //     {
    //         state: 'user_invites',
    //         can_skip: true,
    //         subject_id: 21439,
    //     },
    //     {
    //         state: 'pre_assigned_peer_groups',
    //         can_skip: false,
    //         subject_id: 21439,
    //     },
    // ],
});
