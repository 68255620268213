import { QueryResponse, QueryResult } from '../../api/v2';
import { SeriesEvent } from '../event';

/**
 * Combines separate query results into a single response. the queries must be
 * structurally equivalent (same filters, breakdown, etc.)
 * @param responses
 * @returns
 */
export function combineQueryResponses(responses: QueryResponse[]): QueryResponse {
    const [first, ...rest] = responses;
    if (rest.length === 0) {
        return first;
    }
    return rest.reduce(
        (acc, response): QueryResponse => ({
            ...acc,
            metadata: {
                ...acc.metadata,
                scalar: [...acc.metadata.scalar, ...response.metadata.scalar],
                inverse: [...acc.metadata.inverse, ...response.metadata.inverse],
            },
            queries: acc.queries.map<QueryResult>((query, indexQuery) => ({
                ...query,
                schema: {
                    ...query.schema,
                    properties: {
                        ...query.schema.properties,
                        ...response.queries[indexQuery].schema.properties,
                    },
                },
                series: {
                    ...query.series,
                    items: query.series.items.map((series, indexSeries) => ({
                        ...series,
                        data: series.data.map((row, indexRow) => ({
                            ...row,
                            ...response.queries[indexQuery].series.items[indexSeries]
                                .data[indexRow],
                        })),
                        events: [
                            ...series.events,
                            ...response.queries[indexQuery].series.items[indexSeries]
                                .events,
                        ],
                    })),
                },
            })),
        }),
        first
    );
}
