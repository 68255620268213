import { useSystemContextV2 } from '../../../context';
import { VarosDatasetController } from './datasetInterface';

export const useVarosDatasetController: VarosDatasetController = () => {
    // const { api } = useAuthenticatedContext();

    const result = useSystemContextV2();
    // const query = useQuery({
    //     queryKey: ['asset-counts'],
    //     queryFn() {
    //         return getAssetCounts(api);
    //     },
    //     staleTime: Infinity,
    // });

    return {
        // assetCount: query.data?.asset.active ?? null,
        assetCount: result.data.assetCount.data?.asset.active ?? null,
    };
};
