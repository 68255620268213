import { useQuery } from '@tanstack/react-query';
import { isWorkspaceContext } from '../workspace';
import { FeatureSetAdapter } from './featureAdapter';
import { FeatureSetRepository } from './featureInterface';

export function createFeatureRepository(
    adapter: FeatureSetAdapter
): FeatureSetRepository {
    return {
        useLookup(context, options) {
            const workspaceId = isWorkspaceContext(context) ? context.workspace.id : null;
            return useQuery({
                ...options,
                queryKey: ['feature-set', 'v2', context.organization.id, workspaceId],
                async queryFn() {
                    const response = await adapter.get(context, workspaceId);
                    return response;
                },
            });
        },
    };
}
