import { compose } from 'lodash/fp';
import React from 'react';
import { CalculationView } from '../../../views';
import { Tooltip } from '@chakra-ui/react';
import axios from 'axios';
import { SourceIcons } from '../../config';
import { createEmailUI } from '../../impl/email/ui';
import {
    createSegmentStatusImpl,
    createDependencyStatusImpl,
    createIntlFormatter,
    createErrorFormatterImpl,
    createEmailErrorFormatter,
    createPluginImpl,
} from '../../impl';
import { createMovementAtom, createEnumAtom } from '../../atom/attributes';
import { createEmailEntrypoint } from '../../entrypoint';
import {
    createChartService,
    createReportService,
    createQueryService,
    createVisualizationService,
} from '../../service/report';
import { createWorkspaceService } from '../../service/email';
import { createEmailVisualizationController } from '../../controller/email';
import { createReports } from '../../config/report-legacy';
import {
    createEmailInsightContainer,
    createEmailInsightRoute,
    createEmailReportContainer,
    createEmailReportRoute,
} from '../../route/email';
import {
    createEmailInsightController,
    createEmailInsightView,
    createEmailReportController,
    createEmailReportView,
} from '../../view/email';
import {
    createEmailQueryAdapter,
    createHttpImpl,
    createEmailWorkspaceAdapter,
} from '../../impl';
import { createDashboardApi, createInsightApi } from '../../api';
import { createReportTableView } from '../../view';
import { createRendererImpl } from '../../impl/renderer';
import {
    OrganizationReference,
    WorkspaceReference,
    AuthenticationV2,
} from '../../domain/platform';
import { EmailEntryEnhancer } from '../../entrypoint';
import {
    SESSION_SOURCE_INSIGHT_ID_PARAMETER_KEY,
    SESSION_SOURCE_MEDIUM_PARAMETER_KEY,
    SESSION_SOURCE_NAME_PARAMETER_KEY,
    SESSION_SOURCE_TRIGGER_URL_PARAMETER_KEY,
} from '../../config/tracking';
import { createRenderMiddleware } from './renderer';
import { createEmailAtoms } from './atom';
import { createFormatterMiddleware } from './formatter';
import { createEmailErrorMiddleware } from './error';
import { createTrackingMiddleware } from './tracking';
import { createSystemProvider } from '../../context';
import { createOpportunityImpl } from '../../impl/insights';
import { createOpportunityRepository } from '../../app/insight/opportunity';
import { createInsightVisualizable } from '../../strategy';
import { createPluginRepository } from '../../app';
import { createOpportunityService } from '../../service/insight';

export function configureEmailEntrypoint() {
    const axiosInstance = axios.create();

    const enhancer: EmailEntryEnhancer = compose(
        createRenderMiddleware({
            renderer: createRendererImpl({
                components: createEmailAtoms({
                    icons: SourceIcons,
                }),
                formatter: createIntlFormatter(),
            }),
        }),
        createFormatterMiddleware({
            formatter: createIntlFormatter(),
        }),
        createEmailErrorMiddleware({
            errorFormatter: createEmailErrorFormatter(),
        }),
        createTrackingMiddleware({
            inject: {
                query: {
                    [SESSION_SOURCE_MEDIUM_PARAMETER_KEY]: 'email',
                },
            },
            parameters: {
                insightId: SESSION_SOURCE_INSIGHT_ID_PARAMETER_KEY,
                sourceName: SESSION_SOURCE_NAME_PARAMETER_KEY,
                sourceTrigger: SESSION_SOURCE_TRIGGER_URL_PARAMETER_KEY,
            },
        })
    );

    return createEmailEntrypoint(
        {
            config: { reports: createReports() },
            infra: {
                createAxios() {
                    return axios;
                },
                createHttp() {
                    return createHttpImpl({ axios });
                },
                createErrorFormatter() {
                    return {
                        format(error) {
                            return {
                                name: error.name,
                                description: error.name,
                                hint: null,
                            };
                        },
                    };
                },
            },
            api: {
                createInsightApi: createInsightApi,
                createDashboardApi: createDashboardApi,
            },
            adapter: {
                platform: {
                    createPlugins: createPluginImpl,
                },
                insights: {
                    createOpportunities: createOpportunityImpl,
                },
                report: {
                    useDependencyAdapter() {
                        return createDependencyStatusImpl({
                            axios: axiosInstance,
                        });
                    },
                    useSegmentAdapter() {
                        return createSegmentStatusImpl({ axios: axiosInstance });
                    },
                    createQueryAdapter: createEmailQueryAdapter,
                    createWorkspaceAdapter: createEmailWorkspaceAdapter,
                },
            },
            repository: {
                platform: {
                    createPlugins: createPluginRepository,
                },
                insights: {
                    createOpportunities: createOpportunityRepository,
                },
            },
            service: {
                createOpportunityService: createOpportunityService,
                createReportService: createReportService,
                createQueryService: createQueryService,
                createChartService: createChartService,
                createVisualizationService: createVisualizationService,
                createWorkspaceService: createWorkspaceService,
            },
            component: {
                base: {
                    createUI: createEmailUI,
                },
                report: {
                    createTableVisualization: createReportTableView,
                },
            },
            view: {
                createEmailReportView: createEmailReportView,
                createEmailInsightView: createEmailInsightView,
            },
            strategy: {
                insights: {
                    createVisualizable: createInsightVisualizable,
                },
            },
            controller: {
                createInsightController: createEmailInsightController,
                createReportController: createEmailReportController,
                createVisualizationController: createEmailVisualizationController,
            },
            container: {
                createInsightContainer: createEmailInsightContainer,
                createReportContainer: createEmailReportContainer,
            },
            provider: {
                createAuth() {
                    const AuthContext = React.createContext<AuthenticationV2 | null>(
                        null
                    );
                    return {
                        Provider: AuthContext.Provider,
                        useContext() {
                            const context = React.useContext(AuthContext);
                            if (!context) {
                                throw new Error(`auth context not found`);
                            }
                            return context;
                        },
                    };
                },
                createSystem: createSystemProvider,
                createOrganization() {
                    const OrganizationContext =
                        React.createContext<OrganizationReference | null>(null);
                    return {
                        Provider: OrganizationContext.Provider,
                        useContext() {
                            const context = React.useContext(OrganizationContext);
                            if (!context) {
                                throw new Error(`organization context not found`);
                            }
                            return context;
                        },
                    };
                },
                createWorkspace() {
                    const WorkspaceContext =
                        React.createContext<WorkspaceReference | null>(null);
                    return {
                        Provider: WorkspaceContext.Provider,
                        useContext() {
                            const context = React.useContext(WorkspaceContext);
                            if (!context) {
                                throw new Error(`workspace context not found`);
                            }
                            return context;
                        },
                    };
                },
            },
            route: {
                createReportRoute: createEmailReportRoute,
                createInsightRoute: createEmailInsightRoute,
            },
        },
        enhancer
    );
}
