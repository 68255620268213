import { z } from 'zod';

// Item

export const SetupIntentDtoSchema = z.object({
    client_secret: z.string(),
});

// Create

export const SetupIntentCreateDtoSchema = z.object({});
