import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { sentry, highlight } from './container';
import * as serviceWorker from './serviceWorker';

import {
    Chart,
    PointElement,
    BarElement,
    LineElement,
    CategoryScale,
    LinearScale,
    Tooltip,
} from 'chart.js';

Chart.register(
    Tooltip,
    PointElement,
    BarElement,
    LineElement,
    CategoryScale,
    LinearScale
);

declare global {
    interface Window {}
}

sentry.initialize();

// quick hack to avoid initializing highlight.io on the template
// pages so that the headless browser can properly wait
// for idle network activity
if (window.location.pathname.startsWith(`/template/`)) {
    console.log('skipping highlight.io');
} else {
    highlight.initialize();
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <sentry.ErrorBoundary>
            <App />
        </sentry.ErrorBoundary>
    </React.StrictMode>
);

// ReactDOM.render(
//     <React.StrictMode>
//         <sentry.ErrorBoundary>
//             <App />
//         </sentry.ErrorBoundary>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
