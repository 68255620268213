import { useMemo } from 'react';
import { buildVideoViewProps } from '../../../view/common';
import { ModalIds, PageIds, StudyConstant } from '../../../config';
import { PeerOverviewRouteController } from './peerOverviewInterface';
import { PeerControllerConfig } from '../base';

export function createPeerOverviewController(
    config: PeerControllerConfig
): PeerOverviewRouteController {
    const {
        controller: {
            common: {
                page: pageController,
                modal: modalController,
                onboarding: onboardingController,
            },
        },
    } = config;
    return {
        useProps(context, item, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.PEER_OVERVIEW,
                    breadcrumbs: [],
                },
            });
            // const modalProps = modalController.useProps(props.modal, {
            //     id: ModalIds.STUDY_OVERVIEW_VIDEO,
            //     trigger: null,
            // });

            // const onboardingProps = onboardingController.useProps(item, {
            //     modal: modalProps,
            // });

            const videoProps = useMemo(
                () => buildVideoViewProps(StudyConstant.OVERVIEW_VIDEO),
                []
            );

            return {
                page: pageProps,
                // modal: modalProps,
                // video: videoProps,
                links: {},
            };
        },
    };
}
