import React from 'react';
import { ErrorBoundary } from '../../../../infra';
import { getChildrenProps } from '../../../util';
import { ApplicationUI } from '../../../ui';
import { createGridComponents } from '../../chakra/base/grid';

export function createEmailUI(): ApplicationUI {
    const GRAY = '#43434E';
    const RED = '#EE4848';
    const Instance: ApplicationUI = {
        // @ts-expect-error
        Stepper: {
            Container() {
                throw new Error('not impl');
            },
        },
        Grid: {
            ...createGridComponents(),
            GridContainer() {
                throw new Error('not impl');
            },
        },
        View(props) {
            return <>{props.children}</>;
        },
        Tooltip() {
            throw new Error('not impl');
        },
        Table(props) {
            const style: React.HTMLAttributes<HTMLTableElement>['style'] = {
                ...props.style,
            };
            if (props.width === 'full') {
                style['width'] = '100%';
            }
            return (
                <table className={props.className} style={style}>
                    {props.children}
                </table>
            );
        },
        Thead({ style: style, ...props }) {
            return <thead style={style}>{props.children}</thead>;
        },
        Tbody({ style: style, ...props }) {
            return <tbody style={style}>{props.children}</tbody>;
        },
        Tr({ style: style, ...props }) {
            return <tr style={style}>{props.children}</tr>;
        },
        Th({ style: style, ...props }) {
            return <th style={style}>{props.children}</th>;
        },
        Td({ style: style, ...props }) {
            return <td style={style}>{props.children}</td>;
        },
        Heading({ as = 'p', ...props }) {
            return React.createElement(as, { ...props });
            // return <p style={style}>{props.children}</p>;
        },
        Text({ className, style: style, ...props }) {
            return (
                <p style={style} className={className}>
                    {props.children}
                </p>
            );
        },
        Link({ to, ...props }) {
            if (typeof to !== 'string') {
                throw new Error(`expected property 'to' on link to be a string`);
            }
            return <a {...props} href={to} />;
        },
        Anchor({ ...props }) {
            return <a href={props.href} children={props.children} />;
        },
        Button({ style: style, ...props }) {
            let styleAcc: React.HTMLAttributes<HTMLButtonElement>['style'] = {
                ...style,
                display: 'flex',
                alignItems: 'center',
            };
            if (props.variant === 'outline' && props.colorScheme === 'red') {
                styleAcc = {
                    ...styleAcc,
                    border: `1px solid ${RED}`,
                    color: RED,
                    background: 'transparent',
                    padding: '8px 16px',
                    borderRadius: 'none',
                    fontWeight: 500,
                };
            }
            return (
                <button style={styleAcc} onClick={props.onClick}>
                    {props.leftIcon ? (
                        <div style={{ height: 16, width: 16, marginRight: 8 }}>
                            {props.leftIcon}
                        </div>
                    ) : null}
                    {props.children}
                </button>
            );
        },
        AsyncButton() {
            throw new Error(`not implemented`);
        },
        Tag: {
            List() {
                throw new Error(`not implemented`);
            },
            Item() {
                throw new Error(`not implemented`);
            },
        },
        Icon: {
            IconGroup() {
                throw new Error(`not implemented`);
            },
            Icon() {
                throw new Error(`not implemented`);
            },
            Badge() {
                throw new Error(`not implemented`);
            },
        },
        EmptyState(props) {
            // const childProps = getChildrenProps(props.children, {
            //     heading: Instance.Heading,
            //     text: Instance.Text,
            //     link: Instance.Link,
            // });
            return (
                <table width="100%" cellSpacing="0" cellPadding="0">
                    <tbody>
                        {props.title && (
                            <tr>
                                <td align="center">
                                    <Instance.Heading as="h2">
                                        {props.title}
                                    </Instance.Heading>
                                </td>
                            </tr>
                        )}
                        {props.description && (
                            <tr>
                                <td align="center">
                                    <div
                                        style={{
                                            marginTop: 12,
                                            maxWidth: 500,
                                        }}
                                    >
                                        <Instance.Text className="mute">
                                            {props.description}
                                        </Instance.Text>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {props.children && (
                            <tr>
                                <td align="center">
                                    <div style={{ marginTop: 18 }}>
                                        {props.children}
                                        {/* <Instance.Link
                                            className="button button-cta"
                                            {...childProps.link}
                                        /> */}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        },
        createErrorBoundary(config) {
            return (props) => (
                <ErrorBoundary
                    fallback={(info) => {
                        if (info.error instanceof config.errorClass) {
                            return props.fallback({
                                error: info.error as any,
                            });
                        }
                        throw info.error;
                    }}
                >
                    {props.children}
                </ErrorBoundary>
            );
        },
    };
    return Instance;
}
