import { UseQueryResult } from '@tanstack/react-query';
import { MetricIds } from '../../../config';
import { DashboardSlugs } from '../../../config/dashboard';
import { MetricDefinition } from '../../domain/metrics';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { MetricAccessEnhancerConfig } from './accessConfig';

/**
 * Hack to hide metric definitions for which the asset does not have access to the associated dashboards.
 * Eventually we want to get rid of dashboards
 * @param init
 * @returns
 */
export function createMetricDashboardAccessEnhancer(
    init: MetricAccessEnhancerConfig = {}
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            context: { createWorkspace },
        } = config;
        return create({
            ...config,
            context: {
                ...config.context,
                createWorkspace(config) {
                    return createWorkspace({
                        ...config,
                        provider: {
                            createLoader(deps) {
                                const loader = config.provider.createLoader(deps);
                                return {
                                    ...loader,
                                    useData(context) {
                                        const data = loader.useData(context);

                                        const hasCpaDashboard =
                                            data.data.dashboards.data?.find(
                                                (item) =>
                                                    item.id ===
                                                    DashboardSlugs.FACEBOOK_CPA_V2
                                            );
                                        return {
                                            ...data,
                                            data: {
                                                ...data.data,
                                                metric: {
                                                    ...data.data.metric,
                                                    definitions: {
                                                        ...data.data.metric.definitions,
                                                        data:
                                                            data.data.metric.definitions.data?.filter(
                                                                (def) => {
                                                                    if (
                                                                        def.id ===
                                                                        MetricIds.FACEBOOKADS_CPA
                                                                    ) {
                                                                        // only enable CPA metric for assets with CPA dashboard
                                                                        return hasCpaDashboard;
                                                                    }
                                                                    return true;
                                                                }
                                                            ) ?? [],
                                                    } as UseQueryResult<
                                                        MetricDefinition[]
                                                    >,
                                                },
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
    };
}
