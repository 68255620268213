import { assertNever } from '../../../util';
import { QueryableStrategy } from '../../../app';
import { QueryableVisualizationConfig } from './queryableVisualizationConfig';
import {
    AnyVisualization,
    isDistributionVisualization,
    isLineVisualization,
    isComparisonVisualization,
    isBarVisualization,
} from '../../../domain/visualization';
import { buildTableRequest } from './table';
import { buildDistributionRequest } from './distribution';
import { buildLineRequest } from './line';
import { buildBarRequest } from './bar';

export function createQueryableVisualization(
    config: QueryableVisualizationConfig
): QueryableStrategy<AnyVisualization> {
    return {
        toQuery(context, view, item) {
            if (isComparisonVisualization(item)) {
                return buildTableRequest(context, view, item);
            }
            if (isLineVisualization(item)) {
                return buildLineRequest(context, view, item);
            }
            if (isBarVisualization(item)) {
                return buildBarRequest(context, view, item);
            }
            if (isDistributionVisualization(item)) {
                return buildDistributionRequest(context, view, item);
            }
            assertNever(item);
        },
    };
}
