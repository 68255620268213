import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { SettingDatasetConnectionConfig } from './connectionConfig';
import { createSettingDatasetConnectionContainer } from './connectionContainer';
import { createSettingDatasetConnectionController } from './connectionController';
import { createSettingDatasetConnectionLoader } from './connectionLoader';
import {
    createSettingAssetConnectionEmptyView,
    createSettingAssetConnectionListView,
} from './view';

export function createSettingDatasetConnectionStrategy(
    init: SettingDatasetConnectionConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const controller = createSettingDatasetConnectionController(init, config);
            const loader = createSettingDatasetConnectionLoader(init, config);
            const Container = createSettingDatasetConnectionContainer(
                init,
                config,
                loader,
                controller
            );
            const View = {
                List: createSettingAssetConnectionListView(init, config),
                Empty: createSettingAssetConnectionEmptyView(init, config),
            };
            return [
                {
                    scope: 'data',
                    path: 'datasets',
                    key: 'datasets',
                    title: 'Datasets',
                    Component() {
                        return <Container as={View} />;
                    },
                },
            ];
        },
    };
}
