import { BarVisualizationResult } from './bar';
import { LineVisualizationResult } from './line';
import { AnyVisualizationResult } from './resultModel';
import { ComparisonVisualizationResult } from './comparison';
import { DistributionVisualizationResult } from './distribution';

export function isLineVisualizationResult(
    item: AnyVisualizationResult
): item is LineVisualizationResult {
    return item.kind === 'line';
}

export function isBarVisualizationResult(
    item: AnyVisualizationResult
): item is BarVisualizationResult {
    return item.kind === 'bar';
}

export function isDistributionVisualizationResult(
    item: AnyVisualizationResult
): item is DistributionVisualizationResult {
    return item.kind === 'distribution';
}

export function isComparisonVisualizationResult(
    item: AnyVisualizationResult
): item is ComparisonVisualizationResult {
    return item.kind === 'comparison';
}
