import { InsightRouteSortingV2 } from './insightRouteEnum';
import { OpportunityQueryOrderByKey } from '../../../app/insight';

export const KEY_BY_SORTING_V2: Record<
    InsightRouteSortingV2,
    OpportunityQueryOrderByKey
> = {
    recent: 'period_end_at',
    relevance: 'priority',
};
