import { useRef } from 'react';
import { PageControllerConfig, PageControllerConfigV2 } from './pageConfig';
import { PageController, PageControllerV2 } from './pageInterface';
import { PageViewProps } from './pageProps';
import { PageStatusProps } from './status';

export function createPageControllerV2(config: PageControllerConfigV2): PageControllerV2 {
    const {
        provider: { createAlertListController },
    } = config;

    const { useProps: useAlertProps } = createAlertListController();

    return {
        useProps(item, props) {
            const alertRef = useRef<HTMLDivElement>(null);
            const alert = useAlertProps(
                item.alert ?? { items: [] },
                props.alert
                    ? { ...props.alert, containerRef: alertRef }
                    : { containerRef: alertRef }
            );
            return {
                id: config.id,
                header: {
                    title: config.title ?? 'no title',
                    description: config.description ?? null,
                    breadcrumbs: [],
                },
                alert,
            };
        },
    };
}

export function createPageController(config: PageControllerConfig = {}): PageController {
    return {
        useProps(context, props): PageViewProps {
            return {
                id: props.item.id,
                getHeaderProps() {
                    return {
                        title: props.item.title ?? props.item.id,
                        description: props.item.description ?? null,
                        breadcrumbs: props.item.breadcrumbs,
                    };
                },
                getStatusProps(): PageStatusProps | null {
                    if (props.status?.type === 'readonly') {
                        return {
                            isDisabled: true,
                            description: props.status.description,
                            error: 'You do not have permissions for this action',
                        };
                    }
                    return null;
                },
                getAlertProps() {
                    if (props.status?.type === 'readonly') {
                        return {
                            items: [
                                {
                                    id: props.item.id,
                                    status: props.status.status,
                                    label: props.status.description,
                                    action: null,
                                    onDismiss: null,
                                },
                            ],
                        };
                    }
                    return {
                        items: [],
                    };
                },
                getBreadcrumbProps() {
                    return {
                        items: props.item.breadcrumbs,
                    };
                },
            };
        },
    };
}
