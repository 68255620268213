import { ReportItemAggregate } from '../../item';
import { buildDependencyItemProps } from '../item';
import { StudyDependencyListProps } from './reportDependencyListProps';

export function buildDependencyListProps(
    item: ReportItemAggregate
): StudyDependencyListProps {
    const items = item.report.dependencies.flatMap((dep) => {
        const itemProps = buildDependencyItemProps(item, dep);
        if (!itemProps) {
            return [];
        }
        return itemProps;
    });
    const fulfilledCount = items.filter((item) => item.isFulfilled).length;
    const totalCount = items.length;
    return {
        status: {
            label: `${fulfilledCount}/${totalCount} fulfilled`,
            isFulfilled: fulfilledCount === totalCount,
        },
        items,
    };
}
