import React from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { createOptionListController, createSelectController } from '../../view/common';
import { MetricItemViewProps } from '../../view';
import { createNetworkContextProvider } from './base';
import { NetworkRouterConfig } from './networkConfig';
import {
    createNetworkOverviewController,
    createNetworkOverviewLoader,
    createNetworkOverviewRoute,
    createNetworkOverviewRouteView,
} from './overview';
import {
    createNetworkRequestNewController,
    createNetworkRequestNewDepsProvider,
    createNetworkRequestNewLoader,
    createNetworkRequestNewRoute,
    createNetworkRequestNewRouteView,
    createNetworkRequestNewService,
} from './request';
import {
    createNetworkLayoutController,
    createNetworkLayoutRoute,
    createNetworkLayoutView,
} from './layout';

export function createNetworkRouter(config: NetworkRouterConfig): JSX.Element {
    const {
        provider: { createLayoutController, createOnboardingController },
    } = config;

    const controller = {
        layout: createLayoutController({
            items: [
                {
                    id: 'overview',
                    title: 'Overview',
                    path: `${config.mount}/overview`,
                },
                {
                    id: 'requests',
                    title: 'Requests',
                    path: `${config.mount}/requests`,
                },
            ],
        }),
    };

    const contexts = createNetworkContextProvider(config);

    const Layout = createNetworkLayoutRoute(
        contexts,
        createNetworkLayoutController({
            layout: controller.layout,
        }),
        createNetworkLayoutView(config)
    );

    const Overview = createNetworkOverviewRoute(
        contexts,
        createNetworkOverviewController(config, {
            onboarding: createOnboardingController({
                getStatus(item) {
                    // const key = 'vs_expert_calls_onboarded';
                    // const status = window.localStorage.getItem(key);
                    // if (status) {
                    //     return 'complete';
                    // }
                    // window.localStorage.setItem(key, 'true');
                    return 'intro';
                },
            }),
        }),
        createNetworkOverviewLoader({
            repository: config.repository,
        }),
        createNetworkOverviewRouteView(config)
    );

    const Request = {
        New: createNetworkRequestNewRoute(
            contexts,
            createNetworkRequestNewDepsProvider(config),
            createNetworkRequestNewController(config, createNetworkRequestNewService(), {
                select: createSelectController<MetricItemViewProps>({
                    getSearchTokens(item) {
                        return [item.plugin.label, item.label];
                    },
                    getItemProps(item) {
                        return {
                            icon: {
                                kind: 'image',
                                src: item.image.src,
                            },
                            value: item.id,
                            label: item.label,
                            description: null,
                        };
                    },
                }),
                option: createOptionListController({ id: 'network_request_option' }),
            }),
            createNetworkRequestNewLoader({
                repository: config.repository,
            }),
            createNetworkRequestNewRouteView(config)
        ),
    };

    return (
        <Route
            path={config.mount}
            element={
                <Layout>
                    <Outlet />
                </Layout>
            }
        >
            <Route index={true} element={<Navigate to="overview" replace={true} />} />
            <Route path="overview" element={<Overview />} />
            <Route path="requests">
                <Route index={true} element={<Navigate to="new" replace={true} />} />
                <Route path="new" element={<Request.New />} />
            </Route>
        </Route>
    );
}
