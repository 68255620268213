import { ExecFilterOperator } from 'src/models/DataSet';
import { z } from 'zod';

export const PreferenceSectionTypeSchema = z.enum([
    'trait_filters',
    'data_filters',
    'segment_ranking',
]);

export const PreferenceSubjectTypeSchema = z.enum([
    'dashboard',
    'asset',
    'org',
    'plugin',
]);

export const PreferenceScopeSchema = z.object({
    subject_id: z.string(),
    subject_type: PreferenceSubjectTypeSchema,
});

export function PreferenceSchema<
    TType extends z.ZodLiteral<any>,
    TConf extends z.ZodObject<any>
>(type: TType, section: TConf) {
    return z.object({
        id: z.number(),
        section,
        section_type: type,
        scopes: z.array(PreferenceScopeSchema),
        is_active: z.boolean(),
        created_at: z.date({ coerce: true }),
        modified_at: z.date({ coerce: true }),
    });
}

export const MetadataSingleRuleSchema = z.object({
    operator: z.enum([
        'eq',
        'neq',
        'gt',
        'gte',
        'lt',
        'lte',
        'between',
        'in',
        'nin',
        'empty',
        'not_empty',
        'current',
        'previous',
        'next',
        'before',
        'after',
        'on',
        'contains',
        'rgx',
        'startsw',
        'nstartsw',
    ]),
    metadata_definition_key: z.string(),
    value: z.union([z.string(), z.number(), z.array(z.union([z.string(), z.number()]))]),
});

export const MetadataMultiRuleSchema = z.object({
    operator: z.enum(['and', 'or']),
    sub_rules: z.array(MetadataSingleRuleSchema),
});

export const PreferenceTraitFiltersSchema = PreferenceSchema(
    z.literal('trait_filters'),
    z.object({
        rules: z.union([MetadataMultiRuleSchema, MetadataSingleRuleSchema]),
        is_customized: z.boolean(),
    })
);

export const PreferenceDataFiltersSchema = PreferenceSchema(
    z.literal('data_filters'),
    z.object({
        filters: z.array(z.any()),
        date_range: z.any(),
        granularity: z.any(),
    })
);

export const PreferenceQuerySegmentsSchema = PreferenceSchema(
    z.literal('query_segments'),
    z.object({
        mode: z.enum(['fixed', 'dynamic']),
        fixed: z.array(z.any()),
        dynamic: z.array(z.any()),
    })
);

export const PreferenceUnknownSegmentSchema = PreferenceSchema(
    z.literal('unknown'),
    z.object({})
);

export const AnyPreferenceSchema = z.union([
    PreferenceTraitFiltersSchema,
    PreferenceDataFiltersSchema,
    PreferenceQuerySegmentsSchema,
    z.unknown().transform((value) => {
        return {
            kind: 'unknown',
            value: null,
        } as const;
    }),
]);

// note this endpoint is not wrapped in a data property container
export const PreferenceListResponseSchema = z.array(AnyPreferenceSchema);

// Match

export function PreferenceMatchSchema<
    TType extends z.ZodLiteral<any>,
    TConf extends z.ZodObject<any>
>(type: TType, section: TConf) {
    return z.object({
        section_type: type,
        value: section,
    });
}

export const PreferenceTraitFiltersSchemaMatch = PreferenceMatchSchema(
    z.literal('trait_filters'),
    z.object({
        rules: z.union([MetadataMultiRuleSchema, MetadataSingleRuleSchema]),
        is_customized: z.boolean(),
    })
);

export const PreferenceDataFiltersSchemaMatch = PreferenceMatchSchema(
    z.literal('data_filters'),
    z.object({
        filters: z.array(z.any()),
        date_range: z.any(),
        granularity: z.any(),
    })
);

export const PreferenceQuerySegmentsSchemaMatch = PreferenceMatchSchema(
    z.literal('query_segments'),
    z.object({
        mode: z.enum(['fixed', 'dynamic']),
        fixed: z.array(z.any()),
        dynamic: z.array(z.any()),
    })
);

export const AnyPreferenceMatchSchema = z.union([
    PreferenceTraitFiltersSchemaMatch,
    PreferenceDataFiltersSchemaMatch,
    PreferenceQuerySegmentsSchemaMatch,
    z.unknown().transform((value) => {
        return {
            kind: 'unknown',
            value: null,
        } as const;
    }),
]);

export const PreferenceMatchResponseSchema = z.object({
    context: z.array(z.tuple([PreferenceSubjectTypeSchema, z.string()])),
    sections: z.array(AnyPreferenceMatchSchema),
});

export const PreferenceMatchListResponseSchema = z.array(PreferenceMatchResponseSchema);
