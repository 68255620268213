import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse, isDomainErrorDto } from '../../common';
import { ReportV2Resource } from './reportInterface';

export function createReportV2Resource(client: AxiosInstance): ReportV2Resource {
    return {
        async list(context, query, { signal, ...options } = {}) {
            throw new Error('not impl');
        },
        async get(context, id) {
            throw new Error('not impl');
        },
    };
}
