import { AiOutlineReload } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { ServiceUnavailableError } from '../../base';
import { ErrorController } from './errorInterface';
import { ErrorViewProps } from './errorProps';
import { ErrorControllerConfig } from './errorConfig';

export function createErrorController(config: ErrorControllerConfig): ErrorController {
    return {
        useProps(props): ErrorViewProps {
            const isTemporaryError = props.info.error instanceof ServiceUnavailableError;
            if (isTemporaryError) {
                return {
                    error: {
                        title: props.info.error.message,
                        hint: 'Please try again later',
                    },
                    retryable: true,
                    action: {
                        label: 'Refresh page',
                        Icon: AiOutlineReload,
                        button: {
                            onClick() {
                                props.navigate(0);
                            },
                        },
                    },
                    link: {
                        home: { to: '/u' },
                    },
                };
            }
            return {
                error: {
                    title: 'Something went wrong',
                    hint: `If this issue continues, please contact support`,
                },
                retryable: false,
                action: {
                    label: 'Go back',
                    Icon: BsArrowLeft,
                    button: {
                        onClick() {
                            props.navigate(
                                // @ts-expect-error
                                -1,
                                { replace: true }
                            );
                        },
                    },
                },
                link: {
                    home: { to: '/u' },
                },
            };
        },
    };
}
