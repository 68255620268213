import { useStore } from '../../stores/setupContext';
import { useTrackerAdapter, ApplicationContext } from '../../base';
import { useDialogAdapter, useAuthenticatedApi, useAccessAdapter } from '../../infra';
import { urlConfig } from '../../config';
import { FormatterImpl } from '../../hooks';
import { useSubscription, useFeatures } from '../../domain';

export const useAuthenticatedContext = (): ApplicationContext => {
    const { auth } = useStore();
    const organization = auth.loggedInOrganization ?? null;
    const api = useAuthenticatedApi();
    const tracker = useTrackerAdapter(auth);
    const dialog = useDialogAdapter();
    const access = useAccessAdapter({
        tracker,
    });
    // todo: call subscription hook from a dedicated context provider
    const subscription = useSubscription({ api, organization });
    // const features = useFeatures({ api, subscription, organization });

    return {
        api,
        tracker,
        dialog,
        access,
        formatter: new FormatterImpl(),
        subscription,
        // features,
        organization,
        environment: {
            kind: process.env.NODE_ENV,
        },
        config: {
            teams: {
                addTeamMemberUrl: urlConfig.addTeamMemberHref,
            },
            asset: {
                addBrandUrl: urlConfig.addBrandHref,
            },
        },
    };
};
