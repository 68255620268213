import { createDomainLayoutInfoView } from './info';
import { createDomainLayoutListView } from './list';
import { createDomainLayoutCollectionView } from './collection';
import { DomainLayoutConfig } from './domainLayoutConfig';
import { DomainLayoutInterface } from './domainLayoutInterface';
import { createDomainLayoutView } from './domainLayoutView';
import { createDomainLayoutContainerView } from './container';

export function createDomainLayout(
    config: Pick<DomainLayoutConfig, 'UI'>
): DomainLayoutInterface {
    return {
        Shell: createDomainLayoutView(config),
        View: {
            Container: createDomainLayoutContainerView(),
            Collection: createDomainLayoutCollectionView(),
            List: createDomainLayoutListView(),
            Info: createDomainLayoutInfoView(),
        },
    };
}
