import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getIntegrationById } from '../../api/integrations';
import { Integration } from 'src/models';

export const useIntegration = (api: AxiosInstance, integId?: number | string) => {
    const query = useQuery<Integration | undefined>(
        ['integs', integId],
        () => {
            if (integId) {
                return getIntegrationById(api, integId);
            }
        },
        { suspense: false, enabled: !!integId }
    );
    return query;
};
