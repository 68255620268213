import React from 'react';
import { FormContainerController } from './formContainerInterface';
import {
    FormContainerContainerProps,
    FormContainerViewProps,
} from './formContainerProps';

export function createFormContainerContainer(
    controller: FormContainerController,
    View: React.FC<{ children?: React.ReactNode | undefined } & FormContainerViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & FormContainerContainerProps> {
    return (containerProps) => {
        const props = controller.useProps({});
        return <View {...props}>{containerProps.children}</View>;
    };
}
