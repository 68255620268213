import React, { Suspense, SuspenseProps, useEffect, useRef, useState } from 'react';
import {
    VStack,
    Text,
    Box,
    HStack,
    GridProps,
    GridItemProps,
    Grid,
    GridItem,
    Popover,
    PopoverTrigger,
    Button,
    PopoverBody,
    IconButton,
    PopoverContent,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { getElements, fetchElements, getAllChildrenProps } from '../../../../util';
import { VAROS_GREEN } from '../../../../config';
import { WorkspaceLayoutNavigation } from './WorkspaceLayoutNavigation';
import { WorkspaceLayoutMain } from './WorkspaceLayoutBody';
import { WorkspaceLayoutLoader } from './WorkspaceLayoutLoader';
import { WorkspaceLayoutToolbar } from './WorkspaceLayoutToolbar';
import {
    WorkspaceLayoutDropdown,
    WorkspaceLayoutDropdownProps,
} from './WorkspaceLayoutCommon';

export interface WorkspaceLayoutStyle {
    wrapper: Omit<GridProps, 'children' | 'templateAreas'>;
    navigation: Omit<GridItemProps, 'children' | 'area'>;
    main: Omit<GridItemProps, 'children' | 'area'>;
}

export interface WorkspaceLayoutWrapperProps {
    style?: Partial<WorkspaceLayoutStyle>;
    children: React.ReactElement[];
}

export const WorkspaceLayoutWrapper: React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceLayoutWrapperProps
> = (props) => {
    const { Navigation, Main, Toolbar } = fetchElements(props.children, {
        Navigation: WorkspaceLayoutNavigation,
        Toolbar: WorkspaceLayoutToolbar,
        Main: WorkspaceLayoutMain,
    });
    const { Loader } = getElements(props.children, {
        Loader: WorkspaceLayoutLoader,
    });
    const { dropdown: dropdownPropsList } = getAllChildrenProps(Toolbar.props.children, {
        dropdown: WorkspaceLayoutDropdown,
    });

    return (
        <Grid
            {...props.style?.wrapper}
            templateAreas={`
                "navigation"
                "main"
            `}
            h="100%"
        >
            <GridItem area="navigation" {...props.style?.navigation}>
                <HStack alignItems="center" h="full">
                    <Box flex={1}>{Navigation}</Box>
                    <Box flexShrink={0} px={4}>
                        {dropdownPropsList.map(({ ...item }, index) => (
                            <WorkspaceItemContainer key={index} {...item} />
                        ))}
                    </Box>
                </HStack>
            </GridItem>
            <GridItem area="main" {...props.style?.main}>
                {Loader ? <Suspense fallback={Loader}>{Main}</Suspense> : Main}
            </GridItem>
        </Grid>
    );
};

const WorkspaceItemContainer: React.FC<
    { children?: React.ReactNode | undefined } & WorkspaceLayoutDropdownProps
> = (props) => {
    const viewProps = props.useProps(props);
    if (viewProps.enabled === false) {
        return <></>;
    }
    const { Icon, onClick } = viewProps;
    return (
        <Popover placement="bottom-start" onOpen={viewProps.onOpen}>
            <PopoverTrigger>
                <Button
                    onClick={onClick}
                    css={css`
                        position: relative !important;
                    `}
                    // bg="whiteAlpha.300"
                    bg="transparent"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _expanded={{ bg: 'whiteAlpha.200' }}
                    // borderColor="whiteAlpha.500"
                    color="whiteAlpha.500"
                    borderWidth="2px"
                    borderStyle="solid"
                    _focus={{ outline: 0 }}
                    variant="ghost"
                    size="sm"
                    aria-label={
                        typeof viewProps.label === 'string' ? viewProps.label : ''
                    }
                    rightIcon={
                        <>
                            {/* <Icon fontSize={22} /> */}
                            <Icon fontSize={20} />
                            {viewProps.badge ? (
                                <Box
                                    borderWidth={3}
                                    borderColor="#151515"
                                    borderStyle="solid"
                                    as="span"
                                    color="white"
                                    position="absolute"
                                    top={-2}
                                    right={0}
                                    // fontSize="sm"
                                    fontSize={12}
                                    // bgColor="green.500"
                                    bgColor={VAROS_GREEN[200]}
                                    borderRadius="full"
                                    zIndex={9999}
                                    px={1}
                                >
                                    {viewProps.badge.children}
                                </Box>
                            ) : null}
                        </>
                    }
                    fontWeight="semibold"
                    // fontSize="xs"
                    letterSpacing="wide"
                    // textTransform="uppercase"
                >
                    {viewProps.name}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                minW="400px"
                bg="#292929"
                _focus={{ outline: 'none' }}
                {...props.style?.content}
            >
                <PopoverBody p={0} position="relative">
                    <VStack alignItems="flex-start" spacing={0} position="sticky" top={0}>
                        <Box
                            px={4}
                            py={2}
                            w="full"
                            borderBottomWidth="1px"
                            borderStyle="solid"
                            borderColor="whiteAlpha.300"
                        >
                            <Text
                            // textTransform="uppercase"
                            // fontSize="xs"
                            // fontWeight="semibold"
                            // letterSpacing="wider"
                            // color="whiteAlpha.700"
                            >
                                {viewProps.label}
                            </Text>
                        </Box>
                        <Box w="full" {...props.style?.body}>
                            {props.render(viewProps.props)}
                        </Box>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
