import React, { useState } from 'react';
import {
    Box,
    ButtonGroup,
    Text,
    VStack,
    Button as ChakraButton,
    Heading,
    Icon,
    HStack,
    Tooltip,
    StackDivider,
    Center,
} from '@chakra-ui/react';
import { CardSpinner } from '../../../../domain';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingPeerGroupViewProps } from './onboardingPeerGroupProps';
import { PluginItemControllerProps } from './plugin';
import { WarningIcon } from '@chakra-ui/icons';
import { AiOutlineArrowRight } from 'react-icons/ai';

export function createOnboardingPeerGroupView(
    config: OnboardingStrategyConfig,
    View: {
        Plugin: React.FC<
            { children?: React.ReactNode | undefined } & PluginItemControllerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & OnboardingPeerGroupViewProps> {
    const {
        Layout: { Container, Content, Button },
    } = config;
    return (props) => {
        if (props.isLoading) {
            return (
                <Center>
                    <CardSpinner />
                </Center>
            );
        }
        if (props.emptyState) {
            return (
                <Container
                    title="Build your peer groups"
                    description={
                        <Text>
                            Set the filters for each integration to compare yourself
                            against companies like yours (not all companies have the same
                            integrations and thus the need to set separate peer group
                            filters for each one)
                        </Text>
                    }
                >
                    <Content isLoading={true}>
                        <VStack
                            spacing={3}
                            bg="gray.100"
                            p={12}
                            w="full"
                            borderRadius="lg"
                        >
                            <Box p={3} bg="gray.200" borderRadius="md" flexShrink={0}>
                                <Icon fontSize="2xl" as={WarningIcon} />
                            </Box>
                            <Heading fontSize="lg">{props.emptyState.title}</Heading>
                            <Text color="blackAlpha.700" fontSize="md">
                                {props.emptyState.description}
                            </Text>
                        </VStack>
                    </Content>
                </Container>
            );
        }
        return (
            <Container
                title="Build your peer groups"
                description={
                    <Text>
                        Set the filters for each integration to compare yourself against
                        companies like yours (not all companies have the same integrations
                        and thus the need to set separate peer group filters for each one)
                    </Text>
                }
                rightContent={
                    <HStack align="center">
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            isDisabled={props.isComplete}
                            label={
                                <Box p={2}>
                                    <Text>
                                        Configure each peer group below to continue
                                    </Text>
                                </Box>
                            }
                        >
                            <Box as="span">
                                <ChakraButton
                                    colorScheme="green"
                                    rightIcon={
                                        // props.onBack ? undefined : (
                                        //     <Icon as={AiOutlineArrowRight} />
                                        // )
                                        <Icon as={AiOutlineArrowRight} />
                                    }
                                    onClick={(event) => props.onContinue?.({})}
                                    isDisabled={!props.isComplete}
                                >
                                    {/* hack to show show correct text during onboarding flow where there is no back button */}
                                    {/* {props.onBack ? 'Complete' : 'Continue'} */}
                                    Continue
                                </ChakraButton>
                            </Box>
                        </Tooltip>
                    </HStack>
                }
            >
                <Content isLoading={true}>
                    <VStack
                        w="full"
                        spacing={3}
                        divider={<StackDivider borderColor="gray.300" />}
                    >
                        <HStack w="full" justify="end">
                            <Text
                                letterSpacing="wide"
                                fontWeight="bold"
                                color="gray.500"
                                textTransform="uppercase"
                                fontSize="sm"
                            >
                                {props.getStatusText()}
                            </Text>
                        </HStack>
                        <VStack spacing={3} w="full">
                            {props.items.map((item, index) => {
                                const isVisible = props.isVisible(item, index);
                                const itemProps = props.getPluginItemProps(item, index);
                                return (
                                    <Box
                                        key={item.plugin.id}
                                        w="full"
                                        display={isVisible ? undefined : 'none'}
                                    >
                                        <View.Plugin {...itemProps} />
                                    </Box>
                                );
                            })}
                        </VStack>
                    </VStack>
                </Content>
            </Container>
        );
    };
}
