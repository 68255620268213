import React from 'react';
import { WorkspaceContextData, WorkspaceContextResult } from './workspaceModel';

export const WorkspaceContext = React.createContext<WorkspaceContextResult | null>(null);

export function useWorkspaceContextV2<TExtra extends object>(): WorkspaceContextData &
    TExtra {
    const value = React.useContext(WorkspaceContext);
    if (!value) {
        throw new Error(`not inside workspace v2 context`);
    }
    if (!value.isSuccess) {
        throw new Error(`workspace context data not yet fetched`);
    }
    // @ts-expect-error
    return value.data;
}

export function useWorkspaceContextDeferredV2<
    TExtra extends object,
>(): WorkspaceContextData & TExtra {
    const value = React.useContext(WorkspaceContext);
    if (!value) {
        throw new Error(`not inside workspace v2 context`);
    }
    // @ts-expect-error
    return value.data;
}
