import React from 'react';
import {
    Image,
    List,
    Text,
    Box,
    BoxProps,
    Heading,
    VStack,
    ListItem,
    StackProps,
    SimpleGrid as Grid,
    GridItem,
    useToken,
    HStack,
    Flex,
    Button,
    HeadingProps,
    TextProps,
    IconButton,
} from '@chakra-ui/react';
import { AnyAccessRequest } from '../../../base';
import { FeatureHighlight } from '../../feature';
import checkmarkSVG from '../../../svg/billing-check-icon.svg';
import { CloseIcon } from '@chakra-ui/icons';

export interface AccessCardActionProps {
    variant: 'primary' | 'secondary';
    text: string;
    onClick(): void;
}

export interface AccessCardGraphicProps {
    src: string;
}

export interface AccessCardProps {
    layout?: {
        header?: Pick<HeadingProps, 'fontSize'>;
        tagline?: Pick<HeadingProps, 'fontSize'>;
        base?: Pick<TextProps, 'fontSize'>;
        container?: BoxProps;
        list?: Pick<StackProps, 'spacing'>;
        item?: Pick<BoxProps, 'p'>;
        actions?: Pick<StackProps, 'spacing'>;
    };
    request: AnyAccessRequest & {
        tagline?: string;
        highlights?: FeatureHighlight[];
        graphics?: AccessCardGraphicProps[];
    };
    actions: AccessCardActionProps[];
    onClear?(): void;
}

export const AccessCard: React.FC<
    { children?: React.ReactNode | undefined } & AccessCardProps
> = ({ layout = {}, ...props }) => {
    const {
        header: headerProps = {},
        tagline: taglineProps = {},
        base: baseFontProps = {},
        container: containerProps = {},
        list: listProps = {},
        item: itemProps = {},
        actions: actionsProps = {},
    } = layout;
    const gradient = useToken('backgrounds', 'varosGradient');

    const primaryActions = props.actions.filter((action) => action.variant === 'primary');
    const secondaryActions = props.actions.filter(
        (action) => action.variant === 'secondary'
    );

    const [graphic1, graphic2, ...rest] = (props.request.graphics ?? []) as Array<
        undefined | AccessCardGraphicProps
    >;
    if (rest.length > 0) {
        console.warn(
            `access card supports at most two graphics but received ${props.request.graphics?.length}`
        );
    }
    return (
        <Box
            position="relative"
            border="1px solid"
            borderColor="whiteAlpha.500"
            {...containerProps}
        >
            {props.onClear && (
                <Box position="absolute" right={4} top={4} zIndex={100}>
                    <IconButton
                        bg="transparent"
                        aria-label="close"
                        onClick={props.onClear}
                        icon={<CloseIcon />}
                    />
                </Box>
            )}
            <Grid
                columns={{
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 2,
                }}
            >
                <GridItem
                    colSpan={1}
                    pl={itemProps.p}
                    pt={itemProps.p}
                    pb={itemProps.p}
                    pr={
                        itemProps.p
                            ? [
                                  itemProps.p,
                                  itemProps.p,
                                  itemProps.p,
                                  itemProps.p,
                                  itemProps.p,
                                  0 as any,
                              ]
                            : undefined
                    }
                >
                    <VStack {...listProps} alignItems="flex-start">
                        <Box>
                            <Heading {...headerProps}>{props.request.name}</Heading>
                            {props.request.tagline && (
                                <Text
                                    fontWeight="medium"
                                    color="whiteAlpha.800"
                                    {...taglineProps}
                                >
                                    {props.request.tagline}
                                </Text>
                            )}
                        </Box>
                        {props.request.highlights && (
                            <>
                                <Box
                                    borderRadius="full"
                                    height={1}
                                    w="100%"
                                    // bg={gradient}
                                    bg="linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)"
                                    // bg="whiteAlpha.500"
                                />
                                <List spacing={8}>
                                    {props.request.highlights.map((highlight, index) => (
                                        <ListItem key={index}>
                                            <HStack alignItems="flex-start" spacing={4}>
                                                <Image src={checkmarkSVG} />
                                                <Text
                                                    fontSize={baseFontProps.fontSize}
                                                    color="whiteAlpha.800"
                                                >
                                                    {highlight.text}
                                                </Text>
                                            </HStack>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        <VStack
                            alignItems="center"
                            justifyItems="center"
                            w="full"
                            spacing={actionsProps.spacing}
                        >
                            <Flex alignItems="center" justifyItems="center" w="full">
                                <HStack
                                    display="flex"
                                    w="full"
                                    maxWidth={'80%'}
                                    mx="auto"
                                    spacing={actionsProps.spacing}
                                >
                                    {primaryActions.map((action) => (
                                        <Button
                                            key={action.text}
                                            onClick={action.onClick}
                                            flex={1}
                                            size="lg"
                                            variant="solid"
                                            colorScheme="varosGradient"
                                        >
                                            {action.text}
                                        </Button>
                                    ))}
                                </HStack>
                            </Flex>
                            {secondaryActions.length > 0 ? (
                                <HStack>
                                    {secondaryActions.map((action) => (
                                        <Button
                                            key={action.text}
                                            variant="link"
                                            colorScheme="varosGradient"
                                            onClick={action.onClick}
                                        >
                                            {action.text}
                                        </Button>
                                    ))}
                                </HStack>
                            ) : null}
                        </VStack>
                    </VStack>
                </GridItem>
                {graphic1 && (
                    <GridItem colSpan={[1, 1, 1, 1, 1, 1]}>
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                            overflow="hidden"
                            maxHeight="80vh"
                            borderRadius={containerProps.borderRadius}
                        >
                            <Image src={graphic1.src} />
                            {graphic2 && (
                                <Image
                                    position="absolute"
                                    top={24}
                                    left={24}
                                    right={-24}
                                    bottom={-24}
                                    src={graphic2.src}
                                />
                            )}
                            <Box
                                // zIndex={10}
                                position="absolute"
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                bgGradient="linear(90deg, blackAlpha.100, blackAlpha.900)"
                            />
                        </Box>
                    </GridItem>
                )}
            </Grid>
        </Box>
    );
};
