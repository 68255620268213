import { pluralize } from '../../utils/strings';
import { Asset } from '../../api';
import {
    UserIntegrationDefinition,
    AnyIntegrationStatus,
    DashboardIntegration,
    BasicDashboardDepReason,
} from './integrationType';

export function getDashboardIntegrationStatus(
    asset: Pick<Asset, 'id'>,
    integration: DashboardIntegration
): AnyIntegrationStatus {
    const integrationCount = integration.integration.integrations.filter(
        (integration) => integration.assets.includes(asset.id) && integration.is_connected
    ).length;
    const noun = pluralize('account', integrationCount);

    const title = integrationCount > 0 ? `${integrationCount} ${noun} connected` : null;
    const text = integration.errorReason?.text ?? null;
    const hint = integration.errorReason?.text ? integration.errorReason.instructions.join('\n') : null;

    if (integration.errorReason?.isLoading) {
        return {
            kind: 'pending',
            description: integration.errorReason.text,
            title,
            text: null,
            hint: null,
        };
    }

    if (integration.isFulfilled) {
        return {
            kind: 'connected',
            description: 'Integration is connected for this brand',
            title,
            text: null,
            hint: null,
        };
    }

    if (integration.allowIgnore && integration.isIgnored) {
        return {
            kind: 'ignored',
            description: null,
            title,
            text: text,
            hint,
        };
    }

    return {
        kind: 'error',
        description: null,
        title,
        text: text,
        hint,
    };
}

export function getOrganizationIntegrationStatus(definition: UserIntegrationDefinition): AnyIntegrationStatus {
    const connectedCount = definition.integrations.filter((integration) => integration.is_connected).length ?? 0;
    const noun = pluralize('integration', connectedCount);
    if (connectedCount > 0) {
        return {
            kind: 'connected',
            description: null,
            title: `${connectedCount} ${noun} connected`,
            text: null,
            hint: null,
        };
    }
    return { kind: 'pending', description: null, title: null, hint: null, text: null };
}

export function isAvailable(definition: Pick<UserIntegrationDefinition, 'is_available'>) {
    return definition.is_available;
}

export function isConnected(definition: Pick<UserIntegrationDefinition, 'counts'>) {
    return definition.counts && definition.counts.count_connected > 0;
}

export function isIntegrated(definition: Pick<UserIntegrationDefinition, 'integrations'>) {
    return definition.integrations.some((integration) => integration.is_connected);
}

export function isLive(definition: Pick<UserIntegrationDefinition, 'mode'>) {
    return definition.mode === 'live';
}

/**
 * Returns true if integration should be hidden from the sidebar
 * @param definition
 * @returns
 */
export function isHidden(definition: UserIntegrationDefinition) {
    // return definition.is_ignored && !isIntegrated(definition);
    return !isIntegrated(definition);
}
