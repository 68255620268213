import { AnyMappableEntity } from '../entity';
import { Integration } from '../integration';
import { IntegrationDefinitionStatus } from './definitionEnum';
import { IntegrationDefinition } from './definitionModel';

export function getDefinitionStatus(
    definition: IntegrationDefinition,
    entities: AnyMappableEntity[],
    integrations: Integration[]
): IntegrationDefinitionStatus {
    if (integrations.length === 0) {
        return 'pending';
    }
    if (integrations.every((item) => item.status === 'disconnected')) {
        return 'pending';
    }
    if (integrations.every((item) => item.status === 'analyzing')) {
        return 'analyzing';
    }
    return 'ready';

    // if (entities.length > 0) {
    //     return 'mappable';
    // }
    // if (integrations.length === 0) {
    //     return 'pending';
    // }
    // if (
    //     integrations
    //         .filter((item) => item.definitionId === definition.id)
    //         .some((item) => item.status === 'ready')
    // ) {
    //     return 'ready';
    // }
    // if (entities.length === 0) {
    //     // we can't currently distinguish between empty and syncing state
    //     // return 'empty';
    //     return 'syncing';
    // }
    // if (
    //     integrations
    //         .filter((item) => item.definitionId === definition.id)
    //         .every((item) => item.status === 'syncing')
    // ) {
    //     return 'syncing';
    // }
    // return 'ready';
}
