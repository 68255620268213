import {
    AgencyCompensationReport,
    AIInitiatives,
    CreativeStrategyReport,
    DemographicsKPIReport,
    HexExampleReport,
    TopCompaniesReport,
} from './fixture';

export const ReportDefinitions = {
    AGENCY: AgencyCompensationReport,
    CREATIVE: CreativeStrategyReport,
    HEX_EXAMPLE: HexExampleReport,
    TOP_COMPANIES: TopCompaniesReport,
    AI_INITIATIVES: AIInitiatives,
    DEMOGRAPHICS: DemographicsKPIReport,
};
