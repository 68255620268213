import React from 'react';
import { FeatureMiddleware } from '../featureInterface';

export function createFeatureGroupMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        return create({
            ...config,
            loader: {
                ...config.loader,
                groups: {
                    create(loaderConf) {
                        const underlying = config.loader.groups.create(loaderConf);

                        return {
                            useLoad(scope) {
                                const data = underlying.useLoad(scope);
                                const { feature } = api.useFeature('private_groups');

                                return {
                                    ...data,
                                    allowExtendedVersion: !!feature?.enabled,
                                };
                            },
                        };
                    },
                },
            },
            //             controller: {
            //                 ...config.controller,
            //                 groups: {
            //                     ...config.controller.groups,
            //                     createActivated() {
            //                         const underlying = config.controller.groups.createActivated();
            //                         return {
            //                             useProps(data) {
            //                                 const { feature, restrict } = api.useFeature('private_groups');
            //                                 const props = underlying.useProps(data);

            //                                 React.useEffect(() => {
            //                                     if(!feature?.enabled) {
            //                                         restrict({...feature!, value: null})
            //                                     }
            //                                 }, [feature?.enabled]);

            //                                 return {
            //                                     ...props,
            //                                     onClick: feature?.enabled ? undefined : (e) => {
            //                                         e.preventDefault();
            //                                         e.stopPropagation();
            // ;                                       restrict({...feature!, value: null});
            //                                     }
            //                                 };
            //                             },
            //                         }
            //                     }
            //                 }
            //             }
        });
    };
}
