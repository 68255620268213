import { ColorToken } from '@varos/chakra-charts';
import { ReportChartCategoryController } from './categoryChartInterface';
import { ReportChartCategoryViewProps } from './categoryChartProps';
import { ReportControllerBaseConfig } from '../../../base';

export function createReportCategoryChartController(
    config: ReportControllerBaseConfig
): ReportChartCategoryController {
    return {
        useProps(context, item, props): ReportChartCategoryViewProps {
            const scalar = item.visualization.data.axes.find(
                (candidate) => candidate.type === 'scalar'
            );
            return {
                chart: {
                    legend: {
                        // when showing multiple datasets we use faceted chart with tabs instead
                        // of a legend
                        display: false,
                    },
                    root: {
                        type: item.visualization.style.type as 'bar',
                        datasets: item.visualization.data.series.map((series, index) => ({
                            key: series.key,
                            name: series.label,
                            description: null,
                            data: series.values,
                            color: config.theme.colors[
                                index % config.theme.colors.length
                            ],
                        })),
                        series: {
                            getSegment(props, index) {
                                return {};
                            },
                        },
                        labels: item.visualization.data.labels.flatMap((item) =>
                            item.kind === 'category'
                                ? [{ text: item.display_name ?? item.key }]
                                : []
                        ),
                    },
                    scalarAxis: {
                        startAtZero: true,
                        unit: null,
                        // @ts-expect-error
                        format: scalar?.format ?? null,
                        label: scalar?.label,
                    },
                },
            };
        },
    };
}
