import { capitalize, chain, maxBy } from 'lodash';
import { Formatter } from '../../../../../hooks';
import { FEATURES_BY_PLAN } from '../../../../../config';
import { PlanId } from '../../../../../api';
import { assert } from '../../../../util/assert';
import {
    AnyBillingPlan,
    AnyPersona,
    BillingUsagePlan,
    getBillingPlanUnitDescription,
    getBillingPlanUnitLabel,
} from '../../../../domain/billing';
import {
    PlanSelectControllerProps,
    PlanSelectItemProps,
    PlanSelectViewProps,
} from './planListProps';
import { AnyPlanItem } from './planListModel';
import { SubscriptionEditUsageItemProps } from '../../subscription/edit/usage';

export function buildPlanSelectProps(
    formatter: Formatter,
    props: PlanSelectControllerProps
): PlanSelectViewProps {
    let acc: Array<AnyPersona & { plan: AnyBillingPlan }> = [];

    const free = props.item.plans.find((item) => item.amount === 0);

    const latest = chain(props.item.plans)
        .groupBy((plan) => (plan.amount === 0 ? 'free' : plan.type))
        .mapValues((plansOfType, type) => [type, maxBy(plansOfType, 'version')?.version])
        .flatMap(([planType, version]) => {
            return props.item.plans.filter(
                (item) => item.version === version && item.type === planType
            );
        })
        .value();

    const usage = latest
        .filter((candidate): candidate is BillingUsagePlan => candidate.kind === 'usage')
        .filter((canidate) => canidate.amount > 0);

    const fixed = latest.filter((candidate) => candidate.kind === 'fixed');

    const [usageSample] = usage;

    let acc1: AnyPlanItem[] = [];

    if (free) {
        acc1 = [
            ...acc1,
            {
                id: free.id,
                description: free.description,
                kind: 'fixed',
                name: 'Free',
                type: free.type,
                plan: {
                    id: free.id,
                },
                amount: 0,
                features: FEATURES_BY_PLAN[free.id as PlanId] ?? [],
            },
        ];
    }

    if (usageSample) {
        acc1 = [
            ...acc1,
            {
                id: usageSample.type,
                description: usageSample.description,
                kind: 'usage',
                type: usageSample.type,
                name: capitalize(usageSample.type),
                options: usage,
                amount: usageSample.amount,
                features: FEATURES_BY_PLAN[usageSample.id as PlanId] ?? [],
            },
        ];
    }

    acc1 = [
        ...acc1,
        ...fixed.map(
            (item): AnyPlanItem => ({
                id: item.id,
                description: item.description,
                kind: 'fixed',
                type: item.type,
                name: item.name,
                plan: {
                    id: item.id,
                },
                amount: item.amount,
                features: FEATURES_BY_PLAN[item.id as PlanId] ?? [],
            })
        ),
    ];

    const selected =
        acc1.find((item) =>
            item.kind === 'usage'
                ? item.options.some((candidate) => candidate.id === props.value)
                : props.value === item.id
        ) ?? null;

    return {
        items: acc1,
        getItemProps(item, index): PlanSelectItemProps {
            const mapped = props.item.plans
                // .filter((candidate) => candidate.amount > 0)
                .find((candidate) => {
                    if (candidate.kind === 'usage') {
                        return candidate.type === item.type;
                    }
                    return candidate.id === item.id;
                });
            assert(mapped, () => {
                return `${item.id} did not match any plan`;
            });

            // const priceLabel = formatter.currency(mapped.amount / 100, 'usd');
            const priceLabel = formatter.currency(item.amount / 100, 'usd');

            let featureLabel: string | null = null;

            const previous = acc1[index - 1];
            if (previous) {
                featureLabel = `Everything in ${previous.name} plus`;
            }

            return {
                name: item.name,
                description: item.description,
                price: {
                    label:
                        item.kind === 'usage'
                            ? `Starts at ${priceLabel}/mo`
                            : `${priceLabel}/mo`,
                },
                isSelected: selected?.id === item.id,
                isCurrent: props.item.subscription.plan === item.id,
                features: {
                    label: featureLabel,
                    items: item.features.map((item) => ({
                        label: item.text,
                    })),
                },
                onSelectClick() {
                    if (item.kind === 'usage') {
                        const [first] = item.options;
                        const current = item.options.find(
                            (candidate) => candidate.id === props.item.subscription.plan
                        );
                        const next = current ?? first;
                        return props.onChange(next.id);
                    }
                    props.onChange(item.id);
                },
            };
        },
        getUsageProps() {
            if (selected?.kind !== 'usage') {
                return null;
            }
            const [first] = selected.options;
            return {
                title: `Usage`,
                secondary: null,
                description: getBillingPlanUnitDescription(first),
                items: usage,
                getItemProps(item): SubscriptionEditUsageItemProps {
                    return {
                        label: getBillingPlanUnitLabel(formatter, item),
                        description: null,
                        isCurrent: props.item.subscription.plan === item.id,
                        isSelected: props.value === item.id,
                        onSelectClick() {
                            props.onChange(item.id);
                        },
                    };
                },
            };
        },
    };
}
