import { NetworkRequestNewService } from './networkRequestNewInterface';

export function createNetworkRequestNewService(): NetworkRequestNewService {
    return {
        async create(context, deps, props) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            console.log('Creating', props);
            return;
        },
    };
}
