import React, { useCallback, useRef } from 'react';
import { throttle } from 'lodash';

/**
 * Tracks debounced mouse position
 * @param config
 * @returns
 */
export const useMousePosition = (config: { debounceMs: number; leading?: boolean }) => {
    const isMounted = useRef(true);

    const [mousePosition, setMousePosition] = React.useState<{
        x: number;
        y: number;
    } | null>(null);

    const handleMouseEvent = useCallback(
        (event: MouseEvent) => {
            if (isMounted.current) {
                setMousePosition({ x: event.clientX, y: event.clientY });
            }
        },
        [isMounted.current]
    );

    const updateMousePosition = throttle(handleMouseEvent, config.debounceMs, {
        leading: config.leading,
    });

    React.useEffect(() => {
        window.addEventListener('mousemove', updateMousePosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
            updateMousePosition.cancel();
        };
    }, []);

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return mousePosition;
};
