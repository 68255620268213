import { AxiosInstance, AxiosResponse } from 'axios';
import {
    IntegrationPreference,
    UpdateIntegrationPreferencePayload,
} from '../../models/integration/preference/Preference';

export const listPreferences = async (
    api: AxiosInstance,
    asset?: number
): Promise<IntegrationPreference[]> => {
    const response = await api.get<unknown, AxiosResponse<IntegrationPreference[]>>(
        `/api/v1/integrations/preferences`,
        { params: { asset } }
    );
    return response.data;
};

export const updatePreference = async (
    api: AxiosInstance,
    definitionId: number,
    payload: UpdateIntegrationPreferencePayload
): Promise<IntegrationPreference> => {
    const response = await api.patch<unknown, AxiosResponse<IntegrationPreference>>(
        `/api/v1/integrations/preferences/${definitionId}`,
        payload
    );
    return response.data;
};
