import { OnboardingCompetitiveSetController } from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingCompetitiveSetStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingCompetitiveSetController
        ): OnboardingCompetitiveSetController {
            return {
                ...controller,
                useProps(context, item, props) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(context, item, {
                        ...props,
                        onSkip() {
                            const result = props.onSkip();
                            tracker.track('onboarding_competitive_set_skipped', {});
                            return result;
                        },
                        company: {
                            ...props.company,
                            form: {
                                ...props.company.form,
                                async onSubmit(values, ...args) {
                                    const response = await props.company.form.onSubmit(
                                        values,
                                        ...args
                                    );
                                    tracker.track(
                                        'onboarding_competitive_set_submitted',
                                        {
                                            competitive_set_company_ids: values.companies,
                                        }
                                    );
                                    return response;
                                },
                            },
                        },
                    });
                    return {
                        ...viewProps,
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createCompetitiveSet(...args) {
                        const controller =
                            config.controller.onboarding.createCompetitiveSet(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
