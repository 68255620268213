import {
    AnyCondition,
    AnyResolvedType,
    applyTypeToCondition,
    EnumMember,
    isInCondition,
    Property,
} from '../../attributes';
import { PluginEntity } from '../../platform';
import { PeerGroup } from './groupModel';

export function hasTreeMemberValue(
    membersByValue: Record<string, EnumMember | undefined>,
    group: Pick<PeerGroup, 'conditions'>
) {
    return group.conditions.some((condition) => {
        if (!isInCondition(condition)) {
            return false;
        }
        return condition.value.some((element) => {
            const member = membersByValue[element];
            return member && !member.isDisabled;
        });
    });
}

export function isCustomized(group: PeerGroup) {
    // return !group.default || group.conditions.length > 0;
    return !group.default;
}

export function isPeerGroupConfigured(group: PeerGroup) {
    // return !group.default || group.conditions.length > 0;
    return !group.default || group.conditions.length > 0;
}

export function isPeerGroupPending(group: PeerGroup) {
    return group.default && group.conditions.length === 0;
}

/**
 * Constraints the values of each condition to be valid according to available
 * enum members and disabled status
 * @param plugin
 * @param group
 * @returns
 */
export function getAppliedConditions(
    plugin: PluginEntity<AnyResolvedType>,
    group: PeerGroup
): AnyCondition[] {
    const traitBySource = plugin.traits.reduce(
        (acc, trait) => ({ ...acc, [trait.key]: trait }),
        {} as Record<string, Property<AnyResolvedType> | undefined>
    );
    // console.log('DEBUG plugin', plugin);
    return group.conditions.flatMap((condition) => {
        const trait = traitBySource[condition.key];
        if (!trait) {
            console.warn(
                `trait ${condition.key} not found in plugin ${plugin.id}`,
                traitBySource
            );
            return [];
        }
        if (trait.isDisabled) {
            console.info(`trait ${condition.key} is disabled, skipping conditions`);
            return [];
        }
        const constrained = applyTypeToCondition(condition, trait.type);
        return constrained ? [constrained] : [];
    });
}
