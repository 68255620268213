import { QueryClient } from '@tanstack/query-core';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { UpdateReportScheduleDto, CreateReportScheduleDto } from '../../../api';
import { NotificationSubscriptionConfig } from './scheduleConfig';
import { ReportScheduleService } from './scheduleInterface';

export function createScheduledReportService(
    init: NotificationSubscriptionConfig
): ReportScheduleService {
    const {
        api,
        hook: { useQuery, useMutation },
    } = init;

    const PREFIX = ['report', 'schedules'];

    function refresh(client: QueryClient) {
        client.cancelQueries({ queryKey: PREFIX });
        client.invalidateQueries({ queryKey: PREFIX });
        client.refetchQueries({ queryKey: PREFIX });
        // console.log('refreshing', PREFIX);
        return;
    }

    const instance: ReportScheduleService = {
        useList(context, workspaces) {
            const response = useQuery({
                queryKey: PREFIX,
                async queryFn({ signal }) {
                    const response = await api.schedules.list(
                        context,
                        {
                            assetIds: workspaces.map(
                                (workspace) => workspace.id as number
                            ),
                        },
                        { signal, expand: ['recipient.user'] }
                    );
                    return response.data;
                },
                suspense: true,
                // staleTime: Infinity,
            });
            return response.data ?? [];
        },
        useUpdate(context) {
            const client = useQueryClient();
            const mutationCreate = useMutation<void, null, CreateReportScheduleDto>({
                async mutationFn(payload) {
                    const response = await api.schedules.create(context, payload, {
                        expand: ['recipient.user'],
                    });
                    // console.log('created', response);
                    return;
                },
                onSuccess() {
                    refresh(client);
                },
            });
            const mutationUpdate = useMutation<
                void,
                null,
                [id: number, payload: UpdateReportScheduleDto]
            >({
                async mutationFn([id, payload]) {
                    const response = await api.schedules.update(context, id, payload, {
                        expand: ['recipient.user'],
                    });
                    // console.log('updated', response);
                    return;
                },
                onSuccess() {
                    refresh(client);
                },
            });
            return (subscription, payload) => {
                if (!subscription.id) {
                    return mutationCreate.mutateAsync({
                        asset: subscription.asset,
                        template: subscription.key,
                    });
                }
                return mutationUpdate.mutateAsync([subscription.id, payload], {});
            };
        },
        useToggle(context) {
            const mutation = instance.useUpdate(context);
            return (schedule, payload) =>
                mutation(schedule, {
                    enabled: payload.enabled,
                });
        },
    };
    return instance;
}
