import { ExecFilterOperator } from './DataSet';

export interface ObjectMetaDataDefinition {
    key: string;
    name?: string;
    suggest_filter_options?: boolean;
    default_operator: ExecFilterOperator;
    data_type: 'string' | 'number' | 'boolean';
    allowed_operators?: Array<ExecFilterOperator> | null;
}

export interface PeerGroupFilterSetRequest {
    name?: string;
    filters: MetadataRule | MultiFilterRule;
    is_default?: boolean;
    asset_id?: number;
}

export interface PeerGroupFilterSet extends PeerGroupFilterSetRequest {
    id: number;
    created_at?: Date;
    is_default?: boolean;
}

export interface MetadataOptionTreeNode {
    id: number;
    key: string;
    name: string;
    children?: Array<MetadataOptionTreeNode>;
    is_locked: boolean;
    is_allowed: boolean;
}

export enum NumericOperator {
    eq = '=',
    neq = '!=',
    gt = '>',
    gte = '>=',
    lt = '<',
    lte = '<=',
    between = 'between',
}

export enum StrOperator {
    eq = '=',
    neq = '!=',
    contains = 'contains',
    rgx = 'Regular Expression',
    in = 'in',
    nin = 'not in',
    between = 'between',
    startsw = 'starts with',
    nstartsw = 'not starts with',
}

// export enum Operator {
//     eq = 'eq',
//     neq = 'neq',
//     gt = 'gt',
//     gte = 'gte',
//     lt = 'lt',
//     lte = 'lte',
//     contains = 'contains',
//     rgx = 'rgx',
//     in = 'in',
//     nin = 'nin',
//     between = 'between',
//     startsw = 'startsw',
//     nstartsw = 'nstartsw',
// }

export interface MetadataRule {
    operator: `${ExecFilterOperator}`;
    metadata_definition_key: string;
    value: string | Array<string> | number | Array<number | string>;
}

export interface MultiFilterRule {
    operator: 'and' | 'or';
    // sub_rules: Array<MetadataRule | MultiFilterRule>;
    // NOTE we do not currently supported nested rules
    sub_rules: Array<MetadataRule>;
}

export interface SingleLevelFilterRule {
    operator: 'and' | 'or';
    sub_rules: Array<MetadataRule>;
}

export function isNumberOrRule(
    value: number | MultiFilterRule | MetadataRule | 'new' | null
): value is number {
    return value != null && !isNaN(Number(value.toString()));
}

export function isSingle(value: MultiFilterRule | MetadataRule): value is MetadataRule {
    return (value as MetadataRule).metadata_definition_key !== undefined;
}

export function isFilterSet(
    value: MultiFilterRule | MetadataRule | PeerGroupFilterSet
): value is PeerGroupFilterSet {
    return (value as PeerGroupFilterSet).filters !== undefined;
}

export interface ChangedItem {
    key: string;
    operator?: ExecFilterOperator;
    value?: string | string[] | null;
    metadata?: Record<string, any>;
}

export interface Transparancy {
    quality:
        | 'very_large_sample'
        | 'highly_relevant'
        | 'relevant_sample'
        | 'small_sample'
        | 'insufficient_sample';
    range: Array<Number>;
}

export interface EvaluationResponse {
    evaluated_quality: Transparancy;
    results?: number;
    downgraded_to?: MetadataRule | MultiFilterRule;
}

export type AnyRule = MultiFilterRule | MetadataRule;
