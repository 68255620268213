import { pluralize } from '../../../../utils/strings';
import { Formatter } from '../../../../hooks';
import { assertNever } from '../../../util';
import { BillingUsagePlan, AnyBillingPlan, AddonPlan } from './planModel';

export function getBillingPlanUnitLabel(formatter: Formatter, item: BillingUsagePlan) {
    if (item.unit_kind === 'custom' && item.threshold_end) {
        const pluralized = pluralize(item.unit_name, item.threshold_end);
        return `${item.threshold_start} - ${item.threshold_end} ${pluralized}`;
    }
    if (item.unit_kind === 'custom') {
        const pluralized = pluralize(item.unit_name, item.threshold_start);
        return `${item.threshold_start}+ ${pluralized}`;
    }
    const start = formatter.currency(item.threshold_start, 'usd', {
        notation: 'short',
    });
    const end = item.threshold_end
        ? formatter.currency(item.threshold_end, 'usd', {
              notation: 'short',
          })
        : null;
    return end ? `${start} - ${end}` : `${start}+`;
}

export function getBillingPlanUnitDescription(item: BillingUsagePlan) {
    if (item.type === 'brand') {
        return `Brand pricing is based on your average monthly spend across connected accounts`;
    }
    if (item.type === 'agency') {
        return `Agency pricing is based on the number of clients you have access to`;
    }
    assertNever(item.type);
}

