import { CompanyDto } from '../../../api';
import { CompanyAdapter, CompanyEntity } from '../../../app/companies';
import { CompanyImplConfig } from './companyImplConfig';

export function createCompanyImpl(config: CompanyImplConfig): CompanyAdapter {
    const {
        api: { company: api },
    } = config;

    function toEntity(dto: CompanyDto): CompanyEntity {
        return {
            id: dto.id,
            name: dto.name,
            tagline: null,
            description: dto.description,
            tags: dto.industries,
            logoUrl: dto.logo_url,
            domain: dto.domain,
            industry: dto.main_industry,
            employees: dto.employees,
            revenue: dto.revenue,
            monthlyVisitors: dto.monthly_visitors,
            profiles: dto.profiles.map((profile) => {
                if (profile.kind === 'varos') {
                    return profile;
                }
                return profile;
            }),
            updatedAt: dto.updated_at,
        };
    }

    return {
        async search(context, props) {
            const response = await api.company.search(context, {
                term: props.term,
                limit: 25,
            });
            return response.data.map(toEntity);
        },
        async findById(context, props) {
            if (props.ids.length === 0) {
                return [];
            }
            const response = await api.company.find(context, { id: props.ids });
            return response.data.map(toEntity);
        },
        async findByAsset(context, props) {
            if (props.assets.length === 0) {
                return [];
            }
            const response = await api.company.find(context, {
                varos: props.assets.map(String),
            });
            return response.data.map(toEntity);
        },
    };
}
