import { normalizeCollection } from '../../../base';
import { useQuery } from '@tanstack/react-query';
import { ViewRepositoryConfig } from './viewConfig';
import { ViewRepository } from './viewInterface';

export function createViewRepository(config: ViewRepositoryConfig): ViewRepository {
    const { adapter } = config;
    return {
        useCollection(context, query, options) {
            const result = useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: ['views', 'collection', query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                select(data) {
                    return normalizeCollection(data);
                },
            });
            return result;
        },
        useFind(context, query, options) {
            return useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: ['views', 'find', query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
        },
    };
}
