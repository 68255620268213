
import {
    AnyRequirement,
    IntegrationReasonType,
    IntegrationsRequirement,
    ViewRequirement,
    TestExecutionDetails,
    IntegrationTestExecutionDetails,
    ViewTestExecutionDetails,
    IntegrationAssetState,
    PipelineRequirement,
    PipelineTestExecutionDetails,
    PipelineDepState,
    PipelineAssetState,
} from '../../../../api/v2/dashboard/deps';

export const HIDDEN_REASONS: Set<string> = new Set<IntegrationReasonType>(['no_integration']);
export const PENDING_REASONS: Set<string> = new Set<IntegrationReasonType>([
    'connected_integ_syncing',
    'connected_integ_unmapped',
]);

export function getReasonToShow(reasonObj: IntegrationAssetState, integId?: number): IntegrationAssetState | undefined {
    if (reasonObj.status == 'no_integration' && integId) {
        return reasonObj;
    }
    return !HIDDEN_REASONS.has(reasonObj.status) ? reasonObj : undefined;
}

export function isReasonToShow(reasonObj: IntegrationAssetState): boolean {
    return getReasonToShow(reasonObj) !== undefined;
}



export function isIntegrated(reasonObj: IntegrationAssetState): boolean {
    return reasonObj.status == 'integrated';
}

export function isPipelineInSync(reasonObj: PipelineAssetState): boolean {
    return reasonObj.state == 'in_sync';
}

export function isPending(reasonObj: IntegrationAssetState): boolean {
    return PENDING_REASONS.has(reasonObj.status);
}

export function isIntegrationTestExecDetails(
    details: TestExecutionDetails
): details is IntegrationTestExecutionDetails {
    return !!(details as IntegrationTestExecutionDetails).requirement.integrations;
}

export function isPipelineTestExecDetails(
    details: TestExecutionDetails
): details is PipelineTestExecutionDetails {
    return !!(details as PipelineTestExecutionDetails).requirement.integration_types;
}

export function isViewTestExecDetails(details: TestExecutionDetails): details is ViewTestExecutionDetails {
    return !!(details as ViewTestExecutionDetails).requirement.views;
}

export function isIntegrationRequirement(details: AnyRequirement): details is IntegrationsRequirement {
    return !!(details as IntegrationsRequirement).integrations;
}

export function isPipelineRequirement(details: AnyRequirement): details is PipelineRequirement {
    return !!(details as PipelineRequirement).integration_types;
}

export function isViewRequirement(details: AnyRequirement): details is ViewRequirement {
    return !!(details as ViewRequirement).views;
}
