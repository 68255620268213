import { Container, Flex, VStack, Center, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
    useSession,
    usePortal,
    StripeCheckoutRedirect,
    StripePortalRedirect,
} from '../../../../domain';
import { useAuthenticatedContext, useQuery } from '../../../../container';

export const ChoosePlanRedirectPage: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = () => {
    const match = useQuery<StripeCheckoutRedirect | StripePortalRedirect>();
    const context = useAuthenticatedContext();

    const session =
        match.type === 'checkout'
            ? useSession(context, { id: match.plan }, match)
            : usePortal(context, match.plan ? { id: match.plan } : null, match);

    useEffect(() => {
        if (session.data?.url) {
            window.location.href = session.data.url;
        }
    }, [session.data?.url]);

    return (
        <Flex h="full" justify="center" alignItems="center">
            <Container centerContent maxW="xl">
                <VStack w="full" h="full" p={10} spacing={8} alignItems="flex-center">
                    <Center>
                        <Spinner />
                    </Center>
                </VStack>
            </Container>
        </Flex>
    );
};
