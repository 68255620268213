import { ReportPollController } from './pollInterface';

export function createReportPollController(): ReportPollController {
    return {
        useProps(data, props) {
            if (data.item.status !== 'success') {
                return {
                    status: 'loading',
                };
            }
            const [first] = data.item.data.reports;
            return {
                status: 'success',
                getLinkProps() {
                    return {
                        to:
                            data.item.data!.preference.layout === 'light'
                                ? `/r/reports/${first.id}`
                                : `/u/reports/${first.id}`,
                    };
                },
            };
        },
    };
}
