import React from 'react';
import { useToast } from '@chakra-ui/react';
import { ToasterAdapter } from '../../base';
import { ToasterInit } from './toasterConfig';

export function createToaster({
    options = {},
    Component,
    ...init
}: ToasterInit = {}): ToasterAdapter {
    return {
        useToast() {
            const chakraToast = useToast();
            return ({ durationMs = 5000, ...config }) => {
                chakraToast({
                    ...options,
                    containerStyle: {
                        // lines up with the dashboard gutter
                        // marginRight: '1.25rem',
                        // marginBottom: '1.25rem',
                        marginBottom: '2rem',
                    },
                    status: config.kind,
                    description: config.description,
                    duration: durationMs,
                    render: Component ? (props) => <Component {...props} /> : undefined,
                });
            };
        },
    };
}
