import { z } from 'zod';

export const CompanyConditionKeySchema = z.union([
    z.literal('alexa_score'),
    z.literal('revenue'),
    z.literal('total_employees'),
    z.literal('year_founded'),
    z.literal('country.code'),
    z.literal('main_industry'),
    z.literal('industries'),
    z.literal('monthly_visitors'),
]);

export const CompanyInConditionSchema = z.object({
    key: CompanyConditionKeySchema,
    operator: z.literal('in'),
    value: z.array(z.string()),
});

// export const CompanyEqualConditionSchema = z.object({
//     key: z.string(),
//     operator: z.literal('eq'),
//     value: z.string(),
// });

// export const CompanyIsConditionSchema = z.object({
//     key: z.string(),
//     operator: z.literal('is'),
//     value: z.boolean(),
// });

export const CompanyConditionSchema = z.discriminatedUnion('operator', [
    CompanyInConditionSchema,
    // CompanyEqualConditionSchema,
    // CompanyIsConditionSchema,
]);
