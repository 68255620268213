import { combineQueryResponses } from '../../../../../domain/query/queryHelper';
import { QUERY_OVERRIDES } from '../../../../../config';
import { runQuery } from '../../../../../api/v2';
import { ChartAdapter } from '../../../../app';
import { castQueryToApi, castResponseToDomain } from '../../query';
import { ChartApiAdapterConfig } from './chartApiConfig';

export function createChartApiImpl(config: ChartApiAdapterConfig): ChartAdapter {
    return {
        async executeQuery(workspace, dashboard, card, query, options = {}) {
            const overrideFn = card ? QUERY_OVERRIDES[card.id] : null;
            const { segment, ...queryObject } = castQueryToApi(query);

            if (!overrideFn) {
                const response = await runQuery(
                    config.axios,
                    {
                        asset: workspace.id as number,
                        card: card?.id ?? null,
                        // card: null,
                        query: queryObject,
                        segment: segment,
                        example: options.example,
                    },
                    options
                );
                return castResponseToDomain(response);
            }

            const overridden = overrideFn({ queries: [queryObject] }) ?? [query];

            const responses = await Promise.all(
                overridden.map((query) =>
                    runQuery(config.axios, {
                        asset: workspace.id as number,
                        card: card?.id ?? null,
                        query: query,
                        segment: segment,
                        example: options.example,
                    })
                )
            );

            const combined = combineQueryResponses(responses);
            return castResponseToDomain(combined);
        },
        async executeQueries(workspace, dashboard, card, queries, options = {}) {
            const responses = await Promise.all(
                queries.map((query) => {
                    const { segment, ...queryObject } = castQueryToApi({
                        ...query,
                        segment: [],
                    });
                    return runQuery(config.axios, {
                        asset: workspace.id as number,
                        card: card?.id ?? null,
                        query: queryObject,
                        segment: segment,
                        example: options.example,
                    });
                })
            );
            const combined = combineQueryResponses(responses);
            return castResponseToDomain(combined);
        },
    };
}
