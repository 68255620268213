import { useLocalStorage } from 'react-use';
import { useEffect } from 'react';
import { OnboardingViewController } from '../../../view/common';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FeatureOnboardingMiddlewareConfig } from './onboardingFeatureConfig';

type FeatureOnboardingViewedState = string[];

/**
 * Will automatically show the onboarding modal the first time the users views it
 * assumung the feature is not yet considered onboarding
 * @param init
 * @returns
 */
export function createFeatureOnboardingMiddleware(
    init: FeatureOnboardingMiddlewareConfig = {}
): ApplicationEntryEnhancer {
    function enhanceOnboardingController(
        controller: OnboardingViewController<unknown>
    ): OnboardingViewController<unknown> {
        return {
            ...controller,
            useProps(item, props, ...args) {
                const [state = [], setState] =
                    useLocalStorage<FeatureOnboardingViewedState>(
                        'vs_feature_onboarding_modal_viewed',
                        []
                    );
                const viewProps = controller.useProps(item, props, ...args);

                const isSeenBefore = props.modal ? state.includes(props.modal.id) : false;

                const isIntro = viewProps.status === 'intro';

                const shouldShow =
                    !isSeenBefore && isIntro && !props.modal?.containerProps.isOpen;

                useEffect(() => {
                    // NOTE this only triggers once on page load
                    if (props.modal && shouldShow) {
                        console.info(`auto opening '${props.modal?.id}' modal`);
                        setState([...state, props.modal.id]);
                        return props.modal?.disclosure.onOpen();
                    }
                }, []);

                return viewProps;
            },
        };
    }
    return (create) => (config) => {
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                common: {
                    ...config.controller.common,
                    createOnboarding(...args) {
                        const controller = config.controller.common.createOnboarding(
                            ...args
                        );
                        return enhanceOnboardingController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
