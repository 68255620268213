import React from 'react';
import { Flex, Grid, GridItem, Box, Center } from '@chakra-ui/react';
import { CardSpinner } from '../../../domain';
import { fetchElements } from '../../util';
import { useLazySuspense, LazySuspense } from '../../vendor/lazy-suspense';
import { ReportLayout } from './reportInterface';

export function createReportLayout(): ReportLayout {
    const _Wrapper: ReportLayout['Wrapper'] = (props) => {
        return <>{props.children}</>;
    };
    const _Container: ReportLayout['Container'] = (props) => {
        const { Header, Control, Body } = fetchElements(props.children, {
            Header: _Header,
            Control: _Control,
            Body: _Body,
        });
        const { elementRef, suspenseProps } = useLazySuspense();
        return (
            <Box
                h="full"
                maxW={{
                    md: '85vw',
                    lg: '75vw',
                    xl: '66vw',
                }}
                mx="auto"
                py={4}
            >
                <Grid h="full" gridTemplateRows="min-content 1fr" gap={4} pt={4}>
                    <GridItem>
                        <Flex
                            alignItems="flex-start"
                            justifyContent="space-between"
                            borderColor="whiteAlpha.300"
                            borderBottomWidth={2}
                            borderStyle="solid"
                            pb={4}
                        >
                            <Box>{Header}</Box>
                            <Box>{Control}</Box>
                        </Flex>
                    </GridItem>
                    <GridItem position="relative" pb={32}>
                        <LazySuspense
                            {...suspenseProps}
                            fallback={
                                <Center
                                    position="relative"
                                    alignItems="center"
                                    h="full"
                                    py={4}
                                    top={-8}
                                >
                                    {/* {Loader} */}
                                    <CardSpinner />
                                </Center>
                            }
                        >
                            <Box ref={elementRef}>{Body}</Box>
                        </LazySuspense>
                    </GridItem>
                </Grid>
            </Box>
        );
    };
    const _Header: ReportLayout['Header'] = (props) => {
        return <Box>{props.children}</Box>;
    };
    const _Control: ReportLayout['Control'] = (props) => {
        return <Box>{props.children}</Box>;
    };

    const _Body: ReportLayout['Body'] = (props) => {
        return <Grid gap={8}>{props.children}</Grid>;
    };
    const _Section: ReportLayout['Section'] = (props) => {
        return <GridItem>{props.children}</GridItem>;
    };
    const _Column: ReportLayout['Column'] = (props) => {
        return <Box>{props.children}</Box>;
    };
    const _Row: ReportLayout['Row'] = (props) => {
        return <Box>{props.children}</Box>;
    };
    const _Text: ReportLayout['Text'] = (props) => {
        return <Box>{props.children}</Box>;
    };
    const _Loader: ReportLayout['Loader'] = (props) => {
        return <>{props.children}</>;
    };
    return {
        Wrapper: _Wrapper,
        Container: _Container,
        Header: _Header,
        Control: _Control,
        Body: _Body,
        Section: _Section,
        Column: _Column,
        Row: _Row,
        Text: _Text,
        Loader: _Loader,
    };
}
