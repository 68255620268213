import { OrganizationMappingViewConfig } from './assetMappingConfig';
import { OrganizationMappingLoader } from './assetMappingInterface';

export function createOrganizationMappingLoader(
    config: OrganizationMappingViewConfig
): OrganizationMappingLoader {
    const { service } = config;
    return {
        useData(context, props) {
            const aggregate = service.mapping.useLookup(context);
            const save = service.mapping.useSave(context, aggregate.data);
            return { aggregate, save };
        },
    };
}
