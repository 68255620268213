import {
    Box,
    ButtonGroup,
    Text,
    Tooltip,
    Button,
    Icon,
    VStack,
    Tag,
} from '@chakra-ui/react';
import { useForm, useFormState } from 'react-hook-form';
import { observer } from 'mobx-react';
import React from 'react';
import { OrganizationMappingFormValues } from '../../../view/assets';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { AssetMappingContainerConfigV2 } from './onboardingMappingConfig';
import { createOnboardingMappingViewV2 } from './onboardingMappingView';
import { AiOutlineArrowRight } from 'react-icons/ai';

export function createOnboardingMappingItemV2(
    config: AssetMappingContainerConfigV2
): OnboardingAssetRouteStrategy {
    const { loader, hooks } = config;
    const Component = createOnboardingMappingViewV2(config);
    const controller = config.provider.createController(config);
    return {
        id: 'mapping',
        name: 'Select asset',
        slug: 'mapping',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const organization = hooks.useOrganization();
                    const auth = hooks.useAuth();
                    const context = { organization, auth };

                    const data = loader.mapping.useData(context, {
                        asset: props.asset,
                    });

                    const form = useForm<OrganizationMappingFormValues>({
                        defaultValues: { entityByDefinition: {} },
                    });

                    const formState = useFormState({
                        control: form.control,
                        name: 'entityByDefinition',
                    });

                    const viewProps = controller.useProps({ data, form }, context, {
                        asset: props.asset,
                        onContinue: props.onContinue,
                    });

                    const formProps = viewProps.getFormProps();

                    return (
                        <Container
                            title="Link your accounts"
                            description={
                                <VStack align="start">
                                    <Text>
                                        Select the accounts you would like to link as data
                                        sources for{' '}
                                        <Box fontWeight="semibold" as="span">
                                            {props.asset.name}
                                        </Box>{' '}
                                        <Box as="span">({props.asset.url})</Box>{' '}
                                    </Text>
                                </VStack>
                            }
                            rightContent={
                                <ButtonGroup>
                                    {props.onContinue && (
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            label={
                                                <Box p={2}>{formProps.submitLabel}</Box>
                                            }
                                            isDisabled={formProps.isSubmittable}
                                        >
                                            <span>
                                                <Button
                                                    colorScheme="green"
                                                    onClick={form.handleSubmit(
                                                        formProps.onSubmit
                                                    )}
                                                    isDisabled={!formProps.isSubmittable}
                                                    isLoading={formState.isSubmitting}
                                                    rightIcon={
                                                        <Icon as={AiOutlineArrowRight} />
                                                    }
                                                >
                                                    Continue
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )}
                                </ButtonGroup>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps.getViewProps()} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
