import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { createPermissionController } from './permissionController';
import { PermissionApi, PermissionMiddleware } from './permissionInterface';

export function applyPermissionMiddleware(
    ...middlewares: PermissionMiddleware[]
): ApplicationEntryEnhancer {
    const api: PermissionApi = {
        controller: createPermissionController(),
    };
    const enhancers = middlewares.map((middleware) => middleware(api));
    return compose(...enhancers);
}
