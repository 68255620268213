import { useQuery } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { PlanHookOptions } from './planOptions';
import { createListPlansHandler } from './planQuery';
import { AnyPlan, PlanCategory } from './planType';

export const usePlans = (
    context: ApplicationContext,
    { excludeDefault = false }: Partial<PlanHookOptions> = {}
): PlanCategory[] => {
    const handler = createListPlansHandler(context);
    const query = useQuery(['plans'], handler, { suspense: true });

    const filterNotDefault = (plan: PlanCategory) => plan.key != 'free';
    let items = query.data?.categories ?? [];

    if (excludeDefault) {
        items = items.filter(filterNotDefault);
    }
    return items;
};
