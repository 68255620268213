import { observer } from 'mobx-react';
import React from 'react';
import {
    FilterItemContainerProps,
    FilterListContainerProps,
} from './filterContainerProps';

export const FilterListContainer: React.FC<
    { children?: React.ReactNode | undefined } & FilterListContainerProps
> = ({ as: Component, useController, ...props }) => {
    const viewProps = useController();
    return (
        <Component {...viewProps} renderFilter={props.renderFilter}>
            {props.children}
        </Component>
    );
};

export const FilterItemContainer: React.FC<
    { children?: React.ReactNode | undefined } & FilterItemContainerProps
> = observer(({ as: Component, useController, ...props }) => {
    const viewProps = useController(props);
    return <Component {...viewProps}>{props.children}</Component>;
});
