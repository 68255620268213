import { useMemo } from 'react';
import { ValueFormatter } from '../../../app/attributes';
import { buildPeerExample } from '../../../domain/peers';
import { PeerExampleController } from './exampleInterface';

export function createExampleController(
    formatter: ValueFormatter
): PeerExampleController {
    return {
        useProps(props) {
            const example = useMemo(() => {
                return buildPeerExample({
                    properties: props.properties,
                    size: props.size,
                });
            }, [props.properties]);
            return {
                columns: props.properties.map((item) => item.property),
                data: example.companies,
                format(property, value) {
                    return formatter.format(property.type, value, { notation: 'short' });
                },
            };
        },
    };
}
