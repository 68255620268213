import { useMemo, useState } from 'react';
import { WorkspaceListViewProps } from './workspaceListProps';
import { WorkspaceListConfigControllerConfig } from './workspaceListConfig';
import { WorkspaceListController } from './workspaceListInerface';
import { useDisclosure } from '@chakra-ui/react';
import { LoadedWorkspaceDto } from '../../../../api';
import React from 'react';

export function createWorkspaceListController(
    init: WorkspaceListConfigControllerConfig
): WorkspaceListController {
    const {
        service: {
            workspace: { useEnhancedList: useWorkspaces, useUpdate },
        },
    } = init;
    const maxDisplacedInList = 5;
    return {
        useProps(context, props): WorkspaceListViewProps {
            const updateWorkspace = useUpdate(context);

            const workspaces = useWorkspaces(context, {
                expand: ['users'],
                page_size: 500,
            });
            const modalDisclosure = useDisclosure();
            const [workspaceInEdit, setWorkspaceInEdit] =
                React.useState<LoadedWorkspaceDto | null>(null);
            const mappedWorkspaces = useMemo(
                () => ({
                    data: workspaces.data.map(
                        (ws): WorkspaceListViewProps['workspaces']['data'][number] => {
                            if (ws.users) {
                                if (ws.users.items.length == ws.users.count) {
                                    if (ws.users.items.length <= maxDisplacedInList) {
                                        return {
                                            ...ws,
                                            users: {
                                                ...ws.users,
                                                displayUsers: ws.users.items,
                                                countLeft: 0,
                                            },
                                        };
                                    }
                                }
                                return {
                                    ...ws,
                                    users: {
                                        ...ws.users,
                                        displayUsers: ws.users.items.slice(
                                            0,
                                            maxDisplacedInList - 1
                                        ),
                                        countLeft:
                                            ws.users.count - (maxDisplacedInList - 1),
                                    },
                                };
                            }
                            return {
                                ...ws,
                                users: {
                                    items: [],
                                    count: 0,
                                    displayUsers: [],
                                    countLeft: 0,
                                },
                            };
                        }
                    ),
                    pagination: workspaces.pagination,
                }),
                [workspaces]
            );
            return {
                // getChildProps(workspace, index) {
                // return {
                onEditWorkspace(dto) {
                    setWorkspaceInEdit(dto);
                    modalDisclosure.onOpen();
                },
                editProps: workspaceInEdit
                    ? {
                          ...modalDisclosure,
                          workspace: workspaceInEdit,
                          onSubmit: (values) => {
                              return updateWorkspace(values);
                          },
                      }
                    : null,
                workspaces: mappedWorkspaces,
            };
        },
    };
}
