import { useMemo } from 'react';
import { chain, last } from 'lodash';
import { FieldError } from 'react-hook-form';
import { Icons } from '../../../../../config';
import { FormIds, PageIds } from '../../../../config';
import { OptionListController, SelectListController } from '../../../../view/common';
import { AnyCondition } from '../../../../domain/attributes';
import { buildMetricItemProps, MetricItemViewProps } from '../../../../view';
import { PageController } from '../../../../view/page';
import { NetworkBaseRouteConfig } from '../../base';
import {
    NetworkRequestNewController,
    NetworkRequestNewService,
} from './networkRequestNewInterface';
import { NetworkRequestNewViewProps } from './networkRequestNewProps';
import { BUDGET_STEPS } from './networkRequestNewConstant';

export function createNetworkRequestNewController(
    config: NetworkBaseRouteConfig,
    service: NetworkRequestNewService,
    controller: {
        select: SelectListController<MetricItemViewProps>;
        option: OptionListController<'agency' | 'operator'>;
    }
): NetworkRequestNewController {
    const {
        infra: { oldFormatter: formatter },
        controller: {
            page: pageController,
            form: formController,
            peer: peerFilterController,
            preview: previewController,
        },
    } = config;
    const { select: selectController, option: optionController } = controller;

    function buildUnitProps() {
        return;
    }

    return {
        useProps(context, deps, item): NetworkRequestNewViewProps {
            const page = pageController.useProps(context, {
                item: {
                    id: PageIds.NETWORKS_EXPERT_CALL_REQUEST_NEW,
                    title: 'New call request',
                    description: `The below parameters will be used to search over Varos users and identify the best fit for a tactical call with a High Performer. This request is private and we will not share with anyone that you have made this request.`,
                    breadcrumbs: [
                        {
                            label: 'High Performer Calls',
                            to: '..',
                        },
                        {
                            label: 'Requests',
                            to: '..',
                        },
                    ],
                },
            });

            const form = formController.useProps({
                id: FormIds.NETWORK_EXPERT_CALL_REQUEST_NEW,
                form: deps.form,
                async onSubmit(values) {
                    try {
                        const response = await service.create(context, deps, values);
                        deps.toast({
                            kind: 'success',
                            description: `Call request successfully submitted`,
                        });
                    } catch (error) {
                        deps.toast({
                            kind: 'error',
                            description: `Call request submission failed`,
                        });

                        throw error;
                    }
                },
                onError(errors) {
                    const [first] = Object.values(errors) as Array<
                        FieldError | undefined
                    >;
                    if (first?.message) {
                        deps.toast({
                            kind: 'error',
                            description: first.message,
                        });
                        return;
                    }
                    console.error(errors);
                    deps.toast({
                        kind: 'error',
                        description: `Form has one or more errors`,
                    });
                },
            });

            const metricPropList = useMemo(
                () =>
                    chain(item.metric.items)
                        .map((item) => buildMetricItemProps(item))
                        .value(),
                [item.metric.items]
            );

            const metricPropSelected = useMemo(
                () =>
                    metricPropList.find(
                        (candidate) => candidate.id === deps.formValues.metric
                    ) ?? null,
                [metricPropList, deps.formValues.metric]
            );

            const metric: NetworkRequestNewViewProps['metric'] = {
                item: metricPropSelected ?? null,
                input: selectController.useProps(deps.metric, {
                    items: metricPropList,
                    value: deps.formValues.metric,
                    onChange(value) {
                        deps.form.setValue(
                            'metric',
                            // @ts-expect-error
                            value
                        );
                        deps.form.setValue('threshold', null);
                    },
                }),
                unit: metricPropSelected?.unit ? metricPropSelected.unit : null,
            };

            // const expertType = {
            //     input: optionController.useProps({
            //         items: [
            //             {
            //                 value: 'operator',
            //                 label: 'Operator',
            //                 description: `Connect with individuals from businesses like yours`,
            //                 isDisabled: false,
            //                 Icon: Icons.Networks.Operator,
            //             },
            //             {
            //                 value: 'agency',
            //                 label: 'Agency',
            //                 description: `Connect with marketing agencies managing businesses like yours`,
            //                 isDisabled: false,
            //                 Icon: Icons.Networks.Agency,
            //             },
            //         ],
            //         value: deps.formValues.expert_type,
            //         onChange(value) {
            //             deps.form.setValue('expert_type', value);
            //         },
            //     }),
            // };

            const peer = {
                filter: peerFilterController.useProps(context, item.peer, {
                    truncate: 1,
                    value: deps.formValues.filters as AnyCondition[],
                    onChange(value) {
                        deps.form.setValue(
                            'filters',
                            // @ts-expect-error
                            value
                        );
                    },
                }),
            };

            const preview = previewController.useProps({
                ...item.preview,
                getStatusProps(item) {
                    if (item.transparency.quality === 'insufficient_sample') {
                        return {
                            label: 'High performers not available',
                            description: `Expand filters for a larger pool of candidates`,
                            colorScheme: 'red',
                        };
                    }
                    return {
                        label: `High performers available`,
                        description: `A number of high performers matching your filters are available`,
                        colorScheme: 'green',
                    };
                },
            });

            return {
                page,
                form,
                peer,
                preview,
                metric,
                threshold: {
                    input: {
                        inputMode: 'numeric',
                        value: deps.formValues.threshold ?? undefined,
                        onChange(stringValue, numberValue) {
                            return deps.form.setValue('threshold', numberValue);
                        },
                    },
                },
                // expertType,
                budget: {
                    input: {
                        kind: 'range',
                        scale: {
                            kind: 'static',
                            steps: BUDGET_STEPS,
                        },
                        value: deps.formValues.budget,
                        onChange(value) {
                            deps.form.setValue('budget', value);
                        },
                        getLabel(value) {
                            const [lower, upper] = value;
                            const lowerLabel = formatter.currency(lower, 'usd', {
                                notation: 'short',
                            });
                            const upperLabel = formatter.currency(upper, 'usd', {
                                notation: 'short',
                            });

                            let suffix = '';
                            if (upper === last(BUDGET_STEPS)) {
                                suffix = '+';
                            }
                            return `${lowerLabel} - ${upperLabel}${suffix}`;
                        },
                    },
                },
                links: {},
            };
        },
    };
}
