export function addQueryParamsToHref(href: string, params: Record<string, string>) {
    // Create an anchor element to easily manipulate and dissect URLs
    const a = document.createElement('a');
    a.href = href;

    // Extract existing search parameters, if any
    const searchParams = new URLSearchParams(a.search);

    // Add passed parameters to the existing ones
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            searchParams.set(key, params[key]);
        }
    }

    // Update the search part of the href with the combined search parameters
    a.search = searchParams.toString();

    return a.href;
}
