import { DownloadLinkController } from './downloadLinkInterface';

export function createDownloadLinkController(): DownloadLinkController {
    return {
        useProps(props) {
            const handleClick: React.MouseEventHandler = (event) => {
                event.preventDefault();
                event.stopPropagation();

                const content =
                    typeof props.content === 'function' ? props.content() : props.content;

                const blob = new Blob([content], {
                    type: `${props.mimetype};charset=utf-8;`,
                });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', props.filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };
            return {
                getButtonProps() {
                    return {
                        onClick: handleClick,
                    };
                },
            };
        },
    };
}
