import { useMemo } from 'react';
import { getCollectionItems, LoadedLookup } from '../../../../base';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { GroupItemAggregate } from '../../../../view';
import {
    PeerDatasetEligibilityItemAggregate,
    PeerDatasetEligibilityListAggregate,
} from '../../../../view/peer';
import { PeerLoaderConfig } from '../../base';
import { PeerMemberItemAggregate } from '../../member';
import { PeerGroupRouteDetailLoader } from './peerGroupDetailInterface';
import { PeerGroupDetailAggregate } from './peerGroupDetailModel';

export function createPeerGroupDetailLoader(
    config: PeerLoaderConfig
): PeerGroupRouteDetailLoader {
    const {
        repository: {
            asset: assetRepository,
            invitation: invitationRepository,
            collaborator: collaboratorRepository,
            company: companyRepository,
            peerset: peerSetRepository,
            connection: connectionRepository,
            plugin: pluginRepository,
            healthcheck: healthcheckRepository,
        },
    } = config;
    return {
        useLoad(context, props) {
            const assetQuery = assetRepository.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );

            const pluginQuery = pluginRepository.useCollection(
                context,
                {},
                { suspense: true }
            );

            const connectionQuery = connectionRepository.useFind(
                context,
                { workspaces: [context.workspace] },
                {
                    suspense: true,
                }
            );

            const peerSetQuery = peerSetRepository.useLookup(context, props.peerset, {
                suspense: true,
            });

            const collaboratorQuery = collaboratorRepository.useFind(
                context,
                { workspaces: [context.workspace.id as number] },
                { suspense: true }
            );

            const invitationQuery = invitationRepository.useFind(
                context,
                {
                    source: [
                        {
                            kind: 'group',
                            id: props.peerset.id,
                        },
                    ],
                },
                {
                    suspense: true,
                }
            );

            assert(collaboratorQuery.status === 'success', 'expected suspese');
            assert(assetQuery.status === 'success', 'expected suspese');
            assert(peerSetQuery.status === 'success', 'expected suspese');
            assert(invitationQuery.status === 'success', 'expected suspese');
            assert(connectionQuery.status === 'success', 'expected suspense');
            assert(pluginQuery.status === 'success', 'expected suspense');

            const healthcheckQuery = healthcheckRepository.useMultiLookup(
                context,
                connectionQuery.data.map((connection) => ({
                    plugin: {
                        id: connection.plugin.id,
                    },
                    group: {
                        id: peerSetQuery.data.id,
                    },
                })),
                {
                    suspense: true,
                }
            );

            const companyInvitationQuery = companyRepository.useFind(
                context,
                {
                    ids: invitationQuery.data.flatMap((item) =>
                        item.kind === 'company' ? [item.company.id] : []
                    ),
                },
                { suspense: true }
            );

            const companyMemberQuery = companyRepository.useFind(
                context,
                {
                    assets: peerSetQuery.data.members.map((item) => item.asset.id),
                },
                { suspense: true }
            );

            assert(companyInvitationQuery.status === 'success', 'expected suspese');
            assert(companyMemberQuery.status === 'success', 'expected suspese');
            assert(healthcheckQuery.status === 'success', 'expected suspese');

            const companies = useMemo(
                () => [
                    ...getCollectionItems(companyInvitationQuery.data),
                    ...getCollectionItems(companyMemberQuery.data),
                ],
                [companyInvitationQuery.data, companyMemberQuery.data]
            );

            const companiesById = useMemo(
                () => keyBySafe(companies, (item) => item.id),
                [companyInvitationQuery.data, companyMemberQuery.data]
            );

            const healthcheckByPlugin = useMemo(
                () => keyBySafe(healthcheckQuery.data, (item) => item.plugin.id),
                [healthcheckQuery.data]
            );

            const companiesByAsset = useMemo(
                () =>
                    keyBySafe(
                        companies,
                        (item) =>
                            item.profiles.find((item) => item.kind === 'varos')?.id ??
                            'null'
                    ),
                [companyInvitationQuery.data, companyMemberQuery.data]
            );

            const lookup = useMemo((): PeerGroupDetailAggregate => {
                const group: GroupItemAggregate = {
                    plugin: null,
                    peerset: peerSetQuery.data,
                    user:
                        collaboratorQuery.data.find(
                            (candidate) =>
                                candidate.id === peerSetQuery.data.createdBy?.id
                        ) ?? null,
                    count: {
                        invitation: {
                            value: invitationQuery.data.length,
                        },
                    },
                };

                const pluginAggregate = useMemo(
                    (): PeerDatasetEligibilityListAggregate => ({
                        items: connectionQuery.data
                            .filter((item) => item.status === 'ready')
                            .flatMap(
                                (connection): PeerDatasetEligibilityItemAggregate[] => {
                                    const plugin =
                                        pluginQuery.data.byId[connection.plugin.id];
                                    const healthcheck =
                                        healthcheckByPlugin[connection.plugin.id];
                                    if (!plugin) {
                                        console.warn(
                                            `connection plugin '${connection.plugin.id} not found'`,
                                            pluginQuery.data.byId
                                        );
                                        return [];
                                    }
                                    if (!healthcheck) {
                                        console.warn(
                                            `connection healthcheck '${connection.plugin.id} not found'`,
                                            healthcheckByPlugin
                                        );
                                        return [];
                                    }
                                    return [
                                        {
                                            id: plugin.id,
                                            plugin,
                                            healthcheck,
                                            asset: { id: context.workspace.id as number },
                                            peerset: peerSetQuery.data,
                                        },
                                    ];
                                }
                            ),
                    }),
                    [
                        peerSetQuery.data,
                        connectionQuery.data,
                        pluginQuery.data.byId,
                        healthcheckByPlugin,
                        context.workspace.id,
                    ]
                );

                return {
                    eligibility: pluginAggregate,
                    memberlist: {
                        group,
                        items: [
                            ...invitationQuery.data.flatMap(
                                (invitation): PeerMemberItemAggregate[] => {
                                    if (invitation.kind !== 'company') {
                                        return [];
                                    }
                                    const company = companiesById[invitation.company.id];
                                    if (!company) {
                                        console.warn(
                                            `company ${invitation.company.id} not found`,
                                            companiesById
                                        );
                                        return [];
                                    }
                                    return [
                                        {
                                            member: null,
                                            invitation,
                                            company: {
                                                company,
                                                asset: null,
                                            },
                                        },
                                    ];
                                }
                            ),
                            ...peerSetQuery.data.members.flatMap(
                                (member): PeerMemberItemAggregate[] => {
                                    const company = companiesByAsset[member.asset.id];
                                    if (!company) {
                                        return [];
                                    }
                                    return [
                                        {
                                            member: {
                                                peerset: peerSetQuery.data,
                                                member,
                                                asset: member.asset,
                                                company,
                                            },
                                            invitation: null,
                                            company: {
                                                asset: member.asset,
                                                company,
                                            },
                                        },
                                    ];
                                }
                            ),
                        ],
                    },
                    group,
                };
            }, [
                context,
                peerSetQuery.data,
                assetQuery.data,
                companyInvitationQuery.data,
                companiesById,
                companiesByAsset,
                invitationQuery.data,
            ]);

            return lookup;
        },
    };
}
