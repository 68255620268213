import { QueryResponseAdapter } from '../../../app/query';
import { castQueryToApi, castResponseToDomain } from '../../api';
import { QueryResponseImplConfig } from './responseConfig';

export function createQueryResponseImpl(
    config: QueryResponseImplConfig
): QueryResponseAdapter {
    const {
        api: {
            query: {
                queries: { create: executeQuery },
            },
        },
    } = config;
    return {
        async find(context, query) {
            const { segment, ...casted } = castQueryToApi(query.request);
            const response = await executeQuery(context, {
                asset: query.asset.id as number,
                query: casted,
                segment,
            });
            return castResponseToDomain(response);
        },
    };
}
