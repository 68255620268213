import React, { useState } from 'react';
import { VStack, Switch, Box, Heading } from '@chakra-ui/react';
import { assertSubscriptionExpanded } from '../../../../api/notifications';
import { SettingsSubscriptionConfig } from './subscriptionListConfig';
import { SubscriptionListViewProps } from './subscriptionListProps';

export function createSubscriptionListView(
    config: SettingsSubscriptionConfig
): React.FC<{ children?: React.ReactNode | undefined } & SubscriptionListViewProps> {
    const {
        UI: { View, Table, Tbody, Thead, Tr, Td, Th, Tag, Button, Text },
    } = config;
    return (props) => {
        return (
            <View id="subscriptions" name="Notifications">
                <VStack alignItems="flex-start" spacing={4} w="full">
                    <VStack alignItems="flex-start" spacing={2}>
                        <Heading size="lg">Notifications</Heading>
                        <Text color="whiteAlpha.700">
                            Subscribe to and receive notifications for specific brands in
                            your email inbox
                        </Text>
                    </VStack>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Brand</Th>
                                <Th>Type</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.entities.map((entity, index) => {
                                assertSubscriptionExpanded(entity, [
                                    'asset',
                                    'definition',
                                ]);
                                return (
                                    <Tr key={index}>
                                        <Td>{entity.asset.name}</Td>
                                        <Td>
                                            <VStack alignItems="flex-start">
                                                <Box>
                                                    <Text>{entity.definition.name}</Text>
                                                </Box>
                                                <Box>
                                                    <Text color="whiteAlpha.700">
                                                        {entity.definition.description}
                                                    </Text>
                                                </Box>
                                            </VStack>
                                        </Td>
                                        <Td textAlign="right">
                                            <Switch
                                                size="md"
                                                {...props.getChildProps(entity, index)
                                                    .toggleProps}
                                            />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </VStack>
            </View>
        );
    };
}
