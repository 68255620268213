import { CollaboratorServiceConfig } from './collaboratorConfig';
import { CollaboratorService } from './collaboratorInterface';

export function createCollaboratorService(
    init: CollaboratorServiceConfig
): CollaboratorService {
    const {
        api,
        hook: { useQuery, useMutation },
    } = init;
    const PREFIX = ['v2', 'collaborators'];
    init.cacheRegistrar.register('collaberator', PREFIX);
    return {
        useList(context, query) {
            const response = useQuery({
                queryKey: [...PREFIX, ...query.workspaces],
                async queryFn() {
                    const response = await api.collaborators.list(context, query);
                    return response.data;
                },
                suspense: true,
                staleTime: Infinity,
            });
            return response.data ?? [];
        },
    };
}
