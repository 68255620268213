import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { MetricDefinitionDto } from './definitionModel';
import { MetricDefinitionResource } from './definitionInterface';
import { MetricDefinitionListResponseSchema } from './definitionSchema';

const FORMULA_OVERRIDES: Record<string, string | undefined> = {
    'facebookads.roas': `sum(revenue) / sum(spend)`,
    'googleads.roas': `sum(purchase value) / sum(spend)`,
    'tiktokads.cost_per_conversion': `sum(cost per conversion) / sum(# conversions)`,
    'executive_summary.marketing_efficiency_ratio': `sum(total revenue) / sum(total spend)`,
};

function getFormula(metric: MetricDefinitionDto): string {
    const applied = FORMULA_OVERRIDES[metric.id] ?? metric.formula;
    return applied;
}

export function createMetricDefinitionResource(
    client: AxiosInstance
): MetricDefinitionResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            let params: Record<string, string | string[]> = {};
            let headers: Record<string, string> = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            if (query.kinds !== undefined) {
                params['kind'] = query.kinds;
            }

            if (query.sources !== undefined) {
                params['source'] = query.sources;
            }

            if (query.plugins !== undefined) {
                params['plugin'] = query.plugins;
            }

            const response = await client.get<ListResponse<MetricDefinitionDto>>(
                '/api/v2/metrics',
                {
                    params,
                    headers,
                }
            );
            const parsed = MetricDefinitionListResponseSchema.parse(response.data);
            return {
                ...parsed,
                data: parsed.data.map(
                    (item): MetricDefinitionDto => ({
                        ...item,
                        formula: getFormula(item),
                    })
                ),
            };
        },
    };
}
