import { GroupInviteEntity } from '../../../domain/groups';
import { GroupInviteAdapter } from '../../../app/groups';
import { GroupInviteImplConfig } from './groupInviteConfig';

export function createGroupInviteImpl(config: GroupInviteImplConfig): GroupInviteAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.invites.list(context, query);

            let acc = response.data;
            return {
                limit: 10,
                total: acc.length,
                items: acc,
            };
        },
        create(context, entity) {
            throw new Error('not impl');
        },
        remove(context, entity) {
            throw new Error('not impl');
        },
    };
}
