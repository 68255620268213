import { ApplicationEntryEnhancer } from '../../entrypoint';
import { OnboardingEnhancerConfig } from './onboardingConfig';
import { compose } from 'lodash/fp';
import { createOnboardingAssetMiddleware } from './asset';
import { createOnboardingOrganizationMiddleware } from './organization';
import { createFeatureOnboardingMiddleware } from './feature';

export function createOnboardingEnhancer(
    init: OnboardingEnhancerConfig
): ApplicationEntryEnhancer {
    return compose(createFeatureOnboardingMiddleware());
    // createOnboardingOrganizationMiddleware(init.organization),
    // createOnboardingAssetMiddleware(init.organization)
}
