import { RelativePeriod } from './periodModel';
import { ApplicationContext } from '../../hooks';
import { TimeGranularityType } from '../../models/Common';
import {
    daterangeDifference,
    subtractDuration,
    DateRange,
    daterangeToString,
} from '../daterange';
import { durationToRange, durationToString } from '../duration';
import {
    isDependentPeriod,
    isRelativePeriod,
    isAbsolutePeriod,
    isRelativeDuration,
} from './periodGuard';
import {
    Period,
    DependentPeriod,
    AbsolutePeriod,
    ContextFreePeriod,
} from './periodModel';
import moment from 'moment';

export function periodToRange(
    reference: ContextFreePeriod | null,
    value: Period
    // granularity: TimeGranularityType
) {
    if (isDependentPeriod(value)) {
        if (!reference) {
            throw new Error(`dependant period must have reference period`);
        }
        return dependentPeriodToAbsolute(reference, value);
    }
    if (reference && isRelativePeriod(reference) && isRelativePeriod(value)) {
        const base = relativePeriodToDates(reference);
        return subtractDuration(base, value);
    }
    if (reference && isAbsolutePeriod(reference) && isRelativePeriod(value)) {
        return subtractDuration(reference, value);
    }
    if (isRelativePeriod(value)) {
        return relativePeriodToDates(value);
    }
    return value;
}

export function dependentPeriodToAbsolute(
    reference: ContextFreePeriod,
    period: DependentPeriod
): AbsolutePeriod {
    if (isRelativePeriod(reference)) {
        const range = relativePeriodToDates(reference);
        return subtractDuration(range, {
            interval: reference.interval,
            amount: reference.amount * period.amount,
        });
    }
    const differenceInDays = daterangeDifference(reference, 'day');
    return subtractDuration(reference, {
        interval: 'day',
        amount: differenceInDays,
    });
}

export function periodToString(
    context: Pick<ApplicationContext, 'formatter'>,
    period: Period,
    prefix = 'Last'
) {
    if (isAbsolutePeriod(period)) {
        return daterangeToString(context, period);
    }
    if (isRelativeDuration(period)) {
        const duration = durationToString(context, period);
        return `${prefix} ${duration}`;
    }
    return `${prefix} period`;
}

export function relativePeriodToDates(period: RelativePeriod): DateRange {
    const now = moment(new Date()).startOf('hour').toDate();
    return durationToRange(period, now);
}

export function periodToAbsoluteDateString(
    context: Pick<ApplicationContext, 'formatter'>,
    period: ContextFreePeriod
): string {
    if (isAbsolutePeriod(period)) {
        return periodToString(context, period);
    }
    const range = relativePeriodToDates(period);
    return daterangeToString(context, range);
}
