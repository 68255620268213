import React from 'react';
import { ReportChartCategoryContainerProps } from './categoryChartProps';
import { ReportChartCategoryController } from './categoryChartInterface';
import { ReportContainerBaseConfig } from '../../../base';

export function createReportCategoryChartContainer(
    config: ReportContainerBaseConfig,
    controller: ReportChartCategoryController
): React.FC<ReportChartCategoryContainerProps> {
    const {
        context: { useContext },
    } = config;
    return ({ as: View, visualization, ...containerProps }) => {
        const context = useContext();
        const props = controller.useProps(context, { visualization }, containerProps);
        return <View {...props} />;
    };
}
