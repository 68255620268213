import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { toVisualization } from '../../../app/visualizations';
import { getAnalysisMetricLink } from '../../../route';
import { BenchmarkListController } from '../../../view';
import { DynamicDashboardFeatureConfig } from './dynamicDashboardFeatureConfig';

export function createFeatureDynamicDashboardStrategy(
    init: DynamicDashboardFeatureConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            module: { createMetricModule },
        } = config;

        function enhanceController(
            controller: BenchmarkListController
        ): BenchmarkListController {
            return {
                ...controller,
                useProps(...args) {
                    const [deps, context, collection, input, options] = args;
                    const props = controller.useProps(...args);
                    return {
                        ...props,
                        getItemProps(item) {
                            const itemProps = props.getItemProps(item);
                            // if (!features?.dynamic_dashboards.enabled) {
                            //     return itemProps;
                            // }
                            // console.log(
                            //     'enabling dynamic dashboard link because of feature flag'
                            // );
                            const visualization = toVisualization(
                                init.strategy.visualizable,
                                {
                                    definition: item.metric,
                                    configuration: item.configuration,
                                }
                            );
                            if (!visualization) {
                                return itemProps;
                            }
                            return {
                                ...itemProps,
                                // metricPath: `/u/assets/${context.workspace.id}/metrics/analyze?metric=${item.metric.id}`,
                                metricPath: getAnalysisMetricLink(context, visualization),
                            };
                        },
                    };
                },
            };
        }

        return create({
            ...config,
            module: {
                ...config.module,
                createMetricModule(config) {
                    return createMetricModule({
                        ...config,
                        provider: {
                            ...config.provider,
                            benchmark: {
                                list: {
                                    ...config.provider.benchmark.list,
                                    createController(...args) {
                                        const contoller =
                                            config.provider.benchmark.list.createController(
                                                ...args
                                            );
                                        return enhanceController(contoller);
                                    },
                                },
                            },
                        },
                    });
                },
            },
        });
    };
}
