import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { createSettingPluginItemContainer } from './item';
import { SettingAssetPluginConfig } from './pluginConfig';
import { createSettingAssetPluginContainer } from './pluginContainer';
import { createSettingAssetPluginController } from './pluginController';
import { createSettingAssetPluginLoader } from './pluginLoader';
import { createSettingAssetPluginView } from './pluginView';

export function createSettingAssetPluginStrategy(
    init: SettingAssetPluginConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const controller = createSettingAssetPluginController(init, config);
            const loader = createSettingAssetPluginLoader(init);
            const Container = createSettingAssetPluginContainer(
                init,
                config,
                loader,
                controller
            );
            const View = createSettingAssetPluginView(
                init,
                config,
                createSettingPluginItemContainer(init, config)
            );
            return [
                {
                    scope: 'company',
                    path: 'plugins',
                    key: 'plugins',
                    title: 'Plugins',
                    Component() {
                        return <Container as={View} />;
                    },
                },
            ];
        },
    };
}
