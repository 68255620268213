import moment from 'moment';
import { ViewPropertyDto } from '../../../api';
import { AnyFilter, ConditionObject, SavedDataFilter } from '../../../../api';
import {
    isBetweenCondition,
    isPreviousCondition,
    AnyCondition,
    isDateBetweenCondition,
    Property,
} from '../../../domain/attributes';

export function castConditionToApi(condition: AnyCondition): ConditionObject {
    if (isPreviousCondition(condition)) {
        return {
            key: condition.key,
            operator: condition.operator,
            value: [condition.value.amount.toString(), condition.value.interval],
        };
    }
    if (isDateBetweenCondition(condition)) {
        return {
            key: condition.key,
            operator: condition.operator,
            value: [
                moment.utc(condition.value.from).format('YYYY-MM-DD'),
                moment.utc(condition.value.to).format('YYYY-MM-DD'),
            ],
        };
    }
    if (isBetweenCondition(condition) && condition.value.to === null) {
        return {
            key: condition.key,
            operator: 'gte',
            value: condition.value.from,
        };
    }
    if (isBetweenCondition(condition) && condition.value.from === 0) {
        return {
            key: condition.key,
            operator: 'lte',
            value: condition.value.to,
        };
    }
    if (isBetweenCondition(condition)) {
        return {
            key: condition.key,
            operator: 'between',
            value: [condition.value.from, condition.value.to],
        };
    }
    return condition;
}

export function castConditionToDomain(dto: ConditionObject): AnyCondition {
    if (dto.operator === 'between' && Array.isArray(dto.value)) {
        const [from, to] = dto.value;
        return {
            key: dto.key,
            operator: dto.operator,
            value: {
                from,
                to,
            },
        };
    }
    // @ts-expect-error
    return dto;
}

export function castFilterToConditionDomain(
    propertyKeyByTypeSlug: Record<string, Property | undefined>,
    saved: SavedDataFilter
): AnyCondition | null {
    const property = propertyKeyByTypeSlug[saved.type_slug];
    if (!property) {
        return null;
    }
    if (saved.filter.operator === 'in') {
        return {
            key: property.key,
            operator: saved.filter.operator,
            value: saved.filter.values as string[],
        };
    }
    return null;
}
