import { NotificationApi } from './notificationsInterface';
import { NotificationApiConfig } from './notificationsConfig';
import { createSubscriptionResource } from './subscription';

export function createNotificationApi(config: NotificationApiConfig): NotificationApi {
    const { client } = config;
    return {
        subscriptions: createSubscriptionResource(client),
    };
}
