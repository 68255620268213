import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { assert } from '../../../../../util/assert';
import { ReportNewFormProps } from './reportFormProps';
import { ReportNewFormValues } from './reportFormModel';
import { ReportNewFormValuesSchema } from './reportFormSchema';
import { NavigateFunction } from 'react-router';
import { ReportNewData } from '../reportNewInterface';

export function buildReportFormProps(
    deps: { navigate: NavigateFunction },
    data: ReportNewData,
    form: UseFormReturn<ReportNewFormValues>,
    values: ReportNewFormValues
): ReportNewFormProps {
    const { navigate } = deps;
    return {
        async onSubmit(raw) {
            const parsed = ReportNewFormValuesSchema.parse(raw);
            assert(data.item.data, 'data not ready');
            const report = await data.report.create.mutateAsync({
                asset: data.item.data.asset.id,
                period: parsed.period,
            });
            console.log('report created', report);
            if (data.item.data.preference.layout === 'light') {
                navigate(`/r/reports/${report.id}`);
            } else {
                navigate(`/u/reports/${report.id}`);
            }
        },
        values,
        submitText: 'Create report',
        isSubmittable: !form.formState.isSubmitting,
        isSubmitting: form.formState.isSubmitting,
        form,
        error: data.report.create.error,
    };
}
