import React from 'react';
import { chain } from 'lodash';
import { TableCellProps } from '@chakra-ui/react';
// import { AnyType } from '../../../api';
import { QueryAggregation, Series, SeriesEventStatus } from '../../../api';
import { Column, ColumnData, RowData } from '../../../ui';
import { buildRenderContext } from '../../../domain/visualization/context';
import { render } from '../../../domain/visualization/value';
import { formatType } from '../../../domain';
import { AnyType } from '../../../v2/domain/attributes';
import { EVENT_STYLE_MAPPING } from './tableConstant';
import {
    TableVisualizationCell,
    TableVisualizationPopover,
    TableVisualizationProps,
} from './component';
import { TableVisualizationStrategy } from './tableModel';
import { QueryRequest } from '../../../v2/domain/query';
import type { CohortMode } from '../../../v2/domain/dashboard';

export const tableStrategy: TableVisualizationStrategy = (
    context,
    visualization,
    request,
    response,
    config
): TableVisualizationProps => {
    const [valueQuery, captionQuery, ...rest] = response.queries;

    if (rest.length > 0) {
        console.warn('bar chart does not support more than 2 queries');
    }

    const normal = valueQuery.series.items.flatMap((series) =>
        series.data.map((row) => ({ ...row }))
    );
    const columns = [
        ...response.metadata.group.map<Column>((key) => ({
            primary: true,
            key: key,
            title: valueQuery.schema.properties[key]?.name || null,
        })),
        ...Object.entries(valueQuery.schema.properties)
            .filter(([key]) => response.metadata.scalar.includes(key))
            .map<Column>(([key, column]) => ({
                key,
                title: column.name ?? key,
            })),
    ];

    const aggregationsByPropertyKey =
        request.aggregations?.reduce(
            (acc, aggregation) => ({ ...acc, [aggregation.name]: aggregation }),
            {} as Record<string, QueryAggregation | undefined>
        ) ?? {};

    return {
        renderCell({ rowData, columnData, cellData }, props) {
            const series = valueQuery.series.items[rowData.index];
            const [event] = series ? visualization.getEvents(series, columnData.key) : [];
            const containerProps: TableCellProps = {
                ...props,
                margin: 0.1,
                paddingX: 4,
                paddingY: 2,
            };

            const eventbindings = event
                ? buildRenderContext(context, {
                      response,
                      columnData,
                      rowData,
                      cellData,
                  })
                : {};

            const columnType: AnyType = valueQuery.schema.properties[columnData.key].type;

            const cellValueLong = formatType(context, columnType, cellData.value, {
                notation: 'long',
            });
            const cellValueShort = formatType(context, columnType, cellData.value, {
                notation: 'short',
            });

            const captionRaw =
                !columnData.primary && captionQuery
                    ? (captionQuery.series.items[rowData.index]?.data?.[0]?.[
                          columnData.key
                      ] as unknown | undefined)
                    : undefined;

            let caption =
                captionRaw !== null && captionRaw !== undefined && cellData.value !== null
                    ? formatType(
                          context,
                          captionQuery.schema.properties[columnData.key].type,
                          captionRaw
                      )
                    : undefined;

            if (!cellData.value) {
                caption = undefined;
            } else if (captionRaw === null) {
                caption = 'N/A';
            }

            return (
                <TableVisualizationCell
                    containerProps={containerProps}
                    value={cellData.value ? cellValueShort : 'N/A'}
                    caption={caption ?? undefined}
                    highlight={!!event}
                    tooltip={
                        event ? (
                            <TableVisualizationPopover
                                context={context}
                                period={response.metadata.period ?? undefined}
                                comparison={response.metadata.comparison ?? undefined}
                                title={columnData.title ?? columnData.key}
                                value={cellValueLong}
                                events={
                                    event
                                        ? [
                                              {
                                                  ...event,
                                                  status: event.status,
                                                  text: render(
                                                      event.text ?? event.title ?? '',
                                                      eventbindings
                                                  ),
                                              },
                                          ]
                                        : []
                                }
                            />
                        ) : undefined
                    }
                />
            );
        },
        getCellProps({ rowData, columnData }) {
            // const series = valueQuery.series.items[rowData.index];
            // const [event] = series ? visualization.getEvents(series, columnData.key) : [];
            const defaultProps: TableCellProps = {
                color: columnData.primary ? 'current' : 'gray.400',
            };
            // console.log('request.aggregations', request.aggregations);
            const status = getStatus(
                request,
                aggregationsByPropertyKey,
                valueQuery.series.items,
                rowData,
                columnData
            );
            const eventstyle = status ? EVENT_STYLE_MAPPING[status] : {};
            // console.log('style', status, columnData.key, rowData.value);
            return {
                ...defaultProps,
                ...eventstyle,
                fontWeight: status ? 'bold' : undefined,
            };
        },
        getCellValue({ columnData }, original) {
            if (columnData.value) {
                return render(columnData.value, { self: { value: original } });
            }
            return original;
        },
        columns: columns,
        data: normal,
    };
};

function getStatus(
    request: QueryRequest,
    aggregationByPropertyKey: Record<string, QueryAggregation | undefined>,
    series: Series[],
    row: RowData,
    column: ColumnData
): SeriesEventStatus | null {
    if (row.index !== 0) {
        return null;
    }

    const aggregation = aggregationByPropertyKey[column.key];

    const peers = series
        .filter((item, index) => index !== row.index)
        .map((item) => item.data[0]?.[column.key]);

    if (peers.length !== 3) {
        console.warn(`proximity coloring expected 3 cohorts but got ${peers.length}`);
        return null;
    }

    const self = row.value[column.key];
    const peervalues = peers.map(Number);

    const [positionalBad, positionalMid, positionalGood] = peervalues;
    // const [low, mid, high] = peervalues;

    if (typeof self !== 'number') {
        console.warn(`invalid cohort value`, self);
        return null;
    }

    const proximity = chain(peervalues)
        .map((peer) => ({ actual: peer, difference: Math.abs(peer - self) }))
        .orderBy((peer) => peer.difference, 'asc')
        .value();

    const ordered = chain([...peervalues])
        .orderBy((peer) => peer, aggregation?.inverse ? 'asc' : 'desc')
        .value();

    const [numericGood, numericMid, numericBad] = ordered;

    const [{ actual: closest }] = proximity;

    // console.log('closest', {
    //     self,
    //     peervalues,
    //     proximity,
    // });

    // If ranking is "ordered" meaning high>mid>low then current logic
    // If ranking is not "ordered"
    // If my_company/min(low mid or high) < 0.8 yellow
    // If my_company/min(low mid or high) < 0.5 red
    // Else green (as it's usually the case that companies are very close and we would only highlight if you are significantly below

    const rankingMode: CohortMode = request.segments?.every(
        (segment) => segment.kind === 'asset' || segment.kind === 'expression'
    )
        ? 'dynamic'
        : 'fixed';

    if (rankingMode === 'dynamic') {
        // current behaviour
        if (closest === positionalBad) {
            return 'danger';
        }
        if (closest === positionalMid) {
            return 'warn';
        }
        if (closest === positionalGood) {
            return 'success';
        }
    } else {
        if (closest === numericBad) {
            return 'danger';
        }
        if (closest === numericMid) {
            return 'warn';
        }
        if (closest === numericGood) {
            return 'success';
        }
    }

    // // if (request.)
    // const isOrdered = high > mid && mid > low;
    // //  || (high < mid && mid < low);

    // if (isOrdered) {
    //     console.log('ordered', column.key);
    //     if (closest === low) {
    //         return 'danger';
    //     }

    //     if (closest === mid) {
    //         return 'warn';
    //     }

    //     if (closest === high) {
    //         return 'success';
    //     }
    // } else {
    //     if (aggregation?.inverse) {
    //         // console.log('not ordered inverse', column.key);
    //         if (self / Math.min(low, mid, high) < 0.8) {
    //             return 'warn';
    //         }
    //         if (self / Math.min(low, mid, high) < 0.5) {
    //             return 'success';
    //         }
    //         return 'danger';
    //     } else {
    //         //     // console.log('not ordered not inverse', column.key);
    //         if (self / Math.min(high, mid, low) < 0.8) {
    //             return 'warn';
    //         }
    //         if (self / Math.min(high, mid, low) < 0.5) {
    //             return 'danger';
    //         }
    //         return 'success';
    //     }
    // }

    return null;

    // if (self > mid && closest.actual === high) {
    //     return 'success';
    // }

    // if (self > mid && closest.actual === mid) {
    //     return 'warn';
    // }

    // if (self < mid && closest.actual === mid) {
    //     return 'warn';
    // }

    // if (self < mid && closest.actual === low) {
    //     return 'danger';
    // }

    // if (closest.actual === low) {
    //     return 'danger';
    // }

    // if (closest.actual === mid) {
    //     return 'warn';
    // }

    // if (closest.actual === high) {
    //     return 'success';
    // }

    // console.info('not supported', { key: column.key, self, low, mid, high });
    // // console.log('column', column.key);
    // // console.log('row', self);
    // // console.log('peers', peers);
    // return null;
}
