import { CountDto, CountQueryDtoEntry } from '../../../api';
import { CountAdapter, CountEntity } from '../../../app/platform';
import { CountImplConfig } from './countImplConfig';

export function createCountImpl(config: CountImplConfig): CountAdapter {
    const { api } = config;

    function toEntity(dto: CountDto): CountEntity {
        return {
            kind: dto.kind,
            object: dto.object_id,
            value: dto.value,
        };
    }

    return {
        async find(context, query) {
            if (query.length === 0) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                return [];
            }
            const response = await api.platform.count.list(context, {
                data: query.map(
                    (item): CountQueryDtoEntry => ({
                        kind: item.kind,
                        object_id: item.object,
                    })
                ),
            });
            return response.data.map(toEntity);
        },
    };
}
