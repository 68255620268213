import {
    AnyVisualization,
    AnyVisualizationResult,
    BarVisualizationResult,
    isComparisonVisualizationResult,
    VisualizationResultSeries,
} from '../../../../domain/visualization';
import {
    CohortState,
    DynamicCohortStateItem,
    FixedCohortStateItem,
    isFixedRanking,
} from '../../../../domain';
import { assert } from '../../../../util/assert';
import { LegendItemProps } from '../../legend';
import { TextProps } from '@chakra-ui/react';

export function buildLegendProps(
    visualization: AnyVisualization,
    result: AnyVisualizationResult,
    config: { colors: TextProps['colorScheme'][] }
): LegendItemProps[] {
    const acc: LegendItemProps[] = [
        {
            // TODO the current asset context shoild be modeled as part of the cohort item definitions
            colorScheme: config.colors[0],
            label: 'My company',
            description: null,
        },
    ];
    if (isComparisonVisualizationResult(result)) {
        const [{ series }] = result.entries;
        return [
            ...acc,
            ...(isFixedRanking(visualization.ranking)
                ? visualization.ranking.cohorts
                      .filter((item) => item.visible)
                      .map((cohort, index) => ({
                          colorScheme: config.colors[index + (1 % series.length)],
                          label: cohort.name,
                          description: getMultiMetricFixedCohortDescription(
                              visualization,
                              result,
                              cohort
                          ),
                      }))
                : visualization.ranking.cohorts
                      .filter((item) => item.visible)
                      .map((cohort, index) => ({
                          colorScheme: config.colors[index + (1 % series.length)],
                          label: cohort.name,
                          description: getMultiMetricDynamicCohortDescription(
                              visualization,
                              result,
                              cohort
                          ),
                      }))),
        ];
    }

    return [
        ...acc,
        ...(isFixedRanking(visualization.ranking)
            ? visualization.ranking.cohorts
                  .filter((item) => item.visible)
                  .map((cohort, index) => ({
                      colorScheme: config.colors[index + (1 % result.series.length)],
                      label: cohort.name,
                      description: getFixedCohortDescription(
                          visualization,
                          result,
                          cohort
                      ),
                  }))
            : visualization.ranking.cohorts
                  .filter((item) => item.visible)
                  .map((cohort, index) => ({
                      colorScheme: config.colors[index + (1 % result.series.length)],
                      label: cohort.name,
                      description: getDynamicCohortDescription(
                          visualization,
                          result,
                          cohort
                      ),
                  }))),
    ];
}

function getFixedCohortDescription(
    visualization: AnyVisualization,
    result: AnyVisualizationResult,
    cohort: FixedCohortStateItem
) {
    assert(isFixedRanking(visualization.ranking), 'expected fixed ranking');
    const [metric] = visualization.metrics;
    const comparison =
        (visualization.ranking.comparison
            ? result.schema[visualization.ranking.comparison]?.name
            : null) ?? visualization.ranking.comparison;
    return `Median ${metric.name} for companies between ${cohort.lower}th and ${cohort.upper}th percentile based on ${comparison}`;
}

function getDynamicCohortDescription(
    visualization: AnyVisualization,
    result: AnyVisualizationResult,
    cohort: DynamicCohortStateItem
) {
    const [metric] = visualization.metrics;
    return `${cohort.value}th percentile ${metric.name}`;
}

// Multi-metric

function getMultiMetricFixedCohortDescription(
    visualization: AnyVisualization,
    result: AnyVisualizationResult,
    cohort: FixedCohortStateItem
) {
    assert(isFixedRanking(visualization.ranking), 'expected fixed ranking');
    const [metric] = visualization.metrics;
    const comparison =
        (visualization.ranking.comparison
            ? result.schema[visualization.ranking.comparison]?.name
            : null) ?? visualization.ranking.comparison;
    return `Companies between ${cohort.lower}th and ${cohort.upper}th percentile based on ${comparison}`;
}

function getMultiMetricDynamicCohortDescription(
    visualization: AnyVisualization,
    result: AnyVisualizationResult,
    cohort: DynamicCohortStateItem
) {
    const [metric] = visualization.metrics;
    return `${cohort.value}th percentile`;
}
