import React from 'react';
import {
    VStack,
    Spinner,
    Flex,
    Box,
    Heading,
    Text,
    Image,
    Grid,
    GridItem,
    StackDivider,
    HStack,
    Icon,
    Tag,
    Button,
    Link as ChakraLink,
} from '@chakra-ui/react';
import HexInviteImg from '../../../../../images/hex_invite.png';
import Screenshot from '../../../../../images/top_creatives_preview.png';
import { GroupsActivatedViewProps } from '../../../../view/groups/group/activated/groupsActivatedProps';
import { ApplicationUI } from '../../../../ui';
import { MdOpenInNew } from 'react-icons/md';

export const createGroupsActivatedView = ({
    UI,
}: {
    UI: ApplicationUI;
}): React.FC<{ children?: React.ReactNode | undefined } & GroupsActivatedViewProps> => {
    const { Link, Tooltip } = UI;

    return (props) => {
        const statusProps = props.getStatusProps();
        return (
            <Grid templateRows="1fr" h="full">
                <GridItem>
                    <VStack position="relative">
                        <VStack
                            px={4}
                            py={8}
                            w="full"
                            maxW={{
                                sm: '100vw',
                                md: '90vw',
                                lg: '90vw',
                                xl: '85vw',
                                '2xl': '80rem',
                            }}
                            marginX="auto"
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.300"
                                    borderWidth={1}
                                />
                            }
                            spacing={4}
                            pb={48}
                        >
                            <HStack justify="start" w="full" align="start" spacing={12}>
                                <VStack align="start" w="full">
                                    <HStack flex={1} spacing={3} align="center">
                                        <Heading fontWeight="semibold" fontSize="2xl">
                                            Top Creatives
                                        </Heading>
                                        <Tag
                                            fontWeight="semibold"
                                            color="blackAlpha.900"
                                            bg="#c6e74f"
                                            letterSpacing="wide"
                                        >
                                            New
                                        </Tag>
                                    </HStack>
                                </VStack>
                                <HStack>
                                    <ChakraLink href={props.hexUrl} target="_blank">
                                        <Button
                                            onClick={props.onClick}
                                            // size="md"
                                            size="sm"
                                            colorScheme="green"
                                            rightIcon={<Icon as={MdOpenInNew} />}
                                            _focus={{ outline: 'none' }}
                                        >
                                            Open In Hex
                                        </Button>
                                    </ChakraLink>
                                </HStack>
                            </HStack>
                            <VStack
                                w="full"
                                align="start"
                                spacing={3}
                                fontWeight="medium"
                                borderStyle="solid"
                            >
                                <VStack align="start">
                                    <Text color="whiteAlpha.800">
                                        Facebook Ad Library has many creatives but you
                                        can't tell which ones are actually performing
                                        well.
                                    </Text>
                                    <Text color="whiteAlpha.800">
                                        With this feature you can see top performing
                                        creative by actual performance metrics (ROAS, CTR,
                                        Spend as % of total ad account) from companies
                                        with similar end customers.
                                    </Text>
                                </VStack>
                                <Grid
                                    pt={6}
                                    templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                                    gap={12}
                                    w="full"
                                >
                                    <GridItem>
                                        <VStack align="start" w="full" spacing={6}>
                                            <VStack align="start" w="full">
                                                <Heading fontWeight="bold" fontSize="lg">
                                                    1) Create account in Hex
                                                </Heading>
                                                <VStack
                                                    align="start"
                                                    color="whiteAlpha.700"
                                                >
                                                    <Text>
                                                        You will receive an email from{' '}
                                                        <Box as="span" color="blue.400">
                                                            notify@mail.hex.tech
                                                        </Box>{' '}
                                                        inviting you to this module. Note
                                                        this email can take up to 24h to
                                                        reach your inbox.
                                                    </Text>
                                                </VStack>
                                            </VStack>
                                            <Box
                                                w="full"
                                                p={6}
                                                borderRadius="lg"
                                                borderWidth={2}
                                                borderStyle="solid"
                                                borderColor="whiteAlpha.300"
                                            >
                                                <Image
                                                    borderRadius="lg"
                                                    src={HexInviteImg}
                                                    w="full"
                                                    h="full"
                                                />
                                            </Box>
                                            <Text
                                                fontStyle="italic"
                                                color="whiteAlpha.700"
                                            >
                                                Note - this is not in the standard Varos
                                                app but a different dashboard. Should you
                                                need any help, please reach out to support
                                            </Text>
                                        </VStack>
                                    </GridItem>
                                    <GridItem>
                                        <VStack align="start" w="full" spacing={6}>
                                            <VStack align="start" w="full" spacing={2}>
                                                <Heading fontWeight="bold" fontSize="lg">
                                                    2) Get started
                                                </Heading>
                                                <VStack
                                                    align="start"
                                                    color="whiteAlpha.700"
                                                >
                                                    <Text>
                                                        Note - this is not in the standard
                                                        Varos app but a different
                                                        dashboard. Should you need any
                                                        help, please reach out to support
                                                    </Text>
                                                </VStack>
                                                <ChakraLink
                                                    pt={1}
                                                    href={props.hexUrl}
                                                    target="_blank"
                                                >
                                                    <Button
                                                        colorScheme="green"
                                                        rightIcon={
                                                            <Icon as={MdOpenInNew} />
                                                        }
                                                    >
                                                        View Creatives
                                                    </Button>
                                                </ChakraLink>
                                            </VStack>
                                            <Box
                                                w="full"
                                                p={6}
                                                borderRadius="lg"
                                                borderWidth={2}
                                                borderStyle="solid"
                                                borderColor="whiteAlpha.300"
                                            >
                                                <Image
                                                    borderRadius="lg"
                                                    src={Screenshot}
                                                    w="full"
                                                    h="full"
                                                />
                                            </Box>
                                        </VStack>
                                    </GridItem>
                                </Grid>
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
};
