import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ApplicationContext } from '../../../../base';
import { daterangeToString, VisualizationContext } from '../../../../domain';
import { SeriesEvent, Period } from '../../../../api/v2';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export interface TableVisualizationPopoverProps {
    context: Pick<ApplicationContext, 'formatter'>;
    period?: Period;
    comparison?: Period;
    title: string;
    value: string | React.ReactElement;
    label?: string;
    events?: Pick<SeriesEvent, 'text' | 'status'>[];
}

export const TableVisualizationPopover: React.FC<
    { children?: React.ReactNode | undefined } & TableVisualizationPopoverProps
> = ({ title, value, label, events = [], ...props }) => {
    return (
        <VStack p={3} alignItems="flex-start">
            <Text>{title}</Text>
            <VStack alignItems="flex-start" spacing={0}>
                {props.comparison && (
                    <>
                        <HStack spacing={2}>
                            <Text size="xs" variant="mute">
                                {daterangeToString(props.context, {
                                    start: props.comparison.start,
                                    end: props.comparison.end,
                                })}
                            </Text>
                            <ArrowForwardIcon color="whiteAlpha.600" />
                        </HStack>
                    </>
                )}
                {props.period && (
                    <Text size="xs" variant="mute">
                        {daterangeToString(props.context, {
                            start: props.period.start,
                            end: props.period.end,
                        })}
                    </Text>
                )}
            </VStack>
            <Text bg="blackAlpha.500" px={1}>
                {value}
            </Text>
            {events.length > 0 && (
                <VStack>
                    {events.map((event, index) => (
                        <Box key={index}>
                            <Text
                                py={0}
                                fontStyle="italic"
                                size="sm"
                                color="whiteAlpha.800"
                                dangerouslySetInnerHTML={{
                                    __html: event.text ?? '',
                                }}
                            />
                        </Box>
                    ))}
                </VStack>
            )}
        </VStack>
    );
};
