import { ValueFormatter } from 'src/v2/app/attributes';
import { EmailEntryEnhancer } from '../../../entrypoint';

export interface RenderMiddlewareConfig {
    formatter: ValueFormatter;
}

export function createFormatterMiddleware(
    init: RenderMiddlewareConfig
): EmailEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                createInsightController(controllerConfig) {
                    const controller =
                        config.controller.createInsightController(controllerConfig);
                    return {
                        useProps(...args) {
                            return {
                                ...controller.useProps(...args),
                                formatPeriod(period) {
                                    return init.formatter.format(
                                        'daterange',
                                        {
                                            from: period.start,
                                            to: period.end,
                                        },
                                        {
                                            notation: 'long',
                                        }
                                    );
                                },
                                formatDate(date) {
                                    return init.formatter.format('date', date, {
                                        notation: 'long',
                                    });
                                },
                            };
                        },
                    };
                },
                createReportController(controllerConfig) {
                    const controller =
                        config.controller.createReportController(controllerConfig);
                    return {
                        useProps(context, definition, payload) {
                            return {
                                ...controller.useProps(context, definition, payload),
                                formatPeriod(period) {
                                    return init.formatter.format(
                                        'daterange',
                                        {
                                            from: period.start,
                                            to: period.end,
                                        },
                                        {
                                            notation: 'long',
                                        }
                                    );
                                },
                                formatDate(date) {
                                    return init.formatter.format('date', date, {
                                        notation: 'long',
                                    });
                                },
                            };
                        },
                    };
                },
            },
        });
    };
}
