import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button as ChakraButton,
    ButtonProps as ChakraButtonProps,
    IconButtonProps,
    BoxProps,
    IconButton as ChakraButtonIcon,
    IconButtonProps as ChakraButtonIconProps,
    // Button as ChakraButton,
    // ButtonProps as ChakraButtonProps,
    // IconButtonProps,
    // TooltipProps,
    Tooltip,
} from '@chakra-ui/react';
import { isPromiseLike } from '../../utils/isPromise';
import { popInternal } from './children';
import { TooltipProps } from '../tooltip';

export interface ButtonProps extends Omit<ChakraButtonProps, 'isDisabled'> {
    children?: ChakraButtonProps['children'];
    allowClickWhenDisabled?: boolean;
    tooltipProps?: Pick<TooltipProps, 'label'>;
}

export const Button: React.FC<{ children?: React.ReactNode | undefined } & ButtonProps> =
    React.forwardRef(
        (
            {
                variant = 'solid',
                allowClickWhenDisabled,
                tooltipProps,
                onClick,
                disabled,
                ...props
            },
            ref
        ) => {
            const mountedRef = useRef<boolean>(false);
            const [internal, rest] = popInternal(props.children);
            const [isLoading, setIsLoading] = useState(false);

            useEffect(() => {
                mountedRef.current = true;
                return () => {
                    mountedRef.current = false;
                };
            }, []);

            const handleClick: IconButtonProps['onClick'] = async (event) => {
                const maybePromise = onClick?.(event);
                if (isPromiseLike(maybePromise)) {
                    setIsLoading(true);
                    try {
                        await maybePromise;
                    } finally {
                        // hack to avoid dangling component references
                        // after unmount
                        if (mountedRef.current) {
                            setIsLoading(false);
                        }
                    }
                }
                return maybePromise;
            };

            // this hack is beccause chakra does not support click handlers for disabled buttons
            const isButtonActuallyDisabled = !allowClickWhenDisabled && disabled;

            const disabledStyle: ChakraButtonProps = {
                opacity: 0.4,
            };

            const styleProps: ChakraButtonProps = {
                ...(disabled ? disabledStyle : {}),
                _hover: disabled ? { ...props._hover, ...disabledStyle } : props._hover,
                _focus: disabled ? { ...props._focus, ...disabledStyle } : props._focus,
                _active: disabled
                    ? { ...props._active, ...disabledStyle }
                    : props._active,
            };

            const Wrapper: React.FC<
                { children?: React.ReactNode | undefined } & {
                    children?: React.ReactNode;
                }
            > = useMemo(
                () =>
                    ({ children }) =>
                        tooltipProps?.label ? (
                            <Tooltip label={tooltipProps.label}>
                                <span>{children}</span>
                            </Tooltip>
                        ) : (
                            <>{children}</>
                        ),
                [tooltipProps]
            );

            // this distinction is to allow click handlers on disabled buttons
            // which is not possibly by default
            const actuallyDisabled = disabled && !allowClickWhenDisabled;

            const sharedProps: Partial<ChakraButtonProps & ChakraButtonIconProps> = {
                ...styleProps,
                isLoading,
                onClick: handleClick,
                opacity: disabled ? 0.66 : undefined,
                disabled: actuallyDisabled,
                isDisabled: actuallyDisabled,
                cursor: disabled && allowClickWhenDisabled ? 'pointer' : undefined,
                _hover: disabled
                    ? { bg: styleProps.bg, color: styleProps.color }
                    : undefined,
            };

            if (props.leftIcon && !props.children) {
                return (
                    <Wrapper>
                        <ChakraButtonIcon
                            ref={ref}
                            {...sharedProps}
                            aria-label="button"
                            icon={props.leftIcon}
                        />
                    </Wrapper>
                );
            }

            if (allowClickWhenDisabled && disabled) {
                return (
                    <Wrapper>
                        <Box position="relative" cursor="pointer">
                            <ChakraButton
                                variant={variant}
                                ref={ref}
                                {...sharedProps}
                                {...props}
                                w="full"
                                children={rest}
                            />
                            {internal}
                        </Box>
                    </Wrapper>
                );
            }

            return (
                <Wrapper>
                    <Box position="relative">
                        <ChakraButton
                            variant={variant}
                            ref={ref}
                            {...sharedProps}
                            {...props}
                            w="full"
                            children={rest}
                        />
                        {internal}
                    </Box>
                </Wrapper>
            );
        }
    );
