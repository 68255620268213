import React from 'react';
import { PeerViewConfig } from '../../base';
import { PeerMemberEditViewProps } from './peerMemberEditProps';
import { PeerCompanyEditViewProps } from '../../company';

export function createPeerMemberEditView(
    config: PeerViewConfig & {
        company: React.FC<
            { children?: React.ReactNode | undefined } & PeerCompanyEditViewProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & PeerMemberEditViewProps> {
    const { company: CompanyView } = config;
    return (props) => {
        return <CompanyView {...props.company} />;
    };
}
