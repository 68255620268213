import { MappingCreateDto } from '../../../api/asset/mapping';
import { MappingConnection } from '../../../domain/assets';
import {
    MappingConnecitonBulkItemKind,
    MappingConnectionAdapter,
} from '../../../app/assets';
import { MappingConnectionImplConfig } from './mappingImplConfig';
import { chain } from 'lodash';

export function createMappingConnectionImpl(
    config: MappingConnectionImplConfig
): MappingConnectionAdapter {
    const { api } = config;
    // delete first, then create
    const BULK_ORDER: MappingConnecitonBulkItemKind[] = ['remove', 'create'];
    return {
        async find(context, query) {
            const response = await api.asset.mappings.list(context, {
                integrations: query.integrations.map((item) => item.id),
            });
            return {
                total: response.data.length,
                items: response.data.map(
                    (item): MappingConnection => ({
                        id: item.id,
                        integration: {
                            id: item.integration_id,
                        },
                        asset: {
                            id: item.asset_id,
                        },
                        entity: {
                            key: item.entity_id,
                            type: item.id_type,
                        },
                        createdAt: item.created_at,
                    })
                ),
                limit: query.limit!,
            };
        },
        async create(context, props) {
            const payload: MappingCreateDto = {
                entity_id: props.mappable.key,
                id_type: props.mappable.type,
                asset_id: props.asset.id,
                exclude: false,
                integration_id: props.mappable.integrationId,
            };
            const response = await api.asset.mappings.create(context, payload);
            console.log('mapping created', response);
            return;
        },
        async delete(context, entity) {
            console.log('deleting mapping', entity.id);
            throw new Error('not impl');
        },
        async bulk(context, bulk) {
            const ordered = chain(bulk.items)
                .orderBy(
                    (item) =>
                        BULK_ORDER.findIndex((candidate) => candidate === item.kind),
                    'asc'
                )
                .value();

            const scheduledIntegrations: number[] = [];

            for (const item of ordered) {
                if (item.kind === 'create') {
                    console.log('creating mapping', item.item);
                    const payload: MappingCreateDto = {
                        entity_id: item.item.entity.key,
                        id_type: item.item.entity.type,
                        asset_id: item.item.asset.id,
                        exclude: false,
                        integration_id: item.item.integration.id,
                    };
                    const response = await api.asset.mappings.create(context, payload);
                    console.log('mapping created', response);
                    scheduledIntegrations.push(payload.integration_id);
                }
                if (item.kind === 'remove') {
                    console.log('deleting mapping', item.item.id);
                    const response = await api.asset.mappings.delete(
                        context,
                        item.item.id
                    );
                    console.log('mapping deleted', response);
                }
            }

            for (const integrationId of scheduledIntegrations) {
                console.log('starting sync for integration', integrationId);
                const response = api.asset.datasyncs
                    .create(context, {
                        integration_id: integrationId,
                    })
                    .then((response) => {
                        // NOTE we don't await to avoid partial commits
                        console.log('sync response', response);
                    });
            }
            return;
        },
    };
}
