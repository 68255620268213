import { QueryKey, useQuery } from '@tanstack/react-query';
import { OrganizationScope } from '../../platform';
import { MappableEntityAdapter } from './entityAdapter';
import { MappableEntityQuery } from './entityQuery';
import { MappableEntityRepository } from './entitytInterface';

export function createMappableEntityRepository(
    adapter: MappableEntityAdapter
): MappableEntityRepository {
    function getPrefix(context: OrganizationScope, query: MappableEntityQuery): QueryKey {
        return [
            'mappables',
            context.organization,
            ...query.integrations.map((item) => item.id),
            query.orderBy,
        ];
    }
    return {
        useFind(context, query, options) {
            const applied: MappableEntityQuery = {
                ...query,
                orderBy: query.orderBy ?? [{ key: 'name', direction: 'asc' }],
            };
            const result = useQuery({
                ...options,
                queryKey: getPrefix(context, applied),
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
            });

            return result;
        },
    };
}
