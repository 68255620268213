import moment from 'moment';
import { keyBy } from 'lodash';
import { assert } from '../../../../util/assert';
import { QueryResponse, ViewEntity } from '../../../../domain/query';
import { LineVisualization } from '../../../../domain/visualization';
import {
    LineVisualizationResult,
    LineVisualizationEntry,
    VisualizationResultSeries,
} from '../../../../domain/visualization/result';
import { AnyResolvedType } from '../../../../domain/attributes';

export function buildLineResult(
    view: Pick<ViewEntity<AnyResolvedType>, 'id' | 'columns'>,
    visualization: LineVisualization,
    response: QueryResponse
): LineVisualizationResult {
    const [partitionKey] = response.metadata.partition;
    const [scalarKey] = response.metadata.scalar;
    const [metric] = visualization.metrics;
    assert(partitionKey, `no partition key found for view ${view.id}`);
    assert(response.metadata.period, 'missing metadata period');
    return {
        kind: 'line',
        dimensions: [],
        type: metric.type,
        period: {
            from: response.metadata.period.start,
            to: response.metadata.period.end,
        },
        schema: keyBy(view.columns, (item) => item.key),
        series: response.queries.flatMap((query) => {
            return query.series.items.map(
                (series): VisualizationResultSeries<LineVisualizationEntry> => {
                    return {
                        key: series.name.toLocaleLowerCase(),
                        name: series.name,
                        data: series.data.map((row) => {
                            const dateString = row[partitionKey];
                            const value = row[scalarKey];
                            assert(typeof dateString === 'string', () => {
                                console.info(row);
                                return `date key '${partitionKey}' found in row`;
                            });
                            assert(typeof value === 'number' || value === null, () => {
                                console.error(row);
                                return `no value found in row`;
                            });
                            const date = moment(dateString).toDate();
                            return {
                                date,
                                value,
                                secondary: null,
                                key: {},
                            };
                        }),
                    };
                }
            );
        }),
    };
}
