import { observer } from 'mobx-react';
import React from 'react';
import { SegmentStateProvider } from '../segment';
import {
    CompetitiveStatusController,
    CompetitiveStatusLoader,
} from './competitiveInterface';
import {
    AnalysisCompetitiveSetControllerProps,
    AnalysisCompetitiveSetViewProps,
} from './competitiveProps';

export function createCompetitiveContainer(
    states: SegmentStateProvider,
    loader: CompetitiveStatusLoader,
    controller: CompetitiveStatusController,
    Component: React.FC<
        { children?: React.ReactNode | undefined } & AnalysisCompetitiveSetViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & AnalysisCompetitiveSetControllerProps
> {
    return observer((props) => {
        const state = states.useState(props.context);
        const data = loader.useData(props.context, state, props);
        const viewProps = controller.useProps(data, props);
        return <Component {...viewProps} />;
    });
}
