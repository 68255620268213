import { useMemo } from 'react';
import { assert } from '../../../util/assert';
import { periodToRange } from '../../../../domain';
import { getMovementColorScheme, getMovementDirection } from '../../../domain/metrics';
import { VisualizationMovementItemControllerConfig } from './movementConfig';
import { VisualizationMovementItemController } from './movementInterface';
import { MovementDirection, VisualizationMovementItemViewProps } from './movementProps';

export function createVisualizationMovementItemController(
    config: VisualizationMovementItemControllerConfig
): VisualizationMovementItemController {
    const {
        infra: { formatter },
    } = config;

    return {
        useProps(props): VisualizationMovementItemViewProps {
            const itemProps = useMemo((): VisualizationMovementItemViewProps => {
                const date = periodToRange(null, props.item.calculation.period);
                const comparison = periodToRange(
                    props.item.calculation.period,
                    props.item.calculation.comparison
                );

                const direction = getMovementDirection(props.segment);
                const colorScheme = getMovementColorScheme(
                    props.item.metric,
                    props.segment
                );

                return {
                    metric: {
                        label: props.item.metric.name,
                    },
                    plugin: {
                        label: props.item.plugin.name,
                        iconUrl: props.item.plugin.iconUrl ?? '',
                    },
                    date: {
                        label: formatter.format(
                            'daterange',
                            {
                                from: date.start,
                                to: date.end,
                            },
                            { notation: 'short' }
                        ),
                    },
                    comparison: {
                        label: formatter.format(
                            'daterange',
                            {
                                from: comparison.start,
                                to: comparison.end,
                            },
                            { notation: 'short' }
                        ),
                    },
                    direction,
                    control: {
                        colorScheme: colorScheme,
                    },
                    description: {
                        label: 'asdadsdda',
                    },
                    value: {
                        current: {
                            value: props.segment.value.base,
                            label: props.segment.value.base
                                ? formatter.format(
                                      props.item.metric.type,
                                      Math.abs(props.segment.value.base)
                                  )
                                : 'N/A',
                        },
                        previous: {
                            value: props.segment.value.comparison,
                            label: props.segment.value.comparison
                                ? formatter.format(
                                      props.item.metric.type,
                                      Math.abs(props.segment.value.comparison)
                                  )
                                : 'N/A',
                        },
                        change: {
                            value: props.segment.value.change,
                            label: props.segment.value.change
                                ? formatter.format(
                                      'percent',
                                      Math.abs(props.segment.value.change)
                                  )
                                : 'N/A',
                        },
                    },
                };
            }, [props.item]);
            return {
                ...itemProps,
            };
        },
    };
}
