import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { LocalStorageHookConfig } from './localStorageConfig';
import { LocalStorageHook } from './localStorageInterface';
import { LocalStorageModel } from './localStorageModel';
import { LocalStorageEntrySchema } from './localStorageSchema';

interface State extends Record<string, LocalStorageModel> {}

export function createLocalStorageHook<TValue>(
    config: LocalStorageHookConfig<TValue>
): LocalStorageHook<TValue> {
    const { initialValue } = config;

    return (key: string | null) => {
        const [state, setState] = useLocalStorage<LocalStorageModel>(config.storageKey, {
            // key,
            value: initialValue,
        });

        // useEffect(() => {
        //     if (state?.key !== key) {
        //         console.info('key changed, re-initializing state...');
        //         setState({
        //             key,
        //             value: initialValue,
        //         });
        //     }
        // }, [state, initialValue]);

        // const entry = state?.[key];
        // const value = (entry?.value ?? null ?? initialValue) as TValue;
        // const onChange = createOnChange(key);

        const applied: TValue = (state?.value as TValue) ?? initialValue;

        const handleChange = (value: TValue) => {
            setState({
                // key,
                value,
            });
        };

        const handleClear = () => {
            console.log('resetting local storage for key', config.storageKey);
            window.localStorage.removeItem(config.storageKey);
        };

        return [applied, handleChange, handleClear];

        // return [initialValue, () => {}];
        // const readValue = (): TValue | null => {
        //     // Get from local storage by key
        //     const item = window.localStorage.getItem(config.storageKey);
        //     const parsed = item
        //         ? LocalStorageEntrySchema.safeParse(JSON.parse(item))
        //         : null;
        //     if (parsed?.success) {
        //         const now = new Date().getTime();
        //         // If the current time is greater than the expiry time, remove the item from storage and return null
        //         if (now > parsed.data.expiry) {
        //             console.info('DEBUG evicting expired key...', config.storageKey);
        //             window.localStorage.removeItem(config.storageKey);
        //             return null;
        //         }
        //         if (key && parsed.data.key !== key) {
        //             console.info('DEBUG hash changed, resetting state...', key);
        //             window.localStorage.removeItem(config.storageKey);
        //             return null;
        //         }
        //         return (parsed.data.value ?? null) as TValue | null;
        //     }
        //     return initialValue;
        // };

        // const [storedValue, setStoredValue] = useState<TValue | null>(readValue);

        // const setValue = (value: TValue | null | ((val: TValue) => TValue)) => {
        //     console.log('DEBUG setting value', value);
        //     const item = window.localStorage.getItem(config.storageKey);
        //     const parsed = item
        //         ? LocalStorageEntrySchema.safeParse(JSON.parse(item))
        //         : null;
        //     // Save state
        //     try {
        //         // Allow value to be a function so we have the same API as useState
        //         const valueToStore =
        //             value instanceof Function
        //                 ? // @ts-expect-error
        //                   value(storedValue)
        //                 : value;
        //         // Save state
        //         setStoredValue(valueToStore);
        //         // Save to local storage

        //         const expiry = new Date().getTime() + config.expireMs;

        //         if (parsed?.success) {
        //             console.log('DEBUG before', parsed.data.expiry);
        //             console.log('DEBUG after', expiry);
        //             const diffMs = expiry - parsed.data.expiry
        //             console.log('DEBUG diff', expiry - parsed.data.expiry);
        //         }

        //         const appliedKey =
        //             key ?? (parsed && parsed?.success ? parsed.data.key : null);

        //         if (!appliedKey) {
        //             return;
        //         }

        //         const entry: LocalStorageModel = {
        //             key: appliedKey,
        //             value: valueToStore,
        //             expiry,
        //         };

        //         window.localStorage.setItem(config.storageKey, JSON.stringify(entry));
        //     } catch (error) {
        //         console.error(
        //             `Error storing value for key "${config.storageKey}" in localStorage`,
        //             error
        //         );
        //     }
        // };

        // const handleStorageChange = useCallback(
        //     (event: StorageEvent) => {
        //         if (event.newValue && event.key === config.storageKey) {
        //             console.log('DEBUG storage key changed', event.key, event.newValue);
        //             const parsed = LocalStorageEntrySchema.safeParse(
        //                 JSON.parse(event.newValue)
        //             );
        //             if (parsed.success) {
        //                 console.log(
        //                     'DEBUG storage changed, updating value',
        //                     parsed.data.value
        //                 );
        //                 setValue(parsed.data.value as TValue);
        //             } else {
        //                 console.error(parsed.error);
        //             }
        //         }
        //     },
        //     [setValue, readValue]
        // );

        // useEffect(() => {
        //     setValue(readValue());
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, []);

        // useEffect(() => {
        //     // The readValue function will act as an event handler for localStorage events

        //     console.log('DEBUG subscribing storage');
        //     // Set up the event listener for localStorage changes
        //     window.addEventListener('storage', handleStorageChange);

        //     // Clean up the event listener
        //     return () => {
        //         window.removeEventListener('storage', handleStorageChange);
        //     };
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, []);

        // return [storedValue ?? initialValue, setValue];
    };
}
