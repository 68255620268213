import { useQuery } from '@tanstack/react-query';
import { PeerStatusAdapter } from './peerStatusAdapter';
import { castStatusQueryToExample } from './peerStatusCast';
import { PeerStatusEntity } from './peerStatusEntity';
import { PeerStatusRepository } from './peerStatusInterface';

export function createPeerStatusRepository(
    adapter: PeerStatusAdapter
): PeerStatusRepository {
    return {
        useLookup(context, query, options) {
            return useQuery({
                queryKey: [
                    'peer-status',
                    context.organization,
                    context.workspace,
                    query?.plugin,
                    query?.conditions,
                ],
                async queryFn() {
                    if (!query) {
                        return;
                    }
                    const response = await adapter.findOne(context, query);
                    const entity: PeerStatusEntity = {
                        plugin: query.plugin,
                        transparency: response,
                        example: castStatusQueryToExample(query),
                    };
                    return entity;
                },
                ...options,
            });
        },
    };
}
