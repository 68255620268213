import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { periodToRange } from '../../../../../domain';
import { ReportSelectFormValues, ReportSelectFormValuesSchema } from './form';
import { ReportSelectFormProvider } from './reportSelectInterface';

export function createReportSelectFormProvider(): ReportSelectFormProvider {
    const PERIOD = periodToRange(null, {
        interval: 'week',
        amount: 4,
    });
    return {
        useForm(context, data, options) {
            const form = useForm<ReportSelectFormValues>({
                mode: 'onSubmit',
                resolver: zodResolver(ReportSelectFormValuesSchema),
                defaultValues: {
                    ...options?.defaultValues,
                },
            });

            useEffect(() => {
                if (data.item.data?.items) {
                    const [defaultItem] = data.item.data?.items;
                    if (defaultItem) {
                        form.reset({
                            mode: 'preset',
                            asset: defaultItem.asset.id,
                            ...options?.defaultValues,
                        });
                    } else {
                        form.reset({
                            mode: 'new',
                            name: '',
                            url: '',
                            ...options?.defaultValues,
                        });
                    }
                }
            }, [data.item.data?.items]);

            return form;
        },
    };
}
