import { Box, BoxProps, StackProps, VStack } from '@chakra-ui/react';
import React from 'react';
import ScrollFade from '@benestudioco/react-scrollfade';
import ErrorView from '../../../../components/ErrorView/ErrorView';
import { ErrorInfo } from '../../../../models/Errors';

export interface IntegrationCardListContainerProps extends StackProps {
    error?: ErrorInfo;
}

export const IntegrationCardListContainer: React.FC<
    { children?: React.ReactNode | undefined } & IntegrationCardListContainerProps
> = ({ error, children, ...props }) => {
    return (
        <Box overflow="auto" width="100%">
            <ScrollFade />
            {error && <ErrorView {...error} />}
            <VStack alignItems="flex-start" overflow="auto" {...props}>
                {children}
            </VStack>
        </Box>
    );
};
