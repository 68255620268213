import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { HomeRouteController } from '../../../../route';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { HomeDestinationController } from '../../../home';
import { AnyHomeDestinationTrackingEvent } from './destinationTrackingEvent';

export function createHomeDestinationTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeDestinationTrackingEvent>();
            return tracker;
        }

        const Tracker: React.FC<
            { children?: React.ReactNode | undefined } & { onLoad(): void }
        > = (props) => {
            const [tracked, setTracked] = useState(false);
            const { ref, inView } = useInView();

            // We want to track when the component is actually rendered which
            // is not the same as when it's loaded because of suspense
            useEffect(() => {
                if (inView && !tracked) {
                    props.onLoad();
                    setTracked(true);
                }
            }, [inView, tracked]);
            return <Box as="span" ref={ref}></Box>;
        };

        function enhanceDestinationController(
            controller: HomeDestinationController
        ): HomeDestinationController {
            return {
                ...controller,
                useProps(...args) {
                    const [queries] = args;
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();

                    const handleLoad = () => {
                        if (queries.item.status !== 'loaded') {
                            return;
                        }
                        tracker.track('home_view_destination_list_viewed', {
                            destination_definition_ids: queries.item.data.items.map(
                                (item) => item.definition.id
                            ),
                        });
                    };

                    return {
                        ...viewProps,
                        children: [
                            ...(viewProps.children ?? []),
                            <Tracker onLoad={handleLoad} />,
                        ],
                        getItemProps(...args) {
                            const [item] = args;
                            const itemProps = viewProps.getItemProps(...args);
                            return {
                                ...itemProps,
                                connect: {
                                    ...itemProps.connect,
                                    onClick(...args) {
                                        tracker.track(
                                            'home_view_destination_item_connect_click',
                                            {
                                                destination_definition_id:
                                                    item.definition.id,
                                            }
                                        );
                                        return itemProps.connect.onClick(...args);
                                    },
                                },
                            };
                        },
                    };
                },
            };
        }

        function enhanceHomeController(
            controller: HomeRouteController
        ): HomeRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();
                    return {
                        ...viewProps,
                        getDestinationProps(...args) {
                            const destinationProps = viewProps.getDestinationProps(
                                ...args
                            );
                            return {
                                ...destinationProps,
                                onHide(...args) {
                                    tracker.track('home_view_destination_list_hidden', {
                                        destination_definition_ids:
                                            destinationProps.items.map(
                                                (item) => item.definition.id
                                            ),
                                    });
                                    return destinationProps.onHide(...args);
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                home: {
                    ...config.controller.home,
                    createDestination(...args) {
                        const controller = config.controller.home.createDestination(
                            ...args
                        );
                        return enhanceDestinationController(controller);
                    },
                },
            },
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            root: {
                                ...routeConfig.providers.root,
                                createController(...args) {
                                    const controller =
                                        routeConfig.providers.root.createController(
                                            ...args
                                        );
                                    return enhanceHomeController(controller);
                                },
                            },
                        },
                    });
                },
            },
        });

        return instance;
    };
}
