import { PageIds } from '../../../config';
import { SettingGroupConfig } from './groupsSettingsConfig';
import { SettingGroupController } from './groupsSettingsInterface';

export function createSettingsGroupController(
    config: Pick<SettingGroupConfig, 'settings' | 'controller'>
): SettingGroupController {
    const {
        controller: viewController,
        settings: {
            api: { controller: pageController },
        },
    } = config;
    return {
        useProps(context, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ACCOUNT_PRIVACY,
                    breadcrumbs: [
                        {
                            label: 'Privacy',
                        },
                    ],
                },
            });
            const viewProps = viewController.useProps(context, {
                data: props.item,
            });
            return {
                getPageProps() {
                    return pageProps;
                },
                getFormProps() {
                    return viewProps;
                },
            };
        },
    };
}
