import { QueryFunction } from '@tanstack/react-query';
// import { listPreferences } from '../../../api/integration';
import { fetchIntegrationDefs, fetchIntegrations } from '../../../api/integrations';
import { Integration, IntegrationPreference } from '../../../models';
import { UserIntegrationDefinition } from '../../../domain';
import { ApplicationContext } from '../..';

export function createListHandler(context: ApplicationContext): QueryFunction<UserIntegrationDefinition[]> {
    return async () => {
        const defResponse = await fetchIntegrationDefs(context.api);

        // const preferenceResponse = await listPreferences(context.api);
        // const preferencesByDefinitionId = preferenceResponse.reduce(
        //     (acc, preference) => ({ ...acc, [preference.definition_id]: preference }),
        //     {} as Record<string, IntegrationPreference | undefined>
        // );

        const integrationResponse = await fetchIntegrations(undefined, context.api);
        const integrationsByDefinitionId = integrationResponse.data.items.reduce(
            (acc, integration) => ({
                ...acc,
                [integration.implementation.integration_definition_id]: [
                    ...(acc[integration.implementation.integration_definition_id] ?? []),
                    integration,
                ],
            }),
            {} as Record<string, Integration[] | undefined>
        );

        return defResponse.data.map<UserIntegrationDefinition>((definition) => {
            return {
                ...definition,
                // is_ignored: preferencesByDefinitionId[definition.id]?.is_ignored ?? false,
                reconnectedItems: (definition.counts?.count ?? 0) - (definition.counts?.count_connected ?? 0),
                integrations: integrationsByDefinitionId[definition.id] ?? [],
            };
        });
    };
}
