import { z } from 'zod';

// Item

export const BillingPaymentMethodCard = z.object({
    brand: z.string(),
    display_brand: z.string(),
    exp_month: z.number(),
    exp_year: z.number(),
    last4: z.string(),
});

export const BillingCardPaymentMethodDtoSchema = z.object({
    id: z.string(),
    kind: z.literal('card'),
    card: BillingPaymentMethodCard,
});

export const BillingCustomPaymentMethodDtoSchema = z.object({
    id: z.string(),
    kind: z.literal('custom'),
    type: z.string(),
});

export const BillingAnyPaymentMethodDtoSchema = z.discriminatedUnion('kind', [
    BillingCardPaymentMethodDtoSchema,
    BillingCustomPaymentMethodDtoSchema,
]);

export const BillingPaymentMethodListDtoSchema = z.object({
    data: z.array(BillingAnyPaymentMethodDtoSchema),
});
