import { ApplicationLinks } from '../../../config';
import { PeerControllerConfig } from '../base';
import { CompanyListBoxController } from './companyListBoxInterface';
import { CompanyListBoxViewProps } from './compantListBoxProps';

export function createCompanyListBoxController(
    config: PeerControllerConfig
): CompanyListBoxController {
    const {
        provider: { createFormController },
    } = config;

    const { useProps: useFormProps } = createFormController({
        submitOnEnter: false,
    });

    return {
        useProps(context, item, props): CompanyListBoxViewProps {
            const form = useFormProps(props.form);
            return {
                form,
                match: props.match,
                select: props.select,
                anchor: {
                    documentation: ApplicationLinks.Documentation.CompetitiveSet,
                },
            };
        },
    };
}
