import { AxiosInstance } from 'axios';
import qs from 'qs';
import { applyV2Dashboards } from '../../../config/feature/v2';
import {
    DashboardListResponse,
    DashboardListFlags,
    DashboardFlagQueryOperator,
} from './dashboardListModel';
import { AuthenticationScheme } from 'src/v2';

export async function listDashboards(
    api: AxiosInstance,
    assetIds: number[],
    dashboard?: string,
    pageSize?: number,
    operator?: DashboardFlagQueryOperator,
    flags?: Array<DashboardListFlags>,
    options?: {
        apiKey?: string;
        organizationId?: number;
        auth?: AuthenticationScheme;
        callerName?: string;
    }
): Promise<DashboardListResponse> {
    const params = new URLSearchParams(window.location.search);
    const [assetId] = assetIds;
    const query: Record<string, any> = {
        asset_ids: assetIds,
        dashboard: dashboard,
        is_published: params.get('published') ?? true,
        page_size: pageSize || 100,
    };
    if (flags) {
        query['flags'] = flags;
    }
    if (operator) {
        query['flag_operator'] = operator;
    }

    if (options?.apiKey) {
        query['api-key'] = options.apiKey;
    }

    if (options?.organizationId) {
        query['organization'] = options.organizationId;
    }

    let headers = {};

    if (options?.auth?.kind === 'legacy') {
        await options.auth.store.waitAuthenticated();
        headers = {
            ...headers,
            Authorization: `bearer ${options.auth.store.authToken}`,
        };
    }
    if (options?.callerName) {
        headers = {
            ...headers,
            'x-caller-name': options.callerName,
        };
    }

    const response = await api.get<DashboardListResponse>('/api/v2/dashboards', {
        headers,
        params: query,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
    if (dashboard) {
        // when filtering by a specific dashboard do not scope
        return response.data;
    }
    return applyV2Dashboards(assetId, response.data);
}
