import { Image, Box, VStack, IconButton, Button } from '@chakra-ui/react';
import React from 'react';
import { PriceDto } from '../../../../api';
import { AnyPersona } from '../../../../v2/domain/billing';
import { CTA_INAPP_BY_PLAN, SVG_BY_PLAN, SVG_BY_PLAN_CATEGORY } from '../../../../config';
import { PlanCategoryItem, PlanCategoryList, PlanCategoryListProps } from '../component';
import { isRecommendedPlan } from '../planHelper';
import { AnyPlan, PlanCategory, PricingPlan } from '../planType';
import { ThresholdSelector } from '../component/ThresholdSelector';
import { ArrowBackIcon } from '@chakra-ui/icons';

export interface PlanListViewProps
    extends Pick<
        PlanCategoryListProps,
        'containerProps' | 'variant' | 'subtitle' | 'caption'
    > {
    title?: string;
    current: AnyPlan | null;
    categories: PlanCategory[];
    onNotAdvertising(): void;
    onDemo(): void;
    onSelect(plan: AnyPlan): Promise<void>;
    excludeDefault?: boolean;
    getCta?: (plan: AnyPlan) => string | undefined;
    getCategoryCta?: (plan: PlanCategory) => string | undefined;
    persona: Pick<AnyPersona, 'kind'>;
}

export const PlanListView: React.FC<
    { children?: React.ReactNode | undefined } & PlanListViewProps
> = ({
    title = 'Pricing',
    excludeDefault = false,
    getCta = () => 'Upgrade',
    getCategoryCta = () => 'Upgrade',
    onNotAdvertising,
    categories,
    onDemo,
    onSelect,
    containerProps = { paddingY: '32px' },
    ...restProps
}) => {
    const [selectedCategory, setSelectedCategory] = React.useState<PlanCategory | null>(
        null
    );

    const handleSelect = (plan: AnyPlan) => {
        const cta = CTA_INAPP_BY_PLAN[plan.id];
        if (cta && cta.href) {
            window.open(cta.href, '_blank');
        } else {
            return onSelect(plan);
        }
    };

    const handleCategorySelect = (category: PlanCategory) => {
        if (category.plans.length == 1) {
            handleSelect(category.plans[0]);
        } else {
            setSelectedCategory(category);
        }
    };

    return (
        <VStack color="black" width="100%" spacing={8} {...containerProps}>
            {selectedCategory && selectedCategory.billingUnit ? (
                <>
                    <Button
                        alignSelf="flex-start"
                        bg="transparent"
                        aria-label="back"
                        onClick={setSelectedCategory.bind(null, null)}
                        leftIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                    <ThresholdSelector
                        category={selectedCategory}
                        billingUnit={selectedCategory.billingUnit}
                        currentPlanId={restProps.current?.id}
                        onSelect={handleSelect}
                        getCta={getCta}
                        onNotAdvertising={onNotAdvertising}
                    />
                </>
            ) : (
                <PlanCategoryList
                    {...restProps}
                    defaultValue={{
                        personaKind: restProps.persona.kind,
                    }}
                    currentPlan={restProps.current}
                    title={title}
                    categories={categories}
                    itemRenderer={(category, variant, index) => (
                        <PlanCategoryItem
                            index={index}
                            category={category}
                            variant={variant}
                            current={
                                restProps.current?.id
                                    ? category.plans.some(
                                          (x) => x.id == restProps.current?.id
                                      )
                                    : undefined
                            }
                            caption={category.caption ?? undefined}
                            onSelect={handleCategorySelect.bind(null, category)}
                            recommended={isRecommendedPlan(category)}
                            imageProps={{
                                src: SVG_BY_PLAN_CATEGORY[category.key],
                            }}
                            buttonProps={{
                                children: getCategoryCta(category),
                            }}
                        />
                    )}
                    onBookDemo={onDemo}
                >
                    <Box display="none">
                        {/* hack to preload SVGS to avoid flickering */}
                        {Object.values(SVG_BY_PLAN).map((src, index) => (
                            <Image key={index} src={src} />
                        ))}
                    </Box>
                </PlanCategoryList>
            )}
        </VStack>
    );
};
