import { InsightDefinitionAdapter } from '../../../app/insight';
import { InsightDefinitionImplConfig } from './definitionConfig';

export function createInsightDefinitionImpl(
    config: InsightDefinitionImplConfig
): InsightDefinitionAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.definitions.list(context, {
                asset: query.assets,
            });

            let acc = response.data;

            if (query.category !== undefined) {
                acc = acc.filter((candidate) => candidate.category === query.category);
            }

            if (query.limit !== undefined) {
                acc = acc.slice(0, query.limit);
            }

            return {
                limit: 1000,
                total: acc.length,
                items: acc,
            };
        },
    };
}
