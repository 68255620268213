import { createAssetPermissionsMiddleware } from './asset';
import { createOrganizationPermissionsMiddleware } from './organization';
import { createPermissionPageMiddleware } from './page';
import { createPermissionBillingMiddleware } from './billing';
import { PermissionMiddlewareConfig } from './permissionConfig';
import { PermissionMiddleware } from './permissionInterface';
import { createPermissionGroupsMiddleware } from './group';

export function createPermissionMiddleware(
    config: PermissionMiddlewareConfig
): PermissionMiddleware[] {
    return [
        createPermissionPageMiddleware(),
        createPermissionGroupsMiddleware(),
        ...createPermissionBillingMiddleware(),
        ...createOrganizationPermissionsMiddleware(),
        ...createAssetPermissionsMiddleware(),
    ];
}
