import React from 'react';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyGroupPaywallEvent } from './billingAddonEvent';

export function createBillingAddonTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyGroupPaywallEvent>();
            return tracker;
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                groups: {
                    ...config.controller.groups,
                    // createGroupsSubscriptionAddon(adonProps) {
                    //     const retVal = config.controller.groups.createGroupsSubscriptionAddon(adonProps);

                    //     return {
                    //         useProps(context, data) {
                    //             const props = retVal.useProps(context, data);
                    //             const tracker = useTracker();

                    //             return {
                    //                 ...props,
                    //                 onSubscribe() {
                    //                     try {
                    //                         return props.onSubscribe();
                    //                     } finally {
                    //                         if(data.group) {
                    //                             tracker.track('addon_paywall_clicked', {
                    //                                 action: 'subscribe',
                    //                                 group_id: data.group.id,
                    //                                 group_membership: data.group.membership.kind,
                    //                                 group_name: data.group.name,
                    //                             });
                    //                         }
                    //                     }
                    //                 },
                    //                 onUnSubscribe() {
                    //                     try {
                    //                         return props.onUnSubscribe();
                    //                     } finally {
                    //                         if(data.group) {
                    //                             tracker.track('addon_paywall_clicked', {
                    //                                 action: 'unsubscribe',
                    //                                 group_id: data.group.id,
                    //                                 group_membership: data.group.membership.kind,
                    //                                 group_name: data.group.name,
                    //                             });
                    //                         }
                    //                     }
                    //                 },
                    //                 createPaymentMethod: {
                    //                     ...props.createPaymentMethod,
                    //                     mutate() {
                    //                         try {
                    //                             return props.createPaymentMethod.mutate();
                    //                         } finally {
                    //                             if(data.group) {
                    //                                 tracker.track('addon_paywall_clicked', {
                    //                                     action: 'add_payment_method',
                    //                                     group_id: data.group.id,
                    //                                     group_membership: data.group.membership.kind,
                    //                                     group_name: data.group.name,
                    //                                 });
                    //                             }
                    //                         }
                    //                     },
                    //                 }
                    //             };

                    //         },
                    //     }
                    // }
                },
            },
        });

        return instance;
    };
}
