import { observer } from 'mobx-react';
import React from 'react';
import { ReportContainerProps } from './reportProps';
import { ReportContainerConfig } from './reportConfig';

export const createReportContainer = (
    config: ReportContainerConfig
): React.FC<{ children?: React.ReactNode | undefined } & ReportContainerProps> => {
    const {
        hook: { useOrganization, useWorkspace, useAuth },
        controller,
        Component,
    } = config;
    return observer((props) => {
        const organization = useOrganization();
        const workspace = useWorkspace();
        const auth = useAuth();
        const viewProps = controller.useProps(
            {
                organization,
                workspace,
                auth,
            },
            props
        );
        return <Component {...viewProps} />;
    });
};
