import { StripeElementsOptions } from '@stripe/stripe-js';
import { Location } from 'react-router';
import {
    PaymentMethodEditAggregate,
    PaymentMethodEditOptions,
} from './paymentMethodEditModel';

export function buildElementsApperance(
    item: PaymentMethodEditAggregate,
    props: {
        primaryColor?: string;
        backgroundColor: string;
        baseColor: string;
        borderColor: string;
        placeholderColor: string;
        invalidColor: string;
    }
): StripeElementsOptions['appearance'] {
    return {
        theme: 'night',
        variables: {
            colorBackground: props.backgroundColor,
            colorPrimary: props.primaryColor,
            colorText: props.baseColor,
            colorTextPlaceholder: props.placeholderColor,
        },
        rules: {
            '.Input': {
                backgroundColor: props.backgroundColor,
                color: props.baseColor,
                borderColor: props.borderColor,
            },
            '.Input::placeholder': {
                color: props.placeholderColor,
            },
            // '.PaymentElement': {
            //     color: props.baseColor,
            // },
            // '.PaymentElement--focus': {
            //     borderColor: 'teal.500',
            //     boxShadow: 'outline',
            // },
            // '.PaymentElement--invalid': {
            //     color: props.invalidColor,
            // },
        },
    };
}

export function buildReturnUrl(props: {
    location: Location;
    window: Window;
    returnPath: string | null;
}) {
    if (props.returnPath) {
        const returnUrl = `${props.window.location.origin}${props.returnPath}`;
        return returnUrl;
    }

    // 1. Extract the current pathname (e.g., '/u/settings/payment/edit')
    const currentPath = props.location.pathname;

    // 2. Split the pathname into segments, filtering out empty strings
    const pathSegments = currentPath.split('/').filter((segment) => segment.length > 0);

    if (pathSegments.length === 0) {
        throw new Error('Invalid path: Cannot determine return URL from the root path.');
    }

    // 3. Remove the last segment to get the parent path
    pathSegments.pop(); // Removes 'edit' from '/u/settings/payment/edit'

    // 4. Reconstruct the parent path (e.g., '/u/settings/payment')
    const parentPath = '/' + pathSegments.join('/');

    // 5. Construct the full return URL by prepending the origin
    const returnUrl = `${props.window.location.origin}${parentPath}`;
    return returnUrl;
}

export function buildPaymenthMethodOptions(props: {
    returnPath: string | null;
}): PaymentMethodEditOptions {
    return { returnPath: props.returnPath ?? undefined };
}
