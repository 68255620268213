import { FormatterImpl } from '../../../hooks';
import { isAbsolutePeriod } from '../../../domain';
import { ValueFormatter, MapAsString } from '../../app/attributes';
import {
    isCurrencyType,
    isFloatType,
    isMovementType,
    isMovementValue,
    isPercentType,
    MovementValue,
    isDateRangeValue,
    isListType,
    isMaybePartialNumericRangeValue,
    isEnumType,
    AnyValue,
    isTreeType,
    flattenEnumMembers,
    isIntegerType,
} from '../../domain/attributes';

export function createIntlFormatter(): ValueFormatter {
    const legacy = new FormatterImpl();
    const instance: ValueFormatter = {
        format(type, value, options) {
            if (type === 'string' && typeof value === 'string') {
                return value;
            }
            if (
                (type === 'date' || type === 'datetime') &&
                value instanceof Date &&
                options?.relative
            ) {
                return legacy.timeago(value, {});
            }
            if ((type === 'date' || type === 'datetime') && value instanceof Date) {
                return legacy.date(value, { notation: options?.notation });
            }
            if (type === 'daterange' && isDateRangeValue(value) && value.to) {
                return legacy.daterange(
                    {
                        start: value.from,
                        end: value.to,
                    },
                    options
                );
            }
            if (isEnumType(type) && typeof value === 'string') {
                return type.members.find((item) => item.value === value)?.label ?? value;
            }
            if (isTreeType(type) && typeof value === 'string') {
                return (
                    flattenEnumMembers(type.members).find((item) => item.value === value)
                        ?.label ?? value
                );
            }
            if (isCurrencyType(type) && isMaybePartialNumericRangeValue(value)) {
                const left = instance.format(type, value.from, options);
                const right = instance.format(type, value.to, options);
                return `${left} - ${right}`;
            }
            if (isCurrencyType(type) && typeof value === 'number') {
                return legacy.currency(value, type.currency, {
                    notation: options?.notation,
                });
            }
            if (isFloatType(type) && typeof value === 'number') {
                return legacy.float(value);
            }
            if (isPercentType(type) && typeof value === 'number') {
                return legacy.percent(value, { decimals: 1 });
            }
            if (isListType(type) && Array.isArray(value)) {
                const isTruncated = options?.truncate && value.length > options.truncate;
                const included = options?.truncate
                    ? value.slice(0, options.truncate)
                    : value;
                const formatted = included.map((element) =>
                    instance.format(type.element, element, options)
                );
                const truncatedCount = value.length - included.length;
                let fragments = [...formatted];
                if (isTruncated) {
                    fragments = [...fragments];
                }

                let text = fragments.join(', ');
                if (truncatedCount > 0) {
                    text = `${text} and ${truncatedCount} more`;
                }
                return text;
            }
            if (isIntegerType(type) && typeof value === 'number') {
                return Math.floor(value).toString();
            }
            if (value === null) {
                return 'N/A';
            }
            if (type === 'unknown' && typeof value === 'string') {
                return value;
            }
            console.warn('unexpected type and value', type, value);
            return JSON.stringify(value);
        },
        formatMovement(type, value, options) {
            const prefix =
                options?.notation === 'long' && value.change
                    ? value.change > 0
                        ? '+'
                        : '-'
                    : null;
            return {
                base: instance.format(type.base, value.base, options),
                comparison: instance.format(type.base, value.comparison, options),
                change:
                    options?.notation === 'long' && value.change
                        ? [
                              prefix,
                              instance.format('percent', Math.abs(value.change), options),
                          ]
                              .filter(Boolean)
                              .join('')
                        : instance.format('percent', value.change, options),
            } as MapAsString<MovementValue>;
        },
    };
    return instance;
}
