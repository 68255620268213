import moment, { duration as momentDuration, unitOfTime } from 'moment';
import { Duration, DurationInterval } from './durationModel';

export function durationDifference(
    duration: Duration,
    interval: DurationInterval
): number {
    if (interval === 'quarter') {
        throw new Error(`not implemented for quarter`);
    }
    const dur = momentDuration(duration.amount, duration.interval);
    return dur.as(interval);
}
