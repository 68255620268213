import moment from 'moment';
import Papa from 'papaparse';
import { isCurrencyType, isDateType, isIntegerType } from '../../../../domain/attributes';
import { DatasetConfiguration } from '../../../../domain/datasets';

export function createTemplateContent(item: DatasetConfiguration) {
    const headers = item.schema.map((property) => property.key);
    const row1 = item.schema.map((property) => {
        if (isDateType(property.type)) {
            const now = new Date();
            return moment(now).startOf('month').format('YYYY-MM-DD');
        }
        if (isCurrencyType(property.type)) {
            return `5000`;
        }
        if (isIntegerType(property.type)) {
            return '100';
        }
        return 'some value';
    });
    const templateData = [headers, row1];
    const csvContent = Papa.unparse(templateData);
    return csvContent;
}
