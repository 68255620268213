import { IconType } from 'react-icons';
import {
    AiOutlineFilter,
    AiOutlineInfo,
    AiOutlineSearch,
    AiOutlineWarning,
} from 'react-icons/ai';
import { FiThumbsUp } from 'react-icons/fi';
import { Transparancy } from '../../../../models/Metadata';
import { ValueFormatter } from '../../../app/attributes';
import { QUALITY } from '../../dashboard';
import { TraitPreviewController } from './previewInterface';
import { PreviewColorScheme } from './previewModel';
import { TraitPreviewViewProps } from './previewProps';

const colors: Record<Transparancy['quality'], PreviewColorScheme> = {
    very_large_sample: 'green',
    highly_relevant: 'green',
    relevant_sample: 'green',
    small_sample: 'orange',
    insufficient_sample: 'red',
};
const icons: Record<Transparancy['quality'], IconType> = {
    very_large_sample: FiThumbsUp,
    highly_relevant: FiThumbsUp,
    relevant_sample: AiOutlineSearch,
    small_sample: AiOutlineSearch,
    insufficient_sample: AiOutlineWarning,
};

export function createPreviewController(
    formatter: ValueFormatter
): TraitPreviewController {
    return {
        useProps(props): TraitPreviewViewProps {
            const { data } = props.query.competitive;
            props.query.competitive.data?.transparency;

            const quality = data?.transparency
                ? QUALITY[data.transparency.quality]
                : null;

            const [start, end] = data?.transparency.range ?? [null, null];

            let matchedText = '';

            if (start && end) {
                matchedText = `${start} - ${end} companies matched`;
            } else if (start) {
                matchedText = `${start}+ companies matched`;
            } else {
                matchedText = `Not enough companies `;
            }

            start && end ? `${start} - ${end} companies matched` : undefined;

            const overrides = props.query.competitive.data
                ? props.getStatusProps?.(props.query.competitive.data) ?? null
                : null;

            let emptyState: TraitPreviewViewProps['emptyState'] = null;
            let base: Pick<
                TraitPreviewViewProps,
                'colorScheme' | 'statusName' | 'Icon' | 'description'
            > = {
                statusName: overrides?.label ?? quality?.text ?? 'unknown',
                description: overrides?.description ?? quality?.tooltip ?? null,
                colorScheme:
                    overrides?.colorScheme ??
                    (data?.transparency.quality
                        ? colors[data?.transparency.quality]
                        : 'gray'),
                Icon: data?.transparency.quality
                    ? icons[data?.transparency.quality] ?? AiOutlineSearch
                    : AiOutlineSearch,
            };

            if (
                props.query.competitive.data?.transparency.quality ===
                'insufficient_sample'
            ) {
                emptyState = {
                    Icon: data?.transparency.quality
                        ? icons[data?.transparency.quality] ?? AiOutlineSearch
                        : AiOutlineSearch,
                    status: 'warning',
                    title: `Insufficient sample`,
                    description: `Sample size is too small, please adjust filters`,
                };
            }

            if (props.item.conditions.length === 0) {
                base = {
                    description: null,
                    statusName: 'No filters specified',
                    colorScheme: 'gray',
                    Icon: null,
                };
                emptyState = {
                    Icon: AiOutlineFilter,
                    status: 'info',
                    title: `No filters specified`,
                    description: `At least one filter must be specified to save the peer group`,
                };
            }
            // else if (props.item.conditions.length === 0) {
            //     emptyState = {
            //         title: `No filters specified`,
            //         description: `Specify filters for a more accurate benchmark`,
            //     };
            // }

            return {
                ...base,
                isLoading: props.query.competitive.isFetching,
                pluginImageUrl: props.item.plugin?.iconUrl ?? null,
                emptyState,
                matchedText,
                getExampleProps() {
                    return {
                        columns: props.query.competitive.data?.example.columns ?? [],
                        data: props.query.competitive.data?.example.companies ?? [],
                        // columns: currentState?.example.columns ?? [],
                        // data: currentState?.example.companies ?? [],
                        format(property, value) {
                            return formatter.format(property.type, value, {
                                notation: 'short',
                            });
                        },
                    };
                },
                format(property, value) {
                    const formatted = formatter.format(property.type, value, {
                        notation: 'short',
                    });
                    return formatted;
                },
            };
        },
    };
}
