import React from 'react';
import { GroupRedirectController } from './groupRedirectInterface';

function getRoute(to: string, fromRoute?: string | null) {
    if (fromRoute) {
        return `../${to}`;
    }
    return to;
}

export const createGroupRedirectController = (): GroupRedirectController => ({
    useProps(props, data) {
        const retVal = React.useMemo(() => {
            if (data.initial.state === 'pending') {
                if (!props.fromRoute || props.fromRoute === 'activated')
                    return {
                        isLoading: data.isLoading,
                        to: getRoute('setup', props.fromRoute),
                    };
            } else if (!props.fromRoute || props.fromRoute === 'introduction') {
                if (data.initial.state === 'internal' && data.internalGroup) {
                    return {
                        isLoading: data.isLoading,
                        to: getRoute(data.internalGroup.id, props.fromRoute),
                    };
                } else if (data.initial.state === 'opt_in' && data.optinGroup) {
                    return {
                        isLoading: data.isLoading,
                        to: getRoute(data.optinGroup.id, props.fromRoute),
                    };
                }
            }
            return {
                isLoading: data.isLoading,
                to: null,
            };
        }, [data.isLoading, data.initial.state, props.fromRoute]);

        // if(!retVal.isLoading) {
        //     console.log('REDIRECT RESULT', props, retVal, data);
        // }
        return retVal;
    },
});
