import {
    EnumType,
    AnyType,
    ComplexType,
    CurrencyType,
    MovementType,
    NumericType,
} from '../../v2/domain/attributes';

export function isInteger(type: AnyType): type is 'integer' {
    return type === 'integer';
}

export function isFloat(type: AnyType): type is 'float' {
    return type === 'float';
}

export function isPercent(type: AnyType): type is 'percent' {
    return type === 'percent';
}

export function isAnyPercent(type: AnyType): type is 'percent' {
    return isPercent(type);
}

export function isNumeric(type: AnyType): type is NumericType {
    return isInteger(type) || isFloat(type) || isCurrency(type) || isAnyPercent(type);
}

export function isComplex(type: AnyType): type is ComplexType {
    return typeof type === 'object';
}

export function isCurrency(type: AnyType): type is CurrencyType {
    return isComplex(type) && type.kind === 'currency';
}

export function isMovement(type: AnyType): type is MovementType {
    return isComplex(type) && type.kind === 'movement';
}

export function isEnum(type: AnyType): type is EnumType {
    return isComplex(type) && type.kind === 'enum';
}

export function isDate(type: AnyType): type is 'date' {
    return type === 'date';
}
