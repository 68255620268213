import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { SubmissionItemAggregate } from '../../../../view/studies';
import { isReferenceType } from '../../../../domain/attributes';
import {
    DatasetEntity,
    resolveDatasetTypes,
    resolveSurveyTypes,
} from '../../../../app/datasets';
import { keyBySafe } from '../../../../util';
import { getCollectionItems } from '../../../../base';
import { StudiesSubmissionDetailRouteConfig } from './studiesSubmissionDetailConfig';
import { StudiesSubmissionDetailLoader } from './studiesSubmissionDetailInterface';
import { isDatasetReport } from '../../../../app';

export function createStudiesSubmissionDetailLoader(
    config: Pick<StudiesSubmissionDetailRouteConfig, 'repository'>
): StudiesSubmissionDetailLoader {
    const {
        repository: {
            submission: submissionRepository,
            formdata: dataRepository,
            survey: surveyRepository,
            count: countRepository,
            report: reportRepository,
            collaborator: CollaboratorRepository,
            member: memberRepository,
        },
    } = config;
    return {
        useLoad(context, props) {
            const collaboratorQuery = CollaboratorRepository.useFind(
                context,
                { workspaces: [context.workspace.id as number] },
                { suspense: true }
            );
            const submissionQuery = submissionRepository.useLookup(
                context,
                props.submission,
                { suspense: true }
            );
            const reportsQuery = reportRepository.useCollection(
                context,
                {},
                { suspense: true }
            );
            const formDataQuery = dataRepository.useLookup(
                context,
                {
                    submission: props.submission,
                },
                {
                    suspense: true,
                }
            );
            // TODO refactor this into a multi-key lookup
            const surveyQuery = surveyRepository.useFind(context, {}, { suspense: true });
            // TODO refactor this into a multi-key lookup
            // const groupQuery = groupRepository.useFind(
            //     context,
            //     { kind: 'dataset' },
            //     { suspense: true }
            // );

            assert(submissionQuery.status === 'success', 'expected suspense');
            assert(formDataQuery.status === 'success', 'expected suspense');
            assert(surveyQuery.status === 'success', 'expected suspense');
            assert(reportsQuery.status === 'success', 'expected suspense');
            assert(collaboratorQuery.status === 'success', 'expected suspense');
            // assert(groupQuery.status === 'success', 'expected suspense');

            const countQuery = countRepository.useLookup(
                context,
                [
                    {
                        kind: 'data_ingestion.dataset_member',
                        object: submissionQuery.data.dataset.id,
                    },
                ],
                { suspense: true }
            );

            assert(countQuery.status === 'success', 'expected suspense');

            const surveyById = useMemo(
                () => keyBySafe(surveyQuery.data, (item) => item.id),
                [surveyQuery.data]
            );

            const reportsByDataset = useMemo(
                () =>
                    keyBySafe(
                        getCollectionItems(reportsQuery.data).filter(isDatasetReport),
                        (item) => item.dataset.id
                    ),
                [reportsQuery.data]
            );

            const collaboratorById = useMemo(
                () => keyBySafe(collaboratorQuery.data, (item) => item.id.toString()),
                [collaboratorQuery.data]
            );

            const survey = surveyById[submissionQuery.data.dataset.id];
            assert(survey, `dataset '${submissionQuery.data.dataset.id}' not found`);

            const memberQuery = memberRepository.useFind(
                context,
                {
                    workspace: context.workspace,
                    types:
                        survey.configuration.schema.flatMap((item) =>
                            isReferenceType(item.type) ? item.type : []
                        ) ?? [],
                },
                { suspense: true, staleTime: Infinity }
            );

            assert(memberQuery.status === 'success', 'expected suspese');

            const resolvedDataset = useMemo(
                () => resolveSurveyTypes(survey, memberQuery.data),
                [survey, memberQuery.data]
            );

            const aggregate = useMemo((): SubmissionItemAggregate => {
                const report = reportsByDataset[submissionQuery.data.dataset.id] ?? null;
                assert(report, 'no report found for submission');
                return {
                    user: collaboratorById[submissionQuery.data.user.id] ?? null,
                    report,
                    submission: submissionQuery.data,
                    data: formDataQuery.data ?? null,
                    survey: resolvedDataset,
                    respondents: countQuery.data[0] ?? null,
                };
            }, [
                resolvedDataset,
                submissionQuery.data,
                collaboratorById,
                // groupQuery.data,
                formDataQuery.data,
                survey,
                countQuery.data,
            ]);

            return {
                status: 'loaded',
                data: {
                    submission: aggregate,
                },
            };
        },
    };
}
