import Tap from '@tapfiliate/tapfiliate-js';
import { AffiliateAdapter } from '../../base';
import { TapfiliateConfig } from './affiliateConfig';

export function createTapfiliate(config: TapfiliateConfig): AffiliateAdapter {
    Tap.init(config.accountId, { integration: 'stripe' }, undefined, {
        cookie_domain: config.cookieDomain,
    });
    return {
        trial(lead) {
            console.info('[Tapfiliate] Recording new trial', lead);
            try {
                Tap.trial(lead.id);
                // window.tap('trial', lead.id);
            } catch (error) {
                console.error(error);
            }
        },
    };
}
