import { RouterStore } from '@superwf/mobx-react-router';
import { RootStore } from './RootStore';
import AuthStore from './AuthStore';
import { RegistrationStore } from './RegistrationStore';
import MobileAppsStore from './MobileAppsStore';
import { IntegrationStore } from './IntegrationStore';
import { UsersStore } from './UserStore';
import PeerStore from './PeerStore';
import ReferralStore from './ReferralStore';
import IntegrationConfirmStore from './IntegrationConfirmStore';
import FacebookAdsIntegrationStore from './ext/FacebookAdsIntegrationStore';
import ResetPasswordStore from './ResetPasswordStore';
// import PeerGroupFilterStore from './PeerGroupFIlterStore';
import DataSetsStore from './DataSetStore';
// import DashboardsFiltersStore from './DashboardsFiltersStore';
import IntegMappingStore from './IntegMappingStore';
import { router } from './Router';
import MenuStore from './MenuStore';
import { AccessStore, DialogStore } from '../infra';
import { makeObservable, observable } from 'mobx';
import StoreBase from './StoreBase';

export interface Stores {
    auth: AuthStore;
    routing: RouterStore;
    registration: RegistrationStore;
    mobileApps: MobileAppsStore;
    // assets: AssetStore;
    integrations: IntegrationStore;
    users: UsersStore;
    peers: PeerStore;
    referrals: ReferralStore;
    linkConfirm: IntegrationConfirmStore;
    fbStore: FacebookAdsIntegrationStore;
    setPassword: ResetPasswordStore;
    datasets: DataSetsStore;
    integMapping: IntegMappingStore;
    menu: MenuStore;
    dialog: DialogStore;
    access: AccessStore;
}

export type DeepParitalStores = Partial<Stores>;

export const makeStores = (externalStores?: DeepParitalStores): Stores => {
    const store: Stores = {
        routing: router,
        auth: externalStores?.auth || new AuthStore(),
        registration: externalStores?.registration || new RegistrationStore(),
        mobileApps: externalStores?.mobileApps || new MobileAppsStore(),
        // assets: externalStores?.assets || new AssetStore(),
        integrations: externalStores?.integrations || new IntegrationStore(),
        users: externalStores?.users || new UsersStore(),
        peers: externalStores?.peers || new PeerStore(),
        referrals: externalStores?.referrals || new ReferralStore(),
        linkConfirm: externalStores?.linkConfirm || new IntegrationConfirmStore(),
        fbStore: externalStores?.fbStore || new FacebookAdsIntegrationStore(),
        setPassword: externalStores?.setPassword || new ResetPasswordStore(),
        datasets: externalStores?.datasets || new DataSetsStore(),
        integMapping: externalStores?.integMapping || new IntegMappingStore(),
        menu: externalStores?.menu || new MenuStore(),
        dialog: externalStores?.dialog || new DialogStore(),
        access: externalStores?.access ?? new AccessStore(),
    };
    return store;
};

export const createStore = (externalStores?: DeepParitalStores) => {
    return new RootStore<Stores>(makeStores(externalStores));
};

// ? Dashboard - each page gets a store
