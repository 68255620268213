import {
    OnboardingPeerGroupController,
    OnboardingPrepareController,
} from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingPrepareStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingPrepareController
        ): OnboardingPrepareController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    if (viewProps.status !== 'success') {
                        return viewProps;
                    }
                    return {
                        ...viewProps,
                        getFormProps() {
                            const base = viewProps.getFormProps();
                            return {
                                ...base,
                                async onSubmit() {
                                    const response = await base.onSubmit();
                                    tracker.track(
                                        'onboarding_prepare_account_submitted',
                                        {}
                                    );
                                    return response;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createPrepare(...args) {
                        const controller = config.controller.onboarding.createPrepare(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
