import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { keyByMapSafe, keyBySafe } from '../../../../util';
import { CompanyItemAggregate } from '../../../../view/companies';
import { PeerLoaderConfig } from '../../base';
import { PeerCompanySelectLoader } from './peerCompanySelectInterface';
import { PeerCompanySelectAggregate } from './peerCompanySelectModel';

export function createCompanyListBoxSelectLoader(
    config: PeerLoaderConfig
): PeerCompanySelectLoader {
    const {
        repository: { company: companyRepository },
    } = config;
    return {
        useLoad(context, props): PeerCompanySelectAggregate {
            const companyInitialSelectedQuery = companyRepository.useFind(
                context,
                {
                    ids: useMemo(() => props.selected, []),
                },
                {
                    suspense: true,
                }
            );

            // NOTE we use the query cache which means we assume that the
            // companies have already been loaded as part of a parent component
            const companyQuery = companyRepository.useLookupCache(
                context,
                props.selected.map((item) => ({ id: item }))
            );

            const assetByCompany = useMemo(
                () =>
                    keyByMapSafe(companyQuery, (item) => {
                        const profile = item.profiles.find(
                            (candidate) => candidate.kind === 'varos'
                        );
                        const asset = profile ? { id: Number(profile.id) } : undefined;
                        return [item.id, asset];
                    }),
                [companyQuery]
            );

            const companyById = useMemo(
                () => keyBySafe(companyQuery, (item) => item.id),
                [companyQuery]
            );

            const aggregate = useMemo((): PeerCompanySelectAggregate => {
                return {
                    list: {
                        // iterate on the state and not returned list to ensure correct ordering after updates
                        items: props.selected.flatMap(
                            (companyId): CompanyItemAggregate[] => {
                                const company = companyById[companyId];
                                if (!company) {
                                    console.warn(
                                        `company ${companyId} not found in state`,
                                        companyById
                                    );
                                    return [];
                                }
                                return [
                                    {
                                        company,
                                        asset: assetByCompany[company.id] ?? null,
                                    },
                                ];
                            }
                        ),
                    },
                };
            }, [companyById, assetByCompany, props.selected]);

            return aggregate;
        },
    };
}
