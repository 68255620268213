import { capitalize } from 'lodash';
import { PluginStatusProps } from '.';
import { PluginItemAggregate } from '../item/pluginItemModel';
import { COLORS } from './pluginStatusConstant';

export function buildPluginStatusProps(item: PluginItemAggregate): PluginStatusProps {
    const fulfilledCount = item.connection.dependencies.filter(
        (item) => item.status === 'fulfilled'
    ).length;
    const allCount = item.connection.dependencies.length;
    const allFulfilled = fulfilledCount === allCount;
    const dependencies: PluginStatusProps['dependencies'] = {
        label: allFulfilled ? 'Fulfilled' : 'Unfulfilled',
        summary: `${fulfilledCount} of ${allCount} requirements met`,
        // description: allFulfilled
        //     ? `Every requirement is fulfilled`
        //     : `One or more requirement are not fulfilled`,
        description: `Every requirement must be fulfilled to access plugin`,
        colorScheme: item.connection.status === 'ready' ? 'green' : null,
        items: item.connection.dependencies.map((item) => ({
            label: item.name,
            status: capitalize(item.status),
            colorScheme: item.status === 'fulfilled' ? 'green' : 'red',
        })),
    };
    if (item.connection.status === 'ready') {
        return {
            label: 'Ready',
            colorScheme: 'green',
            description: 'Plugin is connected and data is up-to-date',
            dependencies,
        };
    }
    return {
        label: 'Not eligible',
        colorScheme: 'red',
        description: 'Plugin is not eligible for this company',
        dependencies,
    };
}
