import moment from 'moment';
import { Session } from './sessionModel';

export function buildSession(props: {
    id: string;
    sourceName?: string;
    sourceMedium?: string;
    sourceTrigger?: string;
    startedAt: Date;
    ttlMs: number;
}): Session {
    return {
        id: props.id,
        sourceName: props.sourceName ?? undefined,
        sourceMedium: props.sourceMedium ?? undefined,
        sourceTrigger: props.sourceTrigger ?? undefined,
        startedAt: props.startedAt,
        refreshedAt: props.startedAt,
        expiresAt: moment(props.startedAt).add(props.ttlMs, 'ms').toDate(),
    };
}
