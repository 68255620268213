import { Help } from '../../base';
import { HelpImplConfig } from './helpConfig';

export function createHelpImpl(config: HelpImplConfig): Help {
    return {
        show() {
            const client = config.getIntercom();
            if (!client) {
                console.info('intercom not available in environment');
                return;
            }
            console.info('showing intercom');
            client('show');
        },
    };
}
