import {
    AnyCollection,
    EmptyCollection,
    ErrorCollection,
    LoadedCollection,
    LoadingCollection,
    // RefreshingCollection,
} from './collectionModel';

export function isLoadingCollection<TItem>(
    collection: AnyCollection<TItem>
): collection is LoadingCollection {
    return collection.status === 'loading';
}

// export function isRefreshingCollection<TItem>(
//     collection: AnyCollection<TItem>
// ): collection is RefreshingCollection {
//     return collection.status === 'refreshing';
// }

export function isErrorCollection<TItem>(
    collection: AnyCollection<TItem>
): collection is ErrorCollection {
    return collection.status === 'error';
}

export function isEmptyCollection<TItem>(
    collection: AnyCollection<TItem>
): collection is EmptyCollection {
    return collection.status === 'empty';
}

export function isLoadedCollection<TItem>(
    collection: AnyCollection<TItem>
): collection is LoadedCollection<TItem> {
    return collection.status === 'loaded';
}
