import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { LayoutContainerViewProps } from '../../../view/common';

export function createDomainLayoutContainerView(): React.FC<
    { children?: React.ReactNode | undefined } & LayoutContainerViewProps
> {
    return (props) => {
        return (
            <Grid>
                <GridItem></GridItem>
                <GridItem></GridItem>
            </Grid>
        );
    };
}
