import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { UrlOptionParser } from '../../base';

export function createUrlOptionParser(): UrlOptionParser {
    return {
        useOptions(schema) {
            const [searchParams] = useSearchParams();
            const paramas = useParams();
            const parsed = useMemo(
                () =>
                    schema.safeParse({
                        ...paramas,
                        ...Object.fromEntries(searchParams.entries()),
                    }),
                [searchParams]
            );
            if (!parsed.success) {
                console.error('option parse error', parsed.error);
                return parsed.error;
            }
            return parsed.data;
        },
    };
}
