import React from 'react';
import { ValueRenderer } from '../../app/attributes';
import {
    isEnumType,
    isEnumValue,
    isMovementType,
    isMovementValue,
} from '../../domain/attributes';
import { RendererConfig } from './rendererConfig';

export function createRendererImpl(config: RendererConfig): ValueRenderer {
    const {
        components: { Enum: EnumComponent, Movement: MovementComponent },
    } = config;
    return {
        render(type, value) {
            if (isEnumType(type) && isEnumValue(value)) {
                return <EnumComponent type={type} value={value} />;
            }
            if (isMovementType(type) && type.base && isMovementValue(value)) {
                return (
                    <MovementComponent
                        style={{
                            alignItems: 'flex-end',
                        }}
                        type={type}
                        value={value}
                        format={(type, value) =>
                            config.formatter.format(type, value, {
                                notation: 'short',
                            })
                        }
                    />
                );
            }
            console.warn(
                `failed to render type '${JSON.stringify(
                    type
                )}' for value ${JSON.stringify(value)}`
            );
            return <>{JSON.stringify(value)}</>;
        },
    };
}
