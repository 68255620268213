import { IndustryDto } from '../../../api';
import { IndustryAdapter, IndustryEntity } from '../../../app/companies';
import { IndustryImplConfig } from './industryImplConfig';

export function createIndustryImpl(config: IndustryImplConfig): IndustryAdapter {
    const {
        api: { company: api },
    } = config;

    function toEntity(dto: IndustryDto): IndustryEntity {
        return {
            id: dto.key,
            name: dto.name,
            count: {
                companies: dto.companies_count,
            },
        };
    }

    return {
        async find(context, props) {
            const response = await api.industry.find(context, props);
            return response.data.map(toEntity);
        },
    };
}
