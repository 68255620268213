import { useQuery } from '@tanstack/react-query';
import { RoleAdapter } from './roleAdapter';
import { RoleOrganizationRepository } from './roleInterface';

export function createOrganizationRoleRepository(
    adapter: RoleAdapter
): RoleOrganizationRepository {
    return {
        useFind(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: ['organization-roles', query],
                async queryFn() {
                    const response = adapter.find(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}
