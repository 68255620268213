import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Workspace } from '../../../domain';
import { MetricDashboardConfig } from './dashboardConfig';
import { MetricDashboardRepository } from './dashboardInterface';

export function createMetricDashboardRepository(
    config: MetricDashboardConfig
): MetricDashboardRepository {
    const { adapter } = config;
    const PREFIX = ['metric', 'dashboards'];
    return {
        useFind(context, query, options) {
            const { includePlugins, ignorePlugins } = query;
            const applied: Pick<Workspace, 'id' | 'name'> = {
                id: context.workspace.id as number,
                name: 'N/A',
            };
            return useQuery({
                ...options,
                queryKey: [
                    ...PREFIX,
                    context.organization.id,
                    context.workspace.id,
                    query,
                    applied,
                ],
                async queryFn() {
                    const response = await adapter.listDashboards(context, applied);
                    let acc = response;
                    if (includePlugins) {
                        acc = response.filter((candidate) =>
                            includePlugins.includes(candidate.pluginId)
                        );
                    }
                    if (ignorePlugins) {
                        acc = response.filter(
                            (candidate) => !ignorePlugins.includes(candidate.pluginId)
                        );
                    }
                    return acc;
                },
            });
        },
    };
}
