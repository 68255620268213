import { RecommendationDto } from '../../../../api';
import {
    RecommendationAdapter,
    RecommendationEntity,
} from '../../../app/personalization';
import { assert } from '../../../util/assert';
import { RecommendationImplConfig } from './recommendationImplConfig';

export function createRecommendationImpl(
    config: RecommendationImplConfig
): RecommendationAdapter {
    const {
        api: { platform: api },
    } = config;

    function toEntity(dto: RecommendationDto): RecommendationEntity {
        assert(dto.object.kind === 'company', `only company recommendation impl yet`);
        return {
            kind: dto.object.kind,
            id: dto.object.id,
        };
    }

    return {
        async find(context, query) {
            const response = await api.recommendations.list(context, {
                scope: {
                    kind: 'asset',
                    id: query.asset.id,
                },
                model: 'company',
                options: {},
            });
            return response.data.map(toEntity);
        },
    };
}
