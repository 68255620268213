import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { SettingAssetMappingConfig } from './mappingConfig';
import { SettingAssetMappingLoader } from './mappingInterface';

export function createSettingAssetMappingLoader(
    config: Pick<SettingAssetMappingConfig, 'repository' | 'service'>
): SettingAssetMappingLoader {
    const { repository, service } = config;
    return {
        useLoad(context) {
            const mappingData = service.mapping.useLookup(context, { suspense: true });
            assert(mappingData.status === 'loaded', 'expected suspense query');
            return useMemo(() => mappingData, [mappingData]);
        },
    };
}
