import { AxiosInstance, AxiosResponse } from 'axios';
import { AssetCounts } from '../models/Count';
// import moment from 'moment';
export const getAssetCounts = async (
    api: AxiosInstance,
    options?: { apiKey?: string }
) => {
    // const start = moment('2023-08-14');
    // const numOfDays = Math.floor((moment().diff(start)) / (3600 * 1000 * 24));
    // const assetGrowthPerDay = 2;
    // return Promise.resolve<AssetCounts>({
    //     asset: {
    //         active: 4489 + (numOfDays * assetGrowthPerDay)
    //     }
    // })
    const response = await api.get<unknown, AxiosResponse<AssetCounts>>(
        `/api/v1/counts/assets`,
        {
            params: options?.apiKey ? { 'api-key': options.apiKey } : {},
        }
    );
    return response.data;
};
