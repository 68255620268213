import React, { useContext } from 'react';
import { assert } from '../../../../util/assert';
import {
    RegistrationAcceptContextProvider,
    RegistrationInvitationContext,
} from './acceptContextInterface';

export function createAcceptContextProvider(): RegistrationAcceptContextProvider {
    const Context = React.createContext<RegistrationInvitationContext | null>(null);
    return {
        Provider: Context.Provider,
        useContext() {
            const value = useContext(Context);
            assert(value, 'not inside accept context');
            return value;
        },
    };
}
