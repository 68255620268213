import { observer } from 'mobx-react';
import React from 'react';
import { FilterDateContainerProps } from './dateContainerProps';

export const FilterDateContainer: React.FC<
    { children?: React.ReactNode | undefined } & FilterDateContainerProps
> = observer(({ as: Component, useController, ...props }) => {
    const viewProps = useController();
    return <Component {...viewProps}>{props.children}</Component>;
});
