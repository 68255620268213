import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import {
    Link as ChakraLink,
    Center,
    Box,
    VStack,
    Icon,
    TextProps,
} from '@chakra-ui/react';
// import { Button } from '../../../../ui/button';
import { ErrorBoundary } from '../../../../infra';
import { ApplicationLinkProps, ApplicationUI } from '../../../ui';
import { createChakraStepperComponents } from '../../../vendor/chakra-stepper';
import { Button as AsyncButton } from '../../../../ui';
import { getChildrenProps } from '../../../util';
import { createIconComponents } from './icon';
import { createGridComponents } from './grid';

export function createChakraUI(): ApplicationUI {
    const Instance: ApplicationUI = {
        View(props) {
            return <>{props.children}</>;
        },
        Tooltip(props) {
            return <Chakra.Tooltip id={props.id} {...props} />;
        },
        Table: Chakra.Table,
        Thead: Chakra.Thead,
        Tbody: Chakra.Tbody,
        Tr: Chakra.Tr,
        Th({ tooltip, ...props }) {
            if (!tooltip) {
                return <Chakra.Th {...props} />;
            }
            return (
                <Chakra.Th {...props}>
                    <Chakra.HStack alignItems="center" justifyContent="flex-end">
                        <Chakra.Box>{props.children}</Chakra.Box>
                        <Chakra.Box>
                            <Chakra.Tooltip
                                openDelay={200}
                                placement="top"
                                label={<Chakra.Text p={2}>{tooltip}</Chakra.Text>}
                            >
                                <InfoIcon
                                    width={3.5}
                                    height={3.5}
                                    color="whiteAlpha.800"
                                />
                            </Chakra.Tooltip>
                        </Chakra.Box>
                    </Chakra.HStack>
                </Chakra.Th>
            );
        },
        Td: Chakra.Td,
        Heading: Chakra.Heading,
        Text: Chakra.Text,
        Link: ({ id, ...props }) => {
            return <Link {...props} />;
        },
        Anchor({ target = '_blank', ...props }) {
            return <ChakraLink _focus={{ outline: 'none' }} target={target} {...props} />;
        },
        Button: Chakra.Button,
        AsyncButton: AsyncButton,
        Tag: {
            List() {
                throw new Error(`not implemented`);
            },
            Item() {
                throw new Error(`not implemented`);
            },
        },
        Grid: createGridComponents(),
        Icon: createIconComponents(),
        EmptyState(props) {
            // const childProps = getChildrenProps(props.children, {
            //     icon: Instance.Icon.Icon,
            //     heading: Instance.Heading,
            //     text: Instance.Text,
            //     link: Instance.Link,
            // });
            // throw new Error(`not implemented`);

            let styleProps: TextProps = {};
            if (props.status === 'danger') {
                styleProps = { ...styleProps, color: 'red.500' };
            }

            const icon = props.icon
                ? React.cloneElement(props.icon, {
                      ...props.icon.props,
                      color: 'whiteAlpha.500',
                      fontSize: '4xl',
                  })
                : null;

            return (
                <Box p={8}>
                    <VStack
                        bg="blackAlpha.100"
                        // color="whiteAlpha.500"
                        p={4}
                        borderColor="blackAlpha.300"
                        borderWidth={2}
                        borderStyle="solid"
                        borderRadius="lg"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                    >
                        {icon && (
                            <Instance.Icon.IconGroup>{icon}</Instance.Icon.IconGroup>
                        )}
                        {props.title && (
                            <Chakra.Heading
                                {...styleProps}
                                color="whiteAlpha.700"
                                fontSize="md"
                                fontWeight="medium"
                                as="h2"
                            >
                                {props.title}
                            </Chakra.Heading>
                        )}
                        {props.description && (
                            <Chakra.Text color="whiteAlpha.500" {...styleProps}>
                                {props.description}
                            </Chakra.Text>
                        )}
                        {props.children}
                    </VStack>
                </Box>
            );
        },
        createErrorBoundary(config) {
            return (props) => (
                <ErrorBoundary
                    fallback={(info) => {
                        if (info.error instanceof config.errorClass) {
                            return props.fallback({
                                error: info.error as any,
                            });
                        }
                        throw info.error;
                    }}
                >
                    {props.children}
                </ErrorBoundary>
            );
        },
        Stepper: createChakraStepperComponents(),
    };
    return Instance;
}
