import { AiOutlineLock } from 'react-icons/ai';
import { DateRangeAccessRequest } from '../../../../../base';
import {
    DatePickerController,
    DatePickerViewProps,
} from '../../../../vendor/chakra-date-picker';
import { FeatureMiddleware } from '../../featureInterface';
import { isPeriodEnabled } from './dateRangeHomeHelper';

export function createFeatureDateRangeHomeMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceDateController(
            controller: DatePickerController
        ): DatePickerController {
            return {
                ...controller,
                useProps(...args): DatePickerViewProps {
                    const [props, ...rest] = args;
                    const feature = api.useFeature('date_ranges');
                    const viewProps = controller.useProps(
                        {
                            ...props,
                            onChange(value) {
                                const isEnabled = feature.feature
                                    ? isPeriodEnabled(value, feature.feature)
                                    : true;
                                if (isEnabled) {
                                    // config not available, skip
                                    return props.onChange(value);
                                }
                                feature.restrict<DateRangeAccessRequest>({
                                    ...feature.feature!,
                                    value: {
                                        period: value,
                                        dashboard: null,
                                    },
                                });
                            },
                        },
                        ...rest
                    );

                    return {
                        ...viewProps,
                        getPeriodOptionProps(item) {
                            const optionProps = viewProps.getPeriodOptionProps(item);
                            const isEnabled = feature.feature
                                ? isPeriodEnabled(item.value, feature.feature)
                                : true;
                            if (isEnabled) {
                                return optionProps;
                            }
                            return {
                                ...optionProps,
                                onClick() {
                                    feature.restrict<DateRangeAccessRequest>({
                                        ...feature.feature!,
                                        value: {
                                            period: item.value,
                                            dashboard: null,
                                        },
                                    });
                                },
                                isDisabled: true,
                                Icon: AiOutlineLock,
                            };
                        },
                    };
                },
            };
        }
        return create({
            ...config,
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        deps: {
                            ...routeConfig.deps,
                            controller: {
                                ...routeConfig.deps.controller,
                                date: enhanceDateController(
                                    routeConfig.deps.controller.date
                                ),
                            },
                        },
                    });
                },
            },
        });
    };
}
