import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { toJS } from 'mobx';
import {
    DataPoint,
    DataValue,
    getPreferences,
    MovementDataValue,
    QueryResponse,
    QueryRunPayload,
    runQuery,
} from '../../../../api';
import { MetricCalculation, MetricCalculationSegment } from '../../../domain/metrics';
import {
    MetricCollectionDto,
    MetricCollectionMetricCreatePayload,
    MetricCollectionUpdatePayload,
    ViewDto,
} from '../../../api';
import {
    CalculationAdapterHook,
    MetricCollectionAdapterProvider,
    MetricCollectionAdapter,
} from '../../../app/metrics';
import { CollectionImplConfig } from './collectionConfig';
import { chain } from 'lodash';
import { DashboardSlugs } from '../../../../config/dashboard';
import { ViewSources } from '../../../../config/view';
import { castQueryToApi } from '../../api';
import { useWorkspaceContextV2 } from '../../../context';

export function createCollectionImpl(
    config: CollectionImplConfig
): MetricCollectionAdapterProvider {
    const {
        axios,
        api: {
            metric: { collections, definitions },
        },
    } = config;
    return {
        useAdapter(context) {
            const queryclient = useQueryClient();

            const authToken =
                context.auth.scheme.kind === 'legacy' &&
                context.auth.scheme.store.authToken;

            if (!authToken) {
                throw new Error('only legacy auth supported');
            }

            const contextdata = useWorkspaceContextV2();

            const queryKey = [
                'metric',
                'collection',
                context.organization.id,
                context.workspace.id,
                contextdata.metric.collections,
                contextdata.metric.definitions,
            ];

            const response = useQuery({
                queryKey,
                async queryFn() {
                    // const [responseCollections, responseDefinitions] = await Promise.all([
                    //     collections.list(context, {
                    //         asset: context.workspace.id as number,
                    //     }),
                    //     definitions.list(context, {
                    //         kinds: [],
                    //     }),
                    // ]);

                    const responseCollections = contextdata.metric.collections;
                    const responseDefinitions = contextdata.metric.definitions;

                    // let defaultCollection =
                    //     responseCollections.data?.find((item) => item.default) ?? null;

                    // if (!defaultCollection) {
                    //     console.info('creating default collection...');
                    //     defaultCollection = await collections.create(context, {
                    //         asset: context.workspace.id as number,
                    //         name: 'Default',
                    //         default: true,
                    //         metrics: chain(responseDefinitions.data)
                    //             .filter((item) => item.kind === 'performance')
                    //             .uniqBy((item) => item.plugin)
                    //             .take(4)
                    //             .map<MetricCollectionMetricCreatePayload>((item) => ({
                    //                 name: null,
                    //                 definition: item.id,
                    //             }))
                    //             .value(),
                    //     });
                    // }
                    return {
                        collections: [
                            // defaultCollection,
                            ...(responseCollections.data ?? []),
                        ],
                        // defaultCollection,
                    };
                },
                staleTime: Infinity,
                retry: false,
                suspense: true,
            });

            const mutation = {
                update: useMutation({
                    async mutationFn(
                        input: [
                            collection: MetricCollectionDto,
                            payload: MetricCollectionUpdatePayload
                        ]
                    ) {
                        const [collection, payload] = input;
                        const response = collections.update(context, collection, payload);
                        return response;
                    },
                    async onSuccess(data, variables, _context) {
                        // queryclient.invalidateQueries({
                        //     queryKey: contextdata.metric.collections.queryKey,
                        // });
                        await queryclient.refetchQueries({
                            queryKey: contextdata.metric.collections.queryKey,
                        });
                        return data;
                    },
                }),
            };

            const instance: MetricCollectionAdapter = {
                find(context, query) {
                    return response.data?.collections ?? [];
                },
                get(context, query) {
                    if (query.default === true) {
                        return (
                            response.data!.collections.find((item) => item.default) ??
                            null
                        );
                    }
                    throw new Error('not impl');
                },
                async update(context, collection, payload) {
                    console.log('updating', collection, payload);
                    const response = await mutation.update.mutateAsync([
                        collection,
                        payload,
                    ]);
                    return response;
                },
            };
            return instance;
        },
    };
}
