import {
    AnyIconProps,
    StaticIconProps,
    ImageIconProps,
    RawIconProps,
} from './iconInterface';

export function isStaticIconProps(props: AnyIconProps): props is StaticIconProps {
    return typeof (props as StaticIconProps).icon === 'string';
}

export function isImageIconProps(props: AnyIconProps): props is ImageIconProps {
    return typeof (props as ImageIconProps).src === 'string';
}

export function isRawIconProps(props: AnyIconProps): props is RawIconProps {
    return typeof (props as RawIconProps).as === 'function';
}
