import { Box, BoxProps, useToken, VStack } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import DataLabels from 'chartjs-plugin-datalabels';
import { Bar, ChartProps } from 'react-chartjs-2';
import { useTheme } from '@chakra-ui/react';
import { ChartDataset } from 'chart.js';

function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export interface BarChartDatasetV2 {
    label: string;
    /**
     * If set will be displayed when hovering over the legend
     */
    description?: string | null;
    data: any[];
    colorScheme: string;
    hidden?: boolean;
}

export interface BarChartDataV2 {
    labels: string[];
    flatten?: boolean;
    datasets: BarChartDatasetV2[];
}

export interface BarChartPropsV2 extends Omit<ChartProps, 'data'> {
    // width: BoxProps['width'];
    // height: BoxProps['height'];
    // no propert typing supplied by library
    data: BarChartDataV2;
    stacked?: boolean;
    tooltipProps?: Partial<{ enabled: boolean }>;
    getValue?(
        context: { key: string; columnIndex: number; rowIndex: number },
        original: unknown
    ): unknown;
    getCaption?(
        context: { key: string; columnIndex: number; rowIndex: number },
        original: unknown
    ): unknown;
    formatUnit?(value: number): React.ReactNode;
    formatLabel?(value: string): string;
    barStyle?: Pick<
        ChartDataset<'bar'>,
        | 'barPercentage'
        | 'categoryPercentage'
        | 'minBarLength'
        | 'barThickness'
        | 'maxBarThickness'
    >;
}

export const BarChartV2: React.FC<
    { children?: React.ReactNode | undefined } & BarChartPropsV2
> = ({
    getValue = (context, original) => original,
    getCaption = (context, original) => original,
    formatUnit = (value) => value.toString(),
    formatLabel = (value) => value.toString(),
    tooltipProps: { enabled: tooltipEnabled = true } = {},
    height = 0,
    width = 0,
    ...props
}) => {
    const legendColor = useToken('colors', 'whiteAlpha.900');

    const [xAxisLabelColor, yAxisLabelColor] = useToken('colors', [
        'whiteAlpha.600',
        'whiteAlpha.400',
    ]);

    const [fontWeightValue] = useToken('fontWeight', ['medium']);

    const theme = useTheme();
    const colorsLegend = theme.colors.legend;

    const datasetProps = {
        type: 'bar',
        fill: false,
        cubicInterpolationMode: 'monotone',
        lineTension: 0,
        barPercentage: 0.75,
        categoryPercentage: 0.75,
        ...props.barStyle,
        // maxBarThickness: convertRemToPixels(2),
        // barPercentage: [0.75, 0.75, 0.75][props.data.labels.length] ?? 0.75,
        // categoryPercentage: [0.25, 0.25, 0.75][props.data.labels.length] ?? 0.75,
    };

    const datasets: ChartDataset<'bar'>[] = props.data.flatten
        ? [
              {
                  ...datasetProps,
                  type: 'bar',
                  data: props.data.datasets
                      .filter((x) => !x.hidden)
                      .flatMap((x) => x.data),
                  backgroundColor: props.data.datasets
                      .filter((x) => !x.hidden)
                      .map((x) => colorsLegend?.[x.colorScheme]?.[200]),
                  borderColor: props.data.datasets
                      .filter((x) => !x.hidden)
                      .map((x) => colorsLegend?.[x.colorScheme]?.[200]),
              },
          ]
        : props.data.datasets.map(
              ({ colorScheme, ...dataset }): ChartDataset<'bar'> => ({
                  ...dataset,
                  ...datasetProps,
                  type: 'bar',
                  backgroundColor: colorsLegend?.[colorScheme]?.[200],
                  borderColor: colorsLegend?.[colorScheme]?.[200],
              })
          );

    const labels = props.data.flatten
        ? props.data.labels.filter((_, idx) => !props.data.datasets[idx].hidden)
        : props.data.labels;

    return (
        <Bar
            height="100%"
            width="100%"
            data={{
                ...props.data,
                labels,
                datasets,
            }}
            plugins={[DataLabels]}
            options={{
                layout: {
                    padding: {
                        top: 24,
                    },
                },
                legend: {
                    usePointStyle: true,
                    // This version of chartjs does not support customizing the space
                    // between the chart and the legend and because we have a custom label design we
                    // end up with clipping. instead we use a custom legend component
                    display: false,
                    // align: 'start',
                    padding: 64,
                    labels: {
                        // padding: 32,
                        boxWidth: 6,
                        usePointStyle: true,
                        fontColor: legendColor,
                        fontSize: 14,
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                animation: false,
                plugins: {
                    datalabels: {
                        color(context) {
                            return context.dataset.backgroundColor as string;
                        },
                        anchor: 'end',
                        align: 'end',
                        // display(context) {
                        //     return true;
                        // },
                        font: {
                            weight: 'normal',
                        },
                        formatter(value, context) {
                            const key = labels[context.datasetIndex];
                            const formatted = getValue(
                                {
                                    key,
                                    columnIndex: context.datasetIndex,
                                    rowIndex: context.dataIndex,
                                },
                                value
                            );
                            return formatted;
                        },
                    },
                    tooltip: {
                        enabled: tooltipEnabled,
                        mode: 'index',
                        axis: 'y',
                        position: 'nearest',
                        yAlign: 'top',
                        callbacks: {
                            title(tooltipItems) {
                                const [tooltipItem] = tooltipItems;
                                return formatLabel(tooltipItem.label);
                            },
                            label(tooltipItem) {
                                const dataset =
                                    props.data.datasets[tooltipItem.datasetIndex];
                                const value = getCaption(
                                    {
                                        rowIndex: tooltipItem.datasetIndex,
                                        columnIndex: tooltipItem.dataIndex,
                                        key: dataset.label,
                                    },
                                    tooltipItem.raw
                                );
                                if (!value) {
                                    return;
                                }
                                // console.log('dataset', dataset);
                                return `${dataset.label}: ${value}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: xAxisLabelColor,
                            font: {
                                size: 14,
                            },
                            callback(value, index, values) {
                                // console.log('callback', { value, index, values });
                                const label = labels[index];
                                return formatLabel(label);
                            },
                        },
                        stacked: props.stacked,
                    },
                    y: {
                        stacked: props.stacked,
                        ticks: {
                            color: yAxisLabelColor,
                            font: {
                                size: 14,
                            },
                            // @ts-expect-error
                            callback(value, index, values) {
                                // console.log('callback y', { value, index, values });
                                return typeof value === 'number'
                                    ? formatUnit(value)
                                    : value;
                            },
                        },
                        grid: {
                            offset: true,
                            color: 'rgb(104, 104, 104, 0.3)',
                        },
                    },
                },
            }}
        />
    );
};
