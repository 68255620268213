import React from 'react';

export interface OrganizationLayoutNavigationProps {
    children:
        | React.ReactElement
        | Array<React.ReactElement | React.ReactElement[] | null>
        | null;
}

export const OrganizationLayoutNavigation: React.FC<
    { children?: React.ReactNode | undefined } & OrganizationLayoutNavigationProps
> = (props) => <>{props.children}</>;
