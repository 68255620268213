import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { assert } from '../../../util/assert';
import { SettingAccountConfig } from './accountConfig';
import { SettingAccountContext } from './accountContext';
import {
    isSettingItemProvider,
    SettingItemProviderConfig,
    SettingItemRoot,
} from '../common';
import { SettingAccountApi } from './accountInterface';
import { createSettingAccountContainer } from './accountContainer';
import { createSettingAccountView } from './accountView';

export function createSettingAccountRoute(config: SettingAccountConfig) {
    const {
        deps: { UI, Layout },
        controller,
    } = config;

    const providerConfig: SettingItemProviderConfig<SettingAccountApi> = {
        UI: UI,
        Layout: Layout.Page,
        api: {
            useContext() {
                const found = useContext(SettingAccountContext);
                assert(found, 'not inside account settings route context');
                return found;
            },
            controller: controller.page,
        },
    };

    const items = config.config.items.flatMap((item): SettingItemRoot[] => {
        if (!isSettingItemProvider(item)) {
            return [item];
        }
        const instances = item.create(providerConfig);
        return instances.flatMap((item) => [item]);
    });

    const DefaultRedirect = () => {
        const firstPath = items[0]?.path;
        assert(firstPath, 'no default path found');
        return <Navigate to={firstPath} replace={true} />;
    };

    return {
        Route: createSettingAccountContainer(
            config.deps,
            controller.route,
            createSettingAccountView(config, items)
        ),
        Redirect: DefaultRedirect,
        items,
    };
}
