import { useDisclosure } from '@chakra-ui/react';
import { DashboardLayoutController } from './dashboardLayoutInterface';

export function createDashboardLayoutController(): DashboardLayoutController {
    return {
        useProps() {
            const disclosure = useDisclosure();
            return {
                disclosure,
                isVisible(item) {
                    return true;
                },
            };
        },
    };
}
