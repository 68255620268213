import { QueryApi } from './queryInterface';
import { QueryApiConfig } from './queryConfig';
import { createExecuteResource } from './execute';

export function createQueryApi(config: QueryApiConfig): QueryApi {
    const { client } = config;
    return {
        queries: createExecuteResource(client),
    };
}
