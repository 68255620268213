import React from 'react';
import { buildMultiSelectViewProps } from './inputMultiSelectFactory';
import { InputMultiSelectContainerPropsV2 } from './inputMultiSelectProps';

export function createInputMultiSelectContainer(): React.FC<
    { children?: React.ReactNode | undefined } & InputMultiSelectContainerPropsV2
> {
    return ({ as: View, ...props }) => {
        const viewProps = buildMultiSelectViewProps(props);
        return <View {...viewProps} />;
    };
}
