import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { assert } from '../../../util/assert';
import {
    isSettingItemProvider,
    SettingItemProviderConfig,
    SettingItemRoot,
} from '../common';
import { SettingAssetConfig } from './assetConfig';
import { createSettingAssetContainer } from './assetContainer';
import { SettingAssetContext } from './assetContext';
import { SettingAssetApi } from './assetInterface';
import { createSettingAssetLoader } from './page/pageLoader';
import { createSettingAssetView } from './assetView';
import { createSettingAssetPageController } from './page';

export function createSettingAssetRoute(config: SettingAssetConfig) {
    const {
        deps: { UI, Layout, controller },
    } = config;

    const page = {
        loader: createSettingAssetLoader(config.deps),
        controller: createSettingAssetPageController(
            config.deps,
            createSettingAssetLoader(config.deps)
        ),
    };

    const providerConfig: SettingItemProviderConfig<SettingAssetApi> = {
        UI: { Application: UI },
        Layout: Layout.Page,
        api: {
            useContext() {
                const found = useContext(SettingAssetContext);
                assert(found, 'not inside asset settings route context');
                return found;
            },
            controller: page.controller,
            loader: page.loader,
        },
    };

    const items = config.config.items.flatMap((item): SettingItemRoot[] => {
        if (!isSettingItemProvider(item)) {
            return [item];
        }
        const instances = item.create(providerConfig);
        return instances.flatMap((item) => [item]);
    });

    const DefaultRedirect = () => {
        const firstPath = items[0]?.path;
        assert(firstPath, 'no default path found');
        return <Navigate to={firstPath} replace={true} />;
    };

    return {
        Route: createSettingAssetContainer(
            config.deps,
            controller.route,
            createSettingAssetView(config, items)
        ),
        Redirect: DefaultRedirect,
        items,
    };
}
