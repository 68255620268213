import { H, HighlightSegmentMiddleware } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';
import { HighlightConfig } from './highlightConfig';
import { getApplicationEnv } from '../env';

export function createHighlight(config: HighlightConfig) {
    return {
        initialize() {
            const environment = getApplicationEnv();
            if (process.env.VAROS_ENV !== 'production') {
                console.info(`skpping highlight.run initialization in '${environment}'`);
                return;
            }
            // if (environment !== 'production') {
            //     return;
            // }
            H.init(config.projectId, {
                environment,
                enableSegmentIntegration: true,
                tracingOrigins: true,
                networkRecording: {
                    enabled: true,
                    recordHeadersAndBody: true,
                    urlBlocklist: [
                        // insert full or partial urls that you don't want to record here
                    ],
                },
            });

            try {
                window.analytics.addSourceMiddleware(HighlightSegmentMiddleware);
            } catch (error) {
                console.error(`failed to add highlight segment middleware`);
                throw error;
            }
        },
        ErrorBoundary,
    };
}
