import { z } from 'zod';

export const DataSyncStartRequestDtoSchema = z.object({
    integration_id: z.number(),
});

export const DataSyncStartResponseDtoSchema = z.object({
    handle: z.string(),
});

export const DataSyncDtoSchema = z.union([
    z.object({
        status: z.literal('pending'),
    }),
    z.object({
        status: z.literal('running'),
        integration_id: z.number(),
        internal_state: z.union([
            z.literal('raw_data_syncing'),
            z.literal('processing_data'),
            z.literal('importing_to_datasets'),
        ]),
    }),
    z.object({
        status: z.literal('failed'),
        error_detail: z.nullable(z.string()),
        reason: z.union([
            z.literal('integration_paused'),
            z.literal('sync_timed_out'),
            z.literal('integration_sync_failed'),
            z.literal('no_data_found'),
            z.literal('dataset_sync_error'),
            z.literal('unknown_error'),
        ]),
    }),
    z.object({
        status: z.literal('completed'),
        integration_id: z.number(),
    }),
]);
