import { fetchMembers } from '../../../../api';
import { LegacyAuthenticationScheme } from '../../../domain';
import { EnumType, TreeType } from '../../../domain/attributes';
import { MemberAdapter } from '../../../app/attributes';
import { MemberImplConfig } from './memberConfig';

export function createMemberImpl(config: MemberImplConfig): MemberAdapter {
    const { axios } = config;
    return {
        async findByTypes(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const result = await Promise.all(
                query.types.map(async (item) => {
                    const response = await fetchMembers(
                        axios,
                        item.id,
                        query.workspace.id as number,
                        null,
                        {
                            headers: {
                                Authorization: `bearer ${scheme.store.authToken}`,
                            },
                        }
                    );
                    const isTreeType = response.some(
                        (item) => Array.isArray(item.children) && item.children.length > 0
                    );

                    const type: EnumType | TreeType = isTreeType
                        ? {
                              kind: 'tree',
                              members: response.map((item) => ({
                                  label: item.name,
                                  value: item.value,
                                  children:
                                      item.children?.map((child) => ({
                                          label: child.name,
                                          value: child.value,
                                      })) ?? [],
                              })),
                          }
                        : {
                              kind: 'enum',
                              members: response.map((item) => ({
                                  label: item.name,
                                  value: item.value,
                              })),
                          };

                    const [first, second] = item.id.split('.');
                    const key = second ? second : first;

                    if (!key) {
                        console.error(item);
                        throw new Error(`key not found for item ${item.id}`);
                    }

                    return {
                        id: item.id,
                        // currently trait IDs on the plugin DTO are not qualified so we need to handle both cases
                        key: second ? second : first,
                        ...type,
                    };
                })
            );

            return result;
        },
    };
}
