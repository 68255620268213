import React from 'react';
import { CohortModuleConfig } from './cohortConfig';

export function createCohortModule(config: CohortModuleConfig) {
    const controller = config.provider.createController(config.deps);
    const View = config.provider.createView(config.deps);
    return {
        controller,
        View,
    };
}
