import React from 'react';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { GroupsSettingsSubmittedEvent } from './groupSettingsEvent';

export function createGroupSettingsTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<GroupsSettingsSubmittedEvent>();
            return tracker;
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                groups: {
                    ...config.controller.groups,
                    group: {
                        ...config.controller.groups.group,
                        createSettings(settingProps) {
                            const controller =
                                config.controller.groups.group.createSettings(
                                    settingProps
                                );
                            return {
                                useProps(context, props) {
                                    const retVal = controller.useProps(context, props);
                                    const tracker = useTracker();
                                    return {
                                        ...retVal,
                                        onSave(data) {
                                            try {
                                                return retVal.onSave(data);
                                            } finally {
                                                if (data.state !== 'pending') {
                                                    tracker.track(
                                                        'settings_organization_privacy_submitted',
                                                        {
                                                            group_membership: data.state,
                                                            configured_assets:
                                                                data.optinAssets?.map(
                                                                    (x) => x.id
                                                                ) || [],
                                                        }
                                                    );
                                                }
                                            }
                                        },
                                    };
                                },
                            };
                        },
                    },
                },
            },
        });

        return instance;
    };
}
