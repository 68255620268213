import {
    DemoLayoutWrapper,
    DemoLayoutHeader,
    DemoLayoutBody,
    DemoLayoutBanner,
    DemoLayoutVisualization,
    DemoLayoutFilters,
    DemoLayoutPlatforms,
} from './component';
import { DemoLayoutMetrics } from './component/DemoLayoutMetrics';
import { DemoLayoutTime } from './component/DemoLayoutTime';
import { DemoLayoutErrorState } from './component/DemoLayoutErrorState';
import { DemoLayoutFooter } from './component/DemoLayoutFooter';

export const DemoLayout = {
    Wrapper: DemoLayoutWrapper,
    Header: DemoLayoutHeader,
    Body: DemoLayoutBody,
    Footer: DemoLayoutFooter,
    Banner: DemoLayoutBanner,
    Visualization: DemoLayoutVisualization,
    Platforms: DemoLayoutPlatforms,
    Filters: DemoLayoutFilters,
    Metrics: DemoLayoutMetrics,
    Time: DemoLayoutTime,
    ErrorState: DemoLayoutErrorState,
};
