import React from 'react';
import {
    OrganizationContextAggregate,
    UseOrganizationContextReturn,
} from './organizationModel';

export const OrganizationContext =
    React.createContext<OrganizationContextAggregate | null>(null);

export function useOrganizationContextV2(): UseOrganizationContextReturn {
    const value = React.useContext(OrganizationContext);
    if (!value) {
        throw new Error(`not inside org v2 context`);
    }
    return value;
}
