import { useForm } from 'react-hook-form';
import { InvitationRegisterFormController } from './invitationAcceptFormInterface';
import React from 'react';
import { InvitationRegisterFormValues } from './invitationAcceptFormProps';
import { InvitationService } from '../../../service';

function getDisplayUrl(url: string | undefined | null) {
    if (!url) {
        return url;
    }
    if (url.startsWith('https://')) {
        return url.substring(8);
    } else if (url.startsWith('http://')) {
        return url.substring(7);
    }
    return url;
}

export function createInvitationAcceptController({
    deps: {
        service: { invitations },
    },
}: {
    deps: {
        service: {
            invitations: InvitationService;
        };
    };
}): InvitationRegisterFormController {
    return {
        useProps(context, props) {
            const form = useForm<InvitationRegisterFormValues>({
                mode: 'onChange',
                reValidateMode: 'onChange',
            });
            const acceptCb = invitations.useAccept();
            const allTitles = React.useMemo(
                () =>
                    [
                        'Agency - CEO',
                        'Agency - VP or Director or C-Suite',
                        'Agency - Account Manager or Other',
                        'Brand - Marketer or other',
                        'Brand - CMO/CFO, VP or Director',
                        'Brand - CEO',
                        'SaaS - CEO',
                        'SaaS - VP or Director or C-Suite',
                        'SaaS - Marketer or Other',
                        'Other',
                    ].map((name) => ({ label: name, value: name })),
                []
            );

            React.useEffect(() => {
                if (props.data?.invitation.data?.kind === 'external') {
                    form.reset({
                        organization: {
                            organizationName:
                                props.data?.invitation.data?.organization_name ||
                                undefined,
                            organizationUrl:
                                getDisplayUrl(props.data?.invitation.data.url) ||
                                undefined,
                            assetName:
                                props.data?.invitation.data?.organization_name ||
                                undefined,
                            assetUrl:
                                getDisplayUrl(props.data?.invitation.data?.url) ||
                                undefined,
                        },
                        firstName: props.data?.invitation.data?.first_name || undefined,
                        lastName: props.data?.invitation.data?.last_name || undefined,
                        email: props.data?.invitation.data?.email || undefined,
                    });
                } else if (props.data?.invitation.data?.kind === 'internal') {
                    form.reset({
                        firstName: props.data?.invitation.data.first_name || undefined,
                        lastName: props.data?.invitation.data.last_name || undefined,
                        email: props.data?.invitation.data.email || undefined,
                    });
                }
            }, [props.data.invitation.data]);

            return {
                getFormProps() {
                    return {
                        ...form,
                        onSubmit: (values) =>
                            acceptCb.mutateAsync({
                                token: context.token,
                                invitation_id: context.invitationId,
                                email: values.email,
                                first_name: values.firstName,
                                last_name: values.lastName,
                                title: values.title,
                                organization: values.organization
                                    ? {
                                          name: values.organization.organizationName,
                                          url: values.organization.organizationUrl.startsWith(
                                              'http'
                                          )
                                              ? values.organization.organizationUrl
                                              : `https://${values.organization.organizationUrl}`,
                                          // asset: ((values.organization.analyzeCurrentOrganization && values.organization.assetName) ? {
                                          //     name: values.organization.assetName,
                                          //     url: values.organization.assetUrl.startsWith('http') ? values.organization.assetUrl : `https://${values.organization.assetUrl}`
                                          // } : undefined)
                                      }
                                    : undefined,
                            }),
                        error: props.data.invitation?.error || acceptCb.error || null,
                        titleOptions: allTitles,
                        requireOrganization:
                            props.data.invitation.data?.kind === 'external',
                    };
                },
                getStatusProps() {
                    return {
                        isSaving: false,
                        isDisabled: false,
                        isLoading: props.data.invitation.isLoading,
                    };
                },
            };
        },
    };
}
