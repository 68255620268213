import { AccountProfileConfig } from './profileConfig';
import { AccountProfileLoader } from './profileInterface';

export function createAccountProfileLoader(
    config: AccountProfileConfig
): AccountProfileLoader {
    const { service, repository } = config;
    return {
        useData(context, props) {
            return {
                // registration: {
                //     option: {
                //         lookup: repository.registration.option.useLookup(context),
                //     },
                // },
                profile: {
                    lookup: service.profile.useLookup(context),
                    upsert: service.profile.useUpsert(context),
                },
            };
        },
    };
}
