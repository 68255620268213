import { chain } from 'lodash';
import { plural } from 'pluralize';
import { useMemo } from 'react';
import { AnyMappableEntity, getAssetDomain } from '../../../../domain/assets';
import { EntityListController } from './entityListInterface';
import { EntityListItem } from './entityListModel';
import { EntityListAlertProps } from './entityListProps';

export function createEntityListController(): EntityListController {
    return {
        useProps(context, deps, props) {
            const item = props.item.definition;
            const entities = props.item.entities;

            const entitiesById = useMemo(
                () =>
                    entities.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, AnyMappableEntity | undefined>
                    ),
                [entities]
            );

            const entitiesByKey = useMemo(
                () =>
                    entities.reduce(
                        (acc, item) => ({ ...acc, [item.key]: item }),
                        {} as Record<string, AnyMappableEntity | undefined>
                    ),
                [entities]
            );

            // const mapping = formValues.entityByDefinition[item.id];
            const entityName = item.entityName.toLowerCase();
            const title = `Select ${entityName}`;
            // const definitionMappings = mappingsByDefinition[item.id] ?? [];
            const definitionMappings = props.item.mappings;

            const values = useMemo(
                () =>
                    definitionMappings.flatMap((mapping) => {
                        const entity = entitiesByKey[mapping.entity.key];
                        if (!entity) {
                            return [];
                        }
                        return [
                            {
                                value: entity.id,
                                label: entity.name,
                                secondary: entity.key,
                                iconUrl: item.iconUrl,
                            },
                        ];
                    }),
                [definitionMappings, entitiesByKey]
            );

            const options = useMemo(
                () =>
                    chain(entities)
                        .filter(
                            (entity) =>
                                entity.name
                                    .toLowerCase()
                                    .includes(deps.state.search.value) ||
                                entity.key.toLowerCase().includes(deps.state.search.value)
                        )
                        .map(
                            (entity): EntityListItem => ({
                                value: entity.id,
                                label: entity.name,
                                secondary: entity.key,
                                iconUrl: item.iconUrl,
                                isDisabled: entity.status === 'invalid',
                            })
                        )
                        .orderBy(
                            (item) => [
                                item.isDisabled ? 1 : -1,
                                item.label.toLowerCase(),
                            ],
                            ['asc', 'asc']
                        )
                        .value(),
                [entities, deps.state.search.value]
            );

            const assetUrl = useMemo(
                () =>
                    (props.item.asset ? getAssetDomain(props.item.asset) : null) ?? null,
                [props.item.asset]
            );

            const noun = plural(entityName);

            const alertProps = useMemo<EntityListAlertProps | null>(() => {
                const analyzing = props.item.integrations.filter(
                    (item) => item.status === 'analyzing'
                );
                if (analyzing.length === 0) {
                    return null;
                }
                return {
                    status: 'warning',
                    title: `Integration ${noun} are being analyzed`,
                };
            }, [props.item.integrations]);

            return {
                items: options,
                title,
                targetLabel: assetUrl ?? 'N/A',
                tooltipLabel: item.requirement?.text ?? null,
                placeholder: `Search ${plural(entityName)}`,
                value: values,
                getSearchProps() {
                    return {
                        value: deps.state.search.value,
                        onChange(event) {
                            deps.state.search.onChange(event.target.value);
                        },
                        placeholder: `Search ${noun}`,
                    };
                },
                getItemProps(item) {
                    return {
                        ...item,
                        onSelect() {
                            const entity = entitiesById[item.value];
                            if (!entity) {
                                console.warn(`entity ${item.value} not found`);
                                return;
                            }
                            props.onClick(entity);
                        },
                    };
                },
                getEmptyState() {
                    if (options.length === 0) {
                        return {
                            kind: 'empty',
                            title: `No ${plural(entityName)} found`,
                            action: {
                                label: 'New integration',
                                path: '/u/settings/integrations',
                            },
                        };
                    }
                    return null;
                },
                getAlertProps() {
                    return alertProps;
                },
            };
        },
    };
}
