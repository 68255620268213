// Refresh feature-set every 5 minutes
const FEATURE_REFETCH_INTERVAL_MS = 1000 * 60 * 5;

const UPGRADE_URL = 'https://www.varos.com/pricing';

const BOOK_DEMO_URL = 'https://calendly.com/yardenshaked/varos-premium';
const ENTERPRISE_CONTACT_URL = 'https://calendly.com/yardenshaked/varos-premium';

export const featureConfig = {
    FEATURE_REFETCH_INTERVAL_MS,
    UPGRADE_URL,
    BOOK_DEMO_URL,
    ENTERPRISE_CONTACT_URL,
};
