import React from 'react';
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import { CssBaseline } from '@material-ui/core';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../styles/theme';
interface WithThemeProps {
    theme?: any;
    children?: React.ReactNode;
}

export const WithTheme: React.FC<{ children?: React.ReactNode } & WithThemeProps> = ({
    children,
}) => {
    return (
        // <MuiThemeProvider theme={defaultTheme.main}>
        <ChakraProvider theme={theme}>
            {/* <ThemeProvider {...{ theme }}> */}
            {/* <CssBaseline /> */}
            {React.Children.only(children)}
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                        {
                    </MuiPickersUtilsProvider> */}
            {/* </ThemeProvider> */}
        </ChakraProvider>
        // </MuiThemeProvider>
    );
};
