import { SentryConfig } from '../../infra';

const isProd = process.env.NODE_ENV === 'production';

const SENTRY_CONFIG_DEV: SentryConfig = {
    environment: 'development',
    enabled: false,
    debug: false,
    // Send 100% of traces in development and send 10% of traces in production.
    tracesSampleRate: 0.5,
    dsn: 'https://374e66df50de4910a629c1d691f31d65@o564927.ingest.sentry.io/6728938',
};

const SENTRY_CONFIG_PROD: SentryConfig = {
    environment: 'production',
    enabled: true,
    debug: false,
    // Send 100% of traces in development and send 10% of traces in production.
    tracesSampleRate: 0.1,
    dsn: 'https://374e66df50de4910a629c1d691f31d65@o564927.ingest.sentry.io/6728938',
};

export const SENTRY_CONFIG = isProd ? SENTRY_CONFIG_PROD : SENTRY_CONFIG_DEV;
