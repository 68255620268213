import { z } from 'zod';

export const VisualizationCreatePropsSchema = z.object({
    metric: z
        .string()
        .transform((item) => item.split(',').map((fragment) => fragment.trim()))
        .pipe(z.array(z.string())),
    kind: z.union([
        z.literal('comparison'),
        z.literal('line'),
        z.literal('distribution'),
        z.literal('bar'),
    ]),
    aggregation: z.optional(z.union([z.literal('count'), z.literal('sum')])),
    view: z.optional(z.string()),
    granularity: z.union([
        z.literal('hour'),
        z.literal('day'),
        z.literal('week'),
        z.literal('month'),
        z.literal('quarter'),
        z.literal('year'),
    ]),
    period: z
        .optional(z.string())
        .transform((item) => (item ? JSON.parse(item) : item))
        .pipe(
            z.optional(
                z.union([
                    z.object({
                        start: z.date({ coerce: true }),
                        end: z.date({ coerce: true }),
                    }),
                    z.object({
                        interval: z.union([
                            z.literal('day'),
                            z.literal('week'),
                            z.literal('month'),
                            z.literal('quarter'),
                            z.literal('year'),
                        ]),
                        amount: z.number(),
                    }),
                ])
            )
        ),
    filters: z
        .optional(z.string())
        .transform((item) => (item ? JSON.parse(item) : item))
        .pipe(
            z.optional(
                z.array(
                    z.object({
                        key: z.string(),
                        operator: z.any(),
                        value: z.any(),
                    })
                )
            )
        ),
    ranking: z
        .optional(z.string())
        .transform((item) => (item ? JSON.parse(item) : item))
        .pipe(
            z.optional(
                z.union([
                    z.object({
                        kind: z.literal('dynamic'),
                        cohorts: z.array(
                            z.object({
                                visible: z.boolean(),
                                name: z.string(),
                                value: z.number(),
                            })
                        ),
                    }),
                    z.object({
                        kind: z.literal('fixed'),
                        cohorts: z.array(
                            z.object({
                                visible: z.boolean(),
                                name: z.string(),
                                lower: z.number(),
                                upper: z.number(),
                            })
                        ),
                        comparison: z.string(),
                    }),
                ])
            )
        ),
    breakdown: z
        .optional(z.string())
        .transform((item) => (item ? JSON.parse(item) : item))
        .pipe(
            z.optional(
                z.object({
                    properties: z.array(
                        z.object({
                            key: z.string(),
                        })
                    ),
                })
            )
        ),
});
