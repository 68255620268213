import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { PlanModalLoaderConfig } from './planModalConfig';
import { PlanModalLoader } from './planModalInterface';

export function createPlanModalLoader(config: PlanModalLoaderConfig): PlanModalLoader {
    const {
        repository: {
            plan: planRepository,
            subscription: subscriptionRepository,
            feature: featureRepository,
        },
    } = config;
    return {
        useLoad(context, state) {
            const planQuery = planRepository.useList(context, {}, { suspense: true });
            const subscriptionQuery = subscriptionRepository.useLookup(context, {
                suspense: true,
            });
            const featureQuery = featureRepository.useLookup(context, { suspense: true });
            assert(planQuery.status === 'success', 'expected suspense');
            assert(subscriptionQuery.status === 'success', 'expected suspense');
            assert(featureQuery.status === 'success', 'expected suspense');

            const feature = useMemo(() => {
                if (!state.value.featureId) {
                    return null;
                }
                const feature = featureQuery.data[state.value.featureId];
                return feature ?? null;
            }, [featureQuery.data, state.value.featureId]);

            return {
                status: 'loaded',
                data: {
                    feature,
                    select: {
                        plans: planQuery.data.items,
                        subscription: subscriptionQuery.data,
                    },
                },
            };
        },
    };
}
