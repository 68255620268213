import { BasicDashboardDepReason, UserIntegrationDefinition } from '../../../../domain';
import {
    IntegrationAssetState,
    IntegrationReasonType,
    IntegrationReasonTypeList,
    PipelineAssetState
} from '../../../../api/v2/dashboard/deps';
import { IntegrationHistoryDto } from '../../../../api/integration';
import { isPending } from './helpers';
import moment from 'moment';

const integrationReasonsSet: Set<string> = new Set(IntegrationReasonTypeList);


export function  makePipelineReasonByIntegration(
    state: PipelineAssetState,
    selectedInteg: Pick<UserIntegrationDefinition, 'title'>,
    currentAssetName: string,
    lastConnected?: IntegrationHistoryDto): BasicDashboardDepReason {
    switch(state.state) {
        case 'has_issues': {
            switch(state.issue_detail) {
                case 'integration_disconnected': {
                    if (lastConnected && lastConnected.trigger_user) {
                        const triggerName =
                            lastConnected.trigger_user.first_name &&
                            lastConnected.trigger_user.last_name
                                ? `${lastConnected.trigger_user.first_name} ${lastConnected.trigger_user.last_name}`
                                : lastConnected.trigger_user.email;
                        const when = moment(lastConnected.created_at).format('YYYY-MM-DD HH:mm');
        
                        return {
                            reasonType: 'no_integration',
                            isLoading: false,
                            text: `We no longer have access to ${currentAssetName}’s ${selectedInteg.title} data. This was connected by ${triggerName} at ${when}, and we see that Varos no longer has access to ${triggerName}'s integrations`,
                            instructions: [
                                `Integrate again with ${currentAssetName}’s data in order to access the benchmarks.`,
                            ],
                        };
                    }
                    return {
                        reasonType: 'no_integration',
                        isLoading: false,
                        text: `You don’t have an active ${selectedInteg.title}`,
                        instructions: [`Connect ${selectedInteg.title} to access this dashboard`],
                    };
                }
                case 'integration_unmapped': {
                    return {
                        reasonType: 'connected_integ_unmapped',
                        isLoading: true,
                        text: `Our team is still processing your integration. It takes up to 4 business days.`,
                        instructions: [
                            `Please wait until we process your integration. If it’s been longer than 4 business days, contact`,
                        ],
                    }
                }
                case 'export_no_data':
                case 'not_eligible':
                case 'export_failed_prerequisites':
                case 'data_source_data_check_failed':
                    return {
                        reasonType: 'connected_integ_no_data',
                        isLoading: false,
                        text: `There is no data for the last 30 days in ${currentAssetName}’s ${selectedInteg.title} account.`,
                        instructions: [`Connect another integration with data for this brand.`],
                    };
                case 'integration_first_sync_delay':
                case 'data_source_transformation_delay':
                case 'integration_periodic_sync_delay':
                case 'dataset_periodic_delay':
                case 'dataset_first_sync_delay':
                case 'import_delay':
                case 'export_first_sync_delay':
                case 'export_periodic_delay':
                    return {
                        reasonType: 'connected_integ_delay',
                        isLoading: false,
                        text: `We're experiencing delays on syncing ${currentAssetName}’s data from ${selectedInteg.title}.`,
                        instructions: [`Retry later or reach us for further information`],
                    };
                case 'dataset_first_sync_pending':
                case 'integration_sync_in_flight':
                case 'import_pending':
                    return {
                        reasonType: 'connected_integ_syncing',
                        isLoading: true,
                        text: `An integration is connected, and we're currently syncing its data`,
                        instructions: ['Check back soon. Sync times take between 1 to 3 days.'],
                    };
                default:
                    return {
                        reasonType: 'unknown',
                        isLoading: true,
                        text: 'We detected issues with your integration.',
                        instructions: ['Please reach us for info'],
                    };
            }
        }
        case 'in_flight':
            return {
                reasonType: 'connected_integ_syncing',
                isLoading: true,
                text: `An integration is connected, and we're currently syncing its data`,
                instructions: ['Check back soon. Sync times take between 1 to 3 days.'],
            };
        default:
            return {
                reasonType: 'unknown',
                isLoading: true,
                text: 'Unknown integration status',
                instructions: ['Please reach us for info'],
            };
    }
}

export function makeIntegrationReasonByIntegration(
    integStatus: IntegrationReasonType,
    reason: IntegrationAssetState,
    selectedInteg: Pick<UserIntegrationDefinition, 'title'>,
    currentAssetName: string,
    lastConnected?: IntegrationHistoryDto
) {
    switch (integStatus) {
        case 'connected_integ_no_data_current_asset': {
            if (lastConnected && lastConnected.trigger_user) {
                const triggerName =
                    lastConnected.trigger_user.first_name &&
                    lastConnected.trigger_user.last_name
                        ? `${lastConnected.trigger_user.first_name} ${lastConnected.trigger_user.last_name}`
                        : lastConnected.trigger_user.email;
                const when = moment(lastConnected.created_at).format('YYYY-MM-DD HH:mm');

                return {
                    reasonType: integStatus,
                    isLoading: isPending(reason),
                    text: `We no longer have access to ${currentAssetName}’s ${selectedInteg.title} data. This was connected by ${triggerName} at ${when}, and we see that they no longer have access to the brands account`,
                    instructions: [
                        `Integrate again with ${currentAssetName}’s data in order to access the benchmarks.`,
                    ],
                };
            }
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `We no longer have access to ${currentAssetName}’s data in your ${selectedInteg.title} integration.`,
                instructions: [
                    `Integrate again with ${currentAssetName}’s data in order to access the benchmarks.`,
                ],
            };
        }
        case 'connected_integ_no_data':
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `There is no data for the last 30 days in ${currentAssetName}’s ${selectedInteg.title} account.`,
                instructions: [`Connect another integration with data for this brand.`],
            };
        case 'connected_integ_other_brands':
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `Your ${selectedInteg.title} integration contains no data for this brand.`,
                instructions: [
                    `Connect ${currentAssetName}’s ${selectedInteg.title} account now. If you think this is a mistake, contact support.`,
                ],
            };
        case 'connected_integ_unmapped':
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `Our team is still processing your integration. It takes up to 4 business days.`,
                instructions: [
                    `Please wait until we process your integration. If it’s been longer than 4 business days, contact`,
                ],
            };
        case 'connected_integ_syncing':
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `An integration is connected, and we're currently syncing its data`,
                instructions: ['Check back soon. Sync times take between 1 to 3 days.'],
            };
        case 'connected_integ_empty':
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `Your ${selectedInteg.title} integration is connected, but it looks like your ${selectedInteg.title} account is inactive.`,
                instructions: [
                    'Connect an active account order to get access to this dashboard',
                ],
            };
        case 'no_integration':
            if (lastConnected && lastConnected.trigger_user) {
                const triggerName =
                    lastConnected.trigger_user.first_name &&
                    lastConnected.trigger_user.last_name
                        ? `${lastConnected.trigger_user.first_name} ${lastConnected.trigger_user.last_name}`
                        : lastConnected.trigger_user.email;
                const when = moment(lastConnected.created_at).format('YYYY-MM-DD HH:mm');

                return {
                    reasonType: integStatus,
                    isLoading: isPending(reason),
                    text: `We no longer have access to ${currentAssetName}’s ${selectedInteg.title} data. This was connected by ${triggerName} at ${when}, and we see that Varos no longer has access to ${triggerName}'s integrations`,
                    instructions: [
                        `Integrate again with ${currentAssetName}’s data in order to access the benchmarks.`,
                    ],
                };
            }
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: `You don’t have an active ${selectedInteg.title}`,
                instructions: [`Connect ${selectedInteg.title} to access this dashboard`],
            };
        default:
            return {
                reasonType: integStatus,
                isLoading: isPending(reason),
                text: reason?.reason_text || 'Unknown reason',
                instructions: reason?.instructions || [],
            };
    }
}

export function createForIntegration(
    integStatus: IntegrationReasonType,
    status: IntegrationAssetState,
    currentAssetName: string,
    selectedInteg?: UserIntegrationDefinition,
    lastConnected?: IntegrationHistoryDto
): BasicDashboardDepReason {
    if (!selectedInteg) {
        return {
            reasonType: integStatus,
            isLoading: isPending(status),
            text: status.reason_text || 'Unknown Reason',
            instructions: status.instructions || [],
        };
    }
    return makeIntegrationReasonByIntegration(
        integStatus,
        status,
        selectedInteg,
        currentAssetName,
        lastConnected
    );
}

export function createForView(currentAssetName: string): BasicDashboardDepReason {
    return {
        reasonType: 'not_sharing',
        isLoading: true,
        text: `We are still syncing your ${currentAssetName}'s data to the Varos Platform`,
        instructions: ['It takes us up to 4 days to review and sync your data'],
    };
}
