import { VideoControllerProps, VideoViewProps } from './videoProps';

export function buildVideoViewProps(props: VideoControllerProps): VideoViewProps {
    const url = new URL(props.url);
    url.searchParams.set('hideEmbedTopBar', 'true');
    url.searchParams.set('hide_share', 'true');
    url.searchParams.set('hide_title', 'true');
    url.searchParams.set('hide_owner', 'true');
    url.searchParams.set('autoplay', 'false');
    const paramString = url.searchParams.toString();
    return {
        title: props.title,
        description: props.description ?? null,
        src: `${props.url}${paramString}`,
    };
}
