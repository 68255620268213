import React from 'react';
import { createCalculationLayout } from '../../../layout';
import { ComparisonViewProps } from './comparisonProps';
import { ComparisonConfig } from './comparisonConfig';

export function createComparisonView(
    config: ComparisonConfig
): React.FC<{ children?: React.ReactNode | undefined } & ComparisonViewProps> {
    const { Container, Value, Date, Name, Description, Formula, Ranking } =
        createCalculationLayout(config);
    return (props) => {
        return (
            <Container>
                <Name icon={props.iconUrl ?? undefined}>{props.name}</Name>
                <Date>{props.period}</Date>
                {props.ranking ? <Ranking>{props.ranking.name}</Ranking> : null}
                {props.ticks.map((item, index) => (
                    <Value
                        key={index}
                        label={item.label}
                        group={item.group}
                        color={item.color}
                        description={item.description}
                    >
                        {item.value}
                    </Value>
                ))}
                {props.description ? (
                    <Description>{props.description}</Description>
                ) : null}
            </Container>
        );
    };
}
