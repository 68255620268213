import { OrganizationRoleEntity } from '../../app/permissions';
import { PageIdType } from '../page';

export const RolePageAccess: Record<
    OrganizationRoleEntity['id'],
    Partial<Record<PageIdType, Array<'read' | 'write'>>>
> = {
    admin: {
        settings_billing_privacy: ['read', 'write'],
        settings_billing_subscription: ['read', 'write'],
        settings_billing_payment: ['read', 'write'],
        settings_billing_payment_method_add: ['read', 'write'],
        settings_asset_general_settings: ['read', 'write'],
        settings_asset_mapping: ['read', 'write'],
        settings_asset_plugin: ['read', 'write'],
        settings_asset_imports_list: ['read', 'write'],
    },
    standard: {
        settings_asset_imports_list: ['read'],
        settings_billing_privacy: ['read'],
        settings_asset_general_settings: ['read'],
        settings_asset_mapping: ['read'],
    },
    viewer: {},
};
