import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FeedbackEnhancerConfig } from '../feedbackConfig';
import { AnyFeedbackEvent } from '../feedbackEvent';

export function createVerticalFeedbackEnhancer(
    init: FeedbackEnhancerConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            controller: {
                segment: { createItem: createSegmentItemController },
            },
        } = config;

        function useTracker() {
            const tracker = instance.infra.useTracker<AnyFeedbackEvent>();
            return tracker;
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                // TODO remove duplication. we're removing the dashboard context soon anyway
                segment: {
                    createItem(...args) {
                        const controller = createSegmentItemController(...args);
                        return {
                            ...controller,
                            useProps(props) {
                                const mapped = controller.useProps(props);
                                const tracker = useTracker();
                                const handleTrackClick = () => {
                                    tracker.track('feedback_new_vertical_clicked', {});
                                };
                                return {
                                    ...mapped,
                                    getInputProps(filter) {
                                        const inputProps = mapped.getInputProps(filter);
                                        if (inputProps.type !== 'tree') {
                                            return inputProps;
                                        }
                                        return {
                                            ...inputProps,
                                            getCaption(state) {
                                                const current =
                                                    inputProps.getCaption?.(state);
                                                if (current) {
                                                    console.warn(
                                                        'overriding tree input caption prop'
                                                    );
                                                }
                                                if (!state.isExpanded) {
                                                    return null;
                                                }
                                                return (
                                                    <Text>
                                                        Didn't find what you were looking
                                                        for?{' '}
                                                        <Box
                                                            cursor="pointer"
                                                            as="a"
                                                            target="_blank"
                                                            href="https://docs.google.com/forms/d/e/1FAIpQLSddaqBxBewPi0bhNudyAAItx4oYXqugUbfuScrc133o8npNRA/viewform?usp=sf_link"
                                                            textDecoration="underline"
                                                            onClick={handleTrackClick}
                                                        >
                                                            Request a new vertical
                                                        </Box>
                                                    </Text>
                                                );
                                            },
                                        };
                                        return inputProps;
                                    },
                                };
                            },
                        };
                    },
                },
            },
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    return config.route.createDashboardRoute({
                        ...routeConfig,
                        controller: {
                            ...routeConfig.controller,
                            useSegmentItem(...args) {
                                const tracker = useTracker();
                                const [, filter, index] = args;
                                const viewProps = routeConfig.controller.useSegmentItem(
                                    ...args
                                );
                                if (filter.property.key !== init.verticalPropertyKey) {
                                    return viewProps;
                                }
                                const handleTrackClick = () => {
                                    tracker.track('feedback_new_vertical_clicked', {});
                                };
                                return {
                                    ...viewProps,
                                    getInputProps(props) {
                                        if (props.type !== 'tree') {
                                            return viewProps.getInputProps(props);
                                        }
                                        return viewProps.getInputProps({
                                            ...props,
                                            getCaption(state) {
                                                const current = props.getCaption?.(state);
                                                if (current) {
                                                    console.warn(
                                                        'overriding tree input caption prop'
                                                    );
                                                }
                                                if (!state.isExpanded) {
                                                    return null;
                                                }
                                                return (
                                                    <Text>
                                                        Didn't find what you were looking
                                                        for?{' '}
                                                        <Box
                                                            cursor="pointer"
                                                            as="a"
                                                            target="_blank"
                                                            href="https://docs.google.com/forms/d/e/1FAIpQLSddaqBxBewPi0bhNudyAAItx4oYXqugUbfuScrc133o8npNRA/viewform?usp=sf_link"
                                                            textDecoration="underline"
                                                            onClick={handleTrackClick}
                                                        >
                                                            Request a new vertical
                                                        </Box>
                                                    </Text>
                                                );
                                            },
                                        });
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
