import React from 'react';
import { ApplicationUI } from '../../../ui';
import { InsightModuleConfig } from './insightConfig';
import { createInsightLoader } from './insightLoader';

export function createInsightModule(config: InsightModuleConfig) {
    const controller = config.provider.createController(config.deps);
    const View = config.provider.createView(config.deps);
    return {
        createContainer: config.provider.createContainer.bind(
            null,
            config.deps,
            controller
        ),
        controller,
        loader: createInsightLoader(config.deps),
        View,
        createView(viewConfig: { UI: ApplicationUI }) {
            return config.provider.createView({ ...config.deps, ...viewConfig });
        },
    };
}
