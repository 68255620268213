import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { createMockCompanyEnhancer } from './company';
import { createMockAssistantEnhancer } from './assistant';
import { createMockCountEnhancer } from './count';
import { createMockDatasetEnhancer } from './dataset';
import { createMockSubmissionsEnhancer } from './forms';
import { createMockGroupEnhancer } from './group';
import { createMockInvitationEnhancer } from './invitation';
// import { createMockMetricEnhancer } from './metric';
import { MockEnhancerConfig } from './mockConfig';
import { createMockRecommendationEnhancer } from './recommendation';
// import { createMockPipelineEnhancer } from './pipeline';
import { createMockReportEnhancer } from './report';
import { createMockVisualizationEnhancer } from './visualization';
// import { createMockViewEnhancer } from './view';

export function createMockEnhancer(init: MockEnhancerConfig): ApplicationEntryEnhancer {
    return compose(
        //
        // createMockPipelineEnhancer(init),
        // createMockViewEnhancer(init),
        // createMockMetricEnhancer(init),
        // createMockImportEnhancer(init),
        // createMockSubmissionsEnhancer(init)
        // createMockDatasetEnhancer(init),
        // createMockRecommendationEnhancer(init),
        // createMockCompanyEnhancer(init),
        // createMockCountEnhancer(init),
        // createMockGroupEnhancer(init),
        // createMockVisualizationEnhancer({})
        // createMockInvitationEnhancer(init),
        createMockReportEnhancer(init.report)
        // createMockAssistantEnhancer()
    );
}
