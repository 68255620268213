import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { createBillingAddonTrackingStrategy } from './billing';
import { createGroupHomeTrackingStrategy } from './home';
import { createGroupSettingsTrackingStrategy } from './settings';
export * from './trackingGroupEvent';

export function createGroupTrackingStrategy(): ApplicationEntryEnhancer {
    return compose(
        createBillingAddonTrackingStrategy(),
        createGroupHomeTrackingStrategy(),
        createGroupSettingsTrackingStrategy(),
    );
}
