import { TimeGranularity } from '../../models/Common';
import { MetadataRule, MultiFilterRule } from '../../models/Metadata';
import { SavedDataFilter, TimestampFilter } from '../v2/type';
import {
    TraitFilterSection,
    DataFilterSection,
    SectionConfig,
} from './accountPreferenceDto';

export function isTraitSection(section: SectionConfig): section is TraitFilterSection {
    return section.section_type === 'trait_filters';
}

export function isDataSection(section: SectionConfig): section is DataFilterSection {
    return section.section_type === 'data_filters';
}
