import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { PluginDto } from '../../../../api';
import { useWorkspaceApi } from '../../../../app/dashboard';
import { WorkspaceNavigationController } from './navigationInterface';
import { createWorkspaceItems } from './navigationFactory';
import { WorkspaceNavigationControllerConfig } from './navigationConfig';

// temp hack to workaround not-highlighted dashboards
// not being able to be returned from API
const HIDDEN_SLUGS = ['creative-breakdowns'];

export function createWorkspaceNavigationController(
    config: WorkspaceNavigationControllerConfig
): WorkspaceNavigationController {
    const {
        hook: { useAuth },
        service: {
            plugin: { useList: usePlugins },
        },
    } = config;
    return {
        useProps() {
            const location = useLocation();
            const auth = useAuth();
            const api = useWorkspaceApi();
            const collection = api.getDashboards();
            const plugins = usePlugins({ auth });

            const pluginsById = useMemo(
                () =>
                    plugins.reduce(
                        (acc, plugin) => ({ ...acc, [plugin.id]: plugin }),
                        {} as Record<string, PluginDto>
                    ),
                [plugins]
            );

            const items = useMemo(
                () =>
                    createWorkspaceItems(
                        pluginsById,
                        collection.items.filter((item) => !HIDDEN_SLUGS.includes(item.id))
                    ),
                [collection.items, pluginsById]
            );

            return {
                items,
                isInstalled(item) {
                    return item.kind === 'dashboard'
                        ? api.isHighlighted(item.dashboard)
                        : item.dashboards.some((dashboard) =>
                              api.isHighlighted(dashboard)
                          );
                },
                isActive(item) {
                    return item.kind === 'dashboard'
                        ? location.pathname.includes(item.dashboard.id)
                        : item.dashboards.some((dashboard) =>
                              location.pathname.includes(dashboard.id)
                          );
                },
                getPath(dashboard) {
                    const base = collection.getPath(dashboard);
                    if (dashboard.access === 'inactive') {
                        return `${base}/setup`;
                    }
                    return base;
                },
            };
        },
    };
}
