import { capitalize } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { BeatLoader } from 'react-spinners';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    Tooltip,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { AiOutlineLink, AiOutlineSend, AiOutlineWarning } from 'react-icons/ai';
import { Graphics } from '../../../../config/svg';
import { assertNever } from '../../../../util';
import {
    ActionBlock,
    AnyBlock,
    AnyElement,
    AnyInputBlock,
    ButtonElement,
    EventBlock,
    SectionBlock,
    SelectBlock,
    TextBlock,
} from '../../../../app/assistant';

import { AssistantViewBaseConfig } from '../../base';
import { createThreadInfoView } from './info';
import { MessageItemViewProps } from './message';
import { ThreadDetailViewProps } from './threadDetailProps';
import { ButtonElementContainerProps, ButtonElementViewProps } from '../../view/button';
import {
    VisualizationBlockContainerProps,
    VisualizationBlockViewProps,
} from '../../view';
import { AttachmentReferenceObject } from '@varos/assistant-sdk';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export function createThreadDetailView(
    config: AssistantViewBaseConfig,
    ElementUI: {
        Button: React.FC<
            { children?: React.ReactNode | undefined } & ButtonElementContainerProps
        >;
        Visualization: React.FC<
            { children?: React.ReactNode | undefined } & VisualizationBlockContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & ThreadDetailViewProps> {
    const { Button: ButtonElementContainer, Visualization: VisualizationBlockContainer } =
        ElementUI;
    const {
        Layout,
        UI: {
            Application: { Link },
            Visualization: { BarChartV2: BarChart },
        },
    } = config;

    const Detail = createThreadInfoView(config);

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    const messageStyle = {
        maxW: '32rem',
    } satisfies BoxProps;

    const ButtonElement: React.FC<
        { children?: React.ReactNode | undefined } & ButtonElementViewProps
    > = (props) => {
        return (
            <Button {...props.button} _focus={{ outline: 'none' }}>
                {props.label}
            </Button>
        );
    };

    const Element: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyElement }
    > = (props) => {
        if (props.data.kind === 'button') {
            return <ButtonElementContainer as={ButtonElement} element={props.data} />;
        }
        assertNever(props.data.kind);
    };

    const TextAttachment: React.FC<AttachmentReferenceObject> = (props) => {
        const [searchParams] = useSearchParams();
        const assetId = searchParams.get('asset');
        if (props.type === 'submission') {
            return (
                // TODO generalize and abstract out the link generation
                <Link
                    to={`/u/assets/${assetId}/reporting/surveys/submissions/${props.id}`}
                >
                    <HStack
                        py={2}
                        px={4}
                        cursor="pointer"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderRadius="full"
                        fontWeight="medium"
                        _hover={{ textDecor: 'underline' }}
                    >
                        <Icon as={AiOutlineLink} />
                        <Text>{props.id}</Text>
                    </HStack>
                </Link>
            );
        }
        assertNever(props.type);
    };

    const TextBlock: React.FC<{ children?: React.ReactNode | undefined } & TextBlock> = (
        props
    ) => {
        return (
            <VStack
                align="start"
                w="full"
                spacing={4}
                maxW={containerStyle.maxW}
                fontWeight={props.modifiers?.includes('bold') ? 'semibold' : 'normal'}
                fontStyle={props.modifiers?.includes('italic') ? 'italic' : undefined}
            >
                <Text
                    color={
                        props.modifiers?.includes('bold') ? undefined : 'whiteAlpha.800'
                    }
                >
                    {props.text}
                </Text>
                {props.attachments && (
                    <HStack w="full" spacing={3}>
                        {props.attachments.map((attachment, index) => (
                            <TextAttachment key={attachment.id} {...attachment} />
                        ))}
                    </HStack>
                )}
            </VStack>
        );
    };

    // const JsonBlock: React.FC<{children?: React.ReactNode | undefined } & JsonBlock> = (props) => {
    //     return (
    //         <VStack align="start" w="full" spacing={1} maxW={containerStyle.maxW}>
    //             <Text color="whiteAlpha.800">{JSON.stringify(props.data, null, 2)}</Text>
    //         </VStack>
    //     );
    // };

    const SelectBlock: React.FC<
        { children?: React.ReactNode | undefined } & SelectBlock
    > = (props) => {
        return (
            <HStack>
                <Select placeholder={props.placeholder ?? 'Select'}>
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </HStack>
        );
    };

    const ActionBlock: React.FC<
        { children?: React.ReactNode | undefined } & ActionBlock
    > = (props) => {
        return (
            <HStack>
                {props.elements.map((element, index) => (
                    <Element key={index} data={element} />
                ))}
            </HStack>
        );
    };

    const SectionBlock: React.FC<
        { children?: React.ReactNode | undefined } & SectionBlock
    > = (props) => {
        return (
            <VStack align="start" w="full" spacing={3} maxW={containerStyle.maxW}>
                {props.text && <Text color="whiteAlpha.800">{props.text}</Text>}
                <VStack
                    align="start"
                    w="full"
                    px={6}
                    py={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderRadius="lg"
                    spacing={3}
                >
                    {props.fields.map((field, index) => (
                        <VStack key={index} align="start" w="full" spacing={0}>
                            <Text fontWeight="medium" color="whiteAlpha.600">
                                {field.label}
                            </Text>
                            <Text fontWeight="semibold" color="white">
                                {field.value}
                            </Text>
                        </VStack>
                    ))}
                </VStack>
            </VStack>
        );
    };

    const InputBlock: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyInputBlock }
    > = (props) => {
        if (props.data.type === 'select') {
            return <SelectBlock {...props.data} />;
        }
        assertNever(props.data.type);
    };

    const EventBlock: React.FC<
        { children?: React.ReactNode | undefined } & EventBlock
    > = (props) => {
        return (
            <HStack
                color="whiteAlpha.600"
                fontWeight="medium"
                userSelect="none"
                fontStyle="italic"
                // bg="whiteAlpha.50"
                // borderColor="whiteAlpha.300"
                // borderWidth={2}
                px={6}
                py={4}
                borderRadius="lg"
            >
                <Text>{capitalize(props.data.kind)} submitted</Text>
            </HStack>
        );
    };

    const VisualizationBlock: React.FC<
        { children?: React.ReactNode | undefined } & VisualizationBlockViewProps
    > = (props) => {
        return (
            <HStack
                w="full"
                py={12}
                // borderWidth={2}
                // borderStyle="solid"
                // borderColor="whiteAlpha.300"
                // borderRadius="lg"
            >
                <BarChart.Container
                    labels={['a', 'b', 'c', 'd', 'e', 'f']}
                    formatCategory={(value) => value}
                    formatScale={(value) => value.toString()}
                    formatTooltip={(value) => value.toString()}
                    formatValue={(value) => value.toString()}
                    series={[
                        {
                            name: 'series 1',
                            colorScheme: 'red',
                            data: [
                                { value: 3 },
                                { value: 2.5 },
                                { value: 2 },
                                { value: 2 },
                                { value: 1.4 },
                                { value: 1 },
                            ],
                        },
                    ]}
                >
                    <BarChart.Item bg="red.300" />
                    <BarChart.Scale label="CPM" />
                </BarChart.Container>
            </HStack>
        );
    };

    const Block: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyBlock }
    > = (props) => {
        if (props.data.kind === 'text') {
            return <TextBlock {...props.data} />;
        }
        if (props.data.kind === 'input') {
            return <InputBlock data={props.data} />;
        }
        if (props.data.kind === 'section') {
            return <SectionBlock {...props.data} />;
        }
        if (props.data.kind === 'action') {
            return <ActionBlock {...props.data} />;
        }
        // if (props.data.kind === 'custom') {
        //     return <Text>custom block</Text>;
        // }
        if (props.data.kind === 'event') {
            return <EventBlock {...props.data} />;
        }
        if (props.data.kind === 'unknown') {
            return (
                <HStack userSelect="none" color="red.400" spacing={2}>
                    <Icon as={AiOutlineWarning} color="red.400" />
                    <Text>Unsupported block</Text>
                </HStack>
            );
        }
        if (props.data.kind === 'custom') {
            if (props.data.custom.type === 'visualization') {
                return (
                    <VisualizationBlockContainer
                        block={props.data.custom}
                        as={VisualizationBlock}
                    />
                );
            }
            assertNever(props.data.custom.type);
        }
        assertNever(props.data);
    };

    const SystemMessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        return (
            <Stack align="start" w="full" spacing={6} direction="column-reverse">
                <HStack w="full" spacing={6} align="start">
                    <HStack
                        align="center"
                        justify="center"
                        position="relative"
                        // left={-14}
                        top={-1}
                        flexShrink={0}
                        // bg="whiteAlpha.300"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderRadius="full"
                        h={8}
                        w={8}
                    >
                        <Image
                            position="relative"
                            h={3}
                            w={3}
                            top="1px"
                            right="1px"
                            src={Graphics.Brand.Icon}
                            alt="system"
                            // filter="grayscale(100%)"
                        />
                    </HStack>
                    <VStack w="full" align="start" spacing={3}>
                        {props.content
                            .filter(
                                // NOTE we render select blocks as suggestions
                                (block) =>
                                    !(block.kind === 'input' && block.type === 'select')
                            )
                            .flatMap((block, index) => (
                                <HStack
                                    key={index}
                                    w="full"
                                    maxW={messageStyle.maxW}
                                    // maxW={
                                    //     block.kind === 'custom'
                                    //         ? undefined
                                    //         : messageStyle.maxW
                                    // }
                                >
                                    <Block data={block} />
                                </HStack>
                            ))}
                    </VStack>
                </HStack>
            </Stack>
        );
    };

    const UserMessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        return (
            <VStack w="full" align="end" spacing={3}>
                {props.content.map((block, index) => {
                    if (block.kind === 'event') {
                        return <EventBlock key={index} {...block} />;
                    }
                    if (block.kind !== 'text') {
                        return (
                            <React.Fragment key={index}>
                                user message type '{block.kind}' not implemented
                            </React.Fragment>
                        );
                    }
                    return (
                        <HStack key={index} w="full" justify="end">
                            <HStack
                                w="fit-content"
                                maxW={messageStyle.maxW}
                                bg="whiteAlpha.200"
                                borderRadius="lg"
                                px={6}
                                py={4}
                            >
                                <Text color="whiteAlpha.800">{block.text}</Text>
                            </HStack>
                        </HStack>
                    );
                })}
            </VStack>
        );
    };

    const MessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        if (props.author.kind === 'system') {
            return <SystemMessageGroup {...props} />;
        }
        if (props.author.kind === 'user') {
            return <UserMessageGroup {...props} />;
        }
        assertNever(props.author.kind);
    };

    return (props) => {
        const colorSpinner = useToken('colors', 'whiteAlpha.400');
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Layout title="Join survey" sidebar={<Detail {...props.detail} />}>
                <Grid
                    w="full"
                    h="full"
                    templateColumns="1fr"
                    templateRows="1fr min-content"
                    templateAreas={`
                        "conversation"
                        "composer"
                    `}
                >
                    {/* Messages */}
                    <GridItem gridArea="conversation" position="relative">
                        <Stack
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            align="center"
                            w="full"
                            h="fit-content"
                            maxH="full"
                            spacing={12}
                            // pl={8}
                            pt={8}
                            pb={{
                                base: 32,
                                '2xl': 64,
                            }}
                            overflowY="scroll"
                            direction="column-reverse"
                            onScroll={props.chat.onScroll}
                            ref={props.chat.containerRef}
                            css={{
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    // background: scrollbarBg,
                                    background: scrollbarHoverBg,
                                },
                            }}
                        >
                            {/* Loading indicator */}
                            {props.isLoading && (
                                <HStack maxW={containerStyle.maxW} w="full">
                                    <HStack w="full" spacing={6} maxW={messageStyle.maxW}>
                                        <HStack
                                            align="center"
                                            justify="center"
                                            position="relative"
                                            top={-1}
                                            flexShrink={0}
                                            borderWidth={2}
                                            borderColor="whiteAlpha.300"
                                            borderRadius="full"
                                            h={8}
                                            w={8}
                                        >
                                            <Image
                                                position="relative"
                                                h={3}
                                                w={3}
                                                top="1px"
                                                right="1px"
                                                src={Graphics.Brand.Icon}
                                                alt="system"
                                            />
                                        </HStack>
                                        <HStack
                                            w="full"
                                            maxW={containerStyle.maxW}
                                            color="whiteAlpha.800"
                                            position="relative"
                                            top={-1}
                                        >
                                            <BeatLoader
                                                color={colorSpinner}
                                                speedMultiplier={0.7}
                                                size="1rem"
                                            />
                                        </HStack>
                                    </HStack>
                                </HStack>
                            )}
                            {props.message.items.map((item, index) => (
                                <HStack key={item.id} maxW={containerStyle.maxW} w="full">
                                    <MessageGroup {...item} />
                                </HStack>
                            ))}
                            {props.message.items.length > 0 && (
                                <HStack
                                    w="full"
                                    justify="center"
                                    fontWeight="medium"
                                    fontSize="sm"
                                    color="whiteAlpha.400"
                                >
                                    <Text>Start of conversation</Text>
                                </HStack>
                            )}
                        </Stack>
                    </GridItem>
                    {/* Composer */}
                    <GridItem gridArea="composer">
                        <HStack w="full" justify="center">
                            <VStack
                                align="start"
                                w="full"
                                h="full"
                                spacing={4}
                                pb={8}
                                maxW={containerStyle.maxW}
                            >
                                <Wrap w="full" shouldWrapChildren={true} spacing={2}>
                                    {props.suggestion.items.map((item) => (
                                        <Button
                                            key={item.id}
                                            bg="none"
                                            {...item.button}
                                            borderRadius="full"
                                            borderWidth={2}
                                            borderStyle="dashed"
                                            // borderColor="blue.300"
                                            // color="blue.300"
                                            _hover={{ bg: 'whiteAlpha.50' }}
                                            _active={{ bg: 'whiteAlpha.50' }}
                                            _focus={{ outline: 'none' }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </Wrap>
                                {props.composer.error && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>
                                            {props.composer.error.message}
                                        </AlertTitle>
                                    </Alert>
                                )}
                                <InputGroup>
                                    <Textarea
                                        {...props.chat.input}
                                        tabIndex={1}
                                        resize="none"
                                        ref={props.chat.composerRef}
                                        placeholder="Send a message"
                                        transition="all 0.3s ease"
                                        _readOnly={{
                                            borderColor: 'whiteAlpha.300',
                                            _focus: {
                                                borderColor: 'whiteAlpha.300',
                                            },
                                        }}
                                        _disabled={{
                                            borderColor: 'whiteAlpha.300',
                                            _focus: {
                                                borderColor: 'whiteAlpha.300',
                                            },
                                        }}
                                        _focus={{
                                            outline: 'none',
                                            borderColor: 'whiteAlpha.400',
                                        }}
                                        _focusVisible={{
                                            outline: 'none',
                                            borderColor: 'whiteAlpha.400',
                                        }}
                                    />
                                    <InputRightElement m={5}>
                                        <Tooltip
                                            arrowShadowColor="gray.100"
                                            {...props.composer.tooltip}
                                            shouldWrapChildren={true}
                                            py={2}
                                            px={3}
                                            placement="top"
                                            hasArrow={true}
                                            bg="gray.100"
                                            color="gray.900"
                                        >
                                            <IconButton
                                                {...props.chat.submit}
                                                aria-label="submit"
                                                borderRadius="full"
                                                icon={
                                                    <Icon
                                                        fontSize="lg"
                                                        as={AiOutlineSend}
                                                    />
                                                }
                                                bg="white"
                                                color="black"
                                                _disabled={{
                                                    opacity: 0.33,
                                                    cursor: 'default',
                                                }}
                                                _hover={{ bg: 'white' }}
                                                _focus={{ outline: 'none' }}
                                            />
                                        </Tooltip>
                                    </InputRightElement>
                                </InputGroup>
                            </VStack>
                        </HStack>
                    </GridItem>
                </Grid>
            </Layout>
        );
    };
}
