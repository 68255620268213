import { range, sample, shuffle } from 'lodash';
import { isCurrencyProperty, isTreeProperty } from '../../attributes';
import { PeerGroupExample } from './exampleModel';
import { ExampleProps } from './exampleProps';

export function buildPeerExample(props: ExampleProps): PeerGroupExample {
    const indices = range(0, props.size);

    // const percentiles = [0.1, 0.25, 0.5, 0.75, 0.9];
    const percentiles = [0.05, 0.1, 0.25, 0.5, 0.75];

    const rows = shuffle(indices).map((index) =>
        props.properties.reduce((acc, item) => {
            const { property, constraint } = item;
            if (isTreeProperty(property) && constraint.operator === 'in') {
                const sampled = sample(constraint.value);
                return { ...acc, [property.key]: sampled };
            }
            if (isCurrencyProperty(property) && constraint.operator === 'between') {
                const { from: min, to: max } = constraint.value;

                const difference = max - min;
                const step = difference < 10000 ? 5 : 500;

                const random = getRandomPercentile(
                    min,
                    max,
                    percentiles[index % indices.length],
                    step
                );
                return { ...acc, [property.key]: random };
            }
            console.info(`unexpected property and constraint`, property.key, constraint);
            return { ...acc };
        }, {})
    );

    return {
        columns: props.properties.map((prop) => prop.property),
        companies: indices.map((index) => ({
            name: `Company #${index + 1}`,
            data: rows[index],
        })),
    };
}

function getRandomPercentile(
    min: number,
    max: number,
    fraction: number,
    multiple: number
): number {
    // Calculate the range
    let range = max - min;

    // Generate a random number between 0 and 1, then scale it to the range
    // let randomFraction = Math.random();

    // // Calculate the percentile value
    let percentile = min + fraction * range;

    let tenPercent = percentile * 0.1;

    // Generate a random number within -10% to +10% of the original value
    let randomAdjustment = Math.random() * tenPercent * 2 - tenPercent;

    // Round to nearest multiple of the given parameter
    return Math.round((percentile + randomAdjustment) / multiple) * multiple;
}
