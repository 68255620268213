import { useQuery } from '@tanstack/react-query';
import { OrganizationScope } from '../../platform';
import { IntegrationAdapter } from './integrationAdapter';
import { IntegrationRepository } from './integrationInterface';
import { IntegrationQuery } from './integrationQuery';

export function createIntegrationRepository(
    adapter: IntegrationAdapter
): IntegrationRepository {
    function getPrefix(context: OrganizationScope, query: IntegrationQuery) {
        return ['org-integrations', context.organization, query];
    }
    return {
        useFind(context, query, options) {
            const applied: IntegrationQuery = {
                ...query,
                page_size: query.page_size ?? 1000,
            };
            return useQuery({
                queryKey: [...getPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
        },
    };
}
