import { chain } from 'lodash';
import { PluginDto } from '../../../../api';
import { Dashboard } from '../../../../domain';
import { WorkspaceNavigationItem } from './navigationModel';

export function createWorkspaceItems(
    pluginsById: Record<string, PluginDto | undefined>,
    dashboards: Dashboard[]
) {
    return chain(dashboards)
        .groupBy((dashboard) => dashboard.pluginId ?? dashboard.id)
        .flatMap((dashboards): WorkspaceNavigationItem[] => {
            const [sample] = dashboards;
            const plugin = sample.pluginId ? pluginsById[sample.pluginId] : null;
            return dashboards.length > 1 && plugin
                ? [
                      {
                          kind: 'package',
                          package: plugin,
                          dashboards,
                      },
                  ]
                : dashboards.map((dashboard) => ({
                      kind: 'dashboard',
                      dashboard,
                  }));
        })
        .value();
}
