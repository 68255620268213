import { z } from 'zod';

export const SessionSchema = z.object({
    id: z.string(),
    sourceName: z.optional(z.string()),
    sourceTrigger: z.optional(z.string()),
    sourceMedium: z.optional(z.string()),
    startedAt: z.date({ coerce: true }),
    refreshedAt: z.date({ coerce: true }),
    expiresAt: z.date({ coerce: true }),
});
