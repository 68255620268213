import { FilterItem } from '../../../../view/common';

export const FILTERS: FilterItem[] = [
    // {
    //     key: 'industry',
    //     label: 'Industry',
    //     description: null,
    //     isDisabled: false,
    //     Icon: null,
    //     input: {
    //         kind: 'multi_select',
    //         options: [
    //             {
    //                 label: 'Fashion',
    //                 value: 'fashion',
    //             },
    //             {
    //                 label: 'Beauty',
    //                 value: 'beauty',
    //             },
    //         ],
    //     },
    // },
    // {
    //     key: 'employees',
    //     label: 'Employees',
    //     description: null,
    //     isDisabled: false,
    //     Icon: null,
    //     input: {
    //         kind: 'multi_select',
    //         options: [
    //             {
    //                 label: '1-5',
    //                 value: '1_5',
    //             },
    //             {
    //                 label: '5-10',
    //                 value: '5_10',
    //             },
    //             {
    //                 label: '10-25',
    //                 value: '10_25',
    //             },
    //         ],
    //     },
    // },
];
