import React from 'react';
import {
    Button as ChakraButton,
    ButtonProps as ChakraButtonProps,
    Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button as VarosButton, ButtonProps as VarosButtonProps } from '../../button';

export function applyTargetChildDefaults(element: React.ReactElement) {
    if (element.type === ChakraButton || element.type === VarosButton) {
        const buttonProps: ChakraButtonProps & VarosButtonProps = {
            _focus: { outline: 'none' },
            bg: 'initial',
            size: 'sm',
            fontSize: 'sm',
            color: 'whiteAlpha.800',
            fontWeight: 'medium',
            _hover: {
                cursor: 'pointer',
                color: 'white',
            },
            ...element.props,
            children: (
                <Flex align="flex-start" alignItems="center">
                    {element.props.children}
                    <ChevronDownIcon ml={1} width={4} height={4} />
                </Flex>
            ),
        };
        return React.cloneElement(element, buttonProps);
    }
    return element;
}
