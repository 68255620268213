import { assert } from '../../../../../util/assert';
import { SubscriptionEditController } from '../../../../../view/billing';
import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { AnyBillingTrackingEvent } from '../trackingSettingEvent';

export function createTrackingSettingsBillingSubscriptionStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyBillingTrackingEvent>();
            return tracker;
        }
        function enhanceController(
            controller: SubscriptionEditController
        ): SubscriptionEditController {
            return {
                ...controller,
                useProps(...args) {
                    const [, , props] = args;
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        getFormStateProps() {
                            const formProps = viewProps.getFormStateProps();
                            return {
                                ...formProps,
                                async onSubmit(values) {
                                    const plan = props.item.plans.find(
                                        (candidate) => candidate.id === values.plan
                                    );
                                    assert(plan, `plan ${values.plan} not found`);
                                    const result = await formProps.onSubmit(values);
                                    tracker.track(
                                        'settings_subscription_edit_submitted',
                                        {
                                            subscription_plan_type: plan.type,
                                            subscription_addons: values.addons.map(x => x.addon_id),
                                            subscription_plan: values.plan,
                                        }
                                    );
                                    return result;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                billing: {
                    ...config.controller.billing,
                    subscription: {
                        ...config.controller.billing.subscription,
                        createEdit(...args) {
                            const controller =
                                config.controller.billing.subscription.createEdit(
                                    ...args
                                );
                            return enhanceController(controller);
                        },
                    },
                },
            },
        });

        return instance;
    };
}
