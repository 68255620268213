import {
    Alert,
    AlertTitle,
    Button,
    Input,
    InputGroup,
    Select,
    Switch,
} from '@chakra-ui/react';
import React from 'react';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingAssetProfileConfig } from './profileConfig';
import { SettingAssetProfileViewProps } from './profileProps';

export function createSettingAssetProfileView(
    init: SettingAssetProfileConfig,
    config: SettingAssetItemProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & SettingAssetProfileViewProps> {
    const {
        Layout: {
            Container,
            Content,
            Panel,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
            },
        },
    } = config;
    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const editProps = props.getEditProps();
        const alertProps = props.getAlertProps();
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem key={index} to={item.to}>
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                </Header>
                <Content>
                    <Panel.List>
                        <form
                            style={{ width: '100%' }}
                            {...editProps.getFormElementProps()}
                        >
                            <Panel.Item title="Profile">
                                <Panel.Action.List>
                                    <Button
                                        {...editProps.getSubmitButtonProps()}
                                        colorScheme="blue"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Save
                                    </Button>
                                </Panel.Action.List>
                                {alertProps.items.map((item) => (
                                    <Panel.Alert
                                        key={item.label}
                                        status={item.status ?? undefined}
                                        label={item.label}
                                    />
                                ))}
                                <Panel.FormControl
                                    {...props.getFormControlProps('name')}
                                    label="Company name"
                                    description="The name of the company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('name')}
                                            isDisabled={true}
                                            autoComplete="disabled"
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                                <Panel.FormControl
                                    {...props.getFormControlProps('url')}
                                    label="Website URL"
                                    description="The website for the company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('url')}
                                            autoComplete="disabled"
                                            // readOnly={true}
                                            // isDisabled={true}
                                            isDisabled={true}
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                                <Panel.FormControl
                                    label="Company type"
                                    description="The type of company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('kind')}
                                            isDisabled={true}
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                            </Panel.Item>
                        </form>
                        <Panel.Item title="Preferences">
                            <Panel.FormControl
                                label="Visible"
                                description="A company that is not visible will be hidden
                                in the rest of the platform"
                            >
                                <Switch
                                    isDisabled={true}
                                    isReadOnly={true}
                                    isChecked={true}
                                    size="md"
                                    _focus={{ outline: 'none' }}
                                />
                            </Panel.FormControl>
                        </Panel.Item>
                    </Panel.List>
                </Content>
            </Container>
        );
    };
}
