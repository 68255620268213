import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { CompanyItemViewProps } from '../item';
import { CompanyListControllerConfig } from './companyListConfig';
import { CompanyListController } from './companyListInterface';

export function createCompanyListController(
    config: CompanyListControllerConfig
): CompanyListController {
    const {
        infra: { formatter },
    } = config;

    return {
        useProps(context, item, props) {
            const itemPropsById = useMemo(() => {
                return item.items.reduce(
                    (acc, item) => {
                        const itemProps = config.factory.build(props, item);
                        return { ...acc, [itemProps.id]: itemProps };
                    },
                    {} as Record<string, CompanyItemViewProps | undefined>
                );
            }, [item.items, props]);
            return {
                items: item.items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.company.id];
                    assert(itemProps, 'missing item props');
                    return itemProps;
                },
            };
        },
    };
}
