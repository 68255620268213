import { Icons } from '../../../../../config';
import { FormIds, PageIds } from '../../../../config';
import { buildStudyItemViewProps } from '../../../../view/studies';
import { StudiesSubmissionNewRouteConfig } from './studiesSubmissionNewConfig';
import { StudiesSubmissionNewController } from './studiesSubmissionNewInterface';
import { StudiesSubmissionViewProps } from './studiesSubmissionNewProps';

export function createStudiesSubmissionNewController(
    config: Pick<StudiesSubmissionNewRouteConfig, 'controller' | 'service'>
): StudiesSubmissionNewController {
    const {
        service: { route: routeService },
        controller: {
            // invite: inviteController,
            // submission: viewController,
            field: fieldController,
            page: pageController,
            form: formController,
            group: { option: groupOptionController },
            type: { option: typeOptionController },
        },
    } = config;
    return {
        useProps(context, deps, props): StudiesSubmissionViewProps {
            const formProps = formController.useProps({
                id: FormIds.STUDY_SUBMISSION,
                form: deps.form,
                async onSubmit(values) {
                    try {
                        // const response = await deps.mutation.mutateAsync(values);
                        const response = await routeService.create(context, deps, values);
                        deps.toast({
                            kind: 'success',
                            description: `Study response successfully submitted`,
                        });
                        const path = `/u/assets/${context.workspace.id}/reporting/surveys/submissions/${response.id}`;
                        deps.navigate(path);
                    } catch (error) {
                        deps.toast({
                            kind: 'error',
                            description: `Study response submission failed`,
                        });

                        throw error;
                    }
                },
                onError(errors) {
                    // if (errors.invite?.text?.message) {
                    //     deps.toast({
                    //         kind: 'error',
                    //         description: `Invalid group description`,
                    //     });
                    //     return;
                    // }
                    if (errors.payload) {
                        deps.toast({
                            kind: 'error',
                            description: `Invalid answers`,
                        });
                        return;
                    }
                    console.error(errors);
                    deps.toast({
                        kind: 'error',
                        description: `Form has one or more errors`,
                    });
                },
            });
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.STUDIES_SUBMISSION_NEW,
                    // breadcrumbs: [{ label: submissionProps.title }],
                    breadcrumbs: [{ label: props.item.form.dataset.name }],
                },
            });
            // const inviteProps = inviteController.useProps(context, {
            //     fieldset: {
            //         errors: deps.form.formState.errors.invite ?? {},
            //         value: deps.formValues.invite,
            //         onInputChange(key, value) {
            //             deps.form.setValue(
            //                 `invite.${key}`,
            //                 // @ts-expect-error
            //                 value
            //             );
            //             deps.form.clearErrors(`invite.${key}`);
            //         },
            //     },
            // });

            const typeOptionProps = typeOptionController.useProps({
                value: 'group',
                items: [
                    {
                        Icon: Icons.Reports.Group,
                        value: 'group',
                        label: 'Study group',
                        isDisabled: false,
                        description:
                            'Specify a fixed set of known companies either manually or using AI',
                    },
                    {
                        Icon: Icons.Reports.Cohort,
                        value: 'cohort',
                        label: 'Dynamic cohort',
                        isDisabled: true,
                        description:
                            'Specify a dynamic cohort of companies using filters',
                    },
                ],
                onChange(value) {},
            });

            const groupOptionProps = groupOptionController.useProps({
                value: 'automatic',
                items: [
                    {
                        Icon: Icons.Reports.Automatic,
                        value: 'automatic',
                        label: 'Automatic',
                        isDisabled: false,
                        description:
                            'Varos will automatically invite companies on your behalf',
                    },
                    {
                        Icon: Icons.Reports.Manual,
                        value: 'manual',
                        label: 'Manual',
                        isDisabled: true,
                        description: 'Manually specify and invite a set of companies',
                    },
                ],
                onChange(value) {},
            });

            const fieldProps = fieldController.useProps(context, props.item.form, {
                // form: formStateProps,
                errors: deps.form.formState.errors.payload ?? {},
                value: deps.formValues.payload,
                onInputChange(key, value) {
                    deps.form.setValue(`payload.${key}`, value);
                    deps.form.clearErrors(`payload.${key}`);
                },
            });

            const studyProps = buildStudyItemViewProps(context, props.item.study);

            return {
                // submissionProps,
                input: {
                    group: groupOptionProps,
                    type: typeOptionProps,
                },
                field: fieldProps,
                page: pageProps,
                form: formProps,
                // inviteProps,
                links: {
                    cancel: { to: '..' },
                    report: {
                        to: `/u/assets/${props.item.study.asset.id}/reporting/reports/${props.item.study.report.id}`,
                    },
                },
                study: studyProps,
            };
        },
    };
}
