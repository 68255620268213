import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { createFilterPopoverController } from './filterPopoverController';
import { FilterPopoverDeps } from './filterPopoverInterface';
import { FilterPopoverContainerProps } from './filterPopoverProps';

export function createFilterPopoverContainer(): React.FC<
    { children?: React.ReactNode | undefined } & FilterPopoverContainerProps
> {
    const controller = createFilterPopoverController();
    const CLOSE_ANIMATION_TIME = 200;
    return (props) => {
        const { as: View, item, ...rest } = props;
        const [localValue, setLocalValue] = useState(item.input.value);
        const deps: FilterPopoverDeps = {
            disclosure: useDisclosure(),
            state: {
                // NOTE for some reason if we don't fall-back to the item.input.value here
                // the local value is not set on the first render, even though
                // we have a useeffect further down that is supposed to sync them
                value: localValue ?? item.input.value ?? null,
                onChange(value) {
                    setLocalValue(value);
                },
            },
        };
        const viewProps = controller.useProps(deps, item);
        useEffect(() => {
            if (!deps.disclosure.isOpen) {
                // allow time for animation
                setTimeout(() => setLocalValue(item.input.value), CLOSE_ANIMATION_TIME);
            }
        }, [deps.disclosure.isOpen]);
        useEffect(() => {
            setLocalValue(item.input.value);
        }, [item.input.value]);
        return <View {...viewProps} />;
    };
}
