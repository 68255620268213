import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { getChildrenProps } from '../../../../util';
import { IconComponents, isImageIconProps, isRawIconProps } from '../../../../ui';
import { QuestionIcon } from '@chakra-ui/icons';

export function createIconComponents(): IconComponents {
    const Icon: IconComponents = {
        IconGroup({ children, ...props }) {
            const { iconProps, badgeProps } = getChildrenProps(children, {
                iconProps: Icon.Icon,
                badgeProps: Icon.Badge,
            });
            if (!iconProps) {
                return (
                    <Chakra.HStack flexShrink={0} borderRadius="md" {...props}>
                        <Chakra.Icon as={QuestionIcon} />;
                    </Chakra.HStack>
                );
            }
            if (iconProps && isRawIconProps(iconProps)) {
                // console.log('iasasd', iconProps);
                return (
                    <Chakra.HStack flexShrink={0} borderRadius="md" {...props}>
                        <Chakra.Icon {...iconProps} />;
                    </Chakra.HStack>
                );
            }
            if (iconProps && isImageIconProps(iconProps)) {
                return (
                    <Chakra.HStack
                        flexShrink={0}
                        p={2}
                        bg="whiteAlpha.200"
                        borderRadius="md"
                        {...props}
                    >
                        <Chakra.Box position="relative">
                            {badgeProps ? (
                                <Chakra.Badge
                                    zIndex={10}
                                    fontSize="xs"
                                    borderRadius="full"
                                    position="absolute"
                                    top={-3}
                                    right={-3}
                                    w={4}
                                    h={4}
                                    textAlign="center"
                                    // colorScheme={badgeProps.colorScheme}
                                    bg="red.500"
                                    color="whiteAlpha.900"
                                >
                                    <Chakra.Text position="relative" top="0px">
                                        {badgeProps.children}
                                    </Chakra.Text>
                                </Chakra.Badge>
                            ) : null}
                            <Chakra.Image
                                width={iconProps.width ?? 4}
                                height={iconProps.height ?? 4}
                                w={iconProps.w ?? 4}
                                h={iconProps.h ?? 4}
                                alt={iconProps.alt}
                                src={iconProps.src}
                                filter={iconProps.filter}
                            />
                        </Chakra.Box>
                    </Chakra.HStack>
                );
            }

            // return <div>not supported</div>;
            return <>{children}</>;
        },
        Icon(props) {
            if (isRawIconProps(props)) {
                return <Chakra.Icon {...props} />;
            }
            if (isImageIconProps(props)) {
                return <Chakra.Image {...props} />;
            }
            return <Chakra.Icon {...props} />;
        },
        Badge(props) {
            return <></>;
        },
    };
    return Icon;
}
