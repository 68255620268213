import { action, computed, makeObservable, observable } from 'mobx';
import StoreBase from './StoreBase';
import {
    PeerGroupDataSet,
    DataSetQuery,
    TableSummary,
    MakeQueryRequest,
    isTableSummary,
    KeyValRequest,
    ParticipantDataSetState,
    DataSet,
} from '../models/DataSet';
import {
    getQueryDescriptor,
    fetchDatasetsForParticipants,
    fetcQueriesForDataset,
    executeQuery,
    getKeyVals,
    getParticipantStateInDatasets,
} from '../api/datasets';
import { Option } from '../models/Common';

class DataSetsStore extends StoreBase {
    isLoading: boolean = false;
    datasets: Array<DataSet> | null = null;
    queries: Array<DataSetQuery> | null = null;
    queryDescriptor: TableSummary | null = null;
    queryDescriptorsCache: Map<string, TableSummary | boolean> = new Map<string, TableSummary | boolean>();
    currentOptions: Array<Option> | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            datasets: observable,
            queries: observable,
            queryDescriptor: observable,
            queryDescriptorsCache: observable,
            currentOptions: observable,
            // computed

            // Actions
            setLoading: action,
            getParticipantDataSets: action,
            setParticipantDatasets: action,
            setPeerGroupDatasetQueries: action,
            getPeerGroupDatasetQueries: action,
            setQueryDescriptor: action,
            getQueryDescriptor: action,
            getKeyValues: action,
            setCurrentOptions: action,
            reset: action,
        });
    }

    setLoading(flag: boolean) {
        if (flag) {
            this.err = null;
        }
        this.isLoading = flag;
    }

    setParticipantDatasets(datasets: Array<DataSet> | null) {
        this.datasets = datasets;
    }

    setPeerGroupDatasetQueries(queries: Array<DataSetQuery> | null) {
        this.queries = queries;
    }

    reset() {
        this.queryDescriptor = null;
        this.queries = null;
    }

    setQueryDescriptor(participantId: number, pgdsId: number, queryId: number, desc: TableSummary | boolean | null) {
        if (desc == null || isTableSummary(desc)) {
            this.queryDescriptor = desc;
        }
        if (desc) {
            this.queryDescriptorsCache.set(`${participantId}_${pgdsId}_${queryId}`, desc);
        }
    }

    getParticipantDataSets = async (participantId: number) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await fetchDatasetsForParticipants(participantId);
            this.setParticipantDatasets(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getParticipantDataSets(participantId);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    getPeerGroupDatasetQueries = async (participantId: number, pgdsId: number) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await fetcQueriesForDataset(participantId, pgdsId);
            this.setPeerGroupDatasetQueries(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getPeerGroupDatasetQueries(participantId, pgdsId);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    getQueryDescriptor = async (participantId: number, dsId: number, queryId: number) => {
        const qs = this.queryDescriptorsCache.get(`${participantId}_${dsId}_${queryId}`);
        if (qs) {
            if (isTableSummary(qs)) {
                this.queryDescriptor = qs;
            }
            return;
        }
        this.setError(null);
        this.setQueryDescriptor(participantId, dsId, queryId, true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await getQueryDescriptor(participantId, dsId, queryId);
            this.setQueryDescriptor(participantId, dsId, queryId, data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getQueryDescriptor(participantId, dsId, queryId);
            } else {
                this.handleAxiosError(e);
                this.setQueryDescriptor(participantId, dsId, queryId, false);
            }
        } finally {
            this.setLoading(false);
        }
    };

    executeQuery = async (participantId: number, req: MakeQueryRequest) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await executeQuery(participantId, req);
            return data;
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.executeQuery(participantId, req);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    setCurrentOptions(currentOptions: Array<Option> | null) {
        this.currentOptions = currentOptions;
    }

    getKeyValues = async (
        columnKey: string,
        participantId: number,
        peerGroupFilterId?: number
    ) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const { data } = await getKeyVals(columnKey, participantId, peerGroupFilterId);
            this.setCurrentOptions(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getKeyValues(columnKey, participantId, peerGroupFilterId);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };

    async callMultiGetMultiKeyValues(requests: KeyValRequest[]): Promise<Array<Option>> {
        const uniqueReqs = requests.reduce<Record<string, KeyValRequest>>((agg, curr) => {
            agg[
                `${curr.columnKey}_${curr.participantId}_${curr.peerGroupFilterId || '_'}`
            ] = curr;
            return agg;
        }, {});
        const allOptions = [];
        await this.rootStore.auth.waitAuthenticated();
        for (const { columnKey, participantId, peerGroupFilterId } of Object.values(uniqueReqs)) {
            try {
                const { data } = await getKeyVals(columnKey, participantId, peerGroupFilterId);
                allOptions.push(...data);
            } catch (e) {
                this.handleAxiosError(e);
                break;
            }
        }

        return Object.values(
            allOptions.reduce<Record<string, Option>>((agg, curr) => {
                agg[curr.value] = curr;
                return agg;
            }, {})
        );
    }

    getMultiKeyValues = async (requests: KeyValRequest[]) => {
        this.setLoading(true);
        await this.rootStore.auth.waitAuthenticated();
        try {
            const resp = await this.callMultiGetMultiKeyValues(requests);
            this.setCurrentOptions(resp);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.getMultiKeyValues(requests);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };
    getMyDataSetState = async (
        peerGroupParticipantId: number,
        pgdsIds: Set<number>
    ): Promise<ParticipantDataSetState> => {
        await this.rootStore.auth.waitAuthenticated();
        const { data } = await getParticipantStateInDatasets(peerGroupParticipantId, pgdsIds);
        return data;
    };
}

export default DataSetsStore;
