import { z } from 'zod';

// Item

export const PlanAddonSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.nullable(z.string()),
    amount: z.number(),
    currency: z.string(),
    is_one_time_charge: z.boolean(),
    provider: z.enum(['stripe', 'shopify']),
    product_id: z.string(),
    payment_strategy: z.enum(['integrated', 'external']),
});

export const PlanTypeSchema = z.union([z.literal('brand'), z.literal('agency')]);

export const BillingPlanDtoSchemaBase = z.object({
    id: z.string(),
    type: PlanTypeSchema,
    version: z.number(),
    name: z.string(),
    description: z.nullable(z.string()),
    default: z.boolean(),
    amount: z.number(),
    currency: z.string(),
    add_ons: z.array(PlanAddonSchema),
});

export const BillingPlanDtoUsageSchema = BillingPlanDtoSchemaBase.extend({
    kind: z.literal('usage'),
    unit_kind: z.string(),
    unit_name: z.string(),
    threshold_start: z.number(),
    threshold_end: z.nullable(z.number()),
});

export const BillingPlanDtoFixedSchema = BillingPlanDtoSchemaBase.extend({
    kind: z.literal('fixed'),
});

export const AnyBillingPlanDtoSchema = z.discriminatedUnion('kind', [
    BillingPlanDtoUsageSchema,
    BillingPlanDtoFixedSchema,
]);

export const BillingPlanListDtoSchema = z.object({
    items: z.array(AnyBillingPlanDtoSchema),
});
