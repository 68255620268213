import {
    GroupEditController,
    TraitPreviewController,
    TraitFilterController,
} from '../../../../view/peer';
import { isCustomized, isPeerGroupConfigured } from '../../../../domain/peers';
import { OnboardingPeerGroupContainerConfig } from '../onboardingPeerGroupConfig';
import { PluginItemController } from './pluginInterface';
import { PluginItemViewProps } from './pluginProps';

export function createOnboardingPluginItemController(
    config: OnboardingPeerGroupContainerConfig,
    controller: {
        preview: TraitPreviewController;
        group: GroupEditController;
        trait: TraitFilterController;
    }
): PluginItemController {
    return {
        useProps(context, data, props): PluginItemViewProps {
            const groupProps = controller.group.useProps({
                query: {
                    competitive: data.query.competitive,
                },
                item: {
                    plugin: props.item.plugin,
                    group: props.item.peergroup,
                },
                form: data.form.group,
                async onSubmit(values) {
                    await data.mutation.updatePeerGroup.mutateAsync({
                        ...props.item.peergroup,
                        conditions: values.conditions,
                    });
                },
            });

            const previewProps = controller.preview.useProps(
                groupProps.getPreviewProps()
            );

            const filterProps = controller.trait.useProps(groupProps.getFilterProps());

            // const formValues = data.form.group.watch();
            return {
                ...props,
                form: {
                    isDirty: data.form.group.formState.isDirty,
                    isValid: data.form.group.formState.isValid,
                },
                previewProps,
                groupProps,
                plugin: props.item.plugin,
                peergroup: props.item.peergroup,
                // showStatus: isPeerGroupConfigured(props.item.peergroup),
                showStatus: isCustomized(props.item.peergroup),
                onOpenClick() {
                    return props.onOpen();
                },
                getSubmitButtonProps() {
                    return {
                        async onClick() {
                            await groupProps.onSave();
                            props.onClose();
                        },
                        isLoading: groupProps.isSubmitting,
                        isDisabled:
                            !groupProps.isSubmittable ||
                            groupProps.isSubmitting ||
                            !data.form.group.formState.isDirty,
                        disabled: !groupProps.isSubmittable || groupProps.isSubmitting,
                    };
                },
                getCancelButtonProps() {
                    return {
                        async onClick() {
                            groupProps.onReset();
                            props.onClose();
                        },
                    };
                },
                getFilterProps() {
                    return filterProps;
                },
                getPreviewProps() {
                    return previewProps;
                },
                getOpenButtonText() {
                    return props.item.status === 'configured' ? 'Edit' : 'Configure';
                },
            };
        },
    };
}
