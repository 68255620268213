import { AxiosInstance, AxiosResponse } from 'axios';
import { PriceDto, PriceUnit } from './price';

export interface PlanListDto {
    items: PlanDto[];
}

export type PlanId =
    | 'free'
    | 'brands_free'
    | 'agencies'
    | 'enterprise'
    | 'brands'
    | 'brands_0_10k'
    | 'brands_10k_50k'
    | 'brands_50k_100k'
    | 'brands_100k_500k'
    | 'brands_500k_plus'
    | 'agencies_1_5'
    | 'agencies_6_15'
    | 'agencies_16_30'
    | 'agencies_31_50'
    | 'agencies_50_plus'
    | 'agency_base'
    | 'agency_pro';

export type PlanType = 'brand' | 'agency';

export interface AddonPlanDto {
    id: string;
    name: string;
    description?: string;
    amount: number;
    currency: string;
}

export interface PlanDto {
    id: PlanId;
    type: PlanType;
    name: string;
    description: string | null;
    default: boolean;
    /**
     * Amount in cents
     */
    amount: number;
    currency: string;
    // prices: PriceDto[];
    unit_kind: PriceUnit;
    unit_name: string;
    threshold_start: number;
    threshold_end: number | null;
    add_ons: Array<AddonPlanDto>;
}

export const listPlans = async (
    api: AxiosInstance,
    query: {
        version?: number;
    } = {}
) => {
    const params: Record<string, unknown> = {};
    if (query?.version) {
        params['version'] = query.version;
    }
    const response = await api.get<unknown, AxiosResponse<PlanListDto>>(
        `/api/v1/billing/plans`,
        { params }
    );
    return response.data;
};
