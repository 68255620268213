import { useMemo } from 'react';
import { InsightRouteConfig } from './insightRouteConfig';
import { InsightRouteSorting } from './insightRouteEnum';
import { InsightRouteController } from './insightRouteInterface';
import { InsightRouteViewProps } from './insightRouteProps';
import { buildOpportunityControllerProps } from './insightRouteFactory';

export function createInsightRouteController(
    config: InsightRouteConfig
): InsightRouteController {
    const {
        deps: { controller },
    } = config;

    return {
        useProps(context, state, queries, props): InsightRouteViewProps {
            const applied = useMemo(() => {
                return {
                    period: state.value.period ?? { amount: 4, interval: 'week' },
                    sorting: state.value.sorting ?? 'relevance',
                };
            }, [state.value]);

            const controllerProps = useMemo(
                () => buildOpportunityControllerProps(applied),
                [applied]
            );

            const viewProps = {
                opportunity: controller.opportunity.useProps(
                    context,
                    queries,
                    controllerProps
                ),
                date: controller.date.useProps({
                    value: applied.period,
                    onChange(value) {
                        state.onChange({ ...state.value, period: value });
                    },
                }),
            };

            return {
                getOpportunityProps() {
                    return viewProps.opportunity;
                },
                getDateProps() {
                    return viewProps.date;
                },
                getSortingProps() {
                    return {
                        value: applied.sorting,
                        onChange(event) {
                            state.onChange({
                                period: state.value?.period ?? null,
                                sorting: event.target.value as InsightRouteSorting,
                            });
                        },
                        options: [
                            {
                                label: 'Relevance',
                                value: 'relevance',
                            },
                            {
                                label: 'Most recent',
                                value: 'recent',
                            },
                        ],
                    };
                },
            };
        },
    };
}
