import moment from 'moment';
import { IntegrationDefinition } from '../definition';
import { Integration } from './integrationModel';

/**
 * An integration is considered delayed if the elapsed sync time
 * exceeds the estimate provided by its definition
 * @param now
 * @param item
 * @returns
 */
export function isOrWasDelayed(
    now: Date,
    item: {
        integration: Integration;
        definition: IntegrationDefinition;
    }
) {
    const { lastSyncedStartedAt, lastSyncedFinishedAt = now } = item.integration;

    const {
        range: { to: estimateMinutes },
    } = item.definition.refreshRate;

    if (!lastSyncedStartedAt || !estimateMinutes) {
        return false;
    }

    const elapsedMinutes = moment(lastSyncedFinishedAt).diff(
        lastSyncedStartedAt,
        'minutes'
    );

    return elapsedMinutes > estimateMinutes;
}
