import { ContextFreePeriod } from './periodModel';
import { daterangeDifference } from '../daterange';
import { durationDifference, DurationInterval } from '../duration';
import { isAbsolutePeriod, isRelativeDuration } from './periodGuard';
import { DependentPeriod, Period } from './periodModel';

export function periodDifference(period: Period, interval: DurationInterval = 'day') {
    if (isRelativeDuration(period)) {
        return durationDifference(period, interval);
    } else if (isAbsolutePeriod(period)) {
        return daterangeDifference(period, interval);
    } else {
        return 0;
    }
}

export function addPeriodToReference(reference: Period, period: DependentPeriod) {
    return;
}

export function periodToDays(period: ContextFreePeriod) {
    return periodDifference(period, 'day');
}
