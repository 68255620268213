export const apiBaseURL: string = (process.env && process.env.API_BASE_URL) || '';
export const apiPrefix: string = (process.env && process.env.API_PREFIX) || '/api/v1';

console.log('apiBaseURL', apiBaseURL);
// if (!apiBaseURL) throw new Error(`No API_BASE_URL, can't build`);

export const config = {
    apiBaseURL,
    apiPrefix,
};
