import isUrl from 'is-url-superb';
// import { AssetTypeSchema } from '../../../../api';
import { z } from 'zod';

export const AssetEditFormValuesSchema = z.object({
    // type: AssetTypeSchema,
    name: z
        .string({ required_error: 'Name is required' })
        .min(3, { message: 'Name must be at least 3 characters long' })
        .max(100, { message: 'Name must be at at most 100 characters long' }),
    url: z
        .string({ required_error: 'URL is required' })
        .min(3, { message: 'Invalid URL' }),
    // kind: z.union([z.literal('website'), z.literal('website')]),
    kind: z.string(),
});
