import { z } from 'zod';

export const InsightDefinitionSchema = z.object({
    id: z.string(),
    plugin: z.string(),
    view: z.nullable(z.string()).default(null),
    name: z.string(),
    category: z.union([z.literal('alert'), z.literal('opportunity')]),
    version: z.number(),
    metrics: z.array(
        z.object({
            key: z.string(),
        })
    ),
    visualization: z.nullable(
        z.object({
            kind: z.union([
                z.literal('distribution'),
                z.literal('line'),
                z.literal('comparison'),
            ]),
            granularity: z.union([
                z.literal('day'),
                z.literal('week'),
                z.literal('month'),
            ]),
            breakdown: z.nullable(
                z.object({
                    keys: z.array(z.string()),
                })
            ),
            ranking: z.object({
                key: z.string(),
            }),
        })
    ),
    created_at: z.date({ coerce: true }),
});

export const InsightDefinitionListResponseSchema = z.object({
    data: z.array(InsightDefinitionSchema),
});
