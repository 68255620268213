import { useMemo } from 'react';
import { ModalIds, NetworkConstant, SupportLinks } from '../../../config';
import { buildVideoViewProps, OnboardingViewController } from '../../../view/common';
import { DomainLayoutController } from '../../../layout';
import { NetworkOverviewRouteConfig } from './networkOverviewConfig';
import { NetworkOverviewController } from './networkOverviewInterface';
import { NetworkOverviewAggregate } from './networkOverviewModel';

export function createNetworkOverviewController(
    config: NetworkOverviewRouteConfig,
    controller: {
        onboarding: OnboardingViewController<NetworkOverviewAggregate>;
    }
): NetworkOverviewController {
    const {
        controller: { modal: modalController },
    } = config;
    const { onboarding: onboardingController } = controller;
    return {
        useProps(context, deps, item) {
            const modal = modalController.useProps(deps.modal, {
                id: ModalIds.EXPERT_CALL_VIDEO,
                trigger: null,
            });
            const onboarding = onboardingController.useProps(item, {
                modal: modal,
            });
            const video = useMemo(
                () => buildVideoViewProps(NetworkConstant.OVERVIEW_VIDEO),
                []
            );
            return {
                modal,
                video,
                onboarding,
                links: {
                    faq: {
                        id: 'expert_calls_overview_learn_more_link',
                        href: SupportLinks.EXPERT_CALLS,
                    },
                    bookings: {
                        to: '../requests',
                    },
                },
            };
        },
    };
}
