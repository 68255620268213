import moment from 'moment';
import { Session } from './sessionModel';

export function isSessionStale(session: Session) {
    const expireAt = moment(session.expiresAt);
    return moment().isAfter(expireAt);
}

export function refreshSession(session: Session, ttlMs: number): Session {
    const refreshedAt = new Date();
    return {
        ...session,
        refreshedAt,
        expiresAt: moment(refreshedAt).add(ttlMs, 'ms').toDate(),
    };
}
