import React from 'react';
import { AnalysisRouteConfig } from './analysisRouteConfig';
import {
    createBreakdownContainer,
    createBreakdownController,
    createBreakdownFactory,
    createBreakdownView,
} from './breakdown';
import {
    createCompetitiveContainer,
    createCompetitiveController,
    createCompetitiveLoader,
} from './competitive';
import {
    createContentContainer,
    createContentController,
    createContentView,
} from './content';
import { createContentFactory } from './content/contentFactory';
import { createDateFactory } from './date/dateFactory';
import {
    createRankingContainer,
    createRankingController,
    createRankingFactory,
} from './ranking';
import {
    createAnalysisSegmentView,
    createSegmentContainer,
    createSegmentController,
    createSegmentFactory,
    createSegmentLoader,
} from './segment';
import {
    createVisualizationContainer,
    createVisualizationLoader,
    createVisualizationView,
} from './visualization';
import { createDateContainer, createDateController } from './date';
import { createFilterContainer, createFilterLoader } from './filter';
import { AnalysisContainers } from './analysisRouteInterface';
import { createAnalysisRouteContainer } from './analysisRouteContainer';

export function createMetricAnalysisRoute(config: AnalysisRouteConfig) {
    const {
        deps: {
            hook: { useQuery, ...hook },
        },
        providers: {
            root: { createController, createView, createComponents, createLoader },
            filter: { createController: createFilterController },
            visualization: { createController: createVisualizationController },
        },
    } = config;

    const loader = createLoader(config.deps);
    const Components = {
        ...createComponents(),
        Segment: createAnalysisSegmentView(config.deps),
    };

    const View = {
        Content: createContentView(
            config.deps,
            createVisualizationContainer(
                config.deps,
                createVisualizationLoader(config.deps),
                createVisualizationController(config.deps),
                createVisualizationView(config.deps, Components)
            )
        ),
    };

    const controller = {
        root: createController(config),
        content: createContentController(
            config.deps.content,
            createContentFactory(
                config.deps.strategy.queryable,
                createDateFactory(),
                createSegmentFactory(),
                createRankingFactory()
            )
        ),
        competitive: createCompetitiveController(),
    };

    const Container: AnalysisContainers = {
        Ranking: createRankingContainer(
            config.deps.state.ranking,
            createRankingController(createRankingFactory()),
            Components.Ranking
        ),
        Breakdown: createBreakdownContainer(
            config.deps.state.breakdown,
            createBreakdownController(config.deps.form, createBreakdownFactory()),
            createBreakdownView()
        ),
        Date: createDateContainer(
            config.deps.state.date,
            createDateController(config.deps.form, createDateFactory()),
            Components.Date
        ),
        Filter: createFilterContainer(
            config.deps.state.filter,
            createFilterLoader(config.deps),
            createFilterController(config.deps.form),
            Components.Filter
        ),
        CompetitiveSet: createCompetitiveContainer(
            config.deps.state.segment,
            createCompetitiveLoader(config.deps, createSegmentFactory()),
            createCompetitiveController(),
            Components.CompetitiveSet
        ),
        Segment: createSegmentContainer(
            config.deps.state.segment,
            createSegmentLoader(config.deps, config.deps.repository.member),
            createSegmentController(config.deps),
            Components.Segment
        ),
        Content: createContentContainer(
            {
                useState(context) {
                    const date = config.deps.state.date.useState(context);
                    const filter = config.deps.state.filter.useState(context);
                    const segment = config.deps.state.segment.useState(context);
                    const ranking = config.deps.state.ranking.useState(context);
                    const breakdown = config.deps.state.breakdown.useState(context);
                    return { date, filter, segment, ranking, breakdown };
                },
            },
            controller.content,
            View.Content
        ),
    };

    const RootView = createView(config.deps, Components, Container);

    const Route = createAnalysisRouteContainer(
        config.deps,
        loader,
        controller.root,
        RootView
    );

    return {
        Route,
    };
}
