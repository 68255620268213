import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildStudyItemViewProps, StudyItemViewProps } from '../item';
import { StudyListControllerConfig } from './studyListConfig';
import { StudyListController } from './studyListInterface';

export function createStudyListController(
    config: StudyListControllerConfig = {}
): StudyListController {
    return {
        useProps(context, props) {
            const itemPropsById = useMemo(
                () =>
                    props.item.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.report.id]: buildStudyItemViewProps(context, item),
                        }),
                        {} as Record<string, StudyItemViewProps | undefined>
                    ),
                [context, props.item.items]
            );

            const items = useMemo(
                () =>
                    chain(props.item.items)
                        .orderBy(
                            (item) =>
                                itemPropsById[item.report.id]?.status.access.kind ===
                                'available'
                                    ? 1
                                    : -1,
                            'desc'
                        )
                        .value(),
                [props.item.items, itemPropsById]
            );

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.report.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
            };
        },
    };
}
