import { VisualizationCreatePropsDto } from './visualizationDto';
import { VisualizationCreatePropsSchema } from './visualizationSchema';

export function encodeVisualization(props: VisualizationCreatePropsDto) {
    const urlParams = new URLSearchParams();
    urlParams.set('metric', props.metric.join(','));
    urlParams.set('kind', props.kind);
    if (props.view) {
        urlParams.set('view', props.view);
    }
    urlParams.set('granularity', props.granularity);
    if (props.period) {
        urlParams.set('period', JSON.stringify(props.period));
    }
    if (props.filters) {
        urlParams.set('filters', JSON.stringify(props.filters));
    }
    if (props.ranking) {
        urlParams.set('ranking', JSON.stringify(props.ranking));
    }
    if (props.breakdown) {
        urlParams.set('breakdown', JSON.stringify(props.breakdown));
    }
    const queryString = urlParams.toString();
    return queryString;
}

export function decodeVisualization(
    urlParams: URLSearchParams
): VisualizationCreatePropsDto {
    // const urlParams = new URLSearchParams(raw);
    const metric = urlParams.get('metric');
    const kind = urlParams.get('kind');
    const granularity = urlParams.get('granularity') ?? undefined;
    const period = urlParams.get('period') ?? undefined;
    const filters = urlParams.get('filters') ?? undefined;
    const ranking = urlParams.get('ranking') ?? undefined;
    const breakdown = urlParams.get('breakdown') ?? undefined;
    const view = urlParams.get('view') ?? undefined;
    const raw = { metric, kind, view, granularity, period, filters, ranking, breakdown };
    return VisualizationCreatePropsSchema.parse(raw);
}
