import { template as lodashTemplate } from 'lodash';

export function render(source: string, bindings: Record<string, unknown>) {
    const template = lodashTemplate(source, {
        interpolate: /{{([\s\S]+?)}}/g,
    });
    const value = template(bindings);
    if (value.includes('null')) {
        // if any value in template is null, we consider the entire template null
        return null;
    }
    return value;
}
