import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildReportSelectAssetProps } from './asset';
import { buildReportSelectFormProps } from './form';
import { ReportSelectConfig } from './reportSelectConfig';
import { ReportSelectController } from './reportSelectInterface';

export function createReportSelectController(
    config: Pick<ReportSelectConfig, 'controller'>
): ReportSelectController {
    const { controller } = config;
    return {
        useProps(deps, context, data, form, props) {
            const formValues = form.watch();

            const assetProps = useMemo(() => {
                if (!data.item.data) {
                    return null;
                }
                return buildReportSelectAssetProps(form, formValues, data.item.data);
            }, [formValues, data.item.data]);

            const formProps = buildReportSelectFormProps(deps, data, form, formValues);

            if (!data.item.data || data.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            return {
                status: 'success',
                getAssetProps() {
                    assert(assetProps, 'asset props not ready');
                    return assetProps;
                },
                getFormProps() {
                    assert(formProps, 'form props not ready');
                    return formProps;
                },
            };
        },
    };
}
