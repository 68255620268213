import GsheetSvg from '../../../../svg/gsheet.svg';
import BigQuerySvg from '../../../../svg/google-bigquery-logo.svg';
import DataStudioSvg from '../../../../svg/google-data-studio.svg';
import { DestinationDefinition } from '../../../domain/assets';

export function getDestinationItems(): DestinationDefinition[] {
    return [
        {
            id: 'googlesheets',
            name: 'Google Sheets',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
            status: 'available',
            iconUrl: GsheetSvg,
        },
        {
            id: 'bigquery',
            name: 'Google BigQuery',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
            status: 'available',
            iconUrl: BigQuerySvg,
        },
        {
            id: 'googledatastudio',
            name: 'Google Data Studio',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
            status: 'available',
            iconUrl: DataStudioSvg,
        },
    ];
}
