import React from 'react';
import { fetchChildrenProps, getChildrenProps } from '../../../util';
import { GridComponents } from './gridInterface';
import { GridContainerProps } from './gridProps';

export function getGridProps(Components: GridComponents, props: GridContainerProps) {
    const { listProps } = fetchChildrenProps(props.children, {
        listProps: Components.GridList,
    });

    const mapped = React.Children.map(listProps.children, (child) => {
        return child;
    });

    const itemProps =
        React.Children.map(listProps.children, (child) => {
            if (child === null) {
                return null;
            }
            const { itemProps } = fetchChildrenProps(child, {
                itemProps: Components.GridItem,
            });
            const { contentProps } = fetchChildrenProps(itemProps.children, {
                contentProps: Components.GridContent,
            });
            const { headerProps, bodyProps, footerProps } = getChildrenProps(
                contentProps.children,
                {
                    headerProps: Components.GridHeader,
                    bodyProps: Components.GridBody,
                    footerProps: Components.GridFooter,
                }
            );
            return { itemProps, contentProps, headerProps, bodyProps, footerProps };
        }) ?? [];

    return {
        listProps,
        itemProps,
    };
}
