import { MobileApp } from '../models/MobileApp';
import { apiV1 } from './axios';

export const fetchGooglePlay = async (packageName: string) => {
    return apiV1.get<MobileApp>(`/ext/appstores/google-play/${packageName}`);
};

export const fetchAppStore = async (bundleId: string) => {
    return apiV1.get<MobileApp>(`/ext/appstores/apple-appstore/${bundleId}`);
};
