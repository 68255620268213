import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ClientPreferenceAdapter } from './clientPreferenceAdapter';
import { ClientPreferenceRepository } from './clientPreferenceInterface';

export function createClientPreferenceRepository(
    adapter: ClientPreferenceAdapter
): ClientPreferenceRepository {
    const PREFIX = ['client-preference'];
    return {
        useLookup(context, query, options) {
            return useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.organization.id],
                async queryFn() {
                    const response = await adapter.get(context, query);
                    return response;
                },
            });
        },
        useUpdate(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(payload) {
                    const response = await adapter.update(context, payload);
                    return response;
                },
                async onSuccess() {
                    await client.refetchQueries(PREFIX);
                },
            });
        },
    };
}
