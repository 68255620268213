import { ImportDto } from '../../../api';
import { FormSubmissionAdapter, FormSubmissionEntity } from '../../../app/forms';
import { FormSubmissionImplConfig } from './formSubmissionConfig';

/**
 * Form submission adapter that models form submissions using dataset imports
 * @param config
 * @returns
 */
export function createFormSubmissionImpl(
    config: FormSubmissionImplConfig
): FormSubmissionAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function toEntity(dto: ImportDto): FormSubmissionEntity {
        return {
            id: dto.id,
            asset: {
                id: dto.asset_id,
            },
            dataset: {
                id: dto.dataset.slug,
            },
            user: {
                id: dto.user_id,
            },
            createdAt: dto.created_at,
        };
    }

    return {
        async find(context, query) {
            const response = await api.import.list(context, {
                asset: query.asset.id,
                datasets: query.dataset?.id ? [query.dataset.id] : undefined,
            });
            // console.log('listing submissions', query, response);
            return response.data.map(toEntity);
        },
        async lookup(context, query) {
            const response = await api.import.get(context, query.id);
            return toEntity(response);
        },
        async create(context, props) {
            const response = await api.import.create(context, {
                asset: context.workspace.id as number,
                dataset: props.dataset.id,
                data: [
                    // the form submission is models as a single row in the import
                    props.data,
                ],
            });
            return toEntity(response);
        },
    };
}
