import { makeObservable, observable } from 'mobx';
import StoreBase from '../../stores/StoreBase';
import { buildDialog, Dialog, DialogCreateProps } from '../../base';

export class DialogStore extends StoreBase {
    current: Dialog | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            current: observable,
        });
    }

    create(props: DialogCreateProps) {
        this.current = buildDialog(props);
    }

    clear() {
        this.current = null;
    }
}
