import { PaywallViewedTrackingEvent } from '../../../../../strategy/track/access';
import { PlanModalController } from '../../../../route/billing';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { z } from 'zod';

export function createPaywallTrackingStrategy(): ApplicationEntryEnhancer {
    const DashboardParamSchema = z.object({
        dashboardId: z.string(),
    });
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<PaywallViewedTrackingEvent>();
            return tracker;
        }

        function enhanceController(controller: PlanModalController): PlanModalController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, deps, props] = args;
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();
                    const params = useParams();

                    useEffect(() => {
                        if (!props.item.feature) {
                            return;
                        }
                        if (props.item.feature?.id === 'dashboard') {
                            const parsed = DashboardParamSchema.safeParse(params);
                            if (!parsed.success) {
                                console.info(
                                    `invalid dashboard route params for dashboard tracking`,
                                    params
                                );
                                console.error(parsed.error);
                                return;
                            }
                            tracker.track('paywall_viewed', {
                                paywall_reason: parsed.data.dashboardId,
                            });
                            return;
                        }
                        tracker.track('paywall_viewed', {
                            paywall_reason: props.item.feature.id,
                        });
                    }, [props.item.feature, params]);

                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                modal: {
                    ...init.controller.modal,
                    billing: {
                        ...init.controller.modal.billing,
                        createPlan(...args) {
                            const controller = init.controller.modal.billing.createPlan(
                                ...args
                            );
                            return enhanceController(controller);
                        },
                    },
                },
            },
        });
        return instance;
    };
}
