import { LockIcon } from '@chakra-ui/icons';
import { useMemo } from 'react';
import { HomeDestinationController } from './homeDestinationInterface';
import { HomeDestinationAggregate } from './homeDestinationModel';
import { HomeDestinationItemProps } from './homeDestinationProps';

export function createHomeDestinationController(): HomeDestinationController {
    function getProps(item: HomeDestinationAggregate): HomeDestinationItemProps {
        return {
            id: item.definition.id,
            name: item.definition.name,
            description: item.definition.description,
            iconUrl: item.definition.iconUrl,
            connect: {
                onClick() {},
                Icon: item.definition.status === 'locked' ? LockIcon : null,
                tooltip: null,
            },
        };
    }
    return {
        useProps(data, props) {
            const items = useMemo(
                () => data.item.data?.items ?? [],
                [data.item.data?.items]
            );

            const itemPropsByPlugin = useMemo(() => {
                return (
                    items.reduce(
                        (acc, item) => ({ ...acc, [item.definition.id]: getProps(item) }),
                        {} as Record<string, HomeDestinationItemProps>
                    ) ?? {}
                );
            }, [items]);

            return {
                items: items,
                getItemProps(item) {
                    const props = itemPropsByPlugin[item.definition.id];
                    return props;
                },
            };
        },
    };
}
