import { AccessResponse, AnyAccessRequest, ApplicationContext } from '../../base';
import { AnyTrackingEvent, useTrackOnce } from '../../strategy';

export const useTrackAccessViewed = (context: ApplicationContext, attempt: AccessResponse | null) => {
    const event = attempt?.request ? eventFromAccess(attempt?.request) : null;
    return useTrackOnce(context, attempt, event);
};

export function eventFromAccess(request?: AnyAccessRequest): AnyTrackingEvent | null {
    if (!request) {
        return null;
    }
    if (request.id === 'dashboard') {
        const reason = request.value.slug ?? request.value.name;
        if (!reason) {
            console.warn(`no paywall reason for feature ${request.id}`);
        }
        return {
            type: 'paywall_viewed',
            payload: {
                paywall_reason: reason!,
            },
        };
    }
    return {
        type: 'paywall_viewed',
        payload: {
            paywall_reason: request.id,
        },
    };
}
