import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { MetricCollectionDto } from './collectionModel';
import { MetricCollectionResource } from './collectionInterface';
import {
    MetricCollectionSchema,
    MetricCollectionListResponseSchema,
} from './collectionSchema';

// const FIXTURE: MetricCollectionDto[] | null = [
//     {
//         id: '1',
//         name: 'default',
//         default: true,
//         metrics: [
//             {
//                 name: null,
//                 definition: 'facebookads.roas',
//             },
//         ],
//         created_at: new Date(),
//         updated_at: new Date(),
//     },
// ];
const FIXTURE: MetricCollectionDto[] | null = null;

export function createMetricCollectionResource(
    client: AxiosInstance
): MetricCollectionResource {
    return {
        async list(context, query) {
            // if (Array.isArray(FIXTURE)) {
            //     return { data: FIXTURE };
            // }
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            let params: Record<string, string | string[]> = {
                asset: [String(query.asset)],
            };

            let headers: Record<string, string> = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get('/api/v2/metrics/collections', {
                params,
                headers,
            });
            const parsed = await MetricCollectionListResponseSchema.parseAsync(
                response.data
            );

            return parsed;
        },
        async create(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            let headers: Record<string, string> = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            console.log('creating colllection', payload);

            const response = await client.post(`/api/v2/metrics/collections`, payload, {
                headers,
            });
            const parsed: MetricCollectionDto = await MetricCollectionSchema.parseAsync(
                response.data
            );
            return parsed;
        },
        async update(context, collection, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            let headers: Record<string, string> = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.patch(
                `/api/v2/metrics/collections/${collection.id}`,
                payload,
                {
                    headers,
                }
            );

            const parsed: MetricCollectionDto = await MetricCollectionSchema.parseAsync(
                response.data
            );

            return parsed;
        },
    };
}
