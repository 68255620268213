import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingPaymentIntentResource } from './paymentIntentInterface';
import { BillingPaymentIntentDtoSchema } from './paymentIntentSchema';

export function createBillingPaymentIntentResource(
    client: AxiosInstance
): BillingPaymentIntentResource {
    return {
        async create(context, payload, options) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(
                '/api/v1/billing/payment_intents',
                payload,
                { headers, params }
            );
            const parsed = BillingPaymentIntentDtoSchema.parse(response.data);

            return parsed;
        },
    };
}
