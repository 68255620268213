import { AxiosInstance, AxiosResponse } from 'axios';
import {
    InvitePeerRequest,
    ListParticipantsPagingResponse,
    PeerEmailDelivery,
    PeerGroup,
} from '../models/Peer';
import { apiV1 } from './axios';

export const sendInvitePeer = async (request: InvitePeerRequest) => {
    return apiV1.post<unknown, AxiosResponse<PeerEmailDelivery>>(
        '/peers/invite',
        request
    );
};

export const getPeerGroups = async (assetId: number | string) => {
    return apiV1.get<unknown, AxiosResponse<Array<PeerGroup>>>(
        `/assets/${assetId}/peers/groups`
    );
};

export const listParticipantsV2 = async (
    api: AxiosInstance,
    page?: number,
    pageSize?: number,
    search?: string,
    options?: {
        token?: string;
    }
) => {
    const items = [];
    if (page) {
        items.push(`page=${page}`);
    }
    if (pageSize) {
        items.push(`page_size=${pageSize}`);
    }
    if (search && search.length > 0) {
        items.push(`search=${search}`);
    }
    const qs = items.length > 0 ? '?' + items.join('&') : '';
    const headers: Record<string, string> = {};
    if (options?.token) {
        headers['Authorization'] = `bearer ${options.token}`;
    }
    return api.get<unknown, AxiosResponse<ListParticipantsPagingResponse>>(
        `/api/v1/peers-group-participants${qs}`,
        {
            headers,
        }
    );
};

export const listParticipants = async (
    page?: number,
    pageSize?: number,
    search?: string
) => {
    const items = [];
    if (page) {
        items.push(`page=${page}`);
    }
    if (pageSize) {
        items.push(`page_size=${pageSize}`);
    }
    if (search && search.length > 0) {
        items.push(`search=${search}`);
    }
    const qs = items.length > 0 ? '?' + items.join('&') : '';
    return apiV1.get<unknown, AxiosResponse<ListParticipantsPagingResponse>>(
        `/peers-group-participants${qs}`
    );
};
