import { useLocation, useNavigate } from 'react-router';
import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { AssetNewController } from '../../../../../view/assets';
import { AnySettingAccountTrackingEvent } from '../trackingSettingEvent';

export function createTrackingSettingsAssetStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnySettingAccountTrackingEvent>();
            return tracker;
        }
        function enhanceNewController(
            controller: AssetNewController
        ): AssetNewController {
            return {
                ...controller,
                useProps(...args) {
                    const [context] = args;
                    const location = useLocation();
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    if (!location.pathname.includes('/settings')) {
                        // temp hack to avoid sending this event during onboarding where
                        // where the controller is re-used.
                        return viewProps;
                    }
                    const suggestionProps = viewProps.getSuggestionProps();
                    return {
                        ...viewProps,
                        getFormProps() {
                            const formProps = viewProps.getFormProps();
                            return {
                                ...formProps,
                                async onSubmit(values) {
                                    const result = await formProps.onSubmit(values);
                                    tracker.track('settings_asset_new_submitted', {
                                        asset_name: values.name,
                                        asset_url: values.url,
                                        asset_suggestion: !!suggestionProps.match,
                                    });
                                    return result;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createNew(...args) {
                        const controller = config.controller.assets.createNew(...args);
                        return enhanceNewController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
