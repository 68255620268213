import { EventAdapter, ApplicationEvent } from '../../base';
import { EventInit } from './eventConfig';

export function createEventManager<TEvent extends ApplicationEvent>({
    handlers,
    ...init
}: EventInit<TEvent>): EventAdapter<TEvent> {
    return {
        useEvent(context) {
            return {
                dispatch(event) {
                    // @ts-expect-error
                    const handler = handlers[event.type];
                    if (!handler) {
                        return;
                    }
                    handler(event, context);
                },
            };
        },
    };
}
