import { EnumMember, NamedEnumType } from '../../../domain/attributes';
import {
    QueryRequest,
    AnyQueryResponse,
    QueryResult,
    isErrorResponse,
} from '../../../domain/query';

/**
 * Return each metric as a separate query
 * @param response
 * @returns
 */
export function mapMetricsToProperties(
    responses: Array<AnyQueryResponse>
): Array<AnyQueryResponse> {
    const mapped = responses.map((anyResponse): AnyQueryResponse => {
        if (isErrorResponse(anyResponse)) {
            return anyResponse;
        }
        const { request, response } = anyResponse;
        const sampleQuery = combineQueryResults(response.queries);

        const enumType: NamedEnumType = {
            kind: 'enum',
            namespace: request.source.view.toString(),
            name: 'metric',
            members: response.metadata.scalar.map((metric): EnumMember => {
                return {
                    label: sampleQuery.schema.properties[metric].name ?? metric,
                    value: metric,
                };
            }),
        };

        return {
            ...anyResponse,
            response: {
                ...response,
                queries: response.metadata.scalar.map<QueryResult>(
                    (metric): QueryResult => {
                        const initial: QueryResult['schema']['properties'] = {
                            metric: {
                                key: 'metric',
                                name: 'Metric',
                                type: enumType,
                                constraints: [],
                                description: null,
                                isDisabled: false,
                                disabledReason: null,
                            },
                        };
                        return {
                            key: metric,
                            schema: {
                                properties: response.queries.reduce(
                                    (accQuery, query) =>
                                        query.series.items.reduce(
                                            (accSeries, series, index) => ({
                                                ...accSeries,
                                                [series.name]: {
                                                    key: series.name,
                                                    name: series.name,
                                                    type: query.schema.properties[metric]
                                                        .type,
                                                    description:
                                                        request.segments?.[index]
                                                            ?.description ?? null,
                                                    constraints: [],
                                                    isDisabled: false,
                                                    disabledReason: null,
                                                },
                                            }),
                                            accQuery
                                        ),
                                    initial
                                ),
                            },
                            series: {
                                items: response.queries.flatMap((query) => ({
                                    name: metric,
                                    data: [
                                        query.series.items.reduce(
                                            (acc, series) => ({
                                                ...acc,
                                                [series.name]: series.data[0][metric],
                                            }),
                                            {
                                                metric,
                                            }
                                        ),
                                    ],
                                    events: [],
                                })),
                            },
                        };
                    }
                ),
            },
        };
    });

    return mapped;
}

export function combineQueryResults(results: QueryResult[]): QueryResult {
    const [first, ...rest] = results;
    const initial: QueryResult = first;
    return rest.reduce(
        (acc, query, indexQuery) => ({
            ...query,
            schema: {
                ...acc.schema,
                properties: {
                    ...acc.schema.properties,
                    ...query.schema.properties,
                },
            },
            series: {
                ...query.series,
                items: [
                    ...acc.series.items,
                    ...query.series.items.map((series, indexSeries) => ({
                        ...series,
                        data: series.data.map((row, indexRow) => ({
                            ...row,
                            ...results[indexQuery].series.items[indexSeries].data[
                                indexRow
                            ],
                        })),
                        events: [
                            ...series.events,
                            ...results[indexQuery].series.items[indexSeries].events,
                        ],
                    })),
                ],
            },
        }),
        initial
    );
}
