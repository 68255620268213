import { chain } from 'lodash';
import { useMemo } from 'react';
import { MetricDefinition } from '../../../../domain/metrics';
import { isNumericType } from '../../../../domain/attributes';
import { RankingController, RankingFactory } from './rankingInterface';
import { MetricDefinitionKind } from '../../../../api';
import {
    buildCohortState,
    castCohortStateToRanking,
    castRankingToCohortState,
} from '../../../../domain';
import { AnalysisRankingViewProps } from './rankingProps';

export function createRankingController(factory: RankingFactory): RankingController {
    const ORDER: MetricDefinitionKind[] = [
        'performance',
        'outcome',
        'diagnostic',
        'input',
    ];
    return {
        useProps(props): AnalysisRankingViewProps {
            const metricsByKey = useMemo(() => {
                return props.context.data.metrics
                    .filter((item) => item.view === props.view.id)
                    .reduce(
                        (acc, item) => ({ ...acc, [item.key]: item }),
                        {} as Record<string, MetricDefinition | undefined>
                    );
            }, [props.context.data.metrics, props.view.id]);

            const options = useMemo(() => {
                return chain(props.view.columns)
                    .filter((item) => isNumericType(item.type))
                    .orderBy((item) => {
                        const metric = metricsByKey[item.key];
                        return ORDER.findIndex((candidate) => candidate === metric?.kind);
                    }, 'asc')
                    .map((item) => ({ label: item.name, value: item.key }))
                    .value();
            }, [props.view.id]);

            // const appliedState = useMemo(() => {
            //     return options.some(
            //         (item) =>
            //             item.value === props.visualization.ranking.config.fixed.comparison
            //     )
            //         ? state.value
            //         : null;
            // }, [props.visualization.ranking.config.fixed.comparison, state.value]);

            const cohortState = useMemo(() => {
                // console.log('DEBUG raking props.value', props.value);
                return props.value
                    ? castRankingToCohortState(props.value)
                    : buildCohortState({ mode: 'dynamic', comparison: null });
            }, [props.value]);

            // console.log('DEBUG cohortState', cohortState);

            return {
                options,
                // modes: options.length === 0 ? ['dynamic'] : ['fixed', 'dynamic'],
                // modes: ['fixed'],
                modes: props.value?.kind ? [props.value.kind] : [],
                context: props.context,
                // value: factory.build(appliedState, { options }),
                value: cohortState,
                onChange(value) {
                    // console.log('DEBUG changing', value);
                    const casted = castCohortStateToRanking(value);
                    // console.log('DEBUG casted', casted);
                    props.onChange(casted);
                },
                // onChange: props.onChange,
            };
        },
    };
}
