import React from 'react';
import { UserNavigationViewProps } from '../../../view/platform';
import { UserNavigationController } from './userSelectionInterface';

export interface UserContainerProps {
    as: React.FC<{ children?: React.ReactNode | undefined } & UserNavigationViewProps>;
    useController: UserNavigationController;
}

export const UserContainer: React.FC<
    { children?: React.ReactNode | undefined } & UserContainerProps
> = ({ as: Component, useController, ...props }) => {
    const viewProps = useController();
    return <Component {...viewProps}>{props.children}</Component>;
};
