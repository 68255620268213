import React from 'react';
import { Image, Text, useDisclosure } from '@chakra-ui/react';
import { Button } from '../../ui';
import { Integration, IntegrationConf } from '../../models/integration';
import { FiSettings } from 'react-icons/fi';
import connectSVG from '../../svg/connect-white.svg';
import connectedSVG from '../../svg/connected.svg';
function IntegratedButton({ onEdit }: { onEdit: () => void }): React.ReactElement {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Button
            size="sm"
            color="white"
            disabled={false}
            cursor="default"
            colorScheme="varosGreen"
            leftIcon={<Image src={connectedSVG} />}
        >
            Integrated
        </Button>
    );
}

export interface IntegrationButtonProps {
    integration?: Integration;
    integrationDefinition: IntegrationConf;
    connect: (integDef: IntegrationConf) => void;
    reconnect: (integDef: IntegrationConf, integ: Integration) => void;
    isLoadingConnection?: boolean;
    isLoadingReconnection?: boolean;
    hasAnyIntegrations?: boolean;
    isLoadingId?: number;
}

export default function ({
    integration,
    integrationDefinition,
    connect,
    reconnect,
    isLoadingConnection,
    isLoadingReconnection,
    hasAnyIntegrations,
    isLoadingId,
}: IntegrationButtonProps): React.ReactElement {
    let comp = null;
    if (integration) {
        if (integration.is_connected && integration.is_active) {
            comp = (
                <IntegratedButton
                    {...{
                        onEdit: () => console.log('on edit clicked!'),
                    }}
                />
            );
        } else if (!integration.is_connected) {
            comp = (
                <Button
                    size="sm"
                    isLoading={isLoadingReconnection && isLoadingId == integrationDefinition.id}
                    disabled={!integrationDefinition.is_available}
                    onClick={() => reconnect(integrationDefinition, integration)}
                    leftIcon={<Image src={connectSVG} />}
                >
                    ReConnect
                </Button>
            );
        }
    } else {
        if (integrationDefinition.is_available) {
            comp = (
                <Button
                    size="sm"
                    isLoading={isLoadingConnection && isLoadingId == integrationDefinition.id}
                    onClick={(e) => {
                        e.stopPropagation();
                        connect(integrationDefinition);
                    }}
                >
                    {hasAnyIntegrations ? integrationDefinition.implementation.submit_text || 'Integrate' : 'Integrate'}
                </Button>
            );
        } else {
            comp = (
                <Text marginTop={10} variant="caption" color="orange.300">
                    In Private Preview
                </Text>
            );
        }
    }
    return comp || <></>;
}
