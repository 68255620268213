import VarosIcon from '../../../../../svg/varos-icon.svg';
import { ImageProps } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { CompanyListFactory } from './companyListInterface';
import { CompanyItemViewProps } from '../item';

export function createCompanyListFactory(): CompanyListFactory {
    return {
        build(props, item): CompanyItemViewProps {
            return {
                id: item.company.id,
                domain: item.company.domain,
                name: item.company.name,
                iconUrl: item.company.logoUrl,
                tagline: item.company.tagline ?? item.company.description,
                description: item.company.description,
                industry: item.company.industry
                    ? capitalize(item.company.industry)
                    : null,
                revenue: item.company.revenue,
                visitors: item.company.monthlyVisitors,
                employees: item.company.employees,
                profiles: item.company.profiles.flatMap((item) =>
                    item.kind === 'varos'
                        ? [
                              {
                                  image: <ImageProps>{ alt: 'varos', src: VarosIcon },
                                  description: `This company is registered on the Varos platform`,
                              },
                          ]
                        : []
                ),
                highlight: null,
                anchor: {
                    website: {
                        id: `company_website_link`,
                        href: `https://${item.company.domain}`,
                        target: '_blank',
                    },
                },
            };
        },
    };
}

// export function buildRanges(formatter: FormatterImpl) {
//     function formatCurrency(value: number) {
//         return formatter.currency(value, 'usd', { notation: 'short' });
//     }

//     const employeeRanges: Range[] = [
//         { min: 1, max: 10, formatted: '1-10' },
//         { min: 11, max: 50, formatted: '11-50' },
//         { min: 51, max: 100, formatted: '51-100' },
//         { min: 101, max: 500, formatted: '101-500' },
//         { min: 501, max: 1000, formatted: '501-1000' },
//         { min: 1001, max: 5000, formatted: '1001-5000' },
//         { min: 5001, max: 10000, formatted: '5001-10000' },
//         { min: 10001, max: Infinity, formatted: '10001+' },
//     ];

//     const revenueRanges: Range[] = [
//         { min: 0, max: 100000, formatted: `0-${formatCurrency(100000)}` },
//         {
//             min: 100001,
//             max: 500000,
//             formatted: `${formatCurrency(100001)}-${formatCurrency(500000)}`,
//         },
//         {
//             min: 500001,
//             max: 1000000,
//             formatted: `${formatCurrency(500001)}-${formatCurrency(1000000)}`,
//         },
//         {
//             min: 1000001,
//             max: 5000000,
//             formatted: `${formatCurrency(1000001)}-${formatCurrency(5000000)}`,
//         },
//         {
//             min: 5000001,
//             max: 10000000,
//             formatted: `${formatCurrency(5000001)}-${formatCurrency(10000000)}`,
//         },
//         {
//             min: 10000001,
//             max: 50000000,
//             formatted: `${formatCurrency(10000001)}-${formatCurrency(50000000)}`,
//         },
//         {
//             min: 50000001,
//             max: 100000000,
//             formatted: `${formatCurrency(50000001)}-${formatCurrency(100000000)}`,
//         },
//         { min: 100000001, max: Infinity, formatted: `${formatCurrency(100000001)}+` },
//     ];

//     return {
//         employeeRanges,
//         revenueRanges,
//     };
// }
