import { QueryFunction } from '@tanstack/query-core';
import {
    Workspace,
    Dashboard,
    DashboardConfiguration,
    ViewEntity,
} from '../../../domain';
import { DashboardConfigurationAdapter } from './configurationAdapter';

export const createGetDashboardConfigurationQuery = (
    adapter: DashboardConfigurationAdapter,
    views: ViewEntity[],
    workspace: Workspace,
    dashboardId: string
): QueryFunction<DashboardConfiguration> => {
    return async () => {
        return adapter.getConfiguration(views, workspace, dashboardId);
    };
};
