import { ButtonElementController } from '../../../view/button';
import { AssistantRouterEnhancer } from '../../../assistantInterface';
import { AssistantActionClickedEvent } from './assistantElementTrackingEvent';

export function createAssistantElementTrackingStrategy(): AssistantRouterEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = config.kernel.infra.useTracker<AssistantActionClickedEvent>();
            return tracker;
        }

        function enhanceButtonController(
            instance: ButtonElementController
        ): ButtonElementController {
            return {
                ...instance,
                useProps(context, item, props) {
                    const tracker = useTracker();
                    const viewProps = instance.useProps(context, item, {
                        ...props,
                        onSubmit(...args) {
                            tracker.track('assistant_thread_action_clicked', {
                                thread_id: item.thread.id,
                                thread_workflow_id: item.thread.workflow.id,
                                action_id: item.element.action,
                                action_kind: item.element.kind,
                            });
                            return props.onSubmit(...args);
                        },
                    });

                    return viewProps;
                },
            };
        }

        return create({
            ...config,
            provider: {
                ...config.provider,
                createButtonElementController(...args) {
                    const instance = config.provider.createButtonElementController(
                        ...args
                    );
                    return enhanceButtonController(instance);
                },
            },
        });
    };
}
