import { MemberEntity, resolveProperties } from '../../../domain/attributes';
import { DatasetEntity } from './datasetModel';

export function resolveDatasetTypes(
    dataset: DatasetEntity,
    members: MemberEntity[]
): DatasetEntity {
    return {
        ...dataset,
        configuration: {
            ...dataset.configuration,
            schema: resolveProperties(members, dataset.configuration.schema),
        },
    };
}
