import { z } from 'zod';
import { OffsetPaginationSchema } from '../../common';

export const GroupMemberSchema = z.object({
    membership: z.union([z.literal('internal'), z.literal('opt_in'), z.literal('automatic')]),
    id: z.nullable(z.string()),
    asset_id: z.number(),
    group_id: z.string(),
    created_at: z.date({ coerce: true }),
});

export const GroupMemberListResponseSchema = z.object({
    data: z.array(GroupMemberSchema),
    pagination: OffsetPaginationSchema,
});
