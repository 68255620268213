import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { AnyReportConfiguration } from '../../../middleware';

export const HexExampleReport: AnyReportConfiguration = {
    kind: 'external',
    entity: {
        id: 'hex_example',
        name: 'Hex Example',
        status: 'draft',
        tagline: null,
        description: `Magna enim incididunt nostrud officia ut sunt`,
        category: 'marketing',
        tags: ['compensation'],
        dependencies: [],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [
            {
                description: null,
                url: screenshot_spend_over_revenue,
            },
            {
                description: null,
                url: screenshot_spend_over_budget,
            },
            {
                description: null,
                url: screenshot_compensation_model,
            },
        ],
    },
    getTargetUrl(context) {
        return {
            url: `https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/924f316e-8798-4ffe-97f9-0c60e2db0cc6/latest`,
            params: { asset: context.asset, token: context.token },
        };
    },
};
