import { BasicDashboardDepReason, DashboardIntegration } from '../integration';
import { DashboardError, DashboardStatus } from './dashboardStatusType';

export function dashboardStatusFromDependencies(props: {
    anyRequiredInteg: boolean;
    canIgnore: boolean;
    hasAccess: boolean;
    isActivated: boolean;
    noAccessReasons: BasicDashboardDepReason[];
    integrations: DashboardIntegration[];
    requireDataSharing: boolean;
}): DashboardStatus {
    if (props.noAccessReasons.length === 0 && props.integrations.length === 0) {
        return { kind: 'accessable' };
    }

    if (props.hasAccess) {
        return { kind: 'accessable' };
    }

    const failedDataSharingStatusReasons = props.noAccessReasons.filter((x) => x.reasonType == 'not_sharing');

    const allIntegrationsRequiredFulfilled = props.integrations.every((integration) => integration.isFulfilled);

    const allIntegrationsFulfulledOrOptional = props.integrations.every(
        (integration) => integration.isFulfilled || integration.allowIgnore
    );

    if (failedDataSharingStatusReasons.length > 0) {
        return {
            kind: 'no-data-sharing',
            connected: allIntegrationsFulfulledOrOptional,
            errors: failedDataSharingStatusReasons.flatMap<DashboardError>((status) =>
                status
                    ? [
                          {
                              message: status.text ?? status.reasonType,
                              hint: status.instructions.join('. ') ?? null,
                          },
                      ]
                    : []
            ),
        };
    }

    const isFulfilled = allIntegrationsRequiredFulfilled || (props.canIgnore && allIntegrationsFulfulledOrOptional);

    if (props.hasAccess) {
        if (isFulfilled || !props.requireDataSharing) {
            return { kind: 'accessable' };
        }
    }
    if (!props.hasAccess && isFulfilled) {
        return { kind: 'requires-activation' };
    }
    return { kind: 'missing-dependencies' };
}
