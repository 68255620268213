import {
    AnyCondition,
    isCurrencyType,
    isEnumType,
    isMaybePartialNumericRangeValue,
    isNullEndNumericRangeValue,
    isNullStartNumericRangeValue,
    isNumericRangeValue,
    isReferenceType,
    isTreeType,
    isTreeValue,
} from '../../../../domain/attributes';
import { castTypeToDomain } from '../../../../impl';
import { SegmentFactory } from './segmentInterface';

export function createSegmentFactory(): SegmentFactory {
    return {
        build(plugin, state) {
            return plugin.traits.flatMap((item): AnyCondition[] => {
                const itemType = item.type;
                const value = state[item.key];
                if (!value) {
                    return [];
                }
                if (
                    (isReferenceType(itemType) ||
                        isTreeType(itemType) ||
                        isEnumType(itemType)) &&
                    isTreeValue(value)
                ) {
                    return [
                        {
                            key: item.key,
                            operator: 'in',
                            value: value,
                        },
                    ];
                }
                if (
                    isCurrencyType(itemType) &&
                    value &&
                    isNullStartNumericRangeValue(value)
                ) {
                    return [
                        {
                            key: item.key,
                            operator: 'lte',
                            value: value.to,
                        },
                    ];
                }
                if (
                    isCurrencyType(itemType) &&
                    value &&
                    isNullEndNumericRangeValue(value)
                ) {
                    return [
                        {
                            key: item.key,
                            operator: 'gte',
                            value: value.from,
                        },
                    ];
                }
                if (isCurrencyType(itemType) && value && isNumericRangeValue(value)) {
                    return [
                        {
                            key: item.key,
                            operator: 'between',
                            value: value,
                        },
                    ];
                }
                console.warn(
                    `unexpected trait value for item ${item.key}: ${JSON.stringify(
                        value
                    )}`
                );
                return [];
            });
        },
    };
}
