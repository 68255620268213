import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { PluginEntity } from '../../domain';
import { isAbsolutePeriod } from '../../../domain';
import { buildReport } from '../../domain/report';
import { ReportServiceConfig } from './reportConfig';
import { ReportService } from './reportInterface';

export function createReportService(init: ReportServiceConfig): ReportService {
    const {
        repository,
        adapter: { segment: segmentAdapter, status: statusAdapter },
        config,
        infra,
    } = init;
    return {
        useReport(context, props) {
            const definition = useQuery({
                queryKey: ['report', 'definition', props.definition.id],
                async queryFn() {
                    const found = config.reports[props.definition.id];
                    if (!found) {
                        const message = `report '${props.definition.id}' not found`;
                        console.error(message);
                        throw new Error(message);
                    }
                    return found;
                },
                suspense: true,
                staleTime: Infinity,
                retry: false,
            });

            const response = useQuery({
                queryKey: ['report', 'status', props.workspace.id, props.definition.id],
                async queryFn() {
                    try {
                        const [responseStatus, responseSegment] = await Promise.all([
                            statusAdapter.findByWorkspace(context, {
                                workspace: {
                                    id: props.workspace.id,
                                },
                                dependencies: definition.data?.dependencies ?? [],
                            }),
                            segmentAdapter.findByWorkspace(context, {
                                workspace: {
                                    id: props.workspace.id,
                                },
                                dashboards:
                                    definition.data?.dependencies.map(
                                        (dependency) => dependency.dashboard
                                    ) ?? [],
                            }),
                        ]);
                        return {
                            statuses: responseStatus,
                            segments: responseSegment,
                        };
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                },
                suspense: true,
                staleTime: Infinity,
                retry: false,
            });

            const absolute = useMemo(
                () =>
                    isAbsolutePeriod(props.period)
                        ? props.period
                        : {
                              start: moment(props.date)
                                  .subtract(props.period.amount, props.period.interval)
                                  .add(1, 'day')
                                  .toDate(),
                              end: moment(props.date).toDate(),
                          },
                [props.period, props.date]
            );

            const insights = repository.opportunities.useFind(
                { ...context, workspace: props.workspace },
                {
                    // for now we always look back a month for insights
                    since: moment(props.date).subtract(4, 'week').add(1, 'day').toDate(),
                    before: moment(props.date).add(1, 'day').toDate(),
                    orderBy: [
                        {
                            key: 'priority',
                            direction: 'desc',
                        },
                        {
                            key: 'period_end_at',
                            direction: 'desc',
                        },
                    ],
                },
                { suspense: true, staleTime: Infinity, retry: false }
            );

            const plugins = repository.plugins.useFind(
                { ...context },
                {},
                { suspense: true, staleTime: Infinity, retry: false }
            );

            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const report = useMemo(() => {
                return buildReport({
                    definition: definition.data!,
                    insights:
                        insights.data?.items.flatMap((insight) => {
                            const plugin = pluginsById[insight.definition.plugin];
                            if (!plugin) {
                                console.warn(
                                    `plugin ${insight.definition.plugin} not found`
                                );
                                return [];
                            }
                            return [{ insight: insight, plugin }];
                        }) ?? [],
                    statuses: response.data!.statuses,
                    segments: response.data!.segments,
                    date: props.date,
                    absolute,
                    period: props.period,
                    granularity: props.granularity,
                });
            }, [definition.data, response.data, insights.data, plugins.data]);

            return report;
        },
    };
}
