import { QueryRequest } from '../../../../domain/query';
import { Metric } from '../../../../domain/demo';
import { DEFAULT_SEGMENT } from '../common';

export const SHOPIFY_METRICS: Metric[] = [
    {
        id: 'repeating_orders_ratio',
        name: `% Repeating Orders`,
    },
    {
        id: 'revenue_growth',
        name: `Rev. Growth`,
    },
    {
        id: 'abandoned_checkout_rate',
        name: `Abandoned Checkouts`,
    },
    {
        id: 'refunds_rate',
        name: `Refund Rate`,
    },
];

export function getShopifyQuery(): QueryRequest {
    return {
        segment: [],
        projections: [
            {
                name: 'absolute',
                columns: [
                    {
                        key: 'repeated_orders_ratio',
                        alias: null,
                        expr: null,
                        type: {
                            kind: 'percent',
                            fraction: true,
                        },
                    },
                ],
            },
        ],
        filters: [
            {
                key: 'TS',
                operator: 'previous',
                value: {
                    amount: 4,
                    interval: 'week',
                },
            },
        ],
        comparison: null,
        facet: null,
        aggregations: [
            {
                name: 'repeated_orders_ratio',
                title: 'Repeating Order Ratio',
                inverse: false,
                expr: {
                    kind: 'binary',
                    operator: '/',
                    left: {
                        kind: 'aggregation',
                        type: 'sum',
                        column: 'repeated_count',
                        partition: [],
                        filters: [],
                    },
                    right: {
                        kind: 'aggregation',
                        type: 'sum',
                        column: 'orders_count',
                        partition: [],
                        filters: [],
                    },
                },
            },
        ],
        segments: DEFAULT_SEGMENT,
        granularity: 'week',
        group: [],
        timeseries: {},
        source: {
            view: 'shopify.shopify_v2_orders',
        },
    };
}
