import { AnalysisQueryExecutor } from '../../../route';
import { castQueryToApi, castResponseToDomain } from '../../api';
import { ExecutorConfig } from './executorConfig';

export function createAnalysisQueryExecutorImpl(
    config: ExecutorConfig
): AnalysisQueryExecutor {
    const {
        api: {
            query: {
                queries: { create: executeQuery },
            },
        },
    } = config;
    return {
        async execute(context, request) {
            const { segment, ...casted } = castQueryToApi(request);
            const response = await executeQuery(context, {
                asset: context.workspace.id as number,
                query: casted,
                segment,
            });
            return castResponseToDomain(response);
        },
    };
}
