import React, { useEffect, useRef } from 'react';
import { DASHBOARD_HIGHTLIGHT_BY_SLUG, DEFAULT_HIGHTLIGHT } from '../../config';
import { AccessResponse, ApplicationContext } from '../../base';
import { AccessCard, AccessCardProps } from '../../domain';
import { useTrackAccessViewed } from './AccessRestrictTracker';
import { useOutsideClick } from '@chakra-ui/react';

export interface AccessRestrictViewProps extends Omit<AccessCardProps, 'request'> {
    attempt: AccessResponse | null;
    context: ApplicationContext;
}

export const AccessRestrictView: React.FC<
    { children?: React.ReactNode | undefined } & AccessRestrictViewProps
> = ({ context, children, attempt, ...restProps }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useTrackAccessViewed(context, attempt);
    useOutsideClick({
        ref,
        handler() {
            // clear access modal when clicking outside
            context.access.clear();
        },
    });
    if (attempt == null || !attempt.request || !attempt.restricted) {
        return <>{children}</>;
    }
    if (
        attempt.request?.id === 'dashboard' &&
        attempt.request.value.name &&
        attempt.request.value.slug
    ) {
        // When restricting dashboards we map the specific dashboard data instead of the generic "dashboard" feature
        const highlight = DASHBOARD_HIGHTLIGHT_BY_SLUG[attempt.request.value.slug];
        return (
            <AccessCard
                request={{
                    ...attempt.request,
                    name: highlight?.title ?? attempt.request.value.name,
                    tagline: highlight?.subtitle,
                    highlights: highlight?.features,
                    graphics: highlight?.graphics,
                }}
                {...restProps}
            />
        );
    }
    const defaults = attempt.request
        ? DEFAULT_HIGHTLIGHT(attempt.request)
        : ({} as ReturnType<typeof DEFAULT_HIGHTLIGHT>);
    return (
        <div ref={ref}>
            <AccessCard
                request={{
                    ...attempt.request,
                    name: defaults.title ?? attempt.request.description,
                    tagline: defaults.subtitle ?? attempt.request.description,
                    highlights: defaults.features,
                    graphics: defaults.graphics,
                }}
                {...restProps}
            />
        </div>
    );
};
