import { chain } from 'lodash';
import { useMemo } from 'react';
import { isDateType } from '../../../../domain/attributes';
import { assert } from '../../../../util/assert';
import { buildFormFieldItemProps, FormFieldItemViewProps } from '../item';
import { FormFieldListController } from './formFieldListInterface';

export function createFormFieldListController(): FormFieldListController {
    return {
        useProps(context, item, props) {
            const items = useMemo(
                () =>
                    chain(item.fields)
                        // TODO this should be based on whether the property is the partition
                        // or "writeable" because a form might contain actual date properties
                        // as part of its input schema
                        .filter((item) => !isDateType(item.property.type))
                        .orderBy((item, index) => index, 'asc')
                        .value(),
                [item.fields]
            );
            const itemPropsByKey = useMemo(
                () =>
                    items.reduce(
                        (acc, item) => ({
                            ...acc,
                            // [item.property.key]: props.form.register(item.property),
                            [item.property.key]: buildFormFieldItemProps(item, {
                                value: props.value[item.property.key],
                                onChange(value) {
                                    props.onInputChange(item.property.key, value);
                                },
                                error: props.errors[item.property.key] ?? null,
                            }),
                        }),
                        {} as Record<string, FormFieldItemViewProps | undefined>
                    ),
                [props.value, items]
            );
            return {
                items: items,
                getItemProps(item) {
                    const itemProps = itemPropsByKey[item.property.key];
                    assert(itemProps, 'no item props');
                    return itemProps;
                },
            };
        },
    };
}
