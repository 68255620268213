import React from 'react';
import { UserManagementModuleConfig } from './userManagementConfig';

export function createUserManagementModule(config: UserManagementModuleConfig) {
    return {
        Container: {
            List: config.provider.list.createContainer({
                hook: {
                    useAuth: config.deps.hook.useAuth,
                    useOrganization: config.deps.hook.useOrganization,
                    useUser: config.deps.hook.useUser,
                },
                controller: config.provider.list.createController({
                    service: config.deps.service,
                }),
                View: config.provider.list.createView({
                    UI: config.deps.UI,
                    Layout: config.deps.Layout,
                    // Container: {
                    //     EditForm: config.provider.edit.createContainer({
                    //         hooks: config.deps.hook,
                    //         controller: config.provider.edit.createController({
                    //             service: {
                    //                 collaborator: config.deps.service.collaborator,
                    //                 user: config.deps.service.user,
                    //                 workspace: config.deps.service.workspace,
                    //             },
                    //             hook: {
                    //                 useUser: config.deps.hook.useUser,
                    //             },
                    //         }),
                    //         View: config.provider.edit.createView({ UI: config.deps.UI }),
                    //     }),
                    // },
                }),
            }),
        },
    };
}
