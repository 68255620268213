import { useNavigate } from 'react-router';
import { AssetNewFormValues } from '../../../view/assets';
import { OnboardingAssetConfig } from './onboardingAssetConfig';
import { OnboardingAssetController } from './onboardingAssetInterface';
import { OnboardingAssetViewProps } from './onboardingAssetProps';

export function createOnboardingAssetController(
    config: OnboardingAssetConfig
): OnboardingAssetController {
    const { router, controller } = config;
    return {
        useProps(context, deps, props): OnboardingAssetViewProps {
            const {
                new: { form },
            } = deps;
            const navigate = useNavigate();

            const viewProps = controller.new.useProps(context, deps.new, props);

            const formProps = viewProps.getFormProps();

            const handleSubmitClick = async (values: AssetNewFormValues) => {
                const response = await formProps.onSubmit(values);
                const path = router.getSuccessPath(response);
                console.info(`redirecting to asset onboarding -> ${path}`);
                navigate(path);
            };

            return {
                getFormProps() {
                    return {
                        onSubmit: handleSubmitClick,
                        submitLabel: `Fill out the form to continue`,
                        isSubmittable: form.formState.isDirty || form.formState.isValid,
                        isLoading: form.formState.isSubmitting,
                    };
                },
                getViewProps() {
                    return viewProps;
                },
            };
        },
    };
}
