import {
    AnyCondition,
    AnyValue,
    getBetweenConstraintBounds,
    getIntervalsByConstraint,
    isEnumLikeType,
    isGreaterThanOrEqualCondition,
    isInCondition,
    isLessThanOrEqualCondition,
    isNumericBetweenCondition,
    isNumericType,
} from '../../../../domain/attributes';
import { assert } from '../../../../util/assert';
import { keyBySafe } from '../../../../util';
import { FilterListValue } from '../../../common';
import { isLoadingLookup } from '../../../../base';
import { isRangeInputValue } from '../../input';
import { PeerFilterListAggregate } from './peerFilterListModel';
import { PeerFilterListStatusProps } from './peerFilterListProps';

export function buildStatusProps(
    item: PeerFilterListAggregate
): PeerFilterListStatusProps | null {
    if (!item.status) {
        return null;
    }
    if (isLoadingLookup(item.status)) {
        return {
            loading: true,
            label: 'Loading',
        };
    }

    if (item.status.data?.transparency.quality === 'insufficient_sample') {
        return {
            loading: false,
            status: 'error',
            label: `Not enough candidates found`,
        };
    }

    const [start, end] = item.status.data?.transparency.range ?? [];

    let formatted = null;
    if (start && end) {
        formatted = [start, end].join(' - ');
    } else if (start) {
        formatted = `${start}+`;
    }

    const label = formatted === null ? 'N/A' : `${formatted} candidates matched`;

    if (start && end) {
        return {
            loading: false,
            status: 'info',
            label: label,
        };
    }
    return {
        loading: false,
        status: 'info',
        label: label,
    };
}

export function buildConditionsToState(
    item: PeerFilterListAggregate,
    conditions: AnyCondition[]
): FilterListValue {
    const traitsByKey = keyBySafe(item.traits, (item) => item.key);
    return conditions.reduce((acc, condition) => {
        const property = traitsByKey[condition.key];
        if (!property) {
            // property no longer exist, skip condition for backwards-compatability
            return acc;
        }
        const bounds = getBetweenConstraintBounds(property.constraints);
        if (isNumericBetweenCondition(condition)) {
            return {
                ...acc,
                [condition.key]: [condition.value.from, condition.value.to],
            };
        }
        if (
            isNumericType(property.type) &&
            isGreaterThanOrEqualCondition(condition) &&
            bounds
        ) {
            // we map numeric comparisons to ranges
            const [min, max] = bounds;
            return {
                ...acc,
                [condition.key]: [condition.value, max],
            };
        }
        if (
            isNumericType(property.type) &&
            isLessThanOrEqualCondition(condition) &&
            bounds
        ) {
            // we map numeric comparisons to ranges
            const [min, max] = bounds;
            return {
                ...acc,
                [condition.key]: [min, condition.value],
            };
        }
        return {
            ...acc,
            [condition.key]: condition.value,
        };
    }, {});
}

export function buildStateToConditions(
    item: PeerFilterListAggregate,
    values: FilterListValue
): AnyCondition[] {
    return item.traits.flatMap((item): AnyCondition[] => {
        const value = values[item.key] as AnyValue | undefined;
        if (!value) {
            return [];
        }
        const bounds = getBetweenConstraintBounds(item.constraints);
        if (isNumericType(item.type) && bounds) {
            assert(isRangeInputValue(value), 'expected number type for numeric property');
            const [min, max] = bounds;
            const [from, to] = value;
            if (from === min && to === max) {
                // treat as no filter is selected
                return [];
            }
            if (to === max) {
                return [
                    {
                        key: item.key,
                        operator: 'gte',
                        value: from,
                    },
                ];
            }
            return [
                {
                    key: item.key,
                    operator: 'between',
                    value: {
                        from,
                        to,
                    },
                },
            ];
        }
        if (isEnumLikeType(item.type)) {
            assert(Array.isArray(value), 'expected array type for enum-like property');
            if (value.length === 0) {
                return [];
            }
            return [
                {
                    key: item.key,
                    operator: 'in',
                    value: value as string[],
                },
            ];
        }
        throw new Error(`not impl`);
    });
}
