import { useWorkspaceApi, useDashboardApi, useQueryApi } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';

export function createExportTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                useActions() {
                    const api = {
                        workspace: useWorkspaceApi(),
                        dashboard: useDashboardApi(),
                        query: useQueryApi(),
                    };
                    const tracker = config.deps.useTracker();
                    const workspace = api.workspace.getWorkspace();
                    const dashboard = api.dashboard.getDashboard();
                    const mapped = config.controller.useActions();
                    return {
                        ...mapped,
                        async onSave() {
                            const value = await mapped.onSave();
                            tracker.track(
                                'saved_filters',
                                // @ts-expect-error
                                {
                                    dashboard: dashboard.id,
                                    asset_id: workspace.id,
                                }
                            );
                            return value;
                        },
                        async onExport() {
                            const value = await mapped.onExport();
                            tracker.track(
                                'dashboard_exported',
                                // @ts-expect-error
                                {
                                    dashboard: dashboard.id,
                                    dashboard_name: dashboard.title,
                                    date_granularity: api.query.getGranularity(),
                                }
                            );
                            return value;
                        },
                    };
                },
            },
        });
        return instance;
    };
}
