import { createContext, useContext } from 'react';
import { QueryBuilder } from '../../../../domain';

export interface ExportContextValue {
    querybuilder: QueryBuilder;
}

export const ExportContext = createContext<ExportContextValue | null>(null);

export const useExportContext = (): ExportContextValue => {
    const context = useContext(ExportContext);
    if (!context) {
        throw new Error(`export context not available`);
    }
    return context;
};
