import { LegacyAuthenticationScheme } from '../../../domain';
import { evaluateFilter } from '../../../../api';
import { MetadataRule } from '../../../../models/Metadata';
import { CompetitveSetStatusAdapter } from '../../../app/query';
import { castConditionToApi } from '../../api';
import { CompetitiveStatusImplConfig } from './competitiveStatusConfig';

export function createCompetitiveStatusImpl(
    config: CompetitiveStatusImplConfig
): CompetitveSetStatusAdapter {
    const { axios } = config;
    return {
        async findOne(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            // return { quality: 'relevant_sample', range: [10, 20] };
            const { data } = await evaluateFilter(
                axios,
                context.workspace.id as number,
                {
                    operator: 'and',
                    sub_rules: query.filters
                        .filter((condition) => condition.value)
                        .map<MetadataRule>(
                            // @ts-expect-error
                            (condition) => {
                                const mapped = castConditionToApi(condition);
                                return {
                                    metadata_definition_key: mapped.key,
                                    operator: mapped.operator,
                                    value: mapped.value,
                                };
                            }
                        ),
                },
                'facebookads-v2',
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            // data.evaluated_quality
            // return {
            //     range: [50],
            //     quality: 'relevant_sample',
            // };
            return data.evaluated_quality;
        },
    };
}
