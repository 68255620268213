import { useQueryClient } from '@tanstack/react-query';
import { DemoRouteInit, DemoRouteConfig } from '../../../route/demo';
import { DemoEntryEnhancer } from '../../../entrypoint';
import { cancelChartQueries } from '../../../controller/demo';

export function createDemoCancellationStrategy(): DemoEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            route: {
                ...config.route,
                demo: {
                    ...config.route.demo,
                    config: {
                        ...config.route.demo.config,
                        ...enhanceConfig(config.route.demo.config),
                    },
                },
            },
        });
    };
}

function enhanceConfig(
    config: Pick<DemoRouteInit, 'controller'>
): Partial<DemoRouteInit> {
    return {
        ...config,
        controller: {
            ...config.controller,
            createRouteControllerHook() {
                const useController = config.controller.createRouteControllerHook();
                return () => {
                    const controller = useController();
                    const queryclient = useQueryClient();
                    return {
                        ...controller,
                        fields: {
                            ...controller.fields,
                            getPlatform() {
                                const original = controller.fields.getPlatform();
                                return {
                                    ...original,
                                    onChange(value) {
                                        cancelChartQueries(queryclient);
                                        return original.onChange(value);
                                    },
                                };
                            },
                            getFilter(dashboard, property) {
                                const original = controller.fields.getFilter(
                                    dashboard,
                                    property
                                );
                                return {
                                    ...original,
                                    onChange(value) {
                                        cancelChartQueries(queryclient);
                                        return original.onChange(value);
                                    },
                                };
                            },
                        },
                    };
                };
            },
        },
    };
}
