import { FeatureMap, listFeatures } from '../../../../api';
import { FeatureSetAdapter } from '../../../app/platform';
import { FeatureSetImplConfig } from './featuresetConfig';

export function createFeaturesetImpl(config: FeatureSetImplConfig): FeatureSetAdapter {
    const { axios } = config;
    return {
        async get(context, assetId) {
            const response = await listFeatures(context, axios, assetId);
            const featureById = response.items.reduce(
                (acc, item) => ({ ...acc, [item.id]: item }),
                {} as FeatureMap
            );
            return featureById;
        },
    };
}
