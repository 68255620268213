import { keyBy as lodashKeyBy } from 'lodash';

type KeyByCallback<TItem> = (item: TItem) => string;

/**
 * Properly sets nullable type for members which is currently not done by
 * native lodash types
 * @param items
 * @param callback
 * @returns
 */
export function keyBySafe<TItem>(
    items: TItem[],
    callback: KeyByCallback<TItem>
): Record<string, TItem | undefined> {
    return lodashKeyBy(items, callback);
}
