import React from 'react';
import { CheckIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    VStack,
    HStack,
    Box,
    Text,
    List,
    ListItem,
    Input,
    Table,
    Tbody,
    Tr,
    Thead,
    Th,
    Td,
    TableCellProps,
    Select,
    Divider,
    Tooltip,
    Icon,
    Checkbox,
} from '@chakra-ui/react';
import { CohortMode } from '../../../domain';
import { CohortViewProps } from './cohortProps';
import { CohortModuleConfig } from './cohortConfig';

export function createCohortView(
    config: CohortModuleConfig['deps']
): React.FC<{ children?: React.ReactNode | undefined } & CohortViewProps> {
    const {
        UI: { Tooltip },
        view: { createButtonSelect },
    } = config;
    const { Select: ModeSelect, Option: ModeOption } = createButtonSelect<CohortMode>({
        Component: {
            List(props) {
                return (
                    <HStack w="full" spacing={4}>
                        {props.children}
                    </HStack>
                );
            },
            Item(props) {
                return (
                    <Tooltip
                        placement="top"
                        hasArrow={true}
                        label="Not supported for this dashboard"
                        isDisabled={!props.isDisabled}
                    >
                        <VStack
                            {...{
                                'aria-disabled': props.isDisabled,
                                'aria-selected': props.isSelected,
                            }}
                            py={2}
                            px={4}
                            cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
                            onClick={props.isDisabled ? undefined : props.onClick}
                            alignItems="flex-start"
                            spacing={0}
                            flex={1}
                            borderRadius="lg"
                            borderWidth="2px"
                            borderStyle="solid"
                            borderColor="whiteAlpha.200"
                            _selected={{
                                borderColor: '#04c380',
                            }}
                            _disabled={{
                                opacity: 0.5,
                            }}
                        >
                            <HStack justifyContent="space-between" w="full" spacing={2}>
                                <VStack spacing={0} alignItems="flex-start" flex={1}>
                                    <Text display="block" fontWeight="medium">
                                        {props.children}
                                    </Text>
                                    {props.caption && (
                                        <Text
                                            display="block"
                                            color="whiteAlpha.500"
                                            fontSize="sm"
                                        >
                                            {props.caption}
                                        </Text>
                                    )}
                                </VStack>
                                <CheckIcon
                                    fontSize="sm"
                                    color={props.isSelected ? '#04c380' : 'transparent'}
                                />
                            </HStack>
                        </VStack>
                    </Tooltip>
                );
            },
        },
    });
    return (props) => {
        return (
            <VStack alignItems="flex-start" w="full" spacing={4}>
                <List w="full" spacing={4}>
                    <ListItem>
                        <ModeSelect
                            value={props.fields.mode.value}
                            onChange={props.fields.mode.onChange}
                        >
                            {props.fields.mode.options.map((item) => (
                                <ModeOption
                                    key={item.value}
                                    value={item.value}
                                    caption={item.caption}
                                    isDisabled={item.isDisabled}
                                >
                                    {item.label}
                                </ModeOption>
                            ))}
                        </ModeSelect>
                    </ListItem>
                    <ListItem>
                        <HStack justifyContent="space-between">
                            <Box maxW="75%">
                                <Text fontWeight="medium">Rank metric</Text>
                            </Box>
                            <Box>
                                <Select
                                    value={props.fields.comparison.value ?? undefined}
                                    onChange={(event) =>
                                        props.fields.comparison.onChange(
                                            event.target.value
                                        )
                                    }
                                    size="sm"
                                    minW={32}
                                    placeholder={
                                        props.fields.mode.value === 'dynamic'
                                            ? 'None'
                                            : undefined
                                    }
                                    isDisabled={props.fields.mode.value !== 'fixed'}
                                >
                                    {props.fields.comparison.options.map((item) => (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </Box>
                        </HStack>
                    </ListItem>
                </List>
                <Divider borderColor="whiteAlpha.200" borderWidth="1px" />
                {props.fields.mode.value === 'fixed' && (
                    <VStack align="start">
                        <VStack color="whiteAlpha.700" align="start">
                            <Text>
                                Cohorts of companies are selected by how they rank in your
                                chosen metric above. The median of that same cohort of
                                companies are then used to calculate the rest of the
                                metrics.
                                <Tooltip
                                    id="ranking_fixed_description_tooltip"
                                    placement="top"
                                    label={
                                        <Box p={2}>
                                            For example, if you chose CPP as your "Rank
                                            By" metric and companies A, B and C have the
                                            strongest CPP, they would be classified as
                                            High Performers. Then the CPM shown would be
                                            the median of those same companies (A, B and
                                            C)
                                        </Box>
                                    }
                                >
                                    <Icon
                                        ml={1}
                                        mb={0.5}
                                        display="inline-block"
                                        color="whiteAlpha.700"
                                        as={InfoOutlineIcon}
                                    />
                                </Tooltip>
                            </Text>
                        </VStack>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th
                                        color="whiteAlpha.500"
                                        borderColor="whiteAlpha.200"
                                        w="full"
                                        whiteSpace="nowrap"
                                        pl={0}
                                    >
                                        Cohort
                                    </Th>
                                    <Th
                                        borderColor="whiteAlpha.200"
                                        color="whiteAlpha.500"
                                    >
                                        Rank metric range
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {props.fields.fixedCohorts.value.map(
                                    (item, index, array) => {
                                        const itemProps = props.getFixedCohortProps(
                                            item,
                                            index
                                        );
                                        const isLast = index === array.length - 1;
                                        const tdStyle: TableCellProps = {
                                            borderColor: 'whiteAlpha.200',
                                            ...(isLast ? { borderBottom: 'none' } : {}),
                                        };
                                        return (
                                            <Tr key={index}>
                                                <Td
                                                    w="full"
                                                    whiteSpace="nowrap"
                                                    {...tdStyle}
                                                    pl={0}
                                                >
                                                    <HStack
                                                        flex={1}
                                                        color="whiteAlpha.900"
                                                        cursor="pointer"
                                                        onClick={itemProps.visible.onChange.bind(
                                                            null,
                                                            !itemProps.visible.value
                                                        )}
                                                    >
                                                        <Checkbox
                                                            isChecked={item.visible}
                                                            pointerEvents="none"
                                                            // onChange={(event) => {
                                                            //     event.preventDefault();
                                                            //     event.stopPropagation();
                                                            //     itemProps.visible.onChange(
                                                            //         !itemProps.visible
                                                            //             .value
                                                            //     );
                                                            // }}
                                                        />
                                                        <Text userSelect="none">
                                                            {item.name}
                                                        </Text>
                                                    </HStack>
                                                </Td>
                                                <Td {...tdStyle} w="fit-content" pr={0}>
                                                    <HStack
                                                        alignItems="center"
                                                        spacing={4}
                                                    >
                                                        <Input
                                                            isDisabled={true}
                                                            size="sm"
                                                            min={0}
                                                            max={100}
                                                            minW={16}
                                                            type="number"
                                                            value={itemProps.lower.value}
                                                        />
                                                        <Text
                                                            textTransform="uppercase"
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                            letterSpacing="wider"
                                                            color="whiteAlpha.500"
                                                        >
                                                            To
                                                        </Text>
                                                        <Input
                                                            isDisabled={true}
                                                            size="sm"
                                                            min={0}
                                                            max={100}
                                                            minW={16}
                                                            type="number"
                                                            value={itemProps.upper.value}
                                                        />
                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                            letterSpacing="wider"
                                                            color="whiteAlpha.500"
                                                        >
                                                            %
                                                        </Text>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        );
                                    }
                                )}
                            </Tbody>
                        </Table>
                    </VStack>
                )}
                {props.fields.mode.value === 'dynamic' && (
                    <VStack align="center">
                        <VStack color="whiteAlpha.700" align="start">
                            <Text>
                                If you choose the "per-metric" option, we will rank all
                                brands for each metric independently. Then, we'll
                                determine low, median, and high performers based on the
                                percentiles below.
                                <Tooltip
                                    id="ranking_dynamic_description_tooltip"
                                    placement="top"
                                    label={
                                        <Box p={2}>
                                            For example, high performers for CTR means
                                            that 75% of companies have a lower CTR value
                                            than what's showcased in the performance
                                            summary. It's important to note that brands
                                            with lower CTR values may rank as top
                                            performers in other metrics.
                                        </Box>
                                    }
                                >
                                    <Icon
                                        ml={1}
                                        mb={0.5}
                                        display="inline-block"
                                        color="whiteAlpha.700"
                                        as={InfoOutlineIcon}
                                    />
                                </Tooltip>
                            </Text>
                        </VStack>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th
                                        color="whiteAlpha.500"
                                        borderColor="whiteAlpha.200"
                                        w="full"
                                        whiteSpace="nowrap"
                                        pl={0}
                                    >
                                        Cohort
                                    </Th>
                                    <Th
                                        borderColor="whiteAlpha.200"
                                        color="whiteAlpha.500"
                                    >
                                        Percentile
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {props.fields.dynamicCohorts.value.map(
                                    (item, index, array) => {
                                        const itemProps = props.getDynamicCohortProps(
                                            item,
                                            index
                                        );
                                        const isLast = index === array.length - 1;
                                        const tdStyle: TableCellProps = {
                                            borderColor: 'whiteAlpha.200',
                                            ...(isLast ? { borderBottom: 'none' } : {}),
                                        };
                                        return (
                                            <Tr key={index}>
                                                <Td
                                                    w="full"
                                                    whiteSpace="nowrap"
                                                    {...tdStyle}
                                                    pl={0}
                                                >
                                                    <HStack
                                                        flex={1}
                                                        color="whiteAlpha.900"
                                                        cursor="pointer"
                                                        onClick={itemProps.visible.onChange.bind(
                                                            null,
                                                            !itemProps.visible.value
                                                        )}
                                                    >
                                                        <Checkbox
                                                            isChecked={item.visible}
                                                            pointerEvents="none"
                                                            // onChange={(event) => {
                                                            //     event.preventDefault();
                                                            //     event.stopPropagation();
                                                            //     itemProps.visible.onChange(
                                                            //         !itemProps.visible
                                                            //             .value
                                                            //     );
                                                            // }}
                                                        />
                                                        <Text userSelect="none">
                                                            {item.name}
                                                        </Text>
                                                    </HStack>
                                                </Td>
                                                <Td {...tdStyle} w="fit-content" pr={0}>
                                                    <HStack
                                                        alignItems="center"
                                                        spacing={4}
                                                    >
                                                        <Input
                                                            isDisabled={true}
                                                            size="sm"
                                                            min={0}
                                                            max={100}
                                                            minW={16}
                                                            type="number"
                                                            value={itemProps.value.value}
                                                        />
                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                            letterSpacing="wider"
                                                            color="whiteAlpha.500"
                                                        >
                                                            %
                                                        </Text>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        );
                                    }
                                )}
                            </Tbody>
                        </Table>
                    </VStack>
                )}
            </VStack>
        );
    };
}
