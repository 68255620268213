import isUrl from 'is-url-superb';
import { getAssetDomain } from '../../../../domain/assets';
// import { AssetTypeSchema } from '../../../../api';
import { z } from 'zod';

export const AssetNewFormValuesSchema = z.object({
    // type: AssetTypeSchema,
    name: z
        .string({ required_error: 'Name is required' })
        .min(3, { message: 'Name must be at least 3 characters long' })
        .max(100, { message: 'Name must be at at most 100 characters long' }),
    url: z
        .string({ required_error: 'URL is required' })
        .min(3, { message: 'Invalid URL' })
        .transform((item) => getAssetDomain({ url: item }) ?? ''),
    // .transform((value) => {
    //     try {
    //         const urlString = `https://${value}`;
    //         const url = new URL(urlString);
    //         url.protocol = 'https';
    //         url.pathname = '';
    //         return url.toString().replace(/\/$/, '');
    //     } catch (error) {
    //         return value;
    //     }
    // })
    // .refine(
    //     (value) => {
    //         return URL_REGEX.test(value);
    //         // return URL_REGEX.test(`https://${value}`);
    //         // return isUrl(value, { lenient: true });
    //     },
    //     { message: 'Invalid URL' }
    // ),
});
