import { chain, intersectionBy } from 'lodash';
import { DEFAULT_COMPARISON } from '../../../../../components/DashboardView/DashboardView';
import {
    EnumMember,
    getPreferences,
    listColumns,
    DashTraitFilter,
    DashDataFilter,
    TimestampDashFilter,
    fetchDashboard,
    fetchMembers,
    Column,
    TraitFilterSection,
    DataFilterSection,
    isReferenceNumberFilter,
    isTypeReference,
    AnyType,
    QuerySegmentSection,
    QuerySegments,
    Dashboard,
} from '../../../../../api';
import { MetadataRule, isSingle } from '../../../../../models/Metadata';
import {
    isDateFilter,
    AnyFilter,
    DateFilter,
    Card,
    DashboardConfiguration,
    CohortMode,
} from '../../../../domain/dashboard';
import { isNumeric, Schema } from '../../../../../domain';
import { DashboardSlugs } from '../../../../../config/dashboard';
import {
    EnumMemberWithChildren,
    isNumericType,
    Property,
} from '../../../../domain/attributes';
import { DashboardConfigurationAdapter } from '../../../../app';
import { castQueryToDomain, castTypeToDomain } from '../../query';
import { DashboardApiImplConfig } from './configurationApiConfig';
import { toDomainFilter, apiTypeToPropertyType } from './configurationMapper';
import { ViewDto } from '../../../../api';
import { MetricIds } from '../../../../../config';

export function createApiConfigurationImpl(
    config: DashboardApiImplConfig
): DashboardConfigurationAdapter {
    const { loadEnums = true } = config;

    // const RANKABLE_METRICS = ['roas', ' cpp', 'cost_per_purchase'];
    const CANDIDATES = [
        'cost_per_purchase',
        'cost_per_conversion',
        'roas',
        'marketing_efficiency_ratio',
        // 'cpp',
        'revenue',
        'ctr',
    ];

    const DASHBOARD_COHORT_MODES: Record<string, CohortMode[] | undefined> = {
        [DashboardSlugs.FACEBOOK_BREAKDOWN]: ['fixed'],
        [DashboardSlugs.MEDIA_MIX]: ['dynamic'],
        [DashboardSlugs.GA4_REVENUE_BREAKDOWN]: ['dynamic'],
        [DashboardSlugs.GA4_TRAFFIC]: ['dynamic'],
        [DashboardSlugs.GA4_ECOMMERCE]: ['dynamic'],
        [DashboardSlugs.SHOPIFY_V2]: ['dynamic'],
        [DashboardSlugs.SHOPIFY_LTV_V2]: ['dynamic'],
        [DashboardSlugs.IT_BENCHMARKS_SPEND_DIST]: ['fixed'],
    };

    return {
        async getConfiguration(
            views,
            workspace,
            dashboardId
        ): Promise<DashboardConfiguration> {
            // console.log('views', views);
            const dashboard = await fetchDashboard(
                config.axios,
                dashboardId,
                [workspace.id as number],
                {
                    apiKey: config.overrides?.apiKey,
                    organizationId: config.overrides?.organizationId,
                }
            );
            const [columns, preferences] = await Promise.all([
                listColumns(config.axios, dashboardId, {
                    apiKey: config.overrides?.apiKey,
                }),
                getPreferences(
                    config.axios,
                    ['data_filters', 'trait_filters', 'query_segments'],
                    workspace.id,
                    dashboard.slug,
                    dashboard.plugin,
                    {
                        apiKey: config.overrides?.apiKey,
                        organizationId: config.overrides?.organizationId,
                    }
                ),
            ]);

            const dashboardColumns: Array<{
                key: string;
                type: AnyType;
                view: string | number;
            }> = [
                ...new Set(
                    dashboard.cards.flatMap((card) =>
                        card.queries.flatMap((query) =>
                            query.projections.flatMap((projection) =>
                                projection.columns.flatMap((column) => ({
                                    key: column.key,
                                    type: column.type ?? 'string',
                                    view: query.source.view,
                                }))
                            )
                        )
                    )
                ),
            ];

            const typeReferences = [
                ...new Set(
                    dashboard.cards.flatMap((card) =>
                        card.queries.flatMap((query) =>
                            query.projections.flatMap((projection) =>
                                projection.columns.flatMap((column) =>
                                    column.type && isTypeReference(column.type)
                                        ? [column.type.id]
                                        : []
                                )
                            )
                        )
                    )
                ),
            ];

            const referenceMemberPairs = await Promise.all(
                typeReferences.map(async (reference) => {
                    const value = await fetchMembers(
                        config.axios,
                        reference,
                        workspace.id as number,
                        dashboardId,
                        {
                            apiKey: config.overrides?.apiKey,
                            organizationId: config.overrides?.organizationId,
                        }
                    );
                    return [reference, value] as const;
                })
            );

            const dataMembersByTypeId = referenceMemberPairs.reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: value.map(
                        (element): EnumMemberWithChildren => ({
                            value: element.value,
                            label: element.name,
                            children: [],
                        })
                    ),
                }),
                {} as Record<string, EnumMemberWithChildren[]>
            );

            const viewSchemas: DashboardConfiguration['viewSchemas'] = Object.entries(
                columns.items.reduce<Record<string, Schema>>(
                    (agg, curr) => {
                        const schema = agg[curr.view] || {
                            properties: {},
                            type_properties: {},
                            partition: [],
                        };
                        const existing = schema.properties[curr.key];
                        // console.log('START MAKING PROP');
                        // console.log('COL', curr);
                        // console.log('ExISTING', existing);
                        // || (curr.type_slug && {kind: 'reference', id: curr.type_slug})
                        const property: Property = {
                            ...(existing || {}),
                            key: curr.key,
                            name: curr.name || '',
                            description: curr.description,
                            type: existing?.type || apiTypeToPropertyType(curr.type),
                            constraints: [],
                        };
                        // console.log('END MAKE PROP', property);
                        // @ts-expect-error
                        schema.properties = {
                            ...schema.properties,
                            [curr.key]: property,
                        };
                        if (curr.type_slug) {
                            schema.type_properties = {
                                ...schema.type_properties,
                                [curr.type_slug]: property,
                            };
                        }
                        if (curr.partition) {
                            schema.partition = Array.from(
                                new Set<string>([...schema.partition, curr.key])
                            );
                        }
                        agg[curr.view] = schema;
                        return agg;
                    },
                    dashboardColumns.reduce<Record<string, Schema>>((acc, curr) => {
                        const arr: Schema = acc[curr.view] || {
                            properties: {},
                            type_properties: {},
                            partition: [],
                        };
                        if (isTypeReference(curr.type)) {
                            arr.properties[curr.key] = {
                                name: curr.key,
                                description: null,
                                type: {
                                    kind: 'enum',
                                    members: dataMembersByTypeId[curr.type.id],
                                },
                                constraints: [],
                                isDisabled: false,
                                disabledReason: null,
                            };
                            arr.type_properties[curr.type.id] = {
                                key: curr.key,
                                ...arr.properties[curr.key],
                            };
                        } else {
                            arr.properties[curr.key] = {
                                name: curr.key,
                                description: null,
                                // @ts-expect-error
                                type: apiTypeToPropertyType(curr.type),
                                constraints: [],
                            };
                        }
                        acc[curr.view] = arr;
                        return acc;
                    }, {})
                )
            ).map(([view, schema]) => ({
                view,
                schema,
            }));

            const preferencesTraits = preferences.sections.find(
                (candidate): candidate is TraitFilterSection =>
                    candidate.section_type === 'trait_filters'
            );

            const preferencesData = preferences.sections.find(
                (candidate): candidate is DataFilterSection =>
                    candidate.section_type === 'data_filters'
            );

            const traitRule = preferencesTraits?.value.rules ?? null;
            // TODO: Fix crash when no saved trait filter
            if (traitRule && traitRule.operator !== 'and') {
                throw new Error(`expected trait preference rule operator to be 'and'`);
            }

            const traitPreferencesByKey =
                traitRule?.sub_rules.filter(isSingle).reduce(
                    (acc, rule) => {
                        return { ...acc, [rule.metadata_definition_key]: rule };
                    },
                    {} as Record<string, MetadataRule | undefined>
                ) ?? {};

            const timePreferences = {
                filter: preferencesData?.value.date_range,
                granularity: preferencesData?.value.granularity,
            };

            // console.log('VIEW SCHEMAS', viewSchemas);

            // console.log('fetfetcing...', workspace.id, dashboard.slug);
            let members: Array<[DashDataFilter | DashTraitFilter, EnumMember[] | null]> =
                [];

            if (loadEnums) {
                // NOTE this try catch is a quick hack to fix
                // fetching dynamic members without dashboard access.
                // real solution is to make sure we don't fetch the dashboard configuration
                // until the dashboard is accessable
                try {
                    members = await Promise.all(
                        dashboard.filters
                            .filter(
                                (filter): filter is DashDataFilter | DashTraitFilter =>
                                    filter.kind == 'data' ||
                                    (filter.kind === 'trait' &&
                                        filter.value_type === 'string')
                            )
                            .map(
                                async (
                                    filter
                                ): Promise<
                                    [
                                        DashDataFilter | DashTraitFilter,
                                        EnumMember[] | null,
                                    ]
                                > => {
                                    const columnType =
                                        filter.kind === 'trait'
                                            ? filter.value_type
                                            : viewSchemas.reduce<Property | null>(
                                                  (agg, curr) => {
                                                      return (
                                                          curr.schema.type_properties[
                                                              filter.type_slug
                                                          ] || agg
                                                      );
                                                  },
                                                  null
                                              )?.type;

                                    if (!columnType) {
                                        throw new Error(
                                            `no column type found for filter '${JSON.stringify(
                                                filter
                                            )}'`
                                        );
                                    }

                                    // if (
                                    //     // TODO: fix column types to have enum and not string
                                    //     columnType !== 'string' //&&
                                    //     // (columnType != 'number'
                                    //     // && !isDomainReferenceType(columnType))
                                    //     // typeof column.type !== 'object' ||
                                    //     // column.type.kind !== 'enum'
                                    // ) {
                                    //     console.log(
                                    //         'NOT MAKING MEMBER QUERY',
                                    //         columnType,
                                    //         filter
                                    //     );
                                    //     return [filter, null];
                                    // }

                                    const members =
                                        dataMembersByTypeId[filter.type_slug] ?? [];
                                    if (!members) {
                                        console.warn(
                                            `no type members found for filter '${filter.name}/${filter.type_slug}'`
                                        );
                                    }

                                    return [
                                        filter,
                                        await fetchMembers(
                                            config.axios,
                                            filter.type_slug,
                                            workspace.id as number,
                                            dashboardId,
                                            {
                                                apiKey: config.overrides?.apiKey,
                                                organizationId:
                                                    config.overrides?.organizationId,
                                            }
                                        ),
                                    ];

                                    // return [filter, members];
                                }
                            )
                    );
                } catch (error) {
                    console.error(error);
                }
            }

            const membersByTypeSlug = members.reduce(
                (acc, [filter, members]) =>
                    members ? { ...acc, [filter.type_slug]: members } : acc,
                {}
            );

            // console.log('views', views);

            const referencedViewsIds = new Set(
                dashboard.cards.flatMap((card) =>
                    card.queries.flatMap((query) => query.source.view)
                )
            );
            const referencedViews = views.filter((item) =>
                referencedViewsIds.has(item.source)
            );

            const [firstView, ...restViews] = referencedViews as Array<
                ViewDto | undefined
            >;

            // console.log('referencedViewsIds', referencedViewsIds, firstView);

            const columnsInCommon = new Set(
                referencedViews.reduce(
                    (acc, view) =>
                        intersectionBy(
                            acc,
                            view.columns.map((column) => column.key)
                        ),
                    firstView?.columns.map((column) => column.key)
                )
            );

            // console.log('firstView', firstView, columnsInCommon);

            // console.log('DEBUG filteredViews', referencedViews, columnsInCommon);

            const cohortOptions = chain(firstView?.columns)
                .filter((item) =>
                    isNumeric(castTypeToDomain(item.type, { downgradeReference: true }))
                )
                .filter((item) => columnsInCommon.has(item.key))
                .map((item) => ({ value: item.key, label: item.name }))
                // .flatMap((view) =>
                //     view.columns.flatMap((item) =>
                //         CANDIDATES.includes(item.key) ? [{ id: item.key }] : []
                //     )
                // )
                // .groupBy((item) => item.id)
                // .map((items) => {
                //     return items;
                // })
                // .flatten()
                // .uniqBy((item) => item.id)
                // .filter((item) => columnsInCommon.has(item.value))
                .orderBy(
                    (item) =>
                        CANDIDATES.includes(item.value)
                            ? CANDIDATES.findIndex(
                                  (candidate) => candidate === item.value
                              )
                            : 100,
                    'asc'
                )
                .value();

            // const cohortOptions = chain(views)
            // .flatMap((view) =>
            //     view.columns.flatMap((item) =>
            //         CANDIDATES.includes(item.key) ? [{ id: item.key }] : []
            //     )
            // )
            // .groupBy((item) => item.id)
            // .map((items) => {
            //     return items;
            // })
            // .flatten()
            // .uniqBy((item) => item.id)
            // .filter((item) => columnsInCommon.has(item.id))
            // .orderBy(
            //     (item) => CANDIDATES.findIndex((candidate) => candidate === item.id),
            //     'asc'
            // )
            // .value();

            // console.log('dashboard', dashboard.name, cohortOptions);

            const hasCohortOptions = cohortOptions.length > 0;
            return {
                // @ts-expect-error
                views: referencedViews,
                cards: dashboard.cards.map<Card>((card) => {
                    const [query, ...rest] = card.queries;
                    if (rest.length > 0) {
                        throw new Error(`multi query cards not supported`);
                    }
                    return {
                        id: card.id.toString(),
                        title: card.title,
                        description: card.description ?? null,
                        query: castQueryToDomain([], query),
                        visualization: card.visualization,
                    };
                }),
                defaults: {
                    granularity: dashboard.default_date_granularity ?? 'week',
                    comparison: DEFAULT_COMPARISON,
                },
                viewSchemas,
                cohort:
                    dashboard.show_rank_by === false
                        ? null
                        : {
                              modes: (
                                  DASHBOARD_COHORT_MODES[dashboard.slug] ?? [
                                      'dynamic',
                                      'fixed',
                                  ]
                              ).filter((item) =>
                                  hasCohortOptions ? true : item !== 'fixed'
                              ),
                              options: cohortOptions,
                          },
                properties: {
                    timestamp:
                        (chain(dashboard.filters)
                            .filter(
                                (filter): filter is TimestampDashFilter =>
                                    filter.kind === 'timestamp'
                            )
                            .map<AnyFilter>(
                                toDomainFilter.bind(null, {
                                    dashboard,
                                    config,
                                    columns: columns.items,
                                    preferencesByKey: traitPreferencesByKey,
                                    timePreferences,
                                    membersByTypeSlug,
                                })
                            )
                            .filter(isDateFilter)
                            .first()
                            // .defaultTo(null)
                            .value() as DateFilter | undefined) ?? null,
                    traits: chain(dashboard.filters)
                        .filter(
                            (filter): filter is DashTraitFilter => filter.kind === 'trait'
                        )
                        .map<AnyFilter>(
                            toDomainFilter.bind(null, {
                                dashboard,
                                config,
                                columns: columns.items,
                                preferencesByKey: traitPreferencesByKey,
                                timePreferences,
                                membersByTypeSlug,
                            })
                        )
                        .orderBy(
                            (filter) => (isNumericType(filter.property.type) ? 1 : -1),
                            ['asc']
                        )
                        .value(),
                    data: chain(dashboard.filters)
                        .filter(
                            (filter): filter is DashDataFilter => filter.kind === 'data'
                        )
                        .map<AnyFilter>(
                            toDomainFilter.bind(null, {
                                dashboard,
                                config,
                                columns: columns.items,
                                preferencesByKey: traitPreferencesByKey,
                                timePreferences,
                                membersByTypeSlug,
                            })
                        )
                        .value(),
                },
            };
        },
    };
}
