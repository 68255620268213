import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { periodToRange } from '../../../../../domain';
import { ReportNewFormValues, ReportNewFormValuesSchema } from './form';
import { ReportNewFormProvider } from './reportNewInterface';

export function createReportNewFormProvider(): ReportNewFormProvider {
    const PERIOD = periodToRange(null, {
        interval: 'week',
        amount: 4,
    });
    return {
        useForm(context, data, options) {
            const form = useForm<ReportNewFormValues>({
                mode: 'onSubmit',
                resolver: zodResolver(ReportNewFormValuesSchema),
                defaultValues: {
                    period: PERIOD,
                    ...options?.defaultValues,
                },
            });
            return form;
        },
    };
}
