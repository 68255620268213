import { QueryMetadataFacet, Series } from '../../../api';
import { ApplicationContext } from '../../../base';
import { formatType, VisualizationContext } from '../../../domain';
import { Schema } from '../../../v2/domain/dashboard';

/**
 * Formats the name of a series according to its facets if any
 * @returns
 */
export function formatFacetSeriesLabel(
    context: Pick<ApplicationContext, 'formatter'>,
    metafacet: QueryMetadataFacet | null,
    schemaProperties: Schema['properties'],
    series: Series
) {
    if (!metafacet) {
        return series.name;
    }
    const facet = metafacet.terms.find((term) => term.name === series.name);
    const formatted = Object.entries(facet?.value ?? {}).reduce((acc, [key, value]) => {
        const property = schemaProperties[key];
        if (!property) {
            throw new Error(
                `facet property '${key}' not found in schema ${JSON.stringify(
                    schemaProperties
                )}`
            );
        }
        return { ...acc, [key]: formatType(context, property.type, value) };
    }, {} as Record<string, React.ReactNode | undefined>);

    console.log('DEBUG metafacet', metafacet);
    console.log('DEBUG facet', facet);
    console.log('DEBUG formatted', formatted);
    console.log('DEBUG metafacet.keys', metafacet.keys);

    const labels = metafacet.keys.flatMap((key) => {
        const formattedValue = formatted[key];
        if (!formattedValue) {
            console.warn(
                `facet key'${key}' not found in formatted values ${JSON.stringify(
                    formatted
                )}`
            );
            return [];
        }
        return [formattedValue];
    });

    return labels ? labels.join(' / ') : series.name;
}
