import { periodToRange } from '../../../../domain';
import {
    createOpportunityCollectionLoader,
    OpportunityCollectionConfig,
} from '../../../view';
import { HomeOpportunityLoader } from './homeOpportunityInterface';

export function createHomeOpportunityLoader(
    config: OpportunityCollectionConfig
): HomeOpportunityLoader {
    const loader = createOpportunityCollectionLoader(config);
    return {
        useData(context, props) {
            const period = periodToRange(null, props.period);
            const data = loader.useData(
                context,
                {
                    query: {
                        since: period.start,
                        before: period.end,
                        limit: 25,
                        orderBy: [
                            {
                                key: 'priority',
                                direction: 'desc',
                            },
                            {
                                key: 'period_end_at',
                                direction: 'desc',
                            },
                        ],
                        aggregate: { keys: ['definition', 'kind'], take: 1 },
                    },
                },
                {
                    // suspense: true,
                    staleTime: Infinity,
                }
            );
            return data;
        },
    };
}
