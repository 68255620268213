import { PluginSlugs } from '../../../config';
import { ViewSources } from '../../../config/view';

export const SourceIcons = {
    [ViewSources.FACEBOOK_DAILY_PERFORMANCE]: `https://cdn.varos.io/assets/common/facebook_logo.png`,
    [ViewSources.TIKTOK]: `https://cdn.varos.io/assets/common/TikTok-icon-glyph.png`,
    [ViewSources.GOOGLE]: `https://cdn.varos.io/assets/common/google_a2d2s.png`,
    [ViewSources.SHOPIFY_REVENUE_GROWTH]: `https://cdn.varos.io/assets/common/shopify_logo.png`,
    [ViewSources.SHOPIFY_ORDERS]: `https://cdn.varos.io/assets/common/shopify_logo.png`,
    [ViewSources.EXECUTIVE_SUMMARY]: `https://cdn.varos.io/assets/common/executive_summary_logo.png`,
};

export const PluginIcons: Record<string, string | undefined> = {
    [PluginSlugs.VAROSTEST]: `https://cdn.varos.io/assets/common/varos-icon.jpeg`,
    [PluginSlugs.FACEBOOKADS]: `https://cdn.varos.io/assets/common/facebook_logo.png`,
    [PluginSlugs.GOOGLEADS]: `https://cdn.varos.io/assets/common/google_a2d2s.png`,
    [PluginSlugs.TIKTOKADS]: `https://cdn.varos.io/assets/common/TikTok-icon-glyph.png`,
    [PluginSlugs.EXECUTIVE_SUMMARY]: `https://cdn.varos.io/assets/common/executive_summary_logo.png`,
    [PluginSlugs.GOOGLE_ANALYTICS]: `https://cdn.varos.io/assets/common/google_analytics_logo.svg`,
    [PluginSlugs.SHOPIFY]: `https://cdn.varos.io/assets/common/shopify_logo.png`,
    [PluginSlugs.LINKEDIN]: `https://cdn.varos.io/assets/common/Linkedin-logo.png`,
    [PluginSlugs.IT_BENCHMARKS]: `https://cdn.varos.io/assets/common/executive_summary_logo.png`,
};
