import { FeatureDto, FeatureId, PlanId } from '../../api';
import { DashboardSlugs } from '../dashboard';
import { DEFAULT_GRAPHICS, DEFAULT_HIGHLIGHTS } from './default';
import {
    EXECUTIVE_SUMMARY_GRAPHICS,
    EXECUTIVE_SUMMARY_HIGHLIGHTS,
} from './executive-summary';
import { KLAVIYO_GRAPHICS, KLAVIYO_HIGHLIGHTS } from './klaviyo';
import { MEDIAMIX_GRAPHICS, MEDIAMIX_HIGHLIGHTS } from './mediamix';

export interface FeatureFixture {
    text: string;
    caption?: string;
}

export interface FeatureHighlight {
    title?: string;
    subtitle?: string;
    features: FeatureFixture[];
    graphics: Array<{ src: string }>;
}

export const PLAN_DEFAULT_FEATURES = {
    free: [
        { text: 'Categories (e.g. Fashion)' },
        { text: 'Real-time market trends' },
        { text: '4 week data lookback' },
        { text: 'Multiple Integrations' },
    ],
    brand: [
        { text: "Sub-categories (e.g. Women's Footwear)" },
        { text: '3 year data lookback' },
        { text: 'Advanced filtering (e.g. by country)' },
        { text: `Recomendations based off high performers` },
    ],
    agency: [{ text: 'Multiple client accounts' }, { text: 'Dedicated support' }],
};

export const FEATURES_BY_PLAN: { [key in PlanId]: FeatureFixture[] } = {
    brands_free: [...PLAN_DEFAULT_FEATURES.free],
    free: [...PLAN_DEFAULT_FEATURES.free],
    brands: [...PLAN_DEFAULT_FEATURES.brand],
    brands_0_10k: [...PLAN_DEFAULT_FEATURES.brand],
    brands_10k_50k: [...PLAN_DEFAULT_FEATURES.brand],
    brands_50k_100k: [...PLAN_DEFAULT_FEATURES.brand],
    brands_100k_500k: [...PLAN_DEFAULT_FEATURES.brand],
    brands_500k_plus: [...PLAN_DEFAULT_FEATURES.brand],
    agencies_1_5: [...PLAN_DEFAULT_FEATURES.agency],
    agencies_6_15: [...PLAN_DEFAULT_FEATURES.agency],
    agencies_16_30: [...PLAN_DEFAULT_FEATURES.agency],
    agencies_31_50: [...PLAN_DEFAULT_FEATURES.agency],
    agencies_50_plus: [...PLAN_DEFAULT_FEATURES.agency],
    agencies: [...PLAN_DEFAULT_FEATURES.agency],
    agency_base: [
        { text: 'Multiple client accounts' },
        { text: 'Self-serve audit for new clients' },
        { text: 'Dedicated support' },
    ],
    agency_pro: [
        // { text: 'Multiple client accounts' },
        // { text: 'Self-serve audit for new clients' },
        // { text: 'Dedicated support' },
        {
            text: 'Google Sheets export',
        },
        {
            text: 'Top Creatives add-on',
        },
        {
            text: 'Branded monthly state of the market deck with Varos data',
        },
    ],
    enterprise: [
        { text: 'API integration' },
        { text: 'Monthly presentations' },
        { text: 'One custom data request per month' },
    ],
};

export const DASHBOARD_HIGHTLIGHT_BY_SLUG: {
    [key in string]?: FeatureHighlight;
} = {
    [DashboardSlugs.EXECUTIVE_SUMMARY]: {
        title: 'Executive Summary',
        subtitle: `Benchmark top-level business metrics`,
        features: EXECUTIVE_SUMMARY_HIGHLIGHTS,
        graphics: EXECUTIVE_SUMMARY_GRAPHICS,
    },
    [DashboardSlugs.MEDIA_MIX]: {
        subtitle: `Access your peers' budget allocation`,
        features: MEDIAMIX_HIGHLIGHTS,
        graphics: MEDIAMIX_GRAPHICS,
    },
    [DashboardSlugs.KLAVIYO_CAMPAIGNS]: {
        title: `Email Marketing Benchmarks`,
        subtitle: `Fully unlocked access to competitive data`,
        features: KLAVIYO_HIGHLIGHTS,
        graphics: KLAVIYO_GRAPHICS,
    },
    [DashboardSlugs.KLAVIYO_FLOWS]: {
        title: `Email Marketing Benchmarks`,
        subtitle: `Fully unlocked access to competitive data`,
        features: KLAVIYO_HIGHLIGHTS,
        graphics: KLAVIYO_GRAPHICS,
    },
};

export const DEFAULT_HIGHTLIGHT = (feature: FeatureDto): FeatureHighlight => {
    const mapping: {
        [TKey in FeatureId]?: {
            title?: string;
            subtitle: string;
            features?: FeatureFixture[];
        };
    } = {
        subcategories: {
            subtitle: `Unlock greater granularity with subcategory filters on premium plans`,
        },
        numeric_trait_filters: {
            subtitle: `Unlock greater granularity and more accurate benchmarks with peer group filters`,
        },
        data_destinations: {
            subtitle: `Unlock automated data exports to external systems like databases and online spreadsheets`,
        },
        data_filters: { subtitle: `Unlock access to data filters on our premium plans` },
        date_ranges: {
            subtitle:
                feature.id === 'date_ranges' && feature.config?.lookback_period_days
                    ? `Unlock access to 3 years of data on premium plans. Your current plan is limited to a ${feature.config.lookback_period_days}-day data look-back period`
                    : `Unlock access to 3 years of data on premium plans.`,
        },
        exports: { subtitle: `Unlock access to export data on premium plans. ` },
        multi_assets: {
            subtitle: 'Agencies can add up to 15 brands per account.',
            features: [
                {
                    text: 'Forecast and plan accurately with unlimited historical data',
                },
                {
                    text: 'More granular benchmarks with all data and peer group filters',
                },
                {
                    text: `Optimally allocate ad spend by seeing media mix breakdowns from other companies`,
                },
                {
                    text: `Collaborate with teammates and clients`,
                },
            ],
        },
    };

    const overriddes = mapping[feature.id];
    return {
        title: overriddes?.title ?? 'Premium Plans',
        subtitle: overriddes?.subtitle,
        features: overriddes?.features ?? DEFAULT_HIGHLIGHTS,
        graphics: DEFAULT_GRAPHICS,
    };
};
