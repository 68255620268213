import { useMemo } from 'react';
import { buildStudyResponseItemViewProps } from './responseItemFactory';
import { StudySubmissionItemControllerConfig } from './responseItemConfig';
import { StudyResponseItemController } from './responseItemInterface';

export function createStudyResponseItemController(
    config: Pick<StudySubmissionItemControllerConfig, 'infra'>
): StudyResponseItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const viewProps = useMemo(
                () => buildStudyResponseItemViewProps(formatter, context, item),
                [context, item]
            );
            return viewProps;
        },
    };
}
