import { startCase, toLower } from 'lodash';
import { VisualizationIntl } from '../../../strategy/visualization';
import { VisualizationIntlConfig } from './visualizationConfig';

export function createVisualizationIntl(
    config: VisualizationIntlConfig
): VisualizationIntl {
    function titleCase(value: string) {
        return startCase(toLower(value));
    }
    // this is a hack. instead the formatting should be based
    // in the view column definitions and dashboard context
    function format(key: string) {
        return key.includes('_') ? titleCase(key) : key.toUpperCase();
    }
    return {
        getDescription(segment, metric) {
            if (
                segment.kind === 'range' &&
                segment.reducer?.kind === 'percentile' &&
                segment.reducer?.value === 50
            ) {
                return `Median ${metric.name} for companies between ${
                    segment.range.start.value
                }th and ${segment.range.end.value}th percentile based on ${format(
                    segment.range.column
                )}`;
            }
            if (
                segment.kind === 'expression' &&
                segment.reducer?.kind === 'percentile' &&
                segment.reducer
            ) {
                return `${segment.reducer.value}th percentile ${metric.name}`;
            }
            if (
                segment.kind === 'expression' &&
                segment.reducer?.kind === 'average' &&
                segment.reducer
            ) {
                return `Average ${metric.name}`;
            }
            return null;
        },
    };
}
