import { assert } from '../../../../../../util/assert';
import { SettingsBillingLoaderConfig } from '../../base';
import { PaymentMethodEditLoader } from './paymentMethodEditInterface';

export function createPaymentMethodEditLoader(
    config: SettingsBillingLoaderConfig
): PaymentMethodEditLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const setupIntent = repository.setupIntent.useCreate(
                context,
                {},
                { suspense: true }
            );
            assert(setupIntent.status === 'success', 'expected suspense');
            return { setupIntent: setupIntent.data };
        },
    };
}
