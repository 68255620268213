import {
    useControlApi,
    useExportApi,
    useQueryApi,
    useDashboardApi,
} from '../../../app/dashboard';
import { ActionProps } from '../../../view/dashboard';

export interface ActionViewController {
    (): ActionProps;
}

export const useActionController: ActionViewController = () => {
    const api = {
        dashboard: useDashboardApi(),
        query: useQueryApi(),
        export: useExportApi(),
        control: useControlApi(),
    };
    const exports = api.export.useExport(api.dashboard.getDashboard());
    return {
        hasUnsavedFilters: api.control.isDirty(api.dashboard.getDateConfiguration()),
        onReset() {
            api.control.reset();
        },
        async onExport() {
            await exports.export({ download: true });
            return;
        },
        onSave() {
            return api.control.saveControls();
        },
    };
};
