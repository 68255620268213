import { AnyQuerySegment } from '../../../../api';
import { MovementValue, Property } from '../../attributes';
import { QueryRequest } from '../request';
import { QueryResponse } from '../response';
import { Calculation } from './calculationModel';

export function createCalculationFromResponse(props: {
    segment: AnyQuerySegment | null;
    property: Property;
    request: QueryRequest;
    response: QueryResponse;
    value: MovementValue;
}): Calculation {
    return {
        property: props.property,
        period: props.response.metadata.period,
        comparison: props.response.metadata.comparison,
        value: {
            current: props.value.base,
            previous: props.value.comparison,
            change: props.value.change,
        },
        segment: props.segment,
    };
}
