import React, { useState } from 'react';

export interface UseCollapseListConfig<TItem> {
    maxItems: number;
    items: TItem[];
    renderItem(item: TItem): React.ReactElement;
}

export interface CollapseListTriggerProps {
    onClick(): void;
}

export interface UsesCollapseListReturn<TItem> {
    isExpanded: boolean;
    hiddenCount: number;
    getVisible(): TItem[];
    getTriggerProps(): CollapseListTriggerProps;
}

export const useCollapseList = <TItem>(
    config: UseCollapseListConfig<TItem>
): UsesCollapseListReturn<TItem> => {
    const [expanded, setExpanded] = useState(false);
    return {
        isExpanded: expanded,
        hiddenCount: config.items.length - config.maxItems,
        getVisible() {
            return expanded ? config.items : config.items.slice(0, config.maxItems);
        },
        getTriggerProps() {
            return {
                onClick() {
                    return setExpanded(!expanded);
                },
            };
        },
    };
};
