import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingPaymentResource } from './paymentInterface';
import { BillingPaymentDtoSchema } from './paymentSchema';

export function createBillingPaymentResource(
    client: AxiosInstance
): BillingPaymentResource {
    return {
        async get(context) {
            throw new Error('not impl');
        },
        async create(context, payload, options) {
            throw new Error('not impl');
        },
    };
}
