import { FilterItemViewProps } from '../item';
import { FilterPopoverController, FilterPopoverDeps } from './filterPopoverInterface';
import { FilterPopoverViewProps } from './filterPopoverProps';

export function createFilterPopoverController(): FilterPopoverController {
    function buildViewProps(
        deps: FilterPopoverDeps,
        item: FilterItemViewProps
    ): FilterPopoverViewProps {
        return {
            item: {
                ...item,
                // @ts-expect-error
                input: {
                    ...item.input,
                    value: deps.state.value,
                    onChange: deps.state.onChange,
                },
            },
            popover: deps.disclosure,
            selection:
                item.input.kind === 'multi_select' && Array.isArray(deps.state.value)
                    ? {
                          label: `${deps.state.value.length} selected`,
                      }
                    : null,
            button: {
                trigger: {
                    children: item.value.label,
                },
                apply: {
                    onClick() {
                        item.input.onChange(
                            // @ts-expect-error
                            deps.state.value
                        );
                        deps.disclosure.onClose();
                    },
                },
                reset:
                    deps.state.value === null
                        ? null
                        : {
                              onClick(event) {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  deps.state.onChange(null);
                              },
                          },
                clear: {
                    onClick(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        deps.state.onChange(null);
                        item.input.onChange(
                            // @ts-expect-error
                            null
                        );
                    },
                },
            },
        };
    }
    return {
        useProps(deps, item) {
            const viewProps = buildViewProps(deps, item);
            return viewProps;
        },
    };
}
