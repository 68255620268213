import React from 'react';
import { useLocation } from 'react-router';

/**
 * Returns the current query parameters as an object.
 * Note, does not perform data validation or casting
 */
export const useQuery = <TValue>(): TValue => {
    const { search } = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);
    const entries = params.entries();
    const payload = Object.fromEntries(entries);
    return payload as any as TValue;
};
