import { Icons } from '../../../../config';
import { isCurrencyType, isPercentType } from '../../../domain/attributes';
import { MetricItemAggregate } from './metricItemModel';
import { MetricItemViewProps } from './metricItemProps';

export function buildMetricItemProps(item: MetricItemAggregate): MetricItemViewProps {
    return {
        id: item.definition.id,
        label: item.definition.name,
        description: item.definition.description,
        plugin: {
            label: item.plugin.name,
        },
        unit: buildUnitProps(item),
        isInverse: item.definition.inverse,
        image: {
            src: item.plugin.iconUrl ?? '',
        },
    };
}

function buildUnitProps(item: MetricItemAggregate): MetricItemViewProps['unit'] {
    if (isCurrencyType(item.definition.type)) {
        return {
            Icon: Icons.Attributes.Currency,
        };
    }
    if (isPercentType(item.definition.type)) {
        return {
            Icon: Icons.Attributes.Percentage,
        };
    }
    return null;
}
