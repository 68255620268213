import { AccountInvitationBulkProps } from '../../../../app/accounts';
import { CompanyEntity } from '../../../../app/companies';
import { keyByMapSafe, keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { Reference } from '../../../../base';
import { Asset } from '../../../../domain/assets';
import { PeerSetUpdateProps } from '../../../../app';
import { PeerSetServiceAggregate } from '../peersetModel';
import { PeerSetServiceUpdateProps } from '../peersetProps';

export function buildPeerSetUpdateProps(
    item: PeerSetServiceAggregate & {
        companies: CompanyEntity[];
    },
    props: PeerSetServiceUpdateProps
): PeerSetUpdateProps {
    const acc: PeerSetUpdateProps = {};

    if (props.name) {
        acc.name = props.name;
    }

    if (props.companies) {
        const itemById = keyByMapSafe(item.companies, (company) => {
            const asset: Reference<Asset> | null =
                company.profiles.flatMap((item) =>
                    item.kind === 'varos' ? [{ id: Number(item.id) }] : []
                )[0] ?? null;
            return [
                company.id,
                {
                    company,
                    asset,
                },
            ];
        });

        const accCompanies: Required<PeerSetUpdateProps>['members'] = [];
        for (const company of item.companies) {
            const item = itemById[company.id] ?? null;
            assert(item, `not company found`);
            if (!item.asset) {
                // this company is not registered, send invitation instead
                continue;
            }
            accCompanies.push({
                asset: item.asset.id,
            });
        }

        acc.members = accCompanies;
    }

    return acc;
}

export function buildPeerSetUpdateInvitationBulkProps(
    item: PeerSetServiceAggregate & {
        companies: CompanyEntity[];
    },
    props: PeerSetServiceUpdateProps
): AccountInvitationBulkProps {
    const { peerset } = item;
    const acc: AccountInvitationBulkProps = {
        items: [],
    };
    const itemById = keyByMapSafe(item.companies, (company) => {
        const asset: Reference<Asset> | null =
            company.profiles.flatMap((item) =>
                item.kind === 'varos' ? [{ id: Number(item.id) }] : []
            )[0] ?? null;
        return [
            company.id,
            {
                company,
                asset,
            },
        ];
    });
    const invitationsByCompany = keyBySafe(item.invitations, (item) => item.company.id);
    const initialInvitedCompanies = item.invitations.map((item) => item.company.id);

    const changes = {
        added: item.companies.filter(
            (company) => !initialInvitedCompanies.includes(company.id)
        ),
        removed: initialInvitedCompanies.filter(
            (id) => !item.companies.some((company) => company.id === id)
        ),
    };

    for (const id of changes.removed) {
        const invitation = invitationsByCompany[id] ?? null;
        if (invitation) {
            acc.items.push({
                kind: 'delete',
                entity: invitation,
            });
        }
    }

    for (const company of changes.added) {
        const item = itemById[company.id] ?? null;
        if (!item?.asset) {
            acc.items.push({
                kind: 'create',
                data: {
                    kind: 'company',
                    id: company.id,
                    source: { kind: 'group', id: peerset.id },
                },
            });
        }
    }

    return acc;
}
