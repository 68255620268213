import React from 'react';
import { UserIntegrationDefinition } from '../../../domain';
import { INTEGRATION_MODES } from '../../../views/integration/IntegrationListConstant';

export interface Group {
    title?: string;
    description?: React.ReactNode;
    definitions: UserIntegrationDefinition[];
}

export const useIntegrationCardList = (config: { definitions: UserIntegrationDefinition[] }): { groups: Group[] } => {
    return {
        groups: Object.entries(INTEGRATION_MODES)
            .map<Group>(([mode, value]) => ({
                title: value?.title,
                description: value?.description,
                definitions: config.definitions.filter((definition) => definition.mode === mode).sort((x, y) => x.ordinal - y.ordinal),
            }))
            .filter((group) => group.definitions.length > 0),
    };
};
