import { ButtonProps } from '@chakra-ui/react';
import React from 'react';

export interface OrganizationLayoutItemProps extends Pick<ButtonProps, 'leftIcon'> {
    children: React.ReactElement;
}

export const OrganizationLayoutItem: React.FC<
    { children?: React.ReactNode | undefined } & OrganizationLayoutItemProps
> = (props) => <>{props.children}</>;
