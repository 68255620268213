import { z } from 'zod';

export const OnboardingOrganizationRouteSearchParams = z.object({
    step: z.optional(z.string()),
    source_path: z.optional(z.string()),
    cancel_path: z.optional(z.string()),
    success_path: z.optional(z.string()),
    required: z.optional(
        z
            .string()
            .toLowerCase()
            .transform((value) => JSON.parse(value))
            .pipe(z.boolean())
    ),
});

export const OnboardingAssetRouteSearchParams = z.object({
    step: z.optional(z.string()),
    source_path: z.optional(z.string()),
    cancel_path: z.optional(z.string()),
    success_path: z.optional(z.string()),
    required: z.optional(
        z
            .string()
            .toLowerCase()
            .transform((value) => JSON.parse(value))
            .pipe(z.boolean())
    ),
});

export const OnboardingAssetRouteParamsSchema = z.object({
    workspaceId: z.number({ coerce: true }),
});
