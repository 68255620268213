import React from 'react';
import { chain } from 'lodash';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import { MdBolt } from 'react-icons/md';
import { TreeInputOption } from '../../ui/application/impl/forms';
import { useRecommendationApi } from '../../app';
import { DashboardRouteEnhancer } from '../../entrypoint';

const TOOLTIP_LABEL = `This vertical was automatically suggested based on your brand profile`;

/**
 * Dashboard middleware that add suggested values to competitive set properties
 * @returns
 */
export function createDashboardRecommendationMiddleware(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useSegmentItem(...args) {
                    const [, filter] = args;
                    const api = useRecommendationApi();
                    const recommendations = api.useRecommendations(filter.property);
                    const mapped = config.controller.useSegmentItem(...args);
                    return {
                        ...mapped,
                        getInputProps(props) {
                            if (props.type === 'tree') {
                                const optionsRecommended: TreeInputOption[] = chain(
                                    props.options
                                )
                                    .flatMap(({ children, ...option }) => children)
                                    .filter((option) =>
                                        recommendations.some(
                                            (recommendation) =>
                                                recommendation.object.id === option.value
                                        )
                                    )
                                    .map((option): TreeInputOption => {
                                        return {
                                            children: [],
                                            ...option,
                                            icons: [
                                                <Tooltip
                                                    label={
                                                        <Box p={2}>{TOOLTIP_LABEL}</Box>
                                                    }
                                                    placement="top"
                                                >
                                                    <span>
                                                        <Icon
                                                            as={MdBolt}
                                                            color="whiteAlpha.700"
                                                        />
                                                    </span>
                                                </Tooltip>,
                                                ...(option.icons ?? []),
                                            ],
                                        };
                                    })
                                    .unionBy((option) => option.value)
                                    .value();

                                return mapped.getInputProps({
                                    ...props,
                                    options: [...optionsRecommended, ...props.options],
                                });
                            }
                            return props;
                        },
                    };
                },
            },
        });
    };
}
