import React, { Suspense } from 'react';
import { Box } from '@chakra-ui/react';
import Integrations from '../../../sections/dashboard/Integrations/Integrations';

export interface IntegrationRouteConfig {}

export function createIntegrationRoute({}: IntegrationRouteConfig) {
    const IntegrationRoute: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    > = (props) => {
        return (
            <Box p={4}>
                <Integrations mandatoryOnly={null} />
            </Box>
        );
    };
    return IntegrationRoute;
}
