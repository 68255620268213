import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AssistantContainerBaseConfig } from '../../base';
import { AssistantUrlParamSchema } from '../../assistantSchema';
import { ThreadNewController, ThreadNewLoader } from './threadNewInterface';
import { ThreadNewViewProps } from './threadNewProps';
import { ThreadNewUrlParamSchema } from './threadNewSchema';

export function createThreadNewContainer(
    config: AssistantContainerBaseConfig,
    loader: ThreadNewLoader,
    controller: ThreadNewController,
    View: React.FC<{ children?: React.ReactNode | undefined } & ThreadNewViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
                toaster: { useToast },
            },
        },
        repository: {
            thread: threadRepository,
            run: runRepository,
            message: messageRepository,
        },
        context: {
            root: { useContext },
        },
    } = config;
    return (containerProps) => {
        const navigate = useNavigate();
        const location = useLocation();
        const toast = useToast();
        const [searchParams] = useSearchParams();
        const options = useOptions(ThreadNewUrlParamSchema);
        const context = useContext();
        const prefetch = {
            message: messageRepository.usePrefetch(context),
        };
        const mutation = {
            thread: threadRepository.useCreate(context),
            run: runRepository.useCreate(context),
        };
        const data = loader.useLoad(context, {
            workflow: {
                id: options.thread.type,
            },
        });
        const props = controller.useProps(context, data, {
            location,
            form: {
                id: 'assistant_thread_create_form',
                form: useForm({
                    defaultValues: {},
                }),
                async onSubmit() {
                    const thread = await mutation.thread.mutateAsync(
                        // @ts-expect-error
                        options.thread
                    );
                    await prefetch.message.load(
                        {
                            thread,
                        },
                        {}
                    );
                    const path = `../${thread.id}?${searchParams.toString()}`;
                    navigate(path, { replace: true });
                },
                onSubmitError(error) {
                    toast({
                        kind: 'error',
                        description: error.message,
                    });
                },
            },
        });
        return <View {...props} />;
    };
}
