import { AxiosInstance, AxiosResponse } from 'axios';
import { IntegrationHistoryDto, IntegrationEventType } from './historyDto';
import qs from 'qs';

interface IntegHistoryServerDto extends Omit<IntegrationHistoryDto, 'created_at'> {
    created_at: string;
}
export const listIntegrationHistory = async (
    api: AxiosInstance,
    integId: number,
    eventTypes?: IntegrationEventType[],
    page: number = 1,
    pageSize: number = 100
): Promise<IntegrationHistoryDto[]> => {
    const response = await api.get<
        unknown,
        AxiosResponse<{ items: IntegHistoryServerDto[]; count: number; page: number }>
    >(`/api/v1/integrations/${integId}/history`, {
        params: {
            ...(eventTypes && eventTypes.length > 0 ? { event_types: eventTypes } : {}),
            page,
            page_size: pageSize,
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
    return response.data.items.map(({ created_at, ...rest }) => ({
        created_at: new Date(created_at),
        ...rest,
    }));
};
