import React from 'react';
import { UrlOptionParser } from '../../../../base';
import { StudiesContextProvider } from '../../base';
import {
    StudiesSubmissionDetailController,
    StudiesSubmissionDetailLoader,
} from './studiesSubmissionDetailInterface';
import { StudiesSubmissionDetailViewProps } from './studiesSubmissionDetailProps';
import { StudiesSubmissionDetailPathParamsSchema } from './studiesSubmissionDetailSchema';

export function createStudiesSubmissionDetailRoute(
    options: UrlOptionParser,
    context: StudiesContextProvider,
    controller: StudiesSubmissionDetailController,
    loader: StudiesSubmissionDetailLoader,
    View: React.FC<
        { children?: React.ReactNode | undefined } & StudiesSubmissionDetailViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const viewContext = context.useContext();
        const viewParams = options.useOptions(StudiesSubmissionDetailPathParamsSchema);
        const viewData = loader.useLoad(viewContext, {
            submission: { id: viewParams.submissionId },
        });
        const viewProps = controller.useProps(viewContext, viewData.data);
        return <View {...viewProps} />;
    };
}
