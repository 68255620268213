import { DestinationDefinition } from '../../../domain/assets';
import { DestinationDefinitionAdapter } from '../../../app/assets';
import { DestinationDefinitionImplConfig } from './destinationImplConfig';

export function createDestinationDefinitionImpl(
    config: DestinationDefinitionImplConfig
): DestinationDefinitionAdapter {
    const {
        config: { items },
    } = config;
    return {
        async find(context, query) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            return {
                total: items.length,
                items: items,
                limit: 1000,
            };
        },
    };
}
