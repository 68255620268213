import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Route, Outlet, useSearchParams } from 'react-router-dom';
import { createSystemContextLoader } from '../../context';
import { NotEnoughDataError } from '../../../api';
import { ViewPartitionKeyBySlug } from '../../../config/view';
import { EmailEntrypointConfig } from './emailEntrypointConfig';
import { EmailEntryEnhancer } from './emailEntrypointMiddleware';

export function createEmailEntrypoint(
    config: EmailEntrypointConfig,
    enhancer?: EmailEntryEnhancer
) {
    if (enhancer) {
        return enhancer(createEmailEntrypoint)(config);
    }

    const infra = {
        http: config.infra.createHttp(),
        axios: config.infra.createAxios(),
        errorFormatter: config.infra.createErrorFormatter(),
    };

    const api = {
        insight: config.api.createInsightApi({
            client: infra.axios,
        }),
        dashboard: config.api.createDashboardApi({
            client: infra.axios,
        }),
    };
    const authProvider = config.provider.createAuth();
    const provider = {
        Auth: authProvider,
        Organization: config.provider.createOrganization(),
        Workspace: config.provider.createWorkspace(),
        System: config.provider.createSystem({
            deps: {
                axios: infra.axios,
                api,
                hook: { useAuth: authProvider.useContext },
            },
            provider: {
                createLoader: createSystemContextLoader,
            },
        }),
    };

    const adapter = {
        workspace: config.adapter.report.createWorkspaceAdapter({
            hooks: {
                useHttp() {
                    return infra.axios;
                },
                useAuth: provider.Auth.useContext,
                useOrganization: provider.Organization.useContext,
            },
        }),
        query: config.adapter.report.createQueryAdapter({
            partitionKeyByView: ViewPartitionKeyBySlug,
            hooks: {
                useHttp() {
                    return infra.axios;
                },
                useAuth: provider.Auth.useContext,
                useWorkspace: provider.Workspace.useContext,
            },
        }),
    };

    const strategy = {
        visualizable: config.strategy.insights.createVisualizable({}),
    };

    const repository = {
        opportunity: config.repository.insights.createOpportunities(
            config.adapter.insights.createOpportunities({
                api: api.insight,
            })
        ),
        plugin: config.repository.platform.createPlugins(
            config.adapter.platform.createPlugins({
                api: api,
            })
        ),
    };

    const service = {
        visualization: config.service.createVisualizationService({
            infra,
        }),
        chart: config.service.createChartService({}),
        query: config.service.createQueryService({
            adapter,
        }),
        opportunity: config.service.createOpportunityService({
            opportunity: repository.opportunity,
            plugin: repository.plugin,
        }),
        report: config.service.createReportService({
            config: {
                reports: config.config.reports,
            },
            infra,
            adapter: {
                status: config.adapter.report.useDependencyAdapter(),
                segment: config.adapter.report.useSegmentAdapter(),
            },
            repository: {
                plugins: repository.plugin,
                opportunities: repository.opportunity,
            },
        }),
        workspace: config.service.createWorkspaceService({
            adapter: {
                workspace: adapter.workspace,
            },
        }),
    };

    const UI = config.component.base.createUI();

    const controller = {
        insight: config.controller.createInsightController({
            ui: UI,
            api: {
                insight: api.insight,
            },
            hook: {
                useQuery,
            },
            service,
        }),
        report: config.controller.createReportController({
            service,
            strategy,
        }),
        visualization: config.controller.createVisualizationController({
            service,
            getProps({ visualization }) {
                return { data: visualization.data, columns: visualization.columns };
            },
        }),
    };

    const Component = {
        Table: config.component.report.createTableVisualization({ components: UI }),
    };

    const View = {
        EmailReportView: config.view.createEmailReportView({
            ui: UI,
            strategy,
            service,
            component: {
                Chart: {
                    Table(props) {
                        const visualization = controller.visualization.useProps({
                            metrics: props.metrics,
                            segments: props.segments,
                            date: props.control.date,
                        });
                        // throw new NotEnoughDataError();
                        return (
                            <Component.Table
                                className={props.className}
                                columns={visualization.columns}
                                data={visualization.data}
                            />
                        );
                    },
                },
            },
        }),
        EmailInsightView: config.view.createEmailInsightView({
            ui: UI,
            api: {
                insight: api.insight,
            },
            hook: {
                useQuery,
            },
            service,
        }),
    };

    const Routes = {
        Report: config.route.createReportRoute({
            View: config.container.createReportContainer({
                hook: {
                    useOrganization: provider.Organization.useContext,
                    useAuth: provider.Auth.useContext,
                },
                controller: controller.report,
                View: View.EmailReportView,
            }),
            hook: {
                useQueryParams(schema) {
                    const [raw] = useSearchParams();
                    const mapped = [...raw.entries()].reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value }),
                        {} as Record<string, string>
                    );
                    const parsed = schema.parse(mapped);
                    return parsed;
                },
            },
            provider,
        }),
        Insight: config.route.createInsightRoute({
            View: config.container.createInsightContainer({
                hook: {
                    useOrganization: provider.Organization.useContext,
                    useAuth: provider.Auth.useContext,
                },
                service: {
                    opportunity: service.opportunity,
                },
                controller: controller.insight,
                View: View.EmailInsightView,
            }),
            hook: {
                useQueryParams(schema) {
                    const [raw] = useSearchParams();
                    const mapped = [...raw.entries()].reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value }),
                        {} as Record<string, string>
                    );
                    const parsed = schema.parse(mapped);
                    return parsed;
                },
            },
            provider,
        }),
    };
    return (
        <Route
            element={
                <React.Suspense fallback={'loading...'}>
                    <Outlet />
                </React.Suspense>
            }
        >
            <Route path="insight" element={<Routes.Insight />} />
            <Route path="reports">
                <Route path="summary" element={<Routes.Report />}></Route>
            </Route>
        </Route>
    );
}
