import { useMemo } from 'react';
import { DataFilterAccessRequest } from 'src/base';
import { FilterController } from '../../../../route';
import { InFilter } from '../../../../domain/attributes';
import { FilterForm } from '../../../../service/query';
import { FeatureMiddleware } from '../../featureInterface';

export function createFilterFeatureMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceForm(form: FilterForm): FilterForm {
            return {
                ...form,
                useFormState(props) {
                    const formState = form.useFormState(props);
                    const features = api.useFeature('data_filters');
                    const filters = formState.getFilters();
                    const mapped: InFilter[] = useMemo(
                        () =>
                            features.feature?.enabled
                                ? filters
                                : filters.map((item) => ({
                                      ...item,
                                      status: 'disabled',
                                      statusReason: 'Upgrade to use data filters',
                                  })),
                        [filters, features.feature?.enabled]
                    );
                    return {
                        ...formState,
                        getFilters() {
                            return mapped;
                        },
                    };
                },
            };
        }

        function enhanceController(controller: FilterController): FilterController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const { feature, restrict } = api.useFeature('data_filters');
                    if (!feature || feature.enabled) {
                        return viewProps;
                    }
                    return {
                        ...viewProps,
                        getItemProps(...args) {
                            const itemProps = viewProps.getItemProps(...args);
                            return {
                                ...itemProps,
                                getTriggerProps() {
                                    const triggerProps = itemProps.getTriggerProps();
                                    return {
                                        ...triggerProps,
                                        allowClickWhenDisabled: true,
                                        onClick() {
                                            restrict<DataFilterAccessRequest>({
                                                ...feature,
                                                value: null,
                                            });
                                        },
                                    };
                                },
                            };
                        },
                    };
                },
            };
        }

        return create({
            ...config,
            controller: {
                ...config.controller,
                analysis: {
                    ...config.controller.analysis,
                    createFilter(...args) {
                        const controller = config.controller.analysis.createFilter(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
            form: {
                ...config.form,
                query: {
                    ...config.form.query,
                    createFilter() {
                        const form = config.form.query.createFilter();
                        return enhanceForm(form);
                    },
                },
            },
        });
    };
}
