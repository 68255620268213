import React from 'react';
import { Select } from 'chakra-react-select';
import { SingleValueProps, MultiValueProps } from 'chakra-react-select';

export interface Option {
    label: string;
    value: string;
}

export interface MultiSelectInputProps extends Omit<MultiValueProps, 'isMulti'> {
    value: Option[];
    type: 'select';
    isMulti: true;
    onChange(options: Option[]): void;
}

export interface SingleSelectInputProps extends Omit<SingleValueProps, 'onChange'> {
    value: Option | undefined;
    type: 'select';
    isMulti: false;
    onChange(options: Option): void;
}

export type SelectInputProps = MultiSelectInputProps | SingleSelectInputProps;

export const SelectInput: React.FC<SelectInputProps> = (props) => {
    if (props.isMulti) {
        return (
            <Select
                isMulti={true}
                value={props.value}
                options={props.options}
                onChange={(values) =>
                    props.onChange(
                        // @ts-expect-error
                        values
                    )
                }
            />
        );
    }

    return (
        <Select
            isMulti={false}
            value={props.value}
            options={props.options}
            onChange={(value) =>
                props.onChange(
                    // @ts-expect-error
                    value
                )
            }
        />
    );
};
