import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { LinkOverridesEnhancerConfig } from './linkOverrideConfig';
import { createInsightLinkOverrideEnhancer } from './insights';

export function createLinkOverridesEnhancer(
    init: LinkOverridesEnhancerConfig
): ApplicationEntryEnhancer {
    return compose();
    // createInsightLinkOverrideEnhancer(init.insight)
}
