import {
    InputMultiSelectControllerPropsV2,
    InputMultiSelectViewPropsV2,
} from './inputMultiSelectProps';

export function buildMultiSelectViewProps(
    props: InputMultiSelectControllerPropsV2
): InputMultiSelectViewPropsV2 {
    return {
        options: props.options,
        value: props.value ?? [],
        onChange: props.onChange,
    };
}
