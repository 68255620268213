import { Dashboard, Metric } from '../../../../domain/demo';

export const AD_METRICS: Metric[] = [
    {
        id: 'cpm',
        name: 'CPM',
    },
    {
        id: 'ctr',
        name: 'CTR',
    },
    {
        id: 'cpc',
        name: 'CPC',
    },
    {
        id: 'cvr',
        name: 'CVR',
    },
    {
        id: 'roas',
        name: 'ROAS',
    },
    {
        id: 'spend',
        name: 'Spend',
    },
];

export const DEFAULT_SEGMENT: Dashboard['query']['segments'] = [
    {
        kind: 'expression',
        name: 'Low performers',
        expr: {
            kind: 'boolean',
            value: true,
        },
        reducer: {
            kind: 'percentile',
            value: 25,
        },
    },
    {
        kind: 'expression',
        name: 'Median',
        expr: {
            kind: 'boolean',
            value: true,
        },
        reducer: {
            kind: 'percentile',
            value: 50,
        },
    },
    {
        kind: 'expression',
        name: 'High performers',
        expr: {
            kind: 'boolean',
            value: true,
        },
        reducer: {
            kind: 'percentile',
            value: 75,
        },
    },
];
