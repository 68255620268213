import { Button, Box, ButtonGroup, Text, Icon, Tooltip, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import React from 'react';
import { OnboardingOrganizationRouteStrategy } from '../../../route';
import { OnboardingAssetConfig } from './onboardingAssetConfig';
import { createOnboardingAssetNewView } from './onboardingAssetView';
import { createAssetOnboardingLoader } from './onboardingAssetLoader';
import { OnboardingAssetDeps } from './onboardingAssetInterface';

export function createOnboardingAssetItem(
    init: OnboardingAssetConfig
): OnboardingOrganizationRouteStrategy {
    const { hooks } = init;
    const loader = createAssetOnboardingLoader(init);
    const Component = createOnboardingAssetNewView();
    const controller = init.provider.createController(init);
    return {
        id: 'asset',
        name: 'Create asset',
        slug: 'asset',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const organization = hooks.useOrganization();
                    const auth = hooks.useAuth();
                    const context = { organization, auth };

                    const data = loader.useLoad(context);

                    const form = init.form.new.useForm(context, data);

                    const viewDeps: OnboardingAssetDeps = {
                        new: {
                            form,
                            create: data.create,
                            update: data.update,
                        },
                    };

                    const viewProps = controller.useProps(context, viewDeps, {
                        item: data.item,
                    });
                    const formProps = viewProps.getFormProps();

                    return (
                        <Container
                            title="Set up your first company"
                            description={
                                <VStack align="start">
                                    <Text>
                                        Companies in Varos are compared separately, e.g.
                                        if you have two e-commerce brands you will need to
                                        add two companies to compare each one separately.
                                    </Text>
                                    <Text>
                                        You will be able to add additional companies at a
                                        later step.
                                    </Text>
                                </VStack>
                            }
                            rightContent={
                                <ButtonGroup>
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        label={<Box p={2}>{formProps.submitLabel}</Box>}
                                        isDisabled={formProps.isSubmittable}
                                    >
                                        <span>
                                            <Button
                                                colorScheme="green"
                                                onClick={form.handleSubmit(
                                                    formProps.onSubmit
                                                )}
                                                isDisabled={!formProps.isSubmittable}
                                                isLoading={formProps.isLoading}
                                                rightIcon={
                                                    <Icon as={AiOutlineArrowRight} />
                                                }
                                            >
                                                Continue
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </ButtonGroup>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps.getViewProps()} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
