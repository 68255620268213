import { PageIds } from '../../../config';
import { GroupFormViewController } from '../../../view';
import { PermissionMiddleware } from '../permissionInterface';

export function createPermissionGroupsMiddleware(): PermissionMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(
            controller: GroupFormViewController
        ): GroupFormViewController {
            return {
                ...controller,
                useProps(...args) {
                    const [context] = args;

                    const {
                        data: { roles },
                    } = instance.hook.useOrganizationContext();

                    const permissionProps = api.controller.useProps(context, {
                        page: PageIds.SETTINGS_ACCOUNT_PRIVACY,
                        item: {
                            roles,
                        },
                    });

                    const viewProps = controller.useProps(...args);

                    if (permissionProps.access === 'full') {
                        return viewProps;
                    }

                    return { ...viewProps, isDisabled: true };
                },
            };
        }
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                groups: {
                    ...config.controller.groups,
                    group: {
                        ...config.controller.groups.group,
                        createSettings(...args) {
                            const controller =
                                config.controller.groups.group.createSettings(...args);
                            return enhanceController(controller);
                        },
                    },
                },
            },
        });

        return instance;
    };
}
