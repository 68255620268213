import { z } from 'zod';

export const SurveyDetailUrlParamSchema = z
    .object({
        surveyId: z.string(),
    })
    .transform((item) => ({
        survey: { id: item.surveyId },
    }))
    .pipe(
        z.object({
            survey: z.object({
                id: z.string(),
            }),
        })
    );
