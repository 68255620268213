import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Heading,
    HStack,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    StackDivider,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { EditOverlayConfig } from './editOverlayConfig';
import { EditOverlayViewProps } from './editOverlayProps';

export function createEditOverlayView<TItem extends object, TValues extends object>(
    config: EditOverlayConfig<TItem, TValues>
): React.FC<{ children?: React.ReactNode | undefined } & EditOverlayViewProps<TValues>> {
    return (props) => {
        if (props.variant === 'modal') {
            return (
                <Modal {...props.modal} isCentered={true}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton mt={0.5} _focus={{ outline: 'none' }} />
                        <ModalHeader p={0}>
                            <VStack p={4} align="start" w="full" h="full">
                                <Heading fontSize="md" fontWeight="md">
                                    {props.page.getHeaderProps().title ?? 'Edit'}
                                </Heading>
                            </VStack>
                        </ModalHeader>
                        <ModalBody p={0}>
                            <form
                                style={{ margin: 0 }}
                                {...props.form.getFormElementProps()}
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    spacing={0}
                                    divider={
                                        <StackDivider borderColor="whiteAlpha.300" />
                                    }
                                >
                                    <VStack
                                        p={4}
                                        w="full"
                                        align="start"
                                        spacing={3}
                                        pb={8}
                                    >
                                        {props.form.error && (
                                            <Alert {...props.form.error}>
                                                <AlertIcon />
                                                <AlertTitle>
                                                    {props.form.error.label}
                                                </AlertTitle>
                                            </Alert>
                                        )}
                                        {props.children}
                                    </VStack>
                                    <HStack p={4} w="full" h="full" justify="end">
                                        <Button
                                            {...props.form.getSubmitButtonProps()}
                                            colorScheme="green"
                                            size="sm"
                                            _focus={{ outline: 'none' }}
                                        />
                                    </HStack>
                                </VStack>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            );
        }
        return <>not implemented</>;
    };
}
