import { ViewPartitionKeyBySlug } from '../../config/view';
import { DashboardSlugs } from '../../config/dashboard';
import { createQueryBuilder } from '../../infra';

export const IGNORE_EXPORT_DASHBOARDS = [
    DashboardSlugs.MEDIA_MIX,
    DashboardSlugs.SHOPIFY_LTV,
    DashboardSlugs.SHOPIFY_LTV_V2,
    DashboardSlugs.LINKEDIN_ADS,
    DashboardSlugs.LINKEDIN_ADS_V2,
    DashboardSlugs.STRIPE,
    DashboardSlugs.FACEBOOK_CPA_V2,
    DashboardSlugs.FACEBOOK_CPA_V2,
    DashboardSlugs.FACEBOOK_BREAKDOWN,
    DashboardSlugs.IT_BENCHMARKS_PERFORMANCE,
    DashboardSlugs.IT_BENCHMARKS_SPEND_DIST,
];

export const QUERYBUILDER = createQueryBuilder({
    ignoreDashboards: IGNORE_EXPORT_DASHBOARDS,
    partitionKeyByView: ViewPartitionKeyBySlug,
});
