import { AxiosInstance } from 'axios';
import { Export, CreateExportPayload } from '../models/Export';

export const createExport = async (
    api: AxiosInstance,
    payload: CreateExportPayload
): Promise<Export> => {
    const response = await api.post<any>(`/api/v1/exports`, payload);
    let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        disposition = response.headers['content-disposition'];

    if (!disposition) {
        throw new Error(
            "missing expected header 'content-disposition' on export response"
        );
    }

    const { filename } = parseContentDisposition(disposition);

    if (!filename) {
        throw new Error("missing expected filename in 'content-disposition'");
    }

    return { filename, downloadUrl };
};

function parseContentDisposition(disposition: string) {
    let filename: string | null = null;
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
    }
    return { filename };
}
