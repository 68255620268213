import { EmailEntryEnhancer } from '../../../entrypoint';
import { EmailTrackingMiddlewareConfig } from './emailTrackingConfig';
import { addQueryParamsToHref } from './emailTrackingUtil';

/**
 * Adds tracking URL parameters to all hrefs
 * @param init
 * @returns
 */
export function createTrackingMiddleware(
    init: EmailTrackingMiddlewareConfig
): EmailEntryEnhancer {
    const { parameters } = init;
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                createInsightController(controllerConfig) {
                    const controller =
                        config.controller.createInsightController(controllerConfig);
                    return {
                        useProps(...args) {
                            const original = controller.useProps(...args);

                            const baseParams = {
                                ...init.inject.query,
                                [parameters.sourceName]: 'insight_notification',
                            };

                            return {
                                ...original,
                                getCtaProps() {
                                    const base = original.getCtaProps();
                                    return {
                                        ...base,
                                        href: addQueryParamsToHref(base.href, {
                                            ...baseParams,
                                            [parameters.sourceTrigger]:
                                                'view_insights_button',
                                        }),
                                    };
                                },
                                notificationSettingsHref: addQueryParamsToHref(
                                    original.notificationSettingsHref,
                                    {
                                        ...baseParams,
                                        [parameters.sourceTrigger]:
                                            'manage_notifications_button',
                                    }
                                ),
                            };
                        },
                    };
                },
                createReportController(controllerConfig) {
                    const controller =
                        config.controller.createReportController(controllerConfig);
                    return {
                        useProps(context, definition, payload) {
                            const original = controller.useProps(
                                context,
                                definition,
                                payload
                            );
                            const baseParams = {
                                ...init.inject.query,
                                [parameters.sourceName]: 'scheduled_report',
                            };
                            return {
                                ...original,
                                getInsightProps(...args) {
                                    const [item] = args;
                                    const base = original.getInsightProps(...args);
                                    return {
                                        ...base,
                                        path: addQueryParamsToHref(base.path, {
                                            ...baseParams,
                                            [parameters.sourceTrigger]:
                                                'insight_details_button',
                                            [parameters.insightId]: item.insight.id,
                                        }),
                                    };
                                },
                                insightButtonHref: addQueryParamsToHref(
                                    original.insightButtonHref,
                                    {
                                        ...baseParams,
                                        [parameters.sourceTrigger]:
                                            'view_insights_button',
                                    }
                                ),
                                reportHref: addQueryParamsToHref(original.reportHref, {
                                    ...baseParams,
                                    [parameters.sourceTrigger]: 'dive_deeper_button',
                                }),
                                emptyStateHref: addQueryParamsToHref(
                                    original.emptyStateHref,
                                    {
                                        ...baseParams,
                                        [parameters.sourceTrigger]: 'empty_state',
                                    }
                                ),
                            };
                        },
                    };
                },
            },
        });
    };
}
