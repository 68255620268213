import React from 'react';
import { Input, InputProps, InputGroup, FormLabel } from '@chakra-ui/react';
import FormCtrl from './FormCtrl';

export interface FormCtrlInputProps extends InputProps {
    label: string;
    helperText?: string;
    err?: string;
}

export interface FormCtrlInputGroupProps extends FormCtrlInputProps {
    pre?: React.ReactNode;
    post?: React.ReactNode;
}

const CtrlInput = React.forwardRef<HTMLInputElement, FormCtrlInputProps>(
    ({ label, helperText, err, children, ...rest }, ref) => {
        return (
            <FormCtrl {...{ helperText, err }}>
                <FormLabel color="gray.700">{label}</FormLabel>
                <Input ref={ref} {...rest} _placeholder={{ color: 'gray.400' }} />
            </FormCtrl>
        );
    }
);

export const CtrlGroupInput = React.forwardRef<HTMLInputElement, FormCtrlInputGroupProps>(
    ({ pre, post, label, helperText, err, children, ...rest }, ref) => {
        return (
            <FormCtrl {...{ label, helperText, err }}>
                <InputGroup>
                    {pre}
                    <Input ref={ref} {...rest} />
                    {post}
                </InputGroup>
            </FormCtrl>
        );
    }
);

export default CtrlInput;
