import { useMemo, useState } from 'react';
import { StudiesResponseDetailStateProvider } from './studiesResponseDetailInterface';
import { StudiesResponseDetailStateValues } from './studiesResponseDetailModel';

export function createStudiesResponseDetailStateProvider(): StudiesResponseDetailStateProvider {
    return {
        useState() {
            const [state, setState] = useState<StudiesResponseDetailStateValues>({
                date: {
                    interval: 'week',
                    amount: 4,
                },
                segment: 'all',
                mode: 'absolute',
                filters: [],
            });
            return {
                value: state,
                onChange(value) {
                    let acc: StudiesResponseDetailStateValues = { ...value };
                    // if (state.filters.length === 0 && value.filters.length > 0) {
                    //     // first filter added
                    //     acc = { ...acc, segment: 'peers' };
                    // } else if (state.filters.length !== value.filters.length) {
                    //     // filters changed
                    //     acc = { ...acc, segment: 'peers' };
                    // } else if (value.filters.length === 0) {
                    //     acc = { ...acc, segment: 'all' };
                    // }
                    setState(acc);
                },
            };
        },
    };
}
