import { LockIcon } from '@chakra-ui/icons';
import { useMemo } from 'react';
import { TraitFilterController } from '../../../../view/peer';
import { AnyType, isCurrencyProperty, Property } from '../../../../domain/attributes';
import { PeerGroupRepository, PluginRepository } from '../../../../app';
import { SegmentItemViewController } from '../../../../controller';
import { downcaseUnlessAllCaps } from '../../../../util';
import { FeatureMiddleware } from '../../featureInterface';

export function createFeatureNumericMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceTraitController(
            controller: TraitFilterController
        ): TraitFilterController {
            // Add locked tooltips and icons to trait filters
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const { feature, restrict } = api.useFeature('numeric_trait_filters');

                    if (feature?.enabled) {
                        return viewProps;
                    }

                    return {
                        ...viewProps,
                        getPropertyInputProps(item) {
                            const inputProps = viewProps.getPropertyInputProps(item);
                            if (!isCurrencyProperty(item)) {
                                return inputProps;
                            }
                            // const filterName = downcaseUnlessAllCaps(item.name);
                            return {
                                ...inputProps,
                                status: {
                                    description: `Upgrade to use peer group filters`,
                                    isDisabled: true,
                                    Icon: LockIcon,
                                    onClick() {
                                        restrict({
                                            ...feature!,
                                            value: null,
                                        });
                                    },
                                },
                            };
                        },
                    };
                },
            };
        }

        function enhanceGroupRepository(
            repository: PeerGroupRepository
        ): PeerGroupRepository {
            // Override locked defaults on existing peer groups
            // TODO move into peer group adapter/repository since
            // the disabled property is a general use-case
            return {
                ...repository,
                useFind(...args) {
                    const [context] = args;

                    const query = {
                        group: repository.useFind(...args),
                        plugin: instance.repository.platform.plugin.useFind(
                            context,
                            {},
                            {}
                        ),
                    };

                    const data = useMemo(() => {
                        return (
                            query.group.data?.map((item) => {
                                const disabledKeys = query.plugin.data
                                    ?.find((candidate) => candidate.id === item.plugin.id)
                                    ?.traits.flatMap((item) =>
                                        item.isDisabled ? [item.key] : []
                                    );
                                return {
                                    ...item,
                                    conditions: item.conditions.filter(
                                        (candidate) =>
                                            !disabledKeys?.includes(candidate.key)
                                    ),
                                };
                            }) ?? []
                        );
                    }, [query.group.data, query.plugin.data]);

                    if (
                        query.group.status !== 'success' ||
                        query.plugin.status !== 'success'
                    ) {
                        return query.group;
                    }

                    return {
                        ...query.group,
                        data,
                    };
                },
            };
        }

        function enhancePluginRepository(repository: PluginRepository): PluginRepository {
            // disable plugin peer traits
            return {
                ...repository,
                useFind(...args) {
                    const query = repository.useFind(...args);
                    const { feature } = api.useFeature('numeric_trait_filters');

                    const mapped = useMemo(() => {
                        return (
                            query.data?.map((item) => {
                                return {
                                    ...item,
                                    traits: item.traits.map(
                                        (trait): Property<AnyType> => {
                                            if (!isCurrencyProperty(trait)) {
                                                return trait;
                                            }
                                            // disable all currency traits
                                            return {
                                                ...trait,
                                                isDisabled: true,
                                                disabledReason: 'locked',
                                            };
                                        }
                                    ),
                                };
                            }) ?? []
                        );
                    }, [query.data]);

                    if (feature?.enabled) {
                        return query;
                    }

                    if (query.status !== 'success') {
                        return query;
                    }

                    return {
                        ...query,
                        data: mapped,
                    };
                },
            };
        }

        function enhanceSegmentController(
            controller: SegmentItemViewController
        ): SegmentItemViewController {
            return (...args) => {
                const [, filter, index] = args;
                const viewProps = controller(...args);
                const { feature, restrict } = api.useFeature('numeric_trait_filters');
                if (feature?.enabled) {
                    return viewProps;
                }
                if (!isCurrencyProperty(filter.property)) {
                    return viewProps;
                }
                // const filterName = downcaseUnlessAllCaps(filter.property.name);
                return {
                    ...viewProps,
                    isDisabled: true,
                    disabledReason: `Upgrade to use peer group filters`,
                    onDisabledClick() {
                        restrict({
                            ...feature!,
                            value: null,
                        });
                    },
                };
            };
        }

        const instance = create({
            ...config,
            repository: {
                ...config.repository,
                platform: {
                    ...config.repository.platform,
                    createPlugin(...args) {
                        const repository = config.repository.platform.createPlugin(
                            ...args
                        );
                        return enhancePluginRepository(repository);
                    },
                },
                peers: {
                    ...config.repository.peers,
                    createPeerGroups(...args) {
                        const repository = config.repository.peers.createPeerGroups(
                            ...args
                        );
                        return enhanceGroupRepository(repository);
                    },
                },
            },
            controller: {
                ...config.controller,
                peer: {
                    ...config.controller.peer,
                    createTrait(...args) {
                        const controller = config.controller.peer.createTrait(...args);
                        return enhanceTraitController(controller);
                    },
                },
            },
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    return config.route.createDashboardRoute({
                        ...routeConfig,
                        controller: {
                            ...routeConfig.controller,
                            useSegmentItem: enhanceSegmentController(
                                routeConfig.controller.useSegmentItem
                            ),
                        },
                    });
                },
            },
        });

        return instance;
    };
}
