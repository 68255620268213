import { z } from 'zod';

export const OrganizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    domain: z.nullable(z.string()),
    company_type: z.nullable(z.string()),
    finished_on_boarding: z.boolean(),
    default_asset: z.nullable(z.number()),
    created_at: z.date({ coerce: true }),
    pending_actions: z.nullable(z.array(z.object({
        is_required: z.boolean(),
        scope: z.union([z.literal('organization'), z.object({
            kind: z.literal('assets'),
            asset_ids: z.array(z.number())
        })]),
        spec: z.discriminatedUnion('kind', [
            z.object({
                kind: z.literal('ingestion_survey'),
                survey_ids: z.array(z.string()),
            }),
            z.object({
                kind: z.literal('ingestion_integration'),
                integration_definitions: z.array(z.string()),
            }),
        ])
    })))
});

export const OrganizationListSchema = z.array(OrganizationSchema);
