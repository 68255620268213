import { BillingPaymenMethodDto } from '../../../../api';
import { PaymentMethod, PaymentMethodAdapter } from '../../../../app/billing';
import { PaymentMethodImplConfig } from './paymentMethodApiConfig';

export function createPaymentMethodImpl(
    config: PaymentMethodImplConfig
): PaymentMethodAdapter {
    const {
        api: { billing: api },
    } = config;

    function toEntity(dto: BillingPaymenMethodDto): PaymentMethod {
        if (dto.kind === 'card') {
            return {
                id: dto.id,
                kind: dto.kind,
                brand: dto.card.brand,
                displayBrand: dto.card.display_brand,
                expMonth: dto.card.exp_month,
                expYear: dto.card.exp_year,
                last4: dto.card.last4,
            };
        }
        return {
            id: dto.id,
            kind: dto.kind,
            type: dto.type,
        };
    }

    return {
        async find(context) {
            const response = await api.paymentMethod.list(context);
            return response.data.map(toEntity);
        },
        async portal(context, props) {
            const response = await api.portal.create(context, {
                return_path: props.returnPath,
                payment_methods: true,
            });
            return response;
        },
    };
}
