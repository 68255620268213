import React from 'react';
import { SystemContextResult } from './systemModel';

export const SystemContext = React.createContext<SystemContextResult | null>(null);

export function useSystemContextV2() {
    const value = React.useContext(SystemContext);
    if (!value) {
        throw new Error(`not inside system v2 context`);
    }
    return value;
}
