import {
    background,
    ButtonProps,
    CheckboxProps,
    cssVar,
    extendTheme,
    TextProps,
    withDefaultColorScheme,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { menuTheme } from './menu';

const DARK_GRAY = '#F6F8FA';
const LIGHTER_GRAY = '#b5b5b6';

const x: ComponentStyleConfig = {
    baseStyle: {},
};

export type ColorModeProps<TProps> = TProps & { colorMode: 'dark' | 'light' };

const onBoardingConfig = {
    breakpoints: {
        sm: '30em',
        md: '48em',
        lg: '62em',
        xl: '80em',
        '2xl': '96em',
        '3xl': '104em',
    },
    transparent: 'none',
    styles: {
        global: (props: any) => ({
            body: {
                bg: mode('white', '#0A0A0A')(props),
            },
        }),
    },
    components: {
        Menu: menuTheme,
        Tooltip: {
            baseStyle: (props: any) => ({
                bg: mode('gray.800', 'gray.800')(props),
                color: mode('gray.100', 'gray.100')(props),
                // Ensure arrow is same color as background
                [cssVar('popper-arrow-bg').variable]: mode(
                    'colors.gray.800',
                    'colors.gray.800'
                )(props),
            }),
        },
        Modal: {
            parts: ['dialog', 'header'],
            baseStyle: {
                dialog: {
                    backgroundColor: '#292929',
                },
                header: {
                    borderBottomColor: '#46454A',
                    borderBottomWidth: 'thin',
                },
            },
        },
        Checkbox: {
            parts: ['control', 'label'],
            baseStyle: (props: CheckboxProps) => {
                return {
                    control: {
                        backgroundColor: mode('transparent', '#46454A')(props),
                        borderColor: mode('gray.200', '#646367')(props),
                        _disabled: {
                            borderColor: mode('gray.200', 'transparent')(props),
                            bg: mode('#686868', 'whiteAlpha.300')(props),
                            color: mode('#686868', 'whiteAlpha.500')(props),
                        },
                        _focus: { outline: 'none', boxShadow: 'none' },
                    },
                    label: {
                        color: mode('gray.500', LIGHTER_GRAY)(props),
                    },
                };
            },
        },
        Button: {
            variants: {
                outline: (props: ButtonProps) => {
                    if (props.colorScheme === 'gray') {
                        return {
                            bg: mode('white', '#383a42')(props),
                            color: mode('gray.700', 'gray.100')(props),
                            borderColor: mode('gray.200', 'transparent')(props),
                        };
                    }
                },
                ghost: (props: ButtonProps) => {
                    if (props.colorScheme === 'gray') {
                        return {
                            bg: mode('transparent', '#383a42')(props),
                            color: mode('gray.700', 'gray.100')(props),
                            borderColor: mode('gray.200', 'transparent')(props),
                        };
                    }
                },
                solid: (props: ColorModeProps<ButtonProps>) => {
                    if (props.colorScheme === 'varosGradient') {
                        return {
                            bg: 'varosGradient.full',
                            textColor: mode('white', 'black')(props),
                        };
                    }
                    if (props.colorScheme === 'green') {
                        return {
                            // boxShadow: 'lg',
                            // bg: mode('button.dark.green', 'button.green'),
                            bg: mode('button.dark.green', 'button.dark.green')(props),
                            // _hover: mode(
                            //     'button.dark.greenHover',
                            //     'button.dark.greenHover'
                            // )(props),
                            // _active: mode(
                            //     'button.dark.greenHover',
                            //     'button.dark.greenHover'
                            // )(props),
                            // _focus: mode(
                            //     'button.dark.greenHover',
                            //     'button.dark.greenHover'
                            // )(props),
                            textColor: 'button.white',
                        };
                    }
                    if (props.colorScheme === 'varosGreen') {
                        // console.log('i am solid ubtton', props);
                        return {
                            boxShadow: 'lg',
                            bg: mode('button.dark.green', 'button.green')(props),
                            _hover: { bg: 'button.hoverGreen' },
                            _active: { bg: 'button.hoverGreen' },
                            _focus: { bg: 'button.hoverGreen' },
                            textColor: 'button.white',
                        };
                    }
                },
                link: (props: ButtonProps) => {
                    if (props.colorScheme === 'varosGradient') {
                        return {
                            bgGradient:
                                'linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)',
                            bgClip: 'text',
                        };
                    }
                    if (props.colorScheme === 'gray') {
                        return {
                            color: mode('gray.700', 'whiteAlpha.800')(props),
                        };
                    }
                    return {};
                },
            },
        },
        // Typography
        Heading: {
            baseStyle: (props: { colorScheme: 'gray' }) => {
                if (props.colorScheme === 'gray') {
                    return {
                        color: mode('gray.800', DARK_GRAY)(props),
                    };
                }
                return {
                    color: mode('gray.800', 'gray.100')(props),
                };
            },
        },
        Text: {
            baseStyle: (props: TextProps) => {
                if (props.variant === 'mute') {
                    return {
                        color: mode('blackAlpha.600', 'whiteAlpha.600')(props),
                    };
                }
                if (props.colorScheme === 'varosGreen') {
                    return {
                        color: mode('button.green', 'button.green')(props),
                    };
                }
                if (props.colorScheme === 'gray') {
                    return {
                        color: mode('gray.500', LIGHTER_GRAY)(props),
                    };
                }
                return {
                    // color: mode('gray.500', 'gray.100')(props),
                };
            },
        },
    },
    backgrounds: {
        varosGradient:
            'linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)',
    },
    colors: {
        legend: {
            red: { 200: '#fe476a' },
            teal: { 200: '#7fe1da' },
            green: { 200: '#2bd19a' },
            purple: { 200: '#7756ff' },
            gray: { 200: 'gray' },
        },
        varosGradient: {
            full: 'linear-gradient(90deg, #29D89B 0%, #3CD990 15.57%, #63DC79 52.47%, #7BDE6A 81.91%, #84DF65 100%)',
            left: '#61bd49',
            right: '#02C380',
        },
        varosGreen: {
            200: '#02C380',
        },
        varosPurple: {
            200: '#7164ff',
        },
        modal: {
            200: '#292929',
        },
        accent: {
            200: '#05A87D',
        },
        selected: '#46454A',
        button: {
            dark: {
                green: '#02C380',
                greenHover: '#02C380',
            },
            green: '#02C380',
            white: 'white',
            hoverGreen: '#38A169',
            googleText: '#0000008A',
        },
        onboarding: {
            title: '#212B36',
            subtitle: '#637381',
            dark: {
                title: DARK_GRAY,
                subtitle: LIGHTER_GRAY,
            },
        },
        box: DARK_GRAY,
        frame: '#E0E6EB',
        link: {
            green: '#02C380',
            black: 'black',
        },
        error: '#CD3D64',
        labels: {
            lightblue: '#CFF5F6',
            blue: '#0055BC',
        },
        card: {
            frame: '#CBD5E0',
            frameDarker: '#f6f7fd',
            hoverFrame: '#63B3ED',
            dark: {
                frame: '#1D1C21',
                frameLighter: '#313037',
            },
        },
        border: {
            green: '#05A87D',
        },
        background: '#000',
        placeholder: '#686868',
    },
};

const theme = extendTheme(
    {
        ...onBoardingConfig,
    },
    withDefaultColorScheme({ colorScheme: 'varosGreen', components: ['Switch'] })
);

export default theme;
