import React from 'react';
import { EmailReportController, EmailReportViewProps } from '../../../view/email';
import { OrganizationReference, AuthenticationV2 } from '../../../domain/platform';
import { EmailReportContainerProps } from './emailReportProps';

export function createEmailReportContainer(config: {
    hook: {
        useOrganization(): OrganizationReference;
        useAuth(): AuthenticationV2;
    };
    controller: EmailReportController;
    View: React.FC<{ children?: React.ReactNode | undefined } & EmailReportViewProps>;
}): React.FC<{ children?: React.ReactNode | undefined } & EmailReportContainerProps> {
    const {
        View,
        controller,
        hook: { useOrganization, useAuth },
    } = config;
    return (props) => {
        const organization = useOrganization();
        const auth = useAuth();
        const viewProps = controller.useProps(
            { organization, auth },
            {
                id: props.definition.id,
            },
            {
                workspace: props.workspace,
                date: props.date,
                period: props.period,
                granularity: props.granularity,
            }
        );
        return <View {...viewProps} />;
    };
}
