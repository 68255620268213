import { useQueryApi, useDashboardApi } from '../../../app/dashboard';
import { CardItemController, CardListController } from './cardInterface';

export const useCardListController: CardListController = () => {
    const api = {
        dashboard: useDashboardApi(),
    };
    return {
        cards: api.dashboard.getCards(),
    };
};

export const useCardItemController: CardItemController = (card) => {
    const api = {
        dashboard: useDashboardApi(),
        query: useQueryApi(),
    };
    return {
        card,
        stateHash: api.query.getHash(card.query.source),
        composition: api.dashboard.getComposition(card).sections,
    };
};
