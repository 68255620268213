import React from 'react';
import { Box, TextProps } from '@chakra-ui/react';
import { PluginIcons } from '../../../config';
import { BenchmarkStatus } from '../benchmark';
import { DateRangeValue } from '../../../domain/attributes';
import { DateRange, periodToRange } from '../../../../domain';
import { getCalculationStatus, getMetricBuckets } from '../../../domain/metrics';
import { ComparisonController } from './comparisonInterface';
import { ComparisonViewProps } from './comparisonProps';

function rangeToValue(range: DateRange): DateRangeValue {
    return { from: range.start, to: range.end };
}

function getColor(status?: BenchmarkStatus): TextProps['color'] {
    if (!status) {
        return 'whiteAlpha.900';
    }
    return (
        {
            danger: 'red.500',
            warn: 'yellow.500',
            success: 'green.500',
        } as const
    )[status];
}

export function createComparisonController(): ComparisonController {
    return {
        useProps(
            deps,
            context,
            metric,
            configuration,
            ranking,
            calculation
        ): ComparisonViewProps {
            const { formatter } = deps;

            const buckets = getMetricBuckets(metric);
            const absoluteStatus = getCalculationStatus(buckets, calculation);

            const icon = PluginIcons[metric.plugin] ?? null;

            const valueLabel = calculation.current?.value.base
                ? formatter.format(metric.type, calculation.current.value.base, {
                      notation: 'short',
                  })
                : 'N/A';

            return {
                iconUrl: icon,
                name: metric.name,
                // ticks: [],
                ticks: [
                    calculation.current,
                    calculation.high,
                    calculation.mid,
                    calculation.low,
                ].map((segment, index): ComparisonViewProps['ticks'][number] => {
                    if (segment.definition.kind === 'asset') {
                        return {
                            group: 'self',
                            color: absoluteStatus ? getColor(absoluteStatus) : undefined,
                            label: segment.definition.name,
                            value: segment.value.base
                                ? formatter.format(metric.type, segment.value.base, {
                                      notation: 'short',
                                  })
                                : 'N/A',
                        };
                    }
                    const status = ((
                        {
                            1: 'success',
                            2: 'warn',
                            3: 'danger',
                        } as const
                    )[index] ?? 'success')!;
                    return {
                        color: getColor(status),
                        label: segment.definition.name,
                        value: segment.value.base
                            ? formatter.format(metric.type, segment.value.base, {
                                  notation: 'short',
                              })
                            : 'N/A',
                    };
                }),
                period: formatter.format(
                    'daterange',
                    rangeToValue(periodToRange(null, calculation.period))
                ),
                ranking,
                description: (() => {
                    if (!absoluteStatus) {
                        return null;
                    }

                    const segmentLabel = (
                        {
                            danger: 'low',
                            warn: 'median',
                            success: 'high',
                        } as const
                    )[absoluteStatus];

                    return (
                        <>
                            <Box as="span">Your </Box>
                            <Box as="span" color="whiteAlpha.800" fontWeight="semibold">
                                {metric.name}
                            </Box>{' '}
                            <Box as="span">at</Box>{' '}
                            <Box
                                as="span"
                                px={1}
                                py={0.25}
                                color="whiteAlpha.800"
                                bg="blackAlpha.700"
                                fontWeight="semibold"
                                fontStyle="normal"
                            >
                                {valueLabel}
                            </Box>{' '}
                            <Box as="span">was among the</Box>{' '}
                            <Box as="span" color="whiteAlpha.800" fontWeight="semibold">
                                {segmentLabel} performers
                            </Box>{' '}
                            {ranking ? (
                                <>
                                    <Box as="span">in terms of</Box>{' '}
                                    <Box
                                        as="span"
                                        color="whiteAlpha.800"
                                        fontWeight="semibold"
                                    >
                                        {ranking.name}
                                    </Box>{' '}
                                </>
                            ) : null}
                            <Box as="span">for the selected period</Box>
                        </>
                    );
                })(),
            };
        },
    };
}
