import { createSearchParams } from 'react-router-dom';
import { AnyRedirect } from './redirectType';

export const useRedirect = <TRed extends AnyRedirect>(type: TRed['type']) => {
    const redirect = async (payload: AnyRedirect) => {
        const params = createSearchParams({ ...payload });
        const url = `/redirect?${params}`;
        console.info(`redirecting to ${url}`);
        window.open(url, '_blank');
    };
    return {
        actions: {
            /**
             * Opens a new tab that redirects to Stripe checkout
             */
            redirect,
        },
    };
};
