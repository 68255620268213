import { ReportQueryServiceConfig } from './queryConfig';
import { ReportQueryService } from './queryInterface';

export function createQueryService(init: ReportQueryServiceConfig): ReportQueryService {
    const { useResponse } = init.adapter.query;
    return {
        useResponse(props) {
            const responses = useResponse(props);
            return responses;
        },
    };
}
