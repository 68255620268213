import { useMemo } from 'react';
import { periodToRange } from '../../../../../domain';
import { assert } from '../../../../util/assert';
import { buildReportFormProps } from './form';
import { ReportNewConfig } from './reportNewConfig';
import { ReportNewController } from './reportNewInterface';

export function createReportNewController(
    config: Pick<ReportNewConfig, 'controller'>
): ReportNewController {
    const { controller } = config;
    return {
        useProps(deps, context, data, form, props) {
            const formValues = form.watch();

            const dateProps = controller.date.useProps({
                value: formValues.period,
                onChange(value) {
                    const absolute = periodToRange(null, value);
                    form.setValue('period', absolute);
                },
            });

            const formProps = buildReportFormProps(deps, data, form, formValues);

            if (!data.item.data || data.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            return {
                status: 'success',
                getFormProps() {
                    assert(formProps, 'form props not ready');
                    return formProps;
                },
                getDateProps() {
                    return dateProps;
                },
            };
        },
    };
}
