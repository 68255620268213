import { AxiosInstance } from 'axios';
import { getAssetCounts } from '../../../../api/counts';
import { DatasetStatusAdapter } from '../../../app/demo';

export function createDatasetStatusApiImpl(config: {
    axios: AxiosInstance;
    apiKey: string | null;
}): DatasetStatusAdapter {
    return {
        async getAssetCount() {
            const response = await getAssetCounts(config.axios, {
                apiKey: config.apiKey ?? undefined,
            });
            return {
                count: response.asset.active,
            };
        },
    };
}
