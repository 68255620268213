import React from 'react';
import { InputProps, Input } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { BaseProps } from '../base';

export interface StringInputProps
    extends BaseProps,
        Pick<InputProps, 'value' | 'onChange'> {
    type: 'string';
}

export const StringInput: React.FC<
    { children?: React.ReactNode | undefined } & StringInputProps
> = (props) => {
    return <Input {...props} />;
};
