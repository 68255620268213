import { DashboardConfiguration } from '../../../domain/dashboard';
import { flattenTreeValues, isTreeType } from '../../../domain/attributes';
import { ControlSelectedState } from './controlModel';

export function selectControlState(
    configuration: DashboardConfiguration
): ControlSelectedState {
    const initial: Record<string, Set<unknown> | undefined> = {};
    return {
        treeValuesByPropertyKey: configuration.properties.traits.reduce(
            (acc, trait) =>
                isTreeType(trait.property.type)
                    ? {
                          ...acc,
                          [trait.property.key]: new Set(
                              flattenTreeValues(trait.property.type)
                          ),
                      }
                    : acc,
            initial
        ),
        treeValueParentByChildValue: configuration.properties.traits.reduce(
            (traitAcc, trait) =>
                isTreeType(trait.property.type)
                    ? {
                          ...traitAcc,
                          [trait.property.key]: trait.property.type.members.reduce(
                              (accParent, parent) =>
                                  parent.children.reduce(
                                      (childAcc, child) => ({
                                          ...childAcc,
                                          [child.value]: parent.value,
                                      }),
                                      accParent
                                  ),
                              {} as Record<string, string | undefined>
                          ),
                      }
                    : traitAcc,
            {}
        ),
    };
}
