import {
    action,
    autorun,
    computed,
    extendObservable,
    IReactionDisposer,
    makeObservable,
    observable,
    runInAction,
    toJS,
} from 'mobx';

import StoreBase from './StoreBase';
import { login, loginSocial, loginWithRefreshToken } from '../api/auth';
import {
    fetchMyAccount,
    updateFinishedOnBoarding,
    loginToOrg,
    fetchAcountProps,
    AccountProps,
} from '../api/users';
import { listParticipants } from '../api/peers';
import { SubscriptionDto, getSubscription } from '../api';
import { apiV1NoPrefix, createApi, setToken } from '../api/axios';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import {
    LoginResponse,
    MeUser,
    OnBoardingStatus,
    Organization,
    OrganizationRole,
    SocialLogin,
    User,
    VarosJwtPayload,
} from '../models/Account';
import { Asset } from '../models/Asset';
import * as Sentry from '@sentry/react';

// import mixpanel, { Mixpanel } from 'mixpanel-browser';
// import debounce from 'lodash/debounce';
export type AuthStoreErrors = 'unauthorized' | 'noPerm';

function isSocial(opt: string | SocialLogin): opt is SocialLogin {
    return (opt as SocialLogin).social_type !== undefined;
}

class AuthStore extends StoreBase {
    public authToken: string | null = null;
    public forwardTo: string | null = null;
    public loading: boolean = false;
    public refreshToken: string | null = null;
    public isRefreshingToken: boolean = false;
    public isStarted: boolean = false;
    public isFirstSession: boolean = false;
    public lastRefresh: Date | null = null;
    public currentUser: User | null = null;
    public onBoardingSteps: OnBoardingStatus[] | null = null;
    public requiredState: OnBoardingStatus | null = null;
    public skippedHistory: Set<OnBoardingStatus['state']> = new Set([]);
    public currentPageSubtitles: Array<string> | null = null;
    public globalErrorMessage: string | null = null;
    public allowEditLastSubtitle?: boolean = false;
    public jwtPayload: VarosJwtPayload | null = null;
    public accountProps: AccountProps | null = null;
    private _identifiedAnalytics = false;
    public visibleSections: Map<string, any> = new Map([
        ['home', true],
        ['cards', false],
        ['dashboards', true],
        ['template_dashboards', false],
    ]);
    // private _authenticationPromise: Promise<void>;
    // private _resolveAuthPromise: (() => void) | null = null;
    // private _rejectAuthPromise: ((reason: any) => void) | null = null;
    private _resolveStarted: IReactionDisposer;
    private _resolveAuthCallbacks: Array<(arg0: unknown) => void> = [];
    private _rejectAuthCallbacks: Array<(reason: any) => void> = [];

    // private _mixpanel: Mixpanel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            onBoardingSteps: observable,
            authToken: observable,
            loading: observable,
            refreshToken: observable,
            isRefreshingToken: observable,
            isStarted: observable,
            requiredState: observable,
            currentUser: observable,
            skippedHistory: observable,
            lastRefresh: observable,
            currentPageSubtitles: observable,
            globalErrorMessage: observable,
            visibleSections: observable,
            isFirstSession: observable,
            allowEditLastSubtitle: observable,
            jwtPayload: observable,
            forwardTo: observable,
            accountProps: observable,
            //   // Computed

            loggedInOrganization: computed,
            currentRole: computed,
            nextSteps: computed,

            //   id: computed,

            // Actions
            start: action,
            setLoading: action,
            setToken: action,
            setUserDetails: action,
            setRefreshToken: action,
            setRefreshingToken: action,
            login: action,
            loginRefreshToken: action,
            setRequiredState: action,
            checkOnBoardingStatus: action,
            setCurrentUser: action,
            addSkippedHistory: action,
            setRequiredStateObj: action,
            skipCurrentStage: action,
            logout: action,
            unsetUser: action,
            setCurrentPageSubtitles: action,
            setGlobalErrorMessage: action,
            modifyVisibleSection: action,
            finishOnBoarding: action,
            syncSkippedSteps: action,
            setForwardTo: action,
            changeToPreviousState: action,
            applyAnalytics: action,
        });
        this._resolveStarted = autorun(() => {
            if (!this.isStarted || this.loading || this.isRefreshingToken) {
                return;
            }
            if (this.authToken && this.currentUser) {
                // apply events in reverse order so the oldest event gets sent first
                let cb = this._resolveAuthCallbacks.shift();
                while (cb) {
                    cb(undefined);
                    cb = this._resolveAuthCallbacks.shift();
                }
            } else {
                let cb = this._rejectAuthCallbacks.shift();
                while (cb) {
                    cb = this._rejectAuthCallbacks.shift();
                }
            }
        });
    }

    syncSkippedSteps() {
        const skippedOnBoardingItems = localStorage.getItem('skipped_onboarding_items');
        if (skippedOnBoardingItems && skippedOnBoardingItems.length > 0) {
            const items = JSON.parse(skippedOnBoardingItems);
            for (const i of items) {
                this.skippedHistory.add(i);
            }
        }
    }

    reportEvent = (
        eventType: string,
        event_data: Record<string, any>,
        predicate?: (payload: Record<string, any>) => boolean
    ) => {
        if (!this.jwtPayload?.imper) {
            // make sure to identify prior to track
            // if a predicate is specified, we always reapply analytics
            if (!predicate && this._identifiedAnalytics) {
                const data = {
                    ...event_data,
                    org_id: this.loggedInOrganization?.id,
                    groupId: this.loggedInOrganization?.id,
                    ...(this.accountProps?.event_props || {}),
                };
                if (process.env.VAROS_ENV === 'production') {
                    window.dataLayer.push({
                        event: eventType,
                        ...data,
                    });
                    window.analytics.track(eventType, data);
                } else {
                    console.log('event type!', eventType, data);
                }
            } else {
                this.asyncApplyAnalytics().then(() => {
                    const data = {
                        ...event_data,
                        org_id: this.loggedInOrganization?.id,
                        groupId: this.loggedInOrganization?.id,
                        ...(this.accountProps?.event_props || {}),
                    };
                    if (predicate && !predicate(data)) {
                        return;
                    }
                    window.dataLayer.push({
                        event: eventType,
                        ...data,
                    });
                    if (process.env.VAROS_ENV === 'production') {
                        window.analytics.track(eventType, data);
                    } else {
                        console.log('event type!', eventType, data);
                    }
                });
            }
        } else {
            const data = {
                ...event_data,
                org_id: this.loggedInOrganization?.id,
                groupId: this.loggedInOrganization?.id,
                ...(this.accountProps?.event_props || {}),
            };
            console.log('event type!', eventType, data);
        }
    };

    setCurrentPageSubtitles = (subtitle: Array<string> | null, editLast?: boolean) => {
        this.currentPageSubtitles = subtitle;
        this.allowEditLastSubtitle = editLast || false;
    };

    setAccountProps = (accountProps: AccountProps) => {
        this.accountProps = accountProps;
    };

    getAccountProps = async (): Promise<AccountProps | null> => {
        let retVal: AccountProps | null = null;
        try {
            const { data: properties } = await fetchAcountProps();
            this.setAccountProps(properties);
            retVal = properties;
        } catch (e) {
            Sentry.captureException(e);
            console.log(e);
        }
        return retVal;
    };

    getMyAccount = async () => {
        const { data } = await fetchMyAccount();
        this.setCurrentUser(data);
        return data;
    };

    get loggedInOrganization(): Organization | undefined {
        if (this.jwtPayload?.org_id) {
            return this.currentUser?.organizations?.find(
                (x) => x.organization?.id == this.jwtPayload?.org_id
            )?.organization;
        }
    }

    get currentRole(): OrganizationRole['role'] | undefined {
        if (this.jwtPayload?.org_id) {
            return this.currentUser?.organizations?.find(
                (x) => x.organization?.id == this.jwtPayload?.org_id
            )?.role;
        }
    }

    get nextSteps(): Array<OnBoardingStatus> {
        let nextSteps: Array<OnBoardingStatus> = [...(this.onBoardingSteps || [])];
        if (this.requiredState) {
            const reqState = nextSteps.indexOf(this.requiredState);
            if (reqState >= 0) {
                nextSteps = nextSteps.slice(reqState);
            }
        }
        return nextSteps;
    }

    applyNextStep() {
        let nextReqState: OnBoardingStatus | null = null;
        const nextSteps = [...this.nextSteps];
        do {
            nextReqState = nextSteps.shift() || null;
            if (this.loggedInOrganization?.finished_on_boarding) {
                continue;
            }
            if (nextReqState?.can_skip) {
                if (nextReqState?.state && !this.skippedHistory.has(nextReqState.state)) {
                    break;
                }
            } else {
                break;
            }
        } while (nextSteps && nextSteps.length > 0);
        if (
            (!nextReqState ||
                nextReqState.state == 'complete' ||
                nextReqState.allow_dashboard_preview) &&
            !this.loggedInOrganization?.finished_on_boarding
        ) {
            this.finishOnBoarding().then(() => {
                this.setRequiredStateObj(nextReqState);
            });
        } else {
            this.requiredState = nextReqState;
        }
    }
    setUserDetails(user: User, isNewUser: boolean) {
        this.currentUser = user;
        this.isFirstSession = isNewUser;
    }
    unsetUser() {
        this.currentUser = null;
    }

    modifyVisibleSection = (sectionId: string, addOrRemove: boolean) => {
        // console.log('changing visibility', sectionId, addOrRemove);
        this.visibleSections.set(sectionId, addOrRemove);
    };

    finishOnBoarding = async () => {
        try {
            this.setLoading(true);
            const orgId = this.loggedInOrganization?.id;
            if (!orgId) {
                return;
            }
            const { data } = await updateFinishedOnBoarding(orgId);
            runInAction(() => {
                this.currentUser = data;
            });
        } catch (e) {
            const isDone = await this.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.finishOnBoarding();
            } else {
                this.setErrType('unauthorized');
            }
        } finally {
            this.setLoading(false);
        }
    };

    asyncApplyAnalytics = () => {
        return this.waitAuthenticated().then(this.getMyAccount).then(this.applyAnalytics);
    };

    applyAnalytics = async (user: MeUser) => {
        let subscription: SubscriptionDto | null = null;
        if (this.loggedInOrganization) {
            // subscription only available in org context
            subscription = await getSubscription(apiV1NoPrefix).catch(() => null);
        }
        const props = await this.getAccountProps();
        const requireIntegs = user.on_boarding_states.find(
            (x) => x.state == 'req_integration'
        );

        const plan = subscription?.active ? subscription.plan : undefined;

        const identifyPayload = {
            email: user.user.email,
            first_name: user.user.first_name,
            last_name: user.user.last_name,
            USER_ID: user.user.id,
            org_onboarding_completed: this.loggedInOrganization?.finished_on_boarding,
            org_id: this.loggedInOrganization?.id,
            org: this.loggedInOrganization?.name,
            is_integrated: this.loggedInOrganization?.id && requireIntegs == undefined,
            plan,
            ...(props?.identification_props || {}),
        };

        if (process.env.VAROS_ENV === 'production') {
            try {
                const uId = user.user.id?.toString();
                const orgId = this.loggedInOrganization?.id?.toString();

                if (uId) {
                    window.analytics.identify(uId, identifyPayload);

                    if (orgId) {
                        const groupPayload = {
                            org_name: this.loggedInOrganization?.name,
                            groupId: orgId,
                            ...(props?.identification_props || {}),
                        };
                        window.analytics.group(orgId, groupPayload);
                    }

                    const created_at = user.user.created_at
                        ? new Date(user.user.created_at).getTime() / 1000
                        : undefined;
                    window._cio?.identify({
                        id: uId,
                        // Required attributes — you must include email or ID
                        email: identifyPayload.email,
                        first_name: user.user.first_name,
                        last_name: user.user.last_name,
                        org_id: orgId,
                        groupId: orgId,
                        org: this.loggedInOrganization?.name,
                        plan,
                        is_integrated: requireIntegs == undefined,
                        // // Strongly recommended when you first identify someone
                        created_at, // Timestamp in representing when the user
                        // first signed up in Unix format.
                    });
                    Sentry.setUser({
                        id: uId,
                        email: user.user.email,
                    });
                }
            } catch (e) {
                console.error('failed on register customer in cio or segment', e);
            }
        } else {
            console.log('identify payload', identifyPayload);
        }
        window.dataLayer.push({ event: 'identify', ...identifyPayload });
        this._identifiedAnalytics = true;
    };

    setCurrentUser(user: MeUser) {
        this.currentUser = user.user;
        this.onBoardingSteps = user.on_boarding_states;
        const requireIntegs = this.onBoardingSteps.find(
            (x) => x.state == 'req_integration'
        );

        if (requireIntegs) {
            this.modifyVisibleSection('integrations', true);
        }
        const isAdmin = this.currentRole == 'admin';
        this.visibleSections.set('cards', isAdmin);
        this.visibleSections.set('dashboards', true);
        this.visibleSections.set('template_dashboards', isAdmin);
        this.visibleSections.set('new_dashboard', isAdmin);

        this.applyNextStep();
    }

    changeToPreviousState(reqState: OnBoardingStatus['state']) {
        const step = this.onBoardingSteps?.find((x) => x.state == reqState);
        if (step) {
            const items = localStorage.getItem('skipped_onboarding_items');
            if (items) {
                const skippedItems: Array<string> = JSON.parse(items);
                localStorage.setItem(
                    'skipped_onboarding_items',
                    JSON.stringify(skippedItems.filter((x) => x != reqState))
                );
                this.skippedHistory.clear();
                this.syncSkippedSteps();
            }
            this.requiredState = step;
        }
    }

    setRequiredState(
        reqState: OnBoardingStatus['state'],
        subjectId: number | null = null,
        canSkip: boolean = false
    ) {
        this.requiredState = {
            state: reqState,
            can_skip: canSkip,
            subject_id: subjectId || undefined,
        };
    }

    setRequiredStateObj(reqState: OnBoardingStatus | null) {
        this.requiredState = reqState;
    }

    setGlobalErrorMessage = (msg: string | null) => {
        this.globalErrorMessage = msg;
    };

    loginRefreshToken = async () => {
        if (!this.refreshToken) {
            this.setRequiredState('login');
            return;
        }
        // const isFirstLogin = !this.hasEverLoggedIn;
        try {
            this.setError(null);
            this.setRefreshingToken(true);
            const { data } = await loginWithRefreshToken(this.refreshToken);
            this.setToken(data.access_token);
            this.setRefreshToken(data.refresh_token);

            const user = await this.getMyAccount();
            this.applyAnalytics(user);
            return true;
        } catch (err) {
            this.setToken(null);
            this.setRefreshToken(null);
            this.setRequiredState('login');
            return false;
        } finally {
            this.setRefreshingToken(false);
        }
    };

    checkOnBoardingStatus = async (disableWaitAuth: boolean = false) => {
        if (!disableWaitAuth) {
            try {
                await this.waitAuthenticated();
            } catch (e) {
                this.setRequiredState('login');
                return;
            }
        }
        if (!this.currentUser) {
            this.setRequiredState('login');
            return;
        }
        try {
            this.setLoading(true);
            await this.internalCheckOnBoarding();
        } catch (e) {
            const isDone = await this.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.checkOnBoardingStatus();
            } else {
                this.setErrType('unauthorized');
            }
        } finally {
            this.setLoading(false);
        }
    };

    addSkippedHistory(stage: OnBoardingStatus['state']) {
        this.skippedHistory.add(stage);
    }

    private internalCheckOnBoarding = async () => {
        const user = await this.getMyAccount();
        this.applyAnalytics(user);
    };

    skipCurrentStage = async () => {
        if (this.requiredState?.can_skip) {
            const skippedStep = this.requiredState.state;
            const skippedItemsJSON = localStorage.getItem('skipped_onboarding_items');
            let skippedItems: Array<string> | null = null;
            if (skippedItemsJSON && skippedItemsJSON.length > 0) {
                skippedItems = JSON.parse(skippedItemsJSON) || [];
            } else {
                skippedItems = [];
            }
            if (skippedItems) {
                skippedItems.push(skippedStep);
                localStorage.setItem(
                    'skipped_onboarding_items',
                    JSON.stringify(skippedItems)
                );
            }
            this.addSkippedHistory(skippedStep);
            this.applyNextStep();
        } else {
            console.warn('cannot skip step', this.requiredState);
        }
    };

    getOrgId(): number {
        const retVal = this.loggedInOrganization?.id;
        if (!retVal) {
            throw new Error('CurrentUser / Organization Not Found');
        }
        return retVal;
    }

    doCommonUnauthErrorHandling = async (e: any) => {
        const ex: AxiosError = e;
        if (ex && ex.response?.status === 401) {
            if (
                !this.lastRefresh ||
                new Date().getTime() - this.lastRefresh.getTime() > 10000
            ) {
                return await this.loginRefreshToken();
            } else {
                if (!this.isRefreshingToken) {
                    this.setRequiredState('login');
                }
            }
        }
        return false;
    };

    public start = async () => {
        try {
            this.setRefreshToken(localStorage.getItem('refresh_token'));
            this.syncSkippedSteps();
            await this.loginRefreshToken();
        } finally {
            runInAction(() => {
                this.isStarted = true;
            });
        }
    };

    public waitAuthenticated = async () => {
        if (this.authToken && !this.isRefreshingToken) {
            if (this.jwtPayload && this.jwtPayload.exp && this.refreshToken) {
                const current_time = new Date().getTime() / 1000;
                if (current_time + 10 > this.jwtPayload.exp) {
                    this.loginRefreshToken();
                } else {
                    return Promise.resolve();
                }
            } else {
                return Promise.resolve();
            }
        }
        const authenticationPromise = new Promise((resolve, reject) => {
            if (this._resolveAuthCallbacks.length < 100) {
                this._resolveAuthCallbacks.push(resolve);
                this._rejectAuthCallbacks.push(reject);
            } else {
                if (this.authToken) {
                    resolve(undefined);
                } else {
                    reject('cannot subscribe to auth, buffer size exceeded');
                }
            }
        });
        await authenticationPromise;
    };

    public setLoading = (flag: boolean) => {
        this.loading = flag;
    };
    public setRefreshToken(token: string | null) {
        this.refreshToken = token;
        if (token === null) {
            localStorage.removeItem('refresh_token');
        } else {
            localStorage.setItem('refresh_token', token);
        }
    }
    public setToken = (token: string | null) => {
        this.authToken = token;
        if (token) {
            try {
                const decoded = jwt_decode<VarosJwtPayload>(token);
                this.jwtPayload = decoded;
            } catch (e) {
                console.log('failed to parse jwt token.');
                this.jwtPayload = null;
            }
            setToken(token);
        }
    };

    public setRefreshingToken(flag: boolean) {
        this.isRefreshingToken = flag;
        this.lastRefresh = new Date();
    }

    public setForwardTo(forwardTo: string | null) {
        this.forwardTo = forwardTo;
    }

    handloginResponse = async (
        emailAddress: string,
        data: LoginResponse,
        forwardTo?: string,
        callback?: (user: User) => void
    ) => {
        try {
            this.setForwardTo(forwardTo || null);
            this.setToken(data.access_token);
            this.setRefreshToken(data.refresh_token);
            this.setError(null);
            await this.internalCheckOnBoarding();
            if (this.currentUser && callback) {
                callback(this.currentUser);
            }
            this.currentUser;
            this.reportEvent('loginSuccess', {
                email: emailAddress,
                isFirstLogin: this.isFirstSession,
            });
        } catch (err) {
            this.setErrType('unauthorized');
            this.setToken(null);
            this.setRequiredState('login');
        } finally {
            this.setLoading(false);
        }
    };

    public login = async (emailAddress: string, authPayload: string | SocialLogin) => {
        // this.resetAuthPromise();
        const isSocialAuth = isSocial(authPayload);
        try {
            this.setError(null);
            this.setLoading(true);
            const { data } = await (isSocial(authPayload)
                ? loginSocial(authPayload)
                : login({
                      emailAddress,
                      password: authPayload,
                  }));
            this.setToken(data.access_token);
            this.setRefreshToken(data.refresh_token);
            await this.internalCheckOnBoarding();
            this.reportEvent('loginSuccess', {
                email: emailAddress,
                isSocial: isSocialAuth,
            });
            window.sessionStorage.clear();
        } catch (err) {
            this.handleAxiosError(err);
            this.setToken(null);
            this.reportEvent('loginErr', {
                email: emailAddress,
                isSocial: isSocialAuth,
            });
            this.setRequiredState('login');
        } finally {
            this.setLoading(false);
        }
    };

    public logout = () => {
        this.reportEvent('logout', {
            email: this.currentUser?.email,
        });
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('skipped_onboarding_items');
        this.setRefreshToken(null);
        this.setToken(null);
        this.unsetUser();
        this.setRequiredState('login');
        this.rootStore.reset({
            auth: this,
        });
        if (process.env.VAROS_ENV === 'production') {
            // Sentry.configureScope((scope) => scope.clear());
        }
        window.sessionStorage.clear();
        // window.location.reload();
    };

    connectToOrganization = async (orgId: number) => {
        this.waitAuthenticated();
        try {
            const { data } = await loginToOrg(orgId);
            this.setToken(data.access_token);
            this.reportEvent('connectedToOrgSuccess', {
                org_id: orgId,
            });
            await this.internalCheckOnBoarding();
        } catch (e) {
            this.handleAxiosError(e);
        } finally {
            this.setLoading(false);
        }
    };
}

export default AuthStore;
