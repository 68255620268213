import { MetricApi } from './metricsInterface';
import { MetricApiConfig } from './metricsConfig';
import { createMetricCollectionResource } from './collection';
import { createMetricDefinitionResource, createMetricResource } from '.';

export function createMetricApi(config: MetricApiConfig): MetricApi {
    const { client } = config;
    return {
        definitions: createMetricDefinitionResource(client),
        metrics: createMetricResource(client),
        collections: createMetricCollectionResource(client),
    };
}
