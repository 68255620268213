import React from 'react';
import { useForm } from 'react-hook-form';
import { UserDto } from '../../../../api';
import { EditScheduleFormConfig } from './scheduleEditConfig';
import { UseReportScheduleEditFormHook } from './scheduleEditInterface';
import { ReportScheduleEditFormState } from './scheduleEditModel';

export function createEditScheduleFormController(
    config: EditScheduleFormConfig
): UseReportScheduleEditFormHook {
    const {
        service: {
            collaborator: { useList: useCollaborators },
        },
    } = config;
    return {
        useProps(context, input) {
            const { schedule, onSubmit } = input;

            const collaborators = useCollaborators(context, {
                workspaces: [schedule.asset],
            });

            const form = useForm<ReportScheduleEditFormState>({
                defaultValues: {
                    _error: null,
                    recipients: schedule.recipients.map((recipient) => ({
                        value: (recipient.user as UserDto).id,
                        label: (recipient.user as UserDto).first_name
                            ? [
                                  (recipient.user as UserDto).first_name,
                                  (recipient.user as UserDto).last_name,
                              ]
                                  .filter(Boolean)
                                  .join(' ')
                            : (recipient.user as UserDto).email,
                    })),
                },
            });

            const values = form.watch();

            return {
                isOpen: input.isOpen,
                onClose() {
                    form.reset();
                    input.onClose();
                },
                error: values._error,
                recipients: {
                    value: values.recipients,
                    onChange(values) {
                        form.setValue('recipients', values);
                    },
                    options: collaborators.map((collaborator) => ({
                        value: collaborator.id,
                        label:
                            [collaborator.first_name, collaborator.last_name]
                                .join(' ')
                                .trim() || collaborator.email,
                    })),
                },
                formState: form.formState,
                onSubmit: form.handleSubmit(async (values) => {
                    try {
                        await onSubmit({
                            recipients: values.recipients.map((recipient) => ({
                                user: recipient.value,
                            })),
                        });
                        input.onClose();
                    } catch (error) {
                        if (error instanceof Error) {
                            // NOTE this is a hack, really we should have a way
                            // of setting a global form error, but this is not yet supported
                            // on our version of react-hook-forms
                            form.setValue('_error', {
                                message: error.message,
                            });
                            return;
                        }
                        throw error;
                    }
                }),
            };
        },
    };
}
