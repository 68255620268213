import { OnboardingReportController } from '../../../onboarding';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyOnboardingTrackingEvent } from '../trackingOnboardingEvent';

export function createTrackingOnboardingReportStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyOnboardingTrackingEvent>();
            return tracker;
        }

        function enhanceController(
            controller: OnboardingReportController
        ): OnboardingReportController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    if (viewProps.status !== 'success') {
                        return viewProps;
                    }
                    return {
                        ...viewProps,
                        getFormProps() {
                            const base = viewProps.getFormProps();
                            return {
                                ...base,
                                async onSubmit(...args) {
                                    const submitted = await base.onSubmit(...args);
                                    tracker.track(
                                        'onboarding_report_creation_submitted',
                                        {}
                                    );
                                    return submitted;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                onboarding: {
                    ...config.controller.onboarding,
                    createReport(...args) {
                        const controller = config.controller.onboarding.createReport(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
