import { PlanDto, PlanType, PriceDto } from '../../../api';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

export interface PlanFeature {
    text: string;
    caption: string | null;
}

export type PlanCategoryKey = 'free' | 'enterprise' | PlanType;

export type BillingUnitKey = 'avg_monthly_spend' | 'num_of_clients';

export const PLAN_CATEGORY_NAME_BY_KEY: { [key in PlanCategoryKey]: string } = {
    free: 'Free',
    brand: capitalize(pluralize('brand')),
    agency: capitalize(pluralize('agency')),
    enterprise: 'Enterprise',
};

export type PricingPlan = PlanDto;

export interface CustomPlan
    extends Omit<
            PlanDto,
            'unit_kind' | 'unit_name' | 'threshold_start' | 'threshold_end' | 'amount'
        >,
        Partial<
            Pick<
                PlanDto,
                'unit_kind' | 'unit_name' | 'threshold_start' | 'threshold_end' | 'amount'
            >
        > {
    // caption: string | null;
    // features: PlanFeature[];
    custom: true;
}

export type AnyPlan = PricingPlan | CustomPlan;

export interface BillingUnit {
    key: BillingUnitKey;
    name: string;
    thresholdStart: number;
    thresholdEnd: number | null;
}
export interface PlanCategory {
    key: PlanCategoryKey;
    caption: string | null;
    name: string;
    currency: string;
    billingUnit?: BillingUnit;
    description: string | null;
    features: PlanFeature[];
    plans: AnyPlan[];
    fromAmount: number;
}
