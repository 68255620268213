import { HomeGuideAggregate } from '../../../strategy';

export const HOME_VIDEOS: HomeGuideAggregate[] = [
    {
        id: '1',
        title: `Improvement opportunities`,
        url: `https://www.loom.com/embed/3ccaf323ed1f4b48b551d70838b4a02c?sid=767cb150-edfe-4807-8f81-841730654258`,
        audience: ['agency', 'brand'],
    },
    {
        id: '2',
        title: `Is it me or the market?`,
        url: `https://www.loom.com/embed/34e32e33b9f946f1b7300ce7a9e09a88?sid=40974f1e-a58e-4512-abd6-586e7477dee8`,
        audience: ['agency', 'brand'],
    },
    {
        id: '3',
        title: `Holiday planning`,
        url: `https://www.loom.com/embed/ac371d62228f46d48e4f5b8bbf2b1acb?sid=c8527e20-c6f9-4bb9-9f4f-d4f65c957956`,
        audience: ['brand'],
    },
    {
        id: '4',
        title: `New audits`,
        url: `https://www.loom.com/embed/6ac41092c07e4f438d3ece2bff6f9a01?sid=158b8e30-d00c-4763-8ddb-3d8db42c704a`,
        audience: ['agency'],
    },
];
