import { useMemo } from 'react';
import { combineCollectionStatus } from '../../../base';
import { useWorkspaceContextV2 } from '../../../context';
import { PluginEntity } from '../../../domain';
import { HomeIntegrationConfig } from './homeIntegrationConfig';
import { HomeIntegrationLoader } from './homeIntegrationInterface';
import { HomeIntegrationAggregate } from './homeIntegrationModel';

export function createHomeIntegrationLoader(
    config: HomeIntegrationConfig
): HomeIntegrationLoader {
    const { repository } = config;
    return {
        useData(context, props) {
            // const connection = repository.connection.useFind(context, {});
            // const plugin = repository.plugin.useFind(context, {}, {});

            const { connections: connection, plugins: plugin } = useWorkspaceContextV2();

            const pluginById = useMemo(() => {
                return (
                    plugin.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {}
                );
            }, [plugin]);

            if (!(connection.isFetched && plugin.isFetched)) {
                return {
                    item: {
                        status: 'loading',
                        data: null,
                    },
                };
            }

            return {
                item: {
                    status: 'loaded',
                    data: {
                        limit: 100,
                        total: connection.data?.length ?? 0,
                        items:
                            connection.data?.flatMap(
                                (connection): HomeIntegrationAggregate[] => {
                                    const plugin = pluginById[connection.plugin.id];
                                    if (!plugin) {
                                        console.warn(
                                            `plugin ${connection.plugin.id} not found`,
                                            pluginById
                                        );
                                        return [];
                                    }
                                    return [{ plugin, connection }];
                                }
                            ) ?? [],
                    },
                },
            };
        },
    };
}
