import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import { assert } from '../../../util/assert';
import { assertNever } from '../../../util';
import {
    isBooleanType,
    isEnumLikeType,
    isEnumType,
    isFloatType,
    isIntegerType,
    isNumericType,
    isStringType,
    isTreeType,
} from '../../../domain/attributes';
import { AnyInputControllerPropsV2, AnyInputViewPropsV2 } from '../../../ui';
import { FormFieldItemAggregate } from '../field';
import { FormInputControllerProps } from './formInputProps';

export function buildFormInputProps(
    // form: UseFormReturn<DatasetFormFormValues>,
    // formValues: DatasetFormFormValues,
    item: FormFieldItemAggregate,
    props: FormInputControllerProps
): AnyInputControllerPropsV2 {
    // const value = formValues.payload[item.property.key];
    const value = props.value;

    if (isNumericType(item.property.type)) {
        assert(
            typeof value === 'number' || value === null || value === undefined,
            'invalid number value'
        );
        return {
            kind: 'number',
            value: value ?? undefined,
            onChange: props.onChange,
            ...(isIntegerType(item.property.type) ? {} : { precision: 1, step: 0.1 }),
        };
    }
    if (isBooleanType(item.property.type)) {
        assert(
            typeof value === 'boolean' || value === null || value === undefined,
            'invalid boolean value'
        );
        return {
            kind: 'checkbox',
            value: value ?? false,
            onChange: props.onChange,
        };
    }
    if (isEnumLikeType(item.property.type)) {
        // TODO support nested tree options
        assert(
            typeof value === 'string' || value === null || value === undefined,
            'invalid enum value'
        );
        return {
            kind: 'dropdown',
            value: value ?? undefined,
            onChange: props.onChange,
            options: item.property.type.members.map((member) => ({
                label: member.label,
                value: member.value,
            })),
        };
    }
    if (isStringType(item.property.type)) {
        assert(
            typeof value === 'string' || value === null || value === undefined,
            'invalid string value'
        );
        return {
            kind: 'long_text',
            value: value ?? '',
            onChange: props.onChange,
        };
    }
    throw new Error(`unsupported property type ${JSON.stringify(item.property.type)}`);
    // assertNever(item.property.type);
}
