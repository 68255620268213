import {
    Box,
    Text,
    List,
    ListItem,
    ListIcon,
    Flex,
    Image,
    Heading,
    ImageProps,
    ButtonProps,
    VStack,
    HStack,
    BoxProps,
    Divider,
} from '@chakra-ui/react';
import { Button } from '../../../../ui';
import { PriceDto } from '../../../../api';
import React from 'react';
import { PlanCategory } from '../planType';
import checkmarkSVG from '../../../../svg/billing-check-icon.svg';
import { formatCurrency } from '../../../currency';

export interface PlanCategoryItemProps {
    containerProps?: Omit<BoxProps, 'children'>;
    category: PlanCategory;
    caption?: string;
    current?: boolean;
    onSelect(): void;
    recommended?: boolean;
    variant?: 'default' | 'ghost';
    imageProps?: Pick<ImageProps, 'src' | 'width' | 'height'>;
    buttonProps?: Pick<ButtonProps, 'children'>;
    index: number;
}

const COLORS = {
    PURPLE: '#7164ff',
    GREEN: '#95EF93',
    DARK_GREEN: '#3abb68',
};

export const PlanCategoryItem: React.FC<
    { children?: React.ReactNode | undefined } & PlanCategoryItemProps
> = ({ imageProps, category, recommended, variant = 'default', index, ...props }) => {
    const amount = formatCurrency(category.fromAmount / 100, {
        currency: category.currency,
    });

    return (
        <Box w="full">
            {recommended && (
                <Text
                    color="white"
                    fontSize="16px"
                    fontWeight="600"
                    textAlign={'center'}
                    marginBottom={'8px'}
                >
                    Recommended
                </Text>
            )}
            <Box
                w="full"
                h="full"
                fontSize="14px"
                background="white"
                borderBottomRadius="10px"
            >
                <VStack h="full" spacing={8}>
                    <VStack h="full" w="full" spacing={4} flex={1}>
                        <Text
                            // fontSize="16px"
                            fontSize="lg"
                            fontWeight="semibold"
                            color="black"
                            // marginBottom="5px"
                            textAlign="center"
                        >
                            {category.name}
                        </Text>
                        {imageProps?.src && (
                            <Flex minH={32} justifyContent="center">
                                <Image {...imageProps} objectFit="contain" alt="Icon" />
                            </Flex>
                        )}

                        <Divider
                            bg={COLORS.DARK_GREEN}
                            w="full"
                            h="1px"
                            opacity={1}
                        ></Divider>
                        <Heading color="black" textAlign="center" position="relative">
                            {category.key === 'enterprise' ? (
                                'Talk to us'
                            ) : (
                                <>
                                    {category.key != 'free' && (
                                        <Text
                                            display="inline"
                                            fontSize="sm"
                                            bottom="2px"
                                            position="relative"
                                            fontWeight="light"
                                            mr={1}
                                        >
                                            FROM
                                        </Text>
                                    )}
                                    <Text
                                        display="inline"
                                        fontSize="4xl"
                                        fontWeight="bold"
                                    >
                                        {amount}
                                    </Text>
                                    <Text
                                        position="relative"
                                        ml={1}
                                        bottom="2px"
                                        display="inline"
                                        fontSize="sm"
                                        fontWeight="semibold"
                                        color="blackAlpha.500"
                                    >
                                        /MTH
                                    </Text>
                                </>
                            )}
                        </Heading>
                        <Text
                            alignSelf="center"
                            margin="0 auto"
                            width="90%"
                            // color="#575757"
                            color="blackAlpha.600"
                            // marginBottom="16px"
                            textAlign="center"
                        >
                            {category.description}
                        </Text>
                        <List
                            w="full"
                            color="#575757"
                            spacing={2}
                            // spacing="10px"
                            // marginBottom="15px"
                            textAlign="left"
                        >
                            {props.caption && (
                                <ListItem>
                                    <Text size="xs" fontWeight="bold">
                                        {props.caption}
                                    </Text>
                                </ListItem>
                            )}
                            {category.features.map((item) => (
                                <HStack key={item.text} spacing={2}>
                                    <Image src={checkmarkSVG} />
                                    <ListItem>
                                        <Text>{item.text}</Text>
                                        {item.caption && (
                                            <Text fontSize="13px">{item.caption}</Text>
                                        )}
                                    </ListItem>
                                </HStack>
                            ))}
                        </List>
                    </VStack>
                    <Box w="full">
                        {props.current ? (
                            <Button
                                onClick={props.onSelect}
                                disabled={category.plans.length == 1}
                            >
                                Current plan
                            </Button>
                        ) : (
                            <Button
                                onClick={props.onSelect}
                                w="full"
                                {...(category.key == 'enterprise'
                                    ? {
                                          borderColor: COLORS.DARK_GREEN,
                                          borderWidth: 1,
                                          borderStyle: 'solid',
                                          bg: 'transparent',
                                          color: COLORS.DARK_GREEN,
                                          _hover: { bg: 'transparent' },
                                          _focus: {
                                              outline: 0,
                                              _hover: { bg: 'transparent' },
                                          },
                                      }
                                    : {
                                          color: 'whiteAlpha.900',
                                          bg: COLORS.DARK_GREEN,
                                          _hover: {
                                              color: 'whiteAlpha.900',
                                              bg: COLORS.DARK_GREEN,
                                          },
                                          _focus: {
                                              outline: 0,
                                              color: 'whiteAlpha.900',
                                              bg: COLORS.DARK_GREEN,
                                          },
                                      })}
                            >
                                {props.buttonProps?.children ?? 'Get started'}
                            </Button>
                        )}
                    </Box>
                    {/* <Flex align="center" justifyContent="center"> */}
                    {/* </Flex> */}
                </VStack>
            </Box>
        </Box>
    );
};
