import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Button,
    HStack,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverFooter,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useDashboardApi } from '../../../app';
import { DashboardRouteControlItem } from '../../../route';
import { DashboardControlCohortConfig } from './controlCohortConfig';
import { DashboardCohortView } from './controlCohortView';

export function createDashboardCohortControl(
    config: DashboardControlCohortConfig
): DashboardRouteControlItem {
    const {
        hook: { useStorage, useAdapter },
        controller,
        View,
    } = config;
    return {
        create({ useContext }) {
            return {
                id: config.id,
                Component: observer((props) => {
                    const disclosure = useDisclosure();
                    const context = useContext();
                    const storage = useStorage();
                    const adapter = useAdapter(storage.control);

                    const dashboard = useDashboardApi();
                    const configuration = dashboard.getConfiguration();
                    const cohortState = adapter.get(context);

                    const comparisonOptions = useMemo(() => {
                        if (configuration.cohort) {
                            return configuration.cohort.options.map((item) => ({
                                label: item.label,
                                value: item.value,
                            }));
                        } else return [];
                    }, [configuration.cohort?.options]);

                    const viewProps = controller.useProps(context, {
                        modes: configuration.cohort?.modes || [],
                        options: comparisonOptions,
                        value: cohortState ?? {
                            mode: 'dynamic',
                            config: {
                                dynamic: { cohorts: [], kind: 'dynamic' },
                                fixed: { cohorts: [], comparison: null },
                            },
                        },
                        onChange(value) {
                            return adapter.put(context, value);
                        },
                        // reset local form state when closing
                        formKey: disclosure.isOpen ? 'open' : 'closed',
                        onSubmit(values) {
                            disclosure.onClose();
                        },
                    });
                    if (!cohortState) {
                        return null;
                    }

                    const current = viewProps.initialValues?.config.fixed.comparison;
                    const mapped = viewProps.fields.comparison.options.find(
                        (item) => item.value === current
                    );

                    const dynamicLabel =
                        viewProps.fields.mode.options.find(
                            (item) => item.value === 'dynamic'
                        )?.label ?? 'N/A';

                    const fixedLabel = mapped?.label ?? current ?? 'None';

                    const label =
                        viewProps.initialValues?.mode === 'fixed'
                            ? fixedLabel
                            : dynamicLabel;

                    if (
                        !configuration.cohort ||
                        config.hiddenForDashboards.includes(props.dashboard.id)
                    ) {
                        return null;
                    }

                    return (
                        <DashboardCohortView
                            disclosure={disclosure}
                            onApply={viewProps.onSaveClick}
                            label={label}
                        >
                            <View {...viewProps} />
                        </DashboardCohortView>
                    );
                }),
            };
        },
    };
}
