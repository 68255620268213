import { useMemo } from 'react';

import { assert } from '../../../../util/assert';
import {
    AnyResolvedType,
    EnumMember,
    flattenEnumMembers,
    getBetweenConstraintBounds,
    getIntervalByConstraints,
    isCurrencyType,
    isEnumLikeType,
    isGreaterThanOrEqualCondition,
    isNumericBetweenCondition,
    isNumericType,
    isTreeType,
    Property,
} from '../../../../domain/attributes';
import { keyBySafe } from '../../../../util';
import { FilterItem } from '../../../common';
import { PeerFilterListController } from './peerFilterListInterface';
import { PeerFilterListControllerConfig } from './peerFilterListConfig';
import {
    buildStateToConditions,
    buildConditionsToState,
    buildStatusProps,
} from './peerFilterListFactory';

export function createPeerFilterListController(
    config: PeerFilterListControllerConfig
): PeerFilterListController {
    const {
        infra: { formatter },
        controller: { filter: filterController },
    } = config;

    function getFilterItemInput(item: Property<AnyResolvedType>): FilterItem['input'] {
        if (isTreeType(item.type)) {
            return {
                kind: 'multi_select',
                options: item.type.members.map((member) => ({
                    label: member.label,
                    value: member.value,
                    children: member.children.map((child) => ({
                        label: child.label,
                        value: child.value,
                    })),
                })),
            };
        }
        if (isCurrencyType(item.type)) {
            const [min, max] = getBetweenConstraintBounds(item.constraints) ?? [];
            const steps = getIntervalByConstraints(item.type.constraints);
            assert(steps, 'failed to resolve interval for constraint');
            return {
                kind: 'range',
                scale: {
                    kind: 'static',
                    steps,
                },
                getLabel(value) {
                    const [left, right] = value;
                    const formatLeft = formatter.format(item.type, left, {
                        notation: 'short',
                    });
                    const formatRight = formatter.format(item.type, right, {
                        notation: 'short',
                    });
                    const suffixRight = right === max ? '+' : '';
                    return `${formatLeft} - ${formatRight}${suffixRight}`;
                },
            };
        }
        throw new Error(`type ${JSON.stringify(item.type)} not supported`);
    }

    return {
        useProps(context, item, props) {
            const { truncate = 3 } = props;

            // const preview = previewController.useProps(item.preview);

            const filterItems = useMemo(() => {
                return (
                    item.traits.map(
                        (trait): FilterItem => ({
                            key: trait.key,
                            label: trait.name,
                            description: trait.description ?? null,
                            Icon: null,
                            isDisabled: false,
                            input: getFilterItemInput(trait),
                        })
                    ) ?? []
                );
            }, [item.traits]);

            const conditionByKey = useMemo(
                () => keyBySafe(props.value, (item) => item.key),
                [props.value]
            );

            const filterValue = useMemo(
                () => buildConditionsToState(item, props.value),
                [item, props.value]
            );

            const membersByValuePropertyKey = useMemo(
                () =>
                    item.traits.reduce(
                        (acc, trait) => {
                            if (!isEnumLikeType(trait.type)) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [trait.key]: keyBySafe(
                                    flattenEnumMembers(trait.type.members),
                                    (item) => item.value
                                ),
                            };
                        },
                        {} as Record<
                            string,
                            Record<string, EnumMember | undefined> | undefined
                        >
                    ) ?? {},
                [item.traits]
            );

            const formattedByKey = useMemo(() => {
                return item.traits.reduce(
                    (acc, item) => {
                        const condition = conditionByKey[item.key];
                        if (!condition) {
                            return acc;
                        }
                        const valueOrValues = condition.value;
                        if (isEnumLikeType(item.type) && Array.isArray(valueOrValues)) {
                            const members = membersByValuePropertyKey[item.key] as Record<
                                string,
                                EnumMember | undefined
                            >;

                            const included = valueOrValues.slice(0, truncate);
                            const truncated = valueOrValues.slice(truncate);

                            let formatted = included
                                .map((value) => members[value as string]?.label ?? value)
                                .join(', ');

                            if (truncated.length > 0) {
                                formatted = `${formatted} and ${truncated.length} more`;
                            }

                            return {
                                ...acc,
                                [item.key]: formatted,
                            };
                        }
                        const bounds = getBetweenConstraintBounds(item.constraints);
                        if (
                            isNumericType(item.type) &&
                            isGreaterThanOrEqualCondition(condition)
                        ) {
                            const formatted = formatter.format(item.type, valueOrValues, {
                                notation: 'short',
                            });
                            return {
                                ...acc,
                                [item.key]: `${formatted}+`,
                            };
                        }
                        return {
                            ...acc,
                            [item.key]: formatter.format(item.type, valueOrValues, {
                                notation: 'short',
                            }),
                        };
                    },
                    {} as Record<string, string | undefined>
                );
            }, [item.traits, conditionByKey]);

            const filter = filterController.useProps(
                { items: filterItems },
                {
                    value: filterValue,
                    onChange(value) {
                        const conditions = buildStateToConditions(item, value);
                        props.onChange(conditions);
                    },
                    getLabel(item) {
                        const label = formattedByKey[item.key];
                        if (item.input.kind === 'range') {
                            return `${item.label} ${label}`;
                        }
                        if (item.input.kind === 'multi_select') {
                            return `${item.label} in ${label}`;
                        }
                        return label ?? 'N/A';
                    },
                }
            );

            return {
                filter,
                // preview,
                match: buildStatusProps(item),
            };
        },
    };
}
