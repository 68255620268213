import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { VisualizationLoader } from './visualizationInterface';

export function createVisualizationLoader(
    config: AnalysisRouteDeps
): VisualizationLoader {
    return {
        async load(props) {
            const response = await config.content.executor.execute(
                props.context,
                props.request
            );
            return { response };
        },
    };
}
