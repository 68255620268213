import { EmailConfig } from './emailConfig';

export function createInitialContent(config: EmailConfig) {
    return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="utf-8" />
                <style>
                    ${config.css}
                </style>
                </style>
            </head>
            <body>
                <div></div>
            </body>
        </html>
    `;
}
