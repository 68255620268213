import { useQuery } from '@tanstack/react-query';
import { VisualizationAdapterV2 } from './visualizationAdapter';
import { VisualizationRepositoryV2 } from './visualizationInterface';

export function createVisualizationRepositoryV2(
    adapter: VisualizationAdapterV2
): VisualizationRepositoryV2 {
    return {
        useLookup(context, props, options) {
            const result = useQuery({
                ...options,
                queryKey: ['visualization', 'v2', context.organization, props],
                async queryFn() {
                    const response = await adapter.create(context, props);
                    return response;
                },
            });
            return result;
        },
    };
}
