import { capitalize } from 'lodash';
import { RoleAdapter } from '../../../app/permissions';
import { PermissionRoleImplConfig } from './permissionRoleConfig';

export function createPermissionRoleImpl(config: PermissionRoleImplConfig): RoleAdapter {
    const {
        api: { platform: api },
    } = config;
    return {
        async find(context, query) {
            const response = await api.me.get(context);
            const organization = response.organizations.find(
                (candidate) => candidate.organization.id === query.organization.id
            );
            if (!organization) {
                return [];
            }
            return [
                {
                    id: organization.role,
                    name: capitalize(organization.role),
                    kind: 'organization',
                },
            ];
        },
    };
}
