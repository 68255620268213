import React from 'react';
import {
    Tooltip,
    VStack,
    Grid,
    GridItem,
    HStack,
    Wrap,
    WrapItem,
    Text,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FilterListViewProps } from './filterListProps';

export const FilterListView: React.FC<
    { children?: React.ReactNode | undefined } & FilterListViewProps
> = (props) => {
    return (
        <HStack spacing={4}>
            <Wrap>
                <WrapItem alignItems="center" pr={2}>
                    <HStack spacing={2}>
                        <Text {...props.style?.label}>filters</Text>
                        <Tooltip
                            label={
                                <Text maxW={64}>
                                    Compare your performance in specific campaign types
                                    and user segments by filtering both your data and the
                                    data of your competitive set
                                </Text>
                            }
                        >
                            <InfoOutlineIcon color={props.style?.label?.color} />
                        </Tooltip>
                    </HStack>
                </WrapItem>
                {props.filters.map((filter, index) => (
                    <WrapItem key={index}>{props.renderFilter(filter, index)}</WrapItem>
                ))}
            </Wrap>
        </HStack>
    );
    // return (
    //     <Grid {...props.style?.wrapper} gridTemplateColumns="1fr min-content" gap={4}>
    //         <GridItem display="flex" alignItems="center">
    //             {props.filters.length > 0 && (
    //                 <HStack spacing={4}>
    //                     <Wrap>
    //                         <WrapItem alignItems="center" pr={2}>
    //                             <HStack spacing={2}>
    //                                 <Text {...props.style?.label}>filters</Text>
    //                                 <Tooltip
    //                                     label={
    //                                         <Text maxW={64}>
    //                                             Compare your performance in specific
    //                                             campaign types and user segments by
    //                                             filtering both your data and the data of
    //                                             your competitive set
    //                                         </Text>
    //                                     }
    //                                 >
    //                                     <InfoOutlineIcon
    //                                         color={props.style?.label?.color}
    //                                     />
    //                                 </Tooltip>
    //                             </HStack>
    //                         </WrapItem>
    //                         {props.filters.map((filter, index) => (
    //                             <WrapItem key={index}>
    //                                 {props.renderFilter(filter, index)}
    //                             </WrapItem>
    //                         ))}
    //                     </Wrap>
    //                 </HStack>
    //             )}
    //         </GridItem>
    //         {props.timestamp && (
    //             <GridItem>
    //                 <HStack
    //                     spacing={2}
    //                     h="100%"
    //                     justifyContent="flex-end"
    //                     whiteSpace="nowrap"
    //                 >
    //                     {/* <Button h="full" variant="link" size="sm">
    //                         Add breakdown
    //                     </Button> */}
    //                     {/* <Tag fontSize="md" h="full">
    //                         Breakdown: Targeting Type
    //                     </Tag> */}
    //                     {props.renderDate(props.timestamp)}
    //                 </HStack>
    //             </GridItem>
    //         )}
    //     </Grid>
    // );
};
