import React from 'react';
import { LockIcon } from '@chakra-ui/icons';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { useFeatureApi } from '../../../app';

export function createFeatureDataFiltersStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    return config.route.createDashboardRoute({
                        ...routeConfig,
                        controller: {
                            ...routeConfig.controller,
                            useFilterItem(props) {
                                const api = useFeatureApi();
                                const featureset = api.getFeatureset();
                                const mapped =
                                    routeConfig.controller.useFilterItem(props);
                                return {
                                    ...mapped,
                                    getTriggerProps() {
                                        if (
                                            featureset.data_filters &&
                                            !featureset.data_filters?.enabled
                                        ) {
                                            return {
                                                ...mapped.getTriggerProps(),
                                                allowClickWhenDisabled: true,
                                                disabled: true,
                                                tooltipProps: {
                                                    label: 'Upgrade to use data filters',
                                                },
                                                onClick: api.restrictClick(
                                                    featureset.data_filters
                                                ),
                                                rightIcon: <LockIcon />,
                                            };
                                        }
                                        return { ...mapped.getTriggerProps() };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
    };
}
