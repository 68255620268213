import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { IndustryResource } from './industryInterface';
import { IndustryListSchema } from './industrySchema';

export function createIndustryResource(client: AxiosInstance): IndustryResource {
    return {
        async find(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            headers = {
                ...headers,
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get('/api/v1/companies/industries', {
                params: {
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = IndustryListSchema.parse(response.data);
                return {
                    data: parsed.items,
                };
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
    };
}
