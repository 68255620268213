import { useDisclosure } from '@chakra-ui/hooks';
import React, { useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { useAuthenticatedContext } from '../../../../container';
import { useAuthenticatedApi } from '../../../../infra';
import { FeatureContext, FeatureContextValue } from './featureContext';
import { FeatureSetAdapter } from './featureAdapter';

export interface FeatureConfig {
    getDeps(config: { api: AxiosInstance }): FeatureSetAdapter;
}

export interface FeatureSetProviderProps {}

export function createFeatureProvider(
    config: FeatureConfig
): React.FC<{ children?: React.ReactNode | undefined } & FeatureSetProviderProps> {
    return (props) => {
        // const appcontext = useAuthenticatedContext();
        const api = useAuthenticatedApi();
        const adapter = useMemo(
            () =>
                config.getDeps({
                    api: api,
                }),
            [api]
        );
        const disclosure = useDisclosure();
        const context: FeatureContextValue = {
            adapter,
            disclosure,
        };

        // const accessViewProps = useAccessView(
        //     appcontext,
        //     features.dashboard
        //         ? {
        //               ...features.dashboard,
        //               value: extras.dashboard,
        //           }
        //         : null
        // );

        return (
            <FeatureContext.Provider value={context}>
                {props.children}
            </FeatureContext.Provider>
        );
    };
}
