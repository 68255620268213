import { useMemo } from 'react';
import { PlanSelectControllerConfig } from './planListConfig';
import { buildPlanSelectProps } from './planListFactory';
import { PlanSelectController } from './planListInterface';
import { PlanSelectViewProps } from './planListProps';

export function createPlanSelectController(
    config: Pick<PlanSelectControllerConfig, 'infra'>
): PlanSelectController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props) {
            const viewProps = useMemo<PlanSelectViewProps>(() => {
                return buildPlanSelectProps(formatter, {
                    item: props.item,
                    value: props.value,
                    onChange: props.onChange,
                });
            }, [props.item, props.value]);
            return viewProps;
        },
    };
}
