import React from 'react';
import { createInputMultiSelectContainer } from './choice';
import { createRangeInputContainer } from './range';
import { AnyInputContainerProps } from './inputProps';

export function createInputContainer(): React.FC<
    { children?: React.ReactNode | undefined } & AnyInputContainerProps
> {
    const Container = {
        Range: createRangeInputContainer(),
        MultiSelect: createInputMultiSelectContainer(),
    };
    return ({ as: View, ...rest }) => {
        if (rest.kind === 'range') {
            return <Container.Range as={View.Range} {...rest} />;
        }
        if (rest.kind === 'multi_select') {
            return <Container.MultiSelect as={View.MultiSelect} {...rest} />;
        }
        return <>not impl</>;
    };
}
