import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Radio,
    StackDivider,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineClockCircle,
    AiOutlineInfo,
    AiOutlineInfoCircle,
} from 'react-icons/ai';
import { CgRadioChecked } from 'react-icons/cg';
import { PiMagicWand } from 'react-icons/pi';
import { VAROS_YELLOW } from '../../../../config';
import { Icons } from '../../../../../config';
import { AttributeItem, AttributeList } from '../../../common';
import { StudiesViewBaseConfig } from '../../base';
import { SurveyDetailViewProps } from './surveyDetailProps';

export function createSurveyDetailView(
    config: StudiesViewBaseConfig
): React.FC<{ children?: React.ReactNode | undefined } & SurveyDetailViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;
    const RadioIcon = CgRadioChecked;
    return (props) => {
        return (
            <Grid
                templateAreas={`
                    "header sidebar"
                    "content sidebar"
                `}
                templateColumns={{
                    base: 'minmax(0, 5fr) 2fr',
                    lg: 'minmax(0, 5fr) 2fr',
                }}
                columnGap={8}
                rowGap={8}
                w="full"
            >
                <GridItem gridArea="header">
                    <VStack w="full" align="start" spacing={12}>
                        <VStack
                            w="full"
                            // justify="space-between"
                            spacing={4}
                            align="start"
                        >
                            <VStack w="full" align="start" spacing={3}>
                                <HStack
                                    fontWeight="semibold"
                                    spacing={2}
                                    divider={
                                        <Icon
                                            boxSize="1.20rem"
                                            color="whiteAlpha.600"
                                            as={ChevronRightIcon}
                                            border="none"
                                        />
                                    }
                                >
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Reports
                                        </Text>
                                    </Link>
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Surveys
                                        </Text>
                                    </Link>
                                </HStack>
                                <VStack align="start" w="full" spacing={6}>
                                    <HStack
                                        w="full"
                                        justify="space-between"
                                        align="center"
                                    >
                                        <HStack spacing={4} w="full">
                                            <Box
                                                p={3}
                                                bg="whiteAlpha.300"
                                                borderRadius="sm"
                                            >
                                                <Icon
                                                    w={6}
                                                    h={6}
                                                    as={Icons.Reports.Report}
                                                />
                                            </Box>
                                            <VStack align="start" spacing={1}>
                                                <HStack spacing={3}>
                                                    <Heading fontSize="xl">
                                                        {props.survey.title}
                                                    </Heading>
                                                </HStack>
                                                <HStack
                                                    spacing={2}
                                                    fontSize="sm"
                                                    fontWeight="medium"
                                                    divider={
                                                        <Box border="none">&#x2022;</Box>
                                                    }
                                                    color="whiteAlpha.600"
                                                    userSelect="none"
                                                >
                                                    {props.tag.items.map((tag, index) => (
                                                        <Tooltip
                                                            key={index}
                                                            shouldWrapChildren={true}
                                                            placement="top"
                                                            hasArrow={true}
                                                            p={3}
                                                            bg="white"
                                                            color="blackAlpha.900"
                                                            isDisabled={
                                                                tag.tooltip === null
                                                            }
                                                            label={tag.tooltip?.label}
                                                        >
                                                            <HStack spacing={2}>
                                                                {tag.Icon && (
                                                                    <Icon as={tag.Icon} />
                                                                )}
                                                                <Text>{tag.label}</Text>
                                                            </HStack>
                                                        </Tooltip>
                                                    ))}
                                                </HStack>
                                            </VStack>
                                        </HStack>
                                    </HStack>
                                    {props.survey.description && (
                                        <VStack align="start" w="full">
                                            <Text fontSize="md" color="whiteAlpha.700">
                                                {props.survey.description}
                                            </Text>
                                        </VStack>
                                    )}
                                </VStack>
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
                <GridItem gridArea="content">
                    <VStack align="start" w="full" spacing={8}>
                        <VStack align="start" w="full" spacing={4}>
                            <HStack
                                w="full"
                                fontSize="xl"
                                color="whiteAlpha.800"
                                fontWeight="semibold"
                                spacing={3}
                            >
                                <Text>Choose survey type</Text>
                                {/* <Tooltip
                                    shouldWrapChildren={true}
                                    placement="top"
                                    hasArrow={true}
                                    p={3}
                                    bg="white"
                                    color="blackAlpha.900"
                                    label={
                                        <Text>
                                            Consectetur aute dolore mollit voluptate elit.
                                            Reprehenderit duis incididunt nisi sit minim
                                            minim exercitation aliquip tempor
                                        </Text>
                                    }
                                >
                                    <Icon as={AiOutlineInfoCircle} />
                                </Tooltip> */}
                            </HStack>
                            <VStack align="start" w="full" spacing={4}>
                                <HStack w="full" spacing={4}>
                                    {props.option.mode.items.map((item) => {
                                        const itemProps =
                                            props.option.mode.getItemProps(item);
                                        return (
                                            <VStack
                                                {...itemProps.container}
                                                key={itemProps.id}
                                                aria-selected={itemProps.isSelected}
                                                aria-disabled={itemProps.isDisabled}
                                                flex={1}
                                                borderRadius="lg"
                                                borderStyle="solid"
                                                borderWidth={2}
                                                borderColor="whiteAlpha.300"
                                                p={6}
                                                cursor="pointer"
                                                _hover={{
                                                    bg: 'whiteAlpha.50',
                                                }}
                                                _selected={{
                                                    bg: 'whiteAlpha.100',
                                                }}
                                            >
                                                <HStack
                                                    w="full"
                                                    spacing={3}
                                                    justify="space-between"
                                                >
                                                    <HStack
                                                        spacing={3}
                                                        fontWeight="semibold"
                                                    >
                                                        {itemProps.Icon && (
                                                            <Icon as={itemProps.Icon} />
                                                        )}
                                                        <Text>{itemProps.title}</Text>
                                                    </HStack>
                                                    {itemProps.isSelected && (
                                                        <Icon
                                                            fontSize="lg"
                                                            as={RadioIcon}
                                                            color={VAROS_YELLOW[200]}
                                                        />
                                                    )}
                                                </HStack>
                                                <Text color="whiteAlpha.600">
                                                    {itemProps.description}
                                                </Text>
                                            </VStack>
                                        );
                                    })}
                                </HStack>
                                <HStack w="full" justify="end">
                                    <Link {...props.link.new}>
                                        <Button
                                            color={VAROS_YELLOW['200']}
                                            borderColor={VAROS_YELLOW['200']}
                                            borderRadius="full"
                                            bg="none"
                                            variant="outline"
                                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                                            _active={{ bg: 'whiteAlpha.100' }}
                                            _hover={{ bg: 'whiteAlpha.100' }}
                                            _focus={{ outline: 'none' }}
                                        >
                                            {props.button.join.label}
                                        </Button>
                                    </Link>
                                </HStack>
                            </VStack>
                        </VStack>
                        {props.submission.items.length > 0 && (
                            <VStack align="start" w="full" spacing={4}>
                                <HStack w="full" justify="space-between">
                                    <Text
                                        fontSize="xl"
                                        color="whiteAlpha.800"
                                        fontWeight="semibold"
                                    >
                                        My submissions
                                    </Text>
                                    <Link {...props.link.overview}>
                                        <Text
                                            color="blue.300"
                                            fontWeight="medium"
                                            _hover={{ textDecor: 'underline' }}
                                        >
                                            View all
                                        </Text>
                                    </Link>
                                </HStack>
                                <VStack
                                    align="start"
                                    w="full"
                                    borderColor="whiteAlpha.300"
                                    borderWidth={2}
                                    borderRadius="lg"
                                    spacing={0}
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.300"
                                            borderWidth={1}
                                        />
                                    }
                                >
                                    {props.submission.items.map((item) => {
                                        const itemProps =
                                            props.submission.getItemProps(item);
                                        return (
                                            <Link
                                                style={{ width: '100%' }}
                                                key={itemProps.id}
                                                {...itemProps.links.detail}
                                            >
                                                <HStack
                                                    w="full"
                                                    fontWeight="medium"
                                                    p={5}
                                                    cursor="pointer"
                                                    _hover={{ bg: 'whiteAlpha.100' }}
                                                >
                                                    <HStack spacing={4}>
                                                        <Box
                                                            bg="whiteAlpha.300"
                                                            borderRadius="sm"
                                                            p={2.5}
                                                        >
                                                            <Icon
                                                                as={
                                                                    Icons.Reports
                                                                        .Submission
                                                                }
                                                            />
                                                        </Box>
                                                        <Text>
                                                            {
                                                                itemProps.date.relative
                                                                    .label
                                                            }
                                                        </Text>
                                                    </HStack>
                                                </HStack>
                                            </Link>
                                        );
                                    })}
                                </VStack>
                            </VStack>
                        )}
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
