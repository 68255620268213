import React from 'react';
import { IntegrationConf } from '../../models/integration';
import { ErrorInfo } from '../../models/Errors';
import {
    IntegrationCardListContainer,
    IntegrationCardListGroup,
    IntegrationCardList,
    IntegrationCardListContainerProps,
    IntegrationCardListProps,
    UserIntegrationDefinition,
} from '../../domain';
import { useIntegrationCardList } from '../../hooks/integrations/hooks';
// import { IntegrationItemAssetViewProps } from './asset/IntegrationItemAssetView';
import { useAuthenticatedContext } from '../../hooks';
import {
    useIntegrationDefinitions,
    useIntegrationTheme,
} from '../../hooks/integrations/hooks';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertProps,
    AlertTitle,
    Button,
    HStack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export interface IntegrationCardListViewProps {
    containerProps?: IntegrationCardListContainerProps;
    listProps?: IntegrationCardListProps;
    definitions: IntegrationConf[];
    // itemProps?: Pick<IntegrationItemAssetViewProps, 'isLoadingId' | 'isLoadingConnection'>;
    error?: ErrorInfo;
    getAlertProps?(): IntegrationCardAlertProps;
    renderItem: (props: { definition: UserIntegrationDefinition }) => React.ReactNode;
    suspense?: boolean;
}

export type IntegrationCardAlertProps =
    | (Pick<AlertProps, 'status'> & {
          title: string;
          description: string;
          action: {
              to: string;
              label: string;
          } | null;
      })
    | null;

export const IntegrationCardListView: React.FC<
    { children?: React.ReactNode | undefined } & IntegrationCardListViewProps
> = ({ ...props }) => {
    const context = useAuthenticatedContext();
    const theme = useIntegrationTheme();
    const definitions = useIntegrationDefinitions(context, { suspense: props.suspense });
    const { groups } = useIntegrationCardList({ definitions: definitions.data ?? [] });

    const renderItem = (definition: UserIntegrationDefinition) => {
        return props.renderItem({
            definition,
        });
    };

    const alertProps = props.getAlertProps?.() ?? null;

    return (
        <VStack w="full" spacing={4} px={4}>
            {alertProps && (
                <Alert status={alertProps.status}>
                    <AlertIcon />
                    <AlertTitle whiteSpace="nowrap">{alertProps.title}</AlertTitle>
                    <AlertDescription w="full" fontWeight="medium">
                        <HStack w="full" justify="space-between">
                            <Text>{alertProps.description}</Text>
                            {alertProps.action && (
                                <Link to={alertProps.action.to}>
                                    <Button size="sm">{alertProps.action.label}</Button>
                                </Link>
                            )}
                        </HStack>
                    </AlertDescription>
                </Alert>
            )}
            <IntegrationCardListContainer {...props.containerProps}>
                {groups.map((group, index) => (
                    <IntegrationCardListGroup
                        key={index}
                        title={group.title}
                        description={group.description}
                        theme={theme}
                        containerProps={props.listProps}
                    >
                        <IntegrationCardList {...props.listProps}>
                            {group.definitions.map(renderItem)}
                        </IntegrationCardList>
                    </IntegrationCardListGroup>
                ))}
                {props.children}
            </IntegrationCardListContainer>
        </VStack>
    );
};
