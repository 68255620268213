import { Dashboard } from '../../domain/demo';
import {
    getFacebookQuery,
    getGoogleQuery,
    getTiktokQuery,
    getShopifyQuery,
    AD_METRICS,
    SHOPIFY_METRICS,
} from './dashboard';
import * as Properties from './property';

// in production the api key is injected through a reverse proxy
export const DEMO_API_KEY = process.env.DEMO_API_KEY ?? null;

export const DEMO_CONFIG: { dashboards: Dashboard[] } = {
    dashboards: [
        {
            id: 'facebook',
            name: 'Facebook',
            icon: 'https://cdn.varos.io/assets/common/facebook_logo.png',
            query: getFacebookQuery(),
            visualization: { kind: 'line' },
            metrics: AD_METRICS,
            properties: [
                Properties.VERTICAL_PROPERTY,
                Properties.FACEBOOK_MONTHLY_SPEND_PROPERTY,
                Properties.FACEBOOK_AOV_PROPERTY,
                Properties.FACEBOOK_OBJECTIVE_PROPERTY,
            ],
        },
        {
            id: 'google',
            name: 'Google',
            icon: 'https://cdn.varos.io/assets/common/google_a2d2s.png',
            query: getGoogleQuery(),
            visualization: { kind: 'line' },
            metrics: AD_METRICS,

            properties: [
                Properties.VERTICAL_PROPERTY,
                Properties.GOOGLE_MONTHLY_SPEND_PROPERTY,
                Properties.GOOGLE_AOV_PROPERTY,
                Properties.GOOGLE_NETWORK_PROPERTY,
            ],
        },
        {
            id: 'tiktok',
            name: 'TikTok',
            icon: 'https://cdn.varos.io/assets/common/TikTok-icon-glyph.png',
            query: getTiktokQuery(),
            visualization: { kind: 'line' },
            metrics: AD_METRICS,
            properties: [
                Properties.VERTICAL_PROPERTY,
                Properties.TIKTOK_MONTHLY_SPEND_PROPERTY,
                Properties.TIKTOK_OPTIMIZATION_GOAL_PROPERTY,
            ],
        },
        {
            id: 'shopify',
            name: 'Shopify',
            icon: 'https://cdn.varos.io/assets/common/shopify_logo.png',
            query: getShopifyQuery(),
            visualization: { kind: 'line' },
            metrics: SHOPIFY_METRICS,
            properties: [
                Properties.VERTICAL_PROPERTY,
                Properties.AOV_SHOPIFY_PROPERTY,
                Properties.SHOPIFY_MONTHLY_REVENUE_PROPERTY,
            ],
        },
    ],
};
