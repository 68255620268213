import { DomainLayoutController } from '../../../layout';
import { PeerLayoutController } from './peerLayoutInterface';

export function createPeerLayoutController(controller: {
    layout: DomainLayoutController;
}): PeerLayoutController {
    return {
        useProps(context, props) {
            const { layout: layoutController } = controller;
            const layout = layoutController.useProps(context, props.layout ?? {});
            return { layout };
        },
    };
}
