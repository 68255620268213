import React from 'react';
import {
    Link as ChakraLink,
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Text,
    VStack,
    Spinner,
    Center,
    Box,
    StackDivider,
} from '@chakra-ui/react';
import { HomeExpertCallViewProps, HomeGuideViewProps } from '../../../strategy';
import { RemoteIframe } from '../../../../ui';
import { HomeItemConfig } from '../homeRouteModel';
import { AiOutlineEyeInvisible } from 'react-icons/ai';

export function createHomeExpertCallSecondaryView(init: HomeItemConfig): React.FC<
    { children?: React.ReactNode | undefined } & HomeExpertCallViewProps & {
            onHide: React.MouseEventHandler;
        }
> {
    const {
        UI: { Anchor, Link },
    } = init;
    return (props) => {
        return (
            <HStack spacing={6}>
                <Link {...props.links.overview}>
                    <Button color="blue.400" variant="link" _focus={{ outline: 'none' }}>
                        Learn more about high performer calls
                    </Button>
                </Link>
                <Button
                    variant="link"
                    size="sm"
                    rightIcon={<Icon as={AiOutlineEyeInvisible} />}
                    onClick={props.onHide}
                    _focus={{ outline: 'none' }}
                >
                    Hide
                </Button>
            </HStack>
        );
    };
}

export function createHomeExpertCallView(init: HomeItemConfig): React.FC<
    { children?: React.ReactNode | undefined } & HomeExpertCallViewProps & {
            onHide: React.MouseEventHandler;
        }
> {
    const {
        UI: {
            Tooltip,
            Icon: { IconGroup, Icon },
            EmptyState,
            Link,
        },
    } = init;
    return (props) => {
        return (
            <HStack
                w="full"
                p={6}
                borderColor="whiteAlpha.300"
                borderWidth={2}
                borderStyle="solid"
                borderRadius="lg"
                spacing={6}
                justify="space-between"
            >
                <VStack align="start" w="full" spacing={1}>
                    <HStack w="full">
                        <Text fontSize="lg" fontWeight="semibold">
                            Book a 1:1 call with a high performer in your peer group
                        </Text>
                    </HStack>
                    <Text fontWeight="medium" color="whiteAlpha.700" maxW="42rem">
                        The high performers in your peer group are also Varos customers
                        and most are willing to get on a call (for a small fee) to share
                        tactics and what's working for them to achieve these results
                    </Text>
                </VStack>
                <HStack
                    w="full"
                    justify="end"
                    align="center"
                    maxW="12rem"
                    // divider={
                    //     <StackDivider
                    //         borderColor="whiteAlpha.300"
                    //         borderWidth={1}
                    //     />
                    // }
                    spacing={4}
                >
                    <HStack
                        justify="center"
                        align="center"
                        fontWeight="medium"
                        color="whiteAlpha.600"
                        cursor="pointer"
                        whiteSpace="nowrap"
                    >
                        <Text
                            onClick={props.onHide}
                            _hover={{ textDecoration: 'underline' }}
                        >
                            No thanks
                        </Text>
                    </HStack>
                    <Link {...props.links.new}>
                        <Button
                            flexGrow={0}
                            flexShrink={0}
                            colorScheme="green"
                            w="full"
                            variant="outline"
                        >
                            Request a call
                        </Button>
                    </Link>
                </HStack>
            </HStack>
        );
        // return (
        //     <Grid
        //         w="full"
        //         gap={6}
        //         templateColumns={{
        //             base: '1fr',
        //             sm: '1fr 1fr',
        //             xl: '1fr 1fr 1fr',
        //         }}
        //     >
        //         <GridItem>
        //             <VStack
        //                 w="full"
        //                 p={6}
        //                 borderColor="whiteAlpha.300"
        //                 borderWidth={2}
        //                 borderStyle="solid"
        //                 borderRadius="lg"
        //                 spacing={6}
        //             >
        //                 <VStack align="start" w="full">
        //                     <HStack w="full">
        //                         <Text fontSize="lg" fontWeight="semibold">
        //                             Book an expert call
        //                         </Text>
        //                     </HStack>
        //                     <Text fontWeight="medium" color="whiteAlpha.700">
        //                         Officia laborum pariatur fugiat irure cillum cillum labore
        //                         minim reprehenderit
        //                     </Text>
        //                 </VStack>
        //                 <VStack
        //                     align="start"
        //                     w="full"
        //                     // divider={
        //                     //     <StackDivider
        //                     //         borderColor="whiteAlpha.300"
        //                     //         borderWidth={1}
        //                     //     />
        //                     // }
        //                     spacing={3}
        //                 >
        //                     <Button colorScheme="green" w="full" variant="outline">
        //                         Book expert call
        //                     </Button>
        //                     <HStack
        //                         w="full"
        //                         justify="center"
        //                         fontWeight="medium"
        //                         color="whiteAlpha.600"
        //                         cursor="pointer"
        //                     >
        //                         <Text _hover={{ textDecoration: 'underline' }}>
        //                             No thanks
        //                         </Text>
        //                     </HStack>
        //                 </VStack>
        //             </VStack>
        //         </GridItem>
        //     </Grid>
        // );
    };
}
