import React, { useMemo } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { HomeDestinationController } from '../../../strategy';
import { FeedbackEnhancerConfig } from '../feedbackConfig';

export function createDestinationFeedbackEnhancer(
    init: Pick<FeedbackEnhancerConfig, 'getIntercom'>
): ApplicationEntryEnhancer {
    const { getIntercom } = init;
    return (create) => (config) => {
        function enhanceController(
            controller: HomeDestinationController
        ): HomeDestinationController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const Intercom = useMemo(() => getIntercom(), []);
                    return {
                        ...viewProps,
                        getItemProps(...args) {
                            const itemProps = viewProps.getItemProps(...args);
                            return {
                                ...itemProps,
                                connect: {
                                    ...itemProps.connect,
                                    onClick(...args) {
                                        if (!Intercom) {
                                            console.info(`intercom widget not available`);
                                            return itemProps.connect.onClick(...args);
                                        }
                                        Intercom('show');
                                        return itemProps.connect.onClick(...args);
                                    },
                                },
                            };
                        },
                    };
                },
            };
        }
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                home: {
                    ...config.controller.home,
                    createDestination(...args) {
                        const controller = config.controller.home.createDestination(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
