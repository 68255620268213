import React from 'react';
import { Chart, useChart, BarChartV2 } from '../../../../ui';
import { BarVisualizationProps } from '../barProps';
import { Box, Grid, GridItem } from '@chakra-ui/react';

// const COLORS = ['#7756ff', '#7fe1da', '#fe476a', '#2bd19a'];

export const BarVisualization: React.FC<
    { children?: React.ReactNode | undefined } & BarVisualizationProps
> = ({ ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: props.data.datasets,
    });
    return (
        <Grid width={props.width} height={props.height} templateRows="min-content 1fr">
            <GridItem p={6}>
                <Chart.Legend {...chart.getLegendProps()} />
            </GridItem>
            <GridItem>
                <BarChartV2
                    {...props}
                    type="bar"
                    stacked={false}
                    barStyle={{
                        barPercentage: 0.5,
                        barThickness: 42,
                        maxBarThickness: 32,
                        minBarLength: 2,
                    }}
                    data={{
                        ...props.data,
                        datasets: props.data.datasets.map((dataset) => ({
                            ...dataset,
                            hidden: !chart.isVisible({ id: dataset.label }),
                        })),
                    }}
                />
            </GridItem>
        </Grid>
    );
    // return (
    //     <Chart.Wrapper {...chart.getWrapperProps()}>
    //         <Chart.Legend {...chart.getLegendProps()} />
    //         <Chart.Content {...chart.getContentProps()}>
    //             <Box width={props.width} height={props.height}>
    //                 <BarChartV2
    //                     {...props}
    //                     type="bar"
    //                     stacked={false}
    //                     barStyle={{
    //                         barPercentage: 0.5,
    //                         barThickness: 42,
    //                         maxBarThickness: 32,
    //                         minBarLength: 2,
    //                     }}
    //                     data={{
    //                         ...props.data,
    //                         datasets: props.data.datasets.map((dataset) => ({
    //                             ...dataset,
    //                             hidden: !chart.isVisible({ id: dataset.label }),
    //                         })),
    //                     }}
    //                 />
    //             </Box>
    //         </Chart.Content>
    //     </Chart.Wrapper>
    // );
};

export const BarVisualizationV2: React.FC<
    { children?: React.ReactNode | undefined } & BarVisualizationProps
> = ({ ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: props.data.datasets,
    });
    return (
        <BarChartV2
            {...props}
            type="bar"
            stacked={false}
            data={{
                ...props.data,
                datasets: props.data.datasets.map((dataset) => ({
                    ...dataset,
                    hidden: !chart.isVisible({ id: dataset.label }),
                })),
            }}
        />
    );
    // return (
    //     <Chart.Wrapper {...chart.getWrapperProps()}>
    //         <Chart.Legend {...chart.getLegendProps()} />
    //         <Chart.Content {...chart.getContentProps()}>
    //             <BarChartV2
    //                 {...props}
    //                 width={props.width}
    //                 height={props.height}
    //                 stacked={false}
    //                 data={{
    //                     ...props.data,
    //                     datasets: props.data.datasets.map((dataset) => ({
    //                         ...dataset,
    //                         hidden: !chart.isVisible({ id: dataset.label }),
    //                     })),
    //                 }}
    //             />
    //         </Chart.Content>
    //     </Chart.Wrapper>
    // );
};
