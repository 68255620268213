import {
    AnyConditionDto,
    CreateVisualizationPayload,
    VisualizationMetricDto,
    VisualizationPayload,
} from '@varos/visualization-sdk';
import {
    VisualizationAdapterV2,
    VisualizationCreatePropsV2,
    VisualizationEntity,
} from '../../../app/visualization-v2';
import { assertNever } from '../../../util';
import { assert } from '../../../util/assert';
import { AnyVisualizationMetric } from '../../../app/visualization-v3';
import { AnyCondition } from '../../../domain/attributes';
import { VisualizationImplConfigV2 } from './visualizationImplConfig';

export function createVisualizationImplV2(
    config: VisualizationImplConfigV2
): VisualizationAdapterV2 {
    const {
        api: { visualization: api },
    } = config;

    function toEntity(dto: VisualizationPayload): VisualizationEntity {
        return dto;
    }

    function serializeCondition(condition: AnyCondition): AnyConditionDto {
        // @ts-expect-error
        return condition;
    }

    function serializeMetric(item: AnyVisualizationMetric): VisualizationMetricDto {
        if (item.kind === 'aggregation') {
            return {
                kind: 'aggregation',
                key: item.key,
                namespace: item.plugin,
                table: item.table ?? undefined,
                column: item.column ?? undefined,
                type: item.type,
                visible: item.visible,
                filter: undefined,
            };
        }
        if (item.kind === 'formula') {
            return {
                kind: 'formula',
                key: item.key,
                formula: item.formula,
                format: item.format ?? undefined,
                visible: item.visible,
            };
        }
        assertNever(item);
    }

    function serializePayload(
        dto: VisualizationCreatePropsV2
    ): CreateVisualizationPayload {
        return {
            ...dto,
            metrics: dto.metrics.map(serializeMetric),
            query: {
                ...dto.query,
                breakdown: dto.query.breakdown,
                filter: dto.query.filter
                    ? serializeCondition(dto.query.filter)
                    : undefined,
                period: dto.query.period,
            },
        };
    }

    return {
        async create(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            await context.auth.scheme.store.waitAuthenticated();
            const response = await api.visualization.create(serializePayload(props), {
                headers: {
                    Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                },
            });
            return toEntity(response);
        },
    };
}
