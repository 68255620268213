import React from 'react';
import { NetworkBaseRouteConfig } from '../base';
import { NetworkLayoutRouteViewProps } from './networkLayoutProps';

export function createNetworkLayoutView(
    config: NetworkBaseRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & NetworkLayoutRouteViewProps> {
    const {
        Layout: {
            Domain: { Shell: Layout },
        },
    } = config;
    return (props) => {
        return <Layout {...props.layout}>{props.children}</Layout>;
    };
}
