// import moment, { duration, unitOfTime } from 'moment';
// import { ExecFilterOperator } from '../../../models/DataSet';
import { DateFilterFormValues } from '../../../ui/datefilter/component/DateFilter';
import { DateRangesFeatureDto } from '../../../api';
import { periodDifference } from '../../../domain';
// import { DashboardCurrentParam } from '../../../models/Dashboard';
// import { TimeGranularity } from '../../../models/Common';

export function isDateRangeRestricted(
    config: DateRangesFeatureDto['config'],
    param: Pick<DateFilterFormValues, 'period'>
): boolean {
    if (!config || config.lookback_period_days === null) {
        return false;
    }
    const daysAgo = periodDifference(param.period, 'day');
    // console.log('restricted?', daysAgo, daysAgo > config.lookback_period_days, param);
    return daysAgo > config.lookback_period_days;
}

// function getLowerBoundTimestamp(param: DashboardCurrentParam) {
//     if (!param.value) {
//         return null;
//     }
//     const now = moment();
//     if (param.value.operator === ExecFilterOperator.on && !Array.isArray(param.value.value)) {
//         return moment(param.value.value?.value);
//     }
//     if (param.value.operator === ExecFilterOperator.before && !Array.isArray(param.value.value)) {
//         return moment(param.value.value?.value);
//     }
//     if (param.value.operator === ExecFilterOperator.after && !Array.isArray(param.value.value)) {
//         return moment(param.value.value?.value);
//     }
//     if (param.value.operator === ExecFilterOperator.between && Array.isArray(param.value.value)) {
//         const [{ value: start }, { value: end }] = param.value.value;
//         return moment(start);
//     }
//     if (param.value.operator === ExecFilterOperator.previous && Array.isArray(param.value.value)) {
//         const [{ value: value }, { value: granularity }] = param.value.value;
//         const momentGranularity = getMomentGranularity(granularity as TimeGranularity);
//         return now.subtract(duration(value, momentGranularity));
//     }
//     return null;
// }

// function getMomentGranularity(granularity: TimeGranularity) {
//     const mapping: { [TKey in TimeGranularity]: unitOfTime.Base | 'quarter' } = {
//         hour: 'hour',
//         day: 'day',
//         week: 'week',
//         month: 'month',
//         quarter: 'quarter',
//         year: 'year',
//     };
//     return mapping[granularity];
// }
