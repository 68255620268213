import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { InsightDefinitionDto } from './definitionModel';
import { InsightDefinitionResource } from './definitionInterface';
import { InsightDefinitionListResponseSchema } from './definitionSchema';
import moment from 'moment';

export function createInsightDefinitionResource(
    client: AxiosInstance
): InsightDefinitionResource {
    return {
        async list(context, query): Promise<ListResponse<InsightDefinitionDto>> {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get('/api/v1/insights/definitions', {
                params: {
                    expand: ['definition'],
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = InsightDefinitionListResponseSchema.parse(response.data);
            return parsed;
        },
    };
}
