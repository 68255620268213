import React, { FormEventHandler } from 'react';
import { useForm, DefaultValues } from 'react-hook-form';
import {
    // TagProps,
    useDisclosure,
    PopoverHeader,
    Button,
    // Tag,
    HStack,
    VStack,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    Icon,
    // TagProps,
    // TagCloseButton,
} from '@chakra-ui/react';
import { Tag, TagProps } from '../../../../../ui';
import { InputGroup, Input } from '../../../../ui/application/impl/forms';
import { AnyCondition } from '../../../../domain/attributes';
import { FilterItemInternalProps, FilterItemViewProps } from './filterItemProps';
import { useInternal } from './filterItemHook';
import { getInputProps } from './filterItemHelper';
import { LockIcon } from '@chakra-ui/icons';
// import { isSingleSelectConstraint } from '../../../../domain/dashboard';;

export type FilterItemFormValues = Pick<AnyCondition, 'value'>;

export const FilterItemView: React.FC<
    { children?: React.ReactNode | undefined } & FilterItemViewProps
> = (props) => {
    const internalProps = useInternal(props);
    return <Internal {...internalProps} />;
};

const Internal: React.FC<
    { children?: React.ReactNode | undefined } & FilterItemInternalProps
> = ({ filter, ...props }) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    // const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    //     props.formProps.onSubmit?.(event);
    //     onClose();
    // };

    const handleClearClick: React.MouseEventHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClear?.();
    };

    const handleApply: FilterItemInternalProps['onApply'] = (values) => {
        onClose();
        props.onApply(values);
    };

    const handleClose = () => {
        onClose();
    };

    const isActive = !!props.current?.value;

    const { _active, ...restTriggerStyle } = props.style?.trigger ?? {};
    const triggerStyle: { wrapper: TagProps['style'] } = {
        // @ts-expect-error
        wrapper: isActive ? { ...restTriggerStyle, ..._active } : { ...restTriggerStyle },
    };

    const triggerProps = props.getTriggerProps();
    // const handleClick = () => {
    //     triggerProps.onClick?.()
    // }

    return (
        <Popover
            isOpen={isOpen}
            onClose={handleClose}
            onOpen={onToggle}
            isLazy={true}
            // we want the embedded form to reset when closed
            lazyBehavior="unmount"
        >
            <PopoverTrigger>
                <Tag
                    whiteSpace="nowrap"
                    outline="none"
                    {...(triggerStyle?.wrapper as any)}
                    cursor="pointer"
                    onClear={handleClearClick}
                    showClear={
                        isActive &&
                        !filter.property.constraints.some(
                            (x) => x.expression.operator == 'single_select'
                        )
                    }
                    {...triggerProps}
                    rightIcon={triggerProps.disabled ? <Icon as={LockIcon} /> : undefined}
                    tooltipProps={{
                        ...triggerProps.tooltipProps,
                        placement: 'top',
                    }}
                >
                    {props.label}
                </Tag>
            </PopoverTrigger>
            <PopoverContent _focus={{ outline: 'none' }} {...props.style?.content} p={0}>
                <PopoverCloseButton top={2} />
                <PopoverHeader>{filter.property.name}</PopoverHeader>
                <PopoverBody>
                    <FilterItemForm filter={filter} {...props} onApply={handleApply} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const FilterItemForm: React.FC<
    { children?: React.ReactNode | undefined } & FilterItemInternalProps
> = (props) => {
    const initialValues: DefaultValues<FilterItemFormValues> = props.current ?? {
        // key: props.filter.property.key,
        // operator: props.filter.default.operator,
        value: props.filter.default?.value,
    };
    const form = useForm<FilterItemFormValues>({ defaultValues: initialValues });
    const values = form.watch();

    const handleSubmit = form.handleSubmit((values) => {
        props.onApply(values);
    });

    return (
        <form onSubmit={handleSubmit}>
            <VStack alignItems="flex-start">
                <InputGroup w="100%" label={null}>
                    <Input {...getInputProps(form, props.filter, values)} />
                </InputGroup>
                <HStack w="100%" justifyContent="flex-end">
                    <Button size="sm" colorScheme="varosGreen" type="submit">
                        Apply
                    </Button>
                </HStack>
            </VStack>
        </form>
    );
};
