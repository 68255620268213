import { z } from 'zod';

export const ApiErrorResponseSchema = z.preprocess(
    (item) => {
        if (typeof item === 'object' && item !== null) {
            return {
                detail:
                    // @ts-expect-error
                    item.description,
                ...item,
            };
        }
        return item;
    },
    z.object({
        detail: z.string(),
    })
);
