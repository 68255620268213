import React from 'react';
import { LayoutListViewProps } from '../../../view/common';

export function createDomainLayoutListView(): React.FC<
    { children?: React.ReactNode | undefined } & LayoutListViewProps
> {
    return (props) => {
        return <></>;
    };
}
