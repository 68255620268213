import { z } from 'zod';
import { TypeSchema } from '../type';

export const ViewPropertySchema = z.object({
    key: z.string(),
    name: z.string(),
    description: z.nullable(z.string()),
    source: z.optional(z.string()),
    type: TypeSchema,
});

export const ViewSchema = z.object({
    id: z.string(),
    plugin: z.string(),
    name: z.string(),
    description: z.nullable(z.string()).default(null),
    source: z.string(),
    trait_filters: z.array(z.string()),
    columns: z.array(ViewPropertySchema),
    example_mode: z.union([z.literal('generate'), z.literal('default')]),
});

export const ViewListResponseSchema = z.object({
    data: z.array(ViewSchema),
});
