import { ApplicationContext } from '../../base';
import { Calculation } from '../../domain';
import { CalculationViewProps } from './component';

export function getDescription(
    // context: Pick<ApplicationContext, 'formatter'>,
    format: CalculationViewProps['formatValue'],
    calculation: Calculation
): string | null {
    if (!calculation.value.change) {
        return null;
    }
    const metric = calculation.property.name;
    // const change = context.formatter.percent(Math.abs(calculation.value.change), {});
    // const change = format(Math.abs(calculation.value.change), { format: 'long' });
    const change = `${Math.abs(calculation.value.change * 100).toFixed(1)}%`;
    const previous = format(calculation.value.previous, { format: 'long' });
    const current = format(calculation.value.current, { format: 'long' });
    const direction =
        calculation.value.current &&
        calculation.value.previous &&
        calculation.value.current > calculation.value.previous
            ? 'increased'
            : 'decreased';
    const sign = direction === 'increased' ? '+' : '-';
    const subject =
        (calculation.segment?.kind === 'asset' ? 'Your' : calculation.segment?.name) ??
        null;
    return `${subject} ${metric} ${direction} by ${sign}${change} compared to the previous period, going from ${previous} to ${current}`;
}
