import { DashboardRouteEnhancer } from '../../entrypoint';
import { compose } from 'lodash/fp';
import {
    createExportTrackingStrategy,
    createDashboardLoadTrackingStrategy,
    createFilterAppliedTrackingStrategy,
    createDashboardBannerTrackingStrategy,
    createDashboardDiscardChangesTrackingStrategy,
    createTraitModeTrackingStrategy,
} from '../../strategy';

export function createDashboardTrackingMiddleware(): DashboardRouteEnhancer {
    return compose(
        createExportTrackingStrategy(),
        createFilterAppliedTrackingStrategy(),
        createTraitModeTrackingStrategy(),
        createDashboardLoadTrackingStrategy(),
        createDashboardBannerTrackingStrategy(),
        createDashboardDiscardChangesTrackingStrategy()
    );
}
