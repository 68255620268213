import { GroupsApi } from './groupnterface';
import { GroupApiConfig } from './groupConfig';
import { createGroupResource } from './group';
import { createGroupMemberResource } from './member';
import { createGroupInviteResource } from './invite';

export function createGroupApi(config: GroupApiConfig): GroupsApi {
    const { client } = config;
    return {
        groups: createGroupResource(client),
        members: createGroupMemberResource(client),
        invites: createGroupInviteResource(client),
    };
}
