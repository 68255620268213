import { z } from 'zod';

const datasetToView: Record<string, string | undefined> = {
    facebookads_adsets_demographics: 'facebookads',
};

const PipelineRefSchema = z
    .object({
        pipeline_version: z.string(),
        asset_id: z.number().optional(),
        view_slug: z.string().default('unknown'),
        dataset_slug: z.string(),
        pipeline_id: z.string(),
        dataset_instance_id: z.number(),
        organization_id: z.number(),
        integration_id: z.number(),
        mappable_entity_id: z.string(),
        mappable_entity_type: z.string(),
    })
    .transform((value) => {
        return {
            ...value,
            view_slug:
                value.view_slug === 'unknown'
                    ? datasetToView[value.dataset_slug] ?? 'facebookads'
                    : value.view_slug,
        };
    });

const IssueSchema = z.object({
    kind: z.literal('has_issues'),
    issue_type: z.string(),
    blame_on: z.union([z.literal('system'), z.literal('user')]),
});

const InFlightSchema = z.object({
    kind: z.literal('in_flight'),
    status: z.string(),
});

const InSyncSchema = z.object({
    kind: z.literal('in_sync'),
    status: z.union([
        z.literal('synced'),
        z.literal('pipeline_in_flight'),
        z.literal('integration_sync_in_flight'),
    ]),
});

export const AnyStateSchema = z.discriminatedUnion('kind', [
    InSyncSchema,
    InFlightSchema,
    IssueSchema,
]);

export const PipelineDtochema = z.object({
    id: z.string(),
    pipeline_ref: PipelineRefSchema,
    state: AnyStateSchema,
    updated_at: z.date({ coerce: true }),
});

export const PipelineListDtoSchema = z.object({
    data: z.array(PipelineDtochema),
});
