import { ReportNewController, ReportNewFormValues } from '../../../view/reports';
import { assert } from '../../../util/assert';

import { AnyOnboardingStepId } from '../../../domain/onboarding';
import { FeatureMiddleware } from '../featureInterface';
import { FeatureReportConfig } from './featureReportConfig';
import { useMemo } from 'react';

/**
 * Feature middleware that hides report creation behind an async Stripe checkout process
 * @param config
 * @returns
 */
export function createFeatureReportMiddleware(
    init: FeatureReportConfig
): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(controller: ReportNewController): ReportNewController {
            return {
                ...controller,
                useProps(...args) {
                    const [deps, context, data] = args;
                    const {
                        api: { billing: api },
                    } = instance;

                    const viewProps = controller.useProps(...args);

                    const handleSubmit = async (values: ReportNewFormValues) => {
                        const asset = data.item.data?.asset;
                        assert(asset, `asset not available`);
                        assert(viewProps.status === 'success', 'not ready');

                        const searchParams = new URLSearchParams(deps.location.search);
                        const step: AnyOnboardingStepId = 'receipt';
                        searchParams.set('step', step);
                        const queryString = searchParams.toString();

                        const cancelPath = `${deps.location.pathname}${deps.location.search}`;
                        const redirectPath = `${deps.location.pathname}?${queryString}`;

                        const response = await api.session.create(context, {
                            product: 'report',
                            cancel_path: cancelPath,
                            success_path: redirectPath,
                            metadata: {
                                kind: 'audit',
                                period_start_at: values.period.start,
                                period_end_at: values.period.end,
                                asset: asset.id,
                            },
                        });
                        window.location.href = response.url;
                    };

                    const formatted = useMemo(() => {
                        return instance.infra.formatter.format(
                            { kind: 'currency', currency: 'usd', constraints: [] },
                            init.product.priceCents / 100
                        );
                    }, [init.product.priceCents]);

                    if (viewProps.status !== 'success') {
                        return viewProps;
                    }

                    return {
                        ...viewProps,
                        getFormProps(...args) {
                            const formProps = viewProps.getFormProps(...args);
                            return {
                                ...formProps,
                                async onSubmit(values) {
                                    await handleSubmit(values);
                                },
                                submitText: `Pay ${formatted}`,
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                reports: {
                    ...config.controller.reports,
                    createReportNew(...args) {
                        const controller = config.controller.reports.createReportNew(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
