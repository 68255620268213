import React from 'react';
import { UrlOptionParser } from '../../../../base';
import { StudiesResponseDetailContainerProps } from './studiesResponseDetailProps';
import { StudiesResponseDetailPathParamsSchema } from './studiesResponseDetailSchema';

export function createStudiesResponseDetailRoute(
    options: UrlOptionParser,
    Container: React.FC<
        { children?: React.ReactNode | undefined } & StudiesResponseDetailContainerProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const viewParams = options.useOptions(StudiesResponseDetailPathParamsSchema);
        return <Container report={{ id: viewParams.reportId }} />;
    };
}
