import { observer } from 'mobx-react';
import React from 'react';
import { ActionProps } from '../../../view/dashboard';
import { ActionViewController } from './actionController';

export interface ActionContainerProps {
    as: React.FC<{ children?: React.ReactNode | undefined } & ActionProps>;
    useController: ActionViewController;
}

export const ActionContainer: React.FC<
    { children?: React.ReactNode | undefined } & ActionContainerProps
> = observer(({ as: Component, useController, ...restProps }) => {
    const viewProps = useController();
    return <Component {...viewProps} {...restProps} />;
});
