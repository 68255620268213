import { CompanyApi } from './companyInterface';
import { CompanyApiConfig } from './companyConfig';
import { createCompanyResource } from './company/companyResource';
import { createIndustryResource } from './industry';

export function createCompanyApi(config: CompanyApiConfig): CompanyApi {
    const { client } = config;
    return {
        company: createCompanyResource(client),
        industry: createIndustryResource(client),
    };
}
