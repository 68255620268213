import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { WorkspaceContextV2 } from '../../platform';
import { FormSubmissionAdapter } from './formSubmissionAdapter';
import { FormSubmissionRepository } from './formSubmissionInterface';
import { FormSubmissionEntity } from './formSubmissionModel';
import { FormSubmissionCreateProps } from './formSubmissionProps';
import { FormSubmissionLookupQuery } from './formSubmissionQuery';

export function createFormSubmissionRepository(
    adapter: FormSubmissionAdapter
): FormSubmissionRepository {
    const PREFIX = ['forms', 'submissions'];
    function getLookupKey(
        context: WorkspaceContextV2,
        item: FormSubmissionEntity | FormSubmissionLookupQuery
    ) {
        return [...PREFIX, 'lookup', context.workspace, item.id];
    }
    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const result = useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', context.workspace, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                onSuccess(data) {
                    for (const entity of data) {
                        const queryKey = getLookupKey(context, entity);
                        queryClient.setQueryData(queryKey, entity);
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: getLookupKey(context, query),
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
        useCreate(context) {
            const queryClient = useQueryClient();
            const result = useMutation({
                async mutationFn(props: FormSubmissionCreateProps) {
                    const response = await adapter.create(context, props);
                    return response;
                },
                onSuccess(data, _variables, _context) {
                    const queryKey = getLookupKey(context, data);
                    queryClient.setQueryData(queryKey, data);
                },
            });
            return result;
        },
    };
}
