import { DateForm, DateFormState } from './dateInterface';

export function createDateForm(): DateForm {
    return {
        useFormState(props): DateFormState {
            const instance: DateFormState = {
                setValue(value) {
                    return props.onChange(value);
                },
                getValue() {
                    return props.value;
                },
                getGranularityField() {
                    return {
                        name: 'granularity',
                        value: props.value['granularity'],
                        onChange(value) {
                            instance.setValue({ ...props.value, granularity: value });
                        },
                    };
                },
                getPeriodField() {
                    return {
                        name: 'period',
                        value: props.value['period'],
                        onChange(value) {
                            instance.setValue({ ...props.value, period: value });
                        },
                    };
                },
                getComparisonField() {
                    return {
                        name: 'comparison',
                        value: props.value['comparison'],
                        onChange(value) {
                            instance.setValue({ ...props.value, comparison: value });
                        },
                    };
                },
                getPeriodOptions() {
                    return [];
                },
                getComparisonOptions() {
                    return [];
                },
                getGranularityOptions() {
                    return [];
                },
                getFormattedRange() {
                    return '';
                },
            };
            return instance;
        },
    };
}
