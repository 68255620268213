import React, { useRef } from 'react';
import { useMeasure } from 'react-use';
import { Bar, Line } from 'react-chartjs-2';
import {
    usePrevious,
    useToken,
    Stack,
    Flex,
    Text,
    Grid,
    GridItem,
    FormLabel,
    Select,
    InputGroup,
    Box,
} from '@chakra-ui/react';
import { LineChartViewProps } from '../chartProps';
import { useChart, useLineChart, LineChart } from '../../../../../ui';

function convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const LineChartView: React.FC<
    { children?: React.ReactNode | undefined } & LineChartViewProps
> = (props) => {
    // const chartJsInstance = React.useRef<any | null>(null);
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();

    const [colorLabel, colorTick, colorLine] = useToken('colors', [
        props.style.label.color as string,
        props.style.tick.color as string,
        props.style.line.color as string,
    ]);

    const fontSizeLabel = useToken('fontSizes', props.style.tick.fontSize as string);
    const fontSizeNumeric = Number(fontSizeLabel.replace('rem', ''));
    const fontSizeLabelPixels = convertRemToPixels(fontSizeNumeric);

    const line = useLineChart({
        chart: {
            isVisible() {
                return true;
            },
        },
        data: props.data,
        options: {
            width,
            getColor(dataset, index) {
                return (
                    (props.style?.getLegend?.(index).indicator.bg as string) ?? undefined
                );
            },
            ...props,
        },
    });

    // const prevWidth = usePrevious(width);
    // const prevHeight = usePrevious(height);

    // React.useEffect(() => {
    //     chartJsInstance.current && chartJsInstance.current.chartInstance.update();
    //     if (
    //         prevWidth != width ||
    //         prevHeight != height
    //         // prevWindowSize.width !== windowSize.width ||
    //         // prevWindowSize.height !== windowSize.height
    //     ) {
    //         chartJsInstance.current && chartJsInstance.current.chartInstance.resize();
    //     }
    // }, [
    //     width,
    //     height,
    //     // windowSize.height, windowSize.width
    // ]);

    const linechartProps = line.getLineChartProps();

    return (
        <Grid
            {...props.style?.wrapper}
            minH="100%"
            w="full"
            templateAreas={`
                "main"
                "footer"
            `}
            gridTemplateRows="1fr min-content"
        >
            <GridItem ref={ref} area="main" w="full">
                {height && (
                    <Line
                        // ref={chartJsInstance}
                        {...linechartProps}
                        options={{
                            // devicePixelRatio: 1,
                            // hover: {
                            //     animationDuration: 0,
                            // },
                            animation: false,
                            legend: {
                                display: false,
                            },
                            maintainAspectRatio: false,
                            // responsive = true breaks layout scrolling for some reason
                            responsive: true,
                            plugins: {
                                tooltip: {
                                    // enabled: tooltipEnabled,
                                    enabled: true,
                                    mode: 'index',
                                    // intersect: false,
                                    axis: 'y',
                                    position: 'nearest',
                                    callbacks: {
                                        // @ts-expect-error
                                        title: function (tooltipItems: any[]) {
                                            const [tooltipItem] = tooltipItems;
                                            return linechartProps.formatLabel?.(
                                                tooltipItem.label,
                                                {
                                                    format: 'long',
                                                }
                                            );
                                        },
                                        // @ts-expect-error
                                        label: linechartProps.getLabel,
                                    },
                                },
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        padding: fontSizeLabelPixels,
                                        color: colorTick,
                                        font: {
                                            size: fontSizeLabelPixels,
                                        },
                                        callback(value, index, values) {
                                            // console.log('callback', { value, index, values });
                                            const label = props.data.labels[index];

                                            return (
                                                (linechartProps.formatLabel?.(label, {
                                                    format: 'short',
                                                }) as string) ?? undefined
                                            );
                                        },
                                    },
                                    grid: {
                                        display: true,
                                        color: colorLine,
                                        drawTicks: false,
                                        offset: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        padding: fontSizeLabelPixels,
                                        color: colorTick,
                                        font: {
                                            size: fontSizeLabelPixels,
                                        },
                                        // @ts-expect-error
                                        callback(value, index, values) {
                                            // console.log('callback', { value, index, values });
                                            return typeof value === 'number'
                                                ? linechartProps.formatUnit?.(value)
                                                : value;
                                        },
                                    },
                                    gridLines: {
                                        display: true,
                                        color: colorLine,
                                        drawTicks: false,
                                        offsetGridLines: false,
                                    },
                                },
                            },
                        }}
                    />
                )}
            </GridItem>
            <GridItem area="footer">
                <Stack direction="row" justifyContent="flex-end" spacing={8}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box w={2} h={2} {...props.style?.getLegend?.(0).indicator} />
                        <Text {...props.style?.label}>Low performers</Text>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box w={2} h={2} {...props.style?.getLegend?.(1).indicator} />
                        <Text {...props.style?.label}>Median</Text>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box w={2} h={2} {...props.style?.getLegend?.(2).indicator} />
                        <Text {...props.style?.label}>Top performers</Text>
                    </Stack>
                </Stack>
            </GridItem>
        </Grid>
    );
};
