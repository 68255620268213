import React from 'react';
import {
    VStack,
    Text,
    Box,
    StackDivider,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import { Dashboard } from '../../../api/v2/dashboard';
import { DashboardStatus } from '../..';
import { FeatureLock, FeatureLockProps } from '../../feature';

export interface DashboardOnboardingProps
    extends Pick<
        FeatureLockProps,
        'initialLocked' | 'containerProps' | 'content' | 'onClick' | 'status'
    > {
    dashboard: Pick<Dashboard, 'name' | 'description'>;
    ctaText: string;
    ctaError?: string;
    ctaDescriptions?: string[];
    dashboardStatus: DashboardStatus;
}

export const DashboardOnboarding: React.FC<
    { children?: React.ReactNode | undefined } & DashboardOnboardingProps
> = ({ ctaText, ctaError, ctaDescriptions, dashboardStatus, ...props }) => {
    return (
        <FeatureLock
            {...props}
            canContinue={getCanContinue(dashboardStatus)}
            cta={{ hint: ctaText, error: ctaError }}
            errors={
                dashboardStatus.kind === 'no-data-sharing'
                    ? dashboardStatus.errors
                    : undefined
            }
            feature={{
                name: props.dashboard.name ? props.dashboard.name : `Dashboard`,
                description: (
                    <VStack
                        spacing={4}
                        divider={
                            props.dashboard.description ? (
                                <StackDivider bg="gray.200" />
                            ) : undefined
                        }
                    >
                        {props.dashboard.description && (
                            <Text colorScheme="gray">{props.dashboard.description}</Text>
                        )}
                        <Text colorScheme="gray" fontWeight="semibold">
                            {ctaText}
                        </Text>
                    </VStack>
                ),
            }}
        >
            {props.children}
        </FeatureLock>
    );
};

function getCanContinue(status: DashboardStatus) {
    return status.kind === 'requires-activation' || status.kind === 'accessable';
}
