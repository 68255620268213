import { usePrevious, Box, useToken } from '@chakra-ui/react';
import { useWindowSize } from 'react-use';
import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { LineChartProps } from '../lineChartProps';
import { ChartDataset, LinearScale } from 'chart.js';
import { LineChartDataset } from '../lineChartModel';

// export const LINE_CHART_RIGHT_PADDING = 72;
// export const LINE_CHART_RIGHT_OFFSET = 4;

export const LineChartV3: React.FC<
    { children?: React.ReactNode | undefined } & LineChartProps
> = ({
    getCaption = (context, original) => original,
    formatUnit = (value) => value.toString(),
    formatLabel = (value) => value.toString(),
    // height = 0,
    // width = 0,
    padding = {},
    partial = [],
    ...props
}) => {
    const legendColor = useToken('colors', 'whiteAlpha.900');

    const [xAxisLabelColor, yAxisLabelColor] = useToken('colors', [
        'whiteAlpha.600',
        'whiteAlpha.400',
    ]);

    // Clone the data to avoid mutating props

    const datasets = useMemo(
        () =>
            props.data.datasets.map(
                (dataset): ChartDataset<'line'> => ({
                    ...dataset,
                    tension: 0,
                    segment: {
                        borderDash: (ctx) => {
                            const { p0DataIndex, p1DataIndex } = ctx;
                            const labels = props.data.labels;
                            const label0 = labels[p0DataIndex];
                            const label1 = labels[p1DataIndex];

                            // Check if either point is partial
                            if (partial.includes(label0) || partial.includes(label1)) {
                                return [5, 5]; // Dashed line style
                            }
                            return undefined; // Solid line
                        },
                    },
                })
            ),
        [props.data, partial]
    );

    return (
        <Line
            height="100%"
            width="100%"
            data={{ ...props.data, datasets }}
            options={{
                layout: {
                    padding: {
                        top: 24,
                    },
                },
                elements: {
                    line: {
                        tension: 0,
                    },
                },
                legend: {
                    usePointStyle: true,
                    // This version of chartjs does not support customizing the space
                    // between the chart and the legend and because we have a custom label design we
                    // end up with clipping. instead we use a custom legend component
                    display: false,
                    // align: 'start',
                    padding: 64,
                    labels: {
                        // padding: 32,
                        boxWidth: 6,
                        usePointStyle: true,
                        fontColor: legendColor,
                        fontSize: 14,
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                animation: false,
                // tooltips: {
                //     // enabled: tooltipEnabled,
                //     enabled: true,
                //     mode: 'index',
                //     // intersect: false,
                //     axis: 'y',
                //     position: 'nearest',
                //     callbacks: {
                //         title: function (tooltipItems: any[]) {
                //             const [tooltipItem] = tooltipItems;
                //             return formatLabel(tooltipItem.label, {
                //                 format: 'long',
                //             });
                //         },
                //         label: props.getLabel,
                //     },
                // },
                plugins: {
                    tooltip: {
                        enabled: true,
                        mode: 'index',
                        axis: 'y',
                        position: 'nearest',
                        yAlign: 'top',
                        callbacks: {
                            title(tooltipItems) {
                                const [tooltipItem] = tooltipItems;
                                return formatLabel(tooltipItem.label, {
                                    format: 'long',
                                }) as string;
                            },
                            label(tooltipItem) {
                                const dataset =
                                    props.data.datasets[tooltipItem.datasetIndex];
                                const value = getCaption(
                                    {
                                        rowIndex: tooltipItem.datasetIndex,
                                        columnIndex: tooltipItem.dataIndex,
                                        key: dataset.label,
                                    },
                                    tooltipItem.raw
                                );
                                if (!value) {
                                    return;
                                }
                                // console.log('dataset', dataset);
                                return `${dataset.label}: ${value}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: xAxisLabelColor,
                            font: {
                                size: 14,
                            },
                            // @ts-expect-error
                            callback(value, index, values) {
                                // console.log('callback', { value, index, values });
                                const label = props.data.labels[index];
                                return formatLabel(label, { format: 'short' });
                            },
                        },
                    },
                    y: {
                        ticks: {
                            color: yAxisLabelColor,
                            font: {
                                size: 14,
                            },
                            // @ts-expect-error
                            callback(value, index, values) {
                                // console.log('callback', { value, index, values });
                                return typeof value === 'number'
                                    ? formatUnit(value)
                                    : value;
                            },
                        },
                        grid: {
                            offset: true,
                            color: 'rgb(104, 104, 104, 0.3)',
                        },
                    },
                },
            }}
        />
    );
};
