import {
    fetchChildrenProps,
    fetchElements,
    getAllChildrenProps,
    getChildrenProps,
} from '../../util';
import {
    OnboardingLayout,
    OnboardingLayoutContainerProps,
    OnboardingStrategyLayout,
} from '../../route';

export function buildRouteProps(
    Layout: OnboardingLayout,
    props: OnboardingLayoutContainerProps
) {
    const { navigation, steplist, session } = fetchChildrenProps(props.children, {
        navigation: Layout.Navigation,
        session: Layout.Session,
        steplist: Layout.Step.List,
    });

    const { context, tablist } = getChildrenProps(props.children, {
        context: Layout.Context,
        tablist: Layout.Tab.List,
    });

    const { steps } = getAllChildrenProps(steplist.children, {
        steps: Layout.Step.Item,
    });

    const { tabs } = getAllChildrenProps(tablist?.children, {
        tabs: Layout.Tab.Item,
    });

    return {
        context,
        session,
        navigation,
        steps,
        tabs,
        steplist,
    };
}

export function buildItemProps(
    Layout: OnboardingStrategyLayout,
    props: OnboardingLayoutContainerProps
) {
    const { content } = fetchChildrenProps(props.children, {
        content: Layout.Content,
    });

    return {
        content,
    };
}
