import { assert } from '@varos/util-typescript';
import { ReportLoaderBaseConfig } from '../../../base';
import { ReportInstanceDetailLoader } from './instanceDetailInterface';

export function createReportInstanceDetailLoader(
    config: ReportLoaderBaseConfig
): ReportInstanceDetailLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const instanceQuery = repository.instance.useLookup(
                context,
                {
                    report: props.report,
                    period: props.period,
                    granularity: props.granularity,
                },
                { suspense: true, staleTime: Infinity }
            );
            assert(instanceQuery.status === 'success', 'expected suspense');
            return {
                instance: instanceQuery.data,
            };
        },
    };
}
