import { MetricCollectionCreatePayload, MetricCollectionDto } from '../../../../api';
import { WorkspaceContextV2 } from '../../../../app';
import { CollectionNewAggregate } from './collectionNewModel';

export function buildCollectionUpsertPayload(
    context: WorkspaceContextV2,
    items: CollectionNewAggregate[],
    selected: Set<string>,
    current: MetricCollectionDto | null
): MetricCollectionCreatePayload & { id: number | null } {
    return {
        id: current ? current.id : null,
        asset: context.workspace.id as number,
        name: 'Default',
        default: true,
        metrics: items.flatMap((item) =>
            selected.has(item.metric.id)
                ? [
                      {
                          definition: item.metric.id,
                          name: null,
                      },
                  ]
                : []
        ),
    };
}
