import {
    AnySettingItem,
    SettingItemBase,
    SettingItemProvider,
    SettingItemRoot,
} from './commonInterface';

export function isSettingItemProvider<TContext>(
    item: SettingItemBase | SettingItemProvider<TContext>
): item is SettingItemProvider<TContext> {
    return typeof (item as SettingItemProvider<TContext>).create === 'function';
}

export function isRootItem(item: AnySettingItem): item is SettingItemRoot {
    return typeof (item as SettingItemRoot).scope === 'string';
}
