import { Outlet, Route } from 'react-router';
import { ReportRouterConfig, ReportRouterInit } from './reportRouterConfig';
import { ReportRouter, ReportRouterEnhancer } from './reportRouterInterface';
import {
    ReportContainerBaseConfig,
    ReportContextBaseConfig,
    ReportControllerBaseConfig,
    ReportLoaderBaseConfig,
    ReportViewBaseConfig,
} from './base';
import {
    createReportErrorContainer,
    createReportErrorController,
    createReportErrorView,
    createReportInstanceDetailContainer,
    createReportInstanceDetailController,
    createReportInstanceDetailLoader,
    createReportInstanceDetailView,
    createReportLayoutContainer,
    createReportLayoutController,
    createReportLayoutLoader,
    createReportLayoutStateProvider,
    createReportLayoutView,
    createReportRootContainer,
} from './page';
import { createReportContextProvider } from './page';
import {
    createReportCategoryChartContainer,
    createReportCategoryChartController,
} from './view';
import {
    createReportTrendChartContainer,
    createReportTrendChartController,
} from './view/chart';

export function createReportRouter(
    config: ReportRouterInit,
    enhancer?: ReportRouterEnhancer
) {
    return create({ ...config }, enhancer);
}

function create(
    config: ReportRouterConfig,
    enhancer?: ReportRouterEnhancer
): ReportRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const contextConfig: ReportContextBaseConfig = {
        hook: config.hook,
    };

    const Context = {
        Root: createReportContextProvider(contextConfig),
    };

    const containerConfig: ReportContainerBaseConfig = {
        context: Context.Root,
        kernel: config.kernel,
        repository: config.repository,
        UI: config.UI,
    };

    const loaderConfig: ReportLoaderBaseConfig = {
        repository: config.repository,
    };

    const controllerConfig: ReportControllerBaseConfig = {
        kernel: config.kernel,
        theme: {
            colors: [
                'red.400',
                'orange.400',
                'yellow.400',
                'indigo.400',
                'blue.400',
                'green.400',
                'teal.400',
            ],
        },
    };

    const viewConfig: ReportViewBaseConfig = {
        UI: config.UI,
        Layout: createReportLayoutContainer(
            containerConfig,
            createReportLayoutStateProvider(containerConfig),
            createReportLayoutLoader(),
            createReportLayoutController(controllerConfig),
            createReportLayoutView({
                UI: config.UI,
            })
        ),
    };

    const Routes = {
        Root: createReportRootContainer(containerConfig, {
            Error: createReportErrorContainer(
                containerConfig,
                createReportErrorController(),
                createReportErrorView(viewConfig)
            ),
        }),
        Instance: {
            Detail: createReportInstanceDetailContainer(
                containerConfig,
                createReportInstanceDetailLoader(loaderConfig),
                createReportInstanceDetailController(controllerConfig),
                createReportInstanceDetailView({
                    ...viewConfig,
                    Container: {
                        CategoryChart: createReportCategoryChartContainer(
                            containerConfig,
                            createReportCategoryChartController(controllerConfig)
                        ),
                        TrendChart: createReportTrendChartContainer(
                            containerConfig,
                            createReportTrendChartController(controllerConfig)
                        ),
                    },
                })
            ),
        },
    };

    return {
        element: (
            <Route
                path={config.mount}
                element={
                    <Context.Root.Provider>
                        <Routes.Root>
                            <Outlet />
                        </Routes.Root>
                    </Context.Root.Provider>
                }
            >
                <Route index={true} element={<Routes.Instance.Detail />} />
            </Route>
        ),
    };
}
