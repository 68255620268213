import { ReportDefinitions } from '../report';

export const REPORT_INVITATIONS = {
    [ReportDefinitions.TOP_COMPANIES.entity.id]: {
        title: 'You are among the top 100 companies on Varos, access exclusive research here',
        assets: [
            // 11496
            16387, 23569, 15383, 15384, 15385, 10267, 6172, 16413, 20510, 10272, 14370,
            22564, 21544, 22570, 20523, 4141, 6198, 20536, 12348, 12350, 3135, 1090,
            23626, 7243, 22608, 23635, 7256, 15450, 23648, 11373, 23672, 11384, 6268,
            3201, 19586, 11400, 11401, 6288, 4242, 22681, 19613, 4259, 23716, 4265, 19626,
            13485, 4270, 4271, 5301, 21691, 11452, 15550, 4287, 11454, 8385, 17607, 19660,
            16590, 3278, 9431, 10462, 23776, 7393, 232, 3304, 11496, 20715, 20719, 244,
            17654, 17656, 249, 5368, 17658, 6404, 17668, 262, 4359, 17672, 22796, 6413,
            21779, 17683, 19737, 4379, 9500, 3357, 21789, 20767, 9503, 17698, 291, 17702,
            19751, 5416, 9511, 16683, 16686, 22831, 302, 22833, 4402, 22835, 16691, 17713,
            19766, 19773, 5438, 22852, 19784, 21833, 19801, 16730, 19813, 21864, 3433,
            19824, 3447, 18808, 20859, 20860, 19838, 2439, 10632, 21899, 18833, 22933,
            17816, 2460, 8605, 16802, 7596, 21934, 4528, 16816, 3504, 18873, 6590, 6591,
            6594, 20932, 6596, 19910, 5578, 3533, 21966, 20950, 16860, 5598, 3550, 496,
            2546, 18931, 17399, 5619, 20992, 20999, 15881, 15886, 2575, 5648, 17935,
            21012, 21013, 15895, 4639, 22047, 5668, 5669, 23082, 14890, 10796, 23087,
            1586, 2612, 3637, 8757, 6710, 21046, 21045, 1588, 9782, 17977, 5689, 8766,
            8768, 23107, 15945, 21074, 595, 23129, 19034, 4698, 23130, 18011, 18014,
            22113, 18018, 18019, 3684, 21093, 21097, 21098, 18030, 18032, 20083, 7797,
            18041, 21119, 19075, 19078, 22152, 21129, 12938, 14992, 14993, 18064, 4755,
            5777, 19093, 18065, 19095, 19096, 22166, 22169, 19099, 23196, 19100, 23198,
            19110, 21159, 3753, 23213, 19122, 19125, 3765, 16057, 2750, 19141, 17097,
            3786, 17099, 23245, 22223, 6865, 20177, 21210, 22242, 23268, 16103, 4850,
            22272, 23297, 21254, 20237, 20239, 7961, 23322, 4890, 5924, 22311, 23336,
            3882, 5934, 5939, 17206, 21303, 23352, 10039, 14136, 16189, 17222, 9034,
            20300, 19279, 15183, 20306, 5970, 21336, 2906, 3932, 23391, 5983, 5988, 11110,
            16236, 21357, 16237, 16241, 7026, 7028, 9076, 2935, 10104, 8058, 7036, 19326,
            4994, 13201, 14227, 10132, 19350, 21400, 14233, 19358, 19360, 5025, 4006,
            21419, 4014, 10165, 19382, 16310, 4025, 8127, 4033, 17361, 4056, 8156, 16353,
            20454, 19432, 16367, 17396, 5111,
        ],
    },
} as Record<
    string,
    {
        title: string;
        assets: number[];
    }
>;
