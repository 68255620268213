import { IntegrationDefinition } from '../../../domain/assets';
import { IntegrationDefinitionAdapter } from '../../../app/assets';
import { IntegrationDefinitionImplConfig } from './definitionImplConfig';

export function createIntegrationDefinitionImpl(
    config: IntegrationDefinitionImplConfig
): IntegrationDefinitionAdapter {
    const {
        config: { overridesByIntegrationType },
        api,
    } = config;
    return {
        async find(context, query) {
            const response = await api.asset.definitions.list(context, query);
            return {
                total: response.data.length,
                items: response.data.map((item): IntegrationDefinition => {
                    const overrides = overridesByIntegrationType[item.integration_type];
                    return {
                        refreshRate: {
                            // default to less than a day
                            interval: 'minute',
                            range: {
                                from: null,
                                to: 1440,
                            },
                        },
                        id: item.id,
                        name: item.title,
                        kind: item.integration_type,
                        mode: item.mode,
                        iconUrl: item.icon_url,
                        entityName: 'Ad account',
                        expedited: false,
                        requirement: null,
                        ...overrides,
                    };
                }),
                limit: 1000,
            };
        },
    };
}
