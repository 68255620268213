import { VisualizationResultController } from './resultInterface';
import { useMemo } from 'react';
import { buildVisualizationResultViewProps } from './resultFactory';
import { VisualizationResultControllerConfig } from './resultConfig';

export function createVisualizationResultController(
    config: Pick<VisualizationResultControllerConfig, 'infra'>
): VisualizationResultController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(props) {
            const viewProps = useMemo(
                () =>
                    buildVisualizationResultViewProps(
                        formatter,
                        props.visualization,
                        props.item
                    ),
                [props.item]
            );
            return viewProps;
        },
    };
}
