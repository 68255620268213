import { Series, SeriesEvent } from '../../api';
import { QueryResponse } from '../../v2/domain/query';
import { VisualizationProps } from './visualizationProps';

export function getVisualizationProps(response: QueryResponse): VisualizationProps {
    const [query, ...rest] = response.queries;
    // if (rest.length > 0) {
    //     throw new Error(`multi query support not implemented`);
    // }
    const eventsByMetric = query.series.items.reduce((acc, series) => {
        return series.events.reduce((eventAcc, event) => {
            return {
                ...eventAcc,
                [getEventKey({ xValue: event.key, yValue: series.name })]: event,
            };
        }, acc);
    }, {} as Record<string, SeriesEvent | undefined>);

    function getEventKey(config: { xValue: string; yValue: string }) {
        return `${config.xValue}_${config.yValue}`;
    }

    function getEvents(series: Series, key: string) {
        const eventKey = getEventKey({
            yValue: series.name,
            xValue: key,
        });
        const event = eventsByMetric[eventKey];
        return event ? [event] : [];
    }

    return {
        getEvents,
    };
}
