import { AnyOnboardingStep } from '../step';
import { AnyOnboardingState, OrganizationOnboardingState } from './stateModel';

export function getNextRedirect(state: AnyOnboardingState) {
    if (state.scope === 'asset') {
        return state.steps.filter((step) => step.status === 'force') ?? null;
    }
    return state.steps.filter((step) => step.status === 'force') ?? null;
}

export function getOnboardingRouting(
    state: AnyOnboardingState,
    current: Pick<AnyOnboardingStep, 'slug'>
) {
    const currentIndex = state.steps.findIndex((item) => item.slug === current.slug);
    const previousIndex = currentIndex >= 1 ? currentIndex - 1 : null;
    const nextIndex = Math.min(currentIndex + 1, state.steps.length);
    return {
        previous: previousIndex ? state.steps[previousIndex] : null,
        current: state.steps[currentIndex],
        next: state.steps[nextIndex] ? state.steps[nextIndex] : null,
    };
}
