import { z } from 'zod';

export const PaymentMethodDetailUrlParamsSchema = z
    .object({
        redirect_status: z
            .string()
            .transform((value) => {
                if (value === 'succeeded') {
                    return value;
                }
                return 'unknown';
            })
            .pipe(z.union([z.literal('succeeded'), z.literal('unknown')])),
    })
    .partial();
