import { DemoRouteInit } from '../../../route/demo';
import { DemoEntryEnhancer } from '../../../entrypoint';
import { DemoTrackingStrategyInit } from './demoTrackingConfig';
import { useEffect } from 'react';

export function createDemoTrackingStrategy(
    init: DemoTrackingStrategyInit
): DemoEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            route: {
                ...config.route,
                demo: {
                    ...config.route.demo,
                    config: {
                        ...config.route.demo.config,
                        ...enhanceConfig(init, config.route.demo.config),
                    },
                },
            },
        });
    };
}

function enhanceConfig(
    { useAdapter }: DemoTrackingStrategyInit,
    config: Pick<DemoRouteInit, 'controller'>
): Partial<DemoRouteInit> {
    return {
        ...config,
        controller: {
            ...config.controller,
            createRouteControllerHook() {
                const useController = config.controller.createRouteControllerHook();
                return () => {
                    const controller = useController();
                    const adapter = useAdapter();
                    useEffect(() => {
                        adapter.track('free_tool_dashboard_loaded', {
                            dashboard_id: controller.dashboards.current.id,
                            dashboard_name: controller.dashboards.current.name,
                        });
                    }, []);
                    return {
                        ...controller,
                        getCtaProps() {
                            const original = controller.getCtaProps();
                            return {
                                ...original,
                                onClick(event) {
                                    adapter.track('free_tool_get_started_clicked', {
                                        dashboard_id: controller.dashboards.current.id,
                                        dashboard_name:
                                            controller.dashboards.current.name,
                                    });
                                    return original.onClick?.(event);
                                },
                            };
                        },
                        fields: {
                            ...controller.fields,
                            getPlatform() {
                                const original = controller.fields.getPlatform();
                                return {
                                    ...original,
                                    onChange(value) {
                                        const dashboard =
                                            controller.dashboards.items.find(
                                                (item) => item.id === value
                                            );
                                        if (!dashboard) {
                                            console.warn(
                                                `dashboard '${dashboard}' not found`
                                            );
                                            return original.onChange(value);
                                        }
                                        adapter.track('free_tool_dashboard_changed', {
                                            dashboard_id: dashboard.id,
                                            dashboard_name: dashboard.name,
                                        });
                                        return original.onChange(value);
                                    },
                                };
                            },
                            getFilter(dashboard, property) {
                                const original = controller.fields.getFilter(
                                    dashboard,
                                    property
                                );
                                return {
                                    ...original,
                                    onChange(value) {
                                        adapter.track('free_tool_filter_applied', {
                                            dashboard_id: dashboard.id,
                                            dashboard_name: dashboard.name,
                                            filter_kind: property.kind,
                                            filter_key: property.key,
                                            filter_name: property.name,
                                            filter_value: value,
                                        });
                                        return original.onChange(value);
                                    },
                                };
                            },
                        },
                        getDateFilterProps(dashboard) {
                            const props = controller.getDateFilterProps(dashboard);
                            return {
                                ...props,
                                onClick(event) {
                                    adapter.track('free_tool_date_filter_clicked', {
                                        dashboard_id: dashboard.id,
                                        dashboard_name: dashboard.name,
                                    });
                                    return props.onClick?.(event);
                                },
                            };
                        },
                        getMetricProps(dashboard, metric, index) {
                            const props = controller.getMetricProps(
                                dashboard,
                                metric,
                                index
                            );
                            return {
                                ...props,
                                onClick(event) {
                                    adapter.track('free_tool_metric_clicked', {
                                        dashboard_id: dashboard.id,
                                        dashboard_name: dashboard.name,
                                        metric_name: metric.name,
                                    });
                                    return props.onClick?.(event);
                                },
                            };
                        },
                    };
                };
            },
        },
    };
}
