import { z } from 'zod';

export const PeerSetDtoSchema = z.object({
    id: z.string(),
    name: z.string(),
    creator: z.number(),
    asset: z.number(),
    members: z.array(
        z.object({
            id: z.string(),
            asset: z.number(),
            competitive_set: z.string(),
        })
    ),
    created_at: z.date({ coerce: true }),
});

export const PeerSetListDtoSchema = z.object({
    data: z.array(PeerSetDtoSchema),
});

// Props

export const PeerSetCreatePropsSchema = z.object({
    name: z.string(),
    asset: z.number(),
    members: z.array(
        z.object({
            asset: z.number(),
        })
    ),
});

export const PeerSetUpdatePropsSchema = z
    .object({
        name: z.string(),
        members: z.array(
            z.object({
                asset: z.number(),
            })
        ),
    })
    .partial();
