import { PeerHealthcheckEntity } from './peerHealthcheckEntity';
import { PeerHealthcheckStatus } from './peerHealthcheckModel';

export function getPeerHealthcheckStatus(
    entity: PeerHealthcheckEntity
): PeerHealthcheckStatus {
    if (entity.transparency.quality === 'insufficient_sample') {
        return 'insufficient';
    }
    return 'available';
}
