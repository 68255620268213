import { InCondition } from '../../../../domain/attributes';
import { intersect } from '../../../../util';
import { Property } from '../../../../domain/attributes';
import { ControlSelectedState } from '../controlModel';

export function applyTreeValue(
    state: Pick<ControlSelectedState, 'treeValuesByPropertyKey'>,
    property: Pick<Property, 'key'>,
    current: InCondition
): InCondition | null {
    const setOptions = state.treeValuesByPropertyKey[property.key] ?? new Set();
    const setCurrent = new Set(current.value);
    const value = [...intersect(setCurrent, setOptions)];
    if (value.length === 0) {
        return null;
    }
    return { ...current, value: value as string[] };
}
