import moment from 'moment';
import type { Duration, DurationInterval } from '../duration';
import type { DateRange } from './daterangeModel';

export function daterangeDifference(value: DateRange, interval: DurationInterval) {
    const difference = moment.utc(value.end).diff(value.start, interval);
    return difference;
}

export function daterangeSubtract(value: DateRange, duration: Duration): DateRange {
    return {
        ...value,
        start: moment.utc(value.start).subtract(duration.amount, duration.interval).toDate(),
        end: moment.utc(value.end).subtract(duration.amount, duration.interval).toDate(),
    };
}
