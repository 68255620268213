import moment from 'moment';
import { useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { ReadStatusEnhancerConfig } from './readStatusConfig';
import { ReadStatusState } from './readStatusModel';
import { ReadStatusStateSchema } from './readStatusSchema';

export function createReadStatusEnhancer(
    init: ReadStatusEnhancerConfig
): ApplicationEntryEnhancer {
    const { storageKey, initial: initialState } = init;
    return (create) => (config) => {
        return create({
            ...config,
            container: {
                ...config.container,
                workspaces: {
                    ...config.container.workspaces,
                    createWorkspaceInsightContainer(containerConfig) {
                        const original =
                            config.container.workspaces.createWorkspaceInsightContainer(
                                containerConfig
                            );

                        return {
                            ...original,
                            useProps(input) {
                                const workspace = containerConfig.hooks.useWorkspace();
                                const [raw, setState] = useLocalStorage<ReadStatusState>(
                                    storageKey,
                                    initialState
                                );
                                const parsed = ReadStatusStateSchema.safeParse(raw);

                                const state: ReadStatusState = parsed.success
                                    ? parsed.data
                                    : initialState;

                                const assetState = state.insights[workspace.id];

                                const props = original.useProps(input);

                                const unreadInsights = useMemo(
                                    () =>
                                        // mark insight as unread if occured after last open, or if no previous open and occured within the last week
                                        props.props.items.filter((insight) =>
                                            assetState?.last_read_at
                                                ? moment
                                                      .utc(insight.period_end_at)
                                                      .isAfter(assetState.last_read_at)
                                                : moment
                                                      .utc(insight.period_end_at)
                                                      .isAfter(
                                                          moment.utc().subtract(7, 'days')
                                                      )
                                        ),
                                    [props.props.items, assetState?.last_read_at]
                                );

                                return {
                                    ...props,
                                    badge:
                                        unreadInsights.length > 0
                                            ? { children: unreadInsights.length }
                                            : undefined,
                                    onOpen() {
                                        const now = moment.utc().toDate();
                                        setState({
                                            ...state,
                                            insights: {
                                                ...state.insights,
                                                [workspace.id.toString()]: {
                                                    last_read_at: now,
                                                },
                                            },
                                        });
                                        return props.onOpen?.();
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
    };
}
