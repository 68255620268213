import { useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { getCollectionItems } from '../../../../base';
import { ReportItemAggregate } from '../../../../view/studies';
import { ImageDefinition } from '../../../../view/common';
import { StudiesDetailRouteConfig } from './studiesReportDetailConfig';
import { StudiesDetailLoader } from './studiesReportDetailInterface';
import { StudiesDetailAggregate } from './studiesReportDetailModel';

export function createStudiesDetailLoader(
    config: Pick<StudiesDetailRouteConfig, 'repository'>
): StudiesDetailLoader {
    const {
        repository: {
            dataset: datasetRepository,
            count: countRepository,
            submission: submissionRepository,
            invitation: invitationRepository,
            view: viewRepository,
            report: reportRepository,
        },
    } = config;

    return {
        useLoad(context, props): StudiesDetailAggregate {
            const reportQuery = reportRepository.useLookup(context, props.report, {
                suspense: true,
            });
            assert(reportQuery.status === 'success', 'expected suspese');

            const datasetsQuery = datasetRepository.useLookup(
                context,
                reportQuery.data.kind === 'dataset' ? reportQuery.data.dataset : null,
                {
                    suspense: true,
                    retry: false,
                }
            );
            const viewQuery = viewRepository.useFind(context, {}, { suspense: true });

            const submissionQuery = submissionRepository.useFind(
                context,
                {
                    asset: {
                        id: context.workspace.id as number,
                    },
                },
                { suspense: true }
            );

            const invitationQuery = invitationRepository.useCollection(
                context,
                {
                    report: [reportQuery.data.id],
                },
                { suspense: true }
            );

            assert(invitationQuery.status === 'success', 'expected suspese');
            assert(viewQuery.status === 'success', 'expected suspese');
            assert(
                reportQuery.data.kind === 'dataset'
                    ? datasetsQuery.status === 'success'
                    : true,
                'expected suspese'
            );
            assert(submissionQuery.status === 'success', 'expected suspese');

            const countQuery = countRepository.useLookup(
                context,
                datasetsQuery.data
                    ? [
                          {
                              kind: 'data_ingestion.dataset_member',
                              object: datasetsQuery.data.id,
                          },
                      ]
                    : [],
                { suspense: true }
            );
            assert(countQuery.status === 'success', 'expected suspese');

            const viewsById = useMemo(
                () => keyBySafe(viewQuery.data, (item) => item.id),
                [viewQuery.data]
            );

            const invitationByReport = useMemo(
                () =>
                    keyBySafe(
                        getCollectionItems(invitationQuery.data),
                        (item) => item.report.id
                    ),
                [invitationQuery.data]
            );

            const countsByDataset = useMemo(
                () => keyBySafe(countQuery.data, (item) => item.object),
                [countQuery.data]
            );

            const submissionsByDataset = useMemo(
                () => keyBySafe(submissionQuery.data, (item) => item.dataset.id),
                [submissionQuery.data]
            );

            const aggregate = useMemo((): ReportItemAggregate => {
                const submission = datasetsQuery.data
                    ? submissionsByDataset[datasetsQuery.data.id] ?? null
                    : null;
                return {
                    asset: {
                        id: context.workspace.id as number,
                    },
                    dataset: datasetsQuery.data ?? null,
                    count: {
                        respondents: datasetsQuery.data
                            ? countsByDataset[datasetsQuery.data.id] ?? null
                            : null,
                    },
                    submission,
                    report: reportQuery.data,
                    // HACK right now we assume the view is the same as the
                    // dataset
                    view: datasetsQuery.data
                        ? viewsById[datasetsQuery.data.id] ?? null
                        : null,
                    invitation: invitationByReport[reportQuery.data.id] ?? null,
                };
            }, [
                datasetsQuery.data,
                submissionsByDataset,
                reportQuery.data,
                countsByDataset,
                context.workspace.id,
                invitationByReport,
            ]);

            return {
                report: aggregate,
                image: {
                    items: aggregate.report.images.map(
                        (item): ImageDefinition => ({
                            url: item.url,
                            name: item.description,
                        })
                    ),
                },
            };
        },
    };
}
