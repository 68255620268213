// import { AnyType, NumericType } from '../../api/v2';
import { AnyType, NumericType } from '../../v2/domain/attributes';
import { isNumeric } from './typeGuard';

export function assertNumeric(
    type: AnyType,
    message?: string
): asserts type is NumericType {
    if (!isNumeric(type)) {
        throw new Error(message);
    }
}
