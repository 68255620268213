import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildPropertyItemProps, PropertyItemViewProps } from '../item';
import { PropertyListController } from './propertyListInterface';

export function createPropertyListController(): PropertyListController {
    return {
        useProps(context, props) {
            const itemPropsByKey = useMemo(
                () =>
                    props.item.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.property.key]: buildPropertyItemProps(item),
                        }),
                        {} as Record<string, PropertyItemViewProps | undefined>
                    ),
                [props.item.items]
            );

            const items = useMemo(() => {
                return chain(props.item.items)
                    .orderBy(
                        (item) => [item.property.isRequired ? 1 : -1, item.property.key],
                        ['desc', 'asc']
                    )
                    .value();
            }, [props.item.items]);

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsByKey[item.property.key];
                    assert(itemProps, 'missing item props');
                    return itemProps;
                },
            };
        },
    };
}
