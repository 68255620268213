import {
    AnyType,
    DateType,
    IntegerType,
    FloatType,
    StringType,
    CurrencyType,
    LazyEnumType,
    EnumType,
    NamedEnumType,
    TreeType,
    NumericType,
    ComplexType,
    PercentType,
    MovementType,
    DateTimeType,
    ListType,
    ReferenceType,
    EnumMember,
    EnumMemberWithChildren,
    EnumLikeType,
    AssetType,
    BooleanType,
    URLType,
    DateRangeType,
    UnknownType,
} from './typeModel';

export function isEnumMemberWithChildren(
    member: EnumMember | EnumMemberWithChildren
): member is EnumMemberWithChildren {
    return Array.isArray((member as EnumMemberWithChildren).children);
}

export function isStringType(type: AnyType): type is StringType {
    return type === 'string';
}

export function isIntegerType(type: AnyType): type is IntegerType {
    return type === 'integer';
}

export function isFloatType(type: AnyType): type is FloatType {
    return type === 'float';
}

export function isBooleanType(type: AnyType): type is BooleanType {
    return type === 'boolean';
}

export function isPercentType(type: AnyType): type is PercentType {
    return type === 'percent';
}

export function isDateType(type: AnyType): type is DateType {
    return type === 'date';
}

export function isDatetimeType(type: AnyType): type is DateTimeType {
    return type === 'datetime';
}

export function isUrlType(type: AnyType): type is URLType {
    return type === 'url';
}

export function isDateRangeType(type: AnyType): type is DateRangeType {
    return type === 'daterange';
}

export function isComplexType(type: AnyType): type is ComplexType {
    return typeof type === 'object';
}

export function isListType(type: AnyType): type is ListType {
    return isComplexType(type) && type.kind === 'list';
}

export function isMovementType(type: AnyType): type is MovementType {
    return isComplexType(type) && type.kind === 'movement';
}

export function isUnknownType(type: AnyType): type is UnknownType {
    return type === 'unknown';
}

export function isCurrencyType(type: AnyType): type is CurrencyType {
    return isComplexType(type) && type.kind === 'currency';
}

export function isEnumType(type: AnyType): type is EnumType {
    return isComplexType(type) && type.kind === 'enum';
}

export function isEnumLikeType(type: AnyType): type is EnumLikeType {
    return isEnumType(type) || isTreeType(type);
}

export function isNamedEnum(type: AnyType): type is NamedEnumType {
    return isEnumType(type) && !!type.name && !!type.namespace;
}

export function isReferenceType(type: AnyType): type is ReferenceType {
    return isComplexType(type) && type.kind === 'reference';
}

export function isLazyEnum(type: AnyType): type is LazyEnumType {
    return isComplexType(type) && type.kind === 'lazy-enum';
}

export function isTreeType(type: AnyType): type is TreeType {
    return isComplexType(type) && type.kind === 'tree';
}

export function isAssetType(type: AnyType): type is AssetType {
    return isComplexType(type) && type.kind === 'asset';
}

export function isNumericType(type: AnyType): type is NumericType {
    return (
        isIntegerType(type) ||
        isFloatType(type) ||
        isCurrencyType(type) ||
        isPercentType(type)
    );
}

export function isFloatLikeType(type: AnyType): type is FloatType | PercentType {
    return isFloatType(type) || isPercentType(type);
}
