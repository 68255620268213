import { CompanyListBoxMacro } from '../../../macro';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingCompetitiveSetLoader } from './onboardingCompetitiveSetInterface';

export function createOnboardingCompetitiveSetLoader(
    config: OnboardingStrategyConfig & { macro: CompanyListBoxMacro }
): OnboardingCompetitiveSetLoader {
    const {
        macro: { loader: companyLoader },
    } = config;
    return {
        useLoad(context, props) {
            const company = companyLoader.useLoad(context, props.company);
            return { company };
        },
    };
}
