import isUrl from 'is-url-superb';
import { z } from 'zod';

const URL_REGEX =
    /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i;

export const AccountProfileFormValuesSchema = z.object({
    firstname: z
        .string()
        .min(2, { message: 'First name must be at least 2 characters long' })
        .max(100, { message: 'First name must be at at most 100 characters long' }),
    lastname: z
        .string()
        .min(2, { message: 'Last name must be at least 2 characters long' })
        .max(100, { message: 'Last name must be at at most 100 characters long' }),
    companyName: z
        .string()
        .min(2, { message: 'Company name must be at least 2 characters long' })
        .max(100, { message: 'Company name must be at at most 100 characters long' }),
    companyWebsite: z
        .string({ required_error: 'URL is required' })
        .transform((value) => {
            try {
                const urlString = `https://${value}`;
                const url = new URL(urlString);
                url.protocol = 'https';
                url.pathname = '';
                return url.toString().replace(/\/$/, '');
            } catch (error) {
                return value;
            }
        })
        .refine(
            (value) => {
                return URL_REGEX.test(value);
                // return URL_REGEX.test(`https://${value}`);
                // return isUrl(value, { lenient: true });
            },
            { message: 'Invalid URL' }
        ),
    isAgency: z.boolean(),
});
