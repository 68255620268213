import { useMemo } from 'react';
import { buildStudyItemViewProps } from '../../../../view/studies';
import { PageIds } from '../../../../config';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesChartRouteControllerProps } from '../../chart';
import { StudiesResponseItemControllerProps } from '../item';
import { StudiesResponseDetailRouteController } from './studiesResponseDetailInterface';
import { StudiesResponseDetailItemAggregate } from './studiesResponseDetailModel';
import {
    StudiesResponseDetailControllerProps,
    StudiesResponseDetailViewProps,
} from './studiesResponseDetailProps';

export function createStudiesResponseDetailRouteController(
    config: Pick<StudiesBaseRouteConfig, 'controller'>
): StudiesResponseDetailRouteController {
    const {
        controller: {
            page: pageController,
            submissions: { item: submissionController },
            date: dateController,
            peer: peerFilterController,
        },
    } = config;

    function buildItemProps(
        props: Pick<StudiesResponseDetailControllerProps, 'mode' | 'segment' | 'control'>,
        item: StudiesResponseDetailItemAggregate
    ): StudiesResponseItemControllerProps {
        return {
            ...item,
            mode: props.mode,
            segment: props.segment,
            control: props.control,
        };
    }

    function buildChartProps(
        props: Pick<StudiesResponseDetailControllerProps, 'mode'>,
        item: StudiesResponseDetailItemAggregate
    ): StudiesChartRouteControllerProps {
        return {
            visualization: item.visualization,
            mode: props.mode.value,
        };
    }

    return {
        useProps(context, item, props): StudiesResponseDetailViewProps {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.STUDIES_RESPONSE_DETAIL,
                    breadcrumbs: [],
                },
            });

            // const submissionProps = submissionController.useProps(
            //     context,
            //     item.submission
            // );

            const peerFilterProps = peerFilterController.useProps(
                context,
                item.peer,
                props.filter
            );

            const dateProps = dateController.useProps(props.date);

            const itemPropsByKey = useMemo(
                () =>
                    item.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.visualization.id]: buildItemProps(
                                {
                                    mode: props.mode,
                                    segment: props.segment,
                                    control: props.control,
                                },
                                item
                            ),
                        }),
                        {} as Record<string, StudiesResponseItemControllerProps>
                    ),
                [item.items, props.mode, props.segment, props.control]
            );

            const chartPropsByKey = useMemo(
                () =>
                    item.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.visualization.id]: buildChartProps(
                                {
                                    mode: props.mode,
                                },
                                item
                            ),
                        }),
                        {} as Record<string, StudiesChartRouteControllerProps>
                    ),
                [item.items, props.mode]
            );

            return {
                links: {
                    submission: {
                        overview: {
                            to: `/u/assets/${context.workspace.id}/reporting/surveys/submissions`,
                        },
                    },
                    report: {
                        detail: {
                            to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.report.id}`,
                        },
                    },
                },
                report: buildStudyItemViewProps(context, item.report),
                page: pageProps,
                date: dateProps,
                peer: peerFilterProps,
                // submission: submissionProps,
                items: item.items,
                getItemProps(item) {
                    const itemProps = itemPropsByKey[item.visualization.id];
                    return itemProps;
                },
                getChartProps(item) {
                    const chartProps = chartPropsByKey[item.visualization.id];
                    return chartProps;
                },
            };
        },
    };
}
