import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { ImportDataResource } from './importDataInterface';
import { ImportDataDtoSchema, ImportDataListDtoSchema } from './importDataSchema';

export function createImportDataResource(client: AxiosInstance): ImportDataResource {
    return {
        async list(context, importId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get(
                `/api/v1/data-ingestion/imports/${importId}/data`,
                {
                    params: {},
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            const parsed = ImportDataListDtoSchema.parse(response.data);
            return parsed;
        },
    };
}
