import { toVisualization } from '../../../app/visualizations';
import { getAnalysisMetricLink } from '../../../route';
import { PluginIcons } from '../../../config';
import { InsightModuleConfig } from './insightConfig';
import { InsightController } from './insightInterface';

export function createInsightController(
    init: InsightModuleConfig['deps']
): InsightController {
    const {
        api: {
            insight: {
                insights: { list: listInsights },
            },
        },
        hook: { useQuery },
    } = init;
    return {
        useProps(context, formatter, queries, props) {
            // const key = ['last_opened_at', context.workspace.id].join(':');
            // const storage = useLocalStorage(key, null);
            // const insights = useQuery({
            //     queryKey: [
            //         'insights',
            //         context.workspace.id,
            //         props.query.category,
            //         props.query.confidence,
            //         props.query.status,
            //         props.query.occured_before?.toDateString(),
            //         props.query.occured_since?.toDateString(),
            //     ],
            //     refetchOnWindowFocus: true,
            //     // refetchInterval: 60000,
            //     staleTime: 60000,
            //     async queryFn() {
            //         const [base] = await Promise.all([
            //             // listInsights(context, {
            //             //     ...props.query,
            //             //     asset: [context.workspace.id as number],
            //             //     priority: ['medium', 'low'],
            //             // }),
            //             listInsights(context, {
            //                 ...props.query,
            //                 asset: [context.workspace.id as number],
            //                 // priority: ['high'],
            //             }),
            //         ]);
            //         return [...base.data];
            //         // return [...priority.data, ...base.data];
            //     },
            //     retry: false,
            //     suspense: true,
            // });

            // const truncated = insights.data?.slice(props.truncate?.amount) ?? [];

            return {
                status: null,
                count: {
                    unread: queries.items.data?.length ?? 0,
                },
                items: queries.items.data ?? [],
                formatTitle(value) {
                    return [value.title].filter(Boolean).join(' ');
                },
                getIconSource(value) {
                    return PluginIcons[value.definition.plugin] ?? null;
                },
                getStatusColor(value) {
                    let prefix: string | null = null;
                    if (value.status === 'success') {
                        return 'green.500';
                    }
                    if (value.status === 'warning') {
                        return 'yellow.500';
                    }
                    if (value.status === 'danger') {
                        return 'red.500';
                    }
                    // return 'white';
                    return undefined;
                },
                formatRange(value) {
                    return formatter.daterange(value, { notation: 'short' });
                },
                formatRelative(value) {
                    return formatter.timeago(value);
                },
                getChildProps(item, index) {
                    const visualization = toVisualization(
                        init.strategy.visualizable,
                        item
                    );
                    return {
                        onOpen() {},
                        onClose() {},
                        onDetailClick() {},
                        // detailPath: '/analyze?adadd',
                        detailPath: visualization
                            ? getAnalysisMetricLink(context, visualization)
                            : null,
                    };
                },
            };
        },
    };
}
