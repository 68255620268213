import React from 'react';

export interface DemoLayoutHeaderProps {
    title: string;
    subtitle: string;
}

export const DemoLayoutHeader: React.FC<
    { children?: React.ReactNode | undefined } & DemoLayoutHeaderProps
> = (props) => <>{props.children}</>;
