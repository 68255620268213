import { AnyDashboardIssue, DashboardAssetIssue } from './issueModel';

export function isAssetIssue(
    issue: AnyDashboardIssue | null
): issue is DashboardAssetIssue {
    if (!issue) {
        return false;
    }
    return issue.kind === 'asset';
}

export function isDependencyIssue(
    issue: AnyDashboardIssue | null
): issue is DashboardAssetIssue {
    if (!issue) {
        return false;
    }
    return issue.kind === 'dependency';
}
