import React from 'react';
import { PeerContextProvider } from '../../base';
import {
    PeerGroupListRouteController,
    PeerGroupListRouteLoader,
} from './peerGroupListInterface';
import { PeerGroupListRouteViewProps } from './peerGroupListProps';

export function createPeerGroupListRoute(
    context: PeerContextProvider,
    controller: PeerGroupListRouteController,
    loader: PeerGroupListRouteLoader,
    View: React.FC<
        { children?: React.ReactNode | undefined } & PeerGroupListRouteViewProps
    >
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const viewContext = context.useContext();
        const viewData = loader.useLoad(viewContext);
        const viewProps = controller.useProps(viewContext, viewData, {});
        return <View {...viewProps} />;
    };
}
