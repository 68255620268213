import { QueryableStrategy } from '../../../../app';
import { AnyVisualization } from '../../../../domain/visualization';
import { DateFactory } from '../date';
import { RankingFactory } from '../ranking';
import { SegmentFactory } from '../segment';
import { ContentFactory } from './contentInterface';

export function createContentFactory(
    strategy: QueryableStrategy<AnyVisualization>,
    date: DateFactory,
    segment: SegmentFactory,
    ranking: RankingFactory
): ContentFactory {
    return {
        build(view, visualization, props) {
            const request = strategy.toQuery({ segment: [] }, view, visualization);
            return { ...request, comparison: null, segment: props.segment };
        },
    };
}
