import { useMemo } from 'react';
import { buildLineChartViewProps } from './lineFactory';
import { LineChartController } from './lineInterface';

export function createLineChartController(): LineChartController {
    return {
        useProps(props) {
            const viewProps = useMemo(() => buildLineChartViewProps(props), [props]);
            return viewProps;
        },
    };
}
