import { useMemo } from 'react';
import { isReferenceProperty } from '../../../domain/attributes';
import { BreakdownForm } from './breakdownInterface';
import { BreakdownViewProps } from './breakdownProps';

export function createBreakdownForm(): BreakdownForm {
    return {
        useFormState(view, props): BreakdownViewProps {
            const options = useMemo(() => {
                return [
                    ...view.columns
                        .filter(isReferenceProperty)
                        .map((column) => ({ label: column.name, value: column.key })),
                ];
            }, [view.columns]);
            return {
                getOptions() {
                    return options;
                },
                // @ts-expect-error
                getPropertyField() {
                    return {
                        value: props.value,
                        onChange(value) {
                            props.onChange({
                                ...props.value,
                                properties: [{ key: value }],
                            });
                        },
                    };
                },
                onClear() {
                    props.onChange(null);
                },
            };
        },
    };
}
