import { MetricConnectionConfig } from './connectionConfig';
import { ConnectionAdapter } from '../../../app';

export function createConnectionImpl(config: MetricConnectionConfig): ConnectionAdapter {
    const { axios } = config;
    return {
        async find(context, query) {
            throw new Error('not impl');
        },
    };
}
