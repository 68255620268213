import { VideoControllerProps } from '../../view/common';

const OVERVIEW_VIDEO: VideoControllerProps = {
    id: 'study_overview',
    title: `Tactical Calls with High Performers`,
    description: `Meet 1:1 with a High Performer (verified by Varos) from your peer group to get tactical advice on how to improve`,
    url: `https://www.loom.com/embed/93252b1509c744a49e92163d6dbe5ab4?sid=91695b4c-301d-4620-877d-a8a11862e3f1`,
};

export const NetworkConstant = { OVERVIEW_VIDEO };
