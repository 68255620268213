import { capitalize } from 'lodash';
import {
    getTypeDescription,
    getTypeKind,
    isEnumType,
} from '../../../../domain/attributes';
import { assert } from '../../../../util/assert';
import { buildMemberItemViewProps } from '../../member';
import { TypeAggregate } from './typeItemModel';
import { AnyTypeItemViewProps } from './typeItemProps';

export function buildTypeItemViewProps(item: TypeAggregate): AnyTypeItemViewProps {
    const typeKind = getTypeKind(item.type);
    const description = getTypeDescription(item.type);
    if (isEnumType(item.type)) {
        assert(item.members !== null, 'expected enum type aggregate to have members');
        return {
            enum: true,
            label: capitalize(typeKind),
            description,
            members: item.members.map((member) => buildMemberItemViewProps(member)),
        };
    }
    return {
        enum: false,
        label: capitalize(typeKind),
        description,
    };
}
