import React from 'react';
import { PeerViewConfig } from '../base';
import { PeerLayoutViewProps } from './peerLayoutProps';

export function createPeerLayoutView(
    config: Pick<PeerViewConfig, 'Layout'>
): React.FC<{ children?: React.ReactNode | undefined } & PeerLayoutViewProps> {
    const {
        Layout: {
            Domain: { Shell: Layout },
        },
    } = config;
    return (props) => {
        return <Layout {...props.layout}>{props.children}</Layout>;
    };
}
