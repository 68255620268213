import { ComparisonVisualizationResultViewProps } from './comparison';
import { DistributionVisualizationResultViewProps } from './distribution';
import { LineVisualizationResultViewProps } from './line';
import { AnyVisualizationResultViewProps } from './resultProps';

export function isDistributionResultProps(
    props: AnyVisualizationResultViewProps
): props is DistributionVisualizationResultViewProps {
    return props.kind === 'distribution';
}

export function isLineResultProps(
    props: AnyVisualizationResultViewProps
): props is LineVisualizationResultViewProps {
    return props.kind === 'line';
}

export function isComparisonResultProps(
    props: AnyVisualizationResultViewProps
): props is ComparisonVisualizationResultViewProps {
    return props.kind === 'comparison';
}
