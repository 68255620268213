import { chain } from 'lodash';
import { useMemo } from 'react';
import { groupBySafe, keyBySafe } from '../../../../../util';
import { assert } from '../../../../../util/assert';
import { SettingsIntegrationLoaderConfig } from '../base';
import { SettingIntegrationDetailAggregate } from './integrationDetailModel';
import { SettingIntegrationDetailLoader } from './integrationDetailnterface';

export function createSettingIntegrationDetailLoader(
    config: SettingsIntegrationLoaderConfig
): SettingIntegrationDetailLoader {
    const { repository } = config;
    return {
        useLoad(context, props): SettingIntegrationDetailAggregate {
            // TODO refactor this to be a lookup
            const integrationQuery = repository.integration.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            assert(integrationQuery.status === 'success', 'expected suspense query');
            const integration = integrationQuery.data.items.find(
                (candidate) => candidate.id === props.integration.id
            );
            assert(integration, `integration ${props.integration.id} not found`);

            const assetQuery = repository.asset.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                    select(data) {
                        return chain(data)
                            .orderBy((item) => item.name.toLowerCase(), 'asc')
                            .value();
                    },
                }
            );

            const definitionQuery = repository.definition.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const mappingQuery = repository.mapping.useFind(
                context,
                {
                    integrations: [
                        {
                            id: integration.id,
                        },
                    ],
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const entityQuery = repository.entity.useFind(
                context,
                {
                    integrations: [
                        {
                            id: integration.id,
                        },
                    ],
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            assert(assetQuery.status === 'success', 'expected suspense query');
            assert(definitionQuery.status === 'success', 'expected suspense query');
            assert(mappingQuery.status === 'success', 'expected suspense query');
            assert(entityQuery.status === 'success', 'expected suspense query');

            const assetById = useMemo(
                () => keyBySafe(assetQuery.data, (item) => item.id.toString()),
                [assetQuery.data]
            );

            const mappingsByIntegrationId = useMemo(
                () =>
                    groupBySafe(mappingQuery.data.items, (item) =>
                        item.integration.id.toString()
                    ),
                [mappingQuery.data.items]
            );

            const definitionById = useMemo(
                () => keyBySafe(definitionQuery.data.items, (item) => item.id.toString()),
                [definitionQuery.data.items]
            );

            const entityByKey = useMemo(
                () => keyBySafe(entityQuery.data.items, (item) => item.key),
                [entityQuery.data.items]
            );

            const definition = definitionById[integration.definitionId] ?? null;
            const mappings = mappingsByIntegrationId[integration.id] ?? [];

            assert(definition, `definition not found`);
            assert(integration, `integration ${props.integration.id} not found`);

            const mappingsWithAssets = useMemo(
                () =>
                    chain(mappings)
                        .flatMap((mapping) => {
                            const asset = assetById[mapping.asset.id] ?? null;
                            const entity = entityByKey[mapping.entity.key] ?? null;
                            if (!entity) {
                                console.warn(
                                    `entity ${mapping.entity.key} not found`,
                                    entityByKey
                                );
                                return [];
                            }
                            return [
                                {
                                    connection: mapping,
                                    asset,
                                    entity,
                                },
                            ];
                        })
                        .orderBy(
                            (item) => [
                                item.entity.status === 'valid' ? 1 : -1,
                                item.entity.name.toLowerCase(),
                            ],
                            ['desc', 'asc']
                        )
                        .value(),
                [mappings, assetById, entityByKey]
            );

            return {
                mapping: {
                    assets: assetQuery.data,
                    mappables: entityQuery.data.items,
                    definition: definition,
                },
                integration: useMemo(
                    () => ({
                        integration,
                        definition,
                        mappings,
                        mappingsWithAssets: mappingsWithAssets,
                    }),
                    [integration, definition, mappings, mappingsWithAssets]
                ),
                activeMappings: mappingsWithAssets.filter(
                    (item) => item.entity.status === 'valid'
                ),
            };
        },
    };
}
