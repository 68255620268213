import { AxiosResponse } from 'axios';
import { IntegrationConf } from 'src/models/integration';
import { ConfirmResponse } from '../models/OAuth';
import { apiV1 } from './axios';

export const confirmLink = async (state: string, code: string, qs: string) => {
    const body = {
        state,
        code,
        qs,
    };
    return apiV1.post<unknown, AxiosResponse<ConfirmResponse>>(
        `/integrations/connect-link/confirm`,
        body
    );
};

export const resolveState = async (state: string) => {
    return apiV1.get<unknown, AxiosResponse<IntegrationConf>>(
        `/integrations/connect-link/${state}`
    );
};
