import { chain } from 'lodash';
import { useMemo } from 'react';
import { AccountsHelpers } from '../../../domain/accounts';
import { HomeGuideController } from './homeGuideInterface';

export function createHomeGuideController(): HomeGuideController {
    return {
        useProps(context, props) {
            const accountType = useMemo(
                () => AccountsHelpers.getAccountType(props.item),
                [props.item]
            );
            const items = useMemo(() => {
                return chain(props.item.items)
                    .filter((canidate) => canidate.audience.includes(accountType.kind))
                    .value();
            }, [accountType, props.item.items]);

            return {
                hrefProps: {
                    id: 'home_best_practices_learn_more_link',
                    href: 'https://win.varos.com/en/articles/9352014-varos-best-practices',
                },
                items: items,
                getItemProps(item) {
                    return {
                        id: item.id,
                        title: item.title,
                        url: item.url,
                    };
                },
            };
        },
    };
}
