import React, { useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { useAuthenticatedApi } from '../../../../infra';
import {
    RecommendationContext,
    RecommendationContextValue,
} from './recommendationContext';
import { RecommendationAdapterLegacy } from './recommendationAdapter';

export interface RecommendationAdapterConfig {
    axios: AxiosInstance;
}

export interface RecommendationProviderConfig {
    createAdapter(config: RecommendationAdapterConfig): RecommendationAdapterLegacy;
}

export function createRecommendationProvider(
    config: RecommendationProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const axios = useAuthenticatedApi();
        const adapter = useMemo(() => config.createAdapter({ axios }), []);
        const context: RecommendationContextValue = {
            adapter,
        };
        return (
            <RecommendationContext.Provider value={context}>
                {props.children}
            </RecommendationContext.Provider>
        );
    };
}
