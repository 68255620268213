import { z } from 'zod';
import React, { useEffect } from 'react';
import { InsightDto } from '../../../api';
import {
    OpportunityCollectionController,
    OpportunityItemController,
} from '../../../view';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyInsightTrackingEvent, InsightTrackingPayload } from './insightTrackingEvent';
import { InsightTrackingConfig } from './insightTrackingConfig';
import { chain } from 'lodash';

export function getInsightTrackingPayload(item: InsightDto): InsightTrackingPayload {
    return {
        plugin_id: item.definition.plugin,
        insight_definition_id: item.definition.id,
        insight_definition_name: item.definition.name,
        insight_id: item.id,
        insight_priority: item.priority,
        insight_kind: item.kind,
        insight_confidence: item.confidence,
        insight_status: item.status,
        insight_occured_at: item.period_end_at,
    };
}

export function createInsightTrackingStrategy(
    config: InsightTrackingConfig
): ApplicationEntryEnhancer {
    const { parameters } = config;
    const UrlQuerySchema = z.object({
        [parameters.insightId]: z.optional(z.string()),
    });
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyInsightTrackingEvent>();
            return tracker;
        }

        function enhanceOpportunityController(
            controller: OpportunityCollectionController
        ): OpportunityCollectionController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();

                    useEffect(() => {
                        if (viewProps.collection.data?.items?.length === 0) {
                            return;
                        }
                        // a bit of a hack, but the logic here is that if the url query params contain the key "insight" then
                        // the we assume the session origin is the insight email and send
                        // the correspondign event
                        const searchParams = new URLSearchParams(window.location.search);
                        const paramsObject = [...searchParams.entries()].reduce(
                            (acc, [key, value]) => ({
                                ...acc,
                                [key]: value,
                            }),
                            {}
                        );
                        const parsed = UrlQuerySchema.safeParse(paramsObject);
                        const params = parsed.success ? parsed.data : null;

                        const found = viewProps.collection.data?.items.find(
                            (item) =>
                                item.insight.id === params?.[config.parameters.insightId]
                        );

                        if (found) {
                            tracker.track(
                                'insight_email_dive_deeper_clicked',
                                getInsightTrackingPayload(found.insight)
                            );
                        }
                    }, [viewProps.collection.data?.items]);

                    return {
                        ...viewProps,
                        getRouteProps(...args) {
                            const [item] = args;
                            const routeProps = viewProps.getRouteProps(...args);

                            return {
                                ...routeProps,
                                onClick() {
                                    const payload = getInsightTrackingPayload(
                                        item.insight
                                    );
                                    tracker.track(
                                        'insight_app_dive_deeper_clicked',
                                        payload
                                    );
                                    routeProps.onClick?.();
                                },
                            };
                        },
                    };
                },
            };
        }

        function enhanceOpportunityPageController(
            controller: OpportunityCollectionController
        ): OpportunityCollectionController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();
                    useEffect(() => {
                        if (viewProps.collection.status === 'loaded') {
                            tracker.track('opportunity_list_view_loaded', {
                                insight_count: viewProps.collection.data?.items.length,
                                insight_plugin_ids: chain(
                                    viewProps.collection.data.items ?? []
                                )
                                    .map((item) => item.insight.definition.plugin)
                                    .uniq()
                                    .value(),
                                insight_definition_ids: chain(
                                    viewProps.collection.data.items ?? []
                                )
                                    .map((item) => item.insight.definition.id)
                                    .uniq()
                                    .value(),
                            });
                        }
                    }, [viewProps.collection.status]);
                    return viewProps;
                },
            };
        }

        function enhanceOpportunityItemController(
            controller: OpportunityItemController
        ): OpportunityItemController {
            return {
                ...controller,
                useProps(...args) {
                    const [_context, props] = args;
                    const viewProps = controller.useProps(...args);
                    const tracker = useTracker();

                    return {
                        ...viewProps,
                        onOpenClick(event) {
                            tracker.track(
                                'insight_app_dive_deeper_clicked',
                                getInsightTrackingPayload(props.item.insight)
                            );
                            return viewProps.onOpenClick(event);
                        },
                    };
                },
            };
        }

        const instance = create({
            ...init,
            route: {
                ...init.route,
                createInsightRouteV2(...args) {
                    const [config] = args;
                    const Route = init.route.createInsightRouteV2({
                        ...config,
                        deps: {
                            ...config.deps,
                            controller: {
                                ...config.deps.controller,
                                opportunity: {
                                    ...config.deps.controller.opportunity,
                                    collection: enhanceOpportunityPageController(
                                        config.deps.controller.opportunity.collection
                                    ),
                                },
                            },
                        },
                    });
                    return Route;
                },
            },
            controller: {
                ...init.controller,
                insights: {
                    ...init.controller.insights,
                    opportunity: {
                        ...init.controller.insights.opportunity,
                        createCollection(...args) {
                            const controller =
                                init.controller.insights.opportunity.createCollection(
                                    ...args
                                );
                            return enhanceOpportunityController(controller);
                        },
                        createItem(...args) {
                            const controller =
                                init.controller.insights.opportunity.createItem(...args);
                            return enhanceOpportunityItemController(controller);
                        },
                    },
                },
            },
            container: {
                ...init.container,
                workspaces: {
                    createWorkspaceInsightContainer(containerConfig) {
                        const original =
                            init.container.workspaces.createWorkspaceInsightContainer(
                                containerConfig
                            );

                        return {
                            ...original,
                            useProps(input) {
                                const tracker = useTracker();
                                const props = original.useProps(input);
                                return {
                                    ...props,
                                    onOpen() {
                                        tracker.track('insight_menu_expanded', {});
                                        return props.onOpen?.();
                                    },
                                };
                            },
                        };
                    },
                },
            },
            module: {
                ...init.module,
                createInsightModule(moduleConfig) {
                    return init.module.createInsightModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(...args) {
                                        const [context, formatter, config] = args;
                                        const tracker = useTracker();
                                        const viewProps = controller.useProps(...args);

                                        useEffect(() => {
                                            // a bit of a hack, but the logic here is that if the url query params contain the key "insight" then
                                            // the we assume the session origin is the insight email and send
                                            // the correspondign event
                                            const searchParams = new URLSearchParams(
                                                window.location.search
                                            );
                                            const paramsObject = [
                                                ...searchParams.entries(),
                                            ].reduce(
                                                (acc, [key, value]) => ({
                                                    ...acc,
                                                    [key]: value,
                                                }),
                                                {}
                                            );
                                            const parsed =
                                                UrlQuerySchema.safeParse(paramsObject);
                                            const params = parsed.success
                                                ? parsed.data
                                                : null;

                                            const found = viewProps.items.find(
                                                (item) => item.id === params?.insight
                                            );
                                            if (found) {
                                                tracker.track(
                                                    'insight_email_dive_deeper_clicked',
                                                    getInsightTrackingPayload(found)
                                                );
                                            }
                                        }, []);

                                        return {
                                            ...viewProps,
                                            getChildProps(item, index) {
                                                const childProps =
                                                    viewProps.getChildProps(item, index);
                                                const payload =
                                                    getInsightTrackingPayload(item);
                                                return {
                                                    ...childProps,
                                                    onOpen() {
                                                        tracker.track(
                                                            'insight_detail_expanded',
                                                            payload
                                                        );
                                                        return childProps.onOpen();
                                                    },
                                                    onClose() {
                                                        return childProps.onClose();
                                                    },
                                                    onDetailClick() {
                                                        tracker.track(
                                                            'insight_app_dive_deeper_clicked',
                                                            payload
                                                        );
                                                        return childProps.onDetailClick();
                                                    },
                                                };
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
