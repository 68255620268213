import { keyBy } from 'lodash';
import { assert } from '../../../../util/assert';
import { QueryResponse, ViewEntity } from '../../../../domain/query';
import { DistributionVisualization } from '../../../../domain/visualization';
import {
    BarVisualizationResult,
    BarVisualizationEntry,
    VisualizationResultSeries,
    DistributionVisualizationResult,
} from '../../../../domain/visualization/result';
import { AnyResolvedType } from '../../../../domain/attributes';

export function buildDistributionResult(
    view: Pick<ViewEntity<AnyResolvedType>, 'id' | 'columns'>,
    visualization: DistributionVisualization,
    response: QueryResponse
): DistributionVisualizationResult {
    const [partitionKey] = response.metadata.partition;
    // const [scalarKey] = response.metadata.scalar;
    const [facetKey] = response.facet?.keys ?? [];
    const [metric] = visualization.metrics;

    const propertyFacet = view.columns.find((item) => item.key === facetKey);

    assert(partitionKey, `no partition key found for view ${view.id}`);
    assert(propertyFacet, `facet property ${facetKey} not found in view ${view.id}`);

    // TODO fix this hack
    // it should be based on the key provided as part of the query request
    const scalarPrimaryKey = 'dist';
    const scalarSecondaryKey = metric.key;

    const facetProperty = view.columns.find((candidate) => candidate.key === facetKey);
    // V3 query API downcases source key
    const facetSourceKey = facetProperty?.source?.toLowerCase() ?? facetProperty?.key;
    assert(facetSourceKey, `no facet key found in view`);
    assert(response.facet, `expected query response to be faceted`);
    // console.log('facetSourceKey', facetSourceKey);
    // console.log('view', view);
    // console.log('response.metadata', response.metadata);

    const [queryDist, queryAbsolute] = response.queries;

    assert(response.metadata.period, 'no period found in metadata');
    return {
        kind: 'distribution',
        dimensions: [],
        type: metric.type,
        facet: {
            property: propertyFacet,
            terms: response.facet.terms.map((term) => ({
                key: term.name,
            })),
        },
        period: {
            from: response.metadata.period.start,
            to: response.metadata.period.end,
        },
        schema: keyBy(view.columns, (item) => item.key),
        series: queryDist.series.items.map(
            (series, seriesIndex): VisualizationResultSeries<BarVisualizationEntry> => {
                const seriesAbsolute = queryAbsolute.series.items[seriesIndex];
                return {
                    key: series.name.toLocaleLowerCase(),
                    name: series.name,
                    data: series.data.map((row, rowIndex) => {
                        const valueFacet = row[facetSourceKey];
                        const valuePrimary = row[scalarPrimaryKey];
                        // const valueSecondary = row[scalarSecondaryKey] ?? null;
                        const valueSeconary =
                            seriesAbsolute.data[rowIndex]?.[scalarSecondaryKey] ?? null;
                        assert(typeof valueFacet === 'string', () => {
                            console.info({
                                valueFacet,
                                valuePrimary,
                                facetSourceKey,
                                scalarPrimaryKey,
                                row,
                                view,
                            });
                            return `facet key '${facetKey}' found in row`;
                        });
                        assert(
                            typeof valuePrimary === 'number' || valuePrimary === null,
                            () => {
                                console.error(row);
                                return `no value found in row`;
                            }
                        );
                        assert(
                            typeof valueSeconary === 'number' || valueSeconary === null,
                            () => {
                                console.error(row);
                                return `no value found in row`;
                            }
                        );
                        return {
                            // date,
                            category: valueFacet,
                            value: valuePrimary,
                            secondary: valueSeconary,
                            key: {},
                        };
                    }),
                };
            }
        ),
    };
}
