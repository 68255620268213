import React from 'react';
import {
    Box,
    Heading,
    HStack,
    Icon,
    StackDivider,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import {
    isAssetProperty,
    isAssetType,
    isCurrencyProperty,
    isTreeProperty,
} from '../../../../domain/attributes';
import {
    GroupEditControllerProps,
    GroupEditViewProps,
    TraitFilterViewProps,
} from '../../../../view/peer';
import { AiFillQuestionCircle, AiOutlineInfo, AiOutlineInfoCircle } from 'react-icons/ai';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { AnalysisSegmentViewProps } from './segmentProps';

export function createAnalysisSegmentView(
    config: AnalysisRouteDeps
): React.FC<{ children?: React.ReactNode | undefined } & AnalysisSegmentViewProps> {
    const {
        Container: { PropertyInput: InputContainer },
        View: { Input },
    } = config;
    return (props) => (
        <VStack
            spacing={6}
            divider={<StackDivider borderWidth={1} borderColor="whiteAlpha.200" />}
        >
            {props.items.filter(isAssetProperty).map((field) => {
                return (
                    <VStack key={field.key} w="full" align="start" spacing={3}>
                        <VStack w="full" align="start" spacing={2} position="relative">
                            <HStack w="full" spacing={3}>
                                <Heading
                                    color="whiteAlpha.900"
                                    fontSize="md"
                                    fontWeight="medium"
                                >
                                    {field.name}
                                </Heading>
                            </HStack>
                        </VStack>
                    </VStack>
                );
            })}
            {props.items.filter(isTreeProperty).map((field) => {
                return (
                    <VStack key={field.key} w="full" align="start" spacing={3}>
                        <VStack w="full" align="start" spacing={2} position="relative">
                            <HStack w="full" spacing={3}>
                                <Heading
                                    color="whiteAlpha.900"
                                    fontSize="md"
                                    fontWeight="medium"
                                >
                                    {field.name}
                                </Heading>
                            </HStack>
                        </VStack>
                        <VStack w="full" fontWeight="medium" align="start">
                            <VStack w="full" align="start" spacing={3}>
                                <InputContainer
                                    {...props.getPropertyInputProps(field)}
                                    as={Input}
                                    truncateCount={6}
                                    theme={{
                                        tree: {
                                            item: {
                                                label: {
                                                    fontSize: 'sm',
                                                    color: 'whiteAlpha.700',
                                                },
                                            },
                                            caption: {
                                                label: {
                                                    color: 'whiteAlpha.500',
                                                },
                                            },
                                            more: {
                                                label: {
                                                    color: 'whiteAlpha.700',
                                                },
                                            },
                                        },
                                    }}
                                />
                            </VStack>
                        </VStack>
                    </VStack>
                );
            })}
            {props.items.filter(isCurrencyProperty).map((field) => {
                const inputProps = props.getPropertyInputProps(field);
                return (
                    <VStack key={field.key} w="full" align="start" spacing={3}>
                        <HStack w="full" spacing={3} justify="space-between">
                            <Heading
                                color="whiteAlpha.900"
                                fontSize="md"
                                fontWeight="medium"
                            >
                                {field.name}
                            </Heading>
                            {inputProps.status?.Icon && (
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    isDisabled={!inputProps.status.description}
                                    label={
                                        <Box p={2}>{inputProps.status?.description}</Box>
                                    }
                                >
                                    <Icon
                                        color="gray.500"
                                        as={inputProps.status?.Icon}
                                        onClick={inputProps.status.onClick}
                                        cursor="pointer"
                                        opacity={0.5}
                                    />
                                </Tooltip>
                            )}
                        </HStack>
                        {field.description && (
                            <Text color="whiteAlpha.700">{field.description}</Text>
                        )}
                        <VStack w="full" align="stat" fontWeight="medium">
                            <Box
                                onClick={inputProps.status?.onClick}
                                cursor={
                                    inputProps.status?.onClick ? 'pointer' : undefined
                                }
                            >
                                <InputContainer
                                    {...inputProps}
                                    as={Input}
                                    colorScheme="green"
                                />
                            </Box>
                        </VStack>
                    </VStack>
                );
            })}
        </VStack>
    );
}
