import { WorkspaceRedirectController } from './workspaceRedirectInterface';


export const createWorkspaceRedirectController = (): WorkspaceRedirectController => ({
    useProps(props) {
        props.organization
        return {
            to: props.workspace ? `/u/assets/${props.workspace.id}/home`: null
        }
    },
})