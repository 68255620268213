import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useSystemContextV2, useWorkspaceContextV2 } from '../../../context';
import { resolveView } from '../../../domain/query';
import { castCohortStateToRanking } from '../../../domain';
import { AnalysisRouteConfig } from './analysisRouteConfig';
import { AnalysisRouteViewProps } from './analysisRouteProps';
import { AnalysisRouteContext, AnalysisRouteController } from './analysisRouteInterface';

export function createAnalysisRouteController(
    config: AnalysisRouteConfig
): AnalysisRouteController {
    const {
        deps: { hook },
    } = config;

    return {
        useProps(context, data, props): AnalysisRouteViewProps {
            const disclosure = useDisclosure();
            const [searchParams, setQueryParams] = useSearchParams();

            const {
                data: {
                    views: { data: views = [] },
                },
            } = useSystemContextV2();

            const {
                plugins: { data: plugins = [] },
                metric: {
                    definitions: { data: metrics = [] },
                },
            } = useWorkspaceContextV2();

            const organization = hook.useOrganization();
            const workspace = hook.useWorkspace();
            const auth = hook.useAuth();

            // const selected = useMemo(
            //     () =>
            //         metrics.find((item) => item.id === props.visualization.metrics.id) ??
            //         null,
            //     [metrics, props.visualization.metrics.id]
            // );

            const view = useMemo(() => {
                return resolveView(data.matched, data.query.member.data ?? []);
            }, [data.query.member.data, data.matched]);

            const mappedcontext: AnalysisRouteContext = {
                organization,
                workspace,
                auth,
                values: {
                    // TODO refactor to array
                    metric: data.selected[0],
                    view,
                },
                data,
            };

            const formValues = {
                visualization: data.form.visualization.watch(),
            };

            return {
                // getSelectionProps() {
                //     return {
                //         context: mappedcontext,
                //         selected:data.selected,
                //         // selected:
                //         //     metrics.find(
                //         //         (item) => item.id === props.visualization.metrics.id
                //         //     ) ?? null,
                //         onSelect(value) {
                //             searchParams.set('metric', value.id);
                //             setQueryParams(searchParams, { replace: true });
                //             disclosure.onClose();
                //         },
                //         disclosure,
                //     };
                // },
                getCompetitiveSetProps() {
                    return {
                        context: mappedcontext,
                        view: view,
                        plugin: data.plugin,
                    };
                },
                getRankingProps() {
                    return {
                        context: mappedcontext,
                        view: view,
                        value: data.visualization.ranking,
                        onChange(value) {
                            data.form.visualization.setValue('ranking', value);
                        },
                    };
                },
                getBreakdownProps() {
                    return {
                        context: mappedcontext,
                        view: view,
                    };
                },
                getSegmentProps() {
                    return {
                        context: mappedcontext,
                        view: view,
                        plugin: data.plugin,
                    };
                },
                getFilterProps() {
                    return {
                        context: mappedcontext,
                        view: view,
                        value: data.visualization.filters.reduce(
                            (acc, item) => ({
                                ...acc,
                                [item.key]: item.value,
                            }),
                            {}
                        ),
                        onChange(value) {
                            data.form.visualization.setValue('filters', value);
                        },
                    };
                },
                getContentProps() {
                    return {
                        context: mappedcontext,
                        definitions: data.selected,
                        view: view,
                        plugin: data.plugin,
                        visualization: data.visualization,
                    };
                },
                getDateProps() {
                    return {
                        context: mappedcontext,
                        data,
                    };
                },
            };
        },
    };
}
