import { z } from 'zod';
import { MetricDefinitionSchema } from '../definition/definitionSchema';

export const MetricSchema = z.object({
    name: z.nullable(z.string()),
    definition: z.string(),
    // definition: z.union([z.string(), MetricDefinitionSchema]),
});

export const MetricListResponseSchema = z.object({
    data: z.array(MetricSchema),
});
