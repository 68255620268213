import moment from 'moment';
import { Organization } from '../../domain';
import { AutohideMiddlewareConfig } from './autohideConfig';

export function isAutohidden(
    config: AutohideMiddlewareConfig,
    organization: Organization,
    now: Date
) {
    const diff = moment(now).diff(moment(organization.createdAt), 'days');
    return diff > config.autohideAfterDays;
}
