import React from 'react';
import { fetchChildrenProps, getAllChildrenProps } from '../../util';
import { PageLayoutConfig } from './pageLayoutConfig';
import { PageLayoutInterface } from './pageLayoutInterface';
import { PageLayoutViewProps } from './pageLayoutProps';
import { createPageLayoutView } from './pageLayoutView';

export function createPageLayout(config: PageLayoutConfig): PageLayoutInterface {
    const View = createPageLayoutView(config);
    const Instance: PageLayoutInterface = {
        Container(props) {
            const { header, sectionlist } = fetchChildrenProps(props.children, {
                header: Instance.Header,
                sectionlist: Instance.SectionList,
            });
            const { sectionItemProps } = getAllChildrenProps(sectionlist.children, {
                sectionItemProps: Instance.SectionItem,
            });
            const viewProps: PageLayoutViewProps = {
                header,
                section: {
                    items: sectionItemProps,
                },
            };
            return <View {...viewProps} />;
        },
        Header(props) {
            return <>{props.children}</>;
        },
        SectionList(props) {
            return <>{props.children}</>;
        },
        SectionItem(props) {
            return <>{props.children}</>;
        },
    };
    return Instance;
}
