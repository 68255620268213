import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { DemoChartAdapter } from '../../app/demo';
import { QueryResponse, QueryRequest } from '../../domain/query';

const QUERY_PREFIX = 'queries';

export interface DemoChartService {
    cancelQueries(): void;
    useQueryResponse(query: QueryRequest): QueryResponse;
}

export interface DemoChartServiceConfig {
    adapter: DemoChartAdapter;
}

export function createDemoChartService(config: DemoChartServiceConfig): DemoChartService {
    return {
        cancelQueries() {
            const client = useQueryClient();
            return client.cancelQueries({
                queryKey: [QUERY_PREFIX],
            });
        },
        useQueryResponse(query: QueryRequest) {
            const queryKey = JSON.stringify(query);
            const { data: response } = useQuery({
                queryKey: [QUERY_PREFIX, queryKey],
                async queryFn(context) {
                    const response = await config.adapter.executeQuery(query, {
                        signal: context.signal,
                    });
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
                retry: false,
            });
            if (!response) {
                throw new Error('missing response data');
            }
            return response;
        },
    };
}
