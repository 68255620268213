import { chain, keyBy, orderBy } from 'lodash';
import { assert } from '../../../../util/assert';
import { QueryResponse, ViewEntity } from '../../../../domain/query';
import { BarVisualization } from '../../../../domain/visualization';
import { BarVisualizationResult } from '../../../../domain/visualization/result';
import { AnyResolvedType, AnyType } from '../../../../domain/attributes';

export function buildBarResult(
    view: Pick<ViewEntity<AnyResolvedType>, 'id' | 'columns'>,
    visualization: BarVisualization,
    response: QueryResponse
): BarVisualizationResult {
    const [partitionKey] = response.metadata.partition;
    // const [scalarKey] = response.metadata.scalar;
    const [facetKey] = response.facet?.keys ?? [];

    let metric: { key: string; type: AnyType } | null = null;
    if (visualization.aggregation === 'count') {
        const [facetProp, ...rest] = visualization.breakdown.properties;
        assert(rest.length === 0, 'nested breakdowns not supported');
        const property = view.columns.find(
            (candidate) => candidate.key === facetProp.key
        );
        assert(property, 'facet property not found in dataset');
        metric = property;
    } else {
        const [first] = visualization.metrics;
        assert(first, 'no metric found for bar visualization configuration');
        metric = first;
    }

    const propertyFacet = view.columns.find((item) => item.key === facetKey);

    assert(partitionKey, `no partition key found for view ${view.id}`);
    assert(propertyFacet, `facet property ${facetKey} not found in view ${view.id}`);

    // TODO fix this hack
    // it should be based on the key provided as part of the query request
    const scalarPrimaryKey = visualization.aggregation;
    // const scalarSecondaryKey = metric.key;

    const facetProperty = view.columns.find((candidate) => candidate.key === facetKey);
    // V3 query API downcases source key
    const facetSourceKey = facetProperty?.source?.toLowerCase() ?? facetProperty?.key;
    assert(facetSourceKey, `no facet key found in view`);
    assert(response.facet, `expected query response to be faceted`);
    // console.log('facetSourceKey', facetSourceKey);
    // console.log('view', view);
    // console.log('response.metadata', response.metadata);

    const [query] = response.queries;

    assert(response.metadata.period, 'no period found in metadata');
    return {
        kind: 'bar',
        dimensions: [],
        type: metric.type,
        facet: {
            property: propertyFacet,
            terms: response.facet.terms.map((term) => ({
                key: term.name,
            })),
        },
        period: {
            from: response.metadata.period.start,
            to: response.metadata.period.end,
        },
        schema: keyBy(view.columns, (item) => item.key),
        series: [
            {
                key: query.key,
                name: query.key,
                data: chain(query.series.items)
                    .flatMap((series) => {
                        const [row] = series.data;
                        const valueFacet = row[facetSourceKey];
                        const valuePrimary = row[scalarPrimaryKey];
                        assert(
                            typeof valueFacet === 'string',
                            'expected categorical value to be a string'
                        );
                        assert(
                            typeof valuePrimary === 'number',
                            'expected aggregateed value to be numerical'
                        );
                        return {
                            category: valueFacet,
                            value: valuePrimary,
                            secondary: null,
                        };
                    })
                    .orderBy((item) => item.value, 'desc')
                    .value(),
            },
        ],
    };
}
