import { useContext, useMemo } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FeatureFlagContext } from '../featureFlagConstant';
import {
    ReportInvitationRepository,
    ReportV2Query,
    ReportV2Repository,
} from '../../../app';

export function createFeatureFlagDraftReportsMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceReportRepository(
            repository: ReportV2Repository
        ): ReportV2Repository {
            return {
                ...repository,
                useCollection(context, query, options) {
                    const features = useContext(FeatureFlagContext);
                    if (!features) {
                        throw new Error(`feature flag context not found`);
                    }
                    let transformed: Partial<ReportV2Query> = query;
                    if (features.flags.draftReports?.enabled) {
                        transformed = {
                            ...query,
                            status: [...(query.status ?? []), 'published', 'draft'],
                        };
                    } else {
                        transformed = {
                            ...query,
                            status: [...(query.status ?? []), 'published'],
                        };
                    }
                    return repository.useCollection(context, transformed, options);
                },
            };
        }

        return create({
            ...config,
            repository: {
                ...config.repository,
                reports: {
                    ...config.repository.reports,
                    createReport(...args) {
                        const repository = config.repository.reports.createReport(
                            ...args
                        );
                        return enhanceReportRepository(repository);
                    },
                },
            },
        });
    };
}
