import { ChargeAdapter } from "../../../../app";
import { BillinSessionCompleteDto } from "../../../../api/billing/session";
import { ChargeDto } from "../../../../api/billing/charge";
import { ChargeApiImplConfig } from "./chargeApiConfig";

function isChargeSession(sessionComplete: BillinSessionCompleteDto): sessionComplete is ChargeDto {
    return typeof (sessionComplete as ChargeDto).id !== 'undefined';
}

export function createChargeApiImpl(
    config: ChargeApiImplConfig
): ChargeAdapter {
    return {
        async retrieveBySession(context, query) {
            const retVal = await config.sessionResource.get(context, query.sessionId);
            if(isChargeSession(retVal)) {
                return retVal;
            }
            throw new Error('session does not contain a charge but a subscription object');
        },
        createSession(context, props) {
            return config.sessionResource.create(context, props);
        },
    }
}