import { useMemo } from 'react';
import { combineCollectionStatus } from '../../../base';
import { IntegrationDefinition } from '../../../domain/assets';
import { IntegrationServiceConfig } from './integrationConfig';
import { IntegrationService } from './integrationInterface';
import { OrganizationIntegrationAggregate } from './integrationModel';

export function createIntegrationService(
    config: IntegrationServiceConfig
): IntegrationService {
    const { repository } = config;
    return {
        useFind(context, options) {
            const definitionQuery = repository.definition.useFind(context, {}, {});

            const integrationQuery = repository.integration.useFind(context, {}, {});

            const mappingQuery = repository.mapping.useFind(
                context,
                {
                    integrations:
                        integrationQuery.data?.items.map((item) => ({ id: item.id })) ??
                        [],
                },
                {
                    retry: false,
                }
            );

            const definitionById = useMemo(
                () =>
                    definitionQuery.data?.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: item,
                        }),
                        {} as Record<string, IntegrationDefinition | undefined>
                    ) ?? {},
                [definitionQuery.data]
            );

            // const collectionStatus = combineCollectionStatus(
            //     integrationQuery,
            //     mappingQuery,
            //     definitionQuery
            // );

            const collectionStatus = [
                integrationQuery,
                mappingQuery,
                definitionQuery,
            ].every((item) => item.status === 'success')
                ? 'loaded'
                : 'loading';

            const items = useMemo(() => {
                return (
                    integrationQuery.data?.items.flatMap(
                        (item): OrganizationIntegrationAggregate[] => {
                            const definition = definitionById[item.definitionId];
                            if (!definition) {
                                console.warn(
                                    `definition ${item.definitionId} not found`,
                                    definitionById
                                );
                                return [];
                            }
                            return [
                                {
                                    integration: item,
                                    definition,
                                    mappings:
                                        mappingQuery.data?.items.filter(
                                            (mapping) =>
                                                mapping.integration.id === item.id
                                        ) ?? [],
                                },
                            ];
                        }
                    ) ?? []
                );
            }, [integrationQuery.data, definitionById, mappingQuery.data]);

            if (collectionStatus == 'loading') {
                return {
                    status: 'loading',
                    data: null,
                };
            }

            return {
                status: 'loaded',
                data: {
                    total: items.length,
                    items: items,
                    limit: 1000,
                },
            };
        },
    };
}
