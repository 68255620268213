import moment from 'moment';
import { TagProps } from '@chakra-ui/react';
import {
    BarVisualization,
    BarVisualizationResult,
} from '../../../../domain/visualization';
import { ValueFormatter } from '../../../../app/attributes';
import { ChartSeriesProps } from '../../../../ui';
import { AnyType, isEnumType } from '../../../../domain/attributes';
import { BarChartControllerProps } from '../../../../vendor/chakra-chart';
import { assert } from '../../../../util/assert';
import { BarVisualizationResultViewProps } from './barProps';
import { buildLegendProps } from '../legend';
import { VisualizationResultDateProps } from '../date';

export function buildBarVisualizationResultViewProps(
    // router: VisualizationResultRouter,
    formatter: ValueFormatter,
    // context: OrganizationContextV2,
    visualization: BarVisualization,
    item: BarVisualizationResult
): BarVisualizationResultViewProps {
    const COLORS: TagProps['colorScheme'][] = ['purple', 'green'];

    assert(
        visualization.aggregation === 'count',
        `only coutn based bar charts are implemented`
    );

    const [facetProp, ...rest] = visualization.breakdown.properties;
    assert(rest.length === 0, 'nested breakdowns not supported');
    const labelProperty = item.schema[facetProp.key];
    assert(labelProperty, 'facet prop not found in schema');

    const valueType: AnyType = 'integer';
    const labelType = labelProperty.type;

    // console.log('DEBUG visualization', visualization);

    const labelByValue = item.series.reduce(
        (acc, series) =>
            series.data.reduce(
                (acc, entry) =>
                    entry.value === null
                        ? acc
                        : {
                              ...acc,
                              [entry.value]: {
                                  value: formatter.format(valueType, entry.value),
                                  secondary: entry.secondary
                                      ? formatter.format(item.type, entry.secondary)
                                      : null,
                              },
                          },
                acc
            ),
        {} as Record<number, { value: string | undefined; secondary: string | null }>
    );
    const labelByCategory = item.facet.terms.reduce(
        (acc, term) => ({
            ...acc,
            [term.key]: formatter.format(item.facet.property.type, term.key),
        }),
        {} as Record<string, string | undefined>
    );
    const [sample] = item.series;

    const allowedLabels = new Set(
        isEnumType(labelProperty.type)
            ? labelProperty.type.members.map((member) => member.value)
            : []
    );

    // console.log('DEBUG allowedLabels', allowedLabels);

    const chartProps: BarChartControllerProps = {
        labels: sample.data
            .map((item) => item.category)
            .filter((label) => {
                return isEnumType(labelProperty.type) ? allowedLabels.has(label) : true;
            }),
        series: item.series.map((series, index): ChartSeriesProps => {
            return {
                name: series.name,
                data: series.data.map((row) => ({
                    value: row.value,
                    secondary: row.secondary ?? undefined,
                })),
                colorScheme: COLORS[index] ?? 'gray',
            };
        }),
        formatValue(value) {
            const label = labelByValue[value];
            if (label?.value === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label.value;
        },
        formatScale(value) {
            const label = labelByValue[value];
            if (label?.value === undefined) {
                // fallback to handle dynamic axes
                return formatter.format('percent', value);
            }
            return label.value;
        },
        formatTooltip(value) {
            const label = labelByValue[value];
            if (label?.secondary === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label.secondary;
        },
        formatCategory(value) {
            const label = labelByCategory[value];
            if (label === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label;
        },
    };

    const legendProps = buildLegendProps(visualization, item, { colors: COLORS });

    const dateProps: VisualizationResultDateProps = {
        period: {
            label: formatter.format('daterange', item.period, {
                notation: 'short',
            }),
        },
    };

    return {
        kind: 'bar',
        getDateProps() {
            return dateProps;
        },
        getLegendProps() {
            return legendProps;
        },
        getChartProps() {
            return chartProps;
        },
    };
}
