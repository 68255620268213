import { CompanyListBoxMacro } from '../../../macro';
import { OnboardingStrategyConfig } from '../../../route';
import { OnboardingCompetitiveSetController } from './onboardingCompetitiveSetInterface';

export function createOnboardingCompetitiveSetController(
    config: OnboardingStrategyConfig & { macro: CompanyListBoxMacro }
): OnboardingCompetitiveSetController {
    const {
        macro: {
            controller: { useProps: useCompanyProps },
        },
    } = config;
    return {
        useProps(context, item, props) {
            const company = useCompanyProps(context, item.company, props.company);
            const formValues = company.form.form.watch();
            const isSubmittable = formValues.companies.length > 0;
            return {
                company,
                action: {
                    submit: {
                        button: {
                            ...company.form.getSubmitButtonProps(),
                            isDisabled: !isSubmittable,
                        },
                        tooltip: {
                            label: `Select at least 5 companies to continue`,
                            isDisabled: isSubmittable,
                        },
                    },
                    skip: {
                        onClick: props.onSkip,
                    },
                },
            };
        },
    };
}
