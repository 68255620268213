import React from 'react';
import { AvatarBadgeProps, Badge, Box } from '@chakra-ui/react';

export interface ButtonBadgeProps extends AvatarBadgeProps {}

export const ButtonBadge: React.FC<
    { children?: React.ReactNode | undefined } & ButtonBadgeProps
> = ({ ...props }) => {
    return (
        <Badge
            position={'absolute'}
            top={'-6px'}
            right={'-6px'}
            minWidth="17px"
            minHeight="15px"
            borderRadius={'50%'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            pointerEvents="none"
            {...props}
        >
            {props.children}
        </Badge>
    );
};
