import { useMemo } from 'react';
import { AssetListAggregate } from '../../../../../view/assets';
import { assert } from '../../../../../util/assert';
import { SettingAssetItemConfig } from '../assetConfig';
import { SettingAssetListLoader } from './listInterface';
import { MappingConnection } from 'src/v2/domain/assets';
import { groupBy } from 'lodash';

export function createSettingAssetListLoader(
    config: Pick<SettingAssetItemConfig, 'repository'>
): SettingAssetListLoader {
    const { repository } = config;
    const limit = 500;
    return {
        useLoad(context) {
            const assets = repository.asset.useFind(
                context,
                { limit },
                {
                    staleTime: 60000,
                    suspense: true,
                }
            );
            const organization = repository.organization.useLookup(
                context,
                { id: context.organization.id },
                {
                    staleTime: Infinity,
                    suspense: true,
                }
            );
            const integration = repository.integration.useFind(
                context,
                {},
                {
                    staleTime: 60000,
                    suspense: true,
                }
            );
            const mapping = repository.mapping.useFind(
                context,
                { integrations: integration.data?.items ?? [] },
                {
                    staleTime: 60000,
                    suspense: true,
                }
            );
            assert(assets.status === 'success', 'expected suspense query');
            assert(organization.status === 'success', 'expected suspense query');
            assert(integration.status === 'success', 'expected suspense query');
            assert(mapping.status === 'success', 'expected suspense query');

            const mappingByAsset = useMemo(() => {
                return groupBy(mapping.data.items, (item) => item.asset.id) as Record<
                    string,
                    MappingConnection[]
                >;
            }, [mapping.data]);

            return useMemo<AssetListAggregate>(
                () => ({
                    organization: organization.data,
                    assets: assets.data.map((item) => {
                        const mappings = mappingByAsset[item.id] ?? [];
                        return { asset: item, mappings };
                    }),
                }),
                [assets.data, organization.data, mappingByAsset]
            );
        },
    };
}
