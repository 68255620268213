import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import { buildStatusProps } from '../../../common';
import { Formatter } from '../../../../../hooks';
import { getConnectionStatus } from './connectionItemHelper';
import { DatasetConnectionAggregate } from './connectionItemModel';
import { DatasetConnectionItemProps } from './connectionItemProps';

export function buildDatasetConnectionItemProps(
    formatter: Formatter,
    item: DatasetConnectionAggregate
): DatasetConnectionItemProps {
    const [first] = item.connections;
    const absoluteDateLabel = first ? formatter.date(first.updatedAt, {}) : null;
    const absoluteTimeLabel = first ? formatter.time(first.updatedAt, {}) : null;
    const relativeLabel = first ? formatter.timeago(first.updatedAt, {}) : null;
    const status = getConnectionStatus(item);
    return {
        id: item.view.id,
        name: item.view.name,
        iconUrl: item.plugin.iconUrl ?? 'N/A',
        status: buildStatusProps(status),
        updated:
            relativeLabel && absoluteDateLabel && absoluteTimeLabel
                ? {
                      relative: {
                          label: relativeLabel,
                      },
                      absolute: {
                          description: `Connection was last updated on ${absoluteDateLabel} at ${absoluteTimeLabel}`,
                      },
                  }
                : null,
        getMetadataListProps() {
            const plural = pluralize('sources', item.connections.length);
            return {
                items: [
                    {
                        label:
                            item.connections.length > 0
                                ? `${item.connections.length} data ${plural}`
                                : `No data sources`,
                    },
                ],
            };
        },
    };
}
