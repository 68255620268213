import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { InsightDto } from './insightModel';
import { InsightResource } from './insightInterface';
import { InsightListResponseSchema, InsightSchema } from './insightSchema';
import { INSIGHT_FIXTURE } from './insightFixture';
import moment from 'moment';

export function createInsightResource(client: AxiosInstance): InsightResource {
    return {
        async get(context, id) {
            // const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            // await scheme.store.waitAuthenticated();
            // return INSIGHT_FIXTURE[0];
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get<InsightDto>(`/api/v1/insights/${id}`, {
                params: {
                    ...params,
                    expand: ['definition'],
                },
                headers,
            });
            const parsed = InsightSchema.parse(response.data);
            return parsed;
        },
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get<ListResponse<InsightDto>>(
                '/api/v1/insights',
                {
                    params: {
                        expand: ['definition'],
                        ...params,
                        ...query,
                        occured_before: query.occured_before
                            ? moment.utc(query.occured_before).format('YYYY-MM-DD')
                            : undefined,
                        occured_since: query.occured_since
                            ? moment.utc(query.occured_since).format('YYYY-MM-DD')
                            : undefined,
                    },
                    headers: {
                        ...headers,
                    },
                }
            );

            const parsed = InsightListResponseSchema.parse(response.data);
            return parsed;
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // console.log('referching insights...');

            // return {
            //     data: INSIGHT_FIXTURE,
            // };
        },
        async create(context, payload) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(
                '/api/v1/insights',
                { ...payload, date: moment(payload.date).format('YYYY-MM-DD') },
                {
                    params: {
                        expand: ['definition'],
                    },
                    headers: {
                        ...headers,
                    },
                }
            );

            const parsed = InsightListResponseSchema.parse(response.data);
            return parsed;
        },
    };
}
