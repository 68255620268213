import { isPreviousCondition } from '../../../../domain/attributes';
import { formatCondition } from '../filterHelper';
import { FilterItemViewProps, FilterItemInternalProps } from './filterItemProps';

export const useInternal = (props: FilterItemViewProps): FilterItemInternalProps => {
    let label: null | string = null;

    // console.log('cond?', props.current);
    if (props.current.value && props.filter.default?.operator === 'previous') {
        // console.log('props.current', props.current);
        label = `Previous ${props.current.value.amount} ${props.current.value.interval}`;
    } else if (props.current?.value) {
        label = formatCondition(props.filter.property, {
            key: props.filter.property.key,
            operator: props.filter.default?.operator,
            value: props.current.value,
        });
    } else {
        // label = 'lol';
        // @ts-expect-error
        label = formatCondition(props.filter.property, {
            key: props.filter.property.key,
            operator: 'in',
            value: null,
        });
    }

    // console.log(props);

    return {
        style: props.style,
        current: {
            // key: props.filter.property.key,
            // operator: props.filter.default?.operator,
            value: props.current.value,
        },
        label,
        filter: props.filter,
        // inputProps: getInputProps(props.filter),
        // formProps: {
        //     onSubmit: props.onApply,
        // },
        onApply: props.onApply,
        onClear: props.onClear,
        getTriggerProps: props.getTriggerProps,
    };
};
