import { NotEnoughDataError } from '../../../../api';
import { RateLimitError, ErrorFormatter } from '../../../base';

export function createEmailErrorFormatter(): ErrorFormatter {
    return {
        format(error) {
            if (error instanceof NotEnoughDataError) {
                return {
                    name: 'Insufficient Sample',
                    description: `The filters you've set for your Competitive Set have resulted in too few companies for us to provide benchmarks. Please log in and widen your filters to include more companies`,
                    hint: null,
                };
            }
            return {
                name: error.name,
                description: error.message,
                hint: null,
            };
        },
    };
}
