import React from 'react';
import { AcceptProfileViewProps } from './acceptProfileProps';
import { RegistrationViewConfig } from '../../base';

export function createRegistrationAcceptProfileView(
    config: RegistrationViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & AcceptProfileViewProps> {
    const {
        UI: {
            Form: { FieldList, FieldItem, Input, InputGroup, Select, InputLeftAddon },
        },
        Layout: { Step: Layout },
    } = config;
    return (props) => {
        return (
            <Layout {...props.layout}>
                <FieldList w="full" spacing={4} columns={2}>
                    <FieldItem {...props.form.getFieldItemProps('email')} label="Email">
                        <InputGroup>
                            <InputLeftAddon children="@" />
                            <Input
                                {...props.form.form.register('email')}
                                placeholder="Enter email"
                            />
                        </InputGroup>
                    </FieldItem>
                    <FieldItem
                        {...props.form.getFieldItemProps('firstname')}
                        isInline={true}
                        label="First name"
                    >
                        <InputGroup>
                            <Input
                                {...props.form.form.register('firstname')}
                                placeholder="Enter first name"
                            />
                        </InputGroup>
                    </FieldItem>
                    <FieldItem
                        {...props.form.getFieldItemProps('lastname')}
                        isInline={true}
                        label="Last name"
                    >
                        <InputGroup>
                            <Input
                                {...props.form.form.register('lastname')}
                                placeholder="Enter last name"
                            />
                        </InputGroup>
                    </FieldItem>
                    {/* <FieldItem {...props.form.getFieldItemProps('role')} label="Role">
                        <Select
                            {...props.form.form.register('role')}
                            placeholder="Select role"
                        >
                            {props.input.select.options.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </FieldItem> */}
                </FieldList>
            </Layout>
        );
    };
}
