import React, { Suspense } from 'react';
import { LazySuspenseProps } from './lazySuspenseProps';

export const LazySuspense: React.FC<
    { children?: React.ReactNode | undefined } & LazySuspenseProps
> = ({ initialLoaded, ...props }) =>
    initialLoaded ? (
        <Suspense {...props}>{props.children}</Suspense>
    ) : (
        <>{props.children}</>
    );
