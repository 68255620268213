import { observer } from 'mobx-react';
import React from 'react';
import { useCompetitiveSetController } from './competitiveController';
import { CompetitiveSetContainerProps } from './competitiveProps';

export const CompetitiveSetContainer: React.FC<
    { children?: React.ReactNode | undefined } & CompetitiveSetContainerProps
> = observer(({ as: Component, ...props }) => {
    const viewProps = useCompetitiveSetController(props);
    return <Component {...viewProps}>{props.children}</Component>;
});
