import { chain } from 'lodash';
import { useMemo } from 'react';
import { ImportListController } from './importListtInterface';
import { ImportListViewProps } from './importListProps';
import { buildImportItemProps, ImportItemViewProps } from '../item';
import { ImportListViewConfig } from './importListConfig';

export function createImportListController(
    config: ImportListViewConfig
): ImportListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props): ImportListViewProps {
            const itemPropsByDataset = useMemo(
                () =>
                    chain(props.items)
                        .reduce(
                            (acc, item) => ({
                                ...acc,
                                [item.import.id]: buildImportItemProps(formatter, item),
                            }),
                            {} as Record<string, ImportItemViewProps>
                        )
                        .value(),
                [props.items]
            );

            const items = useMemo(
                () =>
                    chain(props.items)
                        .orderBy((item) => [Number(item.import.createdAt)], ['desc'])
                        .value(),
                [props.items]
            );

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsByDataset[item.import.id];
                    return itemProps;
                },
            };
        },
    };
}
