import { chain } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { CompanyItemAggregate, CompanyListViewProps } from '../../../../view/companies';
import { PeerControllerConfig } from '../../base';
import { PeerCompanyItemViewProps } from '../item';
import { CompanyListBoxMatchController } from './peerCompanyMatchInterface';
import {
    CompanyListboxMatchControllerProps,
    CompanyListboxMatchViewProps,
} from './peerCompanyMatchProps';

export function createCompanyListBoxMatchController(
    config: PeerControllerConfig
): CompanyListBoxMatchController {
    const {
        controller: {
            // common: {
            //     filter: { useProps: useFilterProps },
            // },
            company: { useProps: useCompanyListProps },
        },
        provider: { createSearchController },
    } = config;

    const DEFAULT_MAX_ITEMS_SHOWN = 100;

    const { useProps: useSearchProps } = createSearchController({
        id: 'peers_group_company',
    });

    function buildItemProps(
        props: Pick<CompanyListboxMatchControllerProps, 'onAdd' | 'selected'>,
        list: Pick<CompanyListViewProps, 'getItemProps'>,
        item: CompanyItemAggregate
    ): PeerCompanyItemViewProps {
        const itemProps = list.getItemProps(item);
        return {
            id: itemProps.id,
            company: itemProps,
            select: {
                id: itemProps.id,
                value: itemProps.id,
                isSelected: props.selected.has(item.company.id),
                button: {
                    onClick() {
                        props.onAdd(item);
                    },
                },
            },
        };
    }

    return {
        useProps(context, item, props): CompanyListboxMatchViewProps {
            const { limit = DEFAULT_MAX_ITEMS_SHOWN } = props;
            const company = useCompanyListProps(context, item.list, props.company);
            const search = useSearchProps(item.search, props.search);
            // const filter = useFilterProps(item.filter, props.filter);

            const filtered = useMemo(() => {
                let acc = chain(company.items).filter(
                    // hide selected items from list
                    (item) => !props.selected.has(item.company.id)
                );
                if (limit !== null) {
                    acc = acc.take(limit);
                }
                return acc.value();
            }, [company.items, props.selected, limit]);

            const matchCountLabel =
                item.paging.total >= item.paging.limit
                    ? `${company.items.length}+`
                    : `${company.items.length}`;

            const itemPropsById = useMemo(
                () =>
                    company.items.reduce(
                        (acc, item) => {
                            const itemProps = buildItemProps(
                                {
                                    onAdd: props.onAdd,
                                    selected: props.selected,
                                },
                                company,
                                item
                            );
                            return { ...acc, [itemProps.id]: itemProps };
                        },
                        {} as Record<string, PeerCompanyItemViewProps | undefined>
                    ),
                [company.items, props.selected]
            );

            return {
                // filter,
                search,
                collection: {
                    label:
                        company.items.length > 0
                            ? `${matchCountLabel} ${pluralize('company', company.items.length)} matched`
                            : `No companies matched`,
                },
                items: filtered,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.company.id];
                    assert(itemProps, 'no item props');
                    return itemProps;
                },
            };
        },
    };
}
