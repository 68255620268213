import React, { useEffect } from 'react';
import { AnchorProps, ApplicationLinkProps, ApplicationUI } from '../../../ui';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { PlatformHelpController } from '../../../view';
import { AnyHelpEvent } from './trackingHelpEvent';

export function createHelpTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHelpEvent>();
            return tracker;
        }

        function enhanceHelp(controller: PlatformHelpController): PlatformHelpController {
            return {
                useProps(...args) {
                    const [controllerProps] = args;
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        button: {
                            ...viewProps.button,
                            onClick(...args) {
                                const result = viewProps.button.onClick?.(...args);
                                tracker.track('help_link_clicked', {
                                    link_id: controllerProps.id,
                                });
                                return result;
                            },
                        },
                    };
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                platform: {
                    ...init.controller.platform,
                    createHelp(...args) {
                        const controller = init.controller.platform.createHelp(...args);
                        return enhanceHelp(controller);
                    },
                },
            },
        });

        return instance;
    };
}
