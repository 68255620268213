import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    StackDivider,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
    AiOutlineDelete,
    AiOutlineGroup,
    AiOutlineInfoCircle,
    AiOutlineMenu,
    AiOutlineMore,
    AiOutlinePlus,
    AiOutlinePlusCircle,
    AiOutlineUser,
    AiOutlineUsergroupAdd,
    AiOutlineWarning,
} from 'react-icons/ai';
import { FaRegEnvelope } from 'react-icons/fa';
import { HiDotsVertical, HiOutlineCube } from 'react-icons/hi';
import { Icons } from '../../../../../config';
import {
    GroupInviteItemViewProps,
    GroupMemberItemViewProps,
} from '../../../../view/groups';
import { GRAY } from '../../../../config';
import { GroupItemViewProps } from '../../../../view';
import {
    AttributeItem,
    AttributeList,
    EditOverlay,
    EditOverlayContainerProps,
    EditOverlayViewProps,
} from '../../../common';
import {
    InvitationItemViewProps,
    PeerGroupDetailViewProps,
} from './peerGroupDetailProps';
import { PeerViewConfig } from '../../base';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MdMoreHoriz, MdOutlineMoreHoriz } from 'react-icons/md';
import {
    PeerGroupDetailAggregate,
    PeerGroupDetailEditFormValues,
} from './peerGroupDetailModel';
import { GoOrganization } from 'react-icons/go';
import { createPeerCompanyPopoverView } from '../../company';
import { createPeerCompanyCardView } from '../../company/card';
import { PeerMemberItemViewProps } from '../../member';
import { PeerDatasetEligibilityItemViewProps } from '../../../../view/peer';

export function createPeerGroupDetailView(
    config: PeerViewConfig & {
        edit: EditOverlay<PeerGroupDetailAggregate, PeerGroupDetailEditFormValues>;
    }
): React.FC<{ children?: React.ReactNode | undefined } & PeerGroupDetailViewProps> {
    const {
        UI: {
            Application: { Link, Anchor, Tooltip },
        },
        edit: { View: EditView },
    } = config;

    const CompanyPopover = createPeerCompanyPopoverView();
    const CompanyCard = createPeerCompanyCardView(config);

    const EligibilityItem: React.FC<
        { children?: React.ReactNode | undefined } & PeerDatasetEligibilityItemViewProps
    > = (props) => {
        return (
            <HStack w="full" p={6} justify="space-between" spacing={12}>
                <HStack spacing={4}>
                    <Box p={2.5} flexShrink={0} bg="whiteAlpha.300" borderRadius="sm">
                        <Image {...props.plugin.image} borderRadius="sm" w={5} h={5} />
                    </Box>
                    <VStack align="start" spacing={0}>
                        <Link {...props.plugin.link}>
                            <Text
                                fontWeight="medium"
                                cursor="pointer"
                                _hover={{ textDecor: 'underline' }}
                            >
                                {props.plugin.label}
                            </Text>
                        </Link>
                    </VStack>
                </HStack>
                <HStack>
                    <Tooltip
                        {...props.status.getTooltipProps()}
                        placement="top"
                        hasArrow={true}
                        bg="gray.50"
                        color="gray.900"
                        px={3}
                        py={2}
                    >
                        <Tag {...props.status.getTagProps()} size="lg" userSelect="none">
                            {props.status.label}
                        </Tag>
                    </Tooltip>
                </HStack>
            </HStack>
        );
    };

    const MemberItem: React.FC<
        { children?: React.ReactNode | undefined } & PeerMemberItemViewProps
    > = (props) => {
        return (
            <HStack w="full" p={6} justify="space-between" spacing={12}>
                <HStack spacing={4}>
                    {props.company.iconUrl && (
                        <Box flexShrink={0}>
                            <Image
                                borderRadius="sm"
                                w={10}
                                h={10}
                                src={props.company.iconUrl}
                            />
                        </Box>
                    )}
                    {!props.company.iconUrl && (
                        <Box p={2.5} bg="whiteAlpha.300" borderRadius="sm" flexShrink={0}>
                            <Icon w={5} h={5} as={GoOrganization} />
                        </Box>
                    )}
                    <CompanyPopover
                        placement="top-start"
                        trigger={
                            <VStack align="start" spacing={0}>
                                <Text
                                    fontWeight="medium"
                                    cursor="default"
                                    noOfLines={1}
                                    wordBreak="break-word"
                                    _hover={{ textDecor: 'underline' }}
                                >
                                    {props.company.name}
                                </Text>
                                <Text color="whiteAlpha.700">{props.company.domain}</Text>
                            </VStack>
                        }
                    >
                        <CompanyCard {...props.company} />
                    </CompanyPopover>
                </HStack>
                {props.status && (
                    <HStack>
                        <Tooltip
                            {...props.status.getTooltipProps()}
                            p={4}
                            placement="top"
                            hasArrow={true}
                            bg="#1D1C21"
                            label={
                                <VStack
                                    align="start"
                                    w="full"
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.200"
                                        />
                                    }
                                >
                                    <HStack w="full" spacing={3}>
                                        <Box
                                            w={2}
                                            h={2}
                                            borderRadius="full"
                                            bg={`${props.status.colorScheme}.300`}
                                        />
                                        <Text fontWeight="semibold">
                                            {props.status.label}
                                        </Text>
                                    </HStack>
                                    <Text color="whiteAlpha.700">
                                        {props.company.domain}
                                    </Text>
                                </VStack>
                            }
                        >
                            <Tag
                                {...props.status.getTagProps()}
                                size="lg"
                                cursor="default"
                            />
                        </Tooltip>
                    </HStack>
                )}
            </HStack>
        );
    };

    return (props) => {
        return (
            <>
                <Grid templateColumns="1fr" rowGap={12} w="full">
                    <GridItem>
                        <VStack w="full" align="start" spacing={6}>
                            <HStack
                                w="full"
                                justify="space-between"
                                spacing={12}
                                align="start"
                            >
                                <VStack w="full" align="start" spacing={4}>
                                    <HStack w="full" align="start">
                                        <VStack w="full" align="start" spacing={3}>
                                            <HStack
                                                fontWeight="semibold"
                                                spacing={2}
                                                divider={
                                                    <Icon
                                                        boxSize="1.20rem"
                                                        color="whiteAlpha.600"
                                                        as={ChevronRightIcon}
                                                        border="none"
                                                    />
                                                }
                                            >
                                                <Link to="../..">
                                                    <Text
                                                        color="blue.300"
                                                        _hover={{ color: 'blue.400' }}
                                                    >
                                                        Peers
                                                    </Text>
                                                </Link>
                                                <Link to="..">
                                                    <Text
                                                        color="blue.300"
                                                        _hover={{ color: 'blue.400' }}
                                                    >
                                                        Groups
                                                    </Text>
                                                </Link>
                                            </HStack>
                                            <HStack
                                                w="full"
                                                justify="space-between"
                                                align="start"
                                            >
                                                <HStack spacing={4} w="full">
                                                    <Box
                                                        p={3}
                                                        bg="whiteAlpha.300"
                                                        borderRadius="sm"
                                                    >
                                                        <Icon
                                                            w={6}
                                                            h={6}
                                                            as={
                                                                Icons.Peers
                                                                    .CompetitiveSets
                                                            }
                                                        />
                                                    </Box>
                                                    <VStack align="start" spacing={0.5}>
                                                        <HStack spacing={3}>
                                                            <Heading fontSize="xl">
                                                                {props.group.label}
                                                            </Heading>
                                                            {props.group.status && (
                                                                <Tooltip
                                                                    p={3}
                                                                    placement="top"
                                                                    hasArrow={true}
                                                                    bg="#1D1C21"
                                                                    {...props.group.status.getTooltipProps()}
                                                                >
                                                                    <Tag
                                                                        cursor="default"
                                                                        {...props.group.status.getTagProps()}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </HStack>
                                                        <HStack
                                                            cursor="default"
                                                            fontSize="md"
                                                            color="whiteAlpha.700"
                                                            fontWeight="medium"
                                                            divider={
                                                                <Box border="none">
                                                                    &#x2022;
                                                                </Box>
                                                            }
                                                        >
                                                            <Text>
                                                                {
                                                                    props.member.count
                                                                        .member.label
                                                                }
                                                            </Text>
                                                            {props.member.count
                                                                .invitation && (
                                                                <Text>
                                                                    {
                                                                        props.member.count
                                                                            .invitation
                                                                            .label
                                                                    }
                                                                </Text>
                                                            )}
                                                        </HStack>
                                                    </VStack>
                                                </HStack>
                                                <HStack spacing={2}>
                                                    <Button
                                                        size="sm"
                                                        bg="whiteAlpha.300"
                                                        _focus={{ outline: 'none' }}
                                                        _hover={{ bg: 'whiteAlpha.400' }}
                                                        {...props.action.edit.button}
                                                    >
                                                        {props.action.edit.label}
                                                    </Button>
                                                    <Menu placement="bottom-end">
                                                        <MenuButton
                                                            as={IconButton}
                                                            size="sm"
                                                            bg="whiteAlpha.300"
                                                            icon={
                                                                <Icon as={MdMoreHoriz} />
                                                            }
                                                            _focus={{ outline: 'none' }}
                                                            _hover={{
                                                                bg: 'whiteAlpha.400',
                                                            }}
                                                        />
                                                        <MenuList bg={GRAY[600]}>
                                                            <MenuItem
                                                                color={
                                                                    props.action.delete
                                                                        .colorScheme
                                                                        ? `${props.action.delete.colorScheme}.400`
                                                                        : undefined
                                                                }
                                                                fontWeight="medium"
                                                                {...props.action.delete
                                                                    .button}
                                                            >
                                                                {
                                                                    props.action.delete
                                                                        .label
                                                                }
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </HStack>
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <VStack
                            align="start"
                            w="full"
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.300"
                                    borderWidth={1}
                                />
                            }
                        >
                            <HStack w="full">
                                <Heading fontSize="xl">Details</Heading>
                            </HStack>
                            <AttributeList>
                                <AttributeItem label="Creator">
                                    <HStack>
                                        <Icon as={AiOutlineUser} />
                                        <Text>{props.group.creator.label}</Text>
                                    </HStack>
                                </AttributeItem>
                                <AttributeItem label="Created">
                                    {props.group.created.relative.label}
                                </AttributeItem>
                            </AttributeList>
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <VStack align="start" w="full" spacing={4}>
                            <HStack w="full" justify="space-between">
                                <HStack w="full" spacing={3}>
                                    <Heading fontSize="xl">Datasets</Heading>
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        bg="gray.50"
                                        color="gray.900"
                                        px={3}
                                        py={2}
                                        label="Companies have different integrations, and for a given dataset, enough companies in the group must have an active integration"
                                        shouldWrapChildren={true}
                                    >
                                        <Icon fontSize="xl" as={AiOutlineInfoCircle} />
                                    </Tooltip>
                                </HStack>
                            </HStack>
                            <VStack
                                w="full"
                                align="start"
                                fontWeight="medium"
                                spacing={0}
                                borderWidth={2}
                                borderColor="whiteAlpha.300"
                                borderStyle="solid"
                                borderRadius="lg"
                            >
                                <VStack
                                    h="full"
                                    w="full"
                                    align="start"
                                    spacing={0}
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.300"
                                        />
                                    }
                                >
                                    {props.eligibility.items.map((item) => (
                                        <EligibilityItem
                                            key={item.id}
                                            {...props.eligibility.getItemProps(item)}
                                        />
                                    ))}
                                </VStack>
                            </VStack>
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <VStack align="start" w="full" spacing={4}>
                            <HStack w="full" justify="space-between">
                                <HStack w="full">
                                    <Heading fontSize="xl">Companies</Heading>
                                </HStack>
                                <Link {...props.link.members.edit}>
                                    <Button
                                        flexShrink={0}
                                        variant="outline"
                                        size="sm"
                                        bg="whiteAlpha.300"
                                        _hover={{ bg: 'whiteAlpha.400' }}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Edit companies
                                    </Button>
                                </Link>
                            </HStack>
                            {props.member.status && (
                                <Alert {...props.member.status.getAlertProps()} w="full">
                                    <AlertTitle w="full">
                                        <HStack w="full" justify="space-between">
                                            <HStack spacing={3}>
                                                <Text>{props.member.status.label}</Text>
                                                <Tooltip
                                                    {...props.member.status.getTooltipProps()}
                                                    p={4}
                                                    placement="top"
                                                    hasArrow={true}
                                                    bg="#1D1C21"
                                                >
                                                    <Box as="span">
                                                        <Icon
                                                            fontSize="lg"
                                                            as={AiOutlineInfoCircle}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            </HStack>
                                            <Anchor
                                                {...props.anchor.competitiveSetDocs}
                                                _hover={{ textDecor: 'underline' }}
                                                cursor="pointer"
                                            >
                                                Learn more
                                            </Anchor>
                                        </HStack>
                                    </AlertTitle>
                                </Alert>
                            )}
                            {props.member.items.length === 0 && (
                                <HStack
                                    w="full"
                                    p={8}
                                    borderWidth={3}
                                    borderStyle="dashed"
                                    borderColor="whiteAlpha.300"
                                    borderRadius="md"
                                    justify="center"
                                >
                                    <Heading
                                        fontSize="md"
                                        fontWeight="medium"
                                        color="whiteAlpha.600"
                                    >
                                        No competitive set members
                                    </Heading>
                                </HStack>
                            )}
                            {props.member.items.length > 0 && (
                                <VStack
                                    w="full"
                                    align="start"
                                    fontWeight="medium"
                                    spacing={0}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                    borderRadius="lg"
                                >
                                    <HStack
                                        px={6}
                                        py={4}
                                        w="full"
                                        position="sticky"
                                        top={0}
                                        bg="black"
                                        borderBottomWidth={2}
                                        borderStyle="solid"
                                        borderColor="whiteAlpha.300"
                                        zIndex={5}
                                        borderTopRadius="lg"
                                    >
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            color="whiteAlpha.600"
                                        >
                                            <Text fontWeight="medium" whiteSpace="nowrap">
                                                {props.member.count.label}
                                            </Text>
                                        </HStack>
                                    </HStack>

                                    <VStack
                                        h="full"
                                        w="full"
                                        spacing={0}
                                        divider={
                                            <StackDivider
                                                borderWidth={1}
                                                borderColor="whiteAlpha.300"
                                            />
                                        }
                                    >
                                        {props.member.items.map((item) => {
                                            const itemProps =
                                                props.member.getItemProps(item);
                                            return (
                                                <MemberItem
                                                    key={itemProps.id}
                                                    {...itemProps}
                                                />
                                            );
                                        })}
                                    </VStack>
                                </VStack>
                            )}
                        </VStack>
                    </GridItem>
                </Grid>
                <EditView {...props.edit}>
                    <FormControl>
                        <FormLabel>Competitive set name</FormLabel>
                        <InputGroup>
                            <Input {...props.edit.form.form.register('name')} />
                        </InputGroup>
                    </FormControl>
                </EditView>
                <Modal {...props.confirm.modal} isCentered={true}>
                    <ModalContent>
                        <ModalHeader p={4}>
                            <Heading fontSize="md">
                                Confirm delete competitive set
                            </Heading>
                        </ModalHeader>
                        <ModalBody p={4}>
                            <Text>
                                Are you sure you wan to delete this competitive set?
                            </Text>
                        </ModalBody>
                        <ModalFooter p={4}>
                            <HStack w="full" justify="end">
                                <Button
                                    size="sm"
                                    _focus={{ outline: 'none' }}
                                    {...props.confirm.cancel.button}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="sm"
                                    colorScheme="red"
                                    _focus={{ outline: 'none' }}
                                    {...props.confirm.submit.button}
                                >
                                    Delete competitive set
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}
