import { useLocation, useNavigate } from 'react-router';
import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { OrganizationMappingController } from '../../../../../view/assets';
import { AnySettingsTrackingEvent } from '../../trackingSettingEvent';

export function createTrackingSettingsMappingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnySettingsTrackingEvent>();
            return tracker;
        }
        function enhanceSettingController(
            controller: OrganizationMappingController
        ): OrganizationMappingController {
            return {
                ...controller,
                useProps(...args) {
                    const [context] = args;
                    const location = useLocation();
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    if (!location.pathname.includes('/settings')) {
                        // temp hack to avoid sending this event during onboarding where
                        // where the controller is re-used.
                        return viewProps;
                    }
                    return {
                        ...viewProps,
                        getFormStateProps() {
                            const formProps = viewProps.getFormStateProps();
                            return {
                                ...formProps,
                                async onSubmit() {
                                    const result = await formProps.onSubmit();
                                    tracker.track('settings_mapping_submitted', {});
                                    return result;
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createMapping(...args) {
                        const controller = config.controller.assets.createMapping(
                            ...args
                        );
                        return enhanceSettingController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
