import { compose } from 'lodash/fp';
import { FeatureMiddleware } from '../featureInterface';
import { createFeatureDateRangeDashboardMiddleware } from './dashboard';
import { createFeatureDateRangeHomeMiddleware } from './home';

export function createFeatureDateRangeMiddleware(): FeatureMiddleware[] {
    return [
        createFeatureDateRangeHomeMiddleware(),
        createFeatureDateRangeDashboardMiddleware(),
    ];
}
