import { first, last } from 'lodash';
import { useMemo } from 'react';
import { RangeInputDeps } from './inputRangeInterface';
import { RangeInputControllerPropsV2, RangeInputViewPropsV2 } from './inputRangeProps';

export function buildInputRangeProps(
    deps: RangeInputDeps,
    props: RangeInputControllerPropsV2
): RangeInputViewPropsV2 {
    const initialValue = (
        props.scale.kind === 'linear'
            ? [props.scale.min, props.scale.max]
            : [first(props.scale.steps), last(props.scale.steps)]
    ) as [number, number];
    return {
        value: props.value ?? initialValue,
        scale: props.scale,
        onChange(value) {
            props.onChange(value);
        },
        onSlide(value) {
            deps.state.onChange(value);
        },
        getLabel: props.getLabel,
    };
}
