import {
    AnyResolvedType,
    AnyType,
    EnumMember,
    EnumMemberWithChildren,
    isTreeType,
} from '../type';
import { AnyCondition } from './conditionModel';

/**
 * Restricts condition values based on allowed values defined on the respective type
 * @param condition
 * @param type
 * @returns
 */
export function applyTypeToCondition(
    condition: AnyCondition,
    type: AnyResolvedType
): AnyCondition | null {
    if (condition.operator === 'in' && isTreeType(type)) {
        const parentByChild: Record<string, EnumMemberWithChildren | undefined> = {};

        const allowedValues = new Set(
            type.members.flatMap((parent) => [
                parent.value,
                ...parent.children.flatMap((child) => {
                    if (child.isDisabled) {
                        parentByChild[child.value] = parent;
                        return [];
                    }
                    return child.value;
                }),
            ])
        );
        const matchedValues = new Set(
            condition.value.flatMap((item) => {
                if (typeof item !== 'string') {
                    throw new Error(
                        `unexpected condition value '${JSON.stringify(
                            item
                        )}', expected string`
                    );
                }
                const isAllowed = allowedValues.has(item as string);
                if (isAllowed) {
                    return [item];
                }
                const parent = parentByChild[item];
                if (!parent) {
                    // console.log('DEBUG no parent found for item', item);
                    return [];
                }
                // console.log('DEBUG upgrading parent filter', parent);
                return [parent.value];
            })
        );

        if (matchedValues.size === 0) {
            return null;
        }
        return { ...condition, value: [...matchedValues.values()] };
    }
    return condition;
}
