import React from 'react';
import { PeerContextProvider } from '../base';
import { PeerLayoutController } from './peerLayoutInterface';
import { PeerLayoutContainerProps, PeerLayoutViewProps } from './peerLayoutProps';

export function createPeerLayout(
    contexts: PeerContextProvider,
    controller: PeerLayoutController,
    View: React.FC<{ children?: React.ReactNode | undefined } & PeerLayoutViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & PeerLayoutContainerProps> {
    return (props) => {
        const viewContext = contexts.useContext();
        const viewProps = controller.useProps(viewContext, { layout: props });
        return <View {...viewProps}>{props.children}</View>;
    };
}
