import { z } from 'zod';
import { TypeSchema } from '../../dashboards/type';

const MetricDefinitionKindSchema = z.enum([
    'input',
    'outcome',
    'diagnostic',
    'performance',
]);

export const MetricDefinitionSchema = z.object({
    id: z.string(),
    plugin: z.string(),
    view: z.string(),
    key: z.string(),
    name: z.string(),
    kind: MetricDefinitionKindSchema,
    description: z.nullable(z.string()),
    abbreviation: z.nullable(z.string()),
    type: TypeSchema,
    inverse: z.boolean(),
    formula: z.string(),
    created_at: z.date({ coerce: true }),
    updated_at: z.date({ coerce: true }),
});

export const MetricDefinitionListResponseSchema = z.object({
    data: z.array(MetricDefinitionSchema),
});
