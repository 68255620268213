import { z } from 'zod';
import { ChargeSchema } from '../charge';
// Item

export const BillingSessionDtoSchema = z.object({
    url: z.string(),
});

// // Create

// export const BillingPaymentCreateDtoSchema = z.object({
//     plan: z.string(),
// });

// export const BillingPaymentCreateOptionsDtoSchema = z.object({
//     wait: z.number(),
//     idempotency_key: z.string(),
// });

export const SubscriptionSessionCompleteSchemaDto = z.object({
    amount: z.number(),
    trial: z.boolean(),
    plan: z.string(),
    customer: z.string()

})
export const BillinSessionCompleteSchemaDto = z.union([
    ChargeSchema,
    SubscriptionSessionCompleteSchemaDto,
])