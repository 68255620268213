import { chain } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { IntegrationControllerConfig } from '../integrationConfig';
import { COLORS } from './integrationItemConstant';
import { buildIntegrationStatusProps } from './integrationItemFactory';
import { IntegrationItemController } from './integrationItemInterface';

export function createIntegrationItemController(
    config: IntegrationControllerConfig
): IntegrationItemController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props) {
            const status = useMemo(
                () => buildIntegrationStatusProps(props.item),
                [props.item]
            );

            const dateTagProps = useMemo(
                () => ({
                    label: formatter.format(
                        'datetime',
                        props.item.integration.createdAt,
                        { relative: true }
                    ),
                }),
                [props.item]
            );

            const dateTooltipProps = useMemo(() => {
                const date = formatter.format(
                    'datetime',
                    props.item.integration.createdAt,
                    { notation: 'long' }
                );
                return {
                    label: `Integration was connected on ${date}`,
                    isDisabled: false,
                };
            }, [props.item]);

            const mappingProps = useMemo(() => {
                const assetMappingCount = chain(props.item.mappings)
                    .uniqBy((item) => item.asset.id)
                    .value().length;
                if (assetMappingCount === 0) {
                    return null;
                }
                const pluralized = pluralize('company', assetMappingCount);
                return { label: `${assetMappingCount} ${pluralized}` };
            }, [props.item.mappings]);

            const tagProps = useMemo(() => {
                let acc: Array<{ label: string; to: string | null }> = [];

                if (props.item.integration.key) {
                    acc = [...acc, { label: props.item.integration.key, to: null }];
                }

                if (mappingProps) {
                    acc = [
                        ...acc,
                        { label: mappingProps.label, to: '/u/settings/companies' },
                    ];
                }

                return acc;
            }, [props.item, mappingProps]);

            return {
                id: props.item.integration.id,
                title: props.item.definition.name,
                secondary: props.item.integration.key ?? null,
                iconUrl: props.item.definition.iconUrl,
                status: status,
                getStatusTooltipProps() {
                    return status.tooltip;
                },
                getStatusTagProps() {
                    return {
                        colorScheme: status.type ? COLORS[status.type] : 'gray',
                        label: status.label,
                        isLoading: status.isLoading,
                    };
                },
                getTagProps() {
                    return tagProps;
                },
                getDateTagProps() {
                    return dateTagProps;
                },
                getDateTooltipProps() {
                    return dateTooltipProps;
                },
                getMappingTagProps() {
                    return mappingProps;
                },
            };
        },
    };
}
