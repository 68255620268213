import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useOrganizationContextV2, useSystemContextV2 } from '../../../../context';
import { isLoadedCollection, Tracker } from '../../../../base';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { HomeOpportunityViewProps } from '../../../home';
import { AnyHomeSectionEvent } from './homeSectionTrackingEvent';
import { HomeRouteController } from 'src/v2/route';

export function createHomeSectionTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeSectionEvent>();
            return tracker;
        }

        function enhanceSectionProps<TProps extends { id: string; onHide: Function }>(
            tracker: Tracker<AnyHomeSectionEvent>,
            props: TProps
        ): TProps {
            return {
                ...props,
                onHide() {
                    const result = props.onHide();
                    tracker.track('home_section_hidden', {
                        section_id: props.id,
                    });
                    return result;
                },
            };
        }

        function enhanceController(controller: HomeRouteController): HomeRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    return {
                        ...viewProps,
                        getExpertCallProps(...args) {
                            const sectionProps = viewProps.getExpertCallProps(...args);
                            return enhanceSectionProps(tracker, sectionProps);
                        },
                        getGuideProps(...args) {
                            const sectionProps = viewProps.getGuideProps(...args);
                            return enhanceSectionProps(tracker, sectionProps);
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            root: {
                                ...routeConfig.providers.root,
                                createController(...args) {
                                    const controller =
                                        routeConfig.providers.root.createController(
                                            ...args
                                        );
                                    return enhanceController(controller);
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
