import React from 'react';
import { Route } from 'react-router';
import { LegalContainerConfig, LegalControllerConfig, LegalViewConfig } from './base';
import {
    createLegalLayoutContainer,
    createLegalLayoutController,
    createLegalLayoutView,
    LEGAL_LAYOUT_RETURN_PATH_KEY,
} from './layout';
import { LegalRouterConfig, LegalRouterInit } from './legalRouterConfig';
import { LegalRouter, LegalRouterEnhancer } from './legalRouterInterface';
import { LegalNavigation } from './navigate';
import {
    createTermsOfServiceContainer,
    createTermsOfServiceController,
    createTermsOfServiceView,
} from './page/terms-of-service';

export function configureLegalRouter(
    config: LegalRouterInit,
    enhancer?: LegalRouterEnhancer
) {
    return create(config, enhancer);
}

function create(config: LegalRouterConfig, enhancer?: LegalRouterEnhancer): LegalRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const containerConfig: LegalContainerConfig = {
        kernel: config.kernel,
    };

    const controllerConfig: LegalControllerConfig = {
        kernel: config.kernel,
    };

    const viewConfig: LegalViewConfig = {
        UI: config.UI,
        Layout: createLegalLayoutContainer(
            containerConfig,
            createLegalLayoutController(),
            createLegalLayoutView({ UI: config.UI })
        ),
    };

    const TermsOfService = createTermsOfServiceContainer(
        createTermsOfServiceController(controllerConfig),
        createTermsOfServiceView(viewConfig)
    );

    const navigater: LegalNavigation = {
        termsOfService(props) {
            const params = new URLSearchParams();
            params.set(
                LEGAL_LAYOUT_RETURN_PATH_KEY,
                `${props.location.pathname}${props.location.search}`
            );
            const query = params.toString();
            return {
                to: `/${config.mount}/terms-of-service?${query}`,
            };
        },
    };

    return {
        navigater,
        element: (
            <Route path={config.mount}>
                <Route path="terms-of-service" element={<TermsOfService />} />
            </Route>
        ),
    };
}
