import { periodToAbsoluteDateString } from '../../../../domain';
import { isLoadedCollection } from '../../../base';
import { OpportunityCollectionViewProps } from '../../../view';
import { HomeOpportunityConfig } from './homeOpportunityConfig';
import { HomeOpportunityViewProps } from './homeOpportunityProps';
import { HomeOpportunityController } from './homeOpportunityInterface';

const TRUNCATE = 3;

export function createHomeOpportunityController(
    init: HomeOpportunityConfig
): HomeOpportunityController {
    const { controller, hooks } = init;
    return {
        useProps(context, queries, props): HomeOpportunityViewProps {
            const formatter = hooks.useFormatter();
            const viewProps = controller.oppportunity.useProps(context, queries, {});

            const items = viewProps.collection.data?.items ?? [];
            const first = items.slice(0, TRUNCATE);
            const truncated = items.slice(TRUNCATE);

            return {
                truncateText:
                    truncated.length > 0 ? `and ${truncated.length} more` : null,
                emptyDescription: periodToAbsoluteDateString({ formatter }, props.period),
                getCollectionProps(): OpportunityCollectionViewProps {
                    return {
                        ...viewProps,
                        collection: isLoadedCollection(viewProps.collection)
                            ? {
                                  ...viewProps.collection,
                                  data: {
                                      ...viewProps.collection.data,
                                      items: first,
                                  },
                              }
                            : viewProps.collection,
                    };
                },
                // },
            };
        },
    };
}
