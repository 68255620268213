import { createFeatureDateRangeMiddleware } from './date-range';
import { createFeatureDestinationMiddleware } from './destination';
import { FeatureMiddlewareConfig } from './featureConfig';
import { FeatureMiddleware } from './featureInterface';
import { createFeatureMultiAssetMiddleware } from './multi-asset';
import { createFeatureQueryMiddleware } from './query';
import { createFeatureReportMiddleware } from './report';
import { createFeatureTraitMiddleware } from './trait';
import { createFeatureGroupMiddleware } from './groups';
import { createFeaturePluginMiddleware } from './plugin';

export function createFeatureMiddleware(
    config: FeatureMiddlewareConfig
): FeatureMiddleware[] {
    return [
        ...createFeatureQueryMiddleware(),
        ...createFeatureDateRangeMiddleware(),
        ...createFeatureTraitMiddleware(),
        ...createFeatureDestinationMiddleware(),
        createFeatureReportMiddleware(config.report),
        createFeaturePluginMiddleware(),
        createFeatureMultiAssetMiddleware(),
        createFeatureGroupMiddleware(),
    ];
}
