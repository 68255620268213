import { TextProps } from '@chakra-ui/react';
import {
    applyToBucket,
    Bucket,
    normalize,
    normalizeBuckets,
    Scale,
} from '../../visualization';
import { MetricDefinition } from '../metric';
import {
    BenchmarkStatus,
    MetricCalculation,
    MetricCalculationSegment,
    MovementDirection,
} from './calculationModel';

export function getMovementDirection(segment: MetricCalculationSegment) {
    let direction: MovementDirection | null = null;
    if (segment.value.change && segment.value.change > 0) {
        return 'up';
    } else if (segment.value.change && segment.value.change < 0) {
        return 'down';
    }
    return direction;
}

export function getMovementColorScheme(
    metric: MetricDefinition,
    segment: MetricCalculationSegment
): TextProps['colorScheme'] {
    if (!segment.value.change) {
        return undefined;
    }
    if (segment.value.change > 0 && !metric.inverse) {
        return 'green';
    }
    if (segment.value.change > 0 && metric.inverse) {
        return 'red';
    }
    if (segment.value.change < 0 && !metric.inverse) {
        return 'red';
    }
    if (segment.value.change < 0 && metric.inverse) {
        return 'green';
    }
    return undefined;
}

export function getMetricBuckets(
    metric: MetricDefinition
): Array<Bucket & { status: BenchmarkStatus }> {
    return metric.inverse
        ? [
              {
                  status: 'danger',
                  lower: 62.5,
                  upper: 75,
              },
              {
                  status: 'warn',
                  lower: 37.5,
                  upper: 62.5,
              },
              {
                  status: 'success',
                  lower: 25,
                  upper: 37.5,
              },
          ]
        : [
              {
                  status: 'danger',
                  lower: 25,
                  upper: 37.5,
              },
              {
                  status: 'warn',
                  lower: 37.5,
                  upper: 62.5,
              },
              {
                  status: 'success',
                  lower: 62.5,
                  upper: 75,
              },
          ];
}

export function getCalculationStatus(
    buckets: Array<Bucket & { status: BenchmarkStatus }>,
    calculation: MetricCalculation
): BenchmarkStatus | null {
    if (
        !calculation.low.value.base ||
        !calculation.mid.value.base ||
        !calculation.high.value.base
    ) {
        return null;
    }

    const scale: Scale = {
        points: [
            calculation.low.value.base,
            calculation.mid.value.base,
            calculation.high.value.base,
        ],
    };

    if (!calculation.current.value.base) {
        return null;
    }

    const applied = applyToBucket(
        normalizeBuckets(buckets),
        normalize(scale, calculation.current.value.base)
    );

    return applied?.status ?? null;
}

export function getScalarStatus(
    metric: MetricDefinition,
    calculation: MetricCalculation
): BenchmarkStatus | null {
    const buckets = getMetricBuckets(metric);
    return getCalculationStatus(buckets, calculation);
}

export function getScalarColorScheme(
    metric: MetricDefinition,
    calculation: MetricCalculation
): TextProps['colorScheme'] {
    const status = getScalarStatus(metric, calculation);
    if (status === 'success') {
        return 'green';
    }
    if (status === 'danger') {
        return 'red';
    }
    if (status === 'warn') {
        return 'yellow';
    }
    return undefined;
}

export function getScalarStatusColorScheme(
    status: BenchmarkStatus
): TextProps['colorScheme'] {
    if (status === 'success') {
        return 'green';
    }
    if (status === 'danger') {
        return 'red';
    }
    if (status === 'warn') {
        return 'yellow';
    }
    return undefined;
}
