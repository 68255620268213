import { isLoadedCollection } from '../../../base';
import { HomeDestinationConfig } from './homeDestinationConfig';
import { HomeDestinationLoader } from './homeDestinationInterface';

export function createHomeDestinationLoader(
    config: HomeDestinationConfig
): HomeDestinationLoader {
    const { repository } = config;
    return {
        useData(context, props) {
            const destination = repository.destination.useFind(context, {}, {});

            if (!isLoadedCollection(destination)) {
                return {
                    item: {
                        status: 'loading',
                        data: null,
                    },
                };
            }

            return {
                item: {
                    status: 'loaded',
                    data: {
                        limit: 100,
                        total: destination.data.items.length,
                        items: destination.data.items.map((item) => ({
                            definition: item,
                        })),
                    },
                },
            };
        },
    };
}
