import {
    AnyQueryResponse,
    QueryResponse,
    QuerySuccessResponse,
    QueryErrorResponse,
} from './responseModel';

export function isSuccessResponse(
    response: AnyQueryResponse
): response is QuerySuccessResponse {
    return !isErrorResponse(response);
}

export function isErrorResponse(
    response: AnyQueryResponse
): response is QueryErrorResponse {
    return (response as QueryErrorResponse).error instanceof Error;
}
