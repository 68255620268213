import { DatasetDto } from '../../../api';
import { DatasetImportMode } from '../../../domain/datasets';

export function getSurveyImportMode(item: DatasetDto): DatasetImportMode {
    const totalColumns = item.data.columns.length;
    const stringColumnsCount = item.data.columns.filter(
        (column) => column.type === 'string'
    ).length;

    if (stringColumnsCount > totalColumns / 2) {
        // if majority of questions are string-based we confuct it as an interview
        return 'interview';
    }
    return 'multiple_choice';
}

export function getSurveyEstimate(item: DatasetDto) {
    const stringColumnsCount = item.data.columns.filter(
        (column) => column.type === 'string'
    ).length;
    const otherColumnsCount = item.data.columns.filter(
        (column) => column.type !== 'string'
    ).length;
    const minutePerStringQuestion = 0.5;
    const minutePerOtherQuestion = 0.3;

    const stringEstimate = stringColumnsCount * minutePerStringQuestion;
    const otherEstimate = otherColumnsCount * minutePerOtherQuestion;

    const totalEstimate = Math.floor(stringEstimate + otherEstimate);

    return {
        interval: 'minute',
        amount: Math.max(totalEstimate, 2),
    } as const;
}
