import { Period, Preset } from '../../domain';

export const PERIOD_OPTIONS: Preset<Period>[] = [
    {
        key: '7day',
        text: 'Last 7 days',
        value: {
            interval: 'day',
            amount: 7,
        },
    },
    {
        key: '4weeks',
        text: 'Last 4 weeks',
        value: {
            interval: 'week',
            amount: 4,
        },
    },
    {
        key: '3month',
        text: 'Last 3 months',
        value: {
            interval: 'month',
            amount: 3,
        },
    },
    {
        key: '6month',
        text: 'Last 6 months',
        value: {
            interval: 'month',
            amount: 6,
        },
    },
    {
        key: '12month',
        text: 'Last 12 months',
        value: {
            interval: 'month',
            amount: 12,
        },
    },
    {
        key: '3year',
        text: 'Last 3 years',
        value: {
            interval: 'year',
            amount: 3,
        },
    },
];

export const COMPARISON_OPTIONS: Preset<Period>[] = [
    {
        key: 'previous period',
        text: 'Previous period',
        value: {
            amount: 1,
            interval: 'period',
        },
    },
    {
        key: 'previous month',
        text: 'Previous month',
        value: {
            amount: 1,
            interval: 'month',
        },
    },
    {
        key: 'previous year',
        text: 'Previous year',
        value: {
            amount: 1,
            interval: 'year',
        },
    },
];
