import { WorkspaceRouteConfig } from './workspaceRouteConfig';
import { WorkspaceRouteController } from './workspaceRouteInterface';

export function createWorkspaceRouteController(
    config: WorkspaceRouteConfig
): WorkspaceRouteController {
    const {
        config: { redirects },
        deps: {
            hook: { useRedirects },
        },
    } = config;
    return {
        useProps() {
            useRedirects(redirects);
            return {};
        },
    };
}
