import { assertNever } from '../../../util';
import { VisualizableResultStrategy } from '../../../app/visualizations';
import {
    isBarVisualization,
    isComparisonVisualization,
    isDistributionVisualization,
    isLineVisualization,
} from '../../../domain/visualization';
import { buildLineResult } from './line';
import { buildDistributionResult } from './distribution';
import { buildComparisonResult } from './comparison';
import { buildBarResult } from './bar';

export function createVisualizationQueryResponseStrategy(): VisualizableResultStrategy {
    return {
        create(view, configuration, response) {
            if (isLineVisualization(configuration)) {
                return buildLineResult(view, configuration, response);
            }
            if (isBarVisualization(configuration)) {
                return buildBarResult(view, configuration, response);
            }
            if (isDistributionVisualization(configuration)) {
                return buildDistributionResult(view, configuration, response);
            }
            if (isComparisonVisualization(configuration)) {
                return buildComparisonResult(view, configuration, response);
            }
            assertNever(configuration);
        },
    };
}

// {
//     kind: 'bar',
//     description: '',
//     dimensions: [],
//     type: {
//         kind: 'currency',
//         currency: 'usd',
//         constraints: [],
//     },
//     series: [
//         {
//             key: 'me',
//             name: 'Me',
//             data: [
//                 { category: 'DPA', value: 1 },
//                 { category: 'Video', value: 4 },
//                 { category: 'Carousel', value: 2 },
//                 { category: 'Image', value: 3 },
//             ],
//         },
//         {
//             key: 'peer',
//             name: 'High performers',
//             data: [
//                 { category: 'DPA', value: 1.5 },
//                 { category: 'Video', value: 3 },
//                 { category: 'Carousel', value: 3 },
//                 { category: 'Image', value: 2.5 },
//             ],
//         },
//     ],
// };

// return {
//     kind: 'line',
//     description: 'this is a chart',
//     dimensions: [],
//     type: {
//         kind: 'currency',
//         currency: 'usd',
//         constraints: [],
//     },
//     series: [
//         {
//             key: 'me',
//             name: 'My company',
//             data: [
//                 {
//                     date: new Date(2024, 1, 1),
//                     value: 5,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 2),
//                     value: 6.3,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 3),
//                     value: 4.3,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 4),
//                     value: 6.6,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 5),
//                     value: 7.67,
//                     key: {},
//                 },
//             ],
//         },
//         {
//             key: 'median',
//             name: 'Median',
//             data: [
//                 {
//                     date: new Date(2024, 1, 1),
//                     value: 10,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 2),
//                     value: 10,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 3),
//                     value: 7,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 4),
//                     value: 3.6,
//                     key: {},
//                 },
//                 {
//                     date: new Date(2024, 1, 5),
//                     value: 3,
//                     key: {},
//                 },
//             ],
//         },
//     ],
// };
