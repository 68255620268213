import React from 'react';
import { Tooltip, Text, VStack, Box, Tag, useDisclosure } from '@chakra-ui/react';
import { DateFilter, DateFilterProps } from '../../../ui/datefilter/component/DateFilter';
import {
    periodToAbsoluteDateString,
    isRelativePeriod,
    Period,
    ContextFreePeriod,
    periodToString,
} from '../../../domain';
import { ApplicationContext } from '../../../hooks';
import { ConditionPopover, ConditionPopoverProps } from '../../../domain/condition';
import { TimeGranularityType } from '../../../models/Common';

export interface DateFilterFieldsV2Props
    extends Pick<DateFilterProps, 'onChange' | 'getPresets'>,
        Pick<ConditionPopoverProps, 'column'> {
    context: ApplicationContext;
    granularity: TimeGranularityType | null;
    period: ContextFreePeriod;
    comparison?: Period | null;
    minDate?: Date;
}

export const DateFilterFieldsV2: React.FC<
    { children?: React.ReactNode | undefined } & DateFilterFieldsV2Props
> = ({ context, period, comparison, minDate, ...props }) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const handleChange: DateFilterProps['onChange'] = (values) => {
        props.onChange(values);
        onClose();
    };

    const rangeText = isRelativePeriod(period)
        ? periodToAbsoluteDateString(context, period)
        : undefined;

    return (
        <Tooltip placement="top" hasArrow={true} label={rangeText}>
            <span>
                <ConditionPopover
                    isOpen={isOpen}
                    onClose={onClose}
                    column={props.column}
                    // we want to make sure the nested form values are synced to context
                    // when opened
                    lazyBehavior="unmount"
                    isLazy={true}
                    content={
                        <Box mb={0} borderRadius="lg">
                            <DateFilter
                                granularity={props.granularity ?? 'week'}
                                getPresets={props.getPresets}
                                defaultValues={{ comparison, period }}
                                comparison={comparison ?? null}
                                onChange={handleChange}
                                minDate={minDate}
                            />
                        </Box>
                    }
                >
                    <Tag
                        cursor="pointer"
                        size="lg"
                        p={1.5}
                        borderRadius="md"
                        onClick={onToggle}
                    >
                        <VStack alignItems="flex-start" spacing={0}>
                            <Text fontSize="md" whiteSpace="nowrap">
                                {periodToString(context, period)}
                            </Text>
                            {comparison && (
                                <Text
                                    fontSize="sm"
                                    color="whiteAlpha.500"
                                    whiteSpace="nowrap"
                                >
                                    Compared to{' '}
                                    {periodToString(context, comparison, 'previous')}
                                </Text>
                            )}
                        </VStack>
                    </Tag>
                </ConditionPopover>
            </span>
        </Tooltip>
    );
};
