import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'lodash/fp';
import { useAccessView, AccessRestrictView } from '../../../../views';
import { useAuthenticatedContext } from '../../../../container';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FeatureModalView } from '../../../view';

export function createOrganizationFeatureMiddleware(): ApplicationEntryEnhancer {
    return compose(createFeatureModalMiddleware());
}

export function createFeatureModalMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            context: { createOrganization },
        } = config;

        function enhanceProvider(
            Component: React.FC<{ children?: React.ReactNode }>
        ): React.FC<{ children?: React.ReactNode }> {
            // NOTE this is potentially bad because it subscribes the entire subtree to the
            // state updates and as such will rerender for any downstream state change
            return observer((props) => {
                const context = useAuthenticatedContext();
                const accessViewProps = useAccessView(
                    context,
                    context.access.current?.request ?? null
                );
                return (
                    <>
                        <FeatureModalView
                            isOpen={context.access.current?.restricted ?? false}
                            onClose={context.access.clear}
                        >
                            <AccessRestrictView
                                layout={{
                                    header: {
                                        fontSize: {
                                            base: '4xl',
                                            '2xl': '6xl',
                                        },
                                    },
                                    tagline: {
                                        fontSize: {
                                            base: 'xl',
                                            '2xl': '2xl',
                                        },
                                    },
                                    base: {
                                        fontSize: {
                                            base: 'lg',
                                            '2xl': 'xl',
                                        },
                                    },
                                    container: {
                                        // justifyContent: 'center',
                                        // alignItems: 'center',
                                        // maxW: { base: '100%', xl: '66%' },
                                        borderRadius: { base: 8, '2xl': 12 },
                                        // NOTE: the sidebar layout content padding should be refactored to the child layouts as different
                                        // views need different padding and to avoid this hack
                                        m: {
                                            base: 12,
                                            '2xl': 16,
                                            // base: 8 - SIDEBAR_CONTENT_PADDING,
                                            // '2xl': 12 - SIDEBAR_CONTENT_PADDING,
                                        },
                                    },
                                    list: {
                                        spacing: { base: 8, '2xl': 12 },
                                    },
                                    item: {
                                        p: { base: 8, '2xl': 12 },
                                    },
                                    actions: {
                                        spacing: { base: 4, '2xl': 6 },
                                    },
                                }}
                                {...accessViewProps}
                            />
                        </FeatureModalView>
                        <Component {...props} />
                    </>
                );
            });
        }

        const instance = create({
            ...config,
            context: {
                ...config.context,
                createOrganization(config) {
                    const Context = createOrganization(config);
                    return {
                        ...Context,
                        Provider: enhanceProvider(Context.Provider),
                    };
                },
            },
        });
        return { ...instance };
    };
}
