import { DatasetDto, DatasetFlowType, DatasetQueryDto } from '../../../api';
import { DatasetProperty } from '../../../domain/datasets';
import { SurveyAdapter, SurveyEntity } from '../../../app/datasets';
import { assert } from '../../../util/assert';
import { ReferenceType } from '../../../domain/attributes';
import { castTypeToDomain } from '../../api';
import { SurveyImplConfig } from './surveyConfig';
import { getSurveyEstimate, getSurveyImportMode } from './surveyHelper';

export function createSurveyImpl(config: SurveyImplConfig): SurveyAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function toEntity(dto: DatasetDto): SurveyEntity | null {
        if (dto.data.kind !== 'physical') {
            return null;
        }
        if (!dto.data.ingest_types?.includes('direct')) {
            // for now only show dataset with direct ingest type for studies
            return null;
        }
        const partition = dto.data.columns.find((candidate) =>
            candidate.flags.includes('time_partition')
        );
        const time = dto.data.columns.find((candidate) => candidate.type === 'date');
        assert(partition, `dataset ${dto.slug} does not have partition specified`);
        return {
            id: dto.slug,
            name: dto.name,
            tags: [],
            description: null,
            plugin: dto.plugin ? { id: dto.plugin } : null,
            estimate: getSurveyEstimate(dto),
            configuration: {
                mode: getSurveyImportMode(dto),
                partitionKey: partition.name,
                timeKey: time?.name ?? null,
                schema: dto.data.columns.flatMap((item): DatasetProperty[] => {
                    if (item.flags.includes('asset')) {
                        // skip asset column
                        return [];
                    }
                    if (item.flags.includes('time_partition')) {
                        // skip partition column
                        return [];
                    }
                    return [
                        {
                            key: item.name,
                            name: item.title,
                            description: item.description,
                            type: castTypeToDomain(item.type),
                            constraints: [],
                            isDisabled: false,
                            disabledReason: null,
                            isRequired: item.flags.includes('required'),
                        },
                    ];
                }),
            },
        };
    }

    return {
        async find(context, query) {
            const mapped: DatasetQueryDto = {
                types: ['direct'],
            };
            const response = await api.dataset.list(context, mapped);
            return response.data.flatMap((item) => {
                const mapped = toEntity(item.dataset);
                if (!mapped) {
                    return [];
                }
                return [mapped];
            });
        },
        // TODO implement lookup endpoint
        async lookup(context, query) {
            const items = await this.find(context, { category: 'survey' });
            const found = items.find((candidate) => candidate.id === query.id) ?? null;
            if (!found) {
                throw new Error(`survey '${query.id}' not found`);
            }
            return found;
        },
    };
}
