import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    AiOutlineArrowRight,
    AiOutlineBulb,
    AiOutlineInfoCircle,
    AiOutlineWarning,
} from 'react-icons/ai';
import {
    VStack,
    Box,
    HStack,
    Image,
    StackDivider,
    Text,
    Heading,
    Select,
    Button,
    ButtonGroup,
    Icon,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Tag,
    Grid,
    GridItem,
    Center,
    Checkbox,
    Wrap,
    Link as ChakraLink,
    WrapItem,
    useToken,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
// import { Link } from 'react-router-dom';
import { MdOutlineInsertChartOutlined } from 'react-icons/md';
import { CardSpinner } from '../../../../domain';
import {
    isEmptyCollection,
    isLoadedCollection,
    isLoadingCollection,
} from '../../../base';
import { ApiError } from '../../../base';
import { assertNever } from '../../../util';
import {
    AnyVisualizationResultViewProps,
    isComparisonResultProps,
    isDistributionResultProps,
    isLineResultProps,
} from '../../../view/visualizations';
import { InsightRouteConfigV2 } from './insightRouteConfig';
import { InsightRouteViewPropsV2 } from './insightRouteProps';
import { createInsightItemContainer, InsightRouteItemViewProps } from './item';
import {
    createOpportunityVisualizationContainer,
    OpportunityVisualizationViewProps,
} from './visualization';

export function createInsightRouteViewV2(
    config: InsightRouteConfigV2
): React.FC<{ children?: React.ReactNode | undefined } & InsightRouteViewPropsV2> {
    const {
        deps: {
            UI: {
                Visualization: { BarChart, LineChart },
                Application: {
                    Link,
                    Table,
                    Thead,
                    Tbody,
                    Tr,
                    Td,
                    Th,
                    Tooltip,
                    createErrorBoundary,
                },
            },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: ApiError,
    });

    const PageErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    const InsightItemContainer = createInsightItemContainer(config);
    const VisualizartionItemContainer = createOpportunityVisualizationContainer(config);

    const PageErrorState: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    > = (props) => {
        return (
            <Center w="full" h="full" p={6}>
                <VStack spacing={2} fontWeight="medium">
                    <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} />
                    <VStack color="whiteAlpha.700" spacing={0}>
                        <Text fontWeight="medium">Failed to load opportunities page</Text>
                        <Text fontSize="sm">
                            If this issue continues, please
                            <Box as="span" color="blue.400">
                                {' '}
                                contact support
                            </Box>
                        </Text>
                    </VStack>
                </VStack>
            </Center>
        );
    };

    const VisualizationErrorState: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    > = (props) => {
        return (
            <Center w="full" h="full" p={6}>
                <VStack spacing={2} fontWeight="medium">
                    <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} />
                    <VStack color="whiteAlpha.700" spacing={0}>
                        <Text fontWeight="medium">Failed to load chart data</Text>
                        <Text fontSize="sm">
                            If this issue continues, please
                            <Box as="span" color="blue.400">
                                {' '}
                                contact support
                            </Box>
                        </Text>
                    </VStack>
                </VStack>
            </Center>
        );
    };

    const InsightItem: React.FC<
        { children?: React.ReactNode | undefined } & InsightRouteItemViewProps
    > = (props) => {
        const { opportunityProps } = props;
        return (
            <VStack
                // px={4}
                p={6}
                align="start"
                w="full"
                h="full"
                fontWeight="medium"
                spacing={6}
            >
                <VStack align="start">
                    <HStack spacing={3} w="full">
                        <Box p={2.5} bg="whiteAlpha.300" borderRadius="md" flexShrink={0}>
                            <Image w={5} h={5} src={opportunityProps.iconUrl ?? ''} />
                        </Box>
                        <HStack justify="space-between" w="full" align="start">
                            <Box flex={1}>
                                <Text color="whiteAlpha.900">
                                    {opportunityProps.title}
                                </Text>
                            </Box>
                        </HStack>
                    </HStack>
                    <VStack align="start" w="full" spacing={2} flex={1}>
                        {opportunityProps.description && (
                            <Box color="whiteAlpha.700">
                                {opportunityProps.description}
                            </Box>
                        )}
                        <HStack w="full" justify="space-between">
                            <HStack
                                spacing={2}
                                color="whiteAlpha.500"
                                divider={<Box border="none">&#x2022;</Box>}
                            >
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    label={
                                        <Box p={2}>
                                            {opportunityProps.date.description}
                                        </Box>
                                    }
                                >
                                    <Box cursor="default">
                                        {opportunityProps.date.relative.label}
                                    </Box>
                                </Tooltip>
                                {opportunityProps.actions.map((action) => (
                                    <Box key={action.key}>{action.label}</Box>
                                ))}
                            </HStack>
                        </HStack>
                    </VStack>
                </VStack>
                {props.children && (
                    <VStack flex={1} justify="end" w="full">
                        <Box
                            w="full"
                            pb={3}
                            pt={6}
                            // px={6}
                            borderTopWidth={2}
                            borderColor="whiteAlpha.300"
                            borderStyle="solid"
                            h={{ base: '33vh', xl: '25vh' }}
                            minH="18rem"
                        >
                            <Suspense fallback={<CardSpinner />}>
                                {props.children}
                            </Suspense>
                        </Box>
                    </VStack>
                )}
            </VStack>
        );
    };

    const VisualizationChart: React.FC<
        { children?: React.ReactNode | undefined } & Exclude<
            AnyVisualizationResultViewProps,
            { kind: 'comparison' }
        >
    > = (props) => {
        if (isLineResultProps(props)) {
            return <LineChart {...props.getChartProps()} />;
        }
        if (isDistributionResultProps(props)) {
            return <BarChart {...props.getChartProps()} />;
        }
        // assertNever(props);
        throw new Error('unsupported insight visualization');
    };

    const VisualizationItem: React.FC<
        { children?: React.ReactNode | undefined } & OpportunityVisualizationViewProps
    > = (props) => {
        const routeProps = props.getRouteProps();
        const resultProps = props.getResultProps();
        const pluginProps = props.getPluginProps();
        const dateProps = resultProps.getDateProps();
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

        if (isComparisonResultProps(resultProps)) {
            // for tabular data we do not render legends
            return (
                <VStack
                    // ref={ref}
                    align="start"
                    w="full"
                    color="whiteAlpha.900"
                    spacing={3}
                >
                    <VStack w="full" align="start" spacing={0}>
                        <HStack w="full" justify="space-between">
                            <HStack w="full" spacing={3} align="center">
                                <Icon w={5} h={5} as={MdOutlineInsertChartOutlined} />
                                <ChakraLink
                                    id="opportunity_title_link"
                                    as={Link}
                                    to={routeProps.path}
                                    onClick={routeProps.onOpenClick}
                                >
                                    <Text fontWeight="semibold">{props.label}</Text>
                                </ChakraLink>
                            </HStack>
                            <Link
                                id="opportunity_open_link"
                                to={routeProps.path}
                                onClick={routeProps.onOpenClick}
                            >
                                <Button
                                    size="sm"
                                    borderRadius="sm"
                                    bg="whiteAlpha.300"
                                    _hover={{ bg: 'whiteAlpha.400' }}
                                    _focus={{ outline: 'none' }}
                                >
                                    Open
                                </Button>
                            </Link>
                        </HStack>
                        <Text fontWeight="medium" color="whiteAlpha.500" fontSize="sm">
                            {dateProps.period.label}
                        </Text>
                    </VStack>
                    <Box
                        pb={3}
                        w="full"
                        fontWeight="medium"
                        overflow="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                height: '0.5rem',
                            },
                            '&::-webkit-scrollbar-track': {},
                            '&::-webkit-scrollbar-corner': {
                                '-webkit-appearance': 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '1rem',
                                background: scrollbarBg,
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: scrollbarHoverBg,
                            },
                        }}
                    >
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th
                                        p={0}
                                        borderBottomWidth={2}
                                        borderColor="whiteAlpha.300"
                                        color="whiteAlpha.500"
                                        textAlign="right"
                                    />
                                    {resultProps.getLegendProps().map((legend) => (
                                        <Th
                                            key={legend.label}
                                            borderBottomWidth={2}
                                            borderColor="whiteAlpha.300"
                                            color="whiteAlpha.500"
                                            whiteSpace="nowrap"
                                            textAlign="right"
                                        >
                                            <Tooltip
                                                placement="top"
                                                hasArrow={true}
                                                p={4}
                                                bg="#1D1C21"
                                                isDisabled={!legend.description}
                                                label={
                                                    <VStack
                                                        align="start"
                                                        fontWeight="medium"
                                                        spacing={1}
                                                    >
                                                        <HStack
                                                            spacing={2}
                                                            align="center"
                                                        >
                                                            <Box
                                                                w={2}
                                                                h={2}
                                                                bg={`${legend.colorScheme}.300`}
                                                                borderRadius="full"
                                                            />
                                                            <Text>{legend.label}</Text>
                                                        </HStack>
                                                        <Text
                                                            fontStyle="italic"
                                                            color="whiteAlpha.700"
                                                        >
                                                            {legend.description}
                                                        </Text>
                                                    </VStack>
                                                }
                                            >
                                                <HStack
                                                    justify="end"
                                                    spacing={2}
                                                    align="center"
                                                    cursor="default"
                                                >
                                                    <Checkbox
                                                        size="sm"
                                                        isChecked={true}
                                                        colorScheme={`legend.${legend.colorScheme}`}
                                                    />
                                                    <Text>{legend.label}</Text>
                                                </HStack>
                                            </Tooltip>
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {resultProps.getMetrics().map((metric) => {
                                    return (
                                        <Tr key={metric.id}>
                                            <Td
                                                py={0}
                                                borderBottomWidth={2}
                                                borderColor="whiteAlpha.200"
                                                textAlign="right"
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={0}
                                                    bg="#1D1C21"
                                                    label={
                                                        <VStack p={4} align="start">
                                                            <HStack spacing={3}>
                                                                <Box
                                                                    p={2}
                                                                    bg="whiteAlpha.200"
                                                                    borderRadius="md"
                                                                >
                                                                    <Image
                                                                        w={5}
                                                                        h={5}
                                                                        src={
                                                                            pluginProps.iconUrl
                                                                        }
                                                                    />
                                                                </Box>
                                                                <Text>{metric.name}</Text>
                                                            </HStack>
                                                            {metric.description && (
                                                                <Text color="whiteAlpha.700">
                                                                    {metric.description}
                                                                </Text>
                                                            )}
                                                        </VStack>
                                                    }
                                                >
                                                    <Text
                                                        cursor="default"
                                                        whiteSpace="nowrap"
                                                        fontWeight="medium"
                                                    >
                                                        {metric.name}
                                                    </Text>
                                                </Tooltip>
                                            </Td>
                                            {resultProps
                                                .getValues(metric)
                                                .map((value) => (
                                                    <Td
                                                        key={value.key}
                                                        py={0}
                                                        borderBottomWidth={2}
                                                        borderColor="whiteAlpha.200"
                                                    >
                                                        <HStack py={3} justify="end">
                                                            <Text>{value.label}</Text>
                                                        </HStack>
                                                    </Td>
                                                ))}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                </VStack>
            );
        }
        const legendProps = resultProps.getLegendProps();
        return (
            <VStack align="start" h="full" w="full" color="whiteAlpha.900" spacing={3}>
                <VStack w="full" align="start" spacing={0}>
                    <HStack w="full" justify="space-between">
                        <HStack w="full" spacing={3} align="center">
                            <Icon w={5} h={5} as={MdOutlineInsertChartOutlined} />
                            <ChakraLink
                                as={Link}
                                id="opportunity_title_link"
                                to={routeProps.path}
                                onClick={routeProps.onOpenClick}
                            >
                                <Text fontWeight="semibold">{props.label}</Text>
                            </ChakraLink>
                        </HStack>
                        <Link
                            id="opportunity_open_link"
                            to={routeProps.path}
                            onClick={routeProps.onOpenClick}
                        >
                            <Button
                                size="sm"
                                borderRadius="sm"
                                bg="whiteAlpha.300"
                                _hover={{ bg: 'whiteAlpha.400' }}
                                _focus={{ outline: 'none' }}
                            >
                                Open
                            </Button>
                        </Link>
                    </HStack>
                    <Text fontWeight="medium" color="whiteAlpha.500" fontSize="sm">
                        {dateProps.period.label}
                    </Text>
                </VStack>
                <VStack align="start" h="full" w="full" spacing={3} flex={1}>
                    <VStack w="full">
                        <HStack spacing={6}>
                            {legendProps.map((item) => (
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    key={item.label}
                                    bg="#1D1C21"
                                    p={4}
                                    label={
                                        <VStack
                                            align="start"
                                            fontWeight="medium"
                                            spacing={1}
                                        >
                                            <HStack spacing={2} align="center">
                                                <Box
                                                    w={2}
                                                    h={2}
                                                    bg={`${item.colorScheme}.300`}
                                                    borderRadius="full"
                                                />
                                                <Text>{item.label}</Text>
                                            </HStack>
                                            <Text
                                                fontStyle="italic"
                                                color="whiteAlpha.700"
                                            >
                                                {item.description}
                                            </Text>
                                        </VStack>
                                    }
                                    isDisabled={item.description === null}
                                >
                                    <HStack>
                                        <Checkbox
                                            size="sm"
                                            isChecked={true}
                                            colorScheme={`legend.${item.colorScheme}`}
                                        />
                                        <Text userSelect="none" fontSize="sm">
                                            {item.label}
                                        </Text>
                                    </HStack>
                                </Tooltip>
                            ))}
                        </HStack>
                    </VStack>
                    <HStack align="center" h="full" w="full" flex={1}>
                        <Box position="relative" h="full" width="full">
                            <VisualizationChart {...resultProps} />
                        </Box>
                    </HStack>
                </VStack>
            </VStack>
        );
    };

    return (props) => {
        const opportunityProps = props.getOpportunityProps();
        const dateProps = props.getDateProps();
        const statusProps = opportunityProps.getStatusProps();
        return (
            <PageErrorBoundary fallback={() => <PageErrorState />}>
                <Grid templateRows="1fr" h="full">
                    <GridItem>
                        <VStack position="relative">
                            <VStack
                                px={4}
                                py={8}
                                w="full"
                                maxW={{
                                    sm: '100vw',
                                    md: '90vw',
                                    lg: '90vw',
                                    xl: '85vw',
                                    '2xl': '80rem',
                                }}
                                marginX="auto"
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.300"
                                        borderWidth={1}
                                    />
                                }
                                spacing={4}
                                pb={48}
                            >
                                <HStack justify="start" w="full">
                                    <HStack flex={1} spacing={3} align="center">
                                        <Heading fontWeight="semibold" fontSize="2xl">
                                            Opportunities
                                        </Heading>
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            p={4}
                                            bg="#1D1C21"
                                            label={
                                                <Text>
                                                    Opportunities highlight potential
                                                    areas for improving performance based
                                                    on what you're doing differently
                                                    compared to the high performing
                                                    companies in your peer group
                                                </Text>
                                            }
                                        >
                                            <Box as="span">
                                                <Icon
                                                    fontSize="xl"
                                                    color="whiteAlpha.700"
                                                    as={AiOutlineInfoCircle}
                                                />
                                            </Box>
                                        </Tooltip>
                                        <Tag
                                            fontWeight="semibold"
                                            color="blackAlpha.900"
                                            bg="#c6e74f"
                                            letterSpacing="wide"
                                        >
                                            New
                                        </Tag>
                                    </HStack>
                                    <HStack>
                                        <ButtonGroup isAttached={true}>
                                            <Menu>
                                                <MenuButton
                                                    flexShrink={0}
                                                    borderRight="1px solid"
                                                    borderColor="blackAlpha.500"
                                                    borderRightRadius="none"
                                                    as={Button}
                                                    _selected={{
                                                        bg: 'green.500',
                                                        white: 'white',
                                                    }}
                                                    // minW={32}
                                                    size="sm"
                                                    bg="#292929"
                                                    _focus={{ outline: 'none' }}
                                                    rightIcon={
                                                        <Icon
                                                            fontSize="lg"
                                                            w="1rem"
                                                            as={ChevronDownIcon}
                                                        />
                                                    }
                                                >
                                                    {dateProps.getPeriodLabel()}
                                                </MenuButton>
                                                <MenuList bg="#292929">
                                                    {dateProps
                                                        .getOptions()
                                                        .map((item) => {
                                                            const itemProps =
                                                                dateProps.getPeriodOptionProps(
                                                                    item
                                                                );
                                                            return (
                                                                <MenuItem
                                                                    aria-selected={
                                                                        itemProps.isSelected
                                                                    }
                                                                    _selected={{
                                                                        color: 'green.500',
                                                                    }}
                                                                    icon={
                                                                        <Icon
                                                                            as={CheckIcon}
                                                                            visibility={
                                                                                itemProps.isSelected
                                                                                    ? 'inherit'
                                                                                    : 'hidden'
                                                                            }
                                                                        />
                                                                    }
                                                                    key={itemProps.label}
                                                                    onClick={
                                                                        itemProps.onClick
                                                                    }
                                                                    fontWeight="medium"
                                                                >
                                                                    {itemProps.label}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </MenuList>
                                            </Menu>
                                            <ReactDatePicker
                                                calendarClassName="dark"
                                                customInput={
                                                    <Button
                                                        borderLeftRadius="none"
                                                        w="full"
                                                        leftIcon={
                                                            <Icon as={CalendarIcon} />
                                                        }
                                                        size="sm"
                                                        bg="#292929"
                                                        _focus={{ outline: 'none' }}
                                                    >
                                                        {dateProps.getAbsoluteLabel(
                                                            dateProps.value,
                                                            true
                                                        )}
                                                    </Button>
                                                }
                                                {...dateProps.getRangeProps()}
                                                selectsRange={true}
                                            />
                                        </ButtonGroup>
                                    </HStack>
                                </HStack>
                                <VStack align="start" w="full" spacing={4}>
                                    <HStack w="full">
                                        <HStack flex={1}>
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.700"
                                                letterSpacing="wide"
                                            >
                                                {opportunityProps.getCollectionLabel()}
                                            </Text>
                                        </HStack>
                                        <HStack>
                                            <Select
                                                size="md"
                                                {...props.getSortingProps()}
                                                _focus={{ outline: 'none' }}
                                            >
                                                <option value="relevance">
                                                    Relevance
                                                </option>
                                                <option value="recent">
                                                    Most recent
                                                </option>
                                            </Select>
                                        </HStack>
                                    </HStack>
                                    <VStack w="full">
                                        {isEmptyCollection(
                                            opportunityProps.collection
                                        ) && (
                                            <HStack
                                                p={4}
                                                border="2px solid"
                                                borderColor="whiteAlpha.300"
                                                borderRadius="md"
                                                w="full"
                                            >
                                                <VStack p={4} w="full" spacing={4}>
                                                    <Box
                                                        bg="whiteAlpha.200"
                                                        p={4}
                                                        borderRadius="md"
                                                    >
                                                        <Icon
                                                            color="whiteAlpha.700"
                                                            fontSize="3xl"
                                                            as={AiOutlineBulb}
                                                        ></Icon>
                                                    </Box>
                                                    <VStack spacing={2}>
                                                        <Heading
                                                            fontSize="lg"
                                                            fontWeight="medium"
                                                            color="whiteAlpha.700"
                                                        >
                                                            No opportunities found for
                                                            period
                                                        </Heading>
                                                        <Text
                                                            fontSize="md"
                                                            fontWeight="medium"
                                                            color="whiteAlpha.500"
                                                        >
                                                            {dateProps.getAbsoluteLabel(
                                                                dateProps.getAbsoluteRange(
                                                                    dateProps.value
                                                                )
                                                            )}
                                                        </Text>
                                                    </VStack>
                                                </VStack>
                                            </HStack>
                                        )}
                                        {isLoadingCollection(
                                            opportunityProps.collection
                                        ) ? (
                                            <CardSpinner />
                                        ) : (
                                            <Grid
                                                gap={6}
                                                w="full"
                                                templateColumns={{
                                                    // use minmax to ensure the visualization dimensions
                                                    // does not expand the grid container
                                                    base: 'minmax(0, 1fr)',
                                                    xl: 'minmax(0, 1fr) minmax(0, 1fr)',
                                                }}
                                            >
                                                {opportunityProps.collection.data?.items?.map(
                                                    (item, index, array) => {
                                                        const visualizationProps =
                                                            props.getVisualizationProps(
                                                                item
                                                            );

                                                        const previous = array[index - 1];
                                                        const visualizationPropsPrevious =
                                                            previous
                                                                ? props.getVisualizationProps(
                                                                      previous
                                                                  )
                                                                : null;

                                                        const newLine =
                                                            visualizationPropsPrevious !==
                                                                null &&
                                                            visualizationProps === null;

                                                        return (
                                                            <GridItem
                                                                key={item.insight.id}
                                                                border="2px solid"
                                                                borderColor="whiteAlpha.300"
                                                                borderRadius="md"
                                                                gridColumnStart={
                                                                    newLine
                                                                        ? 1
                                                                        : undefined
                                                                }
                                                            >
                                                                <InsightItemContainer
                                                                    as={InsightItem}
                                                                    {...props.getItemProps(
                                                                        item
                                                                    )}
                                                                >
                                                                    {visualizationProps && (
                                                                        <ErrorBoundary
                                                                            fallback={(
                                                                                error
                                                                            ) => (
                                                                                <VisualizationErrorState />
                                                                            )}
                                                                        >
                                                                            <VisualizartionItemContainer
                                                                                as={
                                                                                    VisualizationItem
                                                                                }
                                                                                opportunity={
                                                                                    item
                                                                                }
                                                                                {...visualizationProps}
                                                                            />
                                                                        </ErrorBoundary>
                                                                    )}
                                                                </InsightItemContainer>
                                                            </GridItem>
                                                        );
                                                    }
                                                )}
                                                {isLoadedCollection(
                                                    opportunityProps.collection
                                                ) && statusProps ? (
                                                    <GridItem>
                                                        <VStack
                                                            p={6}
                                                            align="start"
                                                            border="2px solid"
                                                            borderColor="whiteAlpha.300"
                                                            borderRadius="md"
                                                            w="full"
                                                            h="full"
                                                            spacing={2}
                                                            fontWeight="medium"
                                                        >
                                                            <HStack w="full" h="full">
                                                                <VStack
                                                                    w="full"
                                                                    spacing={4}
                                                                >
                                                                    <VStack
                                                                        w="full"
                                                                        spacing={4}
                                                                    >
                                                                        {statusProps.Icon && (
                                                                            <Box
                                                                                bg="whiteAlpha.300"
                                                                                p={2.5}
                                                                                borderRadius="md"
                                                                            >
                                                                                <Icon
                                                                                    fontSize="lg"
                                                                                    color="whiteAlpha.700"
                                                                                    w={5}
                                                                                    h={5}
                                                                                    as={
                                                                                        statusProps.Icon
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                        {statusProps.description && (
                                                                            <Text color="whiteAlpha.700">
                                                                                {
                                                                                    statusProps.description
                                                                                }
                                                                            </Text>
                                                                        )}
                                                                        <Heading
                                                                            fontSize="lg"
                                                                            color="whiteAlpha.700"
                                                                            fontWeight="medium"
                                                                        >
                                                                            {
                                                                                statusProps.title
                                                                            }
                                                                        </Heading>
                                                                    </VStack>
                                                                    {statusProps.button && (
                                                                        <Button
                                                                            colorScheme="green"
                                                                            variant="outline"
                                                                            {...statusProps.button}
                                                                        />
                                                                    )}
                                                                </VStack>
                                                            </HStack>
                                                        </VStack>
                                                    </GridItem>
                                                ) : null}
                                            </Grid>
                                        )}
                                    </VStack>
                                </VStack>
                            </VStack>
                        </VStack>
                    </GridItem>
                </Grid>
            </PageErrorBoundary>
        );
    };
}
