import { useMemo } from 'react';
import { buildTableViewProps } from './tableFactory';
import { TableController } from './tableInterface';

export function createTableController(): TableController {
    return {
        useProps(props) {
            const tableProps = useMemo(
                () => buildTableViewProps(props.item),
                [props.item]
            );
            return tableProps;
        },
    };
}
