import type { AnyPropertyInputControllerProps } from '../../../strategy/input';
import { AnyResolvedType, Property } from '../../../domain/attributes';
import { TraitFilterControllerProps } from './traitProps';
import { TraitFilterValue } from './traitModel';

export function castPropertyToInputProps(
    props: TraitFilterControllerProps,
    property: Property<AnyResolvedType>
): AnyPropertyInputControllerProps {
    const value = props.value[property.key] ?? null;
    return {
        property,
        value,
        status: null,
        onChange(value) {
            if (value === null) {
                const { [property.key]: _removed, ...rest } = props.value;
                return props.onChange(rest);
            }
            props.onChange({
                ...props.value,
                [property.key]: value as TraitFilterValue,
            });
        },
        onClear() {
            const { [property.key]: _removed, ...rest } = props.value;
            props.onChange(rest);
        },
    };
}
