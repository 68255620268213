import React from 'react';
import { LegalContainerConfig } from '../base';
import { LegalLayoutController } from './legalLayoutInterface';
import { LegalLayoutContainerProps, LegalLayoutViewProps } from './legalLayoutProps';
import { LegalLayoutUrlParamSchema } from './legalLayoutSchema';

export function createLegalLayoutContainer(
    config: LegalContainerConfig,
    controller: LegalLayoutController,
    View: React.FC<{ children?: React.ReactNode | undefined } & LegalLayoutViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & LegalLayoutContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const options = useOptions(LegalLayoutUrlParamSchema);
        const props = controller.useProps(options);
        return <View {...props}>{children}</View>;
    };
}
