import { isAxiosErr } from '../../../../utils/axiosUtils';
import { runQuery } from '../../../../api';
import { AxiosError, AxiosInstance } from 'axios';
import { ApplicationError } from '../../../base';
import { LegacyAuthenticationScheme, ListResponse, isDomainErrorDto } from '../../common';
import { QueryResource } from './executeInterface';
import { ExecuteQueryDto } from './executeProps';

export function createExecuteResource(client: AxiosInstance): QueryResource {
    return {
        async create(context, payload, options) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await runQuery(client, payload, {
                ...options,
                version: 'v3',
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            return response;
            // const response = await client.post<ExecuteQueryDto, ReportScheduleDto>(
            //     '/api/v1/reports/schedules',
            //     payload,
            //     {
            //         params: { ...options },
            //         headers: {
            //             Authorization: `bearer ${scheme.store.authToken}`,
            //         },
            //     }
            // );
            // return response;
        },
    };
}
