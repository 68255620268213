import { CompettiveData } from './competitiveModel';
import {
    AnalysisCompetitiveSetControllerProps,
    AnalysisCompetitiveSetViewProps,
} from './competitiveProps';

export function createCompetitiveController() {
    return {
        useProps(
            data: CompettiveData,
            props: AnalysisCompetitiveSetControllerProps
        ): AnalysisCompetitiveSetViewProps {
            return { transparency: data.transparency, isLoading: data.isLoading };
        },
    };
}
