import { isCurrencyProperty, isTreeProperty, Property } from '../property';
import { AnyResolvedType } from '../type';
import {
    AnyValue,
    assertTreeValue,
    isNullEndNumericRangeValue,
    isNullStartNumericRangeValue,
    isNumericRangeValue,
} from '../value';
import { AnyCondition } from './conditionModel';

export function castPropertyAndValueToCondition(
    property: Property<AnyResolvedType>,
    value: AnyValue
): AnyCondition | null {
    if (value === null) {
        return null;
    }
    if (isCurrencyProperty(property) && isNumericRangeValue(value)) {
        return {
            key: property.key,
            operator: 'between',
            value,
        };
    }
    if (isCurrencyProperty(property) && isNullStartNumericRangeValue(value)) {
        return {
            key: property.key,
            operator: 'lte',
            value: value.to,
        };
    }
    if (isCurrencyProperty(property) && isNullEndNumericRangeValue(value)) {
        return {
            key: property.key,
            operator: 'gte',
            value: value.from,
        };
    }
    if (isTreeProperty(property)) {
        assertTreeValue(value);
        return {
            key: property.key,
            operator: 'in',
            value,
        };
    }
    console.error('property', property);
    console.error('value', value);
    throw new Error(`unmapped property value`);
}
