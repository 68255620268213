import { useQuery } from '@tanstack/react-query';
import { assert } from '../../../../util/assert';
import { ReportPollControllerConfig } from './pollConfig';
import { ReportPollLoader } from './pollInterface';
import { ReportPollAggregate } from './pollModel';

export function createReportPollLoader(
    config: Pick<ReportPollControllerConfig, 'repository'>
): ReportPollLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const reports = repository.report.useFind(
                context,
                {
                    limit: 10,
                    asset: props.query.report.asset,
                },
                {}
            );
            const preference = repository.preference.useLookup(context, {}, {});

            const status = [reports, preference].every(
                (item) => item.status === 'success'
            )
                ? 'success'
                : 'loading';

            return {
                status,
                item: useQuery({
                    enabled: status === 'success',
                    queryKey: ['report', 'poll', reports.data, preference.data],
                    queryFn(): ReportPollAggregate {
                        assert(reports.data, 'reports not ready');
                        assert(preference.data, 'preference not ready');
                        return {
                            reports: reports.data,
                            preference: preference.data,
                        };
                    },
                }),
            };
        },
    };
}
