import { SmallCloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import {
    createInputContainer,
    InputUI,
    RangeInput,
    TreeInput,
} from '../../../../../../ui';
import {
    FilterListViewProps,
    createFilterPopoverContainer,
    FilterPopoverViewProps,
} from '../../../../../../view/common';

export const NetworkInput: InputUI = {
    Number() {
        return <>not impl</>;
    },
    ShortText() {
        return <>not impl</>;
    },
    LongText() {
        return <>not impl</>;
    },
    Dropdown() {
        return <>not impl</>;
    },
    Checkbox() {
        return <>not impl</>;
    },
    Range(props) {
        return (
            <RangeInput
                style={{
                    wrapper: { w: 'full' },
                }}
                type="range"
                variant="slider"
                getLabel={props.getLabel}
                renderLabel={(label) => (
                    <Text fontWeight="semibold" fontSize="sm" color="whiteAlpha.600">
                        {label}
                    </Text>
                )}
                {...(props.scale.kind === 'linear'
                    ? {
                          intervals: [],
                          min: props.scale.min,
                          max: props.scale.max,
                          steps: props.scale.step,
                      }
                    : {
                          intervals: props.scale.steps,
                      })}
                value={props.value}
                onChange={props.onSlide}
                onChangeEnd={props.onChange}
            />
        );
    },
    MultiSelect(props) {
        return (
            <TreeInput
                value={props.value}
                onChange={props.onChange}
                // @ts-expect-error
                options={props.options}
            />
        );
    },
};

export function createCandidateFilterListView(): React.FC<
    { children?: React.ReactNode | undefined } & FilterListViewProps
> {
    const PopoverContainer = createFilterPopoverContainer();
    const InputContainer = createInputContainer();

    const Item: React.FC<
        { children?: React.ReactNode | undefined } & FilterPopoverViewProps
    > = (props) => {
        return (
            <Popover
                placement="bottom"
                {...props.popover}
                isLazy={true}
                lazyBehavior="unmount"
            >
                <PopoverTrigger>
                    <HStack
                        userSelect="none"
                        cursor="pointer"
                        aria-selected={props.item.isActive}
                        borderWidth={2}
                        borderStyle="dashed"
                        borderColor="whiteAlpha.300"
                        h={10}
                        px={4}
                        spacing={2}
                        borderRadius="full"
                        fontWeight="medium"
                        _hover={{
                            bg: 'whiteAlpha.50',
                        }}
                        _selected={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'blue.300',
                            color: 'blue.300',
                        }}
                    >
                        {props.item.isActive && (
                            <>
                                <Text>{props.item.value.label}</Text>
                                <Icon
                                    _hover={{ color: 'blue.400' }}
                                    as={SmallCloseIcon}
                                    {...props.button.clear}
                                />
                            </>
                        )}
                        {!props.item.isActive && (
                            <>
                                <Text>{props.button.trigger.children}</Text>
                                <Icon as={AiOutlinePlus} />
                            </>
                        )}
                    </HStack>
                </PopoverTrigger>
                <PopoverContent bg="#1D1C21" _focus={{ outline: 'none' }}>
                    <PopoverHeader px={3}>
                        <HStack w="full" justify="space-between">
                            <HStack w="full">
                                <Text fontWeight="semibold">{props.item.title}</Text>
                            </HStack>
                        </HStack>
                    </PopoverHeader>
                    <PopoverBody p={6} maxH="32vh" overflowY="auto">
                        <InputContainer {...props.item.input} as={NetworkInput} />
                    </PopoverBody>
                    <PopoverFooter px={3}>
                        <HStack w="full" justify="space-between">
                            {props.selection && (
                                <Text
                                    whiteSpace="nowrap"
                                    color="blue.300"
                                    fontWeight="semibold"
                                    fontSize="sm"
                                >
                                    {props.selection.label}
                                </Text>
                            )}
                            <HStack w="full" justify="end">
                                {props.button.reset && (
                                    <Button
                                        size="sm"
                                        _focus={{ outline: 'none' }}
                                        {...props.button.reset}
                                    >
                                        Reset
                                    </Button>
                                )}
                                <Button
                                    colorScheme="blue"
                                    size="sm"
                                    _focus={{ outline: 'none' }}
                                    {...props.button.apply}
                                >
                                    Apply
                                </Button>
                            </HStack>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        );
    };
    return (props) => {
        return (
            <Wrap spacingX={2} spacingY={2}>
                {props.items.map((item) => {
                    const popoverProps = props.getPopoverProps(item);
                    return (
                        <WrapItem key={item.key}>
                            <PopoverContainer as={Item} {...popoverProps} />
                        </WrapItem>
                    );
                })}
            </Wrap>
        );
    };
}
