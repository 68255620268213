import { periodToDays } from '../../../../../domain';
import { useDashboardContext } from '../../../../app';
import { DashboardRouteController } from '../../../../route';
import { FeatureMiddleware } from '../../featureInterface';

export function createFeatureDateRangeDashboardMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(
            controller: DashboardRouteController
        ): DashboardRouteController {
            return {
                ...controller,
                useDateFilter() {
                    const { dashboard } = useDashboardContext();
                    const { feature, restrict } = api.useFeature('date_ranges');
                    const mapped = controller.useDateFilter();
                    return {
                        ...mapped,
                        onChange(values) {
                            if (!feature?.config?.lookback_period_days) {
                                // no config, skip
                                return mapped.onChange(values);
                            }
                            if (feature.config.exclude?.includes(dashboard.id)) {
                                // excluded, skip
                                return mapped.onChange(values);
                            }
                            const days = periodToDays(values.period);
                            if (days <= feature.config.lookback_period_days) {
                                // not restricted, skip
                                return mapped.onChange(values);
                            }
                            restrict({
                                ...feature!,
                                value: {
                                    dashboard,
                                    period: values.period,
                                },
                            });
                        },
                    };
                },
            };
        }

        return create({
            ...config,
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    return config.route.createDashboardRoute({
                        ...routeConfig,
                        controller: enhanceController(routeConfig.controller),
                    });
                },
            },
        });
    };
}
