import {
    Link as ChakraLink,
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    StackDivider,
    Tag,
    Text,
    useDisclosure,
    VStack,
    Button,
    Center,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    WrapItem,
    Wrap,
    Divider,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlineCloudUpload, AiOutlinePlus } from 'react-icons/ai';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import {
    DatasetConnectionItemProps,
    DataSourceItemProps,
    ImportItemViewProps,
} from '../../../../../view/datasets';
import { SettingImportConfig } from '../importConfig';
import { SettingImportViewProps } from './importListProps';

export function createSettingImportListView(
    init: SettingImportConfig,
    config: SettingAccountItemProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & SettingImportViewProps> {
    const { controller } = init;
    const {
        UI: {
            Application: { Link, Heading, Tooltip },
        },
        Layout: {
            Container,
            Content,
            Panel,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const ListItem: React.FC<
        { children?: React.ReactNode | undefined } & ImportItemViewProps
    > = (props) => {
        const metadataListProps = props.getMetadataListProps();
        return (
            <>
                <GridItem px={6} py={6}>
                    <VStack spacing={1} w="full" align="start">
                        <HStack h="full" spacing={4}>
                            <VStack align="start" spacing={0}>
                                <HStack>
                                    <Text userSelect="none" noOfLines={1}>
                                        {props.name}
                                    </Text>
                                </HStack>
                                <HStack
                                    spacing={3}
                                    color="whiteAlpha.500"
                                    divider={<Box border="none">&#x2022;</Box>}
                                >
                                    {metadataListProps.items.map((item, index) => (
                                        <Box key={index} cursor="pointer">
                                            <Text userSelect="none">{item.label}</Text>
                                        </Box>
                                    ))}
                                </HStack>
                            </VStack>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem px={6} py={6}>
                    <Tag
                        as={Box}
                        w="min-content"
                        size="lg"
                        borderRadius="full"
                        whiteSpace="nowrap"
                        flexShrink={0}
                        pl={4}
                        // weird issue where tag wrapper shrinks and text overflows
                        pr={8}
                    >
                        <HStack w="full" spacing={3}>
                            <Image
                                flexShrink={0}
                                w={4}
                                h={4}
                                src={props.dataset.iconUrl}
                            />
                            <Box flex={1}>
                                <Text whiteSpace="nowrap">{props.dataset.label}</Text>
                            </Box>
                        </HStack>
                    </Tag>
                </GridItem>
                <GridItem px={6} py={6}>
                    <HStack h="full" w="full">
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            p={4}
                            bg="#1D1C21"
                            isDisabled={!props.status.description}
                            label={
                                <VStack
                                    spacing={2}
                                    align="start"
                                    minW={32}
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.300"
                                        />
                                    }
                                >
                                    <VStack spacing={1}>
                                        <HStack w="full">
                                            <Box
                                                w={2}
                                                h={2}
                                                borderRadius="full"
                                                bg={`${props.status.colorScheme}.400`}
                                            />
                                            <Text fontWeight="semibold">
                                                {props.status.label}
                                            </Text>
                                        </HStack>
                                        <Text color="whiteAlpha.700">
                                            {props.status.description}
                                        </Text>
                                    </VStack>
                                </VStack>
                            }
                        >
                            <Tag
                                whiteSpace="nowrap"
                                userSelect="none"
                                size="lg"
                                {...props.status.getTagProps()}
                            />
                        </Tooltip>
                    </HStack>
                </GridItem>
                <GridItem px={6} py={6}>
                    <HStack h="full" w="full" justify="end" whiteSpace="nowrap">
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            p={4}
                            bg="#1D1C21"
                            label={
                                <Text color="whiteAlpha.700">
                                    {props.updated.absolute.description}
                                </Text>
                            }
                        >
                            <Text color="whiteAlpha.700" userSelect="none">
                                {props.updated.relative.label}
                            </Text>
                        </Tooltip>
                    </HStack>
                </GridItem>
            </>
        );
    };

    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const viewProps = props.getViewProps();
        const alertProps = props.getAlertProps();

        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem
                                key={index}
                                to={item.to ?? undefined}
                                description={item.description}
                            >
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                    <ActionList>
                        <Link {...props.getPageActionProps().link}>
                            <Button
                                {...props.getPageActionProps().button}
                                size="sm"
                                colorScheme="blue"
                                leftIcon={<Icon as={AiOutlinePlus} />}
                                _focus={{
                                    outline: 'none',
                                }}
                            />
                        </Link>
                    </ActionList>
                </Header>
                <Content>
                    <VStack spacing={0} align="start" w="full" fontWeight="medium">
                        <Grid
                            w="full"
                            templateColumns={{
                                base: 'minmax(min-content, 2fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr)',
                            }}
                        >
                            <GridItem h={10} px={6} bg="whiteAlpha.300">
                                <HStack h="full" w="full">
                                    <Heading
                                        textTransform="uppercase"
                                        whiteSpace="nowrap"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Import
                                    </Heading>
                                </HStack>
                            </GridItem>
                            <GridItem h={10} px={6} bg="whiteAlpha.300">
                                <HStack h="full" w="full">
                                    <Text
                                        textTransform="uppercase"
                                        whiteSpace="nowrap"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Dataset
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem h={10} px={6} bg="whiteAlpha.300">
                                <HStack h="full" w="full">
                                    <Text
                                        textTransform="uppercase"
                                        whiteSpace="nowrap"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Status
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem h={10} px={6} bg="whiteAlpha.300">
                                <HStack h="full" w="full" justify="end">
                                    <Text
                                        textTransform="uppercase"
                                        whiteSpace="nowrap"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Updated At
                                    </Text>
                                </HStack>
                            </GridItem>
                            {viewProps.items.map((item, index, array) => {
                                const itemProps = viewProps.getItemProps(item);
                                return (
                                    <React.Fragment key={itemProps.id}>
                                        <ListItem {...itemProps} />
                                        {index < array.length - 1 && (
                                            <Divider
                                                borderWidth={1}
                                                borderColor="whiteAlpha.400"
                                                gridColumn="span 4"
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </VStack>
                </Content>
            </Container>
        );
    };
}
