import { AxiosResponse } from 'axios';
import { ReferralRequest, ReferralRequestFull } from '../models/Referral';
import { apiV1 } from './axios';

export const sendInviteReferral = async (request: ReferralRequest) => {
    return apiV1.post<unknown, AxiosResponse<ReferralRequestFull>>('/referrals', request);
};

export const getInviteReferral = async () => {
    return apiV1.get<unknown, AxiosResponse<Array<ReferralRequestFull>>>('/referrals');
};
