import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { AssetCreateDto, AssetResource } from '../asset';
import { MeResource } from './meInterface';
import { MeUserSchema } from './meSchema';

export function createMeResource(
    client: AxiosInstance,
    asset: AssetResource
): MeResource {
    return {
        async get(context) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/users/me`, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = MeUserSchema.parse(response.data.user);

            return parsed;
        },
        async update(context, request) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.patch(`/api/v1/users/me`, request, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = MeUserSchema.parse(response.data);

            return parsed;
        },
    };
}
