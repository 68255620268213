import { PriceDto } from '../../../../api';
import { useStore } from '../../../../stores/setupContext';
import { featureConfig } from '../../../../config';
import { ApplicationContext } from '../../../../base';
import { useRedirect } from '../../../redirect';
import { Subscription, useSubscriptionActions } from '../../subscription';
import { usePlans } from '../planHook';
import { PricingPlan } from '../planType';
import { PlanListViewProps } from './PlanListView';
import { useLocation } from 'react-router';
import { z } from 'zod';
import { useMemo } from 'react';
import { AnyPersona } from 'src/v2/domain/billing';
import { chain } from 'lodash';

const PlanQueryParamsSchema = z.object({
    type: z.optional(z.union([z.literal('agency'), z.literal('brand')])),
});

export const usePlanList = (
    context: ApplicationContext,
    {
        // subscription = null,
        excludeDefault = false,
        ...options
    }: {
        // subscription?: Subscription | null;
        absoluteSuccessPath: string;
        absoluteCancelPath: string;
        returnPath: string;
        excludeDefault?: boolean;
    }
): PlanListViewProps => {
    const { auth } = useStore();
    const planCategories = usePlans(context, { excludeDefault });
    const { create: createSubscription } = useSubscriptionActions(context);
    const location = useLocation();

    const queryParams = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const result = PlanQueryParamsSchema.safeParse(
            [...searchParams.entries()].reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {}
            )
        );
        if (result.success) {
            return result.data;
        }
        return null;
    }, [location.search]);

    const {
        actions: { redirect: startCheckout },
    } = useRedirect('checkout');

    const {
        actions: { redirect: startPortal },
    } = useRedirect('portal');

    const handleBookDemo = () => {
        window.open(featureConfig.BOOK_DEMO_URL, '_blank');
    };

    const handleEnterpriseContact = () => {
        window.open(featureConfig.ENTERPRISE_CONTACT_URL, '_blank');
    };

    const handleSelect = async (plan: PricingPlan) => {
        if (context.subscription?.active) {
            return startPortal({
                type: 'portal',
                plan: plan.id,
                returnPath: options.returnPath,
            });
        } else if (plan.default && !context.subscription?.active) {
            // User selected the free plan and does not currently have an active subscription.
            // Since we do not require a payment method for the default plan we simply create their
            // subscription on the backend
            await createSubscription(plan);
            // we need to sync the updated onboarding status
            // console.log('getting plan list acc');
            await auth.getMyAccount();
            return;
        } else if (plan.id === 'enterprise') {
            return handleEnterpriseContact();
        } else {
            // A paid subscription was selected and we need to collect a payment method. For this
            // we initiate the Stripe checkout flow
            return startCheckout({
                type: 'checkout',
                plan: plan.id,
                successPath: options.absoluteSuccessPath,
                cancelPath: options.absoluteCancelPath,
            });
        }
    };

    // this is a hack to infer the user persona based on the title selected during onboarding
    let persona: Pick<AnyPersona, 'kind'> = auth.currentUser?.title
        ?.toLowerCase()
        .includes('agency')
        ? {
              kind: 'agency',
          }
        : { kind: 'brand' };

    if (queryParams?.type) {
        // if type explitcly set override the default based on the user title
        persona = {
            kind: queryParams.type,
        };
    }

    return {
        current:
            chain(planCategories)
                .flatMap((x) => x.plans)
                .find((item) => !!context.subscription?.active && item.id === context.subscription?.plan)
                .value() ?? null,
        categories: planCategories,
        onSelect: handleSelect,
        onDemo: handleBookDemo,
        onNotAdvertising: handleBookDemo,
        excludeDefault,
        persona,
    };
};
