import { chain } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { PluginConnection, ConnectionDependency } from '../../../domain/metrics';
import { DashboardAdapter, useWorkspaceApi } from '../../dashboard';
import { PluginConnectionRepository } from './connectionInterface';

export function createConnectionRepositoryV2(config: {
    adapter: {
        dashboard: DashboardAdapter;
    };
}): PluginConnectionRepository {
    return {
        useFind(context, query, options = {}) {
            return useQuery({
                ...options,
                // @ts-expect-error
                refetchInterval: options.refetchInterval,
                queryKey: [
                    'workspaces',
                    'dashboards',
                    'v2',
                    context.organization.id,
                    query.workspaces,
                ],
                async queryFn() {
                    const responses = await Promise.all(
                        query.workspaces.map(async (workspace) => {
                            const dashboards =
                                await config.adapter.dashboard.listDashboards(context, {
                                    id: workspace.id,
                                    name: 'does not matter here',
                                });
                            return { workspace, dashboards };
                        })
                    );

                    return responses.flatMap((response) => {
                        return chain(response.dashboards ?? [])
                            .groupBy((item) => item.pluginId)
                            .flatMap((items, pluginId): PluginConnection[] => {
                                const [first, ...rest] = items;

                                const dependencies: ConnectionDependency[] =
                                    first.dependencies?.tests.map(
                                        (test): ConnectionDependency => ({
                                            name: test.test.test_name,
                                            status: test.test_pass
                                                ? 'fulfilled'
                                                : 'unfulfilled',
                                        })
                                    ) ?? [];

                                if (dependencies.length === 0) {
                                    return [
                                        {
                                            workspace: response.workspace,
                                            plugin: { id: pluginId },
                                            status: 'ready',
                                            dependencies,
                                        },
                                    ];
                                }

                                if (
                                    items.some(
                                        (item) => item.connection.kind === 'connected'
                                    )
                                ) {
                                    // connection is considered ready if at least one dashboard is accessable
                                    return [
                                        {
                                            workspace: response.workspace,
                                            plugin: { id: pluginId },
                                            status: 'ready',
                                            dependencies,
                                        },
                                    ];
                                }

                                if (
                                    items.some(
                                        (item) => item.connection.kind === 'syncing'
                                    )
                                ) {
                                    return [
                                        {
                                            workspace: response.workspace,
                                            plugin: { id: pluginId },
                                            status: 'syncing',
                                            dependencies,
                                        },
                                    ];
                                }

                                if (
                                    items.some((item) => item.connection.kind === 'error')
                                ) {
                                    return [
                                        {
                                            workspace: response.workspace,
                                            plugin: { id: pluginId },
                                            status: 'disconnected',
                                            dependencies,
                                        },
                                    ];
                                }
                                return [
                                    {
                                        workspace: response.workspace,
                                        plugin: { id: pluginId },
                                        status: 'pending',
                                        dependencies,
                                    },
                                ];
                            })
                            .value();
                    });
                },
                // keepPreviousData: true,
                // refetchInterval: poll ?? undefined,
                // staleTime: Infinity,
            });
        },
    };
}
