import { useMemo } from 'react';
import { assert } from '../../../util/assert';
import { OnboardingAssetConfig } from './onboardingAssetConfig';
import {
    AssetOnboardingLoader,
    AssetOnboardingLoaderData,
} from './onboardingAssetInterface';

export function createAssetOnboardingLoader(
    config: Pick<OnboardingAssetConfig, 'repository'>
): AssetOnboardingLoader {
    const { repository } = config;
    const limit = 500;
    return {
        useLoad(context) {
            const assets = repository.asset.useFind(
                context,
                { limit },
                { suspense: true }
            );
            const organization = repository.organization.useLookup(
                context,
                context.organization,
                { suspense: true }
            );
            assert(assets.status === 'success', 'expected suspense query');
            assert(organization.status === 'success', 'expected suspense query');

            const current = useMemo(
                () =>
                    assets.data.find(
                        (candidate) => candidate.id === organization.data.defaultAsset?.id
                    ) ?? null,
                [assets.data, organization.data.defaultAsset?.id]
            );

            const create = repository.asset.useCreate(context);
            const update = repository.asset.useUpdate(context);

            return useMemo<AssetOnboardingLoaderData>(
                () => ({
                    status: 'success',
                    item: {
                        current,
                        assets: assets.data ?? [],
                        organization: organization.data,
                    },
                    create,
                    update,
                }),
                [assets.data, organization.data, create, update, current]
            );
        },
    };
}
