import { AxiosInstance } from 'axios';
import React, { useMemo } from 'react';
import { useStore } from '../../../../stores/setupContext';
import { AuthSessionAdapter } from './sessionAdapter';
import { SessionContext, SessionContextValue } from './sessionContext';

export interface SessionProviderConfig {}

export interface SessionProviderProps {}

export function createSessionProvider(
    config: SessionProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & SessionProviderProps> {
    return (props) => {
        const { auth } = useStore();

        const adapter = useMemo<AuthSessionAdapter>(
            () => ({
                async signout() {
                    auth.logout();
                    sessionStorage.clear();
                },
            }),
            [auth.logout]
        );

        const context: SessionContextValue = {
            adapter: {
                session: adapter,
            },
        };

        return (
            <SessionContext.Provider value={context}>
                {props.children}
            </SessionContext.Provider>
        );
    };
}
