import { Workspace } from '../platform';
import moment from 'moment';
import {
    isRelativePeriod,
    isAbsolutePeriod,
    ContextFreePeriod,
    RelativePeriodInterval,
    AbsolutePeriod,
} from '../../../domain';
import { DateRangeValue } from '../attributes';
import { Report } from './reportModel';
import { ReportBlock } from './block';
import { ReportDependencyStatus } from './dependency';
import { ReportSegment } from './segment';
import { ReportDefinition } from './definition';
import { Comparison, TimeGranularity } from '../query';
import { capitalize } from 'lodash';
import { InsightEntity } from '../insights';
import { ReportInsightAggregate } from './insight';

moment.updateLocale('en', {
    week: { dow: 1 },
});

export function buildReport(props: {
    definition: ReportDefinition;
    insights: ReportInsightAggregate[];
    statuses: ReportDependencyStatus[];
    segments: ReportSegment[];
    date: Date;
    period: ContextFreePeriod;
    absolute: AbsolutePeriod;
    comparison?: Comparison;
    granularity: TimeGranularity | null;
}): Report {
    const blocks: ReportBlock[] = props.definition.blocks
        .map((block) => ({
            ...block,
            metrics: block.metrics.filter((metric) =>
                props.statuses.find(
                    (status) =>
                        status.dashboard.id === metric.dependency.dashboard.id &&
                        status.status === 'ready'
                )
            ),
        }))
        .filter((block) => block.metrics.length > 0);

    const periodString = isRelativePeriod(props.period)
        ? capitalize(`${props.period.interval}ly`)
        : null;

    const reportName = periodString
        ? `Varos ${periodString} Market Report`
        : `Varos Market Report`;

    return {
        id: props.definition.id,
        name: props.definition.name,
        title: reportName,
        status: blocks.length > 0 ? 'available' : 'empty',
        period: props.period,
        granularity: props.granularity ?? 'day',
        insights: props.insights,
        blocks,
        absolute: props.absolute,
        // absolute: isAbsolute(props.period)
        //     ? props.period
        //     : {
        //           start: moment(props.date)
        //               .subtract(props.period.amount, props.period.interval)
        //               .add(1, 'day')
        //               .toDate(),
        //           end: moment(props.date).toDate(),
        //       },
        comparison: props.comparison ?? { interval: 'period', amount: 1 },
        dependencies: props.statuses,
        segments: props.segments,
    };
}
