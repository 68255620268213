import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { buildBarChartViewProps } from './barFactory';
import { BarChartController } from './barInterface';

export function createBarChartController(): BarChartController {
    return {
        useProps(props) {
            const viewProps = useMemo(() => buildBarChartViewProps(props), [props]);
            return viewProps;
        },
    };
}
