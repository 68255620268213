import { z } from 'zod';

export const IntegrationModeSchema = z
    .union([z.literal('live'), z.literal('warmup')])
    .transform((value) => {
        if (value !== 'live' && value !== 'warmup') {
            return 'unknown';
        }
        return value;
    });

export const IntegrationDefinitionDtoSchema = z.object({
    id: z.number(),
    integration_type: z.string(),
    // implementation: {
    //     id: 4,
    //     integration_definition_id: 2,
    //     auth_method: 'oauth2',
    //     required_keys: [],
    //     submit_text: 'Add Account',
    // },
    title: z.string(),
    description: z.nullable(z.string()),
    mode: IntegrationModeSchema,
    icon_url: z.string(),
    logo_url: z.string(),
    // mode: z.union([
    //     z.literal('live')
    // ]),
    is_new: z.boolean(),
    is_recommended: z.boolean(),
    is_available: z.boolean(),
    created_at: z.date({ coerce: true }),
});

export const IntegrationDefinitionListResponseSchema = z.array(
    IntegrationDefinitionDtoSchema
);
