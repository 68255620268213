import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { MiniIntegrationDefinition } from 'src/models/integration';
import StoreBase from './StoreBase';
import { ContextType } from '../models/Account';
import { HomeContext } from 'src/models/Context';

class MenuStore extends StoreBase {
    isLoading: boolean = false;
    rootIntegrationMenu: Array<MiniIntegrationDefinition> | null = null;
    selectedCategoryId: string | null = null;
    currentParticipant: number | string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            rootIntegrationMenu: observable,
            selectedCategoryId: observable,
            currentParticipant: observable,

            // computed
            // filtersByProp: computed,

            // Actions
            setParticipant: action,
            setRootIntegrationMenu: action,
            setLoading: action,
            setSelectedCategory: action,
        });
    }

    setParticipant(participantId: string | number | null) {
        this.currentParticipant = participantId;
    }
    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        if (!isLoading) {
            this.setError(null);
        }
    }
    async onContextChanged(contextName: ContextType, ctx?: HomeContext | null): Promise<void> {
        switch (contextName) {
            case 'dashboard':
                break;
            default:
                break;
        }
    }
    setRootIntegrationMenu(integrations: Array<MiniIntegrationDefinition> | null) {
        this.rootIntegrationMenu = integrations;
    }

    setSelectedCategory = (category: string | null) => {
        this.selectedCategoryId = category;
    };
}

export default MenuStore;
