import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileControllerConfig } from '../fileConfig';
import { FileObject } from '../item';
import { FileListController } from './fileListInterface';

export function createFileController<TValue>(
    config: FileControllerConfig
): FileListController<TValue> {
    return {
        useProps(props) {
            const fileInputRef = useRef<HTMLInputElement>(null);
            const [fileValue, setFileValue] = useState('');
            const [files, setFiles] = useState<FileObject[]>([]);
            const [isLoading, setIsLoading] = useState(false);

            const handleButtonClick: React.MouseEventHandler = (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (!fileInputRef.current) {
                    return;
                }
                fileInputRef.current.click();
            };

            const handleFileChange: React.FormEventHandler<HTMLInputElement> = async (
                event
            ) => {
                setFileValue('');
                const [file] = event.currentTarget.files ?? [];
                if (!file) {
                    return;
                }
                setIsLoading(true);
                setTimeout(async () => {
                    try {
                        const parsed = await props.parse(file);
                        if (!fileInputRef.current) {
                            // no longer mounted, skip
                            return;
                        }
                        props.onChange(parsed);
                        setFiles([
                            {
                                filename: file.name,
                                fileSizeBytes: file.size,
                            },
                        ]);
                    } catch (error) {
                        if (error instanceof Error) {
                            props.onError(error);
                        }
                        throw error;
                    } finally {
                        setIsLoading(false);
                    }
                }, config.mockDelayMs);
            };

            return {
                items: files,
                getItemProps(item) {
                    const sizeKb = item.fileSizeBytes / 1000;
                    return {
                        label: item.filename,
                        secondary: `${sizeKb} kb`,
                    };
                },
                getRootProps() {
                    return {
                        onClick: handleButtonClick,
                    };
                },
                getInputProps() {
                    return {
                        ref: fileInputRef,
                        type: 'file',
                        multiple: false,
                        accept: '.csv',
                        onChange: handleFileChange,
                        value: fileValue,
                        display: 'none',
                    };
                },
                getButtonProps() {
                    return {
                        onClick: handleButtonClick,
                        isLoading,
                        isDisabled: isLoading,
                        children: isLoading ? 'Uploading' : undefined,
                    };
                },
            };
        },
    };
}
