import { capitalize, chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { getReportAccessStatus } from '../../../../app';
import { ReportDetailController } from './reportDetailnterface';
import { ReportDetailViewProps } from './reportDetailProps';
import {
    buildMetricItemProps,
    ReportDetailMetricItemProps,
    ReportDetailMetricListProps,
} from './metric';
import { ReportDetailConfig } from './reportDetailConfig';
import {
    buildOpportunityItemProps,
    ReportDetailOpportunityItemProps,
    ReportDetailOpportunityListProps,
} from './opportunity';
import { ReportDetailStatusProps } from './status';
import { buildReportRootProps } from './root';
import { Property } from 'src/v2/domain/attributes';

export function createReportDetailController(
    config: Pick<ReportDetailConfig, 'infra' | 'strategy'>
): ReportDetailController {
    const {
        infra: { formatter },
        strategy,
    } = config;
    return {
        useProps(context, data, props): ReportDetailViewProps {
            throw new Error('not impl');
            const metrics = useMemo(() => {
                return chain(data.item.data?.metrics ?? [])
                    .filter(
                        (item) =>
                            item.connection.status === 'ready' ||
                            item.connection.status === 'disconnected'
                    )
                    .value();
            }, [data.item.data?.metrics]);

            const metricPropsByMetric = useMemo(() => {
                return (
                    metrics.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.definition.id]: buildMetricItemProps(formatter, item),
                        }),
                        {} as Record<string, ReportDetailMetricItemProps | undefined>
                    ) ?? {}
                );
            }, [metrics]);

            // const opportunityPropsByInsight = useMemo(() => {
            //     return (
            //         data.item.data?.opportunities.reduce((acc, item) => {
            //             return {
            //                 ...acc,
            //                 [item.insight.id]: buildOpportunityItemProps(formatter, item),
            //             };
            //         }, {} as Record<string, ReportDetailOpportunityItemProps | undefined>) ??
            //         {}
            //     );
            // }, [metrics]);

            const propertiesByView = useMemo(() => {
                return (
                    data.item.data?.views.reduce(
                        (acc, view) => ({
                            ...acc,
                            [view.id]: view.columns.reduce(
                                (acc, property) => ({ ...acc, [property.key]: property }),
                                {}
                            ),
                        }),
                        {} as Record<
                            string,
                            Record<string, Property | undefined> | undefined
                        >
                    ) ?? {}
                );
            }, [data.item.data?.views]);

            const statusProps = useMemo((): ReportDetailStatusProps | null => {
                if (!data.item.data) {
                    return null;
                }

                const status = getReportAccessStatus({
                    // @ts-expect-error
                    report: data.item.data.report,
                    connections: data.item.data.connections,
                });

                if (metrics.length === 0) {
                    return {
                        status: 'warning',
                        label: 'Report data is not available',
                    };
                }
                // @ts-expect-error
                if (status === 'ready') {
                    return null;
                }

                return {
                    status: 'warning',
                    label: 'Data for this report is still syncing',
                };
            }, [metrics]);

            const rootProps = useMemo(() => {
                if (!data.item.data) {
                    return null;
                }
                return buildReportRootProps(formatter, data.item.data);
            }, [data.item.data]);

            const opportunityListProps = useMemo<ReportDetailOpportunityListProps>(() => {
                return {
                    items: chain(data.item.data?.opportunities ?? []).value(),
                    // getItemProps(item) {
                    //     const itemProps = opportunityPropsByInsight[item.insight.id];
                    //     assert(itemProps, `item props for ${item.insight.id} not found`);
                    //     return itemProps;
                    // },
                };
            }, [
                data.item.data?.opportunities,
                // opportunityPropsByInsight
            ]);

            if (!rootProps || !data.item.data || data.status !== 'success') {
                return {
                    item: null,
                    status: 'loading',
                };
            }

            return {
                // @ts-expect-error
                item: data.item.data,
                status: 'success', // @ts-expect-error
                getRootProps() {
                    return rootProps;
                },
                getStatusProps() {
                    return statusProps;
                },
                getOpportunityListProps() {
                    return opportunityListProps;
                },
                getMetricListProps(): ReportDetailMetricListProps {
                    return {
                        items: metrics,
                        getItemProps(item): ReportDetailMetricItemProps {
                            const itemProps = metricPropsByMetric[item.definition.id];
                            assert(
                                itemProps,
                                `item props for ${item.definition.id} not found`
                            );
                            return itemProps;
                        },
                        getPeerGroupProps(item) {
                            return {
                                item: {
                                    group: item.peergroup,
                                    plugin: item.plugin,
                                    traits: item.traits,
                                },
                            };
                        },
                        getScalarProps(item) {
                            return {
                                item: {
                                    metric: item.definition,
                                    calculation: item.summary,
                                    plugin: item.plugin,
                                },
                            };
                        },
                        getRangeProps(item) {
                            const properties = propertiesByView[item.view.id] ?? {};
                            return {
                                item: {
                                    properties,
                                    metric: item.definition,
                                    calculation: item.summary,
                                    plugin: item.plugin,
                                    ranking: item.configuration.ranking,
                                },
                            };
                        },
                        getMovementProps(item, segment) {
                            return {
                                item: {
                                    metric: item.definition,
                                    calculation: item.summary,
                                    plugin: item.plugin,
                                },
                                segment,
                            };
                        },
                    };
                },
            };
        },
    };
}
