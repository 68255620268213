import { VisualizationControllerConfig, VisualizationLoaderConfig } from '../base';
import {
    VisualizationChartConfig,
    VisualizationChartInit,
} from './visualizationChartConfig';
import { createVisualizationChartController } from './visualizationChartController';
import {
    VisualizationChartEnhancer,
    VisualizationChartMacro,
} from './visualizationChartInterface';
import { createVisualizationChartLoader } from './visualizationChartLoader';

export function createVisualizationChartMacro(
    config: VisualizationChartInit,
    enhancer?: VisualizationChartEnhancer
): VisualizationChartMacro {
    return _create(
        {
            ...config,
            provider: { createChartController: createVisualizationChartController },
        },
        enhancer
    );
}

function _create(
    config: VisualizationChartConfig,
    enhancer?: VisualizationChartEnhancer
) {
    if (enhancer) {
        return enhancer(_create)(config);
    }

    const loaderConfig: VisualizationLoaderConfig = {
        repository: config.repository,
    };

    const controllerConfig: VisualizationControllerConfig = {};

    const loader = createVisualizationChartLoader(loaderConfig);
    const controller = config.provider.createChartController(controllerConfig);

    return { loader, controller };
}
