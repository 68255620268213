import { symmetricDifference } from '../../../util';
import { buildCreateInvitationBulkProps, buildPeerSetCreateProps } from './create';
import { PeerSetServiceConfig } from './peersetConfig';
import { PeerSetService } from './peersetInterface';
import { buildPeerSetUpdateInvitationBulkProps, buildPeerSetUpdateProps } from './update';

export function createPeerSetService(config: PeerSetServiceConfig): PeerSetService {
    const {
        adapter: { company },
    } = config;
    const MIN_COMPANY_COUNT = 5;
    const MAX_COMPANY_COUNT = 25;
    const MIN_COMPANY_DIFF = 3;
    return {
        async create(context, props) {
            console.log('creating group', props);
            // TODO move these invariants to the server
            if (props.companies.length < MIN_COMPANY_COUNT) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                throw new Error(
                    `A competitive set must include at least ${MIN_COMPANY_COUNT} companies`
                );
            }

            if (props.companies.length > MAX_COMPANY_COUNT) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                throw new Error(
                    `A competitive set must include at most ${MAX_COMPANY_COUNT} companies`
                );
            }

            const companies = await company.findById(context, {
                ids: props.companies,
            });

            const peersetProps = buildPeerSetCreateProps({ companies }, props);
            const peerset = await props.createPeerset(peersetProps);
            const invitationProps = buildCreateInvitationBulkProps({
                peerset,
                companies,
            });
            console.log('DEBUG peersetProps', peersetProps);
            console.log('DEBUG invitationProps', invitationProps);
            await props.createInvitations(invitationProps);
            return peerset;
        },
        async update(context, item, props) {
            console.log('updating group', props);
            // TODO move these invariants to the server

            const memberCompanies = await config.adapter.company.findByAsset(context, {
                assets: item.peerset.members.map((item) => item.asset.id),
            });

            const companies = await company.findById(context, {
                ids: props.companies ?? [],
            });

            const beforeCompanies = new Set([
                ...item.invitations.map((item) => item.company.id),
                ...memberCompanies.map((item) => item.id),
            ]);

            const afterCompanies = new Set(props.companies);

            const difference = symmetricDifference(beforeCompanies, afterCompanies);

            if (props.companies ? props.companies.length < MIN_COMPANY_COUNT : false) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                throw new Error(
                    `A group must include at least ${MIN_COMPANY_COUNT} companies`
                );
            }

            if (props.companies ? props.companies.length > MAX_COMPANY_COUNT : false) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                throw new Error(
                    `A group must include at most ${MAX_COMPANY_COUNT} companies`
                );
            }

            if (
                props.companies &&
                difference.size > 0 &&
                difference.size < MIN_COMPANY_DIFF
            ) {
                await new Promise((resolve) => setTimeout(resolve, 500));
                throw new Error(
                    `To gurantee privacy, at least ${MIN_COMPANY_DIFF} companies must be changed at once`
                );
            }

            const peersetProps = buildPeerSetUpdateProps({ ...item, companies }, props);
            const invitationProps = buildPeerSetUpdateInvitationBulkProps(
                {
                    ...item,
                    companies,
                },
                props
            );

            console.log('DEBUG peersetProps', peersetProps);
            console.log('DEBUG invitationProps', invitationProps);
            const peerset = await props.updatePeerset(peersetProps);
            await props.updateInvitations(invitationProps);
            return peerset;
        },
    };
}
