import React, { useCallback, useEffect } from 'react';
import {
    useWorkspaceApi,
    useDashboardApi,
    useControlApi,
    useQueryApi,
} from '../../../app/dashboard';
import { FilterListViewProps, FilterItemViewProps } from '../../../view/dashboard/filter';
import { FilterItemContainerProps } from './filterContainerProps';

export interface FilterItemController {
    (props: Omit<FilterItemContainerProps, 'as' | 'useController'>): FilterItemViewProps;
}

export interface FilterListController {
    (): Omit<FilterListViewProps, 'renderFilter'>;
}

export const useFilterListController = (): Omit<FilterListViewProps, 'renderFilter'> => {
    const api = useDashboardApi();
    return {
        // timestamp: api.getDateConfiguration()?.filter ?? null,
        filters: api.getFilters(),
    };
};

export const useFilterItemController: FilterItemController = (props) => {
    const api = {
        workspace: useWorkspaceApi(),
        query: useQueryApi(),
        control: useControlApi(),
    };
    const current = api.control.getDataFilterValue(props.filter);
    return {
        filter: props.filter,
        index: props.index,
        current: current ? { value: current.value } : { value: null },
        isDisabled: false,
        onApply: (values) => {
            api.control.setDataFilterValue(props.filter, {
                key: props.filter.property.key,
                operator: props.filter.default?.operator ?? 'in',
                ...values,
            });
        },
        onClear() {
            api.control.clearDataFilterValue(props.filter);
        },
        getTriggerProps() {
            return {};
        },
    };
};
