import { assert } from '../../../util/assert';
import { ConversationWorkflowAdapter } from '../../../app/assistant';
import { WorkflowImplConfig } from './workflowImplConfig';
import { toWorkflowEntity } from './workflowImplSerdes';

export function createWorkflowImpl(
    config: WorkflowImplConfig
): ConversationWorkflowAdapter {
    const {
        api: { assistant: api },
    } = config;

    return {
        async find(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.workflowList({
                headers: {
                    Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                },
            });
            return response.data.data.map(toWorkflowEntity);
        },
        async lookup(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.workflowRetrieve(
                {
                    workflowId: props.id,
                },
                {
                    headers: {
                        Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                    },
                }
            );
            return toWorkflowEntity(response.data);
        },
    };
}
