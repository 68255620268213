import { z } from 'zod';

export const CountKindSchema = z.union([
    z.literal('data_ingestion.dataset_member'),
    z.literal('traits.group_member'),
]);

export const CountSchema = z.object({
    kind: CountKindSchema,
    object_id: z.string(),
    value: z.number(),
});

export const CountListSchema = z.object({
    data: z.array(CountSchema),
});
