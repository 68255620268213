import { observer } from 'mobx-react';
import React from 'react';
import { InsightContainerProps, InsightViewProps } from './insightProps';
import { InsightModuleConfig } from './insightConfig';
import { InsightController } from './insightInterface';
import { createInsightLoader } from './insightLoader';

export const createInsightContainer = (
    config: InsightModuleConfig['deps'],
    controller: InsightController,
    Component: React.FC<{ children?: React.ReactNode | undefined } & InsightViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & InsightContainerProps> => {
    const {
        hook: { useOrganization, useWorkspace, useFormatter, useAuth },
    } = config;

    const loader = createInsightLoader(config);
    return observer((props) => {
        const workspace = useWorkspace();
        const organization = useOrganization();
        const auth = useAuth();
        const formatter = useFormatter();

        const viewData = loader.useData(
            {
                organization,
                workspace,
                auth,
            },
            { query: {} }
        );

        const viewProps = controller.useProps(
            {
                organization,
                workspace,
                auth,
            },
            formatter,
            viewData,
            {}
        );

        return <Component {...viewProps} />;
    });
};
