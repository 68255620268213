import { RegistrationContextConfig } from './registrationBaseConfig';
import { RegistrationContextProvider } from './registrationBaseInterface';

export function createRegistrationContextProvider(
    config: RegistrationContextConfig
): RegistrationContextProvider {
    const { hook } = config;
    return {
        useContext() {
            return {};
        },
    };
}
