import { useQuery } from '@tanstack/react-query';
import { BillingPlanRepository } from './planInterface';

import { PlanConfig } from './planConfig';

export function createSubscriptionPlanRepository(
    config: PlanConfig
): BillingPlanRepository {
    const { adapter } = config;
    return {
        useList(context, query, options) {
            const data = useQuery({
                ...options,
                queryKey: ['v2', 'billing', 'plan', query],
                async queryFn() {
                    const response1 = await adapter.find(context, { version: 1 });
                    const response2 = await adapter.find(context, { version: 2 });
                    const response3 = await adapter.find(context, { version: 3 });
                    const merged = [...response1, ...response2, ...response3];
                    return {
                        items: merged,
                        total: merged.length,
                        limit: merged.length,
                    };
                },
                refetchOnWindowFocus: false,
            });

            return data;
            // return collectionFromQuery(data, { keepData: false });
        },
    };
}
