import React, { useState, useEffect, ChangeEvent, ReactNode } from 'react';
import {
    VStack,
    Box,
    Icon,
    Text,
    Grid,
    GridItem,
    HStack,
    Image,
    SimpleGrid,
    IconButton,
    StackDivider,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
} from '@chakra-ui/react';
import { assertNever } from '../../../../../../util';
import { SettingsBillingViewConfig } from '../../base';
import {
    PaymentMethodDetailViewProps,
    PaymenyMethodItemCardProps,
    PaymenyMethodItemCustomProps,
    PaymenyMethodItemProps,
} from './paymentMethodDetailProps';
import { FiCreditCard } from 'react-icons/fi';
import { AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';

export function createPaymentMethodDetailView(
    config: SettingsBillingViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & PaymentMethodDetailViewProps> {
    const {
        settings: {
            UI: {
                Application: { Anchor, Link, Heading, Button },
            },
            Layout: {
                Container,
                Content,
                Panel,
                Form,
                Header: {
                    Action: { List: ActionList },
                    Container: Header,
                    Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                },
            },
        },
    } = config;

    const PaymentMethodCardItem: React.FC<
        { children?: React.ReactNode | undefined } & PaymenyMethodItemCardProps
    > = (props) => {
        return (
            <HStack
                w="full"
                borderWidth={2}
                borderColor="whiteAlpha.300"
                borderRadius="lg"
                p={6}
                spacing={4}
            >
                <Box
                    // borderWidth={1}
                    // borderColor="whiteAlpha.200"
                    // bg="whiteAlpha.100"
                    borderRadius="md"
                    // px={3}
                >
                    {props.brand && <Image h={12} {...props.brand} />}
                    {!props.brand && <Icon h={5} w={5} as={FiCreditCard} />}
                </Box>
                <VStack align="start" spacing={0}>
                    <HStack>
                        <Text color="whiteAlpha.800">{props.number}</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize="sm" fontWeight="medium" color="whiteAlpha.600">
                            {props.expires}
                        </Text>
                    </HStack>
                </VStack>
            </HStack>
        );
    };

    const PaymentMethodCustomItem: React.FC<
        { children?: React.ReactNode | undefined } & PaymenyMethodItemCustomProps
    > = (props) => {
        return (
            <HStack
                w="full"
                borderWidth={1}
                borderColor="whiteAlpha.300"
                borderRadius="lg"
                p={6}
                spacing={4}
            >
                <Icon h={5} w={5} as={FiCreditCard} />
                <VStack align="start" spacing={1}>
                    <HStack>
                        <Text color="whiteAlpha.800">{props.type}</Text>
                    </HStack>
                </VStack>
            </HStack>
        );
    };

    const PaymentMethodItem: React.FC<
        { children?: React.ReactNode | undefined } & PaymenyMethodItemProps
    > = (props) => {
        if (props.kind === 'card') {
            return <PaymentMethodCardItem {...props} />;
        }
        if (props.kind === 'custom') {
            return <PaymentMethodCustomItem {...props} />;
        }
        assertNever(props);
    };

    return (props) => {
        const statusProps = props.page.getStatusProps();
        if (statusProps?.isDisabled) {
            return (
                <Container>
                    <Header description={<Text>Manage billing and payment methods</Text>}>
                        <BreadcrumbsList>
                            <BreadcrumbsItem>Payment</BreadcrumbsItem>
                        </BreadcrumbsList>
                    </Header>
                    <Content>
                        <Alert status="warning">
                            <AlertIcon />
                            <AlertTitle>{statusProps.description}</AlertTitle>
                        </Alert>
                    </Content>
                </Container>
            );
        }
        return (
            <Container>
                <Header description={<Text>Manage billing and payment methods</Text>}>
                    <BreadcrumbsList>
                        <BreadcrumbsItem>Payment</BreadcrumbsItem>
                    </BreadcrumbsList>
                    {/* <ActionList>
                        <HStack w="full" color="blue.400">
                            <Text fontWeight="medium">View in Stripe</Text>
                            <Icon fontSize="sm" as={FaArrowRight} />
                        </HStack>
                    </ActionList> */}
                </Header>
                <Content>
                    <VStack
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderColor="whiteAlpha.200" borderWidth={1} />
                        }
                    >
                        <VStack w="full" align="start" spacing={4}>
                            <Text fontSize="lg" fontWeight="semibold">
                                Payment methods
                            </Text>
                            {props.methods.length === 0 && (
                                <Alert status="info">
                                    <AlertIcon />
                                    <AlertTitle>
                                        An active payment method is required for a paid
                                        subscription
                                    </AlertTitle>
                                </Alert>
                            )}
                            <SimpleGrid columns={{ base: 1, xl: 2 }} w="full" gap={4}>
                                {props.methods.map((item) => (
                                    <PaymentMethodItem key={item.id} {...item} />
                                ))}
                                <Link style={{ height: '100%' }} {...props.link.new}>
                                    <HStack
                                        w="full"
                                        h="full"
                                        borderWidth={2}
                                        borderColor="whiteAlpha.300"
                                        borderStyle="dashed"
                                        borderRadius="lg"
                                        p={6}
                                        spacing={3}
                                        cursor="pointer"
                                        fontWeight="medium"
                                        color="whiteAlpha.800"
                                        _hover={{ bg: 'whiteAlpha.100' }}
                                    >
                                        <Icon as={AiOutlinePlus} />
                                        <Text>Add payment method</Text>
                                    </HStack>
                                </Link>
                            </SimpleGrid>
                        </VStack>
                    </VStack>
                </Content>
            </Container>
        );
    };
}
