import { useQuery, useQueryClient } from '@tanstack/react-query';
import { assertNever } from '../../../util';
import { normalizeCollection } from '../../../base';
import { CompanyAdapter } from './companyAdapter';
import { isCompanyAssetQuery, isCompanyIdQuery } from './companyGuard';
import { CompanyRepository } from './companyInterface';
import { CompanyFindQuery, CompanyLookupQuery } from './companyQuery';
import { CompanyEntity } from './companyEntity';

export function createCompanyRepository(adapter: CompanyAdapter): CompanyRepository {
    const PREFIX = ['companies', 'company'];

    function getFindKey(query: CompanyFindQuery) {
        return [...PREFIX, 'find', query];
    }

    function getLookupKey(query: CompanyLookupQuery) {
        return [...PREFIX, 'lookup', { id: query.id }];
    }

    return {
        useSearch(context, props, options) {
            const client = useQueryClient();
            const query = useQuery({
                queryKey: [...PREFIX, 'search', props],
                async queryFn() {
                    // console.log('DEBUG fetching', props.term);
                    const response = await adapter.search(context, props);
                    return normalizeCollection(response);
                },
                staleTime: Infinity,
                cacheTime: 0,
                retry: false,
                ...options,
                onSuccess(data) {
                    for (const id of data.ids) {
                        const queryKey = getLookupKey({ id });
                        const entry = data.byId[id];
                        client.setQueryData(queryKey, entry);
                    }
                },
            });
            return query;
        },
        useFind(context, props, options) {
            const client = useQueryClient();
            const query = useQuery({
                queryKey: getFindKey(props),
                async queryFn() {
                    if (isCompanyAssetQuery(props)) {
                        const response = await adapter.findByAsset(context, props);
                        return normalizeCollection(response);
                    }
                    if (isCompanyIdQuery(props)) {
                        const response = await adapter.findById(context, props);
                        return normalizeCollection(response);
                    }
                    assertNever(props);
                },
                staleTime: Infinity,
                retry: false,
                ...options,
                onSuccess(data) {
                    for (const id of data.ids) {
                        const queryKey = getLookupKey({ id });
                        const entry = data.byId[id];
                        client.setQueryData(queryKey, entry);
                    }
                },
            });
            return query;
        },
        useMultiLookup(context, props, options) {
            const client = useQueryClient();

            const query = useQuery({
                queryKey: [...PREFIX, 'multi', 'lookup', ...props],
                async queryFn() {
                    const response = await adapter.findById(context, {
                        ids: props.map((item) => item.id),
                    });
                    return response;
                },
                staleTime: Infinity,
                retry: false,
                ...options,
                onSuccess(data) {
                    for (const entry of data) {
                        const queryKey = getLookupKey({ id: entry.id });
                        client.setQueryData(queryKey, entry);
                    }
                },
            });
            return query;
        },
        useLookupCache(context, props) {
            const queryClient = useQueryClient();
            const items = props.flatMap((item) => {
                const entry = queryClient.getQueryData(
                    getLookupKey({
                        id: item.id,
                    })
                );
                return entry ? [entry as CompanyEntity] : [];
            });
            return items;
        },
    };
}
