import { useEffect } from 'react';
import { AccessResponse, ApplicationContext } from '../../base';
import { AnyTrackingEvent } from './trackEvent';

/**
 * Dispatches a track event once when access attempt is restricted
 * @param context
 * @param attempt
 * @param event
 */
export function useTrackOnce<TEvent extends AnyTrackingEvent>(
    context: Pick<ApplicationContext, 'tracker'>,
    attempt: AccessResponse | null,
    event: TEvent | null
) {
    useEffect(() => {
        if (attempt?.restricted && event) {
            context.tracker.track(event.type, event.payload);
        }
    }, [attempt?.restricted && attempt.request?.id, event?.type]);
}
