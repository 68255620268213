import { AxiosInstance } from 'axios';
import moment from 'moment';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingPortalResource } from './portalInterface';
import { BillingPortalDtoSchema } from './portalSchema';

export function createBillingPortalResource(
    client: AxiosInstance
): BillingPortalResource {
    return {
        async create(context, payload) {
            let headers: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                throw new Error('not impl');
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(`/api/v1/billing/portal`, payload, {
                headers,
            });

            const parsed = BillingPortalDtoSchema.parse(response.data);

            return parsed;
        },
    };
}
