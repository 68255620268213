import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../../../stores/setupContext';
import { AccountProfile } from '../../../domain/accounts';
import { OrganizationScope } from '../../platform';
import { AccountProfileAdapter } from './profileAdapter';
import { AccountProfileRepository } from './profileInterface';

export function createAccountProfileRepository(
    adapter: AccountProfileAdapter
): AccountProfileRepository {
    const PREFIX = ['account-profile'];
    function getPrefix(context: Pick<OrganizationScope, 'auth'>) {
        return [...PREFIX];
    }
    return {
        useLookup(context, options) {
            const store = useStore();
            const result = useQuery({
                queryKey: [...getPrefix(context)],
                async queryFn() {
                    const { user } = await store.auth.getMyAccount();
                    const [organization] = user?.organizations ?? [null];
                    const mapped: AccountProfile = {
                        firstname: user?.first_name ?? null,
                        lastname: user?.last_name ?? null,
                        organizationName: organization?.organization?.name ?? null,
                        organizationType:
                            organization?.organization?.company_type ??
                            'ecommerce_website',
                        domain: organization?.organization?.domain ?? null,
                        role: organization?.role ?? 'standard',
                        finishedOnboarding:
                            organization?.organization?.finished_on_boarding ?? false,
                    };
                    return mapped;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return result;
            // return lookupFromQuery(result);
        },
        useCreate(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    const response = await adapter.put(context, props);
                    return response;
                },
                onSuccess(data, variables, context) {
                    client.refetchQueries(PREFIX);
                },
            });
        },
        useUpdate(context) {
            const lookup = this.useLookup(context);
            const client = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    if (lookup.status !== 'success') {
                        throw new Error('not loaded');
                    }
                    const response = await adapter.put(context, {
                        ...lookup.data,
                        ...props,
                    });
                    return response;
                },
                onSuccess(data, variables, context) {
                    client.refetchQueries(PREFIX);
                },
            });
        },
    };
}
