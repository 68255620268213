import { HomeContext } from '../models/Context';
import { ContextType } from '../models/Account';
import { Stores } from './createStore';

export function assignStores<T extends object>(obj: RootStore<T>, stores: T) {
    const combined = Object.keys(stores).reduce((acc: any, item: string) => {
        return {
            ...acc,
            // @ts-ignore
            [item]: stores[item].init && stores[item].init(obj),
        };
    }, {});
    Object.assign(obj, { ...combined });
    Object.values(stores).forEach((x) => x.start && x.start());
}

export class RootStore<T extends object> {
    constructor(stores: T) {
        assignStores(this, stores);
    }
}

export interface WithReset<T> {
    reset(stores?: T): void;
    onContextChanged(
        contextName: ContextType,
        ctx?: HomeContext | null,
        stores?: Partial<Stores>
    ): Promise<void>;
}

export interface StoresWithReset extends Stores {
    reset(stores?: Partial<Stores>): void;
    onContextChanged(
        contextName: ContextType,
        ctx?: HomeContext | null,
        stores?: Partial<Stores>
    ): Promise<void>;
}

// export abstract class StoresWithReset<T extends object = {}> implements Stores, WithReset<T> {
//   abstract reset(stores?: T): void;
// }
