import { PageIds } from '../../../../config';
import { StudiesSubmissionDetailRouteConfig } from './studiesSubmissionDetailConfig';
import { StudiesSubmissionDetailController } from './studiesSubmissionDetailInterface';

export function createStudiesSubmissionDetailController(
    config: Pick<StudiesSubmissionDetailRouteConfig, 'controller'>
): StudiesSubmissionDetailController {
    const {
        controller: {
            page: pageController,
            study: { submission: submissionController },
        },
    } = config;
    return {
        useProps(context, item) {
            const pageProps = pageController.useProps(context, {
                item: { id: PageIds.STUDIES_SUBMISSION_DETAIL, breadcrumbs: [] },
            });
            const itemProps = submissionController.useProps(context, item.submission);
            return {
                page: pageProps,
                item: itemProps,
            };
        },
    };
}
