import { ApplicationLinks } from '../../../config';
import {
    PeerLoaderConfig,
    PeerControllerConfig,
    PeerRouteConfig,
    createCompanyContextProvider,
} from '../base';
import { PeerCompanyEditConfig, PeerCompanyEditInit } from './companyListBoxConfig';
import { CompanyListBoxMacro } from './companyListBoxInterface';
import {
    createCompanyListBoxMatchContainer,
    createCompanyListBoxMatchController,
    createCompanyListBoxMatchLoader,
    peerCompanySearchStateProvider,
} from './match';
import {
    createCompanyListBoxSelectContainer,
    createCompanyListBoxSelectController,
    createCompanyListBoxSelectLoader,
} from './select';
import { createCompanyListBoxController } from './companyListBoxController';
import { createCompanyListBoxLoader } from './companyListBoxLoader';

export function createCompanyListBox(config: PeerCompanyEditConfig): CompanyListBoxMacro {
    const { provider } = config;
    const containerConfig: PeerRouteConfig = {
        infra: config.infra,
        context: createCompanyContextProvider({
            hook: config.hooks,
        }),
    };
    const loaderConfig: PeerLoaderConfig = {
        repository: config.repository,
    };
    const controllerConfig: PeerControllerConfig = {
        controller: config.controller,
        anchor: {
            competitiveSet: {
                documentation: ApplicationLinks.Documentation.CompetitiveSet,
            },
        },
        provider: config.deps,
    };
    return {
        controller: createCompanyListBoxController(controllerConfig),
        loader: createCompanyListBoxLoader(loaderConfig),
        Component: {
            Match: createCompanyListBoxMatchContainer(
                { ...containerConfig, state: peerCompanySearchStateProvider() },
                createCompanyListBoxMatchLoader(loaderConfig),
                provider.createPeerCompanySearchController(controllerConfig)
            ),
            Select: createCompanyListBoxSelectContainer(
                containerConfig,
                createCompanyListBoxSelectLoader(loaderConfig),
                createCompanyListBoxSelectController(controllerConfig)
            ),
        },
    };
}
