import { useLocalStorage } from 'react-use';
import React, { useEffect } from 'react';
import { CardSpinner } from '../../../domain';
import { ApiContext } from '../../api';
import { LocalStorageKeys } from '../../config';
import { BootstrappedContextProvider } from '../../base';
import { OrganizationScope } from '../../app';
import { OrganizationContext, useOrganizationContextV2 } from './organizationContext';
import { OrganizationContextAggregate } from './organizationModel';
import { OrganizationContextConfig } from './organizationConfig';

export function createOrganizationProviderV2(
    config: OrganizationContextConfig
): BootstrappedContextProvider<OrganizationContextAggregate> {
    const {
        deps: { hook },
        provider: { createLoader },
    } = config;
    const loader = createLoader(config);
    return {
        Provider(props) {
            const auth = hook.useAuth();
            const organization = hook.useOrganization();

            const [localOrganizationId, setLocalOrganizationId] = useLocalStorage(
                LocalStorageKeys.ORGANIZATION_ID
            );

            useEffect(() => {
                if (organization.id !== localOrganizationId) {
                    // TODO encapsulate local organization state properly
                    setLocalOrganizationId(organization.id);
                    console.info('organization changed, resetting local state...');
                    window.localStorage.removeItem(LocalStorageKeys.ONBOARDING_STATE);
                    window.localStorage.removeItem(LocalStorageKeys.HOME_HIDDEN);
                }
            }, [organization.id, localOrganizationId]);

            const context: OrganizationScope = { auth, organization };
            const value = loader.useLoad(context);
            if (value.status === 'loading') {
                return <CardSpinner />;
            }
            return (
                <OrganizationContext.Provider value={value.data}>
                    {props.children}
                </OrganizationContext.Provider>
            );
        },
        useContext() {
            return useOrganizationContextV2();
            // throw new Error(`not impl`);
        },
    };
}
