import { assert } from '../../../../../../util/assert';
import { SettingMappingNewService } from './mappingNewInterface';

export function createMappingNewService(): SettingMappingNewService {
    return {
        async create(context, item, props) {
            const mappable = item.mappables.find(
                (candidate) => candidate.id === props.mappable.id
            );
            assert(mappable, `mappable '${props.mappable.id}' not found in local state`);
            const result = await props.mutation.mutateAsync({
                asset: props.asset,
                mappable,
            });
            return;
        },
    };
}
