import qs from 'querystring';
import {
    action,
    autorun,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';

import StoreBase from './StoreBase';
import { fetchAppStore, fetchGooglePlay } from '../api/mobileapps';
import { MobileApp } from '../models/MobileApp';

class MobileAppsStore extends StoreBase {
    isLoading: boolean = false;
    currentMobileApp: MobileApp | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            currentMobileApp: observable,

            // Actions
            setLoading: action,
            start: action,
            setApp: action,
            fetchFromGooglePlay: action,
            fetchFromAppleAppStore: action,
        });
    }

    start = async () => {
        await this.rootStore.auth.waitAuthenticated();
    };

    setLoading(flag: boolean) {
        this.isLoading = flag;
        this.err = null;
    }

    setApp(app: MobileApp | null) {
        this.currentMobileApp = app;
    }

    fetchFromGooglePlay = async (packageName: string) => {
        if (!packageName || packageName.length < 6) {
            return;
        }
        this.setLoading(true);
        try {
            const { data } = await fetchGooglePlay(packageName);
            this.setApp(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.fetchFromGooglePlay(packageName);
            } else {
                this.setErrType('serverErr');
                this.setApp(null);
            }
        } finally {
            this.setLoading(false);
        }
    };

    fetchFromAppleAppStore = async (bundleId: string) => {
        if (!bundleId || bundleId.length < 6) {
            return;
        }
        this.setLoading(true);
        try {
            const { data } = await fetchAppStore(bundleId);
            this.setApp(data);
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.fetchFromAppleAppStore(bundleId);
            } else {
                this.setErrType('serverErr');
                this.setApp(null);
            }
        } finally {
            this.setLoading(false);
        }
    };
}

export default MobileAppsStore;
