import { useMemo, useState } from 'react';
import { keyBySafe } from '../../../util';
import { assert } from '../../../util/assert';
import { createListBoxListController } from './list/listBoxListController';
import { ListBoxConfig } from './listBoxConfig';
import { ListBoxController } from './listBoxInterface';
import { ListBoxViewProps } from './listBoxProps';

export function createListBoxController<TItem>(
    config: ListBoxConfig<TItem>
): ListBoxController<TItem> {
    const { getValue } = config;
    const { useProps: useListProps } = createListBoxListController(config);
    return {
        useProps(item, props): ListBoxViewProps<TItem> {
            const values = useMemo(() => new Set(props.value), [props.value]);

            const itemPropsByValue = useMemo(
                () => keyBySafe(item.items, (item) => getValue(item)),
                [item.items]
            );

            const items = useMemo(() => {
                const selected: TItem[] = props.value.map((element) => {
                    const found = itemPropsByValue[element];
                    assert(found, 'value not ofund');
                    return found;
                });

                const unselected: TItem[] = [];
                for (const option of item.items) {
                    const optionValue = getValue(option);
                    if (!values.has(optionValue)) {
                        unselected.push(option);
                    }
                }

                return { selected, unselected };
            }, [item.items, values]);

            const selected = useListProps(
                { options: items.selected },
                {
                    cache: values,
                    value: props.value,
                    onToggle(value) {
                        props.onChange(
                            props.value.filter((candidate) => candidate !== value)
                        );
                    },
                }
            );
            const unselected = useListProps(
                { options: items.unselected },
                {
                    cache: values,
                    value: props.value,
                    onToggle(value) {
                        props.onChange([
                            // newly selected values should be on-top
                            value,
                            ...props.value,
                        ]);
                    },
                }
            );

            return {
                selected,
                unselected,
                button: {
                    clear: {
                        onClick() {
                            props.onChange([]);
                        },
                    },
                },
            };
        },
    };
}
