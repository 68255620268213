import { buildIntegrationStatusProps } from '../../../../../view/integrations';
import { buildStatusProps } from '../../../../../view/common';
import { SettingsIntegrationControllerConfig } from '../base';
import { createSettingMappingNewController } from '../mapping';
import { SettingIntegrationDetailController } from './integrationDetailnterface';
import { SettingIntegrationDetailViewProps } from './integrationDetailProps';
import { useMemo } from 'react';

export function createSettingIntegrationDetailController(
    config: SettingsIntegrationControllerConfig
): SettingIntegrationDetailController {
    const {
        provider: { createModal },
        infra: { formatter },
    } = config;

    const controller = {
        modal: createModal({
            id: 'integration_detail_add_mapping_modal',
        }),
        mapping: createSettingMappingNewController(config),
    };

    return {
        useProps(context, item, props): SettingIntegrationDetailViewProps {
            const modalProps = controller.modal.useProps(props.modal);

            const statusProps = useMemo(
                () => buildIntegrationStatusProps(item.integration),
                [item.integration]
            );

            const mappingProps = controller.mapping.useProps(
                context,
                item.mapping,
                props.mapping
            );

            const timestamp = {
                createdAt: {
                    relative: formatter.timeago(item.integration.integration.createdAt),
                    absolute: formatter.date(item.integration.integration.createdAt, {
                        notation: 'long',
                    }),
                },
                syncedAt: item.integration.integration.lastSyncedFinishedAt
                    ? {
                          relative: formatter.timeago(
                              item.integration.integration.lastSyncedFinishedAt
                          ),
                          absolute: formatter.date(
                              item.integration.integration.lastSyncedFinishedAt,
                              { notation: 'long' }
                          ),
                      }
                    : null,
            };

            return {
                page: {
                    alert:
                        item.activeMappings.length === 0
                            ? { label: `Integration does not have any active mappings` }
                            : null,
                },
                mapping: {
                    new: mappingProps,
                    collection: {
                        getItemProps(mapping) {
                            return {
                                action: {
                                    delete: {
                                        id: 'delete',
                                        label: 'Delete',
                                        description: null,
                                        button: { onClick() {} },
                                    },
                                },
                            };
                        },
                    },
                },
                modal: modalProps,
                container: {
                    mapping: {
                        new: {
                            integration: item.integration.integration,
                        },
                    },
                },
                integration: {
                    status: buildStatusProps({
                        kind: statusProps.type ?? null,
                        label: statusProps.label,
                        value: statusProps.label,
                        description: (statusProps.tooltip?.label as string) ?? null,
                        reason: null,
                        ordinal: null,
                    }),
                    label: item.integration.integration.key ?? 'N/A',
                    createdAt: {
                        relative: timestamp.createdAt.relative,
                        description: `Integration was connected on ${timestamp.createdAt.absolute}`,
                    },
                    syncedAt: {
                        relative: timestamp.syncedAt?.relative ?? 'N/A',
                        description: timestamp.syncedAt
                            ? `Integration data was last synced ${timestamp.syncedAt.absolute}`
                            : null,
                    },
                },
                definition: {
                    label: item.integration.definition.name.toString(),
                    logo: {
                        src: item.integration.definition.iconUrl,
                    },
                },
                mappings: {
                    collection: item.integration.mappingsWithAssets.map((mapping) => ({
                        id: mapping.connection.id?.toString() ?? '',
                        asset: { label: mapping.asset?.name ?? 'N/A' },
                        entity: {
                            label: mapping.entity.name,
                            caption: mapping.entity.key,
                        },
                        status:
                            mapping.entity.status === 'valid'
                                ? buildStatusProps({
                                      kind: 'success',
                                      label: 'Active',
                                      value: 'active',
                                      description: `Account is active and data is connected`,
                                      ordinal: null,
                                  })
                                : buildStatusProps({
                                      kind: 'warning',
                                      label: 'Inactive',
                                      value: 'inactive',
                                      description:
                                          item.integration.definition.requirement?.text ??
                                          null,
                                      ordinal: null,
                                  }),
                        link: {
                            asset: mapping.asset
                                ? {
                                      to: `/u/assets/${mapping.asset.id}/settings/data-sources`,
                                  }
                                : null,
                        },
                    })),
                    empty:
                        item.integration.mappingsWithAssets.length > 0
                            ? null
                            : {
                                  title: 'No data sources',
                              },
                },
            };
        },
    };
}
