import { useDisclosure } from '@chakra-ui/react';
import { first, last } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { NetworkBaseRouteConfig } from '../../base';
import { NetworkRequestNewFormValuesSchema } from './networkRequestNewSchema';
import { NetworkRequestNewDepsProvider } from './networkRequestNewInterface';
import { NetworkRequestNewFormValues } from './networkRequestNewModel';
import { BUDGET_STEPS } from './networkRequestNewConstant';

export function createNetworkRequestNewDepsProvider(
    config: NetworkBaseRouteConfig
): NetworkRequestNewDepsProvider {
    const {
        infra: { toaster },
        repository: {},
    } = config;
    return {
        useDeps(context) {
            const form = useForm<NetworkRequestNewFormValues>({
                defaultValues: {
                    // expert_type: 'operator',
                    note: null,
                    metric: null,
                    threshold: null,
                    filters: [],
                    budget: [first(BUDGET_STEPS)!, last(BUDGET_STEPS)!],
                },
                resolver: zodResolver(NetworkRequestNewFormValuesSchema),
            });
            return {
                navigate: useNavigate(),
                toast: toaster.useToast(),
                form,
                formValues: form.watch(),
                metric: { disclosure: useDisclosure() },
            };
        },
    };
}
