import StoreBase from './StoreBase';
import { action, computed, makeObservable, observable } from 'mobx';
import { searchUser } from '../api/users';
import { User } from '../models/Account';
import { AxiosError } from 'axios';
import { ServerValidationError } from '../models/Common';

export class UsersStore extends StoreBase {
    users: Array<User> = [];
    isLoading: boolean = false;
    errorFields: Array<ServerValidationError> | null = null;
    searchTerm: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            users: observable,
            searchTerm: observable,
            // Computed
            // valuesByDefinitionId: computed,
            // Actions
            searchUsers: action,
            setLoading: action,

            // XHR -
            //   getInvoiceData: action,
            //   payWithCreditCard: action,
        });
    }

    setLoading(flag: boolean) {
        this.isLoading = flag;
    }

    setUsers(users: Array<User>) {
        this.users = users;
    }

    setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

    searchUsers = async (term: string, includeSelf: boolean = false) => {
        this.setSearchTerm(term);
        this.setLoading(true);
        try {
            const { data } = await searchUser(term);
            this.setUsers(
                includeSelf
                    ? data
                    : data.filter(
                          ({ id }) => !this.rootStore.auth.currentUser?.id || id !== this.rootStore.auth.currentUser.id
                      )
            );
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.searchUsers(term);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };
}
