import { useQuery } from '@tanstack/react-query';
import { collectionFromQuery } from '../../../base';
import { OrganizationScope } from '../../platform';
import { DestinationDefinitionAdapter } from './destinationAdapter';
import { DestinationDefinitionRepository } from './destinationInterface';

export function createDestinationRepository(
    adapter: DestinationDefinitionAdapter
): DestinationDefinitionRepository {
    function getPrefix(context: OrganizationScope) {
        return ['data-destination', context.organization];
    }
    return {
        useFind(context, query, options) {
            const result = useQuery({
                queryKey: [...getPrefix(context)],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                keepPreviousData: true,
                ...options,
            });
            return collectionFromQuery(result, { keepData: true });
        },
    };
}
