import { z } from 'zod';

export const EmailReportInputSchema = z.object({
    'api-key': z.string(),
    report: z.string(),
    organization: z.number({ coerce: true }),
    workspace: z.number({ coerce: true }),
    date: z.date({ coerce: true }),
    period: z.enum(['day', 'week', 'month', 'quarter', 'year']),
    granularity: z.optional(z.enum(['day', 'week', 'month'])),
});
