import { isClickAction } from './actionItemGuard';
import { ActionItemController } from './actionItemInterface';

export function createActionItemController(): ActionItemController {
    return {
        useProps(item, props) {
            return {
                id: item.id,
                label: item.name,
                description: item.description ?? null,
                colorScheme: item.kind === 'delete' ? 'red' : undefined,
                Icon: item.Icon ?? undefined,
                button: isClickAction(item)
                    ? item.button
                    : {
                          onClick() {
                              props.navigate(item.link.to);
                          },
                      },
            };
        },
    };
}
