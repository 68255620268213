import * as React from 'react';
import { Container, Heading, VStack } from '@chakra-ui/react';
import { Image, Text } from '@chakra-ui/react';
// import Logo from '../../icons/logo.png';
import Logo from '../../../../../svg/varos-logo-small-white.svg';

export function NoWorkspaceConfigured({}): React.ReactElement {
    return (
        <Container
            h="100vh"
            flexDir="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={4}>
                <Image src={Logo} width={300} />
                <Heading textAlign="center" fontSize="xx-large" fontWeight="bold">
                    We're setting up your account. the account will be available shortly
                </Heading>
                <Text>
                    Email <a href="mailto:yarden@varos.io">yarden@varos.io</a> if you need
                    anything in the meantime.
                </Text>
            </VStack>
        </Container>
    );
}
