import { DateFactory } from './dateInterface';

export function createDateFactory(): DateFactory {
    return {
        build(props) {
            return (
                props ?? {
                    period: {
                        amount: 4,
                        interval: 'week',
                    },
                    comparison: {
                        amount: 1,
                        interval: 'period',
                    },
                    granularity: 'week',
                }
            );
        },
    };
}
