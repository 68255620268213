import { z } from 'zod';

// model

export const IndustrySchema = z.object({
    key: z.string(),
    name: z.string(),
    companies_count: z.number(),
});

export const IndustryListSchema = z.object({
    items: z.array(IndustrySchema),
});

// requests

export const IndustryFindRequestSchema = z
    .object({
        search: z.string(),
        page_size: z.number().max(25),
    })
    .partial();
