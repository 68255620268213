import { assert } from '../../../../util/assert';
import { LineChartControllerProps } from '../../../../ui/visualization';
import { LineChartViewProps } from './lineProps';

export function buildLineChartViewProps(
    props: LineChartControllerProps
): LineChartViewProps {
    return {
        height: 250,
        width: 600,
        data: {
            labels: props.labels,
            datasets: props.series.map((series) => ({
                label: series.name,
                data: series.data.map((item) => item.value),
                colorScheme: series.colorScheme,
            })),
        },
        partial: [
            // props.labels[props.labels.length - 1]
        ],
        formatUnit(value) {
            return props.formatValue(value as number, 0);
        },
        formatLabel(value, options) {
            return props.formatRange(value, options);
        },
        getCaption(context, original) {
            const series = props.series[context.rowIndex];
            const value = series.data[context.columnIndex];
            return value.secondary
                ? props.formatTooltip(value.secondary)
                : value.value === null
                  ? 'N/A'
                  : props.formatValue(value.value, 0);
        },
    };
}
