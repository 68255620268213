import mediamixPreview1 from '../../../images/mediamix_preview_1.png';
import mediamixPreview2 from '../../../images/mediamix_preview_2.png';
import type { FeatureFixture } from '../featureFixture';

export const MEDIAMIX_HIGHLIGHTS: FeatureFixture[] = [
    {
        text: 'Know which channels to try next',
    },
    {
        text: 'See which channels & placements fit your budget',
    },
    {
        text: 'Manage your media mix in a data-driven way that your whole team can agree on',
    },
    {
        text: 'Get a full picture of current prices across channels in one place',
    },
];

export const MEDIAMIX_GRAPHICS: Array<{ src: string }> = [
    {
        src: mediamixPreview1,
    },
    {
        src: mediamixPreview2,
    },
];
