import { Alert, AlertTitle } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { SettingsBillingContainerConfig } from '../../base/billingBaseConfig';
import {
    PaymentMethodDetailController,
    PaymentMethodDetailLoader,
} from './paymentMethodDetailInterface';
import {
    PaymentMethodDetailContainerProps,
    PaymentMethodDetailViewProps,
} from './paymentMethodDetailProps';
import { PaymentMethodDetailUrlParamsSchema } from './paymentMethodDetailSchema';

export function createPaymentMethodDetailContainer(
    config: SettingsBillingContainerConfig,
    loader: PaymentMethodDetailLoader,
    controller: PaymentMethodDetailController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & PaymentMethodDetailViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & PaymentMethodDetailContainerProps
> {
    const {
        context: { useContext },
        infra: { options, toaster },
    } = config;
    return ({ children, ...containerProps }) => {
        const toast = toaster.useToast();
        const params = options.useOptions(PaymentMethodDetailUrlParamsSchema);

        useEffect(() => {
            if (params.redirect_status === 'succeeded') {
                toast({
                    kind: 'success',
                    description: 'Payment method added',
                });
            }
        }, [params.redirect_status]);
        const context = useContext();
        const data = loader.useLoad(context);
        const props = controller.useProps(context, data, {});

        return <View {...props}>{children}</View>;
    };
}
