import {
    TextProps,
    StackProps,
    VStack,
    HStack,
    TableCellProps,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { Tooltip } from '../../../../ui';

export interface TableVisualizationCellProps {
    containerProps: TableCellProps;
    value: string | React.ReactElement;
    caption?: string | React.ReactElement;
    highlight?: boolean;
    tooltip?: React.ReactElement;
}

export const TableVisualizationCell: React.FC<
    { children?: React.ReactNode | undefined } & TableVisualizationCellProps
> = ({ containerProps, ...props }) => {
    return (
        <Tooltip.Tooltip label={props.tooltip}>
            <HStack {...containerProps} height="100%" role="group">
                <TableCellStackedValue flex={1} {...props} />
            </HStack>
        </Tooltip.Tooltip>
    );
};

const TableCellStackedValue: React.FC<
    { children?: React.ReactNode | undefined } & Pick<
        TableVisualizationCellProps,
        'value' | 'caption' | 'highlight'
    > &
        StackProps
> = ({ value, caption, highlight, ...restProps }) => (
    <VStack {...restProps} spacing={0} alignItems="flex-start">
        <Text color="current">{value}</Text>
        {caption && <TableCellCaption highlight={highlight}>{caption}</TableCellCaption>}
    </VStack>
);

const TableCellCaption: React.FC<
    { children?: React.ReactNode | undefined } & TextProps &
        Pick<TableVisualizationCellProps, 'highlight'>
> = ({ ...props }) => (
    <Text
        fontSize="xs"
        fontWeight={props.highlight ? 'current' : 'normal'}
        // color={props.highlight ? 'current' : 'white'}
        color={props.highlight ? 'current' : 'gray.500'}
        // color={props.highlight ? 'current' : 'current'}
        // opacity={0.7}
    >
        {props.children}
    </Text>
);
