import { TooltipProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useStopWatch } from '../../../vendor/use-stop-watch';
import { Tracker } from '../../../base';
import { TooltipTrackingConfig } from './tooltipTrackingConfig';
import { TooltipHoveredEvent } from './tooltipTrackingEvent';

export function createLooltipTrackingController(
    config: TooltipTrackingConfig & { useTracker(): Tracker<TooltipHoveredEvent> }
) {
    const { useTracker, minOpenDurationMs } = config;
    return {
        useProps(props: TooltipProps): TooltipProps {
            const tracker = useTracker();
            const stopwatch = useStopWatch();

            useEffect(() => {
                if (
                    props.id &&
                    !stopwatch.isRunning &&
                    stopwatch.elapsedMs >= minOpenDurationMs
                ) {
                    tracker.track('tooltip_hovered', {
                        tooltip_id: props.id,
                        tooltip_open_duration_ms: stopwatch.elapsedMs,
                    });
                }
            }, [props.id, stopwatch.isRunning]);

            const handleOpen: TooltipProps['onOpen'] = (...args) => {
                if (props.id) {
                    stopwatch.start({ reset: true });
                }
                return props.onOpen?.(...args);
            };

            const handleClose: TooltipProps['onClose'] = (...args) => {
                if (props.id) {
                    stopwatch.stop();
                }
                return props.onClose?.(...args);
            };

            return { ...props, onOpen: handleOpen, onClose: handleClose };
        },
    };
}
