import { useState } from 'react';
import { CompanyListBoxMatchStateProvider } from './peerCompanyMatchInterface';
import { CompanyListBoxMatchStateValues } from './peerCompanyMatchModel';

export function peerCompanySearchStateProvider(): CompanyListBoxMatchStateProvider {
    return {
        useState(context) {
            const [value, setValue] = useState<CompanyListBoxMatchStateValues>({
                search: '',
            });
            return {
                value,
                onChange: setValue,
            };
        },
    };
}
