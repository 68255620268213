import { usePrevious } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useControlApi, useDashboardApi } from '../../../../app';
import { DashboardRouteEnhancer } from '../../../../entrypoint';
import { AnyDashboardTrackingEvent } from '../dashboardTrackingEvent';

export function createTraitModeTrackingStrategy(): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useSegmentList(...args) {
                    const tracker = config.deps.useTracker<AnyDashboardTrackingEvent>();
                    const api = {
                        dashboard: useDashboardApi(),
                        control: useControlApi(),
                    };
                    const mapped = config.controller.useSegmentList(...args);
                    const filterMode = api.control.getFilterMode();
                    const dashboard = api.dashboard.getDashboard();

                    const previousFilterMode = usePrevious(filterMode);
                    useEffect(() => {
                        if (previousFilterMode && previousFilterMode !== filterMode) {
                            tracker.track('dashboard_trait_mode_changed', {
                                dashboard_id: dashboard.id,
                                dashboard_trait_mode: filterMode,
                            });
                        }
                    }, [filterMode, previousFilterMode]);
                    return mapped;
                },
            },
        });
    };
}
