import { compose } from 'lodash/fp';
import { createAssistantElementTrackingStrategy } from './element';
import { createAssistantThreadTrackingStrategy } from './thread';

export function createAssistantTrackingStrategy() {
    return compose(
        createAssistantElementTrackingStrategy(),
        createAssistantThreadTrackingStrategy()
    );
}
