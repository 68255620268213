import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../../stores/setupContext';
import {
    RequestPasswordForm,
    RequestForgotPasswordProps,
} from '../../../../components/ForgotPassword/Request';
import { Container, Heading } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

export const ReqWrapper: React.FC<
    { children?: React.ReactNode } & Omit<
        RequestForgotPasswordProps,
        'title' | 'actionName'
    >
> = ({ doReqForgotPwd, isLoading, err, requested }) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            {/* <Heading fontSize="lg" marginBottom={4}>
                Change Password Request
            </Heading> */}
            <RequestPasswordForm
                {...{
                    doReqForgotPwd,
                    isLoading,
                    err,
                    requested,
                    title: 'Reset Password',
                    actionName: 'change password',
                }}
            />
        </Flex>
    );
};

const Observed = observer(ReqWrapper);

const WithStoreConnection = () => {
    const { setPassword } = useStore();
    const { isLoading, requestSetPassword: doReqForgotPwd, err, requested } = setPassword;
    return <Observed {...{ doReqForgotPwd, isLoading, err, requested }} />;
};

export default observer(WithStoreConnection);
