import { HomeRouteController } from '../../../../route';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { AnyHomeControlTrackingEvent } from './controlTrackingEvent';

export function createHomeControlTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            route: { createHomeRoute },
        } = config;

        function useTracker() {
            const tracker = instance.infra.useTracker<AnyHomeControlTrackingEvent>();
            return tracker;
        }

        function enhanceHomeController(
            controller: HomeRouteController
        ): HomeRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, workspace, deps] = args;
                    const tracker = useTracker();
                    const props = controller.useProps(...args);
                    return {
                        ...props,
                        getComparisonOptionProps(option) {
                            const original = props.getComparisonOptionProps(option);
                            return {
                                ...original,
                                onClick(...args) {
                                    tracker.track('home_control_comparison_changed', {
                                        asset_id: context.workspace.id as number,
                                        option_value: option.value,
                                        option_label: option.label,
                                    });
                                    return original.onClick(...args);
                                },
                            };
                        },
                        getDateProps() {
                            const base = props.getDateProps();
                            return {
                                ...base,
                                getRangeProps(...args) {
                                    const original = base.getRangeProps(...args);
                                    return {
                                        ...original,
                                        onChange(value) {
                                            tracker.track(
                                                'home_control_custom_period_changed',
                                                {
                                                    asset_id: context.workspace
                                                        .id as number,
                                                    period_from:
                                                        value[0]?.toISOString() ?? null,
                                                    period_to:
                                                        value[1]?.toISOString() ?? null,
                                                }
                                            );
                                            return original.onChange(value);
                                        },
                                    };
                                },
                                getPeriodOptionProps(option) {
                                    const original = base.getPeriodOptionProps(option);
                                    return {
                                        ...original,
                                        onClick(...args) {
                                            tracker.track(
                                                'home_control_relative_period_changed',
                                                {
                                                    asset_id: context.workspace
                                                        .id as number,
                                                    option_value: option.value,
                                                    option_label: option.label,
                                                }
                                            );
                                            return original.onClick(...args);
                                        },
                                    };
                                },
                            };
                        },
                    };
                },
            };
        }

        const instance = create({
            ...config,
            strategy: {
                ...config.strategy,
                home: {
                    ...config.strategy.home,
                },
            },
            route: {
                ...config.route,
                createHomeRoute(config) {
                    const { createController } = config.providers.root;
                    return createHomeRoute({
                        ...config,
                        config: {
                            ...config.config,
                        },
                        providers: {
                            ...config.providers,
                            root: {
                                ...config.providers.root,
                                createController(config) {
                                    const controller = createController(config);
                                    return enhanceHomeController(controller);
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
