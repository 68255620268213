import { createEventManager } from '../../infra';
import { ViewEvent } from '../../views';
import { affiliate } from '../affiliate';
import { SuggestionClickedEvent } from '../track';

export const { useEvent } = createEventManager<ViewEvent>({
    handlers: {
        receiptView(event) {
            affiliate.trial({
                id: event.session.customer,
            });
        },
        filterSuggestionClicked(event, context) {
            context.tracker.track<SuggestionClickedEvent>('suggestion_clicked', {
                filter_type: event.definition.key,
                data_param: false,
                dashboard_id: event.dashboard.slug,
                dashboard_name: event.dashboard.name,
                suggestion_value: String(event.option.value),
                suggestion_label: event.option.name,
            });
        },
    },
});
