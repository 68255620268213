import { Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export interface CardTooltipTitleProps {}

export const CardTooltipTitle: React.FC<
    { children?: React.ReactNode | undefined } & CardTooltipTitleProps
> = ({ children, ...props }) => {
    return <Text>{children}</Text>;
};
