import React from 'react';
import { SettingAssetItemProvider } from '../../../../route/settings';
import { SettingAssetMappingConfig } from './mappingConfig';
import { createSettingAssetMappingContainer } from './mappingContainer';
import { createSettingAssetMappingController } from './mappingController';
import { createSettingAssetMappingLoader } from './mappingLoader';
import { createSettingAssetMappingView } from './mappingView';

export function createSettingAssetMappingStrategy(
    init: SettingAssetMappingConfig
): SettingAssetItemProvider {
    return {
        create(config) {
            const controller = createSettingAssetMappingController(init, config);
            const loader = createSettingAssetMappingLoader(init);
            const Container = createSettingAssetMappingContainer(
                init,
                config,
                loader,
                controller
            );
            const View = createSettingAssetMappingView(init, config);
            return [
                {
                    scope: 'company',
                    path: 'data-sources',
                    key: 'sources',
                    title: 'Data Sources',
                    Component() {
                        return <Container as={View} />;
                    },
                },
            ];
        },
    };
}
