import {
    Link as ChakraLink,
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    StackDivider,
    Tag,
    Text,
    useDisclosure,
    VStack,
    Button,
    Center,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineInfoCircle,
    AiOutlinePlus,
    AiOutlineSearch,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { HiOutlineCube } from 'react-icons/hi';
import {
    OrganizationMappableListProps,
    OrganizationMappingOptionProps,
} from '../../../../view/assets';
import {
    EntityControlControllerProps,
    EntityControlDeps,
    EntityControlViewProps,
    EntityListViewProps,
} from '../../../../view';
import { SettingAccountItemProviderConfig } from '../../../../route';
import { SettingAssetMappingConfig } from './mappingConfig';
import { SettingAssetMappingViewProps } from './mappingProps';
import { FaArrowRight } from 'react-icons/fa';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';

export function createSettingAssetMappingView(
    init: SettingAssetMappingConfig,
    config: SettingAccountItemProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & SettingAssetMappingViewProps> {
    const { controller } = init;
    const {
        UI: {
            Application: { Link, Heading, Tooltip },
        },
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const AssetIcon = HiOutlineCube;

    const MappableSelectContainerV2: React.FC<
        { children?: React.ReactNode | undefined } & EntityControlControllerProps
    > = (props) => {
        const disclosure = useDisclosure();
        const [search, setSearch] = useState('');
        const viewDeps: EntityControlDeps = {
            disclosure,
            state: {
                search: {
                    value: search,
                    onChange: setSearch,
                },
            },
        };
        const viewContext = config.api.useContext();
        const viewProps = controller.entity.useProps(viewContext, viewDeps, props);
        return (
            <Popover
                placement="bottom-end"
                size="lg"
                isLazy={true}
                isOpen={disclosure.isOpen}
                onClose={disclosure.onClose}
                onOpen={disclosure.onOpen}
            >
                <PopoverTrigger>
                    <Box>
                        <EntityControl {...viewProps} />
                    </Box>
                </PopoverTrigger>
                <PopoverContent
                    borderColor="whiteAlpha.300"
                    bg="#141414"
                    // bg="#1D1C21"
                    minW="32rem"
                    _focus={{ outline: 'none' }}
                >
                    <PopoverBody p={0} maxH="50vh" overflow="auto">
                        <EntityListView {...viewProps} />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };

    const EntityControl: React.FC<
        { children?: React.ReactNode | undefined } & EntityControlViewProps
    > = (props) => {
        const listProps = props.getListProps();
        const hasMultiple = listProps.value.length > 1;

        return (
            <VStack align="end" spacing={2}>
                <HStack
                    flexShrink={0}
                    {...(hasMultiple ? { py: 2 } : { h: 10 })}
                    px={4}
                    // py={2}
                    // minH={8}
                    border="1px solid"
                    borderColor="whiteAlpha.400"
                    borderRadius="md"
                    spacing={2}
                    userSelect="none"
                    _expanded={{
                        bg: 'whiteAlpha.100',
                    }}
                >
                    <HStack
                        cursor="pointer"
                        spacing={2}
                        fontWeight="medium"
                        whiteSpace="nowrap"
                    >
                        <VStack w="full">
                            {listProps.value.length === 0 ? (
                                <Text>{listProps.title}</Text>
                            ) : null}
                            {listProps.value.map((valueItem, index) => (
                                <HStack
                                    key={index}
                                    spacing={2}
                                    w="full"
                                    justify="space-between"
                                >
                                    <Text isTruncated={false}>{valueItem.label}</Text>
                                    {valueItem.secondary && (
                                        <Text color="whiteAlpha.500" fontSize="md">
                                            {valueItem.secondary}
                                        </Text>
                                    )}
                                </HStack>
                            ))}
                        </VStack>
                        <Icon fontSize="xl" color="whiteAlpha.500" as={FiChevronDown} />
                    </HStack>
                </HStack>
            </VStack>
        );
    };

    const EntityListView: React.FC<
        { children?: React.ReactNode | undefined } & EntityControlViewProps
    > = (props) => {
        const listProps = props.getListProps();
        const emptyProps = listProps.getEmptyState();
        const alertProps = listProps.getAlertProps();
        return (
            <Grid templateRows="min-content 1fr">
                <GridItem>
                    <HStack pt={3} px={3} w="full" justify="space-between">
                        <HStack spacing={2}>
                            <Heading fontWeight="semibold" fontSize="md">
                                {listProps.title}
                            </Heading>
                            <Tag size="md">{listProps.targetLabel}</Tag>
                        </HStack>
                        {listProps.value && (
                            <Button
                                {...props.getClearProps()}
                                variant="outline"
                                size="sm"
                                _focus={{ outline: 'none' }}
                            >
                                Clear
                            </Button>
                        )}
                    </HStack>
                </GridItem>
                <GridItem>
                    <VStack align="start" w="full" py={3} spacing={3}>
                        {alertProps && (
                            <Box px={3} w="full">
                                <Alert status={alertProps.status}>
                                    {/* <AlertIcon /> */}
                                    <AlertTitle>{alertProps.title}</AlertTitle>
                                </Alert>
                            </Box>
                        )}
                        <Box px={3} w="full">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                        <Icon
                                            color="whiteAlpha.300"
                                            as={AiOutlineSearch}
                                        />
                                    }
                                />
                                <Input
                                    {...listProps.getSearchProps()}
                                    borderColor="whiteAlpha.300"
                                    placeholder={listProps.placeholder}
                                    _placeholder={{
                                        color: 'whiteAlpha.300',
                                    }}
                                />
                            </InputGroup>
                        </Box>
                        {emptyProps ? (
                            <Center w="full" px={3}>
                                <VStack w="full" p={3} spacing={2} fontWeight="medium">
                                    <VStack w="full" spacing={1}>
                                        <Text color="whiteAlpha.700">
                                            {emptyProps.title}
                                        </Text>
                                        {emptyProps.action && (
                                            <ChakraLink
                                                id="setting_mapping_new_integration_link"
                                                color="blue.300"
                                                fontSize="sm"
                                                fontWeight="semibold"
                                                as={Link}
                                                to={emptyProps.action?.path}
                                            >
                                                <HStack spacing={1}>
                                                    <Icon as={AiOutlinePlus}></Icon>
                                                    <Text>
                                                        {emptyProps.action?.label}
                                                    </Text>
                                                </HStack>
                                            </ChakraLink>
                                        )}
                                    </VStack>
                                </VStack>
                            </Center>
                        ) : null}
                        {emptyProps ? null : (
                            <>
                                <VStack
                                    spacing={0}
                                    align="start"
                                    w="full"
                                    divider={
                                        <StackDivider borderColor="whiteAlpha.300" />
                                    }
                                >
                                    {listProps.items.map((item, index, array) => {
                                        const itemProps = props.getItemProps(item);
                                        return (
                                            <HStack
                                                key={itemProps.value}
                                                p={3}
                                                w="full"
                                                justify="space-between"
                                                onClick={itemProps.onClick}
                                                cursor={
                                                    item.isDisabled
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }
                                                _hover={
                                                    item.isDisabled
                                                        ? undefined
                                                        : {
                                                              bg: 'whiteAlpha.50',
                                                          }
                                                }
                                                borderBottomRadius={
                                                    index === array.length - 1
                                                        ? 'md'
                                                        : undefined
                                                }
                                                opacity={
                                                    item.isDisabled ? 0.5 : undefined
                                                }
                                                filter={
                                                    item.isDisabled
                                                        ? 'grayscale(100%)'
                                                        : undefined
                                                }
                                            >
                                                <HStack spacing={3} w="full">
                                                    <Box
                                                        p={2}
                                                        bg="whiteAlpha.100"
                                                        borderRadius="md"
                                                        flexShrink={0}
                                                    >
                                                        <Image
                                                            w={5}
                                                            h={5}
                                                            src={itemProps.iconUrl}
                                                        />
                                                    </Box>
                                                    <Text
                                                        fontWeight="medium"
                                                        noOfLines={1}
                                                    >
                                                        {itemProps.label}
                                                    </Text>
                                                </HStack>
                                                <Tag size="md" flexShrink={0}>
                                                    <Text>{itemProps.secondary}</Text>
                                                </Tag>
                                            </HStack>
                                        );
                                    })}
                                </VStack>
                                <HStack px={3} spacing={3} align="center">
                                    <Text color="whiteAlpha.400">
                                        {listProps.tooltipLabel}
                                    </Text>
                                </HStack>
                            </>
                        )}
                    </VStack>
                </GridItem>
            </Grid>
        );
    };

    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const mappingProps = props.getMappingProps();
        const emptyStateProps = mappingProps.getEmptyStateProps();
        const alertProps = props.getAlertProps();

        if (emptyStateProps?.kind === 'empty') {
            return (
                <Alert status="warning" size="lg">
                    <AlertIcon />
                    <AlertTitle>No integrations found</AlertTitle>
                </Alert>
            );
        }

        return (
            <Container>
                <Header
                    description={
                        <Text>
                            Integrations must be linked to your company by configuring
                            them as data sources. Linked integration data will
                            automatically be synced and become available in the platform
                        </Text>
                    }
                >
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem
                                key={index}
                                to={item.to ?? undefined}
                                description={item.description}
                            >
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                    <ActionList>
                        <Link {...props.getIntegrationsLinkProps()}>
                            <Button
                                colorScheme="blue"
                                size="sm"
                                // bg="whiteAlpha.300"
                                // _hover={{
                                //     bg: 'whiteAlpha.400',
                                // }}
                                _focus={{
                                    outline: 'none',
                                }}
                            >
                                Manage Integrations
                            </Button>
                        </Link>
                    </ActionList>
                </Header>
                <Content>
                    {/* {alertProps.items.length > 0 && (
                        <VStack align="start" w="full">
                            {alertProps.items.map((item) => (
                                <Alert key={item.label} status="warning">
                                    <AlertIcon />
                                    <AlertTitle w="full">
                                        <HStack w="full" justify="space-between">
                                            <Text fontWeight="semibold">
                                                {item.label}
                                            </Text>
                                        </HStack>
                                    </AlertTitle>
                                </Alert>
                            ))}
                        </VStack>
                    )} */}
                    <Panel.List>
                        <Panel.Item title="Integrations">
                            <Panel.Action.List>
                                {/* <Link {...props.getCancelLinkProps()}>
                                    <Button
                                        bg="whiteAlpha.300"
                                        _hover={{
                                            bg: 'whiteAlpha.400',
                                        }}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Cancel
                                    </Button>
                                </Link> */}
                                <Tooltip
                                    {...mappingProps.getSubmitTooltipProps()}
                                    placement="top"
                                    hasArrow={true}
                                    p={4}
                                    bg="#1D1C21"
                                >
                                    <Box as="span">
                                        <Button
                                            {...props.getSubmitButtonProps()}
                                            colorScheme="green"
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </Panel.Action.List>
                            {alertProps.items.map((item) => (
                                <Panel.Alert
                                    key={item.label}
                                    status={item.status ?? undefined}
                                    label={item.label}
                                />
                            ))}
                            <List.Container>
                                {props.items.map((item) => {
                                    const itemProps = props.getItemProps(item);
                                    return (
                                        <List.Item
                                            key={item.id}
                                            label={
                                                <HStack h="full" spacing={4}>
                                                    <Box
                                                        flexShrink={0}
                                                        p={2.5}
                                                        bg="whiteAlpha.300"
                                                        borderRadius="md"
                                                    >
                                                        <Image
                                                            h={5}
                                                            w={5}
                                                            src={itemProps.iconUrl}
                                                        />
                                                    </Box>
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        fontWeight="medium"
                                                        spacing={0}
                                                    >
                                                        <Text>{itemProps.title}</Text>
                                                        {/* <HStack
                                                            w="full"
                                                            color="whiteAlpha.700"
                                                            fontSize="sm"
                                                            spacing={2}
                                                            divider={
                                                                <Box
                                                                    color="whiteAlpha.500"
                                                                    border="none"
                                                                >
                                                                    &#x2022;
                                                                </Box>
                                                            }
                                                        >
                                                            <Tooltip
                                                                placement="top"
                                                                hasArrow={true}
                                                                p={4}
                                                                bg="#1D1C21"
                                                                label={
                                                                    <VStack
                                                                        align="start"
                                                                        w="full"
                                                                    >
                                                                        <HStack
                                                                            userSelect="none"
                                                                            // color={`${itemProps.status.colorScheme}.400`}
                                                                            spacing={2}
                                                                        >
                                                                            <Box
                                                                                bg={`${itemProps.status.colorScheme}.400`}
                                                                                w={2}
                                                                                h={2}
                                                                                borderRadius="full"
                                                                            />
                                                                            <Text>
                                                                                {
                                                                                    itemProps
                                                                                        .status
                                                                                        .label
                                                                                }
                                                                            </Text>
                                                                        </HStack>
                                                                        <Text color="whiteAlpha.700">
                                                                            {
                                                                                itemProps
                                                                                    .status
                                                                                    .description
                                                                            }
                                                                        </Text>
                                                                    </VStack>
                                                                }
                                                            >
                                                                <HStack
                                                                    userSelect="none"
                                                                    color={`${itemProps.status.colorScheme}.400`}
                                                                    spacing={2}
                                                                >
                                                                    <Box
                                                                        bg={`${itemProps.status.colorScheme}.400`}
                                                                        w={1.5}
                                                                        h={1.5}
                                                                        borderRadius="full"
                                                                    />
                                                                    <Text whiteSpace="nowrap">
                                                                        {
                                                                            itemProps
                                                                                .status
                                                                                .label
                                                                        }
                                                                    </Text>
                                                                </HStack>
                                                            </Tooltip>
                                                            {itemProps.integrations && (
                                                                <Link
                                                                    {...itemProps.integrations.getLinkProps()}
                                                                >
                                                                    <Text whiteSpace="nowrap">
                                                                        {
                                                                            itemProps
                                                                                .integrations
                                                                                .label
                                                                        }
                                                                    </Text>
                                                                </Link>
                                                            )}
                                                        </HStack> */}
                                                    </VStack>
                                                </HStack>
                                            }
                                        >
                                            <HStack spacing={4} h="full">
                                                <HStack align="center" justify="center">
                                                    <Icon
                                                        color="whiteAlpha.500"
                                                        fontSize="xl"
                                                        as={BsArrowRight}
                                                    />
                                                </HStack>
                                                <MappableSelectContainerV2
                                                    {...props.getEntityControlProps(item)}
                                                />
                                            </HStack>
                                        </List.Item>
                                    );
                                })}
                            </List.Container>
                        </Panel.Item>
                    </Panel.List>
                </Content>
            </Container>
        );
    };
}
