import { useMutation, useQuery } from '@tanstack/react-query';
import { RegistrationOptionRepositoryConfig } from './optionConfig';
import { RegistrationOptionRepository } from './optionInterface';

export function createRegistrationOptionRepository(
    config: RegistrationOptionRepositoryConfig
): RegistrationOptionRepository {
    const { adapter } = config;
    return {
        useLookup(context) {
            return useQuery({
                queryKey: ['registration-options'],
                async queryFn() {
                    const response = await adapter.get(context);
                    return response;
                },
            });
        },
        useCreate(context) {
            return useMutation({
                mutationFn(value) {
                    return adapter.put(context, value);
                },
            });
        },
    };
}
