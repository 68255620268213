import React from 'react';
import { HomeContext } from '../../../models/Context';

export interface HomeContextState {
    value: HomeContext | null;
    setValue: (v: HomeContext | null) => void;
}
const homeContextProvider = React.createContext<HomeContextState | undefined>(undefined);

// Template Provider
export const HomeContextProvider: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = ({ children }) => {
    const [value, setValue] = React.useState<HomeContext | null>(null);
    // React.useEffect(() => {
    //     console.log('context changed', value);
    // }, [value]);
    // Context values passed to consumer
    const ctx = {
        value, // <------ Expose Value to Consumer
        setValue, // <------ Expose Setter to Consumer
    };

    return (
        <homeContextProvider.Provider value={ctx}>
            {children}
        </homeContextProvider.Provider>
    );
};

export const useHome = () => {
    const _homeContext = React.useContext(homeContextProvider);
    return _homeContext;
};
