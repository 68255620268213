import { formatType, daterangeToString, Calculation } from '../../domain';
import { ApplicationContext } from '../../base';
import { CalculationViewProps } from './component';
import { getDescription } from './calculationHelper';

export const useCalculationView = (context: Pick<ApplicationContext, 'formatter'>) => {
    const getProps = (calculation: Calculation): CalculationViewProps => {
        const formatValue: CalculationViewProps['formatValue'] = (value, options) => {
            if (value === null) {
                return 'N/A';
            }
            return formatType(context, calculation.property.type, value, {
                notation: options?.format ?? 'long',
            });
        };
        return {
            label: calculation.property.name,
            value: calculation.value.current,
            description: getDescription(formatValue, calculation) ?? undefined,
            period: calculation.period,
            comparison: calculation.comparison,
            formatPeriod(period) {
                return daterangeToString(context, period);
            },
            formatValue,
        };
    };
    return { getProps };
};
