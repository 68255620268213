import React from 'react';
import { SettingAccountItemProvider } from '../../../../route/settings/account';
import {
    SettingsBillingContainerConfig,
    SettingsBillingControllerConfig,
    SettingsBillingLoaderConfig,
    SettingsBillingViewConfig,
} from './base';
import {
    createPaymentMethodDetailContainer,
    createPaymentMethodDetailController,
    createPaymentMethodDetailLoader,
    createPaymentMethodDetailView,
    createPaymentMethodEditContainer,
    createPaymentMethodEditController,
    createPaymentMethodEditLoader,
    createPaymentMethodEditView,
} from './payment-method';
import { createPaymentMethodEditService } from './payment-method/edit/paymentMethodEditService';
import { SettingsBillingConfig } from './settingsBillingConfig';
import {
    createSettingSubscriptionEditContainer,
    createSettingSubscriptionEditController,
    createSettingSubscriptionEditLoader,
    createSettingSubscriptionEditView,
} from './subscription/edit';

export function createSettingBillingStrategy(
    init: SettingsBillingConfig
): SettingAccountItemProvider {
    return {
        create(config) {
            const containerConfig: SettingsBillingContainerConfig = {
                context: {
                    useContext: config.api.useContext,
                },
                config: init.config,
                infra: init.infra,
            };

            const controllerConfig: SettingsBillingControllerConfig = {
                controller: {
                    form: init.providers.createForm({}),
                    page: config.api.controller,
                },
                infra: init.infra,
            };

            const loaderConfig: SettingsBillingLoaderConfig = {
                repository: init.repository,
            };

            const viewConfig: SettingsBillingViewConfig = {
                settings: {
                    ...config,
                },
                config: init.config,
            };

            const PaymentMethod = {
                Detail: {
                    Container: createPaymentMethodDetailContainer(
                        containerConfig,
                        createPaymentMethodDetailLoader(loaderConfig),
                        createPaymentMethodDetailController(controllerConfig),
                        createPaymentMethodDetailView(viewConfig)
                    ),
                },
                Edit: {
                    Container: createPaymentMethodEditContainer(
                        containerConfig,
                        createPaymentMethodEditLoader(loaderConfig),
                        createPaymentMethodEditController(controllerConfig),
                        createPaymentMethodEditService(),
                        createPaymentMethodEditView(viewConfig)
                    ),
                },
            };

            const SubscriptionEdit = {
                Container: createSettingSubscriptionEditContainer(
                    init,
                    config,
                    createSettingSubscriptionEditLoader(init),
                    createSettingSubscriptionEditController(init, config)
                ),
                View: createSettingSubscriptionEditView(config),
            };

            return [
                {
                    scope: 'billing',
                    path: 'subscription',
                    key: 'subscription',
                    title: 'Subscription',
                    Component() {
                        return <SubscriptionEdit.Container as={SubscriptionEdit.View} />;
                    },
                },
                {
                    scope: 'billing',
                    path: 'payment',
                    key: 'payment',
                    title: 'Payment',
                    Component() {
                        return <PaymentMethod.Detail.Container />;
                    },
                    children: [
                        {
                            scope: 'billing',
                            path: 'edit',
                            key: 'edit',
                            title: 'Edit payment method',
                            Component() {
                                return <PaymentMethod.Edit.Container />;
                            },
                            children: [],
                        },
                    ],
                },
            ];
        },
    };
}
