import { MenuContainer } from './container';
import { MenuDropdown } from './dropdown';
import { MenuItem } from './item';
import { MenuListLabel } from './label';
import { MenuList } from './list';
import { MenuTarget } from './target';

export const Menu = {
    Container: MenuContainer,
    List: MenuList,
    Item: MenuItem,
    Label: MenuListLabel,
    Dropdown: MenuDropdown,
    Target: MenuTarget,
};
