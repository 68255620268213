import graphic1 from '../../../images/dashboard_preview_default_01.png';
import graphic2 from '../../../images/dashboard_preview_default_02.png';
import type { FeatureFixture } from '../featureFixture';

export const DEFAULT_HIGHLIGHTS: FeatureFixture[] = [
    {
        text: 'Forecast and plan accurately with unlimited historical data',
    },
    {
        text: 'More granular benchmarks with all data and peer group filters',
    },
    {
        text: `Optimally allocate ad spend by seeing media mix breakdowns from other companies`,
    },
    {
        text: `Collaborate with teammates and clients`,
    },
];

export const DEFAULT_GRAPHICS: Array<{ src: string }> = [
    {
        src: graphic2,
    },
    {
        src: graphic1,
    },
];
