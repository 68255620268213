import { DashboardSlugs } from '../../../../config/dashboard';
import { EmailInsightController } from './emailInsightInterface';
import { EmailReportViewConfig } from './emailInsightConfig';
import pluralize from 'pluralize';

export function createEmailInsightController(
    config: EmailReportViewConfig
): EmailInsightController {
    const {
        hook: { useQuery },
        api: {
            insight: {
                insights: { get: getInsight },
            },
        },
        service: {
            workspace: { useWorkspace },
        },
    } = config;
    return {
        useProps(context, data, payload) {
            const workspace = useWorkspace(payload.workspace);
            if (!data.current.data?.insight || !data.latest.data) {
                throw new Error('expected suspense query but data not loaded');
            }
            const { insight } = data.current.data;
            return {
                workspace,
                current: data.current.data,
                latest: data.latest,
                period: {
                    start: insight.period_start_at,
                    end: insight.period_end_at,
                },
                metadata: {},
                notificationSettingsHref: `/u/settings/notifications`,
                getCtaProps() {
                    const restCount = Math.max(0, data.latest.data!.total - 1);
                    if (restCount === 0) {
                        return {
                            label: `View all opportunities`,
                            href: `/u/assets/${workspace.id}/opportunities`,
                        };
                    }
                    const pluralized = pluralize('insight', restCount);
                    return {
                        label: `View ${restCount} more ${pluralized}`,
                        href: `/u/assets/${workspace.id}/opportunities`,
                    };
                },
            };
        },
    };
}
