import AuthStore from '../../stores/AuthStore';
import { TrackHookConfig } from './trackConfig';
import { TrackerAdapter } from './trackAdapter';

export const useTrackerAdapter = (
    auth: Pick<AuthStore, 'reportEvent'>,
    _config: Partial<TrackHookConfig> = {}
): TrackerAdapter => {
    return {
        track(event, payload) {
            auth.reportEvent(event, payload);
        },
    };
};
