import { FilterDateViewProps, FilterDateInternalProps } from './dateProps';

export const useInternal = (props: FilterDateViewProps): FilterDateInternalProps => {
    return {
        style: props.style,
        value: props.value,
        label: 'asd',
        onChange: props.onChange,
        minDate: props.minDate,
    };
};
