import { Duration } from '../duration';
import { AbsolutePeriod, DependentPeriod, Period, RelativePeriod } from './periodModel';

export function isRelativeDuration(value: Period): value is Duration {
    return isRelativePeriod(value);
    //  && value.interval !== 'period';
}

export function isRelativePeriod(value: Period): value is RelativePeriod {
    return typeof (value as RelativePeriod).interval === 'string';
}

export function isDependentPeriod(value: Period): value is DependentPeriod {
    return (value as DependentPeriod).interval === 'period';
}

export function isAbsolutePeriod(value: Period): value is AbsolutePeriod {
    return !isRelativePeriod(value);
}
