import { CollaboratorAdapter } from '../../../app/platform';
import { collaboratorImplConfig } from './collaboratorConfig';

export function createCollaboratorImpl(
    config: collaboratorImplConfig
): CollaboratorAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.platform.collaborators.list(context, query);
            return response.data.map((item) => ({ ...item }));
        },
    };
}
