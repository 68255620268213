import { assert } from '../../../util/assert';
import { VisualizationLoaderConfig } from '../base';
import { VisualizationChartLoader } from './visualizationChartInterface';

export function createVisualizationChartLoader(
    config: VisualizationLoaderConfig
): VisualizationChartLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const visualizationQuery = repository.visualization.useLookup(
                context,
                props.visualization,
                {
                    suspense: true,
                }
            );
            assert(visualizationQuery.status === 'success', 'expected suspense');
            return { visualization: visualizationQuery.data };
        },
    };
}
