import { AxiosInstance } from 'axios';
import { ApiError } from '../../../base';
import { evaluateFilter } from '../../../../api';
import { castConditionToApi } from '../../../impl/api';
import { MetadataRule } from '../../../../models/Metadata';
import { LegacyAuthenticationScheme } from '../../common';
import { CompetitiveSetResource } from './competitiveInterface';
import { CompetitiveSetSchema } from './competitiveSchema';


export function createCompetitiveSetResource(
    client: AxiosInstance
): CompetitiveSetResource {
    return {
        async create(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await evaluateFilter(
                    client,
                    query.asset,
                    {
                        operator: 'and',
                        sub_rules: query.condition
                            // .filter((condition) => condition.value)
                            .map((condition): MetadataRule => {
                                const mapped = castConditionToApi(condition);
                                return {
                                    metadata_definition_key: mapped.key,
                                    operator: mapped.operator,
                                    value: mapped.value as any,
                                };
                            }),
                    },
                    // dashboard does not matter
                    // 'facebookads-v2',
                    query.dashboard,
                    {
                        headers: {
                            Authorization: `bearer ${scheme.store.authToken}`,
                        },
                    }
                );
                const parsed = CompetitiveSetSchema.parse(response.data);

                return parsed.evaluated_quality;
            } catch (e) {
                if (e instanceof ApiError && e.statusCode === 404) {
                    // the dashboard does not exist. we fallback to insufficiant sample
                    return {
                        quality: 'insufficient_sample',
                        range: [0, 5],
                    };
                }
                throw e;
            }
        },
    };
}
