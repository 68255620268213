import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Stack,
    StackDivider,
    Tag,
    Text,
    Textarea,
    useToken,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { SelectItemViewProps, SelectListViewProps } from '../../../../../view/common';

export function createMetricSelect(): React.FC<
    { children?: React.ReactNode | undefined } & SelectListViewProps
> {
    const Item: React.FC<
        { children?: React.ReactNode | undefined } & SelectItemViewProps
    > = (props) => {
        return (
            <HStack
                w="full"
                px={4}
                py={3}
                justify="space-between"
                {...props.container}
                _hover={{
                    bg: 'whiteAlpha.50',
                }}
            >
                <HStack spacing={3}>
                    <Box p={2} borderRadius="sm" bg="whiteAlpha.200">
                        {props.icon?.kind === 'image' && (
                            <Image h={5} w={5} src={props.icon.src}></Image>
                        )}
                    </Box>
                    <Text noOfLines={1} fontWeight="semibold">
                        {props.label}
                    </Text>
                </HStack>
                <HStack>
                    {props.isSelected && <Tag colorScheme="blue">Selected</Tag>}
                </HStack>
            </HStack>
        );
    };
    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

        const triggerContents = props.current ? (
            <>
                {props.current.icon?.kind === 'image' && (
                    <Image h={5} w={5} src={props.current.icon.src} />
                )}
                <Text fontWeight="medium" color="whiteAlpha.700">
                    {props.current.label}
                </Text>
            </>
        ) : (
            <>
                <Text fontWeight="medium" color="whiteAlpha.700">
                    No metric selected
                </Text>
            </>
        );
        return (
            <Popover {...props.getPopoverProps()}>
                <PopoverTrigger>
                    <HStack
                        cursor="pointer"
                        flexShrink={0}
                        spacing={4}
                        px={4}
                        h={10}
                        borderStyle="solid"
                        borderColor="whiteAlpha.300"
                        borderWidth={1}
                        borderRadius="md"
                        _hover={{ borderColor: 'whiteAlpha.400' }}
                    >
                        {triggerContents}
                        <Icon fontSize="xl" color="whiteAlpha.500" as={ChevronDownIcon} />
                    </HStack>
                </PopoverTrigger>

                <PopoverContent
                    minW="24rem"
                    // border="none"
                    bg="#1D1C21"
                    borderColor="whiteAlpha.300"
                    overflow="hidden"
                    _focus={{ outline: 'none' }}
                >
                    <PopoverHeader p={3} border="none">
                        <VStack w="full" align="start" spacing={2}>
                            <HStack w="full" justify="space-between">
                                <Text fontWeight="semibold">Select metric</Text>
                                <Button
                                    size="sm"
                                    {...props.getClearButtonProps()}
                                    _focus={{ outline: 'none' }}
                                >
                                    Clear
                                </Button>
                            </HStack>
                            <InputGroup>
                                <Input
                                    placeholder="Search metrics"
                                    {...props.getSearchInputProps()}
                                />
                                <InputLeftElement>
                                    <Icon color="whiteAlpha.300" as={AiOutlineSearch} />
                                </InputLeftElement>
                            </InputGroup>
                        </VStack>
                    </PopoverHeader>
                    <PopoverBody
                        p={0}
                        maxH="33vh"
                        overflowY="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '0.5rem',
                            },
                            '&::-webkit-scrollbar-track': {},
                            '&::-webkit-scrollbar-corner': {
                                '-webkit-appearance': 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '1rem',
                                background: scrollbarBg,
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: scrollbarHoverBg,
                            },
                        }}
                    >
                        <VStack
                            spacing={0}
                            align="start"
                            w="full"
                            divider={<StackDivider borderColor="whiteAlpha.300" />}
                        >
                            {props.items.map((item) => {
                                const itemProps = props.getItemProps(item);
                                return <Item key={itemProps.value} {...itemProps} />;
                            })}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };
}
