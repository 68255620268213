import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { OrganizationScope } from '../../app';
import {
    GroupRepository,
    GroupMemberRepository,
    GroupFindQuery,
    GroupRepositoryOptions,
} from '../../app/groups';
import { AnyCollection, AnyLookup, ApplicationError, Pagination } from '../../base';
import { GroupAggregate, GroupSaveDto } from './groupModel';

export function createGroupsService(repository: {
    group: GroupRepository;
    member: GroupMemberRepository;
}) {
    return {
        useFind(
            context: OrganizationScope,
            command: GroupFindQuery,
            options: GroupRepositoryOptions = {}
        ): UseQueryResult<Pagination<GroupAggregate>> {
            const query = {
                groups: repository.group.useFind(context, command, {
                    ...options,
                }),
                members: repository.member.useFind(context, {}, options),
            };

            const items = useMemo((): GroupAggregate[] => {
                if (query.groups.status === 'success') {
                    return query.groups.data?.items.map((curr) => ({
                        ...curr,
                        members: (query.members.data?.items || []).filter(
                            (m) => m.group_id === curr.id
                        ),
                    }));
                }
                return [];
            }, [query.groups.data?.items, query.members.data?.items]);

            if (query.groups.status !== 'success') {
                // @ts-expect-error
                return query.groups;
            }

            // @ts-expect-error
            return {
                ...query.groups,
                data: {
                    ...query.groups.data,
                    items,
                },
            };
        },
        useSave(context: OrganizationScope) {
            const currentData = this.useFind(context, {}, { staleTime: Infinity });
            const currInternalGroup = currentData.data?.items.find(
                (x) => x.membership.kind === 'internal'
            );
            const currOptInGroup = currentData.data?.items.find(
                (x) => x.membership.kind === 'opt_in'
            );
            const createGroup = repository.group.useCreate(context);
            const removeGroup = repository.group.useRemove(context);
            const memberBulk = repository.member.useBulkLegacy(context);

            return useMutation<void, Error, GroupSaveDto>({
                async mutationFn(command) {
                    if (command.type === 'internal') {
                        if (currInternalGroup) {
                            // already configured. do nothing;
                            return Promise.resolve();
                        } else {
                            // currOptInGroup?.members || []).map(m => ())
                            await Promise.all([
                                createGroup.mutateAsync({
                                    membershipType: command.type,
                                    name: context.organization.id.toString(),
                                    dataset: null,
                                }),
                                currOptInGroup?.members &&
                                currOptInGroup.members.length > 0
                                    ? memberBulk.mutateAsync({
                                          group_id: currOptInGroup.id,
                                          asset_ids: [],
                                      })
                                    : Promise.resolve(),
                            ]);
                        }
                    } else if (command.type === 'opt_in') {
                        if (currOptInGroup) {
                            await Promise.all([
                                currInternalGroup
                                    ? removeGroup.mutateAsync({
                                          groupId: currInternalGroup.id,
                                      })
                                    : Promise.resolve(),
                                memberBulk.mutateAsync({
                                    group_id: currOptInGroup.id,
                                    asset_ids: command.asset_ids,
                                }),
                            ]);
                        } else {
                            throw new Error('optin group could not be found');
                        }
                    } else {
                        throw new Error(`group not found ${JSON.stringify(command)}`);
                    }
                },
            });
        },
        useClear(context: OrganizationScope) {
            const currentData = this.useFind(context, {}, { staleTime: Infinity });
            const currInternalGroup = currentData.data?.items.find(
                (x) => x.membership.kind === 'internal'
            );
            const currOptInGroup = currentData.data?.items.find(
                (x) => x.membership.kind === 'opt_in' && x.members.length > 0
            );
            const removeGroup = repository.group.useRemove(context);
            const memberBulk = repository.member.useBulkLegacy(context);
            return useMutation<void, Error, null>({
                async mutationFn(command) {
                    if (currOptInGroup) {
                        await memberBulk.mutateAsync({
                            group_id: currOptInGroup.id,
                            asset_ids: [],
                        });
                    }
                    if (currInternalGroup) {
                        await removeGroup.mutateAsync({
                            groupId: currInternalGroup.id,
                        });
                    }
                },
            });
        },
    };
}
