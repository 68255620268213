import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
    Center,
    GridItem,
    Heading,
    Link,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { CompletedSessionDto } from '../../../api';

export interface ReceiptViewProps {
    spacing?: 8 | 12;
    session: CompletedSessionDto;
    redirectPath?: string;
}

export const ReceiptView: React.FC<
    { children?: React.ReactNode | undefined } & ReceiptViewProps
> = ({ spacing = 8, ...props }) => {
    return (
        <VStack
            w="full"
            h="full"
            p={10}
            spacing={8}
            alignItems="flex-center"
            border="1px"
            borderColor="frame"
            borderRadius={10}
            bg="box"
        >
            <VStack spacing={4}>
                <CheckCircleIcon color="green.400" boxSize="40px" />
                {props.session.trial && (
                    <Heading size="lg" textAlign="center" color="onboarding.title">
                        Subscribed to {props.session.plan}!
                    </Heading>
                )}
                {!props.session.trial && (
                    <Heading size="lg" textAlign="center" color="onboarding.title">
                        Subscribed to {props.session.plan}!
                    </Heading>
                )}
                {/* <Text w="sm" fontSize="lg" color="onboarding.subtitle" textAlign="center">
                    Your 14-day trial has started
                </Text> */}
            </VStack>
            {props.redirectPath && (
                <SimpleGrid columns={1} columnGap={4} rowGap={5} w="full">
                    <GridItem colSpan={1}>
                        <Center>
                            <Link
                                w="100%"
                                to={props.redirectPath}
                                as={ReactRouterLink}
                                replace={true}
                            >
                                <Button
                                    w="100%"
                                    textColor="button.white"
                                    boxShadow="lg"
                                    bg="button.green"
                                    type="submit"
                                    _hover={{ bg: 'button.hoverGreen' }}
                                >
                                    Continue
                                </Button>
                            </Link>
                        </Center>
                    </GridItem>
                </SimpleGrid>
            )}
        </VStack>
    );
};
