import { OrganizationScope } from 'src/v2/app';
import { AssetCreateProps } from 'src/v2/domain/assets';
import { AccountProfileAdapter } from '../../../app/accounts';
import { ProfileImplConfig } from './profileImplConfig';

export function createProfileImpl(config: ProfileImplConfig): AccountProfileAdapter {
    const {
        config: { defaultPlan },
        api: { platform, billing, ...api },
        adapter,
    } = config;

    const idempotencyKey = Date.now().toString();

    return {
        async put(context, props) {
            const [options, current] = await Promise.all([
                adapter.registration.get(context),
                platform.me.get(context),
            ]);

            const isCreate = current.organizations.length === 0;

            const response = await platform.me.update(context, {
                first_name: props.firstname,
                last_name: props.lastname,
                asset_url: null,
                title: null,
                with_customer: true,
                organization: {
                    role: props.role,
                    organization: {
                        name: props.organizationName,
                        domain: props.domain,
                        company_type: props.organizationType,
                        finished_on_boarding: props.finishedOnboarding,
                    },
                },
            });

            if (context.auth.scheme.kind === 'legacy') {
                // NOTE we have to refresh the auth context so that it
                // contains the newly created organization context
                await context.auth.scheme.store.loginRefreshToken();
            }

            const [org] = response.organizations;

            const orgContext: OrganizationScope = {
                ...context,
                organization: { id: org.organization.id },
            };

            const [subscription, assets] = await Promise.all([
                billing.subscription.get(context),
                adapter.asset.find(orgContext, { limit: 100, visible: null }),
            ]);

            if (!subscription.active) {
                console.info('activating organization subscription...');
                const plan = defaultPlan;
                const subscription = await billing.subscription.create(
                    context,
                    {
                        plan,
                    },
                    { wait: 15000, idempotency_key: idempotencyKey }
                );
                console.log(`succesfully subscribed to ${plan} plan`, subscription);
            }

            if (
                assets.items.length === 0 &&
                org.organization.company_type !== 'ad_agency'
            ) {
                // NOTE we auto-create assets for non-agencies based on the data provided as part of
                // profile form
                const payload: AssetCreateProps = {
                    name: props.organizationName!,
                    url: props.domain!,
                    type: 'website',
                    isVisible: true,
                };
                console.info(
                    'no assets found, auto-creating asset for brand...',
                    payload
                );
                const asset = await adapter.asset.create(orgContext, payload);
                console.info(`asset created`, asset);
            }

            if (isCreate && options?.source === 'report') {
                console.info(
                    'registration option set to report, updating client preferences...'
                );
                try {
                    const preference = await adapter.preference.update(
                        {
                            ...context,
                            organization: { id: org.organization.id },
                        },
                        {
                            layout: 'light',
                        }
                    );
                    console.log('client preferences set', preference);
                } catch (error) {
                    console.error(`failed to create client preferences`, error);
                }
            }

            return {
                firstname: response.first_name,
                lastname: response.last_name,
                organizationName: org.organization.name,
                organizationType: org.organization.company_type,
                domain: org.organization.domain,
                role: org.role,
                finishedOnboarding: org.organization.finished_on_boarding,
            };
        },
    };
}
