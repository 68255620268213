import { FeedbackRatingController } from './ratingInterface';

export function createRatingController(): FeedbackRatingController {
    return {
        useProps(form, props) {
            const formValues = form.watch();
            return {
                options: [1, 2, 3, 4, 5].map((index) => ({
                    label: index.toString(),
                    value: index,
                })),
                getFormProps(item) {
                    return {
                        isSubmitting: form.formState.isSubmitting,
                        isSubmitted: form.formState.isSubmitted,
                        isValid: typeof formValues.rating === 'number',
                        onSubmit: form.handleSubmit((values) => {
                            return props.onSubmit(item, values);
                        }),
                    };
                },
                getOptionProps(item) {
                    return {
                        isSelected: item.value === formValues.rating,
                        onClick() {
                            form.setValue('rating', item.value);
                        },
                    };
                },
            };
        },
    };
}
