import { NotEnoughDataError } from '../../../api';
import { RateLimitError, ErrorFormatter } from '../../base';

export function createErrorFormatterImpl(): ErrorFormatter {
    return {
        format(error) {
            if (error instanceof RateLimitError) {
                return {
                    name: 'Too many requests',
                    description: 'Please slow down and try again in a few minutes',
                    hint: null,
                };
            }
            if (error instanceof NotEnoughDataError) {
                return {
                    name: 'Insufficient data sample',
                    description: 'Please adjust your dashboard filters',
                    hint: null,
                };
            }
            return {
                name: null,
                description: error.message,
                hint: null,
            };
        },
    };
}
