import { UseQueryResult } from '@tanstack/react-query';
import { Pagination } from '../pagination';
import { AnyCollection } from './collectionModel';

export function collectionFromQuery<TItem>(
    query: UseQueryResult<Pagination<TItem>>,
    options: { keepData?: boolean } = {}
): AnyCollection<TItem> {
    if (options.keepData && query.data !== undefined) {
        return {
            status: 'loaded',
            data: query.data,
        };
    }
    if (
        query.isLoading
        // || query.data === undefined
    ) {
        return {
            status: 'loading',
            data: null,
        };
    }
    if (!options.keepData && query.isFetching) {
        return {
            status: 'loading',
            data: null,
        };
    }
    if (query.isError) {
        return {
            status: 'error',
            data: null,
            error: query.error as Error,
        };
    }
    if (query.data.items.length === 0) {
        return {
            status: 'empty',
            data: null,
        };
    }
    return {
        status: 'loaded',
        data: query.data,
    };
}
