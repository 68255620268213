import { useMemo } from 'react';
import { EventBlock } from '../../../../app/assistant';
import { assert } from '../../../../util/assert';
import { AssistantLoaderBaseConfig } from '../../base';
import { ThreadDetailLoader } from './threadDetailInterface';

export function createThreadDetailLoader(
    config: AssistantLoaderBaseConfig
): ThreadDetailLoader {
    const {
        repository: {
            thread: threadRepository,
            message: messageRepository,
            workflow: workflowRepository,
        },
    } = config;

    return {
        useLoad(context, props) {
            const threadQuery = threadRepository.useLookup(context, props.thread, {
                suspense: true,
            });
            assert(threadQuery.status === 'success', 'expected suspense');
            const workflowQuery = workflowRepository.useLookup(
                context,
                threadQuery.data.workflow,
                {
                    suspense: true,
                }
            );
            assert(workflowQuery.status === 'success', 'expected suspense');
            const messageQuery = messageRepository.useFind(
                context,
                { thread: props.thread },
                {
                    suspense: true,
                }
            );
            assert(messageQuery.status === 'success', 'expected suspense');
            return useMemo(
                () => ({
                    workflow: workflowQuery.data,
                    thread: threadQuery.data,
                    entries: messageQuery.data.map((message, index, array) => ({
                        message,
                        result:
                            array[index - 1]?.content.find(
                                (item): item is EventBlock => item.kind === 'event'
                            ) ?? null,
                    })),
                    mostRecentMessage: messageQuery.data[0] ?? null,
                    chat: {
                        messages: messageQuery.data.map((item) => ({
                            text: item.id,
                        })),
                    },
                }),
                [messageQuery.data, threadQuery.data, workflowQuery.data]
            );
        },
    };
}
