import { Property } from '../../attributes';
import { PluginEntity } from '../../platform';
import { ViewEntity } from '../../query';
import {
    isComparisonVisualization,
    isDistributionVisualization,
} from './visualizationGuard';
import { AnyVisualization } from './visualizationModel';

export function resolveVisualizationView(
    views: ViewEntity[],
    visualization: AnyVisualization
) {
    const { breakdown, view } = visualization;
    // console.log('DEBUG props.visualization', props.visualization);

    if (view) {
        return views.find((item) => item.id === view);
    }

    // view not explicitly configured, so resolve best match

    const directMatch = views.find((item) =>
        visualization.metrics.every((metric) =>
            item.columns.some(
                (column) => column.key === metric.key && item.plugin === metric.plugin
            )
        )
    );
    if (!breakdown) {
        return directMatch ?? null;
    }
    if (
        directMatch &&
        breakdown.properties.every((facet) =>
            directMatch.columns.some((property) => property.key === facet.key)
        )
    ) {
        return directMatch;
    }
    const found = views
        .filter((view) => view.plugin === visualization.metrics?.[0]?.plugin)
        .find((view) =>
            breakdown.properties.every((facet) =>
                view.columns.some((property) => property.key === facet.key)
            )
        );
    return found ?? null;
}

export function getVisualizationName(
    plugin: PluginEntity | null,
    propertiesByKey: Record<string, Property | undefined>,
    visualization: AnyVisualization
) {
    let fragments: Array<string | undefined> = [];

    if (isComparisonVisualization(visualization) && plugin) {
        return plugin.name;
    }

    if (isDistributionVisualization(visualization)) {
        fragments = [...fragments, 'Average'];
    } else {
        fragments = [...fragments];
    }

    const [metric, ...rest] = visualization.metrics;

    if (rest.length > 0) {
        console.warn(`multiple metric not supported in name helper`);
    }

    if (metric.name.toUpperCase() === metric.name || fragments.length === 0) {
        fragments = [...fragments, metric.name];
    } else {
        fragments = [...fragments, metric.name.toLowerCase()];
    }

    if (isDistributionVisualization(visualization)) {
        fragments = [...fragments, 'distribution'];
    }

    if (visualization.breakdown) {
        fragments = [
            ...fragments,
            'by',
            ...visualization.breakdown.properties
                .map((property) => propertiesByKey[property.key]?.name.toLowerCase())
                .join(' and ')
                .split(' '),
        ];
    }

    return fragments.filter(Boolean).join(' ');
}
