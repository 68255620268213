import { AnyLookup, ErrorLookup, LoadedLookup, LoadingLookup } from './lookupModel';

export function isLoadingLookup<TItem>(
    collection: AnyLookup<TItem>
): collection is LoadingLookup {
    return collection.status === 'loading';
}

export function isErrorLookup<TItem>(
    collection: AnyLookup<TItem>
): collection is ErrorLookup {
    return collection.status === 'error';
}

export function isLoadedLookup<TItem>(
    collection: AnyLookup<TItem>
): collection is LoadedLookup<TItem> {
    return collection.status === 'loaded';
}
