import { AnyAction } from './actionItemModel';

export function isClickAction(
    item: AnyAction
): item is Exclude<AnyAction, { kind: 'link' }> {
    return item.kind !== 'link';
}

export function isLinkAction(
    item: AnyAction
): item is Extract<AnyAction, { kind: 'link' }> {
    return item.kind === 'link';
}
