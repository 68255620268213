import { IS_DEVELOPMENT } from '../../../../../infra';
import { PeerStatusEntity } from '../../../../app';
import { GroupEditAggregate } from './groupEditModel';

export function isPeerGroupAggregateValid(
    item: GroupEditAggregate,
    competitive: PeerStatusEntity | null
) {
    if (IS_DEVELOPMENT) {
        return true;
    }
    if (competitive?.transparency.quality === 'insufficient_sample') {
        return false;
    }
    // if (item.group.conditions.length === 0) {
    //     return false;
    // }
    return true;
}
