import { ImportDto } from '../../../api';
import { assert } from '../../../util/assert';
import {
    DatasetImportAdapter,
    DatasetImportEntity,
    DatasetImportStatus,
    DatasetImportStatusKind,
} from '../../../app/datasets';
import { DatasetImportImplConfig } from './datasetImportConfig';

export function createDatasetImportImpl(
    config: DatasetImportImplConfig
): DatasetImportAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function toEntityStatus(status: ImportDto['status']): DatasetImportStatusKind {
        if (status === 'error') {
            return 'failed';
        }
        if (status === 'uploaded') {
            return 'complete';
        }
        if (status === 'pending_data') {
            return 'created';
        }
        return 'in_progress';
    }

    function toEntity(dto: ImportDto): DatasetImportEntity {
        return {
            id: dto.id,
            status: {
                kind: toEntityStatus(dto.status),
                message: null,
            },
            asset: {
                id: dto.asset_id,
            },
            dataset: {
                id: dto.dataset.slug,
            },
            createdAt: dto.created_at,
            updatedAt: dto.updated_at,
        };
    }

    return {
        async find(context, query) {
            const response = await api.import.list(context, {
                asset: query.asset.id,
            });
            return response.data.flatMap((item) => {
                const mapped = toEntity(item);
                return [mapped];
            });
        },
        async create(context, props) {
            const response = await api.import.create(context, {
                ...props,
                asset: props.asset ?? undefined,
            });
            return toEntity(response);
        },
    };
}
