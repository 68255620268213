import moment from 'moment';
import { DataSourceStatus, DataSourceStatusKind } from '../status';
import { DataSourceAggregate } from './sourceItemModel';

export function getSourceStatus(item: DataSourceAggregate): DataSourceStatus {
    if (item.connections.length === 0) {
        // no connections means that the mapping has not yet been synced
        return {
            kind: 'created',
        };
    }
    const [sample] = item.connections;
    const mappedAt = moment(item.mapping.createdAt);
    const lastStatusSyncedAt = moment(sample.updatedAt);
    if (lastStatusSyncedAt.isBefore(mappedAt)) {
        // mapping was modified after the last status so it is stale
        return {
            kind: 'modified',
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'active')) {
        return {
            kind: 'active',
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'importing')) {
        return {
            kind: 'importing',
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'stale')) {
        return {
            kind: 'stale',
        };
    }
    if (item.connections.some((candidate) => candidate.status.kind === 'delayed')) {
        return {
            kind: 'delayed',
        };
    }
    const errorSample = item.connections.find(
        (candidate) => candidate.status.kind === 'error'
    );
    if (errorSample) {
        return {
            kind: 'error',
            key: errorSample.status.message ?? null,
        };
    }
    throw new Error('unexpected item for status');
}
