import { flattenEnumMembers, isTreeType, Property } from '../../../domain/attributes';
import { isEnumType, AnyType, Operator, AnyCondition } from '../../../domain/attributes';

function formatValue(type: AnyType, value: any) {
    if (Array.isArray(value) && isEnumType(type)) {
        return value
            .map(
                (element) =>
                    type.members.find((member) => member.value === element)?.label ??
                    value
            )
            .join(', ');
    }
    if (Array.isArray(value) && isTreeType(type)) {
        return value
            .map(
                (element) =>
                    flattenEnumMembers(type.members).find(
                        (member) => member.value === element
                    )?.label ?? value
            )
            .join(', ');
    }
    if (isEnumType(type)) {
        return type.members.find((member) => member.value === value)?.label ?? value;
    }
    if (isTreeType(type)) {
        return (
            flattenEnumMembers(type.members).find((member) => member.value === value)
                ?.label ?? value
        );
    }
    if (Array.isArray(value)) {
        return `${value.join(', ')}`;
    }
    return value;
}

function formatOperator(operator: Operator) {
    const mapping: { [TKey in Operator]: string } = {
        eq: 'IS',
        in: 'IN',
        previous: 'Previous',
        between: 'between',
        gt: '>',
        gte: '>=',
        lt: '<',
        lte: '<=',
    };
    return mapping[operator];
}

export function formatCondition(property: Property, condition: AnyCondition) {
    if (condition.value === null) {
        return `${property.name} All`;
    }
    if (['eq', 'in'].includes(condition.operator)) {
        return `${property.name}: ${formatValue(property.type, condition.value)}`;
    }
    return `${property.name} ${formatOperator(condition.operator)} ${formatValue(
        property.type,
        condition.value
    )}`;
}
