import React, { useCallback, useState } from 'react';
import { AssistantContainerBaseConfig } from '../../base';
import { ThreadDetailController, ThreadDetailLoader } from './threadDetailInterface';
import { ThreadDetailUrlParamSchema } from './threadDetailSchema';
import { ThreadDetailStateValues } from './threadDetailModel';
import { ThreadDetailContainerProps, ThreadDetailViewProps } from './threadDetailProps';
import { useIsMutating } from '@tanstack/react-query';
import { AnyBlock } from 'src/v2/app/assistant';

export function createThreadDetailContainer(
    config: AssistantContainerBaseConfig,
    loader: ThreadDetailLoader,
    controller: ThreadDetailController,
    View: React.FC<{ children?: React.ReactNode | undefined } & ThreadDetailViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & ThreadDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        repository: { run: runRepository },
        context: {
            root: { useContext },
            thread: { Provider: ThreadContextProvider },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const [state, setState] = useState<ThreadDetailStateValues>({
            chat: { text: '' },
        });
        const context = useContext();
        const options = useOptions(ThreadDetailUrlParamSchema);

        const mutation = {
            create: runRepository.useCreate(context),
        };

        const isSubmitting = mutation.create.status === 'loading';
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {
            status: isSubmitting ? 'processing' : 'settled',
            isSubmitting: isSubmitting,
            error: mutation.create.error instanceof Error ? mutation.create.error : null,
            onSubmit(event) {
                const response = mutation.create.mutate({
                    thread: data.thread,
                    message: {
                        blocks: [
                            {
                                kind: 'text',
                                text: event.message.text,
                                modifiers: null,
                                attachments: null,
                            },
                        ],
                    },
                });
                return;
            },
            chat: {
                // NOTE only allow synchronized message
                isDisabled: isSubmitting,
                state: {
                    text: {
                        value: state.chat.text,
                        onChange(value) {
                            setState({ ...state, chat: { ...state.chat, text: value } });
                        },
                    },
                },
            },
        });

        const onSend = useCallback(
            (block: AnyBlock) => {
                const response = mutation.create.mutate({
                    thread: data.thread,
                    message: {
                        blocks: [block],
                    },
                });
            },
            [mutation.create, data.thread]
        );

        return (
            <ThreadContextProvider item={data} onSend={onSend}>
                <View {...props}>{children}</View>
            </ThreadContextProvider>
        );
    };
}
