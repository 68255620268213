import { ApplicationContext, isProduction } from '../../../base';
import { AnyPlan, PricingPlan, CustomPlan, PlanCategory } from './planType';

export function isRecommendedPlan(plan: PlanCategory) {
    // return plan.id === 'agencies';
    return false;
}

export function isCustomPlan(plan: AnyPlan): plan is CustomPlan {
    return (plan as CustomPlan).custom === true;
}

export function isPricingPlan(plan: AnyPlan): plan is PricingPlan {
    return !isCustomPlan(plan);
}

/**
 * Returns true if free plan should not be displayed as an option to users
 * @param context
 * @returns
 */
export function excludeFreePlan(context: ApplicationContext) {
    return false;// isProduction(context.environment);
}


export function formatPlanName(categoryName: string | undefined, plan: AnyPlan) {
    if(categoryName === plan.name) {
        return plan.name;
    }
    return `${categoryName} - ${plan.name}`;
}