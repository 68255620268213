import { assert } from '../../../../util/assert';
import { QueryExpressionSegment, QueryRangeSegment } from '../../../../../api';
import { periodToRange } from '../../../../../domain';
import { ViewPartitionKeyBySlug } from '../../../../../config/view';
import { DistributionVisualization } from '../../../../domain/visualization';
import { castRankingToCohortState, getPartition, ViewEntity } from '../../../../domain';
import { QueryableContext, QueryableQueryRequest } from '../../../../app';
import { MetricDefinition } from '../../../../domain/metrics';

export function buildDistributionRequest(
    context: QueryableContext,
    view: Pick<ViewEntity, 'id' | 'source' | 'columns'>,
    visualization: DistributionVisualization
): QueryableQueryRequest {
    // const [facet] = visualization.breakdown?.properties ?? [];
    const partition = getPartition(view);
    // assert(facet, 'facet key is required for distribution query');
    assert(partition, 'no partition found on view');

    const [metric] = visualization.metrics as Array<MetricDefinition | undefined>;
    assert(metric, 'distribution visualization requires a metric');
    const partitionKey =
        // HACK we need to use the "source" of the partition property but that is not
        // currently modelled
        ViewPartitionKeyBySlug[view.id] ?? partition.key;

    const period = visualization.period ?? { amount: 4, interval: 'week' };
    const daterange = periodToRange(null, period);

    const cohortstate = castRankingToCohortState(visualization.ranking);

    return {
        projections: [
            {
                name: 'dist',
                columns: [
                    ...(visualization.breakdown?.properties.map((item) => ({
                        key: item.key,
                    })) ?? []),
                    {
                        key: 'dist',
                        type: 'percent',
                    },
                ],
            },
            {
                name: 'absolute',
                columns: [
                    ...(visualization.breakdown?.properties.map((item) => ({
                        key: item.key,
                    })) ?? []),
                    {
                        key: metric.key,
                        alias: null,
                        expr: `total * dist`,
                        type: {
                            kind: 'currency',
                            currency: 'usd',
                            constraints: [],
                        },
                    },
                ],
            },
        ],
        facet: {
            keys: visualization.breakdown?.properties.map((item) => item.key) ?? [],
            bucket_limit: {
                limit: 6,
                over_limit_bucket_name: 'Other',
                value_aggregation: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: metric.key,
                },
            },
            explode_values: true,
        },
        aggregations: [
            {
                name: 'total',
                expr: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: metric.key,
                },
            },
            {
                name: metric.key,
                expr: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: metric.key,
                    partition:
                        visualization.breakdown?.properties.map((item) => item.key) ?? [],
                },
            },
            {
                name: 'dist',
                expr: {
                    kind: 'binary',
                    operator: '/',
                    left: {
                        kind: 'aggregation',
                        type: 'sum',
                        column: metric.key,
                        // partition: [facet.key],
                        partition:
                            visualization.breakdown?.properties.map((item) => item.key) ??
                            [],
                    },
                    right: {
                        kind: 'aggregation',
                        type: 'sum',
                        column: metric.key,
                    },
                },
            },
        ],
        filters: [
            {
                key: partitionKey,
                operator: 'between',
                value: {
                    from: daterange.start,
                    to: daterange.end,
                },
            },
            ...visualization.filters,
        ],
        timeseries: null,
        granularity: visualization.granularity,
        source: { view: view.source },
        comparison: null,
        segment: context.segment,
        segments: [
            {
                kind: 'asset',
                name: 'My company',
            },
            ...(cohortstate.mode === 'fixed'
                ? cohortstate.config.fixed.cohorts
                      .filter((item) => item.visible)
                      .map((item): QueryRangeSegment => {
                          return {
                              kind: 'range',
                              name: item.name,
                              range: {
                                  column: cohortstate.config.fixed.comparison!,
                                  start: {
                                      kind: 'percentile',
                                      value: item.lower,
                                  },
                                  end: {
                                      kind: 'percentile',
                                      value: item.upper,
                                  },
                              },
                              reducer:
                                  visualization.kind === 'distribution'
                                      ? {
                                            kind: 'average',
                                        }
                                      : {
                                            kind: 'percentile',
                                            value: 50,
                                        },
                          };
                      })
                : cohortstate.config.dynamic.cohorts
                      .filter((item) => item.visible)
                      .map(
                          (item): QueryExpressionSegment => ({
                              kind: 'expression',
                              name: item.name,
                              expr: { kind: 'boolean', value: true },
                              reducer: {
                                  kind: 'percentile',
                                  value: item.value,
                              },
                              //   reducer:
                              //       visualization.kind === 'distribution'
                              //           ? {
                              //                 kind: 'average',
                              //             }
                              //           : {
                              //                 kind: 'percentile',
                              //                 value: item.value,
                              //             },
                          })
                      )),
        ],
    };
}
