import { assert } from '../../../util/assert';
import { AnyBillingPlan, SubscriptionUpdateProps } from '../../../domain/billing';
import { SubscriptionAggregate } from './subscriptionEditModel';

export function getSubscriptionRecurringTotalDue(
    item: SubscriptionAggregate,
    plan: AnyBillingPlan,
    props: SubscriptionUpdateProps
) {
    // assert(plan, `plan not found`);
    const addons = plan.add_ons.filter(
        (addon) =>
            !addon.is_one_time_charge &&
            props.addons.some(
                (x) => addon.id === x.addon_id && addon.product_id == x.product_id
            )
    );
    assert(
        addons.every((addon) => addon.currency === plan.currency),
        'mismatch currencies'
    );
    return addons.reduce((acc, addon) => acc + addon.amount, plan.amount);
}
