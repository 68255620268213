import { PlatformHelpControllerConfig } from './platformHelpConfig';
import { PlatformHelpController } from './platformHelpInterface';

export function createPlatformHelpController(
    config: PlatformHelpControllerConfig
): PlatformHelpController {
    const {
        infra: { help },
    } = config;
    return {
        useProps(props) {
            return {
                button: {
                    onClick() {
                        help.show();
                    },
                },
            };
        },
    };
}
