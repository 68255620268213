import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import {
    AnyDashboardTrackingEvent,
    DashboardRankingAppliedEvent,
} from '../dashboardTrackingEvent';

export function createDashboardRankingTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyDashboardTrackingEvent>();
            return tracker;
        }
        const instance = create({
            ...init,
            module: {
                ...init.module,
                createCohortModule(moduleConfig) {
                    return init.module.createCohortModule({
                        ...moduleConfig,
                        provider: {
                            ...moduleConfig.provider,
                            createController(controllerConfig) {
                                const controller =
                                    moduleConfig.provider.createController(
                                        controllerConfig
                                    );
                                return {
                                    useProps(context, options) {
                                        const tracker = useTracker();
                                        const viewProps = controller.useProps(
                                            context,
                                            options
                                        );
                                        return {
                                            ...viewProps,
                                            async onSaveClick() {
                                                const returned =
                                                    await viewProps.onSaveClick();
                                                tracker.track('segment_ranking_applied', {
                                                    // dashboard_id: context.dashboard.id,
                                                    segment_cohorts_visible:
                                                        viewProps.fields.mode.value ===
                                                        'dynamic'
                                                            ? viewProps.fields.dynamicCohorts.value
                                                                  .filter(
                                                                      (item) =>
                                                                          item.visible
                                                                  )
                                                                  .map(
                                                                      (item) => item.name
                                                                  )
                                                            : viewProps.fields.fixedCohorts.value
                                                                  .filter(
                                                                      (item) =>
                                                                          item.visible
                                                                  )
                                                                  .map(
                                                                      (item) => item.name
                                                                  ),
                                                    segment_ranking_mode:
                                                        viewProps.fields.mode.value,
                                                    segment_ranking_metric_key:
                                                        viewProps.fields.mode.value ===
                                                        'fixed'
                                                            ? viewProps.fields.comparison
                                                                  .value
                                                            : null,
                                                });
                                                return returned;
                                            },
                                        };
                                    },
                                };
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
