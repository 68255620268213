import isUrl from 'is-url-superb';
import { z } from 'zod';

export const ReportSelectFormValuesSchema = z.intersection(
    z.object({}),
    z.union([
        z.object({
            mode: z.literal('preset'),
            asset: z.number(),
        }),
        z.object({
            mode: z.literal('new'),
            name: z
                .string({ required_error: 'Company name is required' })
                .min(2, { message: 'Company name must be at least 2 characters long' })
                .max(100, {
                    message: 'Company name must be at at most 100 characters long',
                }),
            url: z

                .string({ required_error: 'URL is required' })
                .min(1, { message: 'URL is required' })
                .max(255, { message: 'URL is too long' }),
        }),
    ])
);
