import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { createHomeControlTrackingStrategy } from './control';
import { createHomeMetricTrackingStrategy } from './metric';
import { createHomeOpportunityTrackingStrategy } from './opportunity';
import { createHomeAlertTrackingStrategy } from './alert';
import { createHomeDestinationTrackingStrategy } from './destination';
import { createHomeSectionTrackingStrategy } from './section';

export function createHomeTrackingStrategy(): ApplicationEntryEnhancer {
    return compose(
        createHomeControlTrackingStrategy(),
        createHomeMetricTrackingStrategy(),
        createHomeOpportunityTrackingStrategy(),
        createHomeAlertTrackingStrategy(),
        createHomeDestinationTrackingStrategy(),
        createHomeSectionTrackingStrategy()
    );
}
