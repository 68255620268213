import { assert } from '../../../util/assert';
import { ConversationMessageAdapter } from '../../../app/assistant';
import { MessageImplConfig } from './messageImplConfig';

export function createMessageImpl(config: MessageImplConfig): ConversationMessageAdapter {
    const {
        api: { assistant: api },
    } = config;

    return {
        async find(context, props) {
            assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.messageList(
                {
                    thread: props.thread.id,
                },
                {
                    headers: {
                        Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                    },
                }
            );
            return response.data.data;
        },
    };
}
