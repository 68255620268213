import { useQuery } from '@tanstack/react-query';
import { assert } from '../../../util/assert';
import { OrganizationRepositoryConfig } from './organizationConfig';
import { OrganizationRepository } from './organizationInterface';

export function createOrganizationRepository(
    config: OrganizationRepositoryConfig
): OrganizationRepository {
    const { adapter } = config;
    return {
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: ['organization-v2', 'lookup', query],
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    assert(response, `organization ${query.id} not found`);
                    return response;
                },
            });
            return result;
        },
    };
}
