import moment from 'moment';
import { castSchemaToDomain } from '../../../v2/impl';
import { getSegmentDescription } from '../../../domain';
import { DateFormatOptions } from '../../../hooks';
import { LineChartDataset } from '../../../ui';
import { formatType, Schema } from '../../../domain';
import { getColorForIndex, getSegmentColor } from '../../../v2/domain/visualization';
import { isEnumType } from '../../../v2/domain/attributes';
import { LineVisualizationStrategy } from './lineModel';
import { formatFacetSeriesLabel } from './lineUtils';
import { LineStrategyConfig } from './lineConfig';
import { LineVisualizationProps } from './component';

export function createLineStrategy(init: LineStrategyConfig): LineVisualizationStrategy {
    return (context, visualization, request, response): LineVisualizationProps => {
        const [valueQuery, ...rest] = response.queries;

        if (!response.metadata.period) {
            throw new Error(`line chart must be have a time-based period`);
        }

        if (rest.length > 0) {
            console.warn('line chart does not support more than 1 queries');
        }

        // if (!request.timeseries) {
        //     throw new Error(`line chart expects timeseries query`);
        // }

        const [partitionKey, ...restPartitions] = response.metadata.partition;
        if (restPartitions.length > 0) {
            console.warn('line chart does not support multiple partition values');
        }

        const [scalarKey, ...restScalars] = response.metadata.scalar;
        if (restScalars.length > 0) {
            console.warn('line chart does not support multiple scalar values');
        }

        let xAxisKey: string | null = null;
        if (request.timeseries) {
            xAxisKey = partitionKey;
        } else if (request.group) {
            const [group, ...restGroups] = request.group;
            if (restGroups.length > 0) {
                throw new Error(
                    `line chart does not support multiple group by columns yet`
                );
            }
            xAxisKey = group.key;
        }

        if (!xAxisKey) {
            throw new Error(`unable to determine line chart x-axis`);
        }

        // const xAxisKey = request.timeseries ? partitionKey :

        // if (!response.facet) {
        //     throw new Error('line chart must be faceted by timestamp');
        // }

        const allProperties: Schema['properties'] = response.queries.reduce(
            (acc, query) =>
                Object.entries(query.schema.properties).reduce(
                    (propAcc, [key, value]) => ({ ...propAcc, [key]: value }),
                    acc
                ),
            {}
        );

        const scalarType = allProperties[scalarKey].type;
        const xAxisType = allProperties[xAxisKey].type;
        const xAxisEnumLabelByValue = isEnumType(xAxisType)
            ? xAxisType.members.reduce(
                  (acc, member) => ({ ...acc, [member.value]: member.label }),
                  {} as Record<string, string | undefined>
              )
            : {};

        // we assume that there is no gaps in the date range returned from the server
        const firstSeriesWithData = response.queries[0]?.series.items?.find((series) =>
            series.data.every((row) => !!row[xAxisKey])
        );

        if (!firstSeriesWithData) {
            throw new Error(`Couldn't find enough data to build x-axis in line-chart`);
        }
        const range = firstSeriesWithData.data.map((row) => {
            const xAxisValue = row[xAxisKey];

            if (xAxisValue === null || xAxisValue === undefined) {
                throw new Error(
                    `x-axis key '${xAxisKey}' not found in row '${JSON.stringify(row)}'`
                );
            }

            const enumLabel = xAxisEnumLabelByValue[xAxisValue as string];
            if (enumLabel) {
                return enumLabel;
            }
            if (typeof xAxisValue !== 'string') {
                throw new Error(
                    `x-axis key '${xAxisKey}' has invalid value '${JSON.stringify(
                        xAxisValue
                    )}'`
                );
            }

            return xAxisValue;
        });

        const datasets = valueQuery.series.items.map<LineChartDataset>(
            (series, index): LineChartDataset => {
                const segment = request.segments?.[index] ?? null;
                return {
                    label: formatFacetSeriesLabel(
                        context,
                        response.facet,
                        // @ts-expect-error
                        allProperties,
                        series
                    ),
                    description: segment
                        ? segment.description ??
                          init.intl.getDescription(segment, {
                              key: scalarKey,
                              ...allProperties[scalarKey],
                          }) ??
                          getSegmentDescription(segment)
                        : undefined,
                    data: series.data.map((row) => row[scalarKey]),
                    colorScheme: segment
                        ? getSegmentColor(segment)
                        : getColorForIndex(index),
                };
            }
        );

        return {
            width: 0,
            height: 0,
            data: {
                labels: range,
                datasets,
            },
            partial: response.metadata.partial,
            getCaption({ rowIndex, columnIndex }) {
                const property = allProperties[scalarKey];
                if (!property) {
                    throw new Error(`caption key '${scalarKey}' not found in schema`);
                }
                const value =
                    valueQuery.series.items[rowIndex]?.data[columnIndex]?.[scalarKey];
                if (!value) {
                    console.warn(
                        `caption not found for row '${rowIndex}' and column '${columnIndex}'`
                    );
                    return value;
                }
                return formatType(context, property.type, value);
            },
            formatUnit: (value) => formatType(context, scalarType, value),
            formatLabel: (label, options) => {
                const optionsFormat: DateFormatOptions = {
                    notation: options.format,
                    granularity: response.metadata.granularity,
                    timezone: 'utc',
                };
                if (
                    options.format === 'long' &&
                    typeof request.timeseries === 'object' &&
                    request.timeseries !== null
                ) {
                    const start = moment.utc(label).toDate();
                    const end = moment
                        .utc(label)
                        .add(1, response.metadata.granularity)
                        .toDate();
                    const formatted = context.formatter.daterange(
                        {
                            start,
                            end,
                        },
                        optionsFormat
                    );
                    return formatted;
                }
                if (
                    options.format === 'short' &&
                    typeof request.timeseries === 'object' &&
                    request.timeseries !== null
                ) {
                    // in timeseries query the y axis is always date
                    return formatType(context, 'date', label, optionsFormat);
                }
                return label;
            },
        };
    };
}
