import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListOffsetResponse } from '../../common';
import { GroupMemberDto } from './memberModel';
import { GroupMemberResource } from './memberInterface';
import { GroupMemberListResponseSchema } from './memberSchema';

export function createGroupMemberResource(client: AxiosInstance): GroupMemberResource {
    return {
        async list(context, query): Promise<ListOffsetResponse<GroupMemberDto>> {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get('/api/v1/traits/group-members', {
                params: {
                    // expand: ['definition'],
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = GroupMemberListResponseSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async bulkUpsert(context, payload) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            await client.put(
                '/api/v1/traits/group-members',
                {
                    asset_ids: payload.asset_ids,
                    group_id: payload.group_id,
                },
                {
                    params: {
                        // expand: ['definition'],
                        ...params,
                    },
                    headers: {
                        ...headers,
                    },
                }
            );
        },
    };
}
