import { InvitationAcceptPayload } from '../../../domain/accounts';
import { AcceptOrganizationFormValues } from './organization';
import { AcceptProfileFormValues } from './profile';
import { RegistrationAcceptCreateContainerProps } from './registrationAcceptCreateProps';
import { RegistrationAcceptCreateAggregate } from './registrationAcceptCreateModel';

export function buildInitialProfileFormValues(
    item: RegistrationAcceptCreateAggregate
): AcceptProfileFormValues {
    return {
        email: item.invitation.email,
        firstname: item.invitation.first_name ?? '',
        lastname: item.invitation.last_name ?? '',
        // role: '',
    };
}

export function buildInitialOrganizationFormValues(
    item: RegistrationAcceptCreateAggregate
): AcceptOrganizationFormValues {
    return {
        name: item.invitation.organization_name ?? '',
        website: item.invitation.url ?? '',
    };
}
