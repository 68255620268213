import { useQuery } from '@tanstack/react-query';
import { TraitAdapter } from './traitAdapter';
import { TraitRepository } from './traitInterface';
import { TraitQuery } from './traitQuery';

export function createTraitRepository(adapter: TraitAdapter): TraitRepository {
    return {
        useFind(query: TraitQuery) {
            return useQuery({
                queryKey: ['traits-v2', query],
                async queryFn() {
                    const response = await adapter.find(query);
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
            });
        },
    };
}
