import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { AlertListViewProps, buildStatusProps } from '../../../common';
import { GroupsActivatedController } from './groupsActivatedInterface';

export const createGroupsActivatedController = (): GroupsActivatedController => {
    return {
        useProps(scope, data) {
            return {
                hexUrl: 'https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/924f316e-8798-4ffe-97f9-0c60e2db0cc6/latest',
                allowExtendedVersion: data.allowExtendedVersion,
                group: data.group,
                getStatusProps() {
                    if (data.allowExtendedVersion) {
                        return buildStatusProps({
                            kind: 'info',
                            label: 'Enabled',
                            description: `Premium mode is enabled`,
                            ordinal: null,
                            value: null,
                        });
                    }
                    return buildStatusProps({
                        kind: 'error',
                        label: 'Disabled',
                        description: `Premium mode is not available`,
                        ordinal: null,
                        reason: null,
                        value: null,
                    });
                },
            };
        },
    };
};
