import { useMemo } from 'react';
import { CompetitveSetStatusQuery } from '../../../../app';
import { AnalysisRouteDeps } from '../analysisRouteConfig';
import { SegmentFactory } from '../segment';
import { CompetitiveStatusLoader } from './competitiveInterface';

export function createCompetitiveLoader(
    config: AnalysisRouteDeps,
    factory: SegmentFactory
): CompetitiveStatusLoader {
    const {
        hook: { useQuery },
        repository: { competitiveStatus: repository },
    } = config;
    return {
        useData(context, state, props) {
            const {
                data: {
                    query: { competitive },
                },
            } = context;
            return {
                isLoading: competitive.isFetching,
                transparency: competitive.data?.transparency ?? null,
            };
            // const query = useMemo<CompetitveSetStatusQuery>(() => {
            //     return {
            //         filters: factory.build(props.plugin, state.value),
            //     };
            // }, [props.plugin, state.value]);
            // // console.log('DEBUG com query', query);
            // const result = useQuery({
            //     ...repository.find(props.context, query),
            //     suspense: true,
            //     staleTime: Infinity,
            // });
            // return { transparency: result.data! };
        },
    };
}
