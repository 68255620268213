import { useLayoutEffect, useRef, useState } from 'react';
import { createVisibleHook } from '../use-element-visible';
import { ChatControllerConfig } from './chatConfig';
import { ChatController } from './chatInterface';
import { ChatViewProps } from './chatProps';
import { ChatMessage } from './message';

export function createChatController(config: ChatControllerConfig = {}): ChatController {
    // const useVisible = createVisibleHook();
    return {
        useProps(item, props): ChatViewProps {
            const containerRef = useRef<HTMLDivElement>(null);
            const composerRef = useRef<HTMLTextAreaElement>(null);
            const [isUserAtBottom, setIsUserAtBottom] = useState(true);
            const [isSubmitting, setIsSubmitting] = useState(false);

            // const visible = useVisible<HTMLDivElement>(containerRef);

            const mostRecent = item.messages[0] as ChatMessage | undefined;

            useLayoutEffect(() => {
                if (containerRef.current) {
                    // containerRef.current.scrollTo(0, containerRef.current.scrollHeight);

                    composerRef.current?.focus();
                }
            }, [mostRecent?.text]);

            const handleScroll = () => {
                const container = containerRef.current;
                if (container) {
                    const { scrollHeight, scrollTop, clientHeight } = container;
                    const isAtBottom = scrollHeight - (scrollTop + clientHeight) < 100;
                    setIsUserAtBottom(isAtBottom);
                }
            };

            const handleSubmit = async () => {
                if (props.state.text.value.trim() === '') return;
                setIsSubmitting(true);
                try {
                    if (containerRef.current) {
                        containerRef.current.scrollTo(
                            0,
                            containerRef.current.scrollHeight
                        );
                    }
                    await props.onSubmit({ text: props.state.text.value });
                    props.state.text.onChange('');
                } finally {
                    setIsSubmitting(false);
                }
            };

            return {
                containerRef,
                composerRef,
                onScroll: handleScroll,
                input: {
                    cursor: props.isDisabled ? 'default' : undefined,
                    isReadOnly: props.isDisabled,
                    value: props.state.text.value,
                    onChange(event) {
                        props.state.text.onChange(event.target.value);
                    },
                    onKeyDown(event) {
                        if (config.submitOnEnter) {
                            // Submit on Enter key press
                            if (
                                event.key === 'Enter' &&
                                !event.shiftKey &&
                                !event.altKey &&
                                !event.metaKey &&
                                !event.ctrlKey
                            ) {
                                event.preventDefault();
                                handleSubmit();
                            }
                        } else {
                            // Default behavior: submit on Command/Ctrl + Enter
                            if (
                                event.key === 'Enter' &&
                                (event.metaKey || event.ctrlKey)
                            ) {
                                event.preventDefault();
                                handleSubmit();
                            }
                        }
                    },
                },
                submit: {
                    isDisabled: isSubmitting || props.state.text.value.trim() === '',
                    isLoading: isSubmitting,
                    onClick: handleSubmit,
                },
            };
        },
    };
}
