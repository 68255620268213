import { chain } from 'lodash';
import { isPluginNestedGroupItem } from '../../../api';
import { PluginEntity } from '../../../domain';
import { IntegrationDefinition } from '../../../domain/assets';
import { PluginConnection } from '../../../domain/metrics';

export function getFastestIntegrationDefinition(
    connections: PluginConnection[],
    items: {
        pluginsById: Record<string, PluginEntity | undefined>;
        definitionsByKind: Record<string, IntegrationDefinition | undefined>;
    }
): IntegrationDefinition | null {
    return chain(connections)
        .flatMap((connection) => {
            if (connection.status === 'pending') {
                return [];
            }
            const plugin = items.pluginsById[connection.plugin.id];
            return (
                plugin?.dependencies.items.flatMap((dependency) => {
                    if (isPluginNestedGroupItem(dependency)) {
                        return dependency.items.flatMap((definition) => {
                            const found = items.definitionsByKind[definition.integration];
                            if (!found) {
                                return [];
                            }
                            return [found];
                        });
                    }
                    const found = items.definitionsByKind[dependency.integration];
                    if (!found) {
                        return [];
                    }
                    return [found];
                    // return dependency.definitions.flatMap((definition) => {
                    //     const found = items.definitionsByKind[definition.kind];
                    //     if (!found) {
                    //         return [];
                    //     }
                    //     return [found];
                    // });
                }) ?? []
            );
        })
        .uniqBy((item) => item?.id)
        .minBy((item) =>
            item.refreshRate.range.to === null ? Infinity : item.refreshRate.range.to
        )
        .defaultTo(null)
        .value();
}

export function formatMinuteDurationRange(startMinutes: number, endMinutes: number) {
    if (endMinutes < 60) {
        return `${startMinutes}-${endMinutes} min`;
    } else if (startMinutes >= 60) {
        const startHours = Math.floor(startMinutes / 60);
        const endHours = Math.floor(endMinutes / 60);
        return `${startHours}-${endHours} hours`;
    } else {
        const endHours = Math.floor(endMinutes / 60);
        if (startMinutes === 0) {
            return `0-${endHours} hours`;
        }
        return `${startMinutes} min - ${endHours} hours`;
    }
}
