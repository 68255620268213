import { UseFormReturn } from 'react-hook-form';
import { PluginConnectionStatus } from 'src/v2/domain/metrics';
import { CollectionNewAggregate, CollectionNewFormValues } from '../collectionNewModel';
import { MetricCollectionNewItemProps } from './collectionNewItemProps';

export function getCollectionNewItemProps(
    form: UseFormReturn<CollectionNewFormValues>,
    state: CollectionNewFormValues,
    item: CollectionNewAggregate
): MetricCollectionNewItemProps {
    const isSelected = state.selected[item.metric.id] ?? false;
    const selectable: PluginConnectionStatus[] = ['syncing', 'ready', 'disconnected'];
    return {
        isSelected,
        isSelectable: item.connection
            ? selectable.includes(item.connection.status)
            : false,
        onSelect() {
            if (isSelected) {
                const { [item.metric.id]: _removed, ...rest } = state.selected;
                form.setValue('selected', rest);
            } else {
                form.setValue('selected', { ...state.selected, [item.metric.id]: true });
            }
        },
    };
}
