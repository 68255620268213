import {
    AssetType,
    CurrencyType,
    DateType,
    EnumLikeType,
    EnumType,
    isAssetType,
    isCurrencyType,
    isDateType,
    isEnumLikeType,
    isEnumType,
    isReferenceType,
    isTreeType,
    ReferenceType,
    TreeType,
} from '../type';
import { Property } from './propertyModel';

export function isReferenceProperty(
    property: Property
): property is Property<ReferenceType> {
    return isReferenceType(property.type);
}

export function isTreeProperty(property: Property): property is Property<TreeType> {
    return isTreeType(property.type);
}

export function isAssetProperty(property: Property): property is Property<AssetType> {
    return isAssetType(property.type);
}

export function isEnumProperty(property: Property): property is Property<EnumType> {
    return isEnumType(property.type);
}

export function isEnumLikeProperty(
    property: Property
): property is Property<EnumLikeType> {
    return isEnumLikeType(property.type);
}

export function isCurrencyProperty(
    property: Property
): property is Property<CurrencyType> {
    return isCurrencyType(property.type);
}

export function isDateProperty(property: Property): property is Property<DateType> {
    return isDateType(property.type);
}
