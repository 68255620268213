import { Tooltip as Base } from './Tooltip';
import { CardTooltipContainer as Container } from './TooltipContainer';
import { CardTooltipTitle as Title } from './TooltipTitle';
import { CardTooltipText as Text } from './TooltipText';

export const Tooltip = {
    Tooltip: Base,
    Container,
    Title,
    Text,
};

// export * from './Tooltip';

export type { TooltipProps } from './Tooltip';
