import { useMemo } from 'react';
import { DatasetConnectionListViewConfig } from './connectionListConfig';
import { DatasetConnectionListController } from './connectionListInterface';
import { DatasetConnectionListViewProps } from './connectionListProps';
import { buildDatasetConnectionItemProps, DatasetConnectionItemProps } from '../item';
import { chain } from 'lodash';

export function createDatasetConnectionListController(
    config: DatasetConnectionListViewConfig
): DatasetConnectionListController {
    const {
        infra: { formatter },
    } = config;

    return {
        useProps(context, props): DatasetConnectionListViewProps {
            const itemPropsByDataset = useMemo(
                () =>
                    chain(props.items)
                        .reduce(
                            (acc, item) => ({
                                ...acc,
                                [item.view.id]: buildDatasetConnectionItemProps(
                                    formatter,
                                    item
                                ),
                            }),
                            {} as Record<string, DatasetConnectionItemProps>
                        )
                        .value(),
                [props.items]
            );

            const items = useMemo(() => {
                return chain(props.items)
                    .orderBy(
                        (item) => {
                            // if (item.connections.length === 0) {
                            //     return [-1];
                            // }
                            const itemProps = itemPropsByDataset[item.view.id];
                            return [itemProps.iconUrl, itemProps.status.ordinal];
                        },
                        ['asc', 'asc']
                    )
                    .value();
            }, [props.items]);

            return {
                items: items,
                getItemProps(item) {
                    const itemProps = itemPropsByDataset[item.view.id];
                    return itemProps;
                },
            };
        },
    };
}
