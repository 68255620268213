import { getChildrenProps } from '../../util';
import { DatePickerComponents } from './chakraDatePickerInterface';
import { DatePickerContainerProps, DatePickerLayoutProps } from './chakraDatePickerProps';

export function buildDatePickerLayoutProps(
    Components: DatePickerComponents,
    props: DatePickerContainerProps
): DatePickerLayoutProps {
    // console.log('DEBUG props factory', props);
    const childProps = {
        ...getChildrenProps(props.children, {
            relative: Components.Relative,
            absolute: Components.Absolute,
        }),
    };
    return {
        container: props,
        relative: childProps.relative ?? null,
        absolute: childProps.absolute ?? null,
    };
}
