import pluralize from 'pluralize';
import { isRelativePeriod } from '../../../../domain';
import { getAnalysisMetricLink } from '../../../route';
import { toVisualization } from '../../../app/visualizations';
import { ReportInsightAggregate } from '../../../domain/report';
import { EmailReportController } from './emailReportInterface';
import { EmailReportConfig } from './emailReportConfig';

export function createEmailReportController(
    config: Pick<EmailReportConfig, 'service' | 'strategy'>
): EmailReportController {
    const {
        service: {
            report: { useReport },
            workspace: { useWorkspace },
        },
    } = config;
    return {
        useProps(context, definition, payload) {
            const workspace = useWorkspace(payload.workspace);
            const report = useReport(context, {
                definition,
                workspace: payload.workspace,
                period: {
                    interval: payload.period,
                    amount: 1,
                },
                date: payload.date,
                granularity: payload.granularity,
            });

            const [first, ...rest] = report.insights as Array<
                ReportInsightAggregate | undefined
            >;

            return {
                workspace,
                report,
                control: {
                    date: {
                        granularity: report.granularity,
                        period: report.absolute,
                        comparison: report.comparison,
                    },
                },
                insightItems: first ? [first] : [],
                insightButtonLabel:
                    rest.length > 0
                        ? `View ${pluralize('opportunity', rest.length)}`
                        : `View all opportunities`,
                insightButtonHref: `/u/assets/${payload.workspace.id}/opportunities`,
                metadata: {
                    dashboardCount: report.dependencies.filter(
                        (status) => status.status === 'ready'
                    ).length,
                    insightCount: report.insights.length,
                },
                period: report.absolute,
                reportHref: isRelativePeriod(report.period)
                    ? `/u/assets/${payload.workspace.id}/home`
                    : `/u/assets/${payload.workspace.id}/home`,
                emptyStateHref: `/u/integrations`,
                getDependencyProps(item) {
                    return {
                        path: `/u/assets/${payload.workspace.id}/dashboards/${item.dashboard.id}`,
                    };
                },
                getInsightProps(item) {
                    const visualization = toVisualization(
                        config.strategy.visualizable,
                        item.insight
                    );
                    if (!visualization) {
                        console.warn(`failed to create analytics link`);
                    }
                    return {
                        path: visualization
                            ? getAnalysisMetricLink(
                                  {
                                      workspace: payload.workspace,
                                  },
                                  visualization
                              )
                            : `/u/assets/${payload.workspace.id}/home`,
                        iconUrl: item.plugin.iconUrl,
                    };
                },
            };
        },
    };
}
