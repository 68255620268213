import { Box, BoxProps, Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Graphics } from '../../../../config/svg';
import { AssistantViewBaseConfig } from '../../base';
import { ThreadNewViewProps } from './threadNewProps';

export function createThreadNewView(
    config: AssistantViewBaseConfig
): React.FC<{ children?: React.ReactNode | undefined } & ThreadNewViewProps> {
    const {
        Layout,
        UI: {
            Application: { Link },
        },
    } = config;

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    return (props) => {
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Layout title={undefined}>
                    <HStack w="full" justify="center" p={{ base: 6, '2xl': 16 }}>
                        <VStack
                            align="center"
                            w="full"
                            justify="center"
                            maxW={containerStyle.maxW}
                            spacing={8}
                            p={6}
                        >
                            <Box
                                p={4}
                                borderWidth={2}
                                borderColor="whiteAlpha.300"
                                color="whiteAlpha.600"
                                borderRadius="full"
                            >
                                <Image
                                    position="relative"
                                    left={-0.5}
                                    top={0.5}
                                    w={4}
                                    h={4}
                                    src={Graphics.Brand.Icon}
                                />
                            </Box>
                            <VStack
                                align="center"
                                textAlign="center"
                                w="full"
                                spacing={2}
                                // maxW={{ base: '24rem', '2xl': '32rem' }}
                                maxW="24rem"
                            >
                                <Text fontSize="xl" fontWeight="medium">
                                    {props.workflow.title}
                                </Text>
                                {props.workflow.description && (
                                    <Text fontSize="md" color="whiteAlpha.700">
                                        {props.workflow.description}
                                    </Text>
                                )}
                            </VStack>
                            <VStack spacing={2}>
                                <Button
                                    {...props.form.getSubmitButtonProps()}
                                    size="md"
                                    colorScheme="green"
                                    _focus={{ outline: 'none' }}
                                >
                                    {props.workflow.button.label}
                                </Button>
                                <Link {...props.terms.link}>
                                    <Text
                                        color="whiteAlpha.600"
                                        // fontStyle="italic"
                                        fontSize="sm"
                                        // fontWeight="medium"
                                        _hover={{ textDecor: 'underline' }}
                                    >
                                        Terms of service
                                    </Text>
                                </Link>
                            </VStack>
                        </VStack>
                    </HStack>
                </Layout>
            </form>
        );
    };
}
