import pluralize from 'pluralize';
import { assert } from '../../../util/assert';
import { DashboardGroupItemViewProps } from '../..';
import { getPeerHealthcheckStatus, getPeerSetStatus } from '../../../app';
import { buildStatusProps, StatusProps } from '../../common';
import { DashboardGroupController } from './dashboardGroupInterface';

export function createDashboardGroupController(): DashboardGroupController {
    return {
        useProps(item, props) {
            return {
                items: item.group.items,
                getItemProps(item): DashboardGroupItemViewProps {
                    const isSelected = props.selected.value.includes(item.peerset.id);

                    const toggle = isSelected
                        ? () => {
                              props.selected.onChange(
                                  props.selected.value.filter(
                                      (candidate) => candidate !== item.peerset.id
                                  )
                              );
                          }
                        : () => {
                              props.selected.onChange([
                                  ...props.selected.value,
                                  item.peerset.id,
                              ]);
                          };

                    const plural = pluralize('member', item.peerset.members.length);

                    const groupstatus = getPeerSetStatus({
                        peerset: item.peerset,
                        invitation: null,
                    });

                    let status: null | StatusProps = null;

                    assert(
                        item.plugin,
                        'expected plugin healthcheck to have been loaded'
                    );
                    const healthStatus = getPeerHealthcheckStatus(
                        item.plugin.healthcheck
                    );

                    if (groupstatus.kind === 'ineligible') {
                        status = buildStatusProps({
                            value: groupstatus.kind,
                            kind: 'warning',
                            label: 'Ineligible',
                            description: `Group does not meet the minimum number of required companies`,
                            ordinal: 1,
                            isDisabled: true,
                        });
                    } else if (groupstatus.kind === 'updating') {
                        status = buildStatusProps({
                            value: groupstatus.kind,
                            kind: 'warning',
                            label: 'Processing',
                            description: `Group changes are being processed`,
                            ordinal: 1,
                        });
                    } else if (healthStatus === 'insufficient') {
                        status = buildStatusProps({
                            value: groupstatus.kind,
                            kind: 'warning',
                            label: 'Ineligible',
                            description: `Not enough group members are connected to this dataset`,
                            ordinal: 1,
                            isDisabled: true,
                        });
                    }

                    return {
                        key: item.peerset.id,
                        status,
                        label: item.peerset.name,
                        checkbox: {
                            isChecked: isSelected,
                            onChange: toggle,
                        },
                        container: {
                            onClick: status?.isDisabled ? undefined : toggle,
                        },
                        count: {
                            members: {
                                label: `${item.peerset.members.length} ${plural}`,
                            },
                        },
                    };
                },
            };
        },
    };
}
