import { StudiesControllerBaseConfig } from '../../base';
import { StudiesChartRouteController } from './studiesChartItemInterface';
import { StudiesChartRouteViewProps } from './studiesChartItemProps';

export function createStudiesChartRouteController(
    config: StudiesControllerBaseConfig
): StudiesChartRouteController {
    const {
        infra: { formatter },
        macro: {
            visualization: {
                chart: { controller: chartController },
            },
        },
    } = config;
    return {
        useProps(context, item, props): StudiesChartRouteViewProps {
            const chartProps = chartController.useProps(context, item.visualizationV2, {
                kind: 'bar',
            });
            return {
                count: {
                    total: item.count.total,
                },
                responses: {
                    relative: {
                        label: formatter.percent(item.count.answers / item.count.total),
                    },
                    absolute: {
                        label: item.count.total.toString(),
                    },
                },
                chart: chartProps,
                // result: resultProps,
                scale: {
                    label: item.column.name,
                    startAtZero: true,
                    mode: props.mode,
                },
            };
        },
    };
}
