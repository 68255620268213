import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { StudiesSubmissionNewRouteConfig } from './studiesSubmissionNewConfig';
import { StudiesSubmissionNewDepsProvider } from './studiesSubmissionNewInterface';
import { StudiesSubmissionNewFormValues } from './studiesSubmissionNewModel';
import { createStudiesSubmissionFormSchema } from './studiesSubmissionNewSchema';

export function createStudiesSubmissionNewDepsProvider(
    config: Pick<StudiesSubmissionNewRouteConfig, 'infra' | 'strategy' | 'repository'>
): StudiesSubmissionNewDepsProvider {
    const {
        infra: { toaster },
        repository: {
            submission: submissionRepository,
            // invitation: invitationRepository,
            // group: groupRepository,
        },
    } = config;
    return {
        useDeps(context, loaded) {
            const schema = createStudiesSubmissionFormSchema(
                config.strategy.schema.getSchema(loaded.data.form)
            );

            const form = useForm<StudiesSubmissionNewFormValues>({
                defaultValues: {
                    dataset: loaded.data.form.dataset.id,
                    // invite: {
                    //     text: '',
                    // },
                    payload: config.strategy.schema.getDefaultValues(loaded.data.form),
                },
                resolver: zodResolver(schema),
            });

            return {
                navigate: useNavigate(),
                toast: toaster.useToast(),
                form,
                formValues: form.watch(),
                mutation: {
                    submission: submissionRepository.useCreate(context),
                    // group: groupRepository.useCreate(context),
                    // invite: invitationRepository.useCreate(context),
                },
            };
        },
    };
}
