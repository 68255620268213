import { useMutation } from '@tanstack/react-query';
import { SubscriptionUpdateProps } from '../../../domain/billing';
import { BillingServiceConfig } from '../billingConfig';
import { BillingSubscriptionService } from './subscriptionEditInterface';

export function createBillingSubscriptionService(
    config: Pick<BillingServiceConfig, 'repository'>
): BillingSubscriptionService {
    const {
        repository: { subscription: repository },
    } = config;
    return {
        useUpdate(context, item) {
            const base = repository.useUpdate(context);
            const mutation = useMutation<void, Error, SubscriptionUpdateProps>({
                async mutationFn(props) {
                    const response = await base.mutateAsync({
                        subscriptionId: item.subscription.id,
                        plan: props.plan,
                        add_ons: props.addons,
                        options: {
                            wait: 15000,
                            idempotency_key: `subscription_${item.subscription.id}_${Date.now().toString()}`,
                        },
                    });
                    return;
                },
            });
            return mutation;
        },
        useRefresh(context) {
            const mutation = repository.useRefresh(context);
            return mutation;
        },
    };
}
