import { first, last } from 'lodash';
import { RangeInputProps } from '../../../ui/application/impl/forms';
import {
    isNumericBetweenConstraint,
    BetweenConstraintExpression,
    Constraint,
    AnyFilter,
} from '../../../domain/dashboard';
import {
    getIntervalsByConstraint,
    isGreaterThanCondition,
    isGreaterThanOrEqualCondition,
    isLessThanCondition,
    isLessThanOrEqualCondition,
    isNumericBetweenCondition,
    NumericBetweenCondition,
} from '../../../domain/attributes';
import { SegmentItemControllerProps } from './segmentProps';

export function castFilterToRangeInputProps(
    props: SegmentItemControllerProps,
    filter: AnyFilter
): RangeInputProps {
    const [constraint, ...rest] = filter.property.constraints.filter(
        isNumericBetweenConstraint
    ) as Array<Constraint<BetweenConstraintExpression> | undefined>;

    if (rest.length > 0) {
        console.warn('range input does not support multiple between constraints');
    }
    if (!constraint) {
        console.warn('no between constraint found for segment range input');
    }

    const actual = props.value;
    // console.log('props', props);

    // const defaultMin = isNumericBetweenCondition(actual) ? actual.value.from : 0;
    // const defaultMax = isNumericBetweenCondition(actual) ? actual.value.to : 100;

    const intervals = getIntervalsByConstraint(constraint ?? null);
    // const { from: min = 0, to: max = 1000 } = constraint?.expression.value ?? {};
    const min = first(intervals);
    const max = last(intervals);

    if (!min || !max) {
        throw new Error(`invalid interval`);
    }

    let value: [number, number] | null = null;

    const condition = actual?.value
        ? {
              key: filter.property.key,
              operator: filter.default?.operator,
              value: actual.value,
          }
        : null;

    // if (!actual?.value) {
    //     value = [min, max];
    // }
    // else if (isLessThanCondition(condition) || isLessThanOrEqualCondition(condition)) {
    //     value = [min, condition.value];
    // } else if (
    //     isGreaterThanCondition(condition) ||
    //     isGreaterThanOrEqualCondition(condition)
    // ) {
    //     value = [condition.value, max];
    // }

    // else if (filter.default.operator === 'between') {
    value = [actual?.value?.from ?? min, actual?.value?.to ?? max];

    // console.log('DEBUG range VALUE', value);
    // } else {
    //     throw new Error(
    //         `unexpected segment value for range input ${JSON.stringify(actual)}`
    //     );
    // }

    function isLessThan(start: number, end: number) {
        return start === first(intervals);
    }

    function isGreaterThan(start: number, end: number) {
        return end === last(intervals);
    }

    // console.log('filter', filter.property.key, filter.property);

    return {
        type: 'range',
        variant: 'slider',
        style: props.style,
        isDisabled: props.filter.property.isDisabled,
        min,
        max,
        value,
        // steps: intervals.length,
        intervals: intervals ?? [],
        onChangeEnd([start, end]) {
            if (start === min && end === max) {
                return props.onClear();
            }
            if (isGreaterThan(start, end)) {
                const value: NumericBetweenCondition['value'] = {
                    from: start,
                    to: null,
                };
                return props.onChange({
                    // key: props.filter.property.key,
                    // operator: 'gte',
                    // value: start,
                    value,
                });
            }
            if (isLessThan(start, end)) {
                const value: NumericBetweenCondition['value'] = {
                    from: 0,
                    to: end,
                };
                return props.onChange({
                    // key: props.filter.property.key,
                    // operator: 'lte',
                    // value: end,
                    value,
                });
            }
            const value: NumericBetweenCondition['value'] = {
                from: start,
                to: end,
            };
            return props.onChange({
                // key: props.filter.property.key,
                // operator: 'between',
                value,
            });
        },
        getLabel([start, end]) {
            // console.log('range', start, end);
            let formatStart = props.format(filter.property.type, start, {
                notation: 'short',
            });
            let formatEnd = props.format(filter.property.type, end, {
                notation: 'short',
            });
            if (isLessThan(start, end)) {
                formatStart = props.format(filter.property.type, start, {
                    notation: 'short',
                });
            }
            if (isGreaterThan(start, end)) {
                formatEnd = `${formatEnd}+`;
            }
            return `${formatStart} - ${formatEnd}`;
        },
    };
}
