import { ImageItemViewProps } from '../item';
import { ImageListController } from './imageListInterface';

export function createImageListController(): ImageListController {
    return {
        useProps(item, _props) {
            return {
                items: item.items.map(
                    (item): ImageItemViewProps => ({
                        id: item.url,
                        image: {
                            alt: item.name ?? 'N/A',
                            src: item.url,
                        },
                    })
                ),
            };
        },
    };
}
