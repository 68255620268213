import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { PeerMemberEditFormProvider } from './peerMemberEditInterface';
import { PeerMemberEditFormValues } from './peerMemberEditModel';
import { PeerMemberEditFormValueSchema } from './peerMemberEditSchema';

export function createPeerMemberEditFormProvider(): PeerMemberEditFormProvider {
    return {
        useForm(context) {
            const form = useForm<PeerMemberEditFormValues>({
                defaultValues: {
                    companies: [],
                },
                resolver: zodResolver(PeerMemberEditFormValueSchema),
            });
            return form;
        },
    };
}
