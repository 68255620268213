import { EnumMember, EnumMemberWithChildren } from '../../domain/attributes';
import { FormatContextValue } from './formatInterface';

export const VERTICAL_KEY = 'vertical-v2';
export const COUNTRY_KEY = 'country';

export const COUNTRY_TYPE_SLUG = 'builtins.country';

export const OPERATOR_LABEL_BY_KEY: Record<string, string | null> = {
    lte: 'less than',
    lt: 'less than',
    gte: 'greater than',
    gt: 'greater than',
    eq: 'equals',
    in: 'in',
    between: 'between',
};

export function createProperties(config: {
    enum: {
        verticals: EnumMemberWithChildren[];
        countries: EnumMember[];
    };
}): FormatContextValue['properties'] {
    const properties: FormatContextValue['properties'] = [
        {
            key: VERTICAL_KEY,
            name: 'Vertical',
            description: null,
            type: {
                kind: 'list',
                element: {
                    kind: 'enum',
                    members: config.enum.verticals.flatMap((item) => [
                        ...item.children,
                        item,
                    ]),
                },
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: COUNTRY_KEY,
            name: 'Country',
            description: null,
            type: {
                kind: 'list',
                element: {
                    kind: 'enum',
                    members: config.enum.countries,
                },
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'avg_monthly_spend',
            name: 'Monthly spend',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'aov',
            name: 'AOV',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'avg_monthly_spend_all_platforms',
            name: 'Total monthly spend',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'shopify_6mo_revenue',
            name: 'Monthly revenue',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'shopify_aov',
            name: 'AOV',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'googleads_aov',
            name: 'AOV',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'googleads_avg_monthly_spend',
            name: 'Monthly spend',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
        {
            key: 'tiktok_avg_monthly_spend',
            name: 'Monthly spend',
            description: null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: [],
            isDisabled: false,
            disabledReason: null,
        },
    ];
    // console.log('config.enum.verticals', config.enum.verticals);
    // console.log('properties', properties);
    return properties;
}
