import React from 'react';
import { Tooltip, Wrap, WrapItem, Button } from '@chakra-ui/react';
import { RadioGroupProps, RadioItemProps } from '../radioProps';
import { getAllChildrenProps } from '../../../../../../util';

export const RadioButtonGroupList: React.FC<
    { children?: React.ReactNode | undefined } & RadioGroupProps
> = (props) => {
    const { itemProps: itemPropsList } = getAllChildrenProps(props.children, {
        itemProps: RadioButtonGroupItem,
    });

    return (
        <Wrap {...props.style}>
            {itemPropsList.map((itemProps) => {
                const isChecked = itemProps.value === props.value;
                const handleClick: React.MouseEventHandler<HTMLButtonElement> = (
                    event
                ) => {
                    itemProps.onClick?.(event);
                    if (!itemProps['aria-disabled']) {
                        props.onChange(itemProps.value);
                        return;
                    }
                };
                return (
                    <WrapItem key={itemProps.value} as="label">
                        <Tooltip {...itemProps.tooltip} isDisabled={!itemProps.tooltip}>
                            <span>
                                <Button
                                    {...itemProps.style}
                                    isDisabled={itemProps.isDisabled}
                                    variant="outline"
                                    aria-checked={isChecked}
                                    aria-disabled={itemProps?.['aria-disabled']}
                                    leftIcon={itemProps.leftIcon}
                                    _focus={{
                                        outline: 'none',
                                    }}
                                    onClick={handleClick}
                                >
                                    {itemProps.children}
                                </Button>
                            </span>
                        </Tooltip>
                    </WrapItem>
                );
            })}
        </Wrap>
    );
};

export const RadioButtonGroupItem: React.FC<
    { children?: React.ReactNode | undefined } & RadioItemProps
> = (props) => {
    return <>{props.children}</>;
};
