import React from 'react';
import { LayoutCollectionViewProps } from '../../../view/common';

export function createDomainLayoutCollectionView(): React.FC<
    { children?: React.ReactNode | undefined } & LayoutCollectionViewProps
> {
    return (props) => {
        return <></>;
    };
}
