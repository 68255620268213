import { useMemo } from 'react';
import { PluginItemAggregate, PluginListAggregate } from '../../../../view';
import { PluginConnection } from '../../../../domain/metrics';
import { assert } from '../../../../util/assert';
import { SettingAssetPluginConfig } from './pluginConfig';
import { SettingAssetPluginLoader } from './pluginInterface';

export function createSettingAssetPluginLoader(
    config: Pick<SettingAssetPluginConfig, 'repository'>
): SettingAssetPluginLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const plugins = repository.plugin.useFind(context, {}, { suspense: true });
            const connections = repository.connection.useFind(
                context,
                { workspaces: [context.workspace], plugins: plugins.data ?? [] },
                { suspense: true }
            );
            assert(plugins.status === 'success', 'expected suspense query');
            assert(connections.status === 'success', 'expected suspense query');

            const connectionsByPlugin = useMemo(
                () =>
                    connections.data.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [connections.data]
            );

            return useMemo<PluginListAggregate>(() => {
                return {
                    items: plugins.data.flatMap((plugin): PluginItemAggregate[] => {
                        const connection = connectionsByPlugin[plugin.id];
                        if (!connection) {
                            console.warn(
                                `plugin connection for plugin ${plugin.id} not found`,
                                connectionsByPlugin
                            );
                            return [];
                        }
                        return [{ plugin, connection }];
                    }),
                };
            }, [plugins.data, connections.data]);
        },
    };
}
