import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { MenuLabelProps, MenuListLabel } from '../label';

function applyMenuListChildDefaults(child: React.ReactElement) {
    if (child.type == Link) {
        const linkProps: LinkProps = {
            ...child.props,
            style: {
                width: '100%',
            },
        };
        return React.cloneElement(child, linkProps);
    }
    if (child.type == MenuListLabel) {
        const labelProps: MenuLabelProps = {
            stackProps: {
                // add spacing to labels
                mb: 2,
            },
            ...child.props,
        };
        return React.cloneElement(child, labelProps);
    }
    return child;
}

/**
 * Extract sticky label if any from child nodes
 * @param children
 * @returns
 */
export function createMenuListChildren(children: React.ReactNode[]) {
    const childrenElements = React.Children.toArray(children) as React.ReactElement[];
    const childElementsWithDefaults = childrenElements.map(applyMenuListChildDefaults);
    const [stickyLabel] = childElementsWithDefaults.filter((child: any, index) => {
        const isSticky =
            child.type === MenuListLabel && (child.props as MenuLabelProps).sticky;
        if (isSticky && index !== 0) {
            console.warn(`MenuList expects only the first label to be 'sticky'`);
        }
        return isSticky;
    });
    const childrenWithoutSticky = childElementsWithDefaults.filter(
        (element) => element !== stickyLabel
    ) as React.ReactElement[];

    return { sticky: stickyLabel, children: childrenWithoutSticky };
}
