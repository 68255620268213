import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { z } from 'zod';
import { FeatureFlagEnhancerConfig } from '../featureFlagConfig';
import { FeatureFlagContext } from '../featureFlagConstant';
import { FeatureFlagContextValue, FeatureFlags } from '../featureFlagModel';
import { UrlParametersSchema } from '../featureFlagSchema';
import { FeatureFlagForm } from './FeatureFlagForm';

export function createFeatureFlagProvider(
    config: FeatureFlagEnhancerConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    return (props) => {
        const disclosure = useDisclosure();
        const [raw, setFlags] = useLocalStorage<FeatureFlags>(
            config.storageKey,
            config.initial
        );

        const Schema = z.object({
            home: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.home?.enabled ?? false }),
            insights: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.insights?.enabled ?? false }),
            dynamicDashboards: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.dynamicDashboards?.enabled ?? false }),
            itbenchmarks: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.itbenchmarks?.enabled ?? false }),
            // studies: z
            //     .object({
            //         enabled: z.boolean(),
            //     })
            //     .default({ enabled: config.initial.studies?.enabled ?? false }),
            peers: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.peers?.enabled ?? false }),
            chat: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.chat?.enabled ?? false }),
            expertCalls: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.expertCalls?.enabled ?? false }),
            permissions: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.permissions?.enabled ?? false }),
            fixedCohorts: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.fixedCohorts?.enabled ?? false }),
            bypassWorkspaceOnboarding: z
                .object({
                    enabled: z.boolean(),
                })
                .default({ enabled: config.initial.fixedCohorts?.enabled ?? false }),
            experimentalDashboardFilters: z
                .object({
                    enabled: z.boolean(),
                })
                .default({
                    enabled:
                        config.initial.experimentalDashboardFilters?.enabled ?? false,
                }),
            exampleMode: z
                .object({
                    enabled: z.boolean(),
                })
                .default({
                    enabled: config.initial.exampleMode?.enabled ?? false,
                }),
            draftReports: z
                .object({
                    enabled: z.boolean(),
                })
                .default({
                    enabled: config.initial.draftReports?.enabled ?? false,
                }),
        });

        const parsed = Schema.safeParse(raw);
        const flags: FeatureFlags = parsed.success ? parsed.data : config.initial;

        const handleChange = (value: FeatureFlags) => {
            setFlags(value);
        };

        useEffect(() => {
            try {
                const searchParams = new URLSearchParams(window.location.search);
                const urlParams = [...searchParams.entries()].reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key]: value,
                    }),
                    {}
                );
                const params = UrlParametersSchema.parse(urlParams);
                if (params.show_features) {
                    disclosure.onOpen();
                }
            } catch (error) {
                console.error('failed to toggle feature flag', error);
            }
        }, []);

        const value: FeatureFlagContextValue = {
            flags,
        };

        return (
            <FeatureFlagContext.Provider value={value}>
                {props.children}
                <Modal
                    isOpen={disclosure.isOpen}
                    onClose={disclosure.onClose}
                    blockScrollOnMount={false}
                >
                    {/* <ModalOverlay /> */}
                    <ModalContent>
                        <ModalHeader>Feature flags</ModalHeader>
                        <ModalBody>
                            <FeatureFlagForm value={flags} onChange={handleChange} />
                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" onClick={disclosure.onClose}>
                                Done
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </FeatureFlagContext.Provider>
        );
    };
}
