import { isEnumType } from '../../../../domain/attributes';
import { buildTypeItemViewProps } from '../../../attributes';
import { PropertyItemAggregate } from './propertyItemModel';
import { PropertyItemViewProps } from './propertyItemProps';

export function buildPropertyItemProps(
    item: PropertyItemAggregate
): PropertyItemViewProps {
    return {
        label: item.property.name,
        description: item.property.description,
        source: {
            label: item.property.key,
        },
        required: item.property.isRequired
            ? {
                  label: 'Yes',
                  isChecked: true,
              }
            : {
                  label: 'No',
                  isChecked: true,
              },
        type: buildTypeItemViewProps({
            type: item.property.type,
            members: isEnumType(item.property.type) ? item.property.type.members : [],
        }),
    };
}
