import React from 'react';
import { Videos } from '../../../../config';
import { RegistrationContainerConfig } from '../../base';
import { RegistrationAcceptContextProvider } from '../context';
import { RegistrationAcceptWelcomeController } from './acceptWelcomeInterface';
import { AcceptWelcomeViewProps } from './acceptWelcomeProps';

export function createRegistrationAcceptWelcomeContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptWelcomeController,
    View: React.FC<{ children?: React.ReactNode | undefined } & AcceptWelcomeViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        accept: { useContext },
    } = config;
    return (_containerProps) => {
        const context = useContext();

        const props = controller.useProps(context, {
            video: Videos.INVITE_WELCOME,
            stepper: context.stepper,
            page: {
                metadata: context.metadata,
            },
        });
        return <View {...props} />;
    };
}
