import { useMemo } from 'react';
import { PageIds } from '../../../../config';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { DatasetConnectionListAggregate } from '../../../../view/datasets';
import { SettingDatasetConnectionConfig } from './connectionConfig';
import { SettingDatasetConnectionController } from './connectionInterface';

export function createSettingDatasetConnectionController(
    init: Pick<SettingDatasetConnectionConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingDatasetConnectionController {
    const {
        controller: {
            dataset: {
                connection: { list: viewController },
            },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_DATASET_CONNECTION,
                    breadcrumbs: [
                        {
                            label: 'Datasets',
                        },
                    ],
                },
            });

            const viewProps = viewController.useProps(context, props);

            return {
                getViewProps() {
                    return viewProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getNewLinkProps() {
                    return {
                        to: `/u/assets/${context.workspace.id}/settings/data-sources`,
                    };
                },
            };
        },
    };
}
