import { makeObservable, observable, runInAction } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { WorkspaceStore, ControlDateValue } from '../../../app';
import { WorkspaceReference } from '../../../domain';
import { DateConfigurationWithComparison } from '../../../domain/query';
import { WorkspaceStoreConfig } from './workspaceConfig';

export interface WorkspaceStateValue {
    configuration: DateConfigurationWithComparison | null;
}

export class WorkspaceStoreImpl extends StoreBase implements WorkspaceStore {
    state: Record<string, WorkspaceStateValue>;

    constructor(config: WorkspaceStoreConfig) {
        super();
        this.state = config.initialValue;
        makeObservable(this, {
            state: observable,
        });
    }

    getDashboardDateState(workspace: WorkspaceReference) {
        return this.state?.[workspace.id]?.configuration ?? null;
    }

    setDashboardDateState(
        workspace: WorkspaceReference,
        configuration: DateConfigurationWithComparison
    ) {
        runInAction(() => {
            this.state = {
                ...this.state,
                [workspace.id]: { ...this.state[workspace.id], configuration },
            };
        });
    }
}
