import { AnyMappableEntity, Asset } from '../../../../../../domain/assets';
import { SettingsIntegrationControllerConfig } from '../../base';
import { SettingsMappingNewController } from './mappingNewInterface';

export function createSettingMappingNewController(
    config: SettingsIntegrationControllerConfig
): SettingsMappingNewController {
    const {
        provider: { createForm, createSelect },
    } = config;

    const controller = {
        form: createForm({}),
        select: {
            asset: createSelect<Asset>({
                getItemProps(item) {
                    return {
                        label: item.name,
                        value: item.id,
                        description: null,
                        icon: null,
                    };
                },
            }),
            mappable: createSelect<AnyMappableEntity>({
                getItemProps(item) {
                    return {
                        label: item.name,
                        value: item.id,
                        description: null,
                        icon: null,
                    };
                },
            }),
        },
    };

    return {
        useProps(context, item, props) {
            const formProps = controller.form.useProps(props.form);
            const formValues = formProps.form.watch();
            console.log('formValues', formValues);
            return {
                form: formProps,
                field: {
                    asset: {
                        input: controller.select.asset.useProps(
                            {
                                disclosure: props.disclosure.asset,
                            },
                            {
                                value: formValues.asset,
                                items: item.assets,
                                onChange(value) {
                                    props.form.form.setValue(
                                        'asset',
                                        value ? Number(value) : null,
                                        { shouldValidate: true }
                                    );
                                },
                            }
                        ),
                    },
                    mappable: {
                        input: controller.select.mappable.useProps(
                            {
                                disclosure: props.disclosure.mappable,
                            },
                            {
                                value: formValues.mappable,
                                items: item.mappables,
                                onChange(value) {
                                    props.form.form.setValue(
                                        'mappable',
                                        value ? Number(value) : null,
                                        { shouldValidate: true }
                                    );
                                },
                            }
                        ),
                        label: item.definition.entityName,
                        placeholder: `Select ${item.definition.entityName}`,
                        helper: item.definition.requirement?.text ?? null,
                    },
                },
            };
        },
    };
}
