import { DomainLayoutController } from '../../../layout';
import { NetworkLayoutController } from './networkLayoutInterface';

export function createNetworkLayoutController(controller: {
    layout: DomainLayoutController;
}): NetworkLayoutController {
    return {
        useProps(context, props) {
            const { layout: layoutController } = controller;
            const layout = layoutController.useProps(context, {});
            return { layout };
        },
    };
}
