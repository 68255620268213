import { getAssetStatus } from '../../../../../domain/assets';
import { AssetListItemAggregate, AssetListItemProps } from '../../../../../view/assets';
import { SettingAssetListItemProps } from '../list';

export function buildSettingAssetItemProps(
    item: AssetListItemAggregate,
    itemProps: AssetListItemProps
): SettingAssetListItemProps {
    const status = getAssetStatus(item);
    if (status === 'unmapped') {
        return {
            ...itemProps,
            path: `/u/assets/${itemProps.id}/settings/data-sources`,
            getButtonProps(...args) {
                const buttonProps = itemProps.getButtonProps(...args);
                return { children: 'Link integrations', ...buttonProps };
            },
        };
    }
    return {
        ...itemProps,
        path: `/u/assets/${itemProps.id}/settings`,
        getButtonProps(...args) {
            const buttonProps = itemProps.getButtonProps(...args);
            return { ...buttonProps, children: 'Manage' };
        },
    };
}
