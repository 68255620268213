import { useMemo } from 'react';
import { OnboardingControllerConfig } from './onboardingConfig';
import { OnboardingViewController } from './onboardingInterface';

export function createOnboardingController<TItem>(
    config: OnboardingControllerConfig<TItem>
): OnboardingViewController<TItem> {
    return {
        useProps(item, props) {
            const status = useMemo(() => config.getStatus(item), [item]);
            return { status };
        },
    };
}
