import { ColorToken } from '@varos/chakra-charts';
import { ReportChartTrendController } from './trendChartInterface';
import { ReportChartTrendViewProps } from './trendChartProps';
import { ReportControllerBaseConfig } from '../../../base';
import { useMemo } from 'react';

export function createReportTrendChartController(
    config: ReportControllerBaseConfig
): ReportChartTrendController {
    const now = new Date();
    const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
        useProps(context, item, props): ReportChartTrendViewProps {
            const scalar = item.visualization.data.axes.find(
                (candidate) => candidate.type === 'scalar'
            );
            return {
                chart: {
                    legend: {
                        // when showing multiple datasets we use faceted chart with tabs instead
                        // of a legend
                        display: false,
                    },
                    root: {
                        type: item.visualization.style.type as 'line' | 'column',
                        datasets: item.visualization.data.series.map((series, index) => ({
                            key: series.key,
                            name: series.label,
                            description: null,
                            data: series.values,
                            color: config.theme.colors[
                                index % config.theme.colors.length
                            ],
                        })),
                        series: {
                            getSegment(props, index) {
                                const { end } = props.labels[index];

                                const endDateOnly = new Date(
                                    end.raw.getFullYear(),
                                    end.raw.getMonth(),
                                    end.raw.getDate()
                                );

                                const isPartial = endDateOnly >= nowDateOnly;

                                return { variant: isPartial ? 'partial' : undefined };
                            },
                        },
                        labels: item.visualization.data.labels.flatMap((item) =>
                            item.kind === 'time' ? [item.start] : []
                        ),
                    },
                    timeAxis: {
                        unit:
                            item.visualization.granularity === 'quarter'
                                ? 'month'
                                : (item.visualization.granularity ?? 'week'),
                    },
                    scalarAxis: {
                        startAtZero: true,
                        unit: null,
                        // @ts-expect-error
                        format: scalar?.format ?? null,
                        label: scalar?.label,
                    },
                },
            };
        },
    };
}
