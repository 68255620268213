import { useMemo } from 'react';
import { chain, filter, groupBy, keyBy } from 'lodash';
import {
    DatasetConnectionAggregate,
    DatasetConnectionListAggregate,
} from '../../../../view/datasets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { IsPluginAvailable, PluginEntity, ViewEntity } from '../../../../domain';
import { assert } from '../../../../util/assert';
import {
    Integration,
    IntegrationDefinition,
    MappingConnection,
} from '../../../../domain/assets';
import { DatasetConnectionEntity, DatasetEntity } from '../../../../app/datasets';
import { SettingDatasetConnectionConfig } from './connectionConfig';
import { SettingDatasetConnectionLoader } from './connectionInterface';
import { isPluginIntegrationGroupItem } from '../../../../api';

export function createSettingDatasetConnectionLoader(
    init: Pick<SettingDatasetConnectionConfig, 'repository'>,
    config: SettingAssetItemProviderConfig
): SettingDatasetConnectionLoader {
    const { repository } = init;
    const {} = config;
    return {
        useLoad(context) {
            const {
                api: { loader: pageLoader },
            } = config;
            const { asset } = pageLoader.useLoad(context);
            const views = repository.view.useFind(context, {}, { suspense: true });
            const plugins = repository.plugin.useFind(context, {}, { suspense: true });

            const metrics = repository.metric.useFind(
                context,
                { plugins: plugins.data?.map((item) => ({ id: item.id })) ?? [] },
                { suspense: true }
            );

            const definitions = repository.definition.useFind(
                context,
                {},
                { suspense: true }
            );
            const integrations = repository.integration.useFind(
                context,
                {},
                { suspense: true }
            );

            const mappings = repository.mapping.useFind(
                context,
                {
                    integrations:
                        integrations.data?.items.map((item) => ({
                            id: item.id,
                        })) ?? [],
                },
                { suspense: true }
            );

            const connections = repository.connection.useFind(
                context,
                { asset },
                { suspense: true }
            );

            const viewsById = useMemo(
                () =>
                    keyBy(views.data, (item) => item.id) as Record<
                        string,
                        ViewEntity | undefined
                    >,
                [views.data]
            );

            const definitionById = useMemo(
                () =>
                    keyBy(definitions.data?.items, (item) => item.id) as Record<
                        string,
                        IntegrationDefinition | undefined
                    >,
                [definitions.data?.items]
            );

            const integrationsByDefinitionId = useMemo(
                () =>
                    groupBy(
                        integrations.data?.items,
                        (item) => item.definitionId
                    ) as Record<string, Integration[] | undefined>,
                [integrations.data?.items]
            );

            const integrationById = useMemo(
                () =>
                    keyBy(integrations.data?.items, (item) => item.id) as Record<
                        string,
                        Integration | undefined
                    >,
                [integrations.data?.items]
            );

            const mappingsByIntegration = useMemo(
                () =>
                    groupBy(
                        mappings.data?.items,
                        (item) => item.integration.id
                    ) as Record<string, MappingConnection[] | undefined>,
                [mappings.data?.items]
            );

            const pluginsById = useMemo(
                () =>
                    keyBy(plugins.data, (item) => item.id) as Record<
                        string,
                        PluginEntity | undefined
                    >,
                [plugins.data]
            );

            const connectionsByView = useMemo(
                () =>
                    groupBy(connections.data, (item) => item.view.id) as Record<
                        string,
                        DatasetConnectionEntity[] | undefined
                    >,
                [connections.data]
            );

            console.log('mappingsByIntegration', mappingsByIntegration);
            console.log('pluginsById', pluginsById);
            console.log('definitionById', definitionById);
            console.log('integrationByDefinitionId', integrationsByDefinitionId);
            console.log('connectionsByView', connectionsByView);

            const referencedViews = useMemo(() => {
                return new Set(
                    chain(metrics.data ?? [])
                        .map((item) => item.view)
                        .value()
                );
            }, [metrics.data]);

            const aggregate = useMemo<DatasetConnectionListAggregate>(() => {
                return {
                    items: chain(views.data)
                        .filter((item) => referencedViews.has(item.id))
                        // .groupBy((item) => item.view.id)
                        .flatMap((view): DatasetConnectionAggregate[] => {
                            // const view = viewsById[viewId];
                            // if (!view) {
                            //     console.warn(`view ${viewId} not found`);
                            //     return [];
                            // }
                            const plugin = pluginsById[view.plugin];
                            if (!plugin) {
                                console.warn(`plugin ${view.plugin} not found`);
                                return [];
                            }

                            const integratedDefinitions =
                                definitions.data?.items.flatMap((item) => {
                                    const integrations =
                                        integrationsByDefinitionId[item.id];
                                    return integrations?.some((integration) =>
                                        mappings.data?.items.some(
                                            (mapping) =>
                                                mapping.integration.id === integration.id
                                        )
                                    )
                                        ? [item]
                                        : [];
                                }) ?? [];

                            if (
                                !IsPluginAvailable(
                                    integratedDefinitions,
                                    integrations.data?.items ?? [],
                                    plugin
                                )
                            ) {
                                console.info(
                                    `plugin ${view.plugin} is not installed, skipping...`
                                );
                                return [];
                            }
                            const connections = connectionsByView[view.id] ?? [];
                            return [
                                {
                                    asset,
                                    view,
                                    plugin,
                                    connections,
                                },
                            ];
                        })
                        .value(),
                };
            }, [
                connections.data,
                definitions.data?.items,
                integrations.data?.items,
                asset,
                viewsById,
                pluginsById,
            ]);

            return aggregate;
        },
    };
}
