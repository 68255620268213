import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isDependencyIssue } from '../../domain';
import { useDashboardContext } from '../../app';
import { DashboardRouteEnhancer } from '../../entrypoint';
import { RedirectConfig } from './redirectConfig';

/**
 * Redirects to setup page if dashboard is not active
 * @returns
 */
export function createRedirectMiddleware(init: RedirectConfig): DashboardRouteEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                useIssue() {
                    const navigate = useNavigate();
                    const result = config.controller.useIssue();
                    const { dashboard } = useDashboardContext();
                    useEffect(() => {
                        if (isDependencyIssue(result.issue)) {
                            return navigate(init.missingDepsRedirectPath, {
                                replace: true,
                            });
                        }
                        // if (dashboard.status === 'inactive') {
                        //     navigate(init.noDashboardRedirectPath, { replace: true });
                        //     return;
                        // }
                    }, [dashboard.id, dashboard.access, result.issue]);

                    return result;
                },
            },
        });
    };
}
