import { useMemo } from 'react';
import { assert } from '../../../../../util/assert';
import { SettingAssetItemConfig } from '../assetConfig';
import { SettingAssetNewLoader } from './newInterface';

export function createSettingAssetNewLoader(
    config: Pick<SettingAssetItemConfig, 'repository'>
): SettingAssetNewLoader {
    const { repository } = config;
    const limit = 500;
    return {
        useLoad(context) {
            const assets = repository.asset.useFind(
                context,
                { limit },
                {
                    suspense: true,
                }
            );
            assert(assets.status === 'success', 'expected suspense query');
            return useMemo(
                () => ({
                    current: null,
                    assets: assets.data,
                }),
                [assets.data]
            );
        },
    };
}
