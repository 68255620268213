import { BarChartControllerProps } from '../../../../vendor/chakra-chart';
import { BarChartViewProps } from './barProps';

export function buildBarChartViewProps(
    props: BarChartControllerProps
): BarChartViewProps {
    return {
        height: 0,
        width: 0,
        type: 'bar',
        data: {
            labels: props.labels,
            datasets: props.series.map((series) => ({
                label: series.name,
                data: series.data.map((item) => item.value),
                colorScheme: series.colorScheme,
            })),
        },
        getValue({}, original) {
            return props.formatValue(original as number);
        },
        getCaption(context, original) {
            const series = props.series[context.rowIndex];
            const value = series.data[context.columnIndex];
            return value.secondary ? props.formatTooltip(value.secondary) : original;
        },
        formatUnit(value) {
            return props.formatScale(value as number);
        },
        formatLabel(value) {
            return props.formatCategory(value);
        },
    };
}
