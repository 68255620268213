import { PipelineDto } from '../../../api';
import { assert } from '../../../util/assert';
import {
    DatasetConnectionAdapter,
    DatasetConnectionEntity,
    DatasetConnectionStatus,
    DatasetConnectionStatusKind,
} from '../../../app/datasets';
import { DatasetConnectionImplConfig } from './datasetConnectionConfig';

export function createDatasetConnectionImpl(
    config: DatasetConnectionImplConfig
): DatasetConnectionAdapter {
    const {
        api: { ingestion: api },
    } = config;

    function getStatus(dto: PipelineDto): DatasetConnectionStatus | null {
        if (dto.state.kind === 'in_sync' && dto.state.status === 'synced') {
            return { kind: 'active', message: null };
        }
        // if (dto.state.kind === 'in_sync') {
        //     return { kind: 'importing', message: null };
        // }
        if (dto.state.kind === 'has_issues' && dto.state.blame_on === 'system') {
            return { kind: 'delayed', message: null };
        }
        if (dto.state.kind === 'has_issues' && dto.state.blame_on === 'user') {
            return { kind: 'error', message: dto.state.issue_type };
        }
        return null;
    }

    function toEntity(dto: PipelineDto): DatasetConnectionEntity | null {
        assert(dto.pipeline_ref.asset_id, 'no asset id on pipeline');
        const status = getStatus(dto);
        if (status === null) {
            return null;
        }
        return {
            id: dto.id,
            status: status,
            view: {
                id: dto.pipeline_ref.view_slug,
            },
            asset: {
                id: dto.pipeline_ref.asset_id,
            },
            entity: {
                key: dto.pipeline_ref.mappable_entity_id,
                type: dto.pipeline_ref.mappable_entity_type,
            },
            updatedAt: dto.updated_at,
        };
    }

    return {
        async find(context, query) {
            const response = await api.pipeline.list(context, {
                asset: query.asset.id,
            });
            return response.data.flatMap((item) => {
                const mapped = toEntity(item);
                if (!mapped) {
                    console.warn(`failed to map pipeline entity`, item);
                    return [];
                }
                return [mapped];
            });
        },
    };
}
