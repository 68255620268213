import React from 'react';
import {
    OnboardingStrategyOrganizationController,
    OnboardingStrategyAssetController,
    OnboardingStrategyUserController,
} from './strategyInterface';
import {
    OnboardingOrganizationStrategyControllerProps,
    OnboardingAssetStrategyControllerProps,
    OnboardingOrganizationStrategyViewProps,
    OnboardingAssetStrategyViewProps,
    OnboardingUserStrategyViewProps,
    OnboardingUserStrategyControllerProps,
} from './strategyProps';

export function createOnboardingUserStrategyContainer(
    // controller: OnboardingStrategyUserController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & OnboardingUserStrategyViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingUserStrategyControllerProps
> {
    return (props) => {
        // const viewProps = controller.useProps(props);
        return <View />;
    };
}

export function createOnboardingOrganizationStrategyContainer(
    controller: OnboardingStrategyOrganizationController,
    View: React.FC<
        {
            children?: React.ReactNode | undefined;
        } & OnboardingOrganizationStrategyViewProps
    >
): React.FC<
    {
        children?: React.ReactNode | undefined;
    } & OnboardingOrganizationStrategyControllerProps
> {
    return (props) => {
        const viewProps = controller.useProps(props);
        return <View {...viewProps} />;
    };
}

export function createOnboardingAssetStrategyContainer(
    controller: OnboardingStrategyAssetController,
    View: React.FC<
        { children?: React.ReactNode | undefined } & OnboardingAssetStrategyViewProps
    >
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingAssetStrategyControllerProps
> {
    return (props) => {
        const viewProps = controller.useProps(props);
        return <View {...viewProps} />;
    };
}
