import { assert } from '../../../util/assert';
import { assertNever } from '../../../util';
import { ValueFormatter } from '../../../app/attributes';
import {
    AnyVisualization,
    AnyVisualizationResult,
    isBarVisualizationResult,
    isLineVisualizationResult,
    isComparisonVisualizationResult,
    isDistributionVisualizationResult,
} from '../../../domain/visualization';
import { AnyVisualizationResultViewProps } from './resultProps';
import { buildLineVisualizationViewProps } from './line';
import { buildDistributionVisualizationResultViewProps } from './distribution';
import { buildComparisonVisualizationResultViewProps } from './comparison';
import { buildBarVisualizationResultViewProps } from './bar';

export function buildVisualizationResultViewProps(
    formatter: ValueFormatter,
    visualization: AnyVisualization,
    item: AnyVisualizationResult
): AnyVisualizationResultViewProps {
    if (isLineVisualizationResult(item)) {
        return buildLineVisualizationViewProps(formatter, visualization, item);
    }
    if (isBarVisualizationResult(item)) {
        assert(visualization.kind === 'bar', 'visualization and result mismatch');
        return buildBarVisualizationResultViewProps(formatter, visualization, item);
    }
    if (isDistributionVisualizationResult(item)) {
        return buildDistributionVisualizationResultViewProps(
            formatter,
            visualization,
            item
        );
    }
    if (isComparisonVisualizationResult(item)) {
        return buildComparisonVisualizationResultViewProps(
            formatter,
            visualization,
            item
        );
    }
    assertNever(item);
}
