import { z } from 'zod';
import { NotificationDefinitionSchema } from '../definition';

export const SuscriptionAssetDtoSchema = z.object({
    id: z.number(),
    organization: z.number(),
    name: z.string(),
    url: z.string(),
});

export const SubscriptionSchema = z.object({
    id: z.optional(z.string()),
    definition: z.union([z.string(), NotificationDefinitionSchema]),
    asset: z.union([z.number(), SuscriptionAssetDtoSchema]),
    user: z.number(),
    status: z.enum(['active', 'disabled']),
    custom: z.boolean(),
    updated_at: z.date({ coerce: true }),
});

export const SubscriptionListSchema = z.object({
    data: z.array(SubscriptionSchema),
});
