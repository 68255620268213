import {
    AnyVisualization,
    DistributionVisualization,
    LineVisualization,
    ComparisonVisualization,
    BarVisualization,
} from './visualizationModel';

export function isComparisonVisualization(
    visualization: AnyVisualization
): visualization is ComparisonVisualization {
    return visualization.kind === 'comparison';
}

export function isLineVisualization(
    visualization: AnyVisualization
): visualization is LineVisualization {
    return visualization.kind === 'line';
}

export function isBarVisualization(
    visualization: AnyVisualization
): visualization is BarVisualization {
    return visualization.kind === 'bar';
}

export function isDistributionVisualization(
    visualization: AnyVisualization
): visualization is DistributionVisualization {
    return visualization.kind === 'distribution';
}
