import { useQuery } from '@tanstack/react-query';
import { assertNever } from '../../../util';
import { assert } from '../../../util/assert';
import { PeerHealthcheckAdapter } from './peerHealthcheckAdapter';
import {
    isPeerHealthcheckConditionQuery,
    isPeerHealthcheckGroupQuery,
} from './peerHealthcheckGuard';
import { PeerHealthcheckRepository } from './peerHealthcheckInterface';

export function createPeerHealthcheckRepository(
    adapter: PeerHealthcheckAdapter
): PeerHealthcheckRepository {
    const PREFIX = ['peers', 'healthcheck'];
    return {
        useMultiLookup(context, queryOrQueries, options) {
            assert(Array.isArray(queryOrQueries), 'expected multi lookup query');
            const result = useQuery({
                ...options,
                queryKey: [
                    ...PREFIX,
                    context.organization,
                    context.workspace,
                    queryOrQueries,
                ],
                async queryFn() {
                    const response = await Promise.all(
                        queryOrQueries.map((query) => {
                            if (isPeerHealthcheckConditionQuery(query)) {
                                return adapter.findOneByConditions(context, query);
                            }
                            if (isPeerHealthcheckGroupQuery(query)) {
                                return adapter.findOneByGroup(context, query);
                            }
                            assertNever(query);
                        })
                    );
                    return response;
                },
            });
            return result;
        },
    };
}
