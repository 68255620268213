import { createContext, useContext } from 'react';
import { WorkspaceStore } from '../workspace';
import { DashboardStore } from '../dashboard';
import { ControlStore } from '../control';
import { ReportStore } from '../../_reportold';

export interface StorageContextValue {
    workspace: WorkspaceStore;
    dashboard: DashboardStore;
    control: ControlStore;
    report: ReportStore;
}

export const StorageContext = createContext<StorageContextValue | null>(null);

export const useStorageContext = () => {
    const context = useContext(StorageContext);
    if (!context) {
        throw new Error(`storage context not available`);
    }
    return context;
};
