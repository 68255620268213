import { useContext } from 'react';
import { assert } from '../../../../util/assert';
import { PageController } from '../../../../view/page';
import { SettingAssetConfig } from '../assetConfig';
import { SettingAssetContext } from '../assetContext';
import { SettingAssetPageLoader } from './pageInterface';

export function createSettingAssetPageController(
    init: Pick<SettingAssetConfig['deps'], 'controller'>,
    loader: SettingAssetPageLoader
): PageController {
    const {
        controller: { page: pageController },
    } = init;
    return {
        useProps(basecontext, props) {
            const context = useContext(SettingAssetContext);
            assert(context, 'no context');
            const data = loader.useLoad(context);
            const pageProps = pageController.useProps(basecontext, {
                ...props,
                item: {
                    ...props.item,
                    breadcrumbs: [
                        { label: 'Settings', to: '/u/settings/companies' },
                        {
                            label: data.asset.name,
                            to: `/u/assets/${data.asset.id}/settings`,
                        },
                        ...props.item.breadcrumbs,
                    ],
                },
            });
            return {
                ...pageProps,
                getBreadrumbs() {
                    return pageProps.getBreadcrumbProps();
                },
            };
        },
    };
}
