import { DataIngestionApi } from './dataIngestionInterface';
import { ReportApiConfig } from './dataIngestionConfig';
import { createPipelineResource } from './pipeline';
import { createImportResource } from './import';
import { createDatasetResource } from './dataset';
import { createImportDataResource } from './import-data';

export function createDataIngestionApi(config: ReportApiConfig): DataIngestionApi {
    const { client } = config;
    return {
        pipeline: createPipelineResource(client),
        import: createImportResource(client),
        dataset: createDatasetResource(client),
        importData: createImportDataResource(client),
    };
}
