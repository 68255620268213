import { AxiosInstance } from 'axios';
import { WorkspaceDto } from './workspaceModel';

export async function fetchWorkspace(
    http: AxiosInstance,
    workspaceId: number,
    options?: { apiKey?: string; organization?: number }
) {
    const params: Record<string, string> = {};
    const headers: Record<string, string> = {};
    if (options?.organization) {
        headers['x-varos-organization'] = options.organization.toString();
    }
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    const response = await http.get<WorkspaceDto>(`/api/v2/workspaces/${workspaceId}`, {
        params,
        headers,
    });
    return response.data;
}
