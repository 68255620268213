import { PluginConnection } from '../../../../domain/metrics';
import { PluginDto } from '../../../../api';
import { PluginEntity } from '../../../../domain';
import { MetricStaticDeps } from '../../metricsConfig';
import { MetricCollectionEditLoader } from './collectionEditInterface';
import { MetricDefinitionListItem } from './collectionEditModel';
import { chain } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { MetricCollectionRepository } from '../../../../app/metrics';

export function createMetricCollectionEditLoader(init: {
    repository: {
        collection: MetricCollectionRepository;
    };
}): MetricCollectionEditLoader {
    // init: MetricStaticDeps
    const { repository } = init;
    return {
        useLoad(context) {
            const pluginsById = useMemo(
                () =>
                    context.data.plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [context.data.plugins.data]
            );

            const connectionByPlugin = useMemo(
                () =>
                    context.data.connections.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [context.data.connections.data]
            );

            const aggregate = useMemo(
                () =>
                    chain(context.data.metric.definitions.data ?? [])
                        .flatMap((item): MetricDefinitionListItem[] => {
                            const plugin = pluginsById[item.plugin];
                            if (!plugin) {
                                console.warn(
                                    `plugin ${item.plugin} referenced from metric definition ${item.id} not found. skipping...`,
                                    pluginsById
                                );
                                return [];
                            }
                            if (!plugin.iconUrl) {
                                console.warn(
                                    `plugin ${item.plugin} does not have an icon configured. skipping...`,
                                    pluginsById
                                );
                                return [];
                            }
                            const connection = connectionByPlugin[item.plugin];
                            return [
                                {
                                    metric: item,
                                    plugin,
                                    connection: connection
                                        ? connection
                                        : {
                                              workspace: context.workspace,
                                              plugin: {
                                                  id: item.plugin,
                                              },
                                              status: 'pending',
                                              dependencies: [
                                                  {
                                                      name: 'Integrations',
                                                      status: 'unfulfilled',
                                                  },
                                                  {
                                                      name: 'Data Sync',
                                                      status: 'unfulfilled',
                                                  },
                                              ],
                                          },
                                },
                            ];
                        })
                        .value(),
                [
                    context.data.metric.definitions.data,
                    pluginsById,
                    connectionByPlugin,
                    context.workspace,
                ]
            );

            const status = [context.data.plugins, context.data.connections].every(
                (item) => item.status === 'success'
            )
                ? 'success'
                : 'loading';

            return {
                status,
                item: {
                    status,
                    data: aggregate,
                },
                update: repository.collection.useUpdate(context),
            };
        },
    };
}
