import React from 'react';
import { TermsOfServiceController } from './termsOfServiceInterface';
import {
    TermsOfServiceContainerProps,
    TermsOfServiceViewProps,
} from './termsOfServiceProps';

export function createTermsOfServiceContainer(
    controller: TermsOfServiceController,
    View: React.FC<{ children?: React.ReactNode | undefined } & TermsOfServiceViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & TermsOfServiceContainerProps> {
    return ({ children, ...containerProps }) => {
        const props = controller.useProps({});
        return <View {...props}>{children}</View>;
    };
}
