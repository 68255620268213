import { ValueRenderer } from '../../../app/attributes';
import { isSuccessResponse } from '../../../domain/query';
import { EmailVisualizationControllerConfig } from '../../../controller/email';
import { EmailEntryEnhancer } from '../../../entrypoint';

export interface RenderMiddlewareConfig {
    renderer: ValueRenderer;
}

export function createRenderMiddleware(init: RenderMiddlewareConfig): EmailEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            controller: {
                ...config.controller,
                createVisualizationController(controllerConfig) {
                    return config.controller.createVisualizationController(
                        enhanceConfig(init, controllerConfig)
                    );
                },
            },
        });
    };
}

function enhanceConfig(
    init: RenderMiddlewareConfig,
    config: EmailVisualizationControllerConfig
): EmailVisualizationControllerConfig {
    return {
        ...config,
        getProps({ chart, responses, visualization }) {
            const original = config.getProps({ chart, responses, visualization });
            const queries = responses
                .filter(isSuccessResponse)
                .flatMap((response) => response.response.queries);
            return {
                ...original,
                columns: original.columns.map((column) => ({
                    ...column,
                    renderValue(value, row, index) {
                        const query = queries[index];
                        const property = query.schema.properties?.[column.key];
                        return init.renderer.render(property.type, value);
                    },
                })),
            };
        },
    };
}
