import React from 'react';
import {
    Flex,
    Avatar,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Box,
    HStack,
    VStack,
} from '@chakra-ui/react';
import { UserNavigationViewProps } from './userNavigationProps';
import {
    FiSettings,
    FiCreditCard,
    FiUserPlus,
    FiDollarSign,
    FiBell,
} from 'react-icons/fi';

export const UserNavigationView: React.FC<
    { children?: React.ReactNode | undefined } & UserNavigationViewProps
> = (props) => {
    return (
        <Menu>
            <MenuButton>
                <Avatar size="sm" />
            </MenuButton>
            <MenuList zIndex="popover">
                <MenuItem>
                    <HStack {...props.style?.header}>
                        <Avatar size="sm" />
                        <VStack align="flex-start" spacing={0.5}>
                            <Text>
                                {props.user.firstname} {props.user.lastname}
                            </Text>
                            {props.user.isAdmin && <Text fontSize="small">Admin</Text>}
                        </VStack>
                    </HStack>
                </MenuItem>
                <MenuItem onClick={props.onInvite} icon={<FiUserPlus />}>
                    <Text>Add teammates</Text>
                    {props.inviteUser.shouldShow && (
                        <props.inviteUser.Form {...props.inviteUser.props} />
                    )}
                </MenuItem>
                <MenuItem onClick={props.onBilling} icon={<FiCreditCard />} isFocusable>
                    <Text>Billing</Text>
                </MenuItem>
                <MenuItem onClick={props.onSettings} icon={<FiSettings />} isFocusable>
                    <Text>Settings</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={props.onSignout}>
                    <Flex w="full" justifyContent="center">
                        <Text>Sign out</Text>
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
