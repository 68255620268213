import {
    isTimestampPrevFilter,
    isTimestampBetweenFilter,
    TimestampFilter,
    EnumMember,
    Column,
    AnyDashFilter,
    Dashboard,
} from '../../../../../api';
import { TimeGranularity } from '../../../../../models/Common';
import {
    NumericType,
    DateType,
    AnyValue,
    AnyType,
    Property,
} from '../../../../domain/attributes';
import {
    AnyFilter,
    Constraint,
    isNumericBetweenConstraint,
    isSingleSelectConstraint,
} from '../../../../domain/dashboard';
import { DashboardApiImplConfig } from './configurationApiConfig';
import {
    AnyType as ApiAnyType,
    isComplexType,
    isMovementType,
    isStringFilter,
    isTypeReference,
} from '../../../../../api/v2/type';
import { normalizeFilterWithConstraints } from './dateHelpers';

export function toDomainFilter(
    deps: {
        dashboard: Dashboard;
        config: DashboardApiImplConfig;
        // preferencesByKey: Record<string, MetadataRule | undefined>;
        columns: Column[];
        timePreferences: { filter?: TimestampFilter; granularity?: TimeGranularity };
        // columnsByKey: Record<string, Column | undefined>;
        // columnsByTypeSlug: Record<string, Column | undefined>;
        membersByTypeSlug: Record<string, EnumMember[] | undefined>;
    },
    filter: AnyDashFilter
): AnyFilter {
    const overrides: DashboardApiImplConfig['overrides'] = deps.config.overrides ?? {};
    const { trait: getTrait = (filter) => filter } = overrides;

    if (filter.kind === 'trait' && filter.type_slug.includes('asset')) {
        // TODO need a more structured way of determining the asset filter
        return {
            kind: 'asset',
            property: {
                key: 'asset',
                name: 'Company',
                type: 'integer',
                description: null,
                constraints: [],
                isDisabled: false,
                disabledReason: null,
            },
            default: {
                operator: 'in',
            },
        };
    }

    if (filter.kind === 'timestamp') {
        // const columns = Object.values(deps.columnsByKey);
        const column = deps.columns.find((column) => column?.partition);
        if (!column) {
            const candidates = deps.columns.map((item) => item?.key);
            throw new Error(`partition column not found. found ${candidates.join(', ')}`);
        }
        if (column.type !== 'date') {
            throw new Error(`expected partition column to be a timestamp`);
        }
        const property: Property<DateType> = {
            key: column.key,
            name: column.name ?? column.key,
            description: null,
            type: 'date',
            constraints: filter.constraints.map((x) => ({
                name: x.name,
                expression: {
                    operator: 'min_lookback_days',
                    value: x.expression.days,
                },
            })),
            isDisabled: false,
            disabledReason: null,
        };

        return normalizeFilterWithConstraints(
            property,
            filter.default_value,
            filter.constraints
        );
    }

    let type: AnyType = 'string';
    let value: AnyValue | null = null;

    const isNumericTrait = filter.kind === 'trait' && filter.value_type === 'number';
    // if () {
    // console.log('filter', filter, defaults);
    const constraints: Constraint[] = filter.constraints.map((constraint) => {
        if (
            filter.value_type == 'number' &&
            constraint.expression.operator == 'between'
        ) {
            return {
                name: constraint.name,
                expression: {
                    operator: 'between',
                    value: {
                        from: constraint.expression.value[0],
                        to: constraint.expression.value[1],
                    },
                },
            };
        } else if (constraint.expression.operator == 'single_select') {
            return {
                name: constraint.name,
                expression: {
                    operator: 'single_select',
                    value: undefined,
                },
            };
        } else {
            throw new Error('unsupported constraint!');
        }
    });
    if (isNumericTrait) {
        let appliedConstraints = constraints;

        if (appliedConstraints.length === 0) {
            // This a temp super hack to fix CPA dashboard peer group filters
            if (filter.type_slug.includes('aov')) {
                appliedConstraints = [
                    ...appliedConstraints,
                    {
                        name: 'default',
                        expression: {
                            operator: 'between',
                            value: {
                                from: 1,
                                to: 500,
                            },
                        },
                    },
                ];
            }
            if (filter.type_slug.includes('spend')) {
                appliedConstraints = [
                    ...appliedConstraints,
                    {
                        name: 'default',
                        expression: {
                            operator: 'between',
                            value: {
                                from: 1,
                                to: 10000000,
                            },
                        },
                    },
                ];
            }
        }
        const constraintDefault = appliedConstraints.find(isNumericBetweenConstraint);

        const property: Property<NumericType> = {
            key: filter.type_slug,
            name: filter.name,
            description: filter.description ?? null,
            type: {
                kind: 'currency',
                currency: 'usd',
                constraints: [],
            },
            constraints: appliedConstraints,
            isDisabled: false,
            disabledReason: null,
        };

        if (!constraintDefault) {
            return {
                kind: 'property',
                property,
                default: {
                    operator: 'between',
                    // value: null,
                },
            };
        }
        // constraintDefault.expression.
        return { kind: 'property', property, default: constraintDefault.expression };
    }

    // if (defaults && Array.isArray(defaults.value) && filter.value_type === 'number') {
    //     type = {
    //         kind: 'integer',
    //     };
    //     // value = {
    //     //     from: defaults.value[0],
    //     //     to: defaults.value[1],
    //     // };
    // } else if (filter.value_type === 'number') {
    //     type = {
    //         kind: 'integer',
    //     };
    //     // value = {
    //     //     from: 0,
    //     //     to: 100,
    //     // };
    // }

    const members = deps.membersByTypeSlug[filter.type_slug] ?? null;
    // console.log('members', filter.type_slug, members);
    if (filter.kind === 'trait' && members) {
        const isTree = members.some((member) => Array.isArray(member.children));

        let type: AnyType | null = null;
        if (isTree) {
            type = {
                kind: 'tree',
                members: members.map((member) => ({
                    label: member.name,
                    value: member.value,
                    children:
                        member.children?.map((child) => ({
                            label: child.name,
                            value: child.value,
                        })) ?? [],
                })),
            };
        } else {
            type = {
                kind: 'enum',
                members: members.map((member) => ({
                    label: member.name,
                    value: member.value,
                })),
            };
        }

        if (!type) {
            throw new Error(`unable to determine type`);
        }

        return {
            kind: 'property',
            property: {
                key: filter.type_slug,
                name: filter.name,
                description: filter.description ?? null,
                type,
                constraints: [],
                isDisabled: false,
                disabledReason: null,
            },
            default: {
                operator: 'in',
                // operator: defaults?.operator ?? 'in',
                value,
            },
        };
    }

    if (filter.kind === 'trait') {
        // console.log('i am trait', filter.name, value);
        return getTrait({
            kind: 'property',
            property: {
                key: filter.type_slug,
                name: filter.name,
                description: filter.description ?? null,
                type,
                constraints,
                isDisabled: false,
                disabledReason: null,
            },
            default: {
                operator: 'in',
                // operator: defaults?.operator ?? 'between',
                value,
            },
        });
    }

    const column = deps.columns.find((x) => x.type_slug == filter.type_slug);
    if (!column) {
        const message = `no column for found filter '${filter.name}' and type ${filter.type_slug}`;
        throw new Error(message);
    }

    if (members) {
        const isSingleSelect = constraints.some(isSingleSelectConstraint);

        if (isSingleSelect) {
            if (filter.default_value && isStringFilter(filter.default_value)) {
                value = filter.default_value.values[0];
            } else if (members.length > 0) {
                value = members[0].value;
            }
        }
        return {
            kind: 'property',
            property: {
                // key: column.key,
                key: filter.type_slug,
                name: filter.name,
                description: filter.description ?? null,
                type: {
                    kind: 'enum',
                    members: members.map((member) => ({
                        label: member.name,
                        value: member.value,
                    })),
                },
                constraints,
                isDisabled: false,
                disabledReason: null,
            },
            default: {
                operator: !value || Array.isArray(value) ? 'in' : 'eq',
                value,
            },
        };
    }

    // console.log(filter.type_slug, deps.membersByTypeSlug);

    // console.log('column', column);
    // console.log('filter', filter);

    return getTrait({
        kind: 'property',
        property: {
            key: filter.name,
            name: filter.name,
            description: filter.description ?? null,
            type,
            constraints,
            isDisabled: false,
            disabledReason: null,
        },
        default: {
            // operator: defaults?.operator ?? 'between',
            operator: 'between',
            value,
        },
    });
}

export function apiTypeToPropertyType(inputType: ApiAnyType): AnyType {
    if (isTypeReference(inputType)) {
        return 'string';
    } else if (isMovementType(inputType)) {
        return {
            kind: 'movement',
            base: inputType.base
                ? apiTypeToPropertyType(
                      inputType.base as Exclude<ApiAnyType, { kind: 'movement' }>
                  )
                : 'integer',
            inverse: inputType.inverse ?? false,
        };
    }
    if (isComplexType(inputType)) {
        if (inputType.kind == 'percent') {
            return 'percent';
        } else if (inputType.kind == 'enum') {
            return {
                kind: 'enum',
                name: inputType.name,
                namespace: inputType.namespace,
                members: inputType.members.map((x) => ({
                    label: x.name,
                    value: x.value,
                })),
            };
        }
    }

    // @ts-expect-error
    return inputType;
}
