import { useMemo } from 'react';
import { downcaseUnlessAllCaps } from '../../../../util';
import { assert } from '../../../../util/assert';
import { FilterItem, FilterItemViewProps } from '../item';
import { FilterListController } from './filterListInterface';
import { FilterListControllerProps, FilterListViewProps } from './filterListProps';
import { chain } from 'lodash';
import { FilterPopoverContainerProps } from '../popover';

export function createFilterListController(): FilterListController {
    function buildItemProps(
        props: FilterListControllerProps,
        item: FilterItem
    ): FilterItemViewProps {
        const value = props.value?.[item.key] ?? null;
        const isActive = value !== null && !(Array.isArray(value) && value.length === 0);

        let formatted = `N/A`;

        if (item.input.kind === 'range' && !isActive) {
            formatted = `Add ${downcaseUnlessAllCaps(item.label)} range`;
        }

        if (isActive) {
            formatted = props.getLabel?.(item) ?? 'N/A';
        }

        let addLabel = `Add ${downcaseUnlessAllCaps(item.label)}`;

        return {
            id: item.key,
            title: item.label,
            description: item.description ?? null,
            isDisabled: item.isDisabled,
            isActive,
            Icon: item.Icon,
            value: isActive
                ? {
                      label: formatted,
                  }
                : {
                      label: `Add ${downcaseUnlessAllCaps(item.label)}`,
                  },
            // @ts-expect-error
            input: {
                ...item.input,
                value,
                // @ts-expect-error
                onChange(next) {
                    props.onChange({ ...props.value, [item.key]: next });
                },
            },
            button: {
                add: {
                    label: addLabel,
                },
                clear: {
                    onClick() {
                        const { [item.key]: _removed, ...rest } = props.value;
                        props.onChange(rest);
                    },
                },
                apply: {
                    onClick() {
                        console.log('DEBUG applying', item);
                    },
                },
            },
        };
    }

    return {
        useProps(item, props): FilterListViewProps {
            const itemPropsByKey = useMemo(() => {
                return item.items.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.key]: buildItemProps(props, item),
                    }),
                    {} as Record<string, FilterItemViewProps | undefined>
                );
            }, [props, item.items]);

            const items = useMemo(
                () => chain(item.items).value(),
                [item.items, itemPropsByKey]
            );

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsByKey[item.key];
                    assert(itemProps, 'no item props');
                    return itemProps;
                },
                getPopoverProps(item): Omit<FilterPopoverContainerProps, 'as'> {
                    const itemProps = itemPropsByKey[item.key];
                    assert(itemProps, 'no item props');
                    return {
                        item: itemProps,
                    };
                },
            };
        },
    };
}
