import { DashboardsByPlugin } from '../../../../config';
import { PeerHealthcheckAdapter } from '../../../app/peers';
import { PeerHealthcheckImplConfig } from './peerHealthcheckImplConfig';

export function createPeerHealthcheckImpl(
    config: PeerHealthcheckImplConfig
): PeerHealthcheckAdapter {
    const GROUP_FILTER_KEY = 'asset';
    return {
        async findOneByConditions(context, query) {
            throw new Error('not impl');
        },
        async findOneByGroup(context, query) {
            const dashboard = DashboardsByPlugin[query.plugin.id];
            if (!dashboard) {
                throw new Error(`unmapped plugin ${query.plugin.id}`);
            }
            const response = await config.api.competitive.create(
                {
                    auth: context.auth,
                },
                {
                    dashboard,
                    asset: Number(context.workspace.id),
                    condition: [
                        {
                            key: GROUP_FILTER_KEY,
                            operator: 'in',
                            value: [
                                // hack to make competitive sets work in query api
                                // in future backend can relax prefix requirement
                                query.group.id.replace('cs_', 'grp_'),
                            ],
                        },
                    ],
                }
            );
            return {
                plugin: query.plugin,
                transparency: response,
            };
        },
    };
}
