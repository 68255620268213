import { useQuery } from '@tanstack/react-query';
import { UseDisclosureReturn } from '@chakra-ui/hooks';
import { useAuthenticatedContext } from '../../../../container';
import { AnyFeature, FeatureSet } from '../../../domain';
import { useOrganizationContextV2, useSystemContextV2, useWorkspaceContextV2 } from '../../../context';
import { useFeatureContext } from './featureContext';

export interface FeatureSetApi {
    disclosure: UseDisclosureReturn;
    getFeatureset(): FeatureSet;
    open(): void;
    close(): void;
    restrict(feature: AnyFeature): void;
    restrictClick(feature: AnyFeature): React.MouseEventHandler;
    handleClick(
        feature: AnyFeature,
        callback?: React.MouseEventHandler
    ): React.MouseEventHandler;
}



function getFeatureApi(features: FeatureSet): FeatureSetApi {
    const context = useAuthenticatedContext();
    const { disclosure, adapter } = useFeatureContext();

    return {
        disclosure,
        getFeatureset() {
            if (!features) {
                throw new Error(`no featureset found`);
            }
            return features;
        },
        open() {
            disclosure.onOpen();
        },
        close() {
            // disclosure.onClose();
            context.access.clear();
        },
        restrict(feature) {
            context.access.restrict({
                // @ts-expect-error
                request: feature,
                restricted: true,
            });
            // disclosure.onOpen();
        },
        restrictClick(feature) {
            return (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.restrict(feature);
            };
        },
        handleClick(feature, callback) {
            return (event) => {
                if (!callback) {
                    return;
                }
                if (!feature.enabled) {
                    this.restrictClick(feature);
                    return;
                }
                return callback(event);
            };
        },
    };
}

export const useWorkspaceFeatureApi = () => {
    const systemdata = useOrganizationContextV2().data;
    const workspacedata = useWorkspaceContextV2();
    
    return getFeatureApi(workspacedata.features.data || systemdata.features);
}

export const useFeatureApi = (): FeatureSetApi => {
    
    const systemdata = useOrganizationContextV2().data;
    
    return getFeatureApi(systemdata.features);
};
