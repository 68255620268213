import React from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { FaSortAmountDown } from 'react-icons/fa';
import {
    Text,
    Box,
    HStack,
    VStack,
    StackDivider,
    Wrap,
    WrapItem,
    List,
    Icon,
    ListItem,
    Tag,
} from '@chakra-ui/react';
import { chain } from 'lodash';
import { getAllChildrenProps, fetchChildrenProps, getChildrenProps } from '../../../util';
import { CalculationLayoutConfig } from './calculationConfig';
import { CalculationLayout } from './calculationInterface';

export function createCalculationLayout(config: CalculationLayoutConfig) {
    const {
        UI: {
            Icon: { Icon, IconGroup },
            Tooltip,
        },
    } = config;
    const instance: CalculationLayout = {
        Container(props) {
            const { Name } = fetchChildrenProps(props.children, {
                Name: instance.Name,
            });

            const {
                formula: formulaProps,
                condition: conditionListProps,
                description: descriptionProps,
                ranking: rankingProps,
            } = getChildrenProps(props.children, {
                formula: instance.Formula,
                condition: instance.Condition.List,
                description: instance.Description,
                ranking: instance.Ranking,
            });

            const { value: valueProps } = getAllChildrenProps(props.children, {
                value: instance.Value,
            });
            const { date: dateProps } = getAllChildrenProps(props.children, {
                date: instance.Date,
            });

            const { condition: conditionItemProps } =
                conditionListProps?.children && conditionListProps?.children.length > 0
                    ? getAllChildrenProps(conditionListProps?.children, {
                          condition: instance.Condition.Item,
                      })
                    : { condition: null };

            const valueGroups = chain(valueProps)
                .groupBy((item) => item.group)
                .values()
                .value();

            return (
                <Box minW="16rem" p={3} borderRadius="md">
                    <VStack align="start" spacing={2} w="full">
                        <HStack
                            // align="start"
                            justify="space-between"
                            w="full"
                            spacing={6}
                        >
                            <HStack spacing={3}>
                                {typeof Name.icon === 'string' ? (
                                    <IconGroup
                                        bg="whiteAlpha.200"
                                        p={2}
                                        borderRadius="md"
                                        flexShrink={0}
                                    >
                                        <Icon w={5} h={5} src={Name.icon} />
                                    </IconGroup>
                                ) : null}
                                {typeof Name.icon === 'object' ? (
                                    <IconGroup
                                        bg="whiteAlpha.200"
                                        p={2}
                                        borderRadius="md"
                                        flexShrink={0}
                                    >
                                        {React.cloneElement(Name.icon, {
                                            w: 5,
                                            h: 5,
                                            ...Name.icon.props,
                                        })}
                                    </IconGroup>
                                ) : null}
                                <VStack
                                    align="flex-start"
                                    fontWeight="medium"
                                    spacing={0}
                                >
                                    <Box>{Name.children}</Box>
                                    {/* {Name.kind && (
                                        <Box color="whiteAlpha.500">{Name.kind}</Box>
                                        // <Tag size="sm" bg="blackAlpha.500">
                                        //     {Name.kind}
                                        // </Tag>
                                    )} */}
                                </VStack>
                            </HStack>
                            {Name.kind && typeof Name.kind === 'string' && (
                                <Box color="whiteAlpha.500">{Name.kind}</Box>
                            )}
                            {Name.kind &&
                                typeof Name.kind === 'object' &&
                                React.cloneElement(Name.kind, {
                                    size: 'sm',
                                    borderRadius: 'sm',
                                    ...Name.kind.props,
                                })}
                        </HStack>
                        {formulaProps?.children && (
                            <Box
                                // borderLeftWidth={5}
                                // borderColor="blackAlpha.900"
                                // borderColor="green.500"
                                px={2}
                                py={0.5}
                                color="whiteAlpha.700"
                                fontWeight="medium"
                                fontStyle="italic"
                                fontSize="sm"
                                bg="blackAlpha.500"
                            >
                                <Box as="pre">{formulaProps.children}</Box>
                            </Box>
                        )}
                        {Name.caption && (
                            <HStack spacing={2}>
                                <Box color="whiteAlpha.800">{Name.caption}</Box>
                            </HStack>
                        )}
                        {dateProps.length > 0 && (
                            <Wrap align="center" spacingY={0} spacingX={2}>
                                {dateProps.map((item, index, array) => (
                                    <React.Fragment key={index}>
                                        <WrapItem>
                                            <Box color="whiteAlpha.500">
                                                {item.children}
                                            </Box>
                                        </WrapItem>
                                        {index < array.length - 1 ? (
                                            <WrapItem>
                                                <ArrowForwardIcon
                                                    mx={2}
                                                    color="whiteAlpha.500"
                                                />
                                            </WrapItem>
                                        ) : (
                                            <></>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Wrap>
                        )}
                        {valueGroups.length > 0 && (
                            <VStack
                                w="full"
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.200"
                                        borderWidth="1px"
                                    />
                                }
                                align="start"
                            >
                                {valueGroups.map((group, indexGroup) => (
                                    <VStack
                                        key={indexGroup}
                                        w="full"
                                        align="start"
                                        spacing={
                                            group.some((item) => item.description) ? 2 : 1
                                        }
                                    >
                                        {group.map((item, index) => (
                                            <VStack
                                                key={index}
                                                w="full"
                                                align="start"
                                                spacing={0}
                                            >
                                                <HStack>
                                                    {item.color && (
                                                        <Box
                                                            flexShrink={0}
                                                            w={2}
                                                            h={2}
                                                            bg={item.color}
                                                        />
                                                    )}
                                                    {item.label && (
                                                        <Box color="whiteAlpha.900">
                                                            {item.label}
                                                        </Box>
                                                    )}
                                                    <Box
                                                        // whiteSpace="nowrap"
                                                        px={1}
                                                        color="whiteAlpha.900"
                                                        bg="blackAlpha.700"
                                                    >
                                                        {item.children}
                                                    </Box>
                                                </HStack>
                                                {item.description && (
                                                    <Box
                                                        px={1}
                                                        color="whiteAlpha.700"
                                                        fontStyle="italic"
                                                    >
                                                        {item.description}
                                                    </Box>
                                                )}
                                            </VStack>
                                        ))}
                                    </VStack>
                                ))}
                            </VStack>
                        )}
                        {rankingProps && (
                            <HStack spacing={2}>
                                <Text color="whiteAlpha.700">Ranked by</Text>
                                <HStack spacing={1}>
                                    <Icon w={3} h={3} as={FaSortAmountDown} />
                                    <Text>{rankingProps.children}</Text>
                                </HStack>
                            </HStack>
                        )}
                        {descriptionProps && (
                            <Box fontStyle="italic" color="whiteAlpha.700">
                                {descriptionProps.children}
                            </Box>
                        )}
                        {conditionListProps && conditionItemProps === null && (
                            <Wrap>
                                <WrapItem>
                                    <Box fontWeight="medium">
                                        <Text color="whiteAlpha.500">
                                            No filters specified
                                        </Text>
                                    </Box>
                                </WrapItem>
                            </Wrap>
                        )}
                        {conditionItemProps && conditionListProps?.displayAsTags && (
                            <VStack
                                pt={2}
                                borderTop="2px"
                                borderStyle="solid"
                                borderColor="whiteAlpha.100"
                                align="start"
                                spacing={1}
                                w="full"
                            >
                                {/* {conditionListProps.label ? (
                                    <HStack h="full" align="center">
                                        <Box
                                            textTransform="uppercase"
                                            color="whiteAlpha.500"
                                            fontSize="xs"
                                            fontWeight="semibold"
                                            letterSpacing="wide"
                                        >
                                            {conditionListProps.label}
                                        </Box>
                                    </HStack>
                                ) : null} */}
                                <Wrap spacingY={1}>
                                    {conditionListProps.label ? (
                                        <WrapItem>
                                            <HStack h="full" align="center">
                                                <Box
                                                    textTransform="uppercase"
                                                    color="whiteAlpha.500"
                                                    fontSize="xs"
                                                    fontWeight="semibold"
                                                    letterSpacing="wide"
                                                >
                                                    {conditionListProps.label}
                                                </Box>
                                            </HStack>
                                        </WrapItem>
                                    ) : null}
                                    {conditionItemProps.map((item, index) => (
                                        <WrapItem key={index}>
                                            <Tag
                                                // size="sm"
                                                variant="subtle"
                                                bg="whiteAlpha.100"
                                                // bg="green.400"
                                                // colorScheme="green"
                                                // bg="blackAlpha.500"
                                            >
                                                {item.children}
                                            </Tag>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </VStack>
                        )}
                        {conditionItemProps && !conditionListProps?.displayAsTags && (
                            <Wrap
                                spacingY={0}
                                // bg="blackAlpha.500"
                                // borderTop="2px solid"
                                // borderColor="whiteAlpha.200"
                                // px={0}
                                // px={2}
                                // py={2}
                            >
                                {conditionItemProps.map((item, index) => (
                                    <WrapItem key={index}>
                                        <Box fontWeight="medium">
                                            <Box
                                                as="span"
                                                color="whiteAlpha.700"
                                                textTransform="capitalize"
                                                fontWeight="semibold"
                                            >
                                                {item.name}
                                            </Box>{' '}
                                            <Box
                                                as="span"
                                                color="whiteAlpha.700"
                                                fontSize="sm"
                                                fontStyle="italic"
                                                // fontWeight="bold"
                                            >
                                                {item.operator}
                                            </Box>{' '}
                                            <Box
                                                as="span"
                                                color="whiteAlpha.800"
                                                fontWeight="semibold"
                                            >
                                                {item.children}
                                            </Box>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        )}
                        {props.children}
                    </VStack>
                </Box>
            );
        },
        Name() {
            return <></>;
        },
        Date() {
            return <></>;
        },
        Value() {
            return <></>;
        },
        Formula() {
            return <></>;
        },
        Ranking() {
            return <></>;
        },
        Description() {
            return <></>;
        },
        Condition: {
            List() {
                return <></>;
            },
            Item() {
                return <></>;
            },
        },
        Tooltip(props) {
            return <Tooltip placement="top" hasArrow={true} bg="#1d1c21" {...props} />;
        },
    };
    return instance;
}
