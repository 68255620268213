import { SetupIntentAdapter } from '../../../../../app/billing';
import { SetupIntentImplConfig } from './setupIntentConfig';

export function createSetupIntentImpl(config: SetupIntentImplConfig): SetupIntentAdapter {
    const {
        api: { billing: api },
    } = config;
    return {
        async create(context, props) {
            const response = await api.setupIntent.create(context, props);
            return { clientSecret: response.client_secret };
        },
    };
}
