import { z } from 'zod';
import { ViewPropertySchema } from '../view/viewSchema';

export const PluginDependencyItemSchema = z.object({
    integration: z.string(),
});

export const PluginDependencyNestedGroupSchema = z.object({
    mode: z.literal('all'),
    items: z.array(PluginDependencyItemSchema),
});

export const PluginDependencyAnyChildSchema = z.union([
    PluginDependencyItemSchema,
    PluginDependencyNestedGroupSchema,
]);

export const PluginDependencyGroupSchema = z.discriminatedUnion('mode', [
    z.object({
        mode: z.literal('all'),
        items: z.array(PluginDependencyAnyChildSchema),
    }),
    z.object({
        mode: z.literal('any'),
        items: z.array(PluginDependencyAnyChildSchema),
    }),
]);

export const PluginSchema = z.object({
    id: z.string(),
    name: z.string(),
    traits: z.array(ViewPropertySchema),
    dependencies: PluginDependencyGroupSchema,
});

export const PluginListResponseSchema = z.object({
    data: z.array(PluginSchema),
});
