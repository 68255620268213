import { useQueryClient } from '@tanstack/react-query';
import { Kernel } from '../../../base';
import { ConversationMessageAdapter } from './conversationMessageAdapter';
import { MessageRepository } from './conversationMessageInterface';
import { MessageEntity } from './conversationMessageModel';
import { UserScope } from '../../platform';
import { MessageQuery } from './conversationMessageQuery';
import { getMessageQueryKey } from './conversationMessageHelper';

export function createMessageRepository(
    kernel: Kernel,
    adapter: ConversationMessageAdapter
): MessageRepository {
    return {
        usePrefetch(context) {
            const client = useQueryClient();
            return {
                load(query, options) {
                    const queryKey = getMessageQueryKey(context, query);
                    return client.prefetchQuery({
                        ...options,
                        queryKey,
                        queryFn: async () => {
                            const response = await adapter.find(context, query);
                            return response;
                        },
                    });
                },
            };
        },
        useFind(context: UserScope, query: MessageQuery, options) {
            const queryKey = getMessageQueryKey(context, query);
            const result = kernel.infra.repository.useQuery<MessageEntity[], Error>({
                ...options,
                staleTime: Infinity,
                queryKey,
                queryFn: async () => {
                    const response = await adapter.find(context, query);
                    return response;
                },
                keepPreviousData: true,
            });
            return result;
        },
    };
}
