import { makeObservable, observable, runInAction } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { InsightRouteStateValue, InsightRouteStore } from '../../../route';
import { WorkspaceStoreConfig } from './stateConfig';

export class InsightStoreImpl extends StoreBase implements InsightRouteStore {
    state: InsightRouteStateValue;

    constructor(config: WorkspaceStoreConfig) {
        super();
        this.state = config.initialValue;
        makeObservable(this, {
            state: observable,
        });
    }

    getState() {
        return this.state;
    }

    setState(value: InsightRouteStateValue) {
        runInAction(() => {
            this.state = value;
        });
    }
}
