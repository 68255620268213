import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Portal } from '../../../domain/billing';
import { PaymentMethodConfig } from './paymentMethodConfig';
import { PaymentMethodRepository } from './paymentMethodInterface';
import { PaymentMethodCreatePortalProps } from './paymentMethodProps';

export function createPaymentMethodRepository(
    config: PaymentMethodConfig
): PaymentMethodRepository {
    const { adapter } = config;
    return {
        useList(context, options) {
            const query = useQuery({
                queryKey: ['payment-methods', context.organization],
                async queryFn() {
                    const response = await adapter.find(context);
                    return response;
                },
                keepPreviousData: true,
                staleTime: Infinity,
                ...options,
            });
            return query;
        },
        usePortal(context) {
            const mutationState = useMutation<
                Portal,
                Error,
                PaymentMethodCreatePortalProps
            >({
                mutationFn(payload) {
                    return adapter.portal(context, payload);
                },
            });
            return mutationState;
        },
    };
}
