import { SettingAccountController } from './accountInterface';
// import { SettingAccountConfig } from './accountConfig';

export function createSettingAccountController(
    // config: SettingAccountConfig['deps']
): SettingAccountController {
    return {
        useProps() {
            return {
                isVisible(item) {
                    return true;
                },
            };
        },
    };
}
