import { TagProps } from '@chakra-ui/react';
import {
    AnyVisualization,
    BarVisualizationResult,
    DistributionVisualizationResult,
} from '../../../../domain/visualization';
import { BarChartControllerProps } from '../../../../vendor/chakra-chart';
import { ValueFormatter } from '../../../../app/attributes';
import { ChartSeriesProps } from '../../../../ui';
import { DistributionVisualizationResultViewProps } from './distributionProps';
import { buildLegendProps } from '../legend';
import { VisualizationResultDateProps } from '../date';

export function buildDistributionVisualizationResultViewProps(
    // router: VisualizationResultRouter,
    formatter: ValueFormatter,
    // context: OrganizationContextV2,
    visualization: AnyVisualization,
    item: DistributionVisualizationResult
): DistributionVisualizationResultViewProps {
    const COLORS: TagProps['colorScheme'][] = ['purple', 'green'];

    const labelByValue = item.series.reduce(
        (acc, series) =>
            series.data.reduce(
                (acc, entry) =>
                    entry.value === null
                        ? acc
                        : {
                              ...acc,
                              [entry.value]: {
                                  value: formatter.format('percent', entry.value),
                                  secondary: entry.secondary
                                      ? formatter.format(item.type, entry.secondary)
                                      : null,
                              },
                          },
                acc
            ),
        {} as Record<number, { value: string | undefined; secondary: string | null }>
    );
    const labelByCategory = item.facet.terms.reduce(
        (acc, term) => ({
            ...acc,
            [term.key]: formatter.format(item.facet.property.type, term.key),
        }),
        {} as Record<string, string | undefined>
    );
    const [sample] = item.series;

    const chartProps: BarChartControllerProps = {
        labels: sample.data.map((item) => item.category),
        series: item.series.map((series, index): ChartSeriesProps => {
            return {
                name: series.name,
                data: series.data.map((row) => ({
                    value: row.value,
                    secondary: row.secondary ?? undefined,
                })),
                colorScheme: COLORS[index] ?? 'gray',
            };
        }),
        formatValue(value) {
            const label = labelByValue[value];
            if (label?.value === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label.value;
        },
        formatScale(value) {
            const label = labelByValue[value];
            if (label?.value === undefined) {
                // fallback to handle dynamic axes
                return formatter.format('percent', value);
            }
            return label.value;
        },
        formatTooltip(value) {
            const label = labelByValue[value];
            if (label?.secondary === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label.secondary;
        },
        formatCategory(value) {
            const label = labelByCategory[value];
            if (label === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label;
        },
    };

    const legendProps = buildLegendProps(visualization, item, { colors: COLORS });

    const dateProps: VisualizationResultDateProps = {
        period: {
            label: formatter.format('daterange', item.period, {
                notation: 'short',
            }),
        },
    };

    return {
        kind: 'distribution',
        getDateProps() {
            return dateProps;
        },
        getLegendProps() {
            return legendProps;
        },
        getChartProps() {
            return chartProps;
        },
    };
}
