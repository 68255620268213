import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { createTrackingSettingAccountStrategy } from './account';
import { createTrackingSettingAssetStrategy } from './assets';
import { createTrackingSettingBillingStrategy } from './billing';

export function createTrackingSettingsStrategy(): ApplicationEntryEnhancer {
    return compose(
        createTrackingSettingAccountStrategy(),
        createTrackingSettingAssetStrategy(),
        createTrackingSettingBillingStrategy()
    );
}
