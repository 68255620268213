import moment from 'moment';
import { ApplicationContext } from '../../hooks';
import type { DateRange } from '../daterange';
import { durationDifference } from './durationHelper';
import { Duration } from './durationModel';

export function durationToString(
    context: Pick<ApplicationContext, 'formatter'>,
    value: Duration
) {
    return context.formatter.duration(value);
}

export function durationToRange(duration: Duration, now = new Date()): DateRange {
    // const differenceDays = durationDifference(duration, 'day');
    const end = now;
    const start = moment.utc(end).subtract(duration.amount, duration.interval).toDate();
    return {
        start,
        end,
    };
}
