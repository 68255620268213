import { TableCellProps } from '@chakra-ui/react';
import { SeriesEventStatus } from '../../../api/v2';

export const EVENT_STYLE_MAPPING: { [key in SeriesEventStatus]: TableCellProps } = {
    info: {},
    warn: {
        color: 'yellow.400',
        bg: 'yellow.900',
    },
    danger: {
        color: 'red.400',
        bg: 'red.900',
    },
    success: {
        color: 'green.400',
        bg: 'green.900',
    },
};
