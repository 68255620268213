import { QueryFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { createSession, SessionCreatePayload, CreatedSessionDto } from '../../../api';

export function createSessionHandler(
    context: ApplicationContext,
    payload: SessionCreatePayload
): QueryFunction<CreatedSessionDto> {
    return () => {
        return createSession(context.api, payload);
    };
}
