import { Box, FlexProps, Grid, GridItem } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
    Chart,
    useChart,
    useLineChart,
    LineChart,
    LineChartProps,
    ChartWrapperProps,
    LineChartV2,
} from '../../../../ui';

export interface LineVisualizationProps extends LineChartProps {
    style?: {
        wrapper?: ChartWrapperProps['style'];
    };
}

export const LineVisualization: React.FC<
    { children?: React.ReactNode | undefined } & LineVisualizationProps
> = ({ data, ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: data.datasets,
    });
    const line = useLineChart({
        chart,
        data,
        options: props,
    });
    // console.log('DEBUG chart', chart);
    // console.log('DEBUG line', line);
    // console.log('getWrapperProps', chart.getWrapperProps());
    // console.log('getLegendProps', chart.getLegendProps());
    // console.log('getContentProps', chart.getContentProps());
    // console.log('props', props);
    return (
        <Grid
            {...props.style?.wrapper}
            width={props.width}
            height={props.height}
            templateRows="min-content 1fr"
        >
            <GridItem p={6}>
                <Chart.Legend {...chart.getLegendProps()} />
            </GridItem>
            <GridItem>
                <LineChart {...line.getLineChartProps()} />
            </GridItem>
        </Grid>
    );
    // return (
    //     <Chart.Wrapper style={props.style?.wrapper} {...chart.getWrapperProps()}>
    //         <Chart.Legend {...chart.getLegendProps()} />
    //         <LineChart {...line.getLineChartProps()} />
    //     </Chart.Wrapper>
    // );
};

export const LineVisualizationV2: React.FC<
    { children?: React.ReactNode | undefined } & LineVisualizationProps
> = ({ data, ...props }) => {
    const chart = useChart({
        width: props.width ?? 0,
        height: props.height ?? 0,
        datasets: data.datasets,
    });
    const line = useLineChart({
        chart,
        data,
        options: props,
    });
    // console.log('DEBUG chart', chart);
    // console.log('DEBUG line', line);
    console.log('i am v2');
    return <LineChartV2 {...line.getLineChartProps()} />;
};
