import { Center } from '@chakra-ui/react';
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../animations/animationLoader.json';

export const CardSpinner: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = () => (
    <Center height="100%">
        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData,
            }}
            height={50}
            width={50}
        />
    </Center>
);
