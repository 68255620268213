import { useMemo } from 'react';
import { relativePeriodToDates } from '../../../../../../domain';
import { ReportControllerBaseConfig } from '../../../base';
import {
    ReportChapterViewProps,
    ReportSectionViewProps,
    ReportVisualizationViewProps,
} from '../../../view';
import { ReportInstanceDetailController } from './instanceDetailInterface';

export function createReportInstanceDetailController(
    config: ReportControllerBaseConfig
): ReportInstanceDetailController {
    const {
        kernel: {
            infra: { formatter },
            provider: { createPageController },
        },
    } = config;
    const pageController = createPageController({
        id: 'report',
    });
    return {
        useProps(context, item, props) {
            const page = pageController.useProps(
                { entity: { id: item.instance.id, object: 'report' } },
                {}
            );

            const chapters = useMemo(
                () =>
                    item.instance.chapters.map(
                        (chapter): ReportChapterViewProps => ({
                            title: chapter.title,
                            subtitle: chapter.description,
                            period: {
                                label: formatter.daterange({
                                    start: item.instance.period.start,
                                    end: item.instance.period.end,
                                }),
                            },
                            updated: {
                                label: [
                                    'Data updated',
                                    formatter
                                        .timeago(item.instance.updated_at, {
                                            granularity: 'day',
                                        })
                                        .toLowerCase(),
                                ].join(' '),
                            },
                            content: {
                                items: chapter.sections.map((section) => ({
                                    label: section.title,
                                    onClick() {
                                        const el = document.getElementById(
                                            [chapter.key, section.key].join('_')
                                        );
                                        if (el) {
                                            el.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    },
                                })),
                            },
                            sections: chapter.sections.map(
                                (section): ReportSectionViewProps => {
                                    // const absolute = relativePeriodToDates(props.period);
                                    const sourceLabel = [
                                        `Varos - Facebook Ads integration`,
                                    ].join(', ');
                                    // console.log('absolute', props.period, absolute);
                                    return {
                                        id: [chapter.key, section.key].join('_'),
                                        title: section.title,
                                        description: section.description,
                                        visualizations: section.visualizations.map(
                                            (
                                                visualization
                                            ): ReportVisualizationViewProps => ({
                                                title: visualization.title,
                                                description: visualization.description,
                                                caption: visualization.caption,
                                                type: visualization.style.type,
                                                source: {
                                                    label: sourceLabel,
                                                },
                                                visualization: { ...visualization },
                                            })
                                        ),
                                    };
                                }
                            ),
                        })
                    ),
                [item.instance.chapters, props.period]
            );

            return {
                page,
                chapters,
            };
        },
    };
}
