import React from 'react';
import { ReportContainerBaseConfig } from '../../../base';
import { ReportErrorController } from './reportErrorInterface';
import { ReportErrorContainerProps, ReportErrorViewProps } from './reportErrorProps';

export function createReportErrorContainer(
    config: ReportContainerBaseConfig,
    controller: ReportErrorController,
    View: React.FC<{ children?: React.ReactNode | undefined } & ReportErrorViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & ReportErrorContainerProps> {
    return ({ children, ...containerProps }) => {
        const props = controller.useProps(containerProps);
        return <View {...props} />;
    };
}
