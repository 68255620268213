import { DashboardsByPlugin } from '../../../../config';
import { PeerStatusAdapter } from '../../../app/peers';
import { PeerStatusImplConfig } from './peerStatusConfig';

export function createPeerStatusImpl(config: PeerStatusImplConfig): PeerStatusAdapter {
    return {
        async findOne(context, query) {
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // return {
            //     range: [50],
            //     quality: 'relevant_sample',
            // };
            const dashboard = DashboardsByPlugin[query.plugin.id];
            if (!dashboard) {
                throw new Error(`unmapped plugin ${query.plugin.id}`);
            }
            const response = await config.api.competitive.create(
                {
                    auth: context.auth,
                },
                {
                    dashboard,
                    asset: Number(context.workspace.id),
                    condition: query.conditions,
                }
            );
            return response;
        },
    };
}
