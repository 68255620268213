import { keyBy } from 'lodash';
import { DatasetProperty } from '../../../../domain/datasets';
import { ValueFormatter } from '../../../../app/attributes';
import { TableRow, TableControllerProps, TableRowData } from '../../../common';
import { EntryListAggregate } from './entryListModel';

export function buildTableControllerProps(
    formatter: ValueFormatter,
    root: EntryListAggregate
): TableControllerProps {
    const propertiesByKey = keyBy(
        root.configuration.schema,
        (item) => item.key
    ) as Record<string, DatasetProperty | undefined>;

    return {
        item: {
            rows: root.items.map((item) => {
                const raw = item.entry.data;
                return {
                    id: item.entry.id,
                    data: Object.entries(raw).reduce((acc, [key, value]) => {
                        const property = propertiesByKey[key];
                        if (!property) {
                            console.warn(`property ${key} not found in row`, raw);
                        }
                        return {
                            ...acc,
                            [key]: property
                                ? formatter.format(property.type, value as any)
                                : JSON.stringify(value),
                        };
                    }, {} as TableRowData),
                };
            }),
            columns: root.configuration.schema.map((column) => ({
                key: column.key,
                title: column.name,
            })),
        },
    };
}
