import { chain } from 'lodash';
import { AnyQuerySegment } from '../../../api';
import { isAbsolutePeriod } from '../../../domain';
import { QueryRequest } from '../../domain/query';
import { ReportSegment, ReportControl, ReportMetric } from '../../domain/report';

export function createReportQueryFromMetrics(
    config: {
        partitionKeyByView: Record<string, string | undefined>;
    },
    control: ReportControl,
    metrics: ReportMetric[],
    segments: ReportSegment[]
): QueryRequest[] {
    const base: Omit<
        QueryRequest,
        'projections' | 'source' | 'segment' | 'filters' | 'segments'
    > = {
        flags: { compare: true },
        comparison: control.date.comparison,
        granularity: control.date.granularity ?? 'week',
    };

    return chain(metrics)
        .groupBy((metric) => metric.source)
        .map((group): QueryRequest => {
            const [sample] = group;
            const querysegments: AnyQuerySegment[] = [
                {
                    kind: 'asset',
                    name: 'My company',
                    description: null,
                },
            ];
            // TODO hack. we should ensure the same partition key across all dashboards
            const partitionKey = config.partitionKeyByView[sample.source] ?? 'TS';
            const segment = segments.find(
                (segment) => segment.dashboard.id === sample.dependency.dashboard.id
            );

            // TODO this is a hack. in the future the dashboard thresholds might be dynamic
            // and so we should have a generic way of inferring the correct segment to choose as the competitive set
            if (segment?.cohort?.mode === 'fixed') {
                querysegments.push({
                    kind: 'range',
                    name: 'Competitive set',
                    description: `Competitive Sets are derived from the Competitive Set filters you have set in the individual dashboards (i.e., Facebook Ads). If you'd like to see or change those configurations, go to those dashboards and look at the Competitive Set filters.`,
                    range: {
                        column: segment.cohort.config.fixed.comparison,
                        start: {
                            kind: 'percentile',
                            value: 33,
                        },
                        end: {
                            kind: 'percentile',
                            value: 66,
                        },
                    },
                    reducer: {
                        kind: 'percentile',
                        value: 50,
                    },
                });
            } else {
                querysegments.push({
                    kind: 'expression',
                    name: 'Competitive set',
                    description: `Competitive Sets are derived from the Competitive Set filters you have set in the individual dashboards (i.e., Facebook Ads). If you'd like to see or change those configurations, go to those dashboards and look at the Competitive Set filters.`,
                    expr: {
                        kind: 'boolean',
                        value: true,
                    },
                    reducer: {
                        kind: 'percentile',
                        value: 50,
                    },
                });
            }
            return {
                ...base,
                projections: [
                    {
                        name: 'main',
                        columns: group.map((metric) => ({
                            key: metric.key,
                            expr: `compare(${metric.key})`,
                        })),
                    },
                ],
                source: { view: sample.source },
                filters: [
                    isAbsolutePeriod(control.date.period)
                        ? {
                              key: partitionKey,
                              operator: 'between',
                              value: {
                                  from: control.date.period.start,
                                  to: control.date.period.end,
                              },
                          }
                        : {
                              key: partitionKey,
                              operator: 'previous',
                              value: control.date.period,
                          },
                    ...(segment?.filters ?? []),
                ],
                segment: segment?.segment ?? [],
                segments: querysegments,
            };
        })
        .value();
}
