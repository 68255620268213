import { chain } from 'lodash';
import { AxiosInstance } from 'axios';
import { PluginSlugs } from '../../../../config';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { PluginDto } from './pluginModel';
import { PluginResource } from './pluginInterface';
import { PluginListResponseSchema } from './pluginSchema';

const OVERRIDES: Record<string, Partial<PluginDto>> = {
    [PluginSlugs.LINKEDIN]: {
        name: 'LinkedIn Ads',
        traits: [
            {
                key: 'vertical-v2',
                name: 'Vertical',
                description: null,
                type: {
                    kind: 'reference',
                    id: 'vertical-v2',
                },
                source: 'vertical-v2',
            },
        ],
    },
};

export function createPluginResource(client: AxiosInstance): PluginResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get<ListResponse<PluginDto>>(
                '/api/v2/plugins',
                {
                    params: {
                        ...params,
                    },
                    headers: {
                        ...headers,
                    },
                }
            );
            const parsed = PluginListResponseSchema.parse(response.data);

            const mapped: PluginDto[] = chain([
                ...parsed.data.map((item) => {
                    const overrides = OVERRIDES[item.id];
                    if (!overrides) {
                        return item;
                    }
                    return { ...item, ...overrides };
                }),
            ])
                .uniqBy((item) => item.id)
                .value();

            return { ...parsed, data: mapped };
        },
    };
}
