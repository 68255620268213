import { PlanCategoryKey } from '../../domain';
import { PlanId } from '../../api';
import PaperplaneSVG from '../../svg/paperplane.svg';
import BiplaneSVG from '../../svg/biplane.svg';
import JetPlaneSVG from '../../svg/jetplane.svg';
import RocketLaunchSVG from '../../svg/rocketlaunch.svg';

const DEFAULTS = {
    brand: {
        icon: BiplaneSVG,
        text: 'Upgrade',
    },
    agency: {
        icon: JetPlaneSVG,
        text: 'Upgrade',
    },
};

export const SVG_BY_PLAN_CATEGORY: { [key in PlanCategoryKey]: string } = {
    free: PaperplaneSVG,
    brand: BiplaneSVG,
    agency: JetPlaneSVG,
    enterprise: RocketLaunchSVG,
};

export const SVG_BY_PLAN: { [key in PlanId]: string } = {
    brands_free: PaperplaneSVG,
    brands_0_10k: DEFAULTS.brand.icon,
    brands_10k_50k: DEFAULTS.brand.icon,
    brands_50k_100k: DEFAULTS.brand.icon,
    brands_100k_500k: DEFAULTS.brand.icon,
    brands_500k_plus: DEFAULTS.brand.icon,
    agencies_1_5: DEFAULTS.agency.icon,
    agencies_6_15: DEFAULTS.agency.icon,
    agencies_16_30: DEFAULTS.agency.icon,
    agencies_31_50: DEFAULTS.agency.icon,
    agencies_50_plus: DEFAULTS.agency.icon,
    agency_base: DEFAULTS.agency.icon,
    agency_pro: DEFAULTS.agency.icon,
    free: PaperplaneSVG,
    brands: BiplaneSVG,
    agencies: JetPlaneSVG,
    enterprise: RocketLaunchSVG,
};

export const CTA_ONBOARDING_BY_PLAN: {
    [key in PlanId]: { text: string; href?: string };
} = {
    brands_free: {
        text: 'Start Free',
    },
    free: {
        text: 'Start Free',
    },
    brands: {
        text: 'Free Trial',
    },
    brands_0_10k: {
        ...DEFAULTS.brand,
        text: 'Get Started',
    },
    brands_10k_50k: {
        ...DEFAULTS.brand,
        text: 'Get Started',
    },
    brands_50k_100k: {
        ...DEFAULTS.brand,
        text: 'Get Started',
    },
    brands_100k_500k: {
        ...DEFAULTS.brand,
        text: 'Get Started',
    },
    brands_500k_plus: {
        ...DEFAULTS.brand,
        text: 'Get Started',
    },
    agencies_1_5: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agencies_6_15: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agencies_16_30: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agencies_31_50: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agencies_50_plus: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agency_base: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agency_pro: {
        ...DEFAULTS.agency,
        text: 'Get Started',
    },
    agencies: {
        text: 'Get Started',
    },
    enterprise: {
        text: 'Talk to us',
        href: 'https://calendly.com/yardenshaked/varos-enterprise',
    },
};

export const CTA_INAPP_BY_PLAN: { [key in PlanId]: { text: string; href?: string } } = {
    brands_free: {
        text: 'Upgrade',
    },
    free: {
        text: 'Upgrade',
    },
    brands: {
        text: 'Upgrade',
    },
    brands_0_10k: DEFAULTS.brand,
    brands_10k_50k: DEFAULTS.brand,
    brands_50k_100k: DEFAULTS.brand,
    brands_100k_500k: DEFAULTS.brand,
    brands_500k_plus: DEFAULTS.brand,
    agencies_1_5: DEFAULTS.agency,
    agencies_6_15: DEFAULTS.agency,
    agencies_16_30: DEFAULTS.agency,
    agencies_31_50: DEFAULTS.agency,
    agencies_50_plus: DEFAULTS.agency,
    agency_base: DEFAULTS.agency,
    agency_pro: DEFAULTS.agency,
    agencies: {
        text: 'Upgrade',
    },
    enterprise: {
        text: 'Talk to us',
        href: 'https://calendly.com/yardenshaked/varos-enterprise',
    },
};
