import { ChakraStepperController } from './chakraStepperInterface';

export function createChakraStepperController(): ChakraStepperController {
    return {
        useProps(props) {
            return {
                index: props.selected,
                onChange: props.onChange,
            };
        },
    };
}
