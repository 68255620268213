import { useMemo } from 'react';
import { DataSourceListViewConfig } from './sourceListConfig';
import { DataSourceListController } from './sourceListtInterface';
import { DataSourceListViewProps } from './sourceListProps';
import { buildDataSourceItemProps, DataSourceItemProps } from '../item';
import { chain } from 'lodash';

export function createDataSourceListController(
    config: DataSourceListViewConfig
): DataSourceListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, props): DataSourceListViewProps {
            const itemPropsByDataset = useMemo(
                () =>
                    chain(props.items)
                        .reduce(
                            (acc, item) => ({
                                ...acc,
                                [item.entity.id]: buildDataSourceItemProps(
                                    formatter,
                                    item
                                ),
                            }),
                            {} as Record<string, DataSourceItemProps>
                        )
                        .value(),
                [props.items]
            );

            // console.log('itemPropsByDataset', itemPropsByDataset);
            // console.log('props.items', props.items);

            const items = useMemo(
                () =>
                    chain(props.items)
                        .orderBy(
                            (item) => [
                                item.mapping.integration.id,
                                Number(item.mapping.createdAt),
                            ],
                            ['asc', 'desc']
                        )
                        .value(),
                [props.items]
            );

            return {
                status: items.length > 0 ? 'loaded' : 'empty',
                items,
                getItemProps(item) {
                    const itemProps = itemPropsByDataset[item.entity.id];
                    return itemProps;
                },
            };
        },
    };
}
