import React from 'react';
import { Box, HStack, Text, Heading, Flex, Spinner, transition } from '@chakra-ui/react';

export default function ({
    isLoading,
    children,
}: {
    isLoading: boolean;
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <Box width="full" height="full" position="relative">
            {isLoading && (
                <Box
                    width="full"
                    height="full"
                    position="absolute"
                    opacity={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDir="column"
                    zIndex={100}
                >
                    <Spinner />
                    <Text>Loading..</Text>
                </Box>
            )}
            <Box
                width="full"
                height="full"
                position="relative"
                {...(isLoading
                    ? {
                          opacity: 0.5,
                          sx: {
                              userSelect: 'none',
                              pointerEvents: 'none',
                              transition: 'opacity .3s',
                          },
                      }
                    : {})}
            >
                {children}
            </Box>
        </Box>
    );
}
