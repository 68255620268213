import { CardIds } from '../../card';
import { QueryDto } from '../../../api';
import {
    SHOPIFY_ABANDONED_CHECKOUT_QUERY_OVERRIDES,
    SHOPIFY_ORDERS_QUERY_OVERRIDES,
    SHOPIFY_REVENUE_GROWTH_QUERY_OVERRIDES,
} from './shopify';
import {
    FACEBOOK_CPA_QUERY_OVERRIDES,
    FACEBOOK_KPIS_QUERY_OVERRIDES,
} from './facebook-cpa';
import { GA4_ORDERS_QUERY_OVERRIDES } from './ga4';

// Override card query configurations
export const QUERY_OVERRIDES: Record<
    number | string,
    ((payload: { queries: QueryDto[] }) => QueryDto[]) | undefined
> = {
    // [CardIds.SHOPIFY_PERFORMANCE_SUMMARY]: (payload) => {
    //     const [first] = payload.queries;
    //     console.log('overriding shopify', payload);
    //     return [
    //         { ...first, ...SHOPIFY_REVENUE_GROWTH_QUERY_OVERRIDES(first) },
    //         { ...first, ...SHOPIFY_ABANDONED_CHECKOUT_QUERY_OVERRIDES(first) },
    //         { ...first, ...SHOPIFY_ORDERS_QUERY_OVERRIDES },
    //     ];
    // },
    [CardIds.GA4_TRAFFIC_PERFORMANCE_SUMMARY]: (payload) => {
        const [first] = payload.queries;
        console.log('overriding ga4', payload);
        return [{ ...first }, { ...first, ...GA4_ORDERS_QUERY_OVERRIDES }];
    },
    // [CardIds.FACEBOOK_CPA_SUMMARY]: (payload) => {
    //     const [first] = payload.queries;
    //     return [
    //         { ...first, ...FACEBOOK_CPA_QUERY_OVERRIDES(first) },
    //         { ...first, ...FACEBOOK_KPIS_QUERY_OVERRIDES(first) },
    //     ];
    // },
};
