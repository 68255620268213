import { useMemo } from 'react';
import { assert } from '../../../../../util/assert';
import { SettingAssetItemConfig } from '../assetConfig';
import { buildSettingAssetItemProps } from '../item';
import { SettingAssetListController } from './listInterface';
import { SettingAssetListItemProps } from './listProps';

export function createSettingAssetListController(
    config: Pick<SettingAssetItemConfig, 'controller'>
): SettingAssetListController {
    const {
        controller: {
            asset: { list: listController },
        },
    } = config;
    return {
        useProps(context, deps, props) {
            const listProps = listController.useProps(context, deps.list, props);

            const itemPropsByAsset = useMemo(() => {
                return listProps.items.reduce(
                    (acc, item) => {
                        const itemProps = listProps.getItemProps(item);
                        return {
                            ...acc,
                            [item.asset.id]: buildSettingAssetItemProps(item, itemProps),
                        };
                    },
                    {} as Record<string, SettingAssetListItemProps | undefined>
                );
            }, [listProps.items]);

            return {
                items: listProps.items,
                getItemProps(item) {
                    const itemProps = itemPropsByAsset[item.asset.id];
                    assert(itemProps, 'no item props found for asset');
                    return itemProps;
                },
                getListProps() {
                    return listProps;
                },
            };
        },
    };
}
