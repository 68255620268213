import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { SubscriptionResource } from './subscriptionInterface';
import { SubscriptionListSchema, SubscriptionSchema } from './subscriptionSchema';

export function createSubscriptionResource(client: AxiosInstance): SubscriptionResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get('/api/v1/notifications/subscriptions', {
                params: {
                    expand: ['asset', 'definition'],
                    asset: query.asset_ids,
                    limit: query.limit,
                },
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = SubscriptionListSchema.parse(response.data);
            return parsed;
        },
        async create(context, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.post(
                '/api/v1/notifications/subscriptions',
                payload,
                {
                    params: {
                        expand: ['asset', 'definition'],
                    },
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            const parsed = SubscriptionSchema.parse(response.data);
            return parsed;
        },
    };
}
