import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { PipelineResource } from './pipelineInterface';
import { PipelineListDtoSchema } from './pipelineSchema';

export function createPipelineResource(client: AxiosInstance): PipelineResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get('/api/v1/data-ingestion/pipelines', {
                params: {
                    asset_id: query.asset,
                },
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = PipelineListDtoSchema.parse(response.data);
            return parsed;
        },
    };
}
