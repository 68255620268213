import { useColorMode } from '@chakra-ui/react';
import React from 'react';
import { Button, ButtonProps } from '../../../../ui';

import { IntegrationTheme } from '../../integrationTheme';

export interface IntegrationCardConnectButtonProps
    extends Pick<
        ButtonProps,
        'leftIcon' | 'rightIcon' | 'isLoading' | 'onClick' | 'children'
    > {
    theme: IntegrationTheme;
}

export const IntegrationCardConnectButton: React.FC<
    { children?: React.ReactNode } & IntegrationCardConnectButtonProps
> = ({ theme, ...props }) => {
    return (
        <Button
            {...props}
            borderWidth={theme.colorMode === 'light' ? 'thin' : ''}
            colorScheme="gray"
            variant="outline"
            shadow="sm"
            size="sm"
        >
            {props.children}
        </Button>
    );
};
