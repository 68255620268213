import { NumberInputControllerPropsV2, NumberInputViewPropsV2 } from './inputNumberProps';

export function buildInputNumberProps(
    props: NumberInputControllerPropsV2
): NumberInputViewPropsV2 {
    return {
        value: props.value ?? undefined,
        onChange(_, valueAsNumber) {
            props.onChange(valueAsNumber);
        },
    };
}
