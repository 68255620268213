import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HStack, Box, BoxProps, StackProps } from '@chakra-ui/react';
import { fetchChildrenProps } from '../../v2/util';
import { useWindowSize } from 'react-use';
import { debounce } from 'lodash';
import { UseCollapseListConfig } from './collapseListHook';

const RESIZE_DEBOUNCE_MS = 100;

export const useCollapseList = (
    config: UseCollapseListConfig
): Pick<CollapseListWrapperProps, 'maxItems'> => {
    return {
        maxItems: config.maxItems,
    };
};

export interface CollapseListWrapperProps extends Omit<StackProps, 'children'> {
    maxItems: number;
    children: React.ReactElement[];
}

export const CollapseListWrapper: React.FC<
    { children?: React.ReactNode | undefined } & CollapseListWrapperProps
> = ({ children, maxItems, ...props }) => {
    const [currentMaxItems, setCurrentMaxItems] = useState(() => maxItems);

    const refContainer = useRef<HTMLDivElement>(null);
    const refTrigger = useRef<HTMLDivElement>(null);

    const {
        itemsProps: { renderItem, items, ...itemsProps },
        dropdownProps,
    } = fetchChildrenProps(children, {
        itemsProps: CollapseListItems,
        dropdownProps: CollapseListDropdown,
    });
    // const itemsIncluded = items.filter((_child, index) => index <= currentMaxItems);
    // const itemsExcluded = items.filter((_child, index) => index > currentMaxItems);

    const itemsIncluded = items.filter((_child, index) => index < maxItems);
    const itemsExcluded = items.filter((_child, index) => index >= maxItems);

    // const windowSize = useWindowSize();

    // const handleResize = useCallback(() => {
    //     if (!refContainer.current || !refTrigger.current) {
    //         return;
    //     }

    //     const boundsContainer = refContainer.current.getBoundingClientRect().right;
    //     const boundsTrigger = refTrigger.current.getBoundingClientRect().right;

    //     const difference = Math.abs(boundsContainer - boundsTrigger);

    //     if (boundsContainer > boundsTrigger) {
    //         setCurrentMaxItems(currentMaxItems + 1);
    //     } else if (boundsContainer <= boundsTrigger) {
    //         setCurrentMaxItems(currentMaxItems - 1);
    //     }
    // }, [refContainer, refTrigger, setCurrentMaxItems, currentMaxItems]);

    // const debouncedHandleResize = useMemo(
    //     () => debounce(handleResize, RESIZE_DEBOUNCE_MS),
    //     [handleResize]
    // );

    // useEffect(() => {
    //     debouncedHandleResize();
    // }, [windowSize.width]);

    return (
        <Box {...props} h="full">
            <HStack
                {...itemsProps}
                ref={refContainer}
                spacing={props.spacing}
                h="full"
                alignItems="center"
            >
                <HStack
                    h="full"
                    spacing={props.spacing}
                    alignItems="center"
                    flexShrink={0}
                >
                    {itemsIncluded.map((item, index) => (
                        <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
                    ))}
                </HStack>
                {itemsExcluded.length > 0 && (
                    <Box ref={refTrigger} zIndex={dropdownProps.zIndex}>
                        {dropdownProps.renderItems(itemsExcluded)}
                    </Box>
                )}
            </HStack>
        </Box>
    );
};

export interface CollapseListItemsProps<TItem = unknown>
    extends Omit<StackProps, 'children'> {
    items: TItem[];
    renderItem(item: TItem): React.ReactElement;
}

export const CollapseListItems: React.FC<
    { children?: React.ReactNode | undefined } & CollapseListItemsProps
> = ({ ...props }) => {
    return <div>{props.children}</div>;
};

export interface CollapseListDropdownProps<TItem = unknown>
    extends Pick<BoxProps, 'zIndex'> {
    renderItems(item: TItem[]): React.ReactElement;
}

export const CollapseListDropdown: React.FC<
    { children?: React.ReactNode | undefined } & CollapseListDropdownProps
> = ({ ...props }) => {
    return <div>{props.children}</div>;
};

export interface CollapseListComponents<TItem> {
    Wrapper: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    >;
    Items: React.FC<
        { children?: React.ReactNode | undefined } & CollapseListItemsProps<TItem>
    >;
    Trigger: React.FC<
        { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
    >;
    Dropdown: React.FC<
        { children?: React.ReactNode | undefined } & CollapseListDropdownProps<TItem>
    >;
}

export function createCollapseList<TItem>(): CollapseListComponents<TItem> {
    return {
        // @ts-expect-error
        Wrapper: CollapseListWrapper,
        // @ts-expect-error
        Items: CollapseListItems,
        Dropdown: CollapseListDropdown,
    };
}
