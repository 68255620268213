import { LegendSeries } from './component';
import { UseChartConfig } from './chartConfig';
import { UseChartReturnValue } from './chartModel';
import { useCallback, useState } from 'react';

export const useChart = (config: UseChartConfig): UseChartReturnValue => {
    const [visible, setVisible] = useState<string[]>(
        config.datasets.map((dataset) => dataset.label)
    );

    const handleVisibleChange = (value: string[]) => setVisible(value);

    const getWrapperProps: UseChartReturnValue['getWrapperProps'] = () => {
        return {};
    };
    const getLegendProps: UseChartReturnValue['getLegendProps'] = () => {
        return {
            // width: config.width,
            // height: config.height,
            visible,
            onVisibleChange: handleVisibleChange,
            series: config.datasets.map<LegendSeries>((dataset) => ({
                id: dataset.label,
                label: dataset.label,
                description: dataset.description ?? null,
                colorScheme: dataset.colorScheme,
            })),
        };
    };

    const getContentProps: UseChartReturnValue['getContentProps'] = () => {
        return {};
    };

    const isVisible = useCallback(
        (dataset: Pick<LegendSeries, 'id'>) => {
            return visible.includes(dataset.id);
        },
        [visible]
    );

    return {
        getWrapperProps,
        getLegendProps,
        getContentProps,
        isVisible,
    };
};
