import { configureApplicationEntrypoint } from '../../v2';

// HACK to integrate some of the new infrastructure with the legacy code
export const ApplicationEntrypointStatic = configureApplicationEntrypoint({
    urlParameterNames: {
        dashboard: 'dashboardId',
    },
    redirect: {
        missingDepsRedirectPath: 'setup',
    },
});
