import React, { useMemo } from 'react';
import { chain } from 'lodash';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import { MdBolt } from 'react-icons/md';
import { RecommendationDto } from '../../../../api';
import { useWorkspaceContextV2 } from '../../../context';
import { AnyInputProps, TreeInputOption } from '../../../ui';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { RecommendationEnhancerConfig } from '../recommendationConfig';
import { UseQueryResult } from '@tanstack/react-query';
import { PropertyInputController } from '../../../strategy/input';

export function createOnboardingRecommendationEnhancer(
    init: RecommendationEnhancerConfig = {}
): ApplicationEntryEnhancer {
    const TOOLTIP_LABEL = `This vertical was automatically suggested based on your brand profile`;

    return (create) => (config) => {
        function enhanceController(
            controller: PropertyInputController
        ): PropertyInputController {
            return {
                ...controller,
                useProps(...args): AnyInputProps {
                    const {
                        recommendations: { data: recommendations = [] },
                    } = useWorkspaceContextV2<{
                        recommendations: UseQueryResult<RecommendationDto[]>;
                    }>();
                    const original = controller.useProps(...args);
                    if (original.type !== 'tree') {
                        return original;
                    }

                    // HACK dangerous we cannot have dynamic use of hooks. this means
                    // that we cannot change the original input type after the initial mount
                    const optionsRecommended: TreeInputOption[] = useMemo(
                        () =>
                            chain(original.options)
                                .flatMap(({ children, ...option }) => children ?? [])
                                .filter((option) =>
                                    recommendations.some(
                                        (recommendation) =>
                                            recommendation.object.id === option?.value
                                    )
                                )
                                .map((option): TreeInputOption => {
                                    return {
                                        children: [],
                                        ...option,
                                        icons: [
                                            <Tooltip
                                                label={<Box p={2}>{TOOLTIP_LABEL}</Box>}
                                                placement="top"
                                            >
                                                <span>
                                                    <Icon as={MdBolt} color="gray.500" />
                                                </span>
                                            </Tooltip>,
                                            ...(option.icons ?? []),
                                        ],
                                    };
                                })
                                .unionBy((option) => option.value)
                                .value(),
                        []
                    );
                    // console.log('DEBUG enhance', original);
                    // console.log('DEBUG recommendations', recommendations);
                    // console.log('DEBUG UPDATED', {
                    //     ...original,
                    //     options: [...optionsRecommended, ...original.options],
                    // });
                    return {
                        ...original,
                        options: [...optionsRecommended, ...original.options],
                    };
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                attributes: {
                    ...config.controller.attributes,
                    createPropertyInput(controllerConfig) {
                        const controller =
                            config.controller.attributes.createPropertyInput(
                                controllerConfig
                            );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
