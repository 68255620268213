import { assert } from '../../../util/assert';
import { MetricSummaryAdapter } from '../../../app/metrics';
import { MetricCalculation, MetricCalculationSegment } from '../../../domain/metrics';
import {
    AnyQuerySegment,
    MovementDataValue,
    QueryRunPayload,
    runQuery,
} from '../../../../api';
import { castQueryToApi } from '../../api';
import { MetricSummaryImplConfig } from './summaryConfig';
import { DashboardByViewId, RANKING_BY_DASHBOARD } from '../configuration';

export function createMetricSummaryImpl(
    config: MetricSummaryImplConfig
): MetricSummaryAdapter {
    const {
        axios,
        api,
        infra: { builder },
    } = config;
    return {
        async find(context, query) {
            // HACK
            // const dashboard = DashboardByViewId[query.view.id];
            // const ranking = dashboard ? RANKING_BY_DASHBOARD[dashboard.id] : null;

            const request = builder.metric.build(
                {
                    ...context,
                    workspace: query.workspace,
                },
                {
                    date: {
                        period: query.period,
                        granularity: 'week',
                        comparison: {
                            amount: 1,
                            interval: 'period',
                        },
                    },
                    view: query.view,
                    metrics: query.metrics.map((item) => ({
                        definition: item,
                    })),
                    configuration: {
                        filters: [],
                        competitiveSet: {
                            conditions: query.peer.conditions,
                        },
                        ranking: query.ranking,
                        //  ?? {
                        //     kind: 'dynamic',
                        //     cohorts: [
                        //         {
                        //             visible: true,
                        //             name: 'Low performers',
                        //             value: 25,
                        //         },
                        //         {
                        //             visible: true,
                        //             name: 'Median',
                        //             value: 50,
                        //         },
                        //         {
                        //             visible: true,
                        //             name: 'High performers',
                        //             value: 75,
                        //         },
                        //     ],
                        // },
                    },
                }
            );

            const { segment: querySegemnt, ...mapped } = castQueryToApi(request);

            const payload: QueryRunPayload = {
                asset: query.workspace.id as number,
                query: mapped,
                segment: querySegemnt,
            };

            try {
                assert(context.auth.scheme.kind === 'legacy', 'unsupported auth scheme');
                await context.auth.scheme.store.waitAuthenticated();
                const response = await runQuery(axios, payload, {
                    // signal: options.signal,
                    headers: {
                        Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                    },
                });

                const calculations = query.metrics.flatMap((metric) => {
                    return response.queries.map((_query): MetricCalculation => {
                        const scalarKey = metric.key;
                        const [
                            meSegment,
                            // minSegment,
                            lowSegment,
                            midSegment,
                            highSegment,
                            // maxSegment,
                        ] = request.segments ?? [];

                        const [
                            meSeries,
                            // minSeries,
                            lowSeries,
                            midSeries,
                            highSeries,
                            // maxSeries,
                        ] = _query.series.items;

                        const current: MetricCalculationSegment = {
                            value: meSeries.data[0][scalarKey] as MovementDataValue,
                            definition: meSegment,
                        };

                        const min: MetricCalculationSegment = {
                            value: lowSeries.data[0][scalarKey] as MovementDataValue,
                            definition: lowSegment,
                        };

                        const low: MetricCalculationSegment = {
                            value: lowSeries.data[0][scalarKey] as MovementDataValue,
                            definition: lowSegment,
                        };

                        const mid: MetricCalculationSegment = {
                            value: midSeries.data[0][scalarKey] as MovementDataValue,
                            definition: midSegment,
                        };

                        const high: MetricCalculationSegment = {
                            value: highSeries.data[0][scalarKey] as MovementDataValue,
                            definition: highSegment,
                        };

                        const max: MetricCalculationSegment = {
                            value: highSeries.data[0][scalarKey] as MovementDataValue,
                            definition: highSegment,
                        };

                        return {
                            definition: metric,
                            peers: query.peer.conditions,
                            period: query.period,
                            comparison: query.comparison,
                            current,
                            min,
                            low,
                            mid,
                            high,
                            max,
                        };
                    });
                });

                // console.log('calculations', calculations);

                // console.log('response', response);
                return calculations;
            } catch (error) {
                console.error(error);
                return [];
            }

            // // console.log('query', query);
            // // console.log('request', request);

            // const segment: AnyQuerySegment = {
            //     kind: 'range',
            //     name: 'low',
            //     range: {
            //         column: 'cost_per_purchase',
            //         start: {
            //             kind: 'percentile',
            //             value: 0,
            //         },
            //         end: {
            //             kind: 'percentile',
            //             value: 33,
            //         },
            //     },
            // } as const;

            // return query.metrics.map(
            //     (definition): MetricCalculation => ({
            //         definition,
            //         period: query.period,
            //         peers: query.peer.conditions,
            //         comparison: {
            //             amount: 1,
            //             interval: 'period',
            //         },
            //         current: {
            //             definition: { kind: 'asset', name: 'My company' },
            //             value: {
            //                 change: 0.05,
            //                 comparison: 1,
            //                 base: 1.05,
            //             },
            //         },
            //         min: {
            //             definition: segment,
            //             value: {
            //                 change: 0.05,
            //                 comparison: 1,
            //                 base: 1.05,
            //             },
            //         },
            //         low: {
            //             definition: { ...segment, name: 'Low performers' },
            //             value: {
            //                 change: 0.05,
            //                 comparison: 1,
            //                 base: 1.05,
            //             },
            //         },
            //         mid: {
            //             definition: { ...segment, name: 'Median' },
            //             value: {
            //                 change: -0.03,
            //                 comparison: 1.03,
            //                 base: 1.0,
            //             },
            //         },
            //         high: {
            //             definition: { ...segment, name: 'High performers' },
            //             value: {
            //                 change: 0.05,
            //                 comparison: 1,
            //                 base: 1.05,
            //             },
            //         },
            //         max: {
            //             definition: segment,
            //             value: {
            //                 change: 0.05,
            //                 comparison: 1,
            //                 base: 1.05,
            //             },
            //         },
            //     })
            // );
        },
    };
}
