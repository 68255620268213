import { MutationFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import {
    createSubscription,
    CreateSubscriptionOptions,
    CreateSubscriptionPayload,
    SubscriptionDto,
} from '../../../api';

export function createSubscriptonMutation(
    context: Pick<ApplicationContext, 'api'>
): MutationFunction<
    SubscriptionDto,
    { payload: CreateSubscriptionPayload; options: CreateSubscriptionOptions }
> {
    return ({ payload, options }) => {
        return createSubscription(context.api, payload, options);
    };
}
