import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { MappableEntityResource } from './entityInterface';
import { MappableEntityListResponseSchema } from './entitySchema';

export function createMappableEntityResource(
    client: AxiosInstance
): MappableEntityResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'organization') {
                    headers = {
                        ...headers,
                        'x-varos-organization': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/integrations/mappables`, {
                params: {
                    ...params,
                    integrations: query.integrations,
                    page: 1,
                    page_size: 1000,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = MappableEntityListResponseSchema.parse(response.data);
            return {
                data: parsed.items,
            };
        },
    };
}
