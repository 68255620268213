import React from 'react';
import { AssistantContainerBaseConfig } from '../../base';
import { VisualizationBlockController } from './blockVisualizationInterface';
import { VisualizationBlockContainerProps } from './blockVisualizationProps';

export function createVisualizationBlockContainer(
    config: AssistantContainerBaseConfig,
    controller: VisualizationBlockController
): React.FC<
    { children?: React.ReactNode | undefined } & VisualizationBlockContainerProps
> {
    const {
        context: {
            root: { useContext: useRootContext },
            thread: { useContext: useThreadContext },
        },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = {
            root: useRootContext(),
            thread: useThreadContext(),
        };
        const props = controller.useProps(
            context.root,
            {
                thread: context.thread.item.thread,
                block: containerProps.block,
            },
            {}
        );
        return <View {...props} />;
    };
}
