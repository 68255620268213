import {
    Tooltip as ChakraTooltip,
    TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react';
import React from 'react';

export interface TooltipProps
    extends Pick<ChakraTooltipProps, 'label' | 'isDisabled' | 'placement' | 'hasArrow'> {
    children: React.ReactNode;
}

export const Tooltip: React.FC<
    { children?: React.ReactNode | undefined } & TooltipProps
> = ({ hasArrow = true, children, ...props }) => {
    return (
        <ChakraTooltip
            bg="#1d1c21"
            borderRadius={4}
            label={props.label}
            openDelay={0}
            hasArrow={hasArrow}
            {...props}
        >
            {children}
        </ChakraTooltip>
    );
};
