import React, { ReactNode } from 'react';
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, FormControlProps } from '@chakra-ui/react';

export interface FormCtrlProps extends FormControlProps {
    label?: string;
    helperText?: string;
    err?: string;
    children: ReactNode;
}

export default function ({ label, helperText, err, children, ...rest }: FormCtrlProps): React.ReactElement {
    return (
        <FormControl isInvalid={!!err} {...rest}>
            {label && <FormLabel htmlFor={rest.id}>{label}</FormLabel>}
            {children}
            {err && <FormErrorMessage>{err}</FormErrorMessage>}
            {helperText && !err && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
