import { z } from 'zod';
import { InsightDefinitionSchema } from '../definition';

export const InsightSchema = z.object({
    id: z.string(),
    definition: InsightDefinitionSchema,
    asset: z.number(),
    status: z.enum(['info', 'warning', 'danger', 'success']),
    priority: z.enum(['low', 'medium', 'high']),
    confidence: z.enum(['low', 'medium', 'high']),
    category: z.optional(z.enum(['opportunity', 'alert'])),
    kind: z.string(),
    title: z.string(),
    body: z.nullable(z.string()),
    hint: z.nullable(z.string()),
    payload: z.record(z.any()),
    tags: z.array(z.string()),
    period_start_at: z.date({ coerce: true }),
    period_end_at: z.date({ coerce: true }),
    comparison_start_at: z.nullable(z.date({ coerce: true })),
    comparison_end_at: z.nullable(z.date({ coerce: true })),
    created_at: z.date({ coerce: true }),
});

export const InsightListResponseSchema = z.object({
    data: z.array(InsightSchema),
});
