import { TagProps } from '@chakra-ui/react';
import {
    AnyVisualization,
    BarVisualizationResult,
    ComparisonVisualizationResult,
} from '../../../../domain/visualization';
import { ValueFormatter } from '../../../../app/attributes';
import { assert } from '../../../../util/assert';
import {
    ComparisonVisualizationResultValueProps,
    ComparisonVisualizationResultViewProps,
} from './comparisonProps';
import { buildLegendProps } from '../legend';
import { VisualizationResultDateProps } from '../date';

export function buildComparisonVisualizationResultViewProps(
    // router: VisualizationResultRouter,
    formatter: ValueFormatter,
    // context: OrganizationContextV2,
    visualization: AnyVisualization,
    item: ComparisonVisualizationResult
): ComparisonVisualizationResultViewProps {
    const COLORS: TagProps['colorScheme'][] = ['purple', 'green'];

    const dateProps: VisualizationResultDateProps = {
        period: {
            label: formatter.format('daterange', item.period, {
                notation: 'short',
            }),
        },
    };

    const labelByValueByMetricKey = item.entries.reduce(
        (acc, entry) => ({
            ...acc,
            [entry.metric.key]: entry.series.reduce(
                (acc, series) =>
                    series.data.reduce(
                        (acc, row) =>
                            row.value === null
                                ? acc
                                : {
                                      ...acc,
                                      [row.value]: {
                                          value: formatter.format(
                                              entry.metric.type,
                                              row.value
                                          ),
                                      },
                                  },
                        acc
                    ),
                {} as Record<number, { value: string } | undefined>
            ),
        }),
        {} as Record<string, Record<number, { value: string } | undefined> | undefined>
    );

    const [
        {
            series: [sample],
        },
    ] = item.entries;
    assert(sample, 'unexpected entries format');

    const valuePropsByMetricId = item.entries.reduce(
        (acc, entry) => {
            const formattedValues = labelByValueByMetricKey[entry.metric.key];
            assert(formattedValues, () => {
                console.log(
                    'labelByValueByMetric',
                    entry.metric.key,
                    labelByValueByMetricKey
                );
                return 'no entry found';
            });
            return {
                ...acc,
                [entry.metric.id]: entry.series.flatMap((series) =>
                    series.data.map((row): ComparisonVisualizationResultValueProps => {
                        const formattedValue =
                            row.value === null
                                ? { value: 'N/A' }
                                : formattedValues[row.value];
                        assert(formattedValue, () => {
                            console.log(
                                'formattedValues',
                                entry.metric.id,
                                row.value,
                                formattedValues
                            );
                            return 'no entry found';
                        });
                        return {
                            key: sample.key,
                            raw: row.value,
                            label: formattedValue.value,
                        };
                    })
                ),
            };
        },
        {} as Record<string, ComparisonVisualizationResultValueProps[] | undefined>
    );

    const legendProps = buildLegendProps(visualization, item, { colors: COLORS });

    return {
        kind: 'comparison',
        result: item,
        getDateProps() {
            return dateProps;
        },
        getMetrics() {
            return item.entries.map((row) => row.metric);
        },
        getValues(metric) {
            const valueProps = valuePropsByMetricId[metric.id];
            assert(valueProps, 'no value props found');
            return valueProps;
        },
        getLegendProps() {
            return legendProps;
            // const [{ series }] = item.entries;
            // assert(series, 'invalid format');
            // return series.map((series) => ({
            //     label: series.name,
            //     description: null,
            //     colorScheme: 'gray',
            // }));
        },
    };
}
