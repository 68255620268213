import { useMemo } from 'react';
import { PageIds } from '../../../../config';
import { PageAlertItemProps, PageAlertProps } from '../../../../view/page';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { OrganizationMappingLoaderData } from '../../../../view/assets';
import { SettingAssetMappingConfig } from './mappingConfig';
import { SettingAssetMappingController } from './mappingInterface';

export function createSettingAssetMappingController(
    init: Pick<SettingAssetMappingConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingAssetMappingController {
    const {
        controller: { mapping: mappingController },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const mappingData: OrganizationMappingLoaderData = {
                save: deps.mutation.save,
                aggregate: {
                    status: 'loaded',
                    data: props.item,
                },
            };

            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ASSET_MAPPING,
                    breadcrumbs: [
                        {
                            // NOTE this view is now nested under data sources
                            label: 'Data Sources',
                        },
                    ],
                },
            });
            const mappingProps = mappingController.useProps(
                context,
                deps.form,
                mappingData,
                {
                    asset: { id: context.workspace.id as number },
                    allowSubmitEmpty: true,
                }
            );

            const definitionListProps = mappingProps.getDefinitionListProps();
            const formProps = mappingProps.getFormStateProps();
            const conflictProps = mappingProps.getConflictListProps();
            const pageAlertProps = pageProps.getAlertProps();

            const alertProps = useMemo<PageAlertProps>(() => {
                let acc: PageAlertItemProps[] = [
                    ...pageAlertProps.items,
                    ...conflictProps.map(
                        (item): PageAlertItemProps => ({
                            status: 'warning',
                            label: item.text,
                        })
                    ),
                ];
                if (formProps.error) {
                    acc = [...acc, { status: 'error', label: formProps.error.message }];
                }
                return {
                    items: acc,
                };
            }, [pageAlertProps.items, conflictProps]);

            return {
                items: definitionListProps.items,
                getItemProps(item) {
                    const itemProps = mappingProps.getDefinitionItemProps(item);
                    return itemProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getEntityControlProps(...args) {
                    return mappingProps.getEntityControlProps(...args);
                },
                getBreadcrumbsProps() {
                    return pageProps.getBreadcrumbProps().items;
                },
                getMappingProps() {
                    return mappingProps;
                },
                getSubmitButtonProps() {
                    return {
                        ...mappingProps.getSubmitButtonProps(),
                        onClick: formProps.handleSubmit(async () => {
                            const response = await formProps.onSubmit();
                            deps.toast({
                                kind: 'success',
                                description: 'Data source mapping saved',
                            });
                            return response;
                        }),
                    };
                },
                getCancelLinkProps() {
                    return {
                        to: '..',
                    };
                },
                getIntegrationsLinkProps() {
                    return {
                        to: '/u/settings/integrations',
                    };
                },
                getAlertProps() {
                    return alertProps;
                },
            };
        },
    };
}
