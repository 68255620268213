import { QueryDto } from '../../../../api';

export const GA4_ORDERS_QUERY_OVERRIDES: Pick<
    QueryDto,
    'source' | 'projections' | 'aggregations'
> = {
    projections: [
        {
            name: 'absolute',
            columns: [
                {
                    key: 'repeating_users_rate',
                    alias: null,
                    expr: null,
                    type: 'float',
                },
            ],
        },
        {
            name: 'relative',
            columns: [
                {
                    key: 'repeating_users_rate',
                    alias: null,
                    expr: '(repeating_users_rate - compare(repeating_users_rate)) / compare(repeating_users_rate)',
                    type: { kind: 'movement' },
                },
            ],
        },
        {
            name: 'previous',
            columns: [
                {
                    key: 'repeating_users_rate',
                    alias: null,
                    expr: 'compare(repeating_users_rate)',
                    type: 'float',
                },
            ],
        },
    ],
    aggregations: [
        {
            title: 'Repeating Users Ratio',
            name: 'repeating_users_rate',
            inverse: false,
            expr: {
                kind: 'binary',
                operator: '/',
                left: {
                    kind: 'aggregation',
                    type: 'average',
                    column: 'returning_users_count',
                },
                right: {
                    kind: 'aggregation',
                    type: 'average',
                    column: 'new_users_count',
                },
            },
        },
    ],
    source: {
        view: 'ga4_repeat_users',
    },
};
