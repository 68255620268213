import { z } from 'zod';
import { CompanyConditionSchema } from './condition';
import { CompanyProfileSchema } from './profile';

// model

export const ExtraAttributeSchema = z.object({
    key: z.string(),
    value: z.unknown(), // Adjust as necessary if more specific types are needed
    type: z.unknown(), // Define possible types if needed
});

export const CountrySchema = z.object({
    code: z.string(),
    name: z.string(),
});

export const CompanySchema = z.object({
    id: z.string(),
    name: z.string(),
    domain: z.string(),
    description: z
        .string()
        .nullable()
        .transform((value) => (value?.trim() === '' ? null : value)),
    logo_url: z.string().nullable(),
    main_industry: z.string().nullable(),
    industries: z
        .array(z.string())
        .nullable()
        .transform((value) => value ?? []),
    revenue: z.string().nullable(),
    employees: z.string().nullable(),
    monthly_visitors: z.string().nullable(),
    country: CountrySchema.nullable(),
    extra_attributes: z.array(ExtraAttributeSchema),
    profiles: z.array(CompanyProfileSchema),
    updated_at: z.date({ coerce: true }).nullable(),
});

export const CompanyListSchema = z.object({
    items: z.array(CompanySchema),
});

// requests

export const CompanyFindRequestSchema = z
    .object({
        id: z.optional(z.array(z.string())),
        domain: z.optional(z.array(z.string())),
        varos: z.optional(z.array(z.string())),
        linkedin: z.optional(z.array(z.string())),
        after_id: z.string(),
        before_id: z.string(),
        limit: z.number(),
    })
    .partial();

export const CompanySearchRequestSchema = z
    .object({
        conditions: z.array(CompanyConditionSchema),
        term: z.string(),
        page: z.number(),
        limit: z.number(),
    })
    .partial();

// conditions: Optional[List[CompanySearchCondition]]
// term: Optional[str]
// limit: int = 25
// page: int = 1
