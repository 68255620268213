import React from 'react';
import { StudiesBaseRouteConfig } from '../base';
import { StudiesLayoutRouteViewProps } from './studiesLayoutProps';

export function createStudiesLayoutView(
    config: Pick<StudiesBaseRouteConfig, 'Layout'>
): React.FC<{ children?: React.ReactNode | undefined } & StudiesLayoutRouteViewProps> {
    const {
        Layout: {
            Domain: { Shell: Layout },
        },
    } = config;
    return (props) => {
        return <Layout {...props.layout}>{props.children}</Layout>;
    };
}
