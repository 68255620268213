import { MemberEntity, resolveProperties } from '../../../domain/attributes';
import { SurveyEntity } from './surveyModel';

export function resolveSurveyTypes(
    dataset: SurveyEntity,
    members: MemberEntity[]
): SurveyEntity {
    return {
        ...dataset,
        configuration: {
            ...dataset.configuration,
            schema: resolveProperties(members, dataset.configuration.schema),
        },
    };
}
