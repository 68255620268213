import { observer } from 'mobx-react';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { OnboardingCompetitiveSetConfig } from './onboardingCompetitiveSetConfig';
import { createOnboardingCompetitiveSetContainer } from './onboardingCompetitiveSetContainer';
import { createOnboardingCompetitiveSetLoader } from './onboardingCompetitiveSetLoader';
import { createOnboardingCompetitiveSetView } from './onboardingCompetitiveSetView';

export function createOnboardingCompetitiveSetItem(
    init: OnboardingCompetitiveSetConfig
): OnboardingAssetRouteStrategy {
    const { provider } = init;
    return {
        id: 'competitive_set',
        name: 'Select your competitors',
        slug: 'competitive-set',
        create(config) {
            const Route = createOnboardingCompetitiveSetContainer(
                init,
                createOnboardingCompetitiveSetLoader({
                    ...init,
                    ...config,
                    macro: init.macro.company.select,
                }),
                provider.createController({
                    ...init,
                    ...config,
                    macro: init.macro.company.select,
                }),
                createOnboardingCompetitiveSetView({
                    ...init,
                    ...config,
                })
            );
            return {
                Component: observer(Route),
            };
        },
    };
}
