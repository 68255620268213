import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { AssetEditFormProvider } from './assetEditInterface';
import { AssetEditFormValuesSchema } from './assetEditSchema';

export function createAssetEditFormProvider(): AssetEditFormProvider {
    return {
        useForm(context, props) {
            return useForm({
                defaultValues: {
                    name: props.item.asset.name,
                    url: props.item.asset.url ?? '',
                    kind: props.item.asset.type,
                },
                mode: 'onSubmit',
                resolver: zodResolver(AssetEditFormValuesSchema),
            });
        },
    };
}
