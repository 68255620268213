import { useEffect } from 'react';
import { OnboardingReceiptConfig } from './onboardingReceiptConfig';
import { OnboardingReceiptController } from './onboardingReceiptInterface';
import { OnboardingReceiptViewProps } from './onboardingReceiptProps';

export function createOnboardingReceiptController(
    config: Pick<OnboardingReceiptConfig, 'controller'>
): OnboardingReceiptController {
    const { controller } = config;
    return {
        useProps(deps, context, data, props): OnboardingReceiptViewProps {
            const pollProps = controller.report.poll.useProps(data.poll, {});

            useEffect(() => {
                if (pollProps.status === 'success') {
                    data.prepare.profile.update
                        .mutateAsync({ finishedOnboarding: true })
                        .then(() => console.log('finished onboarding'))
                        .catch(() => console.error(`failed to finish onboarding`));
                }
            }, [pollProps.status]);

            if (pollProps.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            return {
                status: 'success',
                getLinkProps: pollProps.getLinkProps,
            };
        },
    };
}
