import React from 'react';
import { BarChartConfig } from './chakraBarChartConfig';
import { buildBarChartViewProps } from './chakraBarChartFactory';
import { ChakraBarChartComponents } from './chakraBarChartInterface';
import { createBarChartV2View } from './chakraBarChartView';

export function createChakraBarChart(
    config: BarChartConfig = {}
): ChakraBarChartComponents {
    const View = createBarChartV2View();
    const instance: ChakraBarChartComponents = {
        Container(props) {
            const viewProps = buildBarChartViewProps(instance, props);
            return <View {...viewProps} />;
        },
        Item(props) {
            return <></>;
        },
        Scale(props) {
            return <></>;
        },
    };
    return instance;
}
