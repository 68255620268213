import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { AssetResource } from './assetInterface';
import { AssetDtoListSchema, AssetDtoSchema } from './assetSchema';

export function createAssetResource(client: AxiosInstance): AssetResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = { ...query, page_size: 1000 };

            const headers = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.get('/api/v1/assets', {
                params,
                headers,
            });

            const parsed = AssetDtoListSchema.parse(response.data);
            return parsed;
        },
        async create(context, request) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            const headers = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.post(`/api/v1/assets`, request, {
                params,
                headers,
            });

            const parsed = AssetDtoSchema.parse(response.data);
            return parsed;
        },
        async update(context, id, request) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            const headers = {
                Authorization: `bearer ${scheme.store.authToken}`,
            };

            const response = await client.patch(`/api/v1/assets/${id}`, request, {
                params,
                headers,
            });

            const parsed = AssetDtoSchema.parse(response.data);
            return parsed;
        },
    };
}
