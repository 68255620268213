import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../stores/setupContext';
import {
    AdvancedProps,
    AdvancedRegistrationForm,
} from '../../../components/Registration/Advanced/Advanced';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Spinner } from '@chakra-ui/react';

export interface AdvancedFormProps {
    onContinue: () => void;
    formProps: AdvancedProps;
}

export const AdvancedRegisterWrapper: React.FC<
    { children?: React.ReactNode } & AdvancedProps
> = (props) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            {/* <Heading fontSize="lg" marginBottom={4}>
                Account Information
            </Heading> */}
            <AdvancedRegistrationForm {...props} />
        </Flex>
    );
};

const Observed = observer(AdvancedRegisterWrapper);

const WithStoreConnection = () => {
    const {
        registration,
        auth: { authToken, loading: authLoading, currentUser, loggedInOrganization },
    } = useStore();
    const { isLoading, err, updateUser } = registration;
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!authToken && !currentUser && !authLoading) {
            navigate('../login');
        }
    }, [authToken, authLoading, currentUser]);

    if (currentUser) {
        return (
            <Observed
                {...{
                    updateUser,
                    user: currentUser,
                    organization: loggedInOrganization,
                    isLoading,
                    err,
                }}
            />
        );
    }
    return <Spinner />;
};

export default observer(WithStoreConnection);
