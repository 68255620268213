import React from 'react';
import {
    MetricCalculation,
    MetricConfiguration,
    MetricDefinition,
} from '../../../domain/metrics';
import { ComparisonConfig } from './comparisonConfig';
import { createComparisonController } from './comparisonController';
import { createComparisonView } from './comparisonView';

export function createComparisonContainer(config: ComparisonConfig): React.FC<
    { children?: React.ReactNode | undefined } & {
        metric: MetricDefinition;
        ranking: MetricDefinition | null;
        calculation: MetricCalculation;
        configuration: MetricConfiguration;
    }
> {
    const { hook } = config;
    const controller = createComparisonController();
    const View = createComparisonView(config);
    return ({ metric, calculation, ranking, configuration }) => {
        const formatter = hook.useValueFormatter();
        const organization = hook.useOrganization();
        const workspace = hook.useWorkspace();
        const auth = hook.useAuth();
        const viewProps = controller.useProps(
            { formatter },
            {
                organization,
                workspace,
                auth,
            },
            metric,
            configuration,
            ranking,
            calculation
        );
        return <View {...viewProps} />;
    };
}
