import { z } from 'zod';
import { EMAIL_REGEX } from '../../../../vendor/common-regex';

export const AcceptProfileFormValuesSchema = z.object({
    email: z
        .string()
        .trim()
        .nonempty({
            message: 'Required',
        })
        .regex(EMAIL_REGEX, { message: 'Must be a valid email' }),
    firstname: z.string().trim().nonempty({
        message: 'Required',
    }),
    lastname: z.string().trim().nonempty({
        message: 'Required',
    }),
    // role: z.string().trim().nonempty({
    //     message: 'Required',
    // }),
});
