import { useMemo } from 'react';
import { castPropertyToInputProps } from './propertyCast';
import { PropertyInputConfig } from './propertyConfig';
import { PropertyInputController } from './propertyInterface';

export function createPropertyInputController(
    config: PropertyInputConfig
): PropertyInputController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(props) {
            const inputProps = useMemo(() => {
                return castPropertyToInputProps(formatter, props);
            }, [props]);
            return inputProps;
        },
    };
}
