import { useQuery } from '@tanstack/react-query';
import { useStore } from '../../stores/setupContext';
import { Onboarding } from '../../models';
import { makeNewIntegrateHandler, makeReconnectHandler } from '../integrations/funcs';
import { OnboardingConfig } from './onboardingConfig';
import { useNavigate } from 'react-router';

export const useOnboarding = (config: OnboardingConfig): Onboarding => {
    const {
        auth: { requiredState, getMyAccount },
        integrations: { redirectToConnectLink, redirectToReconnecLink },
    } = useStore();
    const navigate = useNavigate();

    const onConnect = makeNewIntegrateHandler(navigate, redirectToConnectLink, { newTab: true });
    const onReconnect = makeReconnectHandler(navigate, redirectToReconnecLink, { newTab: true });

    // we refresh the account onboarding status when the user
    // returns back to the original tab
    useQuery(
        ['onboarding'],
        async () => {
            const response = await getMyAccount();
            return response;
        },
        { refetchOnReconnect: true, refetchOnWindowFocus: true }
    );

    return {
        onConnect,
        onReconnect,
        canContinue: requiredState?.can_skip ?? false,
    };
};
