import { periodToDays } from '../../../../../domain';
import { DateForm } from '../../../../service/query';
import { FeatureMiddleware } from '../../featureInterface';

export function createDateFeatureMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceForm(form: DateForm): DateForm {
            return {
                ...form,
                useFormState(props) {
                    const features = api.useFeature('date_ranges');
                    const formState = form.useFormState(props);

                    const lookbackDays = features.feature?.config?.lookback_period_days;

                    if (!lookbackDays) {
                        return formState;
                    }

                    return {
                        ...formState,
                        setValue(value) {
                            const days = periodToDays(value.period);
                            if (days <= lookbackDays) {
                                return formState.setValue(value);
                            }
                            features.restrict({
                                ...features.feature!,
                                value: {
                                    ...formState.getValue(),
                                    dashboard: null,
                                },
                            });
                        },
                    };
                },
            };
        }

        return create({
            ...config,
            form: {
                ...config.form,
                query: {
                    ...config.form.query,
                    createDate() {
                        const form = config.form.query.createDate();
                        return enhanceForm(form);
                    },
                },
            },
        });
    };
}
