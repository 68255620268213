import { EntityControlConfig } from './entityControlConfig';
import { EntityControlController } from './entityControlInterface';

export function createEntityControlController(
    config: EntityControlConfig
): EntityControlController {
    const { controller } = config;
    return {
        useProps(context, deps, props) {
            const listProps = controller.list.useProps(context, deps, {
                item: props.item,
                onClick(value) {
                    props.onChange({
                        id: value.id,
                    });
                    deps.disclosure.onClose();
                },
            });
            return {
                getListProps() {
                    return listProps;
                },
                getItemProps(item) {
                    const itemProps = listProps.getItemProps(item);
                    return {
                        ...itemProps,
                        onClick() {
                            if (item.isDisabled) {
                                return;
                            }
                            props.onChange({ id: item.value });
                            deps.disclosure.onClose();
                        },
                    };
                },

                getClearProps() {
                    return {
                        onClick() {
                            props.onClear();
                            deps.disclosure.onClose();
                        },
                    };
                },
            };
        },
    };
}
