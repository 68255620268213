import React from 'react';
import { ChargeCompleteController } from './chargeCompleteInterface';
import { ChargeCompleteViewProps } from './chargeCompleteProps';

export function createChargeCompletedController(): ChargeCompleteController {
    return {
        useProps(context, props, viewData) {
            React.useEffect(() => {
                props.mutation.refreshSubscription.mutate(viewData.subscription.id.toString())
            }, []);
            const status = React.useMemo<ChargeCompleteViewProps['status']>(() => {
                if(props.mutation.refreshSubscription.isError) {
                    return 'error'
                } else if(props.mutation.refreshSubscription.isLoading ||  !props.mutation.refreshSubscription.data) {
                    return 'processing';
                }
                return 'success'
            }, [
                props.mutation.refreshSubscription.isError,
                props.mutation.refreshSubscription.isLoading,
                props.mutation.refreshSubscription.data
            ])
            const [nextUrl, setNextUrl] = React.useState<string>();
            React.useEffect(() => {
                if(status === 'success') {
                    setTimeout(() => {
                        if(viewData.nextRoute) {
                            setNextUrl(viewData.nextRoute);
                        } else {
                            setNextUrl('/u');
                        }
                    }, 2000)
                }
            }, [status])
            return {
                status,
                charge: {
                    name: viewData.charge.name,
                },
                nextUrl
            };
        },
    };
}
