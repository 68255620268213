import { ThreadCreatePayloadSchema } from '@varos/assistant-schema';
import { z } from 'zod';
import { deserializeNewThreadOptions } from './threadNewSerdes';

export const ThreadNewUrlParamSchema = z.preprocess(
    (item) => {
        const data = deserializeNewThreadOptions(item as any);
        const { return_path, thread } = data;
        return {
            return_path,
            thread,
        };
    },
    z.object({
        return_path: z.string().nullable().default(null),
        thread: ThreadCreatePayloadSchema,
    })
);
