import {
    DatasetReportV2Entity,
    ExternalReportV2Entity,
    ReportV2Entity,
} from './reportEntity';
import {
    AnyReportDependency,
    ReportIntegrationDependency,
    ReportStudyDependency,
} from './reportModel';

export function isReportIntegrationDependency(
    item: AnyReportDependency
): item is ReportIntegrationDependency {
    return item.kind === 'integration';
}

export function isReportStudyDependency(
    item: AnyReportDependency
): item is ReportStudyDependency {
    return item.kind === 'survey';
}

export function isReportInviteDependency(
    item: AnyReportDependency
): item is ReportStudyDependency {
    return item.kind === 'invite';
}

export function isDatasetReport(item: ReportV2Entity): item is DatasetReportV2Entity {
    return item.kind === 'dataset';
}

export function isExternalReport(item: ReportV2Entity): item is ExternalReportV2Entity {
    return item.kind === 'external';
}
